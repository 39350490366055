export default {
  'sws.myBooking.station': '工位',
  'sws.myBooking.station.colon': '工位：',
  'sws.myBooking.space.colon': '區域：',
  'sws.myBooking.check.in': '簽到',
  'sws.myBooking.info': '提示',
  'sws.myBooking.station.chose.cancel': '您確定要取消已經選擇的工位?',
  'sws.myBooking.station.using': '您正在使用的工位：',
  'sws.myBooking.station.booking': '您預訂的工位：',
  'sws.station.info': '工位信息',
  'sws.cancel.station': '取消預訂',
  'sws.check.station': '檢視工位',
  'sws.myBooking.station.using.or.reserve': '您沒有正在使用或預訂的工位',
  'sws.myBooking.cancel.reserve.station': '您確定要取消已經預訂的工位?',
  'sws.myBooking.logout.station.using': '您確定要提前註銷正在使用的工位?',
  'sws.myBooking.space': '區域',
  'sws.myBooking.booking.time': '預訂時間',
  'sws.myBooking.booking.cancel': '取消預訂',
  'sws.myBooking.booking.time.colon': '預訂時間：',
  'sws.myBooking.used.time.colon': '使用時間：',
  'sws.myBooking.reserveStatus': '狀態',
  'sws.myBooking.canceled': '已取消',
  'sws.myBooking.used': '已使用',
  'sws.myBooking.using': '使用中',
  'sws.myBooking.operation': '操作',
  'sws.myBooking.reserve.again': '再次預訂',
  'sws.myBooking.delay': '延長工位',
  'sws.myBooking.logout': '註銷工位',
  'sws.myBooking.lock.logout': '註銷储物柜',
  'sws.myBooking.station.num': '共{num}個工位',
  'sws.history.not.booking': '您已經有預訂的工位，不支援預訂兩個或以上的座位',
  'sws.history.no.auth': '此工位沒有許可權，請選擇其他工位',
  'sws.history.reBook': '再次預訂',
  'sws.history.not.booked': '您還沒有預訂過工位',
  'sws.reserve.delay.success': '延長成功',
  'sws.chose.time.delay': '選擇延長時間',
  'sws.can.not.delay': '無法延長',
  'locker.can.not.reverse': '很抱歉，您冇有預訂當前儲物櫃的權限',
  'locker.no.reverse': '您當前冇有預訂的儲物櫃哦～',
  'sws.no.reverse.history': '您當前冇有預訂記錄哦～',
  'sws.locker.reverse': '預訂儲物櫃',
  'sws.myBooking.common': '普通預訂',
  'sws.myBooking.category': '类型：',
  'locker.myBooking.not.fixed': '專用儲物櫃不能再次預訂',
  'locker.myBooking.not.use': '當前儲物櫃不可預訂',
  401115: '特殊預訂無法延時',
  'sws.open.success': '開啟成功',
  'sws.open.error': '開啟失敗',
  'sws.open': '開門',
  'sws.selected': '確認取消以上選擇?',
};
