// @ts-nocheck
import {
  createIntl,
  IntlShape,
  MessageDescriptor,
} from '/home/jenkins/workspace/virsical/h5-app/node_modules/@umijs/plugin-locale/node_modules/react-intl';
import { ApplyPluginsType } from 'umi';
import { event, LANG_CHANGE_EVENT } from './locale';
// @ts-ignore
import warning from '/home/jenkins/workspace/virsical/h5-app/node_modules/warning/warning.js';

import { plugin } from '../core/plugin';

export {
  createIntl,
};
export {
  FormattedDate,
  FormattedDateParts,
  FormattedDisplayName,
  FormattedHTMLMessage,
  FormattedList,
  FormattedMessage,
  FormattedNumber,
  FormattedNumberParts,
  FormattedPlural,
  FormattedRelativeTime,
  FormattedTime,
  FormattedTimeParts,
  IntlContext,
  IntlProvider,
  RawIntlProvider,
  createIntlCache,
  defineMessages,
  injectIntl,
  useIntl,
} from '/home/jenkins/workspace/virsical/h5-app/node_modules/@umijs/plugin-locale/node_modules/react-intl';

let g_intl: IntlShape;

const useLocalStorage = true;

import enUS0 from 'antd/es/locale/en_US';
import lang_enUS0 from "/home/jenkins/workspace/virsical/h5-app/src/locales/en-US.js";
import lang_enUS1 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/AccessFace/locales/en-US.js";
import lang_enUS2 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/AccessQrCode/locales/en-US.js";
import lang_enUS3 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/ApplyAccess/locales/en-US.js";
import lang_enUS4 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/FaceAudit/locales/en-US.js";
import lang_enUS5 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/FaultRepairAccess/locales/en-US.js";
import lang_enUS6 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/locales/en-US.js";
import lang_enUS7 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyAccess/locales/en-US.js";
import lang_enUS8 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyFaultRepair/locales/en-US.js";
import lang_enUS9 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/BackgroundManagement/locales/en-US.js";
import lang_enUS10 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/locales/en-US.js";
import lang_enUS11 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/Register/locales/en-US.js";
import lang_enUS12 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Error/locales/en-US.js";
import lang_enUS13 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Feedback/locales/en-US.js";
import lang_enUS14 from "/home/jenkins/workspace/virsical/h5-app/src/pages/FeiShuHomePage/locales/en-US.js";
import lang_enUS15 from "/home/jenkins/workspace/virsical/h5-app/src/pages/HomePage/locales/en-US.js";
import lang_enUS16 from "/home/jenkins/workspace/virsical/h5-app/src/pages/HomePageNew/locales/en-US.js";
import lang_enUS17 from "/home/jenkins/workspace/virsical/h5-app/src/pages/IndexMap/components/swsPopUp/locales/en-US.js";
import lang_enUS18 from "/home/jenkins/workspace/virsical/h5-app/src/pages/IndexMap/locales/en-US.js";
import lang_enUS19 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Inform/locales/en-US.js";
import lang_enUS20 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/ConfirmDialog/locales/en-US.js";
import lang_enUS21 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/IncludeBottom/locales/en-US.js";
import lang_enUS22 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/Loading/locales/en-US.js";
import lang_enUS23 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/MapRoomDetail/locales/en-US.js";
import lang_enUS24 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/RoomFilter/locales/en-US.js";
import lang_enUS25 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/RouteModal/locales/en-US.js";
import lang_enUS26 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/locales/en-US.js";
import lang_enUS27 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/About/locales/en-US.js";
import lang_enUS28 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/AttendeePage/locales/en-US.js";
import lang_enUS29 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/CycleTab/locales/en-US.js";
import lang_enUS30 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/DeviceTab/locales/en-US.js";
import lang_enUS31 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ExternalContact/locales/en-US.js";
import lang_enUS32 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ExternalPage/locales/en-US.js";
import lang_enUS33 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Feedback/locales/en-US.js";
import lang_enUS34 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/HomePage/locales/en-US.js";
import lang_enUS35 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingCmma/locales/en-US.js";
import lang_enUS36 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingDetail/locales/en-US.js";
import lang_enUS37 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingSeat/locales/en-US.js";
import lang_enUS38 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page1/locales/en-US.js";
import lang_enUS39 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page2/components/AccessQrCode/locales/en-US.js";
import lang_enUS40 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page2/locales/en-US.js";
import lang_enUS41 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyMeeting/locales/en-US.js";
import lang_enUS42 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/NewAttendeePageDetail/locales/en-US.js";
import lang_enUS43 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Polling/locales/en-US.js";
import lang_enUS44 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/PollingQuestion/locales/en-US.js";
import lang_enUS45 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/PollingTitle/locales/en-US.js";
import lang_enUS46 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomDetail/locales/en-US.js";
import lang_enUS47 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomFeeDetail/locales/en-US.js";
import lang_enUS48 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomList/locales/en-US.js";
import lang_enUS49 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ScanCodePage/locales/en-US.js";
import lang_enUS50 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SignTab/locales/en-US.js";
import lang_enUS51 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Subscribe/locales/en-US.js";
import lang_enUS52 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SubscribeDetail/locales/en-US.js";
import lang_enUS53 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcApply/locales/en-US.js";
import lang_enUS54 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcApprovalDetail/locales/en-US.js";
import lang_enUS55 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcFeeDetail/locales/en-US.js";
import lang_enUS56 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcTab/locales/en-US.js";
import lang_enUS57 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ZoomPolling/locales/en-US.js";
import lang_enUS58 from "/home/jenkins/workspace/virsical/h5-app/src/pages/MyInfo/locales/en-US.js";
import lang_enUS59 from "/home/jenkins/workspace/virsical/h5-app/src/pages/NotSubscribePage/locales/en-US.js";
import lang_enUS60 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Points/locales/en-US.js";
import lang_enUS61 from "/home/jenkins/workspace/virsical/h5-app/src/pages/ScanAuth/locales/en-US.js";
import lang_enUS62 from "/home/jenkins/workspace/virsical/h5-app/src/pages/ScanAuthPage/locales/en-US.js";
import lang_enUS63 from "/home/jenkins/workspace/virsical/h5-app/src/pages/ScanQRCode/locales/en-US.js";
import lang_enUS64 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SetLanguage/locales/en-US.js";
import lang_enUS65 from "/home/jenkins/workspace/virsical/h5-app/src/pages/setPassword/locales/en-US.js";
import lang_enUS66 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SetPhone/locales/en-US.js";
import lang_enUS67 from "/home/jenkins/workspace/virsical/h5-app/src/pages/setSetting/locales/en-US.js";
import lang_enUS68 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Common/locales/en-US.js";
import lang_enUS69 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Immediate/locales/en-US.js";
import lang_enUS70 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerBook/locales/en-US.js";
import lang_enUS71 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerMyBooking/locales/en-US.js";
import lang_enUS72 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/OpenDoor/locales/en-US.js";
import lang_enUS73 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Scan/locales/en-US.js";
import lang_enUS74 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Tcs/locales/en-US.js";
import lang_enUS75 from "/home/jenkins/workspace/virsical/h5-app/src/pages/User/locales/en-US.js";
import lang_enUS76 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Vap/pages/MeetingControl/locales/en-US.js";
import lang_enUS77 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/locales/en-US.js";
import lang_enUS78 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/InvitationCode/locales/en-US.js";
import lang_enUS79 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WaferTokenLogin/locales/en-US.js";
import lang_enUS80 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/CloudHub/locales/en-US.js";
import lang_enUS81 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/Dingding/locales/en-US.js";
import lang_enUS82 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/FeiShu/locales/en-US.js";
import lang_enUS83 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/locales/en-US.js";
import lang_enUS84 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/WeChat/locales/en-US.js";
import lang_enUS85 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Common/locales/en-US.js";
import lang_enUS86 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Dashboard/locales/en-US.js";
import lang_enUS87 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/DashboardNew/locales/en-US.js";
import lang_enUS88 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Delay/locales/en-US.js";
import lang_enUS89 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/locales/en-US.js";
import lang_enUS90 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Scan/locales/en-US.js";
import lang_enUS91 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Booking/locales/en-US.js";
import lang_enUS92 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Cover/locales/en-US.js";
import lang_enUS93 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Delay/locales/en-US.js";
import lang_enUS94 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Detail/locales/en-US.js";
import lang_enUS95 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FacilityManage/locales/en-US.js";
import lang_enUS96 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FindStation/locales/en-US.js";
import lang_enUS97 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Immediate/locales/en-US.js";
import lang_enUS98 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Inventory/locales/en-US.js";
import lang_enUS99 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/InventoryList/locales/en-US.js";
import lang_enUS100 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/LiftTable/locales/en-US.js";
import lang_enUS101 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/MyBooking/locales/en-US.js";
import lang_enUS102 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/newInventory/locales/en-US.js";
import lang_enUS103 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RecommendBooking/locales/en-US.js";
import lang_enUS104 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Repair/locales/en-US.js";
import lang_enUS105 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RepairManage/locales/en-US.js";
import lang_enUS106 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Search/locales/en-US.js";
import lang_enUS107 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Sensor/locales/en-US.js";
import lang_enUS108 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/SignOut/locales/en-US.js";
import lang_enUS109 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/locales/en-US.js";
import zhCN0 from 'antd/es/locale/zh_CN';
import lang_zhCN0 from "/home/jenkins/workspace/virsical/h5-app/src/locales/zh-CN.js";
import lang_zhCN1 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/AccessFace/locales/zh-CN.js";
import lang_zhCN2 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/AccessQrCode/locales/zh-CN.js";
import lang_zhCN3 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/ApplyAccess/locales/zh-CN.js";
import lang_zhCN4 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/FaceAudit/locales/zh-CN.js";
import lang_zhCN5 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/FaultRepairAccess/locales/zh-CN.js";
import lang_zhCN6 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/locales/zh-CN.js";
import lang_zhCN7 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyAccess/locales/zh-CN.js";
import lang_zhCN8 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyFaultRepair/locales/zh-CN.js";
import lang_zhCN9 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/BackgroundManagement/locales/zh-CN.js";
import lang_zhCN10 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/locales/zh-CN.js";
import lang_zhCN11 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/Register/locales/zh-CN.js";
import lang_zhCN12 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Error/locales/zh-CN.js";
import lang_zhCN13 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Feedback/locales/zh-CN.js";
import lang_zhCN14 from "/home/jenkins/workspace/virsical/h5-app/src/pages/FeiShuHomePage/locales/zh-CN.js";
import lang_zhCN15 from "/home/jenkins/workspace/virsical/h5-app/src/pages/HomePage/locales/zh-CN.js";
import lang_zhCN16 from "/home/jenkins/workspace/virsical/h5-app/src/pages/HomePageNew/locales/zh-CN.js";
import lang_zhCN17 from "/home/jenkins/workspace/virsical/h5-app/src/pages/IndexMap/components/swsPopUp/locales/zh-CN.js";
import lang_zhCN18 from "/home/jenkins/workspace/virsical/h5-app/src/pages/IndexMap/locales/zh-CN.js";
import lang_zhCN19 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Inform/locales/zh-CN.js";
import lang_zhCN20 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/ConfirmDialog/locales/zh-CN.js";
import lang_zhCN21 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/IncludeBottom/locales/zh-CN.js";
import lang_zhCN22 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/Loading/locales/zh-CN.js";
import lang_zhCN23 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/MapRoomDetail/locales/zh-CN.js";
import lang_zhCN24 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/RoomFilter/locales/zh-CN.js";
import lang_zhCN25 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/RouteModal/locales/zh-CN.js";
import lang_zhCN26 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/locales/zh-CN.js";
import lang_zhCN27 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/About/locales/zh-CN.js";
import lang_zhCN28 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/AttendeePage/locales/zh-CN.js";
import lang_zhCN29 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/CycleTab/locales/zh-CN.js";
import lang_zhCN30 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/DeviceTab/locales/zh-CN.js";
import lang_zhCN31 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ExternalContact/locales/zh-CN.js";
import lang_zhCN32 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ExternalPage/locales/zh-CN.js";
import lang_zhCN33 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Feedback/locales/zh-CN.js";
import lang_zhCN34 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/HomePage/locales/zh-CN.js";
import lang_zhCN35 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingCmma/locales/zh-CN.js";
import lang_zhCN36 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingDetail/locales/zh-CN.js";
import lang_zhCN37 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingSeat/locales/zh-CN.js";
import lang_zhCN38 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page1/locales/zh-CN.js";
import lang_zhCN39 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page2/components/AccessQrCode/locales/zh-CN.js";
import lang_zhCN40 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page2/locales/zh-CN.js";
import lang_zhCN41 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyMeeting/locales/zh-CN.js";
import lang_zhCN42 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/NewAttendeePageDetail/locales/zh-CN.js";
import lang_zhCN43 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Polling/locales/zh-CN.js";
import lang_zhCN44 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/PollingQuestion/locales/zh-CN.js";
import lang_zhCN45 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/PollingTitle/locales/zh-CN.js";
import lang_zhCN46 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomDetail/locales/zh-CN.js";
import lang_zhCN47 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomFeeDetail/locales/zh-CN.js";
import lang_zhCN48 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomList/locales/zh-CN.js";
import lang_zhCN49 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ScanCodePage/locales/zh-CN.js";
import lang_zhCN50 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SignTab/locales/zh-CN.js";
import lang_zhCN51 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Subscribe/locales/zh-CN.js";
import lang_zhCN52 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SubscribeDetail/locales/zh-CN.js";
import lang_zhCN53 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcApply/locales/zh-CN.js";
import lang_zhCN54 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcApprovalDetail/locales/zh-CN.js";
import lang_zhCN55 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcFeeDetail/locales/zh-CN.js";
import lang_zhCN56 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcTab/locales/zh-CN.js";
import lang_zhCN57 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ZoomPolling/locales/zh-CN.js";
import lang_zhCN58 from "/home/jenkins/workspace/virsical/h5-app/src/pages/MyInfo/locales/zh-CN.js";
import lang_zhCN59 from "/home/jenkins/workspace/virsical/h5-app/src/pages/NotSubscribePage/locales/zh-CN.js";
import lang_zhCN60 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Points/locales/zh-CN.js";
import lang_zhCN61 from "/home/jenkins/workspace/virsical/h5-app/src/pages/ScanAuth/locales/zh-CN.js";
import lang_zhCN62 from "/home/jenkins/workspace/virsical/h5-app/src/pages/ScanAuthPage/locales/zh-CN.js";
import lang_zhCN63 from "/home/jenkins/workspace/virsical/h5-app/src/pages/ScanQRCode/locales/zh-CN.js";
import lang_zhCN64 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SetLanguage/locales/zh-CN.js";
import lang_zhCN65 from "/home/jenkins/workspace/virsical/h5-app/src/pages/setPassword/locales/zh-CN.js";
import lang_zhCN66 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SetPhone/locales/zh-CN.js";
import lang_zhCN67 from "/home/jenkins/workspace/virsical/h5-app/src/pages/setSetting/locales/zh-CN.js";
import lang_zhCN68 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Common/locales/zh-CN.js";
import lang_zhCN69 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Immediate/locales/zh-CN.js";
import lang_zhCN70 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerBook/locales/zh-CN.js";
import lang_zhCN71 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerMyBooking/locales/zh-CN.js";
import lang_zhCN72 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/OpenDoor/locales/zh-CN.js";
import lang_zhCN73 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Scan/locales/zh-CN.js";
import lang_zhCN74 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Tcs/locales/zh-CN.js";
import lang_zhCN75 from "/home/jenkins/workspace/virsical/h5-app/src/pages/User/locales/zh-CN.js";
import lang_zhCN76 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Vap/pages/MeetingControl/locales/zh-CN.js";
import lang_zhCN77 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/locales/zh-CN.js";
import lang_zhCN78 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/InvitationCode/locales/zh-CN.js";
import lang_zhCN79 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WaferTokenLogin/locales/zh-CN.js";
import lang_zhCN80 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/CloudHub/locales/zh-CN.js";
import lang_zhCN81 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/Dingding/locales/zh-CN.js";
import lang_zhCN82 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/FeiShu/locales/zh-CN.js";
import lang_zhCN83 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/locales/zh-CN.js";
import lang_zhCN84 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/WeChat/locales/zh-CN.js";
import lang_zhCN85 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Common/locales/zh-CN.js";
import lang_zhCN86 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Dashboard/locales/zh-CN.js";
import lang_zhCN87 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/DashboardNew/locales/zh-CN.js";
import lang_zhCN88 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Delay/locales/zh-CN.js";
import lang_zhCN89 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/locales/zh-CN.js";
import lang_zhCN90 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Scan/locales/zh-CN.js";
import lang_zhCN91 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Booking/locales/zh-CN.js";
import lang_zhCN92 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Cover/locales/zh-CN.js";
import lang_zhCN93 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Delay/locales/zh-CN.js";
import lang_zhCN94 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Detail/locales/zh-CN.js";
import lang_zhCN95 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FacilityManage/locales/zh-CN.js";
import lang_zhCN96 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FindStation/locales/zh-CN.js";
import lang_zhCN97 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Immediate/locales/zh-CN.js";
import lang_zhCN98 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Inventory/locales/zh-CN.js";
import lang_zhCN99 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/InventoryList/locales/zh-CN.js";
import lang_zhCN100 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/LiftTable/locales/zh-CN.js";
import lang_zhCN101 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/MyBooking/locales/zh-CN.js";
import lang_zhCN102 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/newInventory/locales/zh-CN.js";
import lang_zhCN103 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RecommendBooking/locales/zh-CN.js";
import lang_zhCN104 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Repair/locales/zh-CN.js";
import lang_zhCN105 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RepairManage/locales/zh-CN.js";
import lang_zhCN106 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Search/locales/zh-CN.js";
import lang_zhCN107 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Sensor/locales/zh-CN.js";
import lang_zhCN108 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/SignOut/locales/zh-CN.js";
import lang_zhCN109 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/locales/zh-CN.js";
import zhTW0 from 'antd/es/locale/zh_TW';
import lang_zhTW0 from "/home/jenkins/workspace/virsical/h5-app/src/locales/zh-TW.js";
import lang_zhTW1 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/AccessFace/locales/zh-TW.js";
import lang_zhTW2 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/AccessQrCode/locales/zh-TW.js";
import lang_zhTW3 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/ApplyAccess/locales/zh-TW.js";
import lang_zhTW4 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/FaultRepairAccess/locales/zh-TW.js";
import lang_zhTW5 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/locales/zh-TW.js";
import lang_zhTW6 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyAccess/locales/zh-TW.js";
import lang_zhTW7 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyFaultRepair/locales/zh-TW.js";
import lang_zhTW8 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/BackgroundManagement/locales/zh-TW.js";
import lang_zhTW9 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/locales/zh-TW.js";
import lang_zhTW10 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/Register/locales/zh-TW.js";
import lang_zhTW11 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Error/locales/zh-TW.js";
import lang_zhTW12 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Feedback/locales/zh-TW.js";
import lang_zhTW13 from "/home/jenkins/workspace/virsical/h5-app/src/pages/FeiShuHomePage/locales/zh-TW.js";
import lang_zhTW14 from "/home/jenkins/workspace/virsical/h5-app/src/pages/HomePage/locales/zh-TW.js";
import lang_zhTW15 from "/home/jenkins/workspace/virsical/h5-app/src/pages/HomePageNew/locales/zh-TW.js";
import lang_zhTW16 from "/home/jenkins/workspace/virsical/h5-app/src/pages/IndexMap/components/swsPopUp/locales/zh-TW.js";
import lang_zhTW17 from "/home/jenkins/workspace/virsical/h5-app/src/pages/IndexMap/locales/zh-TW.js";
import lang_zhTW18 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Inform/locales/zh-TW.js";
import lang_zhTW19 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/ConfirmDialog/locales/zh-TW.js";
import lang_zhTW20 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/IncludeBottom/locales/zh-TW.js";
import lang_zhTW21 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/Loading/locales/zh-TW.js";
import lang_zhTW22 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/MapRoomDetail/locales/zh-TW.js";
import lang_zhTW23 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/RoomFilter/locales/zh-TW.js";
import lang_zhTW24 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/RouteModal/locales/zh-TW.js";
import lang_zhTW25 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/locales/zh-TW.js";
import lang_zhTW26 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/About/locales/zh-TW.js";
import lang_zhTW27 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/AttendeePage/locales/zh-TW.js";
import lang_zhTW28 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/CycleTab/locales/zh-TW.js";
import lang_zhTW29 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/DeviceTab/locales/zh-TW.js";
import lang_zhTW30 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ExternalContact/locales/zh-TW.js";
import lang_zhTW31 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ExternalPage/locales/zh-TW.js";
import lang_zhTW32 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Feedback/locales/zh-TW.js";
import lang_zhTW33 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/HomePage/locales/zh-TW.js";
import lang_zhTW34 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingCmma/locales/zh-TW.js";
import lang_zhTW35 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingDetail/locales/zh-TW.js";
import lang_zhTW36 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingSeat/locales/zh-TW.js";
import lang_zhTW37 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page1/locales/zh-TW.js";
import lang_zhTW38 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page2/components/AccessQrCode/locales/zh-TW.js";
import lang_zhTW39 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page2/locales/zh-TW.js";
import lang_zhTW40 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyMeeting/locales/zh-TW.js";
import lang_zhTW41 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/NewAttendeePageDetail/locales/zh-TW.js";
import lang_zhTW42 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Polling/locales/zh-TW.js";
import lang_zhTW43 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/PollingQuestion/locales/zh-TW.js";
import lang_zhTW44 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/PollingTitle/locales/zh-TW.js";
import lang_zhTW45 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomDetail/locales/zh-TW.js";
import lang_zhTW46 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomFeeDetail/locales/zh-TW.js";
import lang_zhTW47 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomList/locales/zh-TW.js";
import lang_zhTW48 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ScanCodePage/locales/zh-TW.js";
import lang_zhTW49 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SignTab/locales/zh-TW.js";
import lang_zhTW50 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Subscribe/locales/zh-TW.js";
import lang_zhTW51 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SubscribeDetail/locales/zh-TW.js";
import lang_zhTW52 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcApply/locales/zh-TW.js";
import lang_zhTW53 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcApprovalDetail/locales/zh-TW.js";
import lang_zhTW54 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcFeeDetail/locales/zh-TW.js";
import lang_zhTW55 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcTab/locales/zh-TW.js";
import lang_zhTW56 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ZoomPolling/locales/zh-TW.js";
import lang_zhTW57 from "/home/jenkins/workspace/virsical/h5-app/src/pages/MyInfo/locales/zh-TW.js";
import lang_zhTW58 from "/home/jenkins/workspace/virsical/h5-app/src/pages/NotSubscribePage/locales/zh-TW.js";
import lang_zhTW59 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Points/locales/zh-TW.js";
import lang_zhTW60 from "/home/jenkins/workspace/virsical/h5-app/src/pages/ScanAuth/locales/zh-TW.js";
import lang_zhTW61 from "/home/jenkins/workspace/virsical/h5-app/src/pages/ScanAuthPage/locales/zh-TW.js";
import lang_zhTW62 from "/home/jenkins/workspace/virsical/h5-app/src/pages/ScanQRCode/locales/zh-TW.js";
import lang_zhTW63 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SetLanguage/locales/zh-TW.js";
import lang_zhTW64 from "/home/jenkins/workspace/virsical/h5-app/src/pages/setPassword/locales/zh-TW.js";
import lang_zhTW65 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SetPhone/locales/zh-TW.js";
import lang_zhTW66 from "/home/jenkins/workspace/virsical/h5-app/src/pages/setSetting/locales/zh-TW.js";
import lang_zhTW67 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Common/locales/zh-TW.js";
import lang_zhTW68 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Immediate/locales/zh-TW.js";
import lang_zhTW69 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerBook/locales/zh-TW.js";
import lang_zhTW70 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerMyBooking/locales/zh-TW.js";
import lang_zhTW71 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/OpenDoor/locales/zh-TW.js";
import lang_zhTW72 from "/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Scan/locales/zh-TW.js";
import lang_zhTW73 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Tcs/locales/zh-TW.js";
import lang_zhTW74 from "/home/jenkins/workspace/virsical/h5-app/src/pages/User/locales/zh-TW.js";
import lang_zhTW75 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Vap/pages/MeetingControl/locales/zh-TW.js";
import lang_zhTW76 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/locales/zh-TW.js";
import lang_zhTW77 from "/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/InvitationCode/locales/zh-TW.js";
import lang_zhTW78 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WaferTokenLogin/locales/zh-TW.js";
import lang_zhTW79 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/CloudHub/locales/zh-TW.js";
import lang_zhTW80 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/Dingding/locales/zh-TW.js";
import lang_zhTW81 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/FeiShu/locales/zh-TW.js";
import lang_zhTW82 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/locales/zh-TW.js";
import lang_zhTW83 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/WeChat/locales/zh-TW.js";
import lang_zhTW84 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Common/locales/zh-TW.js";
import lang_zhTW85 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Dashboard/locales/zh-TW.js";
import lang_zhTW86 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/DashboardNew/locales/zh-TW.js";
import lang_zhTW87 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Delay/locales/zh-TW.js";
import lang_zhTW88 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/locales/zh-TW.js";
import lang_zhTW89 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Scan/locales/zh-TW.js";
import lang_zhTW90 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Booking/locales/zh-TW.js";
import lang_zhTW91 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Cover/locales/zh-TW.js";
import lang_zhTW92 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Delay/locales/zh-TW.js";
import lang_zhTW93 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Detail/locales/zh-TW.js";
import lang_zhTW94 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FacilityManage/locales/zh-TW.js";
import lang_zhTW95 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FindStation/locales/zh-TW.js";
import lang_zhTW96 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Immediate/locales/zh-TW.js";
import lang_zhTW97 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Inventory/locales/zh-TW.js";
import lang_zhTW98 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/InventoryList/locales/zh-TW.js";
import lang_zhTW99 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/LiftTable/locales/zh-TW.js";
import lang_zhTW100 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/MyBooking/locales/zh-TW.js";
import lang_zhTW101 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/newInventory/locales/zh-TW.js";
import lang_zhTW102 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RecommendBooking/locales/zh-TW.js";
import lang_zhTW103 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Repair/locales/zh-TW.js";
import lang_zhTW104 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RepairManage/locales/zh-TW.js";
import lang_zhTW105 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Search/locales/zh-TW.js";
import lang_zhTW106 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Sensor/locales/zh-TW.js";
import lang_zhTW107 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/SignOut/locales/zh-TW.js";
import lang_zhTW108 from "/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/locales/zh-TW.js";

export const localeInfo: {[key: string]: any} = {
  'en-US': {
    messages: {
      ...lang_enUS0,...lang_enUS1,...lang_enUS2,...lang_enUS3,...lang_enUS4,...lang_enUS5,...lang_enUS6,...lang_enUS7,...lang_enUS8,...lang_enUS9,...lang_enUS10,...lang_enUS11,...lang_enUS12,...lang_enUS13,...lang_enUS14,...lang_enUS15,...lang_enUS16,...lang_enUS17,...lang_enUS18,...lang_enUS19,...lang_enUS20,...lang_enUS21,...lang_enUS22,...lang_enUS23,...lang_enUS24,...lang_enUS25,...lang_enUS26,...lang_enUS27,...lang_enUS28,...lang_enUS29,...lang_enUS30,...lang_enUS31,...lang_enUS32,...lang_enUS33,...lang_enUS34,...lang_enUS35,...lang_enUS36,...lang_enUS37,...lang_enUS38,...lang_enUS39,...lang_enUS40,...lang_enUS41,...lang_enUS42,...lang_enUS43,...lang_enUS44,...lang_enUS45,...lang_enUS46,...lang_enUS47,...lang_enUS48,...lang_enUS49,...lang_enUS50,...lang_enUS51,...lang_enUS52,...lang_enUS53,...lang_enUS54,...lang_enUS55,...lang_enUS56,...lang_enUS57,...lang_enUS58,...lang_enUS59,...lang_enUS60,...lang_enUS61,...lang_enUS62,...lang_enUS63,...lang_enUS64,...lang_enUS65,...lang_enUS66,...lang_enUS67,...lang_enUS68,...lang_enUS69,...lang_enUS70,...lang_enUS71,...lang_enUS72,...lang_enUS73,...lang_enUS74,...lang_enUS75,...lang_enUS76,...lang_enUS77,...lang_enUS78,...lang_enUS79,...lang_enUS80,...lang_enUS81,...lang_enUS82,...lang_enUS83,...lang_enUS84,...lang_enUS85,...lang_enUS86,...lang_enUS87,...lang_enUS88,...lang_enUS89,...lang_enUS90,...lang_enUS91,...lang_enUS92,...lang_enUS93,...lang_enUS94,...lang_enUS95,...lang_enUS96,...lang_enUS97,...lang_enUS98,...lang_enUS99,...lang_enUS100,...lang_enUS101,...lang_enUS102,...lang_enUS103,...lang_enUS104,...lang_enUS105,...lang_enUS106,...lang_enUS107,...lang_enUS108,...lang_enUS109,
    },
    locale: 'en-US',
    antd: {
      ...enUS0,
    },
    momentLocale: '',
  },
  'zh-CN': {
    messages: {
      ...lang_zhCN0,...lang_zhCN1,...lang_zhCN2,...lang_zhCN3,...lang_zhCN4,...lang_zhCN5,...lang_zhCN6,...lang_zhCN7,...lang_zhCN8,...lang_zhCN9,...lang_zhCN10,...lang_zhCN11,...lang_zhCN12,...lang_zhCN13,...lang_zhCN14,...lang_zhCN15,...lang_zhCN16,...lang_zhCN17,...lang_zhCN18,...lang_zhCN19,...lang_zhCN20,...lang_zhCN21,...lang_zhCN22,...lang_zhCN23,...lang_zhCN24,...lang_zhCN25,...lang_zhCN26,...lang_zhCN27,...lang_zhCN28,...lang_zhCN29,...lang_zhCN30,...lang_zhCN31,...lang_zhCN32,...lang_zhCN33,...lang_zhCN34,...lang_zhCN35,...lang_zhCN36,...lang_zhCN37,...lang_zhCN38,...lang_zhCN39,...lang_zhCN40,...lang_zhCN41,...lang_zhCN42,...lang_zhCN43,...lang_zhCN44,...lang_zhCN45,...lang_zhCN46,...lang_zhCN47,...lang_zhCN48,...lang_zhCN49,...lang_zhCN50,...lang_zhCN51,...lang_zhCN52,...lang_zhCN53,...lang_zhCN54,...lang_zhCN55,...lang_zhCN56,...lang_zhCN57,...lang_zhCN58,...lang_zhCN59,...lang_zhCN60,...lang_zhCN61,...lang_zhCN62,...lang_zhCN63,...lang_zhCN64,...lang_zhCN65,...lang_zhCN66,...lang_zhCN67,...lang_zhCN68,...lang_zhCN69,...lang_zhCN70,...lang_zhCN71,...lang_zhCN72,...lang_zhCN73,...lang_zhCN74,...lang_zhCN75,...lang_zhCN76,...lang_zhCN77,...lang_zhCN78,...lang_zhCN79,...lang_zhCN80,...lang_zhCN81,...lang_zhCN82,...lang_zhCN83,...lang_zhCN84,...lang_zhCN85,...lang_zhCN86,...lang_zhCN87,...lang_zhCN88,...lang_zhCN89,...lang_zhCN90,...lang_zhCN91,...lang_zhCN92,...lang_zhCN93,...lang_zhCN94,...lang_zhCN95,...lang_zhCN96,...lang_zhCN97,...lang_zhCN98,...lang_zhCN99,...lang_zhCN100,...lang_zhCN101,...lang_zhCN102,...lang_zhCN103,...lang_zhCN104,...lang_zhCN105,...lang_zhCN106,...lang_zhCN107,...lang_zhCN108,...lang_zhCN109,
    },
    locale: 'zh-CN',
    antd: {
      ...zhCN0,
    },
    momentLocale: 'zh-cn',
  },
  'zh-TW': {
    messages: {
      ...lang_zhTW0,...lang_zhTW1,...lang_zhTW2,...lang_zhTW3,...lang_zhTW4,...lang_zhTW5,...lang_zhTW6,...lang_zhTW7,...lang_zhTW8,...lang_zhTW9,...lang_zhTW10,...lang_zhTW11,...lang_zhTW12,...lang_zhTW13,...lang_zhTW14,...lang_zhTW15,...lang_zhTW16,...lang_zhTW17,...lang_zhTW18,...lang_zhTW19,...lang_zhTW20,...lang_zhTW21,...lang_zhTW22,...lang_zhTW23,...lang_zhTW24,...lang_zhTW25,...lang_zhTW26,...lang_zhTW27,...lang_zhTW28,...lang_zhTW29,...lang_zhTW30,...lang_zhTW31,...lang_zhTW32,...lang_zhTW33,...lang_zhTW34,...lang_zhTW35,...lang_zhTW36,...lang_zhTW37,...lang_zhTW38,...lang_zhTW39,...lang_zhTW40,...lang_zhTW41,...lang_zhTW42,...lang_zhTW43,...lang_zhTW44,...lang_zhTW45,...lang_zhTW46,...lang_zhTW47,...lang_zhTW48,...lang_zhTW49,...lang_zhTW50,...lang_zhTW51,...lang_zhTW52,...lang_zhTW53,...lang_zhTW54,...lang_zhTW55,...lang_zhTW56,...lang_zhTW57,...lang_zhTW58,...lang_zhTW59,...lang_zhTW60,...lang_zhTW61,...lang_zhTW62,...lang_zhTW63,...lang_zhTW64,...lang_zhTW65,...lang_zhTW66,...lang_zhTW67,...lang_zhTW68,...lang_zhTW69,...lang_zhTW70,...lang_zhTW71,...lang_zhTW72,...lang_zhTW73,...lang_zhTW74,...lang_zhTW75,...lang_zhTW76,...lang_zhTW77,...lang_zhTW78,...lang_zhTW79,...lang_zhTW80,...lang_zhTW81,...lang_zhTW82,...lang_zhTW83,...lang_zhTW84,...lang_zhTW85,...lang_zhTW86,...lang_zhTW87,...lang_zhTW88,...lang_zhTW89,...lang_zhTW90,...lang_zhTW91,...lang_zhTW92,...lang_zhTW93,...lang_zhTW94,...lang_zhTW95,...lang_zhTW96,...lang_zhTW97,...lang_zhTW98,...lang_zhTW99,...lang_zhTW100,...lang_zhTW101,...lang_zhTW102,...lang_zhTW103,...lang_zhTW104,...lang_zhTW105,...lang_zhTW106,...lang_zhTW107,...lang_zhTW108,
    },
    locale: 'zh-TW',
    antd: {
      ...zhTW0,
    },
    momentLocale: 'zh-tw',
  },
};

/**
 * 增加一个新的国际化语言
 * @param name 语言的 key
 * @param messages 对应的枚举对象
 * @param extraLocale momentLocale, antd 国际化
 */
export const addLocale = (
  name: string,
  messages: Object,
  extraLocales?: {
    momentLocale: string;
    antd: string;
  },
) => {
  if (!name) {
    return;
  }
  // 可以合并
  const mergeMessages = localeInfo[name]?.messages
    ? Object.assign({}, localeInfo[name].messages, messages)
    : messages;


  const { momentLocale, antd } = extraLocales || {};
  const locale = name.split('-')?.join('-')
  localeInfo[name] = {
    messages: mergeMessages,
    locale,
    momentLocale: momentLocale,
    antd,
  };
   // 如果这是的 name 和当前的locale 相同需要重新设置一下，不然更新不了
  if (locale === getLocale()) {
    event.emit(LANG_CHANGE_EVENT, locale);
  }
};

/**
 * 获取当前的 intl 对象，可以在 node 中使用
 * @param locale 需要切换的语言类型
 * @param changeIntl 是否不使用 g_intl
 * @returns IntlShape
 */
export const getIntl = (locale?: string, changeIntl?: boolean) => {
  // 如果全局的 g_intl 存在，且不是 setIntl 调用
  if (g_intl && !changeIntl && !locale) {
    return g_intl;
  }
  // 如果存在于 localeInfo 中
  if (locale&&localeInfo[locale]) {
    return createIntl(localeInfo[locale]);
  }
  // 不存在需要一个报错提醒
  warning(
    !locale||!!localeInfo[locale],
    `The current popular language does not exist, please check the locales folder!`,
  );
  // 使用 zh-CN
  if (localeInfo["zh-CN"]) return createIntl(localeInfo["zh-CN"]);

  // 如果还没有，返回一个空的
  return createIntl({
    locale: "zh-CN",
    messages: {},
  });
};

/**
 * 切换全局的 intl 的设置
 * @param locale 语言的key
 */
export const setIntl = (locale: string) => {
  g_intl = getIntl(locale, true);
};

/**
 * 获取当前选择的语言
 * @returns string
 */
export const getLocale = () => {
  const runtimeLocale = plugin.applyPlugins({
    key: 'locale',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  // runtime getLocale for user define
  if (typeof runtimeLocale?.getLocale === 'function') {
    return runtimeLocale.getLocale();
  }
  // please clear localStorage if you change the baseSeparator config
  // because changing will break the app
  const lang =
    navigator.cookieEnabled && typeof localStorage !== 'undefined' && useLocalStorage
      ? window.localStorage.getItem('umi_locale')
      : '';
  // support baseNavigator, default true
  let browserLang;
  const isNavigatorLanguageValid =
    typeof navigator !== 'undefined' && typeof navigator.language === 'string';
  browserLang = isNavigatorLanguageValid
    ? navigator.language.split('-').join('-')
    : '';
  return lang || browserLang || "zh-CN";
};


/**
 * 获取当前选择的方向
 * @returns string
 */
export const getDirection = () => {
  const lang = getLocale();
  // array with all prefixs for rtl langueges ex: ar-EG , he-IL
  const rtlLangs = ['he', 'ar', 'fa', 'ku']
  const direction =  rtlLangs.filter(lng => lang.startsWith(lng)).length ? 'rtl' : 'ltr';
  return direction;
};

/**
 * 切换语言
 * @param lang 语言的 key
 * @param realReload 是否刷新页面，默认刷新
 * @returns string
 */
export const setLocale = (lang: string, realReload: boolean = true) => {
  const runtimeLocale = plugin.applyPlugins({
    key: 'locale',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });

  const updater = () => {
    if (getLocale() !== lang) {
      if (navigator.cookieEnabled && typeof window.localStorage !== 'undefined' && useLocalStorage) {
        window.localStorage.setItem('umi_locale', lang || '');
      }
      setIntl(lang);
      if (realReload) {
        window.location.reload();
      } else {
        event.emit(LANG_CHANGE_EVENT, lang);
        // chrome 不支持这个事件。所以人肉触发一下
        if (window.dispatchEvent) {
          const event = new Event('languagechange');
          window.dispatchEvent(event);
        }
      }
    }
  }

  if (typeof runtimeLocale?.setLocale === 'function') {
    runtimeLocale.setLocale({
      lang,
      realReload,
      updater: updater,
    });
    return;
  }

  updater();
};

let firstWaring = true;

/**
 * intl.formatMessage 的语法糖
 * @deprecated 使用此 api 会造成切换语言的时候无法自动刷新，请使用 useIntl 或 injectIntl
 * @param descriptor { id : string, defaultMessage : string }
 * @param values { [key:string] : string }
 * @returns string
 */
export const formatMessage: IntlShape['formatMessage'] = (
  descriptor: MessageDescriptor,
  values: any,
) => {
  if (firstWaring) {
    warning(
      false,
      `Using this API will cause automatic refresh when switching languages, please use useIntl or injectIntl.

使用此 api 会造成切换语言的时候无法自动刷新，请使用 useIntl 或 injectIntl。

http://j.mp/37Fkd5Q
      `,
    );
    firstWaring = false;
  }
  return g_intl.formatMessage(descriptor, values);
};

/**
 * 获取语言列表
 * @returns string[]
 */
export const getAllLocales = () => Object.keys(localeInfo);
