/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'app.vap.meetingControl.tab1':"设备控制",
  'app.vap.meetingControl.tab2':"场景模式",
  'app.vap.temp.error1':"资源下无模版",
};
