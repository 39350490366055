export default {
  'myInfo.my.code': 'My QR code',
  'myInfo.face.lib': 'Face Library',
  'myInfo.edit.password': 'Change Password',
  'myInfo.edit.phone': 'Change Mobile Number',
  'myInfo.version.num': 'Version number',
  'myInfo.version.user': 'User Information',
  'myInfo.setting.title': 'Setting',
  'myInfo.setting.scan': 'Scan',
  'myInfo.setting.feedback': 'Feedback',
  'myInfo.setting.info': 'User Information',
  'myInfo.my.face': 'Face Recognition',
};
