export default {
  'register.numbers': '來訪人數',
  'register.host.number': '被訪人電話',
  'register.photo.error1': '該流覽器無法拍照，請使用Chrome流覽器',
  'register.photo.error2': '無拍照設備，無法拍照',
  'register.photo.make': '拍照',
  'register.photo.make.tip': '請拍照',
  'register.photo.again': '重新拍照',
  'register.index.step1': '填寫訪客資訊',
  'register.index.step2': '填寫身份證資訊',
  'register.index.step3': '拍照',
  'register.index.step4': '打印訪客貼',
  'register.form.scan': '掃描二維碼',
  'register.form.pen': '輸入邀請碼',
  'register.form.radio': '證件抵押',
  'register.form.radio.0': '未抵押',
  'register.form.radio.1': '已抵押',
  'register.form.cardNumber': '工牌編號',
  'register.form.cardNumber.tip': '請輸入正確的工牌編號',
  'register.modal.scan': '掃描二維碼',
  'register.modal.enter': '輸入邀請碼',
  'register.modal.input.tip': '請輸入六位純數字邀請碼',
  'register.modal.scan.tip': '請直接掃描短訊二維碼',
  'register.id.title.id': '* 可直接掃描身份證或手動輸入身份證號碼',
  'register.id.title.drive': '* 可直接掃描駕駛證或手動輸入駕駛證號碼',
  'register.id.title.officer': '* 可直接掃描軍官證或手動輸入軍官證號碼',
  'register.id.title.passport': '* 可直接掃描護照或手動輸入護照號碼',
  'register.id.title.HK/Macau': '* 可直接掃描港澳通行證或手動輸入港澳通行證號碼',
  'register.id.type.id': '身份證',
  'register.id.type.drive': '駕駛證',
  'register.id.type.officer': '軍官證',
  'register.id.type.passport': '護照',
  'register.id.type.HK/Macau': '港澳通行證',
  'register.id.text.id': '身份證號碼：',
  'register.id.text.drive': '駕駛證號碼：',
  'register.id.text.officer': '軍官證號碼：',
  'register.id.text.passport': '護照號碼：',
  'register.id.text.HK/Macau': '港澳通行證號碼：',
  'register.id.text.tip.id': '請輸入正確的身份證號碼',
  'register.id.text.tip.drive': '請輸入正確的駕駛證號碼',
  'register.id.text.tip.officer': '請輸入正確的軍官證號碼',
  'register.id.text.tip.passport': '請輸入正確的護照號碼',
  'register.id.text.tip.HK/Macau': '請輸入正確的港澳通行證號碼',
};
