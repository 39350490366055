import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取会议议程
 * @param meetingId
 * @returns {Object}
 */
const getAgendaList = ({ meetingId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-agenda/list/${meetingId}`, {
    method: 'GET',
  });
};

export default getAgendaList;
