export default {
  'subscribe.roomlist.select.singleChoice': 'Only single selection supported!',
  'room.selector.atLeastOne': 'Please select at least one room',
  'room.selector.webex.onlyOne': 'Please select one WebEx room at most',
  'meeting.calendar.room.tag.share': 'Shared',
  'meeting.tencent.people': ' peoples',
  'meeting.roomlist.delete.modal.title': 'The current conference room has added participants. What do you want to do with these participants after deleting the conference room?',
  'modal.roomlist.delete.tran': 'Move to',
  'modal.roomlist.delete': 'Delete directly',
};
