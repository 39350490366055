import { ACCESS_TOKEN, MENU_PATH } from '@/pages/Meeting/utils/constant';
import { isMobile } from '@/pages/Meeting/utils/common';

export default {
  namespace: 'meetingVskJump',
  state: {},
  effects: {},

  reducers: {},
  subscriptions: {
    setup({ history }) {
      return history.listen(({ pathname }) => {
        if (pathname.trim().length > 0) {
          // 通过token login登录
          if (pathname.indexOf(MENU_PATH.vskJump) !== -1) {
            if (isMobile()) {
              history.replace(MENU_PATH.home);
            } else {
              const url = window.location.href;
              window.location.href = `${
                url.replace('app', 'web').split('?')[0]
              }?token=${sessionStorage.getItem(ACCESS_TOKEN)}`;
            }
          }
        }
      });
    },
  },
};
