export default {
  'sws.booking.user.choice': 'Desk：',
  'sws.booking.already.occupied': 'This space is in used by another user',
  'sws.booking.already.occupied.tip': 'This space is in used , please select aonther time & space',
  'sws.booking.get.off.work':
    'The selected location has passed the off time, and reservation is not supported',
  'sws.booking.get.no.time': 'No working day time set',
  'sws.booking.no.worktime': 'Outside of working hours, we do not support use or booking service.',
  'sws.booking.time.no.worktime':
    'The selected time is in non-working hours {value1} and does not support use or reservation',
  'sws.booking.no.location': 'Please select location and time',
  'sws.booking.no.location.tip': 'Please select location first',
  'sws.booking.no.select.date': 'Please select',
  'sws.booking.no.select.startTime': 'Please select',
  'sws.booking.no.select.endTime': 'Please select',
  'sws.booking.limit.date': 'Up to {value1} days are available across days',
  'sws.booking.time.error':
    'The start time cannot be less than the current time, please select the start time again',
  'sws.booking.back': 'Back',
  'sws.booking.local': 'local area',
  'sws.booking.share': 'shared area',
  'sws.param.booking.time': 'Time',
  'sws.nodata.tip.title':
    'There are currently no seats available, Please contact the administrator to add a map. ',
  'sws.nodata.tip.desc': 'How to add a map, help link: https://cloud.virsical.cn/help',
  401112: 'Scheduled time exceed than Policy’setting',
  401113: 'Not in working hours, unable to make an appointment',
  401135: 'A workstation with reservation status already exists',
  401136: 'A station in use already exists',
  401134:
    'The number of reservations has exceeded the limit, please release the reserved or used workstations',
  401058: 'The number of appointment days exceeds the opening date limit',
  401059: 'The number of bookings exceeds {value1}',
  401060: 'The number of cross-day appointments exceeds the limit',
  'map.color.setting.material.status.1': 'Reservable',
  'map.color.setting.material.status.2': 'Occupied',
  'map.color.setting.material.status.3': 'Reservations are Not Supported',
  401145: 'The appointment start time cannot be greater than the end time',
  401146: 'The end time has passed the off-duty time, so we can’t make an appointment',
  401153:
    'You have a fixed workstation within the specified area. Sharing workstation reservation is not supported for now',
  401154: 'You are not allowed to book a desk across the day',
  401155: '{value1}The number of valid reservations exceeds the limit',
  401156: 'Valid bookings are longer than {value1} hours',
};
