import { Toast } from 'antd-mobile-v2';
import { formatMessage,history } from 'umi';
import { ROUTE_PATH } from '@/utils/constant';
import setImmediate from './service';

export default {
  namespace: 'lockerImmediate',
  state: {
    locker: {},
    // success or fail
    result: 'success',
    failMsg: '',
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *setImmediate({ payload }, { call, put }) {
      // console.log('setImmediate')
      const result = yield call(setImmediate, payload);
      Toast.hide();
      if (result && result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { result: 'success' } });
      } else if (result && result.code === 1) {
        const failMsg = formatMessage({ id: result.msg }, { ...result.data });
        yield put({ type: 'saveOrUpdateData', payload: { result: 'fail', failMsg } });
      }
      history.push({
        pathname: `${ROUTE_PATH}/locker/immediate/result`,
      });
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname, query }) => {
        if (pathname && pathname === '/locker/immediate/result') {
          const { result, failMsg } = query;
          if (result && failMsg) {
            dispatch({
              type: 'saveOrUpdateData',
              payload: {
                result,
                failMsg,
              },
            });
          }
        }
      });
    },
  },
};
