import * as searchService from './service';

export default {
  namespace: 'swsSearch',
  state: {
    isSearching: false,
    searchFinish: false,
    searchList: [],
    station: {},
    stationStart: {},
    stationEnd: {},
    searchStartList: [],
    searchEndList: [],
    user: {},
    // 固定工位列表
    fixList: [],
    // 移动工位列表
    mobileList: [],
    beforeRoute: '', // 上个路由
    noSearchReload: false // search页面数据是否不刷新
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询用户位置信息
    *getStationMapByUser({ payload, stationType }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(searchService.getSearchResult, param);
      if (result && result.code === 0) {
        if (!stationType) {
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              searchList: result.data,
            },
          });
        } else if (stationType === 'start') {
          // console.log('search start')
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              searchStartList: result.data,
            },
          });
        } else if (stationType === 'end') {
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              searchEndList: result.data,
            },
          });
        }
      }
    },
    // 查询用户默认地图
    *getUserMap(_, { call, put, select, take }) {
      const { curDefaultZone } = yield select((state) => state.user);
      // console.log('getUserMap', curDefaultZone);
      if (curDefaultZone) {
        const locationId = curDefaultZone;
        const result = yield call(searchService.getDefaultMap, locationId);
        if (result && result.code === 0 && result.data) {
          const { mapId } = result.data || {};
          if (mapId) {
            yield put({ type: 'swsCommon/getBaseMap', mapId });
            yield put({
              type: 'swsCommon/saveOrUpdateData',
              payload: { mapId, locationId },
            });
            yield put({
              type: 'swsCommon/getStationsByLocationId',
              payload: {
                locationId,
              },
            });
            yield take('swsCommon/getStationsByLocationId/@@end');
            yield put({ type: 'swsCommon/getEleIdsByMapId', mapId });
          }
          return result.data|| {}
        }
      }
    },
    // 查询用户
    *getUser({ info }, { call, put }) {
      // console.log('getUser payload', payload)
      const result = yield call(searchService.getUser, info);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            searchList: result.data,
            searchFinish: true,
          },
        });
      }
    },
    // 查询用户固定工位
    *getUserFixStation({ payload, callback = () => {} }, { call, put }) {
      const result = yield call(searchService.getUserStation, payload);
      if (result && result.code === 0 && result.data) {
        const { fixed = [], move = {} } = result.data;
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            fixList: fixed || [],
            mobileList: move || [],
          },
        });
        callback(fixed, move);
      }
    },
    // 查询我的预订
    // *getUserMobileStation({ search = false, userId }, { select, call, put }) {
    //   const { myBook, mobileList = [] } = yield select((state) => state.swsSearch);
    //   const { pages, current } = myBook || {};
    //   // 1， 有数据，且是最后一页不在查询
    //   // 2， 无数据 不查询
    //   // 3, search 参数是 true ，则查询
    //   const notSearch = (mobileList.length > 0 && current === pages) || pages === 0;
    //   if (!search && notSearch) {
    //     return;
    //   }
    //   let curr = pages > current ? current + 1 : current;
    //   // 重新搜索，从第一页开始
    //   if (search) {
    //     curr = 1;
    //   }
    //   const payload = {
    //     userId,
    //     current: curr,
    //     size: 5,
    //   };
    //   const result = yield call(searchService.getMybook, payload);
    //   if (result && result.code === 0) {
    //     const { data } = result;
    //     mobileList.push(...(data?.data || []));
    //     let dataArr = mobileList;
    //     // 重新搜索，重置数据
    //     if (search) {
    //       dataArr = data?.data || [];
    //     }
    //     yield put({
    //       type: 'saveOrUpdateData',
    //       payload: {
    //         myBook: data,
    //         mobileList: dataArr,
    //       },
    //     });
    //   }
    // },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        // 监听路由变化，并发送类别查询请求
        // console.log('setup pathname', pathname,);
        if (pathname && !pathname.includes('/workspace/station/search')) {
          dispatch({
            type: 'saveOrUpdateData',
            payload: {
              beforeRoute: pathname,
            },
          });
        }
      });
    },
  },
};
