export default {
  'register.numbers': 'Numbers',
  'register.host.number': 'Receptionist Number',
  'register.photo.error1': 'The browser is not photographed, please use the Chrome browser',
  'register.photo.error2': 'No photo equipment',
  'register.photo.make': 'Shutter',
  'register.photo.make.tip': 'Please take a photo',
  'register.photo.again': 'Again',
  'register.index.step1': 'Fill in the visitor info',
  'register.index.step2': 'Fill in the ID Card',
  'register.index.step3': 'Take a photo',
  'register.index.step4': 'Print visitor stickers',
  'register.modal.scan': 'Scan QR code',
  'register.modal.enter': 'Enter invitation code',
  'register.form.radio': 'Document mortgage',
  'register.form.radio.0': 'Unsecured',
  'register.form.radio.1': 'Mortgage',
  'register.form.cardNumber': 'Work card',
  'register.form.cardNumber.tip': 'Please enter the correct badge number',
  'register.modal.input.tip': 'Please enter a six-digit pure number invitation code',
  'register.modal.scan.tip': 'Please scan the SMS QR code directly',
  'register.id.title': '* You can scan your ID Card directly or enter your ID number manually',
  'register.id.type.1': 'ID Card',
  'register.id.type.2': 'Driver license',
  'register.id.type.3': 'Military officer card',
  'register.id.type.4': 'Passport',
  'register.id.type.5': 'ValidExit-EntryPermit(EEP)toHK/Macau',
  'register.id.title.id': '* Can directly scan ID Card or manually enter ID number',
  'register.id.title.drive':
    "* Can directly scan the driver's license or manually enter the driver's license number",
  'register.id.title.officer':
    "* Can directly scan the military officer's card or manually enter the officer's card number",
  'register.id.title.passport':
    '* You can scan your passport directly or enter your passport number manually',
  'register.id.title.HK/Macau':
    '* You can scan ValidExit-EntryPermit(EEP)toHK/Macau or enter ValidExit-EntryPermit(EEP)toHK/Macau Number manually.',
  'register.id.type.id': 'ID Card',
  'register.id.type.drive': 'Driver license',
  'register.id.type.officer': 'Military officer card',
  'register.id.type.passport': 'Passport',
  'register.id.type.HK/Macau': 'ValidExit-EntryPermit(EEP)toHK/Macau',
  'register.id.text.id': 'ID Card：',
  'register.id.text.drive': 'Driver license：',
  'register.id.text.officer': 'Military officer card：',
  'register.id.text.passport': 'Passport：',
  'register.id.text.HK/Macau': 'ValidExit-EntryPermit(EEP)toHK/Macau：',
  'register.id.text.tip.id': 'Please enter a valid ID number',
  'register.id.text.tip.drive': "Please enter the correct driver's license number",
  'register.id.text.tip.officer': 'Please enter the correct Military officer card number',
  'register.id.text.tip.passport': 'Please enter the correct passport number',
  'register.id.text.tip.HK/Macau':
    'Please enter the correct ValidExit-EntryPermit(EEP)toHK/Macau number',
};
