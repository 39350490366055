import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export function getRoomDetailFun({ roomId }) {
  return request(`/${RESTFUL_PATH.meeting}/room-info/${roomId}`, {
    method: 'GET',
  });
}

// 获取设备控制模型
export async function getTemp(relationId) {
  return request(
    `/${RESTFUL_PATH.vap}/controlScreenTemplate/selectTemplateUnderResource?relationType=1&relationId=${relationId}`,
    {
      method: 'get',
    },
  );
}

export default getRoomDetailFun;
