export default {
  'service.err.200': 'The server successfully returned the requested data. ',
  'service.err.201': 'New or modified data was successful. ',
  'service.err.202': 'A request has been queued in the background (asynchronous task). ',
  'service.err.204': 'Successfully deleted data. ',
  // 'service.err.400':
  //   'There was an error in the request, and the server did not create or modify data. ',
  // 'service.err.401':
  //   'The user session has expired because you have not operated for a long time or logged out elsewhere, please log in again',
  // 'service.err.403': 'The user is authorized, but access is forbidden. ',
  // 'service.err.404':
  //   'The request was made for a record that does not exist, and the server did nothing. ',
  // 'service.err.406': 'The requested format is not available. ',
  // 'service.err.410':
  //   'The requested resource was permanently deleted and will no longer be available. ',
  // 'service.err.422': 'When creating an object, a validation error occurred. ',
  // 'service.err.426': 'app.login.message-invalid-credential',
  'service.err.400': 'Error code 400, please contact administrator to resolve the problem.',
  'service.err.401':
    'The login information has expired or the permissions have changed, please login again.',
  'service.err.403': 'Error code 403, please contact administrator to resolve the problem.',
  'service.err.404': 'Error code 404, please contact administrator to resolve.',
  'service.err.406': 'Error code 406, please contact administrator to resolve the problem.',
  'service.err.410': 'Error code 410, please contact administrator to resolve the problem.',
  'service.err.422': 'Error code 422, please contact administrator to resolve.',
  'service.err.426': 'Error code 426, please contact administrator to resolve.',

  'service.err.500': 'A server error occurred, please check the server. ',
  'service.err.502': 'Gateway error. ',
  'service.err.503':
    'The service is unavailable and the server is temporarily overloaded or maintained. ',
  'service.err.504': 'The gateway timed out. ',
  100000: 'System exception',
  101000: 'Illegal operation',
  101001: 'The parameter is incorrect',
  101002: 'Exit failed, token is empty',
  101003: 'Exit failed, token is invalid',
  101004: 'Operation failed, token is empty',
  101005: 'Operation failed, token format is invalid',
  101006: 'Operation failed, token is invalid',
  101007: 'The operation failed, the refreshToken is empty',
  101008: 'Operation failed, refreshToken is invalid',
  101009: 'Password decryption failed',
  101010: 'The account format is incorrect',
  101011: 'Failed to get domain information through refreshToken',
  101012: 'Does not support authentication mode',
  101013: 'Incorrect account or password, please re-enter',
  101014: 'The client information in the request header is empty',
  101015: 'Failed to query account',
  101020: 'Failed to upload license file',
  101021: 'Upload license file is abnormal',
  101022: 'license does not match tenant domain',
  101023: 'license device code verification failed',
  101024: 'license validity check failed',
  101025: 'License does not exist',
  101026: 'license function is not enabled',
  101027: 'license file name is incorrect',
  101028: 'The number of users has exceeded the limit',
  101029: 'The number of tenants exceeds the limit',
  101030: 'The verification code cannot be empty',
  101031: 'The verification code is not legal',
  1010312: 'The verification code is incorrect',
  101032: 'Verification code does not exist',
  101040: 'Signature or timestamp parameter does not exist',
  101041: 'The timestamp parameter is not formatted correctly',
  101042: 'Request has expired',
  101043: 'Signature is invalid',
  101044: 'The current user has no role, please contact the administrator',
  101045: 'The system default domain does not exist',
  101046: 'Mobile number authentication is not turned on',
  101047: 'Mailbox authentication is not turned on',
  102000: 'Illegal operation',
  102001: 'The parameter is incorrect',
  102002: 'Add department corresponds to tenant does not exist',
  102003: 'There are sub-departments under the current department, please transfer first',
  102004: 'Save department failed',
  102005: 'The menu contains subordinates cannot be deleted',
  102006: 'The user exists under the current role, please transfer the user first',
  102007: 'Only platform administrators can modify generic roles',
  102008: 'Tenant does not exist',
  102009: "Can't delete yourself",
  102010: 'Add user corresponding tenant does not exist',
  102011: 'The mailbox already exists',
  102012: 'The new password is inconsistent with the confirmation password',
  102013: 'Information does not exist',
  102014: 'The original password is wrong',
  102015: 'The department does not exist',
  102016: 'This WeChat has been bundled',
  102017: 'This nail has been bound',
  102018: 'Does not support binding methods other than WeChat and nails',
  102019: 'This user is not bound to WeChat account',
  102020: 'This user is not tied to the nail account',
  102021: 'Weisi guest token is invalid',
  102022: 'Free authentication, does not support current type',
  102023: 'Free authentication, query multiple users',
  102024: 'Free authentication, no user is queried',
  102025: 'The phone number already exists',
  102026: 'User does not exist',
  102027: 'The platform administrator role is not allowed to delete',
  102030: 'This role ID already exists',
  102031: 'This role name already exists',
  102032: 'The role does not exist',
  102033: 'There are users in the current department, please transfer first',
  102040: 'This tenant domain already exists',
  102050: 'Space level exceeds limit',
  102051: 'Please move the subspace in the current space,',
  102052: 'Save space failed',
  102053: 'This space is not bound to holidays',
  102054: 'This space does not exist',
  102055: 'The department administrator has not set',
  102056: 'The department administrator does not exist',
  102057: 'The current tenant is deactivated',
  102058: 'System exception',
  102059: 'The name of the department under the same department cannot be repeated',
  102060: 'This account already exists',
  102061: 'The tenant name cannot be empty',
  102062: 'The tenant name is longer than 50',
  102063: 'The English name of the tenant is more than 50',
  102064: 'The tenant domain cannot be empty',
  102065: 'The tenant domain is longer than 50',
  102066: 'The tenant status cannot be empty',
  102067: 'The tenant status parameter is invalid',
  102068: 'The department name cannot be empty',
  102069: 'The department name is longer than 50',
  102070: 'Parent department id cannot be empty',
  102071: 'Parent department id is invalid',
  102072: 'The account cannot be empty',
  102073: 'The account length is more than 50',
  102074: 'The phone number cannot be empty',
  102075: 'The department cannot be empty',
  102076: 'Name cannot be empty',
  102077: "Gender can't be empty",
  102078: 'Mailbox cannot be empty',
  102079: "The character can't be empty",
  102080: 'Menu id cannot be empty',
  102081: 'The menu id is invalid',
  102082: 'The menu name cannot be empty',
  102083: 'Parent menu id cannot be empty',
  102084: 'The menu type cannot be empty',
  102085: 'The role name cannot be empty',
  102086: 'The character code cannot be empty',
  102087: 'Tenant id cannot be empty',
  102088: 'The tenant id parameter is invalid',
  102089: 'The attribute id cannot be empty',
  102090: 'The attribute value cannot be empty',
  102091: 'Tenant initializes save tenant failed',
  102092: 'Tenant initialization save department failed',
  102093: 'Tenant initialization failed to save department relationship',
  102094: 'Tenant initialized save user failed',
  102095: 'Tenant initialization failed to save user role relationship',
  102096: 'User information is saved abnormally',
  102097: 'User information update is abnormal',
  102098: 'User role relationship is saved abnormally',
  102099: 'Bulk save user exception',
  102100: 'Bulk save user role exception',
  102101: 'The attribute code does not exist',
  102102: 'The space name cannot be repeated in the same space',
  102103: 'Holiday does not exist',
  102104: 'Menu permission identifier already exists',
  102105: "The parent menu's permission ID is empty to add a submenu",
  102106: 'The mailbox account does not exist',
  102107: 'The link has expired',
  102108: 'Set password failed',
  102109: 'The verification code is invalid',
  102110: 'The new password and the confirmation password are inconsistent',
  102111: 'Query failed',
  102112: 'Query user failed',
  102113: 'Query tenant failed',
  102114: 'Minimum space for allocable resources',
  102115: 'Query cloud service menu failed',
  102116: 'The password contains weak passwords and does not meet the strength requirements',
  102117: 'The node is disabled, cannot be edited',
  102118: 'At least one administrator user needs to exist in the system',
  102119: 'Menu ID already exists',
  102120: 'Parent node is disabled, cannot add child node',
  102121: 'The holiday has been bound to the space tree, can not be deleted',
  102122: 'Cannot delete system administrator',
  102123: 'The ID list cannot be empty',
  102124: 'New department ID cannot be empty',
  102125: 'The new password cannot be the same as the original password',
  101048: 'Account failed multiple authentications, locked for {lockTime} minutes',
  102126: 'Can only delete locally added users',
  102127: 'Can only delete locally added departments',
  102128: 'Tenant initial password type cannot be empty',
  102130: 'The route name cannot be empty',
  102131: 'Route ID cannot be empty',
  102132: 'Assertion cannot be null',
  102133: 'Filter cannot be empty',
  102134: 'URL cannot be empty',
  102135: 'Assertion needs to be in json format',
  102136: 'Filter must be in json format',
  102137: 'Route id already exists',
  102138: 'Assertion is malformed',
  102139: 'The filter is malformed',
  102140: 'Mobile area code already exists',
  102141: 'Mobile area code name already exists',

  // access control
  601001: 'Failed to get access list',
  601002: 'Access control name cannot be empty',
  601003: 'The access control has been bound to a group, and the deletion failed',
  601004: 'Failed to delete the access control',
  601005: 'Please configure the access control first',
  601006: 'Simulation call failed',
  601007: 'Failed to get access control group list',
  601008: 'Access control group name cannot be empty',
  601009: 'Group number already exists',
  601010: 'Failed to delete access control group',
  601011: 'Signature authentication failed',
  601014: 'Duplicate controller serial number',
  601015: 'The controller and logic gate number cannot be repeatedly bound',
  601016: 'IDCardNo duplicate configuration',
  601017: 'This access control product does not support remote door opening',
  101049: 'End time is earlier than start time',
  101050: 'The end time is earlier than the current time',
  101051: 'The start time has not arrived',
  101052: 'Account disabled',
  101053: 'Account password not activated',
  603001: 'The QR code does not exist, please check it',
  603002: 'The user has used the QR code the most times',
  603003:
    "The total validity period of the user's QR code has expired, and the user's QR code record has been cleared",
  601012: 'Failed',
  601013: 'Successful',
  200005: 'The conference product has expired',

  102164:
    'The phone number is duplicated, the verification code failed to be sent, please contact the administrator to handle it',
  102165: 'Mobile phone number does not exist',
  102166: 'Verification code is wrong or expired',
  102167: 'The verification code has been sent, please do not operate frequently',
  102175: 'User has been bound',
  102176: 'Teams tenants are inconsistent, please re-enter',
  102177: 'User binding failed, please rebind or use another account',

  1001023: 'The mailbox has been used, please change the mailbox',
  1001027: 'Invalid verification code',
  1001028: 'Verification code error',
  1001031: 'Parameter error',
  1001074: 'Tenant name already exists',
  1001083: 'The phone number has been registered',
  1001084: 'User does not exist',
  1001089: 'E-mail can not be empty',
  1001091: 'Tenant does not exist',
  1001092: 'The registration information does not exist, please re-authorize',
  1001093: 'Secret-free authorization failed',
  1001094: 'The company has been registered',

  1081008: 'Package does not exist',
  1081009: 'Package has expired',

  106110: 'User binding failed',
  106000: 'System abnormal',
  106520: 'Mobile phone number has been bound to other tenants',
  106521: 'The mailbox is bound to another tenant',
  106522: 'The tenants bound to Feishu are inconsistent',
  106523: 'Failed to obtain Feishu corporate information',
  106524: 'Third-party user check failed',
  106525: 'Failed to obtain Feishu user information',
  106526: 'Password-free authentication failed',
  106527: 'Failed to get Feishu application configuration',
  106528: 'Feishu registration information is invalid',

  106507:
    'The request to the enterprise WeChat interface timed out, please log in or try again later.',
  401150: 'The appointment time is not within the setting range',

  401151: 'The maximum booking duration should not exceed {value1} minutes',
  401152: 'The minimum booking length should be {value1} minutes',
  401002: 'station information does not exist',
  401092: 'Station unbound map',
  401093: 'Station locked',
  401095: 'Station locked',
  102151: 'The new password is the same as the historical password',

  401333: 'User department information does not exist',
  401097: 'The station is not a fixed-station one-person use type',
  401098: 'Station {value1} has not been assigned a department',

  401154: 'You do not support cross-day reservations',
  401158:
    'The same station is not supported for consecutive working days, please select a new station',

  101054: 'First login, please change your password on the PC.',
  101055: 'Password expired, please change your password',
  1001025: 'License has expired',
  1001026: 'License number has reached the upper limit',
  1001147: 'The license limit has been exceeded, please open again or update the license',

  'FFA.101083': 'Please log in to bind the authenticator application through PC first.',
  102150: 'The password contains weak passwords and does not meet the strength requirements',

  401507:
    "There are words in the repair notes that do not comply with the company's regulations. Please re-examine and modify them.",
  401508:
    "There is a vocabulary that does not comply with the company's regulations in the remarks for applying for a workstation. Please re-examine and modify it.",
  401509:
    "There is a vocabulary in the borrowed workstation that does not comply with the company's regulations. Please review and modify it again.",
  401510:
    "There is a vocabulary that does not comply with the company's regulations in the kick seat application. Please re-examine and modify it.",
  401511:
    "In the reply to the kickstand, there is a vocabulary that does not comply with the company's regulations. Please review and modify it again.",
};
