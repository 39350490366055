import { Toast } from 'antd-mobile-v2';
import _ from 'lodash';
import { matchPath } from 'umi';
import { formatMessage } from 'umi';
import { MENU_PATH } from '@/pages/Meeting/utils/constant';
import {
  getMeetingDetail,
  cancelMeetingSvc,
  removeMeetingSvc,
  getAllSvcInfo,
  saveServiceData,
  getMeetingOther,
} from './service';

export default {
  namespace: 'svcFeeDetail',
  state: {
    meetingId: 0,
    currentRoomId: 0,
    currentRoomInfo: '',
    meetingDetail: {},
    meetingSvcMap: {}, // 接口返回的会议服务数据
    roomServiceMap: {}, // 新增/编辑服务的服务数据
    allSvcInfo: [], // 所有服务
    meetingSvcRooms: [], // 服务代付人
    tempRoomServiceMap: {}, // 更改代付人但未选择服务，点击返回（新增服务和服务页面代付人不一致）
    isFromPayer:false,  // 是否从选人界面返回
    executeNum :1,  //
    isFromSvcFreeDetail:false,  //
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
    resetState(){
    return {
    meetingId: 0,
    currentRoomId: 0,
    currentRoomInfo: '',
    meetingDetail: {},
    meetingSvcMap: {}, // 接口返回的会议服务数据
    roomServiceMap: {}, // 新增/编辑服务的服务数据
    allSvcInfo: [], // 所有服务
    meetingSvcRooms: [], // 服务代付人
    tempRoomServiceMap: {}, // 更改代付人但未选择服务，点击返回（新增服务和服务页面代付人不一致）
    isFromPayer:false,  // 是否从选人界面返回
    executeNum :1,  //
    isFromSvcFreeDetail:false,  //
      }
    }
  },
  effects: {
    *reload({ meetingId ,fromPage}, { select, call, put }) {
      const { roomInfo = [] } = yield select((state) => state.subscribe);
      const { executeNum} = yield select((state) => state.svcFeeDetail);

      if(fromPage==='booking'&&Number(meetingId)>0){
        yield put({ type: 'update', payload: { executeNum:executeNum+1 } });
        if(executeNum>=2){
          return
        }
      }
      if (Number(meetingId)) {
        const otherData = yield call(getMeetingOther, meetingId);
        const meetingData = yield call(getMeetingDetail, meetingId);
        if (meetingData.code === 0) {
        const { roomInfo :roomInfoEdit } = meetingData.data;
          // 更新一下 roomInfo
          console.log(meetingData,"====meetingData==")
          yield put({
            type: 'update',
            payload: {
              //  编辑会议室 更改会议室 需要更新数据
              meetingDetail: {
                ...meetingData.data,
                roomInfo:fromPage==='booking'?roomInfo:roomInfoEdit
              },
            },
          });
          const meetingSvcMap = {};
          let meetingSvcRooms = [];
          if (otherData.code === 0) {
            const { data = {} } = otherData;
            const { meetingSvc = [] } = data;
            meetingSvcRooms = data.meetingSvcRooms ? data.meetingSvcRooms : []
            if (meetingSvc && meetingSvc.length > 0) {
              meetingSvc.forEach((item) => {
                if (!meetingSvcMap[item.roomId]) {
                  meetingSvcMap[item.roomId] = [];
                }
                meetingSvcMap[item.roomId].push(item);
              });
            }
          }
          yield put({ type: 'update', payload: { meetingSvcMap, meetingSvcRooms } });
        }else{
          Toast.success(formatMessage({ id: meetingData.msg }));
        }
      } else {
      
        yield put({
          type: 'update',
          payload: {
            meetingDetail: {
              roomInfo,
              meetingRooms: roomInfo
            },
            meetingSvcMap: {},
          },
        });
      }


    },
    *cancelOrRemove({ payload }, { call, put }) {
      const { type, processsId, meetingId } = payload;
      let result = {};
      if (type === 0) {
        result = yield call(cancelMeetingSvc, processsId);
      } else {
        result = yield call(removeMeetingSvc, processsId);
      }
      if (result.code === 0) {
        const otherData = yield call(getMeetingOther, meetingId);
        const meetingSvcMap = {};
        let meetingSvcRooms = []
        if (otherData.code === 0) {
          const { data = {} } = otherData;
          const { meetingSvc = [], } = data;
          meetingSvcRooms = data.meetingSvcRooms ? data.meetingSvcRooms : []
          if (meetingSvc && meetingSvc.length > 0) {
            meetingSvc.forEach((item) => {
              if (!meetingSvcMap[item.roomId]) {
                meetingSvcMap[item.roomId] = [];
              }
              meetingSvcMap[item.roomId].push(item);
            });
          }
        }
        yield put({ type: 'update', payload: { meetingSvcMap, meetingSvcRooms } });
        Toast.success(formatMessage({ id: '601013' }));
      }
    },
    *saveMeetingService({ payload }, { call, put }) {
      const { meetingServiceData } = payload;
      const { meetingId } = meetingServiceData[0];
      const { code, msg } = yield call(saveServiceData, meetingServiceData);
      if (code === 0) {
        const otherData = yield call(getMeetingOther, meetingId);
        const meetingSvcMap = {};
        let meetingSvcRooms = []
        if (otherData.code === 0) {
          const { data = {} } = otherData;
          const { meetingSvc = [] } = data;
          meetingSvcRooms = data.meetingSvcRooms ? data.meetingSvcRooms : []
          if (meetingSvc && meetingSvc.length > 0) {
            meetingSvc.forEach((item) => {
              if (!meetingSvcMap[item.roomId]) {
                meetingSvcMap[item.roomId] = [];
              }
              meetingSvcMap[item.roomId].push(item);
            });
          }
        }
        // yield put({ type: 'update', payload: { roomServiceMap: {} } });
        yield put({ type: 'update', payload: { meetingSvcMap, meetingSvcRooms, roomServiceMap: {} } });
        Toast.success(formatMessage({ id: '601013' }));
      }else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *getAllSvcInfo({ payload:{tenantId}  }, { call, put }) {
      const { data } = yield call(getAllSvcInfo, { tenantId });
      yield put({ type: 'update', payload: { allSvcInfo: data } });
    },
    *updateRoomPayer({ payload }, { select, put }) {
      const { roomId, payer, payerName } = payload;
      const { roomServiceMap } = yield select((state) => state.svcFeeDetail);
      const { roomPayerMap } = yield select((state) => state.subscribe);
      const newRoomPayerMap=_.clone(roomPayerMap)
      if(Object.keys(roomPayerMap).includes(String(roomId))){
        
        newRoomPayerMap[roomId].svcPayer=payer
        newRoomPayerMap[roomId].svcPayerName=payerName
      }else{
        newRoomPayerMap[roomId]={
          roomId: Number(roomId),
          roomPayer: '',
          svcPayer: payer,
          svcPayerName:payerName
        };
      }
      yield put({
        type:'subscribe/update',
        payload:{
          roomPayerMap:newRoomPayerMap
        }
      })

      const currentRoomServiceMap = roomServiceMap[roomId] || {};
      const tempServiceMap = { ...currentRoomServiceMap, ...{ payer, payerName } };
      roomServiceMap[Number(roomId)] = tempServiceMap;

      yield put({ type: 'update', payload: {
        roomServiceMap: _.cloneDeep(roomServiceMap),
        tempRoomServiceMap: currentRoomServiceMap ,
        isFromPayer:true
      }
      });
      
    },

  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.svcFeeDetail)) {
          const { query } = history.location;
          const { meetingId ,fromPage} = query || {};
          dispatch({ type: 'update', payload: { meetingId } });
          dispatch({ type: 'reload', meetingId ,fromPage});
        }
      });
    },
  },
};
