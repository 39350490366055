import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { queryCurrentUserInfo, getHasPurviewDoorList, remoteOpenDoor } from './service';

export default {
  namespace: 'myAccess',
  state: {
    currentUser: {},
    hasPurviewDoorList: [],
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetchCurrentUserInfo(_, { call, put }) {
      const response = yield call(queryCurrentUserInfo);
      if (response.code === 0) {
        const { sysUser } = response.data;
        yield put({
          type: 'save',
          payload: {
            currentUser: sysUser,
          },
        });
      }
    },
    *getHasPurviewDoorList(_, { call, put }) {
      const response = yield call(getHasPurviewDoorList);
      if (response.code === 0) {
        yield put({
          type: 'save',
          payload: {
            hasPurviewDoorList: response.data || [],
          },
        });
      }
    },
    *remoteOpenDoor({ params }, { call }) {
      const result = yield call(remoteOpenDoor, { ...params });
      if (result.code === 0) {
        const successText = formatMessage({ id: 'access.open.door.success' });
        Toast.success(successText);
      } else {
        const errorText = formatMessage({ id: result.msg });
        Toast.fail(errorText);
      }
    },
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        if (pathname === '/accessApp/myAccess') {
          dispatch({ type: 'getHasPurviewDoorList' });
        }
      });
    },
  },
};
