export default {
  checkIn_index_tip1: '输入访客登记信息，完成访客签到',
  checkIn_index_tip2: '受邀请的访客，根据收到的邀请码完成签到',
  checkIn_invite_tip1: '请输入完整的邀请码',
  checkIn_invite_tip2: '请输入纯数字的邀请码',
  checkIn_invite_tip3: '请输入邀请码',
  checkIn_result: '操作结果',
  checkIn_host_tip1: '请确认被访人电话',
  checkIn_NDA_tip1: '请勾选同意保密协议',
  checkIn_people_tip1: '请输入访问人数',
  checkIn_people_tip2: '访问人数为1到10人',
  checkIn_people: '访问人数',
  checkIn_host_phone: '拜访对象电话',
  checkIn_no_host_phone: '无拜访人电话',
  checkIn_NDA_tip2: '已阅读并同意',
  checkIn_NDA: '《保密协议》',
  checkIn_login_error: '请联系管理员检查默认配置',
  checkIn_success: '访客登记成功',
  checkIn_fail: '访客登记失败',
  checkIn_sign_fail: '邀请签到失败',
  checkIn_sign_success: '邀请签到成功',
  checkIn_photo_success: '拍照成功',
  checkIn_photo_fail: '拍照失败',
};
