export default {
  group_new: '新建邀请',
  group_subject: '主题',
  group_subject_tip: '请输入主题',
  group_host: '接待人员',
  group_host_tip: '请输入并选择正确的接待人员',
  group_photo: '头像',
  group_loginUrl_: '团体邀请登记表地址：',
  group_subject_: '主题：',
  group_host_: '接待人员：',
  group_address_: '接待地址：',
  group_address: '接待地址',
  group_date_: '访问时间：',
  group_date: '访问时间',
  group_detail: '查看',
  group_invite: '新建邀请',
  group_mail: '邮箱',
  group_mail_tip: '请输入邮箱',
  group_success: '登记成功',
  group_formTitle: '团体邀请登记表',
  group_carNumber: '车牌号',
  group_carNumber_tip: '请输入车牌号',
};
