/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.myFaultRepair.time.current': '今天',
  'access.myFaultRepair.state.Processed': '已處理',
  'access.myFaultRepair.state.confirmed': '已確認',
  'access.myFaultRepair.state.Unprocessed': '未處理',
  'access.myFaultRepair.access.name': '門禁名稱:',
  'access.myFaultRepair.access.repairer': '報修人:',
  'access.myFaultRepair.access.repair.reason': '報修理由:',
  'access.myFaultRepair.access.repair.time': '報修時間:',
  'access.myFaultRepair.state': '報修狀態:',
  'access.myFaultRepair.Opinion': '處理意見:',
  'access.myFaultRepair.noData': '無數據',
  'access.myFaultRepair.reason': '報修理由',
  'access.myFaultRepair.time': '報修時間',
};
