/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 查询报修列表
 * @param payload 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getRepairList(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/repairs/page?domainId=${payload.domainId}&size=${
    payload.size || 5
  }&current=${payload.current || 1}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询报修类型
 * @param domainId 租户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getFacilityList(domainId) {
  const url = `/${RESTFUL_PATH.sws}/api/facility/weblist?domainId=${domainId}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询工位编号
 * @param payload 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getStationNums(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/repairs/stationRepair?domainId=${payload.domainId}&userId=${
    payload.userId
  }&stationNum=${payload.stationNum || ''}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 上传图片
 * @param payload 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function uploadPicture(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/imgupload`;
  return request(
    url,
    {
      method: 'POST',
      body: payload,
      headers: {},
    },
    true,
  );
}

/**
 * 保存报修
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function confirmRepair(param) {
  return request(`/${RESTFUL_PATH.sws}/api/repairs/saveRepairs`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

/**
 * 取消报修
 * @param id 报修记录Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function cancelRepair(id) {
  return request(`/${RESTFUL_PATH.sws}/api/repairs/delete/${id}`, {
    method: 'POST',
  });
}

/**
 * 根据工位报修扫描获取工位列表
 * @param payload
 * @returns {{data, headers}|{data}|*}
 */
export const getRepairStation = (payload) => {
  return request(
    `/${RESTFUL_PATH.sws}/webStation/encoderStation?stationId=${payload.stationId}&scanType=${payload.scanType}`,
    { method: 'GET' },
  );
};
/**
 * 工位id查询工位信息
 * @param stationId 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function searchScanStationInfo(stationId) {
  const url = `/${RESTFUL_PATH.sws}/api/station-check/queryById?id=${stationId}`;
  return request(url, {
    method: 'GET',
  });
}
