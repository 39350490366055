/**
 * Created : vincent
 * Date : 2019-03-20  19:22
 * Email : wangxiao@wafersystems.com
 */
import request, { requestNoAuthorization } from '@/utils/request';
import { ACCESS_TOKEN, RESTFUL_PATH } from '@/utils/constant';


/* 上传头像 */
export async function updateLoadImage({ formData }) {
  return requestNoAuthorization(`/${RESTFUL_PATH.upms}/sss/upload`, {
    method: 'POST',
    body: formData.formData,
    headers: {
      Authorization: `bearer ${sessionStorage.getItem(ACCESS_TOKEN)}`,
    },
  });
}

// 新增意见反馈
export async function addFeedback({ payload }) {
  return request(`/${RESTFUL_PATH.admin}/sys-feedback/add`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

// 获取意见反馈列表
export async function getFeedbackList({ current, size }) {
  const url = `/${RESTFUL_PATH.admin}/sys-feedback/page?current=${current}&size=${size}`;
  return request(url);
}
