/**
 * Created by XUQIANG on 2017/03/14.
 */
import index from './zh_CN/index';
import app from './zh_CN/app';
import reservation from './zh_CN/reservation';
import visit from './zh_CN/visit';
import admin from './zh_CN/admin';
import register from './zh_CN/register';
import report from './zh_CN/report';
import group from './zh_CN/group';
import meeting from './zh_CN/meeting';
import machine from './zh_CN/machine';
import selfService from './zh_CN/selfService';
import checkIn from './zh_CN/checkIn';
import blacklist from './zh_CN/blacklist';
import custom from './zh_CN/custom';
import status from './zh_CN/status';
import InvitationCode from '../pages/InvitationCode/locales/zh-CN';

export default {
  locale: 'zh_CN',
  pluralRuleFunction: () => {
    return 'other';
  },
  ...index,
  ...app,
  ...reservation,
  ...visit,
  ...admin,
  ...register,
  ...report,
  ...meeting,
  ...group,
  ...machine,
  ...selfService,
  ...checkIn,
  ...blacklist,
  ...custom,
  ...status,
  ...InvitationCode,
};
