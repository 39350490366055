import { Toast } from 'antd-mobile-v2';
import _ from 'lodash';
import { matchPath } from 'umi';
import { formatMessage } from 'umi';
import { MENU_PATH } from '@/pages/Meeting/utils/constant';
import * as zoneService from '@/pages/Meeting/services/zoneService';
import { getMeetingDetail, getMeetingOther } from './service';
import * as myMeetingService from '@/pages/Meeting/pages/MyMeeting/service';

export default {
  namespace: 'svcApprovalDetail',
  state: {
    meetingId: 0,
    currentRoomId: 0,
    currentRoomInfo: '',
    meetingDetail: {},
    meetingSvcMap: {}, // 接口返回的会议服务数据
    roomServiceMap: {}, // 新增/编辑服务的服务数据
    allSvcInfo: [], // 所有服务
    meetingSvcRooms: [], // 服务代付人
    tempRoomServiceMap: {}, // 更改代付人但未选择服务，点击返回（新增服务和服务页面代付人不一致）
    isFromPayer: false, // 是否从选人界面返回
    executeNum: 1, //
    isFromSvcFreeDetail: false, //
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
    resetState() {
      return {
        meetingId: 0,
        currentRoomId: 0,
        currentRoomInfo: '',
        meetingDetail: {},
        meetingSvcMap: {}, // 接口返回的会议服务数据
        roomServiceMap: {}, // 新增/编辑服务的服务数据
        allSvcInfo: [], // 所有服务
        meetingSvcRooms: [], // 服务代付人
        tempRoomServiceMap: {}, // 更改代付人但未选择服务，点击返回（新增服务和服务页面代付人不一致）
        isFromPayer: false, // 是否从选人界面返回
        executeNum: 1, //
        isFromSvcFreeDetail: false, //
      };
    },
  },
  effects: {
    *reload({ meetingId, fromPage }, { select, call, put }) {
      const { roomInfo = [] } = yield select((state) => state.subscribe);
      const { tenantId } = yield select((state) => state.user.currentUser);
      const { data: roomInfoData = [] } = yield call(zoneService.getAllRooms, { tenantId });
      const { executeNum } = yield select((state) => state.svcApprovalDetail);

      if (fromPage === 'booking' && Number(meetingId) > 0) {
        yield put({ type: 'update', payload: { executeNum: executeNum + 1 } });
        if (executeNum >= 2) {
          return;
        }
      }
      if (Number(meetingId)) {
        const otherData = yield call(getMeetingOther, meetingId);
        const meetingData = yield call(getMeetingDetail, meetingId);
        if (meetingData.code === 0) {
          const { roomInfo: roomInfoEdit } = meetingData.data;
          // 更新一下 roomInfo
          // console.log(meetingData, '====meetingData==');
          yield put({
            type: 'update',
            payload: {
              //  编辑会议室 更改会议室 需要更新数据
              meetingDetail: {
                ...meetingData.data,
                // 应该获取所有的会议室，不能只拿当前会议的会议室，因为服务审批的数据，可能存在被更换了会议室的数据
                // 如果直接拿会议的会议室，那因更换会议室而被已取消的数据无法正常显示(匹配不到会议室了)
                roomInfo: roomInfoData,
                // roomInfo: fromPage === 'booking' ? roomInfo : roomInfoEdit,
              },
            },
          });
          const meetingSvcMap = {};
          let meetingSvcRooms = [];
          if (otherData.code === 0) {
            const { data = {} } = otherData;
            const { meetingSvc = [] } = data;
            meetingSvcRooms = data.meetingSvcRooms ? data.meetingSvcRooms : [];
            if (meetingSvc && meetingSvc.length > 0) {
              meetingSvc.forEach((item) => {
                if (!meetingSvcMap[item.roomId]) {
                  meetingSvcMap[item.roomId] = [];
                }
                meetingSvcMap[item.roomId].push(item);
              });
            }
          }
          yield put({ type: 'update', payload: { meetingSvcMap, meetingSvcRooms } });
        } else {
          Toast.success(formatMessage({ id: meetingData.msg }));
        }
      } else {
        yield put({
          type: 'update',
          payload: {
            meetingDetail: {
              // roomInfo,
              roomInfo: roomInfoData,
              meetingRooms: roomInfo,
            },
            meetingSvcMap: {},
          },
        });
      }
    },
    *accepted({ bpmId }, { call }) {
      const { code, msg } = yield call(myMeetingService.taskByBusinessId, {
        bpmId,
        processName: 'bpm_service',
      });
      if (code === 0) {
        Toast.success(formatMessage({ id: 'btn_accepted' }));
        //   yield put({ type: 'getSvcApprovalData' });
      } else if (code === 202219) {
        Toast.fail(formatMessage({ id: 'svc_approval_error' }));
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *refuse({ bpmId, sendMsg, remark, needToast = true }, { call }) {
      const { code, msg } = yield call(myMeetingService.refuseByBusinessId, {
        bpmId,
        remark,
        // sendMsg,
        processName: 'bpm_service',
      });
      if (code === 0) {
        if (needToast) {
          Toast.success(formatMessage({ id: 'btn_rejected' }));
        }

        //   yield put({ type: 'getSvcApprovalData' });
      } else if (code === 202219) {
        Toast.fail(formatMessage({ id: 'svc_approval_error' }));
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.svcApprovalDetail)) {
          const { query } = history.location;
          const { meetingId, fromPage } = query || {};
          dispatch({ type: 'update', payload: { meetingId } });
          dispatch({ type: 'reload', meetingId, fromPage });
        }
      });
    },
  },
};
