import * as faceAuditService from './service';
import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';

export default {
  namespace: 'faceAuditData',
  state: {
    faceData: [],
    modal: false,
    imgModal: false,
    imgUrl: '',
    rows: 10,
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getFaceData({ payload }, { call, put }) {
      //  const { faceData } = yield select((state) => state.faceAuditData);
      const result = yield call(faceAuditService.getFaceData, { payload });
      if (result.code === 0) {
        yield put({
          type: 'save',
          payload: {
            faceData: result.data.rows,
          },
        });
      }
      return result.data.total;
    },
    *review({ payload }, { call, put }) {
      const { code, msg } = yield call(faceAuditService.review, { payload });
      if (code === 0) {
        yield put({
          type: 'getFaceData',
          payload: {
            page: 1,
            size: 10,
          },
        });
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
      return code;
    },
  },
  subscriptions: {
    set({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname.endsWith('faceAudit') || pathname.endsWith('faceAudit/')) {
          dispatch({
            type: 'getFaceData',
            payload: {
              page: 1,
              size: 10,
            },
          });
        }
      });
    },
  },
};
