export default {
  'meeting.room.fee.name': 'Name',
  'meeting.room.fee.location': 'Location',
  'meeting.room.fee.time': 'Scheduled Time',
  'meeting.room.fee.payer': 'Payer',
  'meeting.room.fee.payer.unset': 'Select payer',
  'meeting.room.fee.unit.price': 'Price',
  'meeting.room.fee.unit0': ' per hour',
  'meeting.room.fee.unit1': ' Per square meter',
};
