export default {
  200000: '操作成功！',
  200001: '操作失敗！',

  201000: '已簽到，無需重複簽到！',
  201001: '該用戶不是當前會議的參會人，無法簽到！',
  201002: '當前用戶沒有管理會議的許可權！',
  201009: '當前用戶已拒絕該會議，無法簽到！',
  201013: '當前申請已被處理！',
  202031: '會議不存在,操作失敗！',
  203001: '沒有可用會議號資源，無法預定視頻會議，請與管理員聯繫！',
  cmma_no_devices: '視頻會議室 {names} 未綁定視頻設備，預訂失敗！',
  203003: '視頻會議室資源佔用中',
  203004: '找不到視頻會議資訊',

  202001: '開始時間不能為空',
  202002: '結束時間不能為空',
  202003: '開始時間不能晚於結束時間',
  202004: '會議當前狀態不允許編輯',
  202005: '週期會議時間不允許跨天',
  202064: '跨天會議時間不能大於{value}天',
  202006: '跨天會議時間不能小於最小天數',
  202007: '當前用戶許可權不足，無法修改此會議！',
  202008: '您必須為會議主持人或參會人！',
  202009: '開始時間不能早於當前時間，請重新選擇!',
  202010: '預訂時間的會議室處於維護中，不能預訂會議。',
  202011: '週期會議的迴圈參數未填寫完整!',
  202012: '該週期會議根據週期規則無法產生週期會議。',
  202013: '該週期會議預訂時間超過出最大週期。',
  202014: '週期會議功能關閉，不允許預訂週期會議！',
  202015: '會議開始時間早於工作時間，預訂失敗！',
  202016: '會議結束時間晚於工作時間，預訂失敗！',
  202017: '該時間段內沒有可用的webex線路！',
  202018: 'webex模組未啟動！',
  202019: 'webex會議同步失敗！',
  202021: '當前的會議服務正在審批或審批已通過，不允許繼續修改！',
  202022: '該系列會議存在會議服務正在審批或審批已通過，不允許繼續修改！',
  202023: '會議未到簽到時間!',
  202024: '會議簽到時間已過!',
  202028: '請提前{time}分鐘申請該會議！',

  202077: '您沒有{roomList}會議室的預訂權限，如需修改會議信息，請重新選擇會議室!',

  204001: '前會議狀態不符，無法回執!',
  204002: '會議不存在!',
  204003: '當前用戶不是會議參會人或申請人,不允許回執!',
  204004: '當前用戶已回執會議,無需重複回執!',
  202039: 'Exchange會議時間衝突',
  202040: 'Exchange会议时间冲突',
  202041: 'Exchange審批會議室不能與其他審批會議室同時選擇！',
  202045: '會議室無足夠資源，請聯繫管理員。',
  202046: 'Zoom模組未啟動！',
  202047: 'ZOOM會議接口調用異常，請稍後重試或聯繫系統管理員！',
  202048: '會議延長功能未開啟',
  202049: '會議簽到功能未開啟',

  202056: '該會議室為Exchange會議室，預訂的會議時間不能超過Exchange同步天數的範圍!',
  202050: '當前會議狀態不允許編輯調查問卷',
  202051: '當前會議zoom信息不存在',
  202052: '刪除調查問卷失敗',
  202053: '創建調查問卷失敗',
  202054: '更新調查問卷失敗',
  202055: '創建失敗，該線路不具備創建調查的功能',
  202101: '延長失敗，會議可延長時間不足',
  202105: '第三方預約會議創建人不存在',
  202058: '外部參會人和內部參會人郵箱重複',

  202057: '展板開門失敗，原因：未綁定門牌號',
  202038: '用戶權限不足,操作失敗！',
  202061: '會議狀態已改變，取消會議失敗！',
  202059: '這是壹個Exchange會議，請補充外部參會人郵箱信息！',

  202030: '手機號碼{phone}已存在於歷史參會人清單中',
  202065: '郵箱{email}已存在於歷史參會人清單中',
  200006: '當前用戶預訂次數已被限制',
  202060: '沒有開啟反向同步功能，預訂失敗',
  202108: '會議室不可用',
  202109: '非實體會議室不能掃碼預訂',

  202134: '會議正在處理中，不允許編輯！',
  202110: '預訂失敗，會議室：{rooms}已下線',
  202111: '預訂失敗，會議室：{rooms}已删除',
  202112: '預訂失敗，會議室：{rooms}已維護',
  202066: '會議已開始簽到, 不允許編輯！',
  202067: '當前用戶沒有預訂週期會議的權限！',
  202068: '當前用戶沒有預訂跨天會議的權限！',
  20206701: '當前用戶沒有編輯週期會議的權限！',
  'meeting.booking.fail.title.maintain':
    '{roomName}會議室在您選擇的會議時間內會維護，預訂會議失敗！',
  'meeting.booking.fail.title.maintain.edit':
    '{roomName}會議室在您選擇的會議時間內會維護，修改會議失敗！',

  202120: '創建失敗，創建人或主持人電話為空！',
  202121: '創建騰訊會議失敗！',
  202122: '更新騰訊會議失敗！',
  202115: '您已經被拉入系統黑名單，暫時無法預訂會議',
  'meeting.booking.fail.tencent.max': '同一時間只能預訂{num}個騰訊會議，請更換時間後再試。',

  202131: '獲取teams會議參數配置信息錯誤，請聯系管理員',
  202132: '创建teams会议失败！',
  202133: '更新teams會議失敗！',
  207012: '會議預訂時長超出會議室單場會議最大時間{value}分鐘',
  200999: '預約的會議與其他會議衝突，預約失敗',
  207013: '會議時長大於會議室設置跨天最大分鐘數',
  202143: '獲取YMS未綁定設備信息失敗',
  202144: 'YMS模塊未配置，請聯系管理員配置相關信息',
  202145: 'YMS入會時間已到達，不允許操作',
  202146: '創建YMS會議失敗！',
  202147: '會議室未綁定設備終端，請重新選擇會議室！',
  202148: 'YMS預約時間距會議開始時間不可小於5分鐘',
  202149: '結束YMS會議失敗！',
  202151: '操作YMS會議失敗！',
  202150: '編輯YMS會議失敗失敗！',
  202152: '創建飛書會議失敗！',
  202153: '更新飛書會議失敗！',
  202154: '延長飛書會議失敗！',
  'error.meeting.teams.timeout': 'Teams 網絡請求超時',
  202158: '創建人飛書ID不存在',
  20210059: '密碼格式不正確，僅支持6比特純數位！',
  202200: '飛書會議室 和 Exchange 會議室不能同時預約',
  202201: '不能直接將Exchange會議室更換為飛書會議室',
  202202: '不能直接將飛書會議室更換為Exchange會議室',
  202204: '該會議預訂申請已被拒絕',
  202205: '該會議預訂申請，由於無人審批而自動取消',
  202164: '網絡會議不允許預約跨天會議!',
  202162: '當前用戶無預約teams會議權限，預約失敗！',
  202161: '騰訊會議參數配置信息錯誤，請聯系管理員！',

  202206: '訪客預約時間限制{value}天以內，請重新預約',
  202207: '訪客未開啟跨天配置',
  202208: '訪客跨天時間限制{value}天以內，請重新預約',
  202209: '訪客來訪時間早於工作時間',
  202210: '訪客來訪結束時間晚於工作時間',
  202211: '上壹次延長操作正在處理中，請稍後再試',
  202212: '會議開始時間晚於工作時間，預約失敗!',
  202213: '會議結束時間早於工作時間，預約失敗!',
  202214: '訪客人數超出拜訪人當天最大拜訪數量!',
  202169: '單日會議會議時長小於配置最小時長!',
  202172: '延長失敗，會議時長不能超過{value}分鐘!',
  202171: '當前日期不可用，請選擇其他日期預約！',
  // 202180: '視頻會議創建失敗',
  // 202181: '視頻會議更新失敗',
  // 202182: '當前預訂會議人數過多，請稍後重試',
  202173: 'exchange 同步會議無需回執， 請使用outlook上回執',
  202174: '飛書同步會議無需回執， 請使用飛書上回執',
  202178: '跨天不能預約{value}天后的會議！',
  202181: '週期會議不能預約{value}天后的會議！',
  202179: '週期會議會議時長小於配置最小時長！',
  202180: '週期會議會議時長大於配置最大時長！',
  202182: '延長失敗，會議時長不能超過{value}分鐘!',
  202176: '會議延長失敗，{value}正在維護中!',
  202177: '會議延長失敗，{value}已停用!',
  202183: '當前週期會議還存在未處理完的會議，請稍後再進行編輯',
  202184: '當前週期會議還存在未處理完的會議，請稍後再進行取消',
  20218101: '循環會議無法在同一日內預約多場，請選擇其他日期',
  2023001: '創建CloudMeet會議失敗,請檢查後台日誌！',
  2023002: '更新CloudMeet會議失敗,請檢查後台日誌！',
  2023003: '延長CloudMeet會議失敗,請檢查後台日誌！',
  2023004: 'CloudMeet會議已提前進入或已開始，無法取消！',
  2023005: '結束CloudMeet會議失敗,請檢查後台日誌！',
  2023006: '會議已開始或已提前入會，不允許編輯或取消！',
  2023007: 'CloudMeet會議已結束，無法操作！',
  202401: '創建華為視頻會議失敗,請檢查後台日誌！',
  202402: '更新華為視頻會議失敗,請檢查後台日誌！',
  202404: '延長華為視頻會議失敗,請檢查後台日誌！',
  202403: '結束華為視頻會議失敗,請檢查後台日誌！',
  202405: '取消華為視頻會議失敗,請檢查後台日誌！',
  202406: '華為視頻會議配置錯誤，請檢查後臺日誌！',
  202407: '操作失敗，請檢查後臺日誌！',
  202185: '該會議包含需要審批的會議室，修改會議時間後需要重新審批，請確認是否修改？',
  202186: '修改後的會議室包含需要審批的會議室，修改後會議需要重新審批，請確認是否修改？',
  202187: '該會議包含需要審批的會議室，修改循環規則後需要重新審批，請確認是否修改？',
  202190: '變更會議后，針對單場會議預訂的會議服務/設備會被取消，是否繼續？',
  202194: '訪客集成功能未開啟',
  202195: '邀請訪客必須提前{value}分鐘預訂',
  202197: '飛書會議室不能預約teams會議',
  202215: '會議邀請訪客，訪客預約時間為非工作時間，操作失敗！',
  202216: '會議邀請訪客，訪客跨天時間超出天數限制，操作失敗！',
  202217: '會議邀請訪客，訪客預約時間超出天數限制，操作失敗！',
  202218: '會議邀請訪客，訪客數量超出接待地點的可邀請數量，操作失敗！',
  202219: '該條記錄已被其他人員審批，無法再次審批！',
  202199: 'Exchange/Office365會議室日曆上未讀取到該會議，請檢查會議室日曆是否應答成功',
  202220: '當前租戶下不存在會議室，無法生成模擬數據',
  202221: '企微會議參數配置信息錯誤，請聯系管理員',
  202222: '創建企微會議失敗，請檢查後臺日誌',
  202223: '修改企微會議失敗，請檢查後臺日誌',
  202224: '取消企微會議失敗，請檢查後臺日誌',
  202225: '延長失敗，企微會議無法延長會議',
  20231008: 'webex會議時長必須大於10分鐘',
  202310241700: 'webex同步預約失败',
  202310241722: 'webex同步更新失敗',
  202402061443: '請檢查webex賬號是否有效，或者查看scope是否配置了足夠權限',
  202402061445: '請檢查webex配置或查看token是否過期',
  20240923: '請選擇至少一個終端會議室',
  201016: '會議室沒有綁定工作流模版',
  // 201016: '預訂失敗，會議室:{rooms}沒有會議室管理員',
  201017: '會議服務沒有綁定工作流模版',
  201018: '會議移動設備沒有綁定工作流模版',
  2023083001:'附件信息不能為空！',
  2023083002:'文件名稱不能為空！',
  2023083003:'文件後綴不能為空！',
  2023083004:'文件格式錯誤！',
  2023083005:'附件已存在，不能重複上傳！',
  2023083006:'當前用戶無下載權限！',
  2023083007: '存儲空間已滿',
  2023083008: '上傳失敗',
  2023083009: '無效的文件名',
  2023083010: '會議已結束',
  2023083011: '會議已取消',
  20100501: '存在被删除的会议室,请刷新页面重新选择会议室',
};
