export default {
  'register.register': '注册',
  'register.bind': '绑定',
  'register.bind.tip': '请绑定您在威思客云的账号',
  'register.verify.phone': '手机验证',
  'register.verify.mail': '邮箱验证',
  'register.password.rules': '密码必须包含8到16位数字及字母，可包含如下特殊字符：.!@#$%^&*-_）',
  'register.protocol.tip1': '我已阅读并同意威思客云的',
  'register.protocol.tip2': '《服务条款》',
  'register.protocol.tip3': '和',
  'register.protocol.tip4': '《隐私政策》',
  'register.verifycode.get': '获取验证码',
  'register.verifycode.reget': '重新获取',
  'register.verifycode.expired': '验证码过期',
  'register.verifycode.nouser': '没有此用户',
  'register.verifycode.fail': '发送失败',
  'register.second': '秒',
  'register.ok': '确定',
  'register.cancel': '取消',
  'register.bind.success': '绑定成功',
  'register.register.success': '注册成功',

  'register.tenantName.tip': '请输入公司名称',
  'register.contactRealName.tip': '请输入联系人姓名',
  'register.phone.tip': '请输入联系人手机',
  'register.contactMail.tip': '请输入联系人邮箱',
  'register.verifyType.tip': '请选择验证类型',
  'register.captcha.tip': '请输入验证码',
  'register.password.tip': '请输入密码',
  'register.pactCheck.tip': '请阅读服务条款和隐私政策',
  'register.bindAccout.tip': '请输入账号',
  'register.bindPwd.tip': '请输入密码',
  'info.form.phone.placeholder': '请输入手机号码',

  'register.tenantName.error': '公司长度不得超过50个字符',
  'register.contactRealName.error': '姓名长度为2-20个字符',
  'register.phone.error': '请输入正确的手机号',
  'register.contactMail.error': '请输入有效的邮箱地址',
  'register.captcha.error': '请输入正确的验证码',
  'register.password.error': '密码格式不正确',
  'register.bindAccout.error': '邮箱/手机号格式不正确',

  'register.title': '您的企业注册了吗？',
  'register.title.tip': '如果您的企业还没有在威思客云注册，请创建新的企业',
  'register.company.name': '企业名称',
  'register.mail': '邮箱',
  'register.mobile': '手机',
  'register.accout.exist': '已有账户？',
  'register.realname': '姓名',

  'register.try.use': '注册立即获得30天的免费试用',
  'register.try.more': '更多威思客云高级版本',
  'register.hotline': '现在就拨打咨询热线：400-685-3160',
  'register.success.tip1': '完成注册时，系统将开通',
  'register.success.tip2': '服务，您将拥有管理员权限，请先在管理端维护',
  'register.success.tip3': '基础数据，管理端地址：',

  'cloud.productcode.smt': '会议',
  'cloud.productcode.smt.room': '会议室',
  'cloud.productcode.sws': '工位',

  'cloud.auth.fail.tip': '系统出现未知错误，请稍后再试',

  'cloud.register.bind': '返回',
  'cloud.gobind': '去绑定',

  'cloud.safe.verify.code': '获取验证码',
  'cloud.safe.verify.code.tip': '请输入验证码',
  'order.invoice.emil.tip': '请输入邮箱',
  'register.verification.img': '请输入图形验证码',

  'login.security.code.tip': '请输入安全码',
  'login.security.step': '获取步骤',
  'login.security.step1': '1. 打开您设备(手机、平板、电脑等)上的双因素认证应用，推荐使用',
  'login.security.step2': '2. 找到您之前扫描并绑定的账号',
  'login.security.step3': '3. 获取显示的密码代码，填入下方的输入框',

  'cloud.country.areacode.china': '(中国)',
  'cloud.country.areacode.hk': '(中国香港)',
  'cloud.country.areacode.korea': '(韩国)',
  'cloud.country.areacode.singapore': '(新加坡)',
  'cloud.country.areacode.thailand': '(泰国)',
  'cloud.country.areacode.usa': '(美国)',
};
