import moment from 'moment';

// 默认时间间隔
export const defaultTimeInterval = 1 * 60; // 单位分钟
// 时间format
const timeFormatString = 'HH:mm';

/**
 * 影响离开时间的配置
 * 1、跨天访客天数配置
 * 2、工作日配置
 */

/**
 * 计算结束时间
 * @param {Object} params - 参数对象
 * @param {moment} params.startTime - 开始时间（moment 对象）
 * @param {number} params.intervalMinutes - 时间间隔（单位：分钟）
 * @param {moment} params.maxTime - 限制的最大时间（moment 对象）
 * @returns {moment} - 结束时间（moment 对象）
 */
export const calculateEndTime = (params: any) => {
  const { startTime, intervalMinutes, minTime, maxTime } = params || {};

  // console.log(
  //   startTime?.format('YYYY-MM-DD HH:mm:ss'),
  //   'startTime',
  //   intervalMinutes,
  //   'intervalMinutes',
  //   maxTime?.format('YYYY-MM-DD HH:mm:ss'),
  //   'maxTime',
  // );

  // 时间间隔
  const timeInterval = intervalMinutes === undefined ? defaultTimeInterval : intervalMinutes;

  // 结束时间
  let endTime = moment(startTime).add(timeInterval, 'minute').startOf('minute');

  // 限制的最小时间
  if (minTime && endTime.isBefore(minTime)) {
    endTime = moment(minTime);
  }

  // 限制的最大时间
  if (maxTime && endTime.isAfter(maxTime)) {
    if (moment(maxTime)?.hour() === 23) {
      endTime = moment(maxTime)?.minute(45)?.startOf('minute');
    } else {
      endTime = moment(maxTime);
    }
  }

  return endTime;
};

/**
 * 判断传入日期、时间是否符合节假日配置
 * 传入的日期那天的工作日开始时间、结束时间
 * @returns
 */
export const dateIsConformsHolidayConfig = (date: moment.Moment, holidayConfig: any) => {
  const {
    sysHolidayDetailList = [],
    startTimeFromHolidayConfig = null,
    endTimeFromHolidayConfig = null,
  } = holidayConfig || {};
  if (sysHolidayDetailList?.length && startTimeFromHolidayConfig && endTimeFromHolidayConfig) {
    const [workStartHour, workStartMinute] = startTimeFromHolidayConfig?.split(':') || [];
    const [workEndHour, workEndMinute] = endTimeFromHolidayConfig?.split(':') || [];
    let startWorkTime = moment(date).hour(workStartHour).minute(workStartMinute).startOf('m');
    let endWorkTime = moment(date).hour(workEndHour).minute(workEndMinute).startOf('m');
    let nextAvailableDate = date.clone();
    // 先判断日期
    if (
      sysHolidayDetailList?.find((item: any) => item?.day === moment(date).format('YYYY-MM-DD'))
    ) {
      nextAvailableDate.add(1, 'days');
      // 循环查找下一个非节假日日期
      while (
        sysHolidayDetailList.some(
          (item: any) => item.day === nextAvailableDate.format('YYYY-MM-DD'),
        )
      ) {
        nextAvailableDate.add(1, 'days');
      }
      // 根据找到的非节假日日期设置工作时间
      startWorkTime = nextAvailableDate
        .clone()
        .hour(workStartHour)
        .minute(workStartMinute)
        .startOf('m');
      endWorkTime = nextAvailableDate.clone().hour(workEndHour).minute(workEndMinute).startOf('m');
      return {
        isConformsHolidayConfig: false,
        startWorkTime,
        endWorkTime,
      };
    }
    // 再判断时间
    if (!moment(date).isBetween(startWorkTime, endWorkTime, 'minute', '[]')) {
      if (moment(date).isAfter(startWorkTime, 'minute')) {
        nextAvailableDate.add(1, 'days');
        // 循环查找下一个非节假日日期
        while (
          sysHolidayDetailList.some(
            (item: any) => item.day === nextAvailableDate.format('YYYY-MM-DD'),
          )
        ) {
          nextAvailableDate.add(1, 'days');
        }
      }
      // 根据找到的非节假日日期设置工作时间
      startWorkTime = nextAvailableDate
        .clone()
        .hour(workStartHour)
        .minute(workStartMinute)
        .startOf('m');
      endWorkTime = nextAvailableDate.clone().hour(workEndHour).minute(workEndMinute).startOf('m');
      return {
        isConformsHolidayConfig: false,
        startWorkTime,
        endWorkTime,
      };
    }
    return {
      isConformsHolidayConfig: true,
      startWorkTime: moment(date),
      endWorkTime,
    };
  }
  return {
    isConformsHolidayConfig: true,
    date,
  };
};

export default function () {
  return { calculateEndTime, dateIsConformsHolidayConfig };
}
