export default {
  checkIn_index_tip1: 'Input visitor registration information and complete visitor check-in',
  checkIn_index_tip2:
    'The invited visitors will be signed in accordance with the invitation code received.',
  checkIn_invite_tip1: 'Please enter a complete invitation code',
  checkIn_invite_tip2: 'Please enter a pure number invitation code',
  checkIn_invite_tip3: 'Please enter the invitation code',
  checkIn_result: 'Operating results',
  checkIn_host_tip1: 'Please confirm your receptionist call.',
  checkIn_NDA_tip1: 'Please check and agree to confidentiality agreement',
  checkIn_people_tip1: 'Please enter the number of visitors',
  checkIn_people_tip2: 'The number of visitors is 1 to 10',
  checkIn_people: 'Numbers',
  checkIn_host_phone: 'Receptionist Phone',
  checkIn_no_host_phone: 'No receptionist phone',
  checkIn_NDA_tip2: 'Read and agree',
  checkIn_NDA: '《NDA》',
  checkIn_login_error: 'Please contact the administrator to check the default configuration',
  checkIn_success: 'Registration successful',
  checkIn_fail: 'Registration failed',
  checkIn_sign_fail: 'Sign in successfully',
  checkIn_sign_success: 'Sign in failed',
  checkIn_photo_success: 'Take photos success',
  checkIn_photo_fail: 'Pictures failed',
};
