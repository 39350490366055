import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { getDoorTroubleTicketListBySelf } from './service';

export default {
  namespace: 'myFaultRepair',
  state: {
    doorTroubleTicketList: [],
    faultRepairDetail: {},
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getDoorTroubleTicketListBySelf(_, { call, put }) {
      const response = yield call(getDoorTroubleTicketListBySelf);
      if (response.code === 0) {
        yield put({
          type: 'save',
          payload: {
            doorTroubleTicketList: response.data || [],
          },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        if (pathname === '/accessApp/myFaultRepair') {
          dispatch({ type: 'getDoorTroubleTicketListBySelf' });
        }
      });
    },
  },
};
