import { RESTFUL_PATH } from '@/utils/constant';
import request from '@/utils/request';

/**
 * 首页外链地址接口
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getTrinaSolarLinkUrl() {
  return request(`/${RESTFUL_PATH.sws}/api/v2/reserve/guideLinkUrl`);
}
