export default {
  device_tab_value1: '請選擇移動設備!',
  device_tab_value2: '該設備已沒有庫存',

  'meeting.manage.title.device': '移動設備申請',
  'meeting.manage.device.type_': '設備類型：',
  'meeting.manage.device.describe_': '描述：',
  'meeting.manage.device.add': '添加移動設備',
  'meeting.manage.device.all': '全選',
  'meeting.manage.device.desc.null': '無設備描述',
  'meeting.manage.back.home': '返回首頁',
  'meeting.manage.previous.page': '返回上一頁',
  'meeting.manage.device.empty': '請聯繫管理員，為該會議室所在區域增加移動設備',
  'meeting.manage.device.info.shenpi': '該設備處於審批或借用中,暫時無法刪除！',
  'meeting.new.device.add.success': '添加成功！',
  'new.meeting.device.status.approval':'審批中',
  'meeting.svcdeal.wait':'可借用',
  'meeting.devicedeal.borrow':'可借用',
  'meeting.devicedeal.expire':'已過期',
  'meeting.devicedeal.already.cancel':'已取消',
  'meeting.devicedeal.finish':'已歸還',
  'meeting.manage.device.noData':'暂无会议设备',
  'meeting.new.device.stop': '收起',
  'meeting.new.device.Expand': '展开',
};
