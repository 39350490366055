export default {
  'meeting.detail.name': 'Subject',
  'meeting.detail.time': 'Time',
  'meeting.detail.room': 'Room',
  'meeting.detail.holder': 'Host',
  'meeting.detail.member': 'Attendee(s)',
  'meeting.detail.external': 'External attendee(s)',
  'meeting.detail.memberCnt': '{num} attendee(s)',
  'meeting.detail.method': 'Notice',
  'meeting.detail.remark': 'Remark',
  'meeting.detail.welcome': 'Welcome',
  'meeting.detail.audit': 'Review status',
  'meeting.detail.auditRemark': 'Objection',
  'meeting.detail.attach': 'Attachment',
  'meeting.detail.none': 'None',
  'meeting.detail.mailText': 'Email',
  'meeting.detail.rejected': 'Rejected',
  'meeting.detail.webexTitle': 'WebEx meeting',
  'meeting.detail.btn.backToList': 'Back to Meetings',
  'meeting.detail.btn.cancel': 'Cancel',
  'meeting.detail.btn.edit': 'Edit',
  'meeting.detail.btn.save': 'Save as template',
  'meeting.detail.btn.restart': 'Restart',
  'meeting.detail.btn.delay': 'Extend',
  'meeting.detail.btn.close': 'End',
  'meeting.detail.btn.control': 'Control',
  'meeting.detail.btn.join': 'Join',
  'meeting.detail.service': 'Service Type',
  'meeting.detail.no.service': 'No Service',
  'meeting.detail.no.service.placeholder': 'Not Selected',
  'meeting.detail.secret': 'Confidentiality of the meeting',
  'meeting.detail.secret_yes': 'Yes',
  'meeting.detail.secret_no': 'No',
  'meeting.detail.allUser': 'All staff meeting',
  'meeting.detail.allUser_yes': 'Yes',
  'meeting.detail.allUser_no': 'No',
  'meeting.detail.allUser.pls': 'All staff',
  'meeting.detail.sign': 'Sign in record',
  'meeting.detail.close.tip': 'End current meeting?',
  'meeting.detail.confirm.cmma':
    'The video meeting is still in progress. Please end the meeting on control page.',
  'meeting.detail.cancel.tip': 'Are you sure you want to cancel the meeting?',
  cancel_loop_meeting: 'Cancel all recurring meetings of this series?',
  modify_loop_meeting: 'Modify all recurring meetings of this series?',
  'meeting.detail.agenda': 'Agenda',
  'meeting.detail.video': 'Video',
  'meeting.detail.look': 'View',
  'meeting.detail.number': 'Meeting ID',
  'meeting.detail.attender.receipt': 'Accepting acknowledgement',
  'meeting.detail.and': ' and ',
  'meeting.detail.accept': ' accepted',
  'meeting.detail.refuse': ' declined',
  'meeting.detail.undo': ' tentative',
  'meeting.static.accept': 'Accept',
  'meeting.static.accept.tip': 'Receipt of accept',
  'meeting.static.refuse': 'Decline',
  'meeting.static.refuse.tip': 'Receipt of decline',
  'meeting.static.undo': 'Tentative',
  'meeting.static.undo.tip': 'Receipt of tentative',
  'meeting.detail.back': 'Back',
  'meeting.attendee.page.page.value1': 'There is no employee in this department!',
  'meeting.detail.webex.email.empty': ' Email of Webex meeting attendees can not be empty!',

  'detail.meeting.meetingMode': 'Recurrence Pattern',
  'detail.loop.everyworkday': 'Every workday',
  'detail.loop.everyday': 'Every {day} day(s)',
  'detail.loop.everyMonth': 'The {day} day of every month',
  'detail.loop.everyMonthDay': 'No.',
  'detail.loop.every': 'Every ',
  'detail.loop.mon': 'Mon',
  'detail.loop.thu': 'Tue',
  'detail.loop.wed': 'Wed',
  'detail.loop.thr': 'Thu',
  'detail.loop.fri': 'Fri',
  'detail.loop.sat': 'Sat',
  'detail.loop.sun': 'Sun',
  'meeting.status.invalid': 'Invalid time occupation',

  'meeting.detail.zoomTitle': 'Zoom meeting',
  'meeting.detail.zoomRoomTitle': 'Zoom Rooms meeting',
  'meeting.detail.btn.start': 'Open',
  'meeting.detail.form.cancel.reason': 'Cancel reason',
  meeting_detail_zoom_meeting_id: 'Meeting ID',
  meeting_detail_zoom_join_password: 'Meeting Password',
  meeting_detail_zoom_join_url: 'Invitation URL',
  meeting_detail_zoom_copy_url: 'Copy URL',
  meeting_detail_zoom_copy_success: 'The invitation URL has been copied.',
  meeting_detail_webex_meeting_id: 'Meeting ID',
  meeting_detail_webex_join_password: 'Password',
  meeting_detail_webex_join_url: 'Invitation URL',
  meeting_detail_webex_copy_url: 'Copy URL',
  meeting_detail_webex_copy_success: 'The invitation URL has been copied.',
  meeting_detail_copy_success: 'Has been copied',
  'meeting.detail.qr': 'QR Code',
  'meeting.detail.qr.info': 'QR code for opening door',

  'meeting.detail.tencent.title': 'Tencent',
  'meeting.detail.wechat.title': 'Wechat',
  'meeting.detail.teams.title': 'Teams',
  'meeting.detail.flybook.title': 'Feishu',
  'meeting.detail.dingding.title': 'Dingding',
  'meeting.detail.huichang.title': 'Chanyun',
  'meeting.detail.quanshi.title': 'Full time',
  'meeting.detail.net': 'Please click to join the webinar',
  'meeting.cancel.user': 'Canceller',
  'meeting.cancel.type0': 'Auto cancel',
  'meeting.cancel.type1': 'Manually canceled by user',
  'meeting.cancel.type2': 'Cancellation without check-in',
  'meeting.cancel.type3': 'Unapproved cancellation',
  'meeting.cancel.type4': 'Exchange / o356 cancel',
  'meeting.cancel.type5': 'Third party cancellation',
  'meeting.cancel.type6': 'Guest cancellation',
  'meeting.cancel.log': 'Cancel record',
  'meeting.Agenda.title': 'Topic',
  'meeting.Agenda.time': 'Time',
  'meeting.detail.memberCnt.agenda': '{num} attendee(s)',
  main_tip: 'hint',
  main_tip_content:
    'Sorry!it is currently not possible to jump directly from the corporate WeChat. Please copy the team meeting link in the list to the browser to participate in the meeting.',
  'meeting.visit.outuser': 'Visitor',

  'meeting.detail.video.password.tips':
    'Please use the password 【{value}】 to join the membership',

  meeting_sign_status_value0: 'Replied accepted',
  meeting_sign_status_value1: 'Replied Rejected',
  meeting_sign_status_value2: 'Replied pend',
  meeting_sign_status0: 'Accepted',
  meeting_sign_status1: 'Rejected',
  meeting_sign_status2: 'Pending',
  meeting_sign_modal_title: 'Do you attend the meeting ?',
  'meeting.detail.creater.name': 'Booker',
  'meeting.detail.creater.time': 'Creation time',
  'meeting.detail.item.other': 'Other',
  'meeting.info.item.welcome': 'Welcome',
  'meeting.info.item.remark': 'Remark',
  'meeting.info.item.service': 'Services',
  'meeting.info.item.device': 'Devices',
  'meeting.info.item.agenda': 'Agenda',
  'meeting.info.item.cycle': 'Cycle',
  'meeting.info.title.service': 'Meeting services',
  'meeting.info.title.device': 'Devices',
  'meeting.info.item.welcome.tip': 'No welcome message temporarily',
  'meeting.info.item.remark.tip': 'No remark temporarily',
  'meeting.info.item.service.tip': 'No service temporarily',
  'meeting.info.item.device.tip': 'No device temporarily',
  'meeting.info.item.agenda.tip': 'No agenda temporarily',
  'meeting.info.item.cycle.tip': 'Do not cycle',
  'meeting.info.item.questionnaire.tip': 'No findings temporarily',
  'meeting.info.start.time.tip': 'Before the meeting starts',
  'meeting.info.sign.time.before': '{value} minutes before and',
  'meeting.info.sign.time.after': ' {value} minutes after the meeting start time',
  'meeting.info.sign.time.startNow.after':
    'Sign in within {value} minutes after the meeting starts',
  'meeting.info.sign.time.after.before': '{value} minutes before and after the meeting start time.',
  'meeting.info.host.modal.title': 'Meeting Host',
  meeting_sign_status_value_null: 'Not replied',
  meeting_sign_modal_title_refuse: 'Reason for not attending the meeting',
  meeting_sign_modal_title_refuse_tips:
    'please enter the reason for not attending the meeting (optional)',
  'meeting.list.column.attend': 'Invited attendee(s)',
  'meeting.list.column.actual.attend': 'Actual attendee(s)',
  'meeting.list.column.actual.attend.gotit': 'I got it',
  'meeting.detail.release.time': 'Release time',
  'meeting.cancel.type9': 'Canceled due to conference room maintenance',
  'meeting.cancel.type10': 'Canceled due to conference room deactivation',
  'meeting.detail.form.cancel.Stop': 'Meeting room ({value}) deactivated',
  'meeting.detail.form.cancel.Maint': 'Meeting room ({value}) maintenance',
};
