export default {
  meeting_name: 'Name',
  meeting_name_tip: 'Please enter the name',
  meeting_introduce: 'Brief Introduction',
  meeting_introduce_tip1: 'Please enter the brief introduction',
  meeting_introduce_tip2: 'The length of a introduction can not be less than 10 words',
  meeting_address: 'Site Selection',
  meeting_address_tip: 'Please enter the site selection',
  meeting_detail: 'Detailed Address',
  meeting_detail_tip: 'Please enter the detailed address',
  meeting_continue: 'Save and continue',
  meeting_text: 'Text field',
  meeting_validity: 'Period of validity',
  meeting_validity_tip: 'Please enter the period of validity',
  meeting_content: 'Registration Form',
  meeting_set: 'Form Setting',
  meeting_isRAE: '(Required，Editable)',
  meeting_isEdit: '(Editable)',
  meeting_delForm: 'Are you sure you want to delete?',
  meeting_isRequired: '(Required)',
  meeting_add: 'Add',
  meeting_signSuccess: 'Succeed',
  meeting_signFail: 'Failed',
  meeting_signValidity: 'Date Validity',
  meeting_signValidity_tip: 'Please enter the check-in period',
  meeting_successInfo: 'Successful Information',
  meeting_successInfo_tip: 'Please enter the successful prompt',
  meeting_failInfo: 'Failure Information',
  meeting_failInfo_tip: 'Please enter a sign to fail prompt',
  meeting_textSet: 'Text field',
  meeting_titleSet: 'Title',
  meeting_titleSet_tip: 'Please enter the title',
  meeting_presetValue: 'Preset value',
  meeting_tipInfo: 'prompt message',
  meeting_required: 'not null',
  meeting_info: 'Meeting Information',
  meeting_setForm: 'Form Setting',
  meeting_setSign: ' Sign In Information Setting',
  meeting_update: 'Update',
  meeting_create: 'Create',
  meeting_cancel: 'Cancel',
  meeting_createSuccess: 'Success',
  meeting_name_: 'Name：',
  meeting_entryUrl_: 'Conference registration web site：',
  meeting_entryUrlCode_: 'QR code of the conference registration web site：',
  meeting_entryValidity_: 'Period of validity：',
  meeting_entry_tip:
    'The registration form is effective only during the validity period of the registration. The advance and expired visit will not be filled out and submitted.',
  meeting_detail_: 'Detail',
  // 会议详情
  meeting_addUser: 'Add User',
  meeting_updatePhoto_tip: 'Please upload your photo',
  meeting_userName: 'Name',
  meeting_userName_tip: 'Please enter your name',
  meeting_company: 'Company',
  meeting_company_tip: 'Please enter the company',
  meeting_phone: 'Mobile',
  meeting_phone_tip1: 'Please enter your mobile',
  meeting_phone_tip2: 'Please input correct mobile',
  meeting_mail: 'mail',
  meeting_required_halfTip: 'is required',
  meeting_num: 'No.',
  meeting_yet: 'Un Accessed',
  meeting_notYet: 'Accessed',
  meeting_operation: 'Operating',
  meeting_retroactive: 'Sign in',
  meeting_delUser_tip1:
    'Are you sure you want to delete?（This operation causes the user to be unable to sign in）',
  meeting_delUser_tip2: 'Are you sure you want to delete?',
  meeting_delUser: 'Delete',
  meeting_noUser_tip1: 'There is no user, you can add users',
  meeting_noUser_tip2: 'Or share the registration address to the user：',
  meeting_overview: 'Overview',
  meeting_tableCount: 'Registration Forms',
  meeting_viewCount: 'Page View',
  meeting_daySubmitCount: 'Registration Form Submission',
  meeting_signCount: ' Check-in Quantity',
  meeting_projectIntro_: 'Brief introduction：',
  meeting_createDate_: 'Create Time：',
  meeting_entryFormUrl_: 'Registration Form：',
  meeting_signValidity_: 'Date validity of signing in：',
  meeting_loginCode_: 'Login code：',
  meeting_userData: 'User Data',
  meeting_search_tip: 'Please enter the name or phone number you want to search',
  // index
  meeting_userCount: 'Number Of Users',
  meeting_createDate: 'Create Time',
  meeting_notFilled: 'Unfilled',
  meeting_loginCode: 'Login code',
  meeting_status: 'Status',
  meeting_creating: 'Creating',
  meeting_underway: 'In Progress',
  meeting_expired: 'Expired',
  meeting_delProject_tip: 'Are you sure you want to delete?',
  meeting_new: 'New Meeting',
  meeting_to: 'To',
  // 报名表
  meeting_formTitle: 'Entry Form',
  meeting_input: 'Please enter the',
  meeting_noTime: 'No registration time',
  meeting_sign_time: 'Registration Time：',
  meeting_success: 'Success',
  meeting_success_tip1: 'You can sign in on the day of the meeting',
  meeting_success_tip2:
    'The qr code can be used as a check-in voucher. Please save this page for backup',
  // 思科迁移
  meeting_attending_area: 'Area',
  meeting_new_attending_area: 'Create',
  meeting_edit_attending_area: 'Edit',
  meeting_serial_number: 'NO.',
  meeting_attending_area_name: 'Name',
  meeting_attending_area_name_placeholder: 'Please enter the name,Maximum 20 characters',
  meeting_user_count: 'Number Of Users',
  meeting_create_time: 'Create Time',
  meeting_time: 'Meeting Time',
  meeting_not_filled: 'Unfilled',
  meeting_status_in_create: 'Creating',
  meeting_status_process: 'In Progress',
  meeting_status_end: 'Expired',
  meeting_operation_edit: 'Edit',
  meeting_operation_view: 'View',
  meeting_operation_del: 'Delete',
  meeting_del_confirm: 'Are you sure you want to delete?',
  meeting_button_confirm: 'Confirm',
  meeting_button_cancel: 'Cancel',
  meeting_subject: 'Theme',
  meeting_subject_placeholder:
    'Please enter the theme,The length must not be less than 6 characters.',
  meeting_start_time: 'Start',
  meeting_start_time_placeholder: 'Please select start time.',
  meeting_start_time_error: 'The start time must be earlier than the end time.',
  meeting_end_time: 'End',
  meeting_end_time_placeholder: 'Please select end time.',
  meeting_end_time_error: 'The end time must be later than the start time.',
  meeting_attending_area_save_success: 'Success',
  meeting_attending_area_del_success: 'Success',
  meeting_attendees: 'Attendees',
  meeting_sign_in_quantity: 'Check-in Quantity',
  meeting_sign_in_avatar: 'Face photos (used for quick entrance of the face)',
  meeting_user_name: 'Name',
  meeting_user_telephone: 'Phone',
  meeting_user_meeting_time: 'Arrival Time',
  meeting_user_sign_in_time: 'Status',
  meeting_sign_in_status: 'Accessed',
  meeting_fill_avatar: 'Enter the participant code,then re-upload your face photo',
  meeting_fill_avatar_tabs: 'Re-upload photo',
  meeting_participant_code: 'Code',
  meeting_participant_code_placeholder: 'Please enter the code.',
  meeting_query_avatar: 'Query face photos',
  meeting_avatar: 'Face Photos',
  meeting_avatar_placeholder: 'Please select a face photo.',
  meeting_avatar_already_have: 'Face photo has been uploaded, no need to retransmit.',
  meeting_attendees_export: 'Export',
  meeting_user_company: 'Company',
  meeting_button_reset: 'The query again',
  meeting_avatar_update_err: 'Face photo upload error, please try again later.',
  meeting_service_err: 'Server exception. Please try again later',
  meeting_upload_err: 'Upload failed, please try again later',
  meeting_update_face_success: 'Success',
  meeting_please_upload_face: 'Please upload your photos.',
  meeting_export: 'Export',
};
