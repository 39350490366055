export default {
  'sws.menu.station.facilityManage': '設施管理',
  'sws.facility.manage': '此二維碼不是工位二維碼，也不是設施二維碼',
  // hanlei
  'sws.facility.sure.release': '確定解除設施綁定?',
  'sws.facility.sure.no': '放棄操作',
  'sws.facility.sure.yes': '解除綁定',
  'sws.facility.unbind.facility': '解除設施綁定',
  'sws.facility.sure.releaseAll': '確定解除所有設施綁定?',
  'sws.facility.sure.noAll': '放棄操作',
  'sws.facility.sure.yesAll': '解除綁定',
  'sws.facility.enter.num': '輸入工位編號或設施編號',
  'sws.facility.unbind.facilityAll': '解除所有設施綁定',

  'sws.facility.prompt.num': '請輸入工位編號或設施編號',
  'sws.facility.location.information': '工位信息',

  'sws.facility.sure.breleaseAll': '確定解除所有設施綁定?',
  'sws.facility.sure.bnoAll': '放棄操作',
  'sws.facility.sure.byesAll': '解除綁定',
  'sws.facility.unbind.bfacilityAll': '全部解綁',

  'sws.facility.station.belongs': '工位所屬設施',
  'sws.facility.facil.num': '設施編號：',
  'sws.facility.facil.type': '設施類型：',
  'sws.facility.facil.serial': '序列號：',
  'sws.facility.scan.binding': '繼續掃描設施編碼綁定工位信息～',
  'sws.facility.scan.first': '請先掃描工位或設施編碼～',

  'sws.facility.binding.scan': '掃碼工位綁定以下設施信息',
  'sws.facility.sure.bNo': '放棄操作',
  'sws.facility.sweep.location': '掃碼工位',

  'sws.facility.facil.anum': '設施編號：',
  'sws.facility.facil.aname': '設施名稱：',
  'sws.facility.facil.atype': '設施類型：',
  'sws.facility.facil.aserial': '序列號：',

  'sws.facility.whether.bind': '是否綁定設施',
  'sws.facility.no.bind': '放棄綁定',
  'sws.facility.yes.bind': '綁定設施',

  'sws.facility.facil.bnum': '設施編號：',
  'sws.facility.facil.bname': '設施名稱：',
  'sws.facility.facil.btype': '設施類型：',
  'sws.facility.facil.bserial': '序列號：',

  'sws.facility.whether.unBind': '是否解除設施綁定？',
  'sws.facility.no.unBind': '放棄操作',
  'sws.facility.yes.unBind': '解除綁定',

  'sws.facility.facil.cnum': '設施編號：',
  'sws.facility.facil.cname': '設施名稱：',
  'sws.facility.facil.ctype': '設施類型：',
  'sws.facility.facil.cserial': '序列號：',

  'sws.facility.location.location': '工位位置：',
  'sws.facility.location.num': '工位編號：',
  'sws.facility.location.depart': '工位部門：',
  'sws.facility.location.person': '使用人：',

  401316: '工位已綁定此類設施 ',
  401317: '設施不存在 ',
  401013: '此工位不存在 ',
};
