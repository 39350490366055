// 判断是不是IE浏览器
import React, { useCallback, useState,useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getLocale } from 'umi';
import shortid from 'shortid';

import {
  STATUS_DISABLED,
  STATUS_MOBILE_FREE,
  STATUS_OCCUPIED,
  SWS_DISPLAY_SETTING_ITEM,
  SENSOR_OCCUPIED,
  STATUS_MERGE_OCCUPY,
} from './constant';

import defaultAvatar from '@/assets/Workspace/user-haed.png';
import { Toast } from 'antd-mobile-v2';
import { zhIntl } from 'wafer-i18n-utils';

export const isIE = () => {
  const explorer = window.navigator.userAgent;
  return (
    explorer.indexOf('MSIE') >= 0 ||
    explorer.indexOf('Trident/7.0') >= 0 ||
    explorer.indexOf('Edge') >= 0
  );
};

// 将系统参数返回的list组装成对象
export const systemList2Obj = (systemList) => {
  let obj = {};
  systemList.forEach((v) => {
    obj = {
      ...obj,
      [v.paramName]: v.paramValue,
    };
  });
  // 工位预订跨天限制天数
  obj.reserveNormalLimitDay = obj.reserve_normal_limit_day;
  // 特殊预订跨天限制天数
  obj.reserveSpecialLimitDay = obj.reserve_special_limit_day;
  // 工位预订限制天数
  obj.reserveNormalOpenDay = obj.reserve_normal_open_day;
  // 特殊预订开放天数
  obj.reserveSpecialOpenDay = obj.reserve_special_open_day;
  return obj;
};

// 转换对象到数组
export const convertObjToArr = (obj) => {
  const arr = [];
  Object.keys(obj).forEach((v) => {
    arr.push(obj[v]);
  });
  return arr;
};

// 转换显示设置数组为对象
export const convertDisArrToObj = (arr) => {
  const ob = {};
  const disSetting = SWS_DISPLAY_SETTING_ITEM;
  arr.forEach((v) => {
    Object.keys(disSetting).forEach((o) => {
      if (disSetting[o].indexOf(v.nameSpace) !== -1) {
        ob[disSetting[o]] = v.color;
      }
    });
  });
  return ob;
};

// 通过工位状态显示元素颜色
export const getColorByStationStatus = (caseNum) => {
  let color = '';
  switch (caseNum) {
    case STATUS_DISABLED:
      color = 'disabled';
      break;
    case STATUS_OCCUPIED:
      color = 'busy';
      break;
    case STATUS_MOBILE_FREE:
      color = 'free';
      break;
    default:
      // 客户端的不可用状态为显示设置中：工位预订的固定工位颜色
      color = 'disabled';
      break;
  }
  return color;
};

// 转换显示设置数组为对象
export const getItemsColor$Text = (item, settingData) => {
  const text = item.userNames || item.stationNum;
  const contentObj = {};
  if (item.stationNum && Number(settingData[SWS_DISPLAY_SETTING_ITEM.stationNum]) === 1) {
    contentObj.stationNum = item.stationNum;
  }
  if (Number(settingData[SWS_DISPLAY_SETTING_ITEM.userName]) === 1 && item.userNames) {
    contentObj.userName = item.userNames;
  }
  if (
    Number(settingData[SWS_DISPLAY_SETTING_ITEM.userName]) === 1 &&
    item.userNames &&
    Number(item.stationType) === 2
  ) {
    contentObj.orgName = item.orgName;
  }
  const color = getColorByStationStatus(Number(item.statusCode));
  return { contentObj, color, text };
};

// 通过工位状态显示元素颜色
export const getColorDisplay = (caseNum, settingData) => {
  let color = '';
  switch (caseNum) {
    // 占用
    case STATUS_MERGE_OCCUPY:
      color = settingData[SWS_DISPLAY_SETTING_ITEM.stationOccupied];
      break;
    // 空闲
    default:
      // 最新statusCode状态，空闲：10，占用：11
      color = settingData[SWS_DISPLAY_SETTING_ITEM.stationFree];
      break;
  }
  return color;
};

// 转换显示设置数组为对象
// export const getItemsColorDisplay = (item, settingData) => {
//   let color = '';
//   const text = item.userNames || item.stationNum;
//   const contentObj = {};
//   if (item.stationNum && Number(settingData[SWS_DISPLAY_SETTING_ITEM.stationNum]) === 1) {
//     contentObj.stationNum = item.stationNum;
//   }
//   if (Number(settingData[SWS_DISPLAY_SETTING_ITEM.userName]) === 1 && item.userNames) {
//     contentObj.userName = item.userNames;
//   }
//   if (
//     Number(settingData[SWS_DISPLAY_SETTING_ITEM.userName]) === 1 &&
//     item.userNames &&
//     Number(item.stationType) === 2
//   ) {
//     contentObj.orgName = item.orgName;
//   }
//   color = getColorDisplay(Number(item.statusCode), settingData);
//   return { contentObj, color, text };
// };

// 定位标记图标
export const markSvg = () => {
  return (
    <g transform="translate(5, -5) scale(0.8)">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-mark">
          <stop stopColor="#F36F77" offset="0%" />
          <stop stopColor="#E93D4B" offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-532.000000, -633.000000)"
          fill="url(#linearGradient-mark)"
          fillRule="nonzero"
          stroke="#F3A29A"
        >
          <g transform="translate(55.000000, 623.000000)">
            <g transform="translate(473.000000, 0.000000)">
              <g>
                <path d="M27.0102531,11 C14.8531881,11 5,20.855181 5,32.9981718 C5,38.5882954 7.09817366,43.660815 10.5231336,47.5428454 C10.6157001,47.7188307 10.7391221,47.8948161 10.8831144,48.0500973 L25.1794937,63.0192062 C25.2514899,63.1123749 25.323486,63.2158957 25.4057673,63.2987124 C25.6217558,63.5161061 25.8685998,63.6713873 26.136014,63.7852602 C26.9588272,64.1682872 27.9564882,64.0440622 28.625024,63.3711769 C28.8101569,63.1848395 28.9541492,62.9674458 29.0570009,62.7397 L43.0859659,48.0500973 C43.2196731,47.9258723 43.3225247,47.7705911 43.4150912,47.6153099 C46.8811918,43.7332796 48.999968,38.6193517 48.999968,33.0085239 C49.0205061,20.855181 39.1673181,11 27.0102531,11 Z M26.9999679,41.9423696 C21.5282601,41.9423696 17.0850689,37.5013269 17.0850689,32.0354283 C17.0850689,26.5695296 21.5282601,22.1284869 26.9999679,22.1284869 C32.4716757,22.1284869 36.9148669,26.5695296 36.9148669,32.0354283 C36.9148669,37.5013269 32.4716757,41.9423696 26.9999679,41.9423696 L26.9999679,41.9423696 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  );
};

// 定位头像图标
export const markAvatarSvg = (avatar) => {
  return (
    <g transform="translate(2, 0) scale(0.4)">
      <image
        xlinkHref={avatar || defaultAvatar}
        width="100"
        height="100"
        clipPath="url(#clipPath)"
        preserveAspectRatio="xMidYMid slice" // 头像尺寸自适应
      />
      <clipPath id="clipPath">
        {' '}
        <circle cx="50" cy="50" r="50" />{' '}
      </clipPath>
    </g>
  );
};

// 导引线起始点
export const pathStartSvg = () => {
  return (
    <g transform="translate(-2, 3) scale(0.6)">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-start">
          <stop stopColor="#6FD2FB" offset="0%" />
          <stop stopColor="#1FB9F9" offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-59.000000, -646.000000)"
          fill="url(#linearGradient-start)"
          fillRule="nonzero"
          stroke="#5DE5FE"
        >
          <g id="编组-4" transform="translate(55.000000, 623.000000)">
            <g id="编组-3" transform="translate(0.000000, 24.000000)">
              <g id="定位">
                <path d="M27.0102531,0 C14.8531881,0 5,9.85518096 5,21.9981718 C5,27.5882954 7.09817366,32.660815 10.5231336,36.5428454 C10.6157001,36.7188307 10.7391221,36.8948161 10.8831144,37.0500973 L25.1794937,52.0192062 C25.2514899,52.1123749 25.323486,52.2158957 25.4057673,52.2987124 C25.6217558,52.5161061 25.8685998,52.6713873 26.136014,52.7852602 C26.9588272,53.1682872 27.9564882,53.0440622 28.625024,52.3711769 C28.8101569,52.1848395 28.9541492,51.9674458 29.0570009,51.7397 L43.0859659,37.0500973 C43.2196731,36.9258723 43.3225247,36.7705911 43.4150912,36.6153099 C46.8811918,32.7332796 48.999968,27.6193517 48.999968,22.0085239 C49.0205061,9.85518096 39.1673181,0 27.0102531,0 Z M26.9999679,30.9423696 C21.5282601,30.9423696 17.0850689,26.5013269 17.0850689,21.0354283 C17.0850689,15.5695296 21.5282601,11.1284869 26.9999679,11.1284869 C32.4716757,11.1284869 36.9148669,15.5695296 36.9148669,21.0354283 C36.9148669,26.5013269 32.4716757,30.9423696 26.9999679,30.9423696 L26.9999679,30.9423696 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  );
};

// 导引线结束点
export const pathEndSvg = () => {
  return (
    <g transform="translate(1, -1) scale(0.6)">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-end">
          <stop stopColor="#F36F77" offset="0%" />
          <stop stopColor="#E93D4B" offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-532.000000, -633.000000)"
          fill="url(#linearGradient-end)"
          fillRule="nonzero"
          stroke="#F3A29A"
        >
          <g transform="translate(55.000000, 623.000000)">
            <g transform="translate(473.000000, 0.000000)">
              <g>
                <path d="M27.0102531,11 C14.8531881,11 5,20.855181 5,32.9981718 C5,38.5882954 7.09817366,43.660815 10.5231336,47.5428454 C10.6157001,47.7188307 10.7391221,47.8948161 10.8831144,48.0500973 L25.1794937,63.0192062 C25.2514899,63.1123749 25.323486,63.2158957 25.4057673,63.2987124 C25.6217558,63.5161061 25.8685998,63.6713873 26.136014,63.7852602 C26.9588272,64.1682872 27.9564882,64.0440622 28.625024,63.3711769 C28.8101569,63.1848395 28.9541492,62.9674458 29.0570009,62.7397 L43.0859659,48.0500973 C43.2196731,47.9258723 43.3225247,47.7705911 43.4150912,47.6153099 C46.8811918,43.7332796 48.999968,38.6193517 48.999968,33.0085239 C49.0205061,20.855181 39.1673181,11 27.0102531,11 Z M26.9999679,41.9423696 C21.5282601,41.9423696 17.0850689,37.5013269 17.0850689,32.0354283 C17.0850689,26.5695296 21.5282601,22.1284869 26.9999679,22.1284869 C32.4716757,22.1284869 36.9148669,26.5695296 36.9148669,32.0354283 C36.9148669,37.5013269 32.4716757,41.9423696 26.9999679,41.9423696 L26.9999679,41.9423696 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  );
};

/**
 * 处理地图组件气泡提示
 * @param tipObj 气泡显示内容对象
 */
export const handleToolTip = (tipObj) => {
  console.log(tipObj,'tipObj')
  if (_.isEmpty(tipObj)) return null;
  const tipArr = Object.keys(tipObj);
  const radius = 5;
  const width = 150;
  const height = 30 + (tipArr.length - 1) * 20;
  return (
    <g>
      <path
        fill="black"
        fillOpacity="0.7"
        d={`M0 0 l10 10 h ${width / 2 - 10 - radius}
          a ${radius} ${radius} 90 0 1 ${radius} ${radius} v ${height}
          a ${radius} ${radius} 90 0 1 ${-radius} ${radius} h ${2 * radius - width}
          a ${radius} ${radius} 90 0 1 ${-radius} ${-radius} v ${-height}
          a ${radius} ${radius} 90 0 1 ${radius} ${-radius} h ${width / 2 - 10 - radius} Z`}
      />
      {tipArr.map((k, index) => {
        return (
          <text
            key={shortid.generate()}
            dy={`${15 + index * 20}`}
            dominantBaseline="text-before-edge"
            fill="white"
            textAnchor="middle"
          >
            {tipObj[k]}
          </text>
        );
      })}
    </g>
  );
};
export const handleToolTipGis = (tipObj) => {
  if (_.isEmpty(tipObj)) return null;
  const tipArr = Object.keys(tipObj);
  // const radius = 5;
  // const width = 150;
  // const height = 30 + (tipArr.length - 1) * 20;
  const arr=[]
  console.log(tipArr,'tipArr',tipObj)
  tipArr.forEach((k) => {
    arr.push({ name: '', value: tipObj[k] })
  });
  return arr
  // return (
  //   <g>
  //     <path
  //       fill="black"
  //       fillOpacity="0.7"
  //       d={`M0 0 l10 10 h ${width / 2 - 10 - radius}
  //         a ${radius} ${radius} 90 0 1 ${radius} ${radius} v ${height}
  //         a ${radius} ${radius} 90 0 1 ${-radius} ${radius} h ${2 * radius - width}
  //         a ${radius} ${radius} 90 0 1 ${-radius} ${-radius} v ${-height}
  //         a ${radius} ${radius} 90 0 1 ${radius} ${-radius} h ${width / 2 - 10 - radius} Z`}
  //     />
  //     {tipArr.map((k, index) => {
  //       return (
  //         <text
  //           key={shortid.generate()}
  //           dy={`${15 + index * 20}`}
  //           dominantBaseline="text-before-edge"
  //           fill="white"
  //           textAnchor="middle"
  //         >
  //           {tipObj[k]}
  //         </text>
  //       );
  //     })}
  //   </g>
  // );
};
/**
 * 处理创互地图组件气泡提示
 * @param station 气泡显示的工位信息
 */
export const showToolTip = (station, styles) => {
  if (!station) {
    return '';
  }
  return (
    <div className={styles.tooltip}>
      <div> {station.stationNum} </div>
      <div> {station.userName} </div>
      <div> {station.orgName} </div>
    </div>
  );
};

/**
 * 创建显示小人图标的方法
 * @param stationsOnMap 已经绑定的工位
 */
export const createMarkId = (stationsOnMap) => {
  if (_.isEmpty(stationsOnMap)) return '';
  const markId = [];
  stationsOnMap.forEach((v) => {
    if (Number(v.sensorStatus) === SENSOR_OCCUPIED) {
      markId.push(String(v.id));
    }
  });
  if (markId.length <= 1) return markId.join();
  return markId;
};

/**
 * 生成位置Options，获取mapId
 * @param mapIdSpaceTree 数据树
 * @returns {Array}
 */
export const recursionSpace2mapId = ({ spaceTree, selectable = false }) => {
  const treeData = [];
  if (spaceTree) {
    spaceTree.forEach((space) => {
      const isLeaf = true || space.spaceType === 'floor';
      const permission = space.isAuthFlag;
      if (permission && space.children && space.children.length > 0) {
        const floorAble = selectable || isLeaf;
        const able = floorAble && space.mapId;
        treeData.push({
          ...space,
          selectable: able,
          children: recursionSpace2mapId({ spaceTree: space.children, selectable: floorAble }),
        });
      } else if (permission) {
        const floorAble = selectable || isLeaf;
        const able = floorAble && space.mapId;
        treeData.push({ ...space, selectable: able });
      }
    });
  }
  return treeData;
};

/**
 * 通过控制字段查找树节点
 * @param tree 数据树
 * @param keyInItem 字段名
 * @returns {Object}
 */
export const findByRecursion = (tree, keyInItem) => {
  let rItem = {};
  const recursion = (treeTarget, key) => {
    treeTarget.forEach((item) => {
      if (item[key]) {
        rItem = item;
      } else {
        recursion(item.children, key);
      }
    });
  };
  if (tree) {
    recursion(tree, keyInItem);
  }
  return rItem;
};

/**
 * 通过控制字段查找树节点
 * @param tree 数据树
 * @param keyInItem 字段名
 * @returns {Object}
 */
export const findByRecursionId = (tree, locationId) => {
  let rItem = {};
  const recursion = (treeTarget, key) => {
    treeTarget.forEach((item) => {
      // 区域id一致， 叶子节点 item.structure === 2 有地图 Id
      if (item && item.id === locationId && item.mapId) {
        rItem = item;
        if (rItem.timeZone === '') {
          rItem.timeZone = 'Asia/Shanghai';
        }
      } else {
        recursion(item.children, key);
      }
    });
  };
  if (tree) {
    recursion(tree, locationId);
  }
  return rItem;
};

/**
 * 部分浏览器（ios）在页面加载完成后就不监听document.title的change事件了
 * 修改title之后，给页面加上一个内容为空的iframe，随后立即删除这个iframe，这时候会刷新title
 * @param title
 */
export const changeHtmlTitle = (title) => {
  document.title = title;
  // const i = document.createElement('iframe');
  // // i.src = "https://www.baidu.com/favicon.ico";
  // i.style.display = 'none';
  // i.onload = () => {
  //   setTimeout(() => {
  //     i.remove();
  //   }, 10);
  // };
  // document.body.appendChild(i);
};

/**
 * 视口的大小，部分移动设备浏览器对innerWidth的兼容性不好，需要
 * document.documentElement.clientWidth或者document.body.clientWidth
 * 来兼容（混杂模式下对document.documentElement.clientWidth不支持）。
 * 使用方法 ： getViewPort().width;
 */
export function getViewPort() {
  // 浏览器嗅探，混杂模式
  if (document.compatMode === 'BackCompat') {
    return {
      vw: document.body.clientWidth,
      vh: document.body.clientHeight,
    };
  }
  return {
    vw: document.documentElement.clientWidth,
    vh: document.documentElement.clientHeight,
  };
}

// 设施管理页面：绑定状态
export const MAP_TYPE = {
  wafer: 0,
  chuangve: 1,
  gis: 2,
};

/**
 *
 * @param date
 * @returns {*|moment.Moment}
 */
export const toMomentWithZone = (date, timeZone) => {
  const curTimeZone = timeZone;
  const offsetGMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const timezone = window.TIMEZONE?.find((e) => e.key === curTimeZone);
  if (timezone) {
    return moment(date.getTime() - (offsetGMT * 60 * 1000 + timezone.timezone * 60 * 60 * 1000));
  }
  return moment.tz(date.getTime(), timeZone);
};

/**
 * @desc moment 转 js 标准时间
 * @param momentDate
 * @returns {Date}
 */
export const toDateWithZone = (date, timeZone) => {
  const momentDate = moment(date)
  const curTimeZone = timeZone;
  const offsetGMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const TIMEZONE = window.TIMEZONE || [];
  const timezone = TIMEZONE.find((e) => e.key === curTimeZone);
  if (timezone) {
    return new Date(
      momentDate.valueOf() + offsetGMT * 60 * 1000 + timezone.timezone * 60 * 60 * 1000,
    );
  }
  return new Date(momentDate.valueOf());
};

/**
 * @desc date 对象 转 日期，即年月日
 * @param momentDate
 * @returns {Date}
 */
export const toDateWithDate = (date = new Date()) => {
  const year = date.getFullYear();
  const m = date.getMonth() + 1;
  const month = m < 10 ? `0${m}` : `${m}`;
  const d = date.getDate();
  const day = d < 10 ? `0${d}` : `${d}`;
  return `${year}-${month}-${day}`;
};

export const timeZoneI18n = (timeZone) => {
  const locale = getLocale();
  const I18N_TIMEZONE = window.I18N_TIMEZONE || {};
  const timeZoneObj = I18N_TIMEZONE[timeZone] || {};
  const i18n = timeZoneObj[locale] || '';
  return i18n;
};

export const isPhone6p = () => {
  const h = window.innerHeight;
  const w = window.innerWidth;
  const useragent = navigator.userAgent.toLowerCase();
  let isP6p = false;
  if (
    useragent.match(/mobile/i) !== null &&
    useragent.match(/iphone/i) !== null &&
    (h > w ? Math.abs(w - 414) < 10 && h <= 736 : Math.abs(w - 736) < 10 && h <= 414)
  ) {
    isP6p = true;
  }
  return isP6p;
};
/**
 * 判断按钮是否有权限
 * @param list 已拥有权限
 * @param name 按钮
 * @returns {boolean}
 */
export const btnPms = (list, name) => {
  let permissions = false;
  if (name && list) {
    permissions = list.some((item) => item === name);
  }
  return permissions;
};


/**
 * description: 获取首个有权限的节点ID---地图管理
 * treeData: 查找的数据源
 * 返回首个有权限的节点id
 */
export const searchPermissionByMap = ({ treeData }) => {
  const dataS = [];

  const searchPermission = ({ treeData, dataS = [] }) => {
    if (treeData) {
      treeData.forEach((v) => {
        if (Object.prototype.hasOwnProperty.call(v, 'isAuthFlag') && v.isAuthFlag && v.mapId) {
          return dataS.push(v);
        } else if (v.children) {
          searchPermission({ treeData: v.children, dataS });
        }
      });
    }
    return dataS;
  };

  if(treeData?.length > 0){
    searchPermission({treeData: treeData, dataS: dataS});
  }

  return dataS?.[0] || {}
};

// 过滤tree数组的isAuthFlag字段为true
export function getFilterSpaceTreePermission({ spaceTree }) {
  const treeData = [];
  if (spaceTree) {
    spaceTree.forEach((space) => {
      const permission = space.isAuthFlag;
      if (permission && space.children && space.children.length > 0) {
        treeData.push({
          ...space,
          children: getFilterSpaceTreePermission({ spaceTree: space.children }),
        });
      } else if (permission) {
        treeData.push({ ...space});
      }
    });
  }
  return treeData;
}
export const objectColorStatus = {
  disabled: 0,
  busy: 2,
  free: 3,
};
/**
 * find tree
 * @param {*} treeData
 * @param {*} callback
 * @param parent
 * @param childrenField
 */
export function findTree(
  treeData,
  callback,
  parent,
  childrenField = 'children',
) {
  let fondData = undefined;
  if (!treeData || !treeData.length) return fondData;

  function loopFind(loopData) {
    loopData.find((tree) => {
      if (fondData) return true;
      const isFound = callback(tree, parent);
      if (isFound) {
        fondData = tree;
        return true;
      }
      if (tree?.[childrenField]?.length) {
        return loopFind(tree[childrenField]);
      }
      return false;
    });
  }

  loopFind(treeData);
  return fondData;
}
/**
 * 格式化tree结构数据
 * @param {*} treeData
 * @param {*} callback
 * @param parent
 * @param childrenField
 */
export function loopTree(
  treeData,
  callback,
  parent,
  childrenField = 'children',
) {
  if (!treeData || !treeData.length) return treeData;
  return treeData.map((tree) => {
    const newData = callback(tree, parent);
    if (newData?.[childrenField]) {
      newData[childrenField] = loopTree(newData[childrenField], callback, newData, childrenField);
    }
    return newData;
  });
}
/**
 * 弹框
 * @param defaultVisible
 * @returns
 */
function usePopup(defaultVisible = false) {
  const [visible, setVisible] = useState(defaultVisible);

  const [initialValues, setInitialValues] = useState();
  const onOpen = useCallback((data) => {
    setVisible(true);
    setInitialValues(data);
  }, []);
  const onClose = useCallback(() => setVisible(false), []);
  return {
    visible,
    setVisible,
    onClose,
    onOpen,
    initialValues,
    setInitialValues,
    popupProps: {
      visible,
      onClose,
    },
  };
}

export default usePopup;

export function FormateSpaceTree(spaceTree) {
  // 用于options的数据，根据当前语言，获取对应字段
  const [treeData] = useMemo(() => {
    const formatMap = {};
    const formatTree= loopTree(spaceTree, (item) => {
      const formatItem = {
        ...item,
        label: item.name,
        value: item.id,
        disabled: !item.mapId,
        // antd mobile的Cascader组件对空数组也会可以点击，此处进行处理
        children: item.children?.length ? item.children : undefined,
      };
      formatMap[formatItem.value] = formatItem;
      return formatItem;
    });
    return [formatTree || [], formatMap];
  }, [spaceTree]);

  return {
    treeData
  };
}

/**
 * 判断地图id是否存在，不存在提示
 * @param mapId
 * @returns
 */
export function getIsHasMapId(mapId){
  if(!mapId){
    Toast.fail(zhIntl('工位状态异常，请联系管理员！'));
    return false
  }
  return true
}
export const formateUserName=(userName)=>{
  const result = userName?.replace(/\(.*?\)/, ""); // 替换掉括号及其内容
  return result || ''
};
