import globalHeader from './en-US/globalHeader';
import settings from './en-US/settings';
import lighting from './en-US/lighting';
import meeting from './en-US/meeting';
import error from './en-US/error';
import intl from './en-US/intl';
import openaiIntl from './en-US/openai-intl';

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.home.introduce': 'introduce',
  'app.system.initialization': 'System is loading, please wait...',
  'app.loading': 'Loading...',
  pageNotFound: 'Page Not Found',
  'go.index': 'Go Index',
  'user.currentUser.error':
    'The interface could not obtain user information, please try again later',
  'user.currentUser.error2': 'UserId is missing from user information, please try again later',
  ...globalHeader,
  ...settings,
  ...lighting,
  ...error,
  ...meeting,
  ...intl,
  // 加入 openai 翻译的语言包，默认覆盖 youdao 的翻译
  ...openaiIntl,
};
