export default {
  'attendee.search': 'Search by user name',
  'attendee.cancel': 'Cancel',
  'contact.button.select': 'Select',
  'contact.button.showMore': 'Show More',
  'contact.load.fail': 'Grant Virsical access to your mobile contact from "Setting"',
  'meeting.attendee.page.page.value1': 'There is no employee in this department!',

  frequent_concat: 'Frequent Contact Person ',
};
