import { parse, stringify } from 'qs';
import CryptoJS from 'crypto-js';
import { getLocale, formatMessage, history } from 'umi';
import _ from 'lodash';
import moment from 'moment-timezone';
import {
  INDEX_ROUTER,
  MENUDATA_KEY,
  RESTFUL_PATH,
  GETTOKENTIME,
  AUTHORITY,
  PASSWORD_AES_VI,
  RESOURCE_COLOR,
} from '@/utils/constant';
import { getDvaApp } from '@@/plugin-dva/exports';
import * as dingTalk from 'dingtalk-jsapi';

/**
 *
 * @param path
 * @returns {boolean}
 */
export function isUrl(path) {
  //判断URL地址的正则表达式为:http(s)?://([\w-]+\.)+[\w-]+(/[\w- ./?%&=]*)?
  //下面的代码中应用了转义字符"\"输出一个字符"/"
  const expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
  const objExp = new RegExp(expression);
  return objExp.test(path) === true;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * 基础服务认证加密 AES加密
 * @param data
 * @param aesKey
 * @param checkKey
 * @return {string}
 */
export const encryptAes = (data, aesKey, checkKey = true) => {
  if (!data?.trim()) {
    return '';
  }
  const aesKeyUsed = window.encryptStrength === '0' && checkKey ? PASSWORD_AES_VI : aesKey;
  const key = CryptoJS.enc.Utf8.parse(aesKeyUsed);
  const iv = CryptoJS.enc.Utf8.parse(PASSWORD_AES_VI);
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString(); // 返回的是base64格式的密文
};

/**
 * 基础服务认证解密 AES解密
 * @param data
 * @param aesKey
 * @return {string}
 */
export const decryptAes = (data, aesKey, count = 0) => {
  if (!data) {
    return '';
  }

  const curCount = count + 1;
  if(curCount === 3){
    // curCount为解密次数，1为256，2为128，3为两次解密失败，返回原值
    return data;
  }

  // PASSWORD_AES_VI 为偏移量，且为128加解密的秘钥，解密先用256解，未成功则用128

  /* 密钥 */
  const key = CryptoJS.enc.Utf8.parse(aesKey);

  /* 为密钥偏移量 */
  const iv = CryptoJS.enc.Utf8.parse(PASSWORD_AES_VI);

  let decrypted = '';
  let decryptedStr = '';
  try {
    decrypted = CryptoJS.AES.decrypt(data, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    try {
      decrypted?.toString?.(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log('AES解密decrypted失败:', error);
      return decryptAes(data, PASSWORD_AES_VI, curCount);
    }
  } catch (error) {
    console.log('AES解密失败:', error);
    return decryptAes(data, PASSWORD_AES_VI, curCount);
  }

  try {
    decryptedStr = CryptoJS.enc.Utf8.stringify(decrypted); // 返回的是base64格式的明文
    // 拿到明文去加密，校验可逆性
    if (data !== encryptAes(decryptedStr?.toString(), aesKey, false)) {
      // 加解密不可逆，说明256解密后明文为非法字符串，再次使用128加密
      console.log('AES256解密字符串不可逆');
      return decryptAes(data, PASSWORD_AES_VI, curCount);
    }
  } catch (error) {
    console.log('AES解密toString失败:', error);
    return decryptAes(data, PASSWORD_AES_VI, curCount);
  }

  if (!decryptedStr) {
    console.log('256失败,转128, decryptedStr： ', decryptedStr);
    return decryptAes(data, PASSWORD_AES_VI, curCount);
  }

  return decryptedStr?.toString(); // 返回的是base64格式的密文
};

const closest = (el, selector) => {
  const matchesSelector =
    el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    // eslint-disable-next-line no-param-reassign
    el = el.parentElement;
  }
  return null;
};

export const onWrapTouchStart = (e) => {
  // fix touch to scroll background page on iOS
  if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
    return;
  }
  const pNode = closest(e.target, '.am-modal-content');
  if (!pNode) {
    e.preventDefault();
  }
};

// 检查form错误
export const checkFormError = (key, error) => {
  if (Object.prototype.hasOwnProperty.call(error, key)) {
    const {
      errors: [item],
    } = error[key];
    return item.message;
  }
  return false;
};

// 设置cookie
export const setCookie = (name, value, expDays) => {
  const expDate = new Date();
  // 设置Cookie过期日期
  expDate.setDate(expDate.getDate() + expDays);
  // 添加Cookie
  document.cookie = `${name}=${escape(value)};path=/;expires=${expDate.toUTCString()}`;
};

// 读取cookie
export const getCookie = (name) => {
  // 获取name在Cookie中起止位置
  let start = document.cookie.indexOf(`${name}=`);
  if (start !== -1) {
    start = start + name.length + 1;
    // 获取value的终止位置
    let end = document.cookie.indexOf(';', start);
    if (end === -1) end = document.cookie.length;
    // 截获cookie的value值,并返回
    return unescape(document.cookie.substring(start, end));
  }
  return '';
};

// 删除cookie
export const delCookie = (name) => {
  setCookie(name, '', -1);
};

// 获取URL地址的参数值。
// name为URL参数名
// 例如：?param1=abc&param2=123
// 当调用getUrlParam("param2"）时，获取到的值为：123
export const getUrlParam = (name) => {
  const regExp = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = window.location.search.substr(1).match(regExp);
  if (r !== null) return r[2];
  return null;
};

/**
 * 判断是否在飞书应用中打开
 *
 * @returns {boolean}
 */
const isRunningInFeishuIOS = () => {
  var userAgent = navigator.userAgent.toLowerCase();
  // iOS端的飞书User-Agent通常包含特定的字符串，如"lark"或"feishu"
  // 注意：这些标识符可能会随着飞书应用的更新而变化
  return /lark|feishu/.test(userAgent) && /iphone|ipad|ipod/.test(userAgent);
};

/**
 * 判断是否在钉钉中打开
 */
const isInDingTalk = () => {
  var userAgent = navigator.userAgent;
  return /DingTalk/i.test(userAgent);
};

// 判断是不是iPhoneX
export const isIphoneX = () => {
  return true;
  // if (isInDingTalk()) {
  //   return false;
  // }
  // if (typeof window !== 'undefined' && window) {
  //   return /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
  // }
  // if (isRunningInFeishuIOS()) {
  //   return true;
  // }
  // return false;
};

/**
 * 获取页面title
 * @param indexPageInfo
 */
export const getTitle = (indexPageInfo) => {
  const locale = localStorage.getItem('umi_locale');
  let systemsName = indexPageInfo['main-nameCn'];
  if (locale === 'zh-TW') {
    systemsName = indexPageInfo['main-nameTw'];
  } else if (locale === 'en-US') {
    systemsName = indexPageInfo['main-nameEn'];
  } else if (locale === 'zh-CN') {
    systemsName = indexPageInfo['main-nameCn'];
  }
  return systemsName;
};
/**
 * @param len
 * @param radix
 * @returns {string}
 */
export const generateUUID = (len, radix = null) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  let i;
  const base = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; ) {
      // eslint-disable-next-line no-bitwise
      uuid[i] = chars[0 | (Math.random() * base)];
      i += 1;
    }
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    // eslint-disable-next-line no-multi-assign
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; ) {
      if (!uuid[i]) {
        // eslint-disable-next-line no-bitwise
        r = 0 | (Math.random() * 16);
        // eslint-disable-next-line no-bitwise
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
      i += 1;
    }
  }
  return uuid.join('');
};

/**
 * @desc moment 转 js 标准时间
 * @param momentDate
 * @returns {Date}
 */
export const toDateWithZone = (momentDate) => {
  const curTimeZone = sessionStorage.getItem('curTimeZone');
  const offsetGMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const timezone = window.TIMEZONE?.find((e) => e.key === curTimeZone);
  if (timezone) {
    return new Date(
      momentDate.valueOf() + offsetGMT * 60 * 1000 + timezone.timezone * 60 * 60 * 1000,
    );
  }
  return new Date(momentDate.valueOf());
};

/**
 * @desc js date 转 moment
 * @param date
 * @returns {moment}
 */
export const toMomentWithZone = (date) => {
  const curTimeZone = sessionStorage.getItem('curTimeZone');
  const offsetGMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const timezone = window.TIMEZONE?.find((e) => e.key === curTimeZone);
  const difference = offsetGMT * 60 * 1000 + timezone.timezone * 60 * 60 * 1000;
  if (timezone) {
    return moment(date.getTime() - difference);
  }
  return moment(date.getTime());
};

/**
 * 将以base64的图片url数据转换为Blob
 * @param urlData
 *  用url方式表示的base64图片数据
 */
export const convertBase64UrlToBlob = (urlData) => {
  const bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte

  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i += 1) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/png' });
};

// 数字显示规范
export const formatNumberRgx = (number) => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

// 积分单位显示
export const formatPointsUnit = () => {
  if (!window.pointsUnit) {
    return formatMessage({ id: 'points.unit' });
  }

  const pointsUnit = _.clone(window.pointsUnit);
  return pointsUnit[`${getLocale()}`];
};

// 积分名称显示
export const formatPointsName = () => {
  if (!window.pointsUnit) {
    return formatMessage({ id: 'points.name' });
  }

  const pointsName = _.clone(window.pointsName);
  return pointsName[`${getLocale()}`];
};

export const isContained = (aa, bb) => {
  if (!(aa instanceof Array) || !(bb instanceof Array) || aa.length < bb.length) {
    return false;
  }
  for (let i = 0; i < bb.length; i += 1) {
    let flag = false;
    for (let j = 0; j < aa.length; j += 1) {
      if (aa[j] === bb[i]) {
        flag = true;
        break;
      }
    }
    if (flag === false) {
      return flag;
    }
  }

  return true;
};

export const goHome = () => {
  let redirect = localStorage.getItem('h');
  if (redirect && redirect !== 'null') {
    redirect = window.atob(localStorage.getItem('h'));
    // 重定向地址是完整地址的，进行href替换
    if (redirect.startsWith('http')) {
      window.location.href = redirect;
    } else if (redirect !== 'null') {
      history.replace(redirect);
    } else {
      history.replace(INDEX_ROUTER);
    }
  } else {
    history.replace(INDEX_ROUTER);
  }
};

// 判断是不是钉钉
export const isDingTalk = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('dingtalk') > -1;
};

/**
 * 判断是否在飞书应用中打开
 */
export const isFeiShu = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('feishu') > -1;
};

/**
 *
 * 判断是否在teams中打开
 */
export const isTeams = () => {
  return (
    sessionStorage.getItem('entrySource') === 'teams' ||
    navigator.userAgent.toLowerCase().indexOf('teams') > -1
  );
};

/**
 *
 * 判断是否在企业微信 以及。。
 */
const Browser = ['micromessenger'];
export const getWxBrowserAndIphoneX = () => {
  const flag = isIphoneX();
  if (!flag) {
    return false;
  }
  const ua = navigator.userAgent.toLowerCase();
  for (let i = 0; i < Browser.length; i++) {
    const element = Browser[i];
    if (ua.includes(element)) {
      return true;
    }
  }
  return false;
};

/*
 * 查找字符串中某字符第n次出现的位置
 * @param (*) str 某字符串
 * @param (*) char 某字符
 * @param (*) num 第n次出现的位置
 * @return 索引位置
 */
export const findCharIndex = (str, char, num) => {
  let index = str.indexOf(char);
  for (let i = 0; i < num - 1; i += 1) {
    index = str.indexOf(char, index + 1);
  }
  return index;
};

export const initMapElementData = (mapData) => {
  const mapElements = [];
  mapData.forEach((data) => {
    // if (data.svgId === 0) {
    const obj = data;
    obj.viewBox = data.viewBox;
    obj.svgWidth = Number(obj.customElementWidth);
    obj.svgHeight = Number(obj.customElementHeight);
    obj.centerx = Number(obj.stationx) + Number(obj.svgWidth);
    obj.centery = Number(obj.stationy) + Number(obj.svgHeight);
    obj.axisx = 50;
    obj.axixy = 50;
    obj.fontSize = 12;
    obj.stationx = Number(obj.stationx);
    obj.stationy = Number(obj.stationy);
    obj.cycleValue = Number(obj.cycleValue);
    obj.direction = Number(obj.direction);
    mapElements.push(obj);
    // }
  });

  return mapElements;
};

export const blobToBase64 = (blob, callback) => {
  const a = new FileReader();
  a.onload = (e) => {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
};

/**
 * @word 要加密的内容
 * @keyWord String  服务器随机返回的关键字
 *  */
export function aesEncrypt(word, keyWord = 'XwKsGlMcdPMEhR1B') {
  let key = CryptoJS.enc.Utf8.parse(keyWord);
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

/**
 * 滑块验证码解密 AES解密
 * @param data
 * @param aesKey
 * @return {string}
 */
export const aesDecrypt = (data, aesKey) => {
  if (!data) {
    return '';
  }
  /* 密钥 */
  const key = CryptoJS.enc.Utf8.parse(aesKey);

  /* 为密钥偏移量 */
  const iv = CryptoJS.enc.Utf8.parse(PASSWORD_AES_VI);

  const decrypted = CryptoJS.AES.decrypt(data, key, {
    iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypted).toString(); // 返回的是base64格式的密文
};

// url 参数解析去掉空格
export const sendUrlTrim = (url) => {
  if (url.indexOf('?') > -1) {
    const path = url.slice(0, url.indexOf('?'));
    const newParams = stringify(parse(url.substr(url.indexOf('?') + 1)), {
      encodeValuesOnly: true,
      encoder: (v) => {
        return encodeURIComponent(v.trim());
      },
    });
    if (newParams.length) {
      return `${path}?${newParams}`;
    }
  }
  return url;
};

/*
 * 第三方认证
 */
export const thirdAuth = (appId) => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  localStorage.removeItem('wechatAuth');
  let reloadURL = `${window.location.origin}/${RESTFUL_PATH.wdf}/wdfClient/feishu/app?appId=${appId}`;
  if (userAgent.indexOf('micromessenger') !== -1) {
    reloadURL = `${window.location.origin}/${RESTFUL_PATH.wdf}/wdframe/wechat/agent?aId=${appId}`;
  } else if (userAgent.indexOf('dingtalk') !== -1) {
    reloadURL = `${window.location.origin}/${RESTFUL_PATH.wdf}/wdfClient/dingding/app?appId=${appId}`;
  }
  window.location.href = reloadURL;
};

/**
 * 接口增加permission参数权限控制
 * 目前仅做了工位逻辑，其他产品需要可修改过滤条件
 * @param {*} api
 * @param {*} option
 * @returns { path, option }
 */
export const apiAddPermission = (api, option) => {
  let menus = null;
  if (window[MENUDATA_KEY]) {
    menus = window[MENUDATA_KEY];
  } else if (sessionStorage.getItem(MENUDATA_KEY)) {
    menus = JSON.parse(base64Decode(sessionStorage.getItem(MENUDATA_KEY)));
    window[MENUDATA_KEY] = menus;
  }

  // 过滤条件
  if (
    !menus ||
    !(
      // 工位接口前缀标识
      (
        api.includes(RESTFUL_PATH.sws) ||
        api.includes(RESTFUL_PATH.lease) ||
        api.includes(RESTFUL_PATH.locker)
      )
    ) ||
    (option?.method && option?.method?.toLowerCase?.() !== 'get')
  ) {
    return { path: api, option };
  }

  // 解析当前api
  const [apiPath, apiParams] = api.split('?');

  // 如果已经设置过permission则原样返回
  if (
    (apiParams && apiParams.includes('permission=')) ||
    (option?.params && option?.params?.['permission'])
  ) {
    return { path: api, option };
  }

  // 解析当前url
  let path = location?.href?.split('?')?.[0];
  /* const _p = parse(location?.href?.split('?')?.[1] || '')._p
  if (_p) {
    path += `?_p=${_p}`
  } */

  // 根据当前URL在menu数据里找对应的菜单数据
  const findPermissionByMenus = (menuData, path) => {
    let findMenuItem = null;
    const handle = (menuData, path) => {
      for (const menu of menuData) {
        if (path.endsWith(menu.path?.split('?')?.[0] || 'none')) {
          findMenuItem = menu;
          return;
        } else if (menu.children) {
          handle(menu.children, path);
        }
      }
    };
    handle(menuData, path);
    return findMenuItem;
  };

  const findMenu = findPermissionByMenus(menus, path);

  // 没找到对应的菜单数据则原样返回
  if (!findMenu) {
    return { path: api, option };
  }

  // 如果通过params传参 在params拼接permission
  if (option?.params) {
    option.params.permission = findMenu?.permission;
    return { path: api, option };
  }

  // 如果通过url传参 在url拼接permission
  if (apiParams) {
    return {
      path: `${apiPath}?${apiParams}&permission=${findMenu?.permission}`,
      option,
    };
  }

  return {
    path: `${apiPath}?permission=${findMenu?.permission}`,
    option,
  };
};

export function base64Encode(str) {
  return btoa(unescape(encodeURIComponent(str)));
}

export function base64Decode(str) {
  return decodeURIComponent(escape(atob(str)));
}

export function getTokenByRefreshToken() {
  if (window.tokenTime) {
    clearTimeout(window.tokenTime);
  }
  try {
    // 刷新token处理
    const failTime = JSON.parse(sessionStorage.getItem('failTime'));
    if (!failTime) {
      return;
    }
    const timeDiff = (failTime - moment(new Date()).valueOf()) / 1000;
    window.tokenTime = setTimeout(() => {
      let authority;
      const userAgent = window.navigator.userAgent.toLowerCase();
      authority = localStorage.getItem(AUTHORITY);
      if (userAgent.indexOf('lark') !== -1) {
        authority = localStorage.getItem('authorityLark');
      }
      const auth = JSON.parse(decodeURIComponent(window.atob(authority)));

      getDvaApp()._store.dispatch({
        type: 'login/refreshByToken',
        payload: {
          grant_type: 'refresh_token',
          refresh_token: auth.refresh_token,
        },
      });
    }, (timeDiff - GETTOKENTIME) * 1000);
  } catch (err) {
    window.console.log(err);
  }
}

export function getPasswordPa() {
  let indexPageInfo = sessionStorage.getItem('ipiApp');
  if (indexPageInfo) {
    const tmp = JSON.parse(decodeURIComponent(window.atob(indexPageInfo)));
    if (tmp?.pwdRegex) {
      return {
        pwdRegex: tmp?.pwdRegex,
        pwdTipEn: tmp?.pwdTipEn,
        pwdTipZh: tmp?.pwdTipZh,
        pwdTipTw: tmp?.pwdTipTw,
      };
    }
  }

  return null;
}

/**
 *
 * 根据userId获取颜色值
 */
export const getUserColorByUserId = (userId) => {
  return RESOURCE_COLOR[userId % 16];
};


export const formatStringNoTrim = (payload) => {
  let params = {};
  for (let key in payload) {
    if (typeof payload[key] === 'string' && payload[key]) {
      // 未转化的对象不作处理
      params[key] = payload[key].trim();
    } else if (typeof payload[key] !== 'object') {
      // 未转化的对象不作处理
      params[key] = payload[key];
    } else if (
      key === 'startTime' ||
      key === 'endTime' ||
      key?.indexOf('StartTime') > 1 ||
      key?.indexOf('EndTime') > 1
    ) {
      params[key] = payload[key].valueOf();
    }
  }
  return params;
};

/**
 * 获取文件MD5值
 * @param {*} fileName
 * @param {*} url
 */
export const getFileMD5 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const arrayBufferView = event.target.result;
      const wordArray = CryptoJS.lib.WordArray.create(arrayBufferView);
      const md5Hash = CryptoJS.MD5(wordArray).toString();

      resolve(md5Hash);
    }

    reader.onerror = function (event) {
      reject('Fileed to read file');
    }

    reader.readAsArrayBuffer(file);
  })
}

// 组装MD5和后缀
export const getFileIDByMd5AndSuffix = (md5, fileName) => {
  // 文件名使用.截取，最后一个为后缀
  return `${md5}.${fileName?.split('.')?.pop()}`;
}

// 校验文件是否存在
export const checkFileExist = (dispatch, payload) => {
  return new Promise((resolve) => {
    dispatch({
      type: 'global/getFileCheck',
      payload: {...payload},
    }).then((res)=>{
      resolve(res.data);
    })
  });
}

// 文件校验整合方法
export function checkFileExistAndUpload(payload){
  let isUpload = true;
  let resData = null;
  const {file, businessCode, serviceName, service, dispatch, fileMD5} = payload;
  const params = {
    payload: {
      fileId: getFileIDByMd5AndSuffix(fileMD5, file.name),
      fileName: file.name,
      pubOrNot: true,
      productCode: 'w',
      businessCode: businessCode,
      serviceName: serviceName,
    },
    service: service,
  };

  return new Promise((resolve) => {
    checkFileExist(dispatch, params).then((res) => {
      if(res){
        resData = res;
        isUpload = false;
      }
      resolve({resData, isUpload});
    })
  });
  // await checkFileExist(dispatch, params).then((res)=>{
  //   if(res){
  //     resData = res;
  //     isUpload = false;
  //   }
  // })

 // return {isUpload, resData};
}

/**
 * 钉钉title不更新问题处理函数
 */
export async function dingTalkTitleHandler() {
  if (isDingTalk()) {
    await new Promise(r=>setTimeout(r, 500));
    const title = document?.title || '';
    // 使用钉钉的SDK的api设置title
    dingTalk?.biz?.navigation?.setTitle?.(title);
  }
} 