export default {
  checkIn_index_tip1: '輸入訪客登記資訊，完成訪客簽到',
  checkIn_index_tip2: '受邀請的訪客，根據收到的邀請碼完成簽到',
  checkIn_invite_tip1: '請輸入完整的邀請碼',
  checkIn_invite_tip2: '請輸入純數字的邀請碼',
  checkIn_invite_tip3: '請輸入邀請碼',
  checkIn_result: '操作結果',
  checkIn_host_tip1: '請確認被訪人電話',
  checkIn_NDA_tip1: '請勾選同意保密協議',
  checkIn_people_tip1: '請輸入訪問人數',
  checkIn_people_tip2: '訪問人數為1到10人',
  checkIn_people: '訪問人數',
  checkIn_host_phone: '拜訪對象電話',
  checkIn_no_host_phone: '無拜訪人電話',
  checkIn_NDA_tip2: '已閱讀並同意',
  checkIn_NDA: '《保密協議》',
  checkIn_login_error: '請聯繫管理員檢查默認配置',
  checkIn_success: '訪客登記成功',
  checkIn_fail: '訪客登記失敗',
  checkIn_sign_fail: '邀請簽到失敗',
  checkIn_sign_success: '邀請簽到成功',
  checkIn_photo_success: '拍照成功',
  checkIn_photo_fail: '拍照失敗',
};
