export default {
  'subscribe.roomlist.select.singleChoice': '只支持单选',
  'room.selector.atLeastOne': '请至少选择一个会议室',
  'room.selector.webex.onlyOne': '请最多选择一个WebEx会议室',
  'meeting.calendar.room.tag.share': '共享',
  'meeting.tencent.people': '人',
  'meeting.roomlist.delete.modal.title': '当前会议室已经添加了参会人，删除会议室之后您希望怎么处理这些参会人？',
  'modal.roomlist.delete.tran': '移动至',
  'modal.roomlist.delete': '直接删除',
};
