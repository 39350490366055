// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/jenkins/workspace/virsical/h5-app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/index.html",
    "redirect": "/user/login",
    "exact": true
  },
  {
    "path": "/",
    "redirect": "/user/login",
    "exact": true
  },
  {
    "path": "/room/evaluate",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__Evaluate' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Evaluate'), loading: LoadingComponent}),
    "title": "meeting.evaluate.title",
    "exact": true
  },
  {
    "path": "/user",
    "routes": [
      {
        "path": "/user/authorize",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Login' */'/home/jenkins/workspace/virsical/h5-app/src/pages/User/Login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Login' */'/home/jenkins/workspace/virsical/h5-app/src/pages/User/Login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/forgot-password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__ForgotPassword' */'/home/jenkins/workspace/virsical/h5-app/src/pages/User/ForgotPassword'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/send-success",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__SendEmailSuccess' */'/home/jenkins/workspace/virsical/h5-app/src/pages/User/SendEmailSuccess'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/auth2FA",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__FAAuthLogin' */'/home/jenkins/workspace/virsical/h5-app/src/pages/User/FAAuthLogin'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/teamsSSO",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WdfClient__TeamsLogin' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/TeamsLogin'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/init",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SystemInitialization' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SystemInitialization'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/scan-auth",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScanAuth' */'/home/jenkins/workspace/virsical/h5-app/src/pages/ScanAuth'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/delay",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Delay' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Delay'), loading: LoadingComponent}),
    "title": "menu.workspace.user",
    "exact": true
  },
  {
    "path": "/confirmLink",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Sensor' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Sensor'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/token_login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TokenLogin' */'/home/jenkins/workspace/virsical/h5-app/src/pages/TokenLogin'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/pc_token_login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TokenLogin' */'/home/jenkins/workspace/virsical/h5-app/src/pages/TokenLogin'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "title": "router.title.HomePage",
    "path": "/index",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HomePage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/HomePage'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "title": "router.title.HomePage",
    "path": "/indexNew",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HomePageNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/HomePageNew'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/feishuHomePage",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__FeiShuHomePage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/FeiShuHomePage'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "title": "router.title.MyInfo",
    "path": "/myInfo",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyInfo' */'/home/jenkins/workspace/virsical/h5-app/src/pages/MyInfo'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/Inform",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Inform' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Inform'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/setPassword",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setPassword' */'/home/jenkins/workspace/virsical/h5-app/src/pages/setPassword'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/SetPhone",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SetPhone' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SetPhone'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/setSetting",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setSetting' */'/home/jenkins/workspace/virsical/h5-app/src/pages/setSetting'), loading: LoadingComponent}),
    "title": "router.title.setting",
    "exact": true
  },
  {
    "path": "/setLanguage",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SetLanguage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SetLanguage'), loading: LoadingComponent}),
    "title": "router.title.setting.lang",
    "exact": true
  },
  {
    "path": "/setTimezone",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SetTimezone' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SetTimezone'), loading: LoadingComponent}),
    "title": "router.title.setting.timezone",
    "exact": true
  },
  {
    "path": "/setDefaultZone",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SetDefaultZone' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SetDefaultZone'), loading: LoadingComponent}),
    "title": "router.title.setting.zone",
    "exact": true
  },
  {
    "path": "/feedback",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Feedback' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Feedback'), loading: LoadingComponent}),
    "title": "router.title.setting.zone",
    "exact": true
  },
  {
    "path": "/setPassword",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setPassword' */'/home/jenkins/workspace/virsical/h5-app/src/pages/setPassword'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/index-map",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IndexMap' */'/home/jenkins/workspace/virsical/h5-app/src/pages/IndexMap'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/settingPage",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IndexMap__components__SettingPage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/IndexMap/components/SettingPage'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/cloud_scanAuth",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WaferTokenLogin' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WaferTokenLogin'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/cloud/registration",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cloud__Register' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/Register'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/cloud/bind",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cloud__Register' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/Register'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/cloud/expiredPage",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cloud__ExpiredPage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/ExpiredPage'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/cloud/downloadHelp",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cloud__DownloadHelp' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/DownloadHelp'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/envMonitor/dashboard",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cloud__Env__BasicEdition' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/Env/BasicEdition'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/envMonitor/history",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cloud__Env__PremiumEdition' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/Env/PremiumEdition'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/backgroundManagement",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cloud__BackgroundManagement' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/BackgroundManagement'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/scanAuthPage",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScanAuthPage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/ScanAuthPage'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/cloud/privacy",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cloud__PrivacyAndService' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/PrivacyAndService'), loading: LoadingComponent}),
    "title": "cloud.privacy.agreement",
    "exact": true
  },
  {
    "path": "/cloud/service",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cloud__PrivacyAndService' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/PrivacyAndService'), loading: LoadingComponent}),
    "title": "cloud.service.terms",
    "exact": true
  },
  {
    "path": "/workspace",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/workspace/indexPage1",
        "redirect": "/workspace/dashboard",
        "exact": true
      },
      {
        "path": "/workspace/indexPage2",
        "redirect": "/workspace/dashboard",
        "exact": true
      },
      {
        "path": "/workspace/qrcode",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Scan' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Scan'), loading: LoadingComponent}),
        "title": "menu.workspace.user",
        "exact": true
      },
      {
        "path": "/workspace/scanResult",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Scan__ScanResult' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Scan/ScanResult'), loading: LoadingComponent}),
        "title": "menu.workspace.user",
        "exact": true
      },
      {
        "path": "/workspace/scanSuccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Scan__scanSuccess' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Scan/scanSuccess'), loading: LoadingComponent}),
        "title": "menu.workspace.user",
        "exact": true
      },
      {
        "path": "/workspace/common/components/bookingResult",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Common__components__BookingResult' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Common/components/BookingResult'), loading: LoadingComponent}),
        "title": "menu.workspace.user",
        "exact": true
      },
      {
        "path": "/workspace/dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Dashboard' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Dashboard'), loading: LoadingComponent}),
        "title": "menu.workspace.user",
        "exact": true
      },
      {
        "path": "/workspace/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__DashboardNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/DashboardNew'), loading: LoadingComponent}),
        "title": "menu.workspace.user",
        "exact": true
      },
      {
        "path": "/workspace/station",
        "routes": [
          {
            "path": "/workspace/station/booking",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Booking' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Booking'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "routes": [
              {
                "path": "/workspace/station/booking/page",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Booking__page' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Booking/page'), loading: LoadingComponent}),
                "title": "menu.workspace.user",
                "exact": true
              },
              {
                "path": "/workspace/station/booking/locationTime",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Booking__components__locationTime' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Booking/components/locationTime'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              }
            ]
          },
          {
            "path": "/workspace/station/bookingNew",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__BookingNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/BookingNew'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "routes": [
              {
                "path": "/workspace/station/bookingNew/page",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__BookingNew__page' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/BookingNew/page'), loading: LoadingComponent}),
                "title": "menu.workspace.user",
                "exact": true
              },
              {
                "path": "/workspace/station/bookingNew/locationTime",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__BookingNew__components__locationTime' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/BookingNew/components/locationTime'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              },
              {
                "path": "/workspace/station/bookingNew/bindUser",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__BookingNew__BindUser' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/BookingNew/BindUser'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              },
              {
                "path": "/workspace/station/bookingNew/externalContact",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__BookingNew__ExternalContact' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/BookingNew/ExternalContact'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              },
              {
                "path": "/workspace/station/bookingNew/searchSpecialUserList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__BookingNew__SearchSpecialUserList' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/BookingNew/SearchSpecialUserList'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              },
              {
                "path": "/workspace/station/bookingNew/searchSpecialOutUserList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__BookingNew__SearchSpecialOutUserList' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/BookingNew/SearchSpecialOutUserList'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              }
            ]
          },
          {
            "path": "/workspace/station/myBooking",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__MyBooking' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/MyBooking'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/myBookingNew",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__MyBookingNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/MyBookingNew'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/delay",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Delay' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Delay'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/repair",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Repair' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Repair'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/repair/repairDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Repair__RepairDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Repair/RepairDetail'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/repair/searchNum",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Repair__SearchNum' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Repair/SearchNum'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/search",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Search' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Search'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/nav/searchNav",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Search__search-nav' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Search/search-nav'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/line/searchLine",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Search__search-line' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Search/search-line'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/findStation",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__FindStation' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FindStation'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/searchNew",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__SearchNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/SearchNew'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/line/searchLineNew",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__SearchNew__search-line' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/SearchNew/search-line'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/special",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Special' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "routes": [
              {
                "path": "/workspace/station/special/page",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Special__page' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/page'), loading: LoadingComponent}),
                "title": "menu.workspace.user",
                "exact": true
              },
              {
                "path": "/workspace/station/special/selectAreaDate",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Special__compontents__SelectAreaDate' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/compontents/SelectAreaDate'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              },
              {
                "path": "/workspace/station/special/bindUser",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Special__BindUser' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/BindUser'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              },
              {
                "path": "/workspace/station/special/cancelSpecial",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Special__cancelSpecial' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/cancelSpecial'), loading: LoadingComponent}),
                "title": "menu.workspace.user",
                "exact": true
              },
              {
                "path": "/workspace/station/special/searchSpecialUserList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Special__SearchSpecialUserList' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/SearchSpecialUserList'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              },
              {
                "path": "/workspace/station/special/externalContact",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Special__ExternalContact' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/ExternalContact'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              },
              {
                "path": "/workspace/station/special/searchSpecialOutUserList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Special__SearchSpecialOutUserList' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/SearchSpecialOutUserList'), loading: LoadingComponent}),
                "title": "router.default.title",
                "exact": true
              }
            ]
          },
          {
            "path": "/workspace/station/immediate",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Immediate' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Immediate'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/signOut",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__SignOut' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/SignOut'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/repairManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__RepairManage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RepairManage'), loading: LoadingComponent}),
            "title": "router.default.title",
            "exact": true
          },
          {
            "path": "/workspace/station/repairManageDetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__RepairManage__RepairManageDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RepairManage/RepairManageDetail'), loading: LoadingComponent}),
            "title": "router.default.title",
            "exact": true
          },
          {
            "path": "/workspace/station/facilityManage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__FacilityManage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FacilityManage'), loading: LoadingComponent}),
            "title": "router.default.title",
            "exact": true
          },
          {
            "path": "/workspace/station/facilityManage/searchNum",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__FacilityManage__SearchNum' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FacilityManage/SearchNum'), loading: LoadingComponent}),
            "title": "router.default.title",
            "exact": true
          },
          {
            "path": "/workspace/station/inventory",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Inventory' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Inventory'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/inventory/searchNum",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Inventory__SearchInventoryNum' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Inventory/SearchInventoryNum'), loading: LoadingComponent}),
            "title": "router.default.title",
            "exact": true
          },
          {
            "path": "/workspace/station/inventorytask",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__InventoryList' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/InventoryList'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/newinventory",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__newInventory' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/newInventory'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/newinventory/search",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__newInventory__SearchInventoryNum' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/newInventory/SearchInventoryNum'), loading: LoadingComponent}),
            "title": "menu.workspace.user",
            "exact": true
          },
          {
            "path": "/workspace/station/detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Detail__Index' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Detail/Index'), loading: LoadingComponent}),
            "title": "menu.workspace.station.detail",
            "exact": true
          },
          {
            "path": "/workspace/station/detailReserve",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Detail__Reserve' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Detail/Reserve'), loading: LoadingComponent}),
            "title": "menu.workspace.station.detail",
            "exact": true
          },
          {
            "path": "/workspace/station/detailUser",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Detail__Reserve__selectUser' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Detail/Reserve/selectUser'), loading: LoadingComponent}),
            "title": "menu.workspace.station.detail",
            "exact": true
          },
          {
            "path": "/workspace/station/recommendBooking",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__RecommendBooking' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RecommendBooking'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/workspace/station/recommendBooking/view",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__RecommendBooking__view' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RecommendBooking/view'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/workspace/station/liftTable/set",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__LiftTable__set' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/LiftTable/set'), loading: LoadingComponent}),
            "title": "设置",
            "exact": true
          },
          {
            "path": "/workspace/station/liftTable/myLiftTable",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__LiftTable__control' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/LiftTable/control'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/workspace/station/liftTable/record",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__LiftTable__record' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/LiftTable/record'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/workspace/station/fixed",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Fixed' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Fixed'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/workspace/station/cover",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Cover' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Cover'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/workspace/station/cover/reply",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Cover__reply' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Cover/reply.tsx'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/workspace/station/cover/result",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WorkSpace__Station__Cover__result' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Cover/result.tsx'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "path": "/vap",
    "routes": [
      {
        "path": "/vap/workplace",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Vap__pages__Map' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Vap/pages/Map'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/vap/control",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Vap__pages__MeetingControl' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Vap/pages/MeetingControl'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/vap/control/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Vap__pages__MeetingControl__components__EquipmentList' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Vap/pages/MeetingControl/components/EquipmentList'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/locker",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/locker/qrcode",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__Scan' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Scan'), loading: LoadingComponent}),
        "title": "menu.locker.mobile",
        "exact": true
      },
      {
        "path": "/locker/immediate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__Immediate' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Immediate'), loading: LoadingComponent}),
        "title": "menu.locker.mobile",
        "exact": true
      },
      {
        "path": "/locker/immediate/result",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__Immediate__result' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Immediate/result'), loading: LoadingComponent}),
        "title": "menu.locker.mobile",
        "exact": true
      },
      {
        "path": "/locker/open-door",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__OpenDoor' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/OpenDoor'), loading: LoadingComponent}),
        "title": "menu.locker.mobile",
        "exact": true
      },
      {
        "path": "/locker/lockerBook",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__LockerBook' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerBook'), loading: LoadingComponent}),
        "title": "menu.locker.mobile",
        "routes": [
          {
            "path": "/locker/lockerBook/page",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__LockerBook__page' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerBook/page'), loading: LoadingComponent}),
            "title": "menu.locker.mobile",
            "exact": true
          },
          {
            "path": "/locker/lockerBook/lockSelectAreaDate",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__LockerBook__LockSelectAreaDate' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerBook/LockSelectAreaDate'), loading: LoadingComponent}),
            "title": "menu.locker.mobile",
            "exact": true
          }
        ]
      },
      {
        "path": "/locker/lockerMyBooking",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__LockerMyBooking' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerMyBooking'), loading: LoadingComponent}),
        "title": "menu.locker.mobile",
        "exact": true
      },
      {
        "path": "/locker/lockerCancel",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__LockerMyBooking__lockerCancel' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerMyBooking/lockerCancel'), loading: LoadingComponent}),
        "title": "menu.locker.mobile",
        "exact": true
      },
      {
        "path": "/locker/common/components/bookingResult",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__Common__components__BookingResult' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Common/components/BookingResult'), loading: LoadingComponent}),
        "title": "menu.locker.mobile",
        "exact": true
      },
      {
        "path": "/locker/common/components/SignedResult",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SmartLocker__Common__components__SignedResult' */'/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Common/components/SignedResult'), loading: LoadingComponent}),
        "title": "menu.locker.mobile",
        "exact": true
      }
    ]
  },
  {
    "path": "/wdfclient",
    "routes": [
      {
        "path": "/wdfclient/bind",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WdfClient__WeChat__WxBinding' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/WeChat/WxBinding'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/wdfclient/init",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WdfClient__Dingding__DingDingBind' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/Dingding/DingDingBind'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/wdfclient/scanQRCode",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ScanQRCode' */'/home/jenkins/workspace/virsical/h5-app/src/pages/ScanQRCode'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/wdfclient/waferTokenLogin",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WaferTokenLogin' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WaferTokenLogin'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/wdfclient/cloudHub",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WdfClient__CloudHub' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/CloudHub'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/wdfclient/feishu",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WdfClient__FeiShu' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/FeiShu'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/wdfclient/wechatAuth",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__WdfClient__WechatAuth' */'/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/WechatAuth'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      }
    ]
  },
  {
    "path": "/accessApp",
    "routes": [
      {
        "path": "/accessApp/qrCode",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Access__AccessQrCode' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Access/AccessQrCode'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/accessApp/faceLib",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Access__AccessFace' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Access/AccessFace'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/accessApp/myAccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Access__MyAccess' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyAccess'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/accessApp/applyAccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Access__ApplyAccess' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Access/ApplyAccess'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/accessApp/faultRepair",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Access__FaultRepairAccess' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Access/FaultRepairAccess'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/accessApp/myFaultRepair",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Access__MyFaultRepair' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyFaultRepair'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/accessApp/faultRepairDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Access__MyFaultRepair__components__faultRepairDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyFaultRepair/components/faultRepairDetail.js'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/accessApp/faceAudit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Access__FaceAudit' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Access/FaceAudit'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/meeting",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__Index' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Index'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/meeting/f",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__Feedback' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Feedback'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/meeting/scanCodePage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__ScanCodePage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ScanCodePage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/meeting/vsk",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__VskJump' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/VskJump'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/meeting/index",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__HomePage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/HomePage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.About",
        "path": "/meeting/about",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__About' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/About'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.Subscribe",
        "path": "/meeting/subscribe",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__Subscribe' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Subscribe'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.RoomDetail",
        "path": "/meeting/room/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__RoomDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.subdetail",
        "path": "/meeting/subdetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__SubscribeDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SubscribeDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.RoomList",
        "path": "/meeting/roomList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__RoomList' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.AttendeePage",
        "path": "/meeting/attendee",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__AttendeePage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/AttendeePage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.AttendeePage",
        "path": "/meeting/attendeeSmall",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__AttendeePageSmall' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/AttendeePageSmall'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "participants",
        "path": "/meeting/attendeeSmallDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__NewAttendeePageDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/NewAttendeePageDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.ExternalPage",
        "path": "/meeting/external",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__ExternalPage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ExternalPage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.ExternalContact",
        "path": "/meeting/contact/external",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__ExternalContact' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ExternalContact'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.MeetingContact",
        "path": "/meeting/contact/phone",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MeetingContact' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingContact'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.MyMeeting",
        "path": "/meeting/mines",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MyMeeting' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyMeeting'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.MeetingDetail",
        "path": "/meeting/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MeetingDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.cycle",
        "path": "/meeting/manage/cycle",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__CycleTab' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/CycleTab'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.DeviceTab",
        "path": "/meeting/add/device",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__components__Device__components__deviceList' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/Device/components/deviceList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.DeviceTab",
        "path": "/meeting/device/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__DeviceTab' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/DeviceTab'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.DeviceTab",
        "path": "/meeting/device/booking/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__SubscribeDetail__components__device__index' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SubscribeDetail/components/device/index.tsx'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.SignTab",
        "path": "/meeting/manage/sign",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__SignTab' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SignTab'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.Agenda",
        "path": "/meeting/agenda",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__Agenda' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Agenda'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.Agenda",
        "path": "/meeting/meetingAgenda",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MeetingAgenda' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingAgenda'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.AttendeePage",
        "path": "/meeting/meetingAgendaUser",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MeetingAgendaUser' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingAgendaUser'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "participants",
        "path": "/meeting/meetingDetailUser",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MeetingDetailUser' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingDetailUser'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.SvcTab",
        "path": "/meeting/manage/svc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__SvcTab' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcTab'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.MeetingReceipt",
        "path": "/meeting/statistics",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MeetingReceipt' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingReceipt'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.SubscribeDetail",
        "path": "/meeting/videomeeting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__SubscribeDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SubscribeDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.MyMeeting",
        "path": "/meeting/cmma",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MeetingCmma' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingCmma'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.webexmeeting",
        "path": "/meeting/webexmeeting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__SubscribeDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SubscribeDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.MeetingDelay",
        "path": "/meeting/delay",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MeetingDelay' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingDelay'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/meeting/zoomParticipant",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__ZoomParticipants' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ZoomParticipants'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.RoomFeeDetail",
        "path": "/meeting/room/fee",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__RoomFeeDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomFeeDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.SvcFeeDetail",
        "path": "/meeting/svc/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__SvcFeeDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcFeeDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.SvcFeeDetail",
        "path": "/meeting/svc/approvalDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__SvcApprovalDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcApprovalDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.SvcApply",
        "path": "/meeting/svc/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__SvcApply' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcApply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.PollingQuestion",
        "path": "/meeting/zoomPolling",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__ZoomPolling' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ZoomPolling'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.PollingQuestion",
        "path": "/meeting/pollingTitle",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__PollingTitle' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/PollingTitle'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.PollingQuestion",
        "path": "/meeting/pollingQuestion",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__PollingQuestion' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/PollingQuestion'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.PollingQuestion",
        "path": "/meeting/polling",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__Polling' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Polling'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.seat",
        "path": "/meeting/seat",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MeetingSeat' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingSeat'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.seat.member",
        "path": "/meeting/members",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MemberSelect' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MemberSelect'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.MyInfo",
        "path": "/meeting/myInfo1",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MyInfo__Page1' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page1'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.MyInfo",
        "path": "/meeting/myInfo2",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MyInfo__Page2' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page2'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.MyInfo",
        "path": "/meeting/myInfo2",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__MyInfo__Page2' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page2'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.RoomControlList",
        "path": "/meeting/roomControlList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__RoomControlList' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomControlList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "router.title.meeting.RoomFeeDetail",
        "path": "/meeting/map/roomDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__components__MapRoomDetail__detail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/MapRoomDetail/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "title": "meeting.evaluate.title",
        "path": "/room/evaluate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Meeting__pages__Evaluate' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Evaluate'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/lighting",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Lighting__app' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Lighting/app'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/lighting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Lighting__index' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Lighting/index'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/lighting/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Lighting__detail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Lighting/detail'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/lighting/energy",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Lighting__energy' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Lighting/energy'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      },
      {
        "path": "/lighting/control",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Lighting__control' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Lighting/control'), loading: LoadingComponent}),
        "title": "router.default.title",
        "exact": true
      }
    ]
  },
  {
    "path": "/visit",
    "routes": [
      {
        "path": "/visit/reserve",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__AppIndex' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/AppIndex'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/visit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__AppIndex' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/AppIndex'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/area",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__Area' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/Area'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/loop",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__Loop' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/Loop'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/map",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__Map' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/Map'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/again",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__VisitNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/VisitNew'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/delay",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__VisitNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/VisitNew'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/confirm",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__AppIndex' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/AppIndex'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/confirm-detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__VisitNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/VisitNew'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/sure",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__VisitNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/VisitNew'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/invitation-code",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__InvitationCode' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/InvitationCode'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/statistical-analysis",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__StatisticAnalysis' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/StatisticAnalysis'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/visit-new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__VisitNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/VisitNew'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/visitor-new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__VisitorNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/VisitorNew'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/contacts",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__Contacts' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/Contacts'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/visit-detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__VisitDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/VisitDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/visit-accompany",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__Accompany' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/Accompany'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/visit-homepage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__HomePage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/HomePage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/visit-epidemic",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__Epidemic' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/Epidemic'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/batch-invitation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__BatchInvitation' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/BatchInvitation'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/parent-visit-detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__ParentVisitDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/ParentVisitDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/conference-new",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__MeetingNew' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/MeetingNew'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/visit/history-visitor",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visitor__pages__HistoryVisitor' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/pages/HistoryVisitor'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/points",
    "routes": [
      {
        "path": "/points/account",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Points__Account' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Points/Account'), loading: LoadingComponent}),
        "title": "menu.points.app.account",
        "exact": true
      },
      {
        "path": "/points/bill",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Points__Bill' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Points/Bill'), loading: LoadingComponent}),
        "title": "menu.points.app.bill",
        "exact": true
      },
      {
        "path": "/points/bill/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Points__BillDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Points/BillDetail'), loading: LoadingComponent}),
        "title": "menu.points.app.bill",
        "exact": true
      },
      {
        "path": "/points/punishment",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Points__Punishment' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Points/Punishment'), loading: LoadingComponent}),
        "title": "menu.points.app.punishment",
        "exact": true
      },
      {
        "path": "/points/punishment/selectDept",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Points__Punishment__components__SelectDept' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Points/Punishment/components/SelectDept'), loading: LoadingComponent}),
        "title": "menu.points.app.punishment",
        "exact": true
      },
      {
        "path": "/points/punishment/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Points__PunishmentDetail' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Points/PunishmentDetail'), loading: LoadingComponent}),
        "title": "menu.points.app.punishment",
        "exact": true
      },
      {
        "path": "/points/exchange",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Points__ExchangeProduct' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Points/ExchangeProduct'), loading: LoadingComponent}),
        "title": "menu.points.app.account",
        "exact": true
      }
    ]
  },
  {
    "path": "/welcome",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Welcome'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/404",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/jenkins/workspace/virsical/h5-app/src/pages/404'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/cloudHub/getPerson",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CloudHubAuth' */'/home/jenkins/workspace/virsical/h5-app/src/pages/CloudHubAuth'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/error",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Error' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Error'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "path": "/notSubscribePage",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__NotSubscribePage' */'/home/jenkins/workspace/virsical/h5-app/src/pages/NotSubscribePage'), loading: LoadingComponent}),
    "title": "router.default.title",
    "exact": true
  },
  {
    "routes": [
      {
        "path": "/tcs/usestatus",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tcs' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Tcs'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/tcs/show",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tcs' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Tcs'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/tcs/alarmList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tcs__AlarmList' */'/home/jenkins/workspace/virsical/h5-app/src/pages/Tcs/AlarmList'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/shareResourceScanPage",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TokenLogin' */'/home/jenkins/workspace/virsical/h5-app/src/pages/TokenLogin'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
