import { matchPath, history, formatMessage } from 'umi';
import { Modal, Toast } from 'antd-mobile-v2';
import SockJS from 'sockjs-client';
import _ from 'lodash';
import { LIVE_TYPE, MENU_PATH, PARTICIPANT_STATUS } from '@/pages/Meeting/utils/constant';
import * as ctrlService from '../../services/meetingControl';
import * as detailService from '../../services/meetingDetail';
import { getContent, getP } from './util';

const { alert } = Modal;

export default {
  namespace: 'meetingControl',
  state: {
    meetingId: 0,
    cmmaMeetingId: '',
    callNumber: '',
    keys: {
      dialNum: false,
      addRoom: false,
      muteAll: false,
      locked: false,
      autoReconnect: true,
      rotation: 0,
      text: false,
      startAt: 0,
      duration: 0,
      passed: 0,
    },
    data: [],
    devInfo: {},
    zoneTree: [],
    showDevInfo: false,
    hideList: [],
    onlineCnt: 0,
    participants: [],
    participantList: [],
    asrParticipant: {},
    allDevice: [], // 4.8.1所有绑定了会议室的视频终端
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
    socketError({ meetingId }) {
      history.push({
        pathname: MENU_PATH.meetingDetail,
        query: {
          meetingId,
          fromType: 1,
        },
      });
      Toast.fail(formatMessage({ id: 'cmma.msg.socketError' }), 2);
    },
  },
  effects: {
    *getCmmaToken(__, { call }) {
      // 从后台获取cmma认证token
      return yield call(ctrlService.getCmmaAuth);
    },
    *dealMessage({ result: { data } }, { select, put, call }) {
      const msg = JSON.parse(data);
      const { cmmaInfo, participantList: showLst = [] } = yield select(
        (state) => state.meetingDetail,
      );
      const { conferenceId, callNumber, meetingId, joinPwd } = cmmaInfo;

      if (msg.channel === 'CONFERENCE_SUMMARY') {
        // 会议概要
        const { keys } = yield select((state) => state.meetingControl);

        let cmmaMeetingId = '';
        let cmmaNumber = '';

        if (conferenceId && conferenceId !== '') {
          const msgItem = [];
          if (msg.payload) {
            msg.payload.map((m) => m.id === conferenceId && msgItem.push(m));
          }
          if (msgItem.length === 1) {
            cmmaMeetingId = conferenceId;
            cmmaNumber = callNumber;
            keys.muteAll = msgItem[0].muteAll;
            keys.startAt = msgItem[0].startAt;
            keys.locked = msgItem[0].locked;
            keys.autoReconnect = msgItem[0].autoReconnect;
            keys.rotation = msgItem[0].rotation;
            keys.layout = msgItem[0].layout;
            keys.duration = msgItem[0].durationInMinutes;
            keys.passed = msgItem[0].progressInMinutes;
            keys.joinPwd = joinPwd;
          } else {
            // 会议已经结束
            yield call(ctrlService.resetCmmaInfo, meetingId);
            yield put({
              type: 'update',
              payload: {
                keys: {
                  dialNum: false,
                  muteAll: false,
                  locked: false,
                  autoReconnect: true,
                  text: false,
                  startAt: 0,
                },
              },
            });
            Toast.fail(formatMessage({ id: 'cmma.msg.currentClose' }));
            history.replace(MENU_PATH.myMeeting);
          }
        }

        if (cmmaMeetingId !== '') {
          // 匹配到会议，发送订阅消息
          if (window.cmma_ws) {
            window.cmma_ws.send(
              `{"action":"SUBSCRIBE","data":{"conferenceId":"${cmmaMeetingId}"}}`,
            );
          }
        }
        yield put({
          type: 'update',
          payload: {
            cmmaMeetingId,
            callNumber: cmmaNumber,
            keys,
          },
        });
      } else if (msg.channel === 'CONFERENCE') {
        // 会议详细信息
        const parts = msg.payload.participants;
        const { participants: prePartics = [] } = yield select((state) => state.meetingControl);
        if (!_.isEqual(prePartics, parts)) {
          // 如果ws消息中的参会成员与state保存的不一致，更新state中的数据
          yield put({
            type: 'update',
            payload: {
              participants: parts.filter((p) => p.liveType === LIVE_TYPE.normal),
              onlineCnt: parts.filter((p) => p.liveType === LIVE_TYPE.normal).length,
              asrParticipant: parts.filter((p) => p.liveType === LIVE_TYPE.asr)[0] || {},
            },
          });
        }

        // 通过消息中的参会成员信息更新列表成员的状态
        const newShowList = showLst.map((item) => {
          const lp = _.cloneDeep(item);
          if (lp.removed === 0) {
            const p = parts.find((ps) => {
              const { currentAddress = '' } = ps.currentTerminal || {};
              return currentAddress === lp.address;
            });
            if (p) {
              lp.state = p.connectState;
              lp.main = p.main;
              lp.speaker = p.speaker;
              lp.videoMuted = p.videoMuted;
              lp.audioMuted = p.audioMuted;
              lp.layout = p.layout;
              lp.participantId = p.id;
            } else {
              lp.main = false;
              lp.state = PARTICIPANT_STATUS.unknown;
            }
          }
          return lp;
        });
        yield put({ type: 'update', payload: { participantList: newShowList } });
        /**
         * 外部自主呼入设备时
         */
        const addrsOnDB = showLst.map((p) => p.address);
        const addrsOnMSG = [];
        parts.forEach((p) => {
          if (p.liveType === LIVE_TYPE.normal && p.currentTerminal) {
            addrsOnMSG.push(p.currentTerminal.currentAddress);
          }
        });
        // _.difference([2, 1], [2, 3]);     => [1]
        // filter(i=>i) 过滤掉可能存在的null或undefined元素
        const outerAddrs = _.difference(addrsOnMSG, addrsOnDB).filter((i) => i);
        if (outerAddrs.length > 0) {
          window.console.log('There is an incomeing call which number is: ', outerAddrs[0]);
          yield put({
            type: 'dialNumber',
            payload: { meetingId, num: outerAddrs[0] },
          });
        }
      } else if (msg.channel === 'ADD_PARTICIPANT_RESPONSE') {
        // 呼叫了一个被占用的终端
        const { valid, invalidItems } = msg.payload;
        if (!valid && invalidItems && invalidItems.length > 0) {
          const {
            request: { id, title },
            rooms,
          } = invalidItems[0];

          if (id === conferenceId) {
            // 添加的会议室在当前会议中
            Toast.info(formatMessage({ id: 'cmma.msg.duplicate' }), 2);
          } else {
            const usingInfo = `${formatMessage({ id: 'cmma.msg.usingInfo' })}
              ${title}`;
            const param = {
              meetingId,
              cmmaRoomId: Number(rooms[0]),
            };
            yield call(ctrlService.addingFailed, param);
            // 弹出提示信息
            alert(formatMessage({ id: 'cmma.msg.dialFailed' }), usingInfo, [{ text: 'OK' }]);
          }

          const { data: data1 } = yield call(ctrlService.cmmaInfo, meetingId);
          yield put({
            type: 'meetingDetail/update',
            payload: { cmmaInfo: data1, participantList: data1.participants },
          });
        }
      } else if (msg.channel === 'ASR_CONTROL_RESPONSE') {
        const { status } = msg.payload;
        if (status !== 'success') {
          Toast.fail(formatMessage({ id: 'mc_asr_config_error' }), 2);
        }
      }
    },
    *startNow({ meetingId }, { select, put, call }) {
      const { code, data, msg } = yield call(ctrlService.startNow, meetingId);
      if (code === 0) {
        Toast.success(formatMessage({ id: 'cmma.msg.startSuccess' }), 1);
        const { data: data1 } = yield call(ctrlService.cmmaInfo, meetingId);
        yield put({
          type: 'meetingDetail/update',
          payload: { cmmaInfo: data1, participantList: data1.participants },
        });
      } else if (code === 1 && data && data !== '') {
        // 有会议室占用
        const { data: roomList } = yield select((state) => state.meetingControl);
        const msgList = [];
        data.split(';').forEach((er) => {
          if (roomList) {
            roomList.forEach((rl) => {
              // eslint-disable-next-line radix
              if (parseInt(er, 0) === rl.cmmaRoomId) {
                msgList.push(getP(rl));
              }
            });
          }
        });
        if (msgList.length > 0) {
          Toast.fail(getContent(msgList, formatMessage({ id: 'cmma.msg.busyList' })), 2);
        } else {
          Toast.fail(formatMessage({ id: 'cmma.msg.startFailedBusy' }), 2);
        }
      } else {
        Toast.fail(formatMessage({ id: msg }), 2);
      }
    },
    *endMeeting({ conferenceId, release, meetingId }, { select, put, call, take }) {
      // 发送消息
      const action = 'END_CONFERENCE';
      if (window.cmma_ws) {
        window.cmma_ws.send(`{"action":"${action}","data":{"conferenceId":"${conferenceId}"}}`);
      }
      // 重置库表
      yield put({ type: 'meetingDetail/reload', meetingId });
      yield take('meetingDetail/reload/@@end');
      const {
        detail: { status },
      } = yield select((state) => state.meetingDetail);
      yield call(ctrlService.resetCmmaInfo, meetingId);
      yield put({
        type: 'update',
        payload: {
          keys: {
            dialNum: false,
            muteAll: false,
            locked: false,
            text: false,
            startAt: 0,
          },
          participants: [],
        },
      });
      // 释放会议室资源
      if (release) {
        const param = {
          meetingId,
        };
        if (status === 0) {
          // 会议未开始
          yield call(detailService.cancelMeeting, param);
        } else {
          // 会议进行中
          yield call(detailService.closeMeeting, param);
        }
      }
      // 跳转回首页
      history.replace(MENU_PATH.myMeeting);
      Toast.success(formatMessage({ id: 'cmma.msg.closeSuccess' }), 1);
    },
    // *allCmmaRoom(_, { call, select, put }) {
    //   const {
    //     tenantId
    //   } = yield select(state => state.common.currentUser);
    //   const {
    //     data: { child: zones }
    //   } = yield call(ctrlService.allCmmaRoom, tenantId);
    //   const treeData = Common.allCmmaRoomList(zones);
    //   yield put({ type: 'update', payload: { zoneTree: treeData } });
    // },
    *addRoom({ payload: { rooms, mid, cid } }, { call, select, put }) {
      const { allDevice = [] } = yield select((state) => state.meetingControl);
      const fetches = [];
      const action = 'ADD_PARTICIPANT';
      if (rooms) {
        rooms.forEach((r) => {
          const dev = allDevice.find((ad) => ad.cmmaRoomId === r.value);
          if (dev) {
            const param = {
              meetingId: mid,
              address: dev.primaryAddress,
            };
            fetches.push(call(ctrlService.addOnRoom, param));
          }
        });
      }
      yield fetches;

      if (rooms) {
        rooms.forEach((r) => {
          if (window.cmma_ws) {
            const cmmaMsg = `{"action":"${action}","data":{"conferenceId":"${cid}","room":"${r.value}"}}`;
            window.cmma_ws.send(cmmaMsg);
            window.console.log('发送cmmaMsg', cmmaMsg);
          }
        });
      }

      const { data } = yield call(ctrlService.cmmaInfo, mid);
      yield put({
        type: 'meetingDetail/update',
        payload: { cmmaInfo: data, participantList: data.participants },
      });
    },
    *dialNumber({ payload: { meetingId, num } }, { call, put }) {
      const param = {
        meetingId,
        address: num,
      };
      const { code } = yield call(ctrlService.addOnRoom, param);
      if (code === 0) {
        const { data } = yield call(ctrlService.cmmaInfo, meetingId);
        yield put({
          type: 'meetingDetail/update',
          payload: { cmmaInfo: data, participantList: data.participants },
        });
      }
    },
    *removeAddOnRoom({ p }, { call, select, put }) {
      const { meetingId: id, cmmaMeetingId: conferenceId } = yield select(
        (state) => state.meetingControl,
      );
      const param = {
        meetingId: id,
        address: p.address,
      };
      const { code } = yield call(ctrlService.rmvParticipant, param);

      if (code === 0) {
        const msg = {
          action: 'REMOVE_PARTICIPANT',
          data: {
            conferenceId,
            participantId: p.participantId,
          },
        };
        window.cmma_ws.send(JSON.stringify(msg));
        window.console.log('发送cmma消息', msg);
        const { data } = yield call(ctrlService.cmmaInfo, id);
        yield put({
          type: 'meetingDetail/update',
          payload: { cmmaInfo: data, participantList: data.participants },
        });
      }
    },

    *delayCallNum({ callNumber, extend }, { call }) {
      yield call(ctrlService.delayCallNum, callNumber, extend);
    },
    *initData(__, { call, select, put }) {
      const { tenantId } = yield select((state) => state.user.currentUser);
      const { code, data } = yield call(ctrlService.getAllCmmaDevice, tenantId);
      if (code === 0) {
        yield put({
          type: 'update',
          payload: {
            allDevice: data,
          },
        });
      }
    },
    *checkCmmaStatus({ payload }, { call }) {
      const { code, data } = yield call(ctrlService.cmmaStatus, payload);
      if (code === 0) {
        window.cmmaStatus = data;
      } else {
        window.cmmaStatus = false;
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.meetingCtrl)) {
          const { query } = history.location;
          const { meetingId } = query || {};
          const initKeys = {
            dialNum: false,
            muteAll: false,
            locked: false,
            text: false,
            startAt: 0,
          };
          dispatch({
            type: 'update',
            payload: { meetingId, keys: initKeys },
          });
          dispatch({ type: 'meetingDetail/reload', meetingId });

          dispatch({ type: 'initData' });

          // dispatch({ type: 'meetingDetail/connectWS', dispatch, meetingId });

          dispatch({ type: 'getCmmaToken' }).then(({ data }) => {
            if (data) {
              // 建立socket连接
              window.cmma_ws = new SockJS(`/api/ws?cmma-token=${data}`);
              window.cmma_ws.onopen = () => {
                window.cmma_ws.send('{"action":"ATTACH"}');
              };
              window.cmma_ws.onmessage = (result) => {
                dispatch({ type: 'dealMessage', result });
              };
            } else {
              dispatch({ type: 'socketError', meetingId });
            }
          });
        }
      });
    },
  },
};
