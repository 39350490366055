import globalHeader from './zh-CN/globalHeader';
import settings from './zh-CN/settings';
import lighting from './zh-CN/lighting';
import meeting from './zh-CN/meeting';
import error from './zh-CN/error';
import intl from './zh-CN/intl';

export default {
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.home.introduce': '介绍',
  'app.system.initialization': '系统正在加载，请稍后...',
  'app.loading': '加载中...',
  pageNotFound: '页面未找到',
  'go.index': '返回首页',
  'user.currentUser.error': '接口无法获取用户信息，请稍后再试',
  'user.currentUser.error2': '用户信息中缺失userId，请稍后再试',
  ...globalHeader,
  ...settings,
  ...lighting,
  ...error,
  ...meeting,
  ...intl,
};
