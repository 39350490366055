/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'myInfo.info.account': '账号',
  'myInfo.info.name': '姓名',
  'myInfo.info.sex': '性别',
  'myInfo.info.role': '角色',
  'myInfo.info.dept': '所属部门',
  'myInfo.info.position': '职位',
  'myInfo.info.tel': '手机号',
  'myInfo.info.email': '邮箱',
  'myInfo.info.male': '男',
  'myInfo.info.female': '女',

  'myInfo.info.userName': '账号',
  'myInfo.info.back': '返回上一页',
};
