export default {
  'sws.sensor.station.tips': 'You have not used your reserved seat for {value1} minutes. Do you want to continue using it? ',
  'sws.sensor.cancel': 'Release this station immediately? ',
  'sws.sensor.immediate.release': 'release immediately',
  'sws.sensor.continue.to.use': 'Continue to use',
  'sws.sensor.release.tips': 'The station has been successfully released for you',
  'sws.sensor.continue.tips': 'The station has been successfully reserved for you',
  'sws.sensor.hello':', hello:',
  401147: 'The operation has expired and the appointment has ended',
  401148: 'Do not repeat the operation',
};
