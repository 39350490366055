/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  '106534': '操作失败',
  '106535': '程序内部异常！',
  'scanQRCode.scan.type.0': '微信扫一扫(Type=0)',
  'scanQRCode.scan.type.1': '微信扫一扫(Type=1)',
  'scanQRCode.scan.type.dingTalk': '钉钉扫一扫',
  'scanQRCode.scan.type.virsical': '威思客扫一扫',
  'scanQRCode.scan.type.feishu': '飞书扫一扫',
};
