export default {
  'tcs.title': '隔间占用状态',
  'tcs.free': '空闲',
  'tcs.status.0': '空闲',
  'tcs.status.1': '占用',
  'tcs.status.2': '维修',
  'tcs.status.3': '故障',
  'tcs.status.-1': '离线',
  'tcs.free.unit': '个',
  'tcs.build.all': '全部楼栋',
  'tcs.all': '全部楼层',
  'tcs.toilet.show': '显示厕位室',
  'tcs.build': '楼栋',
  'tcs.confirm': '确定',
  'tcs.cancel': '取消',

  'tcs.alarm.config.alarm.receiver.type.device.short': '故障',
  'tcs.alarm.config.alarm.receiver.type.baojie.short': '人流量大',
  'tcs.alarm.config.alarm.receiver.type.timeout.short': '占用超时',
  'tcs.alarm.config.alarm.receiver.type.consumable.short': '厕纸余量不足',
  'tcs.alarm.config.alarm.receiver.type.odor.short': '环境不适',
  'tcs.alarm.config.alarm.receiver.type.waterLevel.short': '洗手液余量不足',

  'tcs.alarm.config.alarm.receiver.type.device': '设备故障报警',
  'tcs.alarm.config.alarm.receiver.type.baojie': '人流量告警',
  'tcs.alarm.config.alarm.receiver.type.timeout': '超时占用告警',
  'tcs.alarm.config.alarm.receiver.type.consumable': '厕纸余量告警',
  'tcs.alarm.config.alarm.receiver.type.odor': '环境告警',
  'tcs.alarm.config.alarm.receiver.type.waterLevel': '洗手液余量告警',

  'tcs.alarm.list.search.time': '告警时间范围',
  'tcs.alarm.list.search.alarm.type': '告警类型',
  'tcs.alarm.list.search.alarm.status': '告警状态',
  'tcs.alarm.list.table.alarm.time': '告警时间',
  'tcs.alarm.list.table.alarm.rank': '告警级别',
  'tcs.alarm.list.table.alarm.content': '告警内容',
  'tcs.alarm.list.table.alarm.address': '告警位置',
  'tcs.alarm.list.table.alarm.receiver': '告警接收人',
  'tcs.alarm.list.table.alarm.status': '状态',
  'tcs.alarm.list.table.alarm.status.0': '未处理',
  'tcs.alarm.list.table.alarm.status.1': '已处理',
  'tcs.alarm.list.table.alarm.status.2': '已取消',
  'tcs.alarm.list.table.alarm.rank.1': '预警',
  'tcs.alarm.list.table.alarm.rank.2': '紧急',
  'tcs.alarm.list.table.alarm.status.tip': '确定已经完成处理此告警？',
  'tcs.alarm.list.nodata': '没有更多了',
  'tcs.alarm.list.handler.person': '处理人',
  'tcs.alarm.list.handler.time': '处理时间',
};
