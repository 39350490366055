import { useMemo } from 'react';
import useRequest from '../useRequest';
import * as service from './service';

/**
 *  访客补填创建后获取分享链接信息
 */
export default function useGetShareInviteLink() {
  const {
    data: shareInviteLinkData,
    run: runGetShareInviteLink,
    loading: getShareInviteLinkLoading,
  } = useRequest((params: any) => service.getShareInviteLink(params), {
    manual: true,
  });
  return useMemo(() => {
    return {
      // 访客补填创建后获取分享链接信息
      shareInviteLinkData,
      runGetShareInviteLink,
      getShareInviteLinkLoading,
    };
  }, [shareInviteLinkData, runGetShareInviteLink, getShareInviteLinkLoading]);
}
