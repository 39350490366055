/**
 * Created : vincent
 * Date : 2019-03-20  19:22
 * Email : wangxiao@wafersystems.com
 */
import request, { requestNoAuthorization } from '@/utils/request';
import { ACCESS_TOKEN, RESTFUL_PATH } from '@/utils/constant';

/* 门禁 人脸库上传头像 */
export async function updateLoadImage({ formData }) {
  return requestNoAuthorization(`/${RESTFUL_PATH.access}/sss/upload`, {
    method: 'POST',
    body: formData.formData,
    headers: {
      Authorization: `bearer ${sessionStorage.getItem(ACCESS_TOKEN)}`,
    },
  });
}

export async function queryCurrentUserInfo() {
  return request(`/${RESTFUL_PATH.admin}/user/info`);
}

export async function getUserFaceByUserName(userName) {
  return request(`/${RESTFUL_PATH.access}/api/face/getFaceByUserName?username=${userName}`);
}
export async function delFace(faceIds) {
  return request(`/${RESTFUL_PATH.access}/api/face/delFace?faceIds=${faceIds}`);
}

export async function createOrUpdateFaceBySelf(params) {
  return request(`/${RESTFUL_PATH.access}/api/face/createOrUpdateFaceBySelf`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
