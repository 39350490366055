export default {
  'year.str': '{year}年',

  'subscribe.select.region': '選擇區域',
  'subscribe.default.region': '請選擇區域',
  'subscribe.default.room': '暫無可供選擇的會議室哦~',
  'subscribe.room.noDev': '無設備',
  'subscribe.room.1to3': '1-3人',
  'subscribe.room.4to9': '4-9人',
  'subscribe.room.over10': '10人以上',
  'subscribe.room.filter': '篩選',
  'subscribe.room.select': '共{num}個',
  'subscribe.send.success': '發送成功',
  'subscribe.free.success': '免費賬號，只能預約40分鐘內的zoom會議。',
  'subscribe.booking.failed.time': '單日會議會議時長大於配置最大時長!',

  'subscribe.filter.attendance': '會議室容納人數',
  'subscribe.filter.number': '{num}人',
  'subscribe.filter.devices': '設備配置',
  'subscribe.filter.devices1': '（多選）',
  'subscribe.filter.inputNum': '請輸入會議室容納人數',
  'subscribe.filter.time': '時間',
  'subscribe.filter.time.plac': '請選擇',

  'subscribe.room.need.audit': '需審核',
  'smt.please.select': '請選擇',
  'smt.please.select.area': '請選擇',
  'smt.node.no.select': '此位置不可以選擇',
  'smt.please.select.none': '暫無區域可供選擇!',
  'subscribe.default.region1': '請選擇{num}級區域',
  'subscribe.default.region.new_tab1': '本地區域',
  'subscribe.default.region.new_tab2': '共享區域',
  'subscribe.default.region.new': '全部區域',
  'subscribe.default.region.select.option.no': '暫不選擇',
  'subscribe.no.teams.room.data': '當前無可預訂會議室，',
  'subscribe.no.teams.room.data1': '請聯繫管理員添加會議室。',
  'subscribe.no.teams.room.data.tip': '如何添加會議室，幫助地址：https://cloud.virsical.cn/help',
  'subscribe.time.hoilday.ok': '該日已被設置為節假日，不能選擇!',

  'smt.please.select.area.new': '請選擇',
};
