export default {
  // APP页面title
  'app.visitor.reserve': '访客预约',
  'app.visit.record': '访问记录',
  'app.new.reserve': '新建邀请',
  'app.visitor.confirmed': '预约确认',
  'app.visitor.Invites': '邀请记录',
  'app.visitor.Visitors': '访问记录',
  'app.visitor.Confirmed': '访客审批',
  'app.visitor.statisticAnalysis': '统计分析',
  'app.visitor.sum': '预约确认',
  'app.reserve.detail': '预约详情',
  'app.visit.type': '访问事由',
  'app.reserve.delay': '变更邀请',
  'app.reserve.again': '再次预约',
  'app.detail': '列表详情',
  'app.visitor.title': '访客信息',
  // 其他
  'app.time.format': 'YYYY年M月D日 HH:mm',
  'app.no.data': '无数据',
  // 'app.loading': '正在加载',
  'app.loading...': '加载中...',
  'app.new.associates': '添加此次邀请的其他接待人员',
  app_ass_tip: '关联人最多5人',
  // 自助预约
  'app.self.rightHost.tip': '请输入正确的拜访对象',
  'app.self.rightHost.tip2': '请先选择访问地点',
  'app.self.confirmed': '已确认',
  'app.self.NoConfirmed': '未确认',
  'app.self.photo': '人脸照片',
  'app.self.isConfirmed': '确认',

  app_invitation_letter: '邀请函',
  app_invitation_code_fail: '获取失败',
  'app.loading.freshed': '刷新完毕',
  'app.loading.pullToRefresh': '下拉可以刷新',
  'app.loading.releaseAndfreshed': '松开立即刷新',
  'app.loading.reject': '拒绝',
  'app.loading.agree': '同意',
  'app.loading.noListShow': '没有数据？',
  'app.loading.noListShow.add': '点击新建邀请即可新建访客哦～',
  'app.loading.noListShow.confirm': '没有需要审批的访问申请',
  'app.loading.pleaseInputOk': '请输入正确的搜索内容',
  'app.loading.pleaseInputValid': '最少请输入1位字符',
  'app.loading.today': '今天',
  'app.analysis.today': '今天',
  'app.analysis.week': '本周',
  'app.analysis.month': '本月',
  'app.analysis.year': '年度',
  'app.analysis.history': '历史访客总数',
  'app.analysis.Invites': '邀请访客总数',
  'app.analysis.reserve': '预约访客总数',
  'app.analysis.temporary': '临时访客总数',
  'app.visitors.input': '请输入关键字搜索',

  'app.visitor.info': '访客信息',
  'app.access.info': '访问信息',
  'app.meeting.details': '访问详情',
  'app.meeting.info': '会议信息',
  'app.new.visitor': '新增访客',
  'app.new.visitor1': '添加访客',
  'app.phone.address': '从手机通讯录选择',
  'app.phone.business_card': '从名片识别(仅支持简体中文)',
  'app.visitors.state': '访问状态',
  'app.create.visitor.error': '姓名是必填项',
  'visit.reserve.visitor.phone.or.email.required': '手机和邮箱至少填写一个',
  'visit.reserve.visitor.phone.required': '手机是必填项',
  'app.create.visitor.error2': '姓名、身份证号是必填项',
  'app.create.number.error': '手机号不能重复',
  'app.contacts.select': '选择',
  'app.upload.businessCard': '正在识别...',

  'app.connect': '只支持选择大陆或香港地区移动电话',
  'app.connect.none': '用户不存在',
  'app.map.none': '查询失败',
  'app.visitor.call': '尊敬的',
  'app.visitor.hello': '您好，',
  'app.visitor.hello_1': '！',
  'app.visitor.tip': '如下信息可协助您的到访，祝您到访顺利！',
  'app.visitor.text': '通过二维码扫码开门或以下邀请码进行签到',
  'app.visitor.signInTime': '到访时间：',
  'app.invitation.tip1': '企业未配置导航点',
  'app.invitation.tip2': '请直接联系访问接待人获取',
  'app.reserve.visit.online.zoom': 'Zoom会议: ',
  'app.reserve.visit.online.webx': 'Webex会议: ',
  'app.reserve.visit.online.teams': 'Teams会议: ',
  'app.reserve.visit.online.flyBook': '飞书会议: ',
  'app.reserve.visit.online.tencent': '腾讯会议: ',
  'app.reserve.visit.online.video': '视频会议: ',
  'visit.reserve.visit.online.zoom.warn': '只能选择一种会议类型',
  'visit.reserve.meetingRoom.tip': '会议室不能为空',
  'visit.reserve.meetingRoom': '选择会议室',

  'visit.meetingRomm.name': '物理会议室',
  'visit.meetingRomm.name.type': '会议类型',
  'visit.meetingRomm.time': '会议开始时间',
  'visit.meetingRomm.end.time': '会议结束时间',
  'visit.meetingRomm.time.length': '会议时长',
  'visit.meetingRomm.time.min': '分钟',
  'visit.reserve.detail.online.zoom': 'Zoom会议',
  'visit.reserve.detail.online.webx': 'Webex会议',
  'visit.reserve.detail.online.teams': 'Teams会议',
  'visit.reserve.detail.online.flyBook': '飞书会议',
  'visit.reserve.detail.online.tencent': '腾讯会议',
  'visit.reserve.detail.online.video': '视频会议',
  'visit.reserve.detail.online.ordinary': '普通会议',
  'visit.meeting.detail': '会议详情',

  'visit.reserve.detail.tip': '确定要取消',
  'visit.reserve.detail.reject.tip': '确定要拒绝',
  'visit.reserve.detail.reject.tip1': '的访问吗?',
  'visit.reserve.detail.tip1': '的邀请吗?',
  'visit.common.ok': '是',
  'visit.common.cancel': '否',
  'app.reserve.new.visitor': '请完成当前访客信息',
  'app.reserve.new.visitor.tip': '变更邀请不支持添加新访客',
  'app_invitation_img.title': '照片采集',
  'app_invitation_img.upload.success': '上传成功',

  'visit.reserve.visitor.repeat.phone.tip': '访客手机不能和当前用户相同',
  'visit.reserve.visitor.repeat.mail.tip': '访客邮箱不能和当前用户相同',
  'app.reject.reason.placeholder': '请输入拒绝理由',
  'app.reject.reason': '拒绝理由',
  'app.currentUser.error': '无法获取用户信息，请稍后再试',

  'app.self.custom.tip': '请输入正确的',
  'visit.delay.tip': '邀请信息变更，请重新确认访客信息。',

  'visit.loop.info': '循环邀请',
  'visit.loop.not': '不循环',
  'visit.loop.month': '按月',
  'visit.loop.day': '按天',
  'visit.loop.week': '按周',
  'visit.loop.not.allow': '会议不支持循环',
  'visit.loop.not.allow2': '跨天不支持循环',

  'visit.reserve.loop.mode': '定期模式',
  'visit.reserve.loop.range': '重复范围',
  'visit.reserve.loop.times': '重复',
  'visit.reserve.loop.count': '次',
  'visit.reserve.loop.day.count': '每',
  'visit.reserve.loop.day.type': '每个工作日',
  'visit.reserve.loop.week.type': '每周的',
  'visit.reserve.loop.week.1': '周一',
  'visit.reserve.loop.week.2': '周二',
  'visit.reserve.loop.week.3': '周三',
  'visit.reserve.loop.week.4': '周四',
  'visit.reserve.loop.week.5': '周五',
  'visit.reserve.loop.week.6': '周六',
  'visit.reserve.loop.week.7': '周日',
  'visit.reserve.loop.count.tip': '请输入重复次数',
  'visit.reserve.loop.times.tip': '请输入重复天数',
  'visit.reserve.loop.day': '天',
  'visit.reserve.loop.week': '周',
  'visit.reserve.loop.month': '月',
  'visit.reserve.loop.title': '循环邀请',
  'visit.reserve.loop.tip.max': '该周期预约次数不能超过最大预约限制',
  'visit.reserve.loop.tip.min': '该周期预约次数不能小于最小预约限制',
  'visit.reserve.loop.tip.max.day': '该周期预约天数不能超过最大预约限制',
  'visit.reserve.loop.tip.min.day': '该周期预约天数不能小于最小预约限制',

  // v6.6 访客主页
  'visit.visitor.homepage': '访客管理',
  'visit.visitor.homepage.title': '接待、访问轻松便捷',
  'visit.visitor.homepage.resever': '邀请记录',
  'visit.visitor.homepage.visit': '访问记录',
  'visit.visitor.homepage.shenpi': '访客审批',
  'visit.visitor.homepage.tongji': '统计分析',
  'visit.visitor.homepage.invite': '邀请访客',

  'visit.reserve.edit.error': '未变更信息，请修改后再试',

  'visit.visitor.epidemic.title': '防疫码',
  // v6.8
  'visit.visitor.avatar': '访客头像',
  // v6.9
  'app.meeting.hand.sign': '访客签名',
  'app.visitor.all': '全部',
  'app.visitor.my.invite': '我邀请的',
  'app.visitor.visit.me': '拜访我的',

  'app.batch.invitation.company': '公司',
  'app.batch.invitation.time': '时间',
  'app.batch.invitation.type': '事由',

  app_batch_invitation_code: '访客信息',
  'app.loading.noListShow.teams': '暂无数据',
  // v7.1
  app_invitation_health: '健康调查',
  app_invitation_meeting_info: '会议信息',
  app_invitation_meeting_topic: '会议主题：',
  app_invitation_meeting_room: '会议室：',
  app_invitation_meeting_start: '开始时间：',
  app_invitation_meeting_end: '结束时间：',
  app_invitation_meeting_id: '会议ID：',
  app_invitation_meeting_no: '参会密码：',
  app_invitation_meeting_link: '邀请链接：',
  'app.reject.cancel.placeholder': '请输入取消原因',
  'app.reject.cancel': '取消原因',

  app_invitation_meeting_info_topic: '会议主题',
  app_invitation_meeting_info_room: '会议室',
  app_invitation_meeting_info_start: '开始时间',
  app_invitation_meeting_info_end: '结束时间',
  app_invitation_meeting_info_id: '会议ID',
  app_invitation_meeting_info_no: '参会密码',
  app_invitation_meeting_info_link: '邀请链接',

  'app.meeting.title': '会议信息',
  'app.meeting.select': '选择会议室',
  'app.loading.select': '选择',
  'app.visitor.home.page': '首页',

  'visit.invitation.code.tip.err': '二维码失效，点击重试',
  'app_invitation_health.button.tip': '（请于{mouth}月{day}日提交）',
  'visit.invitation.code.edit.title.healthCode': '健康码',
  'visit.invitation.code.edit.title.tourCode': '行程卡',
  'visit.invitation.code.edit.title.vaccination': '疫苗证明',
  'visit.invitation.code.edit.title.nucleic': '核酸报告',
  'visit.invitation.code.edit.tip': '注 : 请上传当地健康码、行程卡、疫苗接种证明或核酸检测报告',
  'visit.invitation.code.edit.error': '请上传当地{errStr}',
  'app.fail.reason': '失败原因',
  'visit.reserve.visitor.cancel.outer.meeting': '同时从外部参会人中删除',
  'visit.reserve.meeting.check.max.time.tip': '会议预订时长超出会议室单场会议最大时间{value}分钟',
  'visit.reserve.meeting.check.max.time.tip1': '会议预订时长超出会议室单场会议最大时间',
  'visit.reserve.meeting.check.across.day.tip': '跨天会议时间不能大于{value}天',
  'visit.reserve.meeting.check.across.permissions.tip': '当前用户没有预约跨天会议权限',
  'visit.reserve.meeting.check.same.time.tip': '该时间段已预订会议，不能再次预订',
  'visit.reserve.meeting.check.before.time.tip': '审批会议室需提前{time}分钟申请！',
  'visit.invitation.code.vx.share.title': '{companyName}邀请函',
  'visit.reserve.visitor.cancel.send.notice': '向访客发送取消通知',
  'visit.reserve.copy.link': '复制链接',
  'visit.reserve.copy.link.success': '链接已复制',
  'visit.reserve.generate.qrCode': '生成二维码',
  'visit.reserve.send.letter': '发送邀请函',
  'visit.reserve.share': '分享',
  'visit.reserve.down.generate.qrCode': '邀请函二维码.png',
  'visit.reserve.down.generate.qrCode.success': '下载成功',
  'visit.reserve.read.generate.qrCode': '扫码打开访客邀请链接',
  'visit.visitor.information.repeat.tip': '{str}不能重复',
  'visit.meeting.booking.cross.day.tip1': '跨天会议允许预约{num}天内会议',
  'visit.meeting.booking.cross.day.tip2':
    '跨天会议允许预约{num}天内会议，超出{num}天的会议需要管理员审批',
  'visit.reserve.universal.share': '通用分享',
  'visit.reserve.universal.share.tip': '链接中不含访客个人信息，需要访客自行填写',
  'visit.reserve.exclusive.share': '专属分享',
  'visit.reserve.exclusive.share.tip': '链接中包含访客个人信息，访客可对已有信息进行编辑或补充',
  'visit.reserve.information.filling': '访客信息填写',
  'visit.visitor.fill.sharepage.card.name': '尊敬的{visitorName}，您好！',
  'visit.visitor.fill.sharepage.card.name.placeholder': '访客',
  'visit.visitor.fill.sharepage.card.universal.tip':
    '{inviterName}邀请您来访，请识别二维码填写您的个人信息',
  'visit.visitor.fill.sharepage.card.exclusive.tip':
    '{inviterName}邀请您来访，请识别二维码补充或修改您的个人信息',
  'visit.visitor.fill.bottom.tip':
    '通用链接仅在访问开始时间前有效，专属链接仅在该访客的访问状态是“未开始”时有效。',
  'app.visitors.meeting.filter': '筛选',
  'app.visitors.meeting.hour': '小时',
  'app.reserve.copy.link.tip': '该访客{value}，无法复制链接或生成二维码',
  'visit.reserve.visitor.repeat.current.chooes.user.phone.tip': '访客手机不能和当前拜访对象相同',
  'visit.reserve.visitor.repeat.current.chooes.user.mail.tip': '访客邮箱不能和当前拜访对象相同',
};
