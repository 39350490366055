import { message } from 'antd';
import { formatMessage } from 'umi';
import * as service from './service';

export default {
  namespace: 'privacyAndService',
  state: {
    privacyUrl: []
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getPrivacyURL(_, { call, put }) {
      const { code, data, msg } = yield call(service?.getPrivacyURL);
      if (code === 0) {
        const url = data?.find(item => item.paramKey === 'policy_url')?.paramValue
        yield put({ type: 'setState', payload: { privacyUrl: url } });
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
  },
  subscriptions: {
    set({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname.endsWith('/cloud/privacy') || pathname.endsWith('//cloud/privacy/')) {
          dispatch({ 
            type: 'getPrivacyURL'
          })
        }
      });
    },
  },
};