/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'app.setPassword.oldPassword': '原始密码',
  'app.setPassword.oldPassword.tip': '原始密码不能为空',
  'app.setPassword.newPassword': '新密码',
  'app.setPassword.newPassword.tip': '新密码不能为空',
  'app.setPassword.againPassword': '确认密码',
  'app.setPassword.againPassword.tip': '确认密码不能为空',
  'app.setPassword.passwordAgain':
    '请输入8到16位密码（必需包含数字及字母，可包含如下特殊字符：.!@#$%^&*-_）',
  'app.setPassword.isOk': '确定',
  'app.setPassword.operate.fail': '操作失败',
  'app.setPassword.operate.success': '操作成功',
  'app.setPassword.operate.oldPassword': '请填写原始密码',
  'app.setPassword.operate.againNew': '请输入新的密码',
  'app.setPassword.operate.againNew.1': '请再次输入新的密码',
};
