/**
 * Created by XuQiang on 2017/6/7.
 */

export default {
  'reserve.new': 'New',
  'reserve.visitor.title': 'Visitor',
  'reserve.visitor.name': 'Name',
  'reserve.visitor.name.tip': 'name',
  'reserve.visitor.name.error': 'Name cannot contain special characters',
  'reserve.visitor.name.tip_': 'Name',
  'reserve.visitor.company': 'Company',
  'reserve.visitor.company.tip': 'company',
  'reserve.visitor.company.tip_': 'Company',
  'reserve.visitor.export': 'Export',
  'reserve.visit.telephone': 'Mobile',
  'reserve.visit.telephone.tip': 'mobile',
  'reserve.visit.telephone.tip_': 'Mobile',
  'reserve.visit.telephone.error': 'Please input correct mobile',
  'reserve.visit.email': 'Email',
  'reserve.visit.email.tip': 'email',
  'reserve.visit.email.tip_': 'Email',
  'reserve.visit.email.error': 'Please input correct email',
  'reserve.visit.cause': 'Purpose',
  'reserve.visit.cause_': 'Purpose',
  'reserve.visit.cause.tip': 'Please select visitor purpose',
  'reserve.visit.cause.tip_': 'Please select visitor purpose',
  'reserve.visit.time': 'Arrival Time',
  'reserve.visit.time.tip': 'Please select time',
  'reserve.visit.time.tip_': 'Please select time',
  'reserve.visit.address': 'Location',
  'reserve.visit.address.tip': 'Please select location',
  'reserve.visit.address.tip_': 'Please select location',
  'reserve.visit.elseAddress': 'Other address',
  'reserve.visit.booking': 'Book meeting room',
  'reserve.visit.booking.tip1': 'Please fill in the date first',
  'reserve.visit.booking.tip2': 'Custom reception sites do not support booking room',
  'reserve.visit.booking.tip3': 'Please choose location',
  'reserve.submit': 'Save',
  'reserve.cancel': 'Cancel Invitation',
  'reserve.delete': 'Delete',
  'reserve.resend': 'Resend Invitation',
  'reserve.print': 'Print Sign',
  'reserve.delay': 'Edit',
  'reserve.again': 'Reinvite',
  'reserve.sign.out': 'Sign Out',
  'reserve.time': 'Time',
  'reserve.time_': 'Time：',
  'reserve.view': 'Details',
  'reserve.sign.in.time': 'Sign in Time：',
  'reserve.sign.in.time_': 'Sign in Time',
  'reserve.sign.out.time': 'Sign out Time：',
  'reserve.sign.out.time_': 'Sign out Time',
  'reserve.sign.accompanying_': 'accompanying',
  'reserve.visitor.host': 'Host Employee',
  'reserve.visitor.host.placeholder': 'Please choose the host employee',
  'reserve.visitor.template': 'Template',
  'reserve.visitor.import': 'Import',
  'reserve.visitor.delete': 'Delete',
  'reserve.map.place': 'Please choose the right address',
  'reserve.emailOrPhone': 'Email and phone, please fill in at least one',
  'reserve.intl.phone': 'International number please add an international area code,e.g:+852',
  'visit.reserve.type': 'Visitor Source',
  'visit.reserve.type.0': 'Invited Visitor',
  'visit.reserve.type.1': 'Self-booking',
  'visit.reserve.type.2': 'Walk-in Visitor',
  'visit.reserve.type.3': 'Group Invitation',
  'reserve.visit.type': 'Visitor Type',
  'reserve.visit.type_': 'Please choose the type of visitor',
  'reserve.visit.type_0': 'General Visitor',
  'reserve.visit.type_1': 'VIP Visitor',
  'reserve.visit.face': 'Face photos (used for quick entrance of the face)',
  'reserve.visit.time.exceed': 'The appointment time should exceed the current time of 5 minutes',
  'reserve.tableFooter.tip1': 'No invites yet, to',
  'reserve.tableFooter.tip2': 'create',
  'reserve.tableFooter.tip3': 'one',
  'reserve.startTime': 'start time',
  'reserve.startTime.tip1': 'The start time can not null',
  'reserve.startTime.tip2': 'The start time should be after the visit time',
  'reserve.endTime': 'End time',
  'reserve.endTime.tip1': 'The end time can not null',
  'reserve.endTime.tip2': 'The meeting time should be more than 15 minutes',
  'reserve.meetingRoom': 'Room',
  'reserve.meetingDuration': 'Duration',
  'reserve.meetingDuration.tip': 'Duration can not null',
  'reserve.meetingRoom.tip': 'Room can not null',
  'reserve.ass': 'Add Visitor(s)',
  'reserve.del.record': 'Delete selected record',
  'reserve.no.invitee': 'Please fill in the visitor information (at least one)',
  'reserve.invitee.tip': 'Please confirm the visitor information currently being edited',
  'reserve.15': '15 min',
  'reserve.30': '30 min',
  'reserve.45': '45 min',
  'reserve.60': '1 hour',
  'reserve.90': '1.5 hours',
  'reserve.120': '2 Hours',
  'reserve.180': '3 Hours',
  'reserve.240': '4 Hours',
  'reserve.300': '5 Hours',
  'reserve.360': '6 Hours',
  'reserve.420': '7 Hours',
  'reserve.480': '8 Hours',
  'reserve.540': '9 Hours',
  'reserve.600': '10 Hours',
  'reserve.660': '11 Hours',
  'reserve.720': '12 Hours',
  'reserve.change.tip': 'Change invitation does not support adding new visitors',
  'reserve.change.tip2': 'Change invitation does not allow deletion of visitors',
  'reserve.change.tip3': 'Change invitation does not allow modification of visitor information',
  'reserve.visit.time.than.meeting.time':
    'The meeting start time shall not be earlier than the visiting time',
  'reserve.visitor.card.No': 'ID Card',
  'reserve.confirm.epidemic.code': 'Epidemic prevention code',
  'reserve.visit.time.than.now.time':
    'The meeting start time cannot be earlier than the current time ',
  'reserve.visit.time.than.now.time5':
    'The meeting start time should be 5 minutes after the current time',

  'reserve.visit.car.number': 'License plate No.',
  'reserve.visit.car.number.tip_': 'Please enter the license plate No.',
  'reserve.visit.car.number.error': 'Please enter the correct License plate No.',

  'reserve.leave.time.than.visit.time':
    'The leaving time should exceed the visit time by 15 minutes',
  'reserve.visit.time.end': 'Leaving Time',
  'reserve.visit.end.time.than.meeting.time':
    'The start time of the meeting shall not be later than the leaving time ',
  'reserve.meeting.time.than.meeting.end.time':
    'The end time of the meeting should be 15 minutes later than the start time of the meeting',
  'reserve.visit.tip1': 'The leaving time should exceed the visit time by 15 minutes',

  'reserve.leave.time': 'Please select the leaving time',

  'reserve.visit.private.office': 'Private office',
  'reserve.visit.share.office': 'Shared office',

  'reserve.leave.time.than.visit.time.noAcross':
    'Non Cross Day invitation, please check the visit time',
  'reserve.visitor.name.add': 'Add visitor',
  'reserve.leave.time.than.meeting.time.noAcross':
    'Non Cross Day invitation, please check the meeting time',

  'reserve.visit.meeting.name': 'Meeting Room',
  'reserve.visit.meeting.name.choose.tip': 'Please reselect the meeting room',
  'reserve.visit.meeting.room.none': 'Temporarily no use meeting room',
  'reserve.visit.meeting.name.choose.place': 'Please select the meeting room',
  'reserve.visit.meeting.theme': 'Subject',
  'reserve.visit.meeting.theme.placeholder': "{realName}'s meeting",

  'reserve.visit.now.after.meeting.start':
    'The meeting check-in time or the meeting has started, cannot be changed ',
  'reserve.visit.now.after.meeting.start.cancel':
    "meeting into the check-in time or has begun, invited' cannot be cancelled",

  'reserve.detail.epit.title.healthCode': 'Health code',
  'reserve.detail.epit.title.tourCode': 'Travel card',
  'reserve.detail.epit.title.vaccination': 'Vaccine certificate',
  'reserve.detail.epit.title.nucleic': 'Nucleic acid report',
  'reserve.visit.across.max.time': 'The maximum duration of Cross Day invitation is {day} days',
  'reserve.visit.visitDayNum.vst.time': 'Arrival time is no more than the current date {day} days',
  'reserve.visit.visitDayNum.smt.time':
    'The meeting start time can not more than the current date {day} days',

  'reserve.visit.id.card': 'ID Card',
  'reserve.visit.id.card.tip_': 'Please enter the ID card No.',
  'reserve.visit.id.card.error': 'Please enter the correct ID Card',
  'reserve.visit.id.card.error.validate': 'The visitor ID Card is not correct',
  'reserve.visit.meeting.need.approval.tip': '{tipStr} need approval',
  'reserve.visit.delete.visitor.confirm.text': 'Are you sure you want to delete this invitation?',
  'reserve.visit.work.day.visit.time.error': 'Please select the right time, working time: {time}',
  'reserve.visit.work.day.visit.date.error':
    'Reservations are not allowed on holidays, please select weekdays.',
  'visit.meeting.booking.normal_day_meeting_limit':
    "Is only allowed the booking time' for {time} minutes of meeting",
  'visit.meeting.booking.step.times.limit.tip':
    'Only {days} days are allowed for scheduling meetings',
  'visit.reserve.visitor.edit.send.notice':
    'Whether update notifications need to be sent to visitors',
  'visit.reserve.visitor.edit.send.notice.yes': 'Yes',
  'visit.reserve.visitor.edit.send.notice.no': 'No',
  'visit.meeting.booking.agenda.visit.tip':
    'This invitation booking contains agenda, does not support the visitor side modify visit time',
  'visit.meeting.booking.agenda.meeting.tip':
    'Added the meeting agenda, does not support the visitor side modification time',
  'visit.reserve.visit.after.now.add.five':
    'Book containing the agenda, and the current time from the visit time less than 5 minutes, do not support the change.',
  'visit.reserve.visit.telephone.error': 'Please enter the correct mobile',
  'visit.reserve.visit.email.error': 'Please enter the correct email',
  'visit.reserve.visit.telephone.or.email.error': 'Please enter your mobile phone or email',
  'visit.reserve.across.day.visit.time.error':
    'visit time start date and end date cannot be the same day',
  'visit.reserve.across.day.meeting.time.error':
    'Across the day meeting can not start date and end date for the same day ',
  'visit.visitor.information.count.tip1':
    'The current reception venue {timeFormat} can invite {num} more visitors',
  'visit.visitor.information.count.tip2':
    'Visitor number is beyond the current reception site {timeFormat} to invite number',
  'visit.visitor.information.count.tip3':
    'They can receive today the number of visitors has reached its limit',
  'visit.visitor.information.count.tip4':
    'The number of visitors is beyond the current reception site can invite number',
  'reserve.fill': 'Visitor Fill',
  'reserve.more.operations': 'More Operations',
  'visit.visitor.fill.meeting.tip':
    'If you have used the "Visitor Refill" function in this invitation, the link you have shared with your visitor will be invalid after you open the "Book a Meeting Room". If you have not used the " Visitor Refill" function, please ignore this tip.',
  'reserve.visit.visitor.check.out.title': 'Check-out successfully',
  'reserve.visit.visitor.check.out.text':
    'Please remind the visitors, {value} minutes out in store content ark goods (if already take out, please ignore)',
  'reserve.visit.visitor.check.out.ok': 'OK',

  'reserve.visit.map.type.label': 'Map Type',
  'reserve.visit.map.amap': 'Amap',
  'reserve.visit.map.google': 'Google Maps',
  'reserve.visit.map.mapValue.label': 'Office Location Navigation Mark',
  'reserve.visit.map.mapValue.place': 'Please enter and select address',
  'reserve.visit.map.more.address.label': 'Detailed Address',
  'reserve.visit.map.more.address.place':
    'For example: XX Floor, XX Building, XX Road, XX City, XXX Province',
  'reserve.visit.map.more.location.label': 'Name of reception place',
  'reserve.visit.map.more.location.place': 'Please enter reception place',
  'reserve.visit.map.position.label': 'Mark the reception navigation location',
  'reserve.visit.map.position.place': 'Please enter',
  'reserve.visit.map.position.error': 'Please enter the correct navigation position',
  'reserve.visit.map.position.error2':
    'Please enter the name of the reception location and navigation location',
  'reserve.visit.map.position.tip':
    'Find the location of the office on Google Maps, click the Share button for that location, then copy the share link and fill it in here',
  'visitor.form.check.information.required':
    'The required information for {value} is not completed, please fill in before submitting.',
  'reserve.visit.invitationCode.full.btn': 'Confirm',
};
