/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  602001: '用户名称不能为空',
  602002: '获取上传人脸的URL地址失败',
  602003: '上传第三方人脸库失败',

  603001: '二维码不存在，请检查',

  603002: '二维码使用次数已达到最大',
  603003: '二维码总有效期已到期',

  601013: '操作成功',

  601028: '添加或更新门禁报修单失败',
  601029: '开门失败，请查看配置',
  'menu.sas.app.smartaccess': '智能门禁',
  'menu.sas.app.qrCode': '我的二维码',
  'menu.sas.app.faceDatabase': '我的人脸库',
  'menu.sas.app.faultRepair': '故障报修',
  'menu.sas.app.myAccess': '我的门禁',

  601034: '门禁归属区域为空！',
  601035: '模拟开门失败！',
  601036: '配置文件Pattern属性值为为空！',
  601037: '控制器编号为空！',
  601038: 'door对象为空，请检查门禁配置是否存在！',
  601039: '门禁状态停用',
  601040: '控制器无法连接或控制器编号错误',
  601041: '逻辑门号错误',
  601042: '门禁已停用',
  601043: '二维码已到期或达到最大次数',
  602006: '文件为空！',

  601044: '开门失败！逻辑门号设置不正确',
  601045: '指令未发送成功！请检查控制器是否连接和controllerSN配置',

  601046: '当前卡号不存在',
  601047: '文件为空！',
  601048: 'zip解压异常',
  601049: '暫無開門權限',
  601100: '注册时找不到人脸',
  601101: '注册时有多张人脸',
  601102: '注册时人脸太小',
  601103: '注册时照片模糊',
  601104: '识别时找不到人脸',
  601105: '识别时人脸太小或模糊',
  601106: '识别时人脸组不存在',
  601107: '删除时人脸组不存在',
  601108: '删除时人员id不存在',
  601109: '删除人员id对应的照片文件不存在',
  601110: '查询用户id时人脸组不存在',
  601111: '查询用户id时起始序号大于结束序号',
  601112: '查询用户id下人脸图像时人脸组不存在',
  601113: '查询用户id下人脸图像时用户id不存在',
  601114: '查询用户id下人脸图像时人脸图像文件不存在',
  601115: '下载特征文件时，文件不存在',

  601116: '人脸识别查询为空',
  601117: '人脸无法识别',
  601118: '人脸照片与其他用户重复',
  601119: '人脸阈值设置有误',
  601120: '人脸照片与该用户现有人脸照片不一致',
  601065: '未启用威发人脸库功能',
  601070: '获取用户名失败',
  601071: '数据长度超过限制',
  601121: '人脸带有口罩',
};
