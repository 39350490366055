import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';
import { USER_SIGN_TYPE } from '@/pages/Meeting/utils/constant';

export const checkIn = meetingId => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/signIn`, {
    method: 'POST',
    body: JSON.stringify({ meetingId, checkInType: USER_SIGN_TYPE.QR_CODE }),
  });
};

export const listNoCheckMeeting = roomId => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/listNoCheckMeeting?roomId=${roomId}`, {
    method: 'GET',
  });
};

export const hasAuth = ({ roomId, userId, tenantId }) => {
  return request(`/${RESTFUL_PATH.meeting}/board-info/hasAuth`, {
    method: 'POST',
    body: JSON.stringify({ roomId, type: 0, userId, tenantId }),
  });
};
