export default {
  // 新建會議
  meeting_name: '會議名稱',
  meeting_name_tip: '請輸入會議名稱',
  meeting_introduce: '會議簡介',
  meeting_introduce_tip1: '請輸入會議簡介',
  meeting_introduce_tip2: '會議簡介長度不能小於10個字元',
  meeting_address: '會議選址',
  meeting_address_tip: '請選擇會議地址',
  meeting_detail: '詳細地址',
  meeting_detail_tip: '請輸入會議詳細地址',
  meeting_continue: '保存並繼續',
  meeting_text: '單行文本',
  meeting_validity: '報名有效期',
  meeting_validity_tip: '請輸入報名有效期',
  meeting_content: '報名表內容',
  meeting_set: '報名表設置',
  meeting_isRAE: '(必填項，可編輯)',
  meeting_isEdit: '(可編輯)',
  meeting_delForm: '確定要刪除表單嗎?',
  meeting_isRequired: '(必設項)',
  meeting_add: '添加',
  meeting_signSuccess: '簽到成功',
  meeting_signFail: '簽到失敗',
  meeting_signValidity: '簽到有效期',
  meeting_signValidity_tip: '請輸入簽到有效期',
  meeting_successInfo: '成功提示資訊',
  meeting_successInfo_tip: '請輸入成功提示資訊',
  meeting_failInfo: '簽到失敗提示',
  meeting_failInfo_tip: '請輸入簽到失敗提示',
  meeting_textSet: '單行文本設置',
  meeting_titleSet: '標題設置',
  meeting_titleSet_tip: '請輸入標題',
  meeting_presetValue: '預設值',
  meeting_tipInfo: '提示資訊',
  meeting_required: '是否必填',
  meeting_info: '會議資訊',
  meeting_setForm: '設置報名表',
  meeting_setSign: '設置簽到資訊',
  meeting_update: '更新',
  meeting_create: '創建',
  meeting_cancel: '取消',
  meeting_createSuccess: '創建成功',
  meeting_name_: '會議名稱：',
  meeting_entryUrl_: '會議報名地址：',
  meeting_entryUrlCode_: '報名表地址二維碼：',
  meeting_entryValidity_: '報名表有效期：',
  meeting_entry_tip: ' 報名表地址僅在報名有效期內生效，提前及過期訪問將無法填寫及提交',
  meeting_detail_: ' 查看詳情',
  // 會議詳情
  meeting_addUser: '添加用戶',
  meeting_updatePhoto_tip: '請上傳您的照片',
  meeting_userName: '姓名',
  meeting_userName_tip: '請輸入姓名',
  meeting_company: '公司',
  meeting_company_tip: '請輸入公司',
  meeting_phone: '手機',
  meeting_mail: '郵箱',
  meeting_phone_tip1: '請輸入手機號',
  meeting_phone_tip2: '請輸入正確的手機號',
  meeting_required_halfTip: '不能為空',
  meeting_num: '序號',
  meeting_yet: '未簽到',
  meeting_notYet: '已簽到',
  meeting_operation: '操作',
  meeting_retroactive: '補簽',
  meeting_delUser_tip1: '確定要刪除此條記錄嗎?(此操作會導致該用戶無法簽到)',
  meeting_delUser_tip2: '確定要刪除此條記錄嗎?',
  meeting_delUser: '刪除',
  meeting_noUser_tip1: '還沒有用戶哦，您可以添加用戶',
  meeting_noUser_tip2: '或給用戶分享報名表地址：',
  meeting_overview: '會議概覽',
  meeting_tableCount: '報名表總數',
  meeting_viewCount: '報名表流覽量',
  meeting_daySubmitCount: '今日報名表提交量',
  meeting_signCount: '簽到量',
  meeting_projectIntro_: '會議介紹：',
  meeting_createDate_: '創建時間：',
  meeting_entryFormUrl_: '報名表地址：',
  meeting_signValidity_: '簽到有效期：',
  meeting_loginCode_: '簽到登陸碼：',
  meeting_userData: '用戶數據',
  meeting_search_tip: '請輸入你要搜索的姓名或手機號',
  // index
  meeting_userCount: '用戶數',
  meeting_createDate: '創建時間',
  meeting_notFilled: '未填寫',
  meeting_loginCode: '登錄碼',
  meeting_status: '狀態',
  meeting_creating: '創建中',
  meeting_underway: '進行中',
  meeting_expired: '已過期',
  meeting_delProject_tip: '確定要刪除該訪客資訊嗎?',
  meeting_new: '新建會議',
  meeting_to: '至',
  // 報名表
  meeting_formTitle: '填寫報名表',
  meeting_input: '請輸入',
  meeting_noTime: '未到報名時間',
  meeting_sign_time: '報名時間：',
  meeting_success: '報名成功',
  meeting_success_tip1: '與會當日您可通過刷臉完成簽到',
  meeting_success_tip2: '二維碼可用作簽到憑證，請截屏保存此頁面以備用',

  // 思科遷移
  meeting_attending_area: '參會區',
  meeting_new_attending_area: '創建參會區',
  meeting_edit_attending_area: '編輯參會區',
  meeting_serial_number: '序號',
  meeting_attending_area_name: '參會區名稱',
  meeting_attending_area_name_placeholder: '請輸入參會區名稱,最多20個字元',
  meeting_user_count: '用戶數',
  meeting_create_time: '創建時間',
  meeting_time: '會議時間',
  meeting_not_filled: '未填寫',
  meeting_status_in_create: '創建中',
  meeting_status_process: '進行中',
  meeting_status_end: '已過期',
  meeting_operation_edit: '編輯',
  meeting_operation_view: '查看',
  meeting_operation_del: '刪除',
  meeting_del_confirm: '確定要刪除這個專案嗎?',
  meeting_button_confirm: '確定',
  meeting_button_cancel: '取消',
  meeting_subject: '會議主題',
  meeting_subject_placeholder: '請輸入會議主題，長度不能小於6個字元',
  meeting_start_time: '開始時間',
  meeting_start_time_placeholder: '請選擇開始時間',
  meeting_start_time_error: '開始時間必須早於結束時間',
  meeting_end_time: '結束時間',
  meeting_end_time_placeholder: '請選擇結束時間',
  meeting_end_time_error: '結束時間必須晚於開始時間',
  meeting_attending_area_save_success: '保存成功',
  meeting_attending_area_del_success: '刪除成功',
  meeting_attendees: '參會人員',
  meeting_sign_in_quantity: '簽到量',
  meeting_sign_in_avatar: '人臉照片（用於刷臉快速入場）',
  meeting_user_name: '姓名',
  meeting_user_telephone: '手機',
  meeting_user_meeting_time: '簽到時間',
  meeting_user_sign_in_time: '狀態',
  meeting_sign_in_status: '已簽到',
  meeting_fill_avatar: '輸入參會碼以重新上傳人臉照片',
  meeting_fill_avatar_tabs: '補傳人臉照片',
  meeting_participant_code: '參會碼',
  meeting_participant_code_placeholder: '請輸入參會碼',
  meeting_query_avatar: '查詢人臉照片',
  meeting_avatar: '人臉照片',
  meeting_avatar_placeholder: '請選擇人臉照片',
  meeting_avatar_already_have: '人臉照片已經上傳，不用再補傳',
  meeting_attendees_export: '導出參會區人員',
  meeting_user_company: '公司',
  meeting_button_reset: '重新查詢',
  meeting_avatar_update_err: '人臉照片上傳錯誤，請稍後嘗試',
  meeting_service_err: '伺服器異常，請稍後再試！',
  meeting_upload_err: '上傳失敗，請重試！',
  meeting_update_face_success: '補傳人臉照片成功',
  meeting_please_upload_face: '請上傳您的照片',
  meeting_export: '導出',
};
