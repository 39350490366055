export default {
  'menu.lighting.manage': 'lighting management',
  'title.humidity': 'Indoor humidity',
  'title.env': 'Environmental Index',
  'title.humidity.sub': 'Reference: 30%-80% comfort',
  'title.wendu': 'Indoor temperature',
  'title.wendu.sub': 'refer to: 15℃-20℃ comfort',
  'title.co2.sub': 'refer to: ≤800PPM air fresh',
  'title.pm25.sub': 'refer to: <75 air fresh',
  'title.voc.sub': 'refer to: <125 air fresh',
  'title.energy': 'Lighting energy statistics',
  'title.air.energy': 'Air energy statistics',
  'none.area.au': 'No.area without any authorization',
  'none.area.find': 'No available areas found',
  'env.comfort': 'comfort',
  'env.wendu.high': 'Too much temperature',
  'env.wendu.low': 'Lower temperature',
  'env.shidu.shushi': 'comfort',
  'env.shidu.chaoshi': 'wet',
  'env.shidu.ganzao': 'dry',
  'env.co2.0': 'Air fresh',
  'env.co2.1': 'Compliant',
  'env.co2.2': 'Mild pollution',
  'env.co2.3': 'Severe pollution',
  'env.pm25.0': 'Air fresh',
  'env.pm25.1': 'Compliant',
  'env.pm25.2': 'Mild pollution',
  'env.pm25.3': 'Heavy pollution',
  'env.voc.0': 'Comfort',
  'env.voc.1': 'compliant',
  'env.voc.2': 'Mild pollution',
  'title.energy.all': 'Energy consumption statistics',
  'env.filter.0': 'Today',
  'env.filter.1': 'Week',
  'env.filter.2': 'Month',
  'env.filter.3': 'Year',
  'menu.slt.app.lighting': 'Smartlighting',
  'menu.slt.app.report.environment': 'Environment Analysis',
  'menu.slt.app.report.energy': 'Energy Analysis',
  'light.floor': 'Floor',
  'light.select.floor': 'Select floor',
  'light.area': 'Area',
  'light.select.area': 'Select Area',

  '501000': 'Panel information does not exist!',
  '501001': 'Scene update failed',
  '501002': 'Failed to get zone status',
  '501003': 'No configuration command',
  '501004': 'Channel update failed',
};
