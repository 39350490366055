import { RESTFUL_PATH } from '@/utils/constant';

export default {
  namespace: 'wechatAuth',

  state: {},

  effects: {},

  reducers: {},

  subscriptions: {
    setup({ history: umiHistory }) {
      return umiHistory.listen(({ pathname, query }) => {
        if (pathname === '/wdfclient/wechatAuth') {
          const wechatAuth = localStorage.getItem('wechatAuth') || '';
          if (query.aId) {
            let redirectUrl = `${window.location.origin}/${RESTFUL_PATH.wdf}/wdframe/wechat/agent?aId=${query.aId}`;
            if (query.from === 'dingtalk') {
              redirectUrl = `${window.location.origin}/${RESTFUL_PATH.wdf}/wdfClient/dingding/app?appId=${query.aId}`;
            } else if (query.from === 'feishu') {
              redirectUrl = `${window.location.origin}/${RESTFUL_PATH.wdf}/wdfClient/feishu/app?appId=${query.aId}`;
            }

            if (wechatAuth) {
              redirectUrl = `${redirectUrl}&token=${wechatAuth}`;
            }
            window.location.href = redirectUrl;
          }
        }
      });
    },
  },
};
