export default {
  'visitor.mobile.invitation.code.visitor.name': 'hello, {name}!',
  'visitor.mobile.invitation.code.visitor.clues':
    'The following information can help you to visit, I wish you visit!',
  'visitor.mobile.invitation.code.visitor.inviteCode': 'Invite code',
  'visitor.mobile.invitation.code.visitor.look.navigation': 'View',
  'visitor.mobile.invitation.code.visitor.signTime': 'Visiting time{colon}',
  'visitor.mobile.invitation.code.visitor.address': 'Location{colon}',
  'visitor.mobile.invitation.code.visitor.meetingInfo': 'Meeting information{colon}',
  'visitor.mobile.invitation.code.visitor.click.look': 'View',
  'visitor.mobile.invitation.code.healthCode.tip.title': 'Abnormal health code',
  'visitor.mobile.invitation.code.tourCode.tip.title': 'Abnormal travel card',
  'visitor.mobile.invitation.code.healthCode.tip.failure': 'Health code failure',
  'visitor.mobile.invitation.code.tourCode.tip.failure': 'Travel card failure',
  'visitor.mobile.invitation.code.btn.cancel': 'Cancel',
  'visitor.mobile.invitation.code.upload.again': 'Upload again',
  'visitor.mobile.invitation.code.locker.tip': 'Guest lockers are available',
  'visitor.mobile.invitation.code.visitor.wifi': 'WIFI Information{colon}',
  'visitor.mobile.invitation.code.visitor.wifi.title': 'WIFI Information',
  'visitor.mobile.invitation.code.visitor.wifi.name': 'WIFI',
  'visitor.mobile.invitation.code.visitor.wifi.code': 'Verification Code',
  'visitor.mobile.invitation.code.visitor.wifi.until': 'Validity',
  'visitor.mobile.invitation.code.visitor.wifi.account': 'Username',
  'visitor.mobile.invitation.code.visitor.wifi.password': 'Password',
  'visitor.mobile.invitation.code.visitor.wifi.day': '{num} day{plural}',
  'visitor.mobile.invitation.code.visitor.wifi.hour': '{num} hour{plural}',
  'visitor.mobile.invitation.code.visitor.wifi.min': '{num} minute{plural}',
  'visitor.mobile.invitation.code.visitor.detail.address': 'Detailed address',
  'visitor.mobile.invitation.code.accepted': 'Accepted',
};
