export default {
  'home.timeLater': 'Start{timeStr} later',
  'home.timeStop': 'End after {timeStr}',
  'home.message.knowIt': 'Noted',
  'home.message.refuse': 'Reject',
  'home.message.accept': 'Accept',
  'home.message.gotit': 'Success',

  'home.index.reserved': 'Reserved',
  'home.index.processing': 'In progress',
  'home.index.auditing': 'Pending',
  'home.index.filter': 'Filter',

  'home.audit.refuse': 'Rejected',
  'home.audit.adopt': 'Approved',
  'home.audit.submit': 'Submit',
  'home.audit.refusal': 'Refusal reason',
  'home.audit.emptyRefusal': 'Please input the reasons for refusal',
  'home.audit.noneAudit': 'No pending items',
  'home.audit.meetingMode': 'Frequency',
  'home.audit.meetingRange': 'Range of Recurrence',
  'home.audit.meetingMode.day': 'By Day',
  'home.audit.meetingMode.workday': 'By Weekday',
  'home.audit.meetingMode.week': 'By Week',
  'home.audit.meetingMode.month': 'By Month',
  'home.audit.meetingMode.year': 'By Year',
  'home.audit.meetingMode.normal': "Does not repart",
  'home.audit.meetingRange.forever': 'No end date',
  'home.audit.meetingRange.repeat': 'Recurrence ',
  'home.audit.meetingRange.time': 'occurence(s)',
  'home.audit.repeat.label': 'Recurrence',
  'home.audit.adopt.success': 'Approved',
  'home.audit.refuse.success': 'Success',
  'home.none.meeting': 'No new meeting~',
  'home.none.history': 'No meeting history~',
  'home.none.audit': 'No pending items~',
  'home.text.book': 'Meeting room reservation',

  'home.index.history.label': 'History',
  'home.index.new.label': 'New Meeting',
  'home.index.message.label': 'New message',
  'home.index.audit.label': 'Meeting Review ',
  'home.index.unit.session': ' ',
  'home.index.unit.pcs': ' ',
  'home.index.unit.day': ' day ',
  'home.index.unit.days': ' day(s) ',
  'home.index.unit.hours': ' hr ',
  'home.index.unit.hourss': ' hrs ',
  'home.index.unit.minute': ' mins ',
  'home.index.unit.start': 'Start after',
  'home.index.unit.end': 'End after',
  'home.index.unit.start_now': 'Start soon',
  'home.index.unit.end_now': 'End soon',
  'home.index.unit.time.long': 'Duration ',
  'home.index.meeting.status0': 'Not started',
  'home.index.meeting.status1': 'In progress',
  'home.index.meeting.status2': 'Ended',
  'home.index.meeting.status3': 'Canceled',
  'home.index.meeting.status4': 'Rejected',
  'home.index.meeting.status5': 'Pending approval',
  'home.index.meeting.status6': 'Filed',
  'home.index.meeting.status7': 'Progress',
  'home.index.meeting.status8': 'Fail',
  'home.index.meeting.status9': 'Ex under approval',
  meeting_other_no_approved: "Wait for admin's approval",
  'home.index.none': 'No meeting',
  'home.index.none.info': 'Click the "+" button to reserve meeting',
  'home.history.none': 'No meeting history',
  'home.message.none': 'No new message',
  'home.loading': 'Loading...',
  'home.activate': 'Release to refresh',
  'home.deactivate': 'Pull to refresh',
  'home.finish': 'Refreshed',
  'home.myMeeting': 'Meeting List',
  'home.about': 'About',
  'home.booking': 'Booking',
  'home.video': 'Video Meeting',
  'home.initiate': 'Start',
  'home.room': 'Room',
  'home.segment.history': 'Ended',
  'home.segment.pending': 'To participate',
  'home.segment.mine': 'Publish',
  'home.filter.my.meeting': 'Meeting List',
  'home.filter.appointment': 'Invitation Review ',
  'home.filter.attending': 'As Attendee(s)',
  'home.filter.start.time': 'Start Time',
  'home.filter.end.time': 'End Time',
  'home.search.placeholder': 'Meeting subject',

  'meeting.detail.refuse.tip': 'Reject the current meeting?',
  'meeting.detail.close.tip': 'End current meeting?',
  'meeting.detail.confirm.cmma':
    'The video meeting is still in progress. Please end the meeting on control page.',
  'meeting.detail.cancel.tip': 'Are you sure you want to cancel the meeting?',
  cancel_loop_meeting: 'Cancel all recurring meetings of this series?',
  modify_loop_meeting: 'Modify all recurring meetings of this series?',
  refuse_loop_meeting: 'Reject all recurring meetings of this series?',
  adopt_loop_meeting: 'Approve all recurring meetings of this series?',
  cancel_current_cycle_meeting_confirm: 'Only cancel this meeting',
  cancel_cycle_meeting_confirm: 'Cancel all recurring meetings of this series',
  edit_current_cycle_meeting_confirm: 'Only edit this meeting',
  edit_cycle_meeting_confirm: 'Edit all recurring meetings of this series',
  'home.action.meeting.detail': 'Meeting Detail',
  'home.action.meeting.service': 'Meeting Service',
  'home.action.meeting.edit': 'Edit Meeting',
  'home.action.meeting.sign': 'Check-in Management',
  'home.action.meeting.cancel': 'Cancel Meeting',
  'home.action.meeting.delete': 'Delete Meeting',
  'home.action.meeting.delay': 'Extend Meeting',
  'home.action.meeting.delay.new': 'Extended meeting (minutes)',
  'home.action.meeting.end': 'End Meeting',
  'home.action.meeting.control': 'Meeting Control',
  'home.action.meeting.restart': 'Re-book Meeting',
  'home.action.meeting.reapply': 'Reapply Meeting',
  'home.action.meeting.adopt': 'Approved',
  'home.action.meeting.refuse': 'Rejected',
  'home.action.meeting.polling': 'Polling',
  'home.action.meeting.start': 'Start Meeting',
  'home.action.meeting.join': 'Join Meeting',
  'home.action.cancel': 'Back',
  'home.action.meeting.sign.value1': 'Please enter 6-bit invitation code',
  'home.action.meeting.sign.value2': 'Please enter the invitation code.',

  cancel_meeting_reason_placeholder: 'Reason for cancelation (optioanl).',
  refuse_meeting_reason_placeholder: 'Please enter rejection reason',
  'home.action.meeting.seat.manage': 'Seat Arrangement',
  'home.action.meeting.seat.view': 'Seat Arrangement',
  'home.action.meeting.net.join': 'Join Net Meeting',
  'home.close.cmma.sure': 'Whether to release video conference resources at the same time？',
  'meeting.book.video': 'video',
  'meeting.book.Webex': 'Webex',
  'meeting.book.Tecent': 'Tencent Conference',
  'meeting.book.ZOOM': 'ZOOM',
  'meeting.book.Teams': 'Teams',
  'meeting.book.Feishu': 'Lark Conference',
  'meeting.list.column.duration': 'Duration (minutes)',
  'home.action.meeting.evaluation': 'Evaluate',
  'home.action.meeting.evaluation.show': 'See Evaluation',
  'menu.meeting.svc_approval': '服务确认',
  tab_processed: 'processed',
  tab_pending: 'Pending',
  btn_accepted: 'Approved',
  btn_approved: 'Passed',
  btn_rejected: 'Rejected',
  btn_daiding: 'Not sure whether to accept',
  btn_accept_new: 'accepted',
  svc_approval_error: 'This record has been approved by another person and cannot be re approved',
  'home.action.svc.approval': 'Service Review',
  'home.action.svc.detail': 'Service Details',
  btn_reject: 'Reject',
  btn_accept: 'Approve',
  meeting_status_error: 'The current meeting has expired',
  status_error: 'Expired',
  btn_accept_new_text: 'Your have {text} the meeting invitation',
  btn_accept_new_text_accept: 'You have accepted the meeting invitation',
  btn_accept_new_text_reject: 'You have declined the meeting invitation',
  btn_accept_new_text_daiding: 'You are not sure whether to accept the meeting invitation',
  'home.index.acceptText.start': ', The meeting will start after {value} days',
  'home.index.acceptText.start1': 'The meeting will start after {value} days',
  meeting_opened_action_tip: 'Meeting has started, editing is not allowed',
  'meeting.rowData.meetingAgendaNums': '{value} topic(s)',
  meeting_poly_cycle_edit_cancel:
    'CloudMeet recurring meetings only support editing and canceling the first meeting of the series that has not yet started !',
  'meeting.poly.control.no.auth': 'The current user does not have permission to operate',
};
