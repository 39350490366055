/**
 * Created by admin on 2017/11/3.
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

// 审批拒绝
export function refuseByBusinessId(param) {
  return request(`/${RESTFUL_PATH.meeting}/bpm/refuseByBusinessId`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

// 审批通过
export function taskByBusinessId(param) {
  return request(`/${RESTFUL_PATH.meeting}/bpm/taskByBusinessId`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

export function getMyMeetingNumber() {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/getMyMeetingNumber`, {
    method: 'GET',
  });
}

export function getOpenedMeeting(params) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/listMeetings`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function cancelMeeting({ meetingId, recurringId, cancelReason }) {
  if (recurringId) {
    return request(`/${RESTFUL_PATH.meeting}/meeting-info/cycle-cancel`, {
      method: 'POST',
      body: JSON.stringify({ recurringId, cancelReason }),
    });
  }
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/cancel`, {
    method: 'POST',
    body: JSON.stringify({ meetingId, cancelReason }),
  });
}

export function deleteMeeting({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/delete/${meetingId}`, {
    method: 'POST',
  });
}
export function closeMeeting({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/end/${meetingId}`, {
    method: 'POST',
  });
}

export function closeCmma({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/endCmma/${meetingId}`, {
    method: 'POST',
  });
}

export function signForZoomFuc({ param }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-zoom/signInForAuto`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

export function findMeetingsByRecurringId({ recurringId }) {
  return request(
    `/${RESTFUL_PATH.meeting}/meeting-info/findMeetingsByRecurringId?recurringId=${recurringId}`,
    {
      method: 'GET',
    },
  );
}

export async function cycleApi({recurringId,userId,tenantId}) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/recurringMeetingRole?recurringId=${recurringId}&tenantId=${tenantId}&userId=${userId}`);
}

export async function bpmPending({ page }) {
  return request(`/${RESTFUL_PATH.meeting}/bpm/pending?processName=bpm_service`, {
    method: 'POST',
    body: JSON.stringify(page),
  });
}

export async function bpmApproved({ page }) {
  return request(`/${RESTFUL_PATH.meeting}/bpm/approved?processName=bpm_service`, {
    method: 'POST',
    body: JSON.stringify(page),
  });
}

// new
/**
 * 会议服务同意一个任务
 * @param json 任务参数
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function bpmTask(json) {
  return request(`/${RESTFUL_PATH.meeting}/bpm/task`, {
    method: 'POST',
    body: JSON.stringify(json),
  });
}
/**
 * 会议服务拒绝一个任务
 * @param json 任务参数
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function bpmResuse(json) {
  return request(`/${RESTFUL_PATH.meeting}/bpm/refuse`, {
    method: 'POST',
    body: JSON.stringify(json),
  });
}

export async function findMeetingInfoById({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/detail-info/${meetingId}`, {
    method: 'GET',
  });
}

export async function goPolyPage({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/getPolySSO?meetingId=${meetingId}`, {
    method: 'GET',
  });
}
export async function ifHasControlAuth({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/poly-query/ifHasControlAuth?meetingId=${meetingId}`, {
    method: 'GET',
  });
}

// 会议锁定解锁
export async function setRedStatus(params) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/setRedStatus`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

// 获取设备控制模型
export async function getTemp(relationId) {
  return request(
    `/${RESTFUL_PATH.vap}/controlScreenTemplate/selectTemplateUnderResource?relationType=1&relationId=${relationId}`,
    {
      method: 'get',
    },
  );
}
