export default {
  'sws.detail.sws.time': '時間',
  'sws.detail.sws.type': '類型',
  'sws.detail.sws.device': '固定設備',
  'sws.detail.sws.reserve.log': '預訂記錄',
  'sws.detail.sws.cancel.btn': '取消',
  'sws.detail.sws.reserve.btn': '預訂工位',
  'sws.detail.sws.station.no': '工位號',
  'sws.detail.sws.station.user': '使用人',
  'sws.detail.sws.station.user.select': '請選擇',
  'sws.detail.sws.ok.btn': '確定',
  'sws.detail.sws.reserve.ok': '預訂成功',
  'sws.detail.sws.reserve.time.tip': '請選擇預訂時間',
  'sws.detail.sws.reserve.man.tip': '請選擇使用人',

  'sws.stationType.mobile': '共享工位',
  'sws.stationType.fixed': '固定工位',
  'sws.detail.no.data': '暫無數據',
  'sws.detail.reserve.man': '預訂人',

  'sws.detail.week.0': '星期天',
  'sws.detail.week.1': '星期一',
  'sws.detail.week.2': '星期二',
  'sws.detail.week.3': '星期三',
  'sws.detail.week.4': '星期四',
  'sws.detail.week.5': '星期五',
  'sws.detail.week.6': '星期六',

  'sws.detail.ktyd': '跨天預訂的',
  'sws.detail.daiding': '代訂',
  'sws.detail.s.time': '開始時間',
  'sws.detail.e.time': '結束時間',

  'sws.detail.time.diff': '開始時間不能大於結束時間的',
};
