/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'menu.meeting.meeting_app': '智能会议',
  'menu.meeting.subscribe': '会议预订',
  'menu.meeting.mines': '會議列表',
  'menu.meeting.webexmeeting': '在线会议',
  'menu.meeting.videomeeting': '视频会议',
  'menu.workspace.mobile': '智能工位',
  'sws.menu.station.booking': '工位预约',
  'sws.menu.station.repair': '工位报修',
  'sws.menu.station.search': '工位查询',
  'sws.menu.station.records': '预约记录',
  'sws.menu.workspace.recommendBooking':'推荐预订',
  'menu.slt.app.lighting': '智慧照明',
  'menu.slt.app.report.environment': '环境分析',
  'menu.slt.app.report.energy': '能耗分析',
  'menu.sas.app.smartaccess': '智能门禁',
  'menu.sas.app.qrCode': '我的二维码',
  'menu.sas.app.faceDatabase': '我的人脸库',
  'menu.sas.app.faultRepair': '故障报修',
  'menu.sas.app.myAccess': '我的门禁',
  'menu.vst.app.manage': '智能访客',
  'menu.vst.app.reserve': '邀请记录',
  'menu.vst.app.visit': '访问记录',
  'menu.vst.app.confirm': '访客审批',
  'menu.vst.app.statisticalAnalysis': '统计分析',
  'menu.app.index': '主页',
  'menu.app.my': '我的',
};
