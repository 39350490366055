import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export function getAllZones() {
  return request(`/${RESTFUL_PATH.upms}/space/tree`, {
    method: 'GET',
  });
}
export function getShareZoneTree() {
  return request(`/${RESTFUL_PATH.meeting}/room-info/getShareZoneTree`, {
    method: 'GET',
  });
}

export function getAllRooms(params) {
  return request(`/${RESTFUL_PATH.meeting}/room-info/rooms`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function getAllRoomsByNetWork(params) {
  return request(`/${RESTFUL_PATH.meeting}/room-info/rooms`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
export async function getUserZone(){
  return request(`/${RESTFUL_PATH.meeting}/web-info/getUserZone/app`);
}


