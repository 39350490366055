export default {
  'sws.special.booking': '確認預訂',
  'sws.special.reset': '重置',
  'sws.special.booking.empty': '請選擇要預訂的工位',
  'sws.special.confirm.cancel': '確認取消',
  'sws.special.no.location': '請先選擇區域',
  'sws.special.station.location.none': '您沒有選擇區域信息，請進行選擇',
  'sws.special.station.stop.using': '結束使用',
  'sws.search.select.employee.name': '請輸入姓名',
  'sws.special.station.user': '使用人',
  'sws.special.tip': '操作提示',
  'sws.special.tip.text': '是否取消預定該工位',
  'sws.special.yes': '是',
  'sws.special.no': '否',
  'sws.special.cancel': '取消工位',
  'sws.special.Back': '上一步',
  'sws.special.submit': '提交預訂',
  'sws.special.search': '請輸入關鍵字搜索',
  'sws.special.phone': '手機',
  'sws.special.phone.error': '手機格式不正確',
  'sws.special.external.user': '外部人員',
  'sws.special.input.content': '請輸入內容',
  'timezone.name.title.simplified.chinese': '簡體中文',
  401157: '外部訪客姓名不能爲空！',
  401149: "預訂個數不能超過{value1}個"
};
