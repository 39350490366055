export default {
  'sws.sign.out.end': '結束使用',
  'sws.sign.out.delay': '延長使用',
  'sws.sign.out.tip': '操作提示',
  'sws.sign.out.isEnd': '是否結束當前工位使用？',
  'sws.sign.out.end.success': '簽退成功',
  'sws.sign.out.delay.success': '延時成功',
  'sws.sign.out.no.time': '無可用時間',
  401144: '此工位已綁定{value1}個用戶'
};
