import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

function getZoomParticipantInfo({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/getZoomParticipantInfo/${meetingId}`, {
    method: 'GET',
  });
}

export default getZoomParticipantInfo;
