export default {
  //设置界面
  'sws.liftTable.dept': '部门：',
  'sws.liftTable.memory.height': '记忆高度',
  'sws.liftTable.standing.height': '站姿高度',
  'sws.liftTable.sitting.height': '坐姿高度',
  'sws.liftTable.sedentary.reminder': '久坐提醒',
  'sws.liftTable.reminder.method': '提醒方式',
  'sws.liftTable.reminder.duration': '提醒时长',
  'sws.liftTable.lunch.break': '午休免打扰',
  'sws.liftTable.automatic.homing': '自动归位({value}cm)',
  'sws.liftTable.please.enter': '请输入高度',
  'sws.liftTable.minute': '分钟',
  'sws.liftTable.set.success': '设置成功',
  // 控制界面
  'sws.liftTable.hour': '小时',
  'sws.liftTable.stand.today': '今日站立:',
  'sws.liftTable.stand-to-sit.ratio': '今日站坐比',
  'sws.liftTable.standing.number': '站立次数',
  'sws.liftTable.sitting.number': '坐姿次数',
  'sws.liftTable.burn.calories': '燃烧卡路里',
  'sws.liftTable.second-rate': '次',
  'sws.liftTable.current.altitude': '当前高度',
  'sws.liftTable.stand': '站立',
  'sws.liftTable.stand1': '站立：',
  'sws.liftTable.reset': '归位',
  'sws.liftTable.sitting.position': '坐姿',
  'sws.liftTable.sitting.position1': '坐姿：',
  'sws.liftTable.no.control.data': '你当前没有可控制的升降桌哦～',
  // 数据界面
  'sws.liftTable.shared': '共享',
  'sws.liftTable.fixed': '固定',
  'sws.liftTable.no.data': '暂无数据哦～',
  'sws.liftTable.time': '时间：',
  //底部菜单
  'sws.liftTable.control': '控制',
  'sws.liftTable.data': '数据',
  'sws.liftTable.set': '设置',
  401088:'查询的工位家具类型为空或重复'
};
