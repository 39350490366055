export default {
  index_bottom_1: '功能特点',
  index_bottom_2: '日历',
  index_bottom_3: '会议室预订',
  index_bottom_4: '网络会议',
  index_bottom_5: '电话会议',
  index_bottom_6: '客户化设置',
  index_bottom_7: '用户管理',
  index_bottom_8: '统计报表',
  index_bottom_9: '移动端应用',
  index_bottom_10: '产品体验',
  index_bottom_11: '增值服务',
  index_bottom_12: '会议展板',
  index_bottom_13: '企业V话',
  index_bottom_14: '视频会议',
  index_bottom_15: '硬件设备基础',
  index_bottom_16: '价格',
  index_bottom_17: '免费版',
  index_bottom_18: '标准版',
  index_bottom_19: '增强版',
  index_bottom_20: '关于我们',
  index_bottom_21: '关于公司',
  index_bottom_22: '技术支持',
  index_bottom_23: '媒体报道',
  index_bottom_24: '联系我们',
  index_bottom_25: '扫一扫关注威思客公众号',
  index_bottom_26: '公 司：北京威发新世纪信息技术有限公司',
  index_bottom_27: '地 址：北京市东城区长安街1号东方广场东二办公楼19层1907-1909',
  index_bottom_28: '售前咨询：400-685-3160',
  index_bottom_29: '邮 箱：sales@virsical.com',
  index_bottom_30: '2000-2016威发系统有限公司',
  index_bottom_31: '北京威发新世纪信息技术有限公司',
  index_bottom_32: '推荐使用谷歌、火狐、IE10以上版本浏览器，分辨率1280*800及以上。',
  'menu.meeting.meeting_app': '智能会议',
  'menu.meeting.subscribe': '会议预订',
  'menu.meeting.mines': '会议列表',
  'menu.meeting.webexmeeting': '网络会议',
  'menu.meeting.videomeeting': '视频会议',
};
