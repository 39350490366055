import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';
import type { ExtraData } from 'antd-mobile-v2/es/calendar/PropsType';
import moment, { Moment } from 'moment-timezone';
import { sortDownDate, isDayLimit } from './timeTools';
import { objectColorStatus } from './utils';

type settingProps = {
  swsCommon: {
    systemSet: {
      reserve_normal_open_day: string | number; // 基础规则
      reserve_normal_limit_day: string | number; // 最大预订时长
      reserve_special_count: string | number; // 每次最多可预订工位个数
      [key: string]: any;
    };
    openDateList: {
      workday: string[];
      holiday: string[];
    };
    timeZone: string;
  };
};

type dateDisabledSwsCommon = {
  systemSet: {
    resAdvanceOff: string | number; // 工位提前预订开关(0.开，1.关)
    resAdvanceTime: string | number; // 工位至少提前预订时间(单位：分钟)
    [key: string]: any;
  };
  holiday: {
    amEndTime: string;
    amStartTime: string;
    pmEndTime: string;
    pmStartTime: string;
  };
};

type SEprops = {
  startDate: Moment | undefined; // 开始时间
  endDate: Moment | undefined; // 结束时间
};

type swsSettingProps = {
  swsCommon: { [k: string]: any; swsSetting: { paramName: string; paramValue: string | number }[] };
  [key: string]: any;
};

// 是否开启结束时间选择
export function hasEndTime(state: swsSettingProps): boolean {
  const res = state.swsCommon.swsSetting.find(
    (v) => v.paramName === 'reserve_scan_default_hour',
  )?.paramValue;
  // return true;
  return String(res) !== '0';
}

// 延长时间间隔 15分钟一格
export function createDelay(state: swsSettingProps): { label: string; value: number }[] {
  const step = 15;
  const minNum =
    Number(
      state.swsCommon.swsSetting.find((v) => v.paramName === 'reservationDelayTimeMin')?.paramValue,
    ) || 15;
  const maxNum =
    Number(
      state.swsCommon.swsSetting.find((v) => v.paramName === 'reservationDelayTime')?.paramValue,
    ) || 150;

  const scales: number[] = [minNum];

  if (maxNum <= minNum) {
    Toast.fail('延长时间间隔最大值最小值范围不对');
    return scales as any[];
  }
  while (true) {
    if (scales[scales.length - 1] >= maxNum) {
      break;
    }
    scales.push(scales[scales.length - 1] + step);
  }
  return scales.map((v) => ({
    label: formatMessage({ id: 'sws.delay.num.format' }, { num: v }),
    value: v,
  }));
}

/**
 * 获取最新设置
 * @param props 参数
 */
export const getSetting = (props: {
  dispatch: any;
  locationId: string | number | undefined;
}): Promise<any> => {
  const { dispatch, locationId } = props;
  return dispatch({ type: 'swsCommon/getSystemSet', spaceId: locationId });
};

/**
 * 判断日历组件日期是否可选
 * @param currentDate 当前时间
 * @param props 参数
 * @returns
 */
export const isDisabledDate = (currentDate: Moment, props: settingProps & SEprops): boolean => {
  const {
    swsCommon: {
      systemSet: { reserve_normal_open_day = 7 },
      openDateList: { holiday, workday },
      timeZone = 'Asia/Shanghai',
    },
    startDate,
    endDate,
  } = props;

  const dateRange = [...holiday, ...workday].sort(sortDownDate);
  if (dateRange.length > 1) {
    const before = currentDate.valueOf() < moment.tz(dateRange[0], timeZone).valueOf();
    const after =
      currentDate.valueOf() > moment.tz(dateRange[dateRange.length - 1], timeZone).valueOf();
    return before || after;
  }

  if (
    currentDate > moment().tz(timeZone).startOf('day').add(Number(reserve_normal_open_day), 'day')
  ) {
    return true;
  }

  if (currentDate < moment().tz(timeZone).startOf('day')) {
    return true;
  }

  if (currentDate < moment().tz(timeZone).startOf('day')) {
    return true;
  }

  if (startDate && currentDate < startDate) {
    return true;
  }

  if (endDate && currentDate > endDate) {
    return true;
  }

  return false;
};

/**
 * 获取日历起止时间
 * @param props 参数
 * @returns
 */
export const getSEDate = (props: settingProps): [Date, Date] => {
  const {
    swsCommon: {
      systemSet: { reserve_normal_open_day = 7 },
      openDateList: { holiday = [], workday = [] },
      timeZone = 'Asia/Shanghai',
    },
  } = props;

  const dateRange = [...holiday, ...workday].sort(sortDownDate).filter((day) => {
    return !resAdvanceDateDisable({ date: moment(day), swsCommon: props.swsCommon as any });
  });
  if (dateRange.length > 1) {
    return [
      moment.tz(dateRange[0], timeZone).toDate(),
      moment.tz(dateRange[dateRange.length - 1], timeZone).toDate(),
    ];
  }

  return [
    moment().tz(timeZone).toDate(),
    moment().tz(timeZone).startOf('day').add(Number(reserve_normal_open_day)).toDate(),
  ];
};

export const limitDay = (props: settingProps & SEprops): boolean => {
  const {
    swsCommon: {
      systemSet: { reserve_normal_limit_day, reserveSpecialLimitDay },
      openDateList: { workday },
      timeZone,
    },
    startDate,
    endDate,
    type,
  } = props;
  let limitDay = reserve_normal_limit_day;
  if (type === 'special') {
    limitDay = reserveSpecialLimitDay;
  }
  if (isDayLimit(workday, Number(limitDay), startDate, endDate, timeZone)) {
    Toast.fail(formatMessage({ id: 'sws.booking.limit.date' }, { value1: limitDay }));
    return true;
  }
  return false;
};

// 延长时间间隔 15分钟一格
export function createDelayByNum(
  minNum: number,
  maxNum: number,
): { label: string; value: number }[] {
  const step = 15;

  const scales: number[] = [minNum];

  if (isNaN(minNum) || isNaN(maxNum) || maxNum <= minNum) {
    Toast.fail('延长时间间隔最大值最小值范围不对或者不是数字');
    return scales as any[];
  }
  while (true) {
    if (scales[scales.length - 1] >= maxNum) {
      break;
    }
    scales.push(scales[scales.length - 1] + step);
  }
  return scales.map((v) => ({
    label: formatMessage({ id: 'sws.delay.num.format' }, { num: v }),
    value: v,
  }));
}

export const getAdvanceTime = (props: {
  startDate?: Moment;
  endDate?: Moment;
  swsCommon: {
    systemSet: {
      resAdvanceOff: string | number; // 工位提前预订开关(0.开，1.关)
      resAdvanceTime: string | number; // 工位至少提前预订时间(单位：分钟)
      [key: string]: any;
    };
  };
}): number => {
  const {
    swsCommon: {
      systemSet: { resAdvanceOff, resAdvanceTime = 0 },
    },
  } = props;
  if (String(resAdvanceOff) === '1') {
    return 0;
  }
  return Number(resAdvanceTime);
};

export const resAdvanceDateDisable = (props: {
  date: Moment;
  swsCommon: dateDisabledSwsCommon;
}): Boolean => {
  const {
    date,
    swsCommon: {
      systemSet: { resAdvanceOff, resAdvanceTime = 0 },
      holiday: { pmEndTime },
    },
  } = props;
  if (String(resAdvanceOff) === '1') {
    return false;
  }
  // 提前时间如果超过下班时间，预约时间下一天  例如：6点下班，最少提前20分钟预订，就是5:40之后不能预订，15分钟一个跨度，只能5.30之前的工位，如果现在5.40了。那就只能下一个工作日预约
  const finalDate = moment().add(Number(resAdvanceTime), 'minute');
  let currentDate = moment(`${date.format('YYYY-MM-DD')} ${pmEndTime}:00`);
  if (moment(date).isSame(moment())) {
    currentDate = moment();
  }
  const res = finalDate.isAfter(currentDate);
  return res;
};

export function findPathByMenu(path: string, menuData: any[]): boolean {
  return menuData.some((m) => {
    if (m.authority.includes(path)) {
      return true;
    } else if (m?.children?.length) {
      return findPathByMenu(path, m.children);
    } else {
      return false;
    }
  });
}

export function getDateExtra({ date, holiday }: { date: Date; holiday: String[] }): ExtraData {
  let cellCls = '';
  if (holiday?.includes?.(moment(date).format('YYYY-MM-DD'))) {
    cellCls = 'calendarDateDisable';
  }
  return { cellCls };
}

export const handleSocketData = ({
  mapId,
  socketData,
  stationsOnMap,
  onUpdate,
  onBatch,
}: {
  mapId: number;
  socketData: string;
  stationsOnMap: { stationId: number; [key: string]: any }[];
  onUpdate: (data: any) => any;
  onBatch: () => any;
}) => {
  try {
    const parseData = JSON.parse(socketData);
    console.log('[station booking] msgCallBack parseBody', parseData, mapId);
    if (String(parseData.business) === String(mapId)) {
      if (parseData.msgAction === 'state-update') {
        parseData.data.forEach((item) => {
          const findIndex = stationsOnMap.findIndex(
            (s) => String(s.stationId) === String(item.objectId),
          );
          if (findIndex >= 0) {
            stationsOnMap[findIndex] = {
              ...stationsOnMap[findIndex],
              color: item.objectColor,
              state: objectColorStatus[item.objectColor],
              statusCode: objectColorStatus[item.objectColor],
              ...(item.objectName ? { text: item.objectName } : {}),
            };
          }
          onUpdate(stationsOnMap);
        });
      } else if  (parseData.msgAction === 'reload')  {
        onBatch();
      }
    }
  } catch (e) {
    console.warn('handleSocketDataError', e);
  }
};
//- 小写数字转换成大写, 只处理到[0 ~ 99]
export function numberConvertToUppercase() {
  return function (num) {
    // eslint-disable-next-line no-param-reassign
    num = Number(num);
    var upperCaseNumber = [
      '零',
      '一',
      '二',
      '三',
      '四',
      '五',
      '六',
      '七',
      '八',
      '九',
      '十',
      '百',
      '千',
      '万',
      '亿',
    ];
    var length = String(num).length;
    if (length == 1) {
      return upperCaseNumber[num];
    } else if (length == 2) {
      if (num == 10) {
        return upperCaseNumber[num];
      } else if (num > 10 && num < 20) {
        return '十' + upperCaseNumber[String(num).charAt(1)];
      } else {
        return (
          upperCaseNumber[String(num).charAt(0)] +
          '十' +
          upperCaseNumber[String(num).charAt(1)].replace('零', '')
        );
      }
    }
  };
}
