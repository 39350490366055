import { checkIn, listNoCheckMeeting } from '@/pages/Meeting/pages/Feedback/service';
import { RES_CODE } from '@/utils/constant';
import { Toast } from 'antd-mobile-v2';
import { history, formatMessage } from 'umi';
import { MENU_PATH } from '@/pages/Meeting/utils/constant';

export default {
  namespace: 'feedbackIndex',
  state: {
    checkMeetings: [],
    // type 0:签到成功页面  type 1: 会议列表界面 type 2: 错误界面
    type: -1,
    // 当前扫描的 会议室id
    roomId: -1,
    // 默认操作失败
    errorMsg: '601012',
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *findNoCheckMeetingByRoomId({ roomId }, { call, put }) {
      const { code, data, msg } = yield call(listNoCheckMeeting, roomId);
      if (code === RES_CODE.success) {
        yield put({ type: 'update', payload: { roomId, checkMeetings: data } });
        if (data && data.length > 1) {
          // 跳转到列表中选择会议，点击签到
          yield put({ type: 'update', payload: { type: 1 } });
        } else if (data && data.length === 1) {
          // 调用签到接口
          const meetingInfo = data[0];
          if (meetingInfo.userIsSign === 0) {
            yield put({ type: 'checkIn', meetingId: meetingInfo.meetingId });
          } else {
            yield put({ type: 'update', payload: { type: 2, errorMsg: '201000' } });
          }
        } else {

          // 没有可签到的会议，进入会议预约界面
          history.push(MENU_PATH.subscribeDetail);
        }
      } else {
        if (msg === '201001') {

          history.push(MENU_PATH.subscribeDetail);
        } else {
          yield put({ type: 'update', payload: { type: 2, errorMsg: msg } });
        }
      }
    },
    *checkIn({ meetingId }, { select, call, put }) {
      const { roomId, checkMeetings } = yield select((state) => state.feedbackIndex);
      const { code, msg } = yield call(checkIn, meetingId);
      if (code === RES_CODE.success) {
        // 签到成功，跳转到成功页面
        if (checkMeetings.length === 1) {
          yield put({ type: 'update', payload: { type: 0 } });
        } else {
          Toast.success(formatMessage({ id: 'meeting_signSuccess' }));
          yield put({ type: 'findNoCheckMeetingByRoomId', roomId });
        }
      } else {
        if (checkMeetings.length === 1) {
          Toast.fail(formatMessage({ id: msg }));
        } else {
          Toast.fail(formatMessage({ id: msg }));
          yield put({ type: 'update', payload: { type: 1} });
         
        }
      }
    },
  },
};
