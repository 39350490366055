export default {
  selfService_name: '访客姓名',
  selfService_name_tip: '请输入您的姓名',
  selfService_company: '访客公司',
  selfService_company_tip: '请输入您的公司',
  selfService_phone: '手机号码',
  selfService_phone_tip: '请输入您的手机',
  selfService_mail: '您的邮箱',
  selfService_mail_tip: '请输入您的邮箱',
  selfService_host: '拜访对象',
  selfService_host_tip: '请输入拜访对象',
  selfService_select: '请选择',
  selfService_success_tip1: '预约操作成功',
  selfService_success_tip2: '请等待被访人确认,稍后将以短信形式告知您结果',
  selfService_audit: '待审',
  selfService_pass: '已通过',
  selfService_all: '全部',
  selfService_new: '新增预约',
  selfService_local: '访问地点',
  selfService_time: '访问时间',
  selfService_pull: '下拉',
  selfService_deliver: '释放',
  selfService_loading: '加载中...',
  selfService_loaded: '到底了~',
  self_header_welcome: '欢迎来到',
  self_search_tip: '输入更多企业试试',
  self_no_search_tip: '该企业暂未入驻访客管理系统',
  self_add_booking_tip: '点击下方按钮，新增预约',
  selfService_select_end_time: '请选择离开时间',
};
