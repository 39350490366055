/* eslint-disable no-console */
import {
  APP_HOME,
  APP_HOME_PIX,
  APP_ROUTE_PATH,
  changeTimeAfter,
  customListToInfo,
  defaultNoteConfig,
} from '@/pages/Visitor/utils/constant';
import meetingRoomFormat from '@/pages/Visitor/utils/meetingRoomFormat';
import { isFromCloudAndHasMeeting } from '@/pages/Visitor/utils/utils';
import { getUrlParam } from '@/utils/utils';
import { Modal, Toast } from 'antd-mobile-v2';
import 'antd-mobile-v2/lib/toast/style/index.less';
import _ from 'lodash';
import moment from 'moment';
import { formatMessage, getDvaApp, history, setLocale } from 'umi';
import wx from 'weixin-js-sdk';
import * as AppService from '../services/AppService';
import * as checkInService from '../services/checkIn';
import * as loginService from '../services/login';
import * as applyService from '../services/services';
import * as virsicalService from '../services/virsical';
import * as appService from '../services/visitApp';
import * as wechatService from '../services/wechat';
import { defaultTemplateList } from '@/pages/Visitor/pages/InvitationCode/constant';

const { alert } = Modal;
const getAreaCode = (phone) => {
  const obj = { code: '', mobile: '' };
  if (phone.indexOf('+') === 0) {
    if (phone.indexOf('+86') === 0 && phone.length === 14) {
      obj.code = '+86';
      obj.mobile = phone.substring(3, phone.length);
    } else if (phone.indexOf('+852') === 0 && phone.length === 12) {
      obj.code = '+852';
      obj.mobile = phone.substring(4, phone.length);
    }
  } else if (phone.indexOf('00') === 0) {
    if (phone.indexOf('0086') === 0 && phone.length === 15) {
      obj.code = '+86';
      obj.mobile = phone.substring(4, phone.length);
    } else if (phone.indexOf('00852') === 0 && phone.length === 13) {
      obj.code = '+852';
      obj.mobile = phone.substring(5, phone.length);
    }
  } else if (phone.indexOf('86') === 0 && phone.length === 13) {
    obj.code = '+86';
    obj.mobile = phone.substring(2, phone.length);
  } else if (phone.indexOf('852') === 0 && phone.length === 11) {
    obj.code = '+852';
    obj.mobile = phone.substring(3, phone.length);
  } else if (phone.length === 11) {
    obj.code = '+86';
    obj.mobile = phone;
  } else if (phone.length === 8) {
    obj.code = '+852';
    obj.mobile = phone;
  } else {
    obj.mobile = phone;
  }
  if (obj.mobile === '') {
    Toast.info(formatMessage({ id: 'app.connect' }), 2);
  }
  return obj;
};

const getAdvSignTime = (meetingRooms = {}, meetingRoom = []) => {
  let advSignTime = 0;
  if (meetingRooms?.rooms && meetingRooms.rooms.length && meetingRoom) {
    meetingRooms.rooms.forEach((item) => {
      if (
        item.advSignTime &&
        meetingRoom.filter((v) => String(v) === String(item.roomId)).length &&
        advSignTime < item.advSignTime
      ) {
        advSignTime = item.advSignTime;
      }
    });
  }
  return advSignTime || null;
};

const getNeedAudit = (meetingRooms = {}, meetingRoom = []) => {
  let needAudit = null;
  if (meetingRooms?.rooms && meetingRooms.rooms.length && meetingRoom) {
    meetingRooms.rooms.forEach((item) => {
      if (item.needAudit && meetingRoom.filter((v) => String(v) === String(item.roomId)).length) {
        needAudit = item.needAudit;
      }
    });
  }
  return needAudit;
};

/**
 * get是否弹出发送更新通知选择弹窗
 * @param {*} oldVisit  old访问信息
 * @param {*} newVisit  new访问信息
 * @param {*} oldVisitorList  old访客数据
 * @param {*} newVisitorList  new访客数据
 * @returns isSendVisitorNotice 是否弹出发送更新通知选择弹窗
 */
const getIsSendVisitorNotice = (oldVisit, newVisit, oldVisitorList, newVisitorList) => {
  /**
   * isLocationManageIdDiff,  是否修改了接待地点
   * isVisitorsLengthDiff,  是否添加了访客
   * isNameAndPhoneDiff,  是否同时修改了访客姓名和访客电话
   */
  const isLocationManageIdDiff =
    JSON.stringify({
      locationManageId: oldVisit?.locationManageId,
      locationName: oldVisit?.locationName,
      mapUrl: oldVisit?.mapUrl,
    }) !==
    JSON.stringify({
      locationManageId: newVisit?.locationManageId,
      locationName: newVisit?.locationName,
      mapUrl: newVisit?.mapUrl,
    });
  const isVisitorsLengthDiff = !!newVisitorList?.filter((item) => !item.preRegistrationId)?.length;
  let isNameAndPhoneDiff = false;
  oldVisitorList?.forEach((oldItem) => {
    const newHasOldObj =
      newVisitorList?.filter(
        (newItem) => String(oldItem?.preRegistrationId) === String(newItem?.preRegistrationId),
      )[0] || null;
    if (
      newHasOldObj &&
      oldItem.visitorName !== newHasOldObj.visitorName &&
      oldItem.telAreaCode + oldItem.visitorNumber !==
        newHasOldObj.telAreaCode + newHasOldObj.visitorNumber
    ) {
      isNameAndPhoneDiff = true;
    }
  });
  return isLocationManageIdDiff || isVisitorsLengthDiff || isNameAndPhoneDiff;
};

// 会议室区域选择树
const organizeZonePickerData = (zones) => {
  if (!zones || zones.length === 0) {
    return [];
  }
  const zonePickerData = [];
  zones.forEach((z) => {
    const zData = {};
    zData.id = Number(z.id);
    zData.name = z.name;
    zData.parentId = z.parentId;
    zData.path = z.path;
    zData.pathName = z.pathName;
    zData.children = organizeZonePickerData(z.children);
    zonePickerData.push(zData);
  });
  return zonePickerData;
};

export default {
  namespace: 'app',
  state: {
    selectKey: '/reserve',
    fromPage: '',
    user: {},
    visitor: {},
    visitList: [],
    confirmList: [],
    reservationList: [],
    detail: {},
    visitorType: [],
    namePlaceholder: true,
    companyPlaceholder: true,
    phonePlaceholder: true,
    mailPlaceholder: true,
    carNumberPlaceholder: true,
    meetingSwitch: false,
    allMeetingRoom: [],
    areaList: [],
    area: {},
    mapList: [],
    address: null,
    isGetLocation: true,
    zoom: 15,
    mapValue: '',
    moreAddress: '',
    isShow: false,
    fileList: [],
    faceInfo: {},
    areaCode: [],
    dataSource: [],
    associates: [],
    meetingRooms: [],
    rows: 10,
    isRefreshed: true,
    refreshing: false,
    showlistDate: [], // 1.6.5 新加判断时间
    StatisticalAnalysisType: 4, // 1.6.5新加 数据分析类型 1.年度 2.本月 3.本周，4.今天
    StatisticalAnalysisData: {
      // 1.6.5新加
      historyCount: 0,
      inviteCount: 0,
      reservatationCount: 0,
      tmpCount: 0,
    },
    vstKeyword: '', // 1.6.5新增访客记录搜索框
    prdKeyword: '', //
    notCfmCount: 0,
    isnoData: false,
    visitorTemp: [
      // {visitorName: '123', visitorCompany: '123', telAreaCode: '+86', visitorNumber: '123', visitorMail: '123'},
      // {visitorName: '456', visitorCompany: '456', telAreaCode: '+86', visitorNumber: '456', visitorMail: '456'},
      // {visitorName: '789', visitorCompany: '789', telAreaCode: '+86', visitorNumber: '789', visitorMail: '789'}
    ],
    toConfirm: false, // 到达预约确认 默认为false
    isAgain: false, // 是否是again
    businessCard: {},
    businessMultiple: false,
    businessCardInfo: {},
    productFunction: '',
    visitorList: [],
    visitVisitor: {},
    accompany: [],
    showElseAddress: true,
    modifyReservation: false,
    blackDisable: false,
    showUpload: false,
    faceData: {},
    selfConfirmData: '',
    showReject: false,
    rejectReason: '',
    customFormList: [],
    customForm: [],
    isDelay: false,
    preIds: [],
    loopSwitch: false,
    loopTypeId: 0,
    loopTypeName: '',
    loopItem: {
      loopTypeId: 0,
      loopTypeName: '',
    },
    dayModeCount: 1,
    loopCount: 2,
    weekModeCount: [1],
    typeId: 0,
    isEdit: false,
    isConfirm: false,
    isVisibleInfo: true,
    epidemicList: [],
    carNumberSwitch: 0,
    //  当前详情页面来源 1 邀请 2 访问 3 审批
    detailsPageSource: 1,
    from: 1,
    sourceSwitch: false,
    page: 1,
    storedArr: [],
    total: 0,
    groupInfo: {},
    meetingInfo: {},
    systemObj: {
      systemMode: '0',
      systemIndex: 0,
    },
    healthInfo: {},
    epidemicPreventionSwitch: false,
    parentCustomFormDetail: [],
    // externalUser: {},
    acrossSwtich: false,
    option: 3,
    showMeetingRoomTip: false,
    prefixInvitationCode: '',
    yiLianQrCode: null,
    codeInvalid: false,
    invitationCode: '',
    // 跨天访客天数配置
    acrossDayNum: 14,
    // 访客来访日期不能超过当前日期天数配置
    visitDayNum: 14,
    // 身份证开关配置项
    idCardConfig: {
      idCardSwitch: 0,
      idCardRule: 0,
      idCardValidate: 0,
      idCardFillRule: 'fillInAll', // fillInAll（全部填写）、firstThreeAndThenFour（只填写前三位和后四位）
      idCardEmployeeRule: 0,
    },
    epidemicPreventionSwitchInvitation: 2,
    noteConfig: defaultNoteConfig,
    invitationCodeData: {},
    errorCode: '',
    holidayConfig: null, // 工作日配置
    workdayCheckSwitch: 0, //  工作日开关
    cancelVisitors: [],
    resendSwitch: 0, // 补发邀请函
    meetingConfig: {}, // 会议配置项
    otherLocationId: null,
    integratedMeetingSwitch: 0, // 会议集成开关
    miniSchemeUrl: '', // Url Scheme
    visitorFillSwitch: 0, // 访客补填开关
    isDisplayFillButton: false,
    lockerIntegrationSwitch: 0, // 储物柜集成开关
    lockerIntegrationNum: 30, // 储物柜延迟释放，min
    // 会议室筛选区域
    spaceTree: [],
    zonePickerData: [],
    temporaryParentData: null, // 暂存父级数据
    mapType: null, // 地图类型
    googleMapData: null, // 谷歌地图数据
    activeKey: '1',
    switchConfig: undefined, //  存储一份整体的开关配置
    readProtocolData: null, // 自己维护一份阅读信息对象
    ndaData: null,
    phoneEmailValidationRule: null, //  访客必填信息填写规则，手机号必填：'phoneNumberRequired'，手机号和邮箱至少填写一个：'phoneOrEmailRequired'
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
    setSelectKey(state, { selectKey }) {
      return { ...state, selectKey };
    },
    visitVisitor(state, { visitVisitor }) {
      return { ...state, visitVisitor };
    },
    setUser(state, { user }) {
      return { ...state, user };
    },
    setVisitList(state, { visitList }) {
      return { ...state, visitList };
    },
    setConfirmList(state, { confirmList }) {
      return { ...state, confirmList };
    },
    setVisitor(state, { visitor }) {
      return { ...state, visitor };
    },
    setDetail(state, { detail, fromPage }) {
      return { ...state, detail, fromPage };
    },
    setVisitorType(state, { visitorType }) {
      return { ...state, visitorType };
    },
    meetingSwitch(state, { meetingSwitch }) {
      return { ...state, meetingSwitch };
    },
    allMeetingRoom(state, { allMeetingRoom }) {
      return { ...state, allMeetingRoom };
    },
    areaList(state, { areaList }) {
      return { ...state, areaList };
    },
    area(state, { area }) {
      return { ...state, area };
    },
    mapList(state, { mapList }) {
      return { ...state, mapList };
    },
    address(state, { address }) {
      return { ...state, address };
    },
    zoom(state, { zoom }) {
      return { ...state, zoom };
    },
    isShow(state, { isShow }) {
      return { ...state, isShow };
    },
    mapValue(state, { mapValue }) {
      return { ...state, mapValue };
    },
    moreAddress(state, { moreAddress }) {
      return { ...state, moreAddress };
    },
    fileList(state, { fileList }) {
      return { ...state, fileList };
    },
    faceInfo(state, { faceInfo }) {
      return { ...state, faceInfo };
    },
    areaCode(state, { areaCode }) {
      return { ...state, areaCode };
    },
    dataSource(state, { dataSource }) {
      return { ...state, dataSource };
    },
    invitationCode(state, { invitationCode }) {
      return { ...state, invitationCode };
    },
    associates(state, { associates }) {
      return { ...state, associates };
    },
    meetingRooms(state, { meetingRooms }) {
      return { ...state, meetingRooms };
    },
    rows(state, { rows }) {
      return { ...state, rows };
    },
    refreshing(state, { refreshing }) {
      return { ...state, refreshing };
    },
    isRefreshed(state, { isRefreshed }) {
      return { ...state, isRefreshed };
    },
    setReservationList(state, { reservationList }) {
      return { ...state, reservationList };
    },
    setAccompany(state, { accompany }) {
      return { ...state, accompany };
    },
    systemObj(state, { systemObj }) {
      return { ...state, systemObj };
    },
    healthInfo(state, { healthInfo }) {
      return { ...state, healthInfo };
    },
    acrossSwtich(state, { acrossSwtich }) {
      return { ...state, acrossSwtich };
    },
    setOtherLocationId(state, { otherLocationId }) {
      return {
        ...state,
        otherLocationId,
      };
    },
    miniSchemeUrl(state, { miniSchemeUrl }) {
      return { ...state, miniSchemeUrl };
    },
    setTemporaryParentData(state, { temporaryParentData }) {
      return { ...state, temporaryParentData };
    },
    setMapType(state, { mapType }) {
      return { ...state, mapType };
    },
    setGoogleMapData(state, { googleMapData }) {
      return { ...state, googleMapData };
    },
  },
  effects: {
    *weChatSSO({ payload }, { call, put, select }) {
      const data = yield call(loginService.weChatSSO, payload);
      if (data && Object.prototype.hasOwnProperty.call(data, 'employeeId')) {
        sessionStorage.setItem('user', JSON.stringify(data));
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('type', data.type);
        yield put({ type: 'setUser', user: data });
        yield put({ type: 'getArea' });
        yield put({ type: 'getAreaCode' });
        const { selectKey } = yield select((state) => state.app);
        if (selectKey === APP_ROUTE_PATH.APP_RESERVE) {
          yield put({ type: 'getReservationList' });
        } else {
          Toast.info(formatMessage({ id: 'app.connect.none' }), 2);
        }
      }
    },
    *getVisitorProduct({ authorization }, { call, put }) {
      const result = yield call(loginService.getVisitorProduct, { authorization });
      if (result.msg) {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
      } else if (result.data.length) {
        sessionStorage.setItem('productFunction', result.data[0].productFunction);
        yield put({
          type: 'save',
          payload: {
            productFunction: result.data[0].productFunction,
          },
        });
      }
    },
    *businessCard({ payload }, { put, call, select }) {
      const data = yield call(AppService.businessCard, { payload });
      const { visitorList } = yield select((state) => state.app);
      if (data.code) {
        Toast.fail(formatMessage({ id: `${data.msg}` }));
      } else {
        Toast.success(formatMessage({ id: `${data.msg}` }));
        yield put({
          type: 'save',
          payload: {
            businessCard: data.data,
            businessMultiple: true,
          },
        });
        const phone = getAreaCode(data.data.mobile);
        visitorList.push({
          visitorName: data.data.name,
          visitorCompany: data.data.company,
          telAreaCode: phone.code || '+86',
          visitorNumber: phone.mobile,
          visitorMail: data.data.email,
        });
        const { visitorTemp } = yield select((state) => state.app);
        visitorTemp.forEach((v) => {
          const item = v;
          if (!item.visitorName) {
            const index = visitorTemp.indexOf(item);
            visitorTemp.splice(index, 1);
          }
        });
        const visitor = [].concat(visitorTemp, visitorList);
        const age = 'visitorNumber';
        let arr = [];
        arr = visitor.reduce(
          (all, next) => (all.some((atom) => atom[age] === next[age]) ? all : [...all, next]),
          [],
        );
        yield put({
          type: 'save',
          payload: { visitorTemp: arr },
        });
      }
    },
    /*  5110版本审批同意、拒绝设置为一个接口  */
    *reviewBatch({ payload, needExtraParams = true }, { call, put, select }) {
      const { currentUser: { realName = '' } = {} } = yield select((state) => state.user);
      const { visitor, meetingSwitch, meetingRooms, meetingConfig, visitDayNum, acrossDayNum } =
        yield select((state) => state.app);
      const meetingInfo = {};
      // 不需要额外参数
      if (needExtraParams) {
        meetingInfo.orderMeeting = meetingSwitch ? 0 : 1;
        if (meetingSwitch) {
          meetingInfo.meetingStartTime = visitor.meetingStartTime
            ? visitor.meetingStartTime
            : moment().add(10, 'm').valueOf();

          meetingInfo.meetingDuration = Math.ceil(
            (visitor.meetingEndTime - visitor.meetingStartTime) / 1000 / 60,
          );
          if (visitor.meetingRoom && visitor.meetingRoom.length > 0) {
            meetingInfo.roomId = visitor.meetingRoom.join(';');
            meetingInfo.advSignTime = getAdvSignTime(meetingRooms, visitor.meetingRoom);
            meetingInfo.needAudit = getNeedAudit(meetingRooms, visitor.meetingRoom);
          } else {
            meetingInfo.roomId = '';
            meetingInfo.advSignTime = null;
            meetingInfo.needAudit = null;
          }
          if (visitor.meetingRoomName && visitor.meetingRoomName.length > 0) {
            meetingInfo.roomName = visitor.meetingRoomName.join(';');
          } else {
            meetingInfo.roomName = '';
          }
          meetingInfo.bookType = visitor?.bookType || 0;
          meetingInfo.meetingTopic =
            visitor?.meetingTopic ||
            formatMessage(
              {
                id: 'reserve.visit.meeting.theme.placeholder',
              },
              { realName },
            );
        }
        meetingInfo.preVisitorTime = visitor.preVisitorTime || null;
        meetingInfo.preVisitorEndTime = visitor.preVisitorEndTime || null;
      }
      const data = yield call(AppService.reviewBatch, {
        payload: {
          ...payload,
          ...meetingInfo,
        },
      });
      if (data.code) {
        if (data.msg === '202028') {
          Toast.fail(
            formatMessage({ id: `${data.msg}` }, { time: meetingConfig?.applyAdvanceTime || '' }),
            1,
          );
        } else if (data.msg === '202171') {
          Toast.fail(
            formatMessage(
              { id: `${data.msg}` },
              { value: meetingConfig?.oneDayMeetingCanReserveDays || '' },
            ),
            1,
          );
        } else if (data.msg === '202169' || data.msg === '202170') {
          Toast.fail(
            formatMessage(
              { id: 'meeting.booking.normal_day_meeting_limit' },
              { limitMinTime: data?.data?.min, limitMaxTime: data?.data?.max },
            ),
          );
        } else if (data.msg === '306084') {
          Toast.fail(
            formatMessage(
              { id: data.msg },
              { num: data?.data || meetingConfig?.longMeetingCanReserveDays },
            ),
          );
        } else if (data.msg === '202064') {
          Toast.fail(
            formatMessage(
              { id: data.msg },
              { value: data?.data || meetingConfig?.longMeetingMaxDays },
            ),
          );
        } else if (data.msg === '301014') {
          Toast.fail(formatMessage({ id: data.msg }, { value: visitDayNum - 1 }));
        } else if (data.msg === '301015') {
          Toast.fail(formatMessage({ id: data.msg }, { value: acrossDayNum - 1 }));
        } else {
          Toast.fail(formatMessage({ id: `${data.msg}` }, { timeFormat: data?.data || '' }), 1);
        }
        return;
      } else if (JSON.parse(data.data)?.failNum) {
        Toast.fail(formatMessage({ id: '601012' }));
      } else {
        Toast.success(formatMessage({ id: '601013' }));
      }
      if (payload.isDetail) {
        history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_CONFIRM}`);
      }
      yield put({ type: 'getConfirmData' });
      yield put({ type: 'getAuditCountByUserId' });
    },
    /* 1.6.5 新增 自主预约 确认 拒绝 */
    *selfConfirmReservation({ payload }, { put, call }) {
      const data = yield call(appService.confirmReservation, {
        reservation: payload,
      });

      if (data.code === 0) {
        Toast.success(formatMessage({ id: `${data.data.data}` }), 1);
      } else {
        Toast.fail(formatMessage({ id: `${data.data}` }), 1);
      }
      /* 刷新页面 */
      yield put({ type: 'getConfirmData' });
      yield put({ type: 'getAuditCountByUserId' });
    },
    /* 1.6.5 新增 自主预约 确认 拒绝 */
    *confirmVisitor({ payload }, { put, call }) {
      // eslint-disable-next-line no-param-reassign
      payload.id = payload.preRegistrationId;
      const data = yield call(appService.confirmVisitor, {
        visitorDataId: payload.visitorDataId,
        decision: payload.decision,
      });
      if (data.code === 0) {
        Toast.success(formatMessage({ id: `${data.data.msg}` }));
        yield put({
          type: 'save',
          payload: {
            selfConfirmData: null,
          },
        });
      } else {
        Toast.fail(formatMessage({ id: `${data.msg}` }));
      }
      /* 刷新页面 */
      yield put({ type: 'getConfirmData' });
      yield put({ type: 'getAuditCountByUserId' });
    },
    /* 1.6.5 新增 统计分析数据 */
    *getStatisticalAnalysis({ payload: payloads }, { put, call }) {
      const payload = payloads;
      let startTime;
      if (payload.type === 1) {
        /* 年 */
        const yearDate = `${moment().format('YYYY')}/01/01 00:00`;
        startTime = new Date(yearDate).getTime();
      } else if (payload.type === 2) {
        /* 本月 */
        const monthDate = `${moment().subtract('months').format('YYYY/MM')}/01 00:00`;
        startTime = new Date(monthDate).getTime();
      } else if (payload.type === 3) {
        /* 本周 */
        // eslint-disable-next-line radix
        const weekOfDay = parseInt(moment().format('d'), 0);
        startTime = `${moment().subtract(weekOfDay, 'days').format('YYYY/MM/DD')} 00:00`;
        startTime = new Date(startTime).getTime();
      } else if (payload.type === 4) {
        const dayDate = `${moment().subtract('days').format('YYYY/MM/DD')} 00:00`;
        startTime = new Date(dayDate).getTime();
      }
      const nowEnd = new Date().getTime();
      payload.startTime = startTime;
      payload.endTime = nowEnd;

      const data = yield call(AppService.getStatisticalAnalysisData, {
        payload,
      });
      if (data.code === 0) {
        const obj = { StatisticalAnalysisData: data.data };
        yield put({ type: 'save', payload: obj });
      } else {
        Toast.fail(formatMessage({ id: `${data.msg}` }));
      }
    },
    *saveShowList({ payload }, { put }) {
      const { showList = [] } = payload;
      const showlistDate = [];
      showList.forEach((v) => {
        const k = v;
        const oneDate = moment(k.preVisitorTime).format('LL');
        if (showlistDate.indexOf(oneDate) === -1) {
          showlistDate.push(oneDate);
          k.isShowDate = true; // 是否显示时间
        } else {
          k.isShowDate = false;
        }
      });
      if (payload.formType === 'setReservationList') {
        yield put({ type: 'setReservationList', reservationList: showList });
      } else if (payload.formType === 'setConfirmList') {
        yield put({ type: 'setConfirmList', confirmList: showList });
      } else {
        yield put({ type: 'setVisitList', visitList: showList });
      }
    },
    *loginPortal({ payload }, { call, put, select }) {
      const data = yield call(loginService.portal, payload);
      if (data && Object.prototype.hasOwnProperty.call(data, 'employeeId')) {
        sessionStorage.setItem('user', JSON.stringify(data));
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('type', data.type);
        yield put({ type: 'setUser', user: data });
        yield put({ type: 'getArea' });
        yield put({ type: 'getAreaCode' });
        const { selectKey } = yield select((state) => state.app);
        if (selectKey === APP_ROUTE_PATH.APP_RESERVE) {
          yield put({ type: 'getReservationList' });
        }
      } else {
        Toast.info(formatMessage({ id: 'app.connect.none' }), 2);
      }
    },
    *getReservationList({ rows = 10, page = 1, isRefresh = false }, { call, put, select }) {
      let { user } = yield select((state) => state.app);
      const { vstKeyword, from, storedArr } = yield select((state) => state.app);
      if (!user || !Object.keys(user, 'companyId')) {
        user = JSON.parse(sessionStorage.getItem('user'));
      }
      const prdStartDate = moment('1970-01-01 08:00').valueOf();
      const prdEndDate = moment('2970-01-01 08:00').valueOf();
      // if(rows === 10){
      //   加载
      // }
      const data = yield call(appService.getReservationList, {
        vstKeyword: vstKeyword?.trim(),
        employeeId: user.employeeId,
        page,
        visitorName: '',
        visitorCompany: '',
        preVisitorTimeMin: '',
        preVisitorTimeMax: '',
        rows,
        companyId: user.companyId,
        prdStartDate,
        prdEndDate,
        from,
      });
      if (data.code === 0) {
        yield put({ type: 'isRefreshed', isRefreshed: true });
        yield put({ type: 'refreshing', refreshing: false });
        // 懒加载
        if (isRefresh) {
          yield put({
            type: 'saveShowList',
            payload: {
              showList: storedArr.concat(data.data.records),
              formType: 'setReservationList',
            },
          });
          yield put({
            type: 'save',
            payload: { storedArr: storedArr.concat(data.data.records) },
          });
        } else {
          yield put({
            type: 'saveShowList',
            payload: { showList: data.data.records, formType: 'setReservationList' },
          });
          yield put({
            type: 'save',
            payload: { storedArr: data.data.records },
          });
        }
        yield put({ type: 'save', payload: { page: data.data.current, total: data.data.total } });
        if (rows === 10 && data.data.records.length === 0) {
          yield put({ type: 'save', payload: { isnoData: true } });
        }

        if (window.getReservationListTime) {
          clearTimeout(window.getReservationListTime);
        }
        // 处理中
        const newList = [...data.data.records];
        const isWCL = newList.some((item) => item.visitorStatus?.includes('8'));
        if (isWCL) {
          yield new Promise((resolve) => {
            window.getReservationListTime = setTimeout(() => {
              resolve();
            }, 10000);
          });
          yield put({
            type: 'getReservationList',
          });
        }
      } else {
        Toast.fail(formatMessage({ id: `${data.msg}` }));
      }
      yield put({
        type: 'save',
        payload: {
          isEdit: false,
        },
      });
    },
    *getVisitList({ rows = 10, page = 1, isRefresh = false }, { call, put, select }) {
      let { user } = yield select((state) => state.app);
      const { vstKeyword, from, storedArr } = yield select((state) => state.app);
      if (!user || !Object.prototype.hasOwnProperty.call(user, 'companyId')) {
        user = JSON.parse(sessionStorage.getItem('user')) || {};
      }
      const vstStartDate = moment('1970-01-01 08:00').valueOf();
      const vstEndDate = moment('2970-01-01 08:00').valueOf();
      const data = yield call(appService.getVisitList, {
        vstKeyword: vstKeyword?.trim(),
        employeeId: user.employeeId,
        dataRight: user.employeeType,
        qDate: Date.now(),
        page,
        visitorName: '',
        visitorCompany: '',
        visitTimeMin: '',
        companyId: user.companyId,
        visitTimeMax: '',
        rows,
        from,
        vstStartDate,
        vstEndDate,
      });
      if (data.code === 0) {
        yield put({ type: 'isRefreshed', isRefreshed: true });
        yield put({ type: 'refreshing', refreshing: false });
        // 懒加载
        if (isRefresh) {
          yield put({
            type: 'saveShowList',
            payload: { showList: storedArr.concat(data.data.records), formType: 'visitList' },
          });
          yield put({
            type: 'save',
            payload: { storedArr: storedArr.concat(data.data.records) },
          });
        } else {
          yield put({
            type: 'saveShowList',
            payload: { showList: data.data.records, formType: 'visitList' },
          });
          yield put({
            type: 'save',
            payload: { storedArr: data.data.records },
          });
        }
        yield put({ type: 'save', payload: { page: data.data.current, total: data.data.total } });
        if (rows === 10 && data.data.records.length === 0) {
          yield put({ type: 'save', payload: { isnoData: true } });
        }
      } else {
        Toast.fail(formatMessage({ id: `${data.msg}` }));
      }
    },
    *getConfirmData({ rows = 10, page = 1, isRefresh = false }, { call, put, select }) {
      const { storedArr, vstKeyword } = yield select((state) => state.app);
      const res = yield call(AppService.getAuditData, {
        rows,
        page,
        vstKeyword: vstKeyword?.trim(),
      });
      const { data } = res;
      // 懒加载
      if (isRefresh) {
        yield put({
          type: 'saveShowList',
          payload: {
            showList: storedArr.concat(data.records),
            formType: 'setConfirmList',
          },
        });
        yield put({
          type: 'save',
          payload: { storedArr: storedArr.concat(data.records) },
        });
      } else {
        yield put({
          type: 'saveShowList',
          payload: { showList: data.records, formType: 'setConfirmList' },
        });
        yield put({
          type: 'save',
          payload: { storedArr: data.records },
        });
      }
      if (data.records.length === 0) {
        yield put({ type: 'save', payload: { isnoData: true } });
      } else {
        yield put({ type: 'isRefreshed', isRefreshed: true });
        yield put({ type: 'refreshing', refreshing: false });
        // 懒加载
        if (isRefresh) {
          yield put({
            type: 'saveShowList',
            payload: { showList: storedArr.concat(data.records), formType: 'setConfirmList' },
          });
          yield put({
            type: 'save',
            payload: { storedArr: storedArr.concat(data.records) },
          });
        } else {
          yield put({
            type: 'saveShowList',
            payload: { showList: data.records, formType: 'setConfirmList' },
          });
          yield put({
            type: 'save',
            payload: { storedArr: data.records },
          });
        }
        if (rows === 10 && data.records.length === 0) {
          yield put({ type: 'save', payload: { isnoData: true } });
        }
        if (window.getConfirmDataTime) {
          clearTimeout(window.getConfirmDataTime);
        }
        // 处理中 || 审批中
        if (
          data.records?.some(
            (record) => record.visitorStatus === '8' || record.visitorStatus === '12',
          )
        ) {
          yield new Promise((resolve) => {
            window.getConfirmDataTime = setTimeout(() => {
              resolve();
            }, 3 * 1000);
          });
          yield put({
            type: 'getConfirmData',
          });
        }
      }
      yield put({ type: 'save', payload: { page: data.current, total: data.total } });
      yield put({ type: 'getAuditCountByUserId' });
    },
    *createVisitor(_, { call, put, select }) {
      const {
        visitor,
        toConfirm,
        associates,
        area,
        meetingSwitch,
        faceInfo,
        selectKey,
        rejectReason,
        customFormList,
        areaList,
        meetingRooms,
        otherLocationId,
        meetingConfig,
        visitDayNum,
        acrossDayNum,
      } = yield select((state) => state.app);
      let { currentUser: user } = yield select((state) => state.user);
      if (!user || !Object.prototype.hasOwnProperty.call(user, 'companyId')) {
        user = JSON.parse(sessionStorage.getItem('user')) || {};
      }
      const { realName = '' } = user;
      visitor.faceUrl = faceInfo.list ? faceInfo.list[0].fileUrl : '';
      visitor.preVisitorTime = visitor.preVisitorTime
        ? visitor.preVisitorTime
        : changeTimeAfter(moment().add(10, 'm')).valueOf();
      visitor.preVisitorEndTime = visitor.preVisitorEndTime
        ? visitor.preVisitorEndTime
        : changeTimeAfter(moment().add(70, 'm')).valueOf();
      if (meetingSwitch) {
        visitor.meetingStartTime = visitor.meetingStartTime
          ? visitor.meetingStartTime
          : changeTimeAfter(moment().add(10, 'm')).valueOf();
      } else {
        visitor.meetingStartTime = '';
      }
      if (meetingSwitch) {
        visitor.meetingDuration = Math.ceil(
          (visitor.meetingEndTime - visitor.meetingStartTime) / 1000 / 60,
        );
      } else {
        visitor.meetingDuration = '';
      }
      // window.console.log(area);
      const idArr = area.id.toString().split(',');
      if (idArr.length === 2) {
        visitor.mapAddress = area.name;
        [visitor.lng, visitor.lat] = idArr;
        visitor.mapUrl = encodeURI(
          `http://uri.amap.com/marker?position=${area.id}&name=${area.name}`,
        );
        visitor.areaId = otherLocationId;
      } else if (area?.id.toString().includes('https')) {
        const { name = '' } = areaList?.find((item) => item?.id === area?.id) || {};
        visitor.mapAddress = name;
        visitor.mapUrl = encodeURI(area?.id);
        visitor.areaId = otherLocationId;
      } else {
        visitor.areaId = area.id;
        visitor.mapAddress = null;
        visitor.mapUrl = null;
      }
      if (user) {
        visitor.companyId = user.companyId;
      }
      if (meetingSwitch) {
        if (visitor.meetingRoom && visitor.meetingRoom.length > 0) {
          visitor.roomId = visitor.meetingRoom.join(';');
          visitor.advSignTime = getAdvSignTime(meetingRooms, visitor.meetingRoom);
          visitor.needAudit = getNeedAudit(meetingRooms, visitor.meetingRoom);
        } else {
          visitor.roomId = '';
          visitor.advSignTime = null;
          visitor.needAudit = null;
        }
        if (visitor.meetingRoomName && visitor.meetingRoomName.length > 0) {
          visitor.roomName = visitor.meetingRoomName.join(';');
        } else {
          visitor.roomName = '';
        }
        delete visitor.meetingEndTime;
      }

      visitor.orderMeeting = meetingSwitch ? 0 : 1;
      visitor.locationManageId = visitor.areaId || (areaList.length > 0 && areaList[0].id);
      visitor.destinationId = visitor.destinationId || null;
      visitor.destinationName = visitor.destinationName || '';
      delete visitor.areaId;
      if (!meetingSwitch) {
        delete visitor.roomId;
        delete visitor.advSignTime;
        delete visitor.needAudit;
        delete visitor.roomName;
        delete visitor.meetingTopic;
        delete visitor.meetingStartTime;
        delete visitor.meetingEndTime;
        delete visitor.meetingDuration;
      }
      visitor.dataPra = associates;
      let data = 'visitor';

      // 邀请人员去空
      const showVisitors = () => {
        const newVisitors = [];

        if (visitor.visitors) {
          const { visitors } = visitor;
          visitors.map((e) => {
            if (e.visitorName && !e.isBlack) {
              delete e.isBlack;
              newVisitors.push(e);
            }
            return newVisitors;
          });
        }
        return newVisitors;
      };
      visitor.visitors = showVisitors();

      // 处理visitors自定义数据
      let customForm = [];
      const resVisitors = [];
      visitor.visitors.map((vst) => {
        customFormList.map((item) => {
          if (Object.prototype.hasOwnProperty.call(vst, item.id)) {
            customForm.push({ [item.id]: vst[item.id] });
          } else {
            customForm.push({ [item.id]: item?.defaultValue || '' });
          }
        });
        resVisitors.push({ ...vst, customForm });
        customForm = [];
      });

      // 处理会议主题数据
      const meetingTopic = meetingSwitch
        ? visitor?.meetingTopic ||
          formatMessage(
            {
              id: 'reserve.visit.meeting.theme.placeholder',
            },
            { realName },
          )
        : null;
      visitor.visitors = resVisitors;
      if (
        selectKey === APP_ROUTE_PATH.APP_CONFIRM ||
        selectKey === APP_ROUTE_PATH.APP_CONFIRM_DETAIL ||
        toConfirm
      ) {
        data = yield call(appService.confirmReservation, {
          reservation: {
            ...visitor,
            rejectReason,
            meetingTopic,
          },
        });
      } else if (selectKey === APP_ROUTE_PATH.APP_SURE) {
        data = yield call(appService.confirmReservation, {
          reservation: {
            ...visitor,
            isConfirmed: 1,
            visitorSelfFlag: 1,
            rejectReason,
            meetingTopic,
          },
        });
      } else {
        delete visitor.areaId;
        data = yield call(appService.saveReservation, {
          reservation: {
            ...visitor,
            meetingTopic,
          },
        });
      }

      if (data.code) {
        if (data.msg === '202028') {
          Toast.fail(
            formatMessage({ id: `${data.msg}` }, { time: meetingConfig?.applyAdvanceTime || '' }),
            1,
          );
        } else if (data.msg === '202171') {
          Toast.fail(
            formatMessage(
              { id: `${data.msg}` },
              { value: meetingConfig?.oneDayMeetingCanReserveDays || '' },
            ),
            1,
          );
        } else if (data.msg === '202169' || data.msg === '202170') {
          Toast.fail(
            formatMessage(
              { id: 'meeting.booking.normal_day_meeting_limit' },
              { limitMinTime: data?.data?.min, limitMaxTime: data?.data?.max },
            ),
          );
        } else if (data.msg === '306084') {
          Toast.fail(
            formatMessage(
              { id: data.msg },
              { num: data?.data || meetingConfig?.longMeetingCanReserveDays },
            ),
          );
        } else if (data.msg === '202064') {
          Toast.fail(
            formatMessage(
              { id: data.msg },
              { value: data?.data || meetingConfig?.longMeetingMaxDays },
            ),
          );
        } else if (data.msg === '301014') {
          Toast.fail(formatMessage({ id: data.msg }, { value: visitDayNum - 1 }));
        } else if (data.msg === '301015') {
          Toast.fail(formatMessage({ id: data.msg }, { value: acrossDayNum - 1 }));
        } else {
          Toast.fail(formatMessage({ id: `${data.msg}` }, { timeFormat: data?.data || '' }), 1);
        }
        if (toConfirm) {
          history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_CONFIRM}`);
          yield put({ type: 'getConfirmData' });
          yield put({ type: 'setVisitor', payload: { ...visitor, meetingRoom: [] } });
        }
        return;
      }
      const message = typeof data.msg === 'string' ? data.msg : data.data.msg;
      Toast.success(formatMessage({ id: `${message}` }), 1);
      yield put({ type: 'getAuditCountByUserId' });

      if (selectKey === APP_ROUTE_PATH.APP_CONFIRM) {
        yield put({ type: 'getConfirmData' });
        // yield put({ type: 'getReservationSum' });
      } else if (selectKey === APP_ROUTE_PATH.APP_SURE || toConfirm) {
        history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_CONFIRM}`);
        yield put({ type: 'getConfirmData' });
      } else {
        history.replace(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_RESERVE}`);
        yield put({ type: 'getReservationList' });
      }
    },
    *loopBooking(_, { call, put, select }) {
      const {
        visitor,
        toConfirm,
        associates,
        area,
        meetingSwitch,
        faceInfo,
        selectKey,
        customFormList,
        otherLocationId,
      } = yield select((state) => state.app);
      let { currentUser: user } = yield select((state) => state.user);
      const { areaList } = yield select((state) => state.app);
      if (!user || !Object.prototype.hasOwnProperty.call(user, 'companyId')) {
        user = JSON.parse(sessionStorage.getItem('user')) || {};
      }
      visitor.faceUrl = faceInfo.list ? faceInfo.list[0].fileUrl : '';
      visitor.preVisitorTime = visitor.preVisitorTime
        ? visitor.preVisitorTime
        : changeTimeAfter(moment().add(10, 'm')).valueOf();
      visitor.preVisitorEndTime = visitor.preVisitorEndTime
        ? visitor.preVisitorEndTime
        : changeTimeAfter(moment().add(70, 'm')).valueOf();
      if (meetingSwitch) {
        visitor.meetingStartTime = visitor.meetingStartTime
          ? visitor.meetingStartTime
          : changeTimeAfter(moment().add(10, 'm')).valueOf();
      } else {
        visitor.meetingStartTime = '';
      }
      if (meetingSwitch) {
        visitor.meetingDuration = visitor.meetingDuration ? visitor.meetingDuration : '15';
      } else {
        visitor.meetingDuration = '';
      }
      window.console.log(area);
      const idArr = area.id.toString().split(',');
      if (idArr.length === 2) {
        visitor.mapAddress = area.name;
        [visitor.lng, visitor.lat] = idArr;
        visitor.mapUrl = encodeURI(
          `http://uri.amap.com/marker?position=${area.id}&name=${area.name}`,
        );
        visitor.areaId = otherLocationId;
      } else if (area?.id.toString().includes('https')) {
        const { name = '' } = areaList?.find((item) => item?.id === area?.id) || {};
        visitor.mapAddress = name;
        visitor.mapUrl = encodeURI(area?.id);
        visitor.areaId = otherLocationId;
      } else {
        visitor.areaId = area.id;
        visitor.mapAddress = null;
        visitor.mapUrl = null;
      }
      if (user) {
        visitor.companyId = user.companyId;
      }
      if (meetingSwitch) {
        if (visitor.meetingRoom && visitor.meetingRoom.length > 0) {
          visitor.roomId = visitor.meetingRoom.join(';');
        } else {
          visitor.roomId = '';
        }
        if (visitor.meetingRoomName && visitor.meetingRoomName.length > 0) {
          visitor.roomName = visitor.meetingRoomName.join(';');
        } else {
          visitor.roomName = '';
        }
      }

      visitor.orderMeeting = meetingSwitch ? 0 : 1;
      visitor.locationManageId = visitor.areaId || (areaList.length > 0 && areaList[0].id);
      visitor.destinationId = visitor.destinationId || null;
      visitor.destinationName = visitor.destinationName || '';
      delete visitor.areaId;
      if (!meetingSwitch) {
        delete visitor.roomId;
        delete visitor.roomName;
        delete visitor.meetingStartTime;
        delete visitor.meetingDuration;
        delete visitor.meetingTopic;
      }
      visitor.dataPra = associates;
      let data = 'visitor';

      // 邀请人员去空
      const showVisitors = () => {
        const newVisitors = [];

        if (visitor.visitors) {
          const { visitors } = visitor;
          visitors.map((e) => {
            if (e.visitorName && !e.isBlack) {
              delete e.isBlack;
              newVisitors.push(e);
            }
            return newVisitors;
          });
        }
        return newVisitors;
      };
      visitor.visitors = showVisitors();

      // 处理visitors自定义数据
      let customForm = [];
      const resVisitors = [];
      visitor.visitors.map((vst) => {
        customFormList.map((item) => {
          if (Object.prototype.hasOwnProperty.call(vst, item.id)) {
            customForm.push({ [item.id]: vst[item.id] });
          } else {
            customForm.push({ [item.id]: item?.defaultValue || '' });
          }
        });
        resVisitors.push({ ...vst, customForm });
        customForm = [];
      });

      visitor.visitors = resVisitors;

      delete visitor.areaId;
      data = yield call(appService.loopBooking, {
        reservation: {
          ...visitor,
        },
      });
      if (data.msg) {
        Toast.fail(formatMessage({ id: `${data.msg}` }, { timeFormat: data?.data || '' }), 1);
        if (toConfirm) {
          history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_CONFIRM}`);
          yield put({ type: 'getConfirmData' });
          yield put({ type: 'setVisitor', payload: { ...visitor, meetingRoom: [] } });
        }
        return;
      }
      // const message = typeof data.data === 'string' ? data.data : data.data.data;
      Toast.success(formatMessage({ id: '303027' }), 1);
      yield put({ type: 'getAuditCountByUserId' });
      yield put({
        type: 'save',
        payload: {
          loopTypeId: 0,
          loopTypeName: '',
          loopItem: {
            loopTypeId: 0,
            loopTypeName: '',
          },
          dayModeCount: 1,
          loopCount: 2,
          weekModeCount: [1],
        },
      });
      if (selectKey === APP_ROUTE_PATH.APP_CONFIRM) {
        yield put({ type: 'getConfirmData' });
        // yield put({ type: 'getReservationSum' });
      } else if (selectKey === APP_ROUTE_PATH.APP_SURE || toConfirm) {
        history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_CONFIRM}`);
        yield put({ type: 'getConfirmData' });
      } else {
        history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_RESERVE}`);
        yield put({ type: 'getReservationList' });
      }
    },
    *modifyReservation({ btnType = '', setVisitorFillPopupData }, { call, put, select }) {
      const {
        visitor,
        toConfirm,
        associates,
        meetingRooms,
        area,
        meetingSwitch,
        faceInfo,
        selectKey,
        customFormList,
        detail,
        customDetail,
        parentCustomFormDetail,
        areaList,
        cancelVisitors,
        otherLocationId,
        meetingConfig,
        visitDayNum,
        acrossDayNum,
      } = yield select((state) => state.app);
      let { currentUser: user } = yield select((state) => state.user);
      if (!user || !Object.prototype.hasOwnProperty.call(user, 'companyId')) {
        user = JSON.parse(sessionStorage.getItem('user')) || {};
      }
      const { realName = '' } = user;
      visitor.faceUrl = faceInfo.list ? faceInfo.list[0].fileUrl : '';
      visitor.preVisitorTime = visitor.preVisitorTime
        ? visitor.preVisitorTime
        : changeTimeAfter(moment().add(10, 'm')).valueOf();
      visitor.preVisitorEndTime = visitor.preVisitorEndTime
        ? visitor.preVisitorEndTime
        : changeTimeAfter(moment().add(70, 'm')).valueOf();
      if (meetingSwitch) {
        visitor.meetingStartTime = visitor.meetingStartTime
          ? visitor.meetingStartTime
          : changeTimeAfter(moment().add(10, 'm')).valueOf();
      } else {
        visitor.meetingStartTime = '';
      }
      if (meetingSwitch) {
        visitor.meetingDuration = Math.ceil(
          (visitor.meetingEndTime - visitor.meetingStartTime) / 1000 / 60,
        );
      } else {
        visitor.meetingDuration = '';
      }
      const idArr = area.id.toString().split(',');
      if (idArr.length === 2) {
        visitor.mapAddress = area.name;
        [visitor.lng, visitor.lat] = idArr;
        visitor.mapUrl = encodeURI(
          `http://uri.amap.com/marker?position=${area.id}&name=${area.name}`,
        );
        visitor.areaId = otherLocationId;
      } else if (area?.id.toString().includes('https')) {
        const { name = '' } = areaList?.find((item) => item?.id === area?.id) || {};
        visitor.mapAddress = name;
        visitor.mapUrl = encodeURI(area?.id);
        visitor.areaId = otherLocationId;
      } else {
        visitor.areaId = area.id;
        visitor.mapAddress = null;
        visitor.mapUrl = null;
      }
      if (user) {
        visitor.companyId = user.companyId;
      }
      if (meetingSwitch) {
        if (visitor.meetingRoom && visitor.meetingRoom.length > 0) {
          visitor.roomId = visitor.meetingRoom.join(';');
          visitor.advSignTime = getAdvSignTime(meetingRooms, visitor.meetingRoom);
          visitor.needAudit = getNeedAudit(meetingRooms, visitor.meetingRoom);
        } else {
          visitor.roomId = '';
          visitor.advSignTime = null;
          visitor.needAudit = null;
        }
        if (visitor.meetingRoomName && visitor.meetingRoomName.length > 0) {
          visitor.roomName = visitor.meetingRoomName.join(';');
        } else {
          visitor.roomName = '';
        }
      }

      visitor.orderMeeting = meetingSwitch ? 0 : 1;
      visitor.locationManageId = visitor.areaId || (areaList.length > 0 && areaList[0].id);
      visitor.destinationId = visitor.destinationId || null;
      visitor.destinationName = visitor.destinationName || '';
      delete visitor.areaId;
      if (!meetingSwitch) {
        delete visitor.roomId;
        delete visitor.advSignTime;
        delete visitor.needAudit;
        delete visitor.roomName;
        delete visitor.meetingStartTime;
        delete visitor.meetingEndTime;
        delete visitor.meetingDuration;
        delete visitor.meetingTopic;
      }
      // 处理visitors自定义数据
      let customForm = [];
      const resVisitors = [];
      visitor.visitors.map((vst) => {
        customFormList.map((item) => {
          if (Object.prototype.hasOwnProperty.call(vst, item.id)) {
            customForm.push({ [item.id]: vst[item.id] });
          } else {
            customForm.push({ [item.id]: item?.defaultValue || '' });
          }
        });
        resVisitors.push({ ...vst, customForm });
        customForm = [];
      });
      if (resVisitors.length) {
        visitor.telAreaCode = resVisitors[0].telAreaCode;
        visitor.visitorCompany = resVisitors[0].visitorCompany;
        visitor.visitorMail = resVisitors[0].visitorMail;
        visitor.carNumber = resVisitors[0].carNumber;
        visitor.visitorNumber = resVisitors[0].visitorNumber;
        visitor.visitorCardNo = resVisitors[0].visitorCardNo;
        visitor.visitorName = resVisitors[0].visitorName;
        visitor.visitorNoticeLang = resVisitors[0]?.visitorNoticeLang;
      }
      visitor.visitors = resVisitors;

      visitor.dataPra = associates;
      let data = '';
      if (selectKey === APP_ROUTE_PATH.APP_CONFIRM || toConfirm) {
        data = yield call(appService.confirmReservation, {
          reservation: {
            ...visitor,
          },
        });
      } else if (selectKey === APP_ROUTE_PATH.APP_SURE) {
        data = yield call(appService.confirmReservation, {
          reservation: {
            ...visitor,
            isConfirmed: 1,
            visitorSelfFlag: 1,
          },
        });
      } else {
        delete visitor.areaId;
        // 变更邀请数据校验处理
        let oldVisit = {};
        let newVisit = {};
        let oldVisitorList = [];
        let newVisitorList = [];
        if (
          (Array.isArray(detail.children) && detail.children.length > 1) ||
          visitor.visitors.length > 1
        ) {
          let oldVisitorCustomForm = [];
          let newVisitorCustomForm = [];
          detail.children
            ?.filter((child) => child?.visitorStatus === '0')
            ?.map((item) => {
              let oldCustomForm = [];
              let customForm = {};
              if (Array.isArray(detail.children) && detail.children.length > 1) {
                if (parentCustomFormDetail.length) {
                  customForm = parentCustomFormDetail.filter(
                    (v) => v.preId === item.preRegistrationId,
                  )[0];
                }
                if (customForm?.list && customForm.list.length) {
                  customForm.list.map((v) => {
                    oldCustomForm.push({
                      [v.id]: v.value,
                    });
                  });
                }
              } else if (visitor.visitors.length > 1) {
                if (customDetail.length) {
                  customDetail.map((item) => {
                    oldCustomForm.push({
                      [item.id]: item.value,
                    });
                  });
                }
              }
              oldVisitorList.push({
                preRegistrationId: item.preRegistrationId,
                visitorName: item.visitorName,
                telAreaCode: item.telAreaCode,
                visitorNumber: item.visitorNumber || null,
                visitorCardNo: item.visitorCardNo || '',
                visitorCompany: item.visitorCompany,
                visitorMail: item.visitorMail,
                carNumber: item.carNumber || '',
                visitorNoticeLang: item?.visitorNoticeLang || null,
                // customForm: oldCustomForm,
              });
              oldVisitorCustomForm.push({
                customForm: oldCustomForm,
                preRegistrationId: item.preRegistrationId,
              });
            });
          if (visitor.visitors.length) {
            visitor.visitors.map((item) => {
              newVisitorList.push({
                preRegistrationId: item.preRegistrationId || '',
                visitorName: item.visitorName,
                telAreaCode: item.telAreaCode,
                visitorNumber: item.visitorNumber || null,
                visitorCardNo: item.visitorCardNo || '',
                visitorCompany: item.visitorCompany,
                visitorMail: item.visitorMail,
                carNumber: item.carNumber || '',
                visitorNoticeLang: item?.visitorNoticeLang || null,
                // customForm: item.customForm,
              });
              newVisitorCustomForm.push({
                customForm: item.customForm,
                preRegistrationId: item.preRegistrationId,
              });
            });
          }
          oldVisit = {
            vipFlag: String(detail.vipFlag),
            locationManageId: detail.locationManageId,
            visitorTypeId: detail.visitorTypeId,
            preVisitorTime: detail.preVisitorTime,
            preVisitorEndTime: detail.preVisitorEndTime,
            destinationId: detail.destinationId || '',
            meetingStartTime: Number(detail.meetingRoomDetail.meetingStartTime),
            meetingEndTime: Number(detail.meetingRoomDetail.meetingEndTime),
            roomId: Number(detail.meetingRoomDetail.roomId) || '',
            roomName: detail.meetingRoomDetail.roomName || '',
            meetingTopic: detail.meetingRoomDetail.meetingTopic || '',
            meetingId: Number(detail.meetingRoomDetail.meetingId),
            meetingDuration: String(detail.meetingRoomDetail.meetingDuration),
            bookType: String(detail.meetingRoomDetail.bookType || 0),
            lat: detail.meetingRoomDetail.lat ? String(detail.meetingRoomDetail.lat) : '',
            lng: detail.meetingRoomDetail.lng ? String(detail.meetingRoomDetail.lng) : '',
            locationName: detail.meetingRoomDetail?.mapUrl
              ? detail.meetingRoomDetail.locationName
              : null,
            mapUrl: detail.meetingRoomDetail?.mapUrl || null,
            reserveLocker: detail?.reserveLocker || null,
            specificCause: detail?.specificCause || null,
            employeeId: (detail?.employeeId && String(detail?.employeeId)) || null,
          };
          newVisit = {
            vipFlag: String(visitor.vipFlag),
            locationManageId: visitor.locationManageId,
            visitorTypeId: visitor.visitorTypeId,
            preVisitorTime: visitor.preVisitorTime,
            preVisitorEndTime: visitor.preVisitorEndTime,
            destinationId: visitor.destinationId || '',
            meetingStartTime: visitor.meetingStartTime ? Number(visitor.meetingStartTime) : 0,
            meetingEndTime: visitor.meetingEndTime ? Number(visitor.meetingEndTime) : 0,
            roomId: visitor.roomId ? Number(visitor.roomId) : '',
            roomName: visitor.roomName || '',
            meetingTopic: visitor.meetingTopic || '',
            meetingId: visitor.meetingId ? Number(visitor.meetingId) : 0,
            meetingDuration: visitor.meetingDuration ? String(visitor.meetingDuration) : '0',
            bookType: String(visitor.bookType || 0),
            lat: visitor?.lat || '',
            lng: visitor?.lng || '',
            locationName: visitor?.mapUrl
              ? visitor?.mapAddress || visitor?.locationManageName
              : null,
            mapUrl: visitor?.mapUrl || null,
            reserveLocker: visitor?.reserveLocker || null,
            specificCause: visitor?.specificCause || null,
            employeeId: (visitor?.employeeId && String(visitor?.employeeId)) || null,
          };
          window.console.log(
            JSON.stringify(oldVisit),
            JSON.stringify(oldVisitorList),
            JSON.stringify(oldVisitorCustomForm),
            'JSON.stringify(oldVisit)',
          );
          window.console.log(
            JSON.stringify(newVisit),
            JSON.stringify(newVisitorList),
            JSON.stringify(newVisitorCustomForm),
            'JSON.stringify(newVisit)',
          );
          if (
            JSON.stringify(oldVisit) === JSON.stringify(newVisit) &&
            JSON.stringify(oldVisitorList) === JSON.stringify(newVisitorList) &&
            JSON.stringify(oldVisitorCustomForm) === JSON.stringify(newVisitorCustomForm)
          ) {
            Toast.fail(formatMessage({ id: 'visit.reserve.edit.error' }), 1);
            return;
          } else if (JSON.stringify(oldVisit) !== JSON.stringify(newVisit)) {
            visitor.isVisitEdit = true;
          } else if (JSON.stringify(oldVisitorList) !== JSON.stringify(newVisitorList)) {
            newVisitorList.forEach((item, idx) => {
              // 通过preId匹配数据进行比对，没有preId为新增数据
              const oldItemObj = oldVisitorList.filter(
                (oldItem) => String(oldItem.preRegistrationId) === String(item.preRegistrationId),
              )[0];
              if (JSON.stringify(item) !== JSON.stringify(oldItemObj) && item.preRegistrationId) {
                visitor.visitors[idx].isEditVisitor = true;
              }
            });
          }
          if (JSON.stringify(oldVisitorCustomForm) !== JSON.stringify(newVisitorCustomForm)) {
            newVisitorCustomForm.forEach((item, idx) => {
              const oldCustomObj = oldVisitorCustomForm.filter(
                (oldItem) => String(oldItem.preRegistrationId) === String(item.preRegistrationId),
              )[0];
              if (JSON.stringify(item) !== JSON.stringify(oldCustomObj) && item.preRegistrationId) {
                visitor.visitors[idx].isEditCustom = true;
              }
            });
          }
        } else {
          let oldVisitorCustomForm = [];
          let newVisitorCustomForm = [];
          let oldCustomForm = [];
          if (customDetail.length) {
            customDetail.map((item) => {
              oldCustomForm.push({
                [item.id]: item.value,
              });
            });
          }
          if (detail?.visitorName) {
            oldVisitorList.push({
              preRegistrationId: detail.preRegistrationId || '',
              visitorName: detail.visitorName,
              telAreaCode: detail.telAreaCode,
              visitorNumber: detail.visitorNumber || null,
              visitorCardNo: detail.visitorCardNo || '',
              visitorCompany: detail.visitorCompany,
              visitorMail: detail.visitorMail,
              carNumber: detail.carNumber || '',
              visitorNoticeLang: detail?.visitorNoticeLang || null,
              // customForm: oldCustomForm,
            });
            oldVisitorCustomForm.push({
              customForm: oldCustomForm,
              preRegistrationId: detail.preRegistrationId,
            });
          }
          if (visitor.visitors.length) {
            visitor.visitors.map((item) => {
              newVisitorList.push({
                preRegistrationId: item.preRegistrationId || '',
                visitorName: item.visitorName,
                telAreaCode: item.telAreaCode,
                visitorNumber: item.visitorNumber || null,
                visitorCardNo: item.visitorCardNo || '',
                visitorCompany: item.visitorCompany,
                visitorMail: item.visitorMail,
                carNumber: item.carNumber || '',
                visitorNoticeLang: item?.visitorNoticeLang || null,
                // customForm: item.customForm || [],
              });
              newVisitorCustomForm.push({
                customForm: item.customForm,
                preRegistrationId: item.preRegistrationId,
              });
            });
          }
          oldVisit = {
            vipFlag: String(detail.vipFlag),
            locationManageId: detail.locationManageId,
            visitorTypeId: detail.visitorTypeId,
            preVisitorTime: detail.preVisitorTime,
            preVisitorEndTime: detail.preVisitorEndTime,
            destinationId: detail.destinationId || '',
            meetingStartTime: Number(detail.meetingRoomDetail.meetingStartTime),
            meetingEndTime: Number(detail.meetingRoomDetail.meetingEndTime),
            roomId: Number(detail.meetingRoomDetail.roomId) || '',
            roomName: detail.meetingRoomDetail.roomName || '',
            meetingTopic: detail.meetingRoomDetail.meetingTopic || '',
            meetingId: Number(detail.meetingRoomDetail.meetingId),
            meetingDuration: String(detail.meetingRoomDetail.meetingDuration),
            bookType: String(detail.meetingRoomDetail.bookType || 0),
            lat: detail.meetingRoomDetail.lat ? String(detail.meetingRoomDetail.lat) : '',
            lng: detail.meetingRoomDetail.lng ? String(detail.meetingRoomDetail.lng) : '',
            locationName: detail.meetingRoomDetail?.mapUrl
              ? detail.meetingRoomDetail.locationName
              : null,
            mapUrl: detail.meetingRoomDetail?.mapUrl || null,
            reserveLocker: detail?.reserveLocker || null,
            specificCause: detail?.specificCause || null,
            employeeId: (detail?.employeeId && String(detail?.employeeId)) || null,
          };
          newVisit = {
            vipFlag: String(visitor.vipFlag),
            locationManageId: visitor.locationManageId,
            visitorTypeId: visitor.visitorTypeId,
            preVisitorTime: visitor.preVisitorTime,
            preVisitorEndTime: visitor.preVisitorEndTime,
            destinationId: visitor.destinationId || '',
            meetingStartTime: visitor.meetingStartTime ? Number(visitor.meetingStartTime) : 0,
            meetingEndTime: visitor.meetingEndTime ? Number(visitor.meetingEndTime) : 0,
            roomId: visitor.roomId ? Number(visitor.roomId) : '',
            roomName: visitor.roomName || '',
            meetingTopic: visitor.meetingTopic || '',
            meetingId: visitor.meetingId ? Number(visitor.meetingId) : 0,
            meetingDuration: visitor.meetingDuration ? String(visitor.meetingDuration) : '0',
            bookType: String(visitor.bookType || 0),
            lat: visitor?.lat || '',
            lng: visitor?.lng || '',
            locationName: visitor?.mapUrl
              ? visitor?.mapAddress || visitor?.locationManageName
              : null,
            mapUrl: visitor?.mapUrl || null,
            reserveLocker: visitor?.reserveLocker || null,
            specificCause: visitor?.specificCause || null,
            employeeId: (visitor?.employeeId && String(visitor?.employeeId)) || null,
          };
          window.console.log(
            JSON.stringify(oldVisit),
            JSON.stringify(oldVisitorList),
            JSON.stringify(oldVisitorCustomForm),
            'JSON.stringify(oldVisit)',
          );
          window.console.log(
            JSON.stringify(newVisit),
            JSON.stringify(newVisitorList),
            JSON.stringify(newVisitorCustomForm),
            'JSON.stringify(newVisit)',
          );
          if (
            JSON.stringify(oldVisit) === JSON.stringify(newVisit) &&
            JSON.stringify(oldVisitorList) === JSON.stringify(newVisitorList) &&
            JSON.stringify(oldVisitorCustomForm) === JSON.stringify(newVisitorCustomForm)
          ) {
            Toast.fail(formatMessage({ id: 'visit.reserve.edit.error' }), 1);
            return;
          } else if (JSON.stringify(oldVisit) !== JSON.stringify(newVisit)) {
            visitor.isVisitEdit = true;
          } else if (JSON.stringify(oldVisitorList) !== JSON.stringify(newVisitorList)) {
            newVisitorList.forEach((item, idx) => {
              // 通过preId匹配数据进行比对，没有preId为新增数据
              const oldItemObj = oldVisitorList.filter(
                (oldItem) => String(oldItem.preRegistrationId) === String(item.preRegistrationId),
              )[0];
              if (JSON.stringify(item) !== JSON.stringify(oldItemObj) && item.preRegistrationId) {
                visitor.visitors[idx].isEditVisitor = true;
              }
            });
          }
          if (JSON.stringify(oldVisitorCustomForm) !== JSON.stringify(newVisitorCustomForm)) {
            newVisitorCustomForm.forEach((item, idx) => {
              const oldCustomObj = oldVisitorCustomForm.filter(
                (oldItem) => String(oldItem.preRegistrationId) === String(item.preRegistrationId),
              )[0];
              if (JSON.stringify(item) !== JSON.stringify(oldCustomObj) && item.preRegistrationId) {
                visitor.visitors[idx].isEditCustom = true;
              }
            });
          }
        }
        let isSendVisitorNotice = true;
        if (
          !getIsSendVisitorNotice(oldVisit, newVisit, oldVisitorList, newVisitorList) &&
          visitor?.visitors?.length
        ) {
          yield new Promise((resolve) => {
            alert('', formatMessage({ id: 'visit.reserve.visitor.edit.send.notice' }), [
              {
                text: formatMessage({
                  id: 'visit.reserve.visitor.edit.send.notice.no',
                }),
                onPress: () => {
                  resolve();
                  isSendVisitorNotice = false;
                },
              },
              {
                text: formatMessage({
                  id: 'visit.reserve.visitor.edit.send.notice.yes',
                }),
                onPress: () => {
                  resolve();
                  isSendVisitorNotice = true;
                },
              },
            ]);
          });
        }
        delete visitor.meetingRoom;
        delete visitor.meetingRoomName;
        // 处理会议主题数据
        const meetingTopic = meetingSwitch
          ? visitor?.meetingTopic ||
            formatMessage(
              {
                id: 'reserve.visit.meeting.theme.placeholder',
              },
              { realName },
            )
          : null;
        data = yield call(appService.modifyReservation, {
          reservation: {
            ...visitor,
            visitors: visitor?.visitors?.concat(cancelVisitors || []),
            isSendVisitorNotice,
            meetingTopic,
          },
        });
      }
      if (data.code) {
        if (data.msg === '202028') {
          Toast.fail(
            formatMessage({ id: `${data.msg}` }, { time: meetingConfig?.applyAdvanceTime || '' }),
            1,
          );
        } else if (data.msg === '202171') {
          Toast.fail(
            formatMessage(
              { id: `${data.msg}` },
              { value: meetingConfig?.oneDayMeetingCanReserveDays || '' },
            ),
            1,
          );
        } else if (data.msg === '202169' || data.msg === '202170') {
          Toast.fail(
            formatMessage(
              { id: 'meeting.booking.normal_day_meeting_limit' },
              { limitMinTime: data?.data?.min, limitMaxTime: data?.data?.max },
            ),
          );
        } else if (data.msg === '306084') {
          Toast.fail(
            formatMessage(
              { id: data.msg },
              { num: data?.data || meetingConfig?.longMeetingCanReserveDays },
            ),
          );
        } else if (data.msg === '202064') {
          Toast.fail(
            formatMessage(
              { id: data.msg },
              { value: data?.data || meetingConfig?.longMeetingMaxDays },
            ),
          );
        } else if (data.msg === '301014') {
          Toast.fail(formatMessage({ id: data.msg }, { value: visitDayNum - 1 }));
        } else if (data.msg === '301015') {
          Toast.fail(formatMessage({ id: data.msg }, { value: acrossDayNum - 1 }));
        } else {
          Toast.fail(formatMessage({ id: `${data.msg}` }, { timeFormat: data?.data || '' }), 1);
        }
        if (toConfirm) {
          history.replace(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_CONFIRM}`);
          yield put({ type: 'getConfirmData' });
        }
        return;
      }
      Toast.success(formatMessage({ id: `${data.msg}` }), 2.5);
      yield put({ type: 'save', payload: { modifyReservation: false } });
      if (selectKey === APP_ROUTE_PATH.APP_CONFIRM) {
        yield put({ type: 'getConfirmData' });
      } else if (selectKey === APP_ROUTE_PATH.APP_SURE || toConfirm) {
        history.replace(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_CONFIRM}`);
        yield put({ type: 'getConfirmData' });
      } else {
        history.replace(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_RESERVE}`);
        yield put({ type: 'getReservationList' });
      }
      /** 来自访客补填，成功后弹出补填分享页面 */
      if (btnType === 'visitorFill' && setVisitorFillPopupData)
        setVisitorFillPopupData({
          visitorFillPopupVisible: true,
          batchAddGroupId: data.data,
        });
    },
    *cancelReservation({ visitor: visitorInfo, fromPage }, { call, put, select }) {
      let { currentUser: user } = yield select((state) => state.user);
      const visitor = visitorInfo;
      if (!user || !Object.prototype.hasOwnProperty.call(user, 'companyId')) {
        user = JSON.parse(sessionStorage.getItem('user')) || {};
      }
      delete visitor.roomId;
      delete visitor.roomName;
      delete visitor.meetingStartTime;
      delete visitor.meetingEndTime;
      delete visitor.meetingDuration;
      delete visitor.meetingTopic;
      visitor.companyId = user.companyId;
      visitor.orderMeeting = 1;
      const data = yield call(appService.cancelReservation, {
        reservation: visitor,
      });
      if (data.code) {
        Toast.fail(formatMessage({ id: `${data.msg}` }));
        return;
      }
      Toast.success(formatMessage({ id: `${data.msg}` }));
      if (fromPage === APP_ROUTE_PATH.APP_RESERVE) {
        yield put({ type: 'getReservationList' });
        history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_RESERVE}`);
      } else if (fromPage === APP_ROUTE_PATH.APP_VISIT) {
        yield put({ type: 'getVisitList' });
        history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_VISIT}`);
      }
    },
    *signOutVisitor({ visitorData }, { call, put }) {
      const data = yield call(appService.signOut, { visitorData });
      if (data?.code) {
        Toast.fail(formatMessage({ id: `${data.msg}` }));
      } else if (!data?.data?.isUseLocker) {
        Toast.success(formatMessage({ id: `${data.msg}` }));
        yield put({ type: 'getVisitList' });
        history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_VISIT}`);
      }
      return data?.data || {};
    },
    *getVisitorType(_, { call, put, select }) {
      const { visitor } = yield select((state) => state.app);
      const result = yield call(AppService.getVisitType);
      if (result.msg) {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
      } else {
        yield put({ type: 'setVisitorType', visitorType: result.data });
        if (result.data.length > 0 && !visitor?.visitorTypeId) {
          yield put({
            type: 'setVisitor',
            visitor: {
              ...visitor,
              visitorTypeId: visitor.visitorTypeId || result.data[0].id,
              visitorType: visitor.visitorType || result.data[0].name,
            },
          });
        }
      }
    },

    // * 获取会议室
    *getAllMeetingRoom(
      {
        startTime,
        meetingDuration,
        meetingId = '',
        bookType,
        callback,
        callbackParams,
        resolve,
        ...rest
      },
      { call, put, select },
    ) {
      const { zoneIds, name, seat, deviceIds } = rest;
      const { spaceTree } = yield select((state) => state.app);

      const result = yield call(appService.getAllMeetingRoom, {
        startTime: moment(startTime).valueOf(),
        meetingDuration,
        meetingId: meetingId || '',
        bookType,
        // new
        name: name?.trim() || '',
        seat: seat || '',
        deviceIds: deviceIds?.join(';') || '',
        zoneIds: zoneIds !== -1 && zoneIds !== undefined ? zoneIds : spaceTree[0]?.id,
      });
      if (result.msg) {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
        yield put({ type: 'meetingRooms', meetingRooms: {} });
        yield put({ type: 'allMeetingRoom', allMeetingRoom: [] });
      } else {
        if (!result.data.rooms?.length && result.data?.sameTimeReserveError) {
          Toast.fail(formatMessage({ id: 'visit.reserve.meeting.check.same.time.tip' }));
          return;
        }
        yield put({ type: 'meetingRooms', meetingRooms: result.data });
        yield put({
          type: 'allMeetingRoom',
          allMeetingRoom: meetingRoomFormat(result.data.rooms),
        });
        if (resolve) {
          resolve({
            allMeetingRoom: meetingRoomFormat(result.data.rooms),
            meetingRooms: result.data,
          });
        }
      }
      if (callback && callbackParams) callback(result?.data || {}, callbackParams || {});
    },

    // 默认请求根下所有会议室
    *getRootMeetingRoom(
      { startTime, meetingDuration, meetingId = '', bookType, resolve, ...rest },
      { call },
    ) {
      const { name, seat, deviceIds, zoneIds } = rest;
      const data = {
        startTime,
        meetingDuration,
        meetingId,
        bookType,
        // new
        name: name?.trim() || '',
        seat: seat || '',
        deviceIds: deviceIds || '',
        zoneIds: zoneIds || '',
      };
      const result = yield call(appService.getAllMeetingRoom, data);
      if (result.msg) {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
      } else {
        if (!result.data.rooms?.length && result.data?.sameTimeReserveError) {
          Toast.fail(formatMessage({ id: 'visit.reserve.meeting.check.same.time.tip' }));
          return;
        }
        if (resolve) {
          resolve({
            meetingRooms: result.data,
          });
        }
      }
    },

    *getArea(_, { call, put, select }) {
      const result = yield call(appService.getArea);
      const { area } = yield select((state) => state.app);
      const { privateLocationList, shareLocationList, systemMode } = result.data;
      if (result.msg) {
        Toast.info(formatMessage({ id: `${result.msg}` }));
      } else {
        // 运营模式
        let newData = privateLocationList;
        if (systemMode === '1' && shareLocationList.length) {
          newData = privateLocationList.concat(shareLocationList);
        }
        yield put({ type: 'areaList', areaList: newData });
        if (!area.id) {
          yield put({ type: 'save', payload: { area: newData[0] } });
        }
        yield put({
          type: 'systemObj',
          systemObj: {
            systemMode: systemMode === '1' && shareLocationList.length ? systemMode : '0',
            systemIndex: privateLocationList.length,
          },
        });
        yield put({
          type: 'setOtherLocationId',
          otherLocationId: result.data.otherLocation.id,
        });
      }
    },
    *getVisit({ payload: { id, fromPage, detail } }, { call, put }) {
      const result = yield call(AppService.getVisit, { id });
      if (result.msg) {
        Toast.info(formatMessage({ id: `${result.msg}` }));
      } else {
        yield put({
          type: 'setDetail',
          detail: { ...result.data, ...detail },
          fromPage,
        });
        yield put({
          type: 'getInfoByBatchId',
          payload: {
            batchAddGroupId: result.data.batchAddGroupId,
          },
          isChild: true,
        });
        history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_VISIT_DETAIL}`);
        yield put({ type: 'visitVisitor', visitVisitor: result.data });
      }
    },
    *searchMap({ keywords }, { call, put }) {
      const result = yield call(appService.hint, { keywords });
      if (result.status === '1') {
        yield put({ type: 'mapList', mapList: result.tips });
      } else {
        Toast.fail(formatMessage({ id: 'app.map.none' }));
      }
    },

    *getVisitorAccompany({ visitorData }, { call, put }) {
      const data = yield call(appService.getVisitorAccompany, { visitorData });
      if (data.code) {
        // message.error(formatMessage({id:`visitor.status.${data.msg}`}));
      } else {
        // message.success(formatMessage({id:`visitor.status.${data.msg}`}));
        yield put({ type: 'setAccompany', accompany: data.data });
      }
    },
    *getVisitorAccompanyConfrim({ visitorData }, { call, put }) {
      const data = yield call(appService.getVisitorAccompanyConfrim, { visitorData });
      if (data.code) {
        // message.error(formatMessage({id:`visitor.status.${data.msg}`}));
      } else {
        // message.success(formatMessage({id:`visitor.status.${data.msg}`}));
        yield put({ type: 'setAccompany', accompany: data.data });
      }
    },
    *pushAddress({ payload }, { put, select }) {
      const { areaList, systemObj } = yield select((state) => state.app);
      const newAreaList = _.cloneDeep(areaList);
      newAreaList.splice(
        newAreaList.findIndex((item) => String(item.id) === payload.id) > -1
          ? newAreaList.findIndex((item) => String(item.id) === payload.id)
          : systemObj.systemIndex,
        newAreaList.findIndex((item) => String(item.id) === payload.id) > -1 ? 1 : 0,
        payload,
      );
      yield put({ type: 'areaList', areaList: newAreaList });
      yield put({
        type: 'systemObj',
        systemObj: {
          ...systemObj,
          systemIndex:
            areaList.findIndex((item) => String(item.id) === payload.id) > -1
              ? systemObj.systemIndex
              : (systemObj.systemIndex += 1),
        },
      });
    },
    *getAreaCode({ companyId = null }, { put, call, select }) {
      const { currentUser: { tenantId } = {} } = yield select((state) => state.user);
      const user = JSON.parse(sessionStorage.getItem('user')) || {};
      if (!(companyId || tenantId || user?.tenantId)) {
        return;
      }
      const result = yield call(checkInService.getAreaCode, {
        tenantId: companyId || tenantId || user?.tenantId,
      });
      if (result.code) {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
        return;
      }
      yield put({ type: 'areaCode', areaCode: result.data });
      /* 设置code码 */
      if (result.data && result.data[0] && result.data[0].code) {
        const { visitor } = yield select((state) => state.app);
        const obj = { ...visitor, telAreaCode: result.data[0].code };
        yield put({ type: 'save', payload: { visitor: obj } });
      }
    },
    // *showInvitationCode({ invitationCode }, { put, call }) {
    //   const result = yield call(checkInService.showInvitationCode, {
    //     invitationCode,
    //   });
    //   if (result.result === 0) {
    //     yield put({ type: 'invitationCode', invitationCode: result.resObj });
    //   } else {
    //     Toast.fail(formatMessage({ id: `${result.msg}` }));
    //   }
    // },
    *visitorRegister({ register }, { put, call }) {
      const result = yield call(appService.auditRegister, {
        register,
      });
      if (result.code === 0) {
        yield put({ type: 'getVisitList' });
        history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_CONFIRM}`);
      } else {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
      }
    },
    *isVirsicalBrowser(_, { call }) {
      const obj = sessionStorage.getItem('isVirsicalBrowser');
      if (obj === null) {
        // 获取
        const isVirsical = yield call(virsicalService.getVirsicalBrowser);
        if (isVirsical) {
          sessionStorage.setItem('isVirsicalBrowser', true);
        } else {
          sessionStorage.setItem('isVirsicalBrowser', false);
        }
      }
    },

    // 判断当前环境是否为微信小程序环境
    isMiniProgram() {
      const obj = sessionStorage.getItem('isMiniProgram');
      if (!obj) {
        // 获取
        new Promise((resolve) => {
          wx.miniProgram.getEnv((res) => {
            resolve(res.miniprogram);
          });
        }).then((data) => {
          if (data) {
            sessionStorage.setItem('isMiniProgram', true);
          } else {
            sessionStorage.setItem('isMiniProgram', false);
          }
        });
      }
    },
    *getAccessToken(_, { call, put }) {
      const data = yield call(virsicalService.getAccessToken);
      if (data.access_token) {
        sessionStorage.setItem('auth', JSON.stringify(data));
        sessionStorage.setItem('access_token', `${data.token_type} ${data.access_token}`);
        yield put({
          type: 'userLogin',
        });
      }
    },
    *userLogin(_, { call, put }) {
      const { data } = yield call(virsicalService.userLogin, {});
      if (data && Object.prototype.hasOwnProperty.call(data, 'token')) {
        sessionStorage.setItem('user', JSON.stringify(data));
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('type', data.type);
        history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_RESERVE}`);
        yield put({ type: 'rows', rows: 10 });
        yield put({ type: 'setReservationList', reservationList: [] });
        yield put({ type: 'getReservationList', rows: 10 });

        sessionStorage.setItem('productFunction', data.productFunction);
        yield put({
          type: 'save',
          payload: {
            productFunction: data.productFunction,
          },
        });
      } else {
        Toast.fail(formatMessage({ id: `${data.msg}` }));
      }
    },
    *testMap(udnefined, { call, put }) {
      const result = yield call(appService.hint, { keywords: 111 });
      if (result && result.info === 'OK') {
        yield put({ type: 'save', payload: { showElseAddress: true } });
      } else {
        yield put({ type: 'save', payload: { showElseAddress: false } });
      }
    },
    *batchJudge(
      { payload, isSubset, visitorSelfFlag, runBatchSaveAndUpdateHistoryVisitor },
      { call, put, select },
    ) {
      const { currentUser: { userId = null } = {} } = yield select((state) => state.user);
      const { visitorTemp, visitor } = yield select((state) => state.app);
      const result = yield call(AppService.batchJudge, { payload });
      if (result.code) {
        Toast.fail(formatMessage({ id: result.msg }));
        visitorTemp.splice(result.data[0] - 1, 1);
        yield put({ type: 'save', payload: { visitorTemp } });
      } else {
        if (isSubset || visitorSelfFlag === 3) {
          visitor.visitors = visitorTemp;
          yield put({
            type: 'save',
            payload: {
              visitor,
            },
          });
          yield put({ type: 'modifyReservation' });
        } else {
          if (runBatchSaveAndUpdateHistoryVisitor) {
            runBatchSaveAndUpdateHistoryVisitor({
              batchVisitors: visitorTemp?.map((item) => {
                return { ...item, employeeId: userId };
              }),
            }).then((res) => {
              const newVisitorTemp = visitorTemp.map((item, idx) => {
                return {
                  ...item,
                  visitorId: res?.[idx],
                };
              });
              if (Array.isArray(res) && res?.length) {
                getDvaApp()._store.dispatch({
                  type: 'app/save',
                  payload: {
                    visitorTemp: newVisitorTemp,
                    visitor: {
                      ...visitor,
                      visitors: newVisitorTemp,
                    },
                  },
                });
              }
            });
          }
          history.go(-1);
        }
      }
    },
    *isBlackList({ payload }, { call, put }) {
      const data = yield call(checkInService.isBlackList, { payload });
      if (!data.result && data.data === '1') {
        Toast.fail(formatMessage({ id: `306025` }));
        yield put({ type: 'save', payload: { blackDisable: true } });
      } else {
        yield put({ type: 'save', payload: { blackDisable: false } });
      }
    },
    *faceUpload({ payload }, { call, put }) {
      window.console.log(payload);
      const data = yield call(AppService.faceUpload, { payload });
      window.console.log(data);
      if (data.code) {
        Toast.hide();
        Toast.fail(formatMessage({ id: data.msg }));
        // yield put({ type: 'save', payload: { blackDisable: true } });
      } else {
        Toast.hide();
        Toast.success(formatMessage({ id: 'app_invitation_img.upload.success' }));
        yield put({ type: 'save', payload: { faceData: data.data } });
        yield put({
          type: 'updateFaceDataIdByPreId',
          payload: { faceDataId: data.data.faceDataId, preId: payload?.preId },
        });
      }
    },
    *updateFaceDataIdByPreId({ payload }, { call }) {
      const data = yield call(AppService.updateFaceDataIdByPreId, {
        payload,
      });
      window.console.log(data);
      if (data.code) {
        Toast.fail(formatMessage({ id: data.msg }));
        // yield put({ type: 'save', payload: { blackDisable: true } });
      }
    },
    *getByTenantIdAndTelephone({ payload }, { call, put }) {
      window.console.log(payload);
      const data = yield call(AppService.getByTenantIdAndTelephone, { payload });
      window.console.log(data);
      if (data.code) {
        Toast.fail(formatMessage({ id: data.msg }));
        // yield put({ type: 'save', payload: { blackDisable: true } });
      } else {
        // Toast.success(formatMessage({ id: 'app_invitation_img.upload.success' }));
        yield put({ type: 'save', payload: { faceData: { faceUrl: data.data } } });
      }
    },
    *getAuditCountByUserId(udnefined, { call, put, select }) {
      // const { userId } = JSON.parse(sessionStorage.getItem('user')) || { userId: 0 };
      let hasCurrentUser = {};
      if (!hasCurrentUser.userId) {
        const { currentUser } = yield select((state) => state.user);
        hasCurrentUser = currentUser;
      }
      if (!hasCurrentUser || !hasCurrentUser.userId) {
        // Toast.fail(formatMessage({ id: 'app.currentUser.error' }), 15);
        return;
      }
      const { userId } = hasCurrentUser || { userId: 0 };
      const data = yield call(AppService.getAuditCountByUserId, { userId });
      // window.console.log(data);
      if (data.code) {
        Toast.fail(formatMessage({ id: data.msg }));
        // yield put({ type: 'save', payload: { blackDisable: true } });
      } else {
        // Toast.success(formatMessage({ id: 'app_invitation_img.upload.success' }));
        yield put({ type: 'save', payload: { notCfmCount: data.data } });
      }
    },
    *getInfoByBatchId({ payload }, { call, put, select }) {
      const { detail } = yield select((state) => state.app);
      const result = yield call(AppService.getInfoByBatchId, {
        batchAddGroupId: payload.batchAddGroupId,
      });
      if (result.code) {
        Toast.fail(formatMessage({ id: result.msg }));
      } else {
        yield put({
          type: 'save',
          payload: {
            detail: {
              ...detail,
              meetingRoomDetail: result.data,
              visitorStatus:
                detail.visitorStatus === '8' ? result.data.visitorStatus : detail.visitorStatus,
              children:
                detail?.children?.map((item) => {
                  return item?.visitorStatus === '8'
                    ? {
                        ...item,
                        visitorStatus: result.data?.visitorStatus,
                      }
                    : item;
                }) || detail?.children,
            },
          },
        });
      }
    },
    *getCustomForm({ payload }, { call, put, select }) {
      const { currentUser } = yield select((state) => state.user);
      const { visitorTemp } = yield select((state) => state.app);

      const { tenantId } = currentUser;
      const subObj = { isTemp: 0, ...payload };
      if (payload.locationId && payload.locationId.length >= 5) {
        subObj.locationId = -1;
      }
      window.console.log(subObj, 'locationId');
      const result = yield call(AppService.getCustomForm, {
        payload: { tenantId, ...subObj },
      });
      if (result.code) {
        Toast.fail(formatMessage({ id: result.msg }));
      } else {
        yield put({
          type: 'save',
          payload: {
            customFormList: result.data,
          },
        });
        const customForm = customListToInfo(result.data);
        const newTemp = [];
        visitorTemp.map((item) => {
          return newTemp.push({ ...customForm, ...item });
        });
        yield put({ type: 'save', payload: { visitorTemp: newTemp } });
        return result.data || [];
      }
    },
    *findDetailByTenantIdAndPreId({ payload }, { call, put, select }) {
      const { currentUser } = yield select((state) => state.user);

      const { tenantId } = currentUser;
      const result = yield call(AppService.findDetailByTenantIdAndPreId, {
        payload: { tenantId, ...payload },
      });
      if (result.code) {
        Toast.fail(formatMessage({ id: result.msg }));
      } else {
        yield put({ type: 'save', payload: { customDetail: result.data } });
      }
    },
    *getSwitchConfig({ companyId, isWhitePage = false }, { call, put, select }) {
      const { currentUser } = yield select((state) => state.user);
      const user = JSON.parse(sessionStorage.getItem('user')) || {};
      const { tenantId } = currentUser;
      const result = yield call(AppService.getSwitchConfig, {
        companyId: companyId || tenantId || user.tenantId,
      });
      if (result.code === 0) {
        yield put({
          type: 'save',
          payload: {
            switchConfig: result.data,
            carNumberSwitch: result.data.carNumberSwitch,
            epidemicPreventionSwitch: result.data.epidemicPreventionSwitch,
            acrossDayNum: result.data.acrossDayNum,
            visitDayNum: result.data.visitDayNum,
            idCardConfig: {
              idCardSwitch: result.data.idCardSwitch,
              idCardRule: result.data.idCardRule,
              idCardValidate: result.data.idCardValidate,
              idCardFillRule: result.data.idCardFillRule,
              idCardEmployeeRule: result.data.idCardEmployeeRule,
            },
            epidemicPreventionSwitchInvitation: result.data.epidemicPreventionSwitch,
            workdayCheckSwitch: result.data.workdayCheckSwitch,
            resendSwitch: result.data.resendSwitch,
            integratedMeetingSwitch: result.data.integratedMeetingSwitch,
            visitorFillSwitch: result.data.visitorFillSwitch,
            isDisplayFillButton: result.data.isDisplayFillButton,
            lockerIntegrationSwitch: result.data.lockerIntegrationSwitch,
            lockerIntegrationNum: result.data.lockerIntegrationNum,
            phoneEmailValidationRule: result.data.phoneEmailValidationRule,
          },
        });
        if (!isWhitePage) {
          yield put({ type: 'getMeetingConfig' });
        }
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *getMaxRole(_, { call, put }) {
      const result = yield call(AppService.getMaxRole);
      if (result.code === 0) {
        yield put({
          type: 'save',
          payload: {
            sourceSwitch: Number(result.data) >= 2,
          },
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *getByBatchGroupId({ batchGroupId, lang }, { call, put }) {
      const result = yield call(AppService.getByBatchGroupId, { batchGroupId, lang });
      if (result.code === 0) {
        yield put({
          type: 'save',
          payload: {
            groupInfo: result.data,
          },
        });
        const {
          commonInfo: { companyId = null },
        } = result.data || {};
        if (companyId) yield put({ type: 'getSwitchConfig', companyId, isWhitePage: true });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *getHealth({ companyId, locationManageId }, { call, put }) {
      const result = yield call(AppService.getHealth, { companyId, locationManageId });
      if (result.code) {
        Toast.fail(formatMessage({ id: result.msg }));
      } else {
        yield put({
          type: 'healthInfo',
          healthInfo: Array.isArray(result.data) && result.data.length ? result.data[0] : {},
        });
      }
    },
    *withoutNoticeOption({ payload }, { call, put }) {
      const { option } = payload;
      const result = yield call(AppService.withoutNoticeOption, { payload });
      if (!result.code) {
        yield put({
          type: 'save',
          payload: {
            option,
          },
        });
        sessionStorage.setItem('option', option);
        Toast.success(formatMessage({ id: result.data }));
      }
      return result;
    },
    *getMeetingInfoByVisitId({ meetingId }, { call, put }) {
      const result = yield call(AppService.getMeetingInfoByVisitId, { meetingId });
      if (result.code) {
        Toast.fail(formatMessage({ id: result.msg }));
      } else {
        yield put({
          type: 'save',
          payload: {
            meetingInfo: result.data,
          },
        });
      }
    },
    *findDetailByBatchAddGroupId({ batchAddGroupId }, { call, put }) {
      const result = yield call(AppService.findDetailByBatchAddGroupId, { batchAddGroupId });
      if (result.code) {
        Toast.fail(formatMessage({ id: result.msg }));
      } else {
        yield put({
          type: 'save',
          payload: {
            parentCustomFormDetail: result.data,
          },
        });
      }
    },
    *getInvitationCode({ payload }, { call, put }) {
      const result = yield call(AppService.getInvitationCode, {
        payload,
      });
      if (result.code) {
        Toast.fail(result.msg);
      } else {
        yield put({
          type: 'save',
          payload: {
            prefixInvitationCode: result.data.qrcode,
            invitationCode: result.data.invitationCode,
            codeInvalid: false,
            yiLianQrCode: result.data.yiLianQrCode,
          },
        });
      }
    },
    // 身份证后台校验
    *checkIdCard({ idCard, setIdCardErr, resolve, reject }, { call }) {
      const result = yield call(checkInService.checkIdCard, { idCard });
      if (result.code) {
        Toast.fail(formatMessage({ id: result.msg }));
      } else {
        if (!result.data) {
          Toast.fail(formatMessage({ id: 'reserve.visit.id.card.error.validate' }));
        }
        if (setIdCardErr) {
          setIdCardErr(!result.data);
        }
        if (reject) {
          result.data ? resolve() : reject();
        } else {
          resolve(result.data);
        }
      }
    },
    *getSettings({ companyId }, { call, put }) {
      const result = yield call(AppService.getSettings, {
        tenantId: companyId,
      });
      if (result.code) {
        Toast.fail(formatMessage({ id: result.msg }));
      } else {
        let noteConfigTemp = result.data.templateList.filter(
          (item) => item.templateKey === result.data.chooseTemplate,
        )[0];
        if (!noteConfigTemp?.textColorConfig) {
          noteConfigTemp = {
            ...noteConfigTemp,
            textColorConfig: defaultTemplateList?.find(
              (item) => item?.templateKey === result.data.chooseTemplate,
            )?.textColorConfig,
          };
        }
        yield put({
          type: 'save',
          payload: {
            noteConfig: noteConfigTemp,
          },
        });
      }
    },
    *deleteReservation({ payload }, { call, put }) {
      const data = yield call(appService.deleteReservation, payload);
      if (data.code) {
        Toast.fail(formatMessage({ id: `${data.msg}` }));
        return;
      }
      Toast.success(formatMessage({ id: `${data.data}` }));
      yield put({ type: 'getReservationList' });
      history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_RESERVE}`);
    },
    *getPreRedirectUrl({ icl }, { call, put }) {
      const result = yield call(appService.getPreRedirectUrl, { icl });
      if (result.code) {
        if (result.msg === '301003') {
          yield put({
            type: 'save',
            payload: {
              errorCode: result.msg,
            },
          });
        } else {
          Toast.fail(formatMessage({ id: `${result.msg}` }));
        }
        return;
      } else {
        const {
          lang = 'zh-CN',
          tenantId: companyId,
          meetingId,
          preId,
          locationManageId,
          option = 3,
          fullNumber,
          logo = '',
          companyName = '',
          sqxSwitch = null,
        } = result.data;
        setLocale(lang);
        yield put({
          type: 'save',
          payload: {
            option: sessionStorage.getItem('option') || option,
            invitationCodeData: result.data,
            readProtocolData: {
              isReadPrivacyPolicy: result.data.isReadPrivacyPolicy,
              isReadNda: result.data.isReadNda,
              isSignNda: result.data.isSignNda,
              ndaSignBase64: result.data.signUrl,
              ndaContent: result?.data?.ndaContent,
            },
          },
        });
        if (companyId) {
          yield put({ type: 'getSwitchConfig', companyId, isWhitePage: true });
          yield put({ type: 'getSettings', companyId });
          yield put({ type: 'getAreaCode', companyId });
          if (locationManageId) {
            yield put({ type: 'getHealth', companyId, locationManageId });
          }
        }
        if (meetingId) {
          yield put({ type: 'getMeetingInfoByVisitId', meetingId });
        }
        yield put({
          type: 'getByTenantIdAndTelephone',
          payload: {
            telephone: fullNumber,
            tenantId: companyId,
            preId,
          },
        });
        // yield put({ type: 'getMiniScheme', companyId });
        if (wechatService.getWxBrowser() && companyId) {
          yield put({ type: 'getWeChatTicket', logo, companyName, companyId });
        }
        yield put({
          type: 'getInvitationCode',
          payload: { preId, category: String(sqxSwitch) === '1' ? 1 : 0 },
        });
        yield put({
          type: 'getNdaData',
          payload: { companyId, locationManageId },
        });
      }
    },
    *getHolidayByAreaId({ areaId }, { call, put }) {
      if (!areaId) {
        yield put({ type: 'save', payload: { holidayConfig: null } });
        return;
      }
      const result = yield call(appService.getHolidayByAreaId, areaId);
      if (result.code) {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
        return;
      }
      yield put({ type: 'save', payload: { holidayConfig: result?.data || null } });
    },
    *getMeetingConfig(_, { call, put, select }) {
      const { currentUser } = yield select((state) => state.user);
      const { integratedMeetingSwitch } = yield select((state) => state.app);
      if (!integratedMeetingSwitch || isFromCloudAndHasMeeting()) {
        return;
      }
      const user = JSON.parse(sessionStorage.getItem('user')) || {};
      const result = yield call(AppService.getMeetingConfig, {
        userId: currentUser?.userId || user?.userId || 0,
        tenantId: currentUser?.tenantId || user?.tenantId,
      });
      if (result.code) {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
        return;
      }
      yield put({
        type: 'save',
        payload: {
          meetingConfig: {
            ...result.data,
            oneDayMeetingSwitch: result.data?.oneDayMeetingAllowReserveOutMeeting ? true : false, // 会议单日相关配置是否打开
          },
        },
      });
    },
    // 获取微信jsSdkTicket
    *getWeChatTicket({ logo, companyName, companyId }, { call, put }) {
      const result = yield call(applyService.getJssdkTicket, {
        wechatType: 1,
        domainId: companyId,
      });
      window.console.log(result);
      if (Number(result.code) === 0) {
        yield call(wechatService.init, result.data, [
          'updateAppMessageShareData', // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
          'onMenuShareAppMessage', // 获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
          'updateTimelineShareData', // 自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容
          'onMenuShareTimeline', // 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
        ]);
        setTimeout(() => {}, 500);
        yield put({
          type: 'setShareWithFriend',
          shareLink: result?.data?.url || '',
          logo,
          companyName,
        });
      }
    },
    *setShareWithFriend({ shareLink, logo, companyName }, { call }) {
      const payload = {
        title: formatMessage({ id: 'visit.invitation.code.vx.share.title' }, { companyName }), // 分享标题
        desc: '', // 分享描述
        link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: logo ? `${shareLink.split('/vsk')[0]}${logo}` : '', // 分享图标
      };
      yield call(wechatService.shareWithFriend, payload);
    },
    // 获取小程序URL Scheme
    *getMiniScheme({ companyId }, { call, put }) {
      const result = yield call(AppService.getMiniScheme, {
        companyId,
      });
      if (result?.data?.openlink) {
        yield put({
          type: 'miniSchemeUrl',
          miniSchemeUrl: result.data.openlink,
        });
        window.location.href = result.data.openlink;
      }
    },

    // 获取会议室区域选择
    *getMeetingArea(_, { put, call }) {
      const { data, code, msg } = yield call(AppService.getAllZones);
      if (code === 0) {
        yield put({
          type: 'save',
          payload: {
            spaceTree: data,
            zonePickerData: organizeZonePickerData(data),
          },
        });
      } else {
        Toast.error(msg ? formatMessage({ id: msg }) : '');
      }
      return data;
    },
    // 保存邀请函页面用户阅读隐私、保密、签名操作
    *saveVisitorAction({ payload }, { put, call }) {
      const { code, msg } = yield call(appService.saveVisitorAction, { params: payload });
      if (code === 0) {
        yield put({
          type: 'getPreRedirectUrl',
          icl: getUrlParam('s'),
        });
      } else {
        Toast.fail(formatMessage({ id: `${msg}` }));
        return;
      }
    },
    // 获取保密协议
    *getNdaData({ payload }, { put, call }) {
      const { data, code, msg } = yield call(appService.getNdaData, payload);
      if (code === 0) {
        yield put({
          type: 'save',
          payload: {
            ndaData: data,
          },
        });
      } else {
        Toast.fail(formatMessage({ id: `${msg}` }));
        return;
      }
    },
  },
  subscriptions: {
    set({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        if (pathname === '/visit/statistical-analysis') {
          dispatch({
            type: 'getStatisticalAnalysis',
            payload: { type: 4 },
          });
        }
        let key = pathname.substring(pathname.lastIndexOf('/'));
        key = key === APP_HOME || key === '/' ? '/reserve' : key;
        dispatch({ type: 'setSelectKey', selectKey: key });
        const user = JSON.parse(sessionStorage.getItem('user'));
        // if (pathname.endsWith('/') && !user) {
        //   if (getUrlParam('token') && getUrlParam('tokenType')) {
        //     const token = getUrlParam('token');
        //     sessionStorage.setItem('access_token', `${token} `);
        //   } else {
        //     dispatch({
        //       type: 'getAccessToken',
        //     });
        //   }
        // }
        if (pathname.indexOf('visit') > -1) {
          if (user && Object.prototype.hasOwnProperty.call(user, 'companyId')) {
            dispatch({ type: 'setUser', user });
            // dispatch({ type: 'getArea', from: 'aaaa' });
            dispatch({ type: 'getAreaCode' });
            dispatch({ type: 'getVisitorType' });
          }
          if (
            sessionStorage.getItem('from') === 'cloud' &&
            !sessionStorage.getItem('omsUserProduct')
          ) {
            dispatch({ type: 'login/getUserProductCodes' });
          }
        }
        if (
          pathname.endsWith('virsical') &&
          user &&
          Object.prototype.hasOwnProperty.call(user, 'companyId')
        ) {
          dispatch({ type: 'getReservationList' });
          history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_RESERVE}`);
        }
        if (pathname.endsWith('reserve')) {
          dispatch({ type: 'getReservationList' });
          dispatch({ type: 'getArea' });
          dispatch({ type: 'getMeetingArea' });
          dispatch({ type: 'getAreaCode' });
          dispatch({
            type: 'save',
            payload: { modifyReservation: false, customDetail: [] },
          });
          dispatch({ type: 'getAuditCountByUserId' });
          dispatch({ type: 'getMaxRole' });
        }
        if (pathname.endsWith('visit')) {
          dispatch({ type: 'getVisitList' });
          dispatch({
            type: 'save',
            payload: { modifyReservation: false, customDetail: [] },
          });
          dispatch({ type: 'getArea' });
          dispatch({ type: 'getMeetingArea' });
          dispatch({ type: 'getAreaCode' });
          dispatch({ type: 'getAuditCountByUserId' });
          dispatch({ type: 'getMaxRole' });
        }
        if (pathname.endsWith('confirm')) {
          dispatch({ type: 'getConfirmData' });
          dispatch({ type: 'save', payload: { customDetail: [] } });
          dispatch({ type: 'getArea' });
          dispatch({ type: 'getMeetingArea' });
          dispatch({ type: 'getAreaCode' });
          dispatch({ type: 'getAuditCountByUserId' });
          dispatch({ type: 'getVisitorType' });
          dispatch({
            type: 'setVisitor',
            visitor: {},
          });
        }
        if (pathname.endsWith('visit-homepage')) {
          dispatch({ type: 'user/fetchCurrentUserInfo', payload: {} }).then(() => {
            dispatch({ type: 'getAuditCountByUserId' });
          });
        }
        /* 拉去判断是不是威思客浏览器 */
        dispatch({ type: 'isVirsicalBrowser' });
        /* 判断是否为微信小程序环境 */
        // dispatch({ type: 'isMiniProgram' });
        if (pathname.endsWith('invitation-code') && getUrlParam('s')) {
          dispatch({
            type: 'getPreRedirectUrl',
            icl: getUrlParam('s'),
          });
        }
        if (
          !pathname.endsWith('confirm-detail') &&
          !pathname.endsWith('visit-epidemic') &&
          !pathname.endsWith('area') &&
          !pathname.endsWith('visit-accompany') &&
          !pathname.endsWith('map') &&
          !pathname.endsWith('conference-new') &&
          !pathname.endsWith('visit-detail')
        ) {
          dispatch({ type: 'save', payload: { isConfirm: false } });
        }
        if (pathname.endsWith('visit-detail') || pathname.endsWith('confirm-detail')) {
          dispatch({
            type: 'save',
            payload: {
              isVisibleInfo: true,
              showMeetingRoomTip: false,
            },
          });
          dispatch({ type: 'getSwitchConfig' });
        }
        if (pathname.endsWith('/batch-invitation')) {
          const batchGroupId = getUrlParam('s') || 0;
          const lang = getUrlParam('lang') || 'zh-CN';
          setLocale(lang);
          dispatch({
            type: 'getByBatchGroupId',
            batchGroupId,
            lang,
          });
        }
        if (pathname.endsWith('/conference-new')) {
          dispatch({ type: 'getMeetingArea' });
        }
      });
    },
  },
};
