export default {
  'sws.myBooking.station': '工位',
  'sws.myBooking.station.colon': '工位：',
  'sws.myBooking.space.colon': '區域：',
  'sws.myBooking.check.in': '簽到',
  'sws.myBooking.info': '提示',
  'sws.myBooking.station.chose.cancel': '您確定要取消已經選擇的工位?',
  'sws.myBooking.station.using': '您正在使用的工位：',
  'sws.myBooking.station.booking': '您預訂的工位：',
  'sws.station.info': '工位信息',
  'sws.cancel.station': '取消預訂',
  'sws.check.station': '檢視工位',
  'sws.myBooking.station.using.or.reserve': '您沒有正在使用或預訂的工位',
  'sws.myBooking.cancel.reserve.station': '您確定要取消已經預訂的工位?',
  'sws.myBooking.logout.station.using': '您確定要提前註銷正在使用的工位?',
  'sws.myBooking.space': '區域',
  'sws.myBooking.timeZone': '時區',
  'sws.myBooking.booking.time': '預訂時間',
  'sws.myBooking.booking.cancel': '取消預訂',
  'sws.myBooking.booking.time.colon': '預訂時間：',
  'sws.myBooking.used.time.colon': '使用時間：',
  'sws.myBooking.reserveStatus': '狀態',
  'sws.myBooking.canceled': '已取消',
  'sws.myBooking.used': '已使用',
  'sws.myBooking.using': '使用中',
  'sws.myBooking.operation': '操作',
  'sws.myBooking.reserve.again': '再次預訂',
  'sws.myBooking.delay': '延長工位',
  'sws.myBooking.logout': '註銷工位',
  'sws.myBooking.station.num': '共{num}個工位',
  'sws.history.not.booking': '您已經有預訂的工位,不支援預訂兩個或以上的座位',
  'sws.history.no.auth': '此工位沒有許可權,請選擇其他工位',
  'sws.history.reBook': '再次預訂',
  'sws.history.not.booked': '您還沒有預訂過工位',
  'sws.reserve.delay.success': '延長成功',
  'sws.chose.time.delay': '選擇延長時間',
  'sws.can.not.delay': '無法延長',
  'sws.can.not.reverse': '很抱歉,您冇有預訂當前工位的權限',
  'sws.no.reverse': '您當前冇有預訂的工位哦～',
  'sws.no.reverse.history': '您當前冇有預訂記錄哦～',
  'sws.myBooking.reverse': '預訂工位',
  'sws.myBooking.common': '普通預訂',
  'sws.myBooking.normal': '普通',
  'sws.myBooking.special': '特殊',
  'sws.myBooking.category': '类型：',
  'sws.myBooking.not.fixed': '固定工位不能再次預訂了',
  'sws.myBooking.total': '共',
  'sws.myBooking.set': '個',
  'sws.myBooking.view.detail': '查看詳情',
  401115: '特殊預訂無法延時',
  401139: '預訂記錄不存在',
  'sws.myBooking.confirm.tip': '提示',
  'sws.myBooking.confirm.ok': '確認',
  'sws.myBooking.confirm.no': '取消',
};
