/**
 * Created by XuQiang on 2017/3/14.
 */

export default {
  // 公共
  'common.ok': 'Confirm',
  'common.cancel': 'Cancel',
  'upload.fail': 'Upload failed, please try again later',
  'upload.error': 'Server exception. Please try again later',
  'upload.onlyExcel': 'This feature supports only Excel format files',
  // 首页
  'index.visitor.reserve': 'Visitor Management',
  'index.user.login': 'User Login',
  'index.username': 'Username',
  'index.username.prompt': 'Please input username',
  'index.password.prompt': 'Please input password',
  'index.password': 'Password',
  'index.submit': 'Login',
  'index.exit': 'Exit',
  'index.exit.tip': 'Confirm to exit the system？',
  // 菜单
  'layout.menu.invites': 'Invites',
  'layout.menu.visitors': 'Visitors',
  'layout.menu.registration': 'Visitor Registration',
  'layout.menu.settings': 'Settings',
  'layout.menu.phoneBook': 'The phone book',
  'layout.menu.location': 'Location Management',
  'layout.menu.setting': 'Basic Setting',
  'layout.menu.type': 'Visitor Type',
  'layout.menu.NDA': 'NDA',
  'layout.menu.notify': 'Notification',
  'layout.menu.pcSetting': 'pcSetting',
  'layout.menu.iPadSetting': 'iPadSetting',
  'layout.menu.machineSetting': 'machineSetting',
  'layout.menu.devices': 'Devices Login Log',
  'layout.menu.admin': 'Admin Roles',
  'layout.menu.report': 'Report',
  'layout.menu.custom': 'Custom',
  'layout.menu.report.invite': 'Invite Report',
  'layout.menu.report.visitor': 'Visitor Report',
  'layout.menu.report.area': 'Location statistics',
  'layout.menu.meeting': 'Meeting Activity',
  'layout.menu.meeting.edit': 'Edit',
  'layout.menu.meeting.create': 'Create',
  'layout.menu.meeting.detail': 'Detail',
  'layout.menu.meeting.detail.area': 'Area',
  'layout.menu.group.detail': 'Detail',
  'layout.menu.group': 'Group Invitation',
  'layout.menu.blacklist': 'Blacklist',
  'layout.top.notifications': 'Notifications',
  'layout.top.SMS': 'SMS',
  'layout.top.email': 'Email',
  'layout.top.SMS&email': 'SMS&Email',
  'layout.top.system': 'System',
  // 地图
  'map.point': 'Location map：',
  'map.input.tip': 'Please enter the address (support points on the map)',
  'map.more': 'Detailed address：',
  'map.add.tip': 'Please add the number of the details directly',
  'map.right.place': 'Please enter a valid address',
  // 表格
  table_no_date: 'No Data',
  input_placeHloder: 'Please enter key words',
};
