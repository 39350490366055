/* eslint-disable no-console */
/**
 * Created : fangkemeng
 * Date : 2019-10-12  10:00
 * Email : fangkemeng@wafersystems.com
 */
import { formatMessage,history } from 'umi';
import { Toast } from 'antd-mobile-v2';
import moment from 'moment-timezone';
import { ROUTE_PATH } from '@/utils/constant';
import { isOffWork } from '@/pages/WorkSpace/utils/timeTools';

import * as service from './service';

export default {
  namespace: 'swsLockerBook',
  state: {
    loading: false,
    showAlertModal: false,
    lockerInfo: {},
    showReservationResult: false,
    resultStatus: 0,
    startTime: '',
    endTime: '',
    date: '',
    amPm: '',
    choseResult: {
      timeSelected: '',
      lockerNum: '',
    },
    reloadMap: false,
    selectedEle: [],
    lockerBond: { lockerDtos: [], lockerGroup: {} },
    isReserved: false,
    againLocationId: undefined,
    failReason:''
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 预订确认操作
    *confirmReservation({ payload }, { call, put, select }) {
      const lockerData = yield select((state) => state.locker);
      const { timeZone } = yield select((state) => state.swsCommon);

      // 展示区域 楼宇楼层
      const nameList = lockerData.addressChoseList?.slice(-3);
      let locationNames = [];
      nameList.forEach((item) => locationNames.push(item.name));

      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(service.confirmReservation, param);
      if (result && result.code === 0) {
        Toast.hide();
        const swsLockerBook = yield select((state) => state.swsLockerBook);
        const { choseResult } = swsLockerBook;
        const { lockerNum } = payload;
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            showReservationResult: true,
            reloadMap: true,
            date: '',
            amPm: '',
            startTime: '',
            endTime: '',
            isReserved: false,
            showAlertModal: false,
            choseResult: {
              ...choseResult,
              lockerNum,
            },
          },
        });
        const { locationSelected } = yield select((state) => state.locker);
        history.push({
          pathname: `${ROUTE_PATH}/locker/common/components/bookingResult`,
          state: {
            choseResult: {
              ...choseResult,
              lockerNum,
            },
            locationNames,
            timeZone,
            // locationSelected,
          },
        });
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            showReservationResult: true,
            reloadMap: true,
          },
        });
      }
    },
    // 在位置树获取后尝试初始化时间
    *initTime({ payload,callback = () => {} }, { put }) {
      const { timeZone, globalTime, systemSet } = payload;
      // console.log('initTime timeZone', systemSet, timeZone, globalTime);
      const { reserveDays } = systemSet;
      let dateDay = moment().tz(timeZone).format('YYYY-MM-DD');

      if (isOffWork(globalTime, timeZone || 'Asia/Shanghai')) {
        dateDay = moment().tz(timeZone).add(1, 'day').format('YYYY-MM-DD');
      }
      const startTime = moment.tz(dateDay, timeZone);
      const endTime = moment.tz(dateDay, timeZone);
      let failReason = '';
      // 第二天没有日期，且是下班时间不支持预订
      // 未设置工作日 不支持预订
      const noTime = Number(reserveDays) === 1 && isOffWork(globalTime, timeZone);

      // console.log('initTime reserveNormalLimitDay', Number(reserveNormalLimitDay) === 1, isOffWork(globalTime, timeZone))
      const noGlobalTime = !(
        globalTime.amStartTime ||
        globalTime.amEndTime ||
        globalTime.pmStartTime ||
        globalTime.pmEndTime
      );
      // console.log('initTime noGlobalTime', Number(reserveDays) === 1,isOffWork(globalTime, timeZone))

      if (noTime || noGlobalTime) {
        if (noTime) {
          failReason = noTime && formatMessage({ id: 'sws.booking.get.off.work' });
        } else if (noGlobalTime) {
          failReason = noGlobalTime && formatMessage({ id: 'sws.booking.get.no.time' });
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            failReason,
            startTime: undefined,
            endTime: undefined,
          },
        });
        callback(undefined, undefined, failReason);
        return;
      }

      yield put({
        type: 'saveOrUpdateData',
        payload: { startTime, endTime, failReason }
      });
      callback( startTime, endTime,failReason);
    },
    // 检查储物柜是否占用
    *checkStationOccupy({ payload }, { put, select }) {
      const {
        bookingList,
        globalTime: { amEndTime },
      } = yield select((state) => state.locker);
      if (!bookingList[0]) {
        return;
      }
      const { startTime, endTime } = bookingList[0];
      const sTime = startTime.split(' ')[1];
      const eTime = endTime.split(' ')[1];
      // 用duration判断时、分、秒的大小来区分上午、下午
      let timeDay = 'all_day';
      if (moment.duration(eTime).valueOf() <= moment.duration(amEndTime).valueOf()) {
        timeDay = 'am';
      } else if (moment.duration(amEndTime).valueOf() <= moment.duration(sTime).valueOf()) {
        timeDay = 'pm';
      }
      const time = `${moment(startTime).format('YYYY-MM-DD')} ${formatMessage({ id: timeDay })}`;
      yield put({
        type: 'locker/saveOrUpdateData',
        payload: {
          stationOccupyModal: true,
          stationOccupyData: [{ id: 1, lockerNum: payload.lockerNum, time }],
        },
      });
    },
    *selectedEle({ payload, callback }, { put, select }) {
      const { lockerElementId } = payload;
      const { mapData } = yield select((state) => state.locker);
      const element = mapData.find((d) => {
        return d.mapElementId === lockerElementId;
      });
      // console.log(element,'element',mapData,'pppp',payload)
      if (element) {
        callback([element]);
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            selectedEle: [],
          },
        });
      }
    },
    // 查询楼层一组储物柜
    *lockerBondBySpaceId({ payload }, { call, put }) {
      const result = yield call(service.lockerBondBySpaceId, payload);
      if (result.code === 0) {
        const lockerBond = result.data || { lockerDtos: [], lockerGroup: {} };
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            lockerBond,
          },
        });
      }
    },
    // 清除数据
    *unMountDelData(_, { call, put, select }) {
      const {
        locker: { mapId, locationId },
        swsLockerBook: { startTime },
      } = yield select();
      if (locationId || startTime || mapId) {
        // 有值的话清空数据
        console.log('clearxxxxxxxxxxxx 有值的话清空数据');
        yield put({
          type: 'locker/saveOrUpdateData',
          payload: {
            mapId: '',
            locationId: '',
            locationSelected: '',
            addressChildrenList: [],
            addressChoseList: [],
          },
        });
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            startTime: '',
            endTime: '',
          },
        });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        // if (pathname && pathname === '/locker/lockerBook') {
        //   // TODO
        //   // dispatch({
        //   //   type: 'locker/saveOrUpdateData',
        //   //   payload: {
        //   //     mapId: '',
        //   //     locationId: '',
        //   //   },
        //   // });
        //   // 监听路由变化，并发送类别查询请求
        //   dispatch({ type: 'locker/getUserMap' }).then(() => {
        //     dispatch({ type: 'locker/getSpaceTreeAndMapId' });
        //   });
        //   // 通过域信息获取园区时间信息
        //   dispatch({ type: 'locker/getHoliday' });
        //   dispatch({ type: 'locker/getSystemSet' });
        // } else if (
        //   pathname &&
        //   pathname !== '/locker/lockerBook' &&
        //   pathname !== '/locker/lockerBook/lockSelectAreaDate'
        // ) {
        //   dispatch({
        //     type: 'unMountDelData',
        //   });
        // }
      });
    },
  },
};
