export default {
  main_meeting_zoom_poll_question_title: 'Question Name',
  main_meeting_zoom_poll_question_title_pls: 'Please enter a name',
  main_meeting_zoom_poll_option: 'Optional',
  main_meeting_zoom_poll_option_add: 'Add optional',
  main_meeting_zoom_poll_option_pls: 'Please enter optional',
  main_meeting_zoom_poll_questionName_repeat: 'The questions in the polling cannot be repeated',
  main_meeting_zoom_poll_option_repeat: 'The answer cannot be repeated',
  main_meeting_zoom_poll_option_min: 'There can be no less than 2 options for the question',
  main_meeting_zoom_poll_option_max: 'There can be no more than 10 options for the question',
  main_meeting_zoom_poll_switch: 'Switch to multiple choice',
  main_meeting_zoom_poll_submit: 'Submit',
};
