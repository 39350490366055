export default {
  'meeting.detail.name': '会议主题',
  'meeting.detail.time': '会议时间',
  'meeting.detail.room': '会议地点',
  'meeting.detail.holder': '会议主持人',
  'meeting.detail.member': '参会人',
  'meeting.detail.external': '外部参会人',
  'meeting.detail.memberCnt': '共{num}人',
  'meeting.detail.method': '提醒方式',
  'meeting.detail.remark': '备注',
  'meeting.detail.welcome': '欢迎词',
  'meeting.detail.audit': '审核状态',
  'meeting.detail.auditRemark': '拒绝理由',
  'meeting.detail.attach': '附件',
  'meeting.detail.none': '无',
  'meeting.detail.mailText': '邮件',
  'meeting.detail.rejected': '已拒绝',
  'meeting.detail.webexTitle': 'WebEx会议',
  'meeting.detail.btn.backToList': '返回列表',
  'meeting.detail.btn.cancel': '取消会议',
  'meeting.detail.btn.edit': '编辑会议',
  'meeting.detail.btn.restart': '发起会议',
  'meeting.detail.btn.delay': '延长',
  'meeting.detail.btn.close': '结束',
  'meeting.detail.btn.control': '会控',
  'meeting.detail.btn.join': '加入',
  'meeting.detail.service': '服务类别',
  'meeting.detail.no.service': '无服务',
  'meeting.detail.no.service.placeholder': '未选择',
  'meeting.detail.sign': '签到记录',
  'meeting.detail.secret': '会议保密',
  'meeting.detail.secret_yes': '是',
  'meeting.detail.secret_no': '否',
  'meeting.detail.allUser': '全员会议',
  'meeting.detail.allUser_yes': '是',
  'meeting.detail.allUser_no': '否',
  'meeting.detail.allUser.pls': '全员',
  'meeting.detail.close.tip': '是否结束当前会议？',
  'meeting.detail.confirm.cmma': '视频会议仍在进行，请在会控页面结束视频会议',
  'meeting.detail.cancel.tip': '是否取消当前会议？',
  cancel_loop_meeting: '取消该系列的所有周期会议?',
  modify_loop_meeting: '修改该系列的所有周期会议?',
  'meeting.detail.agenda': '会议议程',
  'meeting.detail.video': '直录播',
  'meeting.detail.look': '查看',
  'meeting.detail.number': '会议号',
  'meeting.detail.attender.receipt': '参会回执',
  'meeting.detail.and': ',',
  'meeting.detail.accept': '人同意',
  'meeting.detail.refuse': '人拒绝',
  'meeting.detail.undo': '人未处理',
  'meeting.static.accept': '同意',
  'meeting.static.accept.tip': '同意的回执',
  'meeting.static.refuse': '拒绝',
  'meeting.static.refuse.tip': '拒绝的回执',
  'meeting.static.undo': '未处理',
  'meeting.static.undo.tip': '未处理的回执',
  'meeting.detail.back': '返回上一页',
  'meeting.attendee.page.page.value1': '该部门下没有任何员工！',
  'meeting.detail.webex.email.empty': 'Webex参会人邮箱不能为空！',

  'detail.meeting.meetingMode': '循环模式',
  'detail.loop.everyworkday': '每个工作日',
  'detail.loop.everyday': '每 {day} 天',
  'detail.loop.everyMonth': '每月 {day} 号',
  'detail.loop.everyMonthDay': '第',
  'detail.loop.every': '每',
  'detail.loop.mon': '周一',
  'detail.loop.thu': '周二',
  'detail.loop.wed': '周三',
  'detail.loop.thr': '周四',
  'detail.loop.fri': '周五',
  'detail.loop.sat': '周六',
  'detail.loop.sun': '周日',
  'meeting.status.invalid': '无效时间占用',

  'meeting.detail.zoomTitle': 'Zoom会议',
  'meeting.detail.zoomRoomTitle': 'Zoom Rooms会议',
  'meeting.detail.btn.start': '开启',

  'meeting.detail.form.cancel.reason': '取消理由',
  meeting_detail_zoom_meeting_id: '会议ID',
  meeting_detail_zoom_join_password: '参会密码',
  meeting_detail_zoom_join_url: '加会链接',
  meeting_detail_zoom_copy_url: '点击复制链接',
  meeting_detail_zoom_copy_success: '加会链接已被复制',
  meeting_detail_webex_meeting_id: '会议ID',
  meeting_detail_webex_join_password: '参会密码',
  meeting_detail_webex_join_url: '加会链接',
  meeting_detail_webex_copy_url: '点击复制链接',
  meeting_detail_webex_copy_success: '加会链接已被复制',
  meeting_detail_copy_success: '已复制',
  'meeting.detail.qr': '开门二维码',
  'meeting.detail.qr.info': '用此二维码对准读头开门',

  'meeting.detail.tencent.title': '腾讯会议',
  'meeting.detail.wechat.title': '腾讯会议',
  'meeting.detail.teams.title': 'Teams会议',
  'meeting.detail.flybook.title': '飞书会议',
  'meeting.detail.dingding.title': '钉钉会议',
  'meeting.detail.huichang.title': '畅云会议',
  'meeting.detail.quanshi.title': '全时会议',
  'meeting.detail.net': '请点击加入网络会议',
  'meeting.cancel.user': '取消人',
  'meeting.cancel.type0': '自动取消',
  'meeting.cancel.type1': '用户取消',
  'meeting.cancel.type2': '未签到取消',
  'meeting.cancel.type3': '未审批取消',
  'meeting.cancel.type4': 'Exchange/O356取消',
  'meeting.cancel.type5': '第三方取消',
  'meeting.cancel.type6': '访客端取消',
  'meeting.cancel.log': '取消记录',
  'meeting.Agenda.title': '议题',
  'meeting.Agenda.time': '时间',
  'meeting.detail.memberCnt.agenda': '共{num}人',
  main_tip: '提示',
  main_tip_content:
    '很抱歉!目前无法从企业微信中直接跳转，请复制列表中的teams会议链接到浏览器中参加会议。',
  'meeting.visit.outuser': '访客',

  'meeting.detail.video.password.tips': '请使用密码【{value}】入会',

  meeting_sign_status_value0: '已回复参会',
  meeting_sign_status_value1: '已回复不参会',
  meeting_sign_status_value2: '已回复待定',
  meeting_sign_status0: '参会',
  meeting_sign_status1: '不参会',
  meeting_sign_status2: '待定',
  meeting_sign_modal_title: '您是否参加会议？',
  'meeting.detail.creater.name': '预订人',
  'meeting.detail.creater.time': '预订时间',
  'meeting.detail.item.other': '其他',
  'meeting.info.item.welcome': '欢迎词',
  'meeting.info.item.remark': '备注',
  'meeting.info.item.service': '服务',
  'meeting.info.item.device': '设备',
  'meeting.info.item.agenda': '议程',
  'meeting.info.item.cycle': '循环',
  'meeting.info.title.service': '会议服务',
  'meeting.info.title.device': '移动设备',
  'meeting.info.item.welcome.tip': '暂时没有会议欢迎词',
  'meeting.info.item.remark.tip': '暂时没有备注信息',
  'meeting.info.item.service.tip': '暂时没有会议服务',
  'meeting.info.item.device.tip': '暂时没有会议设备',
  'meeting.info.item.agenda.tip': '暂时没有会议议程',
  'meeting.info.item.cycle.tip': '不循环',
  'meeting.info.item.questionnaire.tip': '暂时没有调查结果',
  'meeting.info.start.time.tip': '距会议开始还有',
  'meeting.info.sign.time.before': '会议开始前{value}分钟至',
  'meeting.info.sign.time.after': '会议开始后{value}分钟签到',
  'meeting.info.sign.time.startNow.after': '会议开始后{value}分钟内签到',
  'meeting.info.sign.time.after.before': '会议开始前{value}分钟至会议开始后{value}分钟签到',
  'meeting.info.host.modal.title': '会议主持人',
  meeting_sign_status_value_null: '未回复',

  meeting_sign_modal_title_refuse: '不参会原因',
  meeting_sign_modal_title_refuse_tips: '请输入不参会原因（选填）',
  'meeting.list.column.attend': '邀请参会人数',
  'meeting.list.column.actual.attend': '实际参会人数',
  'meeting.list.column.actual.attend.gotit': '我知道了',
  'meeting.detail.release.time': '释放时间',
  'meeting.cancel.type9': '因会议室维护被取消',
  'meeting.cancel.type10': '因会议室停用被取消',
  'meeting.detail.form.cancel.Stop': '会议室（{value}）停用',
  'meeting.detail.form.cancel.Maint': '会议室（{value}）维护',
};
