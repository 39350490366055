export default {
  'sws.cover.current.use': '当前使用人',
  'sws.cover.reason': '申请原因',
  'sws.cover.history.tip': '是否解绑以前的工位',
  'sws.cover.history.0': '是',
  'sws.cover.history.1': '否',
  'sws.cover.reply.station.info': '工位信息',
  'sws.cover.reply.station.num': '工位编号',
  'sws.cover.reply.station.man': '申请使用人',
  'sws.cover.reply.station.reason': '申请原因',
  'sws.cover.reply.station.remark': '回复',
  'sws.cover.reply.station.ok': '同意',
  'sws.cover.reply.station.no': '拒绝',
  'sws.cover.reply.submit.ok': '申请成功',
  'sws.cover.reply.reason.zw': '暂无',

  401148: '请勿重复操作',
  401860: '工位绑定用户已变更，请扫码重试',
};
