/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  bind_finish: '完成',
  bind_virsical: '威思客',
  bind_submit: '提交',
  bind_hasbind: '该微信号已经绑定账号',
  bind_inputAccount: '请输入账号',
  bind_notUserNameEmpty: '用户名不能为空!',
  bind_notPassWordEmpty: '密码不能为空!',
  bind_inputPassword: '请输入密码',
  bind_close: '关闭',
  bind_getStateFail: '获取用户绑定状态失败，请关闭重试',
  bind_bindFail: '绑定账号失败',

  106000: '系统异常',
  106504: '认证失败！',
  106505: '用户名或密码错误！',
};
