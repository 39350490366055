export default {
  'home.page.title': 'Smart Meeting',
  'home.page.subtitle': 'Direct way to efficiency',

  'home.page.btn.book': 'Booking Meeting',
  'home.page.btn.about': 'About',
  'home.page.btn.video.meeting': 'Video Meeting',
  'home.page.btn.my.meeting': 'Meeting List',
  'home.page.btn.initiate': 'Initiate Meeting',
  'home.page.btn.notwork.meeting': 'Online Meeting',
};
