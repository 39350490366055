export default {
  // 訪客專案,30開頭

  // ---

  // 邀請 10

  // 訪問 20

  // 公共 30

  // 通知 40

  // 辦公地/域 50

  // 其他(終端 門禁 訪客雲 會議等) 60

  // ---

  // 邀請

  301001: '找不到該邀請碼的邀請者',
  301002: '該邀請碼無法識別',
  301003: '該邀請碼已過期',
  301004: '該邀請碼已執行過簽到',
  301005: '該邀請碼已簽出',
  301006: '該邀請碼已取消',
  301007: '向被邀請人發送訪問邀請函成功！',
  301008: '團隊邀請不存在',
  301009: '邀請碼不能為空',
  301010: '找不到邀請碼對應的訪客預約記錄',
  301011: '邀請碼簽到成功',
  301012: '你已完成簽到',
  301013: '預約時間小於當前時間，請重新預約',
  301014: '預約時間限制{value}天以內，請重新預約',
  301015: '跨天時間限制{value}天以內，請重新預約',
  301016: '你創建的預約已經取消成功，請知悉。',
  301017: '預約拒絕通知',
  301018: '操作成功',
  301019: '邀請碼簽到失敗',

  301020: '查詢最後一次預約記錄失敗',

  301021: '生成臨時預約記錄失敗',

  301022: '查詢臨時預約記錄失敗',

  301023: '找不到邀請碼對應的訪客簽到記錄',

  301024: '找不到邀請碼對應的訪客登記記錄',

  301025: '臨時預約單ID不能為空',

  301026: '臨時預約單不存在',

  // 訪問訪客

  302001: '操作成功',

  302002: '預約訪問操作失敗',

  302003: '查詢歷史資訊失敗',

  302004: '訪問類型已存在',

  302005: '訪問公司:',

  302006: '訪問時間:',

  302007: '訪問目的:',

  302008: '訪客姓名',

  302009: '訪客公司',

  302010: '訪客電話',

  302011: '訪客郵箱',

  302012: '訪問事由',

  302013: '訪問時間',

  302014: '拜訪通知',

  302015: '訪客（外部參會人）列表不能為空',

  302016: '訪客審核失敗:找不到對應的訪客記錄！',

  302017: '訪客審核失敗:該訪客記錄已審核或無需審核！',

  302018: '訪客審核失敗:審核結果不能為空！',

  302019: '訪客審核失敗',

  302020: '查詢訪問類型失敗',

  302021: '新建訪問類型成功',

  302022: '新建訪問類型失敗',

  302023: '修改訪問類型成功',

  302024: '修改訪問類型失敗',

  302025: '刪除訪問類型成功',

  302026: '刪除訪問類型失敗',

  302027: '訪客簽出成功',

  302028: '訪客簽出失敗',

  302029: '該訪客沒有預約記錄',

  // 公共

  303001: '您好',

  303002: '訪客',

  303003: '祝您工作愉快！',

  303004: '成功',

  303005: '失敗',

  303006: '全部',

  303007: '未開始',

  303008: '未到訪',

  303009: '最近簽入',

  303010: '已簽出',

  303011: '已取消',

  303012: '待審核',

  303013: '已拒絕',

  303014: '已過期',

  303015: '已通過',

  303016: '合計:',

  303017: '人次',

  303018: '登錄失敗',

  303019: '添加成功',

  303020: '添加失敗',

  303021: '刪除成功',

  303022: '刪除失敗',

  303023: '更新成功',

  303024: '更新失敗',

  303025: '查詢的人員不在黑名單中',

  303026: '黑名單查詢失敗',

  303027: '操作成功',

  303028: '當前公司已不存在',

  303029: '查詢失敗',

  303030: '檔不能為空',

  303031: '企業域不存在',

  303032: '用戶不存在',

  303033: '修改成功',

  303034: '修改失敗',

  303035: '參數異常',

  303036: 'token失效',

  303037: '當前用戶沒有操作許可權',

  303038: '公司不存在',

  303039: '伺服器未返回任何結果',

  303040: '伺服器返回失敗結果',

  // 通知

  304001: '短訊或郵件服務異常',

  304002: '通知工位系統分配工位接失敗:調用介面失敗',

  304003: '通知展板顯示歡迎資訊成功',

  304004: '通知打印設備打印通知單失敗:生成請求資訊失敗',

  304005: '通知打印設備打印通知單失敗:',

  304006: '無有效標出提示',

  304007: '獲取WIFI密碼失敗',

  304008: '訪客證打印成功',

  304009: '訪客證打印失敗',

  304010: '查無此人',

  304011: '無效的邀請碼鏈接',

  304012: '臨時登記失敗',

  304013: '獲取訪客系統token失敗',

  304014: '小程式參數無效',

  304015: '用戶已被加入黑名單',

  304016: '獲取用戶資訊失敗',

  304017: '打印機報警',

  304018: '新建保密協議成功',

  304019: '新建保密協議失敗',

  304020: '修改保密協議成功',

  304021: '修改保密協議失敗',

  304022: '刪除保密協議成功',

  304023: '刪除保密協議失敗',

  304024: '查詢管理員列表失敗',

  304025: '創建管理員成功',

  304026: '創建管理員失敗',

  304027: '修改管理員配置失敗',

  304028: '模糊查詢企業列表失敗',

  304029: '獲取WIFI賬密失敗',

  304030: '獲取訪客系統token失敗',

  304031: '入參scope不能為空',

  304032: '入參scope有效值為[0,1]',

  304033: '查詢我的預約失敗',

  304034: '查詢已預約過的企業失敗',

  304035: '請先通過調口介面獲取用戶資料',

  304036: '用戶登錄憑證無效',

  304037: 'Company表未啟用短訊通知',

  304038: '短訊發送失敗',

  304039: '短訊發送成功',

  304040: 'Company表未啟用郵件通知',

  304041: '郵件發送失敗',

  304042: '郵件發送成功',

  304043: '採集身份證資訊成功',

  304044: '採集身份證資訊失敗',

  304045: '訪問歷史批量同步上傳成功',

  304046: '訪問歷史批量同步上傳失敗',

  304047: '檔上傳失敗',

  304048: '查詢不到訪問類型',

  304049: '發送Portal消息失敗',

  // 辦公地

  305001: '辦公區域未授權，禁止進入！',

  305002: '找不到訪客對應的辦公區域！',

  305003: '向辦公區域管理員發送消息失敗:獲取辦公區域管理員清單失敗',

  305004: '辦公區域沒有管理員，跳過管理員消息提醒',

  305005: '辦公區域沒有管理員，跳過管理員消息提醒！',

  305006: '當前用戶沒有設置辦公地',

  305007: '當前用戶未綁定辦公地，請聯繫管理員！',

  305008: '請解綁當前區域下部門',

  305009: '辦公地點不存在（用戶未綁定辦公地）',

  305010: '請解綁當前區域下員工',

  305011: '向辦公區域管理員發送消息成功！',

  305012: '向辦公區域管理員發送消息失敗:解析介面回應內容失敗！',

  305013: '向辦公區域管理員發送消息失敗',

  305014: '向辦公區域管理員發送消息失敗:調用介面失敗',

  305015: '未啟用向辦公區域管理員發送消息功能，自動跳過。',

  // 其他

  306001: '當前域套餐資訊不存在',

  306002: '當前域沒有初始化',

  306003: '當前終端數量已超過限制',

  306004: '請使用終端管理員帳號登錄',

  306005: '執行門禁掃碼相關操作失敗！',

  306006: '訪客簽到成功，自動為您開門',

  306007: '訪客認證成功，自動為您開門',

  306008: '【會議預約訪客】訪客預約取消失敗',

  306009: '[預約+會議]取消失敗',

  306010: '會議取消失敗',

  306011: '獲取會議室失敗',

  306012: '未配置保密協議',

  306013: '微信 - code換取access_token失敗',

  306014: '微信 - 獲取用戶資訊失敗',

  306015: '微信 - 刷新token失敗',

  306016: '微信小程式 - 登錄憑證校驗失敗',

  306017: 'wx -> 獲取訪問類型失敗',

  306018: 'wx -> 獲取辦公地點失敗',

  306019: 'wx -> 模糊查找員工失敗',

  306020: '【會議預約訪客】訪客預約取消成功',

  306021: '向微信介面發起請求未收到回應，請先檢查網絡',

  306022: '獲取accesstoken失敗',

  306023: '微信小程式登錄失敗',
  306024: '訪客門禁掃碼相關操作失敗！',

  306025: '該訪客已被加入黑名單',

  306026: '不在邀請時間範圍內，不能開門',

  306027: '當前區域未配置門禁和設備關聯',

  306028: '分配工位成功',

  306029: '解析接口響應內容失敗',

  306030: '未啟用通知方糖展板顯示歡迎信息功能，自動跳過。',

  306031: '通知方糖展板顯示歡迎信息失敗:查詢統計訪客數量失敗',

  306032: '未啟用通知展板顯示歡迎信息功能，自動跳過。',

  306033: '通知展板顯示歡迎信息失敗:查詢統計訪客數量失敗',

  306034: '通知展板顯示歡迎信息失敗:調用接口失敗',

  306035: '通知展板顯示歡迎信息成功！',

  306036: '通知展板顯示歡迎信息失敗:接口返回失敗結果！',

  306037: '通知展板顯示歡迎信息失敗:解析接口響應內容失敗！',

  306038: '通知打印設備打印通知單失敗:websocket 推送失敗！',

  306039: '通知打印設備打印通知單成功！',

  306040: '未檢測到通知打印設備, 跳過打印！',

  306041: '通知打印設備打印通知單失敗:獲取內部員工或訪問類型失敗',

  306042: '未啟用通知打印設備打印通知單功能，自動跳過。',

  306043: '您的剩餘短訊不夠發送邀請通知，請及時購買短訊包',
  306048: '跨天會議模塊沒有開啟',
  306049: '取消預約失敗',
  306056: '會議開始時間應在到訪時間之後',
  306058: '會議已經開始',
  306059: '您已經上傳過防疫登記信息，請重新點擊鏈接進入此頁面',
  // OCR识别
  306060: '健康碼識別結果異常，請上傳綠色健康碼',
  306061: '行程卡識別結果異常，請上傳綠色行程卡',
  306062: '健康碼已失效，請上傳{number}小時內的健康碼',
  306063: '行程卡已失效，請上傳{number}小時內的行程卡',
  306064: '請上傳正確的健康碼圖片',
  306065: '請上傳正確的行程卡圖片',
  306066: '只有未到訪、已取消、處理中狀態的記錄才能刪除',
  306067: '只有未開始狀態的記錄才能取消',
  306068: '非工作時間，操作失敗！',
  306070: '騰訊ocr配置異常，請聯繫管理員',
  306073: '會議進入簽到時間',
  306081: '手機不能重複',
  306082: '郵箱不能重複',
  306083: '手機、郵箱不能重複',
  306079: '只有未開始狀態的記錄才能補發',
  306080: '訪客數量已超出當前接待地點{timeFormat}的可邀請數量',
  306084: '跨天会议允许预定{num}天内会议',
  306087: '被訪人已離職，您無法進行簽到/簽出，請聯繫管理員。',
  306089: '跨天訪客功能未打開',
  306091: '會議集成開關未打開',
  306098: '從Outlook取消',
  3999999: '已經無法修改個人信息',

  // 菜單

  'menu.system-management.blacklist': '黑名單管理',
  'menu.system-management': '系統管理',
  'menu.system-management.office': '辦公地管理',
  'menu.system-management.setting': '前臺配置',
  'menu.system-management.visitType': '訪問類型管理',
  'menu.system-management.terminal': '終端管理',
  'menu.system-management.manage': '訪客管理',

  // 會議活動70

  307001: '導出參會區人員異常',

  307002: '數據異常,請稍後再試',

  307003: '獲取簽到用戶失敗',

  307004: '導出簽到用戶失敗',

  307005: '當前會議已經被取消',

  307006: '尚未到會議活動開始時間',

  307007: '會議活動已經結束',

  307008: '尚未到會議活動簽到時間',

  307009: '會議活動簽到已經結束',

  307010: '手機號已報名，請更換手機號並重試報名',

  307011: '短訊發送失敗,請稍後再試',

  307012: '未識別出人臉資訊,請重新上傳',

  307013: '用戶未註冊',

  307014: '參會碼為空！',

  307015: '尚未到會議簽到時間',

  307016: '會議已經結束',

  307017: '會議不在今天舉行',

  307018: '用戶簽到失敗！',

  307019: '簽到對象為空',

  307020: '簽到失敗',

  307021: '未查詢到用戶數據',

  307022: '非法token',

  307023: '成功',

  307024: '失敗',

  307025: '當前會議不存在',

  309001: '主鍵不能為空',
  309002: '主鍵參數無效',
  309003: '姓名不能為空',
  309004: '姓名長度不能超過50',
  309005: '手機號碼不能為空',
  309006: '手機號碼長度不能超過15',
  309007: '終端類型不能為空',
  309008: '終端MAC地址不能為空',
  309009: '辦公地名稱不能為空',
  309010: '辦公地名稱長度不能超過100',
  309011: '辦公地全稱不能為空',
  309012: '辦公地全稱長度不能超過100',
  309013: '地址不能為空',
  309014: '地址長度不能超過255',
  309015: '消息類型不能為空',
  309016: '消息類型不能超過11',
  309017: '消息開關標誌不能為空',
  309018: '消息開關標誌長度不能超過11',
  309019: 'Code不能為空',
  309020: '保密協議內容不能為空',
  309021: '保密協議長度不能超過10000',
  309022: '辦公地ID不能為空',
  309023: '門禁編號不能為空',
  309024: '系統簽出開關不能為空',
  309025: '系統簽出時間不能為空',
  309026: '提前通知類型不能為空',
  309027: 'WIFI開關不能為空',
  309028: '人臉開關不能為空',
  309029: '綁定數據不能為空',
  309030: '主題不能為空',
  309031: '主題長度不能超過255',
  309032: '接待人員不能為空',
  309033: '接待人員長度不能超過255',
  309034: '訪問事由不能為空',
  309035: '接待地點不能為空',
  309036: '接待地點長度不能超過255',
  309037: '到訪時間不能為空',
  309038: '人臉不能為空',
  309039: '區號不能為空',
  309040: '拜訪人不能為空',
  309041: '簽到碼不能為空',
  309042: '設備編碼不能為空',
  306107: '選擇的訪客類型不存在，請重新選擇',
  20240919: '會議已取消',
};
