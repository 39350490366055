/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';
import { Toast } from 'antd-mobile-v2';
import { getSign } from 'virsical-sign';
import { formatMessage, getDvaApp } from 'umi';
import { stringify } from 'qs';
import {
  ACCESS_TOKEN,
  SING_KEY,
  RESTFUL_PATH,
  RESTFUL_SIGNATURE,
  STATIC_RESOURCE_PREFIX,
  DEFAULT_INTERFACE,
  DEFAULT_PREFIX,
} from '@/utils/constant';
import {isTeams, sendUrlTrim, thirdAuth, apiAddPermission} from '@/utils/utils';

const path = {};
Object.keys(RESTFUL_PATH).forEach((key) => {
  path[`/${RESTFUL_PATH[key]}`] = key;
});

// const codeMessage = {
//   200: '服务器成功返回请求的数据。',
//   201: '新建或修改数据成功。',
//   202: '一个请求已经进入后台排队（异步任务）。',
//   204: '删除数据成功。',
//   400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
//   401: '用户没有权限（令牌、用户名、密码错误）。',
//   403: '用户得到授权，但是访问是被禁止的。',
//   404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
//   406: '请求的格式不可得。',
//   410: '请求的资源被永久删除，且不会再得到的。',
//   422: '当创建一个对象时，发生一个验证错误。',
//   426: 'app.login.message-invalid-credential',
//   500: '服务器发生错误，请检查服务器。',
//   502: '网关错误。',
//   503: '服务不可用，服务器暂时过载或维护。',
//   504: '网关超时。',
// };

/**
 * 异常消息显示
 * @param errorText
 */
const errorMsgShow = (errorText) => {
  Toast.fail(errorText);
};

/**
 *  解析错误返回的response
 * @param response
 * @param statusText
 * @param showMsg
 * @param callback
 */
const responseHandle = (response, statusText, showMsg = true, callback) => {
  let errorText = '';
  response
    .json()
    .then((data) => {
      try {
        if (Object.prototype.hasOwnProperty.call(data, 'data')) {
          errorText = formatMessage({ id: data.msg }, { ...data.data });
        } else {
          errorText = formatMessage({ id: data.msg });
        }
        if (callback) {
          callback(errorText);
        }
      } catch (e) {
        errorText = data.msg || statusText;
      }

      if (
        response &&
        response?.url &&
        response?.url.indexOf('/virsical-map/map/index?spaceId=') > -1
      ) {
        return;
      }
      if (showMsg) {
        errorMsgShow(errorText);
      }
    })
    .catch((err) => {
      window.console.log(`%c${err}`, 'color: red');
      Toast.fail(formatMessage({ id: '100000' }));
      throw new Error(err);
    });
};

/**
 * 异常处理程序
 */
const errorHandler = (error) => {
  const { response = {} } = error;
  const { status, url, statusText } = response;
  if (url.indexOf('/oauth/token') !== -1) {
    /* eslint-disable */
    getDvaApp()._store.dispatch({
      type: 'login/getVerificationCodeImageUrl',
      payload: {
        randomStr: Math.floor(Math.random() * 100000000000000000000), // 随机生成20位数字
      },
    });

    getDvaApp()._store.dispatch({
      type: 'login/saveOrUpdateData',
      payload: {
        showSlide: false,
      },
    });

    responseHandle(response, statusText);
    return;
  }

  if (url.indexOf('/user/send/sms/captcha') !== -1) {
    responseHandle(response, statusText);

    getDvaApp()._store.dispatch({
      type: 'login/getVerificationCodeImageUrl',
      payload: {
        randomStr: Math.floor(Math.random() * 100000000000000000000), // 随机生成20位数字
      },
    });
    return;
  }

  /* 处理错误码信息 */
  // const errorText = codeMessage[status] || statusText;
  // const responseError = new Error(errorText);

  let responseError = '';

  if (status === 401) {
    const tmp = sessionStorage.getItem('401');
    if (!tmp) {
      sessionStorage.setItem('401', 'true');
      // errorMsgShow(codeMessage[status]);
      errorMsgShow(formatMessage({ id: `service.err.${status}` }));
      setTimeout(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.includes('teamsmobile') && sessionStorage.getItem('from') === 'cloud') {
          localStorage.removeItem('teamsCloudAccess');
          sessionStorage.removeItem(ACCESS_TOKEN);
          const pId = localStorage.getItem('teamsProductCode') || 'vst';
          const { origin } = window.location;
          const url = `${origin}/${DEFAULT_PREFIX}/main/teamsAADLogin?pId=${pId}&loginStatus=logOut`;
          window.location.replace(url);
        } else if (
          userAgent.indexOf('lark') !== -1 ||
          userAgent.indexOf('micromessenger') !== -1 ||
          userAgent.indexOf('dingtalk') !== -1
        ) {
          const sso_refresh_token = sessionStorage.getItem('sso_refresh_token');
          const appId = sessionStorage.getItem('sso_menuIdAuth');
          localStorage.removeItem('authorityLark');
          sessionStorage.removeItem(ACCESS_TOKEN);
          sessionStorage.removeItem('401');
          if (sso_refresh_token) {
            getDvaApp()._store.dispatch({
              type: 'login/refreshToken',
            });
          } else if (appId) {
            thirdAuth(appId);
          } else {
            setTimeout(() => {
              Toast.fail(formatMessage({ id: 'login.feishu.err.title' }));
            }, 300);
          }
        } else {
          const search = stringify({
            redirect: window.location.href,
          });
          window.location.href = `${STATIC_RESOURCE_PREFIX}user/login?${search}`;
          sessionStorage.clear();
        }
        /* eslint-enable */
      }, 3500);
    }
    return;
  }
  if (status === 426) {
    const errorText = formatMessage({ id: `service.err.${status}` });
    responseError = new Error(errorText);

    responseError.name = response.status;
    responseError.response = response;
    throw responseError;
  }
  switch (status) {
    case 400:
    case 500:
      responseHandle(response, statusText);
      break;
    default:
      errorMsgShow(formatMessage({ id: `service.err.${status}` }));
  }
  if (url.indexOf('/forget-pwd/send-mail') !== -1) {
    /*eslint-disable*/
    getDvaApp()._store.dispatch({
      type: 'login/getVerificationCodeImageUrl',
      payload: {
        randomStr: Math.floor(Math.random() * 100000000000000000000), // 随机生成20位数字
      },
    });
  }
};

/**
 * 配置request请求时的默认参数
 */
export const requestNoAuthorization = extend({
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie,
});

export const questMethodAddToUrl = (url, option = {}) => {
  let newUrl = url;
  const newOption = option;
  if (Object.prototype.hasOwnProperty.call(option, 'method')) {
    const { method } = option;
    if (method.trim().toLowerCase() === 'put') {
      newUrl = `${url}/update`;
      newOption.method = 'POST';
    }
    if (method.trim().toLowerCase() === 'delete') {
      const index = url.lastIndexOf('/');
      newUrl = `${url.substring(0, index)}/delete${url.substring(index)}`;
      newOption.method = 'POST';
    }
    if (method.trim().toLowerCase() === 'get') {
      newOption.method = 'GET';
    }
    if (method.trim().toLowerCase() === 'post') {
      newOption.method = 'POST';
    }
  }
  return {
    newUrl,
    newOption,
  };
};

/**
 *
 * @param url origin url
 * @param option
 * @param otherContentType  如果不想系统自动设置 content-type为 application/json，需要设置为false，并且在option中传人值
 * @param signature 控制签名，在提交文件的时候，需要手动设置为false
 * @return questMethodAddToUrl
 */
export default (url, option = {}, otherContentType = false, signature = true) => {
  /***接口增加权限逻辑，try catch包裹，即便报错不影响原有逻辑---START---***/
  try {
    const apiData = apiAddPermission(url, option);
    url = apiData.path
    option = apiData.option
  } catch (e) {
    console.warn('[apiAddPermission]:error', e)
  }
  /***接口增加权限逻辑，try catch包裹，即便报错不影响原有逻辑---END---***/
  let { headers } = option;
  if (headers) {
    if (!('Content-Type' in headers) && !otherContentType) {
      headers['Content-Type'] = 'application/json';
    }
  } else {
    headers = {
      'Content-Type': 'application/json',
    };
  }
  headers['lang'] = (localStorage.getItem('umi_locale') || 'zh-CN').split('-').join('_');
  if (sessionStorage.getItem(ACCESS_TOKEN)) {
    headers.Authorization = `bearer ${sessionStorage.getItem(ACCESS_TOKEN)}`;
    headers['vsk-auth'] = `bearer ${sessionStorage.getItem(ACCESS_TOKEN)}`;
  }

  if (isTeams() && localStorage.getItem('teamsProductCode')) {
    headers.Product = localStorage.getItem('teamsProductCode');
  }

  const { newUrl, newOption } = questMethodAddToUrl(url, option);
  if (RESTFUL_SIGNATURE && signature) {
    // 开始签名
    //  取第二个 '/' 前的字符
    let restfulPath = newUrl.substring(0, newUrl.indexOf('/', 1));
    if (DEFAULT_INTERFACE && DEFAULT_INTERFACE.includes('/')) {
      restfulPath = newUrl.substring(0, newUrl.indexOf('/', newUrl.indexOf('/', 1) + 1));
    }
    let signPath = newUrl;
    // 判断url中如果包含restful前缀，需要清除，签名用
    if (Object.prototype.hasOwnProperty.call(path, restfulPath)) {
      signPath = newUrl.replace(restfulPath, '');
    }
    const tokenSign = sessionStorage.getItem(ACCESS_TOKEN);

    // 生成签名用的时间戳
    const timestamp = new Date().getTime();
    // post 请求需要签名 body or data
    if (
      newOption &&
      newOption.method &&
      newOption.method === 'POST' &&
      (newOption.body || newOption.data)
    ) {
      if (newOption.body) {
        headers['vsk-signature'] = getSign(
          signPath,
          tokenSign,
          SING_KEY,
          timestamp,
          newOption.body,
        );
      } else if (newOption.data) {
        headers['vsk-signature'] = getSign(
          signPath,
          tokenSign,
          SING_KEY,
          timestamp,
          newOption.data,
        );
      }
    } else {
      let query = null;
      // 如果是Get方法，并且通过 newOption.params 来传递参数，需要单独转成字符串拼接，签名需要
      if (
        newOption &&
        newOption.method &&
        newOption.method.toLowerCase() === 'get' &&
        newOption.params
      ) {
        query = '';
        Object.keys(newOption.params).forEach((key, index) => {
          if (index === 0) {
            query += `?${key}=${newOption.params[key]}`;
          } else {
            query += `&${key}=${newOption.params[key]}`;
          }
        });
        signPath += query;
      }
      // Get
      headers['vsk-signature'] = getSign(signPath, tokenSign, SING_KEY, timestamp);
    }
    // headers.test = 'test';
    headers['vsk-timestamp'] = timestamp;
  }

  let urlTemp = sendUrlTrim(newUrl);
  // if (
  //   newUrl.indexOf('/update/phone?phone') === -1 &&
  //   newUrl.indexOf('/dataAnalyse/timeSeries/device') === -1 &&
  //   window.location.pathname.indexOf('/scanAuthPage') === -1 &&
  //   newUrl.indexOf('/map/search') === -1
  // ) {
  //   urlTemp = sendUrlTrim(newUrl);
  // }

  return requestNoAuthorization(urlTemp, {
    ...newOption,
    headers,
  }).then((res) => {
    if (['303042', '200005'].includes(String(res?.msg))) {
      if (!window.loginRedirect) {
        // 所有产品失效，跳转登录页

        Toast.fail(formatMessage({ id: 'packages.expire.tip' }));

        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.includes('teamsmobile') && sessionStorage.getItem('from') === 'cloud') {
          localStorage.removeItem('teamsCloudAccess');
          sessionStorage.removeItem(ACCESS_TOKEN);
          const pId = localStorage.getItem('teamsProductCode') || 'vst';
          const { origin } = window.location;
          const url = `${origin}/${DEFAULT_PREFIX}/main/teamsAADLogin?pId=${pId}&loginStatus=logOut`;
          window.location.replace(url);
        } else {
          window.loginRedirect = setTimeout(() => {
            window.sessionStorage.clear();
            location.href = `/${DEFAULT_PREFIX}/app/user/login`;
          }, 2000);
        }
      }
      throw new Error('expire will redirect');
    }
    return res;
  });
};

// 获取URL地址的参数值。
// name为URL参数名
// 例如：?param1=abc&param2=123
// 当调用getUrlParam("param2"）时，获取到的值为：123
export const getUrlParam = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = window.location.search.substr(1).match(reg);
  if (r !== null) return r[2];
  return null;
};

// export const vConsole = new VConsole({
//   maxLogNumber: 1000,
//   onReady: () => {
//     window.console.log('vConsole ready!');
//     // vConsole.hideSwitch();
//   },
// });
