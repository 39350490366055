/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  bind_finish: 'Finish',
  bind_virsical: 'Virsical',
  bind_submit: 'Submit',
  bind_hasbind: 'The wechat number has been bound to account',
  bind_inputAccount: 'Please input account',
  bind_notUserNameEmpty: 'The UserName can not be empty!',
  bind_notPassWordEmpty: 'The PassWord can not be empty!',
  bind_inputPassword: 'Please input password',
  bind_close: 'Close',
  bind_getStateFail: 'Failed to obtain user binding status. Close retry',
  bind_bindFail: 'Binding failure',
  bind_btn_bind: 'Bind',
  'bind.title.app': 'Bind',
};
