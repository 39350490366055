export default {
  'sws.booking.user.choice': '您所选择的工位：',
  'sws.booking.already.occupied': '该工位已经被占用',
  'sws.booking.already.occupied.tip': '该工位已经被占用，请选择其他时段或其他工位',
  'sws.booking.get.off.work': '所选区域已过下班时间，不支持预订',
  'sws.booking.get.no.time': '未设置工作日时间',
  'sws.booking.no.worktime': '不在上班时间，不支持使用或预订',
  'sws.booking.time.no.worktime': '所选时间处于非上班时间{value1}，不支持使用或预订',
  'sws.booking.no.location': '请选择区域和时间',
  'sws.booking.no.location.tip': '请先选择区域',
  'sws.booking.no.select.date': '请选择日期',
  'sws.booking.no.select.startTime': '请选择开始时间',
  'sws.booking.no.select.endTime': '请选择结束时间',
  'sws.booking.limit.date': '跨天最多支持预订 {value1} 天',
  'sws.booking.time.error': '开始时间不能小于当前时间，请重新选择开始时间',
  'sws.booking.back': '返回',
  'sws.booking.local': '本地区域',
  'sws.booking.share': '共享区域',
  'sws.param.booking.time': '时间',
  'sws.nodata.tip.title': '当前无可预订工位，请联系管理员添加地图。',
  'sws.nodata.tip.desc': '如何添加地图，帮助地址：https://cloud.virsical.cn/help',
  401112: '预订天数超过系统限制',
  401113: '不在工作时间内，无法预订',
  401135: '已存在预订状态工位',
  401136: '已存在使用状态工位',
  401134: '预订数量已超限，请先释放已预订或已使用的工位',
  401058: '预订天数超出开放日期限制',
  401059: '预订次数超过{value1}次',
  401060: '跨天预订天数超出限制',
  'map.color.setting.material.status.1': '空闲',
  'map.color.setting.material.status.2': '占用',
  'map.color.setting.material.status.3': '不可用',
  401145: '预订开始时间不能大于结束时间',
  401146: '结束时间已过下班时间，无法预订',
  401153: '您拥有指定区域范围内的固定工位，暂不支持共享工位预订',
  401154: '您不支持跨天预订工位',
  401155: '{value1}有效预订次数超过限制',
  401156: '有效预订时长超出{value1}小时',
};
