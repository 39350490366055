/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'app.setPassword.oldPassword': '原始密碼',
  'app.setPassword.oldPassword.tip': '原始密碼不能為空',
  'app.setPassword.newPassword': '新密碼',
  'app.setPassword.newPassword.tip': '新密碼不能為空',
  'app.setPassword.againPassword': '確認密碼',
  'app.setPassword.againPassword.tip': '確認密碼不能為空',
  'app.setPassword.passwordAgain':
    '請輸入8到16位密碼（必需包含數字及字母，可包含如下特殊字符：.!@#$%^&*-_）',
  'app.setPassword.isOk': '確定',
  'app.setPassword.operate.fail': '操作失敗',
  'app.setPassword.operate.success': '操作成功',
  'app.setPassword.operate.oldPassword': '請填寫原始密碼',
  'app.setPassword.operate.againNew': '請輸入新的密碼',
  'app.setPassword.operate.againNew.1': '請再次輸入新的密碼',
};
