import { stringify, parse } from 'qs';
import { Toast } from 'antd-mobile-v2';
import clone from 'lodash/clone';
import { formatMessage, getDvaApp, history } from 'umi';
import { setAuthority } from '@/utils/authority';
import {
  getPageQuery,
  sleep,
  getTitle,
  isDingTalk,
  thirdAuth,
  getTokenByRefreshToken,
  decryptAes,
} from '@/utils/utils';
import { zhIntl } from '@/utils/intlUtils';
import login, {
  sendEmailGotPW,
  // getInitData,
  securityCodeCheck,
  sendSMSCaptcha,
  SMSCodeLogin,
  TeamsCodeLogin,
  getUserProductCodes,
  getFeishuUrl,
  getPicture,
  reqCheck,
  refreshToken,
  refreshByToken,
  getIconFontByEnc,
} from '@/services/login';
import {
  ROUTE_PATH,
  ACCESS_TOKEN,
  AUTHORITY,
  ROUTE_ACCESS_LIST,
  TOKEN_LOGIN_ROUTER,
  LOGIN_ROUTER,
  INDEX_ROUTER,
  SOCKET_PATH,
  WX_BIND,
  DD_BIND,
  WAFER_TOKEN_LOGIN_ROUTER,
  CSS_PATH,
  STATIC_RESOURCE_PREFIX,
  PC_TOKEN_LOGIN_ROUTER,
  CLOUDHUB_BIND,
  CLOUDHUB_AUTH,
  DEFAULT_INTERFACE,
  GETTOKENTIME,
  PASSWORD_AES_KEY,
} from '@/utils/constant';
import moment from 'moment-timezone';
import { connect, subscribe, unsubscribe, closeSocket } from '@/utils/socketUtils';

// const delBootstrap = () => {
//   const bootstrap = document.getElementById('bootstrap');
//   if (bootstrap) {
//     bootstrap.remove();
//   }
// };
//
// const addBootstrap = () => {
//   const bootstrap = document.getElementById('bootstrap');
//   if (!bootstrap) {
//     const head = document.getElementsByTagName('HEAD').item(0);
//     const style = document.createElement('link');
//     style.href = `${CSS_PATH}/sliderLib/twitter-bootstrap/css/bootstrap.min.css`;
//     style.rel = 'stylesheet';
//     style.type = 'text/css';
//     style.id = 'bootstrap';
//     head.appendChild(style);
//   }
// };

const delIcon = () => {
  const iconJS = document.getElementById('iconJS');
  if (iconJS) {
    iconJS.remove();
  }
};

const addIcon = (src) => {
  const iconJS = document.getElementById('iconJS');
  if (iconJS) {
    delIcon();
  }
  const head = document.getElementsByTagName('HEAD').item(0);
  const script = document.createElement('script');
  script.src = src;
  script.type = 'text/javascript';
  script.id = 'iconJS';
  head.appendChild(script);
};

export default {
  namespace: 'login',

  state: {
    info: null,
    status: undefined,
    err: null,
    verificationCodeImage: null,
    randomStr: null,
    mail: '',
    indexPageInfo: {},
    loginObj: {
      userName: '',
      password: '',
      code: '',
      randomStr: '',
    },
    showSlide: false,
    UUID: 0,
    securityFlag: '',
    smsCodeTitle: '',
    smsCodeCaptcha: '',
    omsUserProduct: null,
  },

  effects: {
    /* 滑动验证码发起的登录 */
    *actionLogin({ payload }, { put }) {
      //  Toast.loading(formatMessage({ id: 'app.loading' }), 0);
      const obj = { ...payload };

      if (obj) {
        yield put({
          type: 'login/login',
          payload: {
            ...obj,
          },
        });
      }
    },
    *login({ payload }, { call, put, select }) {
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          err: null,
        },
      });
      let response;
      try {
        response = yield call(login, payload);
      } catch (e) {
        Toast.hide();
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            err: formatMessage({ id: e.message }),
          },
        });
      }
      // Login successfully

      const { pathname } = window.location;
      if (pathname?.endsWith('/user/authorize') && response?.code === 0) {
        // 第三方认证
        window.location.href = response.data;
        return;
      }

      if (response && response.code !== 1) {
        // 威思客统一认证失败后登录后跳转
        if (
          sessionStorage.getItem('loginType') === 'WAFERLOGIN' &&
          sessionStorage.getItem('waferLogin')
        ) {
          let redUrl = sessionStorage.getItem('waferLogin');
          redUrl = `${redUrl}?token=${response.access_token}&username=${response.username}`;
          // alert(redUrl);
          window.location.replace(redUrl);
          return;
        }

        sessionStorage.setItem(ACCESS_TOKEN, response.access_token);
        sessionStorage.setItem('system', response.system?.join(','));

        sessionStorage.setItem('expires_in', response.expires_in);
        sessionStorage.setItem(
          'failTime',
          moment(new Date()).add(response.expires_in, 's').valueOf(),
        );

        // 判断公共信息接口中webSocket为true时创建链接
        const { indexPageInfo } = yield select((state) => state.login);
        if (indexPageInfo.websocket === 'true') {
          /* eslint-disable */
          connect(SOCKET_PATH, getDvaApp()._store.dispatch);
          /* eslint-enable */
        }
        yield put({
          type: 'changeLoginStatus',
          payload: {
            info: response,
            status: 'login',
          },
        });
        const params = getPageQuery();
        let { redirect } = params;
        if (redirect) {
          // fix 添加 history: 'hash' 后统一登录跳转失败问题
          if (redirect.match(/^.*#/)) {
            const str = redirect.split('#');
            redirect = str.shift();
          }
          const redirectUrlParams = new URL(redirect);
          if (redirectUrlParams.search.length > 0) {
            const tmpParams = parse(redirectUrlParams.search);
            redirect = tmpParams['?redirect'];
          }
        }
        if (redirect) {
          window.location.href = redirect;
        } else {
          history.replace(`${ROUTE_PATH}/init`);
          // yield put({
          //   type: 'systemInitialization',
          //   payload: {
          //     refresh: false,
          //   },
          // });
        }
      } else if (response && response.msg === '101079') {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            securityFlag: response.data,
          },
        });
        Toast.hide();
        if (window.location.pathname?.endsWith('/user/authorize')) {
          history.replace(`${ROUTE_PATH}/user/auth2FA?code=user_authorize`);
        } else {
          history.replace(`${ROUTE_PATH}/user/auth2FA`);
        }
      } else if (response && response.msg === '101083') {
        Toast.fail(formatMessage({ id: 'FFA.101083' }));
      } else if (response.msg) {
        Toast.fail(formatMessage({ id: response.msg }));
      } else {
        Toast.fail(formatMessage({ id: 'login.err' }));
      }
    },
    *logout(_, { put }) {
      // redirect
      if (window.location.pathname !== `${CSS_PATH}user/login`) {
        // history.replace({
        //   pathname: `${ROUTE_PATH}/user/login`,
        //
        //   // search: stringify({
        //   //   redirect: window.location.href,
        //   // }),
        // });
        window.location.replace(`${CSS_PATH}user/login`);
        //  history.replace(`/user/login`);
      }
      closeSocket();
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          info: null,
          status: undefined,
        },
      });

      if (window.tokenTime) {
        clearTimeout(window.tokenTime);
      }

      sessionStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(AUTHORITY);
    },
    *systemInitialization({ payload: { refresh } }, { put }) {
      yield put({
        type: 'user/fetchCurrentUserInfo',
        payload: {
          saveInfo: false,
        },
      });
      yield put({
        type: 'menu/getMenuData',
        payload: {
          refresh,
          redirect: INDEX_ROUTER,
        },
      });
    },
    *tokenLogin({ payload: { query } }, { put }) {
      //  window.console.log(query, 'tokenLogin');
      // 临时给localStorage里面写入AUTHORITY占位，后面会被正确的值替代
      localStorage.setItem(AUTHORITY, window.btoa(encodeURIComponent(JSON.stringify(query.token))));
      // 设置token存储
      sessionStorage.setItem(ACCESS_TOKEN, query.token);

      // 设置SSO登录标记，用于refreshToken刷新时认证头
      sessionStorage.setItem('thirdSSO', 'true');

      // localStorage 设置token，企业微信认证使用
      localStorage.setItem('wechatAuth', query.token);

      // 设置刷新token存储
      if (query?.refresh_token) {
        sessionStorage.setItem('sso_refresh_token', query.refresh_token);
        sessionStorage.setItem('refresh_token', query.refresh_token);
      }
      // 存储应用Id menuId
      if (query?.menuId) {
        sessionStorage.setItem('sso_menuIdAuth', query.menuId);
      }

      // 存储token过期时间
      if (query?.expires_in) {
        sessionStorage.setItem('expires_in', query.expires_in);
        sessionStorage.setItem('failTime', moment(new Date()).add(query.expires_in, 's').valueOf());

        // 启动refreshToken刷新计时器
        getTokenByRefreshToken();
      }

      // Teams应用切换清除sessionStorage，因此从应用进来token保存在localStorage
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('teams') !== -1 || sessionStorage.getItem('entrySource') === 'teams') {
        localStorage.setItem('teamsCloudAccess', query.token);
      } else if (userAgent.indexOf('lark') !== -1) {
        if (query?.appId) {
          localStorage.setItem('larkAppId', query.appId);
        }
      }

      let redirectTeams = null;
      let key = localStorage.getItem('teamsProductCode') || 'vst';
      key = `${key}_subId`;
      // 判断是否是teams应用，并获取深度连接跳转地址
      if (userAgent.indexOf('teamsmobile') !== -1) {
        // 是teams应用
        redirectTeams = localStorage.getItem(key);
        if (redirectTeams) {
          redirectTeams = JSON.parse(redirectTeams);
        }
      }

      // token login 可选参数hr，如果有hr（home router）参数，则可以传入一个自定义home router，
      if (query.hr) {
        localStorage.setItem('h', window.btoa(query.hr));
      } else {
        // 默认是把首次进入的router作为home router
        localStorage.setItem('h', window.btoa(query.redirect));
      }
      // token login 先跳入过度页面防止循环刷新
      if (!isDingTalk()) {
        // 不是钉钉的话进入过度页面
        history.replace('/welcome');
      }
      //  sleep(500);
      // 获取用户登录信息
      yield put({
        type: 'user/fetchCurrentUserInfo',
        payload: {
          saveInfo: true,
          redirect: redirectTeams?.appUrl ? redirectTeams.appUrl : query.redirect,
        },
      });
      yield put({
        type: 'getInitData',
      });
      sessionStorage.removeItem('lcWx');
      sessionStorage.removeItem('lcDD');
      sessionStorage.removeItem('lcV');
    },
    *checkRefresh(_, { put, select }) {
      const { info, status } = yield select((state) => state.login);
      if (!info && !status) {
        let authority;
        const userAgent = window.navigator.userAgent.toLowerCase();
        authority = localStorage.getItem(AUTHORITY);
        if (userAgent.indexOf('lark') !== -1) {
          authority = localStorage.getItem('authorityLark');
        }
        if (authority) {
          const tmp = JSON.parse(decodeURIComponent(window.atob(authority)));
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              info: tmp,
              status: 'login',
            },
          });
          sessionStorage.setItem(ACCESS_TOKEN, tmp.access_token);
          yield put({
            type: 'systemInitialization',
            payload: {
              refresh: true,
            },
          });
          yield put({
            type: 'getInitData',
          });
        } else {
          if (userAgent.indexOf('lark') !== -1) {
            const appId = localStorage.getItem('larkAppId');
            if (appId) {
              yield put({ type: 'getFeishuUrl', payload: { appId } });
            } else {
              setTimeout(() => {
                Toast.fail(formatMessage({ id: 'login.feishu.err.title' }));
              }, 300);
            }
          } else {
            setTimeout(() => {
              Toast.fail(formatMessage({ id: 'login.storage.err' }));
            }, 1000);
            yield sleep(5000);
            const url = window.location.href;

            if (url.indexOf('/user/login') === -1) {
              const userAgent = window.navigator.userAgent.toLowerCase();
              if (
                userAgent.indexOf('teamsmobile') !== -1 &&
                sessionStorage.getItem('from') === 'cloud'
              ) {
                localStorage.removeItem('teamsCloudAccess');
                sessionStorage.removeItem(ACCESS_TOKEN);
                const pId = localStorage.getItem('teamsProductCode') || 'vst';
                const { origin } = window.location;
                const url = `${origin}/${DEFAULT_INTERFACE}/main/teamsAADLogin?pId=${pId}`;
                window.location.replace(url);
              } else {
                history.replace({
                  pathname: `${ROUTE_PATH}/user/login`,
                  search: stringify({
                    redirect: window.location.href,
                  }),
                });
              }
            }
          }
        }
      }
    },
    *getInitData(_, { put, call, select }) {
      try {
        let response = yield call(getIconFontByEnc, {});

        if (typeof response === 'string') {
          try {
            response = decryptAes(response, PASSWORD_AES_KEY);
            if (response) {
              response = JSON.parse(response);
            }
          } catch (e) {
            window.console.log('兼容老版本接口，整个数据加密， 解密失败', e);
          }
        }
        window.console.log(response, 'getInitData response');
        if (response.code === 0) {
          let data = response.data;
          if (data) {
            try {
              if (typeof data === 'string') {
                data = decryptAes(data, PASSWORD_AES_KEY);
                data = JSON.parse(data);
              }
              window.console.log(data, '44444444444444');
              const iconFontUrlApp = data['icon-app'];
              window.iconFontUrlApp = data['icon-app'];
              window.openFeedback = !data['open-feedback']
                ? true
                : data['open-feedback'] === 'true' || false;
              addIcon(iconFontUrlApp);
              document.title = getTitle(data);
              if (data['points-unit'] && data['points-name']) {
                window.pointsUnit = JSON.parse(data['points-unit']);
                window.pointsName = JSON.parse(data['points-name']);
              }
              window.encryptStrength = data?.['encrypt-strength'] || '0';
              // 设置云服务全局标识
              // eslint-disable-next-line dot-notation
              if (data['open_cloud'] === 'true') {
                sessionStorage.setItem('from', 'cloud');
              }
              /* 首页登录信息存储 */
              yield put({
                type: 'saveOrUpdateData',
                payload: {
                  indexPageInfo: data,
                },
              });

              // 首页登录信息写入 sessionStorage
              sessionStorage.setItem(
                'ipiApp',
                window.btoa(encodeURIComponent(JSON.stringify(data))),
              );

              const { info, status } = yield select((state) => state.login);
              // 判断公共信息接口中webSocket为true时创建链接
              if (data.websocket === 'true' && status && info) {
                /* eslint-disable */
                connect(SOCKET_PATH, getDvaApp()._store.dispatch);
                /* eslint-enable */
              }
            } catch (e) {
              window.console.log('init/data 解析数据失败');
            }
          }
        }
      } catch (e) {
        window.console.warn(`Loading Init Data error => ${e}`);
      }
    },
    // 获取验证码图片地址
    *getVerificationCodeImageUrl({ payload: { randomStr } }, { put }) {
      const verificationCodeImage = `${window.location.origin}/${DEFAULT_INTERFACE}code?randomStr=${randomStr}`;
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          verificationCodeImage,
          randomStr,
        },
      });
    },
    *sendEmailGotPW({ payload }, { call, put }) {
      const obj = clone(payload);
      obj.domainStr = `${window.location.protocol}//${window.location.host}`;
      const response = yield call(sendEmailGotPW, { payload: obj });
      // const response = yield call(sendEmailGotPW, { payload });
      if (response.code === 0) {
        const { mail } = payload;
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            mail,
          },
        });
        history.replace(`${ROUTE_PATH}/user/send-success${window.location.search}`);
        Toast.hide();
      } else {
        Toast.fail(formatMessage({ id: response.msg }));
      }
    },
    *subscribe({ payload: { channel, msgCallback } }, { select }) {
      // 判断公共信息接口中webSocket为true时创建链接
      const { indexPageInfo } = yield select((state) => state.login);
      if (indexPageInfo.websocket === 'true') {
        subscribe(channel, msgCallback);
      } else {
        throw Error('socket不允许链接和订阅，请联系管理员');
      }
    },
    *unsubscribe({ payload: { channel } }, { select }) {
      // 判断公共信息接口中webSocket为true时创建链接
      const { indexPageInfo } = yield select((state) => state.login);
      if (indexPageInfo.websocket === 'true') {
        unsubscribe(channel);
      } else {
        throw Error('socket不允许链接和退订，请联系管理员');
      }
    },
    *securityCodeCheck({ payload }, { call, put, select }) {
      const response = yield call(securityCodeCheck, { payload });

      // 第三方认证
      const { search } = window.location;
      if (search?.includes('user_authorize') && response?.code === 0) {
        // 第三方认证
        window.location.href = response.data;
        return;
      }

      if (response && response.code !== 1) {
        // 威思客统一认证失败后登录后跳转
        if (
          sessionStorage.getItem('loginType') === 'WAFERLOGIN' &&
          sessionStorage.getItem('waferLogin')
        ) {
          let redUrl = sessionStorage.getItem('waferLogin');
          redUrl = `${redUrl}?token=${response.access_token}&username=${response.username}`;
          // alert(redUrl);
          window.location.replace(redUrl);
          return;
        }

        sessionStorage.setItem(ACCESS_TOKEN, response.access_token);

        sessionStorage.setItem('expires_in', response?.expires_in);
        sessionStorage.setItem(
          'failTime',
          moment(new Date()).add(response?.expires_in, 's').valueOf(),
        );

        // 判断公共信息接口中webSocket为true时创建链接
        const { indexPageInfo } = yield select((state) => state.login);
        if (indexPageInfo.websocket === 'true') {
          /* eslint-disable */
          connect(SOCKET_PATH, getDvaApp()._store.dispatch);
          /* eslint-enable */
        }
        yield put({
          type: 'changeLoginStatus',
          payload: {
            info: response,
            status: 'login',
          },
        });
        const params = getPageQuery();
        let { redirect } = params;
        if (redirect) {
          // fix 添加 history: 'hash' 后统一登录跳转失败问题
          if (redirect.match(/^.*#/)) {
            const str = redirect.split('#');
            redirect = str.shift();
          }
          const redirectUrlParams = new URL(redirect);
          if (redirectUrlParams.search.length > 0) {
            const tmpParams = parse(redirectUrlParams.search);
            redirect = tmpParams['?redirect'];
          }
        }
        if (redirect) {
          window.location.href = redirect;
        } else {
          yield put({
            type: 'systemInitialization',
            payload: {
              refresh: false,
            },
          });
        }
      } else if (response.msg) {
        Toast.fail(formatMessage({ id: response.msg }));
      } else {
        Toast.fail(formatMessage({ id: 'login.err' }));
      }
    },
    *sendSMSCaptcha({ payload }, { call, put }) {
      const result = yield call(sendSMSCaptcha, payload);
      if (result.code === 0) {
        //  sessionStorage.setItem('SMSCaptcha', '59');
        yield put({
          type: 'saveOrUpdateData',
          payload: { smsCodeTitle: '59', smsCodeCaptcha: result.data },
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *SMSCodeLogin({ payload }, { call, put, select }) {
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          err: null,
        },
      });
      let response;
      try {
        response = yield call(SMSCodeLogin, payload);
      } catch (e) {
        Toast.hide();
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            err: formatMessage({ id: e.message }),
          },
        });
      }

      // 第三方认证
      const { pathname } = window.location;
      if (pathname?.endsWith('/user/authorize') && response?.code === 0) {
        // 第三方认证
        window.location.href = response.data;
        return;
      }

      // Login successfully
      if (response && response.code !== 1) {
        // 威思客统一认证失败后登录后跳转
        if (
          sessionStorage.getItem('loginType') === 'WAFERLOGIN' &&
          sessionStorage.getItem('waferLogin')
        ) {
          let redUrl = sessionStorage.getItem('waferLogin');
          redUrl = `${redUrl}?token=${response.access_token}&username=${response.username}`;
          // alert(redUrl);
          window.location.replace(redUrl);
          return;
        }

        sessionStorage.setItem(ACCESS_TOKEN, response.access_token);

        sessionStorage.setItem('expires_in', response?.expires_in);
        sessionStorage.setItem(
          'failTime',
          moment(new Date()).add(response?.expires_in, 's').valueOf(),
        );

        // 判断公共信息接口中webSocket为true时创建链接
        const { indexPageInfo } = yield select((state) => state.login);
        if (indexPageInfo.websocket === 'true') {
          /* eslint-disable */
          connect(SOCKET_PATH, getDvaApp()._store.dispatch);
          /* eslint-enable */
        }
        yield put({
          type: 'changeLoginStatus',
          payload: {
            info: response,
            status: 'login',
          },
        });
        const params = getPageQuery();
        let { redirect } = params;
        if (redirect) {
          // fix 添加 history: 'hash' 后统一登录跳转失败问题
          if (redirect.match(/^.*#/)) {
            const str = redirect.split('#');
            redirect = str.shift();
          }
          const redirectUrlParams = new URL(redirect);
          if (redirectUrlParams.search.length > 0) {
            const tmpParams = parse(redirectUrlParams.search);
            redirect = tmpParams['?redirect'];
          }
        }
        if (redirect) {
          window.location.href = redirect;
        } else {
          yield put({
            type: 'systemInitialization',
            payload: {
              refresh: false,
            },
          });
        }
      } else if (response && response.msg === '101079') {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            securityFlag: response.data,
          },
        });
        Toast.hide();
        if (window.location.pathname?.endsWith('/user/authorize')) {
          history.replace(`${ROUTE_PATH}/user/auth2FA?code=user_authorize`);
        } else {
          history.replace(`${ROUTE_PATH}/user/auth2FA`);
        }
      } else if (response && response.msg === '101083') {
        Toast.fail(formatMessage({ id: 'FFA.101083' }));
      } else if (response.msg) {
        yield put({
          type: 'getVerificationCodeImageUrl',
          payload: { randomStr: Math.floor(Math.random() * 100000000000000000000) },
        });
        Toast.fail(formatMessage({ id: response.msg }));
      } else {
        Toast.fail(formatMessage({ id: 'login.err' }));
      }
    },
    *TeamsCodeLogin({ payload }, { call, put, select }) {
      let result;
      try {
        result = yield call(TeamsCodeLogin, payload);
      } catch (e) {
        Toast.hide();
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            err: formatMessage({ id: e.message }),
          },
        });
      }
      // Login successfully
      if (result && result.code !== 1) {
        const { data: response, url, homeUrl } = result.data;
        // yield put({
        //   type: 'tokenLogin',
        //   payload: {
        //     token: response.access_token,
        //     redirect: url,
        //     hr: homeUrl,
        //   },
        // });
        sessionStorage.setItem(ACCESS_TOKEN, response.access_token);

        // 设置SSO登录标记，用于refreshToken刷新时认证头
        sessionStorage.setItem('thirdSSO', 'true');

        sessionStorage.setItem('expires_in', response?.expires_in);
        sessionStorage.setItem(
          'failTime',
          moment(new Date()).add(response?.expires_in, 's').valueOf(),
        );

        // 判断公共信息接口中webSocket为true时创建链接
        const { indexPageInfo } = yield select((state) => state.login);
        if (indexPageInfo.websocket === 'true') {
          /* eslint-disable */
          connect(SOCKET_PATH, getDvaApp()._store.dispatch);
          /* eslint-enable */
        }
        yield put({
          type: 'changeLoginStatus',
          payload: {
            info: response,
            status: 'login',
          },
        });

        let redirect = url;
        let key = sessionStorage.getItem('teamsAppId');
        key = `${key}_subId`;
        let redirectTeams = localStorage.getItem(key);
        //  alert(`redirectTeams--${redirectTeams}`);
        if (redirectTeams) {
          redirectTeams = JSON.parse(redirectTeams);
          redirect = redirectTeams.appUrl || url;
        }

        if (homeUrl) {
          localStorage.setItem('h', window.btoa(homeUrl));
        } else {
          // 默认是把首次进入的router作为home router
          localStorage.setItem('h', window.btoa(redirect));
        }

        if (redirect) {
          // 获取用户登录信息
          yield put({
            type: 'user/fetchCurrentUserInfo',
            payload: {
              saveInfo: true,
              redirect: redirect,
            },
          });

          // // 重定向地址是完整地址的，进行href替换
          // if (redirect.startsWith('http')) {
          //   window.location.href = redirect;
          // } else {
          //   window.location.href = `${window.location.origin}/vsk/app${redirect}`;
          //   //  history.replace(decodeURIComponent(url));
          // }
          // //  window.location.href = `${window.location.origin}/vsk/app${url}`;
        } else {
          yield put({
            type: 'systemInitialization',
            payload: {
              refresh: false,
            },
          });
        }
      } else if (result.msg) {
        yield put({
          type: 'getVerificationCodeImageUrl',
          payload: { randomStr: Math.floor(Math.random() * 100000000000000000000) },
        });
        Toast.fail(formatMessage({ id: result.msg }));
      } else {
        Toast.fail(formatMessage({ id: 'login.err' }));
      }
    },
    *getUserProductCodes(_, { call, put }) {
      const result = yield call(getUserProductCodes);
      if (result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: { omsUserProduct: result.data },
        });
        sessionStorage.setItem('omsUserProduct', result.data);
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *getFeishuUrl({ payload }, { call }) {
      const result = yield call(getFeishuUrl);
      if (result.code === 0) {
        const url = result.data?.find((item) => item.paramKey === 'redirect_auth_url')?.paramValue;
        window.location.href = `${window.location.origin}${url}?appId=${payload.appId}`;
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *getPicture({ payload }, { call }) {
      const result = yield call(getPicture, { ...payload });
      return result;
    },
    *reqCheck({ payload }, { call }) {
      const result = yield call(reqCheck, { ...payload });
      return result;
    },
    // 获取租户激活方式
    *refreshToken(_, { call, put }) {
      const result = yield call(refreshToken);
      if (result.code === 0) {
        // 临时给localStorage里面写入AUTHORITY占位，后面会被正确的值替代
        localStorage.setItem(
          AUTHORITY,
          window.btoa(encodeURIComponent(JSON.stringify(result.access_token))),
        );
        // 设置token存储
        sessionStorage.setItem(ACCESS_TOKEN, result.access_token);
        // localStorage 设置token，企业微信认证使用
        localStorage.setItem('wechatAuth', result.access_token);

        yield put({
          type: 'changeLoginStatus',
          payload: {
            info: result,
            status: 'login',
          },
        });
      } else if (result.code === 1) {
        const appId = sessionStorage.getItem('sso_menuIdAuth');
        if (appId) {
          thirdAuth(appId);
        }
      }
    },
    // 通过refreshToken刷新token
    *refreshByToken({ payload }, { call, put }) {
      let refreshToken = payload.refresh_token || sessionStorage.getItem('refresh_token');
      if (!refreshToken) {
        window.console.log('Miss refresh_token');
        return;
      }
      const result = yield call(refreshByToken, { ...payload, refresh_token: refreshToken });
      if (result && result.access_token) {
        yield put({
          type: 'save',
          payload: {
            authInfo: result,
          },
        });
        yield put({
          type: 'changeLoginStatus',
          payload: {
            info: result,
            status: 'login',
          },
        });

        sessionStorage.setItem(
          'failTime',
          moment(new Date()).add(result.expires_in, 's').valueOf(),
        );

        if (sessionStorage.getItem('thirdSSO') === 'true') {
          sessionStorage.setItem('sso_refresh_token', result.refresh_token);
        }

        sessionStorage.setItem('access_token', `${result.access_token}`);
        // localStorage 设置token，企业微信认证使用
        localStorage.setItem('wechatAuth', result.access_token);
        // Teams存储localStorage Token
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (
          userAgent.indexOf('teams') !== -1 ||
          sessionStorage.getItem('entrySource') === 'teams'
        ) {
          localStorage.setItem('teamsCloudAccess', result.access_token);
        }

        if (window.tokenTime) {
          clearTimeout(window.tokenTime);
        }
        window.tokenTime = setTimeout(() => {
          getDvaApp()._store.dispatch({
            type: 'login/refreshByToken',
            payload: {
              grant_type: 'refresh_token',
              refresh_token: result.refresh_token,
            },
          });
        }, (result.expires_in - GETTOKENTIME) * 1000);
      } else {
        Toast.fail(zhIntl('刷新token失败'));
      }
    },
  },

  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.info);
      return {
        ...state,
        info: payload.info,
        status: payload.status,
      };
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return umiHistory.listen(({ pathname, query }) => {
        if (pathname.trim().length > 0) {
          // 非登录页面去掉bootstrap样式
          // if (pathname.indexOf(LOGIN_ROUTER) === -1) {
          //   delBootstrap();
          // } else {
          //   addBootstrap();
          // }

          // 登录页面带errorCode
          if (pathname.indexOf(LOGIN_ROUTER) !== -1 && query?.errorCode) {
            Toast.fail(formatMessage({ id: query.errorCode }));
          }

          if (pathname.indexOf('/teamsSSO') !== -1) {
            sessionStorage.setItem('entryTeamsType', 'teamsSSO');
            if (query.id) {
              sessionStorage.setItem('teamsAppId', query.id);
            }
            const userAgent = window.navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('teamsmobile') === -1) {
              const tempUrl = window.location.href;
              window.location.href = `${tempUrl.replace('/app/', '/main/')}`;
              return;
            }
          }

          if (
            pathname.indexOf(LOGIN_ROUTER) !== -1 ||
            pathname.indexOf(WX_BIND) !== -1 ||
            pathname.indexOf(DD_BIND) !== -1 ||
            pathname.indexOf(CLOUDHUB_BIND) !== -1 ||
            pathname.indexOf(WAFER_TOKEN_LOGIN_ROUTER) !== -1 ||
            pathname.indexOf(CLOUDHUB_AUTH) !== -1 ||
            pathname.indexOf('/teamsSSO') !== -1 ||
            pathname.indexOf('/wdfclient/feishu') !== -1 ||
            pathname.indexOf('/workspace/station/inventory') !== -1 ||
            pathname.indexOf('/wdfclient/wechatAuth') !== -1 ||
            pathname.indexOf('/scanAuthPage') !== -1 ||
            pathname.indexOf('/error') !== -1 ||
            pathname.indexOf('/user/authorize') !== -1
          ) {
            dispatch({ type: 'getInitData' });
            return;
          }
          // 通过token login登录
          if (pathname.indexOf(TOKEN_LOGIN_ROUTER) !== -1) {
            if (query.token && query.redirect) {
              dispatch({
                type: 'tokenLogin',
                payload: {
                  query,
                },
              });
            } else {
              Toast.fail(`参数缺失 / Parameters are missing`, 5, () => {
                history.replace(LOGIN_ROUTER);
              });
            }
            return;
          }

          if (pathname.indexOf('/shareResourceScanPage') !== -1) {
            const authority = localStorage.getItem(AUTHORITY);
            if (authority) {
              const tmp = JSON.parse(decodeURIComponent(window.atob(authority)));
              if (tmp?.access_token) {
                sessionStorage.setItem(ACCESS_TOKEN, tmp.access_token);
                if (query.redirect) {
                  if (query.redirect.startsWith('http')) {
                    window.location.href = `${query.redirect}&token=${tmp?.access_token}`;
                  } else {
                    history.replace(query.redirect);
                  }
                } else {
                  Toast.fail(`参数缺失 / Parameters are missing`, 5);
                  history.replace('/error');
                }
              } else {
                history.replace('/error?msg=tenant_share');
              }
            } else {
              history.replace('/error?msg=tenant_share');
            }
            return;
          }
          // PC 通过iframe 来加载 login登录
          if (pathname.indexOf(PC_TOKEN_LOGIN_ROUTER) !== -1) {
            if (query.token && query.redirect) {
              if (query.hr) {
                window.location.href = `${
                  window.location.origin + STATIC_RESOURCE_PREFIX
                }appShell.html?token=${query.token}&redirect=${query.redirect}&hr=${query.hr}`;
              } else {
                window.location.href = `${
                  window.location.origin + STATIC_RESOURCE_PREFIX
                }appShell.html?token=${query.token}&redirect=${query.redirect}`;
              }
            } else {
              Toast.fail(`参数缺失 / Parameters are missing`, 5, () => {
                history.replace(LOGIN_ROUTER);
              });
            }
            return;
          }
          // 检查前端访问router白名单
          const result = ROUTE_ACCESS_LIST.find((element) => {
            const reg = new RegExp(element);
            return pathname === '/' || reg.test(pathname);
          });
          if (!result) {
            dispatch({
              type: 'checkRefresh',
            });
          }
        }
      });
    },
  },
};
