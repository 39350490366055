/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 查询普通预订的信息
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getCheckTaskInfo({ size, current }) {
  const url = `/${RESTFUL_PATH.sws}/api/check-task-info/pageForApp?pageSize=${size}&current=${current}`;
  return request(url, {
    method: 'GET',
  });
}
