/**
 * Created by wu on 2017/5/31.
 */
import virsical from 'virsical-jssdk';
import request, { requestNoAuthorization } from '@/utils/request';
import { getUrlParam } from '@/utils/utils';
import { RESTFUL_PATH } from '@/utils/constant';

const config = (jsSDKInfo, isDebug = true) =>
  new Promise((resolve, reject) => {
    virsical.ready(resolve);
    virsical.error(reject);
    virsical.config({
      debug: isDebug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来。
      client_id: jsSDKInfo.appid, // 必填，第三方应用接入ID ReserveStation
      client_secret: jsSDKInfo.signature, // 必填，第三方应用接入秘钥 wafer
    });
  });

const login = () =>
  new Promise((resolve, reject) => {
    virsical.login({
      success: (res) => {
        resolve(res.info);
      },
      fail: (res) => {
        reject(res);
      },
    });
  });

const captureQR = () =>
  new Promise((resolve, reject) => {
    virsical.captureQR({
      success: (res) => {
        resolve(res.url);
      },
      fail: (res) => {
        window.console.log('fail', res);
        reject(res);
      },
    });
  });

/**
 * 获得初始化Virsical环境，获得用户信息
 * @param jsSDKInfo
 * @returns {Promise.<void>}
 */
export const init = async (jsSDKInfo) => {
  const userInfo = await config(jsSDKInfo)
    .then(login, (err) => {
      window.console.log('visical config error', err);
      window.console.log(err.msg);
    })
    .catch((err) => {
      window.console.log(err);
      window.console.log(err.msg);
    });
  return typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo;
};

/**
 * 调用威思客二维码扫描
 * @returns 扫描结果
 */
export const qrScan = async () => {
  const reslut = await captureQR();
  return reslut;
};

export const messageCallback = (callback) => {
  virsical.messageCallback(callback);
};

export const wechatSign = ({ tokenSn, sign }) => {
  const url = `/vclient/client/getUser?tokenSn=${tokenSn}&sign=${sign}`;
  return requestNoAuthorization(url, { headers: { method: 'GET' } });
};
/**
 * 判断是不是威思客浏览器
 * @returns {boolean}
 */
export const getVirsicalBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('virsical') > -1;
};

export const getVstNotCfmCount = () => {
  request(``);
};

export const getAccessToken = () => {
  const url = `/virsical-auth/mobile/token/virsical`;
  // const data =
  //   'mobile=' +
  //   'VIRSICAL@6AE633BB2BE605FC9DDC9135FCD2AC0E9684023E70006D46599A270106E0343A' +
  //   '&' +
  //   'grant_type=' +
  //   'mobile';
  const token = getUrlParam('token');
  const data = `mobile=VIRSICAL@${token}&grant_type=mobile`;
  return requestNoAuthorization(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic dGVzdDp0ZXN0',
      'vsk-auth': 'Basic dGVzdDp0ZXN0',
    },
    // body:formData
    body: data,
  });
};

// 登录
export const userLogin = ({ employeeId, employeeSecret, deviceType, macAddress }) => {
  // if (deviceType === 0 && macAddress) {
  return request(`/${RESTFUL_PATH.visitor}/api/auth/terminal`, {
    method: 'POST',
    body: JSON.stringify({
      username: employeeId,
      password: employeeSecret,
      deviceType,
      macAddress,
    }),
  });
  // }
  // return request('/visitor/api/auth/app', {
  //   method: 'POST',
  //   body: JSON.stringify({ username: employeeId, password: employeeSecret }),
  // });
};
