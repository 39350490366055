/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.qrCode.click': '请点击',
  'access.qrCode.update': '刷新',
  'access.qrCode.show': '已刷新',
};
