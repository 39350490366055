/* eslint-disable global-require */
import { Switch, Slider } from 'antd-mobile-v2';

import { defineMessages } from 'react-intl';
import type1 from '@/assets/Lighting/type1.png';
import type2 from '@/assets/Lighting/type2.png';

import xDenpao from '@/assets/Lighting/x-dengpao.png';
import xHuiyi from '@/assets/Lighting/x-huiyi.png';
import xDuihua from '@/assets/Lighting/x-duihua.png';

export const CHINESE = 'zh-CN';
export const ENGLISH = 'en-US';

export const DASHBOARD_SEAT = 'seat';
export const DASHBOARD_LIGHT = 'light';
export const DASHBOARD_ENVIRONMENT = 'environment';

export const PanelDragType = { layout: 'DRAG_LAYOUT', element: 'DRAG_ELEMENT' };
export const DRAG_EL_1 = 'DRAG_EL_1';
export const DRAG_EL_2 = 'DRAG_EL_2';
export const DRAG_EL_3 = 'DRAG_EL_3';
export const DRAG_EL_4 = 'DRAG_EL_4';
export const ChannelType = { COLOR: 'COLOR', TEMPERATURE: 'TEMPERATURE', BRIGHTNESS: 'NORMAL' };

export const Icons = [
  { id: 'icon-kai', name: { [CHINESE]: '开', [ENGLISH]: 'Open' } },
  { id: 'icon-hengxian', name: { [CHINESE]: '关', [ENGLISH]: 'Close' } },
  { id: 'icon-shuimian1', name: { [CHINESE]: '睡眠', [ENGLISH]: 'Sleep' } },
  { id: 'icon-dengpao', name: { [CHINESE]: '节能', [ENGLISH]: 'Energy ' } },
  { id: 'icon-iconfontyingyuanxinxi3dyanjing', name: { [CHINESE]: '影院', [ENGLISH]: 'Cinema' } },
  { id: 'icon-kafei', name: { [CHINESE]: '休闲', [ENGLISH]: 'Leisure' } },
  { id: 'icon-huiyishi', name: { [CHINESE]: '会议', [ENGLISH]: 'Meeting' } },
  { id: 'icon-iconupload', name: { [CHINESE]: '音乐', [ENGLISH]: 'Music' } },
  { id: 'icon-liangdu', name: { [CHINESE]: '亮度80%', [ENGLISH]: '80%' } },
  { id: 'icon-liangdu2', name: { [CHINESE]: '亮度30%', [ENGLISH]: '30%' } },
  { id: 'icon-kongtiao', name: { [CHINESE]: '空调', [ENGLISH]: 'Conditioner' } },
  { id: 'icon-iconfontjiudianmubu32px', name: { [CHINESE]: '幕布', [ENGLISH]: 'curtain' } },
  { id: 'icon-yule', name: { [CHINESE]: '娱乐', [ENGLISH]: 'Entertainment' } },
  { id: 'icon-touying', name: { [CHINESE]: '投影', [ENGLISH]: 'Projection' } },
  { id: 'icon-taolun', name: { [CHINESE]: '讨论', [ENGLISH]: 'Discuss' } },
  { id: 'icon-peixun', name: { [CHINESE]: '培训', [ENGLISH]: 'Train' } },
  { id: 'icon-huanying', name: { [CHINESE]: '欢迎', [ENGLISH]: 'HI' } },
  { id: 'icon-i-chuanglian-sheng', name: { [CHINESE]: '窗帘-升', [ENGLISH]: 'Curtain-lift' } },
  { id: 'icon-i-chuanglian-jiang', name: { [CHINESE]: '窗帘-降', [ENGLISH]: 'Curtain-drop' } },
  { id: 'icon-i-chuanglian-jiangting', name: { [CHINESE]: '窗帘-停', [ENGLISH]: 'Curtain-Stop' } },
  { id: 'icon-iconliangdu', name: { [CHINESE]: '色温', [ENGLISH]: 'Temperature' } },
  { id: 'icon-icon--', name: { [CHINESE]: '暖色', [ENGLISH]: 'Warm' } },
  { id: 'icon-leng', name: { [CHINESE]: '冷色', [ENGLISH]: 'Cool' } },
  { id: 'icon-PPT', name: { [CHINESE]: 'PPT', [ENGLISH]: 'PPT' } },
  { id: 'icon-kongtiao-kai', name: { [CHINESE]: '开空调', [ENGLISH]: 'Turn on ' } },
  { id: 'icon-kongtiao-guan', name: { [CHINESE]: '关空调', [ENGLISH]: 'Turn off' } },
  { id: 'icon-dafeng', name: { [CHINESE]: '空调大风', [ENGLISH]: 'Gale' } },
  { id: 'icon-zhongfeng', name: { [CHINESE]: '空调中风', [ENGLISH]: 'Apoplexy' } },
  { id: 'icon-xiaofeng', name: { [CHINESE]: '空调小风', [ENGLISH]: 'Breeze' } },
  {
    id: 'icon-dianshifengshandengqitaanzhuang',
    name: { [CHINESE]: '空调自动风', [ENGLISH]: 'Automatic' },
  },
  { id: 'icon-kongtiaowendu-gao', name: { [CHINESE]: '空调高温', [ENGLISH]: 'High' } },
  { id: 'icon-kongtiaowendu-zhong', name: { [CHINESE]: '空调中温', [ENGLISH]: 'Middle' } },
  { id: 'icon-kongtiaowendu-di', name: { [CHINESE]: '空调低温', [ENGLISH]: 'Low' } },
  { id: 'icon-touyingyi-kai', name: { [CHINESE]: '开投影仪', [ENGLISH]: 'Open projector' } },
  { id: 'icon-touyingyi-guan', name: { [CHINESE]: '关投影仪', [ENGLISH]: 'Close projector' } },
  { id: 'icon-touyingyimubu-sheng1', name: { [CHINESE]: '升投影仪幕布', [ENGLISH]: 'Rise' } },
  { id: 'icon-touyingyimubu-jiang', name: { [CHINESE]: '降投影仪幕布', [ENGLISH]: 'Drop' } },
];

export const messages = defineMessages({
  'layout.index': {
    id: 'layout.index',
    defaultMessage: 'Dashboard',
  },
  'layout.park.build': {
    id: 'layout.park.build',
    defaultMessage: '园区楼宇管理',
  },
  'layout.space.map': {
    id: 'layout.space.map',
    defaultMessage: '空间地图管理',
  },
  'layout.space.management': {
    id: 'layout.space.management',
    defaultMessage: '空间管理',
  },
  'layout.material.management': {
    id: 'layout.material.management',
    defaultMessage: '素材管理',
  },
  'layout.area.management': {
    id: 'layout.area.management',
    defaultMessage: '区域管理',
  },
  'layout.device.management': {
    id: 'layout.device.management',
    defaultMessage: '设备管理',
  },
  'layout.channel.management': {
    id: 'layout.channel.management',
    defaultMessage: '通道管理',
  },
  'layout.channel.cmd.management': {
    id: 'layout.channel.cmd.management',
    defaultMessage: '通道指令管理',
  },
  'layout.scene.management': {
    id: 'layout.scene.management',
    defaultMessage: '场景管理',
  },
  'layout.statistics': {
    id: 'layout.statistics',
    defaultMessage: '能耗统计',
  },
  'layout.panel.management': {
    id: 'layout.panel.management',
    defaultMessage: '面板管理',
  },
  'layout.setting': {
    id: 'layout.setting',
    defineMessages: '设置',
  },
  'layout.setting.em': {
    id: 'layout.setting.em',
    defineMessages: 'EM设置',
  },
  'layout.setting.eg': {
    id: 'layout.setting.eg',
    defineMessages: 'EG设置',
  },
  'layout.light.manage': {
    id: 'layout.light.manage',
    defaultMessage: '面板管理',
  },
});

export const AREA_TYPE = 'AREA';
export const SENSOR_TYPE = 'SENSOR';
export const LIGHT_TYPE = 'LIGHT';
export const OTHERS_TYPE = 'OTHERS';
export const DIRECTION_NAME = {
  NORTH: 'n-resize', // 向上移动（北）
  EAST: 'e-resize', // 向右移动（东）
  SOUTH: 's-resize', // 向下移动（南）
  WEST: 'w-resize', // 向左移动（西）
  EAST_NORTH: 'ne-resize', // 向上及向右移动（北/东）
  EAST_SOUTH: 'se-resize', // 向下及向右移动（南/东）
  WEST_SOUTH: 'sw-resize', // 向下及向左移动（南/西）
  WEST_NORTH: 'nw-resize', // 向上及向左移动（北/西）
};

export const NUMBER = [
  '一',
  '二',
  '三',
  '四',
  '五',
  '六',
  '七',
  '八',
  '九',
  '十',
  '十一',
  '十二',
  '十三',
  '十四',
  '十五',
  '十六',
  '十七',
  '十八',
  '十九',
  '二十',
  '二十一',
  '二十二',
  '二十三',
  '二十四',
  '二十五',
  '二十六',
  '二十七',
  '二十八',
  '二十九',
  '三十',
  '三十一',
  '三十二',
];

export const temperatureBrightness = [20, 30, 40, 50, 60, 70, 80, 90, 100];

export const temperatureList = [
  { temperature: 2700, cw: 0 },
  { temperature: 3000, cw: 0.136097 },
  { temperature: 3500, cw: 0.322644 },
  { temperature: 4200, cw: 0.532655 },
  { temperature: 4700, cw: 0.659366 },
  { temperature: 5200, cw: 0.773172 },
  { temperature: 5700, cw: 0.875244 },
  { temperature: 6200, cw: 0.969104 },
  { temperature: 6500, cw: 1 },
];

// channelType
export const NORMAL = 'NORMAL'; // 亮度
export const TEMPERATURE = 'TEMPERATURE'; // 色温
export const COLOR = 'COLOR'; // 颜色

//  开关类型
/* eslint-disable */
export const channelType = [
  { type: 1, img: type1, render: Switch },
  { type: 2, img: type2, render: Slider },
];
/* eslint-enable */

export const PAD_STR = '****';

export const DEFAULT_IMGS = [
  {
    uid: '-1',
    name: '',
    status: 'done',
    // eslint-disable-next-line global-require
    url: xDenpao,
  },
  {
    uid: '-2',
    name: '',
    status: 'done',
    // eslint-disable-next-line global-require
    url: xHuiyi,
  },
  {
    uid: '-3',
    name: '',
    status: 'done',
    // eslint-disable-next-line global-require
    url: xDuihua,
  },
];
