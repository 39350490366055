import { ACCESS_TOKEN } from '@/utils/constant';

export default {
  namespace: 'appLoadData',
  state: {},
  reducers: {},
  effects: {
    *appSSO({ payload: { token } }, { put }) {
      sessionStorage.setItem(ACCESS_TOKEN, token);
      yield put({
        type: 'user/fetchCurrentUserInfo',
        payload: {
          refresh: false,
          sso: true,
        },
      });
    },
  },
  subscriptions: {
    set({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
        const user = sessionStorage.getItem('user');
        if (user) {
          if (pathname.endsWith('reserve')) {
            /* 受邀访客 */
            dispatch({ type: 'app/rows', rows: 10 });
            dispatch({ type: 'app/setReservationList', reservationList: [] });
            dispatch({ type: 'app/getReservationList', rows: 10 });
            dispatch({ type: 'app/getReservationSum' });
            dispatch({ type: 'app/getVstNotCfmCount' });
          } else if (pathname.endsWith('new')) {
            /* 新建预约 */
            dispatch({ type: 'app/fileList', fileList: [] });
            dispatch({ type: 'app/faceInfo', faceInfo: {} });
            dispatch({ type: 'app/associates', associates: [] });
            // dispatch({ type: 'app/getArea' });
            // dispatch({ type: 'app/meetingSwitch', meetingSwitch: false });
          } else if (pathname.endsWith('visit')) {
            /* 访问记录 */
            dispatch({ type: 'app/rows', rows: 10 });
            dispatch({ type: 'app/setVisitList', visitList: [] });
            dispatch({ type: 'app/getVisitList', rows: 10 });
            dispatch({ type: 'app/getReservationSum' });
            dispatch({ type: 'app/getVstNotCfmCount' });
          } else if (pathname.endsWith('confirm')) {
            dispatch({ type: 'app/setConfirmList', confirmList: [] });
            dispatch({ type: 'app/getConfirmData' });
            dispatch({ type: 'app/getArea' });
            dispatch({ type: 'app/meetingSwitch', meetingSwitch: false });
            dispatch({ type: 'app/getReservationSum' });
            dispatch({ type: 'app/getVstNotCfmCount' });
          } else if (pathname.endsWith('statisticalAnalysis')) {
            /* 统计分析加载数据 */
            dispatch({ type: 'app/getStatisticalAnalysis', payload: { type: 1 } });
            dispatch({ type: 'app/getReservationSum' });
            dispatch({ type: 'app/getVstNotCfmCount' });
          }
        } else if (query.token && query.tokenType) {
          dispatch({ type: 'appSSO', payload: { ...query } });
        }
      });
    },
  },
};
