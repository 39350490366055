export default {
  'loop.rule': '重复规则',
  'loop.reserve': '循环预订',
  'start.date': '开始日期',
  'booking.repeat.minutes': '分钟',
  'cycle.tab.loop': '循环，',
  'cycle.tab.loop.everyworkday': '每个工作日循环，',
  'cycle.tab.loop.everyday': '每{day}天循环一次，',
  'cycle.tab.loop.everyMonth': '每月第{day}天循环，',
  'cycle.tab.loop.text.label': '从{startTime}-{endTime}，{startDate}开始，循环{count}次',
  'cycle.tab.loop.text.label2': '从{startDate}开始，循环{count}次',
  'cycle.tab.loop.text.label.date': '从{startTime}-{endTime}，{startDate}开始，{endDate}结束',
  'cycle.tab.loop.text.label.date2': '从{startDate}开始，{endDate}结束',

  'cycle.tab.loop.month.week': '第{weekCount}个星期{week}',
  'cycle.tab.loop.month.week.last': '最后一个星期{week}',
  'cycle.tab.loop.month2.week': '每{freq}个月第{weekCount}个星期{week}',
  'cycle.tab.loop.month2.week.last': '每{freq}个月最后一个星期{week}',
  'cycle.tab.loop.year.date': '{month}月{day}日',
  'cycle.tab.loop.year2.date': '每年{month}月{day}日',
  'cycle.tab.loop.year.week': '{month}月的第{weekCount}个星期{week}',
  'cycle.tab.loop.year.week.last': '{month}月的最后一个星期{week}',
  'cycle.tab.loop.year2.week': '每年{month}月的第{weekCount}个星期{week}',
  'cycle.tab.loop.year2.week.last': '每年{month}月的最后一个星期{week}',

  meeting_cycle_rule_by_day: '每{freq}天循环1次',
  meeting_cycle_rule_by_week:
    '每{freq}个周的{weekStr}循环1次',
  meeting_cycle_rule_by_weekday:
    '每{freq}个工作日循环1次',
  meeting_cycle_rule_by_month:
    '每{freq}个月的第{mexrule}天循环1次',
  meeting_cycle_rule_by_month2:
    '每{freq}个月的最后一天循环1次',

  meeting_cycle_booking_error_tips: '重复结束时间不能早于会议开始时间 !',

  'meeting.booking.step.re_day': '日',
  'meeting.booking.step.every.workday': '个',
  'meeting.booking.step.rework_day': '工作日',
  'meeting.booking.step.re_week': '周',
  'meeting.booking.step.re_week_double': '两周',
  'meeting.booking.step.re_month': '月',
  'meeting.booking.step.re_year': '年',
  'meeting.booking.step.re_every': '每',
  'meeting.booking.step.re_every_md': '每月第',
  'meeting.booking.cycle.end': '结束重复',
  'meeting.booking.cycle.endDate': '结束时间',
  'meeting.booking.cycle.endTimes': '重复次数',
  'meeting.booking.cycle.error': '未选择会议室且当前用户未设置默认区域，请前往设置',
  meeting_cycle_booking_error_tips2: '预约次数至少1次！',
  meeting_poly_check_least_one:'至少选择一个视频会议类型！',
  meeting_cycle_fre_last_day: '最后1',
  meeting_cycle_modal_last_day: '最后1天',
  meeting_cycle_modal_title: '选择天',
};
