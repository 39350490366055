export default {
  'app.setSetting.lang': 'Set Language',
  'app.setSetting.timezone': 'Set Time Zone',
  'app.setSetting.zone': 'Default Zone',
  zh_CN: '简体中文',
  zh_TW: '繁體中文',
  en_US: 'English',
  'Asia/Shanghai': 'China Time',
  'Asia/Tokyo': 'Japan Time',
  'Asia/Seoul': 'Korea Time',
  'Asia/Singapore': 'Singapore Time',
  'Australia/Sydney': 'Australian Eastern Time (New South Wales)',
  'Europe/London': 'British Time',
  'Europe/Paris': 'Central European Time',
  'Europe/Moscow': 'Moscow Time',
  'America/New_York': 'Eastern Time',
  'America/Chicago': 'Central Time',
  'America/Denver': 'Mountain Time',
  'America/Los_Angeles': 'Pacific Time',
  'America/Anchorage': 'Alaska Time',
  'Pacific/Honolulu': 'Hawaii Time',
};
