import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import getAccountData from './service';

export default {
  namespace: 'account',
  state: {
    accountData: {
      balance: 0,
      credit: 0,
      locked: 0,
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getAccountData(_, { put, call }) {
      const result = yield call(getAccountData);
      if (result.code === 0) {
        yield put({
          type: 'save',
          payload: {
            accountData: result.data || '',
          },
        });
        // Toast.success(formatMessage({ id: 'common.action.success' }));
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
  },
  subscriptions: {},
};
