import { history } from 'umi';
import { Toast } from 'antd-mobile-v2';
// import queryString from 'query-string';
import { ChannelType } from '../../../utils/constant';
import { getPanel } from '../../../services/lightingPanel';
import {
  getSceneForPC,
  getSceneForDyNet,
  channelChangeForPC,
  colorChangeForDyNet,
  temperatureChangeForDyNet,
  brightnessChangeForDyNet,
  getChannelForId,
  setSceneForPCAll,
  setSceneData,
  setChannelData,
} from '../../../services/lightingControl';
import request, { getUrlParam } from '../../../utils/request';

const initState = {
  panelId: '',
  panel: {},
  dragging: true,
  query: {
    area: '',
    json: false,
  },
  sceneStatus: {},
  colorPicker: {},
  control: {},
};
export default {
  namespace: 'lightingControl',
  state: initState,
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // eslint-disable-next-line consistent-return
    *getPanel({ panelId }, { call, put }) {
      const { data, code, msg } = yield call(getPanel, { panelId });
      if (code !== 0) {
        return Toast.error(msg);
      }
      const panel = typeof data === 'string' ? JSON.parse(data) : data;
      const agent = navigator.userAgent;
      if (agent.indexOf('iPhone') > -1) {
        // 不能通过 || 来判断，因为ipad中也含有Mobile
        panel.type = '1';
      } else if (agent.indexOf('iPad') > -1) {
        panel.type = '2';
      } else if (agent.indexOf('Mobile') > -1) {
        panel.type = '1';
      } else {
        panel.type = '2';
      }
      panel.panelId = panelId;
      yield put({ type: 'setState', payload: { panel } });
    },
    *getSceneStatus({ query }, { call, put }) {
      let data = {};
      if (query.json) {
        // 处理网关
        data = yield call(getSceneForDyNet, { areaId: query.area });
      } else {
        data = yield call(getSceneForPC, { areaId: query.area });
      }
      yield put({ type: 'setState', payload: { sceneStatus: data } });
    },
    // eslint-disable-next-line consistent-return
    *setScene({ scene }, { call, put }) {
      const result = yield call(setSceneData, { aId: scene.aId, sId: scene.id });
      if (result.code !== 0) {
        return Toast.error(result.message);
      }
      yield put({ type: 'getAllStatus' });
    },
    // eslint-disable-next-line consistent-return
    *setChannel({ data }, { call, put }) {
      const result = yield call(setChannelData, {
        aId: data.aId,
        cId: data.id,
        channelLevel: data.num,
      });
      if (result.code !== 0) {
        return Toast.error(result.message);
      }
      yield put({ type: 'getAllStatus' });
      yield put({ type: 'getMapData' });
    },
    *setColorChannel({ payload }, { call, select, put }) {
      const { query, colorPicker, channelGroupId } = yield select((state) => state.lightingControl);
      if (query.area) {
        yield put({
          type: 'setState',
          payload: { colorPicker: { ...colorPicker, state: colorPicker.state === 1 ? 4 : 1 } },
        });
        yield call(query.json ? colorChangeForDyNet : channelChangeForPC, {
          ...payload,
          area: query.area,
        });
      }
      if (colorPicker.hasOwnProperty('channels')) {
        yield put({ type: 'getChannelForApp', channelId: channelGroupId });
      }
    },
    *setTemperatureChannel({ payload, isSlide, v }, { call, select, put }) {
      const { query, colorPicker, channelGroupId } = yield select((state) => state.lightingControl);
      if (query.area) {
        if (!isSlide) {
          const state = v || (colorPicker.state === 1 ? 4 : 1);
          yield put({ type: 'setState', payload: { colorPicker: { ...colorPicker, state } } });
        }
        // yield put({type: 'setState', payload: {colorPicker: {...colorPicker, state: colorPicker.state === 1 ? 4 : 1}}});
        yield call(query.json ? temperatureChangeForDyNet : channelChangeForPC, {
          ...payload,
          area: query.area,
        });
      }
      if (colorPicker.hasOwnProperty('channels')) {
        yield put({ type: 'getChannelForApp', channelId: channelGroupId });
      }
    },
    *setBrightnessChannel({ payload, isSlide, v }, { call, select, put }) {
      const { query, colorPicker, channelGroupId } = yield select((state) => state.lightingControl);
      if (query.area) {
        if (!isSlide) {
          const state = v || (colorPicker.state === 1 ? 4 : 1);
          yield put({ type: 'setState', payload: { colorPicker: { ...colorPicker, state } } });
        }
        // yield put({type: 'setState', payload: {colorPicker: {...colorPicker, state: colorPicker.state === 1 ? 4 : 1}}});
        yield call(query.json ? brightnessChangeForDyNet : channelChangeForPC, {
          ...payload,
          area: query.area,
        });
      }
      if (colorPicker.hasOwnProperty('channels')) {
        yield put({ type: 'getChannelForApp', channelId: channelGroupId });
      }
    },
    // eslint-disable-next-line consistent-return
    *getChannelForApp({ channelId }, { call, put }) {
      let data = {};
      const query = { json: false };
      if (Number(channelId) !== 0) {
        data = yield call(getChannelForId, { channelId });
        query.area = data.areaId;
        // if(data.hasOwnProperty('channels')) {
        //   data.channels[0].channelId = channelId;
        // }
      } else {
        query.area = getUrlParam('area') || '';
        const channel = getUrlParam('channel').split(',') || [];
        if (channel.length === 0 || !query.area) {
          // eslint-disable-next-line no-alert
          window.alert('请正确输入参数，格式为：/app/set/0?area={areaId}&channel={id1, id2}');
          return false;
        }
        if (channel.length === 1) {
          data.channelType = ChannelType.BRIGHTNESS;
        }
        if (channel.length === 2) {
          data.channelType = ChannelType.TEMPERATURE;
        }
        if (channel.length === 3) {
          data.channelType = ChannelType.COLOR;
        }
        data.channelIds = channel;
      }
      yield put({
        type: 'setState',
        payload: { colorPicker: data, query, channelGroupId: channelId },
      });
      yield put({ type: 'getSceneStatus', query });
    },
    *startScene({ area }, { call }) {
      yield call(setSceneForPCAll, { payload: { areaId: area, areaLevel: getUrlParam('p') || 2 } });
    },
    *init(_, { put, select }) {
      yield put({ type: 'setState', payload: { initState } });
      const { control } = yield select((state) => state.lightingControl);
      const panelId = control.pId;
      if (!panelId) {
        Toast.fail(`没有找到设置好的面板`);
        yield put({ type: 'setState', payload: { panel: {} } });
        setTimeout(() => {
          history.goBack();
        }, 200);
        return;
      }
      if (panelId === -1) {
        Toast.fail(`没有设置面板`, 3);
        yield put({ type: 'setState', payload: { panel: {} } });
        setTimeout(() => {
          history.goBack();
        }, 200);
        return;
      }

      yield put({ type: 'setState', payload: { panelId } });
      yield put({ type: 'getPanel', panelId });
    },
    // eslint-disable-next-line consistent-return
    *getAllStatus({ callBack }, { put, select }) {
      const area = yield select((state) => state.lighting.area);
      // console.log('111', area);
      // eslint-disable-next-line no-shadow
      const { code, message, data } = yield request(`/vc/ctrl/statusforms/${area.current.areaId}`);
      if (code !== 0) {
        return Toast.fail(message);
      }
      yield put({ type: 'setState', payload: { control: data } });
      if (callBack) callBack();
    },
    // eslint-disable-next-line consistent-return
    *getAllStatusEx({ areaId, callBack }, { put }) {
      // console.log('111');
      // eslint-disable-next-line no-shadow
      const { code, message, data } = yield request(`/vc/ctrl/statusforms/${areaId}`);
      if (code !== 0) {
        return Toast.fail(message);
      }
      yield put({ type: 'setState', payload: { control: data } });
      yield put({
        type: 'lighting/setState',
        payload: { area: { current: { areaId, areaName: areaId } } },
      });
      if (callBack) callBack();
    },
  },
  subscriptions: {
    setup({ history: umiHistory, dispatch }) {
      return umiHistory.listen(({ pathname, search }) => {
        if (pathname.indexOf('control') > -1) {
          if (sessionStorage.getItem('fromPage') === '/') {
            if (getUrlParam('area')) {
              // console.log(getUrlParam('area'));
              // dispatch({type:'setState',  payload: { area: {current:{areaId:Number(getUrlParam('area'))}}}});
              dispatch({
                type: 'getAllStatusEx',
                areaId: getUrlParam('area'),
                callBack: () => {
                  dispatch({ type: 'init', pathname, search, dispatch });
                },
              });
            } else {
              dispatch({
                type: 'getAllStatus',
                callBack: () => {
                  dispatch({ type: 'init', pathname, search, dispatch });
                },
              });
            }
          }
        }
        // const ua = navigator.userAgent.toLowerCase();
        // if(!ua || ua.indexOf("micromessenger") === -1) {
        //   dispatch(routerRedux.push(APP_PATH.error));
        //   return;
        // }
      });
    },
  },
};
