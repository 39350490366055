export default {
  'sws.special.booking': '确认预订',
  'sws.special.reset': '重置',
  'sws.special.booking.empty': '请选择要预订的工位',
  'sws.special.confirm.cancel': '确认取消',
  'sws.special.no.location': '请先选择区域',
  'sws.special.station.location.none': '您没有选择区域信息，请进行选择',
  'sws.special.station.stop.using': '结束使用',
  'sws.search.select.employee.name': '请输入姓名',
  'sws.special.station.user': '使用人',
  'sws.special.tip': '操作提示',
  'sws.special.tip.text': '是否取消预定该工位',
  'sws.special.yes': '是',
  'sws.special.no': '否',
  'sws.special.cancel': '取消工位',
  'sws.special.Back': '上一步',
  'sws.special.submit': '提交预订',
  'sws.special.search': '请输入关键字搜索',
  'sws.special.phone': '手机',
  'sws.special.phone.error': '手机格式不正确',
  'sws.special.external.user': '外部人员',
  'sws.special.input.content': '请输入内容',
  'timezone.name.title.simplified.chinese': '简体中文',
  401157: '外部访客姓名不能为空！',
  401149: "预订个数不能超过{value1}个"
};
