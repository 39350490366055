/**
 * Created by wang sen on 2017/7/6.
 */
import { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH, ACCESS_TOKEN } from '@/utils/constant';

/**
 * 获取微信JS-SDK密钥
 * @returns {{data, headers}|{data}|*}
 */
export const getJssdkTicket = () => {
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/wdfClient/enterpriseNumber/getTicket`, {
    method: 'POST',
    data: JSON.stringify({
      token: sessionStorage.getItem(ACCESS_TOKEN),
      urlPath: window.wechaturl ? window.wechaturl : window.location.href,
      // urlPath: 'https://sws5.rd.virsical.cn/app/workspace/station/facilityManage',
    }),
  });
};
