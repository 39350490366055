export default {
  'meeting.room.fee.name': '會議室名稱',
  'meeting.room.fee.location': '地點',
  'meeting.room.fee.time': '使用時間',
  'meeting.room.fee.payer': '代付人',
  'meeting.room.fee.payer.unset': '未指定',
  'meeting.room.fee.unit.price': '單價',
  'meeting.room.fee.unit0': ' /小時',
  'meeting.room.fee.unit1': '/平米',
};
