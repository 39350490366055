export default {
  'router.title.meeting.MyMeeting': '我的会议',
  'router.title.meeting.MeetingDetail': '会议详情',
  'router.title.meeting.DeviceTab': '会议设备',
  'router.title.meeting.SignTab': '会议签到',
  'router.title.meeting.Agenda': '会议议程',
  'router.title.meeting.SvcTab': '会议服务',
  'router.title.meeting.MeetingReceipt': '会议回执',
  'router.title.meeting.SubscribeDetail': '视频会议',
  'router.title.meeting.subdetail': '会议预订',
  'router.title.meeting.webexmeeting': '网络会议',
  'router.title.meeting.MeetingDelay': '延长会议',
  'router.title.meeting.RoomFeeDetail': '会议室详情',
  'router.title.meeting.SvcFeeDetail': '费用详情',
  'router.title.meeting.SvcApply': '会议服务',
  'router.title.meeting.PollingQuestion': '调查问卷',
  'router.title.meeting.MeetingContact': '添加外部参会人',
  'router.title.meeting.ExternalContact': '添加外部参会人',
  'router.title.meeting.ExternalPage': '选择外部参会人',
  'router.title.meeting.AttendeePage': '人员选择',
  'router.title.meeting.RoomList': '选择会议室',
  'router.title.meeting.RoomDetail': '会议室详情',
  'router.title.meeting.Subscribe': '选择会议室',
  'router.title.meeting.About': '关于',
  'router.title.meeting.HomePage': '智能会议',
  'router.title.meeting.cycle': '循环规则',
  'router.title.meeting.seat': '座位安排',
  'router.title.meeting.seat.member': '人员选择',
};
