export default {
  'service.err.200': '服务器成功返回请求的数据。',
  'service.err.201': '新建或修改数据成功。',
  'service.err.202': '一个请求已经进入后台排队（异步任务）。',
  'service.err.204': '删除数据成功。',
  // 'service.err.400': '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  // 'service.err.401': '由于您长时间没有操作或在其它地方登录后退出，用户会话已失效，请重新登录',
  // 'service.err.403': '用户得到授权，但是访问是被禁止的。',
  // 'service.err.404': '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  // 'service.err.406': '请求的格式不可得。',
  // 'service.err.410': '请求的资源被永久删除，且不会再得到的。',
  // 'service.err.422': '当创建一个对象时，发生一个验证错误。',
  // 'service.err.426': 'app.login.message-invalid-credential',
  'service.err.400': '错误码400，请联系管理员解决问题。',
  'service.err.401': '登录信息已过期或权限发生变化，请重新登录。',
  'service.err.403': '错误码403，请联系管理员解决问题。',
  'service.err.404': '错误码404，请联系管理员解决问题。',
  'service.err.406': '错误码406，请联系管理员解决问题。',
  'service.err.410': '错误码410，请联系管理员解决问题。',
  'service.err.422': '错误码422，请联系管理员解决问题。',
  'service.err.426': '错误码426，请联系管理员解决问题。',

  'service.err.500': '服务器发生错误，请检查服务器。',
  'service.err.502': '网关错误。',
  'service.err.503': '服务不可用，服务器暂时过载或维护。',
  'service.err.504': '网关超时。',
  100000: '系统异常',
  101000: '非法操作',
  101001: '参数不正确',
  101002: '退出失败，token为空',
  101003: '退出失败，token无效',
  101004: '操作失败，token为空',
  101005: '操作失败，token格式无效',
  101006: '操作失败，token无效',
  101007: '操作失败，refreshToken为空',
  101008: '操作失败，refreshToken无效',
  101009: '密码解密失败',
  101010: '账号格式不正确',
  101011: '通过refreshToken获取域信息失败',
  101012: '不支持认证模式',
  101013: '账号或密码错误，请重新输入',
  101014: '请求头中client信息为空',
  101015: '查询账号失败',
  101020: '上传license文件失败',
  101021: '上传license文件异常',
  101022: 'license与租户域不匹配',
  101023: 'license设备码校验失败',
  101024: 'license有效期校验失败',
  101025: '许可证不存在',
  101026: 'license功能未开启',
  101027: 'license文件名不正确',
  101028: '用户数量已超出限制',
  101029: '租户数量超出限制',
  101030: '验证码不能为空',
  101031: '验证码不合法',
  1010312: '验证码不正确',
  101032: '验证码不存在',
  101040: '签名或时间戳参数不存在',
  101041: '时间戳参数格式不正确',
  101042: '请求已过期',
  101043: '签名无效',
  101044: '当前用户无角色，请联系管理员',
  101045: '系统默认域不存在',
  101046: '手机号认证未开启',
  101047: '邮箱认证未开启',
  102000: '非法操作',
  102001: '参数不正确',
  102002: '添加部门对应租户不存在',
  102003: '当前部门下存在子部门，请先转移',
  102004: '保存部门失败',
  102005: '菜单含有下级不能删除',
  102006: '当前角色下存在用户，请先转移用户',
  102007: '只有平台管理员才能修改通用角色',
  102008: '租户不存在',
  102009: '不能删除自己',
  102010: '添加用户对应租户不存在',
  102011: '邮箱已存在',
  102012: '新密码与确认密码不一致',
  102013: '信息不存在',
  102014: '原密码错误',
  102015: '部门不存在',
  102016: '此微信已绑定过',
  102017: '此钉钉已绑定过',
  102018: '不支持除微信、钉钉以外其它绑定方式',
  102019: '此用户未绑定微信账号',
  102020: '此用户未绑定钉钉账号',
  102021: '威思客token无效',
  102022: '免密认证，不支持当前类型',
  102023: '免密认证，查询到多个用户',
  102024: '免密认证，未查询到用户',
  102025: '手机号已存在',
  102026: '用户不存在',
  102027: '平台管理员角色不允许删除',
  102030: '此角色标识已存在',
  102031: '此角色名称已存在',
  102032: '角色不存在',
  102033: '当前部门下存在用户，请先转移',
  102040: '此租户域已存在',
  102050: '空间层级超出限制',
  102051: '当前空间下存在子空间请先转移',
  102052: '保存空间失败',
  102053: '此空间没有绑定节假日',
  102054: '此空间不存在',
  102055: '部门管理员未设置',
  102056: '部门管理员不存在',
  102057: '当前租户已停用',
  102058: '系统异常',
  102059: '同一个部门下部门名称不能重复',
  102060: '此账号已存在',
  102061: '租户名称不能为空',
  102062: '租户名称长度超过50',
  102063: '租户英文名长度超过50',
  102064: '租户域不能为空',
  102065: '租户域长度超过50',
  102066: '租户状态不能为空',
  102067: '租户状态参数无效',
  102068: '部门名称不能为空',
  102069: '部门名称长度超过50',
  102070: '父部门id不能为空',
  102071: '父部门id无效',
  102072: '账号不能为空',
  102073: '账号长度超过50',
  102074: '手机号不能为空',
  102075: '部门不能为空',
  102076: '姓名不能为空',
  102077: '性别不能为空',
  102078: '邮箱不能为空',
  102079: '角色不能为空',
  102080: '菜单id不能为空',
  102081: '菜单id无效',
  102082: '菜单名称不能为空',
  102083: '父菜单id不能为空',
  102084: '菜单类型不能为空',
  102085: '角色名称不能为空',
  102086: '角色code不能为空',
  102087: '租户id不能为空',
  102088: '租户id参数无效',
  102089: '属性id不能为空',
  102090: '属性值不能为空',
  102091: '租户初始化保存租户失败',
  102092: '租户初始化保存部门失败',
  102093: '租户初始化保存部门关系失败',
  102094: '租户初始化保存用户失败',
  102095: '租户初始化保存用户角色关系失败',
  102096: '用户信息保存异常',
  102097: '用户信息更新异常',
  102098: '用户角色关系保存异常',
  102099: '批量保存用户异常',
  102100: '批量保存用户角色异常',
  102101: '属性code不存在',
  102102: '同一空间下空间名称不能重复',
  102103: '节假日不存在',
  102104: '菜单权限标识已存在',
  102105: '父菜单的权限标识为空，才能添加子菜单',
  102106: '邮箱账号不存在',
  102107: '链接已过期',
  102108: '设置密码失败',
  102109: '验证码无效',
  102110: '新密码和确认密码不一致',
  102111: '查询失败',
  102112: '查询用户失败',
  102113: '查询租户失败',
  102114: '可分配资源的最小空间',
  102115: '查询云服务菜单失败',
  102116: '密码包含弱密码，不符合强度要求',
  102117: '节点已禁用，无法编辑',
  102118: '系统中至少需要存在一位管理员用户',
  102119: '菜单ID已存在',
  102120: '父节点已禁用，无法添加子节点',
  102121: '该节假日已绑定空间树，无法删除',
  102122: '不能删除系统管理员',
  102123: 'ID列表不能为空',
  102124: '新部门ID不能为空',
  102125: '新密码不能与原密码相同',
  101048: '账号多次认证失败，锁定{lockTime}分钟',
  102126: '只能删除本地添加的用户',
  102127: '只能删除本地添加的部门',
  102128: '租户初始密码类型不能为空',
  102130: ' 路由名称不能为空',
  102131: ' 路由ID不能为空',
  102132: ' 断言不能为空',
  102133: ' 过滤器不能为空',
  102134: ' URL不能为空',
  102135: ' 断言需为json格式',
  102136: ' 过滤器需为json格式',
  102137: ' 路由id已存在',
  102138: ' 断言格式错误',
  102139: ' 过滤器格式错误',
  102140: '手机区号已存在',
  102141: '手机区号名称已存在',
  // 门禁
  601001: '获取门禁列表失败',
  601002: '门禁名称不能为空',
  601003: '该门禁已绑定分组，删除失败',
  601004: '删除门禁失败',
  601005: '请先配置门禁',
  601006: '模拟调用失败',
  601007: '获取门禁分组列表失败',
  601008: '门禁分组名称不能为空',
  601009: '分组编号已存在',
  601010: '删除门禁分组失败',
  601011: '签名认证失败',
  601012: '操作失败',
  601013: '操作成功',
  601014: '控制器序列号重复',
  601015: '控制器及逻辑门号不能重复绑定',
  601016: 'IDCardNo重复配置',
  601017: '该门禁产品不支持远程开门',
  101049: '结束时间早于开始时间',
  101050: '结束时间早于当前时间',
  101051: '未到开始时间',
  101052: '账号禁用',
  101053: '账号密码未激活',
  603001: '二维码不存在，请检查',
  603002: '该用户二维码使用次数已到最大',
  603003: '该用户二维码总有效期已到期，已清空该用户二维码记录',
  200005: '会议产品已过期',

  102164: '手机号重复，验证码发送失败，请联系管理员处理',
  102165: '手机号不存在',
  102166: '验证码错误或过期',
  102167: '验证码已发送，请勿频繁操作',
  102175: '用户已经绑定',
  102176: 'Teams租户不一致，请重新输入',
  102177: '用户绑定失败，请重新绑定或使用其他账号',

  1001023: '该邮箱已使用，请更换邮箱',
  1001027: '验证码无效',
  1001028: '验证码错误',
  1001031: '参数错误',
  1001074: '租户名已存在',
  1001083: '手机号已经被注册',
  1001084: '用户不存在',
  1001089: '邮箱不能为空',
  1001091: '租户不存在',
  1001092: '注册信息不存在，请重新授权',
  1001093: '免密授权失败',
  1001094: '公司已注册',

  1081008: '套餐不存在',
  1081009: '套餐已过期',

  106110: '用户绑定失败',
  106000: '系统异常',
  106520: '手机号在其它租户已绑定',
  106521: '邮箱在其它租户已绑定',
  106522: '飞书绑定的租户不一致',
  106523: '获取飞书企业信息失败',
  106524: '第三方用户检查失败',
  106525: '获取飞书用户信息失败',
  106526: '免密认证失败',
  106527: '获取飞书应用配置失败',
  106528: '飞书注册信息无效',

  106507: '请求企业微信接口超时，请登录或稍后重试',
  401150: '预约时长不在设置范围内',

  401151: '预订最大时长应不超过{value1}分钟',
  401152: '预订最小时长应为{value1}分钟',
  401002: '工位信息不存在',
  401092: '工位未绑定地图',
  401093: '工位已锁定',
  401095: '工位已锁定',
  102151: '新密码与历史密码重复',
  401333: '用户部门信息不存在',
  401097: '工位不是固定工位一人使用类型',
  401098: '工位{value1}未分配归属部门',

  401154: '您不支持跨天预订工位',
  401158: '不支持连续工作日使用相同工位，请选择新的工位',

  101054: '首次登录，请在PC端修改密码',
  101055: '密码过期，请修改密码',
  1001025: '许可证已过期',
  1001026: '许可数已达到上限',
  1001147: '已超过许可证上限，请重新开通或更新许可证',

  'FFA.101083': '请先通过PC端登录绑定认证器应用',

  102150: '密码不能包含用户名，电话，邮箱及邮箱前缀',


  /* 敏感词 */
  401507: '报修备注中出现不符合企业规范词汇，请重新审视并修改',
  401508: '申请工位备注中出现不符合企业规范词汇，请重新审视并修改',
  401509: '借用工位中出现不符合企业规范词汇，请重新审视并修改',
  401510: '踢座申请中出现不符合企业规范词汇，请重新审视并修改',
  401511: '踢座回复中出现不符合企业规范词汇，请重新审视并修改',
};
