import returnCode from '@/pages/Meeting/locales/en-US/returnCode';
import indexBottom from '@/pages/Meeting/locales/en-US/indexBottom';
import common from '@/pages/Meeting/locales/en-US/common';

export default {
  ...common,
  ...returnCode,
  ...indexBottom,
  'upload.success': 'Upload Successfully',
  'upload.fail': 'Upload Failed',
  home: 'Home',
  subscribe: 'Booking',
  confirm: 'Save',
  location: 'Room',
  subject: 'Subject',
  host: 'Host',
  applicants: 'Host',
  applicantsSelect: 'Please select',
  participants: 'Attendee(s)',
  time: 'Time',
  timeZone: 'Time zone',
  delete: 'Delete',
  back: 'Back',
  none: 'None',
  zoomMain: 'Main venue',
  zoomType: 'Zoom Meeting Type',
  zoomScale: 'Number of Attendee(s)',
  zoomType0: 'Online meeting',
  zoomType1: 'Zoom Video Webinar',
  zoomScale0: '100 attendees and 40 mins limit for free',
  zoomScale1: '< 100 attendee',
  zoomScale2: '< 300 attendee',
  zoomScale3: '< 500 attendee',
  zoomScale4: '< 1000 attendee',

  'meeting.notice.none': 'None',
  'meeting.notice.sms': 'SMS',
  'meeting.notice.email': 'Email',
  'meeting.notice.vsk': 'Virsical',
  'meeting.notice.wechat': 'Wechat',
  'meeting.notice.dingtalk': 'DingTalk',
  'meeting.device': 'Meeting Device(s)',
  'meeting.services': 'Service',
  'meeting.room.fee': 'Room charge',

  // 服务端返回key
  'msg.login.failed': 'Login failed!',
  'msg.login.success': 'Login successfully!',
  'result.back.sys.value3': 'Current user does not exist!',
  'result.back.sys.value6': 'This user is not the current domain user!',
  'result.back.device.error.value1': 'This user has no permission to update device status\\uFF01',
  'result.back.scanning.qr.lock.success': 'The meeting has been locked successfully!',
  'result.back.scanning.qr.unlock.success': 'The meeting has been unlocked successfully!',
  'result.back.scanning.qr.notice.success': 'Reminded successfully!',
  'result.back.sys.error': 'Operation Failure. Please Check the Parameter or Backend Log!',

  online_meeting_type: 'Meeting Type',

  main_meeting_zoom_poll: 'Polling',
  main_meeting_zoom_advance: 'Advanced options',
  main_meeting_zoom_wait: 'Enable waiting room',
  main_meeting_zoom_join: 'Enable join before host',
  main_meeting_zoom_mute: 'Mute attendees on entry',
  main_meeting_zoom_record: 'Automatically record meeting on the local computer',

  meeting_detail_zoom_participants: 'Participation Statistics',
  meeting_detail_zoom_polling: 'Polling Statistics',
  zoom_participants_label_device: 'Device',
  zoom_participants_label_ip: 'IP address',
  zoom_participants_label_location: 'Location',
  zoom_participants_during_minute: '{minute} minutes',
  zoom_participants_no_data:
    'The zoom account used in the meeting does not have the authority to query the statistics of attendee.',
  zoom_participant_info_pedding: 'The data is being counted, please try again later.',
  zoom_participant_info_ex: 'Data statistics abnormal, please contact the administrator.',
  zoom_participant_info_nostart: 'The zoom meeting is not yet on。',
  meeting_audit_accept_fail: 'The meeting has conflict, audit failed!',

  'meeting.booking.step.tencent.inner': 'Only internal attendees is allowed',
  'meeting.booking.step.tencent.before': 'Allow attendees to join the meeting before the host',
  meeting_tencent_no_resource: 'No Tencent meeting resources are available at the selected time!',
};
