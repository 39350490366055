import { ActionSheet, Toast } from 'antd-mobile-v2';
import { matchPath } from 'umi';
import { getUrlParam } from '../../utils/common';
import { formatMessage } from 'umi';

import { COMMA, MEETING_STATUS, MENU_PATH, RES_STATUS } from '@/pages/Meeting/utils/constant';
import * as myMeetingService from './service';

export default {
  namespace: 'myMeeting',
  state: {
    currentTimestamp: -1,
    messageNumber: 0,
    meetingNumber: {
      audit: 0,
      pending: 0,
      opened: 0,
    },
    filterName: '',
    isLoading: false,
    current: 1,
    pageSize: 10,
    meetings: [],
    LongMeetingShow: false,
    zoomData: {},
    filterData: {},
    selectedMenu: 0,
    // 是否跨天会议   0 非跨天会议，1跨天会议
    isPendingState: 0, //  服务是否确认 0待处理 1 已处理
    isLongState: 0,
    existFlyBook: true,
    rowMeetingId: 0,
    pageNumber: 1, // list 页数
    isFilter: false,
    myType: -1,
    startDate: null,
    endDate: null,
    controlTemplate: null,
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *taskByBusinessId({ bpmId, recurringId, processName }, { select, call, put }) {
      const { selectedMenu } = yield select((state) => state.myMeeting);
      const { code, msg } = yield call(myMeetingService.taskByBusinessId, {
        bpmId,
        recurringId,
        processName,
      });
      if (code === RES_STATUS.success) {
        yield put({ type: 'searchByParam', current: 1, selectedMenu, filterName: '' });
        Toast.success(formatMessage({ id: msg }));
      } else if (msg === '200999') {
        Toast.fail(formatMessage({ id: 'meeting_audit_accept_fail' }));
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *refuseByBusinessId(
      { param: { bpmId, recurringId, refuseReason, processName } },
      { select, call, put },
    ) {
      const { selectedMenu } = yield select((state) => state.myMeeting);
      const { code, msg } = yield call(myMeetingService.refuseByBusinessId, {
        bpmId,
        recurringId,
        remark: refuseReason,
        processName,
      });
      if (code === RES_STATUS.success) {
        yield put({ type: 'searchByParam', current: 1, selectedMenu, filterName: '' });
        Toast.success(formatMessage({ id: msg }));
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *goPolyPage({ meetingId }, { call }) {
      const { data } = yield call(myMeetingService.ifHasControlAuth, { meetingId });
      if (data) {
        const { data: data1 } = yield call(myMeetingService.goPolyPage, { meetingId });
        return data1;
      } else {
        Toast.fail(formatMessage({ id: 'meeting.poly.control.no.auth' }));
        return;
      }
    },
    *searchByParam(
      { current, selectedMenu, filterName, isLongState: isLong, nameKey },
      { select, put },
    ) {
      const { filterData, isLongState } = yield select((state) => state.myMeeting);
      filterData.theme = filterName || '';
      let long = isLongState;
      if (isLong != null) {
        long = isLong;
      }
      yield put({
        type: 'update',
        payload: { selectedMenu, filterData, filterName, isLongState: long },
      });
      if (nameKey === 'svcApproval') {
        yield put({ type: 'getSvcApprovalData', current });
      } else {
        yield put({ type: 'reload', current });
      }
    },
    *cancel({ param }, { select, call, put }) {
      const { selectedMenu } = yield select((state) => state.myMeeting);
      const { msg, code } = yield call(myMeetingService.cancelMeeting, param);
      if (code === RES_STATUS.success) {
        yield put({ type: 'searchByParam', current: 1, selectedMenu, filterName: '' });
        Toast.success(formatMessage({ id: msg }));
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *endMeeting({ meetingId }, { select, call, put }) {
      const { selectedMenu } = yield select((state) => state.myMeeting);
      const { msg, code } = yield call(myMeetingService.closeMeeting, { meetingId });
      if (code === RES_STATUS.success) {
        yield put({ type: 'searchByParam', current: 1, selectedMenu, filterName: '' });
        Toast.success(formatMessage({ id: msg }));
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *endCmma({ meetingId }, { call }) {
      yield call(myMeetingService.closeCmma, { meetingId });
    },
    *deleteMeeting({ meetingId }, { select, call, put }) {
      const { selectedMenu } = yield select((state) => state.myMeeting);
      const { msg, code } = yield call(myMeetingService.deleteMeeting, { meetingId });
      if (code === RES_STATUS.success) {
        yield put({ type: 'searchByParam', current: 1, selectedMenu, filterName: '' });
        Toast.success(formatMessage({ id: msg }));
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *signForZoom({ param }, { call }) {
      const result = yield call(myMeetingService.signForZoomFuc, { param });
      return result;
    },
    *findRecurringList({ recurringId }, { call }) {
      const { data } = yield call(myMeetingService.findMeetingsByRecurringId, { recurringId });
      return data;
    },
    *checkIsEditAll({ payload }, { call }) {
      const { data } = yield call(myMeetingService.cycleApi, payload);
      return data;
    },
    *getUserAgent({ payload }, { put }) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('larklocale') > -1) {
        yield put({
          type: 'update',
          payload: { existFlyBook: true },
        });
      }
    },
    *reload({ current, selectedMenu }, { select, call, put }) {
      const {
        pageSize,
        meetings,
        zoomData = {},
        isLoading,
        isLongState,
        startDate,
        endDate,
        myType,
      } = yield select((state) => state.myMeeting);
      if (!isLoading) {
        const selectedMenuState = yield select((state) => state.myMeeting.selectedMenu);
        const selMenu = selectedMenu || selectedMenuState;
        // console.log(selectedMenu, selectedMenuState, '11111');
        const { filterData } = yield select((state) => state.myMeeting);
        const newState = { current, isLoading: true };
        yield put({ type: 'update', payload: { newState, selMenu, filterData } });
        const { currentUser = {} } = yield select((state) => state.user);
        const params = {
          userId: currentUser.userId,
          current,
          size: pageSize,
          id: '',
          status: -1,
          name: filterData.theme || '',
          startDate,
          endDate,
          type: 1,
          myType,
          isLongState: filterData.theme ? -1 : isLongState,
        };
        // selectedMenu 0 新会议，1 历史会议 2 待审核
        switch (Number(selMenu)) {
          case 0:
            params.type = '1';
            params.status = [
              MEETING_STATUS.pending,
              MEETING_STATUS.opened,
              MEETING_STATUS.audit,
              MEETING_STATUS.progress,
              MEETING_STATUS.exAudit,
            ].join(COMMA);
            break;
          case 1:
            params.type = '2';
            break;
          case 2:
            params.type = '';
            params.status = MEETING_STATUS.audit;
            break;
          default:
            break;
        }
        const { data: responseData } = yield call(myMeetingService.getOpenedMeeting, params);
        const { pageData: meetingData, zoomData: newZoomData } = responseData;
        const { data: meetingNumber, time: currentTimestamp } = yield call(
          myMeetingService.getMyMeetingNumber,
        );
        let { records: newMeetings, size, total } = meetingData;
        const newPayload = { isLoading: false, currentTimestamp };
        if (meetingNumber) newPayload.meetingNumber = meetingNumber;
        newPayload.pageNumber = Math.ceil(total / size);
        if (current > 1) {
          if (newMeetings.length > 0) {
            newMeetings = meetings.concat(newMeetings);
            newPayload.current = current;
            newPayload.meetings = newMeetings;
            const tmpZoomData = newZoomData ? { ...zoomData, ...newZoomData } : zoomData;
            newPayload.zoomData = tmpZoomData || {};
          }
        } else {
          newPayload.current = current;
          newPayload.meetings = newMeetings;
          newPayload.zoomData = newZoomData || {};
        }
        yield put({ type: 'update', payload: newPayload });
      }
    },
    *getSvcApprovalData({ current = 1 }, { select, call, put }) {
      const { isPendingState, approvalData, filterName } = yield select((state) => state.myMeeting);
      const params = {
        page: {
          total: 0,
          pageSize: 10,
          current,
          orders: [
            {
              column: '',
              asc: true,
            },
          ],
        },
        filterName,
      };
      const {
        data: meetingNumber,
        time: currentTimestamp,
        code,
      } = yield call(myMeetingService.getMyMeetingNumber);
      const newPayload = { isLoading: false, meetings: [] };
      if (code === 0) {
        newPayload.meetingNumber = meetingNumber;
        newPayload.currentTimestamp = currentTimestamp;
      }
      let result = null;
      if (isPendingState === 0) {
        result = yield call(myMeetingService.bpmPending, params);
        const {
          data: { records: newApprovalData, current },
        } = result;
        newApprovalData.forEach((item) => {
          const processVariable = JSON.parse(item.processVariable);
          const { meeting } = processVariable;
          Object.assign(item, item, processVariable);
          Object.assign(item, item, meeting);
        });
        // console.log(newApprovalData, 'newApprovalData1');
        if (current > 1) {
          if (newApprovalData.length > 0) {
            newPayload.current = current;
            newPayload.approvalData = approvalData.concat(newApprovalData);
          }
        } else {
          newPayload.current = current;
          newPayload.approvalData = newApprovalData;
        }
        yield put({ type: 'update', payload: newPayload });
      } else if (isPendingState === 1) {
        result = yield call(myMeetingService.bpmApproved, params);
        const {
          data: { records: newApprovalData, current },
        } = result;
        newApprovalData.forEach((item) => {
          const processVariable = JSON.parse(item.processVariable);
          const { meeting } = processVariable;
          Object.assign(item, item, processVariable);
          Object.assign(item, item, meeting);
        });
        // console.log(newApprovalData, 'newApprovalData2');
        if (current > 1) {
          if (newApprovalData.length > 0) {
            newPayload.current = current;
            newPayload.approvalData = approvalData.concat(newApprovalData);
          }
        } else {
          newPayload.current = current;
          newPayload.approvalData = newApprovalData;
        }
        yield put({ type: 'update', payload: newPayload });
      }
    },
    *findMeetingInfoById({ payload }, { call }) {
      const result = yield call(myMeetingService.findMeetingInfoById, payload);
      return result || {};
    },
    // 会议锁定解锁
    *setRedStatus(params, { select, call, put }) {
      const { selectedMenu } = yield select((state) => state.myMeeting);
      const { msg, code } = yield call(myMeetingService.setRedStatus, params);
      if (code === RES_STATUS.success) {
        yield put({ type: 'searchByParam', current: 1, selectedMenu, filterName: '' });
        Toast.success(formatMessage({ id: msg }));
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *getTemp({ roomId }, { call }) {
      const { code, data } = yield call(myMeetingService.getTemp, roomId);
      if (code === 0) {
        const { controlTemplate } = data[0] || {};
        return controlTemplate;
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.myMeeting)) {
          const { query } = history.location;
          const { type = 0, nameKey } = query || {};
          dispatch({
            type: 'searchByParam',
            current: 1,
            selectedMenu: type,
            filterName: '',
            nameKey,
          });
          const mId = getUrlParam('mId');
          if (mId) {
            dispatch({
              type: 'update',
              payload: { rowMeetingId: mId },
            });
            history.replace({
              search: '',
            });
          }
        } else {
          ActionSheet.close(); // 强制关闭首页操作面板
        }
      });
    },
  },
};
