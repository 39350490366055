export default {
  map_room_detail_basic_title: '基本信息',
  map_room_detail_book_title: '我的预订',
  map_room_detail_book_btn: '预订会议',
  map_room_detail_go_btn: '去这里',
  map_room_detail_view_btn: '查看位置',
  map_room_detail_more_btn: '更多信息 >',
  'meeting.calendar.need_audit_map': '审批',
  map_index_meeting_tab_meeting_list_title: '预订记录',
  'map_indexbooking.meeting.romm.Authority': '你无权使用该会议室,请选择新的会议室！',
};
