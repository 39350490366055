/**
 * Created by XuQiang on 2017/6/7.
 */

export default {
  'visit.visitor.name': '訪客姓名：',
  'visit.visitor.company': '訪客公司：',
  'visit.visitor.telephone': '訪客電話：',
  'visit.visitor.email': '訪客郵箱：',
  'visit.visitor.cause': '訪問事由：',
  'visit.visitor.time': '訪問時間：',
  'visit.visitor.time_': '訪問時間',
  'visit.visitor.status': '訪問狀態：',
  'visit.visitor.status_': '訪問狀態',
  'visit.visitor.leave': '離開時間：',
  'visit.visitor.accompany': '隨行人員',
  'visit.visitor.status.0': '未開始',
  'visit.visitor.status.1': '未到訪',
  'visit.visitor.status.2': '已簽到',
  'visit.visitor.status.3': '已簽出',
  'visit.visitor.status.4': '已取消',
  'visit.visitor.status.5': '待審批',
  'visit.visitor.status.6': '已拒絕',
  'visit.visitor.status.7': '已過期',
  'visit.visitor.status.8': '處理中',
  'visit.visitor.status.9': '系統簽出',
  'visit.visitor.status.10': '失败',
  'visit.visitor.status.11': '已同意',
  'visit.visitor.status.12': '審批中',
  'visit.status.0.tip': '未到訪客來訪時間或未超過來訪當天',
  'visit.status.1.tip': '截止離開時間當天還未到訪',
  'visit.status.2.tip': '已完成簽到',
  'visit.status.3.tip': '已完成簽出',
  'visit.status.4.tip': '已取消邀請',
  'visit.status.5.tip': '需要員工審批的訪問',
  'visit.status.6.tip': '已被員工拒絕的訪問',
  'visit.status.7.tip': '超過離開時間未審批',
  'visit.status.8.tip': '第三方集成還在處理中',
  'visit.status.9.tip': '已在系統設置時間自動簽出',
  'visit.status.10.tip': '第三方集成處理失敗',
  'visit.status.11.tip': '已同意來訪',
  'visit.status.12.tip': '審批流程尚未結束，還需其他人員審批。',
  'visit.visitor.status.unConfirm': '待審批',
  'visit.visitor.status.Confirmed': '已確認',
  'visit.visitor.status.reject': '已拒絕',
  'visit.visitor.status.expired': '已過期',
  'visit.visitor.operation': '操作',
  'visit.visitor.detail': '詳情及操作',
  'visit.visitor.info.search': '資訊搜索',
  'visit.visitor.search': '查詢',
  'visit.visitor.sign.out': '簽出',
  'visit.visitor.view': '訪問詳情',
  'visit.visitor.tip1': '如何創建第一個訪客',
  'visit.visitor.tip2': '1、您可以通過iPad APP Store搜索訪客管理下載訪客終端應用',
  'visit.visitor.tip3': '2、在PC端管理介面中創建終端管理員，使用終端管理員登錄訪客iPad終端',
  'visit.visitor.tip4': '3、登記您自己的資訊，創建第一個訪客！',
  'visit.visitor.export': '導出',
  'visit.visitor.certNumber': '訪客身份證號：',
  'visit.visitor.visit.time': '到訪時間',
  'visit.visitor.visit.invitationCode': '邀請碼',
  'visit.visitor.visit.beizhu': '備註',
  'visit.visitor.visit.end.time': '離開時間',

  'visit.reserve.across.title': '跨天邀請',
  'visit.selfFlag.0.tip': '員工主動邀請訪客來訪的',
  'visit.selfFlag.1.tip': '訪客通過小程序、公眾號、自助預約進行登記訪問的',
  'visit.selfFlag.2.tip': '通過訪客終端（Pad、一體機等）登記或企業前臺登記的臨時來訪的訪客',
  'visit.selfFlag.3.tip': '員工通過“團體邀請”菜單邀請的和訪客通過員工分享鏈接自行登記的',
  'visit.reserve.book.lockers.title': '預訂儲物櫃',
  'visit.reserve.book.lockers.title.tip': '訪客可使用訪客專屬儲物櫃',

  'visit.visitor.date.picker.unit.year': '年',
  'visit.visitor.date.picker.unit.month': '月',
  'visit.visitor.date.picker.unit.day': '日',
  'visit.visitor.date.picker.unit.hour': '時',
  'visit.visitor.date.picker.unit.minute': '分',
};
