export default {
  'cloud.background.management.title': '在電腦上登錄管理後台',
  'cloud.background.management.tip.1': '更方便地管理企業通訊錄',
  'cloud.background.management.tip.2': '更靈活地處理工作需求',
  'cloud.background.management.tip.smt': '更容易的維護會議室',
  'cloud.background.management.tip.vst': '更容易的維護訪客接待地',
  'cloud.background.management.tip.sws': '更容易的維護工位和地圖',
  'cloud.background.management.tip.3': '更豐富的企業數據統計',
  'cloud.background.management.tip.4': '更多的個性化設置',
  'operate.my': '我的信息',
  'operate.admin': '後台管理',
  'operate.scan': '掃一掃',
  'operate.logout': '退出',
}
