export default {
  index_bottom_1: '功能特點',
  index_bottom_2: '日曆',
  index_bottom_3: '會議室預訂',
  index_bottom_4: '網路會議',
  index_bottom_5: '電話會議',
  index_bottom_6: '客戶化設置',
  index_bottom_7: '用戶管理',
  index_bottom_8: '統計報表',
  index_bottom_9: '移動端應用',
  index_bottom_10: '產品體驗',
  index_bottom_11: '增值服務',
  index_bottom_12: '會議展板',
  index_bottom_13: '企業V話',
  index_bottom_14: '視頻會議',
  index_bottom_15: '硬體設備基礎',
  index_bottom_16: '價格',
  index_bottom_17: '免費版',
  index_bottom_18: '標準版',
  index_bottom_19: '增強版',
  index_bottom_20: '關於我們',
  index_bottom_21: '關於公司',
  index_bottom_22: '技術支持',
  index_bottom_23: '媒體報導',
  index_bottom_24: '聯繫我們',
  index_bottom_25: '掃一掃關注威思客公眾號',
  index_bottom_26: '公 司：北京威發新世紀資訊技術有限公司',
  index_bottom_27: '地 址：北京市東城區長安街1號東方廣場東二辦公樓19層1907-1909',
  index_bottom_28: '售前諮詢：400-685-3160',
  index_bottom_29: '郵 箱：sales@virsical.com',
  index_bottom_30: '2000-2016威發系統有限公司',
  index_bottom_31: '北京威發新世紀資訊技術有限公司',
  index_bottom_32: '推薦使用穀歌、火狐、IE10以上版本流覽器，解析度1280*800及以上。',
  'menu.meeting.meeting_app': '智能會議',
  'menu.meeting.subscribe': '會議預訂',
  'menu.meeting.mines': '會議列表',
  'menu.meeting.webexmeeting': '在線會議',
  'menu.meeting.videomeeting': '視頻會議',
};
