import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';
import { matchPath, history } from 'umi';
import { MENU_PATH } from '@/pages/Meeting/utils/constant';
import { getMeetingDetail, updateRoomPayer } from './service';

export default {
  namespace: 'roomFeeDetail',
  state: {
    meetingId: 0,
    meetingDetail: {},
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *reload({ meetingId }, { select, call, put }) {
      const { messages } = yield select((state) => state.i18n);
      const { data, code, msg = '' } = yield call(getMeetingDetail, meetingId);
      if (code === 0) {
        yield put({ type: 'update', payload: { meetingDetail: data } });
      } else {
        Toast.fail(messages[msg]);
      }
    },
    *updateRoomPayer({ payload }, { select, call, put }) {
      const { roomId, payer } = payload;
      const { meetingId } = yield select((state) => state.roomFeeDetail);
      const { code, msg = '' } = yield call(updateRoomPayer, {
        meetingId,
        roomId,
        roomPayer: payer,
      });
      if (code === 0) {
        history.go(-1);
        yield put({ type: 'reload', meetingId });
        Toast.success(formatMessage({ id: '601013' }));
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.roomFeeDetail)) {
          const { query } = history.location;
          const { meetingId } = query || {};
          dispatch({ type: 'update', payload: { meetingId } });
          dispatch({ type: 'reload', meetingId });
        }
      });
    },
  },
};
