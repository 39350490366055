/**
 *  会议模板
 */
import { matchPath, history } from 'umi';
import _ from 'lodash';
import { formatMessage, getLocale } from 'umi';
import { Toast } from 'antd-mobile-v2';
import * as attendeeService from '@/pages/Meeting/services/attendee';
import { CONTACT_PAGE_FROM, ENGLISH, MENU_PATH, UserColor } from '../utils/constant';
import { handleDeptTree, findDepDataByName, getSliceArray, getAttendStatus } from '../utils/common';
import moment from 'moment';
let initState = {};
export default {
  namespace: 'attendee',
  state: {
    userMap: {},
    usersInfo: [],
    selectedUser: [],
    pageFrom: CONTACT_PAGE_FROM.temp_detail_holder,
    searchUsers: [],
    allUsers: [],
    deptTree: [],
    externalUsers: [],
    originalExternalUsers: [],
    selectedExternal: [],
    currentRoomId: '',
    tenantUserVersion: 0,
    frequentUser: [],
    frequentUsers: [],
    frequentUserList: [],
    editUser: {},
    newHostids: '',
    disableOuter: [],
    agendaId: '',
    agendaUsers: [],
    selectUser: [],
    canSplit: true,
    svcSelectedUser: [],
    userData: [],
    userType: 0,
    showReply: false,
    serachParams: {
      nameKey: '',
      current: 1,
      size: 10,
    },
    areaCode: [],
    allUserCount: 0, // 人数总数
    isDeleteUser: false, // 判断是否处于删除参会人
  },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload };
    },
    setOriginalExternalUsers(state) {
      return { ...state, externalUsers: state.originalExternalUsers };
    },
    setSerachParams(state, { payload }) {
      return { ...state, serachParams: { ...state.serachParams, ...payload } };
    },
    reset(state) {
      console.log(initState, '===initState==');
      if (Object.keys(initState).length > 0) {
        return initState;
      }
      return state;
    },
  },
  effects: {
    *resetState(Place, { select }) {
      const state = yield select((state) => state.attendee);
      initState = _.cloneDeep(state);
      console.log(initState, '===initState==');
    },
    *getAllDepts(_, { call, put }) {
      const { data, code } = yield call(attendeeService.getAllDepartment);
      if (code === 0 && data) {
        const d = handleDeptTree(data);
        yield put({ type: 'set', payload: { deptTree: d } });
        return d;
      }
      return [];
    },
    *loadAllUsers(_, { select, put, call }) {
      const { tenantId } = yield select((state) => state.user.currentUser);
      const { tenantUserVersion, allUsers } = yield select((state) => state.attendee);
      if (allUsers.length > 0 || !tenantId) return;
      const { data } = yield call(attendeeService.findUserVersion);
      if (tenantUserVersion === 0 || Number(data) === 0 || tenantUserVersion !== Number(data)) {
        yield put({
          type: 'set',
          payload: {
            tenantUserVersion: Number(data),
          },
        });
        const {
          code,
          data: { ds, us, allUserCount = 0 },
          msg,
        } = yield call(attendeeService.loadAllUser, { tenantId });
        // 按节点分个类
        const userMap = {};
        if (code === 0) {
          const map = new Map();
          if (ds) {
            ds.forEach((e) => {
              map.set(e.d, { deptPath: e.p, deptName: e.n });
            });
          }
          const allUser = [];
          if (us) {
            us.forEach((e) => {
              if (e) {
                let dept = {};
                if (map.has(e.d)) {
                  dept = map.get(e.d);
                }
                const r = Number(e.u) % 6;
                const color = UserColor[r];
                const user = {
                  userId: e.u,
                  deptId: e.d,
                  deptPath: dept.deptPath,
                  deptName: dept.deptName,
                  realName: e.r,
                  realNameEn: e.e,
                  email: e.email,
                  isFre: e.isFre,
                  color,
                };
                allUser.push({ ...e, deptPath: dept.deptPath, deptName: dept.deptName, color });
                if (userMap[`${user.deptPath}`]) {
                  userMap[`${user.deptPath}`].push(user);
                } else {
                  userMap[`${user.deptPath}`] = [user];
                }
              }
            });
          }
          yield put({ type: 'set', payload: { allUsers: allUser ,allUserCount: allUserCount+1} });
          yield put({ type: 'set', payload: { userMap } });
        } else {
          const { messages } = yield select((state) => state.i18n);
          Toast.fail(messages[msg]);
        }
      }
    },
    *loadFrequentUsers(_, { put, call, select }) {
      const { allUsers, frequentUserList } = yield select((state) => state.attendee);
      // if (frequentUserList?.length > 0) {
      //   return;
      // }
      const { code, data, msg } = yield call(attendeeService.getFrequentUsers, {
        current: 1,
        size: 1000,
        name: '',
      });
      if (code === 0) {
        const frequentUserList = data?.map((item) => {
          const u = allUsers.find((a) => Number(a.u) === item.userId);
          if (u) {
            return {
              ...item,
              color: u.color,
            };
          }
          return item;
        });
        yield put({
          type: 'set',
          payload: { frequentUsers: data?.records || [], frequentUserList },
        });
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *loadAllExtranls(_, { put, call }) {
      const { code, data, msg } = yield call(attendeeService.getExternalList);
      if (code === 0) {
        // 给人员添加随机颜色
        const array = data.map((item) => {
          // 用userId 取余
          const r = item.id % 6;
          return { ...item, color: UserColor[r] };
        });
        // 手机号超过20位不在前端展示，只在后端存储
        array.forEach(item=>{
          if(item?.mobile.length>19){
            item.mobileTemp = item.mobile
            item.mobile = ''
            item.phoneTemp = item.phone
            item.phone = ''
          }
        })
        yield put({ type: 'set', payload: { externalUsers: array, originalExternalUsers: array } });
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *beforeAgendaUser({ payload: { agendaId, users, selectUser } }, { put }) {
      yield put({ type: 'set', payload: { agendaId, agendaUsers: users, selectUser } });
    },
    *beforeLoadAttendUser({ payload: { userData, userType, showReply } }, { put }) {
      yield put({ type: 'set', payload: { userData, userType, showReply } });
    },
    *beforeLoad(
      { payload: { userIdStr = '', roomIdStr = 'allOrNone', pageFrom, canSplit } },
      { put, select, take },
    ) {
      const {
        roomPlan = [],
        isAllUser,
        hostId,
        members,
        externals,
        selectedExternal: subSelectedExternal,
        selectedMembers,
      } = yield select((state) => state.subscribe);
      const allHostArrId = []; // 除去该会议室主持人外的已选所有人id(不可选的用户)
      const allUserArrId = []; // 除去该会议室参会人外的已选所有人id(不可选的用户)
      const outerDisable = []; //   其他会议室已选的外部参会人(不可选的用户)
      const newSelectedExternal = []; // 外部参会人（访问邀约）
      if (canSplit && roomPlan && roomPlan.length) {
        roomPlan.forEach((ritem) => {
          const { hostUser = [], selectUsers = [], externalUsers = [] } = ritem;
          if (ritem.roomId !== roomIdStr) {
            hostUser.forEach((hitem) => {
              allHostArrId.push(hitem.userId);
              allUserArrId.push(hitem.userId);
            });
            selectUsers.forEach((sitem) => {
              allHostArrId.push(sitem.userId);
              allUserArrId.push(sitem.userId);
            });
            externalUsers.forEach((eitem) => {
              outerDisable.push(eitem.id);
            });
          }
          if (ritem.roomId === roomIdStr) {
            hostUser.forEach((hitem2) => {
              allUserArrId.push(hitem2.userId);
            });
            selectUsers.forEach((sitem2) => {
              allHostArrId.push(sitem2.userId);
            });
            externalUsers.forEach((nrmeitem) => {
              newSelectedExternal.push(nrmeitem);
            });
          }
        });
      } else if (!canSplit && roomPlan && roomPlan.length) {
        if (pageFrom === CONTACT_PAGE_FROM.subscribe_member) {
          // 不可选参会人---其他会议室的参会人及合并的主持人
          if (String(hostId)) {
            String(hostId)
              .split(';')
              .forEach((hostitem) => {
                allUserArrId.push(hostitem);
              });
          }
          roomPlan.forEach((room2) => {
            if (room2.roomId !== roomIdStr) {
              room2.selectUsers.forEach((select) => {
                allUserArrId.push(select.userId);
              });
            }
          });
        } else if (pageFrom === CONTACT_PAGE_FROM.subscribe_holder) {
          // 不可选主持人---所有会议室的参会人
          roomPlan.forEach((room2) => {
            room2.selectUsers.forEach((select) => {
              allHostArrId.push(select.userId);
            });
          });
        } else if (pageFrom === CONTACT_PAGE_FROM.subscribe_outer_member) {
          roomPlan.forEach((ritem) => {
            if (ritem.roomId !== roomIdStr) {
              ritem.externalUsers.forEach((eitem) => {
                outerDisable.push(eitem.id);
              });
            }
            if (ritem.roomId === roomIdStr) {
              ritem.externalUsers.forEach((nrmeitem) => {
                newSelectedExternal.push(nrmeitem);
              });
            }
          });
        }
      }
      let newHostids = '';
      if (pageFrom === CONTACT_PAGE_FROM.subscribe_member) {
        newHostids = roomIdStr === 'allOrNone' ? hostId : allUserArrId.join(';');
        yield put({ type: 'set', payload: { newHostids } });
      } else if (pageFrom === CONTACT_PAGE_FROM.subscribe_holder) {
        newHostids = roomIdStr === 'allOrNone' ? members : allHostArrId.join(';');
        yield put({ type: 'set', payload: { newHostids, canSplit } });
      } else if (pageFrom === CONTACT_PAGE_FROM.subscribe_outer_member) {
        const temdisableOuter = roomIdStr === 'allOrNone' ? externals : outerDisable;
        yield put({
          type: 'set',
          payload: { disableOuter: roomPlan && roomPlan.length ? temdisableOuter : [] },
        });
      }
      if (isAllUser === 1) {
        yield put({ type: 'set', payload: { newHostids: '', disableOuter: [] } });
      }

      if (pageFrom === CONTACT_PAGE_FROM.subscribe_member) {
        yield put({
          type: 'set',
          payload: { pageFrom: String(pageFrom), currentRoomId: roomIdStr },
        });
        window.history.pushState({ title: 'title', url: '#' }, 'title', '#');
        yield put({ type: 'subscribe/update', payload: { showAttendee: true } });
      } else if (pageFrom === CONTACT_PAGE_FROM.subscribe_holder) {
        yield put({
          type: 'set',
          payload: { pageFrom: String(pageFrom), currentRoomId: roomIdStr },
        });
        window.history.pushState({ title: 'title', url: '#' }, 'title', '#');
        yield put({ type: 'subscribe/update', payload: { showAttendee: true } });
      } else if (pageFrom === CONTACT_PAGE_FROM.subscribe_outer_member) {
        yield put({ type: 'set', payload: { currentRoomId: roomIdStr } });
        window.history.pushState({ title: 'title', url: '#' }, 'title', '#');
      } else if (pageFrom === CONTACT_PAGE_FROM.manage_service_payer) {
        window.history.pushState({ title: 'title', url: '#' }, 'title', '#');
        yield put({ type: 'set', payload: { currentRoomId: roomIdStr } });
      } else if (pageFrom === CONTACT_PAGE_FROM.manage_room_payer) {
        window.history.pushState({ title: 'title', url: '#' }, 'title', '#');
        yield put({ type: 'set', payload: { currentRoomId: roomIdStr } });
      } else if (pageFrom === CONTACT_PAGE_FROM.room_detail_payer) {
        window.history.pushState({ title: 'title', url: '#' }, 'title', '#');
        yield put({ type: 'set', payload: { currentRoomId: roomIdStr } });
      } else if (pageFrom === CONTACT_PAGE_FROM.room_service_payer) {
        window.history.pushState({ title: 'title', url: '#' }, 'title', '#');
        yield put({ type: 'set', payload: { currentRoomId: roomIdStr } });
      }

      if (pageFrom === CONTACT_PAGE_FROM.subscribe_outer_member) {
        yield put({ type: 'loadAllExtranls' });
        yield take('loadAllExtranls/@@end');
        const users = [];
        const userIds = userIdStr.split(';');
        const { externalUsers } = yield select((state) => state.attendee);
        userIds.forEach((id) => {
          const tempUser = externalUsers.find((tUser) => String(id) === String(tUser.id));
          const findSelectedExternal =
            roomPlan && roomPlan.length ? newSelectedExternal : subSelectedExternal;
          const selectUser = findSelectedExternal.find((sUser) => String(id) === String(sUser.id));
          if (tempUser) {
            if (selectUser) {
              tempUser.invite = selectUser.invite;
            }
            //   canntOperate: 是否临时访客
            users.push({
              ...tempUser,
              canntOperate: findSelectedExternal?.some(
                (o) => String(o.id) === String(id) && o.canntOperate,
              ),
            });
          }
        });
        yield put({ type: 'set', payload: { selectedExternal: users } });
      } else {
        yield put({ type: 'loadAllUsers' });
        yield take('loadAllUsers/@@end');
        const users = [];
        const cloneUser = _.cloneDeep(users);
        const { userMap, selectedUser, frequentUserList } = yield select((state) => state.attendee);
        const userIds = userIdStr.split(';');
        userIds.forEach((userId) => {
          let allUsers = [];
          Object.keys(userMap).forEach((key) => {
            allUsers = allUsers.concat(userMap[key]);
          });
          const user = allUsers.find((tempUser) => String(userId) === String(tempUser.userId));
          if (user) {
            users.push(user);
          }
          if (frequentUserList?.length>0 && !user && userId) {
            // allUsers 是查询所有有部门的，导致常用联系人里无部门的用户匹配不到，所以执行以下逻辑
            const userFrequent = frequentUserList?.find((tempUser) => String(userId) === String(tempUser.userId));
            if(userFrequent){
              users.push(userFrequent);
            }
          }
          // 将无部门的用户添加到参会人列表
          // if (selectedMembers.length > 0) {
          //   const noDepUser = selectedMembers?.find((tempUser) => {
          //     return String(userId) === String(tempUser.userId) && !tempUser?.deptId;
          //   });
          //   if (noDepUser) {
          //     users.push(noDepUser);
          //   }
          // }
          // 处理无部门的用户
          if (selectedUser.length > 0) {
            const noDepUser = selectedUser?.find((tempUser) => {
              return String(userId) === String(tempUser.userId) && !tempUser?.deptId;
            });
            const userIdList = users?.map(item=>{
              return item?.userId || ''
            })
            if (noDepUser && noDepUser?.userId && userIdList?.indexOf(noDepUser?.userId) < 0) {
              users.push(noDepUser);
            }
          }
        });
        yield put({
          type: 'set',
          payload: { selectedUser: users, initSelectUserIds: userIdStr, svcSelectedUser: users },
        });
      }
    },
    *searchUserOnline(_, { select, put, call }) {
      const { deptTree, searchUsers, serachParams } = yield select((state) => state.attendee);
      const { nameKey } = serachParams;
      if (nameKey) {
        let searchUserList = [];
        serachParams.nameKey = nameKey?.trim()
        const { code, data, msg } = yield call(attendeeService.getUserList, serachParams);
        if (code === 0) {
          const searchDepList = findDepDataByName(deptTree, nameKey);
          const userDataOfColor = data.records.map((item) => {
            const r = item.userId % 6;
            return { ...item, color: UserColor[r] };
          });
          if (serachParams.current === 1) {
            searchUserList = userDataOfColor;
          } else {
            searchUserList = searchUsers.concat(userDataOfColor);
          }
          if (serachParams.current === data.pages || data.pages === 0) {
            console.log(searchDepList, '+==searchDepList===');
            searchUserList = searchUserList.concat(searchDepList);
          }
          yield put({ type: 'set', payload: { searchUsers: searchUserList } });
          return { searchUserList, pages: data.pages };
        } else {
          Toast.fail(formatMessage({ id: msg }));
        }
      }
      return { page: 0 };
    },
    *searchExternalOnline({ payload }, { select, put }) {
      const { nameKey } = payload;
      if (nameKey) {
        const { originalExternalUsers = [] } = yield select((state) => state.attendee);
        const commonKey = nameKey.trim().toLowerCase().replace(/\s/g, '');
        // console.log(originalExternalUsers,"==",commonKey)
        const newExternalUsers = originalExternalUsers.filter(
          (u) =>
            u.name &&
            String(u.name).trim().toLowerCase().replace(/\s/g, '').indexOf(commonKey) > -1,
        );

        yield put({ type: 'set', payload: { externalUsers: newExternalUsers } });
      }
    },
    *submitSelectUser({ isDetial = false, isGetStatus = false }, { put, select }) {
      const {
        hostId,
        members,
        meetingUserSeat = [],
        roomPlan = [],
        meetingId,
      } = yield select((state) => state.subscribe);
      let { selectedUser, allUsers, isDeleteUser = false } = yield select((state) => state.attendee);
      const { pageFrom, currentRoomId, canSplit } = yield select((state) => state.attendee);
      selectedUser = [].concat(selectedUser);
      if (Number(pageFrom) === CONTACT_PAGE_FROM.subscribe_member) {
        if (hostId) {
          const hostArray = String(hostId).split(';');
          hostArray.forEach((hId) => {
            const index = selectedUser.findIndex((su) => String(su.userId) === String(hId));
            if (index !== -1) {
              selectedUser.splice(index, 1);
            }
          });
        }
      } else if (Number(pageFrom) === CONTACT_PAGE_FROM.subscribe_holder) {
        if (members) {
          const memArray = String(members).split(';');
          memArray.forEach((mId) => {
            const index = selectedUser.findIndex((su) => String(su.userId) === String(mId));
            if (index !== -1) {
              selectedUser.splice(index, 1);
            }
          });
        }
      }
      let userNameStr = '';
      let userIdStr = '';
      if (selectedUser) {
        selectedUser.forEach((user) => {
          if (userNameStr.length === 0) {
            userNameStr += `${getLocale() === ENGLISH ? user.realNameEn : user.realName}`;
          } else {
            userNameStr += `;${getLocale() === ENGLISH ? user.realNameEn : user.realName}`;
          }
          if (userIdStr.length === 0) {
            userIdStr += `${user.userId}`;
          } else {
            userIdStr += `;${user.userId}`;
          }
        });
      }
      if (Number(pageFrom) === CONTACT_PAGE_FROM.subscribe_holder) {
        const hostName = userNameStr;
        let newroomPlan = roomPlan.map((item) => {
          if (isGetStatus && item?.selectUsers?.length) {
            item.selectUsers = item.selectUsers.map((userItem) => {
              userItem.status = selectedUser.filter((userItem2) => {
                return userItem.userId === userItem2.userId;
              })[0]?.status;
              return userItem;
            });
            return item;
          } else {
            return item;
          }
        });
        if (roomPlan && roomPlan.length) {
          newroomPlan = canSplit
            ? roomPlan.map((ritem) => {
                if (ritem.roomId === currentRoomId) {
                  return { ...ritem, hostUser: selectedUser };
                }
                return { ...ritem };
              })
            : roomPlan.map((ritem) => {
                if (ritem.roomId === currentRoomId) {
                  return { ...ritem, hostUser: selectedUser };
                }
                return { ...ritem, hostUser: [] };
              });
        }
        let allHostUser = [];
        let allSelectUser = [];
        if (canSplit && newroomPlan && newroomPlan.length && currentRoomId !== 'allOrNone') {
          newroomPlan.forEach((alitem) => {
            const { hostUser = [], selectUsers = [] } = alitem;
            allHostUser = allHostUser.concat(hostUser);
            allSelectUser = allSelectUser.concat(selectUsers);
          });
        }
        if (!canSplit || currentRoomId === 'allOrNone') {
          // 不拆分或者多个会议室（大于1）
          allHostUser = selectedUser;
          if (newroomPlan && newroomPlan.length) {
            newroomPlan.forEach((alitem2) => {
              allSelectUser = allSelectUser.concat(alitem2.selectUsers);
            });
          } else {
            allSelectUser = members
              ? members.split(';').map((item) => {
                  return { userId: Number(item) };
                })
              : [];
          }
        }
        const allHostUserIdArr = allHostUser.map((a) => a.userId);
        const allSelectUserIdArr = allSelectUser.map((a) => a.userId);
        const hostIdArray = allHostUserIdArr || [];
        const memberArr = allSelectUserIdArr || [];
        if (canSplit && memberArr.find((mb) => hostIdArray.indexOf(mb) > -1)) {
          Toast.fail(formatMessage({ id: 'subscribe.fail.memberConflict' }), 1, null, false);
          return;
        }
        const allUserArray = hostIdArray.concat(memberArr);
        const newMeetingUserSeat = meetingUserSeat.filter((m) => {
          return (
            (Number(m.type) === 0 && allUserArray.indexOf(Number(m.userId)) > -1) ||
            Number(m.type) === 1
          );
        });
        console.log(newroomPlan, 'newroomPlan');
        yield put({
          type: 'subscribe/update',
          payload: {
            roomPlan: newroomPlan,
            hostName:
              currentRoomId === 'allOrNone'
                ? hostName
                : allHostUser
                    .map((an) => (getLocale() === ENGLISH ? an.realNameEn : an.realName))
                    .join(';'), // 处理全部主持人
            hostId: currentRoomId === 'allOrNone' ? userIdStr : allHostUserIdArr.join(';'), // 处理全部主持人 全员或初始无会议室currentRoomId === 'allOrNone'
            hostUsers: currentRoomId === 'allOrNone' ? selectedUser : allHostUser, // 处理全部参会人
            meetingUserSeat: newMeetingUserSeat,
            meetingUserSeatTemp: newMeetingUserSeat,
          },
        });

        if (!isDetial) {
          history.goBack();
          // history.push({
          //   pathname: MENU_PATH.subscribeDetail,
          //   query: {
          //     meetingId,
          //   },
          // });
        }
      } else if (Number(pageFrom) === CONTACT_PAGE_FROM.subscribe_member) {
        let newroomPlan = roomPlan?.map((item) => {
          if (isGetStatus && item?.selectUsers?.length) {
            item.selectUsers = item.selectUsers?.map((userItem) => {
              userItem.status = selectedUser.filter((userItem2) => {
                return userItem.userId === userItem2.userId;
              })[0]?.status;
              return userItem;
            });
            return item;
          } else {
            return item;
          }
        });

        if (roomPlan && roomPlan.length) {
          newroomPlan = roomPlan.map((ritem) => {
            // 处理全部参会人列表删除时，会议室对应的selectedUser赋值是全部的selectedUser值问题
            if (isDeleteUser) {
              let newSelectedUser = selectedUser;
              const tempSelectUserId = ritem?.selectUsers?.map((item) => {
                return item.userId;
              });
              newSelectedUser = selectedUser?.filter((item) => {
                return tempSelectUserId.includes(item.userId);
              });
              return { ...ritem, selectUsers: newSelectedUser };
            }
            if (ritem.roomId === currentRoomId) {
              return { ...ritem, selectUsers: selectedUser };
            }
            return { ...ritem };
          });
        }
        let allHostUser = [];
        let allSelectUser = [];
        if (canSplit && newroomPlan && newroomPlan.length && currentRoomId !== 'allOrNone') {
          newroomPlan.forEach((alitem) => {
            const { hostUser = [], selectUsers = [] } = alitem;
            allHostUser = allHostUser.concat(hostUser);
            allSelectUser = allSelectUser.concat(selectUsers);
          });
        }
        if (!canSplit || currentRoomId === 'allOrNone') {
          // 主持人不拆分或者多个会议室（大于1） 座位安排需要
          allHostUser = String(hostId)
            ? String(hostId)
                .split(';')
                .map((item) => {
                  return { userId: Number(item) };
                })
            : [];
          if (newroomPlan && newroomPlan.length) {
            newroomPlan.forEach((alitem2) => {
              allSelectUser = allSelectUser.concat(alitem2.selectUsers);
            });
          } else {
            allSelectUser = selectedUser;
          }
        }
        const allHostUserIdArr = allHostUser.map((a) => a.userId);
        const allSelectUserIdArr = allSelectUser.map((a) => a.userId);
        const hostIdArray = allHostUserIdArr || [];
        const memberArr = allSelectUserIdArr || [];
        const allUserArray = hostIdArray.concat(memberArr);
        const newMeetingUserSeat = meetingUserSeat.filter((m) => {
          return (
            (Number(m.type) === 0 && allUserArray.indexOf(Number(m.userId)) > -1) ||
            Number(m.type) === 1
          );
        });
        yield put({
          type: 'subscribe/update',
          payload: {
            roomPlan: newroomPlan || [],
            members: currentRoomId === 'allOrNone' ? userIdStr : allSelectUserIdArr.join(';'),
            memberNames:
              currentRoomId === 'allOrNone'
                ? userNameStr
                : allSelectUser
                    .map((an) => (getLocale() === ENGLISH ? an.realNameEn : an.realName))
                    .join(';'), // 处理全部参会人
            selectedMembers: currentRoomId === 'allOrNone' ? selectedUser : allSelectUser,
            meetingUserSeat: newMeetingUserSeat,
            meetingUserSeatTemp: newMeetingUserSeat,
          },
        });
        yield put({ type: 'set', payload: { isDeleteUser: false}});
        if (!isDetial && !isGetStatus) {
          history.goBack();
          // history.push({
          //   pathname: MENU_PATH.subscribeDetail,
          //   query: {
          //     meetingId,
          //   },
          // });
        }
      } else if (Number(pageFrom) === CONTACT_PAGE_FROM.manage_service_payer) {
        yield put({
          type: 'svcFeeDetail/updateRoomPayer',
          payload: { payer: userIdStr, payerName: userNameStr, roomId: currentRoomId },
        });
        history.goBack();
      } else if (Number(pageFrom) === CONTACT_PAGE_FROM.manage_room_payer) {
        yield put({
          type: 'subscribe/updateRoomPayer',
          payload: { payer: userIdStr, payerName: userNameStr, roomId: currentRoomId },
        });
        history.goBack();
      } else if (Number(pageFrom) === CONTACT_PAGE_FROM.room_detail_payer) {
        yield put({
          type: 'roomFeeDetail/updateRoomPayer',
          payload: { payer: userIdStr, roomId: currentRoomId },
        });
      } else if (Number(pageFrom) === CONTACT_PAGE_FROM.room_service_payer) {
        yield put({
          type: 'svcFeeDetail/updateRoomPayer',
          payload: { payer: userIdStr, payerName: userNameStr, roomId: currentRoomId },
        });
        // 预约页面-新增服务时选择代付人，外层费用弹窗服务代付人需同步
        // yield put({
        //   type: 'manage/updateSvcPayer',
        //   payload: { payer: userIdStr, payerName: userNameStr, roomId: currentRoomId },
        // });
        history.goBack();
      } else {
        window.console.log('无效pageForm', pageFrom);
      }
    },
    *handleExternalUserOfVisit(_, { put, select }) {
      const { selectedExternal } = yield select((state) => state.attendee);
      if (selectedExternal?.length > 0) {
        const newSelectedExternal = selectedExternal.map((item) => ({ ...item, invite: 0 }));
        yield put({
          type: 'set',
          payload: { selectedExternal: newSelectedExternal },
        });
        yield put({
          type: 'submitExternalUser',
        });
      }
    },
    *submitExternalUser({ isDetial = false }, { put, select }) {
      //  vConsole.show();
      try {
        const {
          meetingUserSeat = [],
          meetingId,
          roomPlan = [],
        } = yield select((state) => state.subscribe);
        const { selectedExternal, currentRoomId } = yield select((state) => state.attendee);
        // console.log(selectedExternal," selectedExternal")
        let userNameStr = '';
        let userIdStr = '';
        if (selectedExternal) {
          selectedExternal.forEach((user) => {
            if (userNameStr.length === 0) {
              userNameStr += `${user.name}`;
            } else {
              userNameStr += `;${user.name}`;
            }
            if (userIdStr.length === 0) {
              userIdStr += `${user.id}`;
            } else {
              userIdStr += `;${user.id}`;
            }
          });
        }

        let newroomPlan = roomPlan;
        if (roomPlan && roomPlan.length) {
          newroomPlan = roomPlan.map((ritem) => {
            if (ritem.roomId === currentRoomId) {
              return { ...ritem, externalUsers: selectedExternal };
            }
            return { ...ritem };
          });
        }
        let allexternals = [];
        if (newroomPlan && newroomPlan.length) {
          newroomPlan.forEach((alitem) => {
            const { externalUsers = [] } = alitem;
            allexternals = allexternals.concat(externalUsers);
          });
        }
        const externalUserId = allexternals.map((a) => a.id);
        const newMeetingUserSeat = meetingUserSeat.filter((m) => {
          return (
            (Number(m.type) === 1 && externalUserId.indexOf(Number(m.userId)) > -1) ||
            Number(m.type) === 0
          );
        });

        yield put({
          type: 'subscribe/update',
          payload: {
            roomPlan: newroomPlan,
            externals: currentRoomId === 'allOrNone' ? userIdStr : externalUserId.join(';'),
            externalNames:
              currentRoomId === 'allOrNone'
                ? userNameStr
                : allexternals
                    .map((an) => (getLocale() === ENGLISH ? an.realNameEn : an.realName))
                    .join(';'),
            selectedExternal: currentRoomId === 'allOrNone' ? selectedExternal : allexternals,
            meetingUserSeat: newMeetingUserSeat,
            meetingUserSeatTemp: newMeetingUserSeat,
          },
        });
        if (!isDetial) {
          history.push({
            pathname: MENU_PATH.subscribeDetail,
            query: {
              meetingId,
            },
          });
        }
      } catch (error) {
        //  vConsole.show();
      }
    },

    *saveExternalList({ payload }, { call, put, select }) {
      const { id } = payload;
      const { externalList, selectedExternal } = yield select((state) => state.attendee);
      if (externalList.length === 0) {
        history.goBack();
        return;
      }
      let firstInvite = false;
      if (id && id > 0) {
        const user = externalList[0];
        firstInvite = !!user.invite;
        delete user.invite;
      }
      const { visitor } = yield select((state) => state.sysModule);
      const result = yield call(attendeeService.saveUserList, { list: externalList });
      if (result && result.code === 0) {
        const result1 = yield call(attendeeService.getExternalList);

        if (result1.code === 0) {
          let newSelected = [];
          if (id > 0) {
            selectedExternal.forEach((s) => {
              if (s.id && String(s.id) === String(id)) {
                externalList[0].invite = firstInvite;
                externalList[0].canntOperate = s?.canntOperate;
                newSelected.push(externalList[0]);
              } else {
                newSelected.push(s);
              }
            });
          } else {
            const externalNameList = externalList?.map((item) => {
              return item.name;
            });
            const tempResult1 = result1.data?.filter((item) => {
              return externalNameList.includes(item.name);
            });
            newSelected = selectedExternal.concat(
              tempResult1?.map((item) => {
                // if (visitor && String(visitor.vstInviteAutoSelect) === '1') {
                //   return { ...item, invite: 1 };
                // }
                return item;
              }),
            );
          }
          // 给人员添加随机颜色
          const array = result1.data.map((item) => {
            const r = item.id % 6;
            return { ...item, color: UserColor[r] };
          });
          yield put({
            type: 'set',
            payload: {
              externalUsers: array,
              selectedExternal: newSelected,
            },
          });
        }
        const restExternal = [];
        restExternal.push({
          name: '',
          mobile: '',
          companyName: '',
          telAreaCode: '+86',
          email: '',
        });
        yield put({ type: 'set', payload: { externalList: restExternal } });

        yield put({ type: 'meetingContact/reset' });
        history.goBack();
      } else if (result.msg === '202030') {
        Toast.fail(
          result && result.msg ? formatMessage({ id: result.msg }, { phone: result.data }) : '',
        );
      } else if (result.msg === '202065') {
        Toast.fail(
          result && result.msg ? formatMessage({ id: result.msg }, { email: result.data }) : '',
        );
      } else {
        Toast.fail(result && result.msg ? formatMessage({ id: result.msg }) : '');
      }
    },
    *deleteExternal({ user }, { select, call, put }) {
      const data = yield call(attendeeService.deleteUser, { id: user.id });
      if (data && data.code === 0) {
        // 删除后重新进行外部参会人过滤并保存哦~ 不然直接点击返回按钮会出现问题
        // Toast.loading(formatMessage({ id: 'app.loading' }), 30);
        const { selectedExternal = [] } = yield select((state) => state.attendee);

        selectedExternal.forEach((ex, i) => {
          if (ex.id === user.id) {
            selectedExternal.splice(i, 1);
          }
        });
        const { roomPlan = [], selectedExternal: subExteranl } = yield select(
          (state) => state.subscribe,
        );
        let newroomPlan = roomPlan;
        if (roomPlan && roomPlan.length) {
          newroomPlan.forEach((rp) => {
            const { externalUsers = [] } = rp;
            externalUsers.forEach((eu, i) => {
              if (eu.id === user.id) {
                externalUsers.splice(i, 1);
              }
            });
          });
        }
        subExteranl.forEach((st, i) => {
          if (st.id === user.id) {
            subExteranl.splice(i, 1);
          }
        });
        let allexternals = [];
        if (newroomPlan && newroomPlan.length) {
          newroomPlan.forEach((alitem) => {
            allexternals = allexternals.concat(alitem.externalUsers);
          });
        }
        const result = yield call(attendeeService.getExternalList);
        if (result.code === 0) {
          // 给人员添加随机颜色
          const array = result.data.map((item) => {
            const r = item.id % 6;
            return { ...item, color: UserColor[r] };
          });
          yield put({ type: 'set', payload: { externalUsers: array } });
          yield put({
            type: 'subscribe/update',
            payload: {
              roomPlan: newroomPlan,
              externals: allexternals.map((a) => a.userId).join(';'),
              externalNames: allexternals
                .map((an) => (getLocale() === ENGLISH ? an.realNameEn : an.realName))
                .join(';'),
              selectedExternal: allexternals,
            },
          });
        }
        //  Toast.hide();
      }
    },
    *switchInviteFlag({ id }, { select, put }) {
      const { selectedExternal = [] } = yield select((state) => state.attendee);
      const { roomPlan = [], selectedExternal: selectedExternalFromSubscribe } = yield select(
        (state) => state.subscribe,
      );
      const newSelectedExternal = selectedExternal.map((u) => {
        if (u.id === id) {
          return { ...u, invite: u.invite ? 0 : 1 };
        }
        return u;
      });
      const newSelectedExternalFromSubscribe = selectedExternalFromSubscribe.map((u) => {
        if (u.id === id) {
          return { ...u, invite: u.invite ? 0 : 1 };
        }
        return u;
      });

      let newroomPlan = roomPlan;
      if (roomPlan && roomPlan.length) {
        newroomPlan = roomPlan.map((rp) => {
          const { externalUsers = [] } = rp;
          const newExternalUsers = externalUsers.map((eu, i) => {
            if (eu.id === id) {
              return { ...eu, invite: eu.invite ? 0 : 1 };
            }
            return eu;
          });
          return { ...rp, externalUsers: newExternalUsers };
        });
      }
      // 只有选择了外部访客的时候才去查询访客接口
      const isInvite = newSelectedExternal?.some((item) => item.invite === 1);
      if (isInvite) {
        yield put({ type: 'subscribe/getSwitchConfig' });
      }
      yield put({ type: 'set', payload: { selectedExternal: newSelectedExternal } });
      yield put({
        type: 'subscribe/update',
        payload: { roomPlan: newroomPlan, selectedExternal: newSelectedExternalFromSubscribe },
      });
    },
    *getUserStatus({ isEdit = false }, { select, put, call }) {
      const { selectedUser, allUsers, frequentUserList, userMap } = yield select(
        (state) => state.attendee,
      );
      // const { selectedMembers } = yield select((state) => state.subscribe);
      const {
        startTime,
        endTime,
        date,
        selectedMembers,
        roomPlan,
        startNow,
        duration,
        isLongState,
      } = yield select((state) => state.subscribe);
      // 编辑会议 不用判断这些
      if (!isEdit) {
        if (selectedUser?.length <= 0) return;
        if (!startNow && (!startTime || !endTime)) {
          return;
        }
      }
      // if (!isEdit&&!startNow&&(!startTime || !endTime || selectedUser?.length <= 0 )) return
      const userList = isEdit ? selectedMembers : selectedUser;
      let synthesisStartTime = null,
        synthesisEndTime = null;
      if (isLongState === 1) {
        synthesisStartTime = startTime.valueOf();
        synthesisEndTime = endTime.valueOf();
      } else if (startNow) {
        synthesisStartTime = moment().valueOf();
        synthesisEndTime = moment().add('minute', +duration).valueOf();
      } else {
        synthesisStartTime = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm').valueOf();
        synthesisEndTime = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm').valueOf();
      }
      // const synthesisStartTime = nowStartTime ? nowStartTime : moment(`${date} ${startTime}`, "YYYY-MM-DD HH:mm").valueOf();
      // const synthesisEndTime = nowEndTime ? nowEndTime : moment(`${date} ${endTime}`, "YYYY-MM-DD HH:mm").valueOf();
      const userGroup = getSliceArray(userList, 20);
      const newArray = _.cloneDeep(userGroup);
      const callArray = [];
      console.log(synthesisStartTime, '+==synthesisStartTime===');
      for (let index = 0; index < userGroup.length; index++) {
        const userIdList = userGroup[index].map((item) => item.userId);
        const params = {
          startTime: synthesisStartTime,
          endTime: synthesisEndTime,
          userIdList,
        };
        callArray.push(call(attendeeService.getUserStatus, params));
      }
      const reuslt = yield callArray;

      for (let index = 0; index < reuslt.length; index++) {
        const { data, code, msg } = reuslt[index];
        if (code === 0) {
          const newSelectedUser = getAttendStatus(userGroup[index], data);
          newArray[index] = newSelectedUser;
        } else {
          Toast.fail(formatMessage({ id: msg }));
          return;
        }
      }
      const handleArray = newArray.flat();
      if (handleArray?.length <= 0) {
        return;
      }
      const newAllUser = allUsers.map((item) => {
        const userOfStatus = handleArray.find((user) => item.u === user.userId);
        if (userOfStatus) {
          return { ...item, status: userOfStatus.status };
        }
        return item;
      });
      const newFrequentUserList = frequentUserList.map((item) => {
        const userOfStatus = handleArray.find((user) => item.userId === user.userId);
        if (userOfStatus) {
          return { ...item, status: userOfStatus.status };
        }
        return item;
      });
      const newUserMap = _.cloneDeep(userMap);
      for (const key in newUserMap) {
        if (Object.hasOwnProperty.call(newUserMap, key)) {
          const users = newUserMap[key];
          newUserMap[key] = users.map((item) => {
            const userOfStatus = handleArray.find((user) => item.userId === user.userId);
            if (userOfStatus) {
              return { ...item, status: userOfStatus.status };
            }
            return item;
          });
        }
      }
      yield put({
        type: 'set',
        payload: {
          selectedUser: handleArray,
          allUsers: newAllUser,
          frequentUserList: newFrequentUserList,
          userMap: newUserMap,
        },
      });
      if (isEdit) {
        yield put({
          type: 'subscribe/update',
          payload: {
            selectedMembers: handleArray,
          },
        });
        // 对拆分会议室 参会人进行处理
        if (roomPlan?.length > 0) {
          const newRoomPlan = roomPlan.map((roomObj) => {
            const newRoomObj = _.cloneDeep(roomObj);
            newRoomObj.selectUsers = roomObj.selectUsers.map((user) => {
              const userStatus = handleArray.find((item) => user.userId === item.userId);
              if (userStatus) {
                return { ...user, status: userStatus.status };
              }
              return user;
            });
            return newRoomObj;
          });

          yield put({
            type: 'subscribe/update',
            payload: {
              roomPlan: newRoomPlan,
            },
          });
        }
      }
    },
    // eslint-disable-next-line no-empty-pattern
    *getHostStatus(_, { select, put, call }) {
      const {
        startTime,
        endTime,
        date,
        hostId,
        hostUsers,
        roomPlan,
        startNow,
        duration,
        isLongState,
      } = yield select((state) => state.subscribe);
      const { allUsers, userMap } = yield select((state) => state.attendee);

      let synthesisStartTime = null,
        synthesisEndTime = null;
      if (isLongState === 1) {
        synthesisStartTime = startTime.valueOf();
        synthesisEndTime = endTime.valueOf();
      } else if (startNow) {
        synthesisStartTime = moment().valueOf();
        synthesisEndTime = moment().add('minute', +duration).valueOf();
      } else {
        synthesisStartTime = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm').valueOf();
        synthesisEndTime = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm').valueOf();
      }
      const newHostId = typeof hostId === 'number' ? [hostId] : hostId.split(';').map((id) => +id);
      const params = {
        startTime: synthesisStartTime,
        endTime: synthesisEndTime,
        userIdList: newHostId,
      };
      const { data, code, msg } = yield call(attendeeService.getUserStatus, params);
      const newHostUsers = getAttendStatus(hostUsers, data);
      if (code === 0) {
        yield put({
          type: 'subscribe/update',
          payload: {
            hostUsers: newHostUsers,
          },
        });
        // 对参会人列表进行赋值 allUsers userMap
        const newAllUser = allUsers.map((item) => {
          const userOfStatus = newHostUsers.find((user) => item.u === user.userId);
          if (userOfStatus) {
            return { ...item, status: userOfStatus.status };
          }
          return item;
        });

        const newUserMap = _.cloneDeep(userMap);
        for (const key in newUserMap) {
          if (Object.hasOwnProperty.call(newUserMap, key)) {
            const users = newUserMap[key];
            newUserMap[key] = users.map((item) => {
              const userOfStatus = newHostUsers.find((user) => item.userId === user.userId);
              if (userOfStatus) {
                return { ...item, status: userOfStatus.status };
              }
              return item;
            });
          }
        }
        // console.log(newAllUser, '===newAllUser===')
        yield put({
          type: 'set',
          payload: {
            allUsers: newAllUser,
            userMap: newUserMap,
          },
        });

        // 对拆分会议室 参会人进行处理
        if (roomPlan?.length > 0) {
          const newRoomPlan = roomPlan.map((roomObj) => {
            const newRoomObj = _.cloneDeep(roomObj);
            newRoomObj.hostUser = roomObj.hostUser.map((user) => {
              const userStatus = newHostUsers.find((item) => user.userId === item.userId);
              if (userStatus) {
                return { ...user, status: userStatus.status };
              }
              return user;
            });
            return newRoomObj;
          });

          yield put({
            type: 'subscribe/update',
            payload: {
              roomPlan: newRoomPlan,
            },
          });
        }
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *getUserStatusOfEdit(_, { select, put, call }) {
      const { startTime, endTime, date, selectedMembers } = yield select(
        (state) => state.subscribe,
      );
      const synthesisStartTime = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm').valueOf();
      const synthesisEndTime = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm').valueOf();
      const userGroup = getSliceArray(selectedMembers, 20);
      const newArray = _.cloneDeep(userGroup);
      const callArray = [];
      for (let index = 0; index < userGroup.length; index++) {
        const userIdList = userGroup[index].map((item) => item.userId);
        const params = {
          startTime: synthesisStartTime,
          endTime: synthesisEndTime,
          userIdList,
        };
        callArray.push(call(attendeeService.getUserStatus, params));
      }
      const reuslt = yield callArray;

      for (let index = 0; index < reuslt.length; index++) {
        const { data, code, msg } = reuslt[index];
        if (code === 0) {
          const newSelectedUser = getAttendStatus(userGroup[index], data);
          newArray[index] = newSelectedUser;
        } else {
          Toast.fail(formatMessage({ id: msg }));
          return;
        }
      }
      const handleArray = newArray.flat();
      if (handleArray?.length <= 0) {
        return;
      }
      const newAllUser = allUsers.map((item) => {
        const userOfStatus = handleArray.find((user) => item.u === user.userId);
        if (userOfStatus) {
          return { ...item, status: userOfStatus.status };
        }
        return item;
      });
      const newFrequentUserList = frequentUserList.map((item) => {
        const userOfStatus = handleArray.find((user) => item.userId === user.userId);
        if (userOfStatus) {
          return { ...item, status: userOfStatus.status };
        }
        return item;
      });
      const newUserMap = _.cloneDeep(userMap);
      for (const key in newUserMap) {
        if (Object.hasOwnProperty.call(newUserMap, key)) {
          const users = newUserMap[key];
          newUserMap[key] = users.map((item) => {
            const userOfStatus = handleArray.find((user) => item.userId === user.userId);
            if (userOfStatus) {
              return { ...item, status: userOfStatus.status };
            }
            return item;
          });
        }
      }
      yield put({
        type: 'set',
        payload: {
          selectedUser: handleArray,
          allUsers: newAllUser,
          frequentUserList: newFrequentUserList,
          userMap: newUserMap,
        },
      });
    },
    *getAreaCode(_, { call, put }) {
      const result = yield call(attendeeService.getAreaCode);
      if (result.code === 0) {
        let arr = [];
        if (result.data.length > 0) {
          const isEnUS = getLocale() === 'en-US';
          const isTW = getLocale() === 'zh-TW';
          result.data.forEach((item) => {
            const label = isEnUS ? item.nameEn : isTW ? item.nameTW : item.name;
            arr.push({
              label: label + ' ' + item?.code,
              value: item?.code,
            });
          });
          yield put({
            type: 'set',
            payload: {
              areaCode: arr,
            },
          });
        }
      }
    },
  },

  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen((location) => {
        const { pathname } = location;
        if (matchPath(pathname, MENU_PATH.attendee)) {
          const { query } = history.location;
          const { pageFrom } = query || {};
          // 把初始化变量储存起来
          dispatch({ type: 'resetState' });
          dispatch({ type: 'set', payload: { pageFrom } });
        } else if (matchPath(pathname, MENU_PATH.attendeeSmall)) {
          const { query } = history.location;
          const { pageFrom } = query || {};
          // 把初始化变量储存起来
          dispatch({ type: 'resetState' });
          dispatch({ type: 'set', payload: { pageFrom } });
        }
        dispatch({ type: 'set', payload: { searchUsers: [] } });
      });
    },
  },
};
