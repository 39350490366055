import { formatMessage, getLocale } from 'umi';
import * as services from './service';
import { deviceControl, getTemp, selectSpaceDeviceUnderSpaceResource } from './service';
import data from '@/pages/Vap/pages/MeetingControl/contorl';
import { Toast } from 'antd-mobile';

export default {
  namespace: 'meetingControlVap',
  state: {
    config: {},
    resourceName: '',
    subTypeCode: '',
    resourceId: '',
    device: [],
    telemetry: {},
    deviceControl: {},
    showType: '',
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getResourceDetail({ payload }, { call, put, select }) {
      const { code, data = {} } = yield call(services.getResourceDetail, payload);
      if (code === 0) {
        const { modelInstance } = data;
        yield put({
          type: 'selectSpaceDeviceUnderSpaceResource',
          payload,
        });
        const locale = getLocale();
        const locale1 = {
          'zh-CN': 'resourceNameCn',
          'en-US': 'resourceNameEn',
          'zh-TW': 'resourceNameTw',
        }[locale];
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            resourceId: payload,
            resourceName: data[locale1] || '',
          },
        });
      }
    },
    *getTemp({ payload }, { call, put, select }) {
      const { code, data } = yield call(services.getTemp, payload);
      if (code === 0) {
        const { controlTemplate } = data[0] || {};
        if (!controlTemplate) {
          Toast.show({
            icon: 'fail',
            content: formatMessage({ id: 'app.vap.temp.error1' }),
          });
          return;
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            config: JSON.parse(controlTemplate),
          },
        });
      }
    },
    *getDeviceControl({ payload }, { call, put, select }) {
      const { code, data } = yield call(services.getDeviceControl, payload);

      if (code === 0) {
        const { deviceControl } = yield select(({ meetingControlVap }) => {
          return meetingControlVap;
        });
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            deviceControl: {
              ...deviceControl,
              [payload]: data,
            },
          },
        });
      }
    },
    *runDeviceControl1({ payload = [] }, { call, put, select }) {
      let err = false;
      for (let i = 0; i < payload.length; i++) {
        const { code, data } = yield call(services.deviceControl, payload[i]);
        if (code !== 0) {
          err = true;
        }
      }
      return err;
    },
    *runDeviceControl({ payload = [] }, { call, put, select }) {
      const { code, data } = yield call(services.deviceControl, payload);
    },
    *selectSpaceDeviceUnderSpaceResource({ payload }, { call, put, select }) {
      const { code, data } = yield call(services.selectSpaceDeviceUnderSpaceResource, payload);
      if (code === 0) {
        if (data.length !== 0) {
          yield put({
            type: 'getMultipleDeviceLastTelemetry',
            payload: [...data.map((il) => il.deviceNumber)].join(','),
          });
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            device: data,
          },
        });
      }
    },
    *upDatatelemetry({ payload }, { call, put, select }) {
      const { id, data } = payload;
      const { telemetry } = yield select(({ meetingControlVap }) => {
        return meetingControlVap;
      });
      const oldTemp = {
        ...telemetry[id],
        ...data,
      };
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          telemetry: {
            ...telemetry,
            ...oldTemp,
          },
        },
      });
    },
    *getMultipleDeviceLastTelemetry({ payload }, { call, put, select }) {
      const { code, data } = yield services.getMultipleDeviceLastTelemetry(payload);
      if (code === 0) {
        const telemetry = {};
        Object.keys(data).forEach((il) => {
          const obj = {};
          data[il].forEach((kl) => {
            obj[kl.k] = kl.v;
          });
          telemetry[il] = obj;
        });
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            telemetry: telemetry,
          },
        });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
        if (pathname.indexOf('vap/control') !== -1) {
          const { resourceId, type, subTypeCode } = query;
          // console.log(resourceId);
          if (resourceId) {
            dispatch({
              type: 'getResourceDetail',
              payload: resourceId,
            });
            dispatch({
              type: 'getTemp',
              payload: resourceId,
            });
          }
          if (type) {
            dispatch({
              type: 'saveOrUpdateData',
              payload: {
                showType: type,
                subTypeCode: subTypeCode,
              },
            });
          }
        }
      });
    },
  },
};
