import request, { requestNoAuthorization } from '@/utils/request';
import { PASSWORD_AES_KEY, RESTFUL_PATH } from '@/utils/constant';
import { encryptAes } from '@/utils/utils';

/**
 * Created : vincent
 * Date : 2019-03-28  10:35
 * Email : wangxiao@wafersystems.com
 */

export default async function login({ userName, password, code, randomStr }) {
  const aesPassword = encryptAes(password, PASSWORD_AES_KEY);
  const loginData = new FormData();
  loginData.append('username', userName);
  loginData.append('password', aesPassword);
  loginData.append('grant_type', 'password');
  loginData.append('scope', 'server');

  if (code) {
    loginData.append('code', code);
  }
  if (randomStr) {
    loginData.append('randomStr', randomStr);
  }

  const {pathname, search} = window.location;
  let requestUrl = `/${RESTFUL_PATH.auth}/oauth/token`;
  if(pathname?.endsWith('/user/authorize')){
    // 第三方认证
    requestUrl = `/${RESTFUL_PATH.auth}/oauth/token${search}`
  }

  return requestNoAuthorization(requestUrl, {
    method: 'POST',
    data: loginData,
    headers: {
      Authorization: 'Basic bG9naW4taDU6MzdIQDVMbiM3MTQ4',
      'vsk-auth': 'Basic bG9naW4taDU6MzdIQDVMbiM3MTQ4',
    },
  });
}

/**
 *
 * @param payload
 * @returns {Promise<any>}
 */
export async function sendEmailGotPW({ payload }) {
  return request(`/${RESTFUL_PATH.admin}/user/forget-pwd/send-mail`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 *
 * @returns {Promise<any>}
 */
export async function getInitData() {
  return requestNoAuthorization(`/${RESTFUL_PATH.admin}/common/init/data`);
}

export async function getIconFontByEnc() {
  return requestNoAuthorization(`/${RESTFUL_PATH.admin}/common/enc/data`);
}

export async function trackCheckService({ payload }) {
  return request(`/${RESTFUL_PATH.auth}/captcha/trackcheck`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export async function securityCodeCheck({ payload }) {
  const {search} = window.location;
  let requestUrl = `/${RESTFUL_PATH.auth}/token/securityCodeCheck?securityCode=${
    payload.securityCode
  }&flag=${encodeURIComponent(payload.flag)}`;

  if(search?.includes('user_authorize')){
    // 第三方认证
    requestUrl = `/${RESTFUL_PATH.auth}/token/securityCodeCheck${search}&securityCode=${
      payload.securityCode
    }&flag=${encodeURIComponent(payload.flag)}`;
  }

  return requestNoAuthorization(
    requestUrl,
  );
}

export async function sendSMSCaptcha({ mobile, code, randomStr }) {
  const smsData = new FormData();
  smsData.append('mobile', mobile);
  smsData.append('code', code);
  smsData.append('randomStr', randomStr);

  return requestNoAuthorization(`/${RESTFUL_PATH.admin}/user/send/sms/captcha`, {
    method: 'POST',
    data: smsData,
  });
}

export async function SMSCodeLogin({ code }) {
  const smsData = new FormData();
  smsData.append('grant_type', 'mobile');

  const {pathname, search} = window.location;
  let requestUrl = `/${RESTFUL_PATH.auth}/oauth/token?custom=${code}`;
  if(pathname?.endsWith('/user/authorize')){
    // 第三方认证
    requestUrl = `/${RESTFUL_PATH.auth}/oauth/token${search}&custom=${code}`
  }

  return requestNoAuthorization(requestUrl, {
    headers: {
      Authorization: 'Basic bG9naW4taDU6MzdIQDVMbiM3MTQ4',
      'vsk-auth': 'Basic bG9naW4taDU6MzdIQDVMbiM3MTQ4',
    },
    method: 'POST',
    data: smsData,
  });
}

/**
 * Teams SSO 登录
 *
 * @param payload 请求参数体
 */
export async function TeamsCodeLogin({ code, id }) {
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/bot/teams/auth?custom=${code}&id=${id}`, {
    headers: {
      Authorization: 'Basic bG9naW4taDU6MzdIQDVMbiM3MTQ4',
      'vsk-auth': 'Basic bG9naW4taDU6MzdIQDVMbiM3MTQ4',
    },
  });
}

/**
 * 获取app端用户当前可用的产品标识列表
 * @returns {Promise<any>}
 */
export async function getUserProductCodes() {
  return request(`/${RESTFUL_PATH.cloudOms}/users/using/productCodes/app`);
}

// 获取飞书认证URL
export async function getFeishuUrl() {
  return request(`/${RESTFUL_PATH.cloudOms}/sysParamConfig/modules/feishu`);
}

export async function getPicture(params) {
  return request(`/${RESTFUL_PATH.auth}/captcha/get`, {
    method: 'POST',
    data: JSON.stringify(params),
  });
}
export async function reqCheck(params) {
  return request(`/${RESTFUL_PATH.auth}/captcha/check`, {
    method: 'POST',
    data: JSON.stringify(params),
  });
}

export async function refreshToken() {
  const loginData = new FormData();
  loginData.append('grant_type', 'refresh_token');
  loginData.append('refresh_token', sessionStorage.getItem('sso_refresh_token'));

  return requestNoAuthorization(`/${RESTFUL_PATH.auth}/token/refreshToken`, {
    method: 'POST',
    data: loginData,
    headers: {
      Authorization: 'Basic dmlyc2ljYWw6dmlyc2ljYWw=',
      'vsk-auth': 'Basic dmlyc2ljYWw6dmlyc2ljYWw=',
    },
  });
}


/**
 * 使用refreshToken刷新本地Token
 **/
export const refreshByToken = payload => {

  let auth = 'Basic bG9naW4taDU6MzdIQDVMbiM3MTQ4';
  if( sessionStorage.getItem('thirdSSO') === 'true' ){
    auth = 'Basic dmlyc2ljYWw6dmlyc2ljYWw=';
  }
  return requestNoAuthorization(
    `/${RESTFUL_PATH.auth}/oauth/token?grant_type=${payload.grant_type}&refresh_token=${
      payload.refresh_token
    }`,
    {
      headers: {
        Authorization: auth,
        'vsk-auth': auth,

      },
    }
  );
};
