import * as myBookingService from './service';

export default {
  namespace: 'inventoryList',
  state: {
    checkTaskList: [],
    checkTaskListParms: { current: 0, size: 6, total: 0, pages: 0 },
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getCheckTaskInfo({ checkTaskListParms }, { call, put, select }) {
      const { checkTaskList } = yield select((state) => {
        return state.inventoryList;
      });
      const result = yield call(myBookingService.getCheckTaskInfo, checkTaskListParms);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            checkTaskList: [...checkTaskList, ...result.data.records],
            checkTaskListParms: {
              current: result.data.current,
              size: result.data.size,
              total: result.data.total,
              pages: result.data.pages,
            },
          },
        });
      }
    },
  },
};
