export default {
  'sws.inventorylist.inventorytitle.confim': '未盘点',
  'sws.inventorylist.inventorytitle.wait': '待确认',
  'sws.inventorylist.inventorytitle.done': '已盘点',
  'sws.inventorylist.stateTitle.yes': '正常',
  'sws.inventorylist.stateTitle.no': '已终止',
  'sws.inventorylist.taskStatusTitle.will': '未开始',
  'sws.inventorylist.taskStatusTitle.doning': '进行中',
  'sws.inventorylist.taskStatusTitle.done': '已结束',
  'sws.inventorylist.contant.time': '任务日期',
  'sws.inventorylist.contant.name': '任务名称',
  'sws.inventorylist.contant.task': '任务状态',
  'sws.inventorylist.contant.tasktime': '盘点时间',
  'sws.inventorylist.content.placeholder': '在这里手动输入',
};
