/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'login.title': 'Virsical Service Platform',
  'login.title.2':
    'Virsical Service Platform is the most influential service platform in the Eastern Hemisphere',
  'login.title.app': 'Login',
  'login.welcome':
    'Welcome to the Wisdom service platform, click on the menu on the right to select the features you need to access. ',
  'login.username': 'Account',
  'login.username.tip': 'Please enter account',
  'validation.userName.required': 'Please enter account',
  'login.password': 'Password',
  'validation.password.required': 'Please enter password',
  'login.forgot.password': 'Forgot Password',
  'login.remember.password': 'Remember Me',
  'login.login': 'Login',
  'login.copy.right': '2019 Wafer Systems Ltd. All rights reserved',
  'login.err':
    'Login failed, please check if the username and password are correct. If not, please try later',
  'login.storage.err': 'The login information is invalid, please login again. ',
  'login.storage.err.title': 'Login Information error',
  'app.login.message-invalid-credential': 'User Name or Password error.',
  'login.forgot.password.email': 'Email',
  'login.forgot.password.email.required': 'Please enter email',
  'login.forgot.password.email.err': 'Please enter the correct email',
  'login.forgot.password.code': 'Verification code',
  'login.forgot.password.code.required': 'Please enter verification code',
  'login.forgot.password.send.email': 'Send Email',
  'login.forgot.password.send.email.success': 'Send Email success',
  'login.forgot.password.send.email.success.tip': 'The email has been sent, please check it.',
  'login.go.login': 'To Login',
  'login.button.confirm': 'OK',
  'login.fail.title': 'Login Fail',
  'login.fail.title.operate': 'Operation failed',
  'common.operate.slider.safe': 'Please complete security verification',
  'common.operate.slider.barText': 'Slide right to fill the puzzle!',
  'common.operate.slider.loadingText': 'Loading ...!',
  'common.operate.slider.failedText': 'Try again',

  'login.auth.type.2FA.title': 'Enter security code',
  'login.auth.type.2FA.title.placeholder': 'Please enter the security code',

  102163: 'Verification failed',
  101080: 'Security code verification failed',
  101079: 'Security code is empty',

  'login.type.SMS.tip': 'Mobile verification code login',
  'login.type.SMS.tip.1': 'Account login',
  'login.type.SMS.send.title': 'Send',
  'login.type.SMS.phone.title.placeholder': 'Phone number',
  'login.type.SMS.phone.code.title.placeholder': 'Verification code',
  'login.type.SMS.phone.title': 'Phone number',
  'login.type.SMS.phone.code.title': 'Mobile verification code',
  'login.type.slide.check.success': 'Verified Successfully',
  'login.type.slide.check.fail': 'Verified Failed',
  'cloud.safe.verify.invalid': 'The verification code is invalid, please get it again',
};
