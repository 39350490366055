export default {
  'menu.meeting.meeting_app': 'Smart Meeting',
  'menu.meeting.subscribe': 'Meeting Booking',
  'menu.meeting.mines': 'Meeting List',
  'menu.meeting.webexmeeting': 'Online Meeting',
  'menu.meeting.videomeeting': 'Video Meeting',
  'menu.workspace.mobile': 'Smart Workspace',
  'sws.menu.station.booking': 'Station reservation',
  'sws.menu.station.repair': 'Station repair',
  'sws.menu.station.search': 'Wayfinding',
  'sws.menu.station.records': 'Reservation record',
  'sws.menu.workspace.recommendBooking': 'System Arrangement',
  'menu.slt.app.lighting': 'Smartlighting',
  'menu.slt.app.report.environment': 'Environment Analysis',
  'menu.slt.app.report.energy': 'Energy Analysis',
  'menu.sas.app.smartaccess': 'Smart access',
  'menu.sas.app.qrCode': 'My QR code',
  'menu.sas.app.faceDatabase': 'Face Library',
  'menu.sas.app.faultRepair': 'Malfunction Report',
  'menu.sas.app.myAccess': 'My Access',
  'menu.vst.app.manage': 'Smart Visitor',
  'menu.vst.app.reserve': 'Invitations',
  'menu.vst.app.visit': 'Visits',
  'menu.vst.app.confirm': 'Review',
  'menu.vst.app.statisticalAnalysis': 'Statistic',
  'menu.app.index': 'Home',
  'menu.app.my': 'Me',
};
