export default {
  403035: 'Currently in non-working hours, immediate use is not supported!',
  403036: 'You do not have permission to use this locker!',
  403038: 'Cannot sign in before the sign-in time!',
  'scan.signed.result.success.tip': 'Congratulations, Welcome to use it',
  403040: 'The selected time is in non-working hours, please choose another time to book！',
  403050:
    'User {value1} already has a shared locker in this group, and two lockers are not currently supported!',
  403051:
    'User {value1} already has a fixed locker in this group, and the two lockers are not currently supported!',
  403063: 'Your locker is not in the current {value1} cabinet, please go to the {value2} cabinet number {value3} to find your locker! '
};
