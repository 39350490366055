export default {
  'meeting.seat.manage.room': '會議室',
  'meeting.seat.manage.seat.label': '座位',
  'meeting.seat.manage.user.placeholder': '請選擇參會人',
  'meeting.seat.button.text.ok': '確定',
  'meeting.seat.room.template.none': '會議室無可用座位模板',

  'meeting.seat.button.text.save': '保存',
  'meeting.seat.button.text.back': '返回',
  'meeting.seat.manage.seat.label1': '座位編號',
  'meeting.seat.manage.seat.username': '參會人姓名',
};
