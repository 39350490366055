import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { history } from 'umi';
import { queryCurrentUserInfo, getHasNoPurviewDoorList, applyLockPurview } from './service';

export default {
  namespace: 'applyAccess',
  state: {
    currentUser: {},
    hasNoPurviewDoorList: [],
    applyReason: '',
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetchCurrentUserInfo(_, { call, put }) {
      const response = yield call(queryCurrentUserInfo);
      if (response.code === 0) {
        const { sysUser } = response.data;
        yield put({
          type: 'setState',
          payload: {
            currentUser: sysUser,
          },
        });
      }
    },
    *getHasNoPurviewDoorList(_, { call, put }) {
      const response = yield call(getHasNoPurviewDoorList);
      if (response.code === 0) {
        const data = response.data || [];
        const doorList = [];
        if (data.length > 0) {
          data.forEach((item) => {
            const tempItem = { ...item };
            tempItem.bCheck = false;
            doorList.push(tempItem);
          });
        }
        yield put({
          type: 'setState',
          payload: {
            hasNoPurviewDoorList: doorList,
            applyReason: '',
          },
        });
      }
    },
    *applyLockPurview({ params }, { call }) {
      const response = yield call(applyLockPurview, { ...params });
      if (response.code === 0) {
        const errorText = formatMessage({ id: response.msg });
        Toast.success(errorText);
        setTimeout(() => {
          history.push('/accessApp/myAccess');
        }, 3000);
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
  },
  subscriptions: {
    setup({ history: umiHistory, dispatch }) {
      return umiHistory.listen(({ pathname }) => {
        if (pathname === '/accessApp/applyAccess') {
          dispatch({ type: 'fetchCurrentUserInfo' });
          dispatch({ type: 'getHasNoPurviewDoorList' });
        }
      });
    },
  },
};
