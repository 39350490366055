export default {
  'component.globalHeader.search': '站內搜索',
  'component.globalHeader.search.example1': '搜索提示一',
  'component.globalHeader.search.example2': '搜索提示二',
  'component.globalHeader.search.example3': '搜索提示三',
  'component.globalHeader.help': '使用文檔',
  'component.globalHeader.notification': '通知',
  'component.globalHeader.notification.empty': '你已查看所有通知',
  'component.globalHeader.message': '消息',
  'component.globalHeader.message.empty': '您已讀完所有消息',
  'component.globalHeader.event': '待辦',
  'component.globalHeader.event.empty': '你已完成所有待辦',
  'component.noticeIcon.clear': '清空',
  'component.noticeIcon.cleared': '清空了',
  'component.noticeIcon.empty': '暫無數據',
  'packages.expire.tip': '您的產品已過期，請重新登錄',
  'router.title.HomePage': '首頁',
  'router.title.MyInfo': '我的',
  'app.title': '威思客智能辦公',
  'router.title.meeting.HomePage': '智能會議',
  'router.default.title': '智能辦公',

  'router.title.setting': '設置',
  'router.title.setting.lang': '設置語言',
  'router.title.setting.timezone': '設置時區',
  'router.title.setting.zone': '設置默認區域',
  'common.action.success': '操作成功',
  'common.action.fail': '操作失敗',

  'menu.points.app-operation': '積分管理',
  'menu.points.app.account': '賬戶餘額',
  'menu.points.app.bill': '我的賬單',
  'menu.points.app.punishment': '違規檢查',

  'menu.tcs.app.toilet': '廁衛管理',
  'menu.tcs.app.usestatus': '隔間占用狀態',
  'menu.tcs.app.alarm.list': '告警記錄',
  'menu.parameter.is.incorrect': '參數不正確',

  'global.guidance.skip': '跳過',
  'global.guidance.previous': '上壹步',
  'global.guidance.next': '下壹步',
  'global.guidance.finish': '完成',

  'login.feishu.err.title': '認證異常，請重新進入應用',
};
