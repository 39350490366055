/**
 * Created by XUQIANG on 2017/03/14.
 */
import index from './en_US/index';
import app from './en_US/app';
import reservation from './en_US/reservation';
import visit from './en_US/visit';
import admin from './en_US/admin';
import register from './en_US/register';
import report from './en_US/report';
import group from './en_US/group';
import meeting from './en_US/meeting';
import machine from './en_US/machine';
import selfService from './en_US/selfService';
import checkIn from './en_US/checkIn';
import blacklist from './en_US/blacklist';
import custom from './en_US/custom';
import status from './en_US/status';
import InvitationCode from '../pages/InvitationCode/locales/en-US';

export default {
  locale: 'en_US',
  pluralRuleFunction: () => {
    return 'other';
  },
  ...index,
  ...admin,
  ...app,
  ...reservation,
  ...visit,
  ...register,
  ...report,
  ...meeting,
  ...group,
  ...machine,
  ...selfService,
  ...checkIn,
  ...blacklist,
  ...custom,
  ...status,
  ...InvitationCode,
};
