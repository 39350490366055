export default {
  'locker.immediate.hours': 'Hours',
  'locker.immediate.submit': 'Instant Use',
  'locker.immediate.station': 'Locker',
  'locker.immediate.address': 'Location',
  'locker.immediate.time': 'Time to Use',
  'locker.immediate.ok': 'Confirm',
  'locker.immediate.cancel': 'Cancel',
  'locker.immediate.submit.success': 'Congratualtions I Welcome to use it',
  403000: 'System exception',
  403003: 'The parameter is incorrect',
  403013: 'Locker does not exist',
  403014: 'Lockers are not bound',
  403032: 'Locker has been occupied',
  403033: 'No support for booking two or more lockers!',
  403034: 'System working day time is not set',
};
