export default {
  phone_contacts_value1: '从手机通讯录选择',
  phone_contacts_value2: '请输入您要搜索的内容',
  phone_contacts_value3: '只支持选择大陆或香港地区移动电话',

  'external.contacts.add': '添加外部参会人',
  'external.contacts.name': '姓名',
  'external.contacts.name_': '姓名：',
  'external.contacts.department': '部门',
  'external.contacts.position': '职位',
  'external.contacts.mail': '邮箱',
  'external.contacts.phone': '手机',
  'external.contacts.company': '公司',
  'external.contacts.placeholder': '请输入',
  'external.contacts.name.fail': '请输入姓名',
  'external.contacts.department.fail': '请输入部门',
  'external.contacts.position.fail': '请输入职位',
  'external.contacts.mail.null.fail': '请输入邮箱',
  'external.contacts.mail.fail': '邮箱格式不正确',
  'external.contacts.phone.null.fail': '请输入手机号',
  'external.contacts.phone.fail': '手机格式不正确',
  'external.visitor.notice': '访问邀约',

  'meeting.booking.select.outer.form.name.pls': '请输入姓名',
  'meeting.booking.select.outer.form.tel.pls': '请输入手机号码',
  'meeting.booking.select.outer.form.tel.mail.pls': '请输入手机号码或邮箱',
  'meeting.booking.select.outer.form.email.pls': '请输入邮箱',
  'meeting.booking.select.outer.form.tel.error': '手机号码格式不正确',
  'meeting.booking.select.outer.form.email.error': '邮箱格式不正确',
  'meeting.booking.select.outer.form.phone.error': '手机号码{phone}重复',
  'meeting.booking.select.outer.form.email.error': '邮箱{email}重复',
  'meeting.booking.select.outer.form.country.china': '中国 +86',
  'meeting.booking.select.outer.form.country.china.hk': '中国香港 +852',
};
