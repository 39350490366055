/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import * as searchService from './service';

export default {
  namespace: 'swsFindStation',
  state: {
    isSearching: false,
    searchList: [],
    station: {},
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询用户位置信息
    *getStationMapByUser({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(searchService.getStationMapByUser, param);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            searchList: result.data,
          },
        });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        // 监听路由变化，并发送类别查询请求
        if (pathname && pathname.includes('/workspace/station/search')) {
          dispatch({ type: 'swsCommon/getSetting' });
          // console.log('setup search')
          dispatch({
            type: 'swsCommon/saveOrUpdateData',
            payload: {
              mapId: '',
              locationSelected: '',
            },
          });
          const payload = {
            size: 10,
            current: 1,
          };
          dispatch({
            type: 'getStationMapByUser',
            payload,
          });
        }
      });
    },
  },
};
