import { useMemo, useCallback } from 'react';
import { useSelector } from 'umi';
import { useMemoizedFn } from 'ahooks';

export default function () {
  const { menuData = [] } = useSelector((state: any) => state.menu) || {};

  const flattenChildrenToArray = useMemoizedFn((nestedArray: any) => {
    const flattenedArray = [];
    for (const item of nestedArray) {
      if (item?.children && Array.isArray(item.children)) {
        flattenedArray.push(...item.children);
        const deeperChildren = flattenChildrenToArray(item.children);
        flattenedArray.push(...deeperChildren);
      }
    }
    return flattenedArray;
  });

  const menuList = useMemo(() => {
    return flattenChildrenToArray(menuData);
  }, [menuData]);

  const checkMenuVisible = useCallback(
    (permission: string) => {
      return menuList.some((menu) => menu?.permission?.includes(permission));
    },
    [menuList],
  );

  return { checkMenuVisible };
}
