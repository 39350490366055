export default {
  'external.contacts.add': 'Add external attendee(s)',
  'external.contacts.name': 'Name',
  'external.contacts.name_': 'Name：',
  'external.contacts.department': 'Department',
  'external.contacts.position': 'Position',
  'external.contacts.mail': 'Email',
  'external.contacts.phone': 'Mobile',
  'external.contacts.company': 'Company',
  'external.contacts.placeholder': 'Please enter',
  'external.contacts.name.fail': 'Please enter the name',
  'external.contacts.department.fail': 'Please enter the department',
  'external.contacts.position.fail': 'Please enter the position',
  'external.contacts.mail.null.fail': 'Please enter the email',
  'external.contacts.mail.fail': 'Incorrect email format',
  'external.contacts.phone.null.fail': 'Please enter the phone number',
  'external.contacts.phone.fail': 'Incorrect phone number format',
  'external.visitor.notice': 'Invite',
  'external.contacts.edit': 'Modify',
  'external.contacts.del': 'Delete',
  'external.contacts.placeholder.name': 'Please enter name',
  'external.contacts.placeholder.phone': 'Please input mobile phone',
  'external.contacts.placeholder.email': 'Please enter email address',
  'external.contacts.placeholder.company': 'Please enter company',
  'meeting.booking.warning.visitor.canot.audit':
  'The selected conference room contains approval conference room, and cannot invite visitors',
  'external.visit.outuser': 'Visitor',
};
