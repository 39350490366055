export default {
  group_new: 'New Invites',
  group_subject: 'Theme',
  group_subject_tip: 'Please enter the theme',
  group_host: 'Receptionist',
  group_host_tip: 'Please enter and select the correct Receptionist',
  group_photo: 'Photo',
  group_loginUrl_: 'Group invitation registration form：',
  group_subject_: 'Theme：',
  group_host_: 'Receptionist：',
  group_address_: 'Address：',
  group_address: 'Address',
  group_date_: 'Access Time：',
  group_date: 'Access Time',
  group_detail: 'Details',
  group_invite: 'New',
  group_mail: 'Email',
  group_mail_tip: 'Please enter the email',
  group_success: 'Success',
  group_formTitle: 'The registration form',
  group_carNumber: 'License plate No.',
  group_carNumber_tip: 'Please enter License plate No.',
};
