import request, { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH, ACCESS_TOKEN } from '@/utils/constant';

/**
 * 获取部门树
 * @param meetingId
 * @returns {Object}
 */
export async function getDeptTree() {
  return request(`/${RESTFUL_PATH.admin}/dept/tree`);
}

export async function getOrderInfo({ current, size, accountType, businessType }) {
  return request(
    `/${RESTFUL_PATH.points}/business-info/page?current=${current}&size=${size}&accountType=${accountType}&businessType=${businessType}`
  );
}

export async function getOrderRules({ businessType }) {
  return request(`/${RESTFUL_PATH.points}/billing-rules/list?businessType=${businessType}`);
}

/* 上传图片 */
export async function updateLoadImage({ formData }) {
  return requestNoAuthorization(`/${RESTFUL_PATH.sss}/annex/upload`, {
    method: 'POST',
    data: formData.formData,
    headers: {
      Authorization: `bearer ${sessionStorage.getItem(ACCESS_TOKEN)}`,
    },
  });
}

export async function getUserInfoById({ userId }) {
  return request(`/${RESTFUL_PATH.upms}/user/user-info/${userId}`);
}

export async function cancelPunishmentOrder({ id }) {
  return request(`/${RESTFUL_PATH.points}/business-info/punishment/cancel?orderNumber=${id}`);
}

export async function createPunishmentOrder(params) {
  return request(`/${RESTFUL_PATH.points}/order-info/create-punishment-order`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
