export default {
  main_meeting_zoom_poll_subject_add: '添加題目',
  main_meeting_zoom_poll_remove: '刪除調查',
  main_meeting_zoom_poll_publish: '確定',
  main_meeting_zoom_poll_tool_edit: '編輯',
  main_meeting_zoom_poll_tool_copy: '複製',
  main_meeting_zoom_poll_tool_up: '上移',
  main_meeting_zoom_poll_tool_down: '下移',
  main_meeting_zoom_poll_tool_del: '刪除',
  main_meeting_zoom_poll_single_subject: '單選題',
  main_meeting_zoom_poll_multiple_subject: '多選題',
  main_meeting_zoom_poll_confirm: '確認要刪除調查問卷？',
};
