export default {
  'menu.envm.app.env_monitor': 'Environment Monitoring',
  'menu.envm.app.env_dashborad': 'Real-time Data',
  'menu.envm.app.history': 'History Data',
  'env.temperature.indoor': 'Room Temp',
  'env.humidity.indoor': 'Room Humidity',

  'env.excellent': 'Excellent',
  'env.good': 'Good',
  'env.normal': 'Normal',
  'env.damp': 'Damp',
  'env.dry': 'Dry',
  'env.too.high': 'Too high',
  'env.high': 'High',
  'env.low': 'Low',
  'env.too.low': 'Too low',
  'env.scorching': 'Scorching',
  'env.hot': 'Hot',
  'env.comfortable': 'Cozy',
  'env.lightly.cold': 'Slightly cold',
  'env.cold': 'Cold',
  'env.heavily.polluted': 'Seriously',
  'env.heavy.pollution': 'Severely',
  'env.middle.pollution': 'Moderately',
  'env.lightly.pollution': 'Light',
  'env.lightly.exceeded': 'Slightly',
  'env.middle.exceeded': 'Moderately',
  'env.severely.exceeded': 'Severely',
  'env.seriously.exceeded': 'Seriously',
  'env.avoid.outside': 'Avoid staying outside',
  'env.reduce.outside': 'Reduce outdoor activities',
  'env.sensitive.people': 'Sensitive people beware',
  'env.reduce.outside.exercise': 'Reduce strenuous exercise',
  'env.normal.activity': 'Normal activity',
  'env.fresh.air': 'Fresh air',
  'env.discomfort': 'Severe discomfort',
  'env.lightly.discomfort': 'Slight discomfort',
  'env.heavily.discomfort': 'Poor health',
  'env.timely.ventilation': 'Timely ventilation',
  'env.irritant.gas': 'Irritant gas',
  'env.slight.odor': 'Slight smell',

  'env.device': 'Device',
  'env.device.number': 'Device Number',
  'env.device.tip': 'Please select a device',
  'env.current.day': 'Today',
  'env.current.week': 'This week',
  'env.value.min': 'MIN',
  'env.value.max': 'MAX',
  'env.value.avg': 'AVG',

  'env.reference.temp': 'Reference: 16℃~27℃ Comfortable',
  'env.reference.hum': 'Reference: 30%~50% Comfortable',
  'env.reference.pm25': 'Reference: <=35 μg/m³ Fresh air',
  'env.reference.tvoc': 'Reference: <=400 PPb Fresh air',
  'env.reference.co2': 'Reference: <=500 PPm Fresh air',

  'home.week.1': 'Mon',
  'home.week.2': 'Tue',
  'home.week.3': 'Wed',
  'home.week.4': 'Thu',
  'home.week.5': 'Fri',
  'home.week.6': 'Sat',
  'home.week.7': 'Sun',

  'env.device.none': 'Please bind the device!',
  'cloud.nodata': 'No data',

  'env.device.select': 'Click to select device',

  'cloud.expired.renewal.tip1': 'Your subscription has expired, please log in with your browser and renew (Enterprise Administrator)',
  'cloud.expired.renewal.tip2': 'If the subscription is not renewed within 180 days after it expires, the system will automatically log out the tenant and delete all data',
  'cloud.expired.renewal.tip5':
    'You do not have permission to access',
  'cloud.expired.renewal.tip6':
    'It is recommended to check whether you already have a role or whether the role has been assigned a menu(Enterprise Administrator)',

  'cloud.privacy.policy': 'Privacy Policy',
  'cloud.service.terms': 'Terms of Service',
}