import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import {
  queryNotices,
  updatePassword,
  updatedLangService,
  updatedTimeZoneService,
  changePhone,
  getAreTreeService,
  changeGuidance,
  updatedUserPrivacy,
  getFileCheck,
} from '@/services/user';
import { dingTalkTitleHandler } from '@/utils/utils';

export default {
  namespace: 'global',

  state: {
    collapsed: false,
    notices: [],
    isMobile: false,
    passwordModal: false,
    adminInfoModal: false,
    spaceTree: [],
    isTeams: false,
    isFeishu: false,
  },

  effects: {
    *changePhone({ payload }, { call, put }) {
      const data = yield call(changePhone, payload);
      if (data.code === 0) {
        yield put({
          type: 'user/getCurrentUserVo',
          payload: {},
        });
        Toast.success(formatMessage({ id: 'common.action.success' }));
      } else {
        Toast.fail(formatMessage({ id: 'common.action.fail' }));
      }
    },
    *fetchNotices(_, { call, put, select }) {
      const data = yield call(queryNotices);
      yield put({
        type: 'saveNotices',
        payload: data,
      });
      const unreadCount = yield select(
        (state) => state.global.notices.filter((item) => !item.read).length,
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: data.length,
          unreadCount,
        },
      });
    },
    *clearNotices({ payload }, { put, select }) {
      yield put({
        type: 'saveClearedNotices',
        payload,
      });
      const count = yield select((state) => state.global.notices.length);
      const unreadCount = yield select(
        (state) => state.global.notices.filter((item) => !item.read).length,
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: count,
          unreadCount,
        },
      });
    },
    *changeNoticeReadState({ payload }, { put, select }) {
      const notices = yield select((state) =>
        state.global.notices.map((item) => {
          const notice = { ...item };
          if (notice.id === payload) {
            notice.read = true;
          }
          return notice;
        }),
      );
      yield put({
        type: 'saveNotices',
        payload: notices,
      });
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: notices.length,
          unreadCount: notices.filter((item) => !item.read).length,
        },
      });
    },
    *updatePassword({ payload }, { put, call }) {
      const data = yield call(updatePassword, { payload });
      if (!data.code) {
        Toast.success(formatMessage({ id: 'common.operate.success.update' }));
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            passwordModal: false,
          },
        });
        /* 退出 */
        yield put({
          type: 'login/logout',
        });
      } else {
        Toast.fail(data.msg);
      }
    },
    *changeLang({ payload }, { call }) {
      const obj = {
        lang: 'zh_CN',
      };
      if (payload.key) {
        // 中文：zh_CN  英文：en_US 繁体：zh_TW
        if (payload.key === 'zh-TW' || payload.key === 'zh_TW') {
          obj.lang = 'zh_TW';
        } else if (payload.key === 'en-US' || payload.key === 'en_US') {
          obj.lang = 'en_US';
        }
      } else {
        // 中文：zh_CN  英文：en_US 繁体：zh_TW
        const umiLocale = localStorage.getItem('umi_locale');
        if (umiLocale === 'zh-TW') {
          obj.lang = 'zh_TW';
        } else if (umiLocale === 'en-US') {
          obj.lang = 'en_US';
        }
      }

      const data = yield call(updatedLangService, { payload: obj });
      if (data.code === 0) {
        Toast.success(formatMessage({ id: 'common.action.success' }));
      } else {
        Toast.fail(formatMessage({ id: 'common.action.fail' }));
      }
    },
    *changeTimeZone({ payload }, { call, put }) {
      const data = yield call(updatedTimeZoneService, { payload });
      if (data.code === 0) {
        yield put({
          type: 'user/fetchCurrentUserInfo',
          payload: {},
        });
        Toast.success(formatMessage({ id: 'common.action.success' }));
      } else {
        Toast.fail(formatMessage({ id: 'common.action.fail' }));
      }
    },
    *getAreTree(_, { call, put }) {
      const result = yield call(getAreTreeService);
      if (result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            spaceTree: result.data,
          },
        });
      } else {
        Toast.fail(formatMessage({ id: 'common.action.fail' }));
      }
    },
    // 改变新手引导标记
    *changeGuidance({ payload }, { call, put }) {
      const data = yield call(changeGuidance, payload);
      if (data.code === 0) {
        yield put({
          type: 'user/fetchCurrentUserInfo',
          payload: {},
        });
      } else {
        Toast.fail(formatMessage({ id: 'menu.account.adminInfo.lang.fail' }));
      }
    },
    *changeUserPrivacy({ payload }, { call, put }) {
      const data = yield call(updatedUserPrivacy, { payload });
      if (data.code === 0) {
        yield put({
          type: 'user/fetchCurrentUserInfo',
          payload: {},
        });
        Toast.success(formatMessage({ id: 'common.action.success' }));
      } else {
        Toast.fail(formatMessage({ id: 'common.action.fail' }));
      }
    },
    *getFileCheck({ payload }, { call }) {
      const data = yield call(getFileCheck, { ...payload });
      return data;
    },
  },

  reducers: {
    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload,
      };
    },
    saveNotices(state, { payload }) {
      return {
        ...state,
        notices: payload,
      };
    },
    saveClearedNotices(state, { payload }) {
      return {
        ...state,
        notices: state.notices.filter((item) => item.type !== payload),
      };
    },
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  subscriptions: {
    setup({ history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(({ pathname, search }) => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
        dingTalkTitleHandler()
      });
    },
  },
};
