import { encryptionKey } from './constant';

import encUtf8 from 'crypto-js/enc-utf8';
import aes from 'crypto-js/aes';
import encBase64 from 'crypto-js/enc-base64';

export const encrypt = (word) => {
  const key = encUtf8.parse(encryptionKey);
  const srcs = encUtf8.parse(word);
  const encrypted = aes.encrypt(srcs, key);
  return encrypted.toString();
};

export const decrypt = (word) => {
  const key = encUtf8.parse(encryptionKey);
  const decrypt1 = aes.decrypt(word, key);
  return encUtf8.stringify(decrypt1).toString();
};

// 云服务认证加密 base64编码
export const base64String = (word) => {
  const str = encUtf8.parse(word);
  return encBase64.stringify(str);
};

// SSO key编解码计算
export const encodeAuth = (auth) => {
  return window.btoa(encodeURIComponent(JSON.stringify(auth)));
};
