import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export function getMeetingDetail(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/detail-info/${meetingId}`, {
    method: 'GET',
  });
}

export function getReplay(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/signer-info/${meetingId}`, {
    method: 'GET',
  });
}

export const receiptOptionFuc = ({ param }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/receipt`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
};

export const getMeetingAgenda = ({ meetingId }) => {
  return request(`/${RESTFUL_PATH.meeting}/agenda-info/queryAll/${meetingId}`, {
    method: 'GET',
  });
};

export function cancelMeeting({ meetingId, recurringId }) {
  if (recurringId) {
    return request(`/${RESTFUL_PATH.meeting}/meeting-info/cycle-cancel`, {
      method: 'POST',
      body: JSON.stringify({ recurringId }),
    });
  }
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/cancel`, {
    method: 'POST',
    body: JSON.stringify({ meetingId }),
  });
}

export function closeMeeting({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/end/${meetingId}`, {
    method: 'POST',
  });
}

export function delayMeeting({ meetingId, interval }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/extend/${meetingId}/${interval}`, {
    method: 'POST',
  });
}

/**
 * 获取会议议程
 * @param meetingId
 * @returns {Object}
 */
export const getAgendaList = ({ meetingId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-agenda/list/${meetingId}`, {
    method: 'GET',
  });
};

export function checkWebEx({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-webex/checkWebex/${meetingId}`, {
    method: 'GET',
  });
}
export function getZoomUrl() {
  return request(`/${RESTFUL_PATH.meeting}/meeting-zoom/getZoomUrl`, {
    method: 'GET',
  });
}

export const getWebexInfoFuc = ({ param }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-webex/getWebexInfo`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
};

/**
 * 获取zoom会议参会方信息
 * @param meetingId
 * @returns {Object}
 */
export const getZoomPolling = ({ meetingId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-zoom/getZoomPoll?meetingId=${meetingId}`, {
    method: 'GET',
  });
};

// cma 会议相关信息
export async function getMeetingCmmaInfo({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/cmma/cmmaInfo/${meetingId}`, {
    method: 'GET',
  });
}