import request, {requestNoAuthorization} from '@/utils/request';
import { stringify } from 'qs';
import {RESTFUL_PATH, MENU_GROUPING, ACCESS_TOKEN} from '@/utils/constant';
import { formatStringNoTrim } from '@/utils/utils';

export async function query() {
  return request('/api/users');
}

export async function queryCurrent() {
  return request('/api/currentUser');
}

export async function queryNotices() {
  return request('/api/notices');
}

export async function getMenu() {
  return request(`/${RESTFUL_PATH.admin}/menu/grouping/${MENU_GROUPING}?p=w`);
}

export async function queryCurrentUserInfo() {
  return request(`/${RESTFUL_PATH.admin}/user/info`);
}
export async function getUserInfoServe() {
  return request(`/${RESTFUL_PATH.admin}/user/getCurrentUserVo`);
}

export async function getCurrentUserVo() {
  return request(`/${RESTFUL_PATH.admin}/user/getCurrentUserVo`);
}

// export async function updatePassword({ payload }) {
//   return request(`/${RESTFUL_PATH.admin}/user/updatePwd`, {
//     method: 'POST',
//     body: JSON.stringify(payload),
//   });
// }

export async function updatePassword({ payload }) {
  return request(
    `/${RESTFUL_PATH.admin}/user/updatePwd?newPwd=${payload.newPwd}&pwd=${payload.pwd}&reNewPwd=${payload.reNewPwd}`,
    {
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );
}

export async function updatePassword2({ payload }) {
  return request(`/${RESTFUL_PATH.admin}/user/updatePwd`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export async function updatedLangService({ payload }) {
  return request(`/${RESTFUL_PATH.admin}/user/update/lang?lang=${payload.lang}`, {
    method: 'POST',
  });
}

export async function updatedTimeZoneService({ payload }) {
  return request(`/${RESTFUL_PATH.admin}/user/set-timezone`, {
    method: 'POST',
    body: payload.timeZone,
  });
}

export async function getAreTreeService() {
  return request(`/${RESTFUL_PATH.admin}/space/tree`);
}

export async function getSpace(id) {
  return request(`/${RESTFUL_PATH.admin}/space/all/${id}`);
}

export async function getTimeZoneTenantList() {
  return request(`/${RESTFUL_PATH.admin}/time-zone-tenant/list`);
}
export function changePhone(data) {
  window.console.log(data);
  return request(
    `/${RESTFUL_PATH.admin}/user/update/phone?phone=${encodeURIComponent(data.phone.trim())}`,
    {
      method: 'POST',
    },
  );
}

export async function getTenantUserVo() {
  return request(`/${RESTFUL_PATH.admin}/tenant/info`);
}

export function changeGuidance({ productCode }) {
  return request(`/${RESTFUL_PATH.admin}/user/record/guidance?productCode=${productCode}`, {
    method: 'POST',
  });
}

export async function updatedUserPrivacy({ payload }) {
  return request(`/${RESTFUL_PATH.admin}/user/set-privacy-status`, {
    method: 'POST',
    body: payload.privacyStatus,
  });
}


export async function getUserFaceMenuByProductCode(payload) {
  return request(`/${payload.service}/getFaceMenuByProductCode`);
}


/**
 * 获取用户当前可用的产品标识列表
 * @returns {Promise<any>}
 */
export async function getUserProductCodes() {
  return request(`/${RESTFUL_PATH.cloudOms}/tenants/using/all/productCodes`);
}


/* 上传头像 */
export async function updateAvatarImage(formData) {
  return requestNoAuthorization(`/${RESTFUL_PATH.upms}/sss/upload`, {
    method: 'POST',
    body: formData.formData,
    headers: {
      Authorization: `bearer ${sessionStorage.getItem(ACCESS_TOKEN)}`,
    },
  });
}

/* 修改用户头像 */
export async function updatedUserAvatar(payload) {
  return request(`/${RESTFUL_PATH.admin}/user/set-avatar`, {
    method: 'POST',
    body: payload.avatar,
  });
}


/**
 * 获取文件检查
 * @param {*} mapId
 * @returns
 */
export async function getFileCheck(params) {
  const searchData = formatStringNoTrim(params.payload);
  const queryParameters = `${stringify(searchData)}`;

  return request(`/${params.service}/sss/check?${queryParameters}`, {
    method: 'GET',
  });
}
