export default {
  // APP页面title
  'app.visitor.reserve': 'Review  ',
  'app.visit.record': 'Visits',
  'app.new.reserve': 'New',
  'app.visitor.confirmed': 'Confirm',
  'app.visitor.Invites': 'Invitations',
  'app.visitor.Visitors': 'Visits',
  'app.visitor.Confirmed': 'Review  ',
  'app.visitor.statisticAnalysis': 'Statistic',
  'app.visitor.sum': 'Confirmations',
  'app.reserve.detail': 'Details',
  'app.visit.type': 'Purpose',
  'app.reserve.delay': 'Modify',
  'app.reserve.again': 'Re-invite',
  'app.detail': 'List details',
  'app.visitor.title': 'Visitor information',
  // 其他
  'app.time.format': 'YYYY-M-D HH:mm ',
  'app.no.data': 'No data',
  'app.loading': 'Loading',
  'app.loading...': 'Loading...',
  'app.new.associates': 'Add other receptionists of this invitation',
  app_ass_tip: 'The maximum number of the associates is 5',
  // 自助预约
  'app.self.rightHost.tip': 'Please enter the correct contact',
  'app.self.rightHost.tip2': 'Please select the place to be visited first',
  'app.self.confirmed': 'Confirmed',
  'app.self.NoConfirmed': 'Unconfirmed',
  'app.self.photo': 'Face image',
  'app.self.isConfirmed': 'Save',

  // 邀请码
  app_invitation_letter: 'Invitation Letter',
  app_invitation_code_fail: 'Fail to get the invitation code',
  'app.loading.freshed': 'Freshed',
  'app.loading.pullToRefresh': 'Pull down to refresh',
  'app.loading.releaseAndfreshed': 'Release to refresh',
  'app.loading.reject': 'Reject',
  'app.loading.agree': 'Approve',
  'app.loading.noListShow': 'No data?',
  'app.loading.noListShow.add': 'Click New Invitation to create a new visitor ~',
  'app.loading.pleaseInputOk': 'Please enter the correct content to search',
  'app.loading.pleaseInputValid': 'Please enter at least 1 characters',
  'app.loading.today': 'Today',
  'app.analysis.today': 'Today',
  'app.analysis.week': 'Week',
  'app.analysis.month': 'Month',
  'app.analysis.year': 'Year',
  'app.analysis.history': 'Historical visitors',
  'app.analysis.Invites': 'Invited visitors',
  'app.analysis.reserve': 'Self-booking visitors',
  'app.analysis.temporary': 'Walk-in Visitors',
  'app.visitors.input': 'Search by keyword',

  'app.visitor.info': 'Visitor Information',
  'app.access.info': ' Visit Information',
  'app.meeting.details': 'Details',
  'app.meeting.info': 'Meeting Information',
  'app.new.visitor': 'New Visitors',
  'app.new.visitor1': 'New Visitors',
  'app.phone.address': 'Select from phone contacts',
  'app.phone.business_card': 'Select from business card(only simplified Chinese supported)',
  'app.visitors.state': 'Status',
  'app.create.visitor.error': 'Name is required',
  'visit.reserve.visitor.phone.or.email.required': 'Phone and email fill in at least one',
  'visit.reserve.visitor.phone.required': 'Phone is required',
  'app.create.visitor.error2': 'Name, ID Card are mandatory',
  'app.create.number.error': 'Visitor info duplication, please revise and try again',
  'app.contacts.select': 'Select',
  'app.upload.businessCard': 'Recognizing...',

  'app.connect': 'Only phone number in mainland China or Hong Kong can be selected',
  'app.connect.none': 'User does not exist',
  'app.map.none': 'Query failure',

  'app.visitor.call': 'Dear',
  'app.visitor.hello': 'Hello，',
  'app.visitor.hello_1': '!',
  'app.visitor.tip':
    'The following information can help you with your visit. I wish you a smooth visit！',
  'app.visitor.text':
    'Open the door by QR code scanning or sign in with the following invitation code',
  'app.visitor.signInTime': 'Arrival Time：',
  'app.invitation.tip1': 'The enterprise does not have a navigation point',
  'app.invitation.tip2': 'Please contact the host directly',
  'app.reserve.visit.online.zoom': 'Zoom: ',
  'app.reserve.visit.online.webx': 'Webex: ',
  'app.reserve.visit.online.teams': 'Teams：',
  'app.reserve.visit.online.flyBook': 'Feishu：',
  'app.reserve.visit.online.tencent': 'Tencent: ',
  'app.reserve.visit.online.video': 'Video: ',
  'visit.reserve.visit.online.zoom.warn': 'Only one meeting type can be selected',
  'visit.reserve.meetingRoom.tip': 'Conference room cannot be empty',
  'visit.reserve.meetingRoom': 'Select room',

  'visit.meetingRomm.name': 'MeetingRoom',
  'visit.meetingRomm.name.type': 'Meeting Type',
  'visit.meetingRomm.time': 'Start Time',
  'visit.meetingRomm.end.time': 'End Time',
  'visit.meetingRomm.time.length': 'Meeting Duration',
  'visit.meetingRomm.time.min': 'Minutes',
  'visit.reserve.detail.online.zoom': 'Zoom',
  'visit.reserve.detail.online.webx': 'Webex',
  'visit.reserve.detail.online.teams': 'Teams',
  'visit.reserve.detail.online.flyBook': 'Feishu',
  'visit.reserve.detail.online.tencent': 'Tencent',
  'visit.reserve.detail.online.video': 'Video',
  'visit.reserve.detail.online.ordinary': 'Ordinary',
  'visit.meeting.detail': 'Meeting Detail',

  'visit.reserve.detail.tip': 'Are you sure you want to cancel',
  'visit.reserve.detail.tip1': "'s reservation?",
  'visit.reserve.detail.reject.tip': 'Are you sure you want to reject',
  'visit.reserve.detail.reject.tip1': "'s visit?",
  'visit.common.ok': 'Yes',
  'visit.common.cancel': 'No',
  'app.reserve.new.visitor': 'Please complete the current visitor information',
  'app.reserve.new.visitor.tip': 'New visitor cannot be added by editing invitation',
  'app_invitation_img.title': 'Photo',
  'app_invitation_img.upload.success': 'Upload Success',

  'visit.reserve.visitor.repeat.phone.tip':
    "The visitor's mobile phone cannot be the same as that of the current user",
  'visit.reserve.visitor.repeat.mail.tip':
    'The email address of the guest cannot be the same as that of the current user',
  'app.reject.reason.placeholder': 'Please enter the reason for rejection',
  'app.reject.reason': 'Reject reason',
  'app.currentUser.error': 'Unable to obtain user information, please try again later',

  'app.self.custom.tip': 'Please enter the content',
  'visit.delay.tip':
    'The invitation information is changed, please reconfirm the visitor information.',

  'visit.reserve.loop.mode': 'Frequency ',
  'visit.reserve.loop.range': 'Repeat Range',
  'visit.reserve.loop.times': 'Occurrence',
  'visit.reserve.loop.count': 'occurrence(s)',
  'visit.reserve.loop.day.count': 'Every',
  'visit.reserve.loop.day.type': 'Every Weekday',
  'visit.loop.not.allow': 'Meeting is not allow loop',
  'visit.loop.not.allow2': 'Cycle is not supported across days',
  'visit.reserve.loop.week.type': 'Weekly',
  'visit.reserve.loop.week.1': 'Monday',
  'visit.reserve.loop.week.2': 'Tuesday',
  'visit.reserve.loop.week.3': 'Wednesday',
  'visit.reserve.loop.week.4': 'Thursday',
  'visit.reserve.loop.week.5': 'Friday',
  'visit.reserve.loop.week.6': 'Saturday',
  'visit.reserve.loop.week.7': 'Sunday',
  'visit.reserve.loop.count.tip': 'Enter the number of repetitions',
  'visit.reserve.loop.times.tip': 'Enter the repetition days',
  'visit.reserve.loop.day': 'day(s)',
  'visit.reserve.loop.week': 'Week',
  'visit.reserve.loop.month': 'Month',
  'visit.reserve.loop.title': 'Occurrence',
  'visit.reserve.loop.tip.max':
    'The number of appointments in this cycle cannot exceed the maximum reservation limit',
  'visit.reserve.loop.tip.min':
    'The number of appointments in this cycle cannot be less than the minimum reservation limit',
  'visit.reserve.loop.tip.max.day':
    'The appointment days of this cycle cannot exceed the maximum reservation limit ',
  'visit.reserve.loop.tip.min.day':
    'The appointment days of this cycle cannot be less than the minimum reservation limit',

  'visit.loop.info': 'Recurrence',
  'visit.loop.not': 'Does not repeat',
  'visit.loop.day': 'By day',
  'visit.loop.week': 'By week',
  'visit.loop.month': 'By month',

  // v6.6 访客主页
  'visit.visitor.homepage': 'Visitor management ',
  'visit.visitor.homepage.title': 'Easy and convenient reception and visit',
  'visit.visitor.homepage.resever': 'Invitations',
  'visit.visitor.homepage.visit': 'Visits',
  'visit.visitor.homepage.shenpi': 'Review  ',
  'visit.visitor.homepage.tongji': 'Statistic',
  'visit.visitor.homepage.invite': 'Invite visitors',

  'visit.reserve.edit.error': 'No change, please modify and try again',

  'visit.visitor.epidemic.title': 'Epidemic prevention code',
  // v6.8
  'visit.visitor.avatar': 'Visitor Avatar',
  // v6.9
  'app.meeting.hand.sign': 'Visitor signature',
  'app.visitor.all': 'All',
  'app.visitor.my.invite': 'My Invitation',
  'app.visitor.visit.me': 'My Visitor',

  'app.batch.invitation.company': 'Company',
  'app.batch.invitation.time': 'Time',
  'app.batch.invitation.type': 'Cause ',

  app_batch_invitation_code: 'Visitor information',

  'app.loading.noListShow.teams': 'No data.',
  'app.loading.noListShow.confirm': 'No visits to be reviewed',
  // v7.1
  app_invitation_health: 'Health Survey',
  app_invitation_meeting_info: 'Meeting information',
  app_invitation_meeting_topic: 'Title:',
  app_invitation_meeting_room: 'Room:',
  app_invitation_meeting_start: 'Start:',
  app_invitation_meeting_end: 'End:',
  app_invitation_meeting_id: 'Meeting ID:',
  app_invitation_meeting_no: 'Password:',
  app_invitation_meeting_link: 'Invite link:',
  'app.reject.cancel.placeholder': 'Please enter cancellation reason',
  'app.reject.cancel': 'Cancel reason',

  app_invitation_meeting_info_topic: 'Title',
  app_invitation_meeting_info_room: 'Room',
  app_invitation_meeting_info_start: 'Start',
  app_invitation_meeting_info_end: 'End',
  app_invitation_meeting_info_id: 'Meeting ID',
  app_invitation_meeting_info_no: 'Password',
  app_invitation_meeting_info_link: 'Invite link',

  'app.meeting.title': 'Meeting Info',
  'app.meeting.select': 'Select meeting room',
  'app.loading.select': 'Select',
  'app.visitor.home.page': 'Home',

  'visit.invitation.code.tip.err': 'QR code is invalid, click Retry ',
  'app_invitation_health.button.tip': '(please submit by {mouth} month {day}) ',

  'visit.invitation.code.edit.title.healthCode': 'Health code',
  'visit.invitation.code.edit.title.tourCode': 'Travel card',
  'visit.invitation.code.edit.title.vaccination': 'Vaccine certificate',
  'visit.invitation.code.edit.title.nucleic': 'Nucleic acid report',
  'visit.invitation.code.edit.tip':
    'Note: please upload the local health code, code, certificate of vaccination or nucleic acid testing report',
  'visit.invitation.code.edit.error': 'Please upload the local {errStr}',
  'app.fail.reason': 'Failure Reason',
  'visit.reserve.visitor.cancel.outer.meeting':
    'At the same time attending people from outside to delete',
  'visit.reserve.meeting.check.max.time.tip':
    'Meeting reservation time beyond the biggest single-game conference room {value} minutes',
  'visit.reserve.meeting.check.max.time.tip1':
    'Meeting booking time exceeds the maximum meeting time in the conference room',
  'visit.reserve.meeting.check.across.day.tip':
    'Across the meeting time is not greater than {value} days',
  'visit.reserve.meeting.check.across.permissions.tip':
    'Permissions across the current user does not have reservation day meeting',
  'visit.reserve.meeting.check.same.time.tip':
    'Meeting this time has been reserved, can not again booking',
  'visit.reserve.meeting.check.before.time.tip':
    'Request {time} minutes in advance for meeting room approval!',
  'visit.invitation.code.vx.share.title': '{companyName} Invitation Letter',
  'visit.reserve.visitor.cancel.send.notice': 'Send cancellation notices to visitors',
  'visit.reserve.copy.link': 'Copy Link',
  'visit.reserve.copy.link.success': 'The link is copied',
  'visit.reserve.generate.qrCode': 'QR Code',
  'visit.reserve.send.letter': 'Send invitations',
  'visit.reserve.share': 'Share',
  'visit.reserve.down.generate.qrCode': 'The invitation QR code.png',
  'visit.reserve.down.generate.qrCode.success': 'Download Success',
  'visit.reserve.read.generate.qrCode': 'Scan the code',
  'visit.visitor.information.repeat.tip': '{str} cannot be repeated',
  'visit.meeting.booking.cross.day.tip1':
    "Meetings allow appointments across day {num} days' meeting",
  'visit.meeting.booking.cross.day.tip2':
    'Cross-day meetings can be scheduled within {num} days. Meetings beyond {num} days require administrator approval ',
  'visit.reserve.universal.share': 'General Invitation',
  'visit.reserve.universal.share.tip':
    "The invition link does not include visitor's personal information. Visitors need to fill in the information by themselves.",
  'visit.reserve.exclusive.share': 'Exclusive Invitation',
  'visit.reserve.exclusive.share.tip':
    "The invition link contains the visitor's personal information. Visitor can edit or add more personal information.",
  'visit.reserve.information.filling': 'Visitor Filling',
  'visit.visitor.fill.sharepage.card.name': 'Dear {visitorName}, hello!',
  'visit.visitor.fill.sharepage.card.name.placeholder': 'visitor',
  'visit.visitor.fill.sharepage.card.universal.tip':
    '{inviterName} invites you to visit, please fill in your personal information recognition of qr code',
  'visit.visitor.fill.sharepage.card.exclusive.tip':
    '{inviterName} invites you to visit, please identify the qr code supplement or modify your personal information',
  'visit.visitor.fill.bottom.tip':
    'General Invitation link is only valid before the start of the visit, while the exclusive invitation is valid when the invitation status is "Not started".',
  'app.visitors.meeting.filter': 'Filter',
  'app.visitors.meeting.hour': 'h',
  'app.reserve.copy.link.tip': 'The visitor {value}, cannot copy the link or generate the QR code',
  'visit.reserve.visitor.repeat.current.chooes.user.phone.tip':
    "The visitor's mobile phone cannot be the same as that of the host employee",
  'visit.reserve.visitor.repeat.current.chooes.user.mail.tip':
    'The email address of the guest cannot be the same as that of the host employee',
};
