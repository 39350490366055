import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { fetch } from '@/pages/Meeting/services/sysModuleService';
import { RES_CODE } from '@/utils/constant';

export default {
  namespace: 'sysModule',
  state: {},
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetch(_, { call, put, select }) {
      const sysModule = yield select((state) => state.sysModule);
      if (sysModule && sysModule.base) {
        return;
      }
      const { data, code, msg } = yield call(fetch);
      if (code === RES_CODE.success) {
        yield put({
          type: 'update',
          payload: data,
        });
      } else {
        Toast.fail(msg ? formatMessage({ id: msg }) : '');
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname.indexOf('/meeting') !== -1) {
          //  console.log(1111)
          // if (pathname.indexOf('/meeting/index') !== -1) {
          //   return;
          // }
          dispatch({ type: 'fetch' });
        }
      });
    },
  },
};
