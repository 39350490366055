/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  cloudHub_finish: '完成',
  cloudHub_virsical: '威思客',
  cloudHub_submit: '提交',
  cloudHub_hasbind: '账号已绑定',
  cloudHub_inputAccount: '请输入账号',
  cloudHub_notUserNameEmpty: '用户名不能为空!',
  cloudHub_notPassWordEmpty: '密码不能为空!',
  cloudHub_inputPassword: '请输入密码',
  cloudHub_close: '关闭',
  cloudHub_getStateFail: '获取用户绑定状态失败，请关闭重试',
  cloudHub_bindFail: '绑定账号失败',

  106000: '系统异常',
  106504: '认证失败！',
  106505: '用户名或密码错误！',
};
