export default {
  'sws.repair.list.time': '报修时间',
  'sws.repair.list.type': '故障类型',
  'sws.repair.list.status': '报修状态',
  'sws.repair.list.back': '取消',
  'sws.repair.list.cancel': '取消报修',
  'sws.repair.list.detail': '报修详情',
  'sws.repair.list.pending': '待处理',
  'sws.repair.list.processing': '处理中',
  'sws.repair.list.processed': '已处理',
  'sws.repair.no.data': '暂无记录',
  'sws.repair.success': '报修成功',
  'sws.repair.list.new': '新建报修',
  'sws.repair.list.new.title': '输入工位编号/姓名',
  'sws.repair.list.code.scan': '扫一扫',
  'sws.repair.list.record': '报修记录',
  'sws.repair.commit.repair': '提交报修',
  'sws.repair.fault.type.chose': '请选择设施类型',
  'sws.repair.upload.picture.chose': '上传照片(选填)',
  'sws.repair.upload.picture': '上传照片',
  'sws.repair.upload.picture.limit': '最多3张，图片格式可以为jpeg / jpg / png, 大小不超过5M',
  'sws.repair.text.comments': '文本备注(选填)',
  'sws.repair.text.no': '文本备注(无)',
  'sws.repair.fill.in.station.number': '请填写工位编号',
  'sws.repair.station.number.none': '工位编号不存在！',
  'sws.repair.fill.in.comments': '请填写备注',
  'sws.repair.chose.fault': '请选择报修故障！',
  'sws.repair.picture.larger': '图片不能大于5M',
  'sws.repair.scan.qrCode': '请扫描工位二维码',
  'sws.repair.search.placehold': '输入工位编号/姓名模糊查询',
  'sws.repair.search': '查询工位编号/姓名',
  401083: '二维码无法识别',
};
