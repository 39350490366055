import { matchPath, history } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';
import 'pinyin4js';
import Virsical from 'virsical-jssdk';
import { MENU_PATH } from '@/pages/Meeting/utils/constant';

const chooseTypes = {
  single: 1,
  multi: 2,
};

const getAreaCode = (phone) => {
  const obj = { code: '', mobile: '' };
  if (phone && phone.indexOf('+') === 0) {
    if (phone.indexOf('+86') === 0 && phone.length === 14) {
      obj.code = '+86';
      obj.mobile = phone.substring(3, phone.length);
    } else if (phone.indexOf('+852') === 0 && phone.length === 12) {
      obj.code = '+852';
      obj.mobile = phone.substring(4, phone.length);
    }
  } else if (phone && phone.indexOf('00') === 0) {
    if (phone.indexOf('0086') === 0 && phone.length === 15) {
      obj.code = '+86';
      obj.mobile = phone.substring(4, phone.length);
    } else if (phone.indexOf('00852') === 0 && phone.length === 13) {
      obj.code = '+852';
      obj.mobile = phone.substring(5, phone.length);
    }
  } else if (phone && phone.indexOf('86') === 0 && phone.length === 13) {
    obj.code = '+86';
    obj.mobile = phone.substring(2, phone.length);
  } else if (phone && phone.indexOf('852') === 0 && phone.length === 11) {
    obj.code = '+852';
    obj.mobile = phone.substring(3, phone.length);
  } else if (phone && phone.length === 11) {
    obj.code = '+86';
    obj.mobile = phone;
  } else if (phone && phone.length === 8) {
    obj.code = '+852';
    obj.mobile = phone;
  } else {
    obj.mobile = phone;
  }
  return obj;
};

const organizeDataByLetter = (arr) => {
  const letters = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`;
  const newArr = arr.map((item) => {
    const { name } = item;
    const phone = getAreaCode(item.phone);
    const key = `${item.name}_${item.mail ? item.mail : ''}_${phone.mobile ? phone.mobile : ''}`;
    const letter = window.PinyinHelper.convertToPinyinString(
      name,
      '',
      window.PinyinFormat.WITHOUT_TONE,
    ).toUpperCase();
    return { ...item, key, letter };
  });
  newArr.sort((a, b) => a.letter.charCodeAt(0) - b.letter.charCodeAt(0));
  const result = [{ letter: '#', data: [] }];
  const resultLetters = ['#'];
  newArr.forEach((item) => {
    const letter = item.letter.charAt(0);
    if (letters.indexOf(letter) === -1) {
      result[0].data.push(item);
    } else {
      const index = resultLetters.indexOf(letter);
      if (index >= 0) {
        result[index].data.push(item);
      } else {
        result.push({ letter, data: [item] });
        resultLetters.push(letter);
      }
    }
  });
  return { result, resultLetters };
};

const pageTypes = {
  innerContact: 1,
  outerContact: 2,
};

export default {
  namespace: 'meetingContact',
  state: {
    data: [],
    letters: [],
    pageType: pageTypes.innerContact,
    selectedKey: [],
    outerList: [],
    pageFor: null,
    chooseType: chooseTypes.multi,
    isVirsicalBrowser: '',
  },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload };
    },
    doWhenLoadOutContactFail() {
      Toast.info(formatMessage({ id: 'contact.load.fail' }), 3);
      history.replace(MENU_PATH.externalContact);
    },
  },
  effects: {
    *reset(_, { put }) {
      const contact = {
        data: [],
        letters: [],
        pageType: pageTypes.innerContact,
        selectedKey: [],
        outerList: [],
        pageFor: null,
        chooseType: chooseTypes.multi,
      };
      yield put({ type: 'set', payload: contact });
    },
    *loadForOuterContact({ payload: { contactArr } }, { put }) {
      // const { messages } = yield select((state) => state.i18n);
      // 姓名 手机 邮箱必须具备其一
      const noDuplicateArr = contactArr
        .filter((contact) => !!(contact.name || contact.phone || contact.mail))
        .map((contact) => {
          const name = contact.name || contact.phone || contact.mail;
          return { ...contact, name };
        });
      // 去重
      const newContactArr = [];
      noDuplicateArr.forEach((contact) => {
        const exist = newContactArr.some((cat) => {
          return (
            cat.name === contact.name && cat.phone === contact.phone && cat.mail === contact.mail
          );
        });
        if (!exist) {
          newContactArr.push(contact);
        }
      });
      // 按字母组织
      // const { result, resultLetters } = organizeDataByLetter(newContactArr, messages);
      const { result, resultLetters } = organizeDataByLetter(newContactArr);
      const contact = { pageType: pageTypes.outerContact, data: result, letters: resultLetters };
      yield put({ type: 'set', payload: contact });
    },
    *changeSelected({ payload: { key, info } }, { put, select }) {
      // const { messages } = yield select((state) => state.i18n);
      let { selectedKey = [], outerList } = yield select((state) => state.meetingContact);
      const keyValue = info.key
        ? info.key
        : `${info.name}_${info.mail ? info.mail : ''}_${info.phone ? info.phone : ''}_${
            info.company ? info.company : ''
          }`;
      if (selectedKey.indexOf(key) === -1) {
        selectedKey.push(key);
        outerList.push({
          name: info.name,
          email: info.mail,
          mobile: info.phone,
          companyName: info.company,
          key: keyValue,
        });
        // getAreaCode(info.phone, messages);
        getAreaCode(info.phone);
      } else {
        selectedKey = selectedKey.filter((selKey) => String(selKey) !== String(key));
        outerList = outerList.filter((item) => String(item.key) !== String(key));
      }
      yield put({
        type: 'set',
        payload: { selectedKey, outerList },
      });
    },
    *onChoose(_, { put, select }) {
      const { outerList } = yield select((state) => state.meetingContact);
      // 外部参会人
      const list = [];
      if (outerList && outerList.length > 0) {
        outerList.forEach((v) => {
          if (v && v.mobile) {
            const phone = getAreaCode(v.mobile);
            list.push({
              name: v.name,
              telAreaCode: phone.code,
              mobile: phone.mobile,
              email: v.email || v.mail,
              key: v.key,
              companyName: v.companyName,
            });
          }
        });
      }
      yield put({
        type: 'attendee/set',
        payload: { externalList: list },
      });
      history.go(-1);
    },
    *loadSelectedUser(__, { put, select }) {
      let { outerList } = yield select((state) => state.meetingContact);
      const { selectedKey = [] } = yield select((state) => state.meetingContact);
      const { externalList = [] } = yield select((state) => state.attendee);
      outerList = [];
      if (externalList) {
        externalList.forEach((user) => {
          if (user.name && user.mobile) {
            const key = `${user.name}_${user.email ? user.email : ''}_${
              user.mobile ? user.mobile : ''
            }`;
            if (selectedKey.indexOf(key) === -1) {
              selectedKey.push(key);
            }
            outerList.push({
              name: user.name,
              email: user.email,
              mobile: user.mobile,
              companyName: user.companyName,
              key,
            });
          }
        });
      }
      yield put({
        type: 'set',
        payload: { selectedKey, outerList },
      });
      history.push(MENU_PATH.meetingContact);
    },

    *isVirsicalBrowser(__, { put }) {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('virsical') > -1) {
        yield put({ type: 'set', payload: { isVirsicalBrowser: true } });
      } else {
        yield put({ type: 'set', payload: { isVirsicalBrowser: false } });
      }
    },
    *setTempExternalData(__, { put, select }) {
      const { externalList = [] } = yield select((state) => state.attendee);
      if (externalList.length === 0) {
        externalList.push({
          name: '',
          mobile: '',
          companyName: '',
          telAreaCode: '+86',
          email: '',
          uid: Math.random(),
        });
        yield put({
          type: 'attendee/set',
          payload: { externalList },
        });
      }
    },
  },
  subscriptions: {
    setup(root) {
      const { dispatch, history: umiHistory } = root;
      return umiHistory.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.externalContact)) {
          dispatch({ type: 'isVirsicalBrowser' });
          dispatch({ type: 'setTempExternalData' });
        } else if (matchPath(pathname, MENU_PATH.meetingContact)) {
          // const contactArr = [
          //   { id: 1, name: 'Bruce1', phone: `+8613312345654`, mail: '492629143@qq.com', remark: '这是备注' },
          //   { id: 2, name: 'Bruce2', phone: `008613365895231`, mail: '492629143@qq.com', remark: '这是备注' },
          //   { id: 3, name: 'Bruce3', phone: `8613321452532`, mail: '492629143@qq.com', remark: '这是备注' },
          //   { id: 4, name: 'Bruce4', phone: `85265321256`, mail: '492629143@qq.com', remark: '这是备注' },
          //   { id: 5, name: 'Bruce5', phone: `+85265321256`, mail: '492629143@qq.com', remark: '这是备注' },
          //   { id: 6, name: 'Bruce6', phone: `0085265321256`, mail: '492629143@qq.com', remark: '这是备注' },
          //   { id: 7, name: 'Bruce7', phone: `13321452986`, mail: '492629143@qq.com', remark: '这是备注' },
          //   { id: 8, name: 'Bruce8', phone: `65326556`, mail: '492629143@qq.com', remark: '这是备注' },
          // ];
          // dispatch({ type: 'loadForOuterContact', payload: { contactArr } });
          Toast.loading(formatMessage({ id: 'app.loading' }));
          Virsical.phoneContacts({
            success: (res) => {
              Toast.hide();
              try {
                const result = res.result ? JSON.parse(res.result) : [];
                dispatch({ type: 'loadForOuterContact', payload: { contactArr: result } });
              } catch (ee) {
                dispatch({ type: 'doWhenLoadOutContactFail', payload: {} });
              }
            },
            fail: () => {
              Toast.hide();
              // Modal.alert('加载失败返回', JSON.stringify(res));
              dispatch({ type: 'doWhenLoadOutContactFail', payload: {} });
            },
            refresh: true,
          });
        } else {
          dispatch({ type: 'reset' });
        }
      });
    },
  },
};
