/**
 * Created : vincent
 * Date : 5/18/22 4:19 PM
 * Email : wangxiao@wafersystems.com
 */

import { getIntl, getLocale } from 'umi';
import formatMessage, {
  setIntlMap,
  devIntlSocket,
  zhIntl,
  initIntlMap,
  setIntl,
  setLocale,
} from 'wafer-i18n-utils';

setIntl(getIntl);
setLocale(getLocale);

export default formatMessage;

export { setIntlMap, devIntlSocket, zhIntl, initIntlMap };
