/**
 * Created by XuQiang on 2017/6/7.
 */

export default {
  'visit.visitor.name': '访客姓名：',
  'visit.visitor.company': '访客公司：',
  'visit.visitor.telephone': '访客电话：',
  'visit.visitor.email': '访客邮箱：',
  'visit.visitor.cause': '访问事由：',
  'visit.visitor.time': '访问时间：',
  'visit.visitor.time_': '访问时间',
  'visit.visitor.status': '访问状态：',
  'visit.visitor.status_': '访问状态',
  'visit.visitor.leave': '离开时间：',
  'visit.visitor.accompany': '随行人员',
  'visit.visitor.status.0': '未开始',
  'visit.visitor.status.1': '未到访',
  'visit.visitor.status.2': '已签到',
  'visit.visitor.status.3': '已签出',
  'visit.visitor.status.4': '已取消',
  'visit.visitor.status.5': '待审批',
  'visit.visitor.status.6': '已拒绝',
  'visit.visitor.status.7': '已过期',
  'visit.visitor.status.8': '处理中',
  'visit.visitor.status.9': '系统签出',
  'visit.visitor.status.10': '失败',
  'visit.visitor.status.11': '已同意',
  'visit.visitor.status.12': '审批中',
  'visit.status.0.tip': '未到访客来访时间或未超过来访当天',
  'visit.status.1.tip': '截止离开时间当天还未到访',
  'visit.status.2.tip': '已完成签到',
  'visit.status.3.tip': '已完成签出',
  'visit.status.4.tip': '已取消邀请',
  'visit.status.5.tip': '需要员工审批的访问',
  'visit.status.6.tip': '已被员工拒绝的访问',
  'visit.status.7.tip': '超过离开时间未审批',
  'visit.status.8.tip': '第三方集成还在处理中',
  'visit.status.9.tip': '已在系统设置时间自动签出',
  'visit.status.10.tip': '第三方集成处理失败',
  'visit.status.11.tip': '已同意来访',
  'visit.status.12.tip': '审批流程尚未结束，还需其他人员审批。',
  'visit.visitor.status.unConfirm': '待审批',
  'visit.visitor.status.Confirmed': '已确认',
  'visit.visitor.status.reject': '已拒绝',
  'visit.visitor.status.expired': '已过期',
  'visit.visitor.operation': '操作',
  'visit.visitor.detail': '详情及操作',
  'visit.visitor.info.search': '信息搜索',
  'visit.visitor.search': '查询',
  'visit.visitor.sign.out': '签出',
  'visit.visitor.view': '访问详情',
  'visit.visitor.tip1': '如何创建第一个访客',
  'visit.visitor.tip2': '1、您可以通过iPad APP Store搜索访客管理下载访客终端应用',
  'visit.visitor.tip3': '2、在PC端管理界面中创建终端管理员，使用终端管理员登录访客iPad终端',
  'visit.visitor.tip4': '3、登记您自己的信息，创建第一个访客！',
  'visit.visitor.export': '导出',
  'visit.visitor.certNumber': '访客身份证号：',
  'visit.visitor.visit.time': '到访时间',
  'visit.visitor.visit.invitationCode': '邀请码',
  'visit.visitor.visit.beizhu': '备注',
  'visit.visitor.visit.end.time': '离开时间',

  'visit.reserve.across.title': '跨天邀请',
  'visit.selfFlag.0.tip': '员工主动邀请访客来访的',
  'visit.selfFlag.1.tip': '访客通过小程序、公众号、自助预约进行登记访问的',
  'visit.selfFlag.2.tip': '通过访客终端（Pad、一体机等）登记或企业前台登记的临时来访的访客',
  'visit.selfFlag.3.tip': '员工通过“团体邀请”菜单邀请的和访客通过员工分享链接自行登记的',
  'visit.reserve.book.lockers.title': '预订储物柜',
  'visit.reserve.book.lockers.title.tip': '访客可使用访客专属储物柜',

  'visit.visitor.date.picker.unit.year': '年',
  'visit.visitor.date.picker.unit.month': '月',
  'visit.visitor.date.picker.unit.day': '日',
  'visit.visitor.date.picker.unit.hour': '时',
  'visit.visitor.date.picker.unit.minute': '分',
};
