/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  zh_CN: '简体中文',
  zh_TW: '繁體中文',
  en_US: 'English',
  'language.btn.ok': '确定',
};
