/**
 * Created by wu on 2017/6/1.
 */
import Toast from 'antd-mobile-v2/lib/toast';
import { formatMessage } from 'umi';
import * as virsicalService from '../services/virsical';

export default {
  namespace: 'virsical',
  state: {},
  reducers: {},
  effects: {
    *configJS({ payload }, { call, put }) {
      if (Object.prototype.hasOwnProperty.call(payload, 'isWechat')) {
        const data = yield call(virsicalService.wechatSign, { ...payload });
        if (data.result === 0) {
          const employeeId = `${data.userInfo.id}@${data.userInfo.domain}`;
          yield put({ type: 'app/loginPortal', payload: { employeeId } });
        } else {
          Toast.info(formatMessage({ id: `${data.msg}` }));
        }
      } else {
        yield put({
          type: 'app/getAccessToken',
        });
        // const data = yield call(virsicalService.init, payload);
        // let data ={userId:'fengxiang',domain:'wafertest.com'};
        // const employeeId = `${data.userId}@${data.domain}`;
        // yield put({ type: 'app/loginPortal', payload: { employeeId } });
      }
    },
  },
  subscriptions: {},
};
