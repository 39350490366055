import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';
import { stringify } from 'qs'

/**
 * 查询设备或者工位编号列表信息
 * @param param 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getListByDataCode(param) {
  const url = `/${RESTFUL_PATH.sws}/api/device/queryListByDataCode?domainId=${param.domainId}&dataCode=${param.facilityCode}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 查询当前编号的设备或者工位列表信息
 * @param param 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function queryByDataId(param) {
  let url = `/${RESTFUL_PATH.sws}/api/device/queryByDataIdScan?domainId=${param.domainId}&dataId=${param.dataId}&queryType=${param.queryType}`;
  if (param.isNoScan) {
    url = `/${RESTFUL_PATH.sws}/api/device/queryByDataId?domainId=${param.domainId}&dataId=${param.dataId}&queryType=${param.queryType}`;
  }
  return request(url, {
    method: 'GET',
  });
}
/**
 * 解绑
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function unbindDevice(param) {
  return request(`/${RESTFUL_PATH.sws}/api/device/unbindDevice`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}
/**
 * 绑定设施
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function bindDevice(param) {
  return request(`/${RESTFUL_PATH.sws}/api/device/bindDevice`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

/**
 * 获取virsical扫一扫相关参数
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getWaferAppInfo() {
  return request(`/${RESTFUL_PATH.sws}/api/sysSetting/getWaferAppInfo`, {
    method: 'get',
  });
}


/************************ */

export async function getDetail(filter) {
  return request(`/${RESTFUL_PATH.sws}/api/map-resources/reservationInfo?${stringify(filter)}`, {
    method: 'get',
  });
}

export async function getHoliday(filter) {
  return request(`/${RESTFUL_PATH.sws}/api/reserve/getHolidayBySpaceId?${stringify(filter)}`, {
    method: 'get',
  });
}

export async function getWorkTime(filter) {
  return request(`/${RESTFUL_PATH.admin}/holiday/getTenantWorkTime?${stringify(filter)}`, {
    method: 'get',
  });
}

// '/api/map-resources/timeShow?stationId=11&startTime=1642089600000'

export async function getTimeReserve({ stationIds, startTime }) {
  return request(`/${RESTFUL_PATH.sws}/api/map-resources/timeShowBatch?stationIds=${stationIds}&startTime=${startTime}`, {
    method: 'get',
  });
}



export async function getReservationInfo(filter) {
  return request(`/${RESTFUL_PATH.sws}/api/map-resources/reservationInfo?${stringify(filter)}`, {
    method: 'get',
  });
}

export async function reservation(payload) {
  return request(`/${RESTFUL_PATH.sws}/api/map-resources/mapReservation`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 * 查询位置下所有工位
 *
 * @param payload 请求参数体
 */
 export async function getStationsByLocationIdScreen(locationId) {
  const url = `/${RESTFUL_PATH.sws}/api/station/findStationByLocationForScreen?locationId=${locationId}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 获取工位预订详情
 */
 export async function detailInfo({ stationIds }) {
  // const url = `/${RESTFUL_PATH.sws}/api/map-resources/reservationInfo?stationId=${stationId}`;
  const url = `/${RESTFUL_PATH.sws}/api/map-resources/reservationInfoBatch?stationIds=${String(stationIds)}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 预订操作
 * @param payload 请求参数体 client 1：Web端或App端，2：管理端
 * @returns {Promise<questMethodAddToUrl>}
 */
 export async function reservationMore(payload) {
  return request(`/${RESTFUL_PATH.sws}/api/reserve/special`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 * 该工位预订列表
 *
 * @param mapId 地图Id
 */
 export async function stationBookList({ stationIds, startTime }) {
  // const url = `/${RESTFUL_PATH.sws}/api/map-resources/timeShow?stationId=${stationId}&startTime=${startTime}`;
  const url = `/${RESTFUL_PATH.sws}/api/map-resources/timeShowBatch?stationIds=${String(stationIds)}&startTime=${startTime}`;
  return request(url, {
    method: 'GET',
  });
}
