export default {
  'locker.chose.tip': '您选择的储物柜：',
  'sws.btn.return.locker.booking': '返回储物柜预约',
  'menu.locker.mobile': '智能储物柜',
  'sws.reserve.locker.confirm.fail': '不支持预订两个或者两个以上储物柜！',
  'sws.myBooking.locker': '储物柜',
  403043: '当前没有空闲储物柜',
  403044: 'http请求失败',
  403045: '下发权限失败',
  403046: '回收权限失败',
  403047: '开门权限已被释放，无法开门',
  403061: '储物柜类型为访客使用'
};
