export default {
  // 经协商,此处应该备份一份工位菜单
  'sws.smartWorkspace': '智能工位',
  'sws.menu.station.special': '特殊預訂',
  'sws.menu.station.my.booking': '我的預訂',
  'sws.menu.station.booking.history': '歷史預訂',
  'sws.menu.station.booking': '工位預訂',
  'sws.menu.station.repair': '工位報修',
  'sws.menu.station.repair.manage': '報修管理',
  'sws.menu.station.search': '工位查詢',
  'sws.menu.station.records': '我的預訂',
  'sws.menu.locker.booking': '儲物櫃預訂',
  'sws.menu.locker.records': '儲物櫃預訂記錄',

  all_day: '全天',
  am: '上午',
  pm: '下午',
  custom: '自定義',
  locale: 'zh-TW',
  // 表单
  'sws.no.data': '無數據',
  'sws.select.all': '全部',
  'sws.input.name': '請輸入名稱',
  'sws.time.colon': '時間:',
  'sws.time1': '时间',
  'sws.time.hour': '小時',
  // 按钮文字
  'sws.btn.ok': '確定',
  'sws.btn.got.it': '知道了',
  'sws.btn.cancel': '取消',
  'sws.btn.exit': '取消',
  'sws.btn.close': '關閉',
  'sws.btn.chose.time': '選擇時間',
  'sws.btn.return.list': '返回列表',
  'sws.btn.reset': '重置',
  'sws.btn.return.reserve': '返回工位預訂',
  'sws.btn.return.station.dashboard': '返回工位首頁',
  'sws.btn.return.reverse.history': '返回預訂記錄',
  'sws.btn.home': '主頁',
  'sws.btn.more': '更多',
  // 操作结果
  'sws.congratulation.success': '恭喜您,操作成功！',
  'sws.reserve.success': '預訂成功',
  'sws.reserve.error': '預訂失敗',
  'sws.logout.success': '註銷成功',
  'sws.logout.error': '註銷失敗',
  'sws.cancel.success': '取消成功',
  'sws.cancel.error': '取消失敗',
  'sws.globalTime.check.error': '租戶時間查詢失敗',
  // 校验提示信息
  'sws.tip.time': '請選擇時間',
  'sws.tip.station': '請選擇工位',
  'sws.chose.station': '已選中{num}個工位',
  'sws.station.reservation': '確認預訂',
  'sws.station.immediate': '立即使用',
  'sws.station.chosed': '您選擇的工位：',
  'sws.time.date.chosed': '您選擇的日期和時間:',
  // 其它
  'sws.common.location': '區域',
  'sws.common.timezone': '工位所在时区',
  'sws.date.time': '日期和時間',
  'sws.date.range': '日期範圍',
  'sws.time.start': '開始時間',
  'sws.time.end': '結束時間',
  'sws.please.select.colon': '請選擇:',
  'sws.please.select': '請選擇',
  'sws.footer.canUse': '可用',
  'sws.footer.reserved': '已預訂',
  'sws.footer.occupied': '已佔用',
  'sws.footer.cannot.reserve': '不可預訂',
  'sws.footer.fixed.station': '固定工位',
  'sws.station.booking': '預訂工位',
  'sws.booking.info': '預訂信息',
  'sws.station.number': '工位編號',
  'sws.station.check.in': '時間',
  'sws.node.no.select': '此區域不可以選擇',
  'sws.select.tip.title': '工位信息',

  // 提示信息
  'sws.tip.loading': '數據加載中...',
  'sws.reserve.confirm.fail': '您正在使用工位,不支持同一時間預定兩個或兩個以上座位！',
  'sws.tip.station.occupy': '以下時間段工位被占用',
  'sws.tip.station.location.none': '你沒有選擇區域信息,請進行選擇',
  'sws.tip.none': '無',

  'sws.book.result.gong': '共',
  'sws.book.result.ge': '個',

  // 异常
  401000: '系統異常',
  401001: '工位編號已存在',
  401002: '工位資訊不存在',
  401003: '參數錯誤',
  401004: '工位範本Excel表頭列名空錯誤',
  401005: '第{row}行:工位編號空錯誤',
  401006: '第{row}行:工位區域空錯誤',
  401007: '第{row}行:工位家具類型空錯誤',
  401008: '第{row}行:工位編號{stationNum}重複',
  401009: '第{row}行:用戶工號{userId}不存在',
  401010: '第{row}行:工位區域{location}錯誤',
  401011: '第{row}行:共享工位{stationNum}不支持分配人員',
  401012: '工位{stationNum}處於預訂或使用中,請停用後,再刪除',
  401013: '此工位不存在',
  401014: '該工位【{value1}】使用人數超出【{value2}】人系統上限，請聯繫工位管理員調整設置',
  401015: '此工位已綁定素材',
  401016: '此素材已綁定工位',
  401017: '用戶{value1}已綁定3個工位',
  401018: '工位處於預訂或使用中,不可以編輯工位狀態',
  401019: '工位編號未綁定地圖,不可以啟用',
  401020: '工位編號未綁定地圖,不可以停用',
  401021: '工位{stationNum}處於預訂或使用中,請停用後,再解綁',
  401022: '啟用共享工位數量超過license限制,請聯繫管理員',
  401023: '啟用固定工位數量超過license限制,請聯繫管理員',
  401024: '工位{stationNum}處於預訂或使用中,不可編輯部門',
  401025: '空間資訊為空',
  401026: '用戶{value1}綁定的工位數量超過限制',
  401027: '工位處於預訂或使用中,不可以修改類型',
  401028: '工位編號{value}未綁定地圖,不可以啟用',
  401029: '工位編號{stationNum}未綁定地圖,不可以停用',
  401030: '工位處於預訂或使用中,不可編輯部門',
  401031: '請使用正確的檔範本',
  401032: '工位編號{stationNum}已存在,請去除重複工位',
  401033: '第{row}行:工位{stationNum}绑定的人员数量超过限制',
  401034: '第{row}行:员工{userId}分配的工位数量超过限制',
  401035: '工位處於使用中,不允許解除或更改感測器',
  401103: '所選工位在預訂時間段內已被佔用',
  401104: '您已經有預訂或使用的工位,不支持同時預訂或使用兩個及以上的工位',
  401105: '用戶不存在',
  401106: '當前域未設置上下班時間',
  401107: '當前用戶未設置部門許可權',
  401108: '當前用戶未設置區域許可權',
  401109: '當前用戶沒有部門許可權',
  401110: '當前用戶沒有區域許可權',
  401111: '今天已過下班時間,無法預訂',
  401113: '不在工作时间内,扫码无效',
  401201: '該用戶當前綁定的工位數超過限制',
  401202: '{1}當前綁定的用戶數與申請用戶數之和超過三個,請重新申請',
  401251: '上下班時間類型異常',
  401252: '日期參數異常',
  401253: '類型參數異常',
  401254: '用戶未登錄',
  401255: 'top統計用戶資訊異常',
  401256: '操作日誌範本異常',
  401257: '空間樹獲取失敗',
  401258: '空間樹節點異常',
  401259: '許可權不足',
  401260: '部門許可權不足',
  401261: '工作日設定異常',
  401262: '節假日不做統計',
  401263: '對象原型異常',
  401264: '參數異常',
  401265: '預訂記錄工位參數異常',
  401266: '定時任務狀態參數異常',
  401267: '類別已存在',
  401268: '類別工位綁定參數異常!',
  401269: '綁定失敗,不能跨域綁定!',
  401270: '部門不存在！',
  401271: '操作日誌範本異常',
  401272: '操作日誌,查詢用戶失敗！',
  401291: '',
  401311: '工位設施名稱已存在',
  401312: '工位設施保存失敗',
  401313: '工位設施刪除失敗',
  401314: '選擇的設施已綁定工位,需解綁後再刪除',
  401321: '數據字典租戶默認資訊未初始化',
  401322: '數據字典全局默認資訊未初始化',
  401331: '許可權名稱已存在',
  401332: '參數錯誤',
  401351: '檔上傳失敗',
  401356: 'license解析失敗',
  401357: 'license已過期',
  401358: 'license已超過限制',
  401361: '檔上傳模版類型錯誤',
  401362: '第{value1}行,設備編號為空',
  401363: '第{value1}行,設備類型為空',
  401364: '第{value1}行,工位編號不存在',
  401366: '電子標牌服務地址為空',
  401367: '電子標牌MAC已綁定其他工位',
  401381: '工位報修參數錯誤',
  401114: '超出最大延長時時間',
  401131: '不存在已預訂狀態的記錄',
  401143: '當前時間已過預約結束時間,無法預約',

  'sws.booking.interval.tip': '該工位目前處於鎖定狀態,暫不可預訂',
  'sws.booking.interval.cancel.tip': '該工位取消算未履約,確定要繼續嗎？',

  "401504": "工位至少提前{value1}分鐘預訂！",
  "401505": "該工位不支持掃碼簽到！",
  "401506": "工位{value1}已被鎖定,請稍後預訂！",

  "401036": "工位狀態異常",
  "401083": "二維碼無法識別",
  "401057": "二維碼已失效",
  "401073": "當前工位不支持掃碼綁定業務",
  "401037": "工位{value1}使用人數超出系統設置,請聯繫管理員",
  "401859": "工位{value1}僅限單人單座使用",
  "401044": "工位{value1}未分配使用部門",
  "401089": "該工位【{value1}】僅限單人專用，不支持掃碼更換業務",
  "401090": "用戶【{value1}】同時擁有工位數量超過系統設置上限，請聯繫工位管理員調整設置",
  'default.region.select.option.no': '不選本級',
  401159: '用戶無所屬部門',

  //360新增
  401205: '掃碼綁定失敗（僅允許綁定本部門範圍內所屬工位）',
};
