export default {
  'meeting.region.btn.select': '筛选',
  'meeting.region.search.choice': '请选择',
  'meeting.region.search.startDate': '选择日期',
  'meeting.region.search.startTime': '开始时间',
  'meeting.region.search.timeLong': '会议时长(单选)',
  'meeting.region.search.minute': '分钟',
  'meeting.region.search.reset': '重置',
  'meeting.region.search.submit': '确定',
  'meeting.region.search.time': '选择时间',
};
