import { formatMessage, history, setLocale } from 'umi';
import {DEFAULT_PREFIX, ROUTE_PATH} from '@/utils/constant';
import * as service from './service';
import moment from 'moment-timezone';

export default {
  namespace: 'swsSensor',
  state: {
    reservationInfo:{}
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *getReservationInfo({ id,t }, { call, put }) {
      const result = yield call(service.getReservationInfo, { resId: id,t });
      if (result && result.code === 0) {
        const { userLang } = result.data;
        if (userLang) {
          let lang = userLang;
          if (userLang.indexOf('en') !== -1) {
            lang = 'en-US';
          }
          if (userLang.indexOf('TW') !== -1) {
            lang = 'zh-TW';
          }
          if (userLang.indexOf('CN') !== -1) {
            lang = 'zh-CN';
          }
          setLocale(lang);
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: { reservationInfo: result?.data || {} },
        });
      }
    },
    // 取消预订
    *operate({ reservationInfo, noticeId, flag }, { call, put }) {
    const result = yield call(service.operate, reservationInfo.id, noticeId, flag);
      // const result={code:0,msg:null,data:null}
      if (result && result.code === 0) {
          const { locationTimeZone= 'Asia/Shanghai:', useUserName, stationNums, spacePathName, startTime, endTime } = reservationInfo;
          const [timeZone,timeZoneI18n] = locationTimeZone.split(':');
          const time = `${moment(startTime).tz(timeZone).format(formatMessage({ id: 'sws.time' }))} ~ ${moment(endTime).tz(timeZone).format(formatMessage({ id: 'sws.time' }))}`
          yield put({
            type: 'swsScan/saveOrUpdateData',
            payload: {
              station: { stationNum: stationNums, locationName: spacePathName, useUserName, time, timeZoneI18n },
              scanTip: flag === 1? formatMessage({ id: 'sws.sensor.continue.tips' }): formatMessage({ id: 'sws.sensor.release.tips' })
            },
          });
          history.replace(`${ROUTE_PATH}/workspace/scanSuccess`);
      } else if (result.code === 1 && result.msg) {
        yield put({
          type: 'swsScan/saveOrUpdateData',
          payload: { scanTip: formatMessage({ id: result.msg }, { ...result.data }) },
        });
        history.replace(`${ROUTE_PATH}/workspace/scanResult`);
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
        if (pathname.trim().length > 0 && pathname.includes('/confirmLink')) {
          const { id= 8, t } = query;
          dispatch({ type: 'getReservationInfo', id, t });
          const sUserAgent = navigator.userAgent.toLowerCase();
          window.console.log(sUserAgent, 'sUserAgent');
          if (
            /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)
          ) {
            dispatch({ type: 'saveOrUpdateData', payload: { browser: 'mobile' } });
          } else {
            const { origin, search } = window.location;
            const url = `${origin}/${DEFAULT_PREFIX}/web/confirmLink${search}`;
            // const url = `https://sws5.rd.virsical.cn/web/delay${search}`;
            window.location.replace(url);
          }
        }
      });
    },
  },
};
