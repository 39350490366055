import { formatMessage, setLocale, history } from 'umi';
import moment from 'moment-timezone';
import { Toast } from 'antd-mobile-v2';
import {DEFAULT_PREFIX, ROUTE_PATH} from '@/utils/constant';
import { createDelayByNum } from '@/pages/WorkSpace/utils/tools'
import * as service from './service';

// window.moment = moment;

export default {
  namespace: 'linkDelay',
  state: {
    browser: '',
    maxTime: '',
    endTime: '',
    reservationInfo: {},
    timeData: [],
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getResIdInfo({ s }, { call, put }) {
      const result = yield call(service.getResIdInfo, s);
      if (result && result.code === 0) {
        const { rId, endTime, delayTime, delayTimeMin } = result.data;
        yield put({
          type: 'saveOrUpdateData',
          payload: { endTime, delayTime, delayTimeMin, timeData: createDelayByNum(Number(delayTimeMin), Number(delayTime)) },
        });
        yield put({
          type: 'getReservationInfo',
          reservationId: rId,
        });
      }
    },
    *getReservationInfo({ reservationId }, { call, put }) {
      const result = yield call(service.getReservationInfo, { resId: reservationId });
      if (result && result.code === 0) {
        const { userLang } = result.data;
        if (userLang) {
          let lang = userLang;
          if (userLang.indexOf('en') !== -1) {
            lang = 'en-US';
          }
          if (userLang.indexOf('TW') !== -1) {
            lang = 'zh-TW';
          }
          if (userLang.indexOf('CN') !== -1) {
            lang = 'zh-CN';
          }
          setLocale(lang);
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: { reservationInfo: result.data || {} },
        });
      }
    },
    *setDelayTime({ payload }, { call, put, select }) {
      const { browser, reservationInfo } = yield select((state) => state.linkDelay);
      const result = yield call(service.setDelayTime, payload);
      if (result && result.code === 0) {
        const { endTime = '' } = result?.data;
        const locationTimeZone = reservationInfo.locationTimeZone || 'Asia/Shanghai:';
        const [timeZone, timeZoneI18n] = locationTimeZone.split(':');
        const locationNames = reservationInfo?.spacePathName?.split(',').slice(-3);
        history.push({
          pathname: `${ROUTE_PATH}/workspace/common/components/bookingResult`,
          state: {
            choseResult: {
              timeSelected: `${moment
                .tz(reservationInfo.startTime, timeZone)
                .format(formatMessage({ id: 'sws.time' }))}-${moment
                .tz(endTime, timeZone)
                .format('HH:mm')}`,
              stationNum: reservationInfo.stationNums,
            },
            locationNames,
            timeZone,
            timeZoneLang: timeZoneI18n,
            isDelay: true,
          },
        });
        yield put({ type: 'getReservationInfo', reservationId: payload.id });
      } else if (result.code === 1 && result.msg) {
        if (browser === 'mobile') {
          Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
        }
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname, query }) => {
        if (pathname.trim().length > 0 && (pathname === '/delay' || pathname === '/delay/')) {
          const { s } = query;
          dispatch({ type: 'getResIdInfo', s });
          const sUserAgent = navigator.userAgent.toLowerCase();
          window.console.log(sUserAgent, 'sUserAgent');
          // console.log('location', location)
          if (
            /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)
          ) {
            dispatch({ type: 'saveOrUpdateData', payload: { browser: 'mobile' } });
          } else {
            const { origin, search } = window.location;
            const url = `${origin}/${DEFAULT_PREFIX}/main/qk/sws/delay${search}`;
            // const url = `https://sws5.rd.virsical.cn/web/delay${search}`;
            window.location.replace(url);
          }
        }
      });
    },
  },
};
