import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import {
  getUserFaceByUserName,
  queryCurrentUserInfo,
  createOrUpdateFaceBySelf,
  delFace,
} from './service';
import { decryptAes } from '@/utils/utils';
import { CLOUD_AES_KEY  , PREFIX_BASE64} from '@/utils/constant';

export default {
  namespace: 'faceLib',
  state: {
    currentUser: {},
    imgResult: '',
    userFaceData: {},
    straightFace: [],
    sideFace: [],
    leftFace: [],
    rightFace: [],
    upFace: [],
    visibleImage: false,
    isUp: false,
    uPing: false,
    confirmNda: false,
    showNda: false,
    editImage: [],
    isSubmit: false,
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetchCurrentUserInfo(_, { call, put }) {
      const response = yield call(queryCurrentUserInfo);
      if (response.code === 0) {
        const { sysUser } = response.data;
        yield put({
          type: 'save',
          payload: {
            currentUser: sysUser,
          },
        });
        yield put({
          type: 'getUserFaceByUserName',
          userName: sysUser.username || '',
        });
      }
    },
    *delFace({ payload }, { call, put }) {
      const response = yield call(delFace, payload);
      if (response.code === 0) {
        const msg = formatMessage({ id: '601013' });
        Toast.success(msg);
        yield put({
          type: 'save',
          payload: {
            userFaceData: {},
            straightFace: [],
            sideFace: [],
            leftFace: [],
            rightFace: [],
            upFace: [],
          },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
    *getUserFaceByUserName({ userName }, { call, put }) {
      const response = yield call(getUserFaceByUserName, userName);
      if (response.code === 0) {
        const userFaceData = response.data;
        yield put({
          type: 'save',
          payload: {
            userFaceData,
            uPing: false,
            straightFace:
              userFaceData.faceLibInfo || userFaceData.straightFace
                ? [
                    {
                      url: userFaceData?.faceLibInfo?.faceBase64Str
                        ? `${PREFIX_BASE64}${decryptAes(userFaceData?.faceLibInfo?.faceBase64Str, CLOUD_AES_KEY)}`
                        : userFaceData.straightFace,
                      id: 1,
                    },
                  ]
                : [],
            sideFace: userFaceData.sideFace ? [{ url: userFaceData.sideFace, id: 2 }] : [],
            leftFace: userFaceData.leftFace ? [{ url: userFaceData.leftFace, id: 3 }] : [],
            rightFace: userFaceData.rightFace ? [{ url: userFaceData.rightFace, id: 4 }] : [],
            upFace: userFaceData.upFace ? [{ url: userFaceData.upFace, id: 5 }] : [],
          },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
    *createOrUpdateFaceBySelf({ params }, { call, put }) {
      const response = yield call(createOrUpdateFaceBySelf, { ...params });
      if (response.code === 0) {
        const successText = formatMessage({ id: 601013 });
        Toast.success(successText);
        yield put({
          type: 'getUserFaceByUserName',
          userName: params.username || '',
        });
        // yield put({
        //   type: 'save',
        //   payload: {
        //     // isSubmit: false,
        //     straightFace: file,
        //   },
        // });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
    *updateLoadImage({ formData }, { put, select }) {
      // const response = yield call(updateLoadImage, { formData });
      const { userFaceData, currentUser } = yield select((state) => state.faceLib);
      const obj = { ...userFaceData };
      const file = {
        faceBase64Str: formData.faceBase64Str,
        id: userFaceData?.faceLibInfo?.id || '',
      };
      // if (faceType === 1) {
      //   obj.straightFace = formData.faceBase64Str;
      // }
      const tempData = { ...obj };
      if (!Object.prototype.hasOwnProperty.call(tempData, 'faceId')) {
        tempData.faceId = '';
      }
      const data = {
        faceId: tempData.faceId,
        userId: currentUser.userId,
        username: currentUser.username,
        realName: currentUser.realName,
        userType: 'staff',
        signTime: new Date().getTime(),
        deptId: currentUser.deptId,
        straightFace: '',
        sideFace: tempData.sideFace ? tempData.sideFace : '',
        leftFace: tempData.leftFace ? tempData.leftFace : '',
        rightFace: tempData.rightFace ? tempData.rightFace : '',
        upFace: tempData.upFace ? tempData.upFace : '',
      };
      yield put({
        type: 'createOrUpdateFaceBySelf',
        params: { ...data, faceLibInfo: { ...file } },
      });
    },
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        if (pathname === '/accessApp/faceLib') {
          dispatch({ type: 'fetchCurrentUserInfo' });
          dispatch({
            type: 'save',
            payload: {
              showNda: false,
              isUp: false,
              showAgree: false,
              showRejct: false,
            },
          });
        }
      });
    },
  },
};
