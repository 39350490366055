export default {
    'sws.cover.current.use': '當前使用人',
    'sws.cover.reason': '申請原因',
    'sws.cover.history.tip': '是否解綁以前的工位',
    'sws.cover.history.0': '是',
    'sws.cover.history.1': '否',
    'sws.cover.reply.station.info': '工位資訊',
    'sws.cover.reply.station.num': '工位編號',
    'sws.cover.reply.station.man': '申請使用人',
    'sws.cover.reply.station.reason': '申請原因',
    'sws.cover.reply.station.remark': '回復',
    'sws.cover.reply.station.ok': '同意',
    'sws.cover.reply.station.no': '拒絕',
    'sws.cover.reply.submit.ok': '申請成功',
    'sws.cover.reply.reason.zw': '暫無',

    401148: "請勿重複操作",
    401860: '工位綁定使用者已變更,請掃碼重試',
};
