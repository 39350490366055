/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.myFaultRepair.time.current': 'Today',
  'access.myFaultRepair.state.Processed': 'Processed',
  'access.myFaultRepair.state.confirmed': 'Confirmed',
  'access.myFaultRepair.state.Unprocessed': 'Unprocessed',
  'access.myFaultRepair.noData': 'No Data',

  'access.myFaultRepair.reason': 'Repair reason',
  'access.myFaultRepair.time': 'Repair time',
};
