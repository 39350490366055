import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import changeDefaultAreaService from './services';

export default {
  namespace: 'defaultZone',

  state: {
    selectZone: '',
    selectZones: [],
    expandedKeys: [],
    searchSpaces: [],
  },

  effects: {
    *changeDefaultArea(_, { call, select }) {
      const { selectZones } = yield select((state) => state.defaultZone);
      const obj = {
        zone: selectZones?.join(',') || [],
      };
      const result = yield call(changeDefaultAreaService, { payload: obj });

      if (result.code === 0) {
        Toast.success(formatMessage({ id: 'common.action.success' }));
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  subscriptions: {},
};
