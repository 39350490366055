import { history, formatMessage } from 'umi';
import { ROUTE_PATH } from '@/utils/constant';
import { Toast } from 'antd-mobile-v2';
import * as service from './service';

export default {
  namespace: 'swsSignOut',
  state: {},
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 取消预订
    *cancelReservation({ payload, scanStation }, { call, put }) {
      const result = yield call(service.cancelReservation, payload);
      if (result && result.code === 0) {
        const { address, useUserName, stationNums } = scanStation;
        yield put({
          type: 'swsScan/saveOrUpdateData',
          payload: {
            station: { stationNum: stationNums, locationName: address, useUserName },
            scanTip: formatMessage({ id: 'sws.sign.out.end.success' }),
          },
        });
        history.replace(`${ROUTE_PATH}/workspace/scanSuccess`);
      }
    },
    *setDelayTime({ payload, scanStation }, { call, put }) {
      const result = yield call(service.setDelayTime, payload);
      if (result && result.code === 0) {
        const { locationPath, userNames, stationNum } = scanStation;
        yield put({
          type: 'swsScan/saveOrUpdateData',
          payload: {
            station: { stationNum, locationName: locationPath, userNames },
            scanTip: formatMessage({ id: 'sws.congratulation.success' }),
          },
        });
        history.replace(`${ROUTE_PATH}/workspace/scanSuccess`);
      } else if (result.code === 1 && result.msg) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history: umiHistory }) {
    //   return umiHistory.listen(({ pathname }) => {
    //     if (pathname.trim().length > 0 && pathname.includes('/workspace/scan/delay')) {
    //     }
    //   });
    // },
  },
};
