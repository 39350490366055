export default {
  device_tab_value1: '请选择移动设备!',
  device_tab_value2: '该设备已没有库存',

  'meeting.manage.title.device': '移动设备申请',
  'meeting.manage.device.type_': '设备类型：',
  'meeting.manage.device.describe_': '描述：',
  'meeting.manage.device.add': '添加移动设备',
  'meeting.manage.device.all': '全选',
  'meeting.manage.device.desc.null': '无设备描述',
  'meeting.manage.device.empty': '请联系管理员，为该会议室所在区域增加移动设备',
  'meeting.manage.device.info.shenpi': '该设备处于审批或借用中,暂时无法删除！',
  'meeting.new.device.add.success': '添加成功！',
  'new.meeting.device.status.approval':'审批中',
  'meeting.svcdeal.wait':'可借用',
  'meeting.devicedeal.borrow':'借用中',
  'meeting.devicedeal.expire':'已过期',
  'meeting.devicedeal.already.cancel':'已取消',
  'meeting.devicedeal.finish':'已归还',
  'meeting.manage.device.noData':'暂无会议设备',
  'meeting.new.device.stop': '收起',
  'meeting.new.device.Expand': '展开',

};
