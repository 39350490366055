export default {
  'sws.repair.list.time': 'Request of Time',
  'sws.repair.list.type': 'Catagories',
  'sws.repair.list.status': 'In Progress',
  'sws.repair.list.back': 'Back',
  'sws.repair.list.cancel': 'Cancel of Request',
  'sws.repair.list.detail': 'Details of Request',
  'sws.repair.list.pending': 'Pending',
  'sws.repair.list.processing': 'Process',
  'sws.repair.list.processed': 'Done',
  'sws.repair.no.data': 'None of History',
  'sws.repair.success': 'It is OK !',
  'sws.repair.list.new': 'New',
  'sws.repair.list.new.title': 'Enter Station Number/Name',
  'sws.repair.list.code.scan': 'QR Scan',
  'sws.repair.list.record': 'Previous',
  'sws.repair.commit.repair': 'Submit',
  'sws.repair.fault.type.chose': 'Choose from Catagories',
  'sws.repair.upload.picture.chose': 'Upload PIC. (Optional）',
  'sws.repair.upload.picture': 'Uploaded of PIC',
  'sws.repair.upload.picture.limit': 'Max of limited to 3 of them',
  'sws.repair.text.comments': 'Notes（Optional)',
  'sws.repair.text.no': 'Notes（N/A）',
  'sws.repair.fill.in.station.number': 'Please manual input Space ID',
  'sws.repair.station.number.none': 'Space ID is NOT exit',
  'sws.repair.fill.in.comments': 'Please manual input Notes of repair’s request',
  'sws.repair.chose.fault': 'Please manual select Issue of it that need repair',
  'sws.repair.picture.larger': 'Issue of picture’s size is less than 5M',
  'sws.repair.scan.qrCode': 'Please scan it Which Space’s QR Code',
  'sws.repair.search.placehold': 'Please manual iput Sapce’s ID/Name for Search',
  'sws.repair.search': 'Search for Space ID/Name',
  401083: 'The QR code cannot be recognized',
};
