/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 查询普通预订的信息
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getRepairManagePage(param) {
  const { domainId, current, size, repairsStatus } = param;
  const url = `/${RESTFUL_PATH.sws}/api/repairs/findRepairsApp?domainId=${domainId}&size=${size}&current=${current}&repairsStatus=${repairsStatus}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 预订操作
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function convertStatus(param) {
  return request(`/${RESTFUL_PATH.sws}/api/repairs/saveOrUpdate`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

/**
 * 查询报修类型
 * @param domainId 租户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getFacilityList(domainId) {
  const url = `/${RESTFUL_PATH.sws}/api/facility/weblist?domainId=${domainId}`;
  return request(url, {
    method: 'GET',
  });
}
