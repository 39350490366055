import React, { useMemo, useState } from 'react';
import { useSelector } from 'umi';
import { useGetVisitorCount } from '@/pages/Visitor/pages/hooks';

export default function () {
  const [oldVisitorCountObj, setOldVisitorCountObj] = useState(null);
  const {
    visitorCount = 2147483647,
    runGetVisitorCount,
    getVisitorCountLoading,
  } = useGetVisitorCount();

  const state = useMemo(() => {
    return {
      oldVisitorCountObj,
      visitorCount,
      getVisitorCountLoading,
    };
  }, [oldVisitorCountObj, visitorCount, getVisitorCountLoading]);

  const actions = useMemo(() => {
    return { setOldVisitorCountObj, runGetVisitorCount };
  }, [setOldVisitorCountObj, runGetVisitorCount]);

  return {
    state,
    actions,
  };
}
