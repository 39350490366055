
import { Toast } from 'antd-mobile-v2';
import { useRequest as useHooksRequest } from 'ahooks';
import { formatMessage } from 'umi';


export default function useRequest(service, option) {
  return useHooksRequest(async function (params) {
    const result = await service(params);
    // console.log('接口请求返回：', result);
    // 判断是否自行处理异常数据
    if (result?.code !== 0) {
      // console.log('进入异常处理');
      // 如果传递了 onError则不进行错误提示
      if (!option?.onError) {
        // console.log('接口异常：', result);
        Toast.fail(formatMessage({ id: result?.msg }, { ...result?.data }));
      }
      return Promise.reject(result);
    }
    const successResult = result?.data;
    return Promise.resolve(successResult);
  }, option);
}
