import * as ws from '@/pages/WorkSpace/Common/service/wechat';
import {
  JSSDKTYPE_MINI,
  JSSDKTYPE_WECHAT,
  STATUS_DISABLED,
} from '@/pages/WorkSpace/utils/constant';
import {
  convertDisArrToObj,
  findByRecursionId,
  getColorByStationStatus,
  getItemsColor$Text,
  searchPermissionByMap,
  systemList2Obj,
} from '@/pages/WorkSpace/utils/utils';
import { SVG_TYPE_SWS } from '@/utils/constant';
import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';
import * as service from './service';

export default {
  namespace: 'swsCommon',
  state: {
    stationOccupyModal: false,
    // [{}] {stationNum, id, time} {id: 1, stationNum: '234', time: '233'}
    stationOccupyData: [],
    spaceTree: [],
    sharemapIdSpaceTree: [],
    departmentTree: [],
    userSearchList: [],
    systemSet: {},
    holiday: {},
    areaSelected: {},
    mapId: '',
    locationId: '',
    stationsOnMap: [],
    stationData: [],
    floorList: [],
    // globalTime 默认空字符串
    globalTime: {
      amStartTime: '',
      amEndTime: '',
      pmStartTime: '',
      pmEndTime: '',
    },
    btnRole: false,
    showPositionSelect: false,
    // addressChoseList: [],
    // addressChildrenList: null,
    // shareaddressChoseList: [],
    // shareaddressChildrenList: null,
    locationSelected: '',
    scanStation: {},
    bookingList: [],
    settingData: {},
    eleIds: [],
    mapData: [],
    spacePerm: '',
    mapInfo: {},
    timeZone: 'Asia/Shanghai',
    defautLocation: {
      mapId: undefined,
      locationId: undefined,
    },
    lastTimeObj: {},
    mapColors: {},
    isShareLocation: false,
    openDateList: {},
    pathNameArr: [],
    swsSetting: [],
    userSearchOutList: [],
    menuDataFooter:[],
    isShowFooter: false,
    oftenSpaceList: [],
    isMapMultiple:false
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
    initMapCommon(state){
      return {
        ...state,
        locationSelected: '',
        locationId: '',
        isShareLocation: false
      };
    }
  },
  effects: {
    *getSpaceTree(_, { call, put }) {
      const result = yield call(service.getSpaceTree);
      if (result && result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { spaceTree: result.data } });
      }
    },
    *getSpaceTreeAndMapId({ payload = {} }, { call, put }) {
      const result = yield call(service.getSpaceTreeAndMapId, payload);
      if (result && result.code === 0) {
        const Arr = [];
        if (result.data.length > 0) {
          result.data.forEach((item) => {
            const obj = item;
            if (obj.timeZone === '') {
              obj.timeZone = 'Asia/Shanghai';
            }
            Arr.push(obj);
          });
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: { spaceTree: Arr },
        });
      }
    },
    //此接口可以获取默认显示地图的信息及整个tree
    *getSpaceTreeAndDefaultMapId({ payload = {} }, { call, put }) {
      const result = yield call(service.getSpaceTreeAndDefaultMapId, payload);
      if (result && result.code === 0) {
        const arr = [];
        result?.data?.treeDtoList?.forEach((item) => {
          const obj = item;
          if (obj.timeZone === '') {
            obj.timeZone = 'Asia/Shanghai';
          }
          arr.push(obj);
        });
        yield put({
          type: 'saveOrUpdateData',
          payload: { spaceTree: arr, defaultLocation: { mapId: result?.data?.mapId, locationId: result?.data?.locationId } },
        });
      }
    },
    // 共享区域
    *getSharespaceTreeAndMapId(_, { call, put }) {
      const result = yield call(service.getSharespaceTreeAndMapId);
      if (result && result.code === 0) {
        // console.log('result.data', result.data)
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            sharemapIdSpaceTree: result.data,
          },
        });
      }
    },
    *getHoliday(_, { call, put, select }) {
      let tenantId = 0;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        tenantId = info.tenant_id;
      }
      const result = yield call(service.getHoliday, tenantId);
      if (result && result.code === 0 && result.data) {
        const globalTime = {
          amStartTime: result.data.amStartTime,
          amEndTime: result.data.amEndTime,
          pmStartTime: result.data.pmStartTime,
          pmEndTime: result.data.pmEndTime,
        };
        yield put({ type: 'saveOrUpdateData', payload: { holiday: result.data, globalTime } });
        return globalTime;
      }
    },
    *getDepartmentTree(_, { call, put }) {
      const result = yield call(service.getDepartmentTree);
      if (result && result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { departmentTree: result.data } });
      }
    },
    // *getSearchUsers({ payload }, { call, put }) {
    //   const result = yield call(service.getSearchUsers, payload);
    //   if (result && result.code === 0) {
    //     yield put({ type: 'saveOrUpdateData', payload: { userSearchList: result.data } });
    //   }
    // },
    // 查询我的预订
    *getSystemSet({ spaceId = undefined }, { call, put }) {
      const result = yield call(service.getSystemParam, { spaceId });
      if (result && result.code === 0) {
        if (result.data && result.data.length > 0) {
          const systemSet = systemList2Obj(result.data);
          yield put({ type: 'saveOrUpdateData', payload: { systemSet, swsSetting: result.data } });
          return systemSet || {};
        } else {
          Toast.fail(formatMessage({ id: 'sws.search.no.result' }));
        }
      }
    },
    *getEleIdsByMapId({ mapId }, { call, put, select }) {
      const result = yield call(service.getEleIdsByMapId, mapId);
      if (result && result.code === 0) {
        const { data } = result;
        const stationsOnMap = yield select((state) => state.swsCommon.stationsOnMap);
        const eleIds = [];
        let mapData = [];
        data.forEach((v) => {
          const station = stationsOnMap.find((o) => o.id === v.mapElementId);
          if (!station && v.svgTypeCode === SVG_TYPE_SWS) {
            const obj = {
              id: v.mapElementId,
              color: getColorByStationStatus(STATUS_DISABLED),
            };
            eleIds.push(obj);
          }
          mapData.push({ ...v, stationNum: station?.stationNum });
        });
        // 过滤掉停用的素材
        mapData =
          data.filter((v) => {
            if (
              !(v?.objectBind && (Number(v?.objectStatus) === 1 || Number(v?.objectStatus) === 2))
            ) {
              return true;
            } else {
              return false;
            }
          }) || [];
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            eleIds,
            mapData,
          },
        });
        return mapData;
      }
      return [];
    },
    // 查询位置下所有的工位
    *getStationsByLocationId({ payload }, { call, put, select }) {
      const { isShareLocation } = yield select((state) => state.swsCommon);
      const result = yield call(service.getStationsByLocationId, {
        ...payload,
        isShareLocation,
      });
      if (result && result.code === 0) {
        Toast.hide();
        const { data } = result;
        const list = [];
        const settingData = yield select((state) => state.swsCommon.settingData);
        data.forEach((item) => {
          if (item && item.elementId) {
            const ct = getItemsColor$Text(item, settingData);
            list.push({
              id: item.elementId,
              state: item.statusCode,
              sensorStatus: item.sensorStatus,
              text: ct.text,
              color: ct.color,
              contentObj: ct.contentObj,
              stationNum: item.stationNum,
              orgId: item.orgId,
              stationId: item.id,
            });
          }
        });
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            stationsOnMap: list,
            stationData: data,
          },
        });
        return data || [];
      }
      return [];
    },
    // 扫描二维码
    *wechatQrScan({ scanType, repair }, { call, put }) {
      let res = '';
      const clientType = Object.prototype.hasOwnProperty.call(sessionStorage, 'clienttype')
        ? sessionStorage.getItem('clienttype')
        : -1;
      try {
        switch (clientType) {
          case JSSDKTYPE_WECHAT:
          case JSSDKTYPE_MINI:
            res = yield call(ws.qrScan);
            // res = 'stationId=1899&day=20190902';
            // day=20140210&;
            break;
          default:
        }
      } catch (e) {
        // alert('Open QR Scan Fail');
        return;
      }
      if (repair) {
        yield put({ type: 'swsRepair/checkRepairCode', payload: { scanType, res } });
      }
    },
    *getReservations({ current, size, payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(service.getReservations, { current, size, payload: param });
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            bookingListObj: result.data,
            bookingList: result.data.records,
            bookingSearch: param,
          },
        });
      } else if (result.code === 1 && result.msg && result.msg) {
        yield put({
          type: 'swsCommon/errorMsg',
          msg: formatMessage({ id: result.msg }),
        });
      } else {
        yield put({ type: 'swsCommon/errorMsg', msg: formatMessage({ id: 'sws.search.error' }) });
      }
    },
    *getSetting({ param }, { call, put }) {
      const result = yield call(service.getSetting, param);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            settingData: convertDisArrToObj(result.data),
          },
        });
      } else if (result.code === 1 && result.msg) {
        yield put({
          type: 'errorMsg',
          msg: formatMessage({ id: result.msg }),
        });
      } else {
        yield put({ type: 'errorMsg', msg: formatMessage({ id: 'sws.search.error' }) });
      }
    },
    // 获取地图配置信息
    *getBaseMap({ mapId, callback = () => {} }, { call, put }) {
      const result = yield call(service.getBaseMap, { mapId });
      if (result && result.code === 0) {
        const mapInfo = result.data || {};
        callback(mapInfo);
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            mapInfo,
          },
        });
      }
    },
    *saveDataBytree({ tree, bookType }, { put }) {
      const { timeZone, id, name, pathName, mapId = 3 } = tree;
      const openDateList = yield put({
        type: 'getOpenDateList',
        locationId: id,
        flag: bookType === 'special' ? 2 : 1,
      });
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          locationSelected: pathName,
          locationId: id,
          locationSelectedOne: name,
          mapId,
          timeZone: timeZone || 'Asia/Shanghai',
        },
      });
      yield put({ type: 'getBaseMap', mapId });
      return openDateList;
    },

    // 查询用户默认地图
    *getUserMap(_, { call, put, select }) {
      const { curDefaultZone } = yield select((state) => state.user);
      const { spaceTree } = yield select((state) => state.swsCommon);
      if (curDefaultZone) {
        const locationId = curDefaultZone;
        const result = yield call(service.getDefaultMap, locationId);
        if (result && result.code === 0) {
          if (result.data && result.data.mapId) {
            const { mapId, floorId } = result.data || {};
            const defaultSpace = findByRecursionId(spaceTree, floorId);
            if (defaultSpace.id && defaultSpace?.isAuthFlag) {
              yield put({
                type: 'saveOrUpdateData',
                payload: { defaultLocation: { mapId, locationId: floorId } },
              });
              return;
            }
          }
        }
      }
      const searchData = searchPermissionByMap({ treeData: spaceTree });
      window.console.log(searchData, 'getDefaultMap1------');
      if (searchData?.mapId) {
        const { mapId, id: floorId } = searchData;
        yield put({
          type: 'saveOrUpdateData',
          payload: { defaultLocation: { mapId, locationId: floorId } },
        });
      }
    },

    // 特殊预约代订界面用户搜索
    *getSearchUsers({ payload }, { call, put }) {
      const result = yield call(service.getSearchUsers, payload);
      if (result && result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { userSearchList: result.data } });
      }
    },
    *getSearchOutUsers({ payload }, { call, put }) {
      const result = yield call(service.getSearchOutUsers, payload);
      if (result && result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { userSearchOutList: result.data || [] } });
        return result.data || [];
      }
    },
    *getMapColor(_, { call, put, select }) {
      const { mapColors } = yield select((state) => state.swsCommon);
      if (JSON.stringify(mapColors) !== `{}`) {
        return;
      }
      const result = yield call(service.getMapColor);
      if (result.code === 0) {
        if (result.data) {
          const { colors = {} } = result.data || {};
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              mapColors: colors,
            },
          });
        }
      } else {
        Toast.fail(formatMessage({ id: result.msg }), 1);
      }
    },
    // 可预订的日期列表
    *getOpenDateList({ locationId, flag }, { call, put }) {
      const result = yield call(service.getOpenDates, { locationId, flag });
      if (result && result.code === 0) {
        const openDateList = result.data || {};
        yield put({ type: 'saveOrUpdateData', payload: { openDateList } });
        return openDateList;
      }
      return {};
    },
    *savePathNameArr({ pathname }, { put, select }) {
      const { pathNameArr } = yield select((state) => state.swsCommon);
      if (pathNameArr.length >= 5) {
        pathNameArr.shift();
      }
      pathNameArr.push(pathname);
      const uniqueArray = [...new Set(pathNameArr)];
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          pathNameArr: uniqueArray,
        },
      });
    },
    *getSwsSetting(_, { put, select, call }) {
      const { swsSetting } = yield select((state) => state.swsCommon);
      // 有数据禁止再次请求
      if (swsSetting?.length) {
        return;
      }
      const result = yield call(service.getSwsSetting);
      if (result.code === 0) {
        if (result.data) {
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              swsSetting: result.data,
            },
          });
        }
      } else {
        Toast.fail(formatMessage({ id: result.msg }), 1);
      }
    },
    *oftenSpace({ number }, { call, put }) {
      const result = yield call(service.oftenSpace, number);
      if (result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            oftenSpaceList: result.data,
          },
        });
      }
    },
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        dispatch({ type: 'savePathNameArr', pathname });

        if (pathname.includes('/workspace') && sessionStorage.getItem('access_token')) {
          // getSwsSetting内部判断如果有设置数据，则return 避免重复请求
          dispatch({ type: 'getSwsSetting', pathname });
        }
      });
    },
  },
};
