/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  cloudHub_finish: '完成',
  cloudHub_virsical: '威思客',
  cloudHub_submit: '提交',
  cloudHub_hasbind: '賬號已綁定',
  cloudHub_inputAccount: '請輸入賬號',
  cloudHub_notUserNameEmpty: '用戶名不能為空!',
  cloudHub_notPassWordEmpty: '密碼不能為空!',
  cloudHub_inputPassword: '請輸入密碼',
  cloudHub_close: '關閉',
  cloudHub_getStateFail: '獲取用戶綁定狀態失敗，請關閉重試',
  cloudHub_bindFail: '綁定賬號失敗',
  106000: '系統异常',
  106504: '認證失敗！',
  106505: '用戶名或密碼錯誤！',
};
