export default {
  'sws.immediate.hours': '小时',
  'sws.immediate.submit': '提交',
  'sws.immediate.station': '工位',
  'sws.immediate.address': '区域',
  'sws.immediate.starttime': '开始时间',
  'sws.immediate.endtime': '结束时间',
  'sws.immediate.ok': '确认',
  'sws.immediate.cancel': '取消',
  'smt.immediate.select': '请选择',
  'sws.immediate.select.time': '选择时间',
  'sws.immediate.submit.success': '提交成功',
  'sws.immediate.submit.error': '提交失败',
  'sws.immediate.no.worktime': '不在上班时间，不能扫码预订',
  401128: '预订工位未分配部门',
  401140: '传感判定该工位无人,签到失败',
  401141: '传感器判定工位无人，无法使用工位',

  'sws.immediate.continue': '继续使用',
  'sws.immediate.tip': '提示',
  401855: '当前工位未绑定插座设备',
  401857: '【{facilityName}】，设备已下线，是否继续使用?',
  401856: '所选工位已被预约或占用',

  'sws.immediate.remark.tip': '例如：项目编号'
};
