export default {
  'menu.app.index.space': '空间管理',
  'menu.app.index.map.sub': '空间地图',
  'index.map.click.search.placeholder': '搜索',
  'index.map.search.station.placeholder': '请输入搜索关键字',
  'index.map.btn.cancel': '取消',
  'index.map.searchList.noData': '暂无数据',
  104018: '目标区域已下线，无法进行跳转',

  'index.map.select.time.range': '时间范围：',
  'map.color.setting.material.status.1': '空闲',
  'map.color.setting.material.status.2': '占用',
  'map.color.setting.material.status.3': '不可用',

  'index.map.space.local': '本地区域',
  'index.map.space.share': '共享区域',

  'index.map.space.setting.default.space': '默认区域设置',
  'index.map.space.setting.default.placeholder': '去设置',
  'index.map.btn.back': '返回',
  'index.map.space.setting.privacy': '隐私设置',
  'index.map.space.setting.privacy.placeholder': '首页地图允许搜到我',
};
