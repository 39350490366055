/**
 * Created by XuQiang on 2017/3/14.
 */
export const VERSION_NUMBER = '0.0.001';

export const CHINESE = 'zh-CN';
export const TRADITIONAL = 'zh-TW';
export const ENGLISH = 'en-US';

export const CLIENT_ID = 'VMS';
export const CLIENT_SECRET = 'K3LTSg32SO';

export const APP_HOME = `/virsical`;
export const APP_HOME_PIX = `/visit`;

export const APP_ROUTE_PATH = {
  APP_RESERVE: `/reserve`,
  APP_CONFIRM: `/confirm`,
  APP_CONFIRM_DETAIL: `/confirm-detail`,
  APP_VISIT: `/visit`,
  APP_AREA: `/area`,
  APP_LOOP: `/loop`,
  APP_MAP: `/map`,
  APP_AGAIN: `/again`,
  APP_DELAY: `/delay`,
  APP_SURE: `/sure`,
  APP_INVITATION_CODE: `/invitation-code`,
  APP_STATISTICAL_ANALYSIS: `/statistical-analysis`,
  APP_VISIT_NEW: `/visit-new`,
  APP_VISITOR_NEW: `/visitor-new`,
  APP_CONTACTS: `/contacts`,
  APP_VISIT_DETAIL: `/visit-detail`,
  APP_VISIT_ACCOMPANY: `/visit-accompany`,
  APP_VISIT_EPIDEMIC: `/visit-epidemic`,
  APP_PARENT_VISIT_DETAIL: `/parent-visit-detail`,
  APP_MEETING_NEW: `/conference-new`,
  APP_VISIT_HOMEPAGE: `/visit-homepage`,
  APP_HISTORY_VISITOR: `/history-visitor`,
  APP_MY_INFO: `/myInfo`,
};
export const AMAP_KEY = '1927ab84465aec448189a31a21bd037a';

export const PNG_CONVERT_SIZE = 1000000;
export const JPG_CONVERT_QUALITY = 0.6;
export const encryptionKey = 'waferSystems';

/* 企业管理员 */
export const IS_ENTERPRISE_ADMINISTRATOR = 4;

// 判断是不是iPhoneX
export const isIphoneX = () => {
  return true;
  // if (typeof window !== 'undefined' && window) {
  //   return /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
  // }
  // return false;
};

export const customListToInfo = (list) => {
  const info = {};
  if (list && list.length) {
    list.map((item) => {
      info[item.id] = item.defaultValue || '';
      return info;
    });
  }
  return info;
};

/**
 * 改变时间至 最近的 15 分钟倍数; 向后优先原则, 时间会比参数长
 * @param time is moment
 */
export const changeTimeAfter = (time) => {
  const m = Number(time.format('mm'));
  const muti = Math.floor(m / 15) + Number(m % 15 > 0);
  const minute = 15 * muti;
  if (minute === 0) {
    return time;
  }
  if (minute < 60) {
    return time.startOf('hour').add(minute, 'm');
  }
  return time.startOf('hour').add(1, 'h');
};

/**
 * 改变时间至 最近的 15 分钟倍数; 向前优先原则, 时间会比参数短
 * @param time is moment
 */
export const changeTimeBefore = (time) => {
  const m = Number(time.format('mm'));
  const muti = Math.floor(m / 15);
  const minute = 15 * muti;
  if (minute === 0) {
    return time;
  }
  return time.startOf('hour').add(minute, 'm');
};

export const blobToBase64 = (blob, callback) => {
  const a = new FileReader();
  a.onload = (e) => {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
};

export const defaultNoteConfig = {
  templateKey: 1,
  thumbnailUrl: '',
  otherConfig: {
    meetingColor: '#0073A6',
    titleColor: '#000000',
    contentColor: '#000000',
  },
  backgroundConfig: {
    optionkey: 2,
    pureColor: '#B5BCD5',
    gradient: ['#EEF1FF', '#B5BCD5'],
    backgroundImage: '',
  },
  buttonConfig: {
    optionkey: 4,
    pureColor: '#5F677D',
  },
  codeConfig: {
    pureColor: '#000000',
    codePoint: 'rect',
    codeEye: 'rect',
    faultRate: 'M',
    logoUrl: '',
    logoShape: '2',
    logoProjection: 1,
  },
};

// 访问状态提示文案
export const statusTipConfig = {
  0: 'visit.status.0.tip',
  1: 'visit.status.1.tip',
  2: 'visit.status.2.tip',
  3: 'visit.status.3.tip',
  4: 'visit.status.4.tip',
  5: 'visit.status.5.tip',
  6: 'visit.status.6.tip',
  7: 'visit.status.7.tip',
  8: 'visit.status.8.tip',
  9: 'visit.status.9.tip',
  10: 'visit.status.10.tip',
  11: 'visit.status.11.tip',
  12: 'visit.status.12.tip',
};

// 访问类型提示文案
export const visitorSelfFlagTipConfig = {
  0: 'visit.selfFlag.0.tip',
  1: 'visit.selfFlag.1.tip',
  2: 'visit.selfFlag.2.tip',
  3: 'visit.selfFlag.3.tip',
};

// 历史访客（从会议copy过来的）
export const UserColor = [
  'linear-gradient(to bottom right, #D6CEFA 0%, #ACA0F2 100%)',
  'linear-gradient(to bottom right, #FFD9A3 0%, #FFB26A 100%)',
  'linear-gradient(to bottom right, #5ECFC9 0%, #30A299 100%)',
  'linear-gradient(to bottom right, #B4E497 0%, #86D057 100%)',
  'linear-gradient(to bottom right, #A1C6FF 0%, #5E9DFF 100%)',
  'linear-gradient(to bottom right, #FFA6A3 0%, #FF6D6A 100%)',
];

// 访客通知语言options
export const visitorNotificationLanguageOptions = [
  {
    label: '简体中文',
    value: 'zh_CN',
  },
  {
    label: '繁體中文',
    value: 'zh_TW',
  },
  {
    label: 'English',
    value: 'en_US',
  },
];

// 是iPhoneX或底部存在home键时需要额外高度
export const getBottomExtraHeight = () => {
  return isIphoneX() ? 98 / 16 : 80 / 16;
};

export const ENGLISH_MONTH_ARRAY = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const DATE_FORMAT_CHINESE = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_ENGILSH = 'MMM Do, YYYY, HH:mm';
