import React from 'react';
import { Icon } from 'antd-mobile-v2';

// loading components from code split
// https://umijs.org/plugin/umi-plugin-react.html#dynamicimport
export default () => (
  <div style={{ paddingTop: 100, textAlign: 'center' }}>
    <Icon type="loading" />
  </div>
);
