export default {
  'sws.scan.sign.error': '系统暂不支持扫描签到',
  'sws.scan.fixed.error': '系统暂不支持扫描固定工位',
  401118: '预订记录不存在，签到失败。',
  401119: '已签到，请勿重复签到。',
  401120: '未到签到时间，请在签到时间内({value1})进行签到。',
  401121: '该工位已释放或未预订，签到失败',
  401125: '工位正在被使用，请{value1}分钟后进行签到。',
  401046: '特殊预订工位不支持扫码。',
  401047: '特殊预订工位不支持扫码签到。',
  401126: '此工位已被其他人预订，签到失败',
  401044: '工位{value1}未分配使用部门',
  401057: '二维码已失效',
  401138: '当前时间不在签到时间范围内',
  403016: '储物柜部门与用户部门不一致',
  403041: '预定储物柜未分配部门',
  403042: '当前用户没有储物柜{value1}的部门权限',
  401857: "{value1}设备已下线,是否继续使用?",
  'sws.scan.fix.error': '你已经绑定该工位',
  'sws.scan.error.tip': '该工位不支持扫码预订，请更换工位！',
  'sws.scan.fixed.sign': '签到成功',
  'sws.scan.fixed.bind': '绑定成功',
  'sws.scan.fixed.change': '工位变更成功',
  'sws.scan.fixed.username': '使用者',
  'sws.scan.TimeLast': '提醒：最近一次即将使用的时间为：',
  'sws.scan.next.booking': '下一预订',
};
