import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import {
  getDeptTree,
  getOrderInfo,
  getOrderRules,
  updateLoadImage,
  getUserInfoById,
  createPunishmentOrder,
  cancelPunishmentOrder,
} from './service';

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);

export default {
  namespace: 'punishment',
  state: {
    searchDate: now,
    curSelectIndex: 0,
    deptTree: [],
    editData: {},
    expandedKeys: [],
    selectDept: '',
    searchDept: [],
    punishmentRecordData: { records: [], current: 1, size: 10, total: 0, pages: 1 },
    curPage: 1,
    dataArr: [],
    pickerItem: {},
    modalVisible: false,
    punishmentDetailData: {},
    punishmentRules: [],
    imageFiles: [],
    selectDeptDetail: {
      accountName: '',
      accountDeptId: '',
      administrators: '',
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getDeptTree(_, { call, put }) {
      const result = yield call(getDeptTree);
      if (result.code === 0) {
        yield put({
          type: 'save',
          payload: {
            deptTree: result.data,
          },
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
        yield put({
          type: 'save',
          payload: {
            deptTree: [],
          },
        });
      }
    },
    *getOrderInfo({ page, size }, { call, put, select }) {
      const result = yield call(getOrderInfo, {
        current: page || 1,
        size: size || 10,
        accountType: 1,
        businessType: 3,
      });
      if (result.code === 0) {
        if (page > 1) {
          const { data } = result;
          const { punishmentRecordData } = yield select((state) => state.punishment);
          data.records.push(...punishmentRecordData.records);
          Object.assign(punishmentRecordData, data);
          yield put({
            type: 'save',
            payload: { punishmentRecordData: punishmentRecordData || {}, curPage: page },
          });
        } else {
          yield put({
            type: 'save',
            payload: {
              punishmentRecordData: result.data || {},
              curPage: page,
              modalVisible: false,
              pickerItem: {},
            },
          });
        }
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *getOrderRule(_, { call, put }) {
      const result = yield call(getOrderRules, {
        businessType: 3,
      });
      if (result.code === 0) {
        yield put({ type: 'save', payload: { punishmentRules: result.data || [] } });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *updateOrderInfo(_, { put }) {
      yield put({
        type: 'save',
        payload: {
          dataArr: [],
          punishmentRecordData: { records: [], current: 1, size: 10, total: 0, pages: 1 },
        },
      });
      yield put({ type: 'getOrderInfo', page: 1, size: 10 });
    },
    *updateLoadImage({ formData }, { call, select, put }) {
      const response = yield call(updateLoadImage, { formData });
      if (response.code === 0) {
        window.console.log(response);
        let { imageFiles } = yield select((state) => state.punishment);
        const file = { url: response.data.fileUrl };
        if (imageFiles.length === 0) {
          imageFiles = [{ url: response.data.fileUrl }];
        } else {
          imageFiles.push(file);
        }

        yield put({
          type: 'save',
          payload: { imageFiles },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
    *getUserInfoById({ userId }, { call, put, select }) {
      const result = yield call(getUserInfoById, { userId });
      if (result.code === 0) {
        const { editData } = yield select((state) => state.punishment);
        yield put({
          type: 'save',
          payload: { editData: { ...editData, administratorName: result.data.realName } },
        });
      }
    },
    *cancelPunishmentOrder({ id }, { call, put }) {
      const result = yield call(cancelPunishmentOrder, { id });
      if (result.code === 0) {
        Toast.success(formatMessage({ id: 'points.punishment.new.record.cancel.success' }));
        yield put({ type: 'updateOrderInfo' });
      }
    },
    *createPunishmentOrder({ params }, { call, put }) {
      const response = yield call(createPunishmentOrder, { ...params });
      if (response.code === 0) {
        Toast.success(formatMessage({ id: 'points.punishment.new.record.add.success' }));

        yield put({
          type: 'save',
          payload: {
            editData: {},
            searchDate: new Date(Date.now()),
            imageFiles: [],
            expandedKeys: [],
            selectDept: '',
          },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
  },
  subscriptions: {},
};
