import { formatMessage } from 'umi';
import moment from 'moment-timezone';
import { parse } from 'qs';
import { Toast } from 'antd-mobile-v2';
import * as service from './service';
import * as wechatService from '@/pages/WorkSpace/Station/FacilityManage/services/wechat';
import * as applyService from '@/pages/WorkSpace/Station/FacilityManage/services/services';
import * as facilityService from '@/pages/WorkSpace/Station/FacilityManage/service';
import * as virsicalService from '@/pages/WorkSpace/Station/FacilityManage/services/virsical';
import { sleep } from '../../../../utils/utils';

export default {
  namespace: 'newswsInventory',
  state: {
    defaultId: '',
    defaultStationNum: '',
    isValid: true,
    searchStation: [],
    swsInventoryData: {},
    isSuccess: false,
    hasInventory: false,
    nowStation: [],
    searchFlag: false // 通过查询界面跳转打开页面
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getStationCheckInfo({ payload }, { call, put, select }) {
      const { searchFlag } = yield select( state => state.newswsInventory)
      const { date, day, isshow } = payload;
      const curDate = moment().valueOf();
      if (searchFlag) return;
      if (curDate && date && day) {
        const differ = Number(curDate) - Number(date);
        window.console.log(
          date,
          curDate,
          day,
          differ,
          Number(day) * 24 * 60 * 60 * 1000,
          'getStationCheckInfo',
        );
        // if (differ <= Number(day) * 24 * 60 * 60 * 1000) {
        const result = yield call(service.getStationCheckInfo2, payload);
        if (result && result.code === 0) {
          const nowStation = [];
          if (result.data.nowStationId) {
            result.data.nowStationId.split(',').forEach((item, index) => {
              nowStation.push({
                stationId: Number(item),
                stationNum: result.data.nowStationNum.split(',')[index],
                locationName: result.data.nowLocationName.split(',')[index],
                locationId: Number(result.data.nowLocationId.split(',')[index]),
              });
            });
          }
          Toast.hide();
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              swsInventoryData: result.data,
              defaultStationNum: result.data?.nowStationNum.split(','),
              defaultId: payload.id,
              hasInventory:
                (`${result.data.state}` === '0' && Number(result.data.checkStatus) !== 0) ||
                `${result.data.state}` === '1', // 已终止，或者未终止但是不是未盘点   state 1已终止，0进行中  checkStatus: 0 未盘点，1进行中，2已盘点
              isshow: !!isshow,
              nowStation,
            },
          });
          if (differ <= Number(day) * 24 * 60 * 60 * 1000) {
            yield put({
              type: 'saveOrUpdateData',
              payload: {
                isValid: true,
              },
            });
          } else {
            yield put({
              type: 'saveOrUpdateData',
              payload: {
                isValid: false,
              },
            });
          }
        } else if (result && result.code === 1) {
          // Toast.fail(formatMessage({ id: result.msg }));
          yield put({
            type: 'saveOrUpdateData',
            payload: { swsInventoryData: {}, isValid: false },
          });
        }
        // } else {
        //   yield put({
        //     type: 'saveOrUpdateData',
        //     payload: { swsInventoryData: {}, isValid: true },
        //   });
        // }
      }
    },
    *getStationNums({ payload }, { call, put }) {
      const result = yield call(service.getStationNums, payload);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            searchStation: result.data || [],
          },
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
        yield put({ type: 'saveOrUpdateData', payload: { searchStation: [] } });
      }
    },
    *weChatQrScan({ scanType }, { call, put }) {
      /* eslint-disable*/
      const res = yield call(wechatService.qrScan, scanType);
      yield put({ type: 'handleResult', res });
    },
    *handleResult({ res }, { call, put, select }) {
      const result = decodeURIComponent(res);
      if (result) {
        // const { nowStation } = yield select((state) => state.newswsInventory);
        const index = result.indexOf('sId');
        const sId = encodeURIComponent(
          result.substr(index, result.length).split('&')[0].split('sId=')[1],
        );
        console.log(index, '打印index', sId, result);
        yield put({ type: 'searchScanStationInfo', stationId: sId });
      } else {
        Toast.fail(formatMessage({ id: 'sws.inventory.qrCode.error' }));
      }
    },
    *searchScanStationInfo({ stationId }, { call, put, select }) {
      const result = yield call(service.searchScanStationInfo2, stationId);
      const { nowStation } = yield select((state) => state.newswsInventory);
      console.log(nowStation, 'nowStation', result);
      if (result && result.code === 0) {
        const station = {
          stationId: result.data.stationId,
          locationName: result.data?.locationName,
          stationNum: result.data?.stationNum,
          locationId: Number(result.data?.locationIdNow),
        };
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            nowStation:[station],
          },
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *configScan({ scanType }, { call, put }) {
      const data = yield call(applyService.getJssdkTicket);
      if (Number(data.code) === 0) {
        yield call(wechatService.init, data.data, ['scanQRCode']);
        sleep(3000);
        yield put({ type: 'weChatQrScan', scanType });
      } else {
        Toast.fail(formatMessage({ id: data.msg }));
      }
    },
    *virsicalQrScan(_, { call, put }) {
      const data = yield call(facilityService.getWaferAppInfo);
      if (data.code === 0) {
        const res = yield call(virsicalService.qrScan, data.data);
        yield put({ type: 'handleResult', res });
      } else {
        const errorText = formatMessage({ id: data.msg });
        Toast.fail(errorText);
      }
    },
    *checkConfirm({ payload }, { call, put }) {
      const result = yield call(service.checkConfirm, payload);
      if (result && result.code === 0) {
        Toast.success(formatMessage({ id: 'common.action.success' }));
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            isSuccess: true,
          },
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
        yield put({ type: 'saveOrUpdateData', payload: { isSuccess: false } });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
        console.log(history,'history')
        if (pathname.trim().length > 0 && pathname.includes('/workspace/station/newinventory')) {
          const { id, date, day, isshow } = query;
          console.log(query, 'query', isshow);
          dispatch({ type: 'getStationCheckInfo', payload: { id, date, day, isshow } });
        }
      });
    },
  },
};
