export default {
  'sws.delay.hours': 'Hours',
  'sws.delay.pc.title': 'Extend to',
  'sws.delay.pc.station': 'Space：',
  'sws.delay.pc.address': 'Location：',
  'sws.delay.pc.starttime': 'Start Time：',
  'sws.delay.pc.endtime': 'End Time：',
  'sws.delay.pc.delaytime': 'Extend：',
  'sws.delay.submit': 'Submit',
  'sws.delay.mobile.station': 'Space',
  'sws.delay.mobile.address': 'Location',
  'sws.delay.mobile.starttime': 'Start Time',
  'sws.delay.mobile.endtime': 'End Time',
  'sws.delay.mobile.delaytime': 'Extend to',
  'sws.delay.ok': 'Confirm',
  'sws.delay.cancel': 'Cancel',
  'sws.delay.select.delay': 'Extend to',
  'sws.delay.input.delay': 'Extending Hours',
  'sws.delay.input.delay.unit': 'Unit：Hour',
  'sws.delay.submit.success': 'Extend Successfully!',
  'sws.delay.submit.error': 'Extend is Fail',
  'sws.delay.hour.error': 'Extending Policy is longest time less than {hour} hours',
  'sws.delay.hour.null': 'Please manual input extending hours',
};
