export default {
    'sws.cover.current.use': 'current user',
    'sws.cover.reason': 'reason for applying',
    'sws.cover.history.tip': 'whether to unbind the previous workstation',
    'sws.cover.history.0': 'is',
    'sws.cover.history.1': 'No',
    'sws.cover.reply.station.info': 'Workstation Information',
    'sws.cover.reply.station.num': 'station number',
    'sws.cover.reply.station.man': 'applicant user',
    'sws.cover.reply.station.reason': 'reason for applying',
    'sws.cover.reply.station.remark': 'reply',
    'sws.cover.reply.station.ok': 'agree',
    'sws.cover.reply.station.no': 'refuse',
    'sws.cover.reply.submit.ok': 'Application successful',
    'sws.cover.reply.reason.zw': 'None',

    401148: 'Do not repeat the operation',
    401860: 'The workstation binding user has changed, please scan the code and try again',
};
