import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';
import { stringify } from 'qs';

/**
 * 工位扫码
 * @param sId 工位id
 * @param t 时间戳
 * @returns {Promise<questMethodAddToUrl>}
 */
export const getScanResult = (sId, t) => {
  let url = `/${RESTFUL_PATH.sws}/api/station/scan?stationId=${encodeURIComponent(sId)}`;
  if (t) {
    url += `&t=${t}`;
  }
  return request(url, {
    method: 'GET',
  });
};
/**
 * 扫码立即使用界面显示预订时间
 * @param sId 工位id
 * @param t 时间戳
 * @returns {Promise<questMethodAddToUrl>}
 */
export const getSoonest = (sId, t) => {
  let url = `/${RESTFUL_PATH.sws}/api/reserve/soonest-scan?stationId=${encodeURIComponent(sId)}`;
  if (t) {
    url += `&t=${t}`;
  }
  return request(url, {
    method: 'GET',
  });
};

/**
 * 工位扫码
 * @param sId 工位id
 * @param t 时间戳
 * @returns {Promise<questMethodAddToUrl>}
 * *type=1 app扫码，没有type是一体机扫码
 */
export const getSignInResult = (sId) => {
  const url = `/${RESTFUL_PATH.sws}/api/reserve/sign?stationId=${encodeURIComponent(sId)}&type=1`;
  return request(url, {
    method: 'GET',
  });
};

export const fixedBind = (data) => {
  let url = `/${RESTFUL_PATH.sws}/api/station/removeStationUser`;
  return request(url, {
    method: 'POST',
    body: JSON.stringify(data)
  });
}
