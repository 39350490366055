export default {
  'sws.search.show.station.line': '显示路线',
  'sws.search.hidden.station.line': '隐藏路线',
  'sws.search.error.tip': '没有相关搜索结果',
  'sws.search.station.tip': '可以在搜索框输入姓名进行工位查询~',
  'sws.search.station.placeholder': '请输入姓名',
  'sws.search.no.path': '该工位暂无导引线',
  'sws.search.go': '去这里',
  'sws.search.nav.start': '模拟导航',
  'sws.search.nav.end': '结束导航',
  'sws.search.no.floor': '不在同一楼层不支持模拟导航',
  'sws.search.no.startEnd': '起始点不能是同一个',
  'sws.search.no.search': '导航未结束不允许修改起始位置, 请先结束导航',
  'sws.search.start.location': '请输入起点',
  'sws.search.end.location': '请输入终点',
  'sws.search.start.value': '前台',
  'sws.search.look': '查看位置',
  'sws.search.back': '返回上页',
  'sws.search.people.no.station': '没有查询到{value1}的工位信息',
  'sws.search.free': '空闲',
  'sws.search.occupy': '占用',
  'sws.search.no.station': '当前人员没有正在预订或绑定的工位',
  401142: '所选择的时间段不是工作日，无法预约',
};
