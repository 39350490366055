export default {
  //设置界面
  'sws.liftTable.dept': 'Dept: ',
  'sws.liftTable.memory.height': 'Mode Setting',
  'sws.liftTable.standing.height': 'Standing',
  'sws.liftTable.sitting.height': 'Sitting',
  'sws.liftTable.sedentary.reminder': 'Oversize Sitting Not',
  'sws.liftTable.reminder.method': 'How to Notice me',
  'sws.liftTable.reminder.duration': 'How Long Notice me',
  'sws.liftTable.lunch.break': 'Do Not Distribute Me',
  'sws.liftTable.automatic.homing': 'Reset to Default({value}cm)',
  'sws.liftTable.please.enter': 'Please enter the height',
  'sws.liftTable.minute': 'Mins',
  'sws.liftTable.set.success': 'Set successfully',
  // 控制界面
  'sws.liftTable.hour': 'H',
  'sws.liftTable.stand.today': 'Standing: ',
  'sws.liftTable.stand-to-sit.ratio': 'Stand vs Sitting = ',
  'sws.liftTable.standing.number': 'Standing',
  'sws.liftTable.sitting.number': 'Sitting',
  'sws.liftTable.burn.calories': 'Calories',
  'sws.liftTable.second-rate': 'Active',
  'sws.liftTable.current.altitude': 'Current Heigh',
  'sws.liftTable.stand': 'Standing',
  'sws.liftTable.stand1': 'Standing: ',
  'sws.liftTable.reset': 'Normal',
  'sws.liftTable.sitting.position': 'Sitting',
  'sws.liftTable.sitting.position1': 'Sitting: ',
  'sws.liftTable.no.control.data': 'You currently do not have a controllable lift table',
  // 数据界面
  'sws.liftTable.shared': 'Shared',
  'sws.liftTable.fixed': 'Fixed',
  'sws.liftTable.no.data': 'No data',
  'sws.liftTable.time': 'Scheduled: ',
  //底部菜单
  'sws.liftTable.control': 'Mode',
  'sws.liftTable.data': 'Records',
  'sws.liftTable.set': 'Profile',
  401088:'The type of workstation furniture queried is empty or repeated'
};
