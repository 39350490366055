export default {
  'meeting.manage.title.service': '会议服务申请',
  'meeting.manage.need.attend_': '参会人：',
  'meeting.manage.sign.in': ' 人签到, ',
  'meeting.manage.signed.people': '人',
  'meeting.manage.signed.people,': '人，',
  'meeting.manage.signed.in': '已签到',
  'meeting.manage.not.sign': '未签到',
  'meeting.manage.participant.not.sign': ' 人未签到',
  'meeting.manage.sign.time_': '签到时间：',
  'meeting.manage.retroactive': '补签',
  'meeting.manage.service.type_': '服务项目：',
  'meeting.manage.service.level_': '服务等级',
  'meeting.manage.service.level.select.title':'请选择服务等级',
  'meeting.manage.service.payer_': '代付人',
  'meeting.manage.service.payer_unset': '未指定',
  'meeting.manage.service.describe_': '描述：',
  'meeting.manage.service.category_': '服务类别：',
  'meeting.manage.service.unit.price': '元 / {unit}',
  'meeting.manage.service.unit.yuan': '元',
  'meeting.manage.service.upload': '上传',
  'meeting.manage.service.input.remark': '请输入备注文字',
  'meeting.manage.service.total_': '总计：',
  'meeting.manage.service.fee.tip': '会议服务仅对就近一场会议生效!',
  'meeting.manage.service.level': '请选择服务类别',
  'meeting.manage.service.noData': '暂无会议服务',
  'meeting.manage.back.home': '返回首页',
  'meeting.manage.previous.page': '返回上一页',
  'meeting.manage.back.mine': '会议列表',

  // 文件上传
  'meeting.manage.upload.file.type.fail': '上传文件类型不对',
  'meeting.manage.upload.file.fail.value1': '上传照片不能大于5M',
  'meeting.manage.upload.file.fail.value2': '上传文件不能大于10M',
  'meeting.manage.upload.file.fail.value3': '文件格式解析失败',
  'meeting.manage.upload.file.fail.value4': '已选择会议服务需要上传附件!',
};
