export default {
  namespace: 'lockerOpen',
  state: {
    failReason: '',
    // none, success or fail
    result: 'none',
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {},
  subscriptions: {},
};
