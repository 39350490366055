export default {
  'menu.lighting.manage': '照明管理',
  'title.humidity': '室内湿度',
  'title.env': '环境指数',
  'title.humidity.sub': '参考值：30%-80%舒适',
  'title.wendu': '室内温度',
  'title.wendu.sub': '参考值：15℃-20℃舒适',
  'title.co2.sub': '参考值：≤800PPM空气清新',
  'title.pm25.sub': '参考值：<75 空气清新',
  'title.voc.sub': '参考值：<125 空气清新',
  'title.energy': '照明能耗统计',
  'title.air.energy': '空调能耗统计',
  'none.area.auth': '没有任何授权的区域',
  'none.area.find': '没找到可用的区域',
  'env.comfort': '舒适',
  'env.wendu.high': '温度过高',
  'env.wendu.low': '温度较低',
  'env.shidu.shushi': '舒适',
  'env.shidu.chaoshi': '潮湿',
  'env.shidu.ganzao': '干燥',
  'env.co2.0': '空气清新',
  'env.co2.1': '符合标准',
  'env.co2.2': '轻度污染',
  'env.co2.3': '严重污染',
  'env.pm25.0': '空气清新',
  'env.pm25.1': '符合标准',
  'env.pm25.2': '轻度污染',
  'env.pm25.3': '重度污染',
  'env.voc.0': '舒适',
  'env.voc.1': '符合标准',
  'env.voc.2': '轻度污染',
  'select.area': '请先选择区域',
  'title.energy.all': '能耗统计',
  'env.filter.0': '今日',
  'env.filter.1': '本周',
  'env.filter.2': '本月',
  'env.filter.3': '年度',
  'menu.slt.app.lighting': '智慧照明',
  'menu.slt.app.report.environment': '环境分析',
  'menu.slt.app.report.energy': '能耗分析',
  'light.floor': '楼层',
  'light.select.floor': '选择楼层',
  'light.area': '区域',
  'light.select.area': '选择区域',

  '501000': '面板信息不存在！',
  '501001': '场景更新失败',
  '501002': '获取区域状态失败',
  '501003': '未配置指令',
  '501004': '通道更新失败',
};
