import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';
/**
 * 升降桌偏好设置显示
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function findLiftTableByUserId() {
  const url = `/${RESTFUL_PATH.sws}/api/liftTable/findLiftTableByUserId`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 偏好设置保存
 * @param params 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function saveLiftTableSetting(params) {
  return request(`/${RESTFUL_PATH.sws}/api/liftTable/saveLiftTableSetting`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
/**
 * 数据
 * @param params 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getMyTableList(params) {
  const url = `/${RESTFUL_PATH.sws}/api/liftTableUserRecord/page`;
  return request(url, {
    method: 'GET',
    params,
  });
}
/**
 * 用户拥有的升降桌列表
 * @param params 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getControlStationNumList(params) {
  const url = `/${RESTFUL_PATH.sws}/api/liftTable/getLiftTableStation`;
  return request(url, {
    method: 'GET',
    params,
  });
}
/**
 * 升降桌控制数据
 * @param params 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getControlData(params) {
  const url = `/${RESTFUL_PATH.sws}/api/liftTable/getControlData`;
  return request(url, {
    method: 'GET',
    params,
  });
}
/**
 * 升降桌高度控制
 * @param params 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function saveHeightControl(params) {
  const url = `/${RESTFUL_PATH.sws}/api/liftTable/heightControl`;
  return request(url, {
    method: 'GET',
    params,
  });
}
