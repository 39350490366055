export default {
  'room.btn.add': 'Add',

  'subscribe.fail.zoom.scale': 'Please choose number of attendee',
  'subscribe.fail.emptyDelayTime': 'Please choose extension time!',
  'subscribe.fail.delayTimeToMax': 'Extension time should not be more than {min} mins！',
  'subscribe.fail.delayTimeToMin': 'Extension time should not be less than {min} mins！',
  'subscribe.fail.emptyMeetingTime': 'Please choose meeting time!',
  'subscribe.fail.zoom.days.limit': 'Please book a zoom meeting within 90 days!',
  'subscribe.fail.feishu.days.limit': `Feishu meeting can't book a meeting in a month!`,
  'subscribe.time.longer.than.forty.mins':
    'The number of attendees you choose requires that the meeting not exceed 40 minutes!',
  'subscribe.fail.emptySubject': 'Please enter meeting subject',
  'subscribe.subject.placeholder': "'s meeting",
  'subscribe.fail.memberConflict': 'There is a conflict between the host and the attendee!',
  'subscribe.remark.placeholder': 'Enter meeting remarks',
  'subscribe.fail.one.host': 'Only one host can be selected for the network meeting!',
  'subscribe.fail.value0': 'Please select the host！',
  'subscribe.fail.value1': 'Meeting subject cannot be empty!',
  'subscribe.fail.visit.cross': 'For non-cross-day meetings, the visit date must be the same day!',
  'subscribe.fail.value2': 'Visitors appointment is not allowed for immediate meetings',
  'subscribe.fail.value3': 'Periodic meetings cannot make appointment with visitors',
  'subscribe.reservation': 'Submit',
  'subscribe.reminding': 'Notice',
  'subscribe.secret': 'Confidential meeting',
  'subscribe.secret.text':
    'Meeting subject will not be displayed for non-attendees and on public display screen.',
  'subscribe.alluser': 'All staff',
  'subscribe.alluser.pls': 'All staff',
  'subscribe.reminding.ways': 'Email',
  'subscribe.meeting.type': 'Meeting type',
  'subscribe.meeting.type.native': 'Local',
  'subscribe.meeting.type.phone': 'Phone',
  'subscribe.meeting.type.video': 'Video',
  'subscribe.meeting.sign.in': 'Check-in',
  'subscribe.meeting.sign.in.remind':
    'If the check-in is not completed within the specified time, the meeting room will be released again',
  'subscribe.meeting.long.pls': 'Note: the maximum duration of cross-day meeting is {day} days.',
  'subscribe.meeting.sign.time': 'Before / After the meeting',
  'subscribe.meeting.sign.before.time': 'Before the meeting',
  'subscribe.meeting.sign.after.time': 'After the meeting',
  'subscribe.meeting.appointment': 'Save',
  'subscribe.meeting.appointment.inform': 'Reserve and notify',
  'subscribe.meeting.save': 'Save',
  'subscribe.meeting.save.inform': 'Save and notify',
  'subscribe.meeting.prompt': 'Confirmation',
  'subscribe.meeting.create.success': 'Meeting created successfully',
  'subscribe.meeting.edit.success': 'Meeting modified successfully',
  'subscribe.meeting.manage': 'Do you need to continue to manage meetings?',
  'subscribe.startNow': 'Start now',
  'subscribe.startAuto': 'Automatic start',
  'subscribe.reconnect': 'Automatic reconnect',
  'subscribe.longState': 'Cross-day meeting',
  'subscribe.duration': 'Duration',
  'subscribe.duration.new': 'Meeting duration (minutes)',
  'subscribe.startNow.unit': ' mins',
  'subscribe.startNow.placeholder': 'Please enter the meeting duration',
  'subscribe.joinPwd': 'Password',
  'subscribe.joinPwd.placeholder': 'Please set the password',
  'subscribe.joinPwd.warning': 'Password must be number',
  'subscribe.warning.title': 'Confirmation',
  'subscribe.warning.visitor.advance': 'Please invite the visitors at least {value} minutes in advance.',
  'subscribe.warning.content':
    'Switching the conference type will change the selected rooms and time, please confirm to continue or not?',
  'subscribe.meetingType': 'Type',
  'subscribe.meetingType.ok': 'Save',
  'subscribe.meetingType.dismiss': 'Dismiss',
  'subscribe.meetingType.cancel': 'Cancel',
  'subscribe.meetingType.normal': 'General Meeting',
  'subscribe.meetingType.long': 'Cross-day Meeting',
  'subscribe.meetingType.video': 'Video Meeting',
  'subscribe.meetingType.zoom': 'Zoom Meeting',
  'subscribe.meetingType.webex': 'Webex Meeting',
  'subscribe.meetingType.flybook': 'Feishu Meeting',
  'subscribe.meetingType.warning': 'Please select the correct room type',
  'subscribe.people.less':
    'No participants have been added to the meeting room. Please continue the operation?',
  'subscribe.people.more':
    'The number of attendees exceeds the capacity of the conference room. Do you want to continue with the operation?',
  subscribe_ad_options: 'Advanced Options',
  subscribe_room_audit_tip: '(Include meeting rooms for approval)',
  subscribe_apply_project: 'Confirm to apply for these items',

  'webex.routes.choice': 'System automatically selects WebEx circuits',
  'meeting.no.devices': 'No equipment is available now',
  'meeting.history.no.devices': 'No Mobile Device',
  'meeting.save.btn.apply': 'Apply',
  'meeting.save.btn.notice': 'Send notification',

  meeting_save_tips_subject: 'Subject',
  meeting_save_tips_holder: 'Applicant',
  meeting_save_tips_time: 'Time',
  meeting_save_tips_reason: 'Reason',
  meeting_save_tips_lock_time: 'Lock time',
  'meeting.save.tips.modal.loop.create': 'Whether to recreate recurring meetings?',

  'meeting.save.tips.modal.title1':
    'The appointment failed in the following tiem periods. Do you want to skip and continue?',
  'meeting.save.tips.modal.title2':
    'The appointment failed in the following time periods. There is no bookable meeting!',
  meeting_save_tips_reason_0: 'Time period occupied',
  meeting_save_tips_reason_1: 'Conflict with other lock-in time',
  meeting_save_tips_reason_2: 'Meeting lock-in time occupied',
  meeting_save_tips_reason_3: 'The host has aleady reserved meeting for this period.',
  'meeting.save.tips.modal.extend': 'Meeting extension confirmation',
  'meeting.save.tips.modal.title-290':
    'The meeting is postponed due to holidays and room maintenance. Please confirm to continue or not?',
  'meeting.save.tips.modal.title-291':
    'The meeting is postponed due to holidays. Please confirm to continue or not?',
  'meeting.save.tips.modal.title-292':
    'The meeting is postponed due to room maintenance. Please confirm to continue or not?',
  'meeting.save.tips.modal.title-293':
    'The meeting appointment failed due to holidays and room maintenance!',
  'meeting.save.tips.modal.title-294': 'The meeting appointment failed due to holidays!',
  'meeting.save.tips.modal.title-295': 'The meeting appointment failed due to room maintenance!',
  'meeting.save.tips.modal.title-attend':
    'This meeting conflicts with other meeting of the following people, do you want to continue?',

  meeting_tips_holiday: 'Holiday',
  meeting_tips_room_maintain: 'Maintenance',

  'meeting.save.tips.modal.cancel': 'Cancel',
  'meeting.save.tips.modal.continue': 'Continue',
  'meeting.save.tips.modal.meetingprice': 'Meeting Costs',
  'meeting.save.tips.modal.bookit': 'Save',
  'meeting.save.tips.modal.giveup': 'Cancel',
  'meeting.save.tips.modal.gotit': 'Noted',
  'meeting.save.tips.modal.skip': 'Skip and book',
  'meeting.save.tips.modal.ignore': 'Ignore and book',
  'meeting.apply.start.fail.value01': "There is a WebEx meeting room, it can't start right away",
  'meeting.apply.start.fail.value02': "If there is an approval room, you can't start immediately",
  'meeting.apply.start.fail.value03': 'Meeting exists at current time, cannot start immediately',

  room_fee: 'Room costs',
  service_fee: 'Conference services',

  'participants.count': `{num} attendee(s)`,
  'outer.participants': 'External attendee(s)',
  'outer.participants.count': `{num} External(s)`,
  remark: 'Remark',
  welcome: 'Welcome',
  'subscribe.welcome.placeholder': 'Please enter meeting welcome',
  'modal.notice': 'Warning',
  'modal.confirmDelete': 'Confirm to delete?',
  'modal.cancel': 'Cancel',
  'modal.close': 'Close',
  'modal.confirm': 'Confirm',
  'modal.notice.svcApplied':
    'Meeting services are being applied for current meeting, and only the attendee information is allowed to be modified.',

  'subscribe.warning.room': 'Please select the room',
  'zoom.meeting.room.placeholder': 'Optional',
  'meeting.book.outer.visit.time': 'Time of visit',
  'meeting.book.outer.visit.time.modal.start': 'Time of visit start',
  'meeting.book.outer.visit.time.modal.end': 'Time of visit end',
  'meeting.book.outer.visit.type': 'Access type',
  'meeting.book.outer.visit.address': 'Visit location',
  'meeting.booking.vst.type.empty': 'Please select the reason for the visit!',
  'meeting.booking.vst.location.empty': 'Please select the reception location.',

  'meeting.seat.manage': 'Seat arrangement',
  'meeting.seat.bound.number': 'Bound {num} people',
  'subscribe.warning.template': 'No seat template for the selected room',

  'Asia/Shanghai': 'China Time',
  'Asia/Tokyo': 'Japan Time',
  'Asia/Seoul': 'Korea Time',
  'Asia/Singapore': 'Singapore Time',
  'Australia/Sydney': 'Australian Eastern Time (New South Wales)',
  'Europe/London': 'British Time',
  'Europe/Paris': 'Central European Time',
  'Europe/Moscow': 'Moscow Time',
  'America/New_York': 'Eastern Time',
  'America/Chicago': 'Central Time',
  'America/Denver': 'Mountain Time',
  'America/Los_Angeles': 'Pacific Time',
  'America/Anchorage': 'Alaska Time',
  'Pacific/Honolulu': 'Hawaii Time',

  'meeting.booking.step.time.max':
    'The meeting appointment time for this meeting is {value} minutes',
  'meeting.booking.step.time.max.less': 'The maximum reservation time is {value} minutes',
  'meeting.booking.before.day': 'The meeting must be booked {value} days in advance',
  202107: 'The conference room included should be booked in advance',
  'meeting.booking.host_user_no_auth': 'The meeting host has no rights to the meeting room!',
  202129: 'Only one large Tencent conference line is allowed at the same time!',

  'meeting.booking.step.tencent.outer.tips':
    'After opening, external attendees will not be able to join Tencent conference!',
  'subscribe.meetingType.tencent': 'Tencent meeting',
  'subscribe.meetingType.teams': 'Teams meeting',
  'subscribe.meetingType.all': 'Synchronous booking',
  'subscribe.meetingType.poly.all': 'Video Meeting Type',
  'subscribe.meeting.special.type': 'Type',
  'subscribe.meeting_audit_tip':
    '{roomstr} already has a meeting under approval in {timestr}. Do you want to continue?',
  202104: 'Failed to extend the meeting, the current meeting is not an ongoing meeting',
  'meeting.agenda': 'Meeting Agenda',
  'meeting.agenda.look': 'View',
  'agenda.user.name': 'Topic',
  'agenda.user.name.pls': 'Enter the topic',
  'agenda.user.startTime': 'Start time',
  'agenda.user.endTime': 'End time',
  'agenda.user.attend': 'Attendee',
  'agenda.user.select': 'Select attendee(s)',
  'agenda.user.time.tips': 'The agenda start time cannot be later than the end time!',
  'meeting.booking.agenda.tip':
    'Meeting agenda time is out of the range of meeting time. Please modity it.',
  'agenda.user.selectall': 'Select all',
  'meeting.booking.agenda.tip2':
    'There are non conference attendees in the agenda, please check!！',
  'meeting.agenda.starttime.tip': 'Agenda start time cannot be empty!',
  'meeting.agenda.endtime.tip': 'Agenda end time cannot be empty!',
  'meeting.agenda.name.tip': 'topic cannot be empty!',
  'meeting.agenda.repeat.tip': 'Agenda time cannot overlap!',
  'meeting.agenda.sameTime.tip':
    "An existing topic's ending time is the same as the meeting's. Please change the time of the topic first and then add new topics.",
  'meeting.new.no.select.service': 'Select meeting service',
  'meeting.new.no.select.device': 'Select meeting device',
  'meeting.new.outer.loop.tip':
    'Recurring meeting does not support vsitor invitation, do you want to continue?',
  'meeting.manage.service.self.bear': 'self undertake',
  'meeting.booking.before.day.room': 'Reservation required {value} days in advance',
  'meeting.booking.before.day.room.new': 'needs to be booked {value} days in advance,please select another date!',
  'meeting.booking.max.day.room': 'Only meetings in {value} days can be booked, please select another date!',
  subscribe_room_audit_tip_room: 'To make an appointment in this meeting room, approval is required',
  'subscribe.meeting.sign.in.setting': 'Sign-in setting',
  'subscribe.meeting.sign.in.duration':
    'It needs to be less than the meeting duration ({value} minutes)',
  'subscribe.meeting.sign.time.before': '{value} minutes before and',
  'subscribe.meeting.sign.time.after': ' {value} minutes after the meeting start time ',
  'subscribe.meeting.user.placeholder.host': 'Choose a host',
  'subscribe.meeting.user.placeholder.inner': 'Select attendees',
  'subscribe.meeting.user.placeholder.outer': 'Select external attendees',

  'subscribe.meeting.modifiy1':
    'After the conference room is modified, the {modifyDetail} applied for by the original conference room will be cancelled, and the system will automatically reapplied according to the new conference room and the original service items! ',
  'subscribe.meeting.modifiy1.dev.ser':
    'After the conference room is modified, the conference equipment and conference services applied by the original conference room will automatically transferred to the new conference room for you',
  'subscribe.meeting.modifiy1.dev':
    'After the conference room is modified, the conference equipment applied by the original conference room will automatically transferred to the new conference room for you',
  'subscribe.meeting.modifiy2':
    'This meeting has applied for {modifyDetail}. Do you need to modify {modifyDetail} after modifying {modifyStr}?',
  'subscribe.meeting.modifiy3':
    'This meeting has applied for {modifyDetail}. After modifying the conference room, the original {modifyDetail} will be cancelled. Do you want to continue?',
  'subscribe.meeting.modifyAuditType1':
    'This meeting contains meeting rooms that need to be approved. After modifying the meeting time, it needs to be re approved. Please confirm whether to modify it?',
  'subscribe.meeting.modifyAuditType2':
    'The modified conference room contains the conference room that needs approval. The modified conference needs re approval. Please confirm whether to modify it?  ',
  'subscribe.meeting.modifyAuditType3':
    'This meeting contains meeting rooms that need to be approved. After modifying the cycle rule, it needs to be re approved. Please confirm whether to modify it?',
  'subscribe.cross_days_meeting_days_limit':
    'The start date and end date of a cross-day conference cannot be on the same day',
  'subscribe.meeting.startNow.sign.title': 'Appointment succeeded',
  'meeting.booking.step.sign.new.text':
    'This meeting is a sign in meeting, please sign in within {time} minutes!',
  'meeting.booking.step.times.noLimit.tip':
    'Meeting duration should be from {limitMinTime} minutes to {limitMaxTime} minutes.',
  'meeting.booking.step.times.cross.noLimit.tip':
    'Meetings with the maximum date range of {data} days can be booked',
  'meeting.booking.step.times.cross.noCross.tip':
    'Meetings in {data} days can be booked',
  'meeting.booking.step.times.limit.tip':
    'Meetings with the duraion of {limitMinTime}~{limitMaxTime} minutes in {limitMaxDay} days can be booked',
  'meeting.booking.step.times.limit.tip2':
    ', meetings after {limitMaxDay} days need approval.',
  'meeting.booking.step.times.limit.cylce':
    ', meetings after {limitMaxDay} days need approval.',
  'meeting.booking.normal_day_meeting_limit':
    'Only meetings with a duration of {limitMinTime} - {limitMaxTime} minutes can be reserved',
  'meeting.booking.normal_day_meeting_limit_date':
    'The current date is unavailable, please select another date to make an appointment!',
  'meeting.visit.time.year': 'year',
  'meeting.visit.time.month': 'month',
  'meeting.visit.time.day': 'day',
  'meeting.visit.time.hour': 'hour',
  'meeting.visit.time.minute': 'minute',
  'meeting.visit.time.second': 'second',
  'meeting.vst.time.validate':
    'The start time of the meeting should not be earlier than the visiting time',
  'meeting.booking.step.times.cross.limit.tip':
    'Meetings with the maximum date range of {value} days in {data} days can be booked.',
  'meeting.attend.user.status.FREE': 'Free',
  'meeting.attend.user.status.BUSY': 'Busy',
  'meeting.attend.user.status.TENTATIVE': 'Tentative',
  'meeting.attend.user.status.UNKNOW': 'Unknown',
  'meeting.booking.step.meetingType.zoom': 'Zoom',
  'meeting.booking.step.meetingType.teams': 'Teams',
  'meeting.booking.step.meetingType.dingding': 'DingDing',
  'meeting.booking.step.meetingType.feishu': 'Feishu',
  'meeting.booking.step.meetingType.tencent': 'Tencent',
  'meeting.booking.step.meetingType.changyun': 'ChangYun',
  'meeting.booking.step.meetingType.quanshi': 'Full time',
  'meeting.booking.step.meetingType.hasPoly': 'Local',
  'meeting.booking.not.joinPwd.length.less': 'The length of the participating password must be at least 5!',
  'meeting.booking.not.joinPwd.length.less6': 'The password must be at least 6 characters long!',
  'meeting.booking.vst.time.cross.validate': 'The start and end dates of a cross-day meeting visitor visit cannot be the same day',
  'meeting.booking.step.visitorCount.limit': 'The maximum number of visitors to the current location is {data} people',
  'meeting.booking.fail.visit.invite.more': 'The current reception location {timeFormat} can only invite {visitorCount} visitors',
  'meeting.booking.fail.visit.invite.enough':
    'The current reception location {timeFormat} has already invited the maximum number of visitors and cannot continue inviting visitors',
  'meeting.booking.fail.smc.min': 'Huawei video Start Immediately meeting cannot be shorter than {duration} minutes',
  'meeting.booking.fail.smc.min.normal': 'Huawei video meeting cannot be shorter than {duration} minutes',
  
};
