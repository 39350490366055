export default {
  'service.err.200': '服務器成功返回請求的數據。',
  'service.err.201': '新建或修改數據成功。',
  'service.err.202': '一個請求已經進入後台排隊（異步任務）。',
  'service.err.204': '刪除數據成功。',
  // 'service.err.400': '發出的請求有錯誤，服務器沒有進行新建或修改數據的操作。',
  // 'service.err.401': '由於您長時間沒有操作或在其它地方登錄後退出，用戶會話已失效，請重新登錄',
  // 'service.err.403': '用戶得到授權，但是訪問是被禁止的。',
  // 'service.err.404': '發出的請求針對的是不存在的記錄，服務器沒有進行操作。',
  // 'service.err.406': '請求的格式不可得。',
  // 'service.err.410': '請求的資源被永久刪除，且不會再得到的。',
  // 'service.err.422': '當創建一個對象時，發生一個驗證錯誤。',
  // 'service.err.426': 'app.login.message-invalid-credential',
  'service.err.400': '錯誤碼400，請聯繫管理員解決問題。 ',
  'service.err.401': '登錄信息已過期或權限發生變化，請重新登錄。 ',
  'service.err.403': '錯誤碼403，請聯繫管理員解決問題。 ',
  'service.err.404': '錯誤碼404，請聯繫管理員解決問題。 ',
  'service.err.406': '錯誤碼406，請聯繫管理員解決問題。 ',
  'service.err.410': '錯誤碼410，請聯繫管理員解決問題。 ',
  'service.err.422': '錯誤碼422，請聯繫管理員解決問題。 ',
  'service.err.426': '錯誤碼426，請聯繫管理員解決問題。 ',

  'service.err.500': '服務器發生錯誤，請檢查服務器。',
  'service.err.502': '網關錯誤。',
  'service.err.503': '服務不可用，服務器暫時過載或維護。',
  'service.err.504': '網關超時。',
  100000: '系統異常',
  101000: '非法操作',
  101001: '參數不正確',
  101002: '退出失敗，token為空',
  101003: '退出失敗，token無效',
  101004: '操作失敗，token為空',
  101005: '操作失敗，token格式無效',
  101006: '操作失敗，token無效',
  101007: '操作失敗，refreshToken為空',
  101008: '操作失敗，refreshToken無效',
  101009: '密碼解密失敗',
  101010: '賬號格式不正確',
  101011: '通過refreshToken獲取域信息失敗',
  101012: '不支持認證模式',
  101013: '賬號或密碼錯誤，請重新輸入',
  101014: '請求頭中client信息為空',
  101015: '查詢賬號失敗',
  101020: '上傳license文件失敗',
  101021: '上傳license文件異常',
  101022: 'license與租戶域不匹配',
  101023: 'license設備碼校驗失敗',
  101024: 'license有效期校驗失敗',
  101025: '許可證不存在',
  101026: 'license功能未開啟',
  101027: 'license文件名不正確',
  101028: '用戶數量已超出限制',
  101029: '租戶數量超出限制',
  101030: '驗證碼不能為空',
  101031: '驗證碼不合法',
  1010312: '驗證碼不正確',
  101032: '驗證碼不存在',
  101040: '簽名或時間戳參數不存在',
  101041: '時間戳參數格式不正確',
  101042: '請求已過期',
  101043: '簽名無效',
  101044: '當前用戶無角色，請聯繫管理員',
  101045: '系統默認域不存在',
  101046: '手機號認證未開啟',
  101047: '郵箱認證未開啟',
  102000: '非法操作',
  102001: '參數不正確',
  102002: '添加部門對應租戶不存在',
  102003: '當前部門下存在子部門，請先轉移',
  102004: '保存部門失敗',
  102005: '菜單含有下級不能刪除',
  102006: '當前角色下存在用戶，請先轉移用戶',
  102007: '只有平台管理員才能修改通用角色',
  102008: '租戶不存在',
  102009: '不能刪除自己',
  102010: '添加用戶對應租戶不存在',
  102011: '郵箱已存在',
  102012: '新密碼與確認密碼不一致',
  102013: '信息不存在',
  102014: '原密碼錯誤',
  102015: '部門不存在',
  102016: '此微信已綁定過',
  102017: '此釘釘已綁定過',
  102018: '不支持除微信、釘釘以外其它綁定方式',
  102019: '此用戶未綁定微信賬號',
  102020: '此用戶未綁定釘釘賬號',
  102021: '威思客token無效',
  102022: '免密認證，不支持當前類型',
  102023: '免密認證，查詢到多個用戶',
  102024: '免密認證，未查詢到用戶',
  102025: '手機號已存在',
  102026: '用戶不存在',
  102027: '平台管理員角色不允許刪除',
  102030: '此角色標識已存在',
  102031: '此角色名稱已存在',
  102032: '角色不存在',
  102033: '當前部門下存在用戶，請先轉移',
  102040: '此租戶域已存在',
  102050: '空間層級超出限制',
  102051: '當前空間下存在子空間請先轉移',
  102052: '保存空間失敗',
  102053: '此空間沒有綁定節假日',
  102054: '此空間不存在',
  102055: '部門管理員未設置',
  102056: '部門管理員不存在',
  102057: '當前租戶已停用',
  102058: '系統異常',
  102059: '同一個部門下部門名稱不能重複',
  102060: '此賬號已存在',
  102061: '租戶名稱不能為空',
  102062: '租戶名稱長度超過50',
  102063: '租戶英文名長度超過50',
  102064: '租戶域不能為空',
  102065: '租戶域長度超過50',
  102066: '租戶狀態不能為空',
  102067: '租戶狀態參數無效',
  102068: '部門名稱不能為空',
  102069: '部門名稱長度超過50',
  102070: '父部門id不能為空',
  102071: '父部門id無效',
  102072: '賬號不能為空',
  102073: '賬號長度超過50',
  102074: '手機號不能為空',
  102075: '部門不能為空',
  102076: '姓名不能為空',
  102077: '性別不能為空',
  102078: '郵箱不能為空',
  102079: '角色不能為空',
  102080: '菜單id不能為空',
  102081: '菜單id無效',
  102082: '菜單名稱不能為空',
  102083: '父菜單id不能為空',
  102084: '菜單類型不能為空',
  102085: '角色名稱不能為空',
  102086: '角色code不能為空',
  102087: '租戶id不能為空',
  102088: '租戶id參數無效',
  102089: '屬性id不能為空',
  102090: '屬性值不能為空',
  102091: '租戶初始化保存租戶失敗',
  102092: '租戶初始化保存部門失敗',
  102093: '租戶初始化保存部門關係失敗',
  102094: '租戶初始化保存用戶失敗',
  102095: '租戶初始化保存用戶角色關係失敗',
  102096: '用戶信息保存異常',
  102097: '用戶信息更新異常',
  102098: '用戶角色關係保存異常',
  102099: '批量保存用戶異常',
  102100: '批量保存用戶角色異常',
  102101: '屬性code不存在',
  102102: '同一空間下空間名稱不能重複',
  102103: '節假日不存在',
  102104: '菜單權限標識已存在',
  102105: '父菜單的權限標識為空，才能添加子菜單',
  102106: '郵箱賬號不存在',
  102107: '鏈接已過期',
  102108: '設置密碼失敗',
  102109: '驗證碼無效',
  102110: '新密碼和確認密碼不一致',
  102111: '查詢失敗',
  102112: '查詢用戶失敗',
  102113: '查詢租戶失敗',
  102114: '可分配資源的最小空間',
  102115: '查詢雲服務菜單失敗',
  102116: '密碼包含弱密碼，不符合強度要求',
  102117: '節點已禁用，無法編輯',
  102118: '系統中至少需要存在一位管理員用戶',
  102119: '菜單ID已存在',
  102120: '父節點已禁用，無法添加子節點',
  102121: '該節假日已綁定空間樹，無法刪除',
  102122: '不能刪除系統管理員',
  102123: 'ID列表不能為空',
  102124: '新部門ID不能為空',
  102125: '新密碼不能與原密碼相同',
  101048: '賬號多次認證失敗，鎖定{lockTime}分鐘',
  102126: '只能刪除本地添加的用戶',
  102127: '只能刪除本地添加的部門',
  102128: '租戶初始密碼類型不能為空',
  102130: ' 路由名稱不能為空',
  102131: ' 路由ID不能為空',
  102132: ' 斷言不能為空',
  102133: ' 過濾器不能為空',
  102134: ' URL不能為空',
  102135: ' 斷言需為json格式',
  102136: ' 過濾器需為json格式',
  102137: ' 路由id已存在',
  102138: ' 斷言格式錯誤',
  102139: ' 過濾器格式錯誤',
  102140: '手機區號已存在',
  102141: '手機區號名稱已存在',
  // 門禁
  601001: '獲取門禁列表失敗',
  601002: '門禁名稱不能為空',
  601003: '該門禁已綁定分組，刪除失敗',
  601004: '刪除門禁失敗',
  601005: '請先配置門禁',
  601006: '模擬調用失敗',
  601007: '獲取門禁分組列表失敗',
  601008: '門禁分組名稱不能為空',
  601009: '分組編號已存在',
  601010: '刪除門禁分組失敗',
  601011: '簽名認證失敗',
  601012: '操作失敗',
  601013: '操作成功',
  601014: '控制器序列號重複',
  601015: '控制器及邏輯門號不能重複綁定',
  601016: 'IDCardNo重複配置',
  601017: '該門禁產品不支持遠程開門',
  101049: '結束時間早於開始時間',
  101050: '結束時間早於當前時間',
  101051: '未到開始時間',
  101052: '賬號禁用',
  101053: '賬號密碼未激活',
  603001: '二維碼不存在，請檢查',
  603002: '該用戶二維碼使用次數已到最大',
  603003: '該用戶二維碼總有效期已到期，已清空該用戶二維碼記錄',
  200005: '會議產品已過期',

  102164: '手機號重複，驗證碼發送失敗，請聯繫管理員處理',
  102165: '手機號不存在',
  102166: '驗證碼錯誤或過期',
  102167: '驗證碼已發送，請勿頻繁操作',
  102175: '用戶已經綁定',
  102176: 'Teams租戶不一致，請重新輸入',
  102177: '用戶綁定失敗，請重新綁定或使用其他賬號',

  1001023: '該郵箱已使用，請更換郵箱',
  1001027: '驗證碼無效',
  1001028: '驗證碼錯誤',
  1001031: '參數錯誤',
  1001074: '租戶名已存在',
  1001083: '手機號已經被註冊',
  1001084: '用戶不存在',
  1001089: '郵箱不能為空',
  1001091: '租戶不存在',
  1001092: '註冊信息不存在，請重新授權',
  1001093: '免密授權失敗',
  1001094: '公司已註冊',

  1081008: '套餐不存在',
  1081009: '套餐已過期',

  106110: '用戶綁定失敗',
  106000: '系統異常',
  106520: '手機號在其它租戶已綁定',
  106521: '郵箱在其它租戶已綁定',
  106522: '飛書綁定的租戶不一致',
  106523: '獲取飛書企業信息失敗',
  106524: '第三方用戶檢查失敗',
  106525: '獲取飛書用戶信息失敗',
  106526: '免密認證失敗',
  106527: '獲取飛書應用配置失敗',
  106528: '飛書註冊信息無效',

  106507: '請求企業微信接口超時，請登錄或稍後重試',
  401150: '預約時長不在設置範圍內',

  401151: '預訂最大時長應不超過{value1}分鐘',
  401152: '預訂最小時長應為{value1}分鐘',
  401002: '工位信息不存在',
  401092: '工位未綁定地圖',
  401093: '工位已鎖定',
  401095: '工位已鎖定',
  102151: '新密碼與歷史密碼重複',
  401333: '用戶部門信息不存在',
  401097: '工位不是固定工位一人使用類型',
  401098: '工位{value1}未分配歸屬部門',

  401154: '您不支持跨天預訂工位',
  401158: '不支持連續工作日使用相同工位，請選擇新的工位',

  101054: '首次登錄，請在PC端修改密碼',
  101055: '密碼過期，請修改密碼',
  1001025: '許可證已過期',
  1001026: '許可數已達到上限',
  1001147: '已超過許可證上限，請重新開通或更新許可證',

  'FFA.101083': '請先通過PC端登錄綁定認證器應用',
  102150: '密碼不能包含用戶名，電話，郵箱箱及郵箱前綴',

  /* 敏感词 */
  401507: '報修備註中出現不符合企業規範詞彙，請重新審視並修改。',
  401508: '申請工位備註中出現不符合企業規範詞彙，請重新審視並修改。',
  401509: '借用工位中出現不符合企業規範詞彙，請重新審視並修改。',
  401510: '踢座申請中出現不符合企業規範詞彙，請重新審視並修改。',
  401511: '踢座回復中出現不符合企業規範詞彙，請重新審視並修改。',
};
