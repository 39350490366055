/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.faultRepair.title': 'Accesses',
  'access.faultRepair.reason': 'Please enter the reason for repair',
  'access.faultRepair.my.recode': 'Report record',
  'access.faultRepair.commit': 'Submit',
  'menu.sas.app.faultRepair': 'Malfunction Report',
};
