export default {
  common_cancel: '取消',
  common_confirm: '確定',
  'meeting.sub.tip': '提示',
  'meeting.sub.content': '您已被拉入系統黑名單，暫時無法預訂會議室。',
  'meeting.sub.content.flybook': '飛書會議室不可預訂會議',
  'meeting.sub.ok': '確定',
  'meeting.evaluate.title': '使用評價',
  'meeting.evaluate.redio': '單選',
  'meeting.evaluate.choice': '多選',
  'meeting.evaluate.submit.sure': '確定要提交？ ',
  'meeting.evaluate.ok': '確定',
  'meeting.evaluate.cacle': '取消',
  'meeting.evaluate.select.tip': '請選擇此項',
  'meeting.evaluate.text.tip': '請填寫此項',
  'meeting.evaluate.submit': '提交',
  'meeting.evaluate.text.place': '請輸入內容',
  'meeting.evaluate.commit.tip': '感謝您的評價',
  'meeting.evaluate.commit.tip.1': '提交成功！',
  'meeting.evaluate.overdue': '問卷已過期或不可用！',
  'meeting.attend.root': '聯繫人',
  'meeting.attend.name.number': '議題{value}',
  'meeting.xrooms.feishu': '飛書 Rooms',
  'meeting.xrooms.tengxun': '騰訊 Rooms',
  'meeting.xrooms.teams': 'Teams Rooms'
};
