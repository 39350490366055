export default {
  map_room_detail_basic_title: 'Basic information',
  map_room_detail_book_title: 'My Bookings',
  map_room_detail_book_btn: 'Booking',
  map_room_detail_go_btn: 'Go here',
  map_room_detail_view_btn: 'View location',
  map_room_detail_more_btn: 'More >',
  'meeting.calendar.need_audit_map': 'Approve',
  map_index_meeting_tab_meeting_list_title: 'Reservation record',
  'map_indexbooking.meeting.romm.Authority':
    'You are not authorized to use this meeting room, please choose a new meeting room!',
};
