import {
  getSpaceTreeAndMapId,
  getEleIdsByMapId,
  getCalendarRoom,
} from '@/pages/Meeting/services/meetingRoute';

export default {
  nameSpace: 'meetingRoute',
  state: {
    showPath: false,
    reloadMap: false,
    showRouteModal: false,
    roomId: '',
    mapElements: [],
    roomName: '',
    searchInfo: {},
    calendarRoom: [],
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *initPage(_, { select, call, put }) {
      yield put({ type: 'saveOrUpdateData', payload: { calendarRoom: [] } });
      const { searchInfo } = yield select((state) => state.meetingRoute);
      const { timeZone } = yield select((state) => state.user.currentUser);
      searchInfo.total = 0;
      searchInfo.size = 9999;
      searchInfo.current = 1;
      const result = yield call(getCalendarRoom, { ...searchInfo, ...{ timeZone } });
      if (result.code === 0) {
        const d = result.data;
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            calendarRoom: d.records,
          },
        });
      }
      return {};
    },
    *getSpaceTreeAndMapId({ roomId }, { call }) {
      const result = yield call(getSpaceTreeAndMapId, roomId);
      return result?.data || {};
    },
    *getEleIdsByMapId({ mapId }, { call, put }) {
      const result = yield call(getEleIdsByMapId, mapId);
      if (result && result.code === 0) {
        const { data } = result;
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            reloadMap: true,
            mapElements: data,
          },
        });
        return data;
      }
      return null;
    },
  },
  subscriptions: {},
};
