/**
 * Created by wenbei on 2021/09/02.
 */

const config = (jsSDKInfo, apiList) =>
  window.h5sdk.config({
    appId: sessionStorage.getItem('FEISHU_SCAN_APPID'), // 必填，应用ID
    timestamp: jsSDKInfo.timestamp, // 必填，生成签名的时间戳，毫秒级
    nonceStr: jsSDKInfo.nonceStr, // 必填，生成签名的随机串
    signature: jsSDKInfo.signature, // 必填，签名
    jsApiList: apiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2,
    onSuccess(result) {
      // 成功回调，可以在成功之后使用 tt.xx jsapi
      alert(`result=${result}`);
    },
    onFail() {
      // 失败回调
      window.h5sdk.error((error) => alert(error));
    },
  });

/**
 * 飞书调用摄像头扫描二维码
 */
const captureQR = () =>
  new Promise((resolve, reject) => {
    window.h5sdk.ready(function () {
      window.tt.scanCode({
        barCodeInput: true,
        success: (res) => resolve(res.resultStr),
        fail: (res) => {
          alert('Open QR Scan Fail');
          alert(res.errMsg);
          reject(res);
        },
      });
    });
  });

/**
 * 获得初始化Wechat环境，获得用户信息
 * @param jsSDKInfo
 * @param apiList
 * @returns {Promise.<void>}
 */
export const init = async (jsSDKInfo, apiList) => {
  await config(jsSDKInfo, apiList).catch((err) => window.console.log(err));
};

/**
 * 调用飞书二维码扫描
 * @returns 扫描结果
 */
export const qrScan = async (scanType) => {
  return captureQR();
};
