import request from '../utils/request';

const publicPath = '/';

export const savePanel = async ({ panel }) => {
  return request('/v1/api/panelforms', { method: 'POST', body: JSON.stringify(panel) });
};
export const getPanelList = async () => {
  return request('/v1/api/allPanelTemplate');
};
export const getPanel = async ({ panelId, query = {} }) => {
  if (query.area && query.json) {
    return request(`${publicPath}json/${query.area}-${panelId}.json`);
  }
  return request(`/v1/api/panelforms/${panelId}`);
};
export const deletePanel = async ({ panelId }) => {
  return request(`/v1/api/panelforms/${panelId}`, { method: 'DELETE' });
};
