/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  '106534': '操作失敗',
  '106535': '程序內部異常！',
  'scanQRCode.scan.type.0': '微信掃一掃(Type=0)',
  'scanQRCode.scan.type.1': '微信掃一掃(Type=1)',
  'scanQRCode.scan.type.dingTalk': '釘釘掃一掃',
  'scanQRCode.scan.type.virsical': '威思客掃一掃',
  'scanQRCode.scan.type.feishu': '飛書掃一掃',
};
