export default {
  selfService_name: 'Name',
  selfService_name_tip: 'Please enter your name',
  selfService_company: 'Company',
  selfService_company_tip: 'Please enter the company',
  selfService_phone: 'Mobile',
  selfService_phone_tip: 'Please enter your mobile',
  selfService_mail: 'Email',
  selfService_mail_tip: 'Please input email',
  selfService_host: 'Receptionist',
  selfService_host_tip: 'Please input receptionist',
  selfService_select: 'Select',
  selfService_success_tip1: 'The reservation is successful',
  selfService_success_tip2:
    'Please wait for confirmation,You will be informed later in the form of SMS',
  selfService_audit: 'Audit',
  selfService_pass: 'Approved',
  selfService_all: 'All',
  selfService_new: 'New Booking',
  selfService_local: 'Address',
  selfService_time: 'Time',
  selfService_pull: 'Pull Down',
  selfService_deliver: 'Release',
  selfService_loading: 'Loading...',
  selfService_loaded: 'Loaded',
  self_header_welcome: 'Welcome to',
  self_search_tip: 'Try typing in more companies',
  self_no_search_tip: 'The enterprise has not yet entered the visitor management system',
  self_add_booking_tip: 'Click the button below, add a new reservation',
  selfService_select_end_time: 'Please select the leaving time',
};
