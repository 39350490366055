export default {
  'app.settings.menuMap.basic': 'Basic Settings',
  'app.settings.menuMap.security': 'Security Settings',
  'app.settings.menuMap.binding': 'Account Binding',
  'app.settings.menuMap.notification': 'New Message Notification',
  'app.settings.basic.avatar': 'Avatar',
  'app.settings.basic.change-avatar': 'Change avatar',
  'app.settings.basic.email': 'Email',
  'app.settings.basic.email-message': 'Please enter the email!',
  'app.settings.basic.nickname': 'Nickname',
  'app.settings.basic.nickname-message': 'Please input your Nickname!',
  'app.settings.basic.profile': 'Personal profile',
  'app.settings.basic.profile-message': 'Please input your personal profile!',
  'app.settings.basic.profile-placeholder': 'Brief introduction to yourself',
  'app.settings.basic.country': 'Country/Region',
  'app.settings.basic.country-message': 'Please input your country!',
  'app.settings.basic.geographic': 'Province or city',
  'app.settings.basic.geographic-message': 'Please input your geographic info!',
  'app.settings.basic.address': 'Street Address',
  'app.settings.basic.address-message': 'Please input your address!',
  'app.settings.basic.phone': 'Phone Number',
  'app.settings.basic.phone-message': 'Please input your phone!',
  'app.settings.basic.update': 'Update Information',
  'app.settings.security.strong': 'Strong',
  'app.settings.security.medium': 'Medium',
  'app.settings.security.weak': 'Weak',
  'app.settings.security.password': 'Account Password',
  'app.settings.security.password-description': 'Current password strength：',
  'app.settings.security.phone': 'Security Phone',
  'app.settings.security.phone-description': 'Bound phone：',
  'app.settings.security.question': 'Security Question',
  'app.settings.security.question-description':
    'The security question is not set, and the security policy can effectively protect the account security',
  'app.settings.security.email': 'Backup Email',
  'app.settings.security.email-description': 'Bound Email：',
  'app.settings.security.mfa': 'MFA Device',
  'app.settings.security.mfa-description':
    'Unbound MFA device, after binding, can be confirmed twice',
  'app.settings.security.modify': 'Modify',
  'app.settings.security.set': 'Set',
  'app.settings.security.bind': 'Bind',
  'app.settings.binding.taobao': 'Binding Taobao',
  'app.settings.binding.taobao-description': 'Currently unbound Taobao account',
  'app.settings.binding.alipay': 'Binding Alipay',
  'app.settings.binding.alipay-description': 'Currently unbound Alipay account',
  'app.settings.binding.dingding': 'Binding DingTalk',
  'app.settings.binding.dingding-description': 'Currently unbound DingTalk account',
  'app.settings.binding.bind': 'Bind',
  'app.settings.notification.password': 'Account Password',
  'app.settings.notification.password-description':
    'Messages from other users will be notified in the form of a station letter',
  'app.settings.notification.messages': 'System Messages',
  'app.settings.notification.messages-description':
    'System messages will be notified in the form of a station letter',
  'app.settings.notification.todo': 'To-do Notification',
  'app.settings.notification.todo-description':
    'The to-do list will be notified in the form of a letter from the station',
  'app.settings.open': 'Open',
  'app.settings.close': 'Close',
  'menu.vst.app.manage': 'Smart Visitor',
  'menu.vst.app.reserve': 'Invitations',
  'menu.vst.app.visit': 'Visits',
  'menu.vst.app.confirm': 'Review  ',
  'menu.vst.app.statisticalAnalysis': 'Statistic',
  app_invitation_img: 'Upload',
  'app_invitation_img.info': 'Upload photos for face recognition to open or sign in',
  'app_invitation_img.back': 'Return',
  'app_invitation_img.cancel': 'Cancel',
  'app_invitation_img.confirm': 'Confirm',
  'app.upload.tip': 'Photo size cannot exceed 10M',
  'app.upload.loading': 'Uploading',
  'app_invitation_health.title': 'Visitor Health Survey',
  'app_invitation_health.tip': 'I declare current and ongoing access {companyName} previously meets all the requirements listed above and provides true and valid information',
  'app_invitation_health.yes': 'Meets all the above requirements',
  'app_invitation_health.no': 'One or more inconsistent',
  'app_invitation_health.modal.title': 'Are you sure to submit the questionnaire?',
  'app_invitation_health.choose.gain': 'To choose',
  'app_invitation_health.choose.ok': 'Sure',
  'app_invitation_health.not.available': 'Not currently available',
};
