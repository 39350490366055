export default {
  'report.byDays': '按天',
  'report.byMouths': '按月',
  'report.7Days': '7天',
  'report.30Days': '30天',
  'report.60Days': '60天',
  'report.6Mouths': '6个月',
  'report.12Mouths': '12个月',
  'report.24Mouths': '24个月',
  'report.chart': '图表',
  'report.start_': '开始时间：',
  'report.start': '开始时间',
  'report.end_': '结束时间：',
  'report.end': '结束时间',
  'report.reservation.rightDate': '请输入正确的查询日期',
  'report.reservation.signedIn': '到访预约',
  'report.reservation.notIn': '未到访预约',
  'report.reservation.total': '总计',
  'report.reservation.date': '日期',
  'report.reservation.amount': '类型数量',
};
