/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  106101: 'The operation was successful',
  106102: 'The operation failed',
  106103: 'This user is not yet bound, please log in to bind! ',
  106104: 'Failed to get personal WeChat account ID! ',
  106105: 'Failed to obtain the personal WeChat account ID: The public account system configuration is incorrect! ',
  106106: 'Failed to get personal WeChat account ID: No account found! ',
  106107: 'Failed to get personal WeChat account ID: code cannot be empty! ',
  106108: 'Failed to get user binding status! ',
  106109: 'Successful account binding! ',
  106110: 'Failed to bind the account! ',
  106111: 'Wrong username or password! ',
  106112: 'This account is already bound and cannot be rebind! ',
  106113: 'Failed to send message! ',
  106114: 'Failed to get user information! ',
  106115: 'Code authentication failed',
  106116: 'The public account system configuration is incorrect',
  106117: 'No corresponding applet found',
  106118: 'The login server did not respond',
  106119: 'Failed to get account binding interface URL',
  106120: 'Access token is invalid or expired, please log in again',
  106121: 'Failed to obtain domain information',
  106222: 'Duplicate name, please re-enter',
  106223: 'Deletion failed, cannot delete the type of the bound application',
  106224: 'Please select an application type',
  106225: 'No corresponding application type',
  106226: 'Microservice authentication failed',
  106227: 'Failed to set a trusted domain name for the enterprise WeChat platform',
  106228: 'Internal program exception! ',

  106000: 'System abnormality!',
  106504: 'Authentication failed!',
  106505: 'Wrong user name or password!',
};
