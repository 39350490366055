/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.faceLibrary.title': '人臉庫',
  'access.faceLibrary.font': '正面',
  'access.faceLibrary.side': '側面',
  'access.faceLibrary.left': '左面',
  'access.faceLibrary.right': '右面',
  'access.faceLibrary.above': '上面',
  'access.faceLibrary.btn.submit': '提 交',
  'access.faceLibrary.btn.reUpload': '重新上傳',
  'access.faceLibrary.btn.finish': '完成',
  'access.faceLibrary.state.pass': '已通過',
  'access.faceLibrary.state.audit': '審核中',
  'access.faceLibrary.state.NotPass': '未通過',
  'access.faceLibrary.state': '審核狀態',

  straight: '正面',
  side: '側面',
  left: '左面',
  right: '右面',
  up: '上面',
  '10001': '找不到人臉',
  '10002': '多張人臉',
  '10003': '人臉太小',
  '10004': '照片模糊',
  'access.faceLibrary.tip': '請保持五官清晰正臉拍攝，只有一個人臉,',
  'access.faceLibrary.tip.1': '且人臉占照片面積不小於30%',
  'access.faceLibrary.tip1': '1.請選擇五官清晰的正臉照片',
  'access.faceLibrary.tip2': '2.請保證照片中只有一個人臉',
  'access.faceLibrary.tip3': '3.請保證人臉佔照片的面積不小於30%',
  // 'access.faceLibrary.btnText': '選擇照片',
  'access.faceLibrary.uploadText': '正在上傳',
  'access.faceLibrary.errorText': '上傳大小不能超過10M',
  'access.faceLibrary.btnBackText': '返回',
  'access.faceLibrary.btnConfirmText': '確認',
  'access.faceLibrary.identify': '正在識別圖片',
  'access.faceLibrary.uploadText.tip': '正在加載',

  'access.faceLibrary.state.sync.0': '待審核',
  'access.faceLibrary.state.sync.1': '審核拒絕',
  'access.faceLibrary.state.sync.2': '同步中',
  'access.faceLibrary.state.sync.3': '重新拍照',
  'access.faceLibrary.state.sync.4': '重新拍照',
  'access.faceLibrary.nda.agree':"確定",
  // 'access.success.msg': '上傳成功',
  'access.msg.pending': '照片審核中',


  'access.success.msg': '已完成註冊',
  'access.success.del': '删除照片',
  'access.success.msg.tips': '不留存照片已開啟，無法顯示照片',
  'access.faceLibrary.btnText': '點擊拍照',
  'access.faceLibrary.del': '刪除',
};
