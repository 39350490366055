export default {
  'sws.menu.station.facilityManage': '设施管理',
  'sws.facility.manage': '此二维码不是工位二维码，也不是设施二维码',
  // hanlei
  'sws.facility.sure.release': '确定解除设施绑定?',
  'sws.facility.sure.no': '放弃操作',
  'sws.facility.sure.yes': '解除绑定',
  'sws.facility.unbind.facility': '解除设施绑定',
  'sws.facility.sure.releaseAll': '确定解除所有设施绑定?',
  'sws.facility.sure.noAll': '放弃操作',
  'sws.facility.sure.yesAll': '解除绑定',
  'sws.facility.enter.num': '输入工位编号或设施编号',
  'sws.facility.unbind.facilityAll': '解除所有设施绑定',

  'sws.facility.prompt.num': '请输入工位编号或设施编号',
  'sws.facility.location.information': '工位信息',

  'sws.facility.sure.breleaseAll': '确定解除所有设施绑定?',
  'sws.facility.sure.bnoAll': '放弃操作',
  'sws.facility.sure.byesAll': '解除绑定',
  'sws.facility.unbind.bfacilityAll': '全部解绑',

  'sws.facility.station.belongs': '工位所属设施',
  'sws.facility.facil.num': '设施编号：',
  'sws.facility.facil.type': '设施类型：',
  'sws.facility.facil.serial': '序列号：',
  'sws.facility.scan.binding': '继续扫描设施编码绑定工位信息～',
  'sws.facility.scan.first': '请先扫描工位或设施编码～',

  'sws.facility.binding.scan': '扫码工位绑定以下设施信息',
  'sws.facility.sure.bNo': '放弃操作',
  'sws.facility.sweep.location': '扫码工位',

  'sws.facility.facil.anum': '设施编号：',
  'sws.facility.facil.aname': '设施名称：',
  'sws.facility.facil.atype': '设施类型：',
  'sws.facility.facil.aserial': '序列号：',

  'sws.facility.whether.bind': '是否绑定设施',
  'sws.facility.no.bind': '放弃绑定',
  'sws.facility.yes.bind': '绑定设施',

  'sws.facility.facil.bnum': '设施编号：',
  'sws.facility.facil.bname': '设施名称：',
  'sws.facility.facil.btype': '设施类型：',
  'sws.facility.facil.bserial': '序列号：',

  'sws.facility.whether.unBind': '是否解除设施绑定？',
  'sws.facility.no.unBind': '放弃操作',
  'sws.facility.yes.unBind': '解除绑定',

  'sws.facility.facil.cnum': '设施编号：',
  'sws.facility.facil.cname': '设施名称：',
  'sws.facility.facil.ctype': '设施类型：',
  'sws.facility.facil.cserial': '序列号：',

  'sws.facility.location.location': '工位位置：',
  'sws.facility.location.num': '工位编号：',
  'sws.facility.location.depart': '工位部门：',
  'sws.facility.location.person': '使用人：',

  401316: '工位已绑定此类设施',
  401317: '设施不存在 ',
  401013: '此工位不存在 ',
};
