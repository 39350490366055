/**
 * Created : vincent
 * Date : 2019-03-20  19:30
 * Email : wangxiao@wafersystems.com
 */
/* eslint-disable*/

export * from './lightConstant';

/**
 * system
 * 以下内容配置详细请在 /config/config.js中查看
 */

export const ROUTE_PATH = routePath;

export const COPY_RIGHT = copyRight;

// 邮箱统一校验规则
export const emailStandardRegex =  /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

// 云服务
export const CLOUD_AES_KEY = 'XDH206VF30Nf9q7t';
export const CLOUD_USER_ID = 'test';
export const CLOUD_USER_SECRET = 'test';
export const PREFIX_BASE64 = 'data:image/jpeg;base64,'
// 密码加密key
export const PASSWORD_AES_KEY = aesKey;
export const PASSWORD_AES_VI = aesVi;

// 菜单获取类型，admin、user、app，三选一
export const MENU_GROUPING = menuGrouping;

// router 白名单,  白名单配置时切记不要配置 根 /，因为根比较特殊，会在代码里面做单独处理
export const ROUTE_ACCESS_LIST = routeAccessList;

// socket client id 类型，PC_UUID、APP_UUID、DSM_UUID，三选一
export const SOCKET_CLIENT_ID = socketClientID;

// socket 链接地址
export const SOCKET_PATH = socketPath;

// 静态资源路径
export const STATIC_RESOURCE_PREFIX = staticResourcePrefix;

export const DEFAULT_PREFIX = defaultBasePrefix;

/* eslint-enable */
export const USER_STATE_LOCK_FLAG_NORMAL = 0;
export const USER_STATE_LOCK_FLAG_FAILURE = 9;
export const USER_STATE_LOCK_FLAG_DEL = 1;

export const MENU_TYPE_BUTTON = 1;
export const MENU_TYPE_MENU = 0;

export const ACCESS_TOKEN = 'access_token';
export const AUTHORITY = 'authority';
export const ACCESS_USERNAME = 'access_username';

export const DEFAULT_INTERFACE = interfaceDefault;

export const RESTFUL_PATH = {
  auth: `${DEFAULT_INTERFACE}virsical-auth`,
  admin: `${DEFAULT_INTERFACE}virsical-upms`,
  upms: `${DEFAULT_INTERFACE}virsical-upms`,
  meeting: `${DEFAULT_INTERFACE}smt-meeting`,
  syncuser: `${DEFAULT_INTERFACE}virsical-syncuser`,
  notice: `${DEFAULT_INTERFACE}virsical-notice`,
  map: `${DEFAULT_INTERFACE}virsical-map`,
  push: `${DEFAULT_INTERFACE}virsical-push`,
  sss: `${DEFAULT_INTERFACE}virsical-sss`,
  sws: `${DEFAULT_INTERFACE}sws-workspace`,
  locker: `${DEFAULT_INTERFACE}sws-locker`,
  wdf: `${DEFAULT_INTERFACE}virsical-wdf`,
  access: `${DEFAULT_INTERFACE}smartaccess`,
  config: `${DEFAULT_INTERFACE}virsical-config`,
  visitor: `${DEFAULT_INTERFACE}vst-visitor`,
  points: `${DEFAULT_INTERFACE}virsical-points`,
  cloudOms: `${DEFAULT_INTERFACE}cloud-oms`,
  tcs: `${DEFAULT_INTERFACE}tcs-toilet`,
  vap: `${DEFAULT_INTERFACE}vap-service`,
  fm: `${DEFAULT_INTERFACE}fm-service`,
};

export const DEFAULT_ICON_MENU_SETTING = 'setting';

export const FEMALE = 0;
export const MALE = 1;

export const REMEMBER_ME_COOKIE_KEY = 'info';

export const RES_CODE = {
  success: 0,
  fail: 1,
};

// 接口签名key
export const SING_KEY = 'M3n6hK';
// 控制接口签名
export const RESTFUL_SIGNATURE = restfulSignature;

// 登录页面router
export const LOGIN_ROUTER = '/user/login';
// 微信绑定router
export const WX_BIND = '/wdfclient/bind';
// 钉钉绑定router
export const DD_BIND = '/wdfclient/init';
// token登录页面router
export const TOKEN_LOGIN_ROUTER = '/token_login';

// token登录页面router
export const WAFER_TOKEN_LOGIN_ROUTER = '/wdfclient/waferTokenLogin';

export const INDEX_ROUTER = indexRouter;

export const VIRSICAL_SCAN = 'virsical_scan';

export const SVG_TYPE_SWS = 'workspace';

// 扫码认证页面router
export const SCANAUTH = '/scan-auth';
// pc 加载iframe 页面
export const PC_TOKEN_LOGIN_ROUTER = '/pc_token_login';
// css样式路径
export const CSS_PATH = cssPath;

export const MEETING_INDEX_ROUTER = meetingIndexRouter;

// 区域
export const TIMEZONE = [
  { key: 'Asia/Shanghai', value: 'Asia/Shanghai', timezone: 8 },
  { key: 'Asia/Tokyo', value: 'Asia/Tokyo', timezone: 9 },
  { key: 'Asia/Seoul', value: 'Asia/Seoul', timezone: 9 },
  { key: 'Asia/Singapore', value: 'Asia/Singapore', timezone: 8 },
  { key: 'Australia/Sydney', value: 'Australia/Sydney', timezone: 10 },
  { key: 'Europe/London', value: 'Europe/London', timezone: 1 },
  { key: 'Europe/Paris', value: 'Europe/Paris', timezone: 2 },
  { key: 'Europe/Moscow', value: 'Europe/Moscow', timezone: 3 },
  { key: 'America/New_York', value: 'America/New_York', timezone: -4 },
  { key: 'America/Chicago', value: 'America/Chicago', timezone: -5 },
  { key: 'America/Denver', value: 'America/Denver', timezone: -6 },
  { key: 'America/Los_Angeles', value: 'America/Los_Angeles', timezone: -7 },
  { key: 'America/Anchorage', value: 'America/Anchorage', timezone: -8 },
  { key: 'Pacific/Honolulu', value: 'Pacific/Honolulu', timezone: -10 },
];

// 云之家绑定router
export const CLOUDHUB_BIND = '/wdfclient/cloudHub';
// 云之家认证router
export const CLOUDHUB_AUTH = '/cloudHub/getPerson';

// 产品服务
export const PRODUCTCODE_PATH = {
  'smt': RESTFUL_PATH.meeting,
  'sws': RESTFUL_PATH.sws,
  'slk': RESTFUL_PATH.locker,
  'sas': RESTFUL_PATH.access,
  'vst': RESTFUL_PATH.visitor,
};

export const MENUDATA_KEY = 'menuData';

// 提前获取token时间（单位：s)
export const GETTOKENTIME = 300;


// 颜色
export const RESOURCE_COLOR = [
  '#30A299',
  '#FFB26A',
  '#6A81D4',
  '#1B9AFF',
  '#42CDD5',
  '#FF6D6A',
  '#99D0DD',
  '#9BDDC8',
  '#EFCC9A',
  '#A6BFE2',
  '#CED4D6',
  '#E2BDBA',
  '#E0ADC7',
  '#B4D298',
  '#D2C587',
  '#F0AA8F',
  '#C8ADEB',
];
