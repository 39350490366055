/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.faultRepair.title': '所有门禁',
  'access.faultRepair.reason': '请输入报修理由',
  'access.faultRepair.my.recode': '我的报修记录',
  'access.faultRepair.commit': '提交',
  'menu.sas.app.faultRepair': '故障报修',
};
