export default {
  'attendee.search': '搜索',
  'attendee.cancel': '取消',
  'contact.button.select': '选择',
  'contact.button.showMore': '显示更多',
  'contact.load.fail': '需要在手机"设置"中打开威思客获取通讯录的权限',
  'meeting.attendee.page.page.value1': '该部门下没有任何员工！',

  frequent_concat: '常用联系人',
};
