import React, { useMemo, useState } from 'react';
import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { useGetShareInviteLink, useRequest } from '@/pages/Visitor/pages/hooks';
import * as AppService from '../services/AppService';

export default function () {
  /**
   * 访客补填分享Popup Data
   * visitorFillPopupVisible
   * batchAddGroupId  数据分组id
   * preRegistrationId  当前数据preId 多访客查看子级详情访客补填时仅显示当前子级访客信息
   */
  const [visitorFillPopupData, setVisitorFillPopupData] = useState<any>(null);

  /**
   * 创建访客补填
   */
  const { runAsync: runSaveVisitorFill, loading: saveVisitorFillLoading } = useRequest(
    (params: any) => AppService.saveVisitorFill(params),
    {
      manual: true,
      onSuccess: (data: any) => {
        Toast.success(formatMessage({ id: '302001' }));
        setVisitorFillPopupData({
          visitorFillPopupVisible: true,
          batchAddGroupId: data,
        });
      },
    },
  );

  /**
   *  访客补填创建后获取分享链接信息
   */
  const { shareInviteLinkData, runGetShareInviteLink, getShareInviteLinkLoading } =
    useGetShareInviteLink();

  const state = useMemo(() => {
    return {
      visitorFillPopupData,
      shareInviteLinkData,
      getShareInviteLinkLoading,
      saveVisitorFillLoading,
    };
  }, [
    visitorFillPopupData,
    shareInviteLinkData,
    getShareInviteLinkLoading,
    saveVisitorFillLoading,
  ]);

  const actions = useMemo(() => {
    return { setVisitorFillPopupData, runGetShareInviteLink, runSaveVisitorFill };
  }, [setVisitorFillPopupData, runGetShareInviteLink, runSaveVisitorFill]);

  return {
    state,
    actions,
  };
}
