import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取预订Id，最大时间等
 * @param s 加密后的请求参数
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getResIdInfo(s) {
  const url = `/${RESTFUL_PATH.sws}/api/station/check-delay?s=${s}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 获取预订详情
 * @param payload
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getReservationInfo({ resId }) {
  const url = `/${RESTFUL_PATH.sws}/api/client/resId?resId=${resId}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 提交延时预订
 * @param id
 * @param hour
 * @param endTime 结束时间
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function setDelayTime({ id, hour, endTime }) {
  const url = `/${RESTFUL_PATH.sws}/api/client/d?id=${id}&hour=${hour}&hex=${endTime}`;
  return request(url, {
    method: 'GET',
  });
}
