/**
 * Created by XuQiang on 2017/3/14.
 */

export default {
  // 公共
  'common.ok': '確定',
  'common.cancel': '取消',
  'upload.fail': '上傳失敗，請稍後重試',
  'upload.error': '伺服器異常，請稍後再試',
  'upload.onlyExcel': '該功能僅支持Excel格式的檔',
  // 首頁
  'index.visitor.reserve': '訪客管理',
  'index.user.login': '用戶登錄',
  'index.username.prompt': '請輸入用戶名',
  'index.username': '用戶名',
  'index.password.prompt': '請輸入密碼',
  'index.password': '密碼',
  'index.submit': '登錄',
  'index.exit': '退出系統',
  'index.exit.tip': '確認要退出系統？',
  // 菜單
  'layout.menu.invites': '邀請記錄',
  'layout.menu.visitors': '訪問記錄',
  'layout.menu.registration': '訪客登記',
  'layout.menu.settings': '配置管理',
  'layout.menu.phoneBook': '通訊錄管理',
  'layout.menu.location': '企業辦公地管理',
  'layout.menu.setting': '基礎設置',
  'layout.menu.pcSetting': 'pc設置',
  'layout.menu.iPadSetting': 'iPad設置',
  'layout.menu.machineSetting': '一體機設置',
  'layout.menu.type': '訪問類型管理',
  'layout.menu.NDA': '保密協議管理',
  'layout.menu.notify': '通知方式配置',
  'layout.menu.devices': '終端登錄查詢',
  'layout.menu.admin': '管理員管理',
  'layout.menu.custom': '前臺設置',
  'layout.menu.report': '報表管理',
  'layout.menu.report.invite': '邀請報表',
  'layout.menu.report.visitor': '訪問報表',
  'layout.menu.report.area': '辦公地統計',
  'layout.menu.meeting': '會議活動',
  'layout.menu.meeting.edit': '編輯會議',
  'layout.menu.meeting.create': '新建會議',
  'layout.menu.meeting.detail': '查看會議',
  'layout.menu.meeting.detail.area': '參會區',
  'layout.menu.group': '團體邀請',
  'layout.menu.group.detail': '查看邀請',
  'layout.menu.blacklist': '黑名單管理',
  'layout.top.notifications': '通知方式',
  'layout.top.SMS': '短訊',
  'layout.top.email': '郵件',
  'layout.top.SMS&email': '短訊+郵件',
  'layout.top.system': '系統',
  // 地圖
  'map.point': '地圖選點：',
  'map.input.tip': '請輸入地點（支持在地圖中選點）',
  'map.more': '詳細地址：',
  'map.add.tip': '請直接追加詳細樓牌號',
  'map.right.place': '請輸入有效的地址',
  // 表格
  table_no_date: '暫無數據',
  // 輸入框
  input_placeHloder: '請輸入關鍵字',
};
