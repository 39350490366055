import wx from 'weixin-js-sdk';

const config = (jsSDKInfo, apiList, isDebug = false) =>
  new Promise((resolve, reject) => {
    window.console.log(jsSDKInfo, 'jsSDKInfo');
    wx.config({
      debug: isDebug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: jsSDKInfo.appid, // 必填，公众号的唯一标识
      timestamp: jsSDKInfo.timestamp, // 必填，生成签名的时间戳
      nonceStr: jsSDKInfo.nonceStr, // 必填，生成签名的随机串
      signature: jsSDKInfo.signature, // 必填，签名，见附录1
      jsApiList: apiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });
    wx.ready(resolve);
    wx.error(reject);
  }).then(() => {
    window.console.log('wechatConfig注册成功');
  });

/**
 * 获得初始化Wechat环境，获得用户信息
 * @param jsSDKInfo
 * @param apiList
 * @returns {Promise.<void>}
 */
export const init = async (jsSDKInfo, apiList) => {
  await config(jsSDKInfo, apiList).catch((err) => window.console.log(err, 'wechatConfig注册失败'));
};

/**
 * 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
 */
export const shareWithFriend = (payload) => {
  window.console.log(payload, 'shareWithFriend_payload');
  wx.ready(function () {
    //需在用户可能点击分享按钮前就先调用
    wx.updateAppMessageShareData({
      ...payload,
      success: function () {
        // 设置成功
        window.console.log(payload?.link, 'shareWithFriend_设置成功');
      },
    });
  });
};

/**
 * 自定义“分享给朋友”及“分享到QQ”按钮的分享内容 旧版接口
 */
export const shareWithFriendOld = (payload) => {
  window.console.log(payload, 'shareWithFriendOld_payload');
  wx.ready(function () {
    //需在用户可能点击分享按钮前就先调用
    wx.onMenuShareAppMessage({
      ...payload,
      success: function () {
        // 设置成功
        window.console.log(payload?.link, 'shareWithFriendOld_设置成功');
      },
    });
  });
};

/**
 * 判断是不是微信浏览器
 * @returns {boolean}
 */
export const getWxBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') !== -1;
};
