import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import * as service from './service';

export default {
  namespace: 'swsDelay',
  state: {
    browser: '',
    reservationInfo: {},
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getReservationInfo({ id }, { call, put }) {
      const result = yield call(service.getReservationInfo, { resId: id });
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: { reservationInfo: result?.data ||{} },
        });
        return result?.data ||{}
      }
      return {}
    },
    *setDelayTime({ payload }, { call }) {
      const result = yield call(service.setDelayTime, payload);
      if (result && result.code === 0) {
        Toast.hide();
        Toast.success(formatMessage({ id: 'sws.delay.submit.success' }));
        // yield put({ type: 'getReservationInfo', reservationId: payload.id });
      } else if (result.code === 1 && result.msg) {
        Toast.hide();
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
        /* if (pathname.trim().length > 0 && pathname.includes('/workspace/station/delay')) {
          const { id } = query;
          dispatch({ type: 'getReservationInfo', id });
        } */
      });
    },
  },
};
