/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 预订操作
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function confirmReservation(param) {
  return request(`/${RESTFUL_PATH.sws}/api/reserve/normal`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

/**
 * 预定推荐工位/区域
 * @param payload 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getRecommendBooking(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/reserve/recommend?startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}`;
  return request(url, {
    method: 'GET',
  });
}
