/**
 * Created by XuQiang on 2017/6/7.
 */

export default {
  // 办公地
  'admin.office.name': 'Location Name',
  'admin.office.shortName_': 'Short Name',
  'admin.office.shortName': 'Location Short Name',
  'admin.office.allName_': 'Full Name',
  'admin.office.allName': 'Location Full Name',
  'admin.office.address': 'Address',
  'admin.office.shortName.tip': 'Please enter the abbreviation for the location',
  'admin.office.allName.tip': 'Please enter the full name of the location',
  'admin.office.address.tip': 'Please enter the address of the location',
  'admin.office.detailAddress.tip': 'Please enter the detailed address',
  'admin.office.edit': 'Edit',
  'admin.office.organization': 'Organization',
  'admin.office.editInfo': 'Edit Location',
  'admin.office.delete': 'Delete',
  'admin.office.add': 'New Location',
  'admin.office.detailAddress': 'Detail Address',
  'admin.office.choose.organization': 'Organization',
  'admin.office.delete.tip': 'Are you sure you want to delete the location？',
  'admin.new': 'New Admin',
  'admin.edit': 'Edit Admin',
  'admin.name': 'Name',
  'admin.name_': 'Name',
  'admin.name.tip.p': "Please enter an administrator's name",
  'admin.name.tip': "Please enter the correct administrator's name",
  'admin.role': 'Role',
  'admin.role_': 'Role',
  'admin.role.tip': 'Please select the administrator role',
  'admin.del.tip': 'Are you sure you want to delete the administrator？',
  'admin.black.del.tip': 'Are you sure you want to delete the blackUser？',
  'admin.visitType.name': 'Visitor Type',
  'admin.visitType.addInfo': 'Add',
  'admin.visitType.editInfo': 'Edit',
  'admin.visitType.companyName': 'Location Name',
  'admin.visitType.serial': 'No.',
  'admin.visitType.name.tip': 'Please enter the visitor type',
  'admin.visitType.del.tip': 'Are you sure you want to delete this type？',
  'admin.NDA': 'NDA',
  'admin.NDA.companyName': 'Location',
  'admin.NDA.add': 'New NDA',
  'admin.NDA.watch': 'View',
  'admin.NDA.watchNDA': 'Watch NDA',
  'admin.NDA.addNDA': 'Add NDA',
  'admin.NDA.editNDA': 'Edit NDA',
  'admin.NDA.del.tip': 'Are you sure you want to delete the NDA?',
  'admin.NDA.choose.companyName': ' Please choose the location',
  'admin.NDA.exist.companyName':
    'The NDA has already existed. Please perform edit operations on it in the list',
  'admin.NDA.tip': 'Please enter your NDA',
  'admin.device.type': 'Devices Type',
  'admin.device.id': 'Devices ID',
  'admin.device.user': 'Logged User',
  'admin.device.close': 'Close',
  'admin.device.time': 'Login Time',
  'admin.device.type_': 'Devices Type：',
  'admin.device.id_': 'Devices ID：',
  'admin.device.time_': 'Login Time：',
  'admin.device.ip_': 'Login IP：',
  'admin.device.detail': 'Login Detail',
  'admin.notify.msg': 'SMS notification',
  'admin.notify.msg.tip':
    'Notification of configuration related matters in the way of sending short messages',
  'admin.notify.email': 'Mail notification',
  'admin.notify.email.tip':
    'Notification of configuration related matters in the way of sending mail',
  'admin.notify.desk': 'Front desk notice',
  'admin.notify.desk.tip': 'Send a text message to inform the front desk staff of the visit',
};
