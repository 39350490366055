export default {
  'meeting.manage.title.service': 'Meeting service application',
  'meeting.manage.need.attend_': 'Attendee(s): ',
  'meeting.manage.sign.in': ' check-in and ',
  'meeting.manage.signed.people': ' Attendee(s)',
  'meeting.manage.signed.people,': ' Attendee(s), ',
  'meeting.manage.signed.in': 'Checked in',
  'meeting.manage.not.sign': 'Not check in',
  'meeting.manage.participant.not.sign': ' not check in',
  'meeting.manage.sign.time_': 'Check-in time: ',
  'meeting.manage.retroactive': 'Supplementary check-in',
  'meeting.manage.service.type_': 'Service name: ',
  'meeting.manage.service.level_': 'Level',
  'meeting.manage.service.level.select.title':'Select Level',
  'meeting.manage.service.payer_': 'Payer',
  'meeting.manage.service.payer_unset': 'Select payer',
  'meeting.manage.service.describe_': 'Describe: ',
  'meeting.manage.service.category_': 'Type: ',
  'meeting.manage.service.unit.price': 'Yuan / {unit}',
  'meeting.manage.service.unit.yuan': 'Yuan',
  'meeting.manage.service.upload': 'Upload',
  'meeting.manage.service.input.remark': 'Enter the remark',
  'meeting.manage.service.total_': 'Total: ',
  'meeting.manage.service.fee.tip': 'The meeting service is only valid for the nearest meeting!',
  'meeting.manage.service.level': 'Please select the service type',
  'meeting.manage.service.noData': 'No meeting services',
  'meeting.manage.back.home': 'Back Homepage',
  'meeting.manage.previous.page': 'Back',
  'meeting.manage.back.mine': 'Meeting List',

  // 文件上传
  'meeting.manage.upload.file.type.fail': 'Wrong type of the uploaded file ',
  'meeting.manage.upload.file.fail.value1': 'Uploaded photos shall not exceed 5M',
  'meeting.manage.upload.file.fail.value2': 'Uploaded files shall not exceed 10M',
  'meeting.manage.upload.file.fail.value3': 'Fail to analyze the file format',
  'meeting.manage.upload.file.fail.value4':
    'Attachments need to be uploaded for selected meeting services!',
};
