/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import { Toast } from 'antd-mobile';
import { formatMessage } from 'umi';
import * as service from './service';

export default {
  namespace: 'PremiumEdition',
  state: {
    key: '',
    dayData: [],
    dayMaxData: [],
    dayMinData: [],
    weekData: [],
    weekMaxData: [],
    weekMinData: [],
    isShow: false,
    errMsg: '',
    deviceNumber: '',
    deviceList: [],
    keys: '',
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *checkSubscribe(_, { call, put }) {
      const { code, msg } = yield call(service?.checkSubscribe);
      if (code === 0) {
        yield put({ type: 'setState', payload: { isShow: true } });
      } else {
        yield put({
          type: 'setState',
          payload: {
            isShow: false,
            errMsg: msg,
          },
        });
        Toast.fail(formatMessage({ id: msg }));
      }
      return code;
    },
    // 获取设备列表
    *deviceList(_, { call, put }) {
      const { code, data, msg } = yield call(service.deviceList);
      if (code === 0) {
        const arr = data.map(item => {
          return {
            label: item.deviceNumber,
            value: item.deviceNumber
          }
        })
        yield put({
          type: 'setState',
          payload: {
            deviceList: arr,
            errMsg: data?.length === 0 && 'env.device.none',
          },
        });
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
      return data?.[0]?.deviceNumber;
    },
    // 获取周期数据
    *historyData({ payload }, { call, put }) {
      const { code, data, msg } = yield call(service.historyData, payload);
      if (code === 0) {
        if (payload.limit === 24) {
          switch (payload.agg) {
            case 'AVG':
              yield put({ type: 'setState', payload: { dayData: data } });
              break;
            case 'MIN':
              yield put({ type: 'setState', payload: { dayMinData: data } });
              break;
            case 'MAX':
              yield put({ type: 'setState', payload: { dayMaxData: data } });
              break;
            default:
              break;
          }
        } else {
          switch (payload.agg) {
            case 'AVG':
              yield put({ type: 'setState', payload: { weekData: data } });
              break;
            case 'MIN':
              yield put({ type: 'setState', payload: { weekMinData: data } });
              break;
            case 'MAX':
              yield put({ type: 'setState', payload: { weekMaxData: data } });
              break;
            default:
              break;
          }
        }
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
  },
  subscriptions: {
    set({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
        if (pathname.endsWith('/envMonitor/history') || pathname.endsWith('/envMonitor/history/')) {
          dispatch({ type: 'checkSubscribe' }).then(code => {
            if (code === 0) {
              dispatch({ type: 'deviceList' }).then(res => {
                if (res) {
                  if (query?.keys && query?.deviceNumber) {
                    dispatch({
                      type: 'setState',
                      payload: { keys: query.keys, deviceNumber: query.deviceNumber }
                    })
                  } else {
                    dispatch({ type: 'setState', payload: { deviceNumber: res } })
                  }
                }
              })

            }
          })
        }
      });
    },
  },
};
