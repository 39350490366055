export default {
  'component.globalHeader.search': 'Search',
  'component.globalHeader.search.example1': 'Search example 1',
  'component.globalHeader.search.example2': 'Search example 2',
  'component.globalHeader.search.example3': 'Search example 3',
  'component.globalHeader.help': 'Help',
  'component.globalHeader.notification': 'Notification',
  'component.globalHeader.notification.empty': 'You have viewed all notifications.',
  'component.globalHeader.message': 'Message',
  'component.globalHeader.message.empty': 'You have viewed all messsages.',
  'component.globalHeader.event': 'Event',
  'component.globalHeader.event.empty': 'You have viewed all events.',
  'component.noticeIcon.clear': 'Clear',
  'component.noticeIcon.cleared': 'Cleared',
  'component.noticeIcon.empty': 'No notifications',
  'packages.expire.tip': 'Your product has expired, please log in again',
  'router.title.HomePage': 'Home',
  'router.title.MyInfo': 'Me',
  'app.title': 'Virsical Smart Office',
  'router.title.meeting.HomePage': 'Smart Meeting',
  'router.default.title': 'Smart Office',

  'router.title.setting': 'Settings',
  'router.title.setting.lang': 'Set language',
  'router.title.setting.timezone': 'Set time zone',
  'router.title.setting.zone': 'Set default zone',
  'common.action.success': 'Operation succeeded',
  'common.action.fail': 'Operation failed',

  'menu.points.app-operation': 'Points Management',
  'menu.points.app.account': 'Account Balance',
  'menu.points.app.bill': 'My Bill',
  'menu.points.app.punishment': 'Violation Check',

  'menu.tcs.app.toilet': 'Toilet Management',
  'menu.tcs.app.usestatus': 'Cubicle Occupied State',
  'menu.tcs.app.alarm.list': 'Alarm Record',
  'menu.parameter.is.incorrect': 'Parameter Is Incorrect',

  'global.guidance.skip': 'Skip',
  'global.guidance.previous': 'Prev',
  'global.guidance.next': 'Next',
  'global.guidance.finish': 'Finish',

  'login.feishu.err.title': 'The authentication is abnormal. Please re-enter the application',
};
