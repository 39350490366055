import request from '@/utils/request';
// import { stringify } from 'qs'
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 立即使用
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function setImmediate(params) {
  /* const url = `/${RESTFUL_PATH.sws}/api/reserve/use?${stringify(params)}`;
  return request(url, {
    method: 'GET',
  }); */
  const url = `/${RESTFUL_PATH.sws}/api/reserve/use-by-post`;
  return request(url, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
