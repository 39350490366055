import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export async function meetingPage(params) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/page`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export async function getcalendarRoomList(payload) {
  return request(`/${RESTFUL_PATH.meeting}/calendar-view/room`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export function getRoomDetail({ roomId }) {
  return request(`/${RESTFUL_PATH.meeting}/room-info/${roomId}`, {
    method: 'GET',
  });
}
