/**
 * Created by XuQiang on 2017/6/7.
 */

export default {
  'admin.office.name': '辦公地名稱',
  'admin.new': '新增',
  'admin.edit': '編輯管理員',
  'admin.name': '管理員姓名',
  'admin.name_': '姓名',
  'admin.name.tip': '請輸入正確的管理員姓名',
  'admin.name.tip.p': '請輸入管理員姓名',
  'admin.role': '管理員角色',
  'admin.role_': '角色',
  'admin.role.tip': '請選擇管理員角色',
  'admin.del.tip': '您確定要刪除該管理員嗎？',
  'admin.black.del.tip': '您確定要刪除該黑名單嗎？',
  'admin.office.shortName_': '辦公地簡稱',
  'admin.office.shortName': '辦公地簡稱',
  'admin.office.allName_': '辦公地全稱',
  'admin.office.allName': '辦公地全稱',
  'admin.office.address': '辦公地地址',
  'admin.office.organization': '綁定',
  'admin.office.shortName.tip': '請輸入辦公地簡稱',
  'admin.office.allName.tip': '請輸入辦公地全稱',
  'admin.office.address.tip': '請輸入辦公地地址',
  'admin.office.detailAddress.tip': '請輸入詳細地址',
  'admin.office.edit': '編輯',
  'admin.office.editInfo': '編輯辦公地',
  'admin.office.delete': '刪除',
  'admin.office.add': '新增',
  'admin.office.detailAddress': '詳細地址',
  'admin.office.choose.organization': '請選擇組織機構',
  'admin.office.delete.tip': '您確定要刪除該辦公地嗎？',
  'admin.visitType.name': '訪問類型',
  'admin.visitType.name.tip': '請輸入訪問類型',
  'admin.visitType.addInfo': '新增',
  'admin.visitType.editInfo': '編輯類型',
  'admin.visitType.companyName': '辦公地名稱',
  'admin.visitType.serial': '序號',
  'admin.visitType.del.tip': '您確定要刪除該類型嗎？',
  'admin.NDA': '保密協議',
  'admin.NDA.companyName': '辦公地名稱',
  'admin.NDA.add': '新增',
  'admin.NDA.watch': '查看',
  'admin.NDA.watchNDA': '查看協議',
  'admin.NDA.addNDA': '新增協議',
  'admin.NDA.editNDA': '編輯協議',
  'admin.NDA.del.tip': '你確定要刪除該公司的保密協議嗎?',
  'admin.NDA.choose.companyName': '請選擇辦公地',
  'admin.NDA.exist.companyName': '辦公地保密協議已經存在，請在列表中對其執行編輯操作',
  'admin.NDA.tip': '請輸入保密協議',
  'admin.device.type': '登錄終端類型',
  'admin.device.id': '登錄終端標識',
  'admin.device.user': '登錄用戶',
  'admin.device.close': '關閉',
  'admin.device.time': '登錄時間',
  'admin.device.type_': '登錄終端類型：',
  'admin.device.id_': '登錄終端標識：',
  'admin.device.time_': '登錄時間：',
  'admin.device.ip_': '登錄IP：',
  'admin.device.detail': '登錄詳情',
  'admin.notify.msg': '短訊通知',
  'admin.notify.msg.tip': '以發送短訊的方式通知配置相關事項',
  'admin.notify.email': '郵件通知',
  'admin.notify.email.tip': '以發送郵件的方式通知配置相關事項',
  'admin.notify.desk': '前臺通知',
  'admin.notify.desk.tip': '發送短訊的方式通知前臺人員訪客到訪',
};
