import React from 'react';

export const getContent = (msgList, tip) => {
  return (
    <div>
      {tip}
      {msgList}
    </div>
  );
};

export const getP = (rl) => {
  return <p>{rl.roomName}</p>;
};
