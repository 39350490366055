/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'app.vap.meetingControl.tab1': '設備控制',
  'app.vap.meetingControl.tab2': '場景模式',
  'app.vap.temp.error1': '資源下無模版',
};
