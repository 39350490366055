import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取我的账户信息
 * @param meetingId
 * @returns {Object}
 */
const getAccountData = () => {
  return request(`/${RESTFUL_PATH.points}/account/current`, {
    method: 'GET',
  });
};

export default getAccountData;
