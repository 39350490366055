import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export function getMeetingDetail(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/detail-info/${meetingId}`, {
    method: 'GET',
  });
}


export function getMeetingOther(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/getMeetingOther/${meetingId}`, {
    method: 'GET',
  });
}

export function getMeetingSvcList(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-svc/getMeetingSvcList/${meetingId}`, {
    method: 'GET',
  });
}

export function cancelMeetingSvc(processsId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-svc/cancel/${processsId}`, {
    method: 'POST',
  });
}

export function removeMeetingSvc(processsId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-svc/delete/${processsId}`, {
    method: 'POST',
  });
}

export const getAllSvcInfo = ({tenantId=1001} ) => {
  return request(`/${RESTFUL_PATH.meeting}/svc-info/getAllSvcVoInfo?tenantId=${tenantId}`, { method: 'GET' });
};

export const saveServiceData = params => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-svc/saveMeetingSvcList`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};
