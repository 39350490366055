export default {
  200000: '操作成功！',
  200001: '操作失败！',
  201002: '当前用户没有管理会议的权限！',

  201000: '已签到，无需重复签到！',
  201001: '该用户不是当前会议的参会人，无法签到！',
  201009: '当前用户已拒绝该会议，无法签到！',
  201013: '当前申请已被处理！',

  203001: '没有可用会议号资源，无法预定视频会议，请与管理员联系！',
  cmma_no_devices: '视频会议室 {names} 未绑定视频设备，预订失败！',
  203003: '视频会议室资源占用中',
  203004: '找不到视频会议信息',
  202031: '会议不存在,操作失败！',
  202001: '开始时间不能为空',
  202002: '结束时间不能为空',
  202003: '开始时间不能晚于结束时间',
  202004: '会议当前状态不允许编辑',
  202005: '周期会议时间不允许跨天',
  202006: '跨天会议时间不能小于最小天数',
  202064: '跨天会议时间不能大于{value}天',
  202007: '当前用户权限不足，无法修改此会议！',
  202008: '您必须为会议主持人或参会人！',
  202009: '开始时间不能早于当前时间,请重新选择!',
  202010: '预订时间的会议室处于维护中，不能预订会议。',
  202011: '周期会议的循环参数未填写完整!',
  202012: '该周期会议根据周期规则无法产生周期会议。',
  202013: '该周期会议预订时间超过出最大周期。',
  202014: '周期会议功能关闭，不允许预订周期会议！',
  202015: '会议开始时间早于工作时间，预订失败！',
  202016: '会议结束时间晚于工作时间，预订失败！',
  202017: '该时间段内没有可用的webex线路！',
  202018: 'webex模块未启动！',
  202019: 'webex会议同步失败！',
  202021: '当前的会议服务正在审批或审批已通过，不允许继续修改！',
  202022: '该系列会议存在会议服务正在审批或审批已通过，不允许继续修改！',
  202023: '会议未到签到时间!',
  202024: '会议签到时间已过!',
  202028: '请提前{time}分钟申请该会议！',

  202077: '您没有{roomList}会议室的预订权限，如需修改会议信息，请重新选择会议室!',

  204001: '当前会议{state}，无法回执!',
  204002: '会议不存在!',
  204003: '当前用户不是会议参会人或申请人,不允许回执!',
  204004: '当前用户已回执会议,无需重复回执!',
  202039: 'Exchange会议时间冲突',
  202040: 'Google会议时间冲突',
  202041: 'Exchange审批会议室不能与其他审批会议室同时选择！',
  202045: '会议室无足够资源，请联系管理员。',
  202046: 'Zoom模块未启动！',
  202047: 'ZOOM会议接口调用异常，请稍后重试或联系系统管理员！',
  202048: '会议延长功能未开启',
  202049: '会议签到功能未开启',

  202056: '该会议室为Exchange会议室，预订的会议时间不能超过Exchange同步天数的范围!',
  202050: '当前会议状态不允许编辑调查问卷',
  202051: '当前会议zoom信息不存在',
  202052: '删除调查问卷失败',
  202053: '创建调查问卷失败',
  202054: '更新调查问卷失败',
  202101: '延长失败，会议可延长时间不足',
  202105: '第三方预约会议创建人不存在',
  202055: '创建失败，该线路不具备创建调查的功能',
  202058: '外部参会人和内部参会人邮箱重复',

  202057: '展板开门失败,原因:未绑定门牌号',
  202038: '用户权限不足,操作失败！',
  202061: '会议状态已改变，取消会议失败！',
  202059: '这是一个Exchange会议，请补充外部参会人邮箱信息！',

  202030: '手机号码{phone}已存在于历史参会人列表中',
  202065: '邮箱{email}已存在于历史参会人列表中',
  200006: '当前用户预订次数已被限制',
  202060: '没有开启反向同步功能,预订失败',
  202108: '会议室不可用',
  202109: '非实体会议室不能扫码预订',

  202134: '会议正在处理中，不允许编辑！',
  202110: '预订失败，会议室：{rooms}已下线',
  202111: '预订失败，会议室：{rooms}已删除',
  202112: '预订失败，会议室：{rooms}已维护',
  202066: '会议已开始签到, 不允许编辑！',
  202067: '当前用户没有预订周期会议的权限！',
  202068: '当前用户没有预订跨天会议的权限！',
  20206701: '当前用户没有编辑周期会议的权限！',
  'meeting.booking.fail.title.maintain':
    '{roomName}会议室在您选择的会议时间内会维护，预订会议失败！',
  'meeting.booking.fail.title.maintain.edit':
    '{roomName}会议室在您选择的会议时间内会维护，修改会议失败！',

  202120: '创建失败，创建人或主持人电话为空！',
  202121: '创建腾讯会议失败！',
  202122: '更新腾讯会议失败！',
  'meeting.booking.fail.tencent.max': '同一时间只能预订{num}个腾讯会议，请更换时间后再试',
  202115: '您已经被拉入系统黑名单，暂时无法预订会议',
  202131: '获取teams会议参数配置信息错误，请联系管理员',
  202132: '创建teams会议失败！',
  202133: '更新teams会议失败！',
  207012: '会议预订时长超出会议室单场会议最大时间{value}分钟',
  200999: '预约的会议与其他会议冲突，预约失败',
  207013: '会议时长大于会议室设置跨天最大分钟数',

  202143: '获取YMS未绑定设备信息失败',
  202144: 'YMS模块未配置，请联系管理员配置相关信息',
  202145: 'YMS入会时间已到达，不允许操作',
  202146: '创建YMS会议失败！',
  202147: '会议室未绑定设备终端，请重新选择会议室！',
  202148: 'YMS预约时间距会议开始时间不可小于5分钟',
  202149: '结束YMS会议失败！',
  202151: '操作YMS会议失败！',
  202150: '编辑YMS会议失败失败！',
  202152: '创建飞书会议失败！',
  202153: '更新飞书会议失败！',
  202154: '延长飞书会议失败！',
  'error.meeting.teams.timeout': 'Teams 网络请求超时',
  202158: '创建人飞书ID不存在',
  20210059: '密码格式不正确，仅支持6位纯数字！',
  202200: '飞书会议室 和 Exchange 会议室不能同时预约',
  202201: '不能直接将Exchange会议室更换为飞书会议室',
  202202: '不能直接将飞书会议室更换为Exchange会议室',
  202204: '该会议预订申请已被拒绝',
  202205: '该会议预订申请，由于无人审批而自动取消',
  202164: '网络会议不允许预约跨天会议!',
  202162: '当前用户无预约teams会议权限，预约失败！',
  202161: '腾讯会议参数配置信息错误，请联系管理员！',

  202206: '访客预约时间限制{value}天以内，请重新预约',
  202207: '访客未开启跨天配置',
  202208: '访客跨天时间限制{value}天以内，请重新预约',
  202209: '访客来访时间早于工作时间',
  202210: '访客来访结束时间晚于工作时间',
  202211: '上一次延长操作正在处理中，请稍后再试',
  202212: '会议开始时间晚于工作时间，预约失败!',
  202213: '会议结束时间早于工作时间，预约失败!',
  202214: '访客人数超出拜访人当天最大拜访数量!',
  202169: '单日会议会议时长小于配置最小时长!',
  202172: '延长失败，会议时长不能超过{value}分钟!',
  202171: '当前日期不可用，请选择其他日期预约！',
  // 202180: '视频会议创建失败',
  // 202181: '视频会议更新失败',
  // 202182: '当前预订会议人数过多，请稍后重试',
  202173: 'exchange同步会议无需回执, 请使用outlook上回执',
  202174: '飞书同步会议无需回执, 请使用飞书上回执',
  202178: '跨天不能预约{value}天后的会议！',
  202181: '周期会议不能预约{value}天后的会议！',
  202179: '周期会议会议时长小于配置最小时长！',
  202180: '周期会议会议时长大于配置最大时长！',
  202182: '延长失败，会议时长不能超过{value}分钟!',
  202176: '会议延长失败，{value}正在维护中!',
  202177: '会议延长失败，{value}已停用!',
  202183: '当前周期会议还存在未处理完的会议，请稍后再进行编辑',
  202184: '当前周期会议还存在未处理完的会议，请稍后再进行取消',
  20218101: '循环会议无法在同一日内预约多场，请选择其他日期',
  2023001: '创建CloudMeet会议失败,请检查后台日志！',
  2023002: '更新CloudMeet会议失败,请检查后台日志！',
  2023003: '延长CloudMeet会议失败,请检查后台日志！',
  2023004: 'CloudMeet会议已提前进入或已开始，无法取消！',
  2023005: '结束CloudMeet会议失败,请检查后台日志！',
  202401: '创建华为视频会议失败,请检查后台日志！',
  202402: '更新华为视频会议失败,请检查后台日志！',
  202404: '延长华为视频会议失败,请检查后台日志！',
  202403: '结束华为视频会议失败,请检查后台日志！',
  202405: '取消华为视频会议失败,请检查后台日志！',
  202406: '华为视频会议配置错误,请检查后台日志！',
  202407: '操作失败，请检查后台日志！',
  202185: '该会议包含需要审批的会议室，修改会议时间后需要重新审批，请确认是否修改？',
  202186: '修改后的会议室包含需要审批的会议室，修改后会议需要重新审批，请确认是否修改？',
  202187: '该会议包含需要审批的会议室，修改循环规则后需要重新审批，请确认是否修改？',
  202190: '变更会议后，针对单场会议预订的会议服务/设备会被取消，是否继续？',
  2023006: '会议已开始或已提前入会，不允许编辑或取消！',
  2023007: 'CloudMeet会议已结束，无法操作！',
  202194: '访客集成功能未开启',
  202195: '邀请访客必须提前{value}分钟预订',
  202197: '飞书会议室不能预约teams会议',
  202215: '会议邀请访客，访客预约时间为非工作时间，操作失败！',
  202216: '会议邀请访客，访客跨天时间超出天数限制，操作失败！',
  202217: '会议邀请访客，访客预约时间超出天数限制，操作失败！',
  202218: '会议邀请访客，访客数量超出接待地点的可邀请数量，操作失败！',
  202219: '该条记录已被其他人员审批，无法再次审批！',
  202199: 'Exchange/Office365会议室日历上未读取到该会议，请检查会议室日历是否应答成功',
  202220: '当前租户下不存在会议室，无法生成模拟数据',
  202221: '当前为企微会议免费版，当前功能无法使用',
  202222: '创建企微会议失败，请检查后台日志',
  202223: '修改企微会议失败，请检查后台日志',
  202224: '取消企微会议失败，请检查后台日志',
  202225: '延长失败，企微会议无法延长会议',
  20231008: 'webex会议时长必须大于10分钟',
  202310241700: 'webex同步预约失败',
  202310241722: 'webex同步更新失败',
  202402061443: '请检查webex账号是否有效，或者查看scope是否配置了足够权限',
  202402061445: '请检查webex配置或查看token是否过期',
  20240923: '请选择至少一个终端会议室',
  201016: '会议室没有绑定工作流模版',
  // 201016: '预订失败，会议室:{rooms}没有会议室管理员',
  201017: '会议服务没有绑定工作流模版',
  201018: '会议移动设备没有绑定工作流模版',
  2023083001:'附件信息不能为空！',
  2023083002:'文件名称不能为空！',
  2023083003:'文件后缀不能为空！',
  2023083004:'文件格式错误！',
  2023083005:'附件已存在，不能重复上传！',
  2023083006:'当前用户无下载权限！',
  2023083007: '存储空间已满',
  2023083008: '上传失败',
  2023083009: '无效的文件名',
  2023083010: '会议已结束',
  2023083011: '会议已取消',
  20100501: '存在被删除的会议室,请刷新页面重新选择会议室',
};
