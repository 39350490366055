export default {
  'register.numbers': '来访人数',
  'register.host.number': '被访人电话',
  'register.photo.error1': '该浏览器无法拍照，请使用Chrome浏览器',
  'register.photo.error2': '无拍照设备，无法拍照',
  'register.photo.make': '拍照',
  'register.photo.make.tip': '请拍照',
  'register.photo.again': '重新拍照',
  'register.index.step1': '填写访客信息',
  'register.index.step2': '填写身份证信息',
  'register.index.step3': '拍照',
  'register.index.step4': '打印访客贴',
  'register.form.scan': '扫描二维码',
  'register.form.pen': '输入邀请码',
  'register.form.radio': '证件抵押',
  'register.form.radio.0': '未抵押',
  'register.form.radio.1': '已抵押',
  'register.form.cardNumber': '工牌编号',
  'register.form.cardNumber.tip': '请输入正确的工牌编号',
  'register.modal.scan': '扫描二维码',
  'register.modal.enter': '输入邀请码',
  'register.modal.input.tip': '请输入六位纯数字邀请码',
  'register.modal.scan.tip': '请直接扫描短信二维码',
  'register.id.title.id': '* 可直接扫描身份证或手动输入身份证号码',
  'register.id.title.drive': '* 可直接扫描驾驶证或手动输入驾驶证号码',
  'register.id.title.officer': '* 可直接扫描军官证或手动输入军官证号码',
  'register.id.title.passport': '* 可直接扫描护照或手动输入护照号码',
  'register.id.title.HK/Macau': '* 可直接扫描港澳通行证或手动输入港澳通行证号码',
  'register.id.type.id': '身份证',
  'register.id.type.drive': '驾驶证',
  'register.id.type.officer': '军官证',
  'register.id.type.passport': '护照',
  'register.id.type.HK/Macau': '港澳通行证',
  'register.id.text.id': '身份证号码：',
  'register.id.text.drive': '驾驶证号码：',
  'register.id.text.officer': '军官证号码：',
  'register.id.text.passport': '护照号码：',
  'register.id.text.HK/Macau': '港澳通行证号码：',
  'register.id.text.tip.id': '请输入正确的身份证号码',
  'register.id.text.tip.drive': '请输入正确的驾驶证号码',
  'register.id.text.tip.officer': '请输入正确的军官证号码',
  'register.id.text.tip.passport': '请输入正确的护照号码',
  'register.id.text.tip.HK/Macau': '请输入正确的港澳通行证号码',
};
