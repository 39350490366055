export default {
  'home.timeLater': '{timeStr}后开始',
  'home.timeLater.new': '天后开始',
  'home.timeLater.new2': '后开始',
  'home.timeStop': '{timeStr}后结束',
  'home.message.knowIt': '知道了',
  'home.message.refuse': '拒绝',
  'home.message.accept': '参加',
  'home.message.gotit': '操作成功',

  'home.index.reserved': '未开始',
  'home.index.processing': '进行中',
  'home.index.auditing': '待审核',
  'home.index.new.auditing': '',
  'home.index.filter': '会议筛选',

  'home.audit.refuse': '拒绝',
  'home.audit.adopt': '通过',
  'home.audit.submit': '提交',
  'home.audit.refusal': '拒绝理由',
  'home.audit.emptyRefusal': '请输入拒绝理由',
  'home.audit.noneAudit': '暂无审核工作',
  'home.audit.meetingMode': '重复频率',
  'home.audit.meetingRange': '重复范围',
  'home.audit.meetingMode.day': '按日',
  'home.audit.meetingMode.workday': '按工作日',
  'home.audit.meetingMode.week': '按周',
  'home.audit.meetingMode.month': '按月',
  'home.audit.meetingMode.year': '按年',
  'home.audit.meetingMode.normal': '不循环',
  'home.audit.meetingRange.forever': '无结束日期',
  'home.audit.meetingRange.repeat': '重复',
  'home.audit.meetingRange.time': '次',
  'home.audit.repeat.label': '循环',
  'home.audit.adopt.success': '审核通过',
  'home.audit.refuse.success': '操作成功',

  'home.index.history.label': '历史会议',
  'home.index.new.label': '新会议',
  'home.index.message.label': '新消息',
  'home.index.audit.label': '审批会议',
  'home.index.unit.session': '场',
  'home.index.unit.pcs': '个',
  'home.index.unit.day': '天',
  'home.index.unit.days': '天',
  'home.index.unit.hours': '小时',
  'home.index.unit.hourss': '小时',
  'home.index.unit.minute': '分钟',
  'home.index.unit.start': '后开始',
  'home.index.unit.end': '后结束',
  'home.index.unit.start_now': '即将开始',
  'home.index.unit.end_now': '即将结束',
  'home.index.unit.time.long': '时长',
  'home.index.meeting.status0': '未开始',
  'home.index.meeting.status1': '进行中',
  'home.index.meeting.status2': '已结束',
  'home.index.meeting.status3': '已取消',
  'home.index.meeting.status4': '已拒绝',
  'home.index.meeting.status5': '待审批',
  'home.index.meeting.status6': '无效',
  'home.index.meeting.status7': '处理中',
  'home.index.meeting.status8': '失败',
  'home.index.meeting.status9': 'EX审批中',
  meeting_other_no_approved: '等待管理员处理',
  'home.index.none': '暂无会议',
  'home.index.none.info': '点击“+”按钮可预约会议',
  'home.history.none': '暂无历史会议',
  'home.message.none': '暂无新消息',
  'home.loading': '加载中...',
  'home.activate': '松开立即刷新',
  'home.deactivate': '下拉可以刷新',
  'home.finish': '完成刷新',
  'home.room': '会议室',
  'home.segment.history': '已结束',
  'home.segment.pending': '待参加',
  'home.segment.mine': '我发布',
  'home.none.meeting': '您没有新的会议哦~',
  'home.none.history': '您没有历史会议哦~',
  'home.none.audit': '暂时没有审核工作哦~',
  'home.text.book': '预订会议室',

  'home.filter.my.meeting': '会议列表',
  'home.filter.appointment': '我预约的',
  'home.filter.attending': '我参会的',
  'home.filter.start.time': '开始时间',
  'home.filter.end.time': '结束时间',
  'home.search.placeholder': '请输入会议主题搜索',

  'meeting.detail.refuse.tip': '是否拒绝当前会议?',
  'meeting.detail.close.tip': '是否结束当前会议？',
  'meeting.detail.confirm.cmma': '视频会议仍在进行，请在会控页面结束视频会议',
  'meeting.detail.cancel.tip': '是否取消当前会议？',
  cancel_loop_meeting: '取消该系列的所有周期会议?',
  modify_loop_meeting: '修改该系列的所有周期会议?',
  refuse_loop_meeting: '拒绝该系列的所有周期会议?',
  adopt_loop_meeting: '通过该系列的所有周期会议?',
  cancel_current_cycle_meeting_confirm: '仅取消本场会议',
  cancel_cycle_meeting_confirm: '取消该系列的所有周期会议',
  edit_current_cycle_meeting_confirm: '仅编辑本场会议',
  edit_cycle_meeting_confirm: '编辑该系列的所有周期会议',

  'home.action.meeting.detail': '会议详情',
  'home.action.meeting.service': '会议服务',
  'home.action.meeting.edit': '编辑会议',
  'home.action.meeting.sign': '签到管理',
  'home.action.meeting.cancel': '取消会议',
  'home.action.meeting.delete': '删除会议',
  'home.action.meeting.delay': '延长会议',
  'home.action.meeting.delay.new': '延长会议(分钟)',
  'home.action.meeting.end': '结束会议',
  'home.action.meeting.control': '会控管理',
  'home.action.meeting.restart': '再次发起',
  'home.action.meeting.reapply': '重新申请',
  'home.action.meeting.adopt': '审批通过',
  'home.action.meeting.refuse': '审批拒绝',
  'home.action.meeting.polling': '调查问卷',
  'home.action.meeting.start': '开启会议',
  'home.action.meeting.join': '加入会议',
  'home.action.cancel': '取消',
  'home.action.meeting.sign.value1': '请输入6位邀请码',
  'home.action.meeting.sign.value2': '请输入邀请码',

  cancel_meeting_reason_placeholder: '请输入取消理由（非必填）',
  refuse_meeting_reason_placeholder: '请输入拒绝理由（必填）',
  'home.action.meeting.seat.manage': '座位安排',
  'home.action.meeting.seat.view': '座位安排',
  'home.action.meeting.net.join': '加入网络会议',
  'home.close.cmma.sure': '是否同时释放视屏会议资源？',
  'meeting.book.video': '视频',
  'meeting.book.Webex': 'Webex',
  'meeting.book.Tecent': '腾讯会议',
  'meeting.book.ZOOM': 'ZOOM',
  'meeting.book.Teams': 'Teams',
  'meeting.book.Feishu': '飞书视频会议',
  'meeting.list.column.duration': '召开时长(分)',
  'home.action.meeting.evaluation': '使用评价',
  'home.action.meeting.evaluation.show': '查看评价',
  'menu.meeting.svc_approval': '服务确认',
  tab_processed: '已处理',
  tab_pending: '待处理',
  btn_accepted: '已同意',
  btn_approved: '已通过',
  btn_rejected: '已拒绝',
  btn_daiding: '暂不确定是否接受',
  btn_accept_new: '已接受',
  svc_approval_error: '该条记录已被其他人员审批，无法再次审批',
  'home.action.svc.approval': '服务审批',
  'home.action.svc.detail': '服务详情',
  btn_reject: '拒绝',
  btn_accept: '同意',
  meeting_status_error: '当前会议已失效',
  status_error: '已失效',
  btn_accept_new_text: '您{text}会议邀请',
  btn_accept_new_text_accept: '您已接受会议邀请',
  btn_accept_new_text_reject: '您已拒绝会议邀请',
  btn_accept_new_text_daiding: '您暂不确定是否接受会议邀请',
  'home.index.acceptText.start': '，距会议开始还有{value}',
  'home.index.acceptText.start1': '距会议开始还有{value}',
  meeting_opened_action_tip: '会议已经开始，不允许编辑',
  'meeting.rowData.meetingAgendaNums': '{value}个议题',
  meeting_poly_cycle_edit_cancel: 'CloudMeet周期会议仅支持编辑和取消该系列未开始的第一场会议!',
  'meeting.poly.control.no.auth': '当前用户无操作权限',
};
