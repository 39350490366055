export default {
  'tcs.title': '隔間占用狀態',
  'tcs.free': '空閑',
  'tcs.free.unit': '個',
  'tcs.build.all': '全部樓棟',
  'tcs.all': '全部樓層',
  'tcs.toilet.show': '顯示廁位室',
  'tcs.build': '樓棟',
  'tcs.confirm': '確定',
  'tcs.cancel': '取消',
  'tcs.status.0': '空閑',
  'tcs.status.1': '佔用',
  'tcs.status.2': '維修',
  'tcs.status.3': '故障',
  'tcs.status.-1': '離線',

  'tcs.alarm.config.alarm.receiver.type.device.short': '故障',
  'tcs.alarm.config.alarm.receiver.type.baojie.short': '人流量大',
  'tcs.alarm.config.alarm.receiver.type.timeout.short': '佔用超時',
  'tcs.alarm.config.alarm.receiver.type.consumable.short': '廁紙余量不足',
  'tcs.alarm.config.alarm.receiver.type.odor.short': '環境不適',
  'tcs.alarm.config.alarm.receiver.type.waterLevel.short': '洗手液余量不足',

  'tcs.alarm.config.alarm.receiver.type.device': '設備故障報警',
  'tcs.alarm.config.alarm.receiver.type.baojie': '人流量告警',
  'tcs.alarm.config.alarm.receiver.type.timeout': '超時佔用告警',
  'tcs.alarm.config.alarm.receiver.type.consumable': '廁紙余量告警',
  'tcs.alarm.config.alarm.receiver.type.odor': '環境告警',
  'tcs.alarm.config.alarm.receiver.type.waterLevel': '洗手液余量告警',

  'tcs.alarm.list.search.time': '告警時間範圍',
  'tcs.alarm.list.search.alarm.type': '告警類型',
  'tcs.alarm.list.search.alarm.status': '告警狀態',
  'tcs.alarm.list.table.alarm.time': '告警時間',
  'tcs.alarm.list.table.alarm.rank': '告警級別',
  'tcs.alarm.list.table.alarm.content': '告警內容',
  'tcs.alarm.list.table.alarm.address': '告警位置',
  'tcs.alarm.list.table.alarm.receiver': '告警接收人',
  'tcs.alarm.list.table.alarm.status': '狀態',
  'tcs.alarm.list.table.alarm.status.0': '未處理',
  'tcs.alarm.list.table.alarm.status.1': '已處理',
  'tcs.alarm.list.table.alarm.status.2': '已取消',
  'tcs.alarm.list.table.alarm.rank.1': '預警',
  'tcs.alarm.list.table.alarm.rank.2': '緊急',
  'tcs.alarm.list.table.alarm.status.tip': '確定已經完成處理此告警？',
  'tcs.alarm.list.nodata': '沒有更多了',
  'tcs.alarm.list.handler.person': '處理人',
  'tcs.alarm.list.handler.time': '處理時間',
};
