import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 立即使用
 * @returns {Promise<questMethodAddToUrl>}
 */
export default async function setImmediate(payload) {
  return request(`/${RESTFUL_PATH.locker}/api/locker/immediatelyUse`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}
