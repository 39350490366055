/**
 * Created by XuQiang on 2017/6/7.
 */

export default {
  'reserve.new': '新增',
  'reserve.visitor.title': '访客',
  'reserve.visitor.name': '访客姓名',
  'reserve.visitor.name.tip': '请输入访客姓名',
  'reserve.visitor.name.tip_': '请输入访客姓名',
  'reserve.visitor.name.error': '姓名不能包含特殊字符',
  'reserve.visitor.company': '访客公司',
  'reserve.visitor.company.tip': '请输入访客公司',
  'reserve.visitor.company.tip_': '请输入访客公司',
  'reserve.visit.telephone': '访客手机',
  'reserve.visit.telephone.tip': '请输入访客手机',
  'reserve.visit.telephone.tip_': '请输入访客手机',
  'reserve.visit.telephone.error': '请输入正确的电话号码',
  'reserve.visit.email': '访客邮箱',
  'reserve.visit.email.tip': '请输入访客邮箱',
  'reserve.visit.email.tip_': '请输入访客邮箱',
  'reserve.visit.email.error': '请输入正确的邮箱',
  'reserve.visit.cause': '访问事由',
  'reserve.visit.cause_': '访问事由',
  'reserve.visit.cause.tip': '请选择访问事由',
  'reserve.visit.cause.tip_': '请选择访问事由',
  'reserve.visit.time': '到访时间',
  'reserve.visit.time.tip': '请选择到访时间',
  'reserve.visit.time.tip_': '请选择到访时间',
  'reserve.visit.address': '接待地点',
  'reserve.visit.address.tip': '请选择接待地点',
  'reserve.visit.address.tip_': '请选择接待地点',
  'reserve.visit.elseAddress': '其它地址',
  'reserve.visit.booking': '预订会议室',
  'reserve.visit.booking.tip1': '请先填写邀请时间',
  'reserve.visit.booking.tip2': '自定义接待地点不支持预订会议室',
  'reserve.visit.booking.tip3': '请先选择接待地点',
  'reserve.submit': '提交',
  'reserve.cancel': '取消邀请',
  'reserve.delete': '删除',
  'reserve.resend': '补发邀请函',
  'reserve.print': '打印并签到',
  'reserve.delay': '变更邀请',
  'reserve.again': '再次邀请',
  'reserve.sign.out': '签出',
  'reserve.time': '邀请时间',
  'reserve.time_': '邀请时间：',
  'reserve.view': '邀请详情',
  'reserve.sign.in.time': '签到时间：',
  'reserve.sign.in.time_': '签到时间',
  'reserve.sign.out.time': '签出时间：',
  'reserve.sign.out.time_': '签出时间',
  'reserve.sign.accompanying_': '随行人员：',
  'reserve.visitor.host': '拜访对象',
  'reserve.visitor.host.placeholder': '请选择拜访对象',
  'reserve.visitor.template': '模板下载',
  'reserve.visitor.import': '批量导入',
  'reserve.visitor.export': '导出',
  'reserve.visitor.delete': '删除',
  'reserve.map.place': '请选择正确的地点',
  'reserve.emailOrPhone': '访客邮箱与访客手机请至少填写一个',
  'reserve.intl.phone': '国际号码请加国际区号，例如：+852',
  'visit.reserve.type': '访客来源',
  'visit.reserve.type.0': '主动邀约',
  'visit.reserve.type.1': '自助预约',
  'visit.reserve.type.2': '临时访客',
  'visit.reserve.type.3': '团体邀请',
  'reserve.visit.type': '访客类型',
  'reserve.visit.type_': '请选择访客类型',
  'reserve.visit.type_0': '普通访客',
  'reserve.visit.type_1': 'VIP访客',
  'reserve.visit.face': '人脸照片（用于刷脸快速入场）',
  'reserve.visit.time.exceed': '到访时间应超过当前时间5分钟',
  'reserve.tableFooter.tip1': '您还没有邀请记录呢，去',
  'reserve.tableFooter.tip2': '创建',
  'reserve.tableFooter.tip3': '一个吧，点击红色字体直接打开新建邀请界面',
  'reserve.startTime': '开始时间',
  'reserve.startTime.tip1': '开始时间不能为空',
  'reserve.startTime.tip2': '会议开始时间应在邀请时间之后',
  'reserve.endTime': '结束时间',
  'reserve.endTime.tip1': '结束时间不能为空',
  'reserve.endTime.tip2': '会议结束时间应超过开始时间15分钟',
  'reserve.meetingRoom': '选择会议室',
  'reserve.meetingRoom.tip': '会议室不能为空',
  'reserve.meetingDuration': '会议时长',
  'reserve.meetingDuration.tip': '会议时长不能为空',
  'reserve.ass': '添加访客',
  'reserve.del.record': '删除所选记录',
  'reserve.no.invitee': '请填写访客信息（至少一位）',
  'reserve.invitee.tip': '请先确认当前正在编辑的访客信息',
  'reserve.15': '15 分钟',
  'reserve.30': '30 分钟',
  'reserve.45': '45 分钟',
  'reserve.60': '1 小时',
  'reserve.90': '1.5 小时',
  'reserve.120': '2 小时',
  'reserve.180': '3 小时',
  'reserve.240': '4 小时',
  'reserve.300': '5 小时',
  'reserve.360': '6 小时',
  'reserve.420': '7 小时',
  'reserve.480': '8 小时',
  'reserve.540': '9 小时',
  'reserve.600': '10 小时',
  'reserve.660': '11 小时',
  'reserve.720': '12 小时',
  'reserve.change.tip': '变更邀请不支持添加新访客',
  'reserve.change.tip2': '变更邀请不支持删除访客',
  'reserve.change.tip3': '变更邀请不支持编辑访客信息',
  'reserve.visit.time.than.meeting.time': '会议开始时间不得早于到访时间',
  'reserve.visit.time.than.now.time': '会议开始时间不得早于当前时间',
  'reserve.visit.time.than.now.time5': '会议开始时间应在当前时间5分钟之后',

  // 防疫码
  'reserve.confirm.epidemic.code': '防疫码',
  'reserve.visitor.card.No': '证件号码',

  'reserve.visit.car.number': '车牌号',
  'reserve.visit.car.number.tip_': '请输入访客车牌号',
  'reserve.visit.car.number.error': '请输入正确的车牌号',

  'reserve.leave.time.than.visit.time': '离开时间应超过到访时间15分钟',
  'reserve.visit.time.end': '离开时间',
  'reserve.visit.end.time.than.meeting.time': '会议开始时间不得晚于离开时间',
  'reserve.meeting.time.than.meeting.end.time': '会议结束时间应晚于会议开始时间15分钟',
  'reserve.visit.tip1': '离开时间应超过到访时间15分钟',

  'reserve.leave.time': '请选择离开时间',

  'reserve.visit.private.office': '私有办公地',
  'reserve.visit.share.office': '共享办公地',

  'reserve.leave.time.than.visit.time.noAcross': '非跨天邀请，请检查邀请时间',
  'reserve.visitor.name.add': '添加访客',
  'reserve.leave.time.than.meeting.time.noAcross': '非跨天邀请，请检查会议时间',

  'reserve.visit.meeting.name': '会议室',
  'reserve.visit.meeting.name.choose.tip': '请重新选择会议室',
  'reserve.visit.meeting.room.none': '暂无可用会议室',
  'reserve.visit.meeting.name.choose.place': '请选择会议室',
  'reserve.visit.meeting.theme': '会议主题',
  'reserve.visit.meeting.theme.placeholder': '{realName}的会议',

  'reserve.visit.now.after.meeting.start': '会议进入签到时间或已经开始，不可变更邀请',
  'reserve.visit.now.after.meeting.start.cancel': '会议进入签到时间或已经开始，不可取消邀请',

  'reserve.detail.epit.title.healthCode': '健康码',
  'reserve.detail.epit.title.tourCode': '行程卡',
  'reserve.detail.epit.title.vaccination': '疫苗证明',
  'reserve.detail.epit.title.nucleic': '核酸报告',
  'reserve.visit.across.max.time': '跨天邀请持续时间最大为{day}天',
  'reserve.visit.visitDayNum.vst.time': '到访时间不能超过当前日期{day}天',
  'reserve.visit.visitDayNum.smt.time': '会议开始时间不能超过当前日期{day}天',

  'reserve.visit.id.card': '身份证号',
  'reserve.visit.id.card.tip_': '请输入访客身份证号',
  'reserve.visit.id.card.error': '请输入正确的身份证号',
  'reserve.visit.id.card.error.validate': '该访客身份证号不正确',
  'reserve.visit.meeting.need.approval.tip': '{tipStr}需要审批',
  'reserve.visit.delete.visitor.confirm.text': '确定要删除该访客信息吗?',
  'reserve.visit.work.day.visit.time.error': '请选择正确的时间，工作日时间：{time}',
  'reserve.visit.work.day.visit.date.error': '节假日不允许预约，请选择工作日。',
  'visit.meeting.booking.normal_day_meeting_limit': '只允许预订时长为{time}分钟的会议',
  'visit.meeting.booking.step.times.limit.tip': '只允许预约{days}天内的会议',
  'visit.reserve.visitor.edit.send.notice': '是否需要向访客发送更新通知',
  'visit.reserve.visitor.edit.send.notice.yes': '是',
  'visit.reserve.visitor.edit.send.notice.no': '否',
  'visit.meeting.booking.agenda.visit.tip':
    '本次邀请预订的会议含有议程，不支持在访客端修改邀约时间',
  'visit.meeting.booking.agenda.meeting.tip': '该会议添加了议程，不支持在访客端修改时间',
  'visit.reserve.visit.after.now.add.five':
    '预订的会议含有议程，且当前时间距邀约时间不足5分钟，不支持变更。',
  'visit.reserve.visit.telephone.error': '请输入正确的手机号码',
  'visit.reserve.visit.email.error': '请输入正确的邮箱',
  'visit.reserve.visit.telephone.or.email.error': '请输入手机或邮箱',
  'visit.reserve.across.day.visit.time.error': '邀约时间的开始日期和结束日期不能为同一天',
  'visit.reserve.across.day.meeting.time.error': '跨天会议的开始日期和结束日期不能为同一天',
  'visit.visitor.information.count.tip1': '当前接待地点{timeFormat}还可邀请{num}位访客',
  'visit.visitor.information.count.tip2': '访客数量已超出当前接待地点{timeFormat}的可邀请数量',
  'visit.visitor.information.count.tip3': '被访者今日可接待的访客人数已达上限',
  'visit.visitor.information.count.tip4': '访客数量已超出当前接待地点的可邀请数量',
  'reserve.fill': '访客补填',
  'reserve.more.operations': '更多操作',
  'visit.visitor.fill.meeting.tip':
    '如果您在本次邀请中使用过“访客补填”功能，打开“预订会议室”后，之前分享给访客的链接将会失效。如果您未使用“访客补填”功能，请忽略本次提示',
  'reserve.visit.visitor.check.out.title': '签出成功',
  'reserve.visit.visitor.check.out.text':
    '请提醒该访客，{value}分钟内取出存放在储物柜中的物品（如已取出，请忽略）',
  'reserve.visit.visitor.check.out.ok': '知道了',

  'reserve.visit.map.type.label': '地图类型',
  'reserve.visit.map.amap': '高德地图',
  'reserve.visit.map.google': '谷歌地图',
  'reserve.visit.map.mapValue.label': '标记接待地导航位置',
  'reserve.visit.map.mapValue.place': '请输入并选择导航地址',
  'reserve.visit.map.more.address.label': '详细地址',
  'reserve.visit.map.more.address.place': '例如：XXX省XX市XX路XX号XX大厦XX层',
  'reserve.visit.map.more.location.label': '接待地点名称',
  'reserve.visit.map.more.location.place': '请输入接待地点名称',
  'reserve.visit.map.position.label': '标记接待地导航位置',
  'reserve.visit.map.position.place': '请输入导航位置',
  'reserve.visit.map.position.error': '请输入正确的导航位置',
  'reserve.visit.map.position.error2': '请输入接待地点名称和导航位置',
  'reserve.visit.map.position.tip':
    '请在谷歌地图上找到接待地所在位置，点击该位置的分享按钮，然后复制分享链接，填写在这里',
  'visitor.form.check.information.required': '{value}的必填信息未填写，请填写后再提交。',
  'reserve.visit.invitationCode.full.btn': '确认并继续',
};
