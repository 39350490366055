export default {
  'locker.immediate.hours': '小時',
  'locker.immediate.submit': '立即使用',
  'locker.immediate.station': '儲物櫃',
  'locker.immediate.address': '區域',
  'locker.immediate.time': '時間',
  'locker.immediate.ok': '確認',
  'locker.immediate.cancel': '取消',
  'locker.immediate.select.time': '選擇時間',
  'locker.immediate.submit.success': '恭喜您，操作成功！',
  403000: '系統異常',
  403003: '參數錯誤',
  403013: '儲物櫃不存在',
  403014: '儲物櫃未綁定',
  403032: '儲物櫃已被佔用',
  403033: '不支持預訂兩個或者兩個以上儲物櫃！',
  403034: '系統工作日時間未設置',
};
