import {
  query as queryUsers,
  queryCurrent,
  getCurrentUserVo,
  getUserInfoServe,
  queryCurrentUserInfo,
  updatePassword2,
  getSpace,
  getTimeZoneTenantList,
  getTenantUserVo,
  getUserFaceMenuByProductCode,
  getUserProductCodes,
  updateAvatarImage, updateUserInfo, updatedUserAvatar
} from '@/services/user';

import {ACCESS_TOKEN, ROUTE_ACCESS_LIST} from '@/utils/constant';
import moment from 'moment-timezone';
import { Toast } from 'antd-mobile-v2';
import { formatMessage, setLocale } from 'umi';
import { history } from 'umi';
import { timeZoneDateMake, timeZoneI18nMake } from '@/utils/timeZoneUtils';

const userInfoToLoginInfo = (token, userInfo) => {
  return {
    access_token: token,
    dept_id: userInfo.deptId,
    expires_in: null,
    license: null,
    refresh_token: null,
    scope: null,
    tenant_domain: null,
    tenant_id: userInfo.tenantId,
    token_type: null,
    user_id: userInfo.userId,
    username: userInfo.username,
  };
};

export default {
  namespace: 'user',

  state: {
    list: [],
    currentUser: {},
    sysTenant: {},
    modalUserInfo: {},
    roles: [],
    roleCode: [],
    userInform: {},
    password: {
      oldPassowrd: '',
      newPassowrd1: '',
      newPassowrd2: '',
    },
    curLanguage: '',
    curTimezone: '',
    showIndexState: '0',
    curDefaultZone: '',
    curDefaultZoneName: '',
  },

  effects: {
    *updatePassword({ payload }, { put, call }) {
      const data = yield call(updatePassword2, { payload });
      if (!data.code) {
        Toast.success(formatMessage({ id: 'app.setPassword.operate.success' }));
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            password: {
              oldPassowrd: '',
              newPassowrd1: '',
              newPassowrd2: '',
            },
          },
        });
        /* 退出 */
        sessionStorage.clear();
        localStorage.clear();
        // history.push({
        //   pathname: `${ROUTE_PATH}/user/login`,
        // });
        yield put({
          type: 'login/logout',
        });
      } else {
        Toast.fail(formatMessage({ id: 'app.setPassword.operate.fail' }));
      }
    },
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);

      yield put({
        type: 'save',
        payload: response,
      });
    },
    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryCurrent);
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },
    *getTimeZoneTenantList(_, { call }) {
      const response = yield call(getTimeZoneTenantList);
      if (response.code === 0) {
        window.TIMEZONE = timeZoneDateMake(response.data);
        window.I18N_TIMEZONE = timeZoneI18nMake(response.data);
      } else {
        window.TIMEZONE = [];
        Toast.fail(formatMessage({id: response.msg}));
      }
    },
    *getUserInfo(_, { call, put }) {
      yield put({
        type: 'getTimeZoneTenantList',
      });
      const response = yield call(getUserInfoServe);
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          userInform: response.data,
        },
      });
    },
    *fetchCurrentUserInfo({ payload: { saveInfo = false, redirect = null } }, { call, put }) {
      yield put({
        type: 'getTimeZoneTenantList',
      });
      if (sessionStorage.getItem('from') === 'cloud') {
        yield put({
          type: 'getUserProductCodes',
        });
      }
      const response = yield call(queryCurrentUserInfo);
      if (response.code === 0) {
        const { sysUser, sysTenant, permissions, roles, roleCode, guidance } = response.data;
        if (!sysUser.userId) {
          Toast.fail(formatMessage({ id: 'app.currentUser.error2' }));
        }
        yield put({
          type: 'save',
          payload: {
            currentUser: sysUser,
            permissions,
            sysTenant,
            roles,
            roleCode,
            curDefaultZone: sysUser.defaultZone || '',
          },
        });

        sessionStorage.setItem('GUIDANCE', guidance);

        if (sysUser && sysUser.lang) {
          let userLang = sysUser.lang;
          let userLangForMoment = sysUser.lang;
          if (userLang.indexOf('en') !== -1) {
            userLang = 'en-US';
            userLangForMoment = 'en';
          }
          if (userLang.indexOf('TW') !== -1) {
            userLang = 'zh-TW';
            userLangForMoment = 'zh-tw';
          }
          if (userLang.indexOf('CN') !== -1) {
            userLang = 'zh-CN';
            userLangForMoment = 'zh-cn';
          }
          yield put({
            type: 'save',
            payload: { curLanguage: userLang },
          });
          if (!saveInfo) {
            // 判断当前访问是否是白名单页面，白名单页面为未认证页面，不进行刷新
            const result = ROUTE_ACCESS_LIST.find((element) => {
              return window.location.pathname?.includes(element)
            });
            if(!result){
              setLocale(userLang);
            }
          }
          moment.locale(userLangForMoment);
        }

        let curTimeZone = moment.tz.guess(1);

        if (sysUser && sysUser.timeZone) {
          curTimeZone = sysUser.timeZone;
        }
        // 存储curTimeZone，公共方法用
        sessionStorage.setItem('curTimeZone', curTimeZone);
        moment.tz.setDefault(curTimeZone);
        yield put({
          type: 'save',
          payload: { curTimezone: curTimeZone },
        });

        // 如果saveInfo为true，把sysUser信息还原为登录信息，其中部分内容缺失，需要新接口完善
        if (saveInfo) {
          if(sessionStorage.getItem('entryTeamsType') !== 'teamsSSO'){
            yield put({
              type: 'login/changeLoginStatus',
              payload: {
                info: userInfoToLoginInfo(sessionStorage.getItem(ACCESS_TOKEN), sysUser),
                status: 'login',
              },
            });
          }
          // 获取菜单信息，maybe 非必须
          yield put({
            type: 'menu/getMenuData',
            payload: {
              refresh: false,
              redirect,
            },
          });
        }
      } else {
        Toast.fail(formatMessage({ id: 'app.currentUser.error' }), 15);
      }
    },
    *getCurrentUserVo(_, { call, put }) {
      const response = yield call(getCurrentUserVo);
      if (response.code === 0) {
        yield put({
          type: 'save',
          payload: {
            modalUserInfo: response.data,
          },
        });
      }
    },
    *getSpace(_, { call, put, select }) {
      const { curDefaultZone } = yield select((state) => state.user);
      if (curDefaultZone) {
        const result = yield call(getSpace, curDefaultZone);
        if (result.code === 0) {
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              curDefaultZoneName: (result.data && result.data.name) || '',
            },
          });
        } else {
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              curDefaultZone: '',
            },
          });
          Toast.fail(result.msg);
        }
      }
    },
    *getTenantUserVo({ payload: { success } }, { call, put }) {
      const result = yield call(getTenantUserVo);
      if (result.code === 0) {
        yield put({
          type: 'save',
          payload: {
            tenantUserInfo: result.data,
          },
        });
        if (success) {
          success();
        }
      } else {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
      }
    },
    *getUserFaceMenuByProductCode({ payload }, { call }) {
      const result = yield call(getUserFaceMenuByProductCode, {...payload});
      if (result.code !== 0) {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
      }

      return result.code === 0 ? result.data : false;
    },
    *getUserProductCodes(_, { call }) {
      const result = yield call(getUserProductCodes);
      if (result.code === 0) {
        sessionStorage.setItem('omsProduct', result.data);
      } else {
        message.error(formatMessage({ id: result.msg }));
      }
    },
    *updateAvatarImage({ payload }, { call, put }) {
      const result = yield call(updateAvatarImage, {...payload});
      if (result.code !== 0) {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
      } else {
        Toast.success(formatMessage({id: 'common.action.success'}));
        yield put({type: 'updatedUserAvatar', payload: { avatar: result.data?.fileUrl }});
      }
    },
    *updatedUserAvatar({ payload }, { call, put }) {
      const result = yield call(updatedUserAvatar, {...payload});
      if (result.code !== 0) {
        Toast.fail(formatMessage({ id: `${result.msg}` }));
      } else {
        yield put({type: 'fetchCurrentUserInfo', payload: {}});
      }
    },
  },

  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};

