/**
 * Created by admin on 2018/1/16.
 */
import { matchPath ,getLocale } from 'umi';
import { formatMessage } from 'umi';
import moment from 'moment-timezone';
import { DATE_FORMAT, TIME_FORMAT , ENGLISH} from '@/pages/Meeting/utils/constant';
import { Toast } from 'antd-mobile-v2';
import { cloneDeep } from 'lodash';
import { MENU_PATH, RES_STATUS, ROOM_TYPES } from '../utils/constant';
import * as zoneService from '../services/zoneService';

const organizeZonePickerData = (zones) => {
  if (!zones || zones.length === 0) {
    return [];
  }
  const zonePickerData = [];
  zones.forEach((z) => {
    const zData = {};
    zData.id = Number(z.id);
    zData.name = z.name;
    zData.parentId = z.parentId;
    zData.path = z.path;
    zData.pathName = z.pathName;
    zData.children = organizeZonePickerData(z.children);
    zonePickerData.push(zData);
  });
  return zonePickerData;
};
const getZoneDepth = (zones, depth = 0) => {
  if (!zones || zones.length === 0) {
    return depth;
  }
  let newDepth = depth;
  zones.forEach((z) => {
    const newLength = z.path.split('-').filter((item) => {
      return item;
    }).length;
    newDepth = newLength > newDepth ? newLength : newDepth;
    newDepth = getZoneDepth(z.children, newDepth);
  });
  return newDepth;
};

const getZoneMap = (zones, zoneMap = {}) => {
  if (!zones || zones.length === 0) {
    return zoneMap;
  }
  let newZoneMap = cloneDeep(zoneMap);
  zones.forEach((z) => {
    newZoneMap[z.id] = z;
    newZoneMap = getZoneMap(z.children, newZoneMap);
  });
  return newZoneMap;
};

const pageFors = {
  subscribe: 1,
  template: 2,
};

export default {
  namespace: 'zones',
  state: {
    data: [],
    zoneDepth: 3,
    zonePickerData: [],
    allRooms: [],
    allRoomsTemp: [],
    pageFor: pageFors.subscribe,
    addressChildrenList: [],
    addressChoseList: [],
    spaceTree: [],
    shareSpaceTree: [],
    //    showZoneTitle: formatMessage({ id: 'subscribe.select.region' }),
    showZoneTitle: '全部区域',
    shareZoneValues: [],
    defaultZoneId: -1,
    zoneMap: {},
    allZoneMap: {},
    allRoomsByNetWork: [],
    bookType: 0,
    selectedIds: '',
    meetingId: 0,
    tenantId: null,
    roomFilter: {},
    zonePath: '',
    name: '',
    searchFilter: false,
    popupFilter: false,
    isLoading: false
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *setSelectZone({ commonZoneId }, { put }) {
      yield put({ type: 'update', payload: { defaultZoneId: commonZoneId } });
    },
    *loadAllRooms({ resolve, reject }, { select, put, call }) {
      yield put({
        type: 'update',
        payload: {
          isLoading: true,
        },
      });
      const { bookType,
        //  meetingId,
        roomFilter = {},
        name = '',
        zonePath = '',
        searchFilter = false,
        tenantId } = yield select((state) => state.zones);
      const { tenantId: uTenantId = null } = yield select((state) => state.user.currentUser);
      const {
        // bookType,
        type,
        meetingId,
        timeZone,
        startNow,
        duration,
        startTime,
        endTime,
        date,
        startTimeStamp: sTamp,
        endTimeStamp: eTamp,
      } = yield select((state) => state.subscribe);
      let param = {
        tenantId: tenantId || uTenantId,
        meetingId: meetingId || 0,
        capacity: Number(bookType) === 1 ? 6 : -1,
        excludeCapacities: `${ROOM_TYPES.webexRoom};${ROOM_TYPES.zoomRoom}`,
        ...roomFilter,
        zonePath,
        name
      };
      const d = moment(eTamp).diff(moment(sTamp), 'hours');
      const isLong = d >= 24;
      let startTimeStamp = isLong
        ? sTamp
        : moment(
          `${date.replace(/\//g, '-')} ${startTime}`,
          `${DATE_FORMAT} ${TIME_FORMAT}`,
        ).valueOf();
      if (typeof startTime === 'object') {
        startTimeStamp = startTime.valueOf();
      }
      let endTimeStamp = isLong
        ? eTamp
        : moment(
          `${date.replace(/\//g, '-')} ${endTime}`,
          `${DATE_FORMAT} ${TIME_FORMAT}`,
        ).valueOf();
      if (typeof endTime === 'object') {
        endTimeStamp = endTime.valueOf();
      }
      if (endTime === '00:00') {
        // 如果结束时间是00:00，说明是次日的零时
        endTimeStamp = moment(date.replace(/\//g, '-'), DATE_FORMAT).add(1, 'days').valueOf();
      }
      let startTimeStr = moment(startTimeStamp).format('YYYY-MM-DD HH:mm');
      let endTimeStr = moment(endTimeStamp).format('YYYY-MM-DD HH:mm');
      if (startNow) {
        startTimeStr = moment().format('YYYY-MM-DD HH:mm');
        endTimeStr = moment()
          .add(duration || 15, 'minutes')
          .format('YYYY-MM-DD HH:mm');
      }
      if (((startTime && endTime) || startNow) && type < 1) {
        param = {
          ...param,
          startTime: timeZone
            ? moment.tz(startTimeStr, timeZone).valueOf()
            : moment(startTimeStr).valueOf(),
          endTime: timeZone
            ? moment.tz(endTimeStr, timeZone).valueOf()
            : moment(endTimeStr).valueOf(),
        };
      }
      const { data, code, msg } = yield call(zoneService.getAllRooms, param);
      // let param2 = {
      //   tenantId,
      //   meetingId: meetingId || 0,
      //   capacity: -1,
      //   excludeCapacities: '',
      // };
      // const result = yield call(zoneService.getAllRoomsByNetWork, param2);
      // if (result.code === RES_STATUS.success) {
      //   yield put({
      //     type: 'update',
      //     payload: {
      //       allRoomsByNetWork: result.data,
      //     },
      //   });
      // } else {
      //   Toast.error(result.msg ? formatMessage({ id: result.msg }) : '');
      // }

      // const { data, code, msg } = yield call(zoneService.getAllRooms, { tenantId });
      if (code === RES_STATUS.success) {
        yield put({
          type: 'update',
          payload: {
            allRooms: data,
          },
        });
        // 查询所有会议室时存储数据
        if(!zonePath && !name){
          console.log(zonePath,data,'zonePath')
          yield put({
            type: 'update',
            payload: {
              allRoomsTemp: data,
            },
          });
        }
        if (resolve) {
          resolve(data);
        }
      } else {
        Toast.error(msg ? formatMessage({ id: msg }) : '');
        if (reject) {
          reject(msg);
        }
      }
      yield put({
        type: 'update',
        payload: {
          isLoading: false,
        },
      });
    },
    *load(_, { put, call }) {
      const { data, code, msg } = yield call(zoneService.getAllZones);
      const { data: d } = yield call(zoneService.getShareZoneTree);
      const allRooms = [];
      data.forEach((room) => {
        allRooms.push(room);
      });
      if (d && d.length > 0) {
        d.forEach((room) => {
          allRooms.push(room);
        });
      }
      if (code === RES_STATUS.success) {
        yield put({
          type: 'update',
          payload: {
            data,
            spaceTree: data,
            shareSpaceTree: organizeZonePickerData(d),
            zoneDepth: getZoneDepth(data, 0),
            zonePickerData: organizeZonePickerData(data),
            zoneMap: getZoneMap(data, {}),
            allZoneMap: getZoneMap(allRooms, {}),
          },
        });
      } else {
        Toast.error(msg ? formatMessage({ id: msg }) : '');
      }
      return data;
    },
    *reload({ payload: { pageFor } }, { put, select, take }) {
      const { zones } = yield select((state) => state);
      zones.pageFor = pageFor;
      yield put({ type: 'update', payload: { zones} });
      if (zones.data.length === 0) {
        yield put({ type: 'load' });
        yield take('load/@@end');
      }
    },
    *setZoneId(_, { put, select ,call }) {
      const { currentUser } = yield select((state) => state.user);
      const { allZoneMap, shareSpaceTree } = yield select((state) => state.zones);
      const { code, data, msg } = yield call(zoneService.getUserZone);
      let zoneId = currentUser.defaultZone
      if (code === 0 ) {
        if(data?.zoneId||data?.shareZoneId){
            zoneId = Number(data.zoneId)||Number(data.shareZoneId)
        }
        if(data?.shareZoneId){
          yield put({ type: 'subscribe/update', payload: {isShareZoneId:true} });
        }
      } else {
        Toast.error(formatMessage({ id: msg }));
       
      }
    
      if(zoneId){
        const zone = allZoneMap[zoneId];
        // const name = getLocale() === ENGLISH ? zone.nameEn || zone.name : zone.name;
        const pathArray =zone?.pathName?.split(',').filter((item) => !!item);
        const title = pathArray?.length > 1 ? pathArray?.slice(1)?.reverse()?.join('/') : zone?.pathName;

        yield put({ type: 'update', payload: {showZoneTitle: title } });
      
      }
      // 如果共享区域关闭，并且之前存的是共享区域id则将共享区域id清空
      if(!shareSpaceTree.length && data?.shareZoneId){
        yield put({ type: 'update', payload: { defaultZoneId: -1,zonePath: '',showZoneTitle: formatMessage({ id: 'subscribe.default.region.new' })} });
      }else{
        yield put({ type: 'update', payload: { defaultZoneId: zoneId,zonePath: allZoneMap[zoneId]?.path,} });
      }
    },

  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.roomList)) {
          const { query } = history.location;
          const {
            pageFor,
            bookType = 0,
            selectedIds = '',
            meetingId = 0,
            tenantId = 0,
          } = query || {};
          dispatch({
            type: 'update',
            payload: {
              bookType: Number(bookType),
              selectedIds,
              meetingId: Number(meetingId),
              tenantId: Number(tenantId),
            },
          });
          //     dispatch({ type: 'loadAllRooms' });
          dispatch({ type: 'reload', payload: { pageFor } });
          dispatch({ type: 'subscribe/getDevicesByType' });
        } else if (matchPath(pathname, MENU_PATH.meetingCtrl)) {
          dispatch({ type: 'loadAllRooms' });
          dispatch({ type: 'reload', payload: { pageFor: 2 } });
        } else if (matchPath(pathname, MENU_PATH.subscribeDetail)) {
          dispatch({ type: 'loadAllRooms' });
          dispatch({ type: 'getAllRoomsByNetWork' });

        } else if (matchPath(pathname, MENU_PATH.subscribe)) {
          dispatch({
            type: 'update',
            payload: { showZoneTitle: formatMessage({ id: 'subscribe.default.region.new' }) },
          });
        }
      });
    },
  },
};
