export default {
  'register.register': 'Register',
  'register.bind': 'Bind',
  'register.bind.tip': 'Please bind your account in Virsical Cloud',
  'register.verify.phone': 'Phone verify',
  'register.verify.mail': 'E-mail verify',
  'register.password.rules':
    'The password must contain 8-16 digits and letters, and can contain the following special characters:.!@#$%^&*-_）',
  'register.protocol.tip1': "I have read and agree with Virsical Cloud's",
  'register.protocol.tip2': '《Terms of Service》',
  'register.protocol.tip3': 'and',
  'register.protocol.tip4': '《Privacy Policy》',
  'register.verifycode.get': 'Verify Code',
  'register.verifycode.reget': 'Reacquire',
  'register.verifycode.expired': 'Verification code expired',
  'register.verifycode.nouser': 'No such user',
  'register.verifycode.fail': 'Failed to send',
  'register.second': 's',
  'register.ok': 'Confirm',
  'register.cancel': 'Cancel',
  'register.bind.success': 'Bind successfully',
  'register.register.success': 'Registered successfully',

  'register.tenantName.tip': 'Please enter company name',
  'register.contactRealName.tip': 'Please enter the contact name',
  'register.phone.tip': 'Please enter the number',
  'register.contactMail.tip': 'Please enter the contact email',
  'register.verifyType.tip': 'Please select verify type',
  'register.captcha.tip': 'Please enter verify code',
  'register.password.tip': 'Please enter the password',
  'register.pactCheck.tip': 'Please read the Terms of Service and Privacy Policy',
  'register.bindAccout.tip': 'Please input Username',
  'register.bindPwd.tip': 'Please enter the password',
  'info.form.phone.placeholder': 'Please enter your mobile number',

  'register.tenantName.error': 'Company length must not exceed 50 characters',
  'register.contactRealName.error': 'The length of the name is 2-20 characters',
  'register.phone.error': 'Please enter a valid phone number',
  'register.contactMail.error': 'Please enter a valid email address',
  'register.captcha.error': 'Please enter correct verify code',
  'register.password.error': 'Incorrect password format',
  'register.bindAccout.error': 'The email/phone number format is incorrect',

  'register.title': 'Is your business registered?',
  'register.title.tip':
    'If your company has not been registered with Virsical Cloud, please create a new company',
  'register.company.name': 'Company Name',
  'register.mail': 'Mail',
  'register.mobile': 'Mobile',
  'register.accout.exist': 'Already have an account? ',
  'register.realname': 'Name',

  'register.try.use': 'Sign up for a 30-day free trial now',
  'register.try.more': 'More Virsical Cloud Premium Edition',
  'register.hotline': 'Call the consultation hotline now:400-685-3160',
  'register.success.tip1': 'When the registration is completed, the system will be activated ',
  'register.success.tip2':
    ' service, you will have administrator rights, please maintain it on the management side first ',
  'register.success.tip3': ' basic data, Management address: ',

  'cloud.productcode.smt': 'Meeting',
  'cloud.productcode.smt.room': 'Meeting room',
  'cloud.productcode.sws': 'Workspace',

  'cloud.auth.fail.tip': 'An unknown error occurred in the system, please try again later',

  'cloud.register.bind': 'Back',
  'cloud.gobind': 'Go to bind',

  'cloud.safe.verify.code': 'Get verify code',
  'cloud.safe.verify.code.tip': 'Please enter verify code',
  'order.invoice.emil.tip': 'Please enter email',
  'register.verification.img': 'Please enter the graphic verification code',

  'login.security.code.tip': 'Enter the security code',
  'login.security.step': 'Fetch Step',
  'login.security.step1':
    '1. Open the 2FA app on your device (phone, tablet, computer, etc.), recommended',
  'login.security.step2': '2. Find the account you scanned and tied earlier',
  'login.security.step3':
    '3. Get the displayed password code and fill in the input box below',

  'cloud.country.areacode.china': '(China)',
  'cloud.country.areacode.hk': '(HongKong)',
  'cloud.country.areacode.korea': '(Korea)',
  'cloud.country.areacode.singapore': '(Singapore)',
  'cloud.country.areacode.thailand': '(Thailand)',
  'cloud.country.areacode.usa': '(United States)',
};
