export default {
  'year.str': '{year}年',

  'subscribe.select.region': '选择区域',
  'subscribe.default.region': '请选择区域',
  'subscribe.default.room': '暂无可供选择的会议室哦~',
  'subscribe.room.noDev': '无设备',
  'subscribe.room.1to3': '1-3人',
  'subscribe.room.4to9': '4-9人',
  'subscribe.room.over10': '10人以上',
  'subscribe.room.filter': '筛选',
  'subscribe.room.select': '共{num}个',
  'subscribe.send.success': '发送成功',
  'subscribe.free.success': '免费账号，只能预约40分钟内的zoom会议。',
  'subscribe.booking.failed.time': '单日会议会议时长大于配置最大时长!',

  'subscribe.filter.attendance': '会议室容纳人数',
  'subscribe.filter.number': '{num}人',
  'subscribe.filter.devices': '设备配置',
  'subscribe.filter.devices1': '（多选）',
  'subscribe.filter.inputNum': '请输入会议室容纳人数',
  'subscribe.filter.time': '时间',
  'subscribe.filter.time.plac': '请选择',

  'subscribe.room.need.audit': '需审核',
  'smt.please.select': '请选择',
  'smt.please.select.area': '请选择',
  'smt.node.no.select': '此位置不可以选择',
  'subscribe.default.region1': '请选择{num}级区域',
  'smt.please.select.none': '暂无区域可供选择!',
  'subscribe.default.region.new_tab1': '本地区域',
  'subscribe.default.region.new_tab2': '共享区域',
  'subscribe.default.region.new': '全部区域',
  'subscribe.default.region.select.option.no': '暂不选择',
  'subscribe.no.teams.room.data': '当前无可预订会议室，',
  'subscribe.no.teams.room.data1': '请联系管理员添加会议室。',
  'subscribe.no.teams.room.data.tip': '如何添加会议室，帮助地址：https://cloud.virsical.cn/help',
  'subscribe.time.hoilday.ok': '该日已被设置为节假日，不能选择!',

  'smt.please.select.area.new': '请选择',
};
