/**
 * Created by admin on 2017/11/3.
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export const getRoomSeatList = ({ roomIds }) => {
  return request(`/${RESTFUL_PATH.meeting}/room-template/getRoomSeatList?roomIds=${roomIds}`, {
    method: 'GET',
  });
};

export const saveMeetingUserSeat = payload => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/meetingUserSeat`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};
