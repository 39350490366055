import { Toast } from 'antd-mobile-v2';
import { matchPath } from 'umi';
import { MENU_PATH } from '@/pages/Meeting/utils/constant';
import { getRoomDetailFun, getTemp } from './service';

export default {
  namespace: 'roomDetail',
  state: {
    roomId: 0,
    data: {},
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *reload({ roomId }, { select, call, put }) {
      const { roomId: rid } = yield select((state) => state.roomDetail);
      const { messages } = yield select((state) => state.i18n);
      const { data, code, msg = '' } = yield call(getRoomDetailFun, { roomId: roomId || rid });
      if (code === 0) {
        yield put({ type: 'update', payload: { data } });
      } else {
        Toast.fail(messages[msg]);
      }
    },
    *getTemp({ roomId }, { call }) {
      const { code, data } = yield call(getTemp, roomId);
      if (code === 0) {
        const { controlTemplate } = data[0] || {};
        return controlTemplate;
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
        if (matchPath(pathname, MENU_PATH.roomDetail)) {
          const { roomId } = query;
          dispatch({ type: 'update', payload: { roomId } });
          dispatch({ type: 'reload', roomId });
        }
      });
    },
  },
};
