import { Toast } from 'antd-mobile-v2';
import { ROUTE_PATH } from '@/utils/constant';
import * as service from './service';
import { formatMessage } from 'umi';
import { getUrlParam } from '@/utils/utils';
import moment from 'moment';

export default {
  namespace: 'tcs',
  state: {
    data: [],
    config: {},
    showRoom: false,
    buildingId: '',
    floorId: 'all',
    errMsg: '',
    buildingList: [],
    spaceTree: [],
    floorList: [],
    allCategory: [],
    list: [],
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getData({ payload }, { call, put }) {
      const result = yield call(service.getData, payload);
      if (result && result.code === 0) {
        yield put({
          type: 'setState',
          payload: {
            notShowToilet: result.data?.notShowToilet,
            showToilet: result.data?.showToilet,
          },
        });
      } else {
        yield put({
          type: 'setState',
          payload: {
            errMsg: result.msg,
          },
        });
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *getConfig({ payload }, { call, put }) {
      const result = yield call(service.getConfig, payload);
      if (result.code !== 0) {
        return Toast.fail(formatMessage({ id: result.msg }));
      }
      yield put({ type: 'setState', payload: { config: result.data } });
      return result.data;
    },

    *getSpaceTree({ payload }, { call, put }) {
      const result = yield call(service.getSpaceTree, payload);
      if (result.code !== 0) {
        return Toast.fail(formatMessage({ id: result.msg }));
      }

      function getChildIds(obj) {
        const ids = [obj.id];

        if (obj.children && obj.children.length > 0) {
          obj.children.forEach((child) => {
            ids.push(...getChildIds(child));
          });
        }
        return ids;
      }

      function  findObj(data) {
        let item = null;
        let isSearch =false;
        function find(arrObj){
         return  arrObj.forEach((il)=>{
            if(isSearch){
              return ""
            }
           if(il.spaceType === "building"){
             isSearch=true;
             item = il;
           }
              if(il.children){
                find(il.children)
              }
          })
        }
        find(data);
        return item

      }
      const spaceItem  = findObj(result.data||[]);
      const tempArr = getChildIds(spaceItem);
      const pathArray = spaceItem?.pathName?.split(',');
      yield put({ type: 'setState', payload: {
        spaceTree: result.data ,
          buildingName:  pathArray.join('/'),
          buildingId:spaceItem?.id,
          list: [spaceItem?.parentId, ...tempArr],
      } });


    },

    // 获取楼栋列表
    *buildingList(_, { call, put }) {
      const { msg, code, data } = yield call(service.buildingList);
      if (code === 0) {
        // data.map((item) => {
        //   (item.value = item.spaceId), (item.label = item.name);
        // });
        // data.unshift({
        //   value: 'all',
        //   label: formatMessage({ id: 'tcs.build.all' }),
        // });
        // yield put({
        //   type: 'setState',
        //   payload: { buildingList: data, buildingId: data[1]?.spaceId },
        // });
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    // 获取楼栋楼层列表
    *floorList(_, { select, call, put }) {
      const { buildingId } = yield select((state) => state.tcs);
      if (buildingId) {
        const { msg, code, data } = yield call(service.floorList, buildingId);
        if (code === 0) {
          data.map((item) => {
            (item.value = item.spaceId), (item.label = item.name);
          });
          data.unshift({
            value: 'all',
            label: formatMessage({ id: 'tcs.all' }),
          });
          yield put({ type: 'setState', payload: { floorList: data } });
        } else {
          Toast.fail(formatMessage({ id: msg }));
        }
      }
    },
    // 获取厕位类型
    *getAllCategory({ payload }, { call, put }) {
      const { msg, code, data } = yield call(service.getAllCategory, payload);
      if (code === 0) {
        yield put({
          type: 'setState',
          payload: { allCategory: data },
        });
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(async ({ pathname }) => {
        if (pathname?.includes(`/tcs/show`) || pathname?.includes(`/tcs/show/`)) {
          await dispatch({ type: 'tcs/getData', payload: { noAuth: getUrlParam('tenantId'), sjc: moment().valueOf() } });
          await dispatch({ type: 'getSpaceTree', payload: { noAuth: getUrlParam('tenantId'), sjc: moment().valueOf() } });
          // await dispatch({ type: 'floorList', payload: { noAuth: true } });
        }
        if (
          pathname === `${ROUTE_PATH}/tcs/usestatus` ||
          pathname === `${ROUTE_PATH}/tcs/usestatus/`
        ) {
          await dispatch({ type: 'tcs/getData' });
          await dispatch({ type: 'getSpaceTree' });
          await dispatch({ type: 'floorList' });
        }
      });
    },
  },
};
