export default {
  'sws.immediate.hours': 'Hours',
  'sws.immediate.submit': 'Submit',
  'sws.immediate.station': 'Space',
  'sws.immediate.address': 'Location',
  'sws.immediate.starttime': 'Start Time',
  'sws.immediate.endtime': 'End Time',
  'sws.immediate.ok': 'Confirm',
  'sws.immediate.cancel': 'Cancel',
  'smt.immediate.select': 'Please Select',
  'sws.immediate.select.time': 'selection period',
  'sws.immediate.submit.success': 'Extend Successfully!',
  'sws.immediate.submit.error': 'Extend is Fail',
  'sws.immediate.no.worktime': 'Can’t scan code to make appointments during off hours',
  401128: 'Unallocated department',
  401140: 'The sensor judges that there is no one in the station, and the sign-in fails',
  401141: 'The sensor judges that the station is unmanned and the station cannot be used',

  'sws.immediate.continue': 'Continue',
  'sws.immediate.tip': ' Prompt ',
  401855: 'The current station is not bound with a socket device',
  401857: '[{facilityName}], the device has been offline, do you want to continue using it?',
  401856: 'The selected station has been reserved or occupied',

  'sws.immediate.remark.tip': 'Example: item number'
};
