import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取空间树和叶子节点对应的地图id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSpaceTreeAndMapId(roomId) {
  return request(`/${RESTFUL_PATH.map}/map/getByObjectId?objectId=${roomId}&typeCode=meeting-room`, {
    method: 'GET',
  });
}
export async function getEleIdsByMapId(mapId) {
  const url = `/${RESTFUL_PATH.map}/map-element/list?mapId=${mapId}`;
  return request(url, {
    method: 'GET',
  });
}

export async function getCalendarRoom(payload) {
  return request(`/${RESTFUL_PATH.meeting}/calendar-view/room`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}
