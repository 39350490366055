/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import { formatMessage } from 'umi';
import __ from 'lodash';
import { Toast } from 'antd-mobile-v2';
import * as repairService from './service';
import { parse } from 'qs';
import * as wechatService from '@/pages/WorkSpace/Station/FacilityManage/services/wechat';
import * as applyService from '@/pages/WorkSpace/Station/FacilityManage/services/services';
import * as facilityService from '@/pages/WorkSpace/Station/FacilityManage/service';
import * as virsicalService from '@/pages/WorkSpace/Station/FacilityManage/services/virsical';

export default {
  namespace: 'swsRepair',
  state: {
    repairData: { records: [], current: 1, size: 5, total: 0, pages: 1 },
    uploading: false,
    selectedIndex: 0, // 选择的页面(0:新建报修，1:报修记录)
    shade: false,
    isClear: false,
    pickerItem: '',
    facilityList: [],
    searchStation: [],
    pcFileList: [],
    uploadImgs: [],
    dataArr: [],
    putData: {},
    currentStation: '',
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询报修列表信息
    *getRepairList({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(repairService.getRepairList, param);
      if (result && result.code === 0) {
        const { isCancelRep } = payload;
        if (isCancelRep) {
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              dataArr: [],
              repairData: { records: [], current: 1, size: 5, total: 0, pages: 1 },
            },
          });
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            repairData: result.data,
          },
        });
      }
    },
    *getFacilityList(_, { call, put, select }) {
      let domainId = 0;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        domainId = info.tenant_id;
      }
      const result = yield call(repairService.getFacilityList, domainId);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            facilityList: result.data,
          },
        });
      }
    },
    *getStationNums({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
        param.userId = info.user_id;
      }
      const result = yield call(repairService.getStationNums, param);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            searchStation: result.data,
          },
        });
      }
    },
    *uploadPicture({ payload }, { call, put, select }) {
      const result = yield call(repairService.uploadPicture, payload);
      if (result && result.code === 0) {
        const uploadImgs = yield select((state) => state.swsRepair.uploadImgs);
        const {
          data: { fileUrl },
        } = result;
        let ext = '';
        if (payload.orientation) {
          ext = `#${payload.orientation}`;
        }
        if (!uploadImgs.length) {
          uploadImgs.push({ id: 0, url: fileUrl + ext });
        } else {
          uploadImgs.push({
            id: uploadImgs[uploadImgs.length - 1].id + 1,
            url: fileUrl + ext,
          });
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            uploadImgs,
          },
        });
      }
    },
    *confirmRepair({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
        param.userId = info.user_id;
      }
      if (__.isArray(param.imgUrl)) {
        param.imgUrl = param.imgUrl.join(',');
      }
      if (__.isArray(param.repairsType)) {
        param.repairsType = param.repairsType.join(',');
      }
      param.repairsStatus = 1;
      const result = yield call(repairService.confirmRepair, param);
      if (result && result.code === 0) {
        Toast.success(formatMessage({ id: 'sws.repair.success' }));
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            selectedIndex: 1,
            pickerItem: '',
            facilityList: [],
            searchStation: [],
            pcFileList: [],
            uploadImgs: [],
            putData: {},
            currentStation: '',
            dataArr: [],
            repairData: { records: [], current: 1, size: 5, total: 0, pages: 1 },
          },
        });
        yield put({
          type: 'getRepairList',
          payload: {},
        });
      }
    },
    *cancelRepair({ id }, { call, put, select }) {
      const param = { id };
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(repairService.cancelRepair, id);
      if (result && result.code === 0) {
        yield put({
          type: 'getRepairList',
          payload: {
            isCancelRep: true,
          },
        });
        Toast.success(formatMessage({ id: 'sws.cancel.success' }), 1);
      }
    },
    // // 工位报修扫一扫
    // *checkRepairCode({ payload }, { call, put }) {
    //   const { scanType, res } = payload;
    //   // const i18n = yield select(state => state.i18n)
    //   if (res.indexOf('stationId=') > -1) {
    //     const result = res.substring(res.indexOf('stationId=') + 10, res.length);
    //     const { data } = yield call(repairService.getRepairStation, {
    //       stationId: result,
    //       scanType,
    //     });
    //     if (data.code === 0 && data.data) {
    //       yield put({
    //         type: 'saveOrUpdateData',
    //         payload: {
    //           currentStation: data.data.stationNum,
    //         },
    //       });
    //     } else {
    //       Toast.fail(formatMessage({ id: 'sws.repair.scan.qrCode' }));
    //     }
    //   } else {
    //     Toast.fail(formatMessage({ id: 'sws.repair.scan.qrCode' }));
    //   }
    // },
    *weChatQrScan({ scanType }, { call, put }) {
      /* eslint-disable*/
      const res = yield call(wechatService.qrScan, scanType);
      yield put({ type: 'handleResult', res });
    },
    *handleResult({ res }, { put }) {
      let result = decodeURIComponent(res);
      if (result) {
        const index = result.indexOf('sId');
        const sId = encodeURIComponent(
          result.substr(index, result.length).split('&')[0].split('sId=')[1],
        );
        yield put({ type: 'searchScanStationInfo', stationId: sId });
      } else {
        Toast.fail(formatMessage({ id: 'sws.inventory.qrCode.error' }));
      }
    },
    *searchScanStationInfo({ stationId }, { call, put, select }) {
      const result = yield call(repairService.searchScanStationInfo, stationId);
      const { putData } = yield select((state) => state.swsRepair);
      if (result && result.code === 0) {
        const station = result.data || {};
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            currentStation: station?.userNames
              ? `${station?.userNames}：${station?.stationNum}`
              : station?.stationNum,
            putData: {
              ...putData,
              stationId: station?.stationId,
            },
          },
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *configScan({ scanType }, { call, put }) {
      const data = yield call(applyService.getJssdkTicket);
      if (Number(data.code) === 0) {
        yield call(wechatService.init, data.data, ['scanQRCode']);
        // 睡眠一秒
        yield setTimeout(() => {}, 1000);
        yield put({ type: 'weChatQrScan', scanType });
      } else {
        Toast.fail(formatMessage({ id: data.msg }));
      }
    },
    *virsicalQrScan(_, { call, put }) {
      const data = yield call(facilityService.getWaferAppInfo);
      if (data.code === 0) {
        const res = yield call(virsicalService.qrScan, data.data);
        yield put({ type: 'handleResult', res });
      } else {
        const errorText = formatMessage({ id: data.msg });
        Toast.fail(errorText);
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        // 监听路由变化，并发送类别查询请求
        if (pathname && pathname.includes('/workspace/station/repair')) {
          const payload = {
            size: 5,
            current: 1,
          };
          dispatch({
            type: 'getRepairList',
            payload,
          });
          dispatch({
            type: 'getFacilityList',
          });
          if (document) {
            document.body.scrollTop = 0;
          }
        }
      });
    },
  },
};
