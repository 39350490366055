export default {
  selfService_name: '訪客姓名',
  selfService_name_tip: '請輸入您的姓名',
  selfService_company: '訪客公司',
  selfService_company_tip: '請輸入您的公司',
  selfService_phone: '手機號碼',
  selfService_phone_tip: '請輸入您的手機',
  selfService_mail: '您的郵箱',
  selfService_mail_tip: '請輸入您的郵箱',
  selfService_host: '拜訪對象',
  selfService_host_tip: '請輸入拜訪對象',
  selfService_select: '請選擇',
  selfService_success_tip1: '預約操作成功',
  selfService_success_tip2: '請等待被訪人確認,稍後將以短訊形式告知您結果',
  selfService_audit: '待審',
  selfService_pass: '已通過',
  selfService_all: '全部',
  selfService_new: '新增預約',
  selfService_local: '訪問地點',
  selfService_time: '訪問時間',
  selfService_pull: '下拉',
  selfService_deliver: '釋放',
  selfService_loading: '加載中...',
  selfService_loaded: '到底了~',
  self_header_welcome: '歡迎來到',
  self_search_tip: '輸入更多企業試試',
  self_no_search_tip: '該企業暫未入駐訪客管理系統',
  self_add_booking_tip: '點擊下方按鈕，新增預約',
  selfService_select_end_time: '請選擇離開時間',
};
