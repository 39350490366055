import { formatMessage, history } from 'umi';
import { getUrlParam } from '@/utils/utils';
import { ACCESS_TOKEN, ROUTE_PATH } from '@/utils/constant';
import { Toast } from 'antd-mobile-v2';
import { fieshuBindFuc } from './services';

export default {
  namespace: 'feishuBind',

  state: {
    bBindState: 0,
  },

  effects: {
    *fieshuBindFuc({ payload }, { call }) {
      const jsonCode = sessionStorage.getItem('codeFS');
      if(jsonCode){
        const codeFS = JSON.parse(jsonCode);
        const data = yield call(fieshuBindFuc, {
          valueAccount: payload.userName,
          valuePassword: payload.password,
          state: codeFS.state,
          accId: codeFS.accId,
          tempUrl: codeFS.tempUrl,
          hr: codeFS.hr,
          lc: codeFS.lc,
        });
        if (data && data.code === 0) {
          if(data.data.localToken){
            sessionStorage.setItem(ACCESS_TOKEN, data.data.localToken);
          }
          if (data.data.urlReturn.startsWith('http')) {
            window.location.replace(data.data.urlReturn);
          } else {
            console.log(`urlReturn---${data.data.urlReturn}`)
            history.replace(`${ROUTE_PATH}/${data.data.urlReturn}`);
          }
        } else {
          const errorText = formatMessage({ id: data.msg });
          Toast.fail(errorText);
        }
      }
    },
  },

  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  subscriptions: {
    setup({ history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        if (pathname === '/wdfclient/feishu') {
          const codeFS = {
            accId: getUrlParam('accId') || '',
            state: getUrlParam('state') || '',
            tempUrl: getUrlParam('tempUrl') || '',
            hr: getUrlParam('hr') || '',
            lc: getUrlParam('lc') || '',
          };
          sessionStorage.setItem('codeFS', JSON.stringify(codeFS));
        }
      });
    },
  },
};
