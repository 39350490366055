import { ACCESS_TOKEN, SCANAUTH } from '@/utils/constant';
import getUserScanAuth from './services';

export default {
  namespace: 'scanAuth',

  state: {
    userScanAUthState: 0,
  },

  effects: {
    *getUserScanAuth({ code }, { call, put }) {
      const token = sessionStorage.getItem(ACCESS_TOKEN);
      const data = yield call(getUserScanAuth, { code, token });
      window.console.log(data.code);
      yield put({ type: 'saveOrUpdateData', payload: { userScanAUthState: data.code } });
    },
  },

  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname, query }) => {
        if (pathname.indexOf(SCANAUTH) !== -1 && query.userScanAUthState) {
          dispatch({
            type: 'saveOrUpdateData',
            payload: { userScanAUthState: query.userScanAUthState },
          });
        }
      });
    },
  },
};
