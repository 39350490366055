export default {
  common_cancel: '取消',
  common_confirm: '确定',
  'meeting.sub.tip': '提示',
  'meeting.sub.content': '您已被拉入系统黑名单，暂时无法预订会议室。',
  'meeting.sub.content.flybook': '飞书会议室不可预订会议',
  'meeting.sub.ok': '确定',
  'meeting.evaluate.title': '使用评价',
  'meeting.evaluate.redio': '单选',
  'meeting.evaluate.choice': '多选',
  'meeting.evaluate.submit.sure': '确定要提交？',
  'meeting.evaluate.ok': '确定',
  'meeting.evaluate.cacle': '取消',
  'meeting.evaluate.select.tip': '请选择此项',
  'meeting.evaluate.text.tip': '请填写此项',
  'meeting.evaluate.submit': '提交',
  'meeting.evaluate.text.place': '请输入内容',
  'meeting.evaluate.commit.tip': '感谢您的评价',
  'meeting.evaluate.commit.tip.1': '提交成功！',
  'meeting.evaluate.overdue': '问卷已过期或不可用！',
  'meeting.attend.root': '联系人',
  'meeting.attend.name.number': '议题{value}',
  'meeting.xrooms.feishu': '飞书 Rooms',
  'meeting.xrooms.tengxun': '腾讯 Rooms',
  'meeting.xrooms.teams': 'Teams Rooms'
};
