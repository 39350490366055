/**
 * Created by wang sen on 2017/7/6.
 */
import { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH, PASSWORD_AES_KEY } from '@/utils/constant';
import { encryptAes } from '@/utils/utils';

/**
 * 获取绑定信息
 * @param payload
 */

export const fieshuBindFuc = ({
                                valueAccount,
                                valuePassword,
                                accId,
                                state,
                                tempUrl,
                                appId,
                                hr,
                                lc,
                              }) => {
  const aesPassword = encryptAes(valuePassword, PASSWORD_AES_KEY);
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/wdfClient/feishu/bindUser`, {
    method: 'POST',
    body: JSON.stringify({
      accId,
      state,
      tempUrl,
      appId,
      valueAccount,
      valuePassword: aesPassword,
      hr,
      lc,
    }),
  });
};
