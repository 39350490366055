
import { message } from 'antd';
import { formatMessage } from 'umi';

import * as services from './Map/service';

export default {
  namespace: 'metaSpaceGlobal',
  state: {
    spaceTree:[],
    deviceType: [],
    datamodel:{},
    dashBoardModel: {},
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getdeviceTypeList(_, { call, put }) {
      const { code, data = [], msg } = yield call(services.getdeviceTypeList);
      if (code !== 0) {
        return message.error(formatMessage({ id: msg }));
      }

      yield put({
        type: 'setState',
        payload: {
          deviceType: data || [],
        },
      });
    },
    *getSpaceTree(_, { call, put }) {
      const result = yield call(services.getSpaceTree);
      if (result.code === 0) {
        let spaceTree = result.data;
        yield put({
          type: 'setState',
          payload: {
            spaceTree: spaceTree,
          },
        });
        return result.data;
      } else {
        message.error(formatMessage({ id: result.msg }));
      }
    },
    *getSmartDeviceDataModel(_, { call, put, select }) {
      const {
        metaSpaceGlobal: { datamodel },
      } = yield select((state) => state);
      if (Object.keys(datamodel).length !== 0) {
        return datamodel;
      }
      const { code, data, msg } = yield call(services.getSmartDeviceDataModel);
      if (code !== 0) {
        return message.error(formatMessage({ id: msg }));
      }
      yield put({
        type: 'setState',
        payload: {
          datamodel: data,
        },
      });
    },
    *getDataModel(_, { call, put, select }) {
      const {
        metaSpaceGlobal: { dashBoardModel },
      } = yield select((state) => state);
      if (Object.keys(dashBoardModel).length !== 0) {
        return dashBoardModel;
      }
      const { code, data, msg } = yield call(services.getDataModel);
      if (code !== 0) {
        return message.error(formatMessage({ id: msg }));
      }
      const newData = {};
      data.forEach((il) => {
        newData[il.modelKey] = JSON.parse(il.modelValue);
      });
      yield put({
        type: 'setState',
        payload: {
          dashBoardModel: newData,
        },
      });
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if(pathname.indexOf("workplace") !==-1){
          dispatch({
            type:"getSpaceTree"
          })
          dispatch({
            type: 'getSmartDeviceDataModel',
          });
          dispatch({
            type: 'getdeviceTypeList',
          });
          dispatch({
            type: 'getDataModel',
          });
        }

      });
    },
  },
};
