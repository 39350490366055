import React from 'react';
import { getLocale } from 'umi';
// import { TreeSelect } from 'antd';

// const { TreeNode } = TreeSelect;

/**
 * 遍历空间树
 * @param treeData 数据树
 * @param id 查询条件
 * @param dataS 符合条件的子节点
 * @returns {Array}
 */
export function recursiveSearch({ treeData, id, dataS = [] }) {
  if (treeData && treeData.length > 0) {
    treeData.forEach((v) => {
      if (String(v.id) === id) {
        dataS.push(v);
      } else if (v.children) {
        recursiveSearch({ treeData: v.children, id, dataS });
      }
    });
  }
  return dataS;
}

// 定位头像图标
export const markAvatarSvg = (avatar) => {
  return (
    <g transform="translate(-10, -40) scale(0.8)">
      <image
        xlinkHref={avatar}
        width="80"
        height="80"
        color="#f00"
        clipPath="url(#clipPath)"
        preserveAspectRatio="xMidYMid slice"
      />
      <clipPath id="clipPath">
        {' '}
        <circle cx="40" cy="40" r="40" />{' '}
      </clipPath>
    </g>
  );
};

// 选中 svg 图标
export const SelectedIcon = () => {
  return (
    <g transform="translate(5, -5) scale(0.8)">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-532.000000, -600.000000)" fill="#FFC13D" fillRule="nonzero">
          <g transform="translate(527.000000, 589.000000)">
            <g>
              <path
                d="M27.0102531,11 C14.8531881,11 5,20.855181 5,32.9981718 C5,38.5882954 7.09817366,43.660815 10.5231336,47.5428454 C10.6157001,47.7188307 10.7391221,47.8948161 10.8831144,48.0500973 L25.1794937,63.0192062 C25.2514899,63.1123749 25.323486,63.2158957 25.4057673,63.2987124 C25.6217558,63.5161061 25.8685998,63.6713873 26.136014,63.7852602 C26.9588272,64.1682872 27.9564882,64.0440622 28.625024,63.3711769 C28.8101569,63.1848395 28.9541492,62.9674458 29.0570009,62.7397 L43.0859659,48.0500973 C43.2196731,47.9258723 43.3225247,47.7705911 43.4150912,47.6153099 C46.8811918,43.7332796 48.999968,38.6193517 48.999968,33.0085239 C49.0205061,20.855181 39.1673181,11 27.0102531,11 Z M26.9999679,41.9423696 C21.5282601,41.9423696 17.0850689,37.5013269 17.0850689,32.0354283 C17.0850689,26.5695296 21.5282601,22.1284869 26.9999679,22.1284869 C32.4716757,22.1284869 36.9148669,26.5695296 36.9148669,32.0354283 C36.9148669,37.5013269 32.4716757,41.9423696 26.9999679,41.9423696 L26.9999679,41.9423696 Z"
                id="形状"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  );
};

/**
 * 生成位置Options
 * @param spaceList 数据树
 * @param clear 清除选项
 * @param all 全选选项
 * @returns {Array}
 */
// export function recursionSpace(spaceList) {
//   const treeHtml = [];
//   if (spaceList && spaceList.length > 0) {
//     spaceList.forEach(space => {
//       if (space.children && space.children.length > 0) {
//         treeHtml.push(
//           <TreeNode
//             key={space.id}
//             value={String(space.id)}
//             obj={space}
//             title={
//               <div>
//                 {Number(space.structure) === 2 && (
//                   <IconFont
//                     style={{ fontSize: '12px', color: 'rgba(252, 163, 43, 1)', marginRight: 4 }}
//                     type="icon-location"
//                   />
//                 )}
//                 {space.name}
//               </div>
//             }
//             selectable={Number(space.structure) === 2}
//           >
//             {recursionSpace(space.children)}
//           </TreeNode>
//         );
//       } else {
//         treeHtml.push(
//           <TreeNode
//             key={space.id}
//             value={String(space.id)}
//             obj={space}
//             title={
//               <div>
//                 {Number(space.structure) === 2 && (
//                   <IconFont
//                     style={{ fontSize: '12px', color: 'rgba(252, 163, 43, 1)', marginRight: 4 }}
//                     type="icon-location"
//                   />
//                 )}
//                 {space.name}
//               </div>
//             }
//             selectable={Number(space.structure) === 2}
//           />
//         );
//       }
//     });
//   }
//   return treeHtml;
// }

/**
 * 视口的大小，部分移动设备浏览器对innerWidth的兼容性不好，需要
 * document.documentElement.clientWidth或者document.body.clientWidth
 * 来兼容（混杂模式下对document.documentElement.clientWidth不支持）。
 * 使用方法 ： getViewPort().width;
 */
export function getViewPort() {
  // 浏览器嗅探，混杂模式
  if (document.compatMode === 'BackCompat') {
    return {
      vw: document.body.clientWidth,
      vh: document.body.clientHeight,
    };
  }
  return {
    vw: document.documentElement.clientWidth,
    vh: document.documentElement.clientHeight,
  };
}


export const getMapEleListLang = () => {
  const locale = getLocale();
  let lang = 'cn';
  if (locale === 'zh-TW' || locale === 'zh-HK') {
    lang = 'tw';
  } else if (locale === 'en-US') {
    lang = 'en';
  } else if (locale === 'zh-CN') {
    lang = 'cn';
  }

  return lang;
}
