export default {
  'room.btn.add': '添加',
  'subscribe.select.region': '選擇區域',
  'subscribe.default.region': '請選擇區域',
  'subscribe.default.room': '暫無可用會議室信息',
  'subscribe.room.noDev': '無設備',
  'subscribe.room.1to3': '1-3人',
  'subscribe.room.4to9': '4-9人',
  'subscribe.room.over10': '10人以上',
  'subscribe.room.filter': '篩選',
  'subscribe.room.select': '共{num}個',
  'subscribe.send.success': '發送成功',

  'subscribe.fail.zoom.scale': '請選擇參會人數',
  'subscribe.fail.emptyDelayTime': '請選擇延長時間！',
  'subscribe.fail.delayTimeToMax': '會議延長時間不能大於{min}分鐘！',
  'subscribe.fail.delayTimeToMin': '會議延長時間不能小於{min}分鐘！',
  'subscribe.fail.emptyMeetingTime': '請選擇會議時間!',
  'subscribe.fail.zoom.days.limit': '請預定90天以內的Zoom會議!',
  'subscribe.fail.feishu.days.limit': '飛書會議無法預約一個月後的會議!',
  'subscribe.time.longer.than.forty.mins': '免費賬號，只能預約40分鐘內的會議!',
  'subscribe.fail.emptySubject': '請輸入會議主題',
  'subscribe.subject.placeholder': '的會議',
  'subscribe.fail.memberConflict': '主持人與參會人衝突！',
  'subscribe.remark.placeholder': '請輸入備註信息',
  'subscribe.fail.one.host': '網絡會議只能選擇一個主持人！',
  'subscribe.fail.value0': '請選擇主持人！',
  'subscribe.fail.value1': '會議主題不能為空！',
  'subscribe.fail.visit.cross': '非跨天會議，到訪日期必須為同一天！',
  'subscribe.fail.value2': '立即開始的會議不能預約訪客',
  'subscribe.fail.value3': '周期會議不能預約訪客',
  'subscribe.reservation': '立即預訂',
  'subscribe.reminding': '提醒方式',
  'subscribe.secret': '保密',
  'subscribe.secret.text': '對非與會人員及公共顯示屏幕不展示會議主題',
  'subscribe.alluser': '全部人員',
  'subscribe.alluser.pls': '全員',
  'subscribe.reminding.ways': '郵件',
  'subscribe.meeting.type': '會議類型',
  'subscribe.meeting.type.native': '本地',
  'subscribe.meeting.type.phone': '電話',
  'subscribe.meeting.type.video': '視頻',
  'subscribe.meeting.sign.in': '簽到',
  'subscribe.meeting.sign.in.remind': '開啟後，規定時間內未完成簽到，會議室將被釋放',
  'subscribe.meeting.long.pls': '注：跨天會議持續時間最大為{day}天。',
  'subscribe.meeting.sign.time': '會議開始前 / 後',
  'subscribe.meeting.sign.before.time': '會議開始前',
  'subscribe.meeting.sign.after.time': '會議開始後',
  'subscribe.meeting.appointment': '預約',
  'subscribe.meeting.appointment.inform': '預約並通知',
  'subscribe.meeting.save': '保存',
  'subscribe.meeting.save.inform': '保存並通知',
  'subscribe.meeting.prompt': '操作提示',
  'subscribe.meeting.create.success': '新建會議成功',
  'subscribe.meeting.edit.success': '修改會議成功',
  'subscribe.meeting.manage': '是否需要繼續管理會議？',
  'subscribe.startNow': '立即開始',
  'subscribe.startAuto': '自動發起',
  'subscribe.longState': '跨天會議',
  'subscribe.reconnect': '自動重連',
  'subscribe.duration': '會議時長',
  'subscribe.duration.new': '會議時長(分鐘)',
  'subscribe.startNow.unit': ' 分鐘',
  'subscribe.startNow.placeholder': '請輸入會議時長',
  'subscribe.joinPwd': '會議密碼',
  'subscribe.joinPwd.placeholder': '請設置參會密碼',
  'subscribe.joinPwd.warning': '參會密碼必須是數字',
  'subscribe.warning.title': '操作提示',
  'subscribe.warning.visitor.advance': '邀請訪客必須提前{value}分鐘預約',
  'subscribe.warning.content': '切換會議類型會導致已選擇的會議室和時間發生變化，是否繼續？',
  'subscribe.meetingType': '會議類型',
  'subscribe.meetingType.ok': '確定',
  'subscribe.meetingType.dismiss': '取消',
  'subscribe.meetingType.cancel': '取消',
  'subscribe.meetingType.normal': '普通會議',
  'subscribe.meetingType.long': '跨天會議',
  'subscribe.meetingType.video': '視頻會議',
  'subscribe.meetingType.zoom': 'Zoom會議',
  'subscribe.meetingType.webex': 'Webex會議',
  'subscribe.meetingType.flybook': '飛書視頻會議',
  'subscribe.meetingType.warning': '請選擇正確的會議室類型',
  'subscribe.people.less': '會議室沒有添加參會人，是否繼續操作？',
  'subscribe.people.more': '參會人數超出會議室可容納人數，是否繼續操作？',
  subscribe_ad_options: '高級選項',
  subscribe_room_audit_tip: '（包含需要審批的會議室）',
  subscribe_apply_project: '確定要申請這些項目',

  'webex.routes.choice': '系統自動選擇WebEx線路',
  'meeting.no.devices': '暫時沒有可用的設備',
  'meeting.save.btn.apply': '申請',
  'meeting.save.btn.notice': '發送通知',

  meeting_save_tips_subject: '主題',
  meeting_save_tips_holder: '申請人',
  meeting_save_tips_time: '時間',
  meeting_save_tips_reason: '原因',
  meeting_save_tips_lock_time: '鎖定時間',
  'meeting.save.tips.modal.loop.create': '是否重新生成週期會議?',

  'meeting.save.tips.modal.title1': '以下時間段預約失敗,是否跳過並繼續預訂？',
  'meeting.save.tips.modal.title2': '以下時間段預約失敗，無可預約會議！',
  meeting_save_tips_reason_0: '時間段被其他會議佔用',
  meeting_save_tips_reason_1: '與其他會議鎖定時間衝突',
  meeting_save_tips_reason_2: '該會議鎖定時間被佔用',
  meeting_save_tips_reason_3: '主持人已預約該時間段會議',
  'meeting.save.tips.modal.extend': '會議順延提示',
  'meeting.save.tips.modal.title-290': '該周期會議因節假日和會議室維護需順延，是否繼續？',
  'meeting.save.tips.modal.title-291': '該周期會議因節假日需順延，是否繼續？',
  'meeting.save.tips.modal.title-292': '該周期會議因會議室維護需順延，是否繼續？',
  'meeting.save.tips.modal.title-293': '該會議因節假日和會議室維護，預約失敗!',
  'meeting.save.tips.modal.title-294': '該會議因節假日，預約失敗!',
  'meeting.save.tips.modal.title-295': '該會議因會議室，預約失敗!',
  'meeting.save.tips.modal.title-attend': '本場會議與以下人員的其它會議存在衝突，是否繼續？',
  'meeting.save.tips.modal.ignore': '忽略並預約',
  meeting_tips_holiday: '節假日',
  meeting_tips_room_maintain: '會議室維護',

  'meeting.save.tips.modal.cancel': '取消',
  'meeting.save.tips.modal.continue': '繼續',
  'meeting.save.tips.modal.meetingprice': '會議費用',
  'meeting.save.tips.modal.bookit': '繼續預訂',
  'meeting.save.tips.modal.giveup': '放棄預訂',
  'meeting.save.tips.modal.gotit': '知道了',
  'meeting.apply.start.fail.value01': '存在WebEx會議室，不能立即開始',
  'meeting.apply.start.fail.value02': '存在審批會議室，就不能立即開始',
  'meeting.apply.start.fail.value03': '當前時間存在會議，就不能立即開始',

  room_fee: '會議室費用',
  service_fee: '會議服務費用',

  'participants.count': `共{num}人`,
  'outer.participants': '外部參會人',
  'outer.participants.count': `共{num}人`,
  remark: '備註',
  welcome: '歡迎詞',
  'subscribe.welcome.placeholder': '請輸入歡迎詞',
  'modal.notice': '提示',
  'modal.confirmDelete': '是否確認刪除？',
  'modal.cancel': '取消',
  'modal.close': '關閉',
  'modal.confirm': '確認',
  'modal.notice.svcApplied': '當前會議已申請會議服務，只允許修改參會人信息',

  'subscribe.warning.room': '請選擇會議室',
  'zoom.meeting.room.placeholder': '非必填，根據需要選擇',
  'meeting.book.outer.visit.time': '訪問時間',
  'meeting.book.outer.visit.time.modal.start': '訪問開始時間',
  'meeting.book.outer.visit.time.modal.end': '訪問結束時間',
  'meeting.book.outer.visit.type': '訪問事由',
  'meeting.book.outer.visit.address': '接待地點',
  'meeting.booking.vst.type.empty': '請選擇訪客訪問事由',
  'meeting.booking.vst.location.empty': '請選擇訪客訪問地點',

  'meeting.seat.manage': '座位安排',
  'meeting.seat.bound.number': '已綁定 {num} 人',
  'subscribe.warning.template': '所選會議室沒有座位模板',

  'Asia/Shanghai': '中國時間',
  'Asia/Tokyo': '日本時間',
  'Asia/Seoul': '韓國時間',
  'Asia/Singapore': '新加坡時間',
  'Australia/Sydney': '東部時間 (新南威爾斯)',
  'Europe/London': '英國時間',
  'Europe/Paris': '中歐時間',
  'Europe/Moscow': '莫斯科時間',
  'America/New_York': '美國東部時間',
  'America/Chicago': '美國中部時間',
  'America/Denver': '美國山區時間',
  'America/Los_Angeles': '太平洋時間',
  'America/Anchorage': '阿拉斯加時間',
  'Pacific/Honolulu': '夏威夷時間',

  'meeting.booking.step.time.max': '該會議預約最大時間為{value}分鐘',
  'meeting.booking.before.day': '該會議需提前{value}天預約',
  202107: '會議所包含會議室需提前預約',
  202129: '只允許同時存在一條大型騰訊會議線路!',
  'meeting.booking.host_user_no_auth': '會議主持人無會議室權限！',
  'meeting.booking.step.tencent.outer.tips': '開啟後，外部參會人將無法加入騰訊會議！',
  'subscribe.meetingType.tencent': '騰訊會議',
  'subscribe.meetingType.teams': 'Teams會議',
  'subscribe.meetingType.all': '同步預訂',
  'subscribe.meetingType.poly.all': '視頻會議類型',
  'subscribe.meeting.special.type': '類型',
  'subscribe.meeting_audit_tip': '{roomStr}在{timeStr}已經有審批中的會議，是否繼續預約？',
  202104: '會議延長失敗，當前會議不是進行中的會議',
  'meeting.agenda': '會議議程',
  'meeting.agenda.look': '查看',
  'agenda.user.name': '議題',
  'agenda.user.name.pls': '請輸入議題',
  'agenda.user.startTime': '開始時間',
  'agenda.user.endTime': '結束時間',
  'agenda.user.attend': '參會人',
  'agenda.user.select': '請選擇',
  'agenda.user.time.tips': '議程開始時間不能晚於結束時間！',
  'meeting.booking.agenda.tip': '會議議程時間超出會議時間範圍，請修改！',
  'agenda.user.selectall': '全選',
  'meeting.booking.agenda.tip2': '議程參會人中存在非會議參會人，請檢查！',
  'meeting.agenda.starttime.tip': '議程開始時間不能為空!',
  'meeting.agenda.endtime.tip': '議程結束時間不能為空!',
  'meeting.agenda.name.tip': '議題不能為空!',
  'meeting.agenda.repeat.tip': '議程時間不能重疊!',
  'meeting.agenda.sameTime.tip': '已經存在和會議結束時間一致的議題，請先修改議題時間再繼續添加!',
  'meeting.new.no.select.service': '去選擇',
  'meeting.new.no.select.device': '去選擇',
  'meeting.new.outer.loop.tip': '週期會議不支持同時預約訪客，是否繼續？',
  'meeting.manage.service.self.bear': '本人承擔',
  'meeting.booking.before.day.room': '需提前{value}天預訂',
  'meeting.booking.before.day.room.new': '需提前{value}天預訂,請重新選擇日期',
  'meeting.booking.max.day.room': '只能預訂{value}天內的會議，請重新選擇日期',
  subscribe_room_audit_tip_room: '會議室預約需要審批',
  'meeting.booking.step.time.max.less': '預訂最大時間為{value}分鐘',
  'subscribe.meeting.sign.in.setting': '簽到設置',
  'subscribe.meeting.sign.in.duration': '需要小於會議時長({value}分鐘)',
  'subscribe.meeting.sign.time.before': '會議開始前{value}分鐘至',
  'subscribe.meeting.sign.time.after': '開始後{value}分鐘',
  'subscribe.meeting.user.placeholder.host': '選擇主持人',
  'subscribe.meeting.user.placeholder.inner': '選擇參會人',
  'subscribe.meeting.user.placeholder.outer': '選擇外部參會人',

  'subscribe.meeting.modifiy1':
    '會議室修改後，原會議室申請的{modifyDetail}將會被取消，系統將自動按照新的會議室及原有服務項重新申請！',
  'subscribe.meeting.modifiy1.dev.ser':
    '會議室修改後，原會議室申請的會議設備和會議服務，將自動為您轉移到新的會議室',
  'subscribe.meeting.modifiy1.dev':
    '會議室修改後，原會議室申請的會議設備將自動為您轉移到新的會議室',
  'subscribe.meeting.modifiy2':
    '該會議已經申請了{modifyDetail}，修改{modifyStr}後您是否需要修改{modifyDetail}？',
  'subscribe.meeting.modifiy3':
    '該會議已經申請了{modifyDetail}，修改會議室後原{modifyDetail}將被取消，是否繼續？',

  'subscribe.meeting.modifyAuditType1':
    '該會議包含需要審批的會議室，修改會議時間後需要重新審批，請確認是否修改？',
  'subscribe.meeting.modifyAuditType2':
    '修改後的會議室包含需要審批的會議室，修改後會議需要重新審批，請確認是否修改？',
  'subscribe.meeting.modifyAuditType3':
    '該會議包含需要審批的會議室，修改循環規則後需要重新審批，請確認是否修改？',
  'subscribe.cross_days_meeting_days_limit': '跨天會議的開始日期和結束日用期不能為同壹天',
  'subscribe.meeting.startNow.sign.title': '預約成功',
  'meeting.booking.step.sign.new.text': '該會議為簽到會議,請在{time}分鐘內進行簽到！',
  'meeting.booking.step.times.limit.tip':
    '可預訂{limitMaxDay}天內且會議時長為{limitMinTime}-{limitMaxTime}分鐘的會議',
  'meeting.booking.step.times.limit.tip2': '，{limitMaxDay}天以後的會議需要管理員審批',
  'meeting.booking.step.times.noLimit.tip':
    '可預訂會議時長為{limitMinTime}-{limitMaxTime}分鐘的會議',
  'meeting.booking.step.times.cross.noLimit.tip': '可預訂最大跨度為{data}天的會議',
  'meeting.booking.step.times.cross.noCross.tip': '可預訂{data}天內的會議',
  'meeting.booking.step.times.limit.cylce': '，超出{limitMaxDay}天的會議需要管理員審批',
  'meeting.booking.normal_day_meeting_limit':
    '只允許預訂時長為{limitMinTime}-{limitMaxTime}分鐘的會議',
  'meeting.booking.normal_day_meeting_limit_date': '當前日期不可用，請選擇其他日期預約！',
  'meeting.visit.time.year': '年',
  'meeting.visit.time.month': '月',
  'meeting.visit.time.day': '日',
  'meeting.visit.time.hour': '時',
  'meeting.visit.time.minute': '分',
  'meeting.visit.time.second': '秒',
  'meeting.vst.time.validate': '會議開始時間不得早於訪客到訪時間',
  'meeting.booking.step.times.cross.limit.tip': '可預訂{value}天內且最大跨度為{data}天的會議',
  'meeting.attend.user.status.FREE': '空閒',
  'meeting.attend.user.status.BUSY': '忙碌',
  'meeting.attend.user.status.TENTATIVE': '暫定',
  'meeting.attend.user.status.UNKNOW': '未知',
  'meeting.booking.step.meetingType.zoom': 'Zoom',
  'meeting.booking.step.meetingType.teams': 'Teams',
  'meeting.booking.step.meetingType.dingding': 'DingDing',
  'meeting.booking.step.meetingType.feishu': 'Feishu',
  'meeting.booking.step.meetingType.tencent': 'Tencent',
  'meeting.booking.step.meetingType.changyun': '暢雲',
  'meeting.booking.step.meetingType.quanshi': '全時',
  'meeting.booking.step.meetingType.hasPoly': '本地會議',
  'meeting.booking.not.joinPwd.length.less': '參會密碼長度至少為5！',
  'meeting.booking.not.joinPwd.length.less6': '參會密碼長度至少為6位！',
  'meeting.booking.vst.time.cross.validate': '跨天會議訪客到訪的開始日期和結束日期不能為同一天',
  'meeting.booking.step.visitorCount.limit': '當前地點最大訪客人數為{data}人',
  'meeting.booking.fail.visit.invite.more': '當前接待地點{timeFormat}只能邀請{visitorCount}位訪客',
  'meeting.booking.fail.visit.invite.enough':
    '當前接待地點{timeFormat}邀請的訪客數量已達上限，無法繼續邀請訪客',
  'meeting.booking.fail.smc.min': '華為視頻立即開始會議不能小於{duration}分鐘',
  'meeting.booking.fail.smc.min.normal': '華為視頻會議不能小於{duration}分鐘',
  
};
