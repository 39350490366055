/**
 * Created : vincent
 * Date : 8/20/20 11:10
 * Email : alfa.king+ai@gmail.com
 */
import { getLocale } from 'umi';
import moment from 'moment';

/**
 * 时区数据转换
 * @param data
 * @returns {*}
 */
export const timeZoneDateMake = (data) => {
  const times = new Date().getTime();
  return data.map((val) => {
    const utcOffset = moment.tz.zone(val.timeZone).utcOffset(times);
    return {
      key: val.timeZone,
      value: val.timeZone,
      timezone: (utcOffset / 60) * -1,
    };
  });
};

/**
 * 时区数据转换
 * @param data
 * @returns {*}
 */
export const timeZoneI18nMake = (data) => {
  const obj = {};
  data.forEach((val) => {
    obj[val.timeZone] = {
      'zh-CN': val.zhCn,
      'en-US': val.enUs,
      'zh-TW': val.zhTw,
    };
  });
  return obj;
};

/**
 *
 * @param key
 * @returns {string|*}
 */
export const getTimeZoneName = (key) => {
  if (Object.hasOwnProperty.call(window.I18N_TIMEZONE, key)) {
    const obj = window.I18N_TIMEZONE[key];
    if (obj) {
      return obj[getLocale()];
    }
  }
  return '';
};
