export default {
  'sws.scan.sign.error': '係統暫不支援掃描簽到',
  'sws.scan.fixed.error': '係統暫不支援掃描固定工位',
  401118: '預訂記錄不存在，簽到失敗。',
  401119: '已簽到，請勿重複簽到。',
  401120: '未到簽到時間，請在簽到時間內({value1})進行簽到。',
  401121: '該工位已釋放或未預訂，簽到失敗',
  401125: '工位正在被使用，請{value1}分鍾後進行簽到。',
  401046: '特殊預訂工位不支持掃碼。',
  401047: '特殊預訂工位不支持掃碼簽到。',
  401126: '此工位已被其他人預訂，簽到失敗',
  401044: '工位{value1}未分配使用部門',
  401057: '二維碼已失效',
  401138: '當前時間不在簽到時間範圍內',
  403016: '儲物櫃部門與用戶部門不一致',
  403041: '預定儲物櫃未分配部門',
  403042: '當前用戶沒有儲物櫃{value1}的部門權限',
  401857: "{value1}設備已下線，是否繼續使用？",
  'sws.scan.fix.error': '你已經綁定該工位',
  'sws.scan.error.tip': '該工位不支持掃碼預訂，請更換工位！',
  'sws.scan.fixed.sign': '簽到成功',
  'sws.scan.fixed.bind': '綁定成功',
  'sws.scan.fixed.change': '工位變更成功',
  'sws.scan.fixed.username': '使用者',
  'sws.scan.TimeLast': '提醒：最近一次即將使用的時間為：',
  'sws.scan.next.booking': '下一預訂',
};
