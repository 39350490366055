import { useMemo } from 'react';
import { useSelector } from 'umi';
import useRequest from '../useRequest';
import * as service from './service';

// preId获取自定义表单数据
function useApprovalConfig() {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { tenantId = null } = user || {};
  const { currentUser } = useSelector((state: any) => state.user);

  console.log(user, "user来自于sessionStorage.getItem('user')", currentUser, 'currentUser');

  const defaultParams = {
    companyId: tenantId || currentUser?.tenantId,
    bizType: 'ACTIVELY',
  };
  const { data, run: runIsBlackList } = useRequest(
    () =>
      service.getApprovalConfig({
        ...defaultParams,
      }),
    {},
  );

  return { data, runIsBlackList };
}

export default useApprovalConfig;
