/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'myInfo.info.account': '賬號',
  'myInfo.info.name': '姓名',
  'myInfo.info.sex': '性別',
  'myInfo.info.role': '角色',
  'myInfo.info.dept': '所屬部門',
  'myInfo.info.position': '職位',
  'myInfo.info.tel': '手機號',
  'myInfo.info.email': '郵箱',
  'myInfo.info.male': '男',
  'myInfo.info.female': '女',

  'myInfo.info.userName': '賬號',
  'myInfo.info.back': '返回上一頁',
};
