export default {
  'loop.rule': '重複規則',
  'loop.reserve': '循環預訂',
  'start.date': '開始日期',
  'booking.repeat.minutes': '分鐘',
  'cycle.tab.loop': '循環，',
  'cycle.tab.loop.everyworkday': '每個工作日循環，',
  'cycle.tab.loop.everyday': '每{day}天循環一次，',
  'cycle.tab.loop.everyMonth': '每月第{day}天循環，',
  'cycle.tab.loop.text.label': '從{startTime}-{endTime}，{startDate}開始，循環{count}次',
  'cycle.tab.loop.text.label2': '從{startDate}開始，循環{count}次',
  'cycle.tab.loop.text.label.date': '從{startTime}-{endTime}，{startDate}開始，{endDate}結束',
  'cycle.tab.loop.text.label.date2': '從{startDate}開始，{endDate}結束',

  'cycle.tab.loop.month.week': '第{weekCount}個星期{week}',
  'cycle.tab.loop.month.week.last': '最後一個星期{week}',
  'cycle.tab.loop.month2.week': '每{freq}個月第{weekCount}個星期{week}',
  'cycle.tab.loop.month2.week.last': '每{freq}個月最後一個星期{week}',
  'cycle.tab.loop.year.date': '{month}月{day}日',
  'cycle.tab.loop.year2.date': '每年{month}月{day}日',
  'cycle.tab.loop.year.week': '{month}月的第{weekCount}個星期{week}',
  'cycle.tab.loop.year.week.last': '{month}月的最後一個星期{week}',
  'cycle.tab.loop.year2.week': '每年{month}月的第{weekCount}個星期{week}',
  'cycle.tab.loop.year2.week.last': '每年{month}月的最後一個星期{week}',

  meeting_cycle_rule_by_day: '每{freq}天循環1次',
  meeting_cycle_rule_by_week:
    '每{freq}個周的{weekStr}循環1次',
  meeting_cycle_rule_by_weekday:
    '每{freq}個工作日循環1次',
  meeting_cycle_rule_by_month:
    '每{freq}個月的第{mexrule}天循環1次',
  meeting_cycle_rule_by_month2:
    '每{freq}個月的最後一天循環1次',

  meeting_cycle_booking_error_tips: '重複結束時間不能早於會議開始時間!',

  'meeting.booking.step.re_day': '日',
  'meeting.booking.step.every.workday': '個',
  'meeting.booking.step.rework_day': '工作日',
  'meeting.booking.step.re_week': '周',
  'meeting.booking.step.re_week_double': '两周',
  'meeting.booking.step.re_month': '月',
  'meeting.booking.step.re_year': '年',
  'meeting.booking.step.re_every': '每',
  'meeting.booking.step.re_every_md': '每月第',
  'meeting.booking.cycle.end': '結束重複',
  'meeting.booking.cycle.endDate': '結束時間',
  'meeting.booking.cycle.endTimes': '重複次數',
  'meeting.booking.cycle.error': '未選擇會議室且當前用戶未設置默認區域，請前往設置',
  meeting_cycle_booking_error_tips2: '預約次數至少1次!',
  meeting_poly_check_least_one:'至少選擇一個視頻會議類型！',
  meeting_cycle_fre_last_day: '最後1',
  meeting_cycle_modal_last_day: '最後1天',
  meeting_cycle_modal_title: '選擇天',
};
