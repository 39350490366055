import { useSelector } from 'umi';
import { useState, useEffect } from 'react';
import useRequest from '../useRequest';
import * as service from './service';

function useGetVisitTargets() {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { tenantId = null } = user || {};
  const { currentUser } = useSelector((state: any) => state.user);
  const [visitTargets, setVisitTargets] = useState(null);

  const {
    data: visitTargetsData,
    loading: getVisitTargetsLoading,
    runAsync: runGetVisitTargets,
    cancel: cancelGetVisitTargets,
  } = useRequest(
    (params: any) =>
      service.getVisitTargets({
        ...params,
        companyId: tenantId || currentUser?.tenantId,
      }),
    {
      manual: true,
      debounceWait: 300,
    },
  );

  useEffect(() => {
    setVisitTargets(visitTargetsData?.resObj || []);
  }, [visitTargetsData]);

  return {
    visitTargets,
    getVisitTargetsLoading,
    runGetVisitTargets,
    setVisitTargets,
    cancelGetVisitTargets,
  };
}

export default useGetVisitTargets;
