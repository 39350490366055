export default {
  'sws.myBooking.station': 'Space',
  'sws.myBooking.station.colon': 'Space：',
  'sws.myBooking.space.colon': 'Location：',
  'sws.myBooking.check.in': 'Check In',
  'sws.myBooking.info': 'Tips',
  'sws.myBooking.station.chose.cancel': 'Please make sure want to cancel that selected space?',
  'sws.myBooking.station.using': 'This space is occpied by YOU now：',
  'sws.myBooking.station.booking': 'This space is reserved by YOU now：',
  'sws.station.info': 'Info of Space',
  'sws.cancel.station': 'Cancel Booking',
  'sws.check.station': 'Navigation',
  'sws.myBooking.station.using.or.reserve':
    'NOT find have any of record of space in use or reserved',
  'sws.myBooking.cancel.reserve.station': 'Please make sure want to cancel that reserved space?',
  'sws.myBooking.logout.station.using': 'Please make sure want to check out before scheduled?',
  'sws.myBooking.space': 'Location',
  'sws.myBooking.timeZone': 'Time Zone',
  'sws.myBooking.booking.time': 'Scheduled Time',
  'sws.myBooking.booking.cancel': 'Cancel Reservation',
  'sws.myBooking.booking.time.colon': 'Scheduled Time：',
  'sws.myBooking.used.time.colon': 'Scheduled Time：',
  'sws.myBooking.canceled': 'Cancelled',
  'sws.myBooking.used': 'Past',
  'sws.myBooking.using': 'In Use',
  'sws.myBooking.reserve.again': 'Book Again',
  'sws.myBooking.delay': 'Extend',
  'sws.myBooking.logout': 'Cancel Reservated',
  'sws.myBooking.station.num': 'Total of {num} Space',
  'sws.history.not.booking': 'You have sheduled space, NOT make duplicate of it',
  'sws.history.no.auth': 'You don’t have permission, Please select anothers',
  'sws.history.reBook': 'Booking Again',
  'sws.history.not.booked': 'You don’t have any of history that reservation',
  'sws.reserve.delay.success': 'Extend Successfully!',
  'sws.chose.time.delay': 'Choose to extend the time',
  'sws.can.not.delay': 'Unavalible to Extend',
  'sws.can.not.reverse': 'Special Booking is NOT support extend to use',
  'sws.no.reverse': 'Notice : You don’t have any of booked space',
  'sws.no.reverse.history': 'Notice : You don’t have any of booked history',
  'sws.myBooking.reverse': 'Booking in Now',
  'sws.myBooking.common': 'Regular Booking',
  'sws.myBooking.normal': 'Quick Reservation',
  'sws.myBooking.special': 'Special',
  'sws.myBooking.category': 'Category：',
  'sws.myBooking.not.fixed': 'Cannot make a reservation for a fixed station',
  'sws.myBooking.total': 'Total: ',
  'sws.myBooking.set': 'Set',
  'sws.myBooking.view.detail': 'Details',
  401115: 'Special Booking is NOT support extend to use',
  401139: 'Reservation record does not exist',
  'sws.myBooking.confirm.tip': 'Tips',
  'sws.myBooking.confirm.ok': 'Confirm',
  'sws.myBooking.confirm.no': 'Cancel',
};
