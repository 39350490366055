/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  602001: 'User name cannot be empty',
  602002: 'Failed to get the URL address of the uploaded face',
  602003: 'Failed to upload the third party face database',

  603001: 'QR code does not exist, please check',

  603002: 'The number of uses of the QR code has reached the maximum',
  603003: 'The total validity period of the QR code has expired',

  601013: 'Successful Operation',

  601028: 'Failed to add or update the access control repair report',

  601029: 'Door opening failure. Please check the configuration.',

  'menu.sas.app.smartaccess': 'Smart access',
  'menu.sas.app.qrCode': 'My QR code',
  'menu.sas.app.faceDatabase': 'Face Library',
  'menu.sas.app.faultRepair': 'Malfunction Report',
  'menu.sas.app.myAccess': 'My Access',

  601034: 'Access control attribution zone is empty! ',
  601035: 'The simulated door opening failed! ',
  601036: 'The Pattern attribute value of the configuration file is empty! ',
  601037: 'The controller number is empty! ',
  601038: 'The door object is empty, please check if the access control configuration exists! ',
  601039: 'Access control status disabled',
  601040: 'The controller cannot be connected or the controller number is wrong',
  601041: 'Logical gate number error',
  601042: 'Access control is disabled',
  601043: 'The QR code has expired or reached its maximum number of times',
  602006: 'File is empty! ',

  601044: 'Failed to open the door! The logic gate number is not set correctly',
  601045: 'The command was not sent successfully! Please check if the controller is connected and controllerSN configuration',

  601046: 'Current card number does not exist ',
  601047: 'File is empty! ',
  601048: 'Zip unzip exception ',
  601049: 'No opening permission',
  601100: 'Face not found during registration ',
  601101: 'Multiple faces when registering ',
  601102: 'Face too small for registration ',
  601103: 'Photo blurred at registration ',
  601104: 'Face not found during recognition ',
  601105: 'Face too small or blurry for recognition ',
  601106: 'Face group does not exist during recognition ',
  601107: 'Face group does not exist when deleted ',
  601108: 'Staff ID does not exist when deleted ',
  601109: 'Delete the photo file corresponding to the person ID does not exist ',
  601110: 'Face group does not exist when user ID query ',
  601111: 'Start serial number is greater than end serial number when user ID is inquired ',
  601112: 'Face group does not exist when face image query under user ID ',
  601113: 'User ID does not exist when querying face image under user ID ',
  601114: 'Face image file does not exist when query user ID face image ',
  601115: 'When downloading the feature file, the file does not exist ',

  601116: 'Face recognition query is empty',
  601117: 'The face cannot be recognized',
  601118: 'The face photo is repeated with another user',
  601119: 'The face threshold is set incorrectly',
  601120: 'The face photo is inconsistent with the user’s existing face photo',
  601065: 'Wafer face database function not enabled',
  601070: 'Failed to get user name',
  601071: 'Data length exceeds limit',
  601121: 'Face mask ',
};
