export default {
  'locker.booking.please.select': 'Please Select',
  'locker.booking.already.reserved.tip':
    'You have reserved a locker, and reservations for multiple lockers are not supported!',
  'sws.tip.locker.location.none': 'You have no location information now',
  'sws.tip.locker.full':
    "The lockers in this area are full, so let's make an appointment in another area~",
  'locker.station.location.none': 'You have not selected location information, please select',
  403037: 'The number of appointment days exceeds the system limit',
  403039: 'This locker has been reserved by someone else during the selected time',
};
