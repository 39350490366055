import globalHeader from './zh-TW/globalHeader';
import settings from './zh-TW/settings';
import lighting from './zh-TW/lighting';
import meeting from './zh-TW/meeting';
import error from './zh-TW/error';
import intl from './zh-TW/intl';

export default {
  'navBar.lang': '語言',
  'layout.user.link.help': '幫助',
  'layout.user.link.privacy': '隱私',
  'layout.user.link.terms': '條款',
  'app.home.introduce': '介紹',
  'app.system.initialization': '系統正在加載，請稍後...',
  'app.loading': '加載中...',
  pageNotFound: '頁面未找到',
  'go.index': '返回首頁',
  'user.currentUser.error': '接口無法獲取用戶信息，請稍後再試',
  'user.currentUser.error2': '用戶信息中缺失userId，請稍後再試',
  ...globalHeader,
  ...settings,
  ...lighting,
  ...error,
  ...meeting,
  ...intl,
};
