export default {
  403035: '當前處於非工作時間，不支持立即使用！',
  403036: '您沒有權限使用該儲物櫃！',
  403038: '未到簽到時間，無法簽到！',
  'scan.signed.result.success.tip': '恭喜您，操作成功！',
  403040: '所選時間處於非工作時間，請選擇其他時間進行預訂！',
  403050: '用戶{value1}在該組中已擁有共享儲物櫃，暫不支持使用兩種儲物櫃！',
  403051: '用戶{value1}在該組中已擁有固定儲物櫃，暫不支持使用兩種儲物櫃！',
  403063: '您的儲物櫃不在當前{value1}櫃，請移步至 {value2}櫃，櫃號為{value3} 尋找您的儲物櫃！'
};
