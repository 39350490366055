export default {
  'sws.sensor.station.tips': '您已经{value1}分钟没有使用您预订的工位了，请问是否要继续使用？',
  'sws.sensor.cancel': '是否立即释放该工位？',
  'sws.sensor.immediate.release':'立即释放',
  'sws.sensor.continue.to.use':'继续使用',
  'sws.sensor.release.tips':'已为您成功释放该工位',
  'sws.sensor.continue.tips':'已为您成功保留该工位',
  'sws.sensor.hello':'，您好：',
  401147:'操作过期，预订已结束',
  401148:'请勿重复操作',
};
