export default {
  'meeting.region.btn.select': 'Search',
  'meeting.region.search.choice': 'Select',
  'meeting.region.search.startDate': 'Date',
  'meeting.region.search.startTime': 'Time',
  'meeting.region.search.timeLong': 'Time Long',
  'meeting.region.search.minute': ' mins',
  'meeting.region.search.reset': 'Reset',
  'meeting.region.search.submit': 'OK',
  'meeting.region.search.time': 'Time',
};
