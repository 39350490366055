import { matchPath, history, formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import SockJS from 'sockjs-client';
import moment from 'moment-timezone';
import * as detailService from '@/pages/Meeting/services/meetingDetail';
import * as subscribeService from '@/pages/Meeting/services/subscribe';
import * as controlService from '@/pages/Meeting/services/meetingControl';
import * as zoneService from '../services/zoneService';
import * as svcService from '@/pages/Meeting/pages/SvcFeeDetail/service';
import {
  ATTENDEE_STATUS,
  DATE_FORMAT,
  MEETING_STATUS,
  MENU_PATH,
  RES_STATUS,
  MEETING_BOOK_TYPE,
  SEMICOLON,
} from '../utils/constant';
import { BPMSTATE } from '@/pages/Meeting/utils/constant';

export default {
  namespace: 'meetingDetail',
  state: {
    meetingId: 0,
    webExStatus: -1,
    fbServer: '',
    roomValidInfo: {
      // 延长会议需要用到的会议室信息
      avaliableStart: '00:00', // 会议室可用开始时间
      avaliableEnd: '24:00', // 会议室可用结束时间
      schedules: [],
      isHoliday: '0', // 当天是否可以预约，0可以，1不可以
    },
    showDeleteModal: false,
    agendaList: [],
    moduleConfig: {},
    currentPage: 0,
    pollingData: {},
    currentMeetingUser: {},
    // 是否为纯网络会议
    isWebMeeting: false,
    newMeetingAgenda: [],
    from: 0,
    svcTotal: { total: 0, unConfirm: 0 },
    signStatu: -1, // 回执状态（-1 未响应，0接受，1拒绝，2待定）
    isAttend: false, // 是否参会人/主持人
    meetingSvc: [], // 当前会议服务
    meetingDevice: [],
    currentTimeZone: sessionStorage.getItem('curTimeZone'), // 时区。用于议程处理
    cmmaInfo: {
      // meetingNumber:'111',
      // joinPwd:'123',
      // joinWebRtc:'www.baidu.com',
      // hostWebRtc:'www.baidu.com',
    },
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *reload({ meetingId }, { select, call, put }) {
      const { data, code, msg } = yield call(detailService.getMeetingDetail, meetingId);
      const { zoom = { advanceEnterTime: 10 } } = yield select((state) => state.sysModule);

      if (code === RES_STATUS.failure) {
        Toast.fail(formatMessage({ id: msg }));
        return;
      }
      const { meetingInfo = {}, currentMeetingUser, meetingWebex } = data;
      // 判断是否为纯网络会议
      let isWebMeeting = false;
      const rooms = meetingInfo.roomIds.split(';');
      if (
        Number(meetingInfo.synCapacity) === 0 &&
        (Number(meetingInfo.bookType) === MEETING_BOOK_TYPE.ZOOM ||
          Number(meetingInfo.bookType) === MEETING_BOOK_TYPE.WEBEX) &&
        rooms.length === 1
      ) {
        isWebMeeting = true;
      }

      const { bookType } = meetingInfo;
      const newPayload = {};
      const { data: d } = yield call(detailService.getZoomUrl);
      newPayload.fbServer = d;
      if (bookType === 1) {
        // cmma视频会议
        const result = yield call(controlService.cmmaInfo, meetingId);
        newPayload.cmmaInfo = result.data;
        newPayload.participantList = result.data.participants;
      } else if (bookType === 2) {
        newPayload.zoomStatus =
          moment().isAfter(moment(meetingInfo.startTime).subtract(zoom.advanceEnterTime, 'm')) &&
          moment().isBefore(meetingInfo.endTime) &&
          (meetingInfo.status === MEETING_STATUS.pending ||
            meetingInfo.status === MEETING_STATUS.opened)
            ? 0
            : -1;
      } else if (bookType === 3) {
        // webEx类型会议
        // const result = yield call(detailService.checkWebEx, { meetingId });
        // const { enable, fbServer } = result.data;
        // newPayload.webExStatus = enable ? 0 : -1;
        // newPayload.fbServer = fbServer;
        newPayload.fbServer = meetingWebex?.webexJoinUrl;
      }
      yield put({
        type: 'subscribe/update',
        payload: { startNow: false, bookType, isWebMeeting },
      });
      // 手机号超过20位不在前端展示，只在后端存储
      if(data?.outerUser?.length>0){
        data?.outerUser?.forEach((item) => {
          if (item?.mobile.length > 19) {
            item.phoneTemp = item.phone;
            item.phone = '';
          }
        });
      }
      newPayload.detail = data;
      newPayload.currentMeetingUser = currentMeetingUser;
      yield put({ type: 'update', payload: newPayload });
    },
    *getReplay({ meetingId }, { call, put }) {
      // 获取当前用户回执
      const { data, code, msg } = yield call(detailService.getReplay, meetingId);
      if (code === 0) {
        yield put({
          type: 'update',
          payload: { signStatu: data?.status, isAttend: data ? true : false },
        });
      } else {
        yield put({
          type: 'update',
          payload: { signStatu: -1, isAttend: false },
        });
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *receiptOption({ meetingId, status, userId, userType, remark }, { call, put }) {
      const param = { meetingId, status, userId, userType, remark };
      const result = yield call(detailService.receiptOptionFuc, { param });
      if (result.code === 0) {
        yield put({ type: 'getReplay', meetingId });
        yield put({ type: 'reload', meetingId });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
      return result;
    },
    *loadRoomValidInfo(_, { select, call, put }) {
      const { detail = {} } = yield select((state) => state.meetingDetail);
      const { meetingInfo } = detail;
      if (meetingInfo) {
        const { roomIds, startTime, bookType } = meetingInfo;
        if (startTime && roomIds) {
          const { data, msg, code } = yield call(subscribeService.getValidInfo, {
            chooseDate: moment(startTime).format(DATE_FORMAT),
            roomIds,
            bookType,
            timeZone: sessionStorage.getItem('curTimeZone') || '',
          });
          if (code === RES_STATUS.success) {
            yield put({ type: 'update', payload: { roomValidInfo: data } });
          } else {
            Toast.fail(formatMessage({ id: msg }));
          }
        }
      }
    },
    *getMeetingOther({ meetingId }, { call, put }) {
      const result = yield call(svcService.getMeetingOther, meetingId);
      if (result.code === 0) {
        const { data = {} } = result;
        const { meetingSvc = [], meetingDevice = [], meetingSvcRooms = [] } = data;
        const meetingSvcRoomsId = meetingSvcRooms.map((item) => item.roomId);
        let unConfirm = 0;
        let total = 0;

        const newMeetingSvc = meetingSvc.filter((item) => meetingSvcRoomsId.includes(item.roomId));
        const newMeetingDevice = meetingDevice.filter((item) =>
          meetingSvcRoomsId.includes(item.supplementInfo.roomId),
        );
        newMeetingSvc.forEach((item) => {
          const svcTotal = item.svcCost || 0;
          if (
            item?.bpmState === BPMSTATE.APPROVALING ||
            item?.bpmState === BPMSTATE.TOBESERVED ||
            item?.bpmState === BPMSTATE.ENDED
          ) {
            total += parseFloat(svcTotal);
          }
          if (item.bpmState === BPMSTATE.APPROVALING) {
            unConfirm += parseFloat(svcTotal);
          }
        });
        console.log(newMeetingSvc, newMeetingDevice);
        yield put({
          type: 'update',
          payload: {
            svcTotal: { total, unConfirm },
            meetingSvc: newMeetingSvc,
            meetingDevice: newMeetingDevice,
          },
        });
      } else {
        yield put({
          type: 'update',
          payload: { svcTotal: { total: 0, unConfirm: 0 }, meetingSvc: [], meetingDevice: [] },
        });
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *cancel({ param }, { select, call }) {
      const { pathname } = yield select((state) => state.common);
      const { msg, code } = yield call(detailService.cancelMeeting, param);
      if (code === RES_STATUS.success) {
        Toast.success(formatMessage({ id: msg }));
        history.replace(pathname);
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *close({ meetingId }, { call, put }) {
      const { msg, code } = yield call(detailService.closeMeeting, { meetingId });
      if (code === RES_STATUS.success) {
        Toast.success(formatMessage({ id: msg }));
        yield put({ type: 'reload', meetingId });
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *delay({ meetingId, interval }, { call, select }) {
      const { tenantId } = yield select((state) => state.user.currentUser);
      const { data } = yield call(zoneService.getAllRooms, { tenantId });
      const {
        code,
        msg,
        data: data2,
      } = yield call(detailService.delayMeeting, { meetingId, interval });
      const { meetings } = yield select((state) => state.myMeeting);
      const { base } = yield select((state) => state.sysModule);
      if (msg === '207012') {
        let maxTime = 0;
        const info = meetings.filter((item) => item.meetingId === Number(meetingId));
        const selectRoomId = info[0].roomIds.split(SEMICOLON);
        const roomLists = data.filter((item) => selectRoomId.indexOf(item.roomId.toString()) > -1);
        roomLists.forEach((r) => {
          if (r.maximumTime > 0) {
            if (maxTime === 0) {
              maxTime = r.maximumTime;
            } else {
              maxTime = maxTime < r.maximumTime ? maxTime : r.maximumTime;
            }
          }
        });
        maxTime = maxTime === 0 ? Number(base.meetingRoomMaxTime) : maxTime;
        return { maxTime, msg };
      }
      return { code, msg, data: data2 };
    },
    // *jumpToWebEx({ meetingId, userName, email, joinType }, { select }) {
    //   const { fbServer } = yield select((state) => state.meetingDetail);
    //   let fbUrl = fbServer;
    //   if (fbUrl && !fbUrl.endsWith('/')) {
    //     fbUrl += '/';
    //   }
    //   const webexUrl = `${fbUrl}webex?meetingId=${meetingId}&type=${joinType}&userName=${userName}&email=${email}`;
    //   const deviceAgent = navigator.userAgent;
    //   if (deviceAgent.toLowerCase().match(/(iphone|ipod|ipad)/)) {
    //     document.location.href = webexUrl;
    //   } else {
    //     window.open(webexUrl);
    //   }
    // },
    *jumpToWebEx({ payload }, { select }) {
      const { fbServer } = yield select((state) => state.meetingDetail);
      window.open(fbServer);
    },
    *jumpToZoom({ meetingId, userId, joinType }, { select }) {
      const { fbServer } = yield select((state) => state.meetingDetail);
      let fbUrl = fbServer;
      if (fbUrl && !fbUrl.endsWith('/')) {
        fbUrl += '/';
      }
      const zoomUrl = `${fbUrl}f?p=1-${meetingId}-${userId}-${joinType}`;
      const deviceAgent = navigator.userAgent;
      if (deviceAgent.toLowerCase().match(/(iphone|ipod|ipad)/)) {
        document.location.href = zoomUrl;
      } else {
        window.open(zoomUrl);
      }
    },
    *getMeetingAgenda({ meetingId }, { call, select, put }) {
      const result = yield call(detailService.getMeetingAgenda, { meetingId });
      const { messages } = yield select((state) => state.i18n);
      if (result && result.code === 0) {
        const { data = {} } = result;
        yield put({
          type: 'update',
          payload: {
            newMeetingAgenda: data.agenda ? data.agenda : [],
          },
        });
      } else {
        Toast.fail(messages[result.msg]);
      }
    },
    *connectWS({ dispatch, meetingId }, { select }) {
      const { locale } = yield select((state) => state.i18n);
      const { userInfo } = yield select((state) => state.common);
      const { domainName } = userInfo;
      if (window.mc_ws) {
        window.mc_ws.close();
      }
      window.mc_ws = new SockJS(
        `/socket/websocket/?email=${meetingId}&domainName=${domainName}&lang=${locale}`,
      );
      window.mc_ws.onmessage = (result) => {
        const msg = JSON.parse(result.data);
        if (msg.data === 'cmmaStart') {
          dispatch({ type: 'meetingDetail/reload', meetingId });
        }
      };
    },
    *loadForDelayMeeting({ meetingId }, { put, take, select }) {
      yield put({ type: 'reload', meetingId });
      yield take('reload/@@end');
      yield put({ type: 'loadRoomValidInfo' });

      const { detail } = yield select((state) => state.meetingDetail);
      if (
        detail &&
        detail.meetingInfo &&
        detail.meetingInfo.timeZone &&
        detail.meetingInfo.startNow === 1
      ) {
        moment.tz.setDefault(detail.meetingInfo.timeZone);
      }
    },
    *loadPollingInfo({ meetingId }, { put, call }) {
      const { code, data } = yield call(detailService.getZoomPolling, { meetingId });
      if (code === 0) {
        yield put({ type: 'update', payload: { pollingData: data || {} } });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.meetingDetail)) {
          const { query } = history.location;
          const { meetingId, fromType } = query || {};
          dispatch({ type: 'update', payload: { fromType } });
          dispatch({ type: 'reload', meetingId });
          dispatch({ type: 'getMeetingAgenda', meetingId });
          dispatch({ type: 'getMeetingOther', meetingId });
          dispatch({ type: 'getReplay', meetingId });
        } else if (matchPath(pathname, MENU_PATH.delay)) {
          const { query } = history.location || {};
          const { meetingId } = query || {};
          dispatch({ type: 'loadForDelayMeeting', meetingId });
        } else if (matchPath(pathname, MENU_PATH.zoomPolling)) {
          const { query } = history.location;
          const { meetingId } = query || {};
          dispatch({ type: 'loadPollingInfo', meetingId });
        }
        if (!matchPath(pathname, MENU_PATH.delay)) {
          moment.tz.setDefault(sessionStorage.getItem('curTimeZone'));
        }
      });
    },
  },
};
