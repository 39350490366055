export default {
  'sws.booking.user.choice': '您所選擇的工位：',
  'sws.booking.already.occupied': '該工位已經被佔用',
  'sws.booking.already.occupied.tip': '該工位已經被佔用，請選擇其他時段或其他工位',
  'sws.booking.get.off.work': '所選區域已過下班時間，不支持預訂',
  'sws.booking.no.worktime': '不在上班時間，不支援使用或預訂',
  'sws.booking.get.no.time': '未設置工作日時間',
  'sws.booking.time.no.worktime': '所選時間處於非上班時間{value1}，不支持使用或預訂',
  'sws.booking.no.location': '請選擇區域和時間',
  'sws.booking.no.location.tip': '請先選擇區域',
  'sws.booking.no.select.date': '請選擇日期',
  'sws.booking.no.select.startTime': '請選擇開始時間',
  'sws.booking.no.select.endTime': '請選擇結束時間',
  'sws.booking.limit.date': '跨天最多支持預約 {value1} 天',
  'sws.booking.time.error': '開始時間不能小於當前時間，請重新選擇開始時間',
  'sws.booking.back': '返回',
  'sws.booking.local': '本地區域',
  'sws.booking.share': '共享區域',
  'sws.param.booking.time': '時間',
  'sws.nodata.tip.title': '當前無可預訂工位，請聯系管理員添加地圖。',
  'sws.nodata.tip.desc': '如何添加地圖，幫助地址： https://cloud.virsical.cn/help ',
  401112: '預訂天數超過系統限制',
  401113: '不在工作時間內，無法預訂',
  401135: '已存在預訂狀態工位',
  401136: '已存在使用狀態工位',
  401134: '預約數量已超限，請先釋放已預約或已使用的工位',
  401058: '預約天數超出開放日期限制',
  401059: '預訂次數超過{value1}次',
  401060: '跨天預約天數超出限制',
  'map.color.setting.material.status.1': '空閑',
  'map.color.setting.material.status.2': '佔用',
  'map.color.setting.material.status.3': '不可用',
  401145: '預約開始時間不能大於結束時間',
  401146: '結束時間已過下班時間，無法預約',
  401153: '您擁有指定區域範圍內的固定工位，暫不支持共享工位預訂',
  401154: '您不支持跨天預訂工位',
  401155: '{value1}有效預訂次數超過限制',
  401156: '有效預訂時長超出{value1}小時',
};
