import { AMAP_KEY } from '@/pages/Visitor/utils/constant';
import { DEFAULT_INTERFACE, RESTFUL_PATH } from '@/utils/constant';
import { convertBase64UrlToBlob } from '@/utils/utils';
import { requestNoToken } from '../utils/request';
import request from '@/utils/request';

export const getVisitType = ({ id }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/vType?id=${id}`);
};

export const saveReservation = ({ reservation }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations`, {
    // method: Object.prototype.hasOwnProperty.call(reservation, 'preRegistrationId') ? 'PUT' : 'POST',
    method: 'POST',
    body: JSON.stringify(reservation),
  });
};

export const loopBooking = ({ reservation }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/loopBooking`, {
    // method: Object.prototype.hasOwnProperty.call(reservation, 'preRegistrationId') ? 'PUT' : 'POST',
    method: 'POST',
    body: JSON.stringify(reservation),
  });
};
export const modifyReservation = ({ reservation }) => {
  if (reservation.isSubset || reservation.visitorSelfFlag === 3) {
    return request(`/${RESTFUL_PATH.visitor}/api/invitations/modify`, {
      // method: Object.prototype.hasOwnProperty.call(reservation, 'preRegistrationId') ? 'PUT' : 'POST',
      method: 'POST',
      body: JSON.stringify(reservation),
    });
  } else {
    if (
      reservation.children.length !== reservation.visitors.length ||
      reservation.visitors.length > 1 ||
      (reservation.visitors.length === 1 && !reservation.visitors[0].preRegistrationId)
    ) {
      // 父级数据批量变更
      return request(`/${RESTFUL_PATH.visitor}/api/invitations/batchModify`, {
        method: 'POST',
        body: JSON.stringify(reservation),
      });
    } else {
      return request(`/${RESTFUL_PATH.visitor}/api/invitations/modify`, {
        // method: Object.prototype.hasOwnProperty.call(reservation, 'preRegistrationId') ? 'PUT' : 'POST',
        method: 'POST',
        body: JSON.stringify(reservation),
      });
    }
  }
};
export const cancelReservation = ({ reservation }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/cancel`, {
    // method: Object.prototype.hasOwnProperty.call(reservation, 'preRegistrationId') ? 'PUT' : 'POST',
    method: 'POST',
    body: JSON.stringify(reservation),
  });
};

export const getReservationList = ({ page, vstKeyword, rows, from }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/app/invitations?page=${page}&vstKeyword=${encodeURIComponent(
      vstKeyword,
    )}&rows=${rows}&from=${from}`,
  );
};

export const download = ({ companyId }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/download?companyId=${companyId}`);
};

export const getAllMeetingRoom = ({
  startTime,
  meetingDuration,
  meetingId,
  bookType,
  name,
  seat,
  deviceIds,
  zoneIds,
}) => {
  return request(
    `/${
      RESTFUL_PATH.visitor
    }/api/meeting?startTime=${startTime}&meetingDuration=${meetingDuration}&meetingId=${
      meetingId || ''
    }&bookType=${String(bookType) === '1' ? bookType : 0}&name=${encodeURIComponent(
      name,
    )}&seat=${seat}&deviceIds=${deviceIds}&zoneIds=${zoneIds || ''}`,
  );
};

/* 获取办公地 名称与id */
export const getArea = () => {
  return request(`/${RESTFUL_PATH.visitor}/api/config/area/user`);
};

export const hint = ({ keywords }) => {
  return request(
    `/${DEFAULT_INTERFACE}/v3/assistant/inputtips?key=${AMAP_KEY}&keywords=${keywords}`,
  );
};
/* 访客自主预约 确认 拒绝接口  */
export const confirmReservation = ({ reservation }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/visitors/confirmdata`, {
    method: 'POST',
    body: JSON.stringify(reservation),
  });
};

export const confirmVisitor = ({ visitorDataId, decision }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/auditRegister`, {
    method: 'POST',
    body: JSON.stringify({ visitorDataId, decision }),
  });
};

export const getInviteStatus = () => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/inviteStatus`);
};

export const getVisitList = ({ page, vstKeyword, rows, from }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/app/visitorDatas?page=${page}&vstKeyword=${encodeURIComponent(
      vstKeyword,
    )}&rows=${rows}&from=${from}`,
  );
};

export const signOut = ({ visitorData }) => {
  // return request('/${RESTFUL_PATH.visitor}/api/visitors/sign_out', { method: 'PUT', body: JSON.stringify(visitorData) });
  return request(`/${RESTFUL_PATH.visitor}/api/visitors/signOut`, {
    method: 'POST',
    body: JSON.stringify(visitorData),
  });
};

export const getConfirmData = ({ page, rows }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/visitors/confirmdata?page=${page}&rows=${rows}`);
};

export const auditRegister = ({ register }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/auditRegister`, {
    method: 'POST',
    body: JSON.stringify(register),
  });
};

export const getVisitorStatus = () => {
  return request(`/${RESTFUL_PATH.visitor}/api/visitors/visitorStatus`);
};
export const getVisitorAccompany = ({ visitorData }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/visitors/associators?visitorDataId=${visitorData.visitorDataId}&name=&phone=`,
  );
};
export const getVisitorAccompanyConfrim = ({ visitorData }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/visitors/associatorsByPreId?preId=${visitorData.preRegistrationId}&name=&phone=`,
  );
};

// 删除
export const deleteReservation = (payload) => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/delInvite`, {
    method: 'POST',
    body: JSON.stringify(payload.selectedRows),
  });
};

export const getPreRedirectUrl = ({ icl }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/whiteList/getPreRedirectUrl?icl=${icl}`);
};

// 删除
export const getHolidayByAreaId = (areaId) => {
  return request(`/${RESTFUL_PATH.visitor}/api/whiteList/getHolidayByAreaId?areaId=${areaId}`);
};

// 获取隐私政策
export const getPrivacyPolicyData = ({ companyId, formType = 2, locationManageId }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/whiteList/getDataFormTemplate/${companyId}/${formType}?locationManageId=${locationManageId}`,
  );
};

// 获取保密协议
export const getNdaData = ({ companyId, locationManageId }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/nda?companyId=${companyId}&locationManageId=${locationManageId}`,
  );
};

/**
 * 上传base64格式文件
 * @param params
 * @returns
 */
export function uploadBase64File(params) {
  const { image, businessCode } = params || {};
  const data = new FormData();
  const file = convertBase64UrlToBlob(image);
  data.append('file', file);
  data.append('pubOrNot', true);
  data.append('serviceName', 'vst-visitor');
  data.append('productCode', 'vst');
  data.append('businessCode', businessCode);
  return requestNoToken(
    `/${RESTFUL_PATH.visitor}/sss/upload`,
    { method: 'POST', body: data },
    true,
  );
}

// 保存邀请函页面用户阅读隐私、保密、签名操作
export const saveVisitorAction = ({ params }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/whiteList/saveVisitorAction `, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};
