/**
 * Created : fangkemeng
 * Date : 2019-10-12  10:00
 * Email : fangkemeng@wafersystems.com
 */
import { formatMessage, history } from 'umi';
import { Toast } from 'antd-mobile-v2';
import moment from 'moment-timezone';
import { ROUTE_PATH } from '@/utils/constant';
import {
  isOffWork,
  changeTimeAfter,
  changeTimeAfter as changeTimeAfterFun,
  changeTimeBefore,
  isTimeWorkTime,
  allAmPm,
  onlyPm,
  ALL_VALUE,
  CUSTOM_VALUE,
  selectTime,
  AM_VALUE,
  PM_VALUE,
  TIME_FORMAT
} from '../../utils/timeTools';
import * as recommendBookingService from './service';
import { getAdvanceTime, resAdvanceDateDisable } from '@/pages/WorkSpace/utils/tools'

export default {
  namespace: 'swsRecommendBooking',
  state: {
    loading: false,
    showAlertModal: false,
    showReservationResult: false,
    resultStatus: 0,
    startDate: undefined, // 开始日期
    endDate: undefined, // 结束日期
    startTime: undefined, // 开始时间
    endTime: undefined, // 开始时间
    selectAmPm: '',
    // todo 干啥的
    date: '',
    // todo 干啥的
    amPm: '',
    // 时间选择结果
    choseResult: {
      timeSelected: '',
      stationNum: '',
      // 开始结束日期
      startDate: '',
      endDate: '',
      // 开始结束时间
      startTime: '',
      endTime: '',
    },
    againLocationId: '',
    failReason: '',
    reloadMap: false,
    selectedEle: [],
    timeText: '', // 预订页面需要回显的时间
    recommendObj: {},
    clickableElements: []
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 预订确认操作
    *confirmReservation({ payload, timeZone }, { call, select }) {
      const result = yield call(recommendBookingService.confirmReservation, payload);
      if (result && result.code === 0) {
        const { startTime = '', endTime = '' } = result?.data?.[0]
        Toast.hide();
        const { station, startDate, endDate, selectAmPm, mapId, locationId } = yield select(
          (state) => state.swsRecommendBooking,
        );
        const locationNames = station?.locationName?.split(',').slice(-3);

        let timeSelected = '';
        timeSelected = selectTime(selectAmPm, startDate, endDate, moment(startTime), moment(endTime));
        const stationInfo = { ...station, startTime, endTime, mapId, locationId }
        history.push({
          pathname: `${ROUTE_PATH}/workspace/common/components/bookingResult`,
          state: {
            choseResult: {
              timeSelected,
              stationNum: stationInfo.stationNum,
            },
            station: stationInfo,
            locationNames,
            timeZone,
            bookType: 'recommendBook',
          },
        });
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
    // *selectedEle({ payload, callback }, { put, select }) {
    //   const { elementId } = payload;
    //   const { mapData } = yield select((state) => state.swsCommon);
    //   const element = mapData.find((d) => {
    //     return d.mapElementId === elementId;
    //   });
    //   // console.log('selectedEle element', element)
    //   if (element) {
    //     callback([element]);
    //     yield put({
    //       type: 'saveOrUpdateData',
    //       payload: {
    //         selectedEle: [element],
    //       },
    //     });
    //   }
    // },
    // 在位置树获取后尝试初始化时间
    *initTime({ payload, callback = () => { } }, { put }) {
      const { timeZone, globalTime, systemSet: { reserve_time_mode }, workday, swsCommon,
        currentDate = undefined,
        init = false } = payload;
      // console.log('initTime timeZone', systemSet);
      const now = moment.tz(moment(), timeZone)
      let amPm = '';
      let selectAmPm = ALL_VALUE;
      let dateDay = workday?.[0] || now.format('YYYY-MM-DD');
      if (currentDate) {
        dateDay = (currentDate?.isSame?.(moment(), 'day') ? moment() : moment(currentDate)).tz(timeZone).format('YYYY-MM-DD')
      }
      if (!currentDate && init && resAdvanceDateDisable({ date: moment(dateDay), swsCommon })) {
        let hasFind = false
        for (const day of workday) {
          if (!resAdvanceDateDisable({ date: moment(day), swsCommon })) {
            dateDay = day
            hasFind = true;
            break;
          }
        }
        if (!hasFind) {
          console.log('not find')
          return false;
        }
      }
      const offWork = isOffWork(globalTime, timeZone);
      // // 如果过了下班时间，日期按工作日数组里顺延一天
      // if (offWork) {
      //   dateDay = moment.tz(workday?.[1], timeZone).format('YYYY-MM-DD');
      // console.log(dateDay,'dateDay')
      const startDate = moment.tz(dateDay, timeZone);
      const endDate = moment.tz(dateDay, timeZone);
      const amStartTime = moment.tz(`${dateDay} ${globalTime.amStartTime}:00`, timeZone);
      const amEndTime = moment.tz(`${dateDay} ${globalTime.amEndTime}:00`, timeZone);
      const pmStartTime = moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone);
      const pmEndTime = moment.tz(`${dateDay} ${globalTime.pmEndTime}:00`, timeZone);
      const endTime = changeTimeBefore(pmEndTime);

      // console.log('endTime', endTime.format('HH:mm'))
      let failReason = '';
      // 第二天没有日期，且是下班时间不支持预订
      // 未设置工作日 不支持预订
      const noTime = workday?.length === 0 && isOffWork(globalTime, timeZone);
      // console.log('initTime reserveNormalLimitDay', Number(reserveNormalLimitDay) === 1, isOffWork(globalTime, timeZone))
      const noGlobalTime = !(
        globalTime.amStartTime ||
        globalTime.amEndTime ||
        globalTime.pmStartTime ||
        globalTime.pmEndTime
      );

      if (noTime || noGlobalTime) {
        if (noTime) {
          failReason = noTime && formatMessage({ id: 'sws.booking.get.off.work' });
        } else if (noGlobalTime) {
          failReason = noGlobalTime && formatMessage({ id: 'sws.booking.get.no.time' });
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            failReason,
            startDate: undefined,
            endDate: undefined,
            startTime: undefined,
            endTime: undefined,
          },
        });
        callback(undefined, undefined, failReason);
        return;
      }
      yield put({
        type: 'saveOrUpdateData',
        payload: { failReason },
      });

      const changeTimeAfter = (startDate) => {
        return changeTimeAfterFun(startDate, getAdvanceTime({ startDate, endDate, swsCommon }))
      }

      let startTime = changeTimeBefore(amStartTime);
      // 开始时间设置，如果当前时间大于上午开始时间，开始时间则是当前时间
      if (now > amStartTime && !offWork) {
        startTime = changeTimeAfter(now);
      }
      if (isTimeWorkTime(startTime, 'start', globalTime, timeZone)) {
        startTime = changeTimeAfter(pmStartTime);
      }

      if (changeTimeAfter(now) < amEndTime) {
        amPm = allAmPm;
        selectAmPm = ALL_VALUE;
      } else if (changeTimeAfter(now) < pmEndTime) {
        amPm = onlyPm;
        selectAmPm = PM_VALUE;
      } else {
        amPm = allAmPm;
        selectAmPm = ALL_VALUE;
      }

      let timeText = ''; // 预订页面需要回显的时间
      const date = `${moment
        .tz(startDate, timeZone)
        .format(formatMessage({ id: 'sws.time.date' }))} ~ ${moment
          .tz(endDate, timeZone)
          .format(formatMessage({ id: 'sws.time.date' }))}`;
      // 如果是从再次预约跳转过来的timeFlag,或者不是从再次预约跳过来并且时间选择不是自定义
      // if (timeFlag !== CUSTOM_VALUE || (!timeFlag && selectAmPm !== CUSTOM_VALUE)) {
      let timeDay = formatMessage({ id: 'all_day' });
      if (
        moment.duration(moment.tz(endTime, timeZone)).valueOf() <=
        moment.duration(amEndTime).valueOf()
      ) {
        timeDay = formatMessage({ id: 'am' });
        selectAmPm = AM_VALUE;
      } else if (
        moment.duration(amEndTime).valueOf() <=
        moment.duration(moment.tz(startTime, timeZone)).valueOf()
      ) {
        timeDay = formatMessage({ id: 'pm' });
        selectAmPm = PM_VALUE;
      }
      timeText = `${date} ${timeDay}`;
      let modal = amPm
      if (Number(reserve_time_mode) === 1) {
        modal = amPm.filter(v => v.value === CUSTOM_VALUE)
        selectAmPm = CUSTOM_VALUE;
        timeText = `${moment
          .tz(startDate, timeZone)
          .format(formatMessage({ id: 'sws.time.date' }))} ${moment
            .tz(startTime, timeZone)
            .format('HH:mm')} ~ ${moment
              .tz(endDate, timeZone)
              .format(formatMessage({ id: 'sws.time.date' }))} ${moment
                .tz(endTime, timeZone)
                .format('HH:mm')}`;
      }
      if (Number(reserve_time_mode) === 2) {
        modal = amPm.filter(v => v.value !== CUSTOM_VALUE)
      }
      console.log(startDate.format('llll'), 'ssssssssssssssssssssssssss')
      yield put({
        type: 'saveOrUpdateData',
        payload: { startDate, endDate, startTime, endTime, amPm: modal, timeText, selectAmPm },
      });
      callback(startTime, endTime, failReason, startDate, endDate);
    },
    // 初始化minTime,MaxTime
    // *initChangeTime({ payload, callback = () => {} }, { put, select }) {
    //   const { timeZone, globalTime, systemSet, workday } = payload;
    //   const now = moment.tz(moment(), timeZone)
    //   const { selectAmPm } = yield select((state) => state.swsRecommendBooking);
    //   const { reserveNormalOpenDay } = systemSet;
    //   const dateDay = workday?.[0] || now.format('YYYY-MM-DD');
    //   const offWork = isOffWork(globalTime, timeZone);
    //   const startDate = moment.tz(dateDay, timeZone);
    //   const endDate = moment.tz(dateDay, timeZone);
    //   const amStartTime = moment.tz(`${dateDay} ${globalTime.amStartTime}:00`, timeZone);
    //   const amEndTime = moment.tz(`${dateDay} ${globalTime.amEndTime}:00`, timeZone);
    //   const pmStartTime = moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone);
    //   const pmEndTime = moment.tz(`${dateDay} ${globalTime.pmEndTime}:00`, timeZone);
    //   const endTime = changeTimeBefore(pmEndTime);
    //
    //   // console.log('endTime', endTime.format('HH:mm'))
    //   let failReason = '';
    //   // 第二天没有日期，且是下班时间不支持预订
    //   // 未设置工作日 不支持预订
    //   const noTime = Number(reserveNormalOpenDay) === 1 && isOffWork(globalTime, timeZone);
    //   // console.log('initTime reserveNormalLimitDay', Number(reserveNormalLimitDay) === 1, isOffWork(globalTime, timeZone))
    //   const noGlobalTime = !(
    //     globalTime.amStartTime ||
    //     globalTime.amEndTime ||
    //     globalTime.pmStartTime ||
    //     globalTime.pmEndTime
    //   );
    //
    //   if (noTime || noGlobalTime) {
    //     if (noTime) {
    //       failReason = noTime && formatMessage({ id: 'sws.booking.get.off.work' });
    //     } else if (noGlobalTime) {
    //       failReason = noGlobalTime && formatMessage({ id: 'sws.booking.get.no.time' });
    //     }
    //     yield put({
    //       type: 'saveOrUpdateData',
    //       payload: {
    //         failReason,
    //         startDate: undefined,
    //         endDate: undefined,
    //         startTime: undefined,
    //         endTime: undefined,
    //       },
    //     });
    //     callback(undefined, undefined, failReason);
    //     return;
    //   }
    //   yield put({
    //     type: 'saveOrUpdateData',
    //     payload: { failReason },
    //   });
    //
    //   let startTime = changeTimeBefore(amStartTime);
    //   // 开始时间设置，如果当前时间大于上午开始时间，开始时间则是当前时间
    //   if (now > amStartTime && !offWork) {
    //     startTime = changeTimeAfter(now);
    //   }
    //   if (isTimeWorkTime(startTime, 'start', globalTime, timeZone)) {
    //     startTime = changeTimeAfter(pmStartTime);
    //   }
    //   if (selectAmPm !== CUSTOM_VALUE) {
    //     yield put({
    //       type: 'saveOrUpdateData',
    //       payload: { selectAmPm: ALL_VALUE },
    //     });
    //     if (
    //       moment.duration(moment.tz(endTime, timeZone)).valueOf() <=
    //       moment.duration(amEndTime).valueOf()
    //     ) {
    //       yield put({
    //         type: 'saveOrUpdateData',
    //         payload: { selectAmPm: AM_VALUE },
    //       });
    //     } else if (
    //       moment.duration(amEndTime).valueOf() <=
    //       moment.duration(moment.tz(startTime, timeZone)).valueOf()
    //     ) {
    //       yield put({
    //         type: 'saveOrUpdateData',
    //         payload: { selectAmPm: PM_VALUE },
    //       });
    //     }
    //   } else {
    //     yield put({
    //       type: 'saveOrUpdateData',
    //       payload: { selectAmPm: CUSTOM_VALUE },
    //     });
    //   }
    //   callback(startTime, endTime, failReason, startDate, endDate);
    // },
    // 改变上下午全天或者自定义选项
    *changAmPmTime({ payload, callback = () => { } }, { put, select }) {
      const { timeZone, globalTime, selectAmPm } = payload;
      const now = moment.tz(moment(), timeZone)
      let startTime = payload.startTime || '';
      let endTime = payload.endTime || '';
      const dateDay = moment(startTime).tz(timeZone).format('YYYY-MM-DD');
      // if (isOffWork(globalTime, timeZone || 'Asia/Shanghai')) {
      //   dateDay = moment(startTime).tz(timeZone).add(1, 'day').format('YYYY-MM-DD');
      // }
      const startDate = moment.tz(dateDay, timeZone);
      const endDate = moment.tz(dateDay, timeZone);
      const amStartTime = moment.tz(`${dateDay} ${globalTime.amStartTime}:00`, timeZone);
      const amEndTime = moment.tz(`${dateDay} ${globalTime.amEndTime}:00`, timeZone);
      const pmStartTime = moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone);
      const pmEndTime = moment.tz(`${dateDay} ${globalTime.pmEndTime}:00`, timeZone);
      const { swsCommon } = yield select(state => state)

      const changeTimeAfter = (startDate) => {
        return changeTimeAfterFun(startDate, getAdvanceTime({ startDate, endDate, swsCommon }))
      }

      startTime = changeTimeAfter(amStartTime);
      // 开始时间设置，如果当前时间大于上午开始时间，开始时间则是当前时间
      if (now > amStartTime && now < endTime) {
        startTime = changeTimeAfter(now);
      }
      if (isTimeWorkTime(startTime, 'start', globalTime, timeZone)) {
        startTime = changeTimeAfter(pmStartTime);
      }
      if (Number(selectAmPm) === AM_VALUE) {
        endTime = changeTimeBefore(amEndTime);
      }
      if (Number(selectAmPm) === PM_VALUE) {
        startTime = changeTimeAfter(pmStartTime);
        if (now > startTime) {
          startTime = changeTimeAfter(now);
        }
        endTime = changeTimeBefore(pmEndTime);
      }
      if (Number(selectAmPm) === ALL_VALUE || Number(selectAmPm) === CUSTOM_VALUE) {
        endTime = changeTimeBefore(pmEndTime);
      }
      yield put({
        type: 'saveOrUpdateData',
        payload: { startDate, endDate, startTime, endTime, selectAmPm },
      });
      callback(startTime, endTime, startDate, endDate, selectAmPm);
    },
    // 预定推荐工位/区域
    *getRecommendBooking({ payload }, { call, put }) {
      const result = yield call(recommendBookingService.getRecommendBooking, payload);
      // const result={"code":1,"msg":'11111',"data":null,"traceId":"f585a5e04b434b20"}
      if (result && result.code === 0) {
        const recommendObj = result?.data || {};
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            recommendObj,
          },
        });
        return recommendObj;
      } else if (result.code === 1 && result?.msg) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
  },
  subscriptions: {},
};
