/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'login.title': '威思客服務平台',
  'login.title.2': '威思客服務平台是東半球最具影響力的服務平台',
  'login.title.app': '用戶登錄',
  'login.welcome': '歡迎訪問威思客服務平台，點擊右側菜單選擇您需要訪問的功能。',
  'login.username': '賬號',
  'login.username.tip': '請輸入賬號',
  'validation.userName.required': '請輸入賬號',
  'login.password': '密碼',
  'validation.password.required': '請輸入密碼',
  'login.forgot.password': '忘記密碼',
  'login.remember.password': '記住我',
  'login.login': '登錄',
  'login.copy.right': '2019 威發系統有限公司 版權所有',
  'login.err': '登錄失敗，請檢查用戶名和密碼是否正確，如果不行，請稍後嘗試',
  'login.storage.err': '登錄信息失效，請重新登錄。',
  'login.storage.err.title': '登錄信息錯誤',
  'app.login.message-invalid-credential': '用戶名或密碼錯誤',
  'login.forgot.password.email': '郵箱',
  'login.forgot.password.email.required': '請輸入您的郵箱',
  'login.forgot.password.email.err': '請輸入正確的郵箱格式',
  'login.forgot.password.code': '驗證碼',
  'login.forgot.password.code.required': '請輸入驗證碼',
  'login.forgot.password.send.email': '發送郵件',
  'login.forgot.password.send.email.success': '郵件發送成功！',
  'login.forgot.password.send.email.success.tip': '郵件已發送至你的郵箱，請去查收郵件吧',
  'login.go.login': '重新登錄',
  'login.button.confirm': '確定',
  'login.fail.title': '登錄失敗',
  'login.fail.title.operate': '操作失敗',
  'common.operate.slider.safe': '請完成安全驗證',
  'common.operate.slider.barText': '向右滑動完成驗證',
  'common.operate.slider.loadingText': '正在加載中...!',
  'common.operate.slider.failedText': '再試一次',

  'login.auth.type.2FA.title': '輸入安全碼',
  'login.auth.type.2FA.title.placeholder': '請輸入安全碼',
  102163: '校驗失敗',
  101080: '安全碼校驗失敗',
  101079: '安全碼為空',

  'login.type.SMS.tip': '手機驗證碼登錄',
  'login.type.SMS.tip.1': '賬號登錄',
  'login.type.SMS.send.title': '獲取驗證碼',
  'login.type.SMS.phone.title.placeholder': '請輸入手機號',
  'login.type.SMS.phone.code.title.placeholder': '請輸入手機號驗證碼',
  'login.type.SMS.phone.title': '手機號',
  'login.type.SMS.phone.code.title': '手機驗證碼',
  'login.type.slide.check.success': '驗證成功',
  'login.type.slide.check.fail': '驗證失敗',
  'cloud.safe.verify.invalid': '驗證碼已失效，請重新獲取',
};
