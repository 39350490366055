/**
 * Created : fangkemeng
 * Date : 2020-09-01 11:47
 * Email : fangkemeng@wafersystems.com
 */
import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { SWS_REPAIR_STATUS } from '@/pages/SmartLocker/utils/constant';
import * as service from './service';

export default {
  namespace: 'swsRepairManage',
  state: {
    loading: false,
    untreatedRecords: [],
    untreatedData: {},
    untreatedDataArr: [],
    treatingRecords: [],
    treatingData: {},
    treatingDataArr: [],
    treatedRecords: [],
    treatedData: {},
    treatedDataArr: [],
    facilityList: [],
    selectedIndex: 0,
    isConfirm: undefined,
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询我的预订
    *getRepairManagePage({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(service.getRepairManagePage, param);
      if (result && result.code === 0) {
        const { data } = result;
        const { records } = data;
        const { repairsStatus } = param;
        const { isConfirm } = yield select((state) => state.swsRepairManage);
        switch (repairsStatus) {
          case SWS_REPAIR_STATUS.pending:
            if (isConfirm === 'isPending') {
              yield put({
                type: 'saveOrUpdateData',
                payload: {
                  untreatedDataArr: [],
                  treatingDataArr: [],
                  untreatedRecords: records || [],
                  untreatedData: data,
                  isConfirm: false,
                },
              });
            } else {
              yield put({
                type: 'saveOrUpdateData',
                payload: { untreatedRecords: records || [], untreatedData: data },
              });
            }
            break;
          case SWS_REPAIR_STATUS.processing:
            if (isConfirm === 'isProcessing') {
              yield put({
                type: 'saveOrUpdateData',
                payload: {
                  treatingDataArr: [],
                  treatedDataArr: [],
                  treatingRecords: records || [],
                  treatingData: data,
                  isConfirm: false,
                },
              });
            } else {
              yield put({
                type: 'saveOrUpdateData',
                payload: { treatingRecords: records || [], treatingData: data },
              });
            }
            break;
          case SWS_REPAIR_STATUS.processed:
            yield put({
              type: 'saveOrUpdateData',
              payload: { treatedRecords: records || [], treatedData: data },
            });
            break;
          default:
          // do nothing
        }
      }
    },
    *getFacilityList(_, { call, put, select }) {
      let domainId = 0;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        domainId = info.tenant_id;
      }
      const result = yield call(service.getFacilityList, domainId);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            facilityList: result.data,
          },
        });
      }
    },
    // 预订确认操作
    *confirmReservation({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(service.convertStatus, param);
      if (result && result.code === 0) {
        Toast.success(formatMessage({ id: 'sws.repairManage.station.update.success' }));
        const { repairsStatus } = param;
        const repairsStatus1 = repairsStatus - 1;
        let clearArr;
        let isConfirm;
        if (repairsStatus1 === SWS_REPAIR_STATUS.processing) {
          clearArr = {
            treatedDataArr: [],
            treatedRecords: [],
            treatedData: {},
          };
          isConfirm = 'isProcessing';
        } else {
          clearArr = {
            treatingDataArr: [],
            treatingRecords: [],
            treatingData: {},
          };
          isConfirm = 'isPending';
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: { ...clearArr, isConfirm },
        });
        // 1、操作后按照返回第一页处理
        const obj = { size: 5, current: 1 };
        yield put({
          type: 'getRepairManagePage',
          payload: { ...obj, repairsStatus: repairsStatus1 },
        });
        yield put({ type: 'getRepairManagePage', payload: { ...obj, repairsStatus } });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        // 监听路由变化，并发送类别查询请求
        if (pathname && pathname.includes('/workspace/station/repairManage')) {
          const obj = { size: 5, current: 1 };
          dispatch({
            type: 'getRepairManagePage',
            payload: { ...obj, repairsStatus: SWS_REPAIR_STATUS.pending },
          });
          dispatch({
            type: 'getRepairManagePage',
            payload: { ...obj, repairsStatus: SWS_REPAIR_STATUS.processing },
          });
          dispatch({
            type: 'getRepairManagePage',
            payload: { ...obj, repairsStatus: SWS_REPAIR_STATUS.processed },
          });
          dispatch({ type: 'getFacilityList' });
        } else if (pathname && pathname.includes('/workspace/station/repairManageDetail')) {
          dispatch({ type: 'getFacilityList' });
        }
      });
    },
  },
};
