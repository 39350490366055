export default {
  'sws.inventory.title': '工位盤點',
  'sws.inventory.detail.userName': '員工姓名',
  'sws.inventory.detail.userNum': '員工账号',
  'sws.inventory.detail.deptName': '員工部門',
  'sws.inventory.station.stationNum': '手動輸入工位編號',
  'sws.inventory.station.scanStationNum': '掃碼識別工位編號',
  'sws.inventory.station.remarks': '備註：工位編號如有變化，請在右側輸入或掃碼更改',
  'sws.inventory.station.location': '工位位置',
  'sws.inventory.station.ok': '確認',

  'sws.inventory.detail.empty': '當前無需工位盤點～',
  'sws.inventory.qrCode.error': '工位二維碼信息不正確',
  'sws.inventory.btn.stationnum': '請選擇工位編號',
  'sws.inventory.btn.confim': '確定',
  'sws.inventory.stationnum': '工位編號',
  'sws.inventory.return': '返回任務',
  'sws.inventory.length': '共{value1}個',
  'sws.menu.station.inventorytask': '工位盤點',
};
