import { useMemo } from 'react';
import { getLocale } from 'umi';
import { getContentByLanguage } from '@/pages/Visitor/utils/utils';
import useRequest from '../useRequest';
import * as service from './service';

export default function useVisitorTypeList() {
  const lang = getLocale();
  const { data: visitorTypeList = [] } = useRequest(service.getVisitorTypeList, {}, null);

  const visitorTypeListDataSource = useMemo(() => {
    return visitorTypeList?.map((item: any) => {
      return {
        label: getContentByLanguage({
          contentCN: item?.name,
          contentTW: item?.nameTw,
          contentEN: item?.nameEn,
          lang,
        }),
        value: item?.businessId,
      };
    });
  }, [visitorTypeList]);

  return {
    visitorTypeList,
    visitorTypeListDataSource,
  };
}
