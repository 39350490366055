import { formatMessage } from 'umi';
import moment from 'moment-timezone';
import { parse } from 'qs';
import { Toast } from 'antd-mobile-v2';
import * as service from './service';
import * as wechatService from '@/pages/WorkSpace/Station/FacilityManage/services/wechat';
import * as applyService from '@/pages/WorkSpace/Station/FacilityManage/services/services';
import * as facilityService from '@/pages/WorkSpace/Station/FacilityManage/service';
import * as virsicalService from '@/pages/WorkSpace/Station/FacilityManage/services/virsical';

export default {
  namespace: 'swsInventory',
  state: {
    defaultId: null,
    defaultStationNum: '',
    isValid: true,
    searchStation: [],
    swsInventoryData: {},
    isSuccess: false,
    hasInventory: false,
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getStationCheckInfo({ payload }, { call, put }) {
      const { date, day } = payload;
      const curDate = moment().valueOf();
      if (curDate && date && day) {
        const differ = Number(curDate) - Number(date);
        // window.console.log(date, curDate, day, differ, Number(day)*24*60*60*1000, 'getStationCheckInfo');
        if (differ < Number(day) * 24 * 60 * 60 * 1000) {
          const result = yield call(service.getStationCheckInfo, payload);
          if (result && result.code === 0) {
            Toast.hide();
            yield put({
              type: 'saveOrUpdateData',
              payload: {
                swsInventoryData: result.data,
                defaultStationNum: result.data?.stationNum || '',
                defaultId: payload.id,
                hasInventory: Number(result.data.checkState) === 0,
              },
            });
          } else if (result && result.code === 1) {
            // Toast.fail(formatMessage({ id: result.msg }));
            yield put({
              type: 'saveOrUpdateData',
              payload: { swsInventoryData: {}, isValid: false },
            });
          }
        } else {
          yield put({
            type: 'saveOrUpdateData',
            payload: { swsInventoryData: {}, isValid: false },
          });
        }
      }
    },
    *getStationNums({ payload }, { call, put }) {
      const result = yield call(service.getStationNums, payload);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            searchStation: result.data || [],
          },
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
        yield put({ type: 'saveOrUpdateData', payload: { searchStation: [] } });
      }
    },
    *weChatQrScan({ scanType }, { call, put }) {
      /* eslint-disable*/
      const res = yield call(wechatService.qrScan, scanType);
      yield put({ type: 'handleResult', res });
    },
    *handleResult({ res }, { put }) {
      let result = decodeURIComponent(res);
      if (result) {
        // const index = result.indexOf('sId');
        // result = result.substr(index, result.length);
        // const params = parse(result);
        const index = result.indexOf('sId');
        const sId = encodeURIComponent(
          result.substr(index, result.length).split('&')[0].split('sId=')[1],
        );
        yield put({ type: 'searchScanStationInfo', stationId: sId });
      } else {
        Toast.fail(formatMessage({ id: 'sws.inventory.qrCode.error' }));
      }
    },
    *searchScanStationInfo({ stationId }, { call, put, select }) {
      const result = yield call(service.searchScanStationInfo, stationId);
      const { swsInventoryData } = yield select((state) => state.swsInventory);
      if (result && result.code === 0) {
        const station = result.data || {};
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            swsInventoryData: {
              ...swsInventoryData,
              id: station?.stationId,
              locationName: station?.locationName,
              stationNum: station?.stationNum,
              // locationId: station?.locId,
            },
          },
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *configScan({ scanType }, { call, put }) {
      const data = yield call(applyService.getJssdkTicket);
      if (Number(data.code) === 0) {
        yield call(wechatService.init, data.data, ['scanQRCode']);
        // 睡眠一秒
        yield setTimeout(() => {}, 1000);
        yield put({ type: 'weChatQrScan', scanType });
      } else {
        Toast.fail(formatMessage({ id: data.msg }));
      }
    },
    *virsicalQrScan(_, { call, put }) {
      const data = yield call(facilityService.getWaferAppInfo);
      if (data.code === 0) {
        const res = yield call(virsicalService.qrScan, data.data);
        yield put({ type: 'handleResult', res });
      } else {
        const errorText = formatMessage({ id: data.msg });
        Toast.fail(errorText);
      }
    },
    *checkConfirm({ payload }, { call, put }) {
      const result = yield call(service.checkConfirm, payload);
      if (result && result.code === 0) {
        Toast.success(formatMessage({ id: 'common.action.success' }));
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            isSuccess: true,
          },
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
        yield put({ type: 'saveOrUpdateData', payload: { isSuccess: false } });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
        if (pathname.trim().length > 0 && pathname.includes('/workspace/station/inventory')) {
          const { id, date, day } = query;
          dispatch({ type: 'getStationCheckInfo', payload: { id, date, day } });
        }
      });
    },
  },
};
