/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'feedback.textArea.placeholder': '歡迎提出寶貴意見，請輸入您的反饋意見。',
  'feedback.picture.add': '添加圖片',
  'feedback.btn.submit': '提交',
  'feedback.btn.get.more': '更多意見反饋',
  'feedback.picture.preview': '圖片預覽',
  'feedback.picture.tips': '最多5張，圖片格式可為jpeg/jpg/png, 大小不超過5M\n',
};
