/**
 * Created by wang sen on 2017/7/6.
 */
import { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH, ACCESS_TOKEN } from '@/utils/constant';

/**
 * 获取微信JS-SDK密钥
 * @returns {{data, headers}|{data}|*}
 */

export const getJssdkTicket = () => {
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/wdfClient/enterpriseNumber/getTicket`, {
    method: 'POST',
    data: JSON.stringify({
      token: sessionStorage.getItem(ACCESS_TOKEN),
      urlPath: window.wechaturl
        ? window.wechaturl
        : encodeURIComponent(window.location.href.split('#')[0]),
    }),
  });
};

/**
 * 获取威思客第三方应用密钥
 * @returns {{data, headers}|{data}|*}
 */
export async function getWaferAppInfo(appId) {
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/wdf/getWaferAppInfo?appId=${appId}`);
}

/**
 * 获取飞书S-SDK密钥
 * @returns {{data, headers}|{data}|*}
 */

export const getJssdkTicketFeiShu = () => {
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/wdframe/feishujoint/getJsapiTicket`, {
    method: 'POST',
    data: JSON.stringify({
      token: sessionStorage.getItem(ACCESS_TOKEN),
      enterpriseId: sessionStorage.getItem('FEISHU_SCAN'),
      agentId: sessionStorage.getItem('FEISHU_SCAN_APPID'),
      urlPath: window.location.href.split('#')[0],
    }),
  });
};
