/* eslint-disable no-lonely-if */
/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { formatMessage, setLocale } from 'umi';
import { Toast } from 'antd-mobile-v2';
import {
  sendMailCode,
  thirdRegister,
  thirdBind,
  cloudLogin,
  checkIsExist,
  codeRegister,
  feishuBind,
  commonInitData,
  securityCodeCheck,
  getAreaCode,
} from './service.js';
import { encryptAes } from '@/utils/utils';
import {CLOUD_AES_KEY, ACCESS_TOKEN, PASSWORD_AES_KEY, DEFAULT_PREFIX} from '@/utils/constant';

export default {
  namespace: 'register',
  state: {
    operateType: 'register',
    modalType: '',
    tenantName: '',
    contactRealName: '',
    phone: '',
    contactTel: '',
    contactMail: '',
    captcha: '', // 验证码
    password: '',
    areaCode: ['+86'],
    verifyType: '',
    highLight: '0',
    pactCheck: false,
    bindAccout: '',
    bindPwd: '',
    params: '',
    alternatePhone: '',
    bindCode: '',
    phoneAreaCode: [],
  },
  reducers: {
    saveOrUpdateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *sendMailCode({ payload, callBack, errorCallBack = null }, { call }) {
      const res = yield call(sendMailCode, {
        verification: payload.verification,
        source: payload.source || 2,
        code: payload?.code,
        randomStr: payload?.randomStr,
      });
      if (!res || res.code !== 0) {
        if (errorCallBack) errorCallBack();
        return Toast.fail(
          formatMessage({ id: res.msg }) || formatMessage({ id: 'register.verifycode.fail' }),
        );
      }
      if (res.data === 200 || res.data === true || res.code === 0) {
        return callBack();
      }
      if (res.data === 400) {
        if (errorCallBack) errorCallBack();
        return Toast.fail(formatMessage({ id: 'register.verifycode.expired' }));
      }
      if (payload.source === 1 && res.data === false) {
        return callBack();
      }
      if (res.data === false) {
        if (errorCallBack) errorCallBack();
        return Toast.fail(formatMessage({ id: 'register.verifycode.nouser' }));
      }
    },
    *thirdRegister({ payload }, { call }) {
      payload.password = encryptAes(payload.password, CLOUD_AES_KEY);
      const { code, msg, data } = yield call(thirdRegister, payload);
      if (code === 0) {
        Toast.success(formatMessage({ id: 'register.register.success' }));
        if (localStorage.getItem('teamsProductCode') === 'fm') {
          window.location.href = `${window.location.origin}/vsk/fm-app${data}`;
        } else {
          window.location.href = data;
        }
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *thirdBind({ payload }, { call }) {
      const { code, msg, data } = yield call(thirdBind, payload);
      if (code === 0) {
        Toast.success(formatMessage({ id: 'register.bind.success' }));
        if (localStorage.getItem('teamsProductCode') === 'fm') {
          window.location.href = `${window.location.origin}/vsk/fm-app${data}`;
        } else {
          window.location.href = data;
        }
      } else {
        Toast.fail(formatMessage({ id: msg }));
        sessionStorage.removeItem(ACCESS_TOKEN);
        // window.location.href = `${window.location.origin}/vsk/main/auth-end.html`;
      }
      return code
    },
    *feishuBind({ payload }, { call }) {
      const { code, msg, data } = yield call(feishuBind, payload);
      if (code === 0) {
        Toast.success(formatMessage({ id: 'register.bind.success' }));
        // if (localStorage.getItem('teamsProductCode') === 'fm') {
        //   window.location.href = `${window.location.origin}/vsk/fm-app${data}`;
        // } else {
          window.location.href = data;
        // }
      } else {
        Toast.fail(formatMessage({ id: msg }));
        sessionStorage.removeItem(ACCESS_TOKEN);
      }
      return code
    },
    // 云服务用户名密码认证
    *cloudAuth({ payload: { username, password, type, code, randomStr } }, { call }) {
      const passwordAes = encryptAes(password, CLOUD_AES_KEY);
      const Authorization = 'Basic bG9naW4taDU6MzdIQDVMbiM3MTQ4';
      const result = yield call(cloudLogin, {
        username,
        password: passwordAes,
        grantType: 'password',
        scope: 'server',
        Authorization,
        code,
        randomStr,
      });
      if (result) {
        if (result && result.access_token) {
          sessionStorage.setItem(ACCESS_TOKEN, result.access_token);
          if (type === 'bind') {
            return 'success';
          }
        } else {
          if (!result.msg) {
            Toast.fail(formatMessage({ id: 'cloud.auth.fail.tip' }));
          } else if (result.msg !== '101079') {
            Toast.fail(formatMessage({ id: result.msg }));
          }
          return result
        }
      }
    },
    // 用户是否存在
    *checkIsExist({ payload: { username } }, { call }) {
      const verification = yield encryptAes(username, PASSWORD_AES_KEY);
      const { code, msg } = yield call(checkIsExist, { verification });
      if (code === 0) {
        return code;
      }
      if (code !== 0) {
        Toast.fail(formatMessage({ id: msg }));
      }
    },

    // code 注册
    *codeRegister(payload, { call, select }) {
      const { params } = yield select((state) => state.register);
      payload.entrySource = params?.entrySource;
      const { code, msg, data } = yield call(codeRegister, payload);
      if (code === 0) {
        Toast.success(formatMessage({ id: 'register.register.success' }));
        if (localStorage.getItem('teamsProductCode') === 'fm') {
          window.location.href = `${window.location.origin}/vsk/fm-app${data}`;
        } else {
          window.location.href = data;
        }
      } else {
        Toast.fail(formatMessage({ id: msg }));
        if (msg === '1001094') {
          const userAgent = window.navigator.userAgent.toLowerCase();
          if (userAgent.includes('teamsmobile') && sessionStorage.getItem('from') === 'cloud') {
            localStorage.removeItem('teamsCloudAccess');
            sessionStorage.removeItem(ACCESS_TOKEN);
            const pId = localStorage.getItem('teamsProductCode') || 'vst';
            setTimeout(() => {
              const { origin } = window.location;
              const url = `${origin}/${DEFAULT_PREFIX}/main/teamsAADLogin?pId=${pId}&loginStatus=logOut`;
              window.location.replace(url);
            }, 2000);
          }
        }
      }
    },
    *getCommonInitData(_, { call }) {
      const { code, data, msg } = yield call(commonInitData);
      if (code === 0) {
        if (data?.open_cloud === 'true') {
          sessionStorage.setItem('from', 'cloud');
        }
        return data;
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    // 双因素验证
    *securityCodeCheck({ payload }, { call }) {
      const result = yield call(securityCodeCheck, { payload });
      if (result && result.code !== 1) {
        sessionStorage.setItem(ACCESS_TOKEN, result.access_token);
        return 'success'
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    // 获取手机区号
    *getAreaCode(_, { call, put }) {
      const result = yield call(getAreaCode);
      if (result.code === 0) {
        yield put({
          type: 'saveOrUpdateState',
          payload: {
            phoneAreaCode: result.data,
          },
        });
      } else {
        message.error(formatMessage({ id: result.msg }));
      }
    },
  },
  subscriptions: {
    set({ dispatch, history }) {
      return history.listen(async ({ pathname }) => {
        if (pathname.indexOf('/cloud/registration') > -1 || pathname.indexOf('/cloud/bind') > -1) {
          dispatch({ type: 'getAreaCode' });
          const lang = localStorage.getItem('teamsLocale');
          if (lang) setLocale(lang);
          if (history.location.query && pathname.indexOf('/cloud/registration') > -1) {
            const params = decodeURIComponent(history.location.query?.data);
            dispatch({
              type: 'saveOrUpdateState',
              payload: {
                lang,
                params: params !== 'undefined' && JSON.parse(params),
              },
            });
            if (JSON.parse(params)?.entrySource === 'feishu') setLocale('zh-CN');
          }
          if (history.location.query && pathname.indexOf('/cloud/bind') > -1) {
            if (history.location.query?.entrySource === 'feishu') {
              const {
                entrySource,
                productCode,
                terminal,
                feishuTenantId,
                feishuUnionId,
                feishuOpenId,
              } = history.location.query;
              dispatch({
                type: 'saveOrUpdateState',
                payload: {
                  entrySource,
                  productCode,
                  terminal,
                  feishuTenantId,
                  feishuUnionId,
                  feishuOpenId,
                },
              });
              setLocale('zh-CN');
            }
            if (history.location.query?.entrySource === 'teams') {
              const { entrySource, productCode, terminal, teamsTenantId, azureUserid } =
                history.location.query;
              dispatch({
                type: 'saveOrUpdateState',
                payload: {
                  entrySource,
                  productCode,
                  terminal,
                  teamsTenantId,
                  azureUserid,
                  lang,
                },
              });
            }
          }
        }

        dispatch({
          type: 'saveOrUpdateState',
          payload: {
            operateType: pathname.indexOf('/cloud/registration') > -1 ? 'register' : 'bind',
          },
        });
      });
    },
  },
};
