export default {
  'meeting.seat.manage.room': '会议室',
  'meeting.seat.manage.seat.label': '座位',
  'meeting.seat.manage.user.placeholder': '请选择参会人',
  'meeting.seat.button.text.ok': '确定',
  'meeting.seat.room.template.none': '会议室无可用座位模板',

  'meeting.seat.button.text.save': '保存',
  'meeting.seat.button.text.back': '返回',
  'meeting.seat.manage.seat.label1': '座位编号',
  'meeting.seat.manage.seat.username': '参会人姓名',
};
