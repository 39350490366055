/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'scan.auth.page.product.code.title': '當前',
  'scan.auth.page.product.code.title.workspace': '工位',
  'scan.auth.page.product.code.title.meeting-room': '會議室',
  'scan.auth.page.product.code.title.locker': '儲物櫃',
  'scan.auth.page.product.code.name': '名稱',
  'scan.auth.page.product.code.number': '編號',
  'scan.auth.page.product.code.space': '位置',
  'scan.auth.page.product.code.dept': '部門',
  'scan.auth.page.product.code.login.bind': '登錄綁定',
  'scan.auth.page.product.code.more.info': '更多信息',
  'scan.auth.page.product.code.more.action': '更多操作',
  'scan.auth.page.product.code.action.report': '提報需求',
  'scan.auth.page.product.code.action.report.warn': '暫不支持此功能',
};
