export default {
  'external.contacts.add': '添加外部參會人',
  'external.contacts.name': '姓名',
  'external.contacts.name_': '姓名：',
  'external.contacts.department': '部門',
  'external.contacts.position': '職位',
  'external.contacts.mail': '郵箱',
  'external.contacts.phone': '手機',
  'external.contacts.company': '公司',
  'external.contacts.placeholder': '請輸入',
  'external.contacts.name.fail': '請輸入姓名',
  'external.contacts.department.fail': '請輸入部門',
  'external.contacts.position.fail': '請輸入職位',
  'external.contacts.mail.null.fail': '請輸入郵箱',
  'external.contacts.mail.fail': '郵箱格式不正確',
  'external.contacts.phone.null.fail': '請輸入手機號',
  'external.contacts.phone.fail': '手機格式不正確',
  'external.visitor.notice': '訪問邀約',
  'external.contacts.edit': '修改',
  'external.contacts.del': '刪除',
  'external.contacts.placeholder.name': '請輸入姓名',
  'external.contacts.placeholder.phone': '請輸入手機',
  'external.contacts.placeholder.email': '請輸入郵箱',
  'external.contacts.placeholder.company': '請輸入公司',
  'meeting.booking.warning.visitor.canot.audit': '所選會議室中包含審批會議室，無法進行訪客邀約',
  'external.visit.outuser': '設為訪客',
};
