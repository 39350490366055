export default {
  'menu.workspace.user': '智能工位',
  'menu.workspace.booking': '工位預訂',
  'menu.workspace.specialBooking': '特殊預訂',
  'menu.workspace.myBooking': '我的預訂',
  'menu.workspace.history': '歷史記錄',
  'menu.workspace.search': '工位查詢',
  'menu.workspace.repair': '工位報修',
  'menu.workspace.mobile': '智能工位',
  'sws.menu.station.booking': '工位預訂',
  'sws.menu.station.repair': '工位報修',
  'sws.menu.station.search': '工位查詢',
  'sws.menu.station.records': '我的預訂',
  'sws.menu.station.special': '工位特殊預訂',
  'sws.menu.locker.booking': '儲物櫃预订',
  'sws.menu.locker.records': '儲物櫃預訂記錄',
  'sws.time': 'YYYY-MM-DD HH:mm',
  'sws.time.date': 'YYYY-MM-DD',
  'sws.location.tip': '請選擇區域',
  'sws.location.tip.no.permission': '您添加的區域暫無權限',
  'sws.booking.no.all.tip': '請選擇區域和時間',
  'sws.menu.station.liftTable': '我的升降桌',

  401127: '係統工作日時間未設定',
  401129: '當前用戶沒有工位{value1}的部門權限',
  401137: '當前用戶在所選時間內已佔用或預約工位，請選擇其他時間',
  401061: '區域{value1}未設置時區信息，請在區域管理中進行維護',
  403054: '儲物櫃認證權限獲取失敗',
  403055: '當前用戶未設置位置權限',

  'sws.delay.num.format': '{num}分鐘',
  'sws.myBooking.station.no': '工位編號',
  'sws.fixed.confirm.bind.tip': '是否解除所有已綁定的固定工位？',
  'sws.fixed.confirm.bind.scan.expire.tip': '二維碼資訊已失效，請重新掃碼',
};
