export default {
  'component.globalHeader.search': '站内搜索',
  'component.globalHeader.search.example1': '搜索提示一',
  'component.globalHeader.search.example2': '搜索提示二',
  'component.globalHeader.search.example3': '搜索提示三',
  'component.globalHeader.help': '使用文档',
  'component.globalHeader.notification': '通知',
  'component.globalHeader.notification.empty': '你已查看所有通知',
  'component.globalHeader.message': '消息',
  'component.globalHeader.message.empty': '您已读完所有消息',
  'component.globalHeader.event': '待办',
  'component.globalHeader.event.empty': '你已完成所有待办',
  'component.noticeIcon.clear': '清空',
  'component.noticeIcon.cleared': '清空了',
  'component.noticeIcon.empty': '暂无数据',
  'packages.expire.tip': '您的产品已过期，请重新登录',
  'router.title.HomePage': '首页',
  'router.title.MyInfo': '我的',
  'app.title': '威思客智能办公',
  'router.title.meeting.HomePage': '智能会议',
  'router.default.title': '智能办公',

  'router.title.setting': '设置',
  'router.title.setting.lang': '设置语言',
  'router.title.setting.timezone': '设置时区',
  'router.title.setting.zone': '设置默认区域',

  'common.action.success': '操作成功',
  'common.action.fail': '操作失败',

  'menu.points.app-operation': '积分管理',
  'menu.points.app.account': '账户余额',
  'menu.points.app.bill': '我的账单',
  'menu.points.app.punishment': '违规检查',
  'menu.tcs.app.toilet': '厕卫管理',
  'menu.tcs.app.usestatus': '隔间占用状态',
  'menu.tcs.app.alarm.list': '告警记录',
  'menu.parameter.is.incorrect': '参数不正确',

  'global.guidance.skip': '跳过',
  'global.guidance.previous': '上一步',
  'global.guidance.next': '下一步',
  'global.guidance.finish': '完成',

  'login.feishu.err.title': '认证异常，请重新进入应用',
};
