/**
 * Created by wang sen on 2017/7/6.
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export default ({ payload }) => {
  return request(`/${RESTFUL_PATH.admin}/user/setDefaultZones/${payload.zone}`, {
    method: 'POST',
  });
};
