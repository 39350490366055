export default {
  'sws.sensor.station.tips': '您已經{value1}分鐘沒有使用您預訂的工位了，請問是否要繼續使用？',
  'sws.sensor.cancel': '是否立即釋放該工位？',
  'sws.sensor.immediate.release':'立即釋放',
  'sws.sensor.continue.to.use':'繼續使用',
  'sws.sensor.release.tips':'已為您成功釋放該工位',
  'sws.sensor.continue.tips':'已為您成功保留該工位',
  'sws.sensor.hello':'，您好：',
  401147:'操作過期，預訂已結束',
  401148:'請勿重複操作',
};
