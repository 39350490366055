/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'feedback.textArea.placeholder': '欢迎提出宝贵意见，请输入您的反馈意见。',
  'feedback.picture.add': '添加图片',
  'feedback.btn.submit': '提交',
  'feedback.btn.get.more': '更多意见反馈',
  'feedback.picture.preview': '图片预览',
  'feedback.picture.tips': '最多5张，图片格式可为jpeg/jpg/png, 大小不超过5M\n',
};
