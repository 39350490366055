import { formatMessage } from 'umi';
import moment from 'moment-timezone';
import { Toast } from 'antd-mobile-v2';
import { history } from 'umi';
import { ROUTE_PATH } from '@/utils/constant';
import * as service from './service';

export default {
  namespace: 'swsImmediate',
  state: {
    selectedTime: moment(),
    remark: ''
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *setImmediate({ payload }, { call }) {
      const result = yield call(service.setImmediate, payload);
      if (result && result.code === 0) {
        Toast.hide();
        const { stationNums, address, startTime, endTime, locationTimeZone } = result.data;
        const timeZone = locationTimeZone || 'Asia/Shanghai';
        const day = moment.tz(startTime, timeZone).format(formatMessage({ id: 'sws.time.date' }));
        const sTime = moment.tz(startTime, timeZone).format('HH:mm');
        const eTime = moment.tz(endTime, timeZone).format('HH:mm');
        const locationNames = address?.split(',').slice(-3);

        history.push({
          pathname: `${ROUTE_PATH}/workspace/common/components/bookingResult`,
          state: {
            ...payload,
            choseResult: {
              timeSelected: [`${day} ${sTime}`,`${day} ${eTime}`]/* `${day} ${sTime} ~ ${eTime}` */,
              stationNum: stationNums,
            },
            locationNames,
            timeZone,
          },
        });
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }), 2);
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        if (pathname.trim().length > 0 && pathname.includes('/workspace/station/immediate')) {
          dispatch({ type: 'swsCommon/getHoliday' });
          dispatch({ type: 'swsCommon/getSystemSet' });
        }
      });
    },
  },
};
