import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取账单分类
 * @param meetingId
 * @returns {Object}
 */
export async function getBillCategory() {
  return request(`/${RESTFUL_PATH.points}/billing-rules/category`, {
    method: 'GET',
  });
}

/**
 * 查询收入支出接口
 * @param meetingId
 * @returns {Object}
 */
export async function getBillTotal({ queryStartTime, queryEndTime }) {
  return request(
    `/${RESTFUL_PATH.points}/order-info/current/total?queryStartTime=${queryStartTime}&queryEndTime=${queryEndTime}`,
    {
      method: 'GET',
    }
  );
}

/**
 * 查询我的账单列表
 * @param meetingId
 * @returns {Object}
 */
export async function getBillList({
  queryStartTime,
  queryEndTime,
  businessType,
  businessCode,
  page,
  size,
}) {
  return request(
    `/${RESTFUL_PATH.points}/order-info/current/page?queryStartTime=${queryStartTime}&queryEndTime=${queryEndTime}&businessType=${businessType}&businessCode=${businessCode}&current=${page}&size=${size}`,
    {
      method: 'GET',
    }
  );
}

/**
 * 查询我的账单列表
 * @param meetingId
 * @returns {Object}
 */
export async function getBillDetail(payload) {
  return request(`/${RESTFUL_PATH.points}/order-info/detail/${payload.id}`, {
    method: 'GET',
  });
}
