export default {
  'home.page.title': '智能會議',
  'home.page.subtitle': '一鍵開啟，智能辦公',

  'home.page.btn.book': '預訂會議',
  'home.page.btn.about': '關於',
  'home.page.btn.video.meeting': '視頻會議',
  'home.page.btn.my.meeting': '會議列表',
  'home.page.btn.initiate': '發起會議',
  'home.page.btn.notwork.meeting': '網路會議',
};
