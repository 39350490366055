export default {
  'app.setPassword.oldPassword': 'Original Password',
  'app.setPassword.oldPassword.tip': 'Original Password cannot be empty',
  'app.setPassword.newPassword': 'New Password',
  'app.setPassword.newPassword.tip': 'New password cannot be empty',
  'app.setPassword.againPassword': 'Confirm Password',
  'app.setPassword.againPassword.tip': 'Confirm password cannot be empty',
  'app.setPassword.passwordAgain':
    'Password must be 8~16 characters with numbers, letters and symbols like .! @ # $% ^ & * -_',
  'app.setPassword.isOk': 'OK',
  'app.setPassword.operate.fail': 'Failed',
  'app.setPassword.operate.success': 'Success',
  'app.setPassword.operate.oldPassword': 'Original password',
  'app.setPassword.operate.againNew': 'New password',
  'app.setPassword.operate.againNew.1': 'Confirm Password',
};
