/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.faceLibrary.title': '人脸库',
  'access.faceLibrary.font': '正面',
  'access.faceLibrary.side': '侧面',
  'access.faceLibrary.left': '左面',
  'access.faceLibrary.right': '右面',
  'access.faceLibrary.above': '上面',
  'access.faceLibrary.btn.submit': '提 交',
  'access.faceLibrary.btn.reUpload': '重新上传',
  'access.faceLibrary.btn.finish': '完成',
  'access.faceLibrary.state.pass': '已通过',
  'access.faceLibrary.state.audit': '审核中',
  'access.faceLibrary.state.NotPass': '未通过',
  'access.faceLibrary.state': '审核状态',

  straight: '正面',
  side: '侧面',
  left: '左面',
  right: '右面',
  up: '上面',
  '10001': '找不到人脸',
  '10002': '多张人脸',
  '10003': '人脸太小',
  '10004': '照片模糊',
  'access.faceLibrary.tip': '请保持五官清晰正脸拍摄，只有一个人脸,',
  'access.faceLibrary.tip.1': '且人脸占照片面积不小于30%',
  'access.faceLibrary.tip1': '1.请选择五官清晰的正脸照片',
  'access.faceLibrary.tip2': '2.请保证照片中只有一个人脸',
  'access.faceLibrary.tip3': '3.请保证人脸占照片的面积不小于30%',

  'access.faceLibrary.uploadText': '正在上传',
  'access.faceLibrary.errorText': '上传大小不能超过10M',
  'access.faceLibrary.btnBackText': '返回',
  'access.faceLibrary.btnConfirmText': '确认',
  'access.faceLibrary.identify': '正在识别图片',
  'access.faceLibrary.uploadText.tip': '正在加载',

  'access.faceLibrary.state.sync.3': '重新拍照',
  'access.faceLibrary.state.sync.4': '重新拍照',
  'access.faceLibrary.nda.agree':"确定",

  'access.msg.pending': '照片审核中',

  'access.success.msg': '已完成注册',
  'access.success.del': '删除照片',
  'access.success.msg.tips': '不留存照片已开启，无法显示照片',
  'access.faceLibrary.btnText': '点击拍照',
  'access.faceLibrary.del': '删除',
};
