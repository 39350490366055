export default {
  phone_contacts_value1: '從手機通訊錄選擇',
  phone_contacts_value2: '請輸入您要搜索的內容',
  phone_contacts_value3: '只支持選擇大陸或香港地區流動電話',

  'external.contacts.add': '添加外部參會人',
  'external.contacts.name': '姓名',
  'external.contacts.name_': '姓名：',
  'external.contacts.department': '部門',
  'external.contacts.position': '職位',
  'external.contacts.mail': '郵箱',
  'external.contacts.phone': '手機',
  'external.contacts.company': '公司',
  'external.contacts.placeholder': '請輸入',
  'external.contacts.name.fail': '請輸入姓名',
  'external.contacts.department.fail': '請輸入部門',
  'external.contacts.position.fail': '請輸入職位',
  'external.contacts.mail.null.fail': '請輸入郵箱',
  'external.contacts.mail.fail': '郵箱格式不正確',
  'external.contacts.phone.null.fail': '請輸入手機號',
  'external.contacts.phone.fail': '手機格式不正確',
  'external.visitor.notice': '訪問邀約',

  'meeting.booking.select.outer.form.name.pls': '請輸入姓名',
  'meeting.booking.select.outer.form.tel.pls': '請輸入手機號碼',
  'meeting.booking.select.outer.form.tel.mail.pls': '請輸入手機號碼或郵箱',
  'meeting.booking.select.outer.form.email.pls': '請輸入郵箱',
  'meeting.booking.select.outer.form.tel.error': '手機號碼格式不正確',
  'meeting.booking.select.outer.form.email.error': '郵箱格式不正確',
  'meeting.booking.select.outer.form.phone.error': '手機號碼{phone}重複',
  'meeting.booking.select.outer.form.email.error': '郵箱{email}重複',
  'meeting.booking.select.outer.form.country.china': '中國 +86',
  'meeting.booking.select.outer.form.country.china.hk': '中國香港 +852',
};
