export default {
  'locker.chose.tip': '您選擇的儲物櫃：',
  'sws.btn.return.locker.booking': '返回儲物櫃預約',
  'menu.locker.mobile': '智能儲物櫃',
  'sws.reserve.locker.confirm.fail': '不支持預訂兩個或者兩個以上儲物櫃！',
  'sws.myBooking.locker': '儲物櫃',
  403043: '當前沒有空閑儲物櫃',
  403044: 'http請求失敗',
  403045: '下發權限失敗',
  403046: '回收權限失敗',
  403047: '開門權限已被釋放，無法開門',
  403061: '儲物櫃類型為訪客使用'
};
