/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  scan_auth_success: 'Scan code authentication succeeded',
  scan_auth_fail: 'Scan code authentication failed',
  scan_auth_close: 'Close',
};
