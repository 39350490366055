import { formatMessage, getLocale, history, matchPath } from 'umi';
import { Modal, Toast } from 'antd-mobile-v2';
import moment from 'moment-timezone';
import _ from 'lodash';
import * as subscribeService from '@/pages/Meeting/services/subscribe';
import * as zoneService from '../services/zoneService';
import * as detailService from '@/pages/Meeting/services/meetingDetail';
import * as svcService from '@/pages/Meeting/pages/SvcFeeDetail/service';
import * as Common from '@/pages/Meeting/utils/common';
import * as AppService from '@/pages/Visitor/services/AppService';
import { checkIsNotEmpty, checkIsShareRoom } from '@/pages/Meeting/utils/common';
import { BPMSTATE } from '@/pages/Meeting/utils/constant';
import { zhIntl } from '@/utils/intlUtils';
import {
  DATE_FORMAT,
  ENGLISH,
  CHINESE,
  MEETING_AUDIT,
  MEETING_BOOK_TYPE,
  MEETING_ROOM_TYPE,
  MEETING_SOURCE,
  MEETING_STATUS,
  MEETING_TYPE,
  MENU_PATH,
  RES_STATUS,
  ROLES_KEY,
  ROOM_TYPES,
  TIME_FORMAT,
  SEMICOLON,
  UserColor,
} from '@/pages/Meeting/utils/constant';
import * as sysModuleService from '@/pages/Meeting/services/sysModuleService';

// 多级属性在析构赋值时子属性是浅copy的，导致修改state会影响到这个对象，因此使用该对象时，需要深copy一份出来
let initialState = {};

const dateFormat = 'YYYY-MM-DD';
const rangeMinute = 15 - (moment().minute() % 15);
const filterBeginTime = moment()
  .hour(moment().hour())
  .minute(moment().minute())
  .add(rangeMinute, 'minutes')
  .valueOf();

const buildCompareKey = ({ editSeries, meetingInfo, innerUser = [], outerUser = [] }) => {
  let key = '';
  if (!!editSeries && meetingInfo.type > 0) {
    const innerUserIds = [];
    innerUser.forEach((iUser) => {
      innerUserIds.push(iUser.userId);
    });
    const outerUserIds = [];
    outerUser.forEach((oUser) => {
      outerUserIds.push(oUser.id);
    });
    const data = {
      roomIds: String(meetingInfo.roomIds),
      hostId: String(meetingInfo.hostId),
      startTime: meetingInfo.startTime && String(moment(meetingInfo.startTime).format('HH:mm')),
      endTime: meetingInfo.endTime && String(moment(meetingInfo.endTime).format('HH:mm')),
      startDate: meetingInfo.recurringStartDate
        ? meetingInfo.recurringStartDate
        : moment(meetingInfo.startTime).format('YYYY-MM-DD'),
      recurringRule: String(meetingInfo.recurringRule),
      recurringRange: String(meetingInfo.recurringRange),
      recurringExrule: String(meetingInfo.recurringExrule),
      recurringTime: String(meetingInfo.recurringTime),
      recurringEndType: String(meetingInfo.recurringEndType),
      recurringFreq: String(meetingInfo.recurringFreq),
      innerUser: JSON.stringify(innerUserIds),
      outerUser: JSON.stringify(outerUserIds),
    };
    key = window.btoa(JSON.stringify(data));
  }
  return key;
};

export default {
  namespace: 'subscribe',
  state: {
    meetingId: 0,
    startTime: '',
    endTime: '',
    startTimeStamp: '',
    endTimeStamp: '',
    roomIds: '',
    roomNames: '',
    roomNamesEn: '',
    subject: '',
    creatorId: '',
    creatorName: '',
    hostId: '',
    hostName: '',
    remark: '',
    isSecretMeeting: 0,
    isAllUser: 0,
    avaliableStart: '00:00',
    avaliableEnd: '24:00',
    isHoliday: 0,
    roomReplaceable: true, // 会议室是否可更换。通过展板预约进来时，会议室不能更换
    roomInfo: [],
    schedules: [],
    webExSchedules: [],
    tencentSchedules: [],
    tencentMax: 10,
    zoomScheduled: [],
    maintains: [],
    needToSign: false,
    advSignTime: -1,
    afterSignTime: -1,
    audit: 0,
    bookType: 0,
    onlyVideo: false,
    joinPwd: '',
    hostJoinPwd: '',
    immediateAllow: true,
    startNow: false,
    autoReconnect: true,
    cmmaStartAuto: true,
    duration: '',
    members: '',
    memberNames: '',
    selectedMembers: [],
    externals: '',
    externalNames: '',
    selectedExternal: [],
    svcPrice: 0,
    deviceContent: '',
    type: MEETING_TYPE.normal,
    recurringRule: 0, // 周期会议类型（即是循环规则） 1 日   2 周   3月 4工作日 0不循环
    recurringExrule: [`${moment().format('d') > 0 ? moment().format('d') : 7}`], // 频率  周几  （只有‘周’ 和 ‘月’ 用，周的可选'1;2'）
    recurringRange: 1, // 周期会议重复次数
    mrecurringExrule: moment().format('D'), // 频率  月第几天（月的可选天范围 1-31  最后一天 0）
    recurringFreq: 1, // 每几天/工作日/周/月（可选范围1-99）
    recurringTime: '', // 结束日期
    recurringEndType: 1, // 结束类型 0结束时间 1次数
    weekCount: [], // 第几周
    week: 0, // 周几
    mExruleType: '1',
    yExruleType: '1',
    mrecurringExrule2: '',// 相对月的值
    yrecurringExrule: `${moment().format('M')}/${moment().format('D')}`,// 按年循环的值
    sendSms: 0,
    sendEmail: 0,
    outSendSms: 0,
    outSendEmail: 0,
    noticeSet: 0,
    webexMeeting: false,
    date: moment().format(DATE_FORMAT),
    today: moment().format(DATE_FORMAT),
    month: moment().month() + 1,
    rooms: [],
    searchParam: {},
    showSearchModal: false,
    showFilterModal: false,
    devices: [],
    filterData: {},
    defaultMeetingRoom: true,
    attendanceCache: 1,
    startTimeStrCache: '',
    endTimeStrCache: '',
    deviceSelectedCache: [],
    isFilter: false,
    editMeetingInfo: {},
    showAttendee: false,
    clickRoomInfo: {},
    clickRoomInfo2: undefined,
    addRoomIds: false,
    editSeries: false, // 循环会议是否编辑整个系列的会议
    showModal: false,
    conflicts: {},
    showPriceModal: false,
    priceInfo: null,
    showAuditModal: false,
    auditTipData: null,
    showHostAuditModal: false,
    MeetingHostUserData: null,
    compareKey: '',
    onlyTextEdit: 0,
    cycleOneEdit: false,
    ifPoly: null,
    source: MEETING_SOURCE.APP,
    zoomPwd: '',
    zoomAdvance: 0,
    zoomAdvanceSetting: 0,
    zoomWait: 0,
    zoomMute: 0,
    zoomRecordLocale: 0,
    zoomAllowInBeforeHost: 1,
    zoomType: 0,
    meetingScale: 1,
    roomPayerMap: {},
    vstLocations: [],
    vstTypes: [],
    specialGroup: {},
    startTimeOption: [],
    isVisitorMeeting: false,
    webinars: false,
    zoomScaleSet: [],
    vstTypeId: '',
    vstLocationId: '',
    zoomPoll: {},
    editPollIndex: -1,
    mainZoom: -1,
    roomStates: [],
    seatTemplate: {},
    meetingUserSeat: [],
    meetingUserSeatTemp: [],
    selectRoomIdForSeat: 0,
    hostUsers: [],
    creatorUser: {},
    timeZone: '',
    isLongState: 0,
    waitingRoom: 0,
    onlyEnterpriseUser: 0,
    allowInBeforeHost: 1,
    muteEnableJoin: 0,
    hasZoom: 0,
    hasTencent: 0,
    hasTeams: 0,
    hasDingDing: 0,
    hasFeiShu: 0,
    hasHuiChang: 0,
    hasQuanShi: 0,
    hasPoly: 0,
    promptParam: {},
    isFromMap: false,
    isShareZoneId: false,
    tecentDisabled: true, // 腾讯会议室是否禁用 fasle 不禁用 true 禁用
    hasEntityRoom: true, // 是否存在实体会议室
    sdefaultCheckInType: false,
    roomPlan: [], // 按会议室拆分
    agendaData: [], // 接口返回的数据
    isFlyBook: 0, //是否是飞书会议
    existFlyBook: true, //是否是飞书应用打开
    startMeetingId: '', // 立即开始-编辑会议id
    isSetSign: false,
    isHelper: false, // 是否代订人员
    isSpecialNet: false, // 为true 不需要拆分主持人
    isSplit: true, // true拆分 false不拆分   1.isHelperUser && isSpecialNet || !isHelperUser; 不拆分,看isHelperUser决定是否可更改; 2. isHelperUser && !isSpecialNet; 拆分 且可更改主持人
    canSplit: true,
    roomTenantId: 0, // 会议室列表使用roomTenantId做会议室过滤
    isRestartMeeting: false,
    temStart: '',
    temEnd: '', // 按时间筛选会议室(picker选择暂存的，接口拿的是searchParam的)

    recurringId: null, // 循环会议编辑
    svcRoomUpList: [], //  编辑会议时  用户取消 或者删除服务时信息
    deviceUpData: [], //  编辑会议时  用户取消 或者删除设备时信息
    deviceData: [], //  编辑会议时  用户添加设备信息
    aduitValue: undefined,
    selectVideo: [],
    meetingDeviceForBooking: [], // 从预约入口进来 设备暂存信息
    meetingSvc: [],
    hasGoToSer: false, // 是否已经跳转过服务页面，如果有 则不再需要服务弹窗
    recurringNewDate: moment().format(DATE_FORMAT),
    startNowAndSignVisibile: false,
    isVisitorEdit: false, // 为true时，访问地点和事由直接禁用，不允许添加或取消访客邀约，已勾选访客邀约的，不允许删除，修改个人信息
    // 跨天访客天数配置
    acrossDayNum: 14,
    // 访客来访日期不能超过当前日期天数配置
    visitDayNum: 14,
    visitorStartTime: null, // 访客到访时间
    visitorEndTime: null, // 访客到访时间
    vstCross: false, // 访客邀请是否支持跨天
    bookingMsgModalVisible: false,
    submitAgainObj: {
      newWorkMsg: '',
    },
    agendaUserId: '',
    isFromAgendaUser: false,
    isVisitorTimeChange: false, //判断访客时间是否触发手动修改
    isFmRoom: false, // 是否展板扫码的FM会议室
    tempBookType: 0,
    meetingAttachments: [],
    editCycle: 0, // 循环编辑类型
    isTemporaryVisitor:false, 
    signVisitorList: [] //已签到用户
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
    updateSvcRoomUpList(state, { payload }) {
      return { ...state, svcRoomUpList: [...state.svcRoomUpList, ...payload.svcRoomUpList] };
    },
    updateUDeviceData(state, { payload }) {
      return { ...state, deviceUpData: [...state.deviceUpData, payload.uDeviceObj] };
    },

    updatePromptParam(state, { payload }) {
      return { ...state, promptParam: { ...state.promptParam, ...payload.promptParam } };
    },
    updateSvcPrice(state, { payload }) {
      return { ...state, svcPrice: state.svcPrice - payload.cancelCost };
    },
    reset() {
      return { ..._.cloneDeep(initialState) };
    },
    resetNoIncludeFromMap(state) {
      return { ..._.cloneDeep(initialState), isFromMap: state.isFromMap };
    },
  },
  effects: {
    *loadValidInfo({ from }, { select, call, put }) {
      let { date, isSetSign } = yield select((state) => state.subscribe);
      const {
        roomIds = '',
        meetingId,
        mainZoom = -1,
        bookType,
        advSignTime,
        afterSignTime,
        needToSign,
        isLongState,
        // startNow,
        startTime,
        endTime,
        isRestartMeeting,
        editCycle
      } = yield select((state) => state.subscribe);
      const { detail = {} } = yield select((state) => state.meetingDetail);
      const { sysModule, shareSysModule } = yield select((state) => state);
      let smtSysModule = sysModule;
      if (_.isEmpty(sysModule)) {
        const { data, code } = yield call(sysModuleService.fetch);
        if (code === 0) {
          smtSysModule = data;
        }
      }
      const { checkIn: lcheckIn = {}, base: lbase = {} } = smtSysModule;
      const { checkIn: scheckIn = {}, base: sbase = {} } = shareSysModule;
      const { currentUser, permissions = [] } = yield select((state) => state.user);
      const isShareForUser = currentUser.tenantId === 1000;
      const enableHolidayReserve = isShareForUser
        ? sbase?.enableHolidayReserve
        : lbase?.enableHolidayReserve;
      if (date) {
        if (_.isNumber(date)) {
          date = moment(date).format('YYYY-MM-DD');
        }
      } else {
        date = moment().format('YYYY-MM-DD');
      }
      const { data } = yield call(subscribeService.getValidInfo, {
        chooseDate: moment(date, 'YYYY-MM-DD').valueOf(),
        roomIds: roomIds || '-1',
        meetingId,
        bookType,
        timeZone: sessionStorage.getItem('curTimeZone') || '',
      });

      const {
        schedules,
        roomInfo,
        webExSchedules,
        zoomSchedules,
        tencentSchedules,
        maintains,
        isHoliday,
        avaliableStart,
        avaliableEnd,
        tencentMax,
      } = data;
      let isSignRoom = false;
      let tenantId = 0;
      let isFmRoom = false;
      const zoomRooms = [];
      if (roomInfo) {
        roomInfo.forEach((room) => {
          tenantId = room.tenantId;
          if (String(room.signType) !== '-1') isSignRoom = true;
          if (Number(bookType) === 2 && room.zoomRoomSwitch === 1)
            zoomRooms.push(String(room.roomId));
          if (Number(room.bizUsable) === 0) {
            // 展板扫码FM会议室，该会议室缺少必要属性，暂时不支持预订，请选择其它会议室！
            isFmRoom = true;
          }
        });
      }
      const checkIn = tenantId === 1000 ? scheckIn : lcheckIn;
      // if (!isSetSign && roomIds) {
      //   if ((Number(meetingId) > 0 && needToSign === 1) || !isSignRoom) {
      //     yield put({ type: 'update', payload: { isSetSign: true, needToSign: false } });
      //   } else {
      //     if (
      //       isSignRoom &&
      //       checkIn &&
      //       (Number(checkIn.defaultCheckInType) === 1 || Number(checkIn.defaultCheckInForce) === 0)
      //     ) {
      //       yield put({ type: 'update', payload: { needToSign: true, isSetSign: true } });
      //     }
      //   }
      // }
      // isSetSign 只在这里使用，导致每次选择或者去掉签到会议室，则签到状态不能跟随所选会议室变化
      if (roomIds) {
        // 如果强制签到开关打开签到状态打开且不可编辑，否则编辑状态时判断会议室是否变化，如果变化则根据配置修改签到状态
        const detailRoomId = detail?.roomInfo?.map(item=>{
          return String(item.roomId)
          
        })
        let isDetailSignRoom = false
        // 编辑时，详情中的会议室是否有签到会议室
        detail?.roomInfo?.forEach((room) => {
          tenantId = room.tenantId;
          if (String(room.signType) !== '-1') isDetailSignRoom = true;
        });
        const roomIdTemp = roomIds?.split(';')
        if (
          isSignRoom &&
          checkIn &&
          (Number(checkIn.defaultCheckInForce) === 0 || ((meetingId === 0 || !isDetailSignRoom && JSON.stringify(detailRoomId) !== JSON.stringify(roomIdTemp)) && Number(checkIn.defaultCheckInType) === 1))
        ) {
          // 处理签到开关手动关闭后，needToSign值为1但是在这里被修改为true的情况
          yield put({ type: 'update', payload: { needToSign: true } });
        } else {
          if ((Number(meetingId) > 0 && needToSign === 1) || !isSignRoom) {
            yield put({ type: 'update', payload: { needToSign: false } });
          }
        }
      }

      if (zoomRooms.length > 0) {
        if (zoomRooms.indexOf(String(mainZoom)) === -1) {
          yield put({ type: 'update', payload: { mainZoom: zoomRooms[0] } });
        }
      } else {
        yield put({ type: 'update', payload: { mainZoom: -1 } });
      }
      // 暂时把冲突会议 过滤掉
      const newSchedules = schedules.filter((meeting) => meeting.status !== 10);

      // 判断开始时间 结束时间 是否处于已经预约的是时间内
      if (isLongState === 0) {
        let isTimeOccupied = false;
        // 比较一下 开始时间 结束时间 在不在有效范围内
        const roomStart = moment(
          `${date} ${Number(enableHolidayReserve) === 1 ? '00:00' : avaliableStart}`,
        );
        const roomEnd = moment(
          `${date} ${Number(enableHolidayReserve) === 1 ? '24:00' : avaliableEnd}`,
        );
        const startTimeObj = moment(`${date} ${startTime}`);
        const endTimeObj = moment(`${date} ${endTime}`);
        for (let i = 0; i < newSchedules.length; i++) {
          const { meetingStartTime, meetingEndTime } = newSchedules[i];
          if (newSchedules[i].isSelf === '0') continue;
          if (
            startTimeObj.isBetween(
              moment(meetingStartTime).add(1, 'minute'),
              moment(meetingEndTime).subtract(1, 'minute'),
              'minute',
              '[]',
            ) ||
            endTimeObj.isBetween(
              moment(meetingStartTime).add(1, 'minute'),
              moment(meetingEndTime).subtract(1, 'minute'),
              'minute',
              '[]',
            ) ||
            (startTimeObj.isSameOrBefore(moment(meetingStartTime), 'minute') &&
              endTimeObj.isSameOrAfter(moment(meetingEndTime), 'minute'))
          ) {
            // 编辑会议时不需要置成true，重新发起需要重置为true,需要占用时间
            isTimeOccupied = from !== 'editMeetingPage'||!!isRestartMeeting;
            break;
          }
        }
        // startTime和endTime存在的时候才需要判断开始和结束时间是不是在 会议室工作时间之内
        if (
          (!!startTime &&
            !startTimeObj.isBetween(
              moment(roomStart).subtract(1, 'minute'),
              moment(roomEnd).add(1, 'minute'),
              'minute',
            )) ||
          (!!endTime &&
            !endTimeObj.isBetween(
              moment(roomStart).subtract(1, 'minute'),
              moment(roomEnd).add(1, 'minute'),
              'minute',
            )) ||
          isTimeOccupied
        ) {
          yield put({
            type: 'update',
            payload: { startTime: '', endTime: '' },
          });
        }

        if (((!!startTime && startTimeObj.isBefore(moment(), 'minute')) || isHoliday === 1) && editCycle !== 2) {
          yield put({
            type: 'update',
            payload: { startTime: '', endTime: '' },
          });
        }
      }

      if (isFmRoom) {
        Modal.alert(
          zhIntl('提示'),
          zhIntl('该会议室缺少必要属性，暂时不支持预订，请选择其它会议室！'),
          [{ text: zhIntl('确定') }],
        );
      }

      yield put({
        type: 'update',
        payload: {
          schedules: newSchedules,
          roomInfo: roomInfo.filter((item) => Number(item.bizUsable) === 1),
          webExSchedules,
          zoomSchedules,
          tencentSchedules,
          tencentMax: tencentMax || 10,
          maintains,
          isHoliday,
          avaliableStart,
          avaliableEnd,
          isFmRoom,
          isSpecialNet: [1, 2, 3, 5, 8].indexOf(Number(bookType)) > -1,
          roomIds:
            roomInfo && roomInfo?.length > 0
              ? roomInfo
                  .filter((item) => Number(item.bizUsable) === 1)
                  .map((item) => item.roomId)
                  .join(';')
              : roomIds,
        },
      });
      if (from === 'dateChange' && isHoliday === 1) {
        Modal.alert(
          formatMessage({ id: 'modal.notice' }),
          formatMessage({ id: 'subscribe.time.hoilday.ok' }),
          [{ text: formatMessage({ id: 'common_confirm' }) }],
        );
      }
      const { defaultBeforeStartSignTime, defaultAfterStartSignTime } = checkIn;
      if (
        defaultBeforeStartSignTime &&
        defaultAfterStartSignTime &&
        advSignTime < 0 &&
        afterSignTime < 0
      ) {
        yield put({
          type: 'update',
          payload: {
            advSignTime: defaultBeforeStartSignTime,
            afterSignTime: defaultAfterStartSignTime,
          },
        });
      }
      const vstCross = permissions?.some((item) => item === 'user@vst@acrossDay');
      yield put({
        type: 'update',
        payload: { vstCross },
      });
      return roomInfo;
    },
    *getSwitchConfig(__, { call, put, select }) {
      const { currentUser, permissions = [] } = yield select((state) => state.user);

      const allowGetApi = permissions?.some((item) => item === 'user@vst@reservation');
      if (!allowGetApi) {
        return;
      }
      const vstCross = permissions?.some((item) => item === 'user@vst@acrossDay');
      const user = JSON.parse(sessionStorage.getItem('user')) || {};
      const { tenantId } = currentUser;
      if (tenantId) {
        const result = yield call(AppService.getSwitchConfig, {
          companyId: tenantId || user.tenantId,
        });

        if (result?.code === 0) {
          yield put({
            type: 'update',
            payload: {
              acrossDayNum: result.data.acrossDayNum,
              visitDayNum: result.data.visitDayNum,
              vstCross,
            },
          });
        } else {
          yield put({
            type: 'update',
            payload: { vstCross },
          });
          Toast.fail(formatMessage({ id: result.msg }));
        }
      }
    },
    *submit({ newPromptParam }, { select, call, put, take }) {
      yield put({
        type: 'updatePromptParam',
        payload: {
          promptParam: newPromptParam,
        },
      });
      const {
        date,
        startTime,
        endTime,
        startTimeStamp: sTamp,
        endTimeStamp: eTamp,
        roomIds,
        hostId,
        hostName,
        creatorId,
        creatorName,
        remark,
        meetingId,
        needToSign,
        advSignTime,
        afterSignTime,
        type,
        sendEmail,
        sendSms,
        outSendSms,
        outSendEmail,
        bookType,
        selectedMembers,
        selectedExternal,
        joinPwd,
        hostJoinPwd,
        startNow,
        autoReconnect,
        cmmaStartAuto,
        // duration,
        webexMeeting,
        recurringRule,
        recurringExrule,
        recurringRange,
        mrecurringExrule,
        recurringFreq,
        recurringTime,
        recurringEndType,
        svcPrice,
        roomInfo,
        onlyTextEdit,
        compareKey,
        editSeries,
        source,
        zoomPwd,
        zoomAdvance,
        zoomWait,
        zoomMute,
        zoomRecordLocale,
        zoomAllowInBeforeHost,
        zoomType,
        roomPayerMap,
        vstTypeId,
        vstLocationId,
        visitorStartTime,
        visitorEndTime,
        mainZoom,
        meetingUserSeat,
        timeZone,
        isLongState,
        cycleOneEdit,
        welCome,
        waitingRoom,
        onlyEnterpriseUser,
        allowInBeforeHost,
        muteEnableJoin,
        hasZoom,
        hasTencent,
        hasFeiShu,
        hasTeams,
        hasDingDing,
        hasHuiChang,
        hasQuanShi,
        hasPoly,
        isSecretMeeting,
        isAllUser,
        tecentDisabled,
        roomPlan,
        agendaData,
        hostUsers,
        isFlyBook,
        isSplit,
        recurringId,
        svcRoomUpList,
        deviceData,
        deviceUpData,
        aduitValue,
        promptParam,
        specialGroup,
        isVisitorMeeting,
        meetingAttachments,
        signVisitorList,
        mExruleType,
        yExruleType,
        mrecurringExrule2,
        yrecurringExrule,
        editCycle
      } = yield select((state) => state.subscribe);
      const { showConfirm = false, canSplit } = promptParam;
      // const { svcIsCancel = -1, isChangeRecurringRole = false, goUpdateAudit = 0 } = newPromptParam;
      const { detail = {} } = yield select((state) => state.meetingDetail);
      let {
        subject: subjectStr,
        meetingScale,
        startTimeOption,
        duration,
      } = yield select((state) => state.subscribe);
      const { currentUser } = yield select((state) => state.user);
      const { serviceDataArray } = yield select((state) => state.manage);
      const {
        visitor,
        base,
        longMeeting = {},
        cycleMeeting = {},
        oneDayMeeting = {},
        exchange = {},
        feishu = {},
        googleCalendar = {},
        poly,
        smc,
        wechatMeeting,
      } = yield select((state) => state.sysModule);
      const { longMeeting: slong = {}, cycleMeeting: scycle = {} } = yield select(
        (state) => state.shareSysModule,
      );

      const { allRooms } = yield select((state) => state.zones);
      // fix case 100994 如果是teams tencent feishu webex 需要清除一下zoom 会议室
      let newRoomIds = roomIds;
      if (bookType === 3 || bookType === 4 || bookType === 5 || bookType === 8) {
        const roomIdArray = newRoomIds?.split(';');
        const roomIdArrayNoZoomId = roomIdArray?.filter((idString) => {
          const roomObj = allRooms.find((item2) => +idString == item2.roomId);
          if ((roomObj && roomObj.capacity === 8) || !roomObj) {
            return false;
          }
          return true;
        });
        newRoomIds = roomIdArrayNoZoomId?.join(';');
      }
      if (!subjectStr) {
        subjectStr = `${
          getLocale() === ENGLISH
            ? currentUser.realNameEn || currentUser.realName
            : currentUser.realName
        }${formatMessage({
          id: 'subscribe.subject.placeholder',
        })}`;
      }

      let allHost = []; // 会议室主持人为空
      let alloneHostNull = false; // 当前会议类型只能选择一个主持人
      if (roomPlan) {
        roomPlan?.forEach((host) => {
          if (canSplit && host.hostUser && host.hostUser.length > 1) {
            alloneHostNull = true;
          }
          if (host.hostUser && host.hostUser.length > 0) {
            allHost = allHost.concat(host.hostUser);
          }
        });
      }
      if (
        isAllUser === 1 ||
        (roomPlan && roomPlan.length === 0) ||
        roomPlan === null ||
        !canSplit
      ) {
        // 全员 || 会议室长度为0 || 主持人非拆分
        allHost = hostUsers;
      }
      if (allHost.length === 0) {
        // 全员 || 会议室长度为0  主持人为空
        Toast.fail(formatMessage({ id: 'subscribe.fail.value0' }), 2);
        return;
      }
      if (
        bookType > 1 &&
        bookType !== 7 &&
        bookType !== 4 &&
        ((alloneHostNull && isAllUser === 0) ||
          (isAllUser === 1 && hostUsers.length > 1) ||
          (roomPlan === null && hostUsers.length > 1) ||
          (!canSplit && hostUsers.length > 1))
      ) {
        Toast.fail(formatMessage({ id: 'subscribe.fail.one.host' }), 2);
        return;
      }

      if (subjectStr.length < 1) {
        Toast.fail(formatMessage({ id: 'subscribe.fail.value1' }), 2);
        return;
      }

      if (startNow) {
        if (!duration) {
          duration = startTimeOption && startTimeOption.length > 0 ? startTimeOption[0] : 15;
        }
        if (!duration || duration === '') {
          Toast.fail(formatMessage({ id: 'subscribe.startNow.placeholder' }), 2);
          return;
        }
      } else {
        if (!startTime || !endTime) {
          Toast.fail(formatMessage({ id: 'subscribe.fail.emptyMeetingTime' }), 2, null, false);
          yield put({ type: 'loadValidInfo' });
          yield put({ type: 'update', payload: { startTime: '', endTime: '' } });
          return;
        }

        if (bookType === 2) {
          const interDays = moment(
            `${date.replace(/\//g, '-')} ${endTime}`,
            `${DATE_FORMAT} ${TIME_FORMAT}`,
          ).diff(moment(), 'day');
          if (interDays > 90) {
            Toast.fail(formatMessage({ id: 'subscribe.fail.zoom.days.limit' }), 2, null, false);
            return;
          }
        }
      }

      if (bookType === 2 && meetingScale === null) {
        meetingScale = 0;
      }
      let newSelectedExternal = []; // 外部参会人（访问邀约）
      if (roomPlan && roomPlan.length) {
        roomPlan?.forEach((ritem) => {
          ritem.externalUsers.forEach((nrmeitem) => {
            newSelectedExternal.push(nrmeitem);
          });
        });
      } else if (roomPlan === null || roomPlan?.length === 0) {
        newSelectedExternal = selectedExternal;
      }
      const isShareRoom = checkIsShareRoom(allRooms, newRoomIds);
      if (isShareRoom) {
        // 共享会议室不集成访客
        newSelectedExternal = newSelectedExternal.map((item) => {
          return { ...item, invite: 0 };
        });
      }
      if (
        visitor &&
        newSelectedExternal &&
        newSelectedExternal.some((item) => item.invite) &&
        base?.showMeetingOutUser === '1' &&
        (bookType !== 9 || (bookType === 9 && wechatMeeting?.wechatMeetingPremium === '1'))
      ) {
        if (!vstTypeId) {
          Toast.fail(formatMessage({ id: 'meeting.booking.vst.type.empty' }), 2, null, false);
          return;
        }
        if (!vstLocationId) {
          Toast.fail(formatMessage({ id: 'meeting.booking.vst.location.empty' }), 2, null, false);
          return;
        }

        if (
          isLongState === 0 &&
          visitorStartTime &&
          signVisitorList?.length === 0 &&
          !visitorStartTime.isSame(visitorEndTime, 'day')
        ) {
          Toast.fail(formatMessage({ id: 'subscribe.fail.visit.cross' }));
          return;
        }
        if (
          isLongState === 1 &&
          visitorStartTime &&
          visitorStartTime.isSameOrAfter(visitorEndTime, 'day')
        ) {
          Toast.fail(formatMessage({ id: 'meeting.booking.vst.time.cross.validate' }));
          return;
        }
        if (startTime) {
          const handleTime =
            typeof startTime === 'string' ? moment(`${date} ${startTime}`) : startTime;

          // 会议开始时间 必须再访客到访时间之后
          if (moment(handleTime).isBefore(moment(visitorStartTime))) {
            Toast.fail(formatMessage({ id: 'meeting.vst.time.validate' }));
            return;
          }
        }
      }

      const d = moment(eTamp).diff(moment(sTamp), 'hours');
      const isLong = d >= 24;
      let startTimeStamp = isLong
        ? sTamp
        : moment(
            `${date.replace(/\//g, '-')} ${startTime}`,
            `${DATE_FORMAT} ${TIME_FORMAT}`,
          ).valueOf();
      if (typeof startTime === 'object') {
        startTimeStamp = startTime.valueOf();
      }
      let endTimeStamp = isLong
        ? eTamp
        : moment(
            `${date.replace(/\//g, '-')} ${endTime}`,
            `${DATE_FORMAT} ${TIME_FORMAT}`,
          ).valueOf();
      if (typeof endTime === 'object') {
        endTimeStamp = endTime.valueOf();
      }
      if (endTime === '00:00') {
        // 如果结束时间是00:00，说明是次日的零时
        endTimeStamp = moment(date.replace(/\//g, '-'), DATE_FORMAT).add(1, 'days').valueOf();
      }
      // 会议约访客，需提前预约
      let visitType =
        Number(type) === 0 &&
        !startNow &&
        newSelectedExternal &&
        newSelectedExternal.some((outer) => !!outer.invite)
          ? 1
          : 0;
      const auditFlag = roomInfo && roomInfo.some((r) => Number(r.needAudit) === 1) ? 1 : 0;
      // 需要付费会议室 id
      const roomPriceIdArray = [];
      allRooms.forEach((room) => {
        if (room.unitPrice > 0) {
          roomPriceIdArray.push(room.roomId);
        }
      });
      const roomPayInfo = newRoomIds?.split(';').map((id) => ({
        roomId: Number(id),
        roomPayer: roomPriceIdArray.includes(+id) ? String(currentUser.userId) : '',
        svcPayer: '',
      }));
      if (!_.isEmpty(roomPayerMap)) {
        Object.keys(roomPayerMap).forEach((roomId) => {
          const roomPayObj = roomPayInfo.find((item) => item.roomId === +roomId);
          if (roomPayObj) {
            const obj = roomPayerMap[roomId];
            if (obj.roomPayer) {
              roomPayObj.roomPayer = obj.roomPayer;
            }
            if (obj.svcPayer) {
              roomPayObj.svcPayer = obj.svcPayer;
            }
          }
        });
      }
      const startTimeStr = moment(startTimeStamp).format('YYYY-MM-DD HH:mm');
      const endTimeStr = moment(endTimeStamp).format('YYYY-MM-DD HH:mm');
      let newNeedToSign;
      if (_.isNumber(needToSign)) {
        newNeedToSign = needToSign;
      } else {
        newNeedToSign = needToSign ? 0 : 1;
      }

      // 如果没有签到会议室，则签到状态置为1
      if (needToSign || needToSign === 0) {
        let isSignRoom = false
          roomInfo?.forEach((room) => {
          if (String(room.signType) !== '-1') isSignRoom = true;
        });
        if(!isSignRoom){
          newNeedToSign = 1;
        }
      }

      const nowRoomPlan = [];
      const curhostName = [];
      const curhostIds = [];
      let selUser = []; // 内部参会人
      let outerUser = []; // 外部参会人
      if (roomPlan) {
        roomPlan.forEach((roomdata) => {
          const { roomId = '', hostUser = [], selectUsers = [], externalUsers = [] } = roomdata;
          const roomPlanParam = {
            roomId,
            holderIds: hostUser
              .map((hostitem) => {
                return hostitem.userId;
              })
              .join(';'),
            innerIds: selectUsers
              .map((selitem) => {
                return selitem.userId;
              })
              .join(';'),
            outerIds: externalUsers
              .map((outeritem) => {
                return outeritem.id;
              })
              .join(';'),
          };
          if (newRoomIds?.split(';').indexOf(String(roomId)) > -1) {
            hostUser.forEach((hitem) => {
              curhostName.push(hitem.realName);
              curhostIds.push(hitem.userId);
            });
            selUser = selUser.concat(selectUsers);
            outerUser = outerUser.concat(externalUsers);
            // 如果外部参会人模块关闭则将外部参会人信息清空
            if (base?.showMeetingOutUser === '0') {
              roomPlanParam.outerIds = '';
            }
            nowRoomPlan.push(roomPlanParam);
          }
        });
      }
      if (isAllUser === 1 || (roomPlan && roomPlan.length === 0) || roomPlan === null) {
        outerUser = selectedExternal;
      }
      if ((roomPlan && roomPlan.length === 0) || roomPlan === null) {
        selUser = selectedMembers;
      }
      if (outerUser) {
        outerUser = outerUser.map((item) => {
          return { ...item, invite: item.invite ? 1 : 0 };
        });
      }
      const apihostId =
        isAllUser === 1 || (roomPlan && roomPlan.length === 0) || roomPlan === null || !canSplit
          ? hostId
          : curhostIds.join(';');
      const apiinnerUser = isAllUser === 1 ? [] : selUser;
      const zoomAdv =
        waitingRoom +
          onlyEnterpriseUser +
          allowInBeforeHost +
          muteEnableJoin +
          zoomWait +
          zoomMute +
          zoomRecordLocale +
          zoomAllowInBeforeHost >
        1
          ? 1
          : 0;
      if (type > 0 || isShareRoom || !isVisitorMeeting) {
        // 循环会议不支持访客预约
        outerUser = outerUser.map((item) => {
          return { ...item, invite: 0 };
        });
      }
      // 企业微信会议非高级版不支持外部参会人
      if (bookType === 9 && wechatMeeting?.wechatMeetingPremium === '0') {
        outerUser = [];
        visitType = '0';
      }
      // webex会议在跨天的时候不支持超过24小时
      if (bookType === 3 && isLongState === 1) {
        console.log(startTimeStr, endTimeStr);
        const diffMinute = moment(endTimeStamp).diff(moment(startTimeStamp), 'minute');
        if (diffMinute >= 1435) {
          Toast.fail(zhIntl('WebEx会议不允许预订超过24小时的会议'));
          return;
        }
      }
      if(smc && bookType === 1){
        const diffMinute = moment(endTimeStamp).diff(moment(startTimeStamp), 'minute');
        // 华为视频除立即开始外最小只能约30分钟会议
        if(diffMinute<30 && !startNow){
          Toast.fail(zhIntl('华为视频会议时长不能小于30分钟'));
          return
        }
        if (diffMinute > 1440 && isLongState === 1) {
          Toast.fail(zhIntl('华为会议不允许预订超过24小时的会议'));
          return;
        }
      }
      let startTimeStr2 = startTime;
      let endTimeStr2 = endTime;
      if (typeof startTimeStr2 === 'object') {
        startTimeStr2 = startTimeStr2.format('YYYY-MM-DD HH:mm');
      } else {
        startTimeStr2 = `${moment().format('YYYY-MM-DD')} ${startTime}`;
      }
      if (typeof endTimeStr2 === 'object') {
        endTimeStr2 = endTimeStr2.format('YYYY-MM-DD HH:mm');
      } else {
        endTimeStr2 = `${moment().format('YYYY-MM-DD')} ${endTime}`;
      }
      let newAdvSignTime = startNow ? 0 : Number(advSignTime);

      //
      const userColor = {};
      hostUsers.forEach((item) => {
        userColor[`0-${item.userId}`] = item.color || UserColor[(item.userId || 2) % 6];
      });
      apiinnerUser.forEach((item) => {
        userColor[`0-${item.userId}`] = item.color || UserColor[(item.userId || 2) % 6];
      });
      outerUser.forEach((item) => {
        userColor[`1-${item.id}`] = item.color || UserColor[(item.id || 2) % 6];
      });
      const { exSynDays = 30 } = exchange;
      const { feishuSyncDays = 30 } = feishu;
      const { gcSynDays = 30 } = googleCalendar;
      const capacitys = [3, 5, 10];
      const isOnlineRoom =
        roomInfo && roomInfo.some((item) => capacitys.includes(Number(item?.capacity)));
      let beforeDaysNum = 0;
      let ondaymaxTime = 0;
      const specialDayArr = [];
      let specialMinDay = 30;
      let isShareTenant = 0;
      let hasO365 = false; // 是否选择了O365会议室
      const isCanReserve = checkIsNotEmpty(specialGroup) && Number(specialGroup?.noLimit) === 0; // 特殊预定--若勾选了不受预订期限制，则不考虑会议室提前预定的内容
      if (newRoomIds && allRooms && allRooms.length > 0) {
        const roomIdArr = newRoomIds.split(';');
        allRooms.forEach((r) => {
          if (!isCanReserve && roomIdArr.indexOf(String(r.roomId)) > -1 && r.beforeDays > 0) {
            beforeDaysNum = beforeDaysNum > r.beforeDays ? beforeDaysNum : r.beforeDays;
          }
          if (roomIdArr.indexOf(String(r.roomId)) > -1 && r.capacity === 3) {
            hasO365 = true;
            specialDayArr.push(Number(exSynDays || 30));
          }
          if (roomIdArr.indexOf(String(r.roomId)) > -1 && r.capacity === 10) {
            specialDayArr.push(Number(feishuSyncDays || 30));
          }
          if (roomIdArr.indexOf(String(r.roomId)) > -1 && r.capacity === 5) {
            specialDayArr.push(Number(gcSynDays || 30));
          }
          if (roomIdArr.indexOf(String(r.roomId)) > -1) {
            isShareTenant = Number(r?.tenantId);
          }
        });
        const ondayroomLists = allRooms.filter(
          (item) => roomIdArr.indexOf(item.roomId.toString()) > -1,
        );
        ondayroomLists.forEach((r) => {
          if (r.maximumTime > 0) {
            if (ondaymaxTime === 0) {
              ondaymaxTime = r.maximumTime;
            } else {
              ondaymaxTime = ondaymaxTime < r.maximumTime ? ondaymaxTime : r.maximumTime;
            }
          }
        });
        ondaymaxTime = ondaymaxTime === 0 ? Number(base.meetingRoomMaxTime) : ondaymaxTime;
      }
      if (isOnlineRoom) {
        specialDayArr.sort(function (m, n) {
          if (m < n) return -1;
          else if (m > n) return 1;
          else return 0;
        });
        specialMinDay = specialDayArr[0];
      }
      const enableMeetingMaxTime = Number(base ? base.enableMeetingMaxTime : 0);
      // 计算处理单场会议最小最大时长，天数以及是否可审批预约超出天数的
      const {
        hasLimit,
        limitMinTime,
        limitMaxTime,
        limitMaxDay,
        allowReserveOutMeeting,
        isNoLimit,
        isAlwaysLimit,
        isNormal,
        isShowNone,
        onlyDayShow,
      } = Common.checkTimeLimit({
        long: isShareTenant === 1000 ? slong : longMeeting,
        cycle: isShareTenant === 1000 ? scycle : cycleMeeting,
        oneDayMeeting,
        enableMeetingMaxTime,
        maxTime: ondaymaxTime,
        isLoop: type !== MEETING_TYPE.normal,
        cycleOneEdit,
        isLong: !!isLongState,
        specialGroup,
        isOnlineRoom,
        specialMinDay,
        exchange,
        hasO365,
      });
      // O365关闭、O365打开且选择了O365会议室才有时间限制，O365打开但是没有选择O365会议室则无时间限制
      // onlyDayShow O365打开且选择了O365会议室，只走O365的天数校验
      if (hasLimit) {
        // 禁用日期
        const limitMaxDate = moment()
          .add(Number(limitMaxDay - 1 || 0), 'days')
          .endOf('day'); // 系统配置的最大可选日期
        const needAheadDate = beforeDaysNum
          ? moment()
              .add(Number(beforeDaysNum || 0), 'days')
              .startOf('day')
          : moment().startOf('day'); // 会议室需提前审批天数
        if (
          onlyDayShow &&
          (moment(startTimeStr).isBefore(needAheadDate, 'days') ||
            moment(startTimeStr).isAfter(limitMaxDate, 'days'))
        ) {
          // O365&O365会议室
          // 提前预订跟预订多少天内会议的提示分开展示
          if(moment(startTimeStr).isBefore(moment())){
            Toast.fail(
            formatMessage(
              { id: 'meeting.booking.normal_day_meeting_limit_date' },
            ),
            2,
            null,
            false,)
          } else if (moment(startTimeStr).isBefore(needAheadDate, 'days')) {
            const selectRoomId = roomIds?.split(SEMICOLON);
            const roomLists = allRooms?.filter(
              (item) => selectRoomId?.indexOf(item.roomId.toString()) > -1,
            );
            let maxObject = roomLists?.reduce((max, obj) => {
              return max.beforeDays > obj.beforeDays ? max : obj;
            }, roomLists[0]);
            let rName =
              getLocale() === ENGLISH
                ? maxObject.nameEn || maxObject.name
                : getLocale() === CHINESE
                ? maxObject.name
                : maxObject.nameTw || maxObject.name;
            const str = `${rName + '\xa0'}${formatMessage(
              { id: 'meeting.booking.before.day.room.new' },
              { value: maxObject.beforeDays },
            )}`;
            Toast.fail(str, 2, null, false);
          } else {
            Toast.fail(
              formatMessage({ id: 'meeting.booking.max.day.room' }, { value: limitMaxDay }),
              2,
              null,
              false,
            );
          }
          // Toast.fail(
          //   formatMessage(
          //     { id: 'meeting.booking.normal_day_meeting_limit_date' },
          //   ),
          //   2,
          //   null,
          //   false,
          // );
          return;
        }
        if (
          !onlyDayShow &&
          (moment(startTimeStr).isBefore(needAheadDate, 'days') ||
            (moment(startTimeStr).isAfter(limitMaxDate, 'days') &&
              !allowReserveOutMeeting &&
              (!isNoLimit || isAlwaysLimit)))
        ) {
          // 提前预订跟预订多少天内会议的提示分开展示
          if(moment(startTimeStr).isBefore(moment())){
            Toast.fail(
            formatMessage(
              { id: 'meeting.booking.normal_day_meeting_limit_date' },
            ),
            2,
            null,
            false,)
          } else if (moment(startTimeStr).isBefore(needAheadDate, 'days')) {
            const selectRoomId = roomIds?.split(SEMICOLON);
            const roomLists = allRooms?.filter(
              (item) => selectRoomId?.indexOf(item.roomId.toString()) > -1,
            );
            let maxObject = roomLists?.reduce((max, obj) => {
              return max.beforeDays > obj.beforeDays ? max : obj;
            }, roomLists[0]);
            let rName =
              getLocale() === ENGLISH
                ? maxObject.nameEn || maxObject.name
                : getLocale() === CHINESE
                ? maxObject.name
                : maxObject.nameTw || maxObject.name;
            const str = `${rName + '\xa0'}${formatMessage(
              { id: 'meeting.booking.before.day.room.new' },
              { value: maxObject.beforeDays },
            )}`;
            Toast.fail(str, 2, null, false);
          } else {
            Toast.fail(
              formatMessage({ id: 'meeting.booking.max.day.room' }, { value: limitMaxDay }),
              2,
              null,
              false,
            );
          }
          // 当前所选日期，不在允许预约的日期范围内
          // Toast.fail(
          //   formatMessage(
          //     { id: 'meeting.booking.normal_day_meeting_limit_date' },
          //   ),
          //   2,
          //   null,
          //   false,
          // );
          return;
        }

        const timeLong = startNow
          ? duration
          : moment(endTimeStr).diff(moment(startTimeStr), 'minutes');
        if (!smc && !onlyDayShow && (timeLong > limitMaxTime || timeLong < limitMinTime)) {
          Toast.fail(
            formatMessage(
              { id: 'meeting.booking.normal_day_meeting_limit' },
              { limitMinTime, limitMaxTime },
            ),
            2,
            null,
            false,
          );
          return;
        }
        // 华为视频最小为30分钟会议，如果限制小于30分钟就走30分钟限制，大于三十分钟则走配置限制
        if (smc && !onlyDayShow && limitMaxTime>30 && limitMinTime>30 && (timeLong > limitMaxTime || timeLong < limitMinTime)) {
          Toast.fail(
            formatMessage(
              { id: 'meeting.booking.normal_day_meeting_limit' },
              { limitMinTime, limitMaxTime },
            ),
            2,
            null,
            false,
          );
          return;
        }
      }
      // 处理周期会议，选择按结束时间，但是没有填写时间的场景
      if(cycleMeeting && (meetingId>0? Number(editCycle) === 2:true) && recurringRule > 0 && recurringEndType === 0 && !recurringTime){
        Toast.fail(formatMessage({ id: 202011 }));
        return;
      }
      const data1 = {
        meetingId: meetingId || 0,
        source,
        name: subjectStr,
        bookType: tecentDisabled
          ? Number(isFlyBook) === 1 && bookType === 8
            ? MEETING_BOOK_TYPE.FEISHU
            : bookType
          : 4,
        isWebex: Boolean(webexMeeting && bookType === 3),
        joinPwd,
        hostJoinPwd,
        roomIds: newRoomIds,
        hostId: apihostId,
        hostName:
          isAllUser === 1 || (roomPlan && roomPlan.length === 0) || roomPlan === null || !canSplit
            ? hostName
            : curhostName.join(';'),
        isSplit: meetingId > 0 ? isSplit : canSplit, //  编辑会议-保存是传接口返回的isSplit(代订-多会议室 由普通用户编辑时，isSplit不能置为false,否则代订人再次编辑会出错)
        creatorId,
        creatorName,
        needToSign: newNeedToSign,
        advSignTime: newAdvSignTime,
        afterSignTime: Number(afterSignTime),
        audit: auditFlag,
        startTimeStamp: startTime,
        endTimeStamp: endTime,
        startTime: timeZone
          ? moment.tz(startTimeStr, timeZone).valueOf()
          : moment(startTimeStr).valueOf(),
        endTime: timeZone
          ? moment.tz(endTimeStr, timeZone).valueOf()
          : moment(endTimeStr).valueOf(),
        sendEmail,
        sendSms,
        outSendSms,
        outSendEmail,
        remark,
        startNow: startNow ? 0 : 1,
        duration,
        autoReconnect: autoReconnect ? 1 : 0,
        cmmaStartAuto: cmmaStartAuto ? 1 : 0,
        innerUser: apiinnerUser,
        outerUser,
        visitType,
        type, // 会议类型(0: 普通会议|1.周期会议[按天]|2: 周期会议[按周]|3: 周期会议[按月]|4: 周期会议[按工作日])
        recurringRule: type === 0 ? 0 : recurringRule,
        recurringRange,
        recurringExrule:
          recurringRule === 2
            ? recurringExrule.join(';') || ''
            : recurringRule === 3
            ? mrecurringExrule
            : '',
        recurringFreq,
        // recurringTime,
        recurringEndType,
        svcPrice,
        onlyTextEdit,

        isZoom: Boolean(bookType === 2),
        zoomPwd,
        zoomAdvance,
        zoomAdvanceSetting: zoomAdv,
        zoomWait,
        zoomMute,
        zoomRecordLocale,
        zoomAllowInBeforeHost,
        zoomType,
        meetingScale,
        roomPayInfo,
        vstTypeId:
          newSelectedExternal &&
          newSelectedExternal.some((outer) => !!outer.invite) &&
          bookType !== 9
            ? vstTypeId
            : '',
        vstLocationId:
          newSelectedExternal &&
          newSelectedExternal.some((outer) => !!outer.invite) &&
          bookType !== 9
            ? vstLocationId
            : '',
        visitorStartTime:
          newSelectedExternal &&
          newSelectedExternal.some((outer) => !!outer.invite) &&
          bookType !== 9
            ? moment(visitorStartTime)?.seconds(0)?.valueOf()
            : '',
        visitorEndTime:
          newSelectedExternal &&
          newSelectedExternal.some((outer) => !!outer.invite) &&
          bookType !== 9
            ? moment(visitorEndTime)?.seconds(0)?.valueOf()
            : '',
        meetingUserSeat,
        timeZone,
        isLongState,
        welCome: welCome || '',
        waitingRoom,
        onlyEnterpriseUser,
        allowInBeforeHost,
        muteEnableJoin,
        hasZoom,
        hasTencent,
        hasTeams,
        hasHuiChang,
        hasQuanShi,
        hasPoly,
        hasFeiShu,
        hasDingDing,
        isSecretMeeting,
        isAllUser,
        roomPlan:
          isAllUser !== 0 || roomPlan === null || !newRoomIds || nowRoomPlan?.length === 0
            ? null
            : nowRoomPlan,
        userColor: userColor, // 内部参会人 外部参会人 颜色
        // svcIsCancel,
        svcRoomList: serviceDataArray,
        svcRoomUpList,
        deviceData,
        deviceUpData,
        modifyOutUsers: [],
        meetingAttachments,
        ...promptParam,
      };
      // if (data1.meetingId > 0) {
      //   // 编辑会议时
      //   data1.svcIsCancel = svcIsCancel;
      //   data1.isChangeRecurringRole = isChangeRecurringRole;
      //   data1.goUpdateAudit = goUpdateAudit;
      // }
      // 如果是相对月，则将type值由3改为5传给后端 3代表绝对月5代表相对月
      if (recurringRule === 3 && (mExruleType === '2' || mExruleType === '3')) {
        data1.recurringRule = 5
        data1.recurringExrule = mrecurringExrule2
        data1.type = 5
      }
      // 如果是相对年，则将type值由7改为6传给后端 7代表绝对年6代表相对年
      if (recurringRule === 7) {
        if(yExruleType === '2' || yExruleType === '3'){
          data1.recurringRule = 6
          data1.type = 6
        }
        data1.recurringExrule = yrecurringExrule
      }
      if (data1.recurringEndType === 0) {
        data1.recurringTime = recurringTime;
      }
      // 如果外部参会人模块关闭，则将外部参会人数据清空
      if (base?.showMeetingOutUser === '0') {
        console.log(roomPlan,'roomPlan')
        data1.visitType = '0';
        data1.vstTypeId = '';
        data1.vstLocationId = '';
        data1.visitorStartTime = null; // 访客到访时间
        data1.visitorEndTime = null; // 访客到访时间
        data1.outerUser = [];
        data1.modifyOutUsers = []
      }
      if (!!poly && data1.bookType === 1 && !!data1.joinPwd && data1.joinPwd.length < 5) {
        Toast.fail(
          formatMessage({ id: 'meeting.booking.not.joinPwd.length.less' }),
          2,
          null,
          false,
        );
        return;
      }
      if (!!smc && data1.bookType === 1 && (!!data1.joinPwd && data1.joinPwd.length < 6 || !!data1.hostJoinPwd && data1.hostJoinPwd.length < 6)) {
        Toast.fail(
          formatMessage({ id: 'meeting.booking.not.joinPwd.length.less6' }),
          2,
          null,
          false,
        );
        return;
      }
      const checkedArr = [
        hasZoom,
        hasTencent,
        hasFeiShu,
        hasTeams,
        hasDingDing,
        hasHuiChang,
        hasQuanShi,
        hasPoly,
      ].filter((item) => !!item);
      if (!!poly && data1.bookType === 1 && checkedArr && checkedArr?.length === 0) {
        Toast.fail(formatMessage({ id: 'meeting_poly_check_least_one' }), 2, null, false);
        return;
        // data1.hasPoly = 1
      }
      if (data1.recurringEndType === 0 && Number(type) > 0 && recurringTime && data1.startTime) {
        if (moment(recurringTime).isBefore(moment(data1.startTime), 'day')) {
          Toast.fail(formatMessage({ id: 'meeting_cycle_booking_error_tips' }), 2, null, false);
          return;
        }
      }
      if (data1.meetingId && aduitValue) {
        data1.audit = Number(aduitValue) - 1;
      }
      if (mainZoom > 0) {
        data1.mainRoomId = mainZoom;
      }
      if (recurringId && data1.meetingId) {
        data1.recurringId = recurringId;
      }
      let reCreate = false;
      const key = buildCompareKey({
        editSeries,
        meetingInfo: data1,
        innerUser: isAllUser === 1 ? [] : selUser,
        outerUser,
      });
      if (data1.innerUser) {
        data1.innerUser.forEach((item, index) => {
          Object.assign(item, { ord: index });
        });
      }
      if (data1.bookType === MEETING_BOOK_TYPE.NORMAL && data1.roomIds === '') {
        Toast.fail(formatMessage({ id: 'subscribe.warning.room' }));
        return;
      }
      //
      if (data1.type === 0 && data1.isLongState === 1) {
        const momentStart = moment(data1.startTime);
        const momentEnd = moment(data1.endTime);
        if (momentStart.format('YYYY-MM-DD') === momentEnd.format('YYYY-MM-DD')) {
          Toast.fail(formatMessage({ id: 'subscribe.cross_days_meeting_days_limit' }));

          return;
        }
      }
      if (!showConfirm && key && compareKey) {
        if (key === compareKey) {
          data1.onlyTextEdit = 1;
          yield put({ type: 'update', payload: { onlyTextEdit: 1 } });
        }
      }
      const newAgendaData = [];
      agendaData.forEach((arr) => {
        if (
          !(
            arr.startTime === '' &&
            arr.endTime === '' &&
            arr.name === '' &&
            arr.innerIds === '' &&
            arr.outerIds === ''
          )
        ) {
          newAgendaData.push(arr);
        }
      });
      const AgendaD = [];
      newAgendaData.forEach((ageD) => {
        // 解决主持人，参会人，外部参会人改变后议程里参会人没做联动改变
        let newAgeDinnerIds = ageD?.innerIds || ''
        let newAgeDouterIds = ageD?.outerIds || ''
        if(ageD?.innerIds && ageD?.innerIds !=='all'){
          const hostIdTemp = String(data1?.hostId)?.split(';') || []
          const innerUserTemp = data1?.innerUser?.map(item=>{
            return String(item?.userId)
          }) || []
          const innerIdTemp = hostIdTemp?.concat(innerUserTemp)
          newAgeDinnerIds = String(ageD?.innerIds)?.split(';')?.filter(item=>{
            return innerIdTemp?.includes(item)
          }) || []
          newAgeDinnerIds = newAgeDinnerIds?.join(';')
        }
        if(ageD?.outerIds){
          const outerUserTemp = data1?.outerUser?.map(item=>{
            return String(item?.id)
          }) || []
          newAgeDouterIds = String(ageD?.outerIds)?.split(';')?.filter(item=>{
            return outerUserTemp?.includes(item)
          }) || []
          newAgeDouterIds = newAgeDouterIds?.join(';')
        }
        const param = {
          id: typeof ageD.id === 'string' ? 0 : ageD.id,
          name: ageD.name,
          startTime: ageD.startTime,
          endTime: ageD.endTime,
          innerIds:ageD.innerIds==='all'?'':newAgeDinnerIds,
          outerIds: newAgeDouterIds,
          fileDTos: ageD?.fileDTos || [],
        };
        AgendaD.push(param);
      });
      // 对比一下 外部参会人  是访客 与meetingInfo  里面 有访客的对比一下  如果信息有更改， 把Id 存放到 modifyOutUsers 里面
      if (meetingId > 0) {
        detail.outerUser.forEach((item) => {
          data1.outerUser.forEach((newItem) => {
            if (item.id === newItem.id && item.invite === 1 && newItem.invite === 1) {
              delete item.color;
              delete newItem.color;
              if (!Common.customizer(item, newItem)) {
                data1.modifyOutUsers.push(item.id);
              }
            }
          });
        });
      }
      data1.agendaInfoList = data1.type < 1 && data1.startNow !== 0 ? AgendaD : [];
      // 超过20位手机号只存储不展示
      if(data1?.outerUser?.length>0 && data1?.outerUser[0]?.mobileTemp){
        data1?.outerUser?.forEach(item=>{
          if(item?.mobileTemp){
            item.mobile = item.mobileTemp
            delete item.mobileTemp
            item.phone = item.phoneTemp
            delete item.phoneTemp
          }
        })
      }
      if (!reCreate) {
        const result = yield call(subscribeService.reserveMeeting, {
          params: { ...data1, from: 'app' },
        });
        if (result.code === 0) {
          const { meetingInfo = {}, roomInfos = [] } = result.data[0];
          if (meetingInfo.bookType === 2) {
            const isFree = roomInfos.some(
              (r) => r.meetingScale === 0 && r.capacity === MEETING_BOOK_TYPE.FEISHU,
            );
            if (isFree) {
              Toast.success(formatMessage({ id: 'subscribe.free.success' }));
            }
          }
          const { meetingId: mid, startNow, needToSign } = meetingInfo;
          yield put({ type: 'update', payload: { meetingId: mid } });
          yield put({ type: 'meetingDetail/reload', meetingId: mid });
          yield take('meetingDetail/reload/@@end');
          const {
            meetingDetail: { detail = {} },
          } = yield select((state) => state);
          const { audit = MEETING_AUDIT.no } = detail;
          if (audit === MEETING_AUDIT.no && !startNow) {
            yield put({ type: 'manage/update', payload: { meetingId: mid } });
            yield put({ type: 'savePolling', payload: { meetingId: mid } });
            yield take('savePolling/@@end');
          }
          yield put({ type: 'update', payload: { showPriceModal: false } });
          yield put({ type: 'myMeeting/update', payload: { isLongState: 0 } });
          yield put({
            type: 'update',
            payload: {
              svcRoomUpList: [], //  编辑会议时  用户取消 或者删除服务时信息
              deviceUpData: [], //  编辑会议时  用户取消 或者删除设备时信息
              deviceData: [],
              meetingDeviceForBooking: [],
              isInit: false
            },
          });
          yield put({
            type: 'svcFeeDetail/update',
            payload: {
              executeNum: 1,
            },
          });
          let isSignRoom = false
          roomInfos?.forEach((room) => {
            if (String(room.signType) !== '-1') isSignRoom = true;
          });
          if (startNow === 0 && needToSign === 0 && isSignRoom) {
            yield put({ type: 'update', payload: { startNowAndSignVisibile: true } });
          } else {
            history.push(MENU_PATH.myMeeting);
          }

          // 预约完成清空会议服务、移动设备等数据
          yield put({ type: 'manage/reset' });
        } else if (result.msg === '202077') {
          Toast.fail(
            formatMessage(
              { id: result.msg },
              {
                roomList:
                  ENGLISH === getLocale()
                    ? result.data.nameEn
                    : CHINESE === getLocale()
                    ? result.data.name
                    : result.data.nameTw,
              },
            ),
          );
        } else if (result.msg === '202028') {
          Toast.fail(formatMessage({ id: result.msg }, { time: result.data }));
        } else if (result.msg === '202206' || result.msg === '202208') {
          Toast.fail(formatMessage({ id: result.msg }, { value: result.data }));
        } else if (result.msg === '202171') {
          Toast.fail(formatMessage({ id: result.msg }, { value: result.data }));
        } else if (result.msg === '202170' || result.msg === '202169') {
          const { min, max } = result.data;
          Toast.fail(
            formatMessage(
              { id: 'meeting.booking.normal_day_meeting_limit' },
              { limitMinTime: min, limitMaxTime: max },
            ),
          );
        } else if (result.msg === '202110' || result.msg === '202111') {
          Toast.fail(
            formatMessage(
              { id: result.msg },
              {
                rooms:
                  ENGLISH === getLocale()
                    ? result.data.roomNameEn
                    : CHINESE === getLocale()
                    ? result.data.roomName
                    : result.data.roomNameTw || result.data.roomName,
              },
            ),
          );
        } else if (result.msg === '202112') {
          const isEdit = meetingId && Number(meetingId) > 0;
          Toast.fail(
            formatMessage(
              {
                id: isEdit
                  ? 'meeting.booking.fail.title.maintain.edit'
                  : 'meeting.booking.fail.title.maintain',
              },
              {
                roomName:
                  ENGLISH === getLocale()
                    ? result.data.roomNameEn
                    : CHINESE === getLocale()
                    ? result.data.roomName
                    : result.data.roomNameTw || result.data.roomName,
              },
            ),
          );
        } else if (result.msg === '202123') {
          Toast.fail(
            formatMessage(
              {
                id: 'meeting.booking.fail.tencent.max',
              },
              { num: result.data },
            ),
          );
        } else if (result.msg === '203002') {
          Toast.fail(
            formatMessage(
              {
                id: 'cmma_no_devices',
              },
              { num: result.data },
            ),
          );
        } else if (result.msg === '202134') {
          Toast.fail(formatMessage({ id: 'error.meeting.teams.timeout' }));
        } else if (result.msg === '200999') {
          const { tipType } = result.data;
          if (Number(tipType) === 0) {
            yield put({ type: 'update', payload: { showModal: true, conflicts: result.data } });
          } else if (Number(tipType) === 1) {
            yield put({
              type: 'update',
              payload: { showPriceModal: true, priceInfo: result.data },
            });
          }
        } else if (result.msg === '202075') {
          yield put({
            type: 'update',
            payload: { showAuditModal: true, auditTipData: result.data },
          });
        } else if (result.msg === '202076') {
          yield put({
            type: 'update',
            payload: { showHostAuditModal: true, MeetingHostUserData: result.data },
          });
        } else if (result.msg === '207012') {
          let maxTime = 0;
          const selectRoomId = roomIds.split(SEMICOLON);
          const roomLists = allRooms.filter(
            (item) => selectRoomId.indexOf(item.roomId.toString()) > -1,
          );
          roomLists.forEach((r) => {
            if (r.maximumTime > 0) {
              if (maxTime === 0) {
                maxTime = r.maximumTime;
              } else {
                maxTime = maxTime < r.maximumTime ? maxTime : r.maximumTime;
              }
            }
          });
          maxTime = maxTime === 0 ? Number(base.meetingRoomMaxTime) : maxTime;
          Toast.fail(formatMessage({ id: result.msg }, { value: maxTime }));
        } else if (result.msg === '202107') {
          let str = '';
          const selectRoomId = roomIds.split(SEMICOLON);
          const roomLists = allRooms.filter(
            (item) => selectRoomId.indexOf(item.roomId.toString()) > -1,
          );
          let maxObject = roomLists.reduce((max, obj) => {
            return max.beforeDays > obj.beforeDays ? max : obj;
          }, roomLists[0]);
          let rName =
            getLocale() === ENGLISH
              ? maxObject.nameEn || maxObject.name
              : getLocale() === CHINESE
              ? maxObject.name
              : maxObject.nameTw || maxObject.name;
          if (!isCanReserve && maxObject.beforeDays > 0) {
            str += `${rName + '\xa0'}${formatMessage(
              { id: 'meeting.booking.before.day.room.new' },
              { value: maxObject.beforeDays },
            )}`;
          }
          Toast.fail(str);
        } else if (result.msg === '202064') {
          const { longMeetingMaxDays } = longMeeting;
          Toast.fail(formatMessage({ id: result.msg }, { value: longMeetingMaxDays }));
        } else if (result.msg === '202178' || result.msg === '202181') {
          Toast.fail(formatMessage({ id: result.msg }, { value: result.data?.key1 }));
        } else if (result.msg === '202189' || result.msg === '202191') {
          Toast.fail(
            formatMessage({
              id:
                result.msg === '202189'
                  ? 'meeting.booking.agenda.tip'
                  : 'meeting.booking.agenda.tip2',
            }),
            2,
          );
        } else if (
          result.msg === '202188' ||
          result.msg === '202185' ||
          result.msg === '202186' ||
          result.msg === '202187' ||
          result.msg === '202198'
        ) {
          // 202188 编辑会议-循环会议重新生成
          // 202185 202186 202187  编辑会议--审批后再次审批提示
          // 202198 编辑会议--服务 设备提示
          yield put({
            type: 'update',
            payload: {
              bookingMsgModalVisible: true,
              submitAgainObj: { newWorkMsg: result.msg, data: result.data },
            },
          });
        } else if (result.msg === '202195') {
          Toast.fail(formatMessage({ id: result.msg }, { value: result.data }));
        } else if (result.msg === '202214') {
          if (!!result.data?.visitorCount) {
            Toast.fail(
              formatMessage(
                { id: 'meeting.booking.fail.visit.invite.more' },
                {
                  timeFormat: moment(result.data?.startVisitTime || visitorStartTime)?.format(
                    'YYYY-MM-DD',
                  ),
                  visitorCount: result.data?.visitorCount,
                },
              ),
            );
          } else {
            Toast.fail(
              formatMessage(
                { id: 'meeting.booking.fail.visit.invite.enough' },
                {
                  timeFormat: moment(result.data?.startVisitTime || visitorStartTime)?.format(
                    'YYYY-MM-DD',
                  ),
                },
              ),
            );
          }
        } else if (result?.msg === '202408') {
          Toast.fail(
            formatMessage(
              {
                id: result?.data?.type === 1?'meeting.booking.fail.smc.min':'meeting.booking.fail.smc.min.normal',
              },
              { duration: result?.data?.duration },
            ),
          );
        } else {
          Toast.fail(formatMessage({ id: result.msg }));
        }
      }
    },
    *savePolling({ payload }, { select, call }) {
      const { meetingId } = payload;
      const { zoomPoll } = yield select((state) => state.subscribe);
      const poll = _.cloneDeep(zoomPoll);
      if (meetingId > 0 && poll && poll.questions) {
        const pollData = { ...{ meetingId }, ...poll };
        if (pollData.questions) {
          pollData.questions = pollData.questions.map((quest) => {
            const newQuest = _.cloneDeep(quest);
            delete newQuest.time;
            const quesAns = quest.answers.map((ans) => ans.name);
            return { ...newQuest, answers: quesAns };
          });
        }
        const { code, msg } = yield call(subscribeService.savePollings, { pollData });
        if (code === 1) {
          Toast.fail(formatMessage({ id: msg }));
        }
        return { code };
      }
      return { code: 0 };
    },
    *updateRoomPayer({ payload }, { select, put }) {
      const { roomId, payer, payerName } = payload;
      const { roomPayerMap } = yield select((state) => state.subscribe);
      const newRoomPayerMap = _.cloneDeep(roomPayerMap);
      if (Object.keys(roomPayerMap).includes(String(roomId))) {
        newRoomPayerMap[roomId].roomPayer = payer;
        newRoomPayerMap[roomId].payerName = payerName;
      } else {
        newRoomPayerMap[roomId] = {
          roomId: Number(roomId),
          roomPayer: payer,
          payerName: payerName,
          svcPayer: '',
          svcPayerName: '',
        };
      }
      yield put({ type: 'update', payload: { roomPayerMap: newRoomPayerMap } });
    },
    *sendMeetingNotice({ meetingId }, { call }) {
      const { code, msg } = yield call(subscribeService.sendMeetingNotice, { meetingId });
      if (code === RES_STATUS.success) {
        Toast.success(formatMessage({ id: 'subscribe.send.success' }));
      } else {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *searchRooms({ subscribe = {} }, { select, call, put }) {
      const newSubscribe = _.cloneDeep(subscribe);
      const { searchParam = {}, isFilter, date } = yield select((state) => state.subscribe);
      const { defaultZoneId } = yield select((state) => state.zones);
      const {
        startStr = '',
        endStr = '',
        attendance = -1,
        deviceSelected = [],
        startTimeStr = '',
        endTimeStr = '',
      } = searchParam;
      const zoneIds = defaultZoneId || -1;
      const devices = deviceSelected.join(';');
      const chooseDateStr = subscribe.date || date || moment().format('YYYY-MM-DD');
      const formatStart = moment(startTimeStr).format('HH:mm');
      const formatEnd = moment(endTimeStr).format('HH:mm');
      let dateS = `${moment(chooseDateStr).format(dateFormat)} ${formatStart}`;
      let dateE = `${moment(chooseDateStr).format(dateFormat)} ${formatEnd}`;
      // 若切换到未来日期 选择了早于当前时间的时间段，单独做处理(还需要更新searchParam)
      const isToday = moment(chooseDateStr).startOf('day').isSame(moment().startOf('day')); // 今天
      if (isToday && startTimeStr && endTimeStr) {
        const fbS = moment(filterBeginTime).format('HH:mm');
        if (formatStart < fbS && formatEnd < fbS) {
          dateS = '';
          dateE = '';
        }
        if (formatStart < fbS && formatEnd > fbS) {
          dateS = `${moment(chooseDateStr).format(dateFormat)} ${fbS}`;
        }
      }
      const newSearchParam = {
        ...searchParam,
        startTimeStr: dateS ? moment(dateS).valueOf() : '',
        endTimeStr: dateE ? moment(dateE).valueOf() : '',
      };
      const params = {
        zoneIds,
        chooseDate: moment(chooseDateStr, 'YYYY-MM-DD').valueOf(),
        startTime: startStr,
        endTime: endStr,
        peopleNum: attendance || -1,
        deviceIds: devices,
        startTimeStr: moment(dateS).valueOf(),
        endTimeStr: moment(dateE).valueOf(),
        timeZone: sessionStorage.getItem('curTimeZone') || '',
      };
      if (
        (!startTimeStr && !endTimeStr) ||
        (isToday &&
          startTimeStr &&
          endTimeStr &&
          !newSearchParam.startTimeStr &&
          !newSearchParam.endTimeStr)
      ) {
        delete params.startTimeStr;
        delete params.endTimeStr;
      }
      const result = yield call(subscribeService.getRoomListValidInfo, params);
      newSubscribe.rooms = result.data;
      if (!result.data) {
        Toast.fail(formatMessage({ id: 'subscribe.default.room' }), 2, null, false);
        newSubscribe.rooms = [];
      } else {
        newSubscribe.rooms = result.data.filter((r) => {
          return (
            Number(r.status) !== 1 &&
            Number(r.capacity) !== MEETING_ROOM_TYPE.zoom &&
            Number(r.capacity) !== MEETING_ROOM_TYPE.WebEx
          );
        });
      }
      if (!subscribe.holdModal) {
        yield put({ type: 'showFilterModal', show: false });
      }
      newSubscribe.holdModal = false;
      newSubscribe.isFilter = isFilter;
      newSubscribe.searchParam = newSearchParam;
      newSubscribe.temStart = dateS ? moment(dateS).valueOf() : '';
      newSubscribe.temEnd = dateE ? moment(dateE).valueOf() : '';
      yield put({ type: 'update', payload: { ...newSubscribe } });
    },
    // eslint-disable-next-line no-empty-pattern
    *initState(__, { select, put }) {
      // 进入预约页面时，初始化date、today等数据，同时清空其他的预约详情数据，
      // 避免上次预约的残留state对本次造成影响
      const { date, month } = yield select((state) => state.subscribe);
      const now = moment();
      const nowObj = {
        today: now.format(DATE_FORMAT),
        date: date || now.format(DATE_FORMAT),
        month: month || now.month() + 1,
        showFilterModal: false,
      };
      yield put({
        type: 'update',
        payload: { ..._.cloneDeep(initialState), ...nowObj, externals: [] },
      });
    },
    *initForDing({ payload }, { select, call, put }) {
      // 进入预约页面时，初始化date、today等数据，同时清空其他的预约详情数据，
      const { email = '' } = payload;
      // 避免上次预约的残留state对本次造成影响
      const { date, month } = yield select((state) => state.subscribe);
      const now = moment();
      const nowObj = {
        today: now.format(DATE_FORMAT),
        date: date || now.format(DATE_FORMAT),
        month: month || now.month() + 1,
      };
      const roomInfo = yield call(subscribeService.findRoomByEmail, { email });
      let roomIds = '';
      let roomNames = '';
      let roomNamesEn = '';
      if (roomInfo.code === 0 && !_.isEmpty(roomInfo.data)) {
        roomIds = String(roomInfo.data.roomId);
        roomNames = roomInfo.data.name;
        roomNamesEn = roomInfo.data.nameEn;
      }
      yield put({
        type: 'update',
        payload: {
          ..._.cloneDeep(initialState),
          ...nowObj,
          immediate: false,
          roomIds,
          roomNames,
          roomNamesEn,
        },
      });
    },
    *dateChange({ date, month }, { put, select }) {
      const { isFlyBook } = yield select((state) => state.subscribe);
      if (Number(isFlyBook) === 1 && moment(date).isAfter(moment().add(30, 'd'))) {
        Modal.alert(
          formatMessage({ id: 'modal.notice' }),
          formatMessage({ id: 'subscribe.fail.feishu.days.limit' }),
          [{ text: formatMessage({ id: 'language.btn.ok' }) }],
        );
        return;
      }
      yield put({
        type: 'update',
        payload: { date, month, recurringNewDate: date },
      });

      yield put({ type: 'loadValidInfo', from: 'dateChange' });
    },
    *bookTypeChange({ bookType, notClearLoop=false }, { put, select }) {
      // booktype改变 循环数据清空
      const { date } = yield select((state) => state.subscribe);
      // 根据条件去判断是否清空循环规则
      if(!notClearLoop){
        yield put({
          type: 'update',
          payload: {
            recurringRule: 0,
            recurringRange: 1,
            recurringExrule: [`${moment(date).format('d') > 0 ? moment(date).format('d') : 7}`],
            mrecurringExrule: moment(date).format('D'),
            recurringFreq: 1,
            recurringTime: '', // 结束日期
            recurringEndType: 1, // 结束类型 0结束时间 1次数
          },
        });
      }
      yield put({
        type: 'update',
        payload: {
          bookType,
          tecentDisabled: bookType !== 4,
          isSpecialNet: [1, 2, 3, 5, 8].indexOf(Number(bookType)) > -1,
          type: 0,
          // booktype改变时清空原有数据
          hasZoom: 0,
          hasTencent: 0,
          hasFeiShu: 0,
          hasDingDing: 0,
          hasTeams: 0,
          hasHuiChang: 0,
          hasQuanShi: 0,
          hasPoly: 0,
        },
      });
      yield put({ type: 'loadValidInfo' });
    },
    // 单纯清空循环规则
    *clearLoop(_, { put, select }) {
      const { date } = yield select((state) => state.subscribe);
      yield put({
        type: 'update',
        payload: {
          recurringRule: 0,
          recurringRange: 1,
          recurringExrule: [`${moment(date).format('d') > 0 ? moment(date).format('d') : 7}`],
          mrecurringExrule: moment(date).format('D'),
          recurringFreq: 1,
          recurringTime: '', // 结束日期
          recurringEndType: 1, // 结束类型 0结束时间 1次数
        },
      });
    },
    *initLongStartTime(__, { put, select }) {
      const { signVisitorList } = yield select((state) => state.subscribe);
      const mins = 15 - (moment().minute() % 15);
      const start = moment().add(mins, 'minutes').seconds(0).milliseconds(0);
      const end = moment(start).add(1, 'days').add(15, 'minute');
      yield put({
        type: 'update',
        payload: {
          startTimeStamp: start,
          endTimeStamp: end,
          startTime: start,
          endTime: end,
        },
      });
      if(signVisitorList?.length ===0){
        yield put({
          type: 'update',
          payload: {
            visitorStartTime: start,
            visitorEndTime: end,
          },
        });
      }
    },
    *initHost(__, { put, call, select }) {
      // 如果主持人还未选择，则默认初始化为当前user
      const {
        creatorId,
        roomIds = '',
        rooms = [],
        bookType,
        roomPlan,
        // isFromMap,
      } = yield select((state) => state.subscribe);
      const { tenantId } = yield select((state) => state.user.currentUser);
      yield put({
        type: 'update',
        payload: { roomTenantId: tenantId },
      });
      yield put({
        type: 'zones/update',
        payload: {
          tenantId: Number(tenantId),
        },
      });
      if (!creatorId) {
        const { currentUser, roleCode = [] } = yield select((state) => state.user);
        const isHelper = roleCode.some((code) => code === ROLES_KEY.reserve_helper);
        const { userId, realName } = currentUser;
        const roomTencent = rooms.filter((r) => roomIds.split(';').includes(String(r.roomId)));
        let isDisable = false;
        let hasEntityRoom = false;
        if (roomTencent && roomTencent.length > 0) {
          isDisable = roomTencent[0].capacity !== 9;
          hasEntityRoom =
            roomTencent[0].capacity === 0 ||
            roomTencent[0].capacity === 3 ||
            roomTencent[0].capacity === 5;
        }
        if ((roomIds === '' && rooms && rooms.length === 0) || roomTencent.length === 0) {
          isDisable = true;
        }
        const roomObj = [];
        if (rooms && roomIds && rooms.length > 0) {
          const roomArray2 = roomIds.split(';');
          rooms.forEach((room) => {
            if (roomArray2.indexOf(String(room.roomId)) > -1) {
              let roomHostUser = [currentUser];
              let selUsers = [];
              let roomExternalUsers = [];
              roomPlan.forEach((item) => {
                if (item.roomId === room.roomId) {
                  roomHostUser = item.hostUser;
                }
              });
              roomPlan.forEach((item) => {
                if (item.roomId === room.roomId) {
                  selUsers = item.selectUsers;
                }
              });
              roomPlan.forEach((item) => {
                if (item.roomId === room.roomId) {
                  roomExternalUsers = item.externalUsers;
                }
              });
              const roomParam = {
                roomId: room.roomId,
                // roomName
                hostUser: roomHostUser,
                selectUsers: selUsers,
                externalUsers: roomExternalUsers,
              };
              roomObj.push(roomParam);
            }
          });
        } else if (rooms && rooms.length === 0 && roomIds) {
          roomObj.push({
            roomId: roomIds,
            hostUser: [currentUser],
            selectUsers: [],
            externalUsers: [],
          });
        }
        yield put({
          type: 'update',
          payload: {
            creatorId: userId,
            creatorName: realName,
            hostId: userId,
            hostName: realName,
            hostUsers: [currentUser],
            creatorUser: currentUser,
            tecentDisabled: isDisable,
            hasEntityRoom,
            bookType: isDisable ? bookType : 4,
            roomPlan: roomObj,
            isHelper,
            isSpecialNet: isDisable ? [1, 2, 3, 5, 8].indexOf(Number(bookType)) > -1 : false,
            // isSplit: isHelper && !(isDisable ? [1, 2, 3, 5, 8].indexOf(Number(bookType)) > -1 : false),
          },
        });
        if (roomIds !== '' && rooms && rooms.length === 0) {
          // const { tenantId } = yield select((state) => state.user.currentUser);
          const { data } = yield call(zoneService.getAllRooms, { tenantId });
          if (data) {
            const roomTencent2 = data.filter((r) => roomIds.split(';').includes(String(r.roomId)));
            let isDisable1 = false;
            let hasEntityRoom1 = true;
            if (roomTencent && roomTencent2.length > 0) {
              isDisable1 = roomTencent2[0].capacity !== 9;
              hasEntityRoom1 =
                roomTencent2[0].capacity === 0 ||
                roomTencent2[0].capacity === 3 ||
                roomTencent2[0].capacity === 5;
            }
            if (roomTencent2.length === 0) {
              isDisable1 = true;
            }
            yield put({
              type: 'update',
              payload: {
                tecentDisabled: isDisable1,
                hasEntityRoom: hasEntityRoom1,
                bookType: isDisable1 ? bookType : 4,
                isSpecialNet: isDisable1 ? [1, 2, 3, 5, 8].indexOf(Number(bookType)) > -1 : false,
                // isSplit: isHelper && !(isDisable1 ? [1, 2, 3, 5, 8].indexOf(Number(bookType)) > -1 : false),
              },
            });
            yield put({
              type: 'zone/update',
              payload: {
                allRooms: data,
              },
            });
          }
        }
      }
    },
    *initForEdit(
      { meetingInfo, roomInfo = [], roomPlan, meetingAttachments = [],smcMeetingInfo },
      { put, call, select },
    ) {
      const {
        meetingId,
        type,
        roomIds,
        roomNames,
        roomNamesEn,
        name: subject,
        startTime,
        endTime,
        creatorId,
        creatorName,
        hostId,
        hostName,
        remark,
        needToSign,
        selectedMembers,
        selectedExternal,
        status,
        recurringRule,
        recurringExrule,
        recurringRange,
        recurringFreq,
        recurringTime,
        recurringEndType,
        recurringStartDate,
        autoReconnect,
        cmmaStartAuto,
        bookType,
        isSplit,
        zoomPwd,
        zoomWait,
        zoomMute,
        zoomRecordLocale,
        zoomAllowInBeforeHost,
        zoomType,
        meetingScale,
        startNow,
        duration,
        vstTypeId,
        vstLocationId,
        mainRoomId,
        hostUsers,
        sendSms,
        sendEmail,
        outSendSms,
        outSendEmail,
        timeZone,
        isLongState,
        hasZoom,
        hasTencent,
        hasFeiShu,
        hasDingDing,
        hasTeams,
        hasHuiChang,
        hasQuanShi,
        hasPoly,
        isSecretMeeting,
        isAllUser,
        recurringId,
        visitorStartTime, // 访客到访时间
        visitorEndTime,
        joinPwd,
        ifPoly = null,
        prdExtraToMap = {},
      } = meetingInfo;
      let isTemporaryVisitor = false;
      if (prdExtraToMap && prdExtraToMap.hasOwnProperty('visitorSelfFlag')) {
        // 'visitorSelfFlag' 存在于 prdExtraToMap 中
        isTemporaryVisitor = Number(prdExtraToMap.visitorSelfFlag) === 2;
      }
      yield put({
        type: 'update',
        payload: {
          tempBookType: bookType,
        },
      });
      const { isRestartMeeting, editCycle } = yield select((state) => state.subscribe);
      const { checkIn = {}, base = {}, smc } = yield select((state) => state.sysModule);
      const { defaultAfterStartSignTime, defaultBeforeStartSignTime } = checkIn;
      const { enableUserScheduler = '1' } = base;
      let deviceContent = '';
      let newMeetingDevice = [];
      // 2和3表示会议结束或取消后重新开启
      const isEdit = status === MEETING_STATUS.pending;
      let date = '';
      let recurringNewDate = '';
      date = startTime ? moment(startTime).format(DATE_FORMAT) : moment().format(DATE_FORMAT);
      // const isLoop = Boolean(type > 0 && Number(meetingId) > 0) || (type > 0 && isRestartMeeting);
      // if (isLoop) {
      //   recurringNewDate = recurringStartDate || moment().format(DATE_FORMAT);
      // }
      // 目前普通会议也可以编辑成循环会议所以需要把这个放开
      recurringNewDate = recurringStartDate || moment().format(DATE_FORMAT);
      let initDate = null;
      if (timeZone) {
        initDate = isEdit
          ? moment.tz(date, timeZone).format(DATE_FORMAT)
          : moment.tz(timeZone).format(DATE_FORMAT);
        recurringNewDate = moment.tz(recurringNewDate, timeZone).format(DATE_FORMAT);
      } else {
        initDate = isEdit ? date : moment().format(DATE_FORMAT);
      }

      let zoomAdvance = 1;
      if (isEdit) {
        zoomAdvance = zoomWait === 1 || zoomMute === 1 || zoomRecordLocale === 1 || zoomAllowInBeforeHost === 1 ? 1 : 0;
      }
      let newStartTime = startTime;
      let newEndTime = endTime;
      if (startTime && endTime) {
        newStartTime = timeZone
          ? moment.tz(startTime, timeZone).format(TIME_FORMAT)
          : moment(startTime).format(TIME_FORMAT);
        newEndTime = timeZone
          ? moment.tz(endTime, timeZone).format(TIME_FORMAT)
          : moment(endTime).format(TIME_FORMAT);
      }

      if (meetingInfo.isLongState === 1 && !isEdit) {
        // 长期会议再次发起时
        newStartTime = moment(moment().format('YYYY-MM-DD ') + newStartTime);
        newEndTime = moment(moment().format('YYYY-MM-DD ') + newEndTime);
      }
      // 编辑整个周期会议，时间默认给初始的循环时间
      if(meetingInfo?.type > 0 && Number(editCycle) === 2){
        initDate = meetingInfo?.recurringStartDate
      }
      const { roleCode = [], currentUser } = yield select((state) => state.user);
      const isHelper = roleCode.some((code) => code === ROLES_KEY.reserve_helper);
      const { userId, realName } = currentUser;
      const newRoomPlan = roomPlan
        ? roomPlan.map((roomPlanitem, i) => {
            if (isEdit) {
              return Object.assign(roomPlanitem, {
                hostUser: roomPlanitem.holderList || [],
                selectUsers: roomPlanitem.innerList || [],
                externalUsers: roomPlanitem.outerList?.map((item)=>({...item,canntOperate:isTemporaryVisitor})) || [],
              });
            } else {
              return Object.assign(roomPlanitem, {
                hostUser: isHelper ? roomPlanitem.holderList || [] : i === 0 ? [currentUser] : [],
                selectUsers: roomPlanitem.innerList || [],
                externalUsers: roomPlanitem.outerList?.map((item)=>({...item,canntOperate:isTemporaryVisitor})) || [],
              });
            }
          })
        : null;
      let svcPrice = 0;
      if (isEdit) {
        const result = yield call(svcService.getMeetingOther, meetingId);
        if (result.code === 0) {
          const { data = {} } = result;
          const { meetingSvc = [], meetingDevice = [], meetingSvcRooms = [] } = data;
          let total = 0;
          meetingSvc.forEach((item) => {
            const svcTotal = item.svcCost || 0;
            if (
              item?.bpmState === BPMSTATE.APPROVALING ||
              item?.bpmState === BPMSTATE.TOBESERVED ||
              item?.bpmState === BPMSTATE.ENDED
            ) {
              total += parseFloat(svcTotal);
            }
          });
          if (total > 0) {
            svcPrice = total;
          }

          // 赋值
          const meetingSvcRoomsId = meetingSvcRooms.map((item) => item.roomId);
          newMeetingDevice = meetingDevice.filter((item) =>
            meetingSvcRoomsId.includes(item.supplementInfo.roomId),
          );
          if (newMeetingDevice.length > 0) {
            deviceContent = formatMessage({ id: 'meeting.detail.look' });
          }
        } else {
          Toast.fail(formatMessage({ id: result.msg }));
        }
        yield put({
          type: 'update',
          payload: {
            ifPoly,
            deviceContent,
            meetingDeviceForBooking: newMeetingDevice,
            isVisitorEdit: meetingInfo?.isVisitorEdit,
            visitorStartTime: visitorStartTime ? moment(visitorStartTime) : moment(startTime), // 访客到访时间
            visitorEndTime: visitorEndTime ? moment(visitorEndTime) : moment(endTime),
            meetingAttachments,
            isTemporaryVisitor
          },
        });
      } else {
        yield put({
          type: 'update',
          payload: {
            visitorStartTime: moment(
              meetingInfo.isLongState === 1
                ? newStartTime
                : moment(moment().format('YYYY-MM-DD ') + newStartTime),
            ), // 访客到访时间
            visitorEndTime: moment(
              meetingInfo.isLongState === 1
                ? newEndTime
                : moment(moment().format('YYYY-MM-DD ') + newEndTime),
            ),
          },
        });
      }
      const selectVideo = [];
      if (hasZoom) {
        selectVideo.push('Zoom');
      }
      if (hasFeiShu) {
        selectVideo.push('Feishu');
      }
      if (hasTencent) {
        selectVideo.push('Tencent');
      }
      if (hasDingDing) {
        selectVideo.push('Dingding');
      }
      if (hasTeams) {
        selectVideo.push('Teams');
      }
      if (hasHuiChang) {
        selectVideo.push('Changyun');
      }
      if (hasQuanShi) {
        selectVideo.push('FullTime');
      }
      if (hasPoly) {
        selectVideo.push('Poly');
      }
      yield put({
        type: 'update',
        payload: {
          meetingId: isEdit ? meetingId : '',
          type,
          ifPoly,
          roomIds,
          roomNames,
          roomNamesEn,
          bookType,
          webexMeeting:
            Number(bookType) === 2 ||
            Number(bookType) === 3 ||
            Number(bookType) === 4 ||
            Number(bookType) === 5 ||
            Number(bookType) === 8 ||
            Number(bookType) === 9,
          date: initDate,
          recurringNewDate,
          subject,
          startTimeStamp: startTime,
          endTimeStamp: endTime,
          startTime: newStartTime,
          endTime: newEndTime,
          creatorId,
          creatorName,
          hostId: isEdit ? hostId : isHelper ? hostId : userId,
          hostName: isEdit ? hostName : isHelper ? hostName : realName,
          hostUsers: isEdit ? hostUsers : isHelper ? hostUsers : [currentUser],
          remark,
          meetingAttachments,
          editMeetingInfo: meetingInfo,
          needToSign,
          advSignTime: Number(defaultBeforeStartSignTime),
          afterSignTime: Number(defaultAfterStartSignTime),
          recurringRule: Number(type) || Number(recurringRule),
          recurringStartDate: moment(recurringStartDate),
          recurringExrule:
            Number(type) === 2
              ? recurringExrule.split(';')
              : [`${moment(initDate).format('d') > 0 ? moment(initDate).format('d') : 7}`],
          mrecurringExrule: Number(type) === 3 ? recurringExrule : moment(initDate).format('D'),
          recurringRange: Number(recurringRange),
          recurringFreq: Number(recurringFreq) || 1,
          recurringTime: Number(recurringEndType) === 1 ? '' : recurringTime,
          recurringEndType,
          // 再次发起会议，暂时不携带会议服务及移动设备信息
          svcPrice: isEdit ? svcPrice : 0,
          autoReconnect: Number(autoReconnect) === 1,
          cmmaStartAuto: Number(cmmaStartAuto) === 1,
          deviceContent,
          selectedMembers,
          selectedExternal:selectedExternal?.map((item)=>({...item,canntOperate:isTemporaryVisitor})),
          zoomPwd: isEdit ? zoomPwd : '',
          zoomAdvance,
          zoomWait: isEdit ? zoomWait : 0,
          zoomMute: isEdit ? zoomMute : 0,
          zoomRecordLocale: isEdit ? zoomRecordLocale : 0,
          zoomAllowInBeforeHost: isEdit ? zoomAllowInBeforeHost : 0,
          zoomType: isEdit ? zoomType : 0,
          meetingScale: isEdit ? meetingScale : 0,
          startNow: startNow === 0,
          duration,
          roomInfo,
          vstTypeId,
          vstLocationId,
          mainZoom: mainRoomId,
          sendSms,
          sendEmail,
          outSendSms,
          outSendEmail,
          timeZone,
          isLongState,
          hasZoom: hasZoom || 0,
          hasTencent: hasTencent || 0,
          hasFeiShu: hasFeiShu || 0,
          hasDingDing: hasDingDing || 0,
          hasTeams: hasTeams || 0,
          hasHuiChang: hasHuiChang || 0,
          hasQuanShi: hasQuanShi || 0,
          hasPoly: hasPoly || 0,
          selectVideo,
          isSecretMeeting,
          isAllUser: base.enableMeetingAllUser === '0'?0:isAllUser,
          roomPlan:
            roomPlan && roomPlan.length === 0
              ? [
                  {
                    roomId: parseInt(roomIds, 10),
                    hostUser: [currentUser],
                    selectUsers: [],
                    externalUsers: [],
                  },
                ]
              : newRoomPlan,
          isFlyBook: Number(bookType) === MEETING_BOOK_TYPE.FEISHU ? 1 : 0,
          isHelper,
          isSplit,
          isSpecialNet: [1, 2, 3, 5, 8].indexOf(Number(bookType)) > -1,
          recurringId: Number(type) > -1 ? recurringId : null,
          joinPwd,
          isTemporaryVisitor,
        },
      });
      if(meetingInfo.recurringRule === '5'){
        yield put({
          type: 'update',
          payload: {
            recurringRule: '3',
            // '0|x'代表是相对月的最后一周  'x|x'代表的是相对月的第几周周几
            mExruleType: meetingInfo?.recurringExrule?.split('/')[0] === '0'?'3':'2',
            // 如果是相对月类型，则绝对月的值给个默认天数
            mrecurringExrule: moment().format('D'),
            mrecurringExrule2: meetingInfo?.recurringExrule,
            weekCount: meetingInfo?.recurringExrule?.split('/')[0] || 5,
            week: meetingInfo?.recurringExrule?.split('/')[1]
        }})
      }
      if(meetingInfo.recurringRule === '6'){
        yield put({
          type: 'update',
          payload: {
            recurringRule: '7',
            // 'x|0|x'代表是相对年的几月最后一周  'x|x|x'代表的是相对年的几月第几周周几
            yExruleType: meetingInfo?.recurringExrule?.split('/')[1] === '0'?'3':'2',
            yrecurringExrule: meetingInfo?.recurringExrule,
            weekCount: meetingInfo?.recurringExrule?.split('/')[1] || 5,
            week: meetingInfo?.recurringExrule?.split('/')[2]
        }})
      }
      // 华为视频主持人参会密码
      if(smcMeetingInfo && smcMeetingInfo?.accessCode){
        yield put({
          type: 'update',
          payload: {
            hostJoinPwd: smcMeetingInfo?.chairmanPassword || ''
          }
          }
        )
      }
      // 编辑会议时候  获取主持人 参会人 忙闲状态
      if (isEdit && enableUserScheduler === '1') {
        yield put({ type: 'attendee/getHostStatus' });
        if (selectedMembers.length > 0) {
          yield put({ type: 'attendee/getUserStatus', isEdit });
        }
      }
      yield put({ type: 'loadValidInfo', from: 'editMeetingPage' });
    },
    *initPollingInfo({ meetingId }, { put, call }) {
      const { code, data } = yield call(subscribeService.getZoomPolling, { meetingId });
      if (code === 0) {
        if (data.meetingId > 0 && data.questions) {
          const zoomPoll = { meetingId: data.meetingId, title: data.title };
          const questions = [];
          data.questions.forEach((ques, j) => {
            const { questionName, questionType } = ques;
            const answers = [];
            ques.answers.forEach((ans, i) => {
              answers.push({ id: String(Date.now()) + i, name: ans });
            });
            questions.push({ answers, questionName, questionType, time: String(Date.now()) + j });
          });
          zoomPoll.questions = questions;
          yield put({ type: 'update', payload: { zoomPoll } });
        } else {
          yield put({ type: 'update', payload: { zoomPoll: {} } });
        }
      }
    },
    *pollingMeeting({ payload }, { put, call }) {
      const { meetingId, meetingStatus } = payload;
      const { code, data } = yield call(subscribeService.getZoomPolling, { meetingId });
      if (code === 0) {
        if (data && data.meetingId > 0 && data.questions) {
          if (meetingStatus === 0) {
            const zoomPoll = { meetingId: data.meetingId, title: data.title };
            const questions = [];
            data.questions.forEach((ques, j) => {
              const { questionName, questionType } = ques;
              const answers = [];
              ques.answers.forEach((ans, i) => {
                answers.push({ id: String(Date.now()) + i, name: ans });
              });
              questions.push({ answers, questionName, questionType, time: String(Date.now()) + j });
            });
            zoomPoll.questions = questions;
            yield put({ type: 'update', payload: { zoomPoll } });
            history.push({
              pathname: MENU_PATH.polling,
              query: {
                meetingId: Number(meetingId),
                type: 1,
              },
            });
          } else {
            history.push({
              pathname: MENU_PATH.zoomPolling,
              query: {
                meetingId: Number(meetingId),
              },
            });
          }
        } else {
          yield put({ type: 'update', payload: { zoomPoll: {} } });
          history.push({
            pathname: MENU_PATH.pollingTitle,
            query: {
              meetingId: Number(meetingId),
              type: 1,
            },
          });
        }
      }
    },
    *showSearchModal(__, { put }) {
      yield put({ type: 'update', payload: { showSearchModal: true } });
    },
    *showFilterModal({ show }, { put, select }) {
      const { searchParam = {} } = yield select((state) => state.subscribe);
      const { attendance = 1, deviceSelected = [], startTimeStr, endTimeStr } = searchParam;
      if (show) {
        yield put({
          type: 'update',
          payload: {
            attendanceCache: _.cloneDeep(attendance),
            deviceSelectedCache: _.cloneDeep(deviceSelected),
            startTimeStrCache: _.cloneDeep(startTimeStr),
            endTimeStrCache: _.cloneDeep(endTimeStr),
          },
        });
      }
      yield put({ type: 'update', payload: { showFilterModal: show } });
    },
    *getDevicesByType(__, { call, put }) {
      const { data, code } = yield call(subscribeService.getDevices);
      yield put({ type: 'update', payload: { devices: data } });
      return { data, code };
    },
    *getServiceCost({ meetingId }, { call, put, select }) {
      // 获取数据-服务数据
      const result = yield call(svcService.getMeetingOther, meetingId);
      const { serviceDataArray } = yield select((state) => state.manage);
      const { isFromSvcFreeDetail } = yield select((state) => state.svcFeeDetail);
      const { roomIds } = yield select((state) => state.subscribe);
      if (isFromSvcFreeDetail) {
        return;
      }
      if (result.code === 0) {
        const { data = {} } = result;
        const { meetingSvc = [], meetingDevice = [], meetingSvcRooms = [] } = data;
        // let unConfirm = 0;
        let total = 0;
        const meetingSvcRoomsId = meetingSvcRooms.map((item) => item.roomId);
        const newMeetingSvc = meetingSvc.filter((item) => meetingSvcRoomsId.includes(item.roomId));
        let roomIdArray = [];
        if (roomIds) {
          roomIdArray = roomIds.split(';');
        }
        newMeetingSvc.forEach((item) => {
          const svcTotal = item.svcCost || 0;
          if (
            roomIdArray.includes(String(item.roomId)) &&
            (item?.bpmState === BPMSTATE.APPROVALING ||
              item?.bpmState === BPMSTATE.TOBESERVED ||
              item?.bpmState === BPMSTATE.ENDED)
          ) {
            total += parseFloat(svcTotal);
          }
        });
        // 加上 预约 编辑入口的total
        serviceDataArray.forEach((item) => {
          total += item.svcCost;
        });

        yield put({
          type: 'update',
          payload: {
            svcPrice: total,
            meetingSvc: newMeetingSvc,
          },
        });
      } else {
        yield put({
          type: 'update',
          payload: {
            svcPrice: 0,
          },
        });
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *initForSeat({ meetingId }, { put, select, take }) {
      yield put({ type: 'meetingDetail/reload', meetingId });
      yield take('meetingDetail/reload/@@end');

      const { detail = {} } = yield select((state) => state.meetingDetail);
      const meetingInfo = {
        ...detail.meetingInfo,
        selectedMembers: detail.innerUser,
        selectedExternal: detail.outerUser,
        hostUsers: detail.hostUser,
        type: MEETING_TYPE.normal,
      };
      yield put({
        type: 'update',
        payload: {
          onlyTextEdit: 0,
          cycleOneEdit: detail.meetingInfo.type > 0,
          ifPoly: detail.meetingInfo?.ifPoly,
          roomPlan: detail.roomPlan
            ? detail.roomPlan.map((roomPlanitem) => {
                return Object.assign(roomPlanitem, {
                  hostUser: roomPlanitem.holderList || [],
                  selectUsers: roomPlanitem.innerList || [],
                  externalUsers: roomPlanitem.outerList || [],
                });
              })
            : [],
        },
      });

      const {
        type,
        roomIds,
        name: subject,
        creatorId,
        creatorName,
        hostId,
        hostName,
        selectedMembers,
        selectedExternal,
        status,
        startNow,
        duration,
        hostUsers,
      } = meetingInfo;

      let roomInfo = [];
      if (detail.roomInfo && detail.roomInfo.length > 0) {
        roomInfo = detail.roomInfo.map((item) => {
          return {
            roomId: item.roomId,
            roomName: item.name,
            roomNameEn: item.nameEn,
            capacity: item.capacity,
            signType: item.signType,
            needAudit: item.needAudit,
            pathName: item.pathName,
          };
        });
      }

      const selectRoomIdForSeat = roomInfo && roomInfo.length > 0 ? String(roomInfo[0].roomId) : '';

      // 2和3表示会议结束或取消后重新开启
      const isEdit = status === MEETING_STATUS.pending || status === MEETING_STATUS.reject;
      yield put({
        type: 'update',
        payload: {
          meetingId: isEdit ? meetingId : '',
          type,
          roomIds,
          subject,
          creatorId,
          creatorName,
          hostId,
          hostName,
          editMeetingInfo: meetingInfo,
          selectedMembers,
          selectedExternal,
          startNow: startNow === 0,
          duration,
          roomInfo,
          hostUsers,
          selectRoomIdForSeat,
        },
      });
      return {
        meetingId: isEdit ? meetingId : '',
        type,
        roomIds,
        subject,
        creatorId,
        creatorName,
        hostId,
        hostName,
        editMeetingInfo: meetingInfo,
        selectedMembers,
        selectedExternal,
        startNow: startNow === 0,
        duration,
        roomInfo: detail.roomInfo || [],
        hostUsers,
        selectRoomIdForSeat,
      };
    },
    *supplementMeetingInfo({ meetingId, editSeries }, { put, select, take }) {
      yield put({ type: 'meetingDetail/reload', meetingId });
      yield put({ type: 'getMeetingAgenda', meetingId });
      yield take('meetingDetail/reload/@@end');
      yield put({ type: 'attendee/loadAllUsers' });
      yield take('attendee/loadAllUsers/@@end');

      const { detail = {} } = yield select((state) => state.meetingDetail);
      const { isRestartMeeting, roomIds } = yield select((state) => state.subscribe);
      const roomPayerMap = {};
      // let svcCostTotal = 0;
      detail.meetingRooms.forEach((mr) => {
        const { roomId, roomPayer, roomPayers, svcPayer = '', svcUsers = '' } = mr;
        // svcCostTotal += parseFloat(svcCost);
        const payerNames = [];
        roomPayers.forEach((rp) => {
          payerNames.push(rp.realName);
        });
        const svcPayerName =
          svcUsers?.map((item) => (getLocale() === ENGLISH ? item.realNameEn : item.realName)) ||
          [];
        roomPayerMap[roomId] = {
          roomPayer,
          payerName: payerNames.join(';'),
          svcPayer,
          svcPayerName: svcPayerName.join(';'),
        };
      });
      const meetingInfo = {
        ...detail.meetingInfo,
        webexMeeting: Number(detail.meetingInfo.bookType) === MEETING_BOOK_TYPE.WEBEX,
        deviceList: detail.deviceList,
        isVisitorEdit: detail?.isVisitorEdit,
        selectedMembers: detail.innerUser,
        selectedExternal: detail.outerUser,
        hostUsers: detail.hostUser,
        type: editSeries ? detail.meetingInfo.type : MEETING_TYPE.normal,
      };

      const compareKey = buildCompareKey({
        editSeries,
        meetingInfo,
        innerUser: detail.innerUser,
        outerUser: detail.outerUser,
      });
      // 再次发起 如果时间已经超过当前时间 则清空
      const startTimeByDay = moment(
        moment(meetingInfo.startTime).format('HH:mm'),
        'HH:mm',
      ).valueOf();
      if (isRestartMeeting && startTimeByDay < moment().valueOf()) {
        meetingInfo.startTime = '';
        meetingInfo.endTime = '';
      }
      yield put({
        type: 'initForEdit',
        meetingInfo,
        meetingUser: detail.meetingUser,
        roomPlan: detail.roomPlan,
        meetingAttachments: detail?.meetingAttachments,
        smcMeetingInfo: detail?.smcMeetingInfo,
      });
      const entityRoom = detail.meetingRooms.filter(
        (mr1) => mr1.room.capacity === 0 || mr1.room.capacity === 3 || mr1.room.capacity === 5,
      );
      let hasEntityRoom = false;
      if (entityRoom && entityRoom.length > 0) {
        hasEntityRoom = true;
      }
      yield put({
        type: 'update',
        payload: {
          roomPayerMap,
          editSeries: !!editSeries,
          compareKey,
          onlyTextEdit: 0,
          cycleOneEdit: detail.meetingInfo.type > 0 && !editSeries,
          ifPoly: detail.meetingInfo?.ifPoly,
          tecentDisabled: Number(detail.meetingInfo.bookType) !== 4,
          hasEntityRoom,
          roomIds: detail.roomInfo && detail.roomInfo?.length > 0
              ? detail.roomInfo
                  .filter((item) => Number(item.bizUsable) === 1)
                  .map((item) => item.roomId)
                  .join(';')
              : roomIds,
        },
      });
    },
    *initForVideo(__, { put }) {
      yield put({
        type: 'update',
        payload: {
          webexMeeting: false,
          onlyVideo: true,
          bookType: 1,
          isSpecialNet: true,
        },
      });
    },
    *initForWebex(__, { put, call, select }) {
      const { bookType, isInit, meetingId } = yield select((state) => state.subscribe);
      // const { data, code } = yield call(sysModuleService.fetch);
      const data = yield select((state) => state.sysModule);
      // if (code === 0) {
      if (Object.keys(data).length !== 0) {
        const { zoom, webex, tencent, teams, flybook, wechatMeeting } = data;
        let newBookType = 0;
        if ([1, 2, 3, 4, 5, 8, 9].indexOf(Number(bookType)) > -1) {
          newBookType = bookType;
        } else {
          if(window.location.href.indexOf('webexmeeting') !== -1 && Number(meetingId) ===0){
            yield put({
              type: 'update',
              payload: {
                date: moment().format(DATE_FORMAT)
              },
            });
          }
          if (window.location.href.indexOf('webexmeeting') !== -1 && !isInit) {
            yield put({
              type: 'update',
              payload: {
                isInit: true,
              },
            });
            // 如果 嵌入飞书app 会议 缺省值为飞书会议
            const userAgent = window.navigator.userAgent.toLowerCase();
            if ((flybook && userAgent.includes('feishu')) || userAgent.includes('larklocale')) {
              newBookType = 8;
            }
            if (wechatMeeting) {
              newBookType = 9;
            }
            if (tencent) {
              newBookType = 4;
            }
            if (webex) {
              newBookType = 3;
            }
            if (zoom) {
              newBookType = 2;
            }
            if (teams) {
              newBookType = 5;
            }
          }
        }
        yield put({
          type: 'update',
          payload: {
            initWebexFlg: true,
            webexMeeting: true,
            bookType: newBookType,
            isSpecialNet: [1, 2, 3, 5, 8].indexOf(Number(newBookType)) > -1,
          },
        });
        return newBookType;
      } 
      // else {
      //   yield put({
      //     type: 'update',
      //     payload: {
      //       webexMeeting: true,
      //       bookType: 2,
      //       isSpecialNet: true,
      //     },
      //   });
      // }
      // return 2;
    },
    *meetingType({ mt }, { select, put }) {
      const {
        roomIds = '',
        roomNames = '',
        roomNamesEn,
      } = yield select((state) => state.subscribe);
      const { allRooms = [] } = yield select((state) => state.zones);
      let newIds = roomIds;
      let newNames = roomNames;
      let newNamesEn = roomNamesEn;
      if (mt === 1) {
        // 会议类型从普通会议变为视频会议，需要过滤掉已选择的普通会议室
        const idLst = [];
        const nameLst = [];
        const nameEnLst = [];
        if (roomIds) {
          roomIds.split(';').forEach((rid) => {
            if (
              allRooms &&
              allRooms.some((r) => {
                if (
                  String(r.roomId) === String(rid) &&
                  String(r.capacity) === ROOM_TYPES.videoRoom
                ) {
                  nameLst.push(r.name);
                  nameEnLst.push(r.nameEn);
                  return true;
                }
                return false;
              })
            ) {
              idLst.push(rid);
            }
          });
        }
        if (idLst.length <= 0) {
          // 如果选择的全部是普通会议室，不允许切换
          Toast.fail(formatMessage({ id: 'subscribe.meetingType.warning' }), 2);
          return;
        }
        newIds = idLst.join(';');
        newNames = nameLst.join(';');
        newNamesEn = nameEnLst.join(';');
      } else {
        yield put({ type: 'zones/load' });
      }
      yield put({
        type: 'update',
        payload: { bookType: mt, roomIds: newIds, roomNames: newNames, roomNamesEn: newNamesEn },
      });
      yield put({ type: 'loadValidInfo' });
    },
    // eslint-disable-next-line no-empty-pattern
    *recordInitialState(__, { select }) {
      const subscribe = yield select((state) => state.subscribe);
      initialState = _.cloneDeep(subscribe);
    },
    *setSmsEmailType(__, { select, put, call }) {
      const { noticeSet } = yield select((state) => state.subscribe);
      const { sysModule } = yield select((state) => state);
      const { sms, email } = sysModule || {};
      if (noticeSet === 0) {
        let sendSms = 0;
        let sendEmail = 0;
        let outSendSms = 0;
        let outSendEmail = 0;
        if (_.isEmpty(sysModule)) {
          const { data, code } = yield call(sysModuleService.fetch);
          if (code === 0) {
            const { sms: smsConfig, email: emailConfig } = data;
            sendSms =
              smsConfig &&
              Number(smsConfig.smsGlobalNoticeSwitch) === 1 &&
              Number(smsConfig.smsUserDefaultSwitch) === 1
                ? 1
                : 0;
            sendEmail =
              emailConfig &&
              Number(emailConfig.emailGlobalNoticeSwitch) === 1 &&
              Number(emailConfig.emailUserDefaultSwitch) === 1
                ? 1
                : 0;
            outSendSms =
              smsConfig &&
              Number(smsConfig.smsOutUserGlobalNoticeSwitch) === 1 &&
              Number(smsConfig.smsOutUserDefaultSwitch) === 1
                ? 1
                : 0;
            outSendEmail =
              emailConfig &&
              Number(emailConfig.emailOutUserGlobalNoticeSwitch) === 1 &&
              Number(emailConfig.emailOutUserDefaultSwitch) === 1
                ? 1
                : 0;
          }
        } else {
          sendSms =
            sms && Number(sms.smsGlobalNoticeSwitch) === 1 && Number(sms.smsUserDefaultSwitch) === 1
              ? 1
              : 0;
          sendEmail =
            email &&
            Number(email.emailGlobalNoticeSwitch) === 1 &&
            Number(email.emailUserDefaultSwitch) === 1
              ? 1
              : 0;
          outSendSms =
            sms &&
            Number(sms.smsOutUserGlobalNoticeSwitch) === 1 &&
            Number(sms.smsOutUserDefaultSwitch) === 1
              ? 1
              : 0;
          outSendEmail =
            email &&
            Number(email.emailOutUserGlobalNoticeSwitch) === 1 &&
            Number(email.emailOutUserDefaultSwitch) === 1
              ? 1
              : 0;
        }
        yield put({
          type: 'update',
          payload: {
            sendSms,
            sendEmail,
            outSendSms,
            outSendEmail,
            noticeSet: 1,
          },
        });
      }
    },
    *getReservePageInfo(__, { put, call, select }) {
      const { roomIds, meetingId } = yield select((state) => state.subscribe);
      const result = yield call(subscribeService.getMeetingstartTimeOption, {
        roomIds:roomIds || '' ,
        meetingId,
      });
      const { code, data } = yield call(subscribeService.getReservePageInfo);
      if (result.code === 0) {
        yield put({
          type: 'update',
          payload: { startTimeOption: result.data ? result.data : [] },
        });
      } else {
        yield put({
          type: 'update',
          payload: { startTimeOption: [] },
        });
      }
      if (code === RES_STATUS.success) {
        const {
          webinars,
          visitor,
          vstLocations = [],
          vstTypes = [],
          zoomScaleSet = [],
          specialGroup = {},
        } = data;
        const meetingScale = zoomScaleSet.length > 0 ? zoomScaleSet[0] : null;
        yield put({
          type: 'update',
          payload: {
            webinars,
            isVisitorMeeting: visitor,
            vstLocations,
            vstTypes,
            zoomScaleSet,
            meetingScale,
            specialGroup,
          },
        });
      }
    },
    *getSeatTemplateInfo(__, { put, call }) {
      const { code, data } = yield call(subscribeService.getSeatTemplateInfo);
      if (code === RES_STATUS.success) {
        yield put({
          type: 'update',
          payload: { seatTemplate: data },
        });
      }
    },
    *getMeetingAgenda({ meetingId }, { call, select, put }) {
      const result = yield call(detailService.getMeetingAgenda, { meetingId });
      const { messages } = yield select((state) => state.i18n);
      if (result && result.code === 0) {
        const { data = {} } = result;
        yield put({
          type: 'update',
          payload: {
            agendaData: data.agenda ? data.agenda : [],
          },
        });
      } else {
        Toast.fail(messages[result.msg]);
      }
    },
    *getRoomState(__, { put, call }) {
      const { code, data } = yield call(subscribeService.getRoomStates);
      if (code === RES_STATUS.success) {
        yield put({ type: 'update', payload: { roomStates: data } });
      } else {
        yield put({ type: 'update', payload: { roomStates: [] } });
      }
    },
    *getUserSeatInfo({ meetingId }, { put, call }) {
      const { code, data } = yield call(subscribeService.getUserSeat, { meetingId });
      if (code === RES_STATUS.success) {
        if (data) {
          const meetingUserSeat = [];
          Object.keys(data).forEach((key) => {
            const val = data[key];
            const keyArray = key.split('-');
            const valArray = val.split('-');
            const seatId = keyArray[1];
            const type = valArray[0];
            const userId = valArray[1];
            meetingUserSeat.push({ seatId, userId, type });
          });
          yield put({
            type: 'update',
            payload: { meetingUserSeat, meetingUserSeatTemp: _.cloneDeep(meetingUserSeat) },
          });
        }
      }
    },
    *findRoomDash({ payload }, { call }) {
      const { roomId } = payload;
      const result = yield call(subscribeService.getRoomDash, { roomId });
      return result || {};
    },
    *jumpToBooking({ payload }, { call }) {
      const { roomId } = payload;
      const result = yield call(subscribeService.getRoomDash, { roomId });
      return result;
    },
    *checkIsBlack({ payload }, { call }) {
      const { data } = yield call(subscribeService.blackApi, payload.userId);
      return data === true;
    },
    //解密空间资源Id
    *getdecryptRoomId({ payload }, { call }) {
      const { roomId } = payload;
      const { data } = yield call(subscribeService.getdecryptRoomId, { roomId });
      return data;
    },
    //获取当前userAgent进行判断
    *getUserAgent(_, { put }) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('larklocale') > -1) {
        yield put({
          type: 'update',
          payload: { existFlyBook: true },
        });
      }
    },
    *fetchCheckCycle({ payload }, { call }) {
      const { code, msg, data } = yield call(subscribeService.fetchCheckCycle, payload);
      if (code === 1) {
        Toast.fail(formatMessage({ id: msg }));
      }
      return { code, msg, data };
    },
    // CUSTOM : 会议附件下载
    *exportMeetingFile({ item }, { put, call, select }) {
      const { currentUser } = yield select((state) => state.user);
      const { userId } = currentUser;
      const { tenantId } = yield select((state) => state.user.currentUser);
      const result = yield call(subscribeService.exportMeetingFile, {
        meetingAttachment: item,
        userId,
        tenantId,
      });
      if (result.code !== 0) {
        Toast.fail(formatMessage({ id: msg }));
      }
    },
    *getVisitorList({ payload }, { put, call }) {
      const { visitId } = payload;
      const { code, data } = yield call(subscribeService.getMeetingVisitorList, { visitId });
      if (code === RES_STATUS.success) {
        if (data) {
          // 获取已经签到的访客信息
          const signVisitorList =  data?.filter(item=>{
            return item?.visitorStatus === '2'
          })
          yield put({
            type: 'update',
            payload: { signVisitorList},
          });
        }
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      dispatch({ type: 'recordInitialState' });
      return umiHistory.listen((params) => {
        const { pathname } = params;
        if (pathname === MENU_PATH.subscribe) {
          dispatch({ type: 'reset' });
          dispatch({ type: 'getRoomState' });
          dispatch({ type: 'attendee/reset' });
        } else if (matchPath(pathname, MENU_PATH.index) || matchPath(pathname, MENU_PATH.home)) {
          dispatch({ type: 'update', payload: { bookType: 0 } });
        } else if (pathname === MENU_PATH.onlyVideo) {
          dispatch({ type: 'initForVideo' });
          dispatch({ type: 'getReservePageInfo' });
          dispatch({ type: 'getSeatTemplateInfo' });
          dispatch({ type: 'setSmsEmailType' });
          // dispatch({ type: 'getSwitchConfig' });
          dispatch({ type: 'setSmsEmailType' });
        } else if (pathname === MENU_PATH.webExMeeting) {
          dispatch({ type: 'initForWebex' });
          dispatch({ type: 'setSmsEmailType' });
          dispatch({ type: 'getReservePageInfo' });
          dispatch({ type: 'getSeatTemplateInfo' });
          // dispatch({ type: 'getSwitchConfig' });
        }

        if (pathname === MENU_PATH.index || pathname === MENU_PATH.home) {
          dispatch({
            type: 'update',
            payload: { zoomAdvance: 0, zoomMute: 0, zoomRecordLocale: 0, zoomWait: 0 },
          });
        }
        if (matchPath(pathname, MENU_PATH.subscribeDetail)) {
          const { query } = history.location;
          const { meetingId, roomId } = query || {};
          if (!meetingId || meetingId === '0') {
            const qrScanRoomId = sessionStorage.getItem('QR_SCAN_ROOM_ID');
            if (qrScanRoomId) {
              sessionStorage.removeItem('QR_SCAN_ROOM_ID');
              dispatch({ type: 'update', payload: { roomIds: String(qrScanRoomId) } });
            }
            if (roomId) {
              dispatch({ type: 'update', payload: { roomIds: String(roomId) } });
              history.replace({
                search: '',
              });
            }
            dispatch({ type: 'setSmsEmailType' });
          } else {
            dispatch({ type: 'getUserSeatInfo', meetingId });
            dispatch({ type: 'update', payload: { meetingId } });
            dispatch({ type: 'getServiceCost', meetingId });
          }
          dispatch({ type: 'attendee/loadAllUsers' });
          // dispatch({ type: 'getSwitchConfig' });
          dispatch({ type: 'getReservePageInfo' });
          dispatch({ type: 'getSeatTemplateInfo' });
        } else {
          moment.tz.setDefault(sessionStorage.getItem('curTimeZone'));
        }
      });
    },
    onload({ history: umiHistory }) {
      window.onload = () => {
        const { location } = umiHistory;
        const { pathname } = location;
        if (matchPath(pathname, MENU_PATH.subscribe)) {
          Common.timeDisplayerScroll();
        }
      };
    },
  },
};
