export default {
  'visitor.mobile.invitation.code.visitor.name': '{name}，您好！',
  'visitor.mobile.invitation.code.visitor.clues': '如下信息可协助您的到访，祝您到访顺利！',
  'visitor.mobile.invitation.code.visitor.inviteCode': '邀请码',
  'visitor.mobile.invitation.code.visitor.look.navigation': '查看导航',
  'visitor.mobile.invitation.code.visitor.signTime': '到访时间{colon}',
  'visitor.mobile.invitation.code.visitor.address': '接待地点{colon}',
  'visitor.mobile.invitation.code.visitor.meetingInfo': '会议信息{colon}',
  'visitor.mobile.invitation.code.visitor.click.look': '点击查看',
  'visitor.mobile.invitation.code.healthCode.tip.title': '健康码异常',
  'visitor.mobile.invitation.code.tourCode.tip.title': '行程卡异常',
  'visitor.mobile.invitation.code.healthCode.tip.failure': '健康码失效',
  'visitor.mobile.invitation.code.tourCode.tip.failure': '行程卡失效',
  'visitor.mobile.invitation.code.btn.cancel': '取消',
  'visitor.mobile.invitation.code.upload.again': '重新上传',
  'visitor.mobile.invitation.code.locker.tip': '可使用访客专属储物柜',
  'visitor.mobile.invitation.code.visitor.wifi': 'WIFI信息{colon}',
  'visitor.mobile.invitation.code.visitor.wifi.title': 'WIFI信息',
  'visitor.mobile.invitation.code.visitor.wifi.name': 'WIFI',
  'visitor.mobile.invitation.code.visitor.wifi.code': '验证码',
  'visitor.mobile.invitation.code.visitor.wifi.until': '有效期',
  'visitor.mobile.invitation.code.visitor.wifi.account': '账号',
  'visitor.mobile.invitation.code.visitor.wifi.password': '密码',
  'visitor.mobile.invitation.code.visitor.wifi.day': '{num}天',
  'visitor.mobile.invitation.code.visitor.wifi.hour': '{num}小时',
  'visitor.mobile.invitation.code.visitor.wifi.min': '{num}分钟',
  'visitor.mobile.invitation.code.visitor.detail.address': '详细地址',
  'visitor.mobile.invitation.code.accepted': '已知晓并同意',
};
