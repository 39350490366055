import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { v4 as uuidv4 } from 'uuid';
import { ACCESS_TOKEN, SOCKET_CLIENT_ID } from '@/utils/constant';

window.socketLog = false;
let tryTime = 0;
let sockJS;
let stompClient;
// 是否连接成功
let connected = false;
let channelMap = {};
let tmpSubscribe = {};
let onError = false;

const initData = () => {
  window.socketLog = false;
  tryTime = 0;
  sockJS = undefined;
  stompClient = undefined;
  // 是否连接成功
  connected = false;
  channelMap = {};
  tmpSubscribe = {};
  onError = false;
};

/**
 * channel 退订
 * @param channel
 */
export const unsubscribe = channel => {
  if (channelMap[channel]) {
    if (window.socketLog) {
      window.console.log(`${new Date()} socket info: channel-[${channel}] unsubscribe success.`);
    }
    channelMap[channel].unsubscribe();
  } else {
    window.console.error(`${new Date()} socket err: channel not found,unsubscribe fail.`);
    throw Error('channel not found,unsubscribe fail.');
  }
};

/**
 * channel订阅
 * @param channel
 * @param msgCallback
 */
export const subscribe = (channel, msgCallback) => {
  // 如果没有连接成功，先暂时保存订阅信息，等连接成功后再订阅
  if (!connected) {
    tmpSubscribe[channel] = msgCallback;
    return;
  }
  // 如果通道已经注册，需要先退订后再注册
  if (Object.prototype.hasOwnProperty.call(channelMap, channel)) {
    unsubscribe(channel);
  }
  channelMap[channel] = stompClient.subscribe(channel, response => {
    if (window.socketLog) {
      window.console.log(
        `${new Date()} socket info: channel-[${channel}] received msg :`,
        response
      );
    }
    msgCallback(response.body);
  });
  if (window.socketLog) {
    window.console.log(`${new Date()} socket info: channel-[${channel}] subscribe success.`);
  }
};

/**
 * 初始化sock链接
 * @param url
 * @param dispatch
 */
export const connect = (url, dispatch) => {
  // 连接错误或者断开后重试
  const resetTryTime = () => {
    setTimeout(() => {
      tryTime = 0;
      sockJS = null;
      connect(url, dispatch);
      onError = false;
    }, 10 * 60 * 10000);
  };

  // 连接WebSocket服务端回调函数
  const onConnect = frame => {
    window.console.log(`${new Date()}-------- open --------`);
    window.console.log(`${new Date()}websocket info is :`, frame);
    // 连接成功后，把临时保存的订阅取出进行订阅
    connected = true;
    Object.keys(tmpSubscribe).forEach(key => {
      subscribe(key, tmpSubscribe[key]);
    });
  //  tmpSubscribe = {};
  };

  // 连接失败时的回调函数，此函数重新调用连接方法，形成循环，直到连接成功
  const onStompError = () => {
    if (onError) {
      return;
    }
    onError = true;
    connected = false;
    window.console.log(`${new Date()}-------- error --------`);
    if (tryTime > 5) {
      resetTryTime();
      return;
    }
    tryTime += 1;
    setTimeout(() => {
      sockJS = null;
      connect(url, dispatch);
      onError = false;
    }, 10000 * 15);
  };

  // 如果已经建立链接，则返回不需重新建立
  if (sockJS) {
    return;
  }
  // 建立socket主线
  sockJS = new SockJS(url);

  // 使用STMOP子协议的WebSocket客户端
  stompClient = Stomp.over(sockJS);
  // 客户端每20000ms发送一次心跳检测
  stompClient.heartbeat.outgoing = 20000;
  // 客户端不从服务端接收心跳包
  stompClient.heartbeat.incoming = 20000;

  //  如果有token可以继续连接
  if (sessionStorage.getItem(ACCESS_TOKEN)) {
    stompClient.connectHeaders = {
      token: sessionStorage.getItem(ACCESS_TOKEN),
      clientId: `${SOCKET_CLIENT_ID}${uuidv4()}`,
    };
    stompClient.onConnect = onConnect;
    stompClient.onStompError = onStompError;
    // 断开和错误使用相同处理逻辑
    stompClient.onDisconnect = onStompError;
    stompClient.onWebSocketClose = onStompError;
    stompClient.onWebSocketError = onStompError;
    stompClient.activate();
    window.console.log(`${new Date()} socket info: socket activate.`);
  } else {
    window.console.error(`${new Date()} web socket 连接错误，无法获token！`);
  }
};

/**
 * 关闭socket
 */
export const closeSocket = () => {
  if (sockJS && stompClient) {
    stompClient.forceDisconnect();
    initData();
  }
};
