export default {
  'menu.envm.app.env_monitor': '环境监测',
  'menu.envm.app.env_dashborad': '实时数据',
  'menu.envm.app.history': '历史数据',
  'env.temperature.indoor': '室内温度',
  'env.humidity.indoor': '室内湿度',

  'env.excellent': '优',
  'env.good': '良',
  'env.normal': '正常',
  'env.damp': '潮湿',
  'env.dry': '干燥',
  'env.too.high': '过高',
  'env.high': '偏高',
  'env.low': '偏低',
  'env.too.low': '过低',
  'env.scorching': '酷热',
  'env.hot': '炎热',
  'env.comfortable': '舒适',
  'env.lightly.cold': '微寒',
  'env.cold': '寒冷',
  'env.heavily.polluted': '严重污染',
  'env.heavy.pollution': '重度污染',
  'env.middle.pollution': '中度污染',
  'env.lightly.pollution': '轻度污染',
  'env.lightly.exceeded': '轻度超标',
  'env.middle.exceeded': '中度超标',
  'env.severely.exceeded': '重度超标',
  'env.seriously.exceeded': '严重超标',
  'env.avoid.outside': '避免留在室外',
  'env.reduce.outside': '减少室外活动',
  'env.sensitive.people': '敏感人群注意',
  'env.reduce.outside.exercise': '减少户外剧烈运动',
  'env.normal.activity': '正常活动',
  'env.fresh.air': '空气清新',
  'env.discomfort': '身体严重不适',
  'env.lightly.discomfort': '身体轻微不适',
  'env.heavily.discomfort': '身体状态欠佳',
  'env.timely.ventilation': '及时通风换气',
  'env.irritant.gas': '刺激性气体',
  'env.slight.odor': '轻微异味',

  'env.device': '设备',
  'env.device.number': '设备编号',
  'env.device.tip': '请选择设备',
  'env.current.day': '当天',
  'env.current.week': '本周',
  'env.value.min': '最小值',
  'env.value.max': '最大值',
  'env.value.avg': '平均值',

  'env.reference.temp': '参考值：16℃～27℃ 舒适',
  'env.reference.hum': '参考值：30%～50% 舒适',
  'env.reference.pm25': '参考值：<=35 μg/m³ 空气清新',
  'env.reference.tvoc': '参考值：<=400 PPb 空气清新',
  'env.reference.co2': '参考值：<=500 PPm 空气清新',

  'home.week.1': '周一',
  'home.week.2': '周二',
  'home.week.3': '周三',
  'home.week.4': '周四',
  'home.week.5': '周五',
  'home.week.6': '周六',
  'home.week.7': '周天',

  'env.device.none': '请绑定设备！',
  'cloud.nodata': '暂无数据',

  'env.device.select': '点击选择设备',

  'cloud.expired.renewal.tip1': '您的订阅已过期，请使用浏览器登录并续费(企业管理员)',
  'cloud.expired.renewal.tip2': '订阅过期后满180天未及时续订，系统将自动注销租户并删除所有数据',
  'cloud.expired.renewal.tip5': '您无权限访问',
  'cloud.expired.renewal.tip6': '建议检查您是否已拥有角色，或者角色是否已分配菜单(企业管理员)',

  'cloud.privacy.policy': '隐私政策',
  'cloud.service.terms': '服务条款',
}