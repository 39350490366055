export default {
  index_bottom_1: 'Key Features ',
  index_bottom_2: 'Calendar',
  index_bottom_3: 'Meeting Booking',
  index_bottom_4: 'Web Meeting',
  index_bottom_5: 'Meeting Call',
  index_bottom_6: 'Customized Setting',
  index_bottom_7: 'User Management',
  index_bottom_8: 'Analystic & Report',
  index_bottom_9: 'Mobile APP',
  index_bottom_10: 'Product Demo',
  index_bottom_11: 'Value added services',
  index_bottom_12: 'Meeting room Door display',
  index_bottom_13: 'VS Call',
  index_bottom_14: 'Video Meeting',
  index_bottom_15: 'System Integration ',
  index_bottom_16: 'Price',
  index_bottom_17: 'Free Edition',
  index_bottom_18: 'Standard Edition',
  index_bottom_19: 'Enhanced Edition',
  index_bottom_20: 'About Us',
  index_bottom_21: 'About Company',
  index_bottom_22: 'Technical Support',
  index_bottom_23: 'News Room',
  index_bottom_24: 'Contact us',
  index_bottom_25: 'Wechat QR',
  index_bottom_26: 'Company: Beijing Wafer New Century Information Technology Co. Ltd.',
  index_bottom_27:
    'Address: 19F 1907-1909 Tower E2 , Oriental Plaza No.1 East Chang An Ave, Dong Cheng Dist. Beijing, China',
  index_bottom_28: 'Product Hotline : 400-685-3160',
  index_bottom_29: 'Email:sales@virsical.com',
  index_bottom_30: '2000-2016 Wafer Systems Ltd',
  index_bottom_31: 'Beijing Wafer New Century Information Technology Co. Ltd.',
  index_bottom_32:
    'Recommend the use of Google, Firefox and IE10 versions of the browser, the resolution of 1280*800 and above.',
  'menu.meeting.meeting_app': 'Smart Meeting',
  'menu.meeting.subscribe': 'Meeting Booking',
  'menu.meeting.mines': 'Meeting List',
  'menu.meeting.webexmeeting': 'Online Meeting',
  'menu.meeting.videomeeting': 'Video Meeting',
};
