import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 储物柜扫码
 * @param id 储物柜id
 * @param value 储物柜是组还是单个柜子 value  0是单个柜子，1是一组柜子空闲任意一个开门
 * @returns {Promise<questMethodAddToUrl>}
 */
export default function getScanResult(id, value, t) {
  let url = `/${RESTFUL_PATH.locker}/api/locker/getLockerScanInfo?lockerId=${id}`;
  if (t === 'group' && Number(value) === 1) {
    url = `/${RESTFUL_PATH.locker}/api/locker/leisure?groupId=${id}`;
  }
  return request(url, {
    method: 'GET',
  });
}
