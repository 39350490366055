/**
 * Created : vincent
 * Date : 2019-04-12  12:32
 * Email : wangxiao@wafersystems.com
 */
import { initIntlMap } from '@/utils/intlUtils';

export const dva = {
  config: {
    onError(e) {
      e.preventDefault();
      window.console.error(e.message);
    },
  },
};

// 自定义 render，比如在 render 前做权限校验
export function render(oldRender) {
  initIntlMap();
  oldRender();
}
