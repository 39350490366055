export default {
  'sws.sign.out.end': 'End Use',
  'sws.sign.out.delay': 'Extended Use',
  'sws.sign.out.tip': 'Operation Tips',
  'sws.sign.out.isEnd': 'Do you want to end the use of the current station? ',
  'sws.sign.out.end.success': 'Sign out successfully',
  'sws.sign.out.delay.success': 'Delayed success',
  'sws.sign.out.no.time': 'No time available',
  401144: 'This workstation has been bound to {value1} users',
};
