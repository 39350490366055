export default {
  'report.byDays': '按天',
  'report.byMouths': '按月',
  'report.7Days': '7天',
  'report.30Days': '30天',
  'report.60Days': '60天',
  'report.6Mouths': '6個月',
  'report.12Mouths': '12個月',
  'report.24Mouths': '24個月',
  'report.chart': '圖表',
  'report.start_': '開始時間：',
  'report.start': '開始時間',
  'report.end_': '結束時間：',
  'report.end': '結束時間',
  'report.reservation.rightDate': '請輸入正確的查詢日期',
  'report.reservation.signedIn': '到訪預約',
  'report.reservation.notIn': '未到訪預約',
  'report.reservation.total': '總計',
  'report.reservation.date': '日期',
  'report.reservation.amount': '類型數量',
};
