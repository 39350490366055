export default {
  'subscribe.roomlist.select.singleChoice': '只支持單選',
  'room.selector.atLeastOne': '請至少選擇一個會議室',
  'room.selector.webex.onlyOne': '請最多選擇一個WebEx會議室',
  'meeting.calendar.room.tag.share': '共亯',
  'meeting.tencent.people': '人',
  'meeting.roomlist.delete.modal.title': '當前會議室已經添加了參會人，刪除會議室之後您希望怎麼處理這些參會人？',
  'modal.roomlist.delete.tran': '移動至',
  'modal.roomlist.delete': '直接刪除',
};
