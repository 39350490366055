/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'myInfo.my.code': '我的二维码',
  'myInfo.face.lib': '人脸库',
  'myInfo.edit.password': '修改密码',
  'myInfo.version.num': '版本号',
  'myInfo.version.user': '用户信息',
};
