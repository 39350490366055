export default {
  'year.str': ' {year}',

  'subscribe.select.region': 'Select Location',
  'subscribe.default.region': 'Select Location',
  'subscribe.default.room': 'No meeting room available',
  'subscribe.room.noDev': 'No device',
  'subscribe.room.1to3': '1-3 person',
  'subscribe.room.4to9': '4-9 person',
  'subscribe.room.over10': 'More than 10 person',
  'subscribe.room.filter': 'Filter',
  'subscribe.room.select': '{num} rooms',
  'subscribe.send.success': 'Send successfully',
  'subscribe.free.success': 'Free account. You can only book zoom meeting within 40 minutes.',
  'subscribe.booking.failed.time': 'Single day meeting duration is greater than the configured maximum duration!',

  'subscribe.filter.attendance': 'Capacity',
  'subscribe.filter.number': '{num}',
  'subscribe.filter.devices': 'Device(s) ',
  'subscribe.filter.devices1': '(multiple)',
  'subscribe.filter.inputNum': 'Meeting room capacity',
  'subscribe.filter.time': 'Time',
  'subscribe.filter.time.plac': 'Time',

  'subscribe.room.need.audit': 'Approval required',
  'smt.please.select': 'Please Select',
  'smt.please.select.area': 'Please select the area',
  'smt.node.no.select': 'This location cannot be selected',
  'smt.please.select.none': 'There are no areas to choose from!',
  'subscribe.default.region1': 'Please select level {num} area',
  'subscribe.default.region.new_tab1': 'Local area',
  'subscribe.default.region.new_tab2': 'Shared area',
  'subscribe.default.region.new': 'All areas',
  'subscribe.default.region.select.option.no': "Don't choose",
  'subscribe.no.teams.room.data': 'Currently, no meeting room is available!',
  'subscribe.no.teams.room.data1': 'Please contact the administrator to add meeting rooms.',
  'subscribe.no.teams.room.data.tip':
    'How to add a meeting room? Please refer to https://cloud.virsical.cn/help.',
    'subscribe.time.hoilday.ok': 'The day has been set as a holiday and cannot be selected!',

  'smt.please.select.area.new': 'Select area',
};
