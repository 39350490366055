/**
 * Created by XuQiang on 2017/3/14.
 */

export default {
  // 公共
  'common.ok': '确定',
  'common.cancel': '取消',
  'upload.fail': '上传失败，请稍后重试',
  'upload.error': '服务器异常，请稍后再试',
  'upload.onlyExcel': '该功能仅支持Excel格式的文件',
  // 首页
  'index.visitor.reserve': '访客管理',
  'index.user.login': '用户登录',
  'index.username.prompt': '请输入用户名',
  'index.username': '用户名',
  'index.password.prompt': '请输入密码',
  'index.password': '密码',
  'index.submit': '登录',
  'index.exit': '退出系统',
  'index.exit.tip': '确认要退出系统？',
  // 菜单
  'layout.menu.invites': '邀请记录',
  'layout.menu.visitors': '访问记录',
  'layout.menu.registration': '访客登记',
  'layout.menu.settings': '配置管理',
  'layout.menu.phoneBook': '通讯录管理',
  'layout.menu.location': '企业办公地管理',
  'layout.menu.setting': '基础设置',
  'layout.menu.pcSetting': 'pc设置',
  'layout.menu.iPadSetting': 'iPad设置',
  'layout.menu.machineSetting': '一体机设置',
  'layout.menu.type': '访问类型管理',
  'layout.menu.NDA': '保密协议管理',
  'layout.menu.notify': '通知方式配置',
  'layout.menu.devices': '终端登录查询',
  'layout.menu.admin': '管理员管理',
  'layout.menu.custom': '前台设置',
  'layout.menu.report': '报表管理',
  'layout.menu.report.invite': '邀请报表',
  'layout.menu.report.visitor': '访问报表',
  'layout.menu.report.area': '办公地统计',
  'layout.menu.meeting': '会议活动',
  'layout.menu.meeting.edit': '编辑会议',
  'layout.menu.meeting.create': '新建会议',
  'layout.menu.meeting.detail': '查看会议',
  'layout.menu.meeting.detail.area': '参会区',
  'layout.menu.group': '团体邀请',
  'layout.menu.group.detail': '查看邀请',
  'layout.menu.blacklist': '黑名单管理',
  'layout.top.notifications': '通知方式',
  'layout.top.SMS': '短信',
  'layout.top.email': '邮件',
  'layout.top.SMS&email': '短信+邮件',
  'layout.top.system': '系统',
  // 地图
  'map.point': '地图选点：',
  'map.input.tip': '请输入地点（支持在地图中选点）',
  'map.more': '详细地址：',
  'map.add.tip': '请直接追加详细楼牌号',
  'map.right.place': '请输入有效的地址',
  // 表格
  table_no_date: '暂无数据',
  // 输入框
  input_placeHloder: '请输入关键字',
};
