import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 提交延时预订
 * @param id
 * @param hour
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function setDelayTime(params) {
  const url = `/${RESTFUL_PATH.sws}/api/client/reset`;
  return request(url, {
    method: 'GET',
    params,
  });
}

/**
 * 取消预订/注销预订
 * @param payload 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function cancelReservation(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/reserve/cancel?rid=${payload.id}`;
  return request(url, {
    method: 'GET',
  });
}
