export default {
  'sws.repairManage.space.colon': '区域：',
  'sws.repairManage.check.in': '签到',
  'sws.repairManage.info': '提示',
  'sws.repairManage.pending': '待处理',
  'sws.repairManage.processing': '处理中',
  'sws.repairManage.processed': '已处理',
  'sws.repairManage.cancelled': '已取消',
  'sws.repairManage.repairType': '故障类型',
  'sws.repairManage.userName': '报修人',
  'sws.repairManage.repairTime': '报修时间',
  'sws.repairManage.no.pending': '您当前没有未处理的报修哦~',
  'sws.repairManage.no.processing': '您当前没有处理中的报修哦~',
  'sws.repairManage.no.processed': '您当前没有已处理的报修哦~',
  'sws.repairManage.no.cancel': '取消',
  'sws.repairManage.no.detail': '报修详情',
  'sws.repairManage.handle.start': '开始处理',
  'sws.repairManage.handle.end': '结束处理',
  'sws.repairManage.department': '部门',
  'sws.repairManage.repair.status': '维修状态',
  'sws.repairManage.station.type': '工位家具类型',
  'sws.repairManage.station.fixed': '固定工位',
  'sws.repairManage.station.mobile': '共享工位',
  'sws.repairManage.station.update.success': '更新成功！',
};
