/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 查询普通预订的信息
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getLockerMybook() {
  const url = `/${RESTFUL_PATH.locker}/api/lockerReservation/getMyBooking`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 取消预订/注销预订
 * @param payload 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function cancelReservation(payload) {
  return request(`/${RESTFUL_PATH.locker}/api/lockerReservation/cancelReservation`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}
/**
 * 取消预约详细列表
 * @param uuid 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function lockerCancel(uuid) {
  const url = `/${RESTFUL_PATH.locker}/api/lockerReservation/getMyBookingList?uuid=${uuid}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 查询历史记录
 * @param payload 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getLockerHistory(payload = {}) {
  const url = `/${RESTFUL_PATH.locker}/api/lockerReservation/getBookingHistory?size=${
    payload.size || 5
  }&current=${payload.current || 1}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 预订操作
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function confirmReservation(param) {
  return request(`/${RESTFUL_PATH.locker}/api/lockerReservation/confirmReservation`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}
