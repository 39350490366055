export default {
  'locker.chose.tip': 'The Locker you chose: ',
  'sws.btn.return.locker.booking': 'Return to the locker booking page',
  'menu.locker.mobile': 'Smart Locker',
  'sws.reserve.locker.confirm.fail': 'No support for booking two or more lockers!',
  'sws.myBooking.locker': 'Smart Locker',
  403043: 'There are currently no free lockers',
  403044: 'http request failed',
  403045: 'Failed to issue permissions',
  403046: 'Failed to reclaim permission',
  403047: 'The permission to open the door has been released and the door cannot be opened',
  403061: 'Locker types are for visitor use'
};
