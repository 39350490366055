export default {
  'sws.repairManage.space.colon': '區域：',
  'sws.repairManage.check.in': '簽到',
  'sws.repairManage.info': '提示',
  'sws.repairManage.pending': '待處理',
  'sws.repairManage.processing': '處理中',
  'sws.repairManage.processed': '已處理',
  'sws.repairManage.cancelled': '已取消',
  'sws.repairManage.repairType': '故障類型',
  'sws.repairManage.userName': '報修人',
  'sws.repairManage.repairTime': '保修時間',
  'sws.repairManage.no.pending': '您當前沒有未處理的報修哦~',
  'sws.repairManage.no.processing': '您當前沒有處理中的報修哦~',
  'sws.repairManage.no.processed': '您當前沒有已處理的報修哦~',
  'sws.repairManage.no.cancel': '取消',
  'sws.repairManage.no.detail': '報修詳情',
  'sws.repairManage.handle.start': '開始處理',
  'sws.repairManage.handle.end': '結束處理',
  'sws.repairManage.department': '部門',
  'sws.repairManage.repair.status': '維修狀態',
  'sws.repairManage.station.type': '工位家具類型',
  'sws.repairManage.station.fixed': '固定工位',
  'sws.repairManage.station.mobile': '共享工位',
  'sws.repairManage.station.update.success': '更新成功！',
};
