/**
 * Created by wafer on 2017/8/8.
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export const getEmployeeList = ({ companyId }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/employees/list?companyId=${companyId}`);
};

export const getAreaCode = ({ tenantId }) => {
  let locale = localStorage.getItem('umi_locale') || 'zh-CN';
  if (locale === 'zh_TW') {
    locale = 'zh_HK';
  }
  const lang = locale && locale.split('-').join('_');
  return request(`/${RESTFUL_PATH.visitor}/api/areaCode?tenantId=${tenantId}`, {
    headers: { lang: lang },
  });
};
/* 判断是否是黑名单 */
export const isBlackList = ({ payload }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/blackList/bList/check`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};
// 身份证国际校验
export const checkIdCard = ({ idCard }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/wx/checkIdCard?idCard=${idCard}`);
};

/**
 * 根据姓名、电话号码校验访客是否黑名单
 * @param params {telAreaCode, visitorName, visitorNumber}
 * @returns
 */
 export const checkIsBlacklist = (params) => {
  return request(`/${RESTFUL_PATH.visitor}/api/blackList/checkForTeam`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};