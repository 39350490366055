/**
 * Created by wang sen on 2017/7/6.
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 扫码查询资源详情
 * @param payload
 */

export const getInfoForScan = ({ cm }) => {
  return request(`/${RESTFUL_PATH.map}/space-resource/infoForScan?cm=${cm}`);
};

/**
 * 扫码资源状态
 * @param payload
 */

export const getInfoByProduct = (payload) => {
  let url = `${payload.url}?resourceId=${payload.resourceId}`;
  if(payload?.productParam){
    url = `${url}&productParam=${encodeURIComponent(payload.productParam)}`;
  }
  return request(
    url,
    {
      method: 'GET',
    },
  );
};

/**
 * 资源操作
 * @param payload
 */

export const handleActionApi = (payload) => {
  return request(
    `${payload.url}`,
    {
      method: 'GET',
    },
  );
};


export const showSubmitBtn = ({ spaceId, tenantId }) => {
  return request(`/${RESTFUL_PATH.fm}/api/requirement/showSubmitBtn?spaceId=${spaceId}&tenantId=${tenantId}`);
};
