/**
 * Created by joe on 2017/3/24.
 */
import moment from 'moment-timezone';
import { formatMessage } from 'umi';
import _ from 'lodash';

export const AM_VALUE = 0;
export const PM_VALUE = 1;
export const ALL_VALUE = 2;
export const CUSTOM_VALUE = 3;

export const allAmPm = [
  { value: ALL_VALUE, name: 'all_day' },
  {
    value: AM_VALUE,
    name: 'am',
  },
  { value: PM_VALUE, name: 'pm' },
  { value: CUSTOM_VALUE, name: 'custom' },
];
export const allAm = [
  { value: ALL_VALUE, name: 'all_day' },
  { value: AM_VALUE, name: 'am' },
  { value: CUSTOM_VALUE, name: 'custom' },
];
export const onlyPm = [
  { value: PM_VALUE, name: 'pm' },
  { value: CUSTOM_VALUE, name: 'custom' },
];
export const TIME_FORMAT = {
  date: 'YYYY-MM-DD',
  time: 'HH:mm',
};
/**
 *
 * @param globalTime
 * @param reservationDays
 * @returns {{amPm: *, selectedAmPm: *, selectedDate: *, beginDate: (boolean|*), endDate: *}}
 */
export const getAmPm = (globalTime, reservationDays = 2, timeZone = 'Asia/Shanghai') => {
  // 预约的时区时间是哪一天, TODO 那一天待验证
  const day = moment().tz(timeZone).format('YYYY-MM-DD');
  // 现在时间
  const now = moment();
  // 时区当天开始时间
  const today = moment.tz(day, timeZone).startOf('d');
  const tomorrow = moment.tz(day, timeZone).add(1, 'days').startOf('d');
  const amEndString = `${day} ${globalTime.amEndTime}`;
  const amEndTime = moment.tz(amEndString, timeZone).valueOf();
  const pmEndString = `${day} ${globalTime.pmEndTime}`;
  const pmEndTime = _.isEmpty(globalTime.pmEndTime)
    ? tomorrow
    : moment.tz(pmEndString, timeZone).valueOf();
  // 显示日期用，这里加上时区
  const tmpDate = moment.tz(now, timeZone).valueOf();
  // console.log('getAmPm tmpDate amEndTime pmEndTime', tmpDate, amEndString, amEndTime, pmEndTime)
  let amPm;
  let selectedAmPm;
  let selectedDate;
  const beginDate = (now < pmEndTime && today) || tomorrow;
  const endDate = moment
    .tz(day, timeZone)
    .add(reservationDays - 1, 'days')
    .startOf('d');
  if (tmpDate < amEndTime) {
    amPm = allAmPm;
    selectedAmPm = ALL_VALUE;
    selectedDate = today;
  } else if (tmpDate < pmEndTime) {
    amPm = onlyPm;
    selectedAmPm = PM_VALUE;
    selectedDate = today;
  } else if (tmpDate <= now && now > pmEndTime && reservationDays > 1) {
    amPm = allAmPm;
    selectedAmPm = ALL_VALUE;
    selectedDate = tomorrow;
  } else {
    amPm = allAmPm;
    selectedAmPm = ALL_VALUE;
    selectedDate = today;
  }
  return {
    amPm,
    selectedAmPm,
    selectedDate,
    beginDate,
    endDate,
  };
};

/**
 * 改变时间至 最近的 15 分钟倍数; 向后优先原则, 时间会比参数长
 * @param time is moment
 */
export const changeTimeAfter = (t, advance = 0) => {
  const time = advance ? moment(t).add(advance, 'minute') : moment(t)
  const m = Number(time.format('mm'));
  const muti = Math.floor(m / 15) + Number(m % 15 > 0);
  const minute = 15 * muti;
  if (minute === 0) {
    return time.startOf('hour');
  }
  if (minute < 60) {
    return time.startOf('hour').add(minute, 'm');
  }
  return time.startOf('hour').add(1, 'h');
};

/**
 * 改变时间至 最近的 15 分钟倍数; 向前优先原则, 时间会比参数短
 * @param time is moment
 */
export const changeTimeBefore = (time) => {
  const m = Number(time.format('mm'));
  const muti = Math.floor(m / 15);
  const minute = 15 * muti;
  // console.log('changeTimeBefore minute', minute)
  if (minute === 0) {
    return time.startOf('hour');
  }
  return time.startOf('hour').add(minute, 'm');
};

/**
 * 判断当前时间是不是下班
 * @param globalTime
 */
export const isOffWork = (globalTime, timeZone = 'Asia/Shanghai') => {
  // 预约的时区时间是哪一天,
  const day = moment().tz(timeZone).format('YYYY-MM-DD');
  // 现在时间
  const today = changeTimeAfter(moment());
  const endTime = moment.tz(`${day} ${globalTime.pmEndTime}:00`, timeZone);

  const allDayEnd = changeTimeBefore(endTime);
  return today >= allDayEnd;
};

export const getSelectTimeData = (
  globalTime,
  beforeDay,
  immediateUseOff = false,
  timeZone = 'Asia/Shanghai',
) => {
  const amPmData = [];
  const amPmConf = getAmPm(globalTime, beforeDay, timeZone);
  // console.log('getSelectTimeData amPmConf', amPmConf)
  const day = moment().tz(timeZone).format('YYYY-MM-DD');
  // 时区开始时间戳
  const tmpDate =
    Number(beforeDay) === 1 ? moment.tz(day, timeZone).startOf('d') : moment(amPmConf.beginDate);
  const onlyPmList = onlyPm.map((item) => {
    return {
      label: formatMessage({ id: item.name }),
      value: item.value,
    };
  });

  const allAmPmList = allAmPm.map((item) => {
    return {
      label: formatMessage({ id: item.name }),
      value: item.value,
    };
  });

  const allAmList = allAm.map((item) => {
    return {
      label: formatMessage({ id: item.name }),
      value: item.value,
    };
  });
  let length;
  if (beforeDay > 1) {
    length = isOffWork(globalTime, timeZone) ? beforeDay - 1 : beforeDay;
  } else {
    length = beforeDay;
  }

  for (let i = 1; i <= length; i += 1) {
    const label = tmpDate.format(formatMessage({ id: 'sws.time.date' }));
    let amPm;
    if (i === 1 && amPmConf.selectedAmPm === PM_VALUE) {
      amPm = onlyPmList;
    } else {
      amPm = immediateUseOff ? allAmList : allAmPmList;
    }
    amPmData.push({
      label,
      value: tmpDate.valueOf(),
      children: amPm,
    });
    tmpDate.add(1, 'days');
  }
  // console.log('amPmData', amPmData)
  return amPmData;
};

/**
 * 判断 给定时间是否小于 当前时间; 基于分钟判断
 * @param time
 * @param timeZone
 */
export const isTimeBeforeNow = (time, timeZone) => {
  const now = moment().tz(timeZone);
  const mint = now.format('mm');
  const day = now.format('YYYY-MM-DD');
  const hour = now.format('HH');
  if (day !== time?.format('YYYY-MM-DD')) {
    return false;
  }
  // console.log('isTimeBeforeNow', day, time?.format('YYYY-MM-DD'))
  if (Number(hour) > Number(time?.format('HH') || 0)) {
    return true;
  }
  if (Number(hour) === Number(time?.format('HH') || 0)) {
    const mintue = time.tz(timeZone).format('mm');
    return Number(mintue) < Number(mint);
  }
  return false;
};

/**
 * 判断当前时间是不是上班时间, true 不在上班时间
 * @param globalTime
 * @param timeZone
 */
export const isWorkTime = (globalTime, timeZone = 'Asia/Shanghai') => {
  // 预约的时区时间是哪一天,
  const day = moment().tz(timeZone).format('YYYY-MM-DD');
  // 现在时间
  const now = moment().valueOf();
  const amStart = changeTimeAfter(moment.tz(`${day} ${globalTime.amStartTime}:00`, timeZone));
  const amEnd = changeTimeAfter(moment.tz(`${day} ${globalTime.amEndTime}:00`, timeZone));
  const pmStart = changeTimeBefore(moment.tz(`${day} ${globalTime.pmStartTime}:00`, timeZone));
  const pmEnd = changeTimeBefore(moment.tz(`${day} ${globalTime.pmEndTime}:00`, timeZone));
  const beforeSch = now <= amStart;
  const midSch = now <= pmStart && now >= amEnd;
  const aferSch = now >= pmEnd;
  // console.log('beforeSch midSch aferSch', beforeSch, midSch, aferSch, moment.tz(`${day} ${globalTime.pmEndTime}:00`, timeZone))
  return beforeSch || midSch || aferSch;
};

/**
 * 判断所选时间是不是上班时间, true 不在上班时间
 * @param time 需要判断的时间
 * @param type start， end 开始时间，结束时间
 * @param globalTime
 * @param timeZone
 */
export const isTimeWorkTime = (time, type, globalTime, timeZone) => {
  // 预约的时区时间是哪一天,
  const day = moment(time).format('YYYY-MM-DD');
  const amEnd = changeTimeAfter(moment.tz(`${day} ${globalTime.amEndTime}:00`, timeZone));
  const pmStart = changeTimeBefore(moment.tz(`${day} ${globalTime.pmStartTime}:00`, timeZone));
  // [, amEnd) [pmStart, ]
  if (type === 'start') {
    const midSch = moment(time) < pmStart && moment(time) >= amEnd;
    return midSch && `${globalTime.amEndTime} ~ ${globalTime.pmStartTime}`;
  }
  const midSch = moment(time) <= pmStart && moment(time) > amEnd;
  return midSch && `${globalTime.amEndTime} ~ ${globalTime.pmStartTime}`;
};

// 最小可预约日期
export const minBookingDate = (globalTime, timeZone) => {
  let minDate = moment();
  if (isOffWork(globalTime, timeZone)) {
    minDate = moment().add(1, 'day');
  }
  return minDate;
};

// 判断日期是否超过 跨天预约限制, true 超过，false 未超过
export const isDayLimit = (workday, limitDay, startDate, endDate, timeZone) => {
  // const startIndex = workday.findIndex(
  //   (v) => v === moment(startDate).tz(timeZone).format('YYYY-MM-DD'),
  // );
  // const endIndex = workday.findIndex(
  //   (v) => v === moment(endDate).tz(timeZone).format('YYYY-MM-DD'),
  // );
  // return endIndex - startIndex > Number(limitDay);
  const days = moment(endDate).tz(timeZone).diff(moment(startDate).tz(timeZone), 'days');
  const arr = [];
  for (let i = 0; i <= days; i++) {
    arr.push(moment(startDate).tz(timeZone).add(i, 'day').format('YYYY-MM-DD'));
  }
  let num = 0;
  for (let i = 0; i < workday?.length; i++) {
    for (let j = 0; j < arr.length; j++) {
      if (workday?.[i] === arr[j]) {
        num++;
        break;
      }
    }
  }
  return num > Number(limitDay);
};

/**
 * 获取当天的 可选时间
 * @param
 */
export const getTimeDate = (globalTime, timeZone = 'Asia/Shanghai', amPm, selectAmPm) => {
  const dateDay = moment().tz(timeZone).format('YYYY-MM-DD');
  // console.log('getTimeDate', dateDay)
  const amStartTime = moment.tz(`${dateDay} ${globalTime.amStartTime}:00`, timeZone);
  let startTime = changeTimeAfter(amStartTime);
  const pmEndTime = moment.tz(`${dateDay} ${globalTime.pmEndTime}:00`, timeZone);
  const endTime = changeTimeBefore(pmEndTime);
  // 开始时间设置，如果当前时间大于上午开始时间，开始时间则是当前时间
  if (moment() > amStartTime && moment() < endTime) {
    startTime = changeTimeAfter(moment().tz(timeZone));
  }
  if (isTimeWorkTime(startTime, 'start', globalTime, timeZone)) {
    startTime = changeTimeAfter(moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone));
  }
  return { startTime, endTime, amPm, selectAmPm };
};

/**
 * 根据日期获取时间
 * @param
 */
export const getTimeByDate = (date, globalTime, timeZone = 'Asia/Shanghai', reserve_time_mode) => {
  const dateDay = date.format('YYYY-MM-DD');
  const now = moment();
  let amPm;
  let selectAmPm;
  let modal;
  // console.log('getTimeByDate', dateDay)
  const amStartTime = moment.tz(`${dateDay} ${globalTime.amStartTime}:00`, timeZone);
  const startTime = changeTimeAfter(amStartTime);
  const pmStartTime = moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone);
  const pmEndTime = moment.tz(`${dateDay} ${globalTime.pmEndTime}:00`, timeZone);
  const endTime = changeTimeBefore(pmEndTime);
  const amEndTime = moment.tz(`${dateDay} ${globalTime.amEndTime}:00`, timeZone);
  if (changeTimeAfter(now) < amEndTime) {
    amPm = allAmPm;
    selectAmPm = ALL_VALUE;
  } else if (changeTimeAfter(now) < pmEndTime) {
    amPm = onlyPm;
    selectAmPm = PM_VALUE;
  } else {
    amPm = allAmPm;
    selectAmPm = ALL_VALUE;
  }
  // 是给上午，下午，全天增加时间显示
  amPm = getAmPmTime(amPm, dateDay,timeZone, amStartTime, amEndTime, pmStartTime,pmEndTime)
  modal = amPm;
  if (Number(reserve_time_mode) === 1) {
    modal = amPm.filter((v) => v.value === CUSTOM_VALUE);
    selectAmPm = CUSTOM_VALUE;
  }
  if (Number(reserve_time_mode) === 2) {
    modal = amPm.filter((v) => v.value !== CUSTOM_VALUE);
  }

  // 开始时间设置，如果当前时间大于上午开始时间，开始时间则是当前时间
  if (dateDay === moment().tz(timeZone).format('YYYY-MM-DD')) {
    return getTimeDate(globalTime, timeZone, modal, selectAmPm);
  }
  return { startTime, endTime, amPm: modal, selectAmPm };
};
/**
 * 将选择的日期转化为起始时间
 *
 * @param globalTime 全局时间对象，包含上下班起止时间等信息
 * @param selectedDate 选择的日期字符串, 如：2019-12-22
 * @param selectAmPm 选择的时段，如：上午、下午等 的数字值
 * @returns {*}
 */
export const timeSelection = (globalTime, selectedDate, selectAmPm, timeZone = 'Asia/Shanghai') => {
  const { amStartTime, amEndTime, pmStartTime, pmEndTime } = globalTime;
  const [allDayStart, allDayEnd] = [amStartTime, pmEndTime];
  const now = moment().valueOf();
  let startTime;
  let endTime;
  let startShow;
  const startDay = moment.tz(selectedDate, timeZone).startOf('d').valueOf();
  switch (selectAmPm) {
    case AM_VALUE:
      startTime = moment(startDay).add(moment.duration(amStartTime));
      endTime = moment(startDay).add(moment.duration(amEndTime));
      break;
    case PM_VALUE:
      startTime = moment(startDay).add(moment.duration(pmStartTime));
      endTime = moment(startDay).add(moment.duration(pmEndTime));
      break;
    case ALL_VALUE:
      startTime = moment(startDay).add(moment.duration(allDayStart));
      endTime = moment(startDay).add(moment.duration(allDayEnd));
      break;
    default:
  }
  if (now > startTime) {
    startShow = now;
  } else {
    startShow = startTime;
  }
  const selectPeriod = allAmPm.find((v) => v.value === selectAmPm) || {};
  const selectedM = selectPeriod.name;
  return { startTime, endTime, selectedM, startShow };
};
// 预订成功界面返回的时间格式
export const selectTime = (selectAmPm, startDate, endDate, startTime, endTime) => {
  let timeSelected = startDate.format(formatMessage({ id: 'sws.time.date' }));
  const showTime = `(${moment(startTime).format('HH:mm')} - ${moment(endTime).format('HH:mm')})`;
  if (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
    /* timeSelected = `${startDate.format(formatMessage({ id: 'sws.time.date' }))} ~ ${endDate.format(
      formatMessage({ id: 'sws.time.date' }),
    )}`; */
    timeSelected = [
      startDate.format(formatMessage({ id: 'sws.time.date' })),
      endDate.format(formatMessage({ id: 'sws.time.date' })),
    ];
  } else if (selectAmPm === AM_VALUE) {
    timeSelected = `${timeSelected} ${formatMessage({ id: 'am' })} ${showTime}`;
  } else if (selectAmPm === PM_VALUE) {
    timeSelected = `${startDate.format(formatMessage({ id: 'sws.time.date' }))} ${formatMessage({
      id: 'pm',
    })} ${showTime}`;
  } else if (selectAmPm === ALL_VALUE) {
    timeSelected = `${startDate.format(formatMessage({ id: 'sws.time.date' }))} ${formatMessage({
      id: 'all_day',
    })} ${showTime}`;
  } else if (selectAmPm === CUSTOM_VALUE) {
    /*  timeSelected = `${startDate.format(formatMessage({ id: 'sws.time.date' }))} ${startTime.format(
      'HH:mm',
    )} ~ ${endTime.format('HH:mm')}`; */
    timeSelected = [
      `${startDate.format(formatMessage({ id: 'sws.time.date' }))} ${startTime.format('HH:mm')}`,
      `${startDate.format(formatMessage({ id: 'sws.time.date' }))} ${endTime.format('HH:mm')}`,
    ];
  }

  return timeSelected;
};
export const getInitStartTime = (start, globalTime, timeZone) => {
  let startTime = start;
  let dateDay = moment(startTime).tz(timeZone).format('YYYY-MM-DD');
  if (isOffWork(globalTime, timeZone || 'Asia/Shanghai')) {
    dateDay = moment().tz(timeZone).add(1, 'day').format('YYYY-MM-DD');
  }
  const amStartTime = moment.tz(`${dateDay} ${globalTime.amStartTime}:00`, timeZone);
  if (moment() > amStartTime) {
    startTime = changeTimeAfter(moment().tz(timeZone));
  }
  if (isTimeWorkTime(startTime, 'start', globalTime, timeZone)) {
    startTime = moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone);
  }
  return startTime;
};
// 日期排序
export const sortDownDate = (a, b) => {
  return Date.parse(a) - Date.parse(b);
};
// 是给上午，下午，全天增加时间显示
export const getAmPmTime = (amPm,dateDay,timeZone,amStartTime, amEndTime, pmStartTime,pmEndTime) => {
  let amStart;
  let amEnd;
  let pmStart;
  let pmEnd;
  const time = changeTimeAfter(moment().tz(timeZone));
  if (moment() > amStartTime) {
    amStart = time
    amEnd= changeTimeBefore(amEndTime);
  }
  if(moment() < amStartTime){
    amStart = amStartTime
    amEnd= amEndTime
  }
  if(moment() > pmStartTime){
    pmStart = time
    pmEnd= changeTimeBefore(pmEndTime);
  }

  if(moment() < pmStartTime){
    pmStart = pmStartTime
    pmEnd= pmEndTime
  }
  if(dateDay !== moment().tz(timeZone).format('YYYY-MM-DD')){
    amStart = amStartTime
    amEnd= amEndTime
    pmStart = pmStartTime
    pmEnd= pmEndTime
  }

  amPm?.map(item => {
    item.startTime = '';
    item.endTime = '';
    if (item.value === ALL_VALUE) {
      item.startTime = amStart;
      item.endTime = pmEnd;
    }
    if (item.value === AM_VALUE) {
      item.startTime = amStart;
      item.endTime = amEnd;
    }
    if (item.value === PM_VALUE) {
      item.startTime = pmStart;
      item.endTime = pmEnd;
    }
  });

  return amPm;
};

//获取日期及时间显示
export const getDateShow = (startDate,endDate,timeZone)=>{
  let date = `${moment
    .tz(startDate, timeZone)
    .format(formatMessage({ id: 'sws.time.date' }))} ~ ${moment
    .tz(endDate, timeZone)
    .format(formatMessage({ id: 'sws.time.date' }))}`;
  if(moment.tz(startDate, timeZone).format(formatMessage({ id: 'sws.time.date' })) === moment
    .tz(endDate, timeZone)
    .format(formatMessage({ id: 'sws.time.date' }))){
    date = moment.tz(startDate, timeZone).format(formatMessage({ id: 'sws.time.date' }))
  }
  return date
}

// 获取有权限的菜单下的按钮权限
export const getUrlPermission = (menuData, permission, btnAuthority)=>{
  if (menuData.length > 0) {
    if (!_.isEmpty(menuData)) {
      const menuArr = menuData?.filter(
        (v) => v.path.includes('workspace')
      );
      const menuItem = [];
      menuArr?.forEach((v) => menuItem.push(...v.children));
      return menuItem.filter((item) => item.permission === permission)?.[0]?.authority?.some(b => b === btnAuthority);
    }
  }
};
