export default {
  custom_iPadLogo: '企業LOGO',
  custom_iPadBgImg: '首頁背景圖',
  custom_iPadOtherBgImg: '其他頁背景圖',
  custom_pcColor: '企業顏色',
  custom_pc_template: '打印範本',
  custom_machineBgImg: '首頁背景輪播圖',
  custom_iPadLogo_title: '我們將在iPad展示您的企業LOGO',
  custom_machineLogo_title: '我們將在一體機展示您的企業LOGO',
  custom_pcLogo_title: '我們將在pc展示您的企業LOGO',
  custom_pc_template_title: '我們將在pc打印時使用您選擇的範本尺寸',
  custom_iPad_template_title: '我們將在iPad打印時使用您選擇的範本尺寸',
  custom_machine_template_title: '我們將在一體機打印時使用您選擇的範本尺寸',
  custom_pc_template_tip: '適用於DK-11202',
  custom_pc_template_tip1: '適用於DK-22205,DK-22251',
  custom_iPadBgImg_title: '我們將在iPad首頁展示您的背景圖',
  custom_machineBgImg_title: '我們將在一體機展示您的背景輪播圖',
  custom_pcBgImg_title: '您可以自定義設置PC客戶端的外觀顏色',
  custom_iPadOtherBgImg_title: '我們將在iPad其他頁面展示您的背景圖',
  custom_machineOtherBgImg_title: '我們將在一體機其他頁展示您的背景圖',
  custom_imgTip: '提示：支持JPEG、PNG格式，推薦您使用透明背景的PNG圖片',
  custom_pcColor_btn: '選擇顏色',
  custom_pcColor_set: '輸入設置',
  custom_pcColor_input: '輸入色值',
  custom_pcColor_placeholder: '請輸入色值',
  custom_pcColor_Recommend: '推薦顏色',
  custom_machine_Horizontal: '橫屏',
  custom_machine_Vertical: '豎屏',
  custom_save: '保存',
};
