export default {
  //  访客项目,30开头

  //  ---

  //  邀请 10

  //  访问 20

  //  公共 30

  //  通知 40

  //  办公地/域 50

  //  其他(终端 门禁 访客云 会议等) 60

  //  ---

  //  邀请

  301001: 'Could not find a user with this referral code.',
  301002: 'The qr code cannot be identified',
  301003: 'Invitation Code Expired',
  301004: 'The invitation code has been executed and checked in',
  301005: 'The invitation code has been Checked Out',
  301006: 'The invitation code has been Cancel',
  301007: 'Successful sending invitations to invitees！',
  301008: 'Team invitation does not exist',
  301009: 'Invitation code cannot be empty',
  301010: 'Visitor reservation record corresponding to invitation code could not be found',
  301011: 'Invitation Code Check-in Success',
  301012: 'You have completed your check-in',
  301013: 'Reservation time is less than the current time. Please reserve it again.',
  301014: 'Reservation time is limited to {value} days. Please make a new reservation.',
  301015: 'Cross-day time limit {value} days, please reschedule ',
  301016: 'Your appointment has been cancelled successfully. Please be informed.',
  301017: 'Reservation Denial Notice',
  301018: 'Successful operation',
  301019: 'Invitation Code Check-in Failed',

  301020: 'Failed to query the last reservation record',

  301021: 'Failed to generate temporary reservation record',

  301022: 'Failed to query temporary reservation record',

  301023: "Visitor Check-in Record Can'\t Find the Invitation Code Corresponding",

  301024: 'Visitor registration record corresponding to invitation code could not be found',

  301025: 'Temporary reservation ID cannot be empty',

  301026: 'Temporary reservation does not exist',

  //  访问访客

  302001: 'Successful operation',

  302002: 'Reservation access operation failed',

  302003: 'Failed to query history information',

  302004: 'Access type already exists',

  302005: 'Access the company:',

  302006: 'Access the time:',

  302007: 'Access the cause:',

  302008: 'VISITOR NAME',

  302009: 'VISITOR COMPANY',

  302010: 'VISITOR PHONE',

  302011: 'VISITOR MAIL',

  302012: 'Access the cause',

  302013: 'Access the time',

  302014: 'visit Notifications',

  302015: 'Visitors (external attendees) list cannot be empty',

  302016: 'Visitor Audit Failure: No corresponding visitor record was found！',

  302017:
    'Visitor Audit Failure: The Visitor Record has been audited or does not need to be audited！',

  302018: "Visitor Audit Failure: Audit Results Can'\t Be Empty！",

  302019: 'Visitor Audit Failed',

  302020: 'Query access type failed',

  302021: 'New Access Type Successful',

  302022: 'New access type failed',

  302023: 'Successful modification of access type',

  302024: 'Failed to modify access type',

  302025: 'Successful deletion of access type',

  302026: 'Failed to delete access type',

  302027: 'Visitors Check Out Successfully',

  302028: 'Visitor Check-out Failure',

  302029: 'The visitor has no reservation record',

  //  公共

  303001: 'Hello',

  303002: 'Visitors',

  303003: 'Happy Work！',

  303004: 'Success',

  303005: 'Failed',

  303006: 'All',

  303007: 'Not Yet',

  303008: 'Unaccessed',

  303009: 'Recent check-in',

  303010: 'Signed Out',

  303011: 'Cancelled',

  303012: 'Pending review',

  303013: 'Rejected',

  303014: 'Expired',

  303015: 'Approved',

  303016: 'Total:',

  303017: '人次',

  303018: 'Login Failed',

  303019: 'Added successfully',

  303020: 'Added failed',

  303021: 'Deleted successfully',

  303022: 'Deleted failed',

  303023: 'Update successfully',

  303024: 'Update failed',

  303025: 'The inquirer is not on the blacklist',

  303026: 'Blacklist Query Failed',

  303027: 'Successful operation',

  303028: 'The company no longer exists at present.',

  303029: 'Query failure',

  303030: 'Files cannot be empty',

  303031: 'Enterprise domain does not exist',

  303032: 'User does not exist',

  303033: 'Change successfully',

  303034: 'Change failed',

  303035: 'Parameter error.',

  303036: 'Token failure',

  303037: 'Current user does not have permission to operate',

  303038: 'The company does not exist',

  303039: 'The server did not return any results',

  303040: 'The server returns a failed result',

  // 通知

  304001: 'Exceptions in SMS or Mail Service',

  304002: 'Notify workstation system that allocation fails: call interface fails',

  304003: 'Notification Exhibition Board Displays Welcome Information Successfully',

  304004:
    'Notification Printing Device Printing Notice Failure: Failure to Generate Request Information',

  304005: 'Notification Printing Device Printing Notice Failure:',

  304006: 'No valid indication',

  304007: 'Failed to get Wifi Password',

  304008: 'Visitor Card Printed Successfully',

  304009: 'Visitor Card Printing Failed',

  304010: 'Nobody Found',

  304011: 'Invalid Invitation Link',

  304012: 'Failure of provisional registration',

  304013: 'Failure to get the visitor system token',

  304014: 'Invalid widget parameters',

  304015: 'Users have been blacklisted',

  304016: 'Failed to obtain user information',

  304017: 'Printer Alarm',

  304018: 'Add Secret Agreement Successful',

  304019: 'Add Secret Agreement Failed',

  304020: 'Edit Secret Agreement Successful',

  304021: 'Edit Secret Agreement Failed',

  304022: 'Delete Secret Agreement Successful',

  304023: 'Delete Secret Agreement Failed',

  304024: 'Query Administrator List Failed',

  304025: 'Create Administrator Successfully',

  304026: 'Create Administrator Failed',

  304027: 'Failed to modify administrator configuration',

  304028: 'Fuzzy Query Enterprise List Failure',

  304029: 'Failure to obtain WIFI accounting',

  304030: 'Failure to get the visitor system token',

  304031: "Scope can'\t be empty",

  304032: 'The effective value of participation scope [0,1]',

  304033: 'Inquiry for my reservation failed',

  304034: 'Failure to query an appointed enterprise',

  304035: 'Please get user information through the interface first.',

  304036: 'Invalid user login credentials',

  304037: 'No SMS notification enabled in Company table',

  304038: 'Message sent failure ',

  304039: 'SMS delivered',

  304040: 'No mail notification is enabled in Company table',

  304041: 'Mail delivery failed.',

  304042: 'Send mail successfully.',

  304043: 'Successful collection of identity card information',

  304044: 'Failure collection of identity card information',

  304045: 'Access history batch synchronous upload success',

  304046: 'Access history batch synchronous upload fail',

  304047: 'File upload failed',

  304048: 'No access type can be queried',

  304049: 'Failed to send Portal message',

  // 办公地

  305001: 'No access to office area without authorization！',

  305002: 'No office area for visitors!',

  305003:
    'Failed to send a message to the office area administrator: Failed to get the list of office area administrators',

  305004: 'There is no administrator in the office area, skip the administrator message reminder',

  305005: 'There is no administrator in the office area, skip the administrator message reminder！',

  305006: 'Current users do not have office space ',

  305007: 'The current user is not bound to the office, please contact the administrator！',

  305008: 'Please unbind the current sub-sector',

  305009: 'Office does not exist (user unbound office)',

  305010: 'Please unbind employees in the current area',

  305011: 'Send a message to the office area administrator successfully',

  305012:
    'Failed to send message to office area administrator: Failed to parse interface response content！',

  305013: 'Failed to send message to office area administrator',

  305014: 'Failed to send a message to the office area administrator: Failed to call the interface',

  305015:
    'Sending messages to the office area administrator is not enabled and automatically skipped.',

  // 其他

  306001: 'Current domain package information does not exist',

  306002: 'The current domain is not initialized',

  306003: 'The current number of terminals has exceeded the limit',

  306004: 'Please login using the Terminal Administrator account',

  306005: 'Failed to perform access control scanner operation!',

  306006: 'Visitors sign in successfully and open the door for you automatically',

  306007: 'Visitor Certification Successful, Open the door for you automatically',

  306008: '[Conference Reservation Visitors]Visitor Reservation Cancellation Failed',

  306009: '[Appointment + Meeting]Cancellation failure',

  306010: 'Failure to cancel the meeting',

  306011: 'Failure to access conference rooms',

  306012: 'Confidentiality Protocol Not Configured',

  306013: 'WeChat - Code for access_token failed',

  306014: 'WeChat - Failed to obtain user information',

  306015: 'WeChat - Refresh token failed',

  306016: 'Wechat applet  - Logon credentials failed to verify',

  306017: 'wx -> Failed to obtain access type',

  306018: 'wx -> Failure to acquire office space',

  306019: 'wx -> Fuzzy Search for Employee Failure',

  306020: '【Appointment of visitors at the meeting】Visitor Reservation Cancellation Successfully',

  306021:
    'Initiating a request to the Wechat Interface did not receive a response. Please check the network first.',

  306022: 'Failure to obtain access token',

  306023: 'Wechat applet login failed',

  306024: '访客门禁扫码相关操作失败！',

  306025: '该访客已被加入黑名单',

  306026: '不在邀请时间范围内，不能开门',

  306027: '当前区域未配置门禁和设备关联',

  306028: '分配工位成功',

  306029: '解析接口响应内容失败',

  306030: '未启用通知方糖展板显示欢迎信息功能，自动跳过。',

  306031: '通知方糖展板显示欢迎信息失败:查询统计访客数量失败',

  306032: '未启用通知展板显示欢迎信息功能，自动跳过。',

  306033: '通知展板显示欢迎信息失败:查询统计访客数量失败',

  306034: '通知展板显示欢迎信息失败:调用接口失败',

  306035: '通知展板显示欢迎信息成功！',

  306036: '通知展板显示欢迎信息失败:接口返回失败结果！',

  306037: '通知展板显示欢迎信息失败:解析接口响应内容失败！',

  306038: '通知打印设备打印通知单失败:websocket 推送失败！',

  306039: '通知打印设备打印通知单成功！',

  306040: '未检测到通知打印设备, 跳过打印！',

  306041: '通知打印设备打印通知单失败:获取内部员工或访问类型失败',

  306042: '未启用通知打印设备打印通知单功能，自动跳过。',

  306043: '您的剩余短信不够发送邀请通知，请及时购买短信包',
  306048: 'Cross day conference module not turned on',
  306049: 'Failed to cancel appointment',
  306056: 'The start time of the meeting should be after the visit time',
  306058: 'The meeting has started',
  306059:
    'You have uploaded your epidemic registration information, please click the link again to enter this page',
  // OCR识别
  306060: 'Abnormal health code identification result, please upload green health code',
  306061: 'Trip card identification result is abnormal, please upload green trip card',
  306062: 'Health code is invalid, please upload health code within {number} hours',
  306063: 'Travel card is invalid, please upload {number} travel card within hours',
  306064: 'Please upload correct health code picture',
  306065: 'Please upload correct itinerary card picture',
  306066: 'Only unvisited, cancelled, and in-process records can be deleted',
  306067: 'Only records not started can be cancelled',
  306068: 'Out of hours, operation failed!',
  306070: 'Tencent ocr configuration is abnormal, please contact administrator',
  306073: 'Meeting check-in time',
  306081: 'Mobile cannot be repeated',
  306082: 'Email cannot be repeated',
  306083: 'Mobile、Email cannot be repeated',
  306079: 'Only records not started can be reissued',
  306080: 'Visitor number is beyond the current reception site {timeFormat} to invite number',
  306084: 'Cross-day meeting allows booking {num} days',
  306087: 'The client has left and you cannot sign in/out, please contact the administrator.',
  306089: 'Cross-Day guest function not turned on ',
  306091: 'Conference integration switch not turned on',
  306098: 'Cancel from Outlook',
  3999999: 'No personal information can be modified already',

  // 菜单

  'menu.system-management.blacklist': 'Blacklist Management',
  'menu.system-management': 'System Management',
  'menu.system-management.office': 'Office Management',
  'menu.system-management.setting': 'Setting Management',
  'menu.system-management.visitType': 'VisitType Management',
  'menu.system-management.terminal': 'Terminal Management',
  'menu.system-management.manage': 'Visit Management',

  //  会议活动70

  307001: 'Export personnel anomalies in attendance area',

  307002: 'Data exception, please try again later',

  307003: 'Failed to get check-in user',

  307004: 'Export Check-in User Failed',

  307005: 'The current meeting has been cancelled',

  307006: 'It is not yet time for the commencement of conference activities',

  307007: 'The conference activity is over',

  307008: 'Check-in time for meeting activities has not yet arrived',

  307009: 'Check-in for conference activities has been completed',

  307010:
    'Mobile phone number has been registered. Please change mobile phone number and try to register again',

  307011: 'Message failed. Please try again later',

  307012: 'Face information not recognized, please re-upload',

  307013: 'User not registered',

  307014: 'The attendance code is empty!',

  307015: 'Check-in time for the meeting has not yet arrived',

  307016: 'The meeting is over.',

  307017: 'The meeting is not held today.',

  307018: 'User check-in failed!',

  307019: 'The check-in object is empty',

  307020: 'Check-in failed',

  307021: 'User data not queried',

  307022: 'Illegal token',

  307023: 'Success',

  307024: 'Fail',

  307025: 'The current meeting does not exist',

  // 人脸识别
  10001: 'Cannot find any faces in the image!',
  10002: 'The image contains multiple faces!',
  10003: 'The face in the image is too small!',
  10004: 'The image is too blurry!',
  30001: 'The group id does not exist!',
  30002: 'The user id does not exist!',

  309001: 'ID cannot be empty',
  309002: 'The ID parameter is not valid',
  309003: 'Name cannot be empty',
  309004: 'Name length cannot exceed 50',
  309005: 'Mobile cannot be empty',
  309006: 'Mobile length cannot exceed 50',
  309007: 'Device type cannot be empty',
  309008: 'MAC address cannot be empty',
  309009: 'Location name cannot be empty',
  309010: 'Location name length cannot exceed 100',
  309011: 'Location full name cannot be empty',
  309012: 'Location full name length cannot exceed 100',
  309013: 'Address cannot be empty',
  309014: 'Address length cannot exceed 255',
  309015: 'Notice type cannot be empty',
  309016: 'Notice type length cannot exceed 11',
  309017: 'Notice switch flag cannot be empty',
  309018: 'Notice switch flag length cannot exceed 11',
  309019: 'Code cannot be empty',
  309020: 'NDA cannot be empty',
  309021: 'NDA length cannot exceed 10000',
  309022: 'Location ID cannot be empty',
  309023: 'Access control number cannot be empty',
  309024: 'System sign-out switch cannot be empty',
  309025: 'System sign-out time cannot be empty',
  309026: 'Advance notice cannot be empty',
  309027: 'Wifi switch cannot be empty',
  309028: 'Face switch cannot be empty',
  309029: 'Binding data cannot be empty',
  309030: 'Subject cannot be empty',
  309031: 'Subject length cannot exceed 255',
  309032: 'Receptionist cannot be empty',
  309033: 'Receptionist length cannot exceed 255',
  309034: 'Purpose cannot be empty',
  309035: 'Reception location cannot be empty',
  309036: 'Reception location length cannot exceed 255',
  309037: 'Arrival time cannot be empty',
  309038: 'Face cannot be empty',
  309039: 'Area code cannot be empty',
  309040: 'Host cannot be empty',
  309041: 'QR code cannot be empty',
  309042: 'Device code cannot be empty',
  306107: 'The selected visitor type does not exist, please re-select',
  20240919: 'Meeting has been cancelled',
};
