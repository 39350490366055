/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 查询用户位置信息
 * @param payload 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getStationMapByUser(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/station/findStationsByUser?domainId=${
    payload.domainId
  }&occUserName=${payload.userName || ''}&localeTimeZone=Asia/Shanghai`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询用户位置信息
 * @param payload 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getStationMapByUserX(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/station/findStationsByUser?domainId=${
    payload.domainId
  }&occUserName=${payload.userName || ''}&localeTimeZone=Asia/Shanghai`;
  return request(url, {
    method: 'GET',
  });
}
