import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import moment from 'moment-timezone';
import * as services from './services';

const initState = {
  data: [],
  detail: {},
  holiday: { workday: [], holiday: [], sysHoliday: {} },
  workTime: {},
  selectUser: {},
  timeReserve: [],
  postData: {
    date: moment(),
    endTime: '',
    startTime: '',
    startDate: moment(),
    endDate: moment(),
    stationId: '',
    useUserId: '',
  },
  reservationInfo: {},
  currentSpace: {},
  multiple: false,
  selectEles: [],
  stationData: [],
  selectStation: [],
  detailInfoBatch: [],
  isAcrossDay: false,
  tabActiveKey: '1',
  allStations: [],
  selectStations: [],
  daySwitch: false,
  manSwitch: false,
  bookingList: [],
};

export default {
  namespace: 'swsDetail',
  state: initState,
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    initState() {
      return initState;
    },
  },
  effects: {
    *getList({ payload }, { call, put, select }) {
      const { data: originData } = yield select((state) => state.swsDetail);
      const { code, data, msg } = { code: 0, data: Array.from({ length: 10 }).map((_, i) => i) }; // yield call(services.getList, payload);

      if (code !== 0) {
        Toast.fail(formatMessage({ id: msg }));
        return false;
      }
      const newData = [...originData, ...data];
      yield put({
        type: 'setState',
        payload: {
          data: newData,
        },
      });
      return newData;
    },

    *getDetail({ filter }, { call, put, select }) {
      const { code, data, msg } = yield call(services.getDetail, filter);

      if (code !== 0) {
        Toast.fail(formatMessage({ id: msg }));
        return false;
      }

      yield put({
        type: 'setState',
        payload: {
          detail: { stationId: filter.stationId, ...data },
        },
      });

      return data;
    },

    *getHoliday({ filter }, { call, put, select }) {
      const { code, data, msg } = yield call(services.getHoliday, { flag: 1, ...filter });

      if (code !== 0) {
        Toast.fail(formatMessage({ id: msg }));
        return false;
      }

      yield put({
        type: 'setState',
        payload: {
          holiday: data,
        },
      });

      return data;
    },

    *getWorkTime(_, { call, put, select }) {
      let { sysTenant } = yield select((state) => state.user);
      if (!sysTenant?.id) {
        sysTenant = JSON.parse(localStorage.getItem('SYSTENANT') || '{}');
      }

      const { code, data, msg } = yield call(services.getWorkTime, { tenantId: sysTenant?.id });

      if (code !== 0) {
        Toast.fail(formatMessage({ id: msg }));
        return false;
      }

      yield put({
        type: 'setState',
        payload: {
          workTime: data,
        },
      });

      return data;
    },

    *getTimeReserve({ filter }, { call, put, select }) {
      const { code, data, msg } = yield call(services.getTimeReserve, filter);

      if (code !== 0) {
        Toast.fail(formatMessage({ id: msg }));
        return false;
      }

      yield put({
        type: 'setState',
        payload: {
          timeReserve: data,
        },
      });

      return data;
    },
    *reservation({ payload }, { call, put, select }) {
      try {
        const { code, msg, data } = yield call(services.reservation, payload);
        if (code !== 0) {
          Toast.fail(formatMessage({ id: msg }, {...data} ));
          return false;
        }

        Toast.success(formatMessage({ id: 'sws.detail.sws.reserve.ok' }));

        return true;
      } catch (e) {
        return false;
      }
    },
    *getReservationInfo({ filter }, { call, put, select }) {
      const { code, data, msg } = yield call(services.getReservationInfo, filter);

      if (code !== 0) {
        Toast.fail(formatMessage({ id: msg }));
        return false;
      }

      yield put({
        type: 'setState',
        payload: {
          reservationInfo: data,
        },
      });

      return data;
    },
    *getStationByLocation({ locationId }, { call, put, select }) {
      const { code, data, msg } = yield call(services.getStationsByLocationIdScreen, locationId);

      if (code !== 0) {
        Toast.fail(formatMessage({ id: msg }));
        return false;
      }

      yield put({
        type: 'setState',
        payload: {
          allStations: data,
        },
      });

      return data;
    },
    *detailInfo(_, { call, put, select }) {
      const {
        swsDetail: { selectEles },
      } = yield select((state) => state);
      // const stationIds = selectStation.map(v => v.id)
      const result = yield call(services.detailInfo, {
        stationIds: selectEles.map((v) => v.objectId),
      });
      if (result.code === 0) {
        const data = result.data || {};
        yield put({
          type: 'setState',
          payload: {
            detailInfoBatch: data,
          },
        });
      }
    },
    *reservationMore({ payload }, { call }) {
      const { code, msg, data } = yield call(services.reservationMore, payload);
      if (code !== 0) {
        Toast.fail(formatMessage({ id: msg }, { ...data }));
        return false;
      }
      Toast.success(formatMessage({ id: 'sws.detail.sws.reserve.ok' }));
      return true;
    },
    *stationBookList({ /* stationId,  */ startTime }, { call, put, select }) {
      const {
        swsDetail: { selectEles },
      } = yield select((state) => state);
      // const stationIds = selectStation.map(v => v.id)
      const { code, data, msg } = yield call(services.stationBookList, {
        stationIds: selectEles.map((v) => v.objectId),
        startTime,
      });
      // console.log(data, 'bookingListbookingListbookingList');
      if (code !== 0) {
        Toast.fail(formatMessage({ id: msg }));
        return false;
      }

      let bookingList = [];
      for (const key in data) {
        bookingList = [...bookingList, ...data[key]];
      }

      // console.log(bookingList, 'bookingListbookingListbookingList');

      yield put({
        type: 'setState',
        payload: {
          bookingList /* :  data[stationId] */,
        },
      });
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname.indexOf('/index-map') !== -1) {
          dispatch({ type: 'swsDetail/initState' });
        }
      });
    },
  },
};
