/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
/* eslint-disable guard-for-in */
/* eslint-disable no-empty-pattern */
/* eslint-disable require-yield */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { Toast } from 'antd-mobile-v2';
import queryString from 'query-string';
import { history } from 'umi';
import qparse from 'query-string';
import { formatMessage } from 'umi';
import { getUrlParam } from '@/utils/utils';
import request from '../../../utils/request';
import { getReportData, queryCurrentUserInfo } from '../../../services/lighting';

export default {
  namespace: 'lighting',
  state: {
    floor: {
      current: {},
      list: [],
      temp: {},
    },
    area: {
      current: {},
      list: [],
      temp: {},
    },
    envData: {
      humidity: {
        // 湿度
        current: 0,
        status: 0,
        desc: '--',
        dataX: [],
        dataY: [],
        info: {
          // title: formatMessage({ id: 'title.humidity' }), // '室内湿度',
          title: '室内湿度',
          // subtitle: formatMessage({ id: 'title.humidity.sub' }),
          subtitle: '参考值：30%-80%舒适',
          lineColor: '#32A7EF',
          yName: '%',
          yMax: '100',
          yPart: 5,
        },
      },
      temperature: {
        // 温度
        current: 0,
        status: 0,
        desc: '--',
        dataX: [],
        dataY: [],
        info: {
          // title: formatMessage({ id: 'title.wendu' }),
          title: '室内温度',
          // subtitle: formatMessage({ id: 'title.wendu.sub' }),
          subtitle: '参考值：15℃-20℃舒适',
          lineColor: 'pink',
          yName: '℃',
          yMax: '50',
          yPart: 5,
        },
      },
      co2: {
        current: 0,
        status: 0,
        desc: '--',
        dataX: [],
        dataY: [],
        info: {
          title: 'CO2',
          // subtitle: formatMessage({ id: 'title.co2.sub' }),
          subtitle: '参考值：≤800PPM空气清新',
          lineColor: '#32A7EF',
          yName: 'PPM',
          yMax: '5000',
          yPart: 5,
        },
      },
      pm25: {
        current: 0,
        status: 0,
        desc: '--',
        dataX: [],
        dataY: [],
        info: {
          title: 'PM2.5',
          // subtitle: formatMessage({ id: 'title.pm25.sub' }),
          subtitle: '参考值：<75 空气清新',
          lineColor: '#F78540',
          yName: 'μg/m³',
          yMax: '500',
          yPart: 5,
        },
      },
      voc: {
        current: 0,
        status: 0,
        desc: '--',
        dataX: [],
        dataY: [],
        info: {
          title: 'VOC',
          // subtitle: formatMessage({ id: 'title.voc.sub' }),
          subtitle: '参考值：<125 空气清新',
          lineColor: '#91BC5B',
          yName: 'mg/m³',
          yMax: 5,
          yPart: 5,
        },
      },
      methanal: {
        // 甲醛
        current: 0,
        status: 0,
        desc: '--',
        dataX: [],
        dataY: [],
      },
    },
    energy: {
      light: {
        current: 0,
        status: 0,
        desc: '--',
        dataX: [],
        dataY: [],
        info: {
          // title: formatMessage({ id: 'title.energy' }),
          title: '参考值：<125 空气清新',
          subtitle: '',
          lineColor: '#32A7EF',
          yName: 'kWh',
          yMax: 1200,
          yPart: 5,
        },
      },
      air: {
        current: 0,
        status: 0,
        desc: '--',
        dataX: [],
        dataY: [],
        info: {
          // title: formatMessage({ id: 'title.air.energy' }),
          title: '空调能耗统计',
          subtitle: '',
          lineColor: '#91BC5B',
          yName: 'kWh',
          yMax: 1200,
          yPart: 5,
        },
      },
    },
    envWhere: 'DAY',
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetchCurrentUserInfo(_, { call, put }) {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      if (!userInfo) {
        const response = yield call(queryCurrentUserInfo);
        if (response.code === 0) {
          const { sysUser } = response.data;
          sessionStorage.setItem('userInfo', JSON.stringify(sysUser));

          userInfo.userName = userInfo.username; // 兼容老系统
          sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
          sessionStorage.setItem('token', `${userInfo.token_type} ${userInfo.access_token}`);
        }
      }
    },
    *getAuth({ location, callBack }, { call, put, select }) {
      const { code, data, msg } = yield request('/v1/api/area/roleforms');
      if (code !== 0) {
        return Toast.fail(msg);
      }
      if (data.length === 0) {
        return Toast.fail(formatMessage({ id: 'none.area.auth' }), 0, null, true);
      }
      const floors = [];
      const getData = (tempData, level = 0) => {
        for (const item of tempData) {
          if (item.areaInfos && item.areaInfos.length) {
            floors.push(item);
          }
          getData(item.children, level + 1);
        }
      };
      getData(data);
      if (floors.length === 0) {
        return Toast.fail(formatMessage({ id: 'none.area.find' }), 0, null, true);
      }

      const { floor, area } = yield select((state) => state.lighting);
      floor.list = floors;
      yield put({ type: 'setState', payload: { floor } });

      // 默认第一个楼层第一个区域数据
      floor.current = floor.list[0];
      area.list = floor.list[0].areaInfos;
      area.current = floor.list[0].areaInfos[0];
      yield put({ type: 'setState', payload: { floor, area } });
      yield put({ type: 'getEnvData' });
      if (callBack) callBack();
    },
    *login(_, { put, call }) {
      // const data = yield request('/admin/dept/tree');
      window.location.href = `/loginAuth?redirect=${encodeURIComponent(
        window.location.href.split('?')[0],
      )}`;
      yield '';
    },
    *getControlData(_, { call, put, select }) {
      const { area, envWhere } = yield select((state) => state.lighting);
      const { areaId } = area.current;
      const { data, code, msg } = yield request(`/vc/ctrl/statusforms/${areaId}`);
      if (code !== 0) {
        return Toast.fail(msg);
      }
      console.log(data);
    },
    *getEnvData(_, { call, put, select }) {
      const { id: aId } = yield select((state) => state.lighting.area.current);
      const { envWhere } = yield select((state) => state.lighting);
      // 温度
      const data = {
        aId,
        dataType: 'TEMPERATURE',
        searchType: envWhere,
        inputTime: new Date().getTime(),
        location: 'INDOOR',
      };
      const result = yield call(getReportData, data);
      if (result.code !== 0) {
        return Toast.fail(result.message);
      }
      const { currentData, nowList } = result.data;
      const { envData } = yield select((state) => state.lighting);
      envData.temperature.dataX = nowList.map((v) => v.xValue);
      envData.temperature.dataY = nowList.map((v) => v.yValue);
      envData.temperature.current = Math.abs(parseFloat(currentData).toFixed(2));
      if (envData.temperature.current >= 15 && envData.temperature.current <= 25) {
        envData.temperature.status = 0;
        envData.temperature.desc = formatMessage({ id: 'env.comfort' });
      } else if (envData.temperature.current > 25) {
        envData.temperature.status = 3;
        envData.temperature.desc = formatMessage({ id: 'env.wendu.high' });
      } else if (envData.temperature.current < 15) {
        envData.temperature.status = 2;
        envData.temperature.desc = formatMessage({ id: 'wnv.wendu.low' });
      }

      // 湿度
      const data2 = {
        aId,
        dataType: 'HUMIDITY',
        searchType: envWhere,
        inputTime: new Date().getTime(),
        location: 'INDOOR',
      };
      const result2 = yield call(getReportData, data2);
      if (result2.code !== 0) {
        return Toast.fail(result2.message);
      }
      const { currentData: currentData2, nowList: nowList2 } = result2.data;
      envData.humidity.dataX = nowList2.map((v) => v.xValue);
      envData.humidity.dataY = nowList2.map((v) => v.yValue);
      envData.humidity.current = Math.abs(parseFloat(currentData2).toFixed(2));
      if (envData.humidity.current >= 40 && envData.humidity.current <= 70) {
        envData.humidity.status = 0;
        envData.humidity.desc = formatMessage({ id: 'env.shidu.shushi' });
      } else if (envData.humidity.current > 70) {
        envData.humidity.status = 3;
        envData.humidity.desc = formatMessage({ id: 'env.shidu.chaoshi' });
      } else if (envData.humidity.current < 40) {
        envData.humidity.status = 2;
        envData.humidity.desc = formatMessage({ id: 'env.shidu.ganzao' });
      }

      // CO2
      const data3 = {
        aId,
        dataType: 'CO2',
        searchType: envWhere,
        inputTime: new Date().getTime(),
        location: 'INDOOR',
      };
      const result3 = yield call(getReportData, data3);
      if (result3.code !== 0) {
        return Toast.fail(result3.message);
      }
      const { currentData: currentData3, nowList: nowList3 } = result3.data;
      envData.co2.dataX = nowList3.map((v) => v.xValue);
      envData.co2.dataY = nowList3.map((v) => v.yValue);
      envData.co2.current = Math.abs(parseFloat(currentData3).toFixed(2));
      if (envData.co2.current <= 800) {
        envData.co2.status = 0;
        envData.co2.desc = formatMessage({ id: 'env.co2.0' });
      } else if (envData.co2.current > 800 && envData.co2.current <= 2000) {
        envData.co2.status = 1;
        envData.co2.desc = formatMessage({ id: 'env.co2.1' });
      } else if (envData.co2.current > 2000 && envData.co2.current <= 5000) {
        envData.co2.status = 2;
        envData.co2.desc = formatMessage({ id: 'env.co2.2' });
      } else {
        envData.co2.status = 3;
        envData.co2.desc = formatMessage({ id: 'env.co2.3' });
      }

      // PM2.5
      const data4 = {
        aId,
        dataType: 'PM25',
        searchType: envWhere,
        inputTime: new Date().getTime(),
        location: 'INDOOR',
      };
      const result4 = yield call(getReportData, data4);
      if (result4.code !== 0) {
        return Toast.fail(result4.message);
      }
      const { currentData: currentData4, nowList: nowList4 } = result4.data;
      envData.pm25.dataX = nowList4.map((v) => v.xValue);
      envData.pm25.dataY = nowList4.map((v) => v.yValue);
      envData.pm25.current = Math.abs(parseFloat(currentData4).toFixed(2));
      if (envData.pm25.current <= 35) {
        envData.pm25.status = 0;
        envData.pm25.desc = formatMessage({ id: 'env.pm25.0' });
      } else if (envData.pm25.current >= 36 && envData.pm25.current <= 75) {
        envData.pm25.status = 1;
        envData.pm25.desc = formatMessage({ id: 'env.pm25.1' });
      } else if (envData.pm25.current >= 76 && envData.pm25.current <= 115) {
        envData.pm25.status = 2;
        envData.pm25.desc = formatMessage({ id: 'env.pm25.2' });
      } else if (envData.pm25.current >= 116 && envData.pm25.current <= 150) {
        envData.pm25.status = 3;
        envData.pm25.desc = formatMessage({ id: 'env.pm25.3' });
      } else if (envData.pm25.current >= 151 && envData.pm25.current <= 250) {
        envData.pm25.status = 3;
        envData.pm25.desc = formatMessage({ id: 'env.pm25.3' });
      } else {
        envData.pm25.status = 3;
        envData.pm25.desc = formatMessage({ id: 'env.pm25.3' });
      }

      // VOC
      const data5 = {
        aId,
        dataType: 'VOC',
        searchType: envWhere,
        inputTime: new Date().getTime(),
        location: 'INDOOR',
      };
      const result5 = yield call(getReportData, data5);
      if (result5.code !== 0) {
        return Toast.fail(result5.message);
      }
      const { currentData: currentData5, nowList: nowList5 } = result5.data;
      envData.voc.dataX = nowList5.map((v) => v.xValue);
      envData.voc.dataY = nowList5.map((v) => v.yValue);
      envData.voc.current = Math.abs(parseFloat(currentData5).toFixed(2));
      if (envData.voc.current <= 0.5) {
        envData.voc.status = 0;
        envData.voc.desc = formatMessage({ id: 'env.voc.0' });
      } else if (envData.voc.current > 0.5 && envData.voc.current <= 1) {
        envData.voc.status = 1;
        envData.voc.desc = formatMessage({ id: 'env.voc.1' });
      } else {
        envData.voc.status = 2;
        envData.voc.desc = formatMessage({ id: 'env.voc.2' });
      }

      /* // VOC
      let data5 = {
        "aId":aId,
        "dataType":"VOC",
        "searchType":"DAY",
        "energyType":"ALL",
        "location":"INDOOR"
      }
      const result5 = yield call(getReportData, data5)
      if (result5.code!=0) {
        return Toast.fail(result5.message)
      }
      const {currentData:currentData5,nowList:nowList5} = result5.result
      envData.voc.dataX =  nowList5.map(v=>v.xValue)
      envData.voc.dataY =  nowList5.map(v=>v.yValue)
      envData.voc.current =  Math.abs(parseFloat(currentData5).toFixed(2)) */

      for (const key in envData) {
        envData[key].current = isNaN(envData[key].current) ? '--' : envData[key].current;
        envData[key].status = isNaN(envData[key].current) ? '--' : envData[key].status;
        envData[key].desc = isNaN(envData[key].current) ? '--' : envData[key].desc;
      }

      yield put({ type: 'setState', payload: { envData } });
    },
    *getEnergyData(_, { call, put, select }) {
      const { id: aId } = yield select((state) => state.lighting.area.current);
      const { envWhere } = yield select((state) => state.lighting);
      // 照明能耗
      const data = {
        aId,
        dataType: 'LIGHTENERGY',
        searchType: envWhere,
        inputTime: new Date().getTime(),
        location: 'INDOOR',
      };
      const result = yield call(getReportData, data);
      if (result.code !== 0) {
        return Toast.fail(result.message);
      }
      const { currentData, nowList } = result.data;
      const { energy } = yield select((state) => state.lighting);
      energy.light.dataX = nowList.map((v) => v.xValue);
      energy.light.dataY = nowList.map((v) => v.yValue);
      energy.light.current = Math.abs(parseFloat(currentData).toFixed(2));

      // 照明能耗
      const data2 = {
        aId,
        dataType: 'AIRENERGY',
        searchType: envWhere,
        inputTime: new Date().getTime(),
        location: 'INDOOR',
      };
      const result2 = yield call(getReportData, data2);
      if (result2.code !== 0) {
        return Toast.fail(result2.message);
      }
      const { currentData: currentData2, nowList: nowList2 } = result.data;
      energy.air.dataX = nowList2.map((v) => v.xValue);
      energy.air.dataY = nowList2.map((v) => v.yValue);
      energy.air.current = Math.abs(parseFloat(currentData2).toFixed(2));

      yield put({ type: 'setState', payload: { energy } });
    },
  },
  subscriptions: {
    set({ dispatch, history: umiHistory }) {
      return umiHistory.listen((location) => {
        const { pathname, search } = location;
        if (pathname.startsWith('/lighting')) {
          dispatch({ type: 'fetchCurrentUserInfo' });

          if (pathname.indexOf('/lighting') > -1) {
            sessionStorage.setItem('fromPage', '/');
          }

          if (pathname.indexOf('control') > -1) {
            if (sessionStorage.getItem('fromPage') === '/') {
              if (!getUrlParam('area')) {
                dispatch({
                  type: 'lightingControl/getAllStatus',
                  callBack: () => {
                    dispatch({ type: 'lightingControl/init', pathname, search, dispatch });
                  },
                });
              }
            } else {
              // 这段代码暂时无用
              dispatch({ type: 'lighting/getAuth' }).then(() => {
                dispatch({
                  type: 'lightingControl/getAllStatus',
                  callBack: () => {
                    dispatch({ type: 'lightingControl/init', pathname, search, dispatch });
                  },
                });
              });
            }
          }
          console.log('init ok');
        }
      });
    },
  },
};
