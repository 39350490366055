export default {
  'meeting.room.fee.name': '会议室名称',
  'meeting.room.fee.location': '地点',
  'meeting.room.fee.time': '使用时间',
  'meeting.room.fee.payer': '代付人',
  'meeting.room.fee.payer.unset': '未指定',
  'meeting.room.fee.unit.price': '单价',
  'meeting.room.fee.unit0': ' /小时',
  'meeting.room.fee.unit1': ' /平米',
};
