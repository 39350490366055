import { matchPath } from 'umi';
import { MENU_PATH } from '@/pages/Meeting/utils/constant';
import { RES_CODE } from '@/utils/constant';
import getAgendaList from './service';

export default {
  namespace: 'meetingAgenda',
  state: {
    list: [],
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getMeetingAgenda({ meetingId }, { call, put }) {
      const { data, code } = yield call(getAgendaList, { meetingId });
      if (code === RES_CODE.success && data) {
        yield put({ type: 'update', payload: { list: data, meetingId } });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.meetingAgenda)) {
          const { query } = history.location;
          const { meetingId } = query || {};
          dispatch({ type: 'getMeetingAgenda', meetingId });
        }
      });
    },
  },
};
