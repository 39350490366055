

// /spaceDevice/getRelations

import request from '@/utils/request';
import {RESTFUL_PATH} from "@/utils/constant";
import url from "url";

/**
 * 查询子设备
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getRelations({ deviceNumber }) {
  return request(
    `/${RESTFUL_PATH.vap}/spaceDevice/getRelations${url.format({
      query:{deviceNumber}
    })}`
  );
}

//批量获取最后一次数据
export async function getMultipleDeviceLastTelemetry(data) {
  return request(`/${RESTFUL_PATH.vap}/dashBoard/getMultipleDeviceLastTelemetry${url.format({
    query:data
  })}`,{
    method: 'get'
  });
}



//获取智能设备自动化相关模型
export async function getDeviceDataModel({ typeCode, identifier }) {
  return request(`/${RESTFUL_PATH.vap}/spaceDevice/getDeviceDataModel/${typeCode}/${identifier}`);
}


/**
 * 获取空间树
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSpaceTree() {
  return request(`/${RESTFUL_PATH.map}/map/space-tree`, {
    method: 'GET',
  });
}


/**
 * 获取地图元素
 * @param mapId
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getEleIdsByMapId(mapId) {
  return request(`/${RESTFUL_PATH.map}/map-element/list?mapId=${mapId}`, {
    method: 'GET',
  });
}

export async function getdeviceTypeList() {
  return request(`/${RESTFUL_PATH.map}/object-model/deviceTypeList`);
}

export async function getMapsBySpaceId(spaceId) {
  return request(`/${RESTFUL_PATH.map}/resource-manager/mapsBySpaceId?spaceId=${spaceId}`);
}


/**
 * 获取素材列表
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSvgType() {
  return request(`/${RESTFUL_PATH.map}/svg-type/list?`, {
    method: 'GET',
  });
}

// 所有设备的物模型
export async function getSmartDeviceDataModel() {
  return request(`/${RESTFUL_PATH.vap}/spaceDevice/getSmartDeviceDataModel`);
}


// 所有设备相关模型
export async function getDataModel() {
  return request(`/${RESTFUL_PATH.vap}/dashBoard/getDataModel`);
}


/**
 * 设备控制测试
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function exdeviceControl({ deviceNumber, body }) {
  return request(
    `/${RESTFUL_PATH.vap}/spaceDevice/deviceControl/${deviceNumber}`,
    {
      method:"post",
      body:body
    }
  );
}


//获取设备最后一次遥测数据
export async function getDeviceLastTelemetry(deviceNumber) {
  return request(
    `/${RESTFUL_PATH.vap}/dashBoard/getDeviceLastTelemetry?deviceNumber=${deviceNumber}`,
  );
}


export async function getMapInfo(payload) {
  const url = `/${RESTFUL_PATH.map}/map/${payload}`;
  return request(url, {
    method: 'GET',
  });
}


