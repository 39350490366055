import { useRef, useEffect } from 'react';
import equal from 'fast-deep-equal';
import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';
import { formatMessage, getLocale } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { useRequest as useHooksRequest } from 'ahooks';
import mEnUS from 'antd-mobile-v2/lib/date-picker/locale/en_US';
import mZhCN from 'antd-mobile-v2/lib/date-picker/locale/zh_CN';
import { CHINESE_HK, ENGLISH, MEETING_TYPE, CHINESE, XroomsTypeColor, ROLES_KEY } from './constant';
import { ACCESS_TOKEN } from '@/utils/constant';
import receipt0 from '@/pages/Meeting/assets/meeting_detail/receipt0.svg';
import receipt1 from '@/pages/Meeting/assets/meeting_detail/receipt1.svg';
import receipt2 from '@/pages/Meeting/assets/meeting_detail/receipt2.svg';

export function encryptAes(data, aesKey) {
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const iv = CryptoJS.enc.Utf8.parse(aesKey);
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString(); // 返回的是base64格式的密文
}

export function handleDeptTree(treeDatas = []) {
  return treeDatas.map((data) => {
    const d = { ...data, ...{ key: data.path, title: data.name } };
    if (d.children.length > 0) {
      d.children = handleDeptTree(d.children);
    }
    return d;
  });
}

export function daysPickerScroll() {
  // 定位到选中的日期
  const ulObjD = document.getElementById('day-picker');
  if (ulObjD) {
    const ulLengthD = ulObjD.scrollWidth;
    const liArrayD = ulObjD.getElementsByTagName('li');
    for (let i = 0; i < liArrayD.length; i += 1) {
      // i的值就是选中日期之前的天数
      if (liArrayD[i].innerHTML.indexOf('selected') > 0) {
        // 每个li的宽度
        const liWidthD = ulLengthD / liArrayD.length;
        // ul要横向滚动的宽度
        ulObjD.scrollLeft = liWidthD * i;
        break;
      }
    }
  }
}

export function timeDisplayerScroll(searchTIme) {
  // 定位到当前时间
  const now = moment();
  const nowHour = now.hour();
  const roomPlans = document.getElementsByName('room-plan-list');
  for (let j = 0; j < roomPlans.length; j += 1) {
    const ulObjR = roomPlans[j]; // 获取当前roomplan对象
    // const ulLengthR = ulObjR.scrollWidth; // 获取宽度
    const liArrayR = ulObjR.getElementsByTagName('li'); //  获取时间块个数
    const ulLengthR2 = liArrayR.length * liArrayR[0]?.offsetWidth; // 获取宽度（方法2 同ulLengthR）
    const firstHour = liArrayR[0] ? parseInt(liArrayR[0].children[0].innerText, 10) : 0; // 获取可选最小开始时间
    // const liWidthR = ulLengthR / liArrayR.length; // 获取1H的宽度
    const liWidthR2 = ulLengthR2 / liArrayR.length; // 同上 1H的宽度
    const curHour = searchTIme ? moment(searchTIme).hour() : nowHour; // 获取当前时间（有筛选则跟着筛选走）
    if (curHour >= firstHour) {
      // ul要横向滚动的宽度
      ulObjR.scrollLeft = liWidthR2 * (curHour - firstHour);
    } else {
      ulObjR.scrollLeft = 0;
    }
  }
}

// export function allCmmaRoomList(zones) {
//   // 先检查区域中是否存在会议室
//   if (zones) {
//     zones.map(zone => {
//       zone.hasRoom = false;
//       zone.child &&
//         zone.child.map(floor => {
//           if (floor.rooms && floor.rooms.length > 0) {
//             zone.hasRoom = true;
//           }
//         });
//     });
//   }
//
//   // 组装数据
//   const roomTree = [];
//   zones &&
//     zones.map(zone => {
//       if (zone.hasRoom) {
//         const floors = [];
//         zone.child &&
//           zone.child.map(floor => {
//             const rooms = [];
//             floor.rooms &&
//               floor.rooms.map(room => {
//                 rooms.push({
//                   label: room.name,
//                   value: room.cmmaRoomId,
//                   exists: false,
//                 });
//               });
//             floors.push({
//               label: floor.name,
//               value: floor.id,
//               rooms,
//             });
//           });
//         roomTree.push({
//           label: zone.name,
//           value: zone.id,
//           floors,
//         });
//       }
//     });
//
//   return roomTree;
// }

/**
 * 部分浏览器（ios）在页面加载完成后就不监听document.title的change事件了
 * 修改title之后，给页面加上一个内容为空的iframe，随后立即删除这个iframe，这时候会刷新title
 * @param title
 */
export const changeHtmlTitle = (title) => {
  document.title = title;
  const i = document.createElement('iframe');
  // i.src = "https://www.baidu.com/favicon.ico";
  i.style.display = 'none';
  i.onload = () => {
    setTimeout(() => {
      i.remove();
    }, 10);
  };
  document.body.appendChild(i);
};

/**
 * 获取meeting type，带国际化
 * @param type
 * @param rrule
 * @return {string}
 */
export const getMeetingTypeIntlKey = (type) => {
  switch (type) {
    case MEETING_TYPE.cycleDay:
      return 'home.audit.meetingMode.day';
    case MEETING_TYPE.cycleWorkDay:
      return 'home.audit.meetingMode.workday';
    case MEETING_TYPE.cycleWeek:
      return 'home.audit.meetingMode.week';
    case MEETING_TYPE.cycleMonth:
      return 'home.audit.meetingMode.month';
    case MEETING_TYPE.cycleYear:
      return 'home.audit.meetingMode.year';
    default:
      return 'home.audit.meetingMode.normal';
  }
};

// 获取URL地址的参数值
export const getUrlParam = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = decodeURIComponent(window.location.search).substr(1).match(reg);
  if (r != null) return r[2];
  return null;
};
/**
 * 用途：js中字符串超长作固定长度加省略号（...）处理
 * 参数说明：
 * str:需要进行处理的字符串，可含汉字
 * len:需要显示多少个汉字，两个英文字母相当于一个汉字。
 */
export const beautySubStr = (str, len) => {
  const reg = /[\u4e00-\u9fa5]/g; // 专业匹配中文
  const slice = str.substring(0, len);
  const chineseCharNum = slice.match(reg) && slice.match(reg).length.toFixed(0);
  const realer = slice.length * 2 - chineseCharNum;
  return str.substr(0, realer) + (realer < str.length ? '...' : '');
};

export const getTimePickerData = (type, isToday, beginDate) => {
  // window.console.log(type, '0开始 1 结束', isToday, '是否今天', beginDate, '最大开始时间')
  const step = 15;
  const group = 60 / step; // 计算每小时被分成几组
  const len = (60 / step) * 24; // 计算遍历的长度
  // const y = Number(moment().get('year'));
  // const month = Number(moment().get('month'));
  // const d = Number(moment().get('date'));
  const timeArr = [];
  for (let i = 0; i < len; i += 1) {
    const h = Math.floor(i / group);
    let m = Math.floor((i % group) * step);
    timeArr.push({
      label: `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`,
      value: `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`,
      // value: moment({ year: y, month: month, date: d, hour: h, minute: m, second: 0 }),
    });
  }
  if (isToday) {
    return [timeArr.filter((item) => item.value >= beginDate)];
  }
  return [timeArr];
};
/**
 * 获取国际化月字符串
 * @param month
 * @param locale
 * @returns {string}
 */
export const getMonthStr = (month, locale) => {
  let monthStr = `${month}月`;
  if (locale.startsWith('en')) {
    const monthsArr = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    monthStr = monthsArr[month - 1];
  }
  return monthStr;
};

export const isMobile = () =>
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
  );

/**
 * @desc moment 转 js 标准时间
 * @param moment
 * @returns {Date}
 */
export const toDateWithZone = (momentDate) => {
  const curTimeZone = sessionStorage.getItem('curTimeZone');
  const offsetGMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const timezone = window.TIMEZONE?.find((e) => e.key === curTimeZone);
  if (timezone) {
    return new Date(
      momentDate.valueOf() + offsetGMT * 60 * 1000 + timezone.timezone * 60 * 60 * 1000,
    );
  }
  return new Date(momentDate.valueOf());
};

/**
 *
 * @param date
 * @returns {*|moment.Moment}
 */
export const toMomentWithZone = (date) => {
  const curTimeZone = sessionStorage.getItem('curTimeZone');
  const offsetGMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const timezone = window.TIMEZONE?.find((e) => e.key === curTimeZone);

  if (timezone) {
    const difference = offsetGMT * 60 * 1000 + timezone.timezone * 60 * 60 * 1000;
    return moment(date.getTime() - difference);
  }
  return moment(date.getTime());
};

export const toShowTime = (startTime, endTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const month = start.format('MM');
  const day = start.format('DD');
  const st = `${start.format('HH')}:${start.format('mm')}`;
  const et = `${end.format('HH')}:${end.format('mm')}`;
  const lt = Math.round((end - start) / (1000 * 60));
  const endDay = end.format('DD');
  const endMonth = end.format('MM');
  const isLongTerm = month + day !== endMonth + endDay;
  let timeStr = '';
  if (startTime && endTime) {
    if (isLongTerm) {
      timeStr = `${month}月${day}日 ${st} ~ ${endMonth}月${endDay}日 ${et}`;
      if (getLocale() === ENGLISH) {
        timeStr = `${day}/${month} ${st} ~ ${endDay}/${endMonth} ${et}`;
      } else if (getLocale() === CHINESE_HK) {
        timeStr = `${month}月${day}日 ${st} ~ ${endMonth}月${endDay}日 ${et}`;
      }
    } else {
      timeStr = `${month}月${day}日 ${st} ~ ${et} (${lt}分钟)`;
      if (getLocale() === ENGLISH) {
        timeStr = `${day}/${month} ${st} ~ ${et} (${lt} mins)`;
      } else if (getLocale() === CHINESE_HK) {
        timeStr = `${month}月${day}日 ${st} ~ ${et} (${lt}分鐘)`;
      }
    }
  }
  return timeStr;
};

//- 小写数字转换成大写, 只处理到[0 ~ 99]
export function numberConvertToUppercase() {
  return function (num) {
    // eslint-disable-next-line no-param-reassign
    num = Number(num);
    var upperCaseNumber = [
      '零',
      '一',
      '二',
      '三',
      '四',
      '五',
      '六',
      '七',
      '八',
      '九',
      '十',
      '百',
      '千',
      '万',
      '亿',
    ];
    var length = String(num).length;
    if (length == 1) {
      return upperCaseNumber[num];
    } else if (length == 2) {
      if (num == 10) {
        return upperCaseNumber[num];
      } else if (num > 10 && num < 20) {
        return '十' + upperCaseNumber[String(num).charAt(1)];
      } else {
        return (
          upperCaseNumber[String(num).charAt(0)] +
          '十' +
          upperCaseNumber[String(num).charAt(1)].replace('零', '')
        );
      }
    }
  };
}

export const hasRepeatFn = (date) => {
  // 判断多个议程时间是否重叠 , true有重叠
  const dateAr = [];
  date.forEach((item) => {
    const { startTime = '', endTime = '', startDate = '', endDate = '' } = item;
    const param = {
      startTime: item.crossDay
        ? moment(startDate)
            .hour(moment(startTime).hour())
            .minute(moment(startTime).minute())
            .valueOf()
        : startTime,
      endTime: item.crossDay
        ? moment(endDate).hour(moment(endTime).hour()).minute(moment(endTime).minute()).valueOf()
        : endTime,
    };
    dateAr.push(param);
  });
  for (let k in dateAr) {
    if (!judege(k, dateAr)) {
      return true;
    }
  }
  return false;
};

export const judege = (idx, dateAr) => {
  for (let k in dateAr) {
    if (idx !== k) {
      if (
        dateAr[k].startTime <= dateAr[idx].startTime &&
        dateAr[k].endTime > dateAr[idx].startTime
      ) {
        return false;
      }
      if (dateAr[k].startTime < dateAr[idx].endTime && dateAr[k].endTime >= dateAr[idx].endTime) {
        return false;
      }
    }
  }
  return true;
};
//生成从minNum到maxNum的随机数  0-4
export function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);

    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);

    default:
      return 0;
  }
}
export const findDepDataByName = (deptData = [], name = '') => {
  let arr = [];
  if (deptData?.length === 0) {
    return [];
  }
  deptData.forEach((item) => {
    if (item.name?.includes(name) || item.nameEn?.includes(name)) {
      arr.push({
        id: item.id,
        key: item.key,
        name: item.name,
        nameEn: item.nameEn,
        parentName: item.parentName,
        parentId: item.parentId,
        path: item.path,
        pathName: item.pathName,
        children: item.children || [],
      });
    }
    if (item.children) {
      arr = arr.concat(findDepDataByName(item.children, name));
    }
  });
  return arr;
};

export const treeByGroup = (tree, array) => {
  if (!tree || tree.length === 0) {
    return [];
  }
  for (let i = 0; i < tree.length; i++) {
    const element = tree[i];
    array.push(element);
    if (element.children?.length > 0) {
      treeByGroup(element.children, array);
    }
  }
};

export const formatName = (nameStr) => {
  const nameArr = nameStr.split(' ');
  let concatName = [];
  nameArr.forEach((item) => {
    let currentL = 0;
    concatName.forEach((item) => {
      currentL += item.length;
    });
    const allowL = 12 - currentL;
    if (currentL < 13) {
      if (item.length < allowL) {
        concatName.push(item);
      } else {
        concatName.push(item.slice(0, allowL));
      }
    }
  });
  return concatName.join(' ');
};

export const spliceName = (user) => {
  if (!user) {
    return;
  }
  var reg = /^[^\u4e00-\u9fa5]+$/; // 不包含汉字
  const { realName, realNameEn, name } = user;
  const isEnglish = getLocale() === ENGLISH;

  const nameStr = realName ? (isEnglish ? realNameEn || realName : realName) : name;
  if (reg.test(nameStr)) {
    if (nameStr.length < 13) {
      return nameStr;
    } else {
      return formatName(nameStr);
    }
  }
  return nameStr;
};

export const filterName = (user, num = 2) => {
  if (!user) {
    return;
  }
  var reg = /^[^\u4e00-\u9fa5]+$/; // 不包含汉字
  // const reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");  // 只包含汉字
  const { realName, realNameEn, name } = user;
  const isEnglish = getLocale() === ENGLISH;
  let nameStr = realName ? (isEnglish ? realNameEn || realName : realName) : name;
  // 去除空格 以及特殊字符
  const pattern =
    /[`~!@#$^\-&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
  nameStr = nameStr.replace(pattern, '');
  // nameStr = nameStr.replace(/^\s*|\s*$/g, "");
  if (reg.test(nameStr)) {
    const enStr = nameStr.split(' ');
    return enStr?.length > 1
      ? `${enStr[0].slice(0, 1)}${enStr[1].slice(0, 1)}`
      : nameStr.slice(0, num);
  }
  if (nameStr.length > num) {
    return nameStr.slice(nameStr.length - num);
  }
  return nameStr;
};

export function useDeepCompareEffect(fn, deps) {
  const trigger = useRef(0);
  const prevDeps = useRef(deps);
  if (!equal(prevDeps.current, deps)) {
    trigger.current += 1;
  }
  prevDeps.current = deps;
  return useEffect(fn, [trigger.current]);
}

export const getReceipt = (receipt) => {
  let receiptstr = formatMessage({ id: 'meeting_sign_status_value_null' });
  let img = '';
  switch (Number(receipt)) {
    case -1:
      receiptstr = formatMessage({ id: 'meeting_sign_status_value_null' });
      img = '';
      break;
    case 0:
      receiptstr = formatMessage({ id: 'meeting_sign_status0' });
      img = receipt0;
      break;
    case 1:
      receiptstr = formatMessage({ id: 'meeting_sign_status1' });
      img = receipt1;
      break;
    case 2:
      receiptstr = formatMessage({ id: 'meeting_sign_status2' });
      img = receipt2;
      break;
    default:
      break;
  }
  return { receiptstr, img };
};
export function getCtzName(key) {
  let text = '';
  switch (key) {
    case 'ctzDingding':
      text = 'Dingding';
      break;
    case 'ctzFeishu':
      text = 'Feishu';
      break;
    case 'ctzTeams':
      text = 'Teams';
      break;
    case 'ctzTencent':
      text = 'Tencent';
      break;
    case 'ctzZoom':
      text = 'Zoom';
      break;
    default:
      break;
  }
  return text;
}

export const getRecurringInfo = ({
  startDate, // 开始日期
  type, // 循环规则
  freq, // 频率（每几天/工作日/周/月）
  exrule, // 周几
  mexrule, //每月第几天
  yexrule, //每月第几天
  mExruleType, // 相对月绝对月类型
  yExruleType, // 相对年类型
  weekCount, // 第几周
  week, // 第几周周几
  time, // 结束日期
  range, //重复次数
  endType, // 结束规则
  // startTime,
  // endTime,
  // from, // book 预订 detail 详情
}) => {
  const weekTypeCn = {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '日'
  }
  const weekTypeEn = {
    1: 'Mon.',
    2: 'Tue.',
    3: 'Wed.',
    4: 'Thur.',
    5: 'Fri.',
    6: 'Sat.',
    7: 'Sun.'
  }
  const weekType = ENGLISH === getLocale()? weekTypeEn: weekTypeCn
  const weekList = [
    formatMessage({ id: 'detail.loop.mon' }),
    formatMessage({ id: 'detail.loop.thu' }),
    formatMessage({ id: 'detail.loop.wed' }),
    formatMessage({ id: 'detail.loop.thr' }),
    formatMessage({ id: 'detail.loop.fri' }),
    formatMessage({ id: 'detail.loop.sat' }),
    formatMessage({ id: 'detail.loop.sun' }),
  ];
  let infoStr = '';
  if (Number(type) === 1) {
    infoStr = formatMessage({ id: 'meeting_cycle_rule_by_day' }, { freq });
  }
  if (Number(type) === 2) {
    const showWeekArr = weekList.filter((item, index) =>
      exrule?.some((eitem) => Number(eitem) === index + 1),
    );
    infoStr = formatMessage(
      { id: 'meeting_cycle_rule_by_week' },
      { freq, weekStr: showWeekArr.join('、') },
    );
  }
  if (Number(type) === 3 || Number(type) === 5) {
    let dayNum = mexrule;
    if (ENGLISH === getLocale()) {
      if (Number(dayNum) === 1 || Number(dayNum) === 21 || Number(dayNum) === 31) {
        dayNum = `${mexrule}st`;
      } else if (Number(dayNum) === 2 || Number(dayNum) === 22) {
        dayNum = `${mexrule}nd`;
      } else if (Number(dayNum) === 3 || Number(dayNum) === 23) {
        dayNum = `${mexrule}rd`;
      } else {
        dayNum = `${mexrule}th`;
      }
    }
    infoStr =
      mExruleType === '1'
        ? Number(mexrule) === 0
          ? formatMessage({ id: 'meeting_cycle_rule_by_month2' }, { freq })
          : formatMessage({ id: 'meeting_cycle_rule_by_month' }, { freq, mexrule: dayNum })
        : mExruleType === '2'
        ? formatMessage({ id: 'cycle.tab.loop.month2.week' }, { freq, weekCount: weekCount[0], week: weekType[week] })
        : formatMessage({ id: 'cycle.tab.loop.month2.week.last' }, { freq, week: weekType[week]  });
  }
  if (Number(type) === 4) {
    infoStr = formatMessage({ id: 'meeting_cycle_rule_by_weekday' }, { freq });
  }
  if (Number(type) === 7 || Number(type) === 6) {
    const month = moment(startDate).month() + 1;
    const day = moment(startDate).date();
    infoStr =
      yExruleType === '1'
        ? formatMessage({ id: 'cycle.tab.loop.year2.date' }, { freq, month, day })
        : yExruleType === '2'
        ? formatMessage({ id: 'cycle.tab.loop.year2.week' }, {freq, month, weekCount: weekCount[0], week: weekType[week]  })
        : formatMessage({ id: 'cycle.tab.loop.year2.week.last' }, { freq, month, week: weekType[week]  });
  }
  const endStrBook =
    endType === 0
      ? formatMessage({ id: 'cycle.tab.loop.text.label.date2' }, { startDate, endDate: time })
      : formatMessage({ id: 'cycle.tab.loop.text.label2' }, { startDate, count: range });
  return { infoStr, endStr: endStrBook };
};

// 根据会议室id计算重复个数
export const getRoomCnt = (arr) => {
  let arrData = arr;
  let obj = {};
  for (let i = 0; i < arrData.length; i++) {
    var item = arrData[i].roomId; // a为计算的属性,可换成b,c
    obj[item] = obj[item] + 1 || 1;
  }
  return obj;
};

export const getMonthDayArr = (date) => {
  const yearNum = moment(date).year()
  const monthNum = moment(date).month()
  const days =  new Date(yearNum, monthNum + 1, 0).getDate()
  const arr = [];
  for (let i = 1; i < days+1; i++) {
    arr.push({
      label: i,
      value: i,
    });
  }
  arr.push({
    label: formatMessage({ id: 'meeting_cycle_modal_last_day' }),
    value: 0,
  });
  return [arr];
};

export function useRequest(service, option) {
  return useHooksRequest(async function (params) {
    const result = await service(params);
    // console.log('接口请求返回：', result);
    // 判断是否自行处理异常数据
    if (result?.code !== 0) {
      // console.log('进入异常处理');
      // 如果传递了 onError则不进行错误提示
      if (!option?.onError) {
        // console.log('接口异常：', result);
        Toast.fail(formatMessage({ id: result?.msg }, { ...result?.data }));
      }
      return Promise.reject(result);
    }
    const successResult = result?.data;
    return Promise.resolve(successResult);
  }, option);
  // return result;
}

export function getNameKey(key) {
  let nameKey = 'new';
  switch (key) {
    case 0:
      nameKey = 'new';
      break;
    case 1:
      nameKey = 'history';
      break;
    case 2:
      nameKey = 'meetingApproval';
      break;
    case 3:
      nameKey = 'svcApproval';
      break;
  }
  return nameKey;
}

/**
 *  外部参会人 是否更改 对比
 * @param { } objValue
 * @param {*} othValue
 * @returns
 */
export function customizer(objValue, othValue) {
  if (
    objValue.companyName === othValue.companyName &&
    objValue.phone === othValue.phone &&
    objValue.name === othValue.name &&
    objValue.email === othValue.email
  ) {
    return true;
  }
  return false;
}

export function getRecentMinuteOf15(minute) {
  const multiple = [0, 15, 30, 45, 60];
  let recentMinute = 0;
  let min = 60;
  multiple.forEach((item) => {
    const num = item - minute;
    if (num > 0 && num < min) {
      min = num;
      recentMinute = item;
    }
  });
  return recentMinute;
}

export function checkIsNotEmpty(value) {
  return !!value && JSON.stringify(value) !== '{}';
}

export function isShareRoom(roomList = []) {
  if (roomList?.length <= 0) {
    return false;
  }
  const isShare = roomList.every((item) => item.tenantId === 1000);

  return isShare;
}
export function numeberTozhNumber(num) {
  // eslint-disable-next-line no-param-reassign
  num = Number(num);
  var upperCaseNumber = [
    '零',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
    '十',
    '百',
    '千',
    '万',
    '亿',
  ];
  var length = String(num).length;
  if (length == 1) {
    return upperCaseNumber[num];
  } else if (length == 2) {
    if (num == 10) {
      return upperCaseNumber[num];
    } else if (num > 10 && num < 20) {
      return '十' + upperCaseNumber[String(num).charAt(1)];
    } else {
      return (
        upperCaseNumber[String(num).charAt(0)] +
        '十' +
        upperCaseNumber[String(num).charAt(1)].replace('零', '')
      );
    }
  }
}

export const checkTimeLimit = (params) => {
  const {
    long,
    cycle,
    oneDayMeeting,
    enableMeetingMaxTime,
    maxTime,
    isLoop,
    cycleOneEdit,
    isLong,
    specialGroup,
    isOnlineRoom,
    specialMinDay,
    exchange = {},
    hasO365 = false, // 是否存在O365会议室
  } = params;
  // exchange,飞书，google（不论受不受期限限制）
  // 1、单日、周期、跨天：天数走自己的最大同步天数 30天
  // 2、单日、周期：分钟走本地单日和周期配置的
  // 3、在会议室下面那里有提示，显示会议室需审批
  // 4、不会在时间那里显示超过n天的需要审批
  // 5、跨天不存在最大跨度为X天

  // 显示审批----受期限且不是特殊会议室（exchange,飞书，google） （!isNoLimit&&!isOnlineRoom）
  // 不显示天数---不受期限且不是特殊会议室（exchange,飞书，google）（isNoLimit true不显示天数）
  const oneDay = checkIsNotEmpty(oneDayMeeting);
  let hasLimit = false,
    limitMinTime,
    limitMaxTime,
    limitMaxDay,
    allowReserveOutMeeting = false,
    isNoLimit = false;
  if (isLong && checkIsNotEmpty(long)) {
    const { longMeetingAllowReserveOutMeeting = 0, longMeetingCanReserveDays = 30 } = long;
    // 跨天会议
    limitMaxDay =
      isOnlineRoom && specialMinDay ? Number(specialMinDay) : Number(longMeetingCanReserveDays);
    allowReserveOutMeeting = Number(longMeetingAllowReserveOutMeeting) === 1;
    hasLimit = true;
  }
  if ((isLoop || cycleOneEdit) && checkIsNotEmpty(cycle)) {
    const {
      cycleMeetingMinDuration = 15,
      cycleMeetingMaxDuration = 180,
      cycleMeetingAllowReserveOutMeeting = 0,
      cycleMeetingMaxDays = 45,
    } = cycle;
    // 循环会议
    limitMinTime = Number(cycleMeetingMinDuration);
    limitMaxTime =
      Number(enableMeetingMaxTime) > 0 &&
      maxTime &&
      Number(maxTime) > 0 &&
      Number(maxTime) < Number(cycleMeetingMaxDuration)
        ? Number(maxTime)
        : Number(cycleMeetingMaxDuration);
    limitMaxDay =
      isOnlineRoom && specialMinDay ? Number(specialMinDay) : Number(cycleMeetingMaxDays);
    allowReserveOutMeeting = Number(cycleMeetingAllowReserveOutMeeting) === 1;
    hasLimit = true;
  }
  if (!isLong && !isLoop && oneDay && !cycleOneEdit) {
    const {
      oneDayMeetingCanReserveDays = 30,
      oneDayMeetingMaxDuration = 240,
      oneDayMeetingMinDuration = 15,
      oneDayMeetingAllowReserveOutMeeting = 0,
    } = oneDayMeeting;
    // 单日会议时长相关配置
    limitMinTime = Number(oneDayMeetingMinDuration);
    limitMaxTime =
      Number(enableMeetingMaxTime) > 0 &&
      maxTime &&
      Number(maxTime) > 0 &&
      Number(maxTime) < Number(oneDayMeetingMaxDuration)
        ? Number(maxTime)
        : Number(oneDayMeetingMaxDuration);
    limitMaxDay =
      isOnlineRoom && specialMinDay ? Number(specialMinDay) : Number(oneDayMeetingCanReserveDays);
    allowReserveOutMeeting = Number(oneDayMeetingAllowReserveOutMeeting) === 1;
    hasLimit = true;
  }
  if (checkIsNotEmpty(specialGroup) && Number(specialGroup?.noLimit) === 0 && !isOnlineRoom) {
    // 特殊预定--若勾选了不受预订期限制，则不考虑配置的最大天数及是否可预约超出（需要审批）
    isNoLimit = true;
  }
  // 9.2 功能126592
  const turnedOnO365 = checkIsNotEmpty(exchange); // 是否打开O365
  // O365关闭，走原来正常的逻辑
  const isNormal = !turnedOnO365;
  // O365打开，并且选择了O365会议室，就只走O365的天数配置
  // 如果没有选择O365会议室，则什么也不显示
  const isShowNone = turnedOnO365 && !hasO365;
  const onlyDayShow = turnedOnO365 && hasO365;
  if (onlyDayShow) {
    //  O365打开，并且选择了O365会议室,只显示O365的天数
    hasLimit = true;
    limitMaxDay = Number(specialMinDay) || 30;
  }
  if (isShowNone) {
    // O365打开并且如果没有选择O365会议室,什么都不显示，最大天数，时长都不要了，跨天的最大跨度也不要
    hasLimit = false;
  }
  return {
    hasLimit,
    limitMinTime,
    limitMaxTime,
    limitMaxDay,
    allowReserveOutMeeting,
    isNoLimit,
    isAlwaysLimit: isOnlineRoom,
    isNormal,
    isShowNone,
    onlyDayShow,
  };
};

/**
 * 会议最大最小时长，最大天数，是否审批 页面提示语
 */
export const renderTimeAndDateTip = (
  limitMaxDay,
  limitMinTime,
  limitMaxTime,
  allowReserveOutMeeting,
  type,
  cycleOneEdit,
  isNoLimit,
  isAlwaysLimit,
  onlyDayShow = false,
) => {
  return (
    <div style={{ marginTop: '10px', fontSize: '13px', color: '#F4A65C' }}>
      {/* O365打开并且O365会议室，只显示O365的天数 */}
      {/* 不受预定期限限制，不显示天数，只显示时长*/}
      {onlyDayShow
        ? formatMessage(
            { id: 'meeting.booking.step.times.cross.noCross.tip' },
            { data: limitMaxDay },
          )
        : isNoLimit
        ? formatMessage(
            { id: 'meeting.booking.step.times.noLimit.tip' },
            { limitMinTime, limitMaxTime },
          )
        : formatMessage(
            { id: 'meeting.booking.step.times.limit.tip' },
            { limitMaxDay, limitMinTime, limitMaxTime },
          )}
      {/* 允许超出，受期限，不是exchange ---需审批*/}
      {allowReserveOutMeeting && !isNoLimit && !isAlwaysLimit
        ? type !== MEETING_TYPE.normal || cycleOneEdit
          ? formatMessage({ id: 'meeting.booking.step.times.limit.cylce' }, { limitMaxDay })
          : formatMessage({ id: 'meeting.booking.step.times.limit.tip2' }, { limitMaxDay })
        : null}
    </div>
  );
};
export function getSliceArray(arr = [], num = 20) {
  const array = [];
  if (arr.length <= 20) {
    array[0] = arr;
  } else {
    for (let index = 0; index < arr.length; ) {
      //  0 20  20 40
      const element = arr.slice(index, (index += num));
      array.push(element);
    }
  }

  return array;
}
export const statusObj = {
  FREE: 'FREE', // 空闲
  BUSY: 'BUSY', // 忙碌
  UNKNOW: 'UNKNOW', // 未知
  TENTATIVE: 'TENTATIVE', // 暂定
};

export const getStatusText = (status, startTime = '', startNow = false) => {
  let text = formatMessage({ id: 'meeting.attend.user.status.UNKNOW' });
  if (!startNow && !startTime) {
    return text;
  }
  switch (status) {
    case statusObj.FREE:
      text = formatMessage({ id: 'meeting.attend.user.status.FREE' });
      break;
    case statusObj.BUSY:
      text = formatMessage({ id: 'meeting.attend.user.status.BUSY' });
      break;
    case statusObj.UNKNOW:
      text = formatMessage({ id: 'meeting.attend.user.status.UNKNOW' });
      break;
    case statusObj.TENTATIVE:
      text = formatMessage({ id: 'meeting.attend.user.status.TENTATIVE' });
      break;

    default:
      break;
  }
  return text;
};
//  判断参会人的忙闲状态ß

// export function getAttendStatus(users, userOfStatus) {
//   const newUsers = users.map((user) => {
//     const item = userOfStatus.find(statusObj => statusObj.userId === users.userId);
//     if (item) {
//       if (item.status === statusObj.BUSY || item.status === statusObj.BUSY || item.status === statusObj.BUSY) {
//         return { ...user, status: statusObj.BUSY }
//       } else if (item.status == statusObj.FREE && item.status === statusObj.FREE && item.status === statusObj.FREE) {
//         return { ...user, status: statusObj.FREE }
//       } else if (item.status === statusObj.TENTATIVE || item.status === statusObj.TENTATIVE || item.status === statusObj.TENTATIVE) {
//         return { ...user, status: statusObj.TENTATIVE }
//       } else {
//         return { ...user, status: statusObj.UNKNOW }
//       }
//     }
//     return { ...user, status: statusObj.UNKNOW }

//   })
//   return newUsers

// }

export function getAttendStatus(users, userOfStatus) {
  const newUsers = users.map((user) => {
    const item = userOfStatus.find((statusObj) => statusObj.userId === user.userId);
    if (item) {
      return { ...user, status: item.status };
    }
    return user;
  });
  return newUsers;
}

export function getRoomIdArrayOfRoomIds(roomIds) {
  if (!roomIds) {
    return [];
  }
  return Array.isArray(roomIds) ? roomIds.map((id) => +id) : roomIds.split(';').map((id) => +id);
}

export function getRoomName({ roomName, roomNameEn, roomNameTw }) {
  const lang = getLocale();
  switch (lang) {
    case CHINESE:
      return roomName;
    case ENGLISH:
      return roomNameEn;
    case CHINESE_HK:
      return roomNameTw;
    default:
      return roomName;
  }
}

export function getXoomsText(array = [], isCalendar = false) {
  const style = {
    background: '#F8FAFD',
    padding: '2px 3px',
    marginRight: '5px',
    borderRadius: '4px',
    fontSize: '10px',
    border: '1px solid #E9EEF6',
    minWidth: 0,
  };
  if (isCalendar && array.length > 1) {
    return [
      <span style={{ color: XroomsTypeColor[0], ...style }} key={0}>
        {'Rooms'}{' '}
      </span>,
    ];
  }
  if (isCalendar && array.length > 1) {
    return [
      <span style={{ color: XroomsTypeColor[0], ...style }} key={0}>
        {'Rooms'}{' '}
      </span>,
    ];
  }
  return array.map((item) => {
    let text = '';
    if (item === '0') {
      text = 'Teams Rooms';
    } else if (item === '1') {
      text = formatMessage({ id: 'meeting.xrooms.feishu' });
    } else if (item === '2') {
      text = formatMessage({ id: 'meeting.xrooms.tengxun' });
    } else if (item === '3') {
      text = 'Zoom Rooms';
    }
    return (
      <span style={{ color: XroomsTypeColor[item], ...style }} key={item}>
        {text}{' '}
      </span>
    );
  });
}
/**
 * 检查预订当前会议室是否共享会议室
 * */
export const checkIsShareRoom = (allRooms, roomIds) => {
  let isShareRoom = false;
  if (!roomIds) return false;
  const roomIdArr = roomIds.split(';');
  if (roomIds && allRooms && allRooms.length > 0) {
    allRooms.forEach((r) => {
      if (roomIdArr.indexOf(String(r.roomId)) > -1) {
        isShareRoom = r?.tenantId === 1000;
      }
    });
  }
  return isShareRoom;
};

export const localeDatePicker = () => {
  const locale = getLocale();
  let obj = mZhCN;
  switch (locale) {
    case CHINESE:
      obj = mZhCN;
      break;
    case CHINESE_HK:
      obj = {
        ...mZhCN,
        dismissText: '取消',
        okText: '確定',
      };
      break;
    case ENGLISH:
      obj = {
        ...mEnUS,
        DatePickerLocale: {
          ...mEnUS.DatePickerLocale,
          year: 'Y',
          month: 'M',
          day: 'D',
          hour: 'H',
          minute: 'min',
        },
      };
      break;
  }
  return obj;
};

export const hasReplyAuth = (meetingInfo = {}, userId, roleCode = []) => {
  const { hostId, creatorId } = meetingInfo;
  const hostArray = String(hostId).split(';');
  if (hostArray.indexOf(String(userId)) > -1 || creatorId === userId) {
    // 主持人或创建者
    return true;
  }
  if (roleCode.some((code) => code === ROLES_KEY.admin)) {
    return true;
  }
  return roleCode.some((code) => code === ROLES_KEY.meeting_admin);
};

export const getLongMaxDate = (
  isNoLimit,
  allowReserveOutMeeting,
  isAlwaysLimit,
  onlyDayShow,
  hasLimit,
  startTimeStamp,
  longStateDay, // 跨X天
  limitMaxDate, // 可预订X天内的会议
  isShowNone,
) => {
  let longMaxStartDate = '',
    longMaxEndDate = '';
  // 开始的最大日期
  const notMax = !hasLimit && isShowNone; // O365打开并且未选择O365会议室--只受提前审批的限制,无最大日期限制

  if (hasLimit) {
    if (!(!onlyDayShow && (allowReserveOutMeeting || isNoLimit) && !isAlwaysLimit)) {
      longMaxStartDate = toDateWithZone(moment(limitMaxDate));
    }
  }
  // if ((!isNoLimit && !allowReserveOutMeeting) || isAlwaysLimit||onlyDayShow) {
  //   // 受限制，不允许超出预约 或者 特殊网络会议-自己的配置
  //   longMaxStartDate = toDateWithZone(moment(limitMaxDate));
  // }
  if (notMax) {
    longMaxStartDate = '';
  }

  // 结束的最大日期
  // 原逻辑
  if ((!isNoLimit && !allowReserveOutMeeting) || isAlwaysLimit) {
    // 受期限显示,判断最大跨度X天，是否在最大可预约之前，如之前，则最大到跨度，反正最大到允许的天数
    longMaxEndDate = toDateWithZone(
      moment(startTimeStamp || moment())
        .add(longStateDay - 1, 'days')
        .isBefore(moment(limitMaxDate), 'days')
        ? moment(startTimeStamp || moment())
            .add(longStateDay - 1, 'days')
            .endOf('days')
        : moment(limitMaxDate),
    );
  } else {
    // 不受期限，可以随便选日期，最大为跨度天数
    longMaxEndDate = toDateWithZone(
      moment(startTimeStamp || moment())
        .add(longStateDay - 1, 'days')
        .endOf('days'),
    );
  }
  if (onlyDayShow) {
    // O365打开，并且选择了O365会议室，就只走O365的天数配置
    longMaxEndDate = toDateWithZone(moment(limitMaxDate));
  }

  if (isShowNone) {
    // O365打开，没有选择O365会议室，无跨天限制
    longMaxEndDate = '';
  }

  return {
    longMaxStartDate,
    longMaxEndDate,
  };
};

// CUSTOM:判断是否为微信内置浏览器
export const isInWechat = () => {
  const ua = navigator.userAgent.toLowerCase();
  return (
    // eslint-disable-next-line eqeqeq
    ua.match(/wxwork/i) == 'wxwork' ||
    // eslint-disable-next-line eqeqeq
    ua.match(/MicroMessenger/i) == 'micromessenger'
  );
};

// 特殊字符转义
export const specialAscall = (str) => {
  return str
    ?.replaceAll('%', '%25')
    .replaceAll('&', '%26')
    .replaceAll('=', '%3D')
    .replaceAll('/', '%2F')
    .replaceAll('+', '%2B')
    .replaceAll('#', '%23')
    .replaceAll('?', '%3F')
    .replaceAll('^', '%5E');
};

// 复制链接
export const copyLink = (url) => {
  const textArea = document.createElement('textarea');
  textArea.style.width = '1px';
  textArea.style.height = '1px';
  textArea.value = url;
  textArea.innerText = url;
  document.body.appendChild(textArea);
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    this.throwError(err.toString());
  }
  document.body.removeChild(textArea);
};

// 对私有图片添加token
export const urlToken = (url) => {
  let newUrl = url;
  if (url && url.includes('pri') && !url.includes('token')) {
    newUrl = `${url}?token=${sessionStorage.getItem(ACCESS_TOKEN)}`;
  }
  return newUrl;
};

// 编辑图片上传时，将token去掉
export const deleteToken = (url) => {
  let newUrl = url;
  if (url && url.includes('?token')) {
    newUrl = url.split('?token')[0];
  }
  // console.log(newUrl, 'newUrl');
  return newUrl;
};
