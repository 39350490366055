export default {
  'sws.repair.list.time': '報修時間',
  'sws.repair.list.type': '故障類型',
  'sws.repair.list.status': '報修狀態',
  'sws.repair.list.back': '取消',
  'sws.repair.list.cancel': '取消報修',
  'sws.repair.list.detail': '報修詳情',
  'sws.repair.list.pending': '待處理',
  'sws.repair.list.processing': '處理中',
  'sws.repair.list.processed': '已處理',
  'sws.repair.no.data': '暫無記錄',
  'sws.repair.success': '報修成功',
  'sws.repair.list.new': '新建報修',
  'sws.repair.list.new.title': '輸入工位編號/姓名',
  'sws.repair.list.code.scan': '掃一掃',
  'sws.repair.list.record': '報修記錄',
  'sws.repair.commit.repair': '提交報修',
  'sws.repair.fault.type.chose': '請選擇設施類型',
  'sws.repair.upload.picture.chose': '上傳照片(選填)',
  'sws.repair.upload.picture': '上傳照片',
  'sws.repair.upload.picture.limit': '最多3張，圖片格式可以為jpeg / jpg / png, 大小不超過5M',
  'sws.repair.text.comments': '文本備註(選填)',
  'sws.repair.text.no': '文本備註(無)',
  'sws.repair.fill.in.station.number': '請填寫工位編號',
  'sws.repair.station.number.none': '工位編號不存在！',
  'sws.repair.fill.in.comments': '請填寫備註',
  'sws.repair.chose.fault': '請選擇報修故障！',
  'sws.repair.picture.larger': '圖片不能大於5M',
  'sws.repair.scan.qrCode': '請掃描工位二維碼',
  'sws.repair.search.placehold': '輸入工位編號/姓名模糊查詢',
  'sws.repair.search': '查詢工位編號/姓名',
  401083: '二維碼無法識別',
};
