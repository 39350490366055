import { setLocale } from 'umi';
import { TOKEN_LOGIN_ROUTER, LOGIN_ROUTER } from '@/utils/constant';

export default {
  namespace: 'i18n',

  state: {},

  effects: {
    setLang() {
      let userLang = navigator.language ? navigator.language : navigator.userLanguage;
      if (userLang.indexOf('en') !== -1) {
        userLang = 'en-US';
      } else if (userLang.indexOf('HK') !== -1) {
        userLang = 'zh-TW';
      } else {
        userLang = 'zh-CN';
      }
      setLocale(userLang);
    },
  },

  reducers: {},

  subscriptions: {
    setup({ dispatch, history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(({ pathname }) => {
        if (
          pathname.trim().length > 0 &&
          (pathname === '/' || pathname === TOKEN_LOGIN_ROUTER || pathname === LOGIN_ROUTER)
        ) {
          dispatch({
            type: 'setLang',
          });
        }
      });
    },
  },
};
