// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/home/jenkins/workspace/virsical/h5-app/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelGlobal0 from '/home/jenkins/workspace/virsical/h5-app/src/models/global.js';
import ModelI18N1 from '/home/jenkins/workspace/virsical/h5-app/src/models/i18n.js';
import ModelLogin2 from '/home/jenkins/workspace/virsical/h5-app/src/models/login.js';
import ModelMenu3 from '/home/jenkins/workspace/virsical/h5-app/src/models/menu.js';
import ModelUser4 from '/home/jenkins/workspace/virsical/h5-app/src/models/user.js';
import ModelLighting5 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Lighting/models/lighting.js';
import ModelLightingControl6 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Lighting/models/lightingControl.js';
import ModelAttendee7 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/models/attendee.js';
import ModelMeetingContact8 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/models/meetingContact.js';
import ModelMeetingDetail9 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/models/meetingDetail.js';
import ModelMeetingManage10 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/models/meetingManage.js';
import ModelMeetingRoute11 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/models/meetingRoute.js';
import ModelShareSysModule12 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/models/shareSysModule.js';
import ModelSubscribe13 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/models/subscribe.js';
import ModelSysModule14 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/models/sysModule.js';
import ModelZones15 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/models/zones.js';
import ModelApp16 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/models/app.js';
import ModelAppLoadData17 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/models/appLoadData.js';
import ModelPhoneContacts18 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/models/phoneContacts.js';
import ModelVirsical19 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Visitor/models/virsical.js';
import ModelModel20 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Access/AccessFace/model.js';
import ModelModel21 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Access/AccessQrCode/model.js';
import ModelModel22 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Access/ApplyAccess/model.js';
import ModelModel23 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Access/FaceAudit/model.js';
import ModelModel24 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Access/FaultRepairAccess/model.js';
import ModelModel25 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyAccess/model.js';
import ModelModel26 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Access/MyFaultRepair/model.js';
import ModelModel27 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/Env/BasicEdition/model.js';
import ModelModel28 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/Env/PremiumEdition/model.js';
import ModelModel29 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/PrivacyAndService/model.js';
import ModelModel30 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Cloud/Register/model.js';
import ModelModel31 from '/home/jenkins/workspace/virsical/h5-app/src/pages/CloudHubAuth/model.js';
import ModelModel32 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Feedback/model.js';
import ModelModel33 from '/home/jenkins/workspace/virsical/h5-app/src/pages/IndexMap/model.js';
import ModelModel34 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/components/MapRoomDetail/model.js';
import ModelModel35 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Agenda/model.js';
import ModelModel36 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/Feedback/model.js';
import ModelModel37 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/HomePage/model.js';
import ModelModel38 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingCmma/model.js';
import ModelModel39 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MeetingSeat/model.js';
import ModelModel40 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyInfo/Page2/model.js';
import ModelModel41 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/MyMeeting/model.js';
import ModelModel42 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomDetail/model.js';
import ModelModel43 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/RoomFeeDetail/model.js';
import ModelModel44 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ScanCodePage/model.js';
import ModelModel45 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcApprovalDetail/model.js';
import ModelModel46 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/SvcFeeDetail/model.js';
import ModelModel47 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/VskJump/model.js';
import ModelModel48 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Meeting/pages/ZoomParticipants/model.js';
import ModelModel49 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Points/Account/model.js';
import ModelModel50 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Points/Bill/model.js';
import ModelModel51 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Points/Punishment/model.js';
import ModelModel52 from '/home/jenkins/workspace/virsical/h5-app/src/pages/ScanAuth/model.js';
import ModelModel53 from '/home/jenkins/workspace/virsical/h5-app/src/pages/ScanAuthPage/model.js';
import ModelModel54 from '/home/jenkins/workspace/virsical/h5-app/src/pages/ScanQRCode/model.js';
import ModelModel55 from '/home/jenkins/workspace/virsical/h5-app/src/pages/SetDefaultZone/model.js';
import ModelModel56 from '/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Immediate/model.js';
import ModelModel57 from '/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerBook/model.js';
import ModelModel58 from '/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/LockerMyBooking/model.js';
import ModelModel59 from '/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/model.js';
import ModelModel60 from '/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/OpenDoor/model.js';
import ModelModel61 from '/home/jenkins/workspace/virsical/h5-app/src/pages/SmartLocker/Scan/model.js';
import ModelModel62 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Tcs/model.js';
import ModelModel63 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Vap/pages/Map/model.js';
import ModelModel64 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Vap/pages/MeetingControl/model.js';
import ModelModel65 from '/home/jenkins/workspace/virsical/h5-app/src/pages/Vap/pages/model.js';
import ModelModel66 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WaferTokenLogin/model.js';
import ModelModel67 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/CloudHub/model.js';
import ModelModel68 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/Dingding/model.js';
import ModelModel69 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/FeiShu/model.js';
import ModelModel70 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/WeChat/model.js';
import ModelModel71 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WdfClient/WechatAuth/model.js';
import ModelModel72 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/DashboardNew/model.js';
import ModelModel73 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Delay/model.js';
import ModelModel74 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/model.js';
import ModelModel75 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Scan/model.js';
import ModelModel76 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Booking/model.js';
import ModelModel77 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/BookingNew/model.js';
import ModelModel78 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Delay/model.js';
import ModelModel79 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Detail/model.js';
import ModelModel80 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FacilityManage/model.js';
import ModelModel81 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/FindStation/model.js';
import ModelModel82 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Immediate/model.js';
import ModelModel83 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Inventory/model.js';
import ModelModel84 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/InventoryList/model.js';
import ModelModel85 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/LiftTable/model.js';
import ModelModel86 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/MyBooking/model.js';
import ModelModel87 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/MyBookingNew/model.js';
import ModelModel88 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/newInventory/model.js';
import ModelModel89 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RecommendBooking/model.js';
import ModelModel90 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Repair/model.js';
import ModelModel91 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/RepairManage/model.js';
import ModelModel92 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Search/model.js';
import ModelModel93 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/SearchNew/model.js';
import ModelModel94 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Sensor/model.js';
import ModelModel95 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/SignOut/model.js';
import ModelModel96 from '/home/jenkins/workspace/virsical/h5-app/src/pages/WorkSpace/Station/Special/model.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'global', ...ModelGlobal0 });
app.model({ namespace: 'i18n', ...ModelI18N1 });
app.model({ namespace: 'login', ...ModelLogin2 });
app.model({ namespace: 'menu', ...ModelMenu3 });
app.model({ namespace: 'user', ...ModelUser4 });
app.model({ namespace: 'lighting', ...ModelLighting5 });
app.model({ namespace: 'lightingControl', ...ModelLightingControl6 });
app.model({ namespace: 'attendee', ...ModelAttendee7 });
app.model({ namespace: 'meetingContact', ...ModelMeetingContact8 });
app.model({ namespace: 'meetingDetail', ...ModelMeetingDetail9 });
app.model({ namespace: 'meetingManage', ...ModelMeetingManage10 });
app.model({ namespace: 'meetingRoute', ...ModelMeetingRoute11 });
app.model({ namespace: 'shareSysModule', ...ModelShareSysModule12 });
app.model({ namespace: 'subscribe', ...ModelSubscribe13 });
app.model({ namespace: 'sysModule', ...ModelSysModule14 });
app.model({ namespace: 'zones', ...ModelZones15 });
app.model({ namespace: 'app', ...ModelApp16 });
app.model({ namespace: 'appLoadData', ...ModelAppLoadData17 });
app.model({ namespace: 'phoneContacts', ...ModelPhoneContacts18 });
app.model({ namespace: 'virsical', ...ModelVirsical19 });
app.model({ namespace: 'model', ...ModelModel20 });
app.model({ namespace: 'model', ...ModelModel21 });
app.model({ namespace: 'model', ...ModelModel22 });
app.model({ namespace: 'model', ...ModelModel23 });
app.model({ namespace: 'model', ...ModelModel24 });
app.model({ namespace: 'model', ...ModelModel25 });
app.model({ namespace: 'model', ...ModelModel26 });
app.model({ namespace: 'model', ...ModelModel27 });
app.model({ namespace: 'model', ...ModelModel28 });
app.model({ namespace: 'model', ...ModelModel29 });
app.model({ namespace: 'model', ...ModelModel30 });
app.model({ namespace: 'model', ...ModelModel31 });
app.model({ namespace: 'model', ...ModelModel32 });
app.model({ namespace: 'model', ...ModelModel33 });
app.model({ namespace: 'model', ...ModelModel34 });
app.model({ namespace: 'model', ...ModelModel35 });
app.model({ namespace: 'model', ...ModelModel36 });
app.model({ namespace: 'model', ...ModelModel37 });
app.model({ namespace: 'model', ...ModelModel38 });
app.model({ namespace: 'model', ...ModelModel39 });
app.model({ namespace: 'model', ...ModelModel40 });
app.model({ namespace: 'model', ...ModelModel41 });
app.model({ namespace: 'model', ...ModelModel42 });
app.model({ namespace: 'model', ...ModelModel43 });
app.model({ namespace: 'model', ...ModelModel44 });
app.model({ namespace: 'model', ...ModelModel45 });
app.model({ namespace: 'model', ...ModelModel46 });
app.model({ namespace: 'model', ...ModelModel47 });
app.model({ namespace: 'model', ...ModelModel48 });
app.model({ namespace: 'model', ...ModelModel49 });
app.model({ namespace: 'model', ...ModelModel50 });
app.model({ namespace: 'model', ...ModelModel51 });
app.model({ namespace: 'model', ...ModelModel52 });
app.model({ namespace: 'model', ...ModelModel53 });
app.model({ namespace: 'model', ...ModelModel54 });
app.model({ namespace: 'model', ...ModelModel55 });
app.model({ namespace: 'model', ...ModelModel56 });
app.model({ namespace: 'model', ...ModelModel57 });
app.model({ namespace: 'model', ...ModelModel58 });
app.model({ namespace: 'model', ...ModelModel59 });
app.model({ namespace: 'model', ...ModelModel60 });
app.model({ namespace: 'model', ...ModelModel61 });
app.model({ namespace: 'model', ...ModelModel62 });
app.model({ namespace: 'model', ...ModelModel63 });
app.model({ namespace: 'model', ...ModelModel64 });
app.model({ namespace: 'model', ...ModelModel65 });
app.model({ namespace: 'model', ...ModelModel66 });
app.model({ namespace: 'model', ...ModelModel67 });
app.model({ namespace: 'model', ...ModelModel68 });
app.model({ namespace: 'model', ...ModelModel69 });
app.model({ namespace: 'model', ...ModelModel70 });
app.model({ namespace: 'model', ...ModelModel71 });
app.model({ namespace: 'model', ...ModelModel72 });
app.model({ namespace: 'model', ...ModelModel73 });
app.model({ namespace: 'model', ...ModelModel74 });
app.model({ namespace: 'model', ...ModelModel75 });
app.model({ namespace: 'model', ...ModelModel76 });
app.model({ namespace: 'model', ...ModelModel77 });
app.model({ namespace: 'model', ...ModelModel78 });
app.model({ namespace: 'model', ...ModelModel79 });
app.model({ namespace: 'model', ...ModelModel80 });
app.model({ namespace: 'model', ...ModelModel81 });
app.model({ namespace: 'model', ...ModelModel82 });
app.model({ namespace: 'model', ...ModelModel83 });
app.model({ namespace: 'model', ...ModelModel84 });
app.model({ namespace: 'model', ...ModelModel85 });
app.model({ namespace: 'model', ...ModelModel86 });
app.model({ namespace: 'model', ...ModelModel87 });
app.model({ namespace: 'model', ...ModelModel88 });
app.model({ namespace: 'model', ...ModelModel89 });
app.model({ namespace: 'model', ...ModelModel90 });
app.model({ namespace: 'model', ...ModelModel91 });
app.model({ namespace: 'model', ...ModelModel92 });
app.model({ namespace: 'model', ...ModelModel93 });
app.model({ namespace: 'model', ...ModelModel94 });
app.model({ namespace: 'model', ...ModelModel95 });
app.model({ namespace: 'model', ...ModelModel96 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
