/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'app.setSetting.lang': '設置語言',
  'app.setSetting.timezone': '設置時區',
  'app.setSetting.zone': '默認區域',
  zh_CN: '简体中文',
  zh_TW: '繁體中文',
  en_US: 'English',
  'Asia/Shanghai': '中國時間',
  'Asia/Tokyo': '日本時間',
  'Asia/Seoul': '韓國時間',
  'Asia/Singapore': '新加坡時間',
  'Australia/Sydney': '東部時間 (新南威爾斯)',
  'Europe/London': '英國時間',
  'Europe/Paris': '中歐時間',
  'Europe/Moscow': '莫斯科時間',
  'America/New_York': '美國東部時間',
  'America/Chicago': '美國中部時間',
  'America/Denver': '美國山區時間',
  'America/Los_Angeles': '太平洋時間',
  'America/Anchorage': '阿拉斯加時間',
  'Pacific/Honolulu': '夏威夷時間',
};
