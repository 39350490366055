export default {
  'register.register': '註冊',
  'register.bind': '綁定',
  'register.bind.tip': '請綁定您在威思客雲的賬號',
  'register.verify.phone': '手機驗證',
  'register.verify.mail': '郵箱驗證',
  'register.password.rules': '密碼必須包含8到16位數字及字母，可包含如下特殊字符：.!@#$%^&*-_）',
  'register.protocol.tip1': '我已閱讀並同意威思客雲的',
  'register.protocol.tip2': '《服務條款》',
  'register.protocol.tip3': '和',
  'register.protocol.tip4': '《隱私政策》',
  'register.verifycode.get': '獲取驗證碼',
  'register.verifycode.reget': '重新獲取',
  'register.verifycode.expired': '驗證碼過期',
  'register.verifycode.nouser': '沒有此用戶',
  'register.verifycode.fail': '發送失敗',
  'register.second': '秒',
  'register.ok': '確定',
  'register.cancel': '取消',
  'register.bind.success': '綁定成功',
  'register.register.success': '註冊成功',

  'register.tenantName.tip': '請輸入公司名稱',
  'register.contactRealName.tip': '請輸入聯系人姓名',
  'register.phone.tip': '請輸入聯系人手機',
  'register.contactMail.tip': '請輸入聯系人郵箱',
  'register.verifyType.tip': '請選擇驗證類型',
  'register.captcha.tip': '請輸入驗證碼',
  'register.password.tip': '請輸入密碼',
  'register.pactCheck.tip': '請閱讀服務條款和隱私政策',
  'register.bindAccout.tip': '請輸入賬號',
  'register.bindPwd.tip': '請輸入密碼',
  'info.form.phone.placeholder': '請輸入手機號碼',

  'register.tenantName.error': '公司長度不得超過50個字符',
  'register.contactRealName.error': '姓名長度為2-20個字符',
  'register.phone.error': '請輸入正確的手機號',
  'register.contactMail.error': '請輸入有效的郵箱地址',
  'register.captcha.error': '請輸入正確的驗證碼',
  'register.password.error': '密碼格式不正確',
  'register.bindAccout.error': '郵箱/手機號格式不正確',

  'register.title': '您的企業註冊了嗎？',
  'register.title.tip': '如果您的企業還沒有在威思客雲註冊，請創建新的企業',
  'register.company.name': '企業名稱',
  'register.mail': '郵箱',
  'register.mobile': '手機',
  'register.accout.exist': '已有賬戶？',
  'register.realname': '姓名',

  'register.try.use': '註冊立即獲得30天的免費試用',
  'register.try.more': '更多威思客雲高級版本',
  'register.hotline': '現在就撥打咨詢熱線：400-685-3160',
  'register.success.tip1': '完成註冊時，系統將開通',
  'register.success.tip2': '服務，您將擁有管理員權限，請先在管理端維護',
  'register.success.tip3': '基礎數據，管理端地址：',

  'cloud.productcode.smt': '會議',
  'cloud.productcode.smt.room': '會議室',
  'cloud.productcode.sws': '工位',

  'cloud.auth.fail.tip': '系統出現未知錯誤，請稍後再試',

  'cloud.register.bind': '返回',
  'cloud.gobind': '去綁定',

  'cloud.safe.verify.code': '獲取驗證碼',
  'cloud.safe.verify.code.tip': '請輸入驗證碼',
  'order.invoice.emil.tip': '請輸入郵箱',
  'register.verification.img': '請輸入圖形驗證碼',

  'login.security.code.tip': '請輸入安全碼',
  'login.security.step': '獲取步驟',
  'login.security.step1': '1. 打開您設備(手機、平板、電腦等)上的雙因素認證應用，推薦使用',
  'login.security.step2': '2. 找到您之前掃描並綁定的賬號',
  'login.security.step3': '3. 獲取顯示的密碼代碼，填入下方的輸入框',

  'cloud.country.areacode.china': '(中國)',
  'cloud.country.areacode.hk': '(中國香港)',
  'cloud.country.areacode.korea': '(韓國)',
  'cloud.country.areacode.singapore': '(新加坡)',
  'cloud.country.areacode.thailand': '(泰國)',
  'cloud.country.areacode.usa': '(美國)',
};
