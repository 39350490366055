/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.myFaultRepair.time.current': '今天',
  'access.myFaultRepair.state.Processed': '已处理',
  'access.myFaultRepair.state.confirmed': '已确认',
  'access.myFaultRepair.state.Unprocessed': '未处理',
  'access.myFaultRepair.access.name': '门禁名称:',
  'access.myFaultRepair.access.repairer': '报修人:',
  'access.myFaultRepair.access.repair.reason': '报修理由:',
  'access.myFaultRepair.access.repair.time': '报修时间:',
  'access.myFaultRepair.state': '报修状态:',
  'access.myFaultRepair.Opinion': '处理意见:',
  'access.myFaultRepair.noData': '无数据',
  'access.myFaultRepair.reason': '报修理由',
  'access.myFaultRepair.time': '报修时间',
};
