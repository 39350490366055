/**
 * Created by wang sen on 2017/7/6.
 */
import { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH, PASSWORD_AES_KEY } from '@/utils/constant';
import { encryptAes } from '@/utils/utils';

/**
 * 获取绑定信息
 * @param payload
 */

export const getUserBindState = ({ accId, state, menuId, tempUrl, hr }) => {
  return requestNoAuthorization(
    `/${RESTFUL_PATH.wdf}/wdfClient/enterpriseNumber/getUserBindState`,
    {
      method: 'POST',
      body: JSON.stringify({
        accId,
        state,
        menuId,
        tempUrl,
        hr,
      }),
    },
  );
};

export const weChatBindFuc = ({
  userId,
  password,
  code,
  randomStr,
  accId,
  state,
  menuId,
  tempUrl,
  hr,
  lc,
}) => {
  const aesPassword = encryptAes(password, PASSWORD_AES_KEY);
  return requestNoAuthorization(
    `/${RESTFUL_PATH.wdf}/wdfClient/enterpriseNumber/vertifyMember4Ext`,
    {
      method: 'POST',
      body: JSON.stringify({
        accId,
        state,
        userId,
        password: aesPassword,
        code,
        randomStr,
        menuId,
        tempUrl,
        hr,
        lc,
      }),
    },
  );
};

export const dingDingBindFuc = ({
  userId,
  password,
  code,
  randomStr,
  accId,
  state,
  tempUrl,
  appId,
  hr,
  lc,
                                  isFm,
}) => {
  const aesPassword = encryptAes(password, PASSWORD_AES_KEY);
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/wdfClient/dingding/bindUser`, {
    method: 'POST',
    body: JSON.stringify({
      accId,
      state,
      tempUrl,
      appId,
      valueAccount: userId,
      valuePassword: aesPassword,
      code,
      randomStr,
      hr,
      lc,
      isFm
    }),
  });
};

export const callback = ({ code, state, id, domain, tempUrl, hr, lc, isFm }) => {
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/wdfClient/dingding/callback/app`, {
    method: 'POST',
    body: JSON.stringify({
      appId: id,
      state,
      code,
      domainId: domain,
      tempUrl,
      hr,
      lc,
      isFm
    }),
  });
};
