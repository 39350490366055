/**
 * Created by wang sen on 2017/7/6.
 */
import { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取绑定信息
 * @param payload
 */

export async function authCloudHub(params) {
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/yunzhijia/app/scanAuth`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

const loginAuth = () =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    qing.call('getPersonInfo', {
      success: (result) => {
        resolve(result);
      },
      error: (result) => {
        // eslint-disable-next-line no-alert
        alert('yunzhijia error');
        reject(result);
      },
      errorCode: (result) => {
        // eslint-disable-next-line no-alert
        alert('yunzhijia errorCode');
        reject(result);
      },
    });
  });

/**
 * 获得初始化qing环境，获得用户信息
 * @param null
 * @returns {Promise.<void>}
 */
export const initAuth = async () => {
  return loginAuth();
};
