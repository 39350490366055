export default {
  'points.account.overage.name': 'Account Balance Points',
  'points.account.overage.credits': 'Credit Limit',
  'points.account.overage.lockIn': 'Lock Amount',

  'points.bill.orderStatus.title': 'My Transaction',
  'points.bill.orderStatus.income': 'Income',
  'points.bill.orderStatus.expenditure': 'Expenditure',
  'points.bill.modal.confirm': 'OK',
  'points.bill.modal.cancel': 'Cancel',

  'points.billDetail.serial.number': 'Serial Number',
  'points.billDetail.state': 'State',
  'points.billDetail.consumer.users': 'Consumer Users',
  'points.billDetail.orderStatus': 'My Transaction',
  'points.billDetail.transaction.hour': 'Transaction Time',
  'points.billDetail.principal': 'Person in charge',
  'points.billDetail.remarks': 'Remarks',
  'points.billDetail.remarks.none': 'None',
  'points.billDetail.consumption.username': 'Subscriber',
  'points.billDetail.consumption.number': 'Booking Object',
  'points.billDetail.consumption.startTime': 'Start Time',
  'points.billDetail.consumption.endTime': 'End Time',
  'points.billDetail.orderStatus.0': 'Top-up',
  'points.billDetail.orderStatus.1': 'Redemption',
  'points.billDetail.orderStatus.2': 'Bonus',
  'points.billDetail.orderStatus.3': 'Deduction',
  'points.billDetail.orderStatus.4': 'Refund',
  'points.billDetail.orderStatus.all': 'All',
  'points.billDetail.noData': 'No data yet',
  'points.billDetail.state.0': 'Locked',
  'points.billDetail.state.1': 'Completed',
  'points.billDetail.state.2': 'Cancelled',

  'points.punishment.new.btn.title': 'New Ticket',
  'points.punishment.myList.btn.title': 'Ticket Record',
  'points.punishment.new.record.violation': 'Violation Item',
  'points.punishment.new.record.violation.dept': 'Department',
  'points.punishment.new.record.violation.userName': 'Principal',
  'points.punishment.new.record.violation.checkTime': 'Check Time',
  'points.punishment.new.record.violation.upload': 'Upload Photos (optional)',
  'points.punishment.new.record.violation.upload.tip':
    'Photo format is jpeg / jpg / png, the size does not exceed 500K',
  'points.punishment.new.record.violation.remark': 'Text Remarks (optional)',
  'points.punishment.new.record.submit': 'Submit',
  'points.punishment.state.0': 'Confirming',
  'points.punishment.state.1': 'Confirmed',
  'points.punishment.state.2': 'Canceled',
  'points.punishment.state.3': 'Rejected',
  'points.punishment.record.account': 'Associated account:',
  'points.punishment.record.checkTime': 'Check Time:',
  'points.punishment.record.cancelPunishment': 'Cancel Ticket',
  'points.punishment.record.resetPunishment': 'Restart',
  'points.punishment.record.punishmentDetail': 'Ticket Details',
  'points.punishment.record.cancel': 'Cancel',
  'points.punishment.record.detail.back': 'Return Back',
  'points.punishment.new.record.select': 'Please select',
  'points.punishment.new.record.add.success': 'Operation successful',
  'points.punishment.new.record.add.error': 'Operation failed',
  'points.punishment.new.record.add.rule':
    'The offending item or the offending department cannot be empty',
  'points.punishment.new.record.cancel.success': 'Cancellation successful',

  107001: 'Rule number already exists',
  107002: 'Insufficient available balance',
  107003: 'Account type configuration cannot be recognized',
  107004: 'Account not found',
  107005: 'Business identification not recognized',
  107006: 'Failed to lock the amount',
  107007: 'Recharge amount failed',
  107008: 'Cancel order failed',
  107009: 'Order does not exist',
  107010: 'Order modification failed',
  107011: 'The operation failed',
  107012: 'Parameter error',
  107013: 'The department administrator user does not exist',
  107014: 'Ticket has been processed',
  107015: 'Credit limit modification failed',
  107016: 'There is an automatic recharge configuration in this department',
  107017: 'The domain name is not configured in the system, please contact the administrator',

  'points.year': 'yr',
  'points.month': 'm',
  'points.day': 'd',
  'points.hour': 'h',
  'points.min': 'min',
  
  'points.product.exchange': 'Redemption',
  'points.product.exchange1': 'Redeem',
  'points.product.exchange.total': 'Total: ',
  'points.product.exchange.success': 'Successful Redeem',
  'points.product.exchange.points.current': 'Your current points are',
  'points.product.exchange.points.unit': 'points',
  'points.product.exchange.points.unit1': 'points',
  'points.product.exchange.points.price': 'Redeem Points',
  'points.product.exchange.points.detail': 'Points Records',
  'points.product.exchange.points.notenough': 'Insufficient Points',
  'points.product.exchange.points.enough': 'Gift List',
  'points.product.exchange.sure': 'Exchange',
  'points.product.exchange.back': 'Back List',
  'points.details.view': 'View Details',
  'points.header.main': 'Consumer User',
  'points.rewards': 'Points Obtained',
  'points.used': 'Points Consum',
};
