import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取空间树
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSpaceTree() {
  return request(`/${RESTFUL_PATH.upms}/space/tree`, {
    method: 'GET',
  });
}

/**
 * 获取空间树和叶子节点对应的地图id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSpaceTreeAndMapId() {
  return request(`/${RESTFUL_PATH.locker}/api/locker/getReservedLocations`, {
    method: 'GET',
  });
}

/**
 * 获取节假日
 * @param tenantId 租户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getHoliday(tenantId) {
  return request(`/${RESTFUL_PATH.upms}/holiday/getTenantWorkTime?tenantId=${tenantId}`, {
    method: 'GET',
  });
}

/**
 * 获取部门树
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getDepartmentTree() {
  return request(`/${RESTFUL_PATH.upms}/dept/tree`, {
    method: 'GET',
  });
}

/**
 * 查询用户信息
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSearchUsers(params) {
  return request(`/${RESTFUL_PATH.upms}/user/getUserVoList`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

/**
 * 获取系统配置查询接口
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSystemParam() {
  return request(`/${RESTFUL_PATH.locker}/api/sysParamConfig/list`, {
    method: 'GET',
  });
}

/**
 * 查询对应mapId下的所有元素
 *
 * @param mapId 地图Id
 */
export async function getEleIdsByMapId(mapId) {
  const url = `/${RESTFUL_PATH.map}/map-element/list?mapId=${mapId}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 获取地图配置信息
 * @param id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getBaseMap({ mapId }) {
  return request(`/${RESTFUL_PATH.map}/map/${mapId}`, {
    method: 'GET',
  });
}

/**
 * 查询位置下所有储物柜
 *
 * @param payload 请求参数体
 */
export async function getLockersByLocationId(payload) {
  const url = `/${RESTFUL_PATH.locker}/api/locker/findLockersReservation?locationId=${
    payload.locationId
  }&startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 *
 * 参数：开门操作类型operateType：dimcodeOpenDoor 二维码开门 / cardOpenDoor 门禁卡开门 / faceOpenDoor 人脸开门 / otherOpenDoor 其他开门
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function openDoor(payload) {
  return request(`/${RESTFUL_PATH.locker}/api/locker/client-open-door/${payload.lockerId}`, {
    method: 'GET',
    params: {
      operateType: 'dimcodeOpenDoor',
    },
  });
}

/**
 * 查询用户默认区域对应的地图信息
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getDefaultMap(spaceId) {
  return request(`/${RESTFUL_PATH.map}/map/getBySpaceId/${spaceId}`, {
    method: 'GET',
  });
}
