import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';
import { encryptAes } from '@/utils/utils';
import { CLOUD_AES_KEY } from '@/utils/constant';
import { stringify } from 'qs';

export async function getData(payload) {
  if (payload?.noAuth) {
    const params = {
      tenantId: payload.noAuth || '',
      sjc: encryptAes(String(payload.sjc), CLOUD_AES_KEY) || '',
    }
    return request(`/${RESTFUL_PATH.tcs}/api/toiletCubicle/selectAppToiletCubicleForThird`, {
      method: 'POST',
      body: JSON.stringify(params),
    });
  } else {
    return request(`/${RESTFUL_PATH.tcs}/api/toiletCubicle/selectAppToiletCubicle`);
  }
}

export async function getConfig(payload) {
  if (payload?.noAuth) {
    const params = {
      tenantId: payload.noAuth || '',
      sjc: encryptAes(String(payload.sjc), CLOUD_AES_KEY) || '',
    }
    return request(`/${RESTFUL_PATH.tcs}/common/mqttAddrForIgnore`, {
      method: 'POST',
      body: JSON.stringify(params),
    });
  } else {
    return request(`/${RESTFUL_PATH.tcs}/common/mqttAddr`);
  }
}

export async function buildingList() {
  return request(`/${RESTFUL_PATH.tcs}/api/area/buildingList`);
}

export async function floorList(payload) {
  return request(`/${RESTFUL_PATH.tcs}/api/area/floorList?buildingId=${payload || ''}`);
}

export async function getSpaceTree(payload) {
  if (payload?.noAuth) {
    const params = {
      tenantId: payload.noAuth || '',
      sjc: encryptAes(String(payload.sjc), CLOUD_AES_KEY) || '',
    }
    return request(`/${RESTFUL_PATH.tcs}/api/area/selectAreaTreeForIgnore`, {
      method: 'POST',
      body: JSON.stringify(params),
    });
  } else {
    return request(`/${RESTFUL_PATH.upms}/space/tree-all`);
  }
}

export async function getAllCategory(payload) {
  if (payload?.noAuth) {
    const params = {
      tenantId: payload.noAuth || '',
      sjc: encryptAes(String(payload.sjc), CLOUD_AES_KEY) || '',
    }
    return request(`/${RESTFUL_PATH.tcs}/api/category/selectAllCategoryForIgnore`, {
      method: 'POST',
      body: JSON.stringify(params),
    });
  } else {
    return request(`/${RESTFUL_PATH.tcs}/api/category/selectAllCategory`);
  }
}

// 获取厕位告警列表
export const getAlarmList = (params) => {
  return request(`/${RESTFUL_PATH.tcs}/alarmEvent/list?${stringify(params)}`, {
    method: 'GET',
  });
};

// 更新厕位告警状态
export const updateStatus = (params) => {
  return request(`/${RESTFUL_PATH.tcs}/alarmEvent/updateStatus`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

// 获取卫生间名称
export const getFullToiletName = (params) => {
  return request(`/${RESTFUL_PATH.tcs}/toilet/getFullToiletName?${stringify(params)}`, {
    method: 'GET',
  });
};
