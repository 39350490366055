export default {
  'report.byDays': 'By Day',
  'report.byMouths': 'By Mouth',
  'report.7Days': '7 Days',
  'report.30Days': '30 Days',
  'report.60Days': '60 Days',
  'report.6Mouths': '6 Mouths',
  'report.12Mouths': '12 Mouths',
  'report.24Mouths': '24 Mouths',
  'report.chart': 'Chart',
  'report.start_': 'Start：',
  'report.start': 'Start Time',
  'report.end_': 'End：',
  'report.end': 'End Time',
  'report.reservation.rightDate': 'Please enter the correct date',
  'report.reservation.signedIn': 'Sign In',
  'report.reservation.notIn': 'Not Reached',
  'report.reservation.total': 'Total',
  'report.reservation.date': 'Date',
  'report.reservation.amount': 'Amount',
};
