import { formatMessage, getLocale } from 'umi';
import { MENU_PATH, ACCESS_TOKEN } from '@/pages/Meeting/utils/constant';
import { getUrlParam } from '../../utils/common';
import { hasAuth } from '../Feedback/service'

export default {
  namespace: 'scanCodePage',
  state: {
    errorMsg: '',
    loading: true
  },

  effects: {
    *hasAuth({ roomId }, { select, call, put }) {
      const { currentUser: { userId, tenantId } } = yield select((state) => state.user);
      console.log(userId, "+==userId=")
      const { code, msg } = yield call(hasAuth, { roomId, userId, tenantId });
      if (code !== 0) {
        yield put({
          type: 'update',
          payload: { errorMsg: msg, loading: false }
        })
      }
      return { code, msg }
    }
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname.indexOf('/meeting/scanCodePage') > -1) {
          console.log(history, "===history")
          const roomId = getUrlParam('roomId');
          const v = getUrlParam('v');
          const token = getUrlParam('token');

          if (token) {
            sessionStorage.setItem(ACCESS_TOKEN, token);
          }
          if (roomId) {
            // dispatch({
            //   type: 'user/fetchCurrentUserInfo',
            //   payload: {
            //     saveInfo: false,
            //   },
            // }).then(() => {
            //     dispatch({ type: 'subscribe/initHost' }).then(()=>{
            //        sessionStorage.setItem('QR_SCAN_ROOM_ID', String(roomId));
            //   history.replace(MENU_PATH.subscribeDetail);
            //     });
            // });
            // 校验是否有权限
            sessionStorage.setItem('QR_SCAN_ROOM_ID', String(roomId));
            dispatch({ type: 'hasAuth', roomId }).then(async ({ code }) => {
              if (code === 0) {
                //  await  dispatch({ type: 'feedbackIndex/findNoCheckMeetingByRoomId', roomId });
                await dispatch({
                  type: 'user/fetchCurrentUserInfo',
                  payload: {
                    saveInfo: true,
                    redirect: decodeURIComponent(`${MENU_PATH.feedback}?roomId=${roomId}`),
                  },
                });
              }
            })


          } else if (v) {
            dispatch({
              type: 'subscribe/getdecryptRoomId',
              payload: {
                roomId: v,
              },
            }).then(async (res) => {
              // 校验是否有权限
              const roomId = res
              sessionStorage.setItem('QR_SCAN_ROOM_ID', String(roomId));
              //   await  dispatch({ type: 'feedbackIndex/findNoCheckMeetingByRoomId', roomId });
              dispatch({ type: 'hasAuth', roomId }).then(({ code }) => {
                if (code === 0) {
                  dispatch({
                    type: 'user/fetchCurrentUserInfo',
                    payload: {
                      saveInfo: true,
                      redirect: decodeURIComponent(`${MENU_PATH.feedback}?roomId=${res}`),
                    },
                  });
                }
              })

            }).catch((e) => {
              dispatch({
                type: 'user/fetchCurrentUserInfo',
                payload: {
                  saveInfo: true,
                  redirect: decodeURIComponent(`${MENU_PATH.feedback}?roomId=${v}`),
                },
              });
            });
          }


        }
      });
    },
  },
};
