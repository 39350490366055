/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  '106534': 'The operation failed',
  '106535': 'Internal program exception! ',

  'scanQRCode.scan.type.0': 'WeChat Scan (Type = 0)',
  'scanQRCode.scan.type.1': 'WeChat Scan (Type = 1)',

  'scanQRCode.scan.type.dingTalk': 'DingTalk Scan',

  'scanQRCode.scan.type.virsical': 'Virsical Scan',
  'scanQRCode.scan.type.feishu': 'FeiShu Scan',
};
