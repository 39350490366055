import { stringify } from 'qs';
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

const filterTrans = (payload) => {
  /* eslint-disable */
  const params = {};
  for (let key in payload) {
    params[key] = payload[key];
  }
  return params;
};

// 检查套餐是否过期
export async function checkSubscribe() {
  return request(`/${RESTFUL_PATH.cloudOms}/envMonitor/subscribeExpired/check`);
}

export async function deviceList() {
  return request(`/${RESTFUL_PATH.map}/space-device/queryByDeviceType?deviceType=EnvirSensor`);
}

// 获取周期数据
export async function historyData(payload) {
  const params = filterTrans(payload);
  delete params.deviceNumber;
  return request(
    `/${RESTFUL_PATH.vap}/dataAnalyse/timeSeries/device/${payload?.deviceNumber}?${stringify(
      params,
    )}`,
  );
}
