export default {
  machine_invited: 'Invited Visitors',
  machine_invited_code: 'Invitation Code',
  machine_uninvited: 'Walk-in Visitor',
  machine_signOut: 'Sign Out',
  machine_home: 'Home',
  machine_clear: 'Clear',
  machine_del: 'Del',
  machine_signOut_tip1: 'The printed',
  machine_signOut_tip2: 'QR code remains flat',
  machine_signOut_tip3: 'Please align the QR code camera',
  machine_signOut_tip4: 'you can automatically scan',
  machine_login_title: 'Administrator Login',
  machine_login_account: 'Account',
  machine_login_password: 'Password',
  machine_login_account_tip: 'Please input account',
  machine_login_password_tip: 'Please input password',
  machine_format: 'YYYY-M-D ddd HH:mm:ss',
  machine_welcome: 'Welcome',
  machine_disagree: 'Disagree',
  machine_agree: 'Agree',
  machine_remake: 'Remake',
  machine_back: 'Back',
  machine_next: 'Next',
  machine_success: 'Check Out successfully', // Check Out successfully
  machine_print: 'Printing...',
  machine_verify_tip1: 'Please prepare',
  machine_verify_tip2: 'the second generation ID Card',
  machine_verify_tip3: 'Place the identity',
  machine_verify_tip4: 'document in the read card area',
  machine_verify_tip5: 'Please hold ID Card',
  machine_verify_tip6: 'Place for at least three seconds',
  machine_verify_tip7: 'Until you jump to the next step',
  machine_forgetID: 'Forgotten identification card',
  machine_signOn: 'Registration is successful, please wait for review',
  machine_addAccompany: 'add personal',
  machine_registerAgain: 'register again',
  machine_register: 'register',
  machine_accompany: 'Accompanying person',
  machine_idCard_tip1: 'ID number cannot be empty',
  machine_idCard_tip2: 'please enter the correct ID number',
};
