/**
 * Created by XuQiang on 2017/6/7.
 */

export default {
  'visit.visitor.name': 'Name：',
  'visit.visitor.company': 'Company：',
  'visit.visitor.telephone': 'Mobile：',
  'visit.visitor.email': 'Email：',
  'visit.visitor.cause': 'Purpose：',
  'visit.visitor.time': 'Arrival Time：',
  'visit.visitor.time_': 'Arrival Time',
  'visit.visitor.status': 'Status：',
  'visit.visitor.status_': 'Status',
  'visit.visitor.leave': 'Leaving Time：',
  'visit.visitor.accompany': 'Entourage',
  'visit.visitor.status.0': 'Not started',
  'visit.visitor.status.1': 'Not visited',
  'visit.visitor.status.2': 'Signed in',
  'visit.visitor.status.3': 'Signed out',
  'visit.visitor.status.4': 'Canceled',
  'visit.visitor.status.5': 'Pending',
  'visit.visitor.status.6': 'Rejected',
  'visit.visitor.status.7': 'Expired',
  'visit.visitor.status.8': 'Processing',
  'visit.visitor.status.9': 'System signed out',
  'visit.visitor.status.10': 'Failed',
  'visit.visitor.status.11': 'Approved',
  'visit.visitor.status.12': 'Reviewing',
  'visit.status.0.tip': 'Arrival time has not started or the visit time has not passed',
  'visit.status.1.tip': 'Not arrived until the leaving time',
  'visit.status.2.tip': 'Signed in',
  'visit.status.3.tip': 'Signed out',
  'visit.status.4.tip': 'Canceled',
  'visit.status.5.tip': "Visits that need employee's review",
  'visit.status.6.tip': 'Visits that rejected by employees',
  'visit.status.7.tip': 'Visits have not been reviewed after the leaving time',
  'visit.status.8.tip': 'Visits are under processing by integrated third party system',
  'visit.status.9.tip': 'Automatically signed out on system configured time',
  'visit.status.10.tip': 'Integrated third party system processing failed',
  'visit.status.11.tip': 'Agreed to visit',
  'visit.status.12.tip':
    'The approval process has not been completed and needs to be approved by other personnel.',
  'visit.visitor.status.unConfirm': 'Pending',
  'visit.visitor.status.Confirmed': 'Confirmed',
  'visit.visitor.status.reject': 'Rejected',
  'visit.visitor.status.expired': 'Expired',
  'visit.visitor.operation': 'Operating',
  'visit.visitor.detail': 'Details',
  'visit.visitor.info.search': 'Search',
  'visit.visitor.search': 'Search',
  'visit.visitor.sign.out': 'Sign Out',
  'visit.visitor.view': 'Details',
  'visit.visitor.tip1': 'How to greet your first visitor',
  'visit.visitor.tip2': '1、Open the App Store on your iPad and download the Visitor management',
  'visit.visitor.tip3': '2、Visit the admin page and Create a device administrator，Login iPad app',
  'visit.visitor.tip4': '3、Sign yourself in as your first visitor!！',
  'visit.visitor.export': 'Export',
  'visit.visitor.certNumber': 'ID Number:',
  'visit.visitor.visit.time': 'Arrival Time',
  'visit.visitor.visit.invitationCode': 'Invitation Code',
  'visit.visitor.visit.beizhu': 'Remark',
  'visit.visitor.visit.end.time': 'Leaving Time ',

  'visit.reserve.across.title': 'Cross-day Visit',
  'visit.selfFlag.0.tip': 'Visitors invited by employees',
  'visit.selfFlag.1.tip':
    'Visitors who register and visit through the mini program, offical account or self-service reservation.',
  'visit.selfFlag.2.tip':
    'Walk-in visitors who registered by terminal device (Pad, Kiosk) or at front desk',
  'visit.selfFlag.3.tip':
    'Visitors who are invited through "Group Invitation" and register through the invitation link shared by employees.',
  'visit.reserve.book.lockers.title': 'To reserve lockers',
  'visit.reserve.book.lockers.title.tip': 'Visitors can use their own lockers',

  'visit.visitor.date.picker.unit.year': 'Y',
  'visit.visitor.date.picker.unit.month': 'M',
  'visit.visitor.date.picker.unit.day': 'D',
  'visit.visitor.date.picker.unit.hour': 'H',
  'visit.visitor.date.picker.unit.minute': 'min',
};
