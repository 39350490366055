/**
 * Created by joe on 2017/5/12.
 */
import wx from 'weixin-js-sdk';

const config = (jsSDKInfo, apiList, isDebug = false) =>
  new Promise((resolve, reject) => {
    wx.config({
      debug: isDebug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: jsSDKInfo.appid, // 必填，公众号的唯一标识
      timestamp: jsSDKInfo.timestamp, // 必填，生成签名的时间戳
      nonceStr: jsSDKInfo.nonceStr, // 必填，生成签名的随机串
      signature: jsSDKInfo.signature, // 必填，签名，见附录1
      jsApiList: apiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });
    wx.ready(resolve);
    wx.error(reject);
  });

/**
 * 隐藏微信分享按钮
 * @returns {function()}
 * 基本类
 * 举报: "menuItem:exposeArticle"
 * 调整字体: "menuItem:setFont"
 * 日间模式: "menuItem:dayMode"
 * 夜间模式: "menuItem:nightMode"
 * 刷新: "menuItem:refresh"
 * 查看公众号（已添加）: "menuItem:profile"
 * 查看公众号（未添加）: "menuItem:addContact"
 *
 * 传播类
 * 发送给朋友: "menuItem:share:appMessage"
 * 分享到朋友圈: "menuItem:share:timeline"
 * 分享到QQ: "menuItem:share:qq"
 * 分享到Weibo: "menuItem:share:weiboApp"
 * 收藏: "menuItem:favorite"
 * 分享到FB: "menuItem:share:facebook"
 * 分享到 QQ 空间/menuItem:share:QZone
 *
 * 保护类
 * 编辑标签: "menuItem:editTag"
 * 删除: "menuItem:delete"
 * 复制链接: "menuItem:copyUrl"
 * 原网页: "menuItem:originPage"
 * 阅读模式: "menuItem:readMode"
 * 在QQ浏览器中打开: "menuItem:openWithQQBrowser"
 * 在Safari中打开: "menuItem:openWithSafari"
 * 邮件: "menuItem:share:email"
 * 一些特殊公众号: "menuItem:share:brand"
 */
const hideMenuItems = () =>
  new Promise(() => {
    wx.hideMenuItems({
      menuList: [
        'menuItem:share:qq',
        'menuItem:share:weiboApp',
        'menuItem:favorite',
        'menuItem:share:facebook',
        'menuItem:share:QZone',
        'menuItem:copyUrl',
        'menuItem:originPage',
        'menuItem:openWithQQBrowser',
        'menuItem:openWithSafari',
        'menuItem:share:email',
        'menuItem:share:brand',
      ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
    });
  });

/**
 * 微信调用摄像头扫描二维码
 */
const captureQR = () =>
  new Promise((resolve, reject) => {
    wx.scanQRCode({
      desc: 'scanQRCode desc',
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ['qrCode'], // 可以指定扫二维码
      success: (res) => resolve(res.resultStr),
      fail: (res) => {
        /* eslint-disable*/
        alert('Open QR Scan Fail');
        /* eslint-enable */
        reject(res);
      },
    });
  });

/**
 * 获得初始化Wechat环境，获得用户信息
 * @param jsSDKInfo
 * @param apiList
 * @returns {Promise.<void>}
 */
export const init = async (jsSDKInfo, apiList) => {
  await config(jsSDKInfo, apiList)
    .then(hideMenuItems, (err) => window.console.log('config error', err))
    .catch((err) => window.console.log(err));
};

/**
 * 调用微信二维码扫描
 * @returns 扫描结果
 */
export const qrScan = async () => {
  return captureQR();
};

/**
 * 判断是不是微信浏览器
 * @returns {boolean}
 */
export const getWxBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') !== -1;
  // return ua.match(/MicroMessenger/i) === 'micromessenger';
};
