export default {
  'loop.rule': 'Set Recurrence',
  'loop.reserve': 'Recurrence',
  'start.date': 'Start date',
  'booking.repeat.minutes': 'minutes',
  'cycle.tab.loop': 'repeat',
  'cycle.tab.loop.everyworkday': 'Every workday,',
  'cycle.tab.loop.everyday': 'Every {day} day(s),',
  'cycle.tab.loop.everyMonth': 'The {day} day of every month,',
  'cycle.tab.loop.text.label': 'starting from {startTime} to {endTime}, {startDate}, with {count} occurrence(s).',
  'cycle.tab.loop.text.label2': 'starting from {startDate} with {count} occurrence(s).',
  'cycle.tab.loop.text.label.date': 'starting from {startTime} to {endTime}, {startDate}， end with {endDate}.',
  'cycle.tab.loop.text.label.date2': 'starting from {startDate}， end with {endDate}.',

  'cycle.tab.loop.month.week': 'The {weekCount}th {week}',
  'cycle.tab.loop.month.week.last': 'Last {week}',
  'cycle.tab.loop.month2.week': ' The {weekCount}th {week} every {freq} month',
  'cycle.tab.loop.month2.week.last': 'Last {week} of every {freq} months',
  'cycle.tab.loop.year.date': '{month} month {day} day',
  'cycle.tab.loop.year2.date': 'The {month} month {day} day every year',
  'cycle.tab.loop.year.week': 'The {weekCount}th {week} in {month} month ',
  'cycle.tab.loop.year.week.last': 'The last {week} in  {month} month',
  'cycle.tab.loop.year2.week': 'The {weekCount}th {week} of {month} month every year',
  'cycle.tab.loop.year2.week.last': 'The last week of each month in every year',

  meeting_cycle_rule_by_day:
    'Meeting occurs every {freq} day(s)',
  meeting_cycle_rule_by_week:
    'Meeting occurs on {weekStr} every {freq} week(s)',
  meeting_cycle_rule_by_weekday:
    'Meeting occurs every {freq} workday(s)',
  meeting_cycle_rule_by_month:
    'Meeting occurs on day {mexrule} every {freq} month(s)',
  meeting_cycle_rule_by_month2:
    'Meeting occurs every {freq} month on the last day',
  'meeting.booking.step.re_day': 'day(s)',
  'meeting.booking.step.rework_day': 'weekday(s)',
  'meeting.booking.step.re_week': 'week(s)',
  'meeting.booking.step.re_week_double': 'Two Weeks',
  'meeting.booking.step.re_month': 'month(s)',
  'meeting.booking.step.re_year': 'year(s)',
  'meeting.booking.step.re_every': 'Every',
  'meeting.booking.step.re_every_md': 'Every month',
  'meeting.booking.cycle.end': 'Occurrence',
  'meeting.booking.cycle.endDate': 'End at',
  'meeting.booking.cycle.endTimes': 'Occurence',
  'meeting.booking.cycle.error': 'No conference room is selected and the current user has not set the default area, please go to the setting',
  meeting_cycle_booking_error_tips2: 'At least 1 appointment !',
  meeting_poly_check_least_one:'Select at least one video conference type!',
  meeting_cycle_fre_last_day: 'Last',
  meeting_cycle_modal_last_day: 'Last day',
  meeting_cycle_modal_title: 'Select Day',
};
