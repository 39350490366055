/**
 * Created JS
 * User: JiangYilong
 * Date: 2019/2/15
 * Time: 10:02
 * Email: jiangyilong@wafersystems.com
 */
export default {
  custom_iPadLogo: '企业LOGO',
  custom_iPadBgImg: '首页背景图',
  custom_iPadOtherBgImg: '其他页背景图',
  custom_pcColor: '企业颜色',
  custom_pc_template: '打印模板',
  custom_machineBgImg: '首页背景轮播图',
  custom_iPadLogo_title: '我们将在iPad展示您的企业LOGO',
  custom_machineLogo_title: '我们将在一体机展示您的企业LOGO',
  custom_pcLogo_title: '我们将在pc展示您的企业LOGO',
  custom_pc_template_title: '我们将在pc打印时使用您选择的模板尺寸',
  custom_iPad_template_title: '我们将在iPad打印时使用您选择的模板尺寸',
  custom_machine_template_title: '我们将在一体机打印时使用您选择的模板尺寸',
  custom_pc_template_tip: '适用于DK-11202',
  custom_pc_template_tip1: '适用于DK-22205,DK-22251',
  custom_iPadBgImg_title: '我们将在iPad首页展示您的背景图',
  custom_machineBgImg_title: '我们将在一体机展示您的背景轮播图',
  custom_pcBgImg_title: '您可以自定义设置PC客户端的外观颜色',
  custom_iPadOtherBgImg_title: '我们将在iPad其他页面展示您的背景图',
  custom_machineOtherBgImg_title: '我们将在一体机其他页展示您的背景图',
  custom_imgTip: '提示：支持JPEG、PNG格式，推荐您使用透明背景的PNG图片',
  custom_pcColor_btn: '选择颜色',
  custom_pcColor_set: '输入设置',
  custom_pcColor_input: '输入色值',
  custom_pcColor_placeholder: '请输入色值',
  custom_pcColor_Recommend: '推荐颜色',
  custom_machine_Horizontal: '横屏',
  custom_machine_Vertical: '竖屏',
  custom_save: '保存',
};
