export default {
  machine_invited: '受邀訪客',
  machine_invited_code: '邀請碼',
  machine_uninvited: '臨時訪客',
  machine_signOut: '簽出',
  machine_home: '返回首頁',
  machine_clear: '清空',
  machine_del: '刪除',
  machine_signOut_tip1: '已打印的',
  machine_signOut_tip2: '二維碼保持平整',
  machine_signOut_tip3: '將二維碼',
  machine_signOut_tip4: '對準機器掃描窗口',
  machine_login_title: '管理員登錄',
  machine_login_account: '企業帳號',
  machine_login_password: '密碼',
  machine_login_account_tip: '企業帳號不能為空',
  machine_login_password_tip: '密碼不能為空',
  machine_format: 'YYYY年MM月DD日 ddd HH:mm:ss',
  machine_welcome: '歡迎光臨',
  machine_disagree: '不同意',
  machine_agree: '同意協議',
  machine_remake: '重拍',
  machine_back: '上一步',
  machine_next: '下一步',
  machine_success: '簽出成功', // Check Out successfully
  machine_print: '正在打印中...',
  machine_verify_tip1: '請準備',
  machine_verify_tip2: '二代身份證件',
  machine_verify_tip3: '將身份證件',
  machine_verify_tip4: '置於讀卡區域',
  machine_verify_tip5: '請保持身份證',
  machine_verify_tip6: '放置至少3秒,直到',
  machine_verify_tip7: '登記介面跳轉到下一步',
  machine_forgetID: '未帶身份證',
  machine_signOn: '登記成功，請等待審核',
  machine_addAccompany: '添加人員',
  machine_registerAgain: '重新登記',
  machine_register: '登記',
  machine_accompany: '隨行人員',
  machine_idCard_tip1: '身份證號不能為空',
  machine_idCard_tip2: '請輸入正確身份證號',
};
