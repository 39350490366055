/**
 * Created : fangkemeng
 * Date : 2019-10-12  10:00
 * Email : fangkemeng@wafersystems.com
 */
import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';
import * as myBookingService from './service';

export default {
  namespace: 'swsMyBooking',
  state: {
    myBookList: [],
    mySpecialBookList: [],
    historyBook: { records: [], current: 1, size: 5, total: 0, pages: 1 },
    historyBookList: [],
    loading: false,
    showReservationResult: false,
    dataSpecailArr: [], // 特殊预约数组
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询我的预订
    *getMybook(_, { call, put }) {
      const result = yield call(myBookingService.getMybook);
      if (result && result.code === 0) {
        const { data } = result;
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            myBookList: data?.data || [],
          },
        });
      }
    },
    // 取消预订
    *cancelReservation({ payload }, { call, put }) {
      const result = yield call(myBookingService.cancelReservation, payload);
      if (result && result.code === 0) {
        yield put({
          type: 'getMybook',
        });
        yield put({
          type: 'getHistory',
        });
        Toast.success(formatMessage({ id: 'sws.cancel.success' }), 1);
      }
    },
    // 查询我的特殊预订
    *getSpecialMybook(_, { call, put }) {
      const result = yield call(myBookingService.getSpecialMybook);
      if (result && result.code === 0) {
        const { data } = result;
        let obj = {};
        const arr = [];
        if (data && data.data) {
          data.data.forEach((item) => {
            obj = item;
            obj.id = item.uuid;
            arr.push(obj);
          });
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            mySpecialBookList: data?.data || [],
            dataSpecailArr: arr,
          },
        });
      }
    },
    // 查询历史记录
    *getHistory({ payload }, { call, put, select }) {
      // console.log('###########getHistory', search)
      const { historyBookList } = yield select((state) => state.swsMyBooking);
      const result = yield call(myBookingService.getHistory, payload);
      if (result && result.code === 0) {
        const { data = {} } = result;
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            historyBookList: [...historyBookList, ...data?.records],
            historyBook: data
          },
        });
      }
    },
    // 预订确认操作
    *confirmReservation({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(myBookingService.confirmReservation, param);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            bookingVisible: false,
            speVisible: false,
            showReservationResult: true,
          },
        });
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
    // 注销预订
    *logoutReservation({ payload }, { call, put }) {
      const result = yield call(myBookingService.cancelReservation, payload);
      if (result && result.code === 0) {
        yield put({
          type: 'getMybook',
        });
        Toast.success(formatMessage({ id: 'sws.logout.success' }), 1);
      }
    },
    // 预订延时
    *delayReservation({ payload }, { call, put }) {
      const result = yield call(myBookingService.delayReservation, payload);
      if (result && result.code === 0) {
        yield put({
          type: 'getMybook',
        });
        Toast.success(formatMessage({ id: 'sws.reserve.delay.success' }), 1);
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        // 监听路由变化，并发送类别查询请求
        if (pathname && pathname.includes('/workspace/station/myBooking')) {
          // console.log('myBooking model 初始化')
          dispatch({ type: 'swsCommon/getSpaceTreeAndMapId' });
          dispatch({ type: 'getMybook'});
          dispatch({ type: 'getSpecialMybook' });
          dispatch({ type: 'getHistory' });
          dispatch({ type: 'swsCommon/getHoliday' });
        }
      });
    },
  },
};
