/**
 * Created by wang sen on 2017/7/6.
 */
import { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH, PASSWORD_AES_KEY } from '@/utils/constant';
import { encryptAes } from '@/utils/utils';

/**
 * 获取绑定信息
 * @param payload
 */

export const cloudHubBindFuc = ({
  userId,
  password,
  code,
  randomStr,
  aid,
  openid,
  client,
  lc,
  tempUrl,
  hr,
}) => {
  const aesPassword = encryptAes(password, PASSWORD_AES_KEY);
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/yunzhijia/app/userBind`, {
    method: 'POST',
    body: JSON.stringify({
      aid,
      openid,
      client,
      userId,
      lc,
      tempUrl,
      hr,
      password: aesPassword,
      code,
      randomStr,
    }),
  });
};
