/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.faceLibrary.title': 'Face Library',
  'access.faceLibrary.font': 'Front',
  'access.faceLibrary.side': 'Profile',
  'access.faceLibrary.left': 'Left',
  'access.faceLibrary.right': 'Right',
  'access.faceLibrary.above': 'Upside',
  'access.faceLibrary.btn.submit': 'Submit',
  'access.faceLibrary.btn.reUpload': 'Re-upload',
  'access.faceLibrary.btn.finish': 'Finish',
  'access.faceLibrary.state.pass': 'Passed',
  'access.faceLibrary.state.audit': 'Pending',
  'access.faceLibrary.state.NotPass': 'Failed',

  straight: 'Front',
  side: 'Profile',
  left: 'Left',
  right: 'right',
  up: 'Upside',
  '10001': 'No face found',
  '10002': 'Multiple faces',
  '10003': 'The face is too small',
  '10004': 'Blurred photos',
  'access.faceLibrary.state': 'Status',
  'access.faceLibrary.tip': 'Please keep your face clear and take a photo of your face. There can only be one face in the photo, and the proportion should not be less than 30%.',
  'access.faceLibrary.tip.1': ' ',
  'access.faceLibrary.tip1': '1. Please select a face photo with clear facial features',
  'access.faceLibrary.tip2': '2. Please ensure that there is only one face in the photo',
  'access.faceLibrary.tip3':
    '3. Please make sure that the face area of the photo is not less than 30%',
  // 'access.faceLibrary.btnText': 'Select photo',
  'access.faceLibrary.uploadText': 'Uploading',
  'access.faceLibrary.errorText': 'Upload size cannot exceed 10M',
  'access.faceLibrary.btnBackText': 'Return',
  'access.faceLibrary.btnConfirmText': 'Confirm',
  'access.faceLibrary.identify': 'Identifying the picture',
  'access.faceLibrary.uploadText.tip': 'Loading',

  'access.faceLibrary.state.sync.0':'Pending review',
  'access.faceLibrary.state.sync.1':'Audit rejected',
  'access.faceLibrary.state.sync.2':'Syncing',
  'access.faceLibrary.state.sync.3': 'Take a Photo',
  'access.faceLibrary.state.sync.4': 'Take a Photo',
  'access.faceLibrary.nda.agree':"Confirm",
  // 'access.success.msg': 'Uploaded successfully',
  'access.msg.pending': 'Photos under review',

  'access.success.msg': 'Registration completed',
  'access.success.del': 'Delete photo ',
  'access.success.msg.tips': 'No photo retention enabled, can\'t show photos ',
  'access.faceLibrary.btnText': 'Take a Photo',
  'access.faceLibrary.del': 'Delete',
};
