import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';
import { stringify } from 'qs';

/**
 * 获取空间树
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSpaceTree() {
  return request(`/${RESTFUL_PATH.upms}/space/tree`, {
    method: 'GET',
  });
}

/**
 * 获取空间树和叶子节点对应的地图id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSpaceTreeAndMapId(params) {
  return request(`/${RESTFUL_PATH.sws}/api/station/reserveLocation`, {
    method: 'GET',
    params,
  });
}

/**
 * 获取空间树和叶子节点对应的默认地图id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSpaceTreeAndDefaultMapId(params) {
  console.log(params,'params')
  return request(`/${RESTFUL_PATH.sws}/api/v2/reserve/reserveLocation`, {
    method: 'GET',
    params,
  });
}


/**
 * 获取节假日
 * @param tenantId 租户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getHoliday(tenantId) {
  return request(`/${RESTFUL_PATH.upms}/holiday/getTenantWorkTime?tenantId=${tenantId}`, {
    method: 'GET',
  });
}

/**
 * 获取部门树
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getDepartmentTree() {
  return request(`/${RESTFUL_PATH.upms}/dept/tree`, {
    method: 'GET',
  });
}

// /**
//  * 查询用户信息
//  * @returns {Promise<questMethodAddToUrl>}
//  */
// export async function getSearchUsers(params) {
//   return request(`/${RESTFUL_PATH.upms}/user/getUserVoList`, {
//     method: 'POST',
//     body: JSON.stringify(params),
//   });
// }

/**
 * 获取系统配置查询接口
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSystemParam(params) {
  return request(`/${RESTFUL_PATH.sws}/api/sysSetting/listBySpaceId?${stringify(params)}`, {
    method: 'GET',
  });
}

/**
 * 查询位置下所有工位
 *
 * @param payload 请求参数体
 */
export async function getStationsByLocationId(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/station/findStationByLocationForReservation?locationId=${
    payload.locationId
  }&startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}&type=2&isShareLocation=${
    payload?.isShareLocation || false
  }&isAgent=${payload?.isAgent||false}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 获取分页预订列表
 * client 1：web/app，2：manage
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getReservations({ current, size, payload }) {
  const url = `/${RESTFUL_PATH.sws}/api/reservation/page?client=1&domainId=${payload.domainId}&current=${current}&size=${size}&locationIds=${payload.locationIds}&deptIds=${payload.deptIds}&userName=${payload.userName}&stationNums=${payload.stationNums}&startTimeStr=${payload.startTimeStr}&endTimeStr=${payload.endTimeStr}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 显示设置：获取
 * @param param 显示类型 setting为全部
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSetting(param) {
  const url = `/${RESTFUL_PATH.sws}/api/displaySetting/search?business=${param || 'setting'}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询对应mapId下的所有元素
 *
 * @param mapId 地图Id
 */
export async function getEleIdsByMapId(mapId) {
  const url = `/${RESTFUL_PATH.map}/map-element/list?mapId=${mapId}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 获取地图配置信息
 * @param id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getBaseMap({ mapId }) {
  return request(`/${RESTFUL_PATH.map}/map/${mapId}`, {
    method: 'GET',
  });
}

/**
 * 查询位置下所有工位
 *
 * @param payload 请求参数体
 */
export async function getStationsByLocationIdScreen(payload) {
  const url = `/sws-workspace/api/station/findStationByLocationForScreen?locationId=${payload.locationId}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询用户默认区域对应的地图信息
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getDefaultMap(spaceId) {
  return request(`/${RESTFUL_PATH.map}/map/getBySpaceId/${spaceId}`, {
    method: 'GET',
  });
}
/**
 * 特殊预约代订查询用户信息
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSearchUsers({ key }) {
  const url = `/${RESTFUL_PATH.sws}/api/web/like-user?like=${key}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 特殊预约代订查询外部用户信息
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSearchOutUsers({ key }) {
  const url = `/${RESTFUL_PATH.sws}/api/externalUser/queryByUserName?userName=${key}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 查询地图素材颜色
 */
export async function getMapColor() {
  return request(`/${RESTFUL_PATH.upms}/dict/map/conf`);
}

/**
 * 获取共享区域数据
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSharespaceTreeAndMapId() {
  return request(`/${RESTFUL_PATH.sws}/api/station/reserveShareLocation`, {
    method: 'GET',
  });
}
/**
 * 可预订的日期列表
 * flag 1普通预约，2特殊预约
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getOpenDates({ locationId, flag }) {
  const url = `/${RESTFUL_PATH.sws}/api/reserve/getHolidayBySpaceId?spaceId=${locationId}&flag=${
    flag || ''
  }`;
  return request(url, {
    method: 'GET',
  });
}


export async function getSwsSetting() {
  return request(`/${RESTFUL_PATH.sws}/api/sysSetting/listBySpaceId`, {
    method: 'GET',
  });
}
/**
 * 常用区域查询
 * @param number
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function oftenSpace(number) {
  const url = `/${RESTFUL_PATH.sws}/api/v2/reserve/oftenSpace?number=${number}`;
  return request(url, {
    method: 'GET',
  });
}
