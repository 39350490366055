/**
 * Created : fangkemeng
 * Date : 2019-10-12  10:00
 * Email : fangkemeng@wafersystems.com
 */
import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';
import { history } from 'umi';
import { ROUTE_PATH } from '@/utils/constant';
import * as service from './service';

export default {
  namespace: 'swsLockerMyBooking',
  state: {
    myLockerBookList: [],
    historyLockerList: [],
    historyLocker: { records: [], current: 1, size: 5, total: 0, pages: 1 },
    loading: false,
    showReservationResult: false,
    lockerCancelinfo: { group: [], location: '' },
    stationItem: {},
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询我的预订
    *getLockerMybook({ payload }, { call, put }) {
      const result = yield call(service.getLockerMybook, payload);
      const { data } = result;
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            myLockerBookList: data || [],
          },
        });
      }
    },
    // 查询历史记录
    *getLockerHistory({ payload }, { call, put, select }) {
      const { historyLockerList } = yield select((state) => state.swsLockerMyBooking);
      const result = yield call(service.getLockerHistory, payload);
      if (result && result.code === 0) {
        const { data = {} } = result;
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            historyLockerList: [...historyLockerList, ...data?.records],
            historyLocker: data,
          },
        });
      }
    },
    // 预订确认操作
    *confirmReservation({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(service.confirmReservation, param);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            bookingVisible: false,
            speVisible: false,
            showReservationResult: true,
          },
        });
      } else if (result.code === 1 && result?.msg) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
    // 取消预订
    *cancelReservation({ payload }, { call, put }) {
      const result = yield call(service.cancelReservation, payload);
      if (result && result.code === 0) {
        Toast.success(formatMessage({ id: 'sws.cancel.success' }), 1);
        yield put({
          type: 'getLockerMybook',
        });
        yield put({
          type: 'getLockerHistory',
        });
        history.go(-1);
      }
    },
    // 我的预约-取消预约详细列表
    *lockerCancel({ uuid }, { call, put }) {
      const result = yield call(service.lockerCancel, uuid);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            lockerCancelinfo: result.data || {},
          },
        });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        // 监听路由变化，并发送类别查询请求
        if (pathname && pathname.includes('/locker/lockerMyBooking')) {
          dispatch({ type: 'locker/getSpaceTreeAndMapId' });
          dispatch({ type: 'getLockerMybook' });
          dispatch({ type: 'getLockerHistory' });
          dispatch({ type: 'locker/getHoliday' });
        }
      });
    },
  },
};
