export default {
  'app.settings.menuMap.basic': '基本設置',
  'app.settings.menuMap.security': '安全設置',
  'app.settings.menuMap.binding': '賬號綁定',
  'app.settings.menuMap.notification': '新消息通知',
  'app.settings.basic.avatar': '頭像',
  'app.settings.basic.change-avatar': '更換頭像',
  'app.settings.basic.email': '郵箱',
  'app.settings.basic.email-message': '請輸入您的郵箱!',
  'app.settings.basic.nickname': '昵稱',
  'app.settings.basic.nickname-message': '請輸入您的昵稱!',
  'app.settings.basic.profile': '個人簡介',
  'app.settings.basic.profile-message': '請輸入個人簡介!',
  'app.settings.basic.profile-placeholder': '個人簡介',
  'app.settings.basic.country': '國家/地區',
  'app.settings.basic.country-message': '請輸入您的國家或地區!',
  'app.settings.basic.geographic': '所在省市',
  'app.settings.basic.geographic-message': '請輸入您的所在省市!',
  'app.settings.basic.address': '街道地址',
  'app.settings.basic.address-message': '請輸入您的街道地址!',
  'app.settings.basic.phone': '聯繫電話',
  'app.settings.basic.phone-message': '請輸入您的聯繫電話!',
  'app.settings.basic.update': '更新基本信息',
  'app.settings.security.strong': '強',
  'app.settings.security.medium': '中',
  'app.settings.security.weak': '弱',
  'app.settings.security.password': '賬戶密碼',
  'app.settings.security.password-description': '當前密碼強度：',
  'app.settings.security.phone': '密保手機',
  'app.settings.security.phone-description': '已綁定手機：',
  'app.settings.security.question': '密保問題',
  'app.settings.security.question-description': '未設置密保問題，密保問題可有效保護賬戶安全',
  'app.settings.security.email': '備用郵箱',
  'app.settings.security.email-description': '已綁定郵箱：',
  'app.settings.security.mfa': 'MFA 設備',
  'app.settings.security.mfa-description': '未綁定 MFA 設備，綁定後，可以進行二次確認',
  'app.settings.security.modify': '修改',
  'app.settings.security.set': '設置',
  'app.settings.security.bind': '綁定',
  'app.settings.binding.taobao': '綁定淘寶',
  'app.settings.binding.taobao-description': '當前未綁定淘寶賬號',
  'app.settings.binding.alipay': '綁定支付寶',
  'app.settings.binding.alipay-description': '當前未綁定支付寶賬號',
  'app.settings.binding.dingding': '綁定釘釘',
  'app.settings.binding.dingding-description': '當前未綁定釘釘賬號',
  'app.settings.binding.bind': '綁定',
  'app.settings.notification.password': '賬戶密碼',
  'app.settings.notification.password-description': '其他用戶的消息將以站內信的形式通知',
  'app.settings.notification.messages': '系統消息',
  'app.settings.notification.messages-description': '系統消息將以站內信的形式通知',
  'app.settings.notification.todo': '待辦任務',
  'app.settings.notification.todo-description': '待辦任務將以站內信的形式通知',
  'app.settings.open': '開',
  'app.settings.close': '關',
  'menu.vst.app.manage': '智能訪客',
  'menu.vst.app.reserve': '邀請記錄',
  'menu.vst.app.visit': '訪問記錄',
  'menu.vst.app.confirm': '訪客審批',
  'menu.vst.app.statisticalAnalysis': '統計分析',
  app_invitation_img: '選擇照片',
  'app_invitation_img.info': '上傳照片用於人臉識別開門或者簽到',
  'app_invitation_img.back': '返回',
  'app_invitation_img.cancel': '取消',
  'app_invitation_img.confirm': '確定',
  'app.upload.tip': '照片大小不能超過10M',
  'app.upload.loading': '正在上傳',
  'app_invitation_health.title': '訪客健康調查表',
  'app_invitation_health.tip': '本人聲明當前以及在訪問{companyName}之前符合上述所列全部要求，提供信息真實有效',
  'app_invitation_health.yes': '符合上述全部要求',
  'app_invitation_health.no': '有一項或多項不符',
  'app_invitation_health.modal.title': '確定提交問卷嗎？',
  'app_invitation_health.choose.gain': '重新選擇',
  'app_invitation_health.choose.ok': '確定',
  'app_invitation_health.not.available': '暫無',
};
