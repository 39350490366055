/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  cloudHub_finish: 'Finish',
  cloudHub_virsical: 'Visike',
  cloudHub_submit: 'Submit',
  cloudHub_hasbind: 'Account has been bound',
  cloudHub_inputAccount: 'Please enter the account',
  cloudHub_notUserNameEmpty: 'User name cannot be empty!',
  cloudHub_notPassWordEmpty: 'Password cannot be empty!',
  cloudHub_inputPassword: 'Please enter the password',
  cloudHub_close: 'Close',
  cloudHub_getStateFail: 'Failed to get user binding status, please close and try again',
  cloudHub_bindFail: 'Failed to bind account',

  106000: 'System abnormality!',
  106504: 'Authentication failed!',
  106505: 'Wrong user name or password!',
};
