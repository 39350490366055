import { RESTFUL_PATH } from '@/utils/constant';
import request from '@/utils/request';
import { stringify } from 'qs';

/**
 * 根据办公地id、员工id、到访时间获取剩余访客人数数量
 * @param params {locationManageId, employeeId, visitorTime}
 * @returns
 */
export const getVisitorCount = (params: {
  locationManageId: number;
  employeeId: number;
  visitorTime: number;
}) => {
  return request(`/${RESTFUL_PATH.visitor}/api/whiteList/checkVisitorCount?${stringify(params)}`);
};

/**
 * 访客补填创建后获取分享链接信息
 * @param batchAddGroupId 分组id
 * @returns
 */
export const getShareInviteLink = ({ batchAddGroupId }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/getShareInviteLink/${batchAddGroupId}`);
};

/**
 * 新增/更新访客补填数据
 * @param params 邀请数据
 * @returns
 */
export const saveOrUpdateVisitorFill = (params: any) => {
  const { randomStr = null, code = null } = params;
  return request(
    `/${RESTFUL_PATH.visitor}/api/whiteList/saveVisitorFill?randomStr=${randomStr}&code=${code}`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
  );
};

/**
 * 获取拜访对象
 * @param param { empName, companyId }
 * @returns 
 */
export const getVisitTargets = (params: any) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/employees/fuzzyQry?${stringify(params)}`,
  );
};

/**
 * 获取访客类型列表
 * @returns
 */
 export function getVisitorTypeList() {
  return request(`/${RESTFUL_PATH.visitor}/api/visitorMode/listVisitorMode`);
}

/**
 * 获取员工邀请审批配置
 * @returns
 */
 export function getApprovalConfig(params: any) {
  const { companyId, bizType } = params || {}
  return request(`/${RESTFUL_PATH.visitor}/api/company/approvalConfig/${companyId}/${bizType}`);
}