import { requestNoAuthorization } from '@/utils/request';

import { Toast } from 'antd-mobile-v2';
import { history } from 'umi';
import {
  // CHECK_IN_INDEX,
  RESTFUL_PATH,
  // CLOUD_CLIENT_ID,
  // CLOUD_CLIENT_SECRET,
} from '@/utils/constant';
import { isIE } from './download';
import { base64String } from './encryption';

let isRefreshing = false;

const parseJSON = (response) => {
  switch (response.status) {
    case 211:
    case 212: {
      response.json().then((d) => {
        Toast.fail({
          title: d.msg,
          onOk: () => {
            history.push('/');
          },
        });
      });
      return false;
    }
    case 213: // 试用版
      sessionStorage.setItem('type', 1);
      return response.json();
    case 214: // 标准版
      sessionStorage.setItem('type', 2);
      return response.json();
    case 215: // 高级版
      sessionStorage.setItem('type', 3);
      return response.json();
    case 400:
      return response.json();
    default:
      return response.json();
  }
};

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  let msg = '';
  switch (Number(String(response.status).substring(0, 1))) {
    case 3:
      msg = '请求的资源已经被重定向到其他服务器，请联系管理员';
      break;
    case 4:
      if (response.status === 401) {
        msg = '401';
      } else {
        return response;
      }
      break;
    case 5:
      msg = '网络异常，请过一段时间再来重试';
      break;
    default:
      msg = response.statusText;
  }
  const error = new Error(msg);
  error.response = response;
  throw error;
};

/* 刷新token */
function refreshTokenRequest() {
  const refreshToken = JSON.parse(sessionStorage.getItem('auth')).refresh_token;
  // const Authorization = base64String(`${CLOUD_CLIENT_ID}:${CLOUD_CLIENT_SECRET}`);
  const Authorization = base64String(`CLOUD_CLIENT_ID:CLOUD_CLIENT_SECRET`);
  return requestNoAuthorization(
    `/virsical-auth/oauth/token?grant_type=refresh_token&refresh_token=${encodeURIComponent(
      refreshToken,
    )}`,
    {
      headers: {
        Authorization: `Basic ${Authorization}`,
      },
    },
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data && data.access_token) {
        sessionStorage.setItem('auth', JSON.stringify(data));
        sessionStorage.setItem('access_token', `${data.token_type} ${data.access_token}`);
        return data;
      }
      Toast.fail('用户信息失效，请重新登录');
      sessionStorage.clear();
      history.push('/');
      return true;
    })
    .then(() => {
      isRefreshing = false;
    });
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "requestNoAuthorization"
 * @return {object}           An object containing either "data" or "err"
 */

export const requestNoToken = (urls, option, isForm) => {
  const options = option;
  let url = urls;
  if (!isForm && options && Object.prototype.hasOwnProperty.call(options, 'method')) {
    if (!options.headers['Content-Type']) {
      options.headers['Content-Type'] = 'application/json';
    }
  } else if (isIE()) {
    url += `${url.indexOf('?') > -1 ? '&' : '?'}t=${Date.now()}`;
  }
  if (isForm) {
    options.headers = {};
    options.headers.Authorization = `bearer ${sessionStorage.getItem('access_token')}`;
  }

  return requestNoAuthorization(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => data)
    .catch(async (err) => {
      if (err.response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;
          await refreshTokenRequest();
          // } else {
          //   while (isRefreshing) {
          // 刷新token时，其他请求等待
          // sleep(300);
          // }
        }
        options.headers.Authorization = sessionStorage.getItem('access_token') || '';
        return requestNoAuthorization(url, options)
          .then(checkStatus)
          .then(parseJSON)
          .then((data) => data)
          .catch((error) => {
            if (JSON.stringify(error).indexOf('json')) {
              throw new Error('页面正在赶来途中...');
            } else {
              throw new Error(error);
            }
          });
      }
      throw new Error(err);
    });
};

export default (urls, option = { headers: {} }) => {
  const options = option;
  let url = urls;
  if (!Object.prototype.hasOwnProperty.call(options, 'headers')) {
    options.headers = {};
  }
  if (sessionStorage.getItem('mark') && sessionStorage.getItem('mark') === 'machine') {
    options.headers.deviceType = 0;
  }
  // let signUrl = '';
  const tokenSn = sessionStorage.getItem('user')
    ? JSON.parse(sessionStorage.getItem('user')).tokenSN
    : '';
  // if (url.indexOf('?') > -1) {
  //   signUrl = `${url}&tokenSn=${tokenSn}`;
  // } else {
  //   signUrl = `${url}?tokenSn=${tokenSn}`;
  // }
  // if (options.body) {
  //   signUrl += `&reqstr=${encodeURIComponent(options.body)}`;
  // }
  // options.headers.SIGNATURE = getSign(signUrl, sessionStorage.getItem('token'));
  options.headers.TOKENSN = tokenSn;
  options.headers.type = sessionStorage.getItem('type') || '';
  options.headers.token = sessionStorage.getItem('token') || '';
  options.headers.lang = localStorage.getItem('locale') || '';
  options.headers.Authorization = sessionStorage.getItem('access_token') || '';
  if (RESTFUL_PATH) {
    url = RESTFUL_PATH + url;
  }
  return requestNoToken(url, options);
};

// 获取URL地址的参数值。
// name为URL参数名
// 例如：?param1=abc&param2=123
// 当调用GetURLparam("param2"）时，获取到的值为：123
export const getUrlParam = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = window.location.search.substr(1).match(reg);
  if (r !== null) return r[2];
  return null;
};

/**
 * 将以base64的图片url数据转换为Blob
 * @param urlData
 *  用url方式表示的base64图片数据
 */
export const convertBase64UrlToBlob = (urlData) => {
  const bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte

  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i += 1) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/png' });
};

/**
 * 用于判断访问客户端为手机/pc浏览器
 */
export const isPC = () => {
  const userAgentInfo = navigator.userAgent;
  const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let flag = true;
  for (let v = 0; v < Agents.length; v += 1) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};
