/**
 * Created : vincent
 * Date : 2019-03-20  19:22
 * Email : wangxiao@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export async function queryCurrentUserInfo() {
  return request(`/${RESTFUL_PATH.admin}/user/info`);
}

export async function queryCurrentUserDept(deptId) {
  return request(`/${RESTFUL_PATH.admin}/dept/${deptId}`);
}

export async function getUserAccessQrCode(params) {
  return request(`/${RESTFUL_PATH.access}/api/qrCode/createQrCode?clientId=mobile`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export async function updateUserAccessQrCode(qrCode) {
  return request(`/${RESTFUL_PATH.access}/api/qrCode/flushQrCode`, {
    method: 'POST',
    body: JSON.stringify({
      clientId: 'mobile',
      qrCode,
    }),
  });
}
