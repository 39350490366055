import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

// 检查套餐是否过期
export async function checkSubscribe() {
  return request(`/${RESTFUL_PATH.cloudOms}/envMonitor/subscribeExpired/check`);
}

export async function deviceList() {
  return request(`/${RESTFUL_PATH.map}/space-device/queryByDeviceType?deviceType=EnvirSensor`);
}

export async function currentData(payload) {
  return request(
    `/${RESTFUL_PATH.vap}/spaceDevice/getDeviceDataShow?deviceNumber=${payload}&typeCode=EnvirSensor`,
  );
}

// 获取厂商实时数据
export async function getRealData(payload) {
  return request(`/${RESTFUL_PATH.cloudOms}/envMonitor/device/realTimeData?deviceNumber=${payload || ''}`);
}

// 获取设置信息
export async function getEnvSetting() {
  return request(`/${RESTFUL_PATH.cloudOms}/envMonitor/screen/setting`);
}
