
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';
import url from "url"


export async function getDeviceControl(payload) {
  return request(`/${RESTFUL_PATH.vap}/spaceDevice/getDeviceDataModel/${payload}/deviceControl`, {
    method: 'GET',
  });
}

export async function selectListByModelInstance() {
  return request(`/${RESTFUL_PATH.vap}/spaceResource/selectListByModelInstance`, {
    method: 'GET',
  });
}


export async function deviceControl({id,body}) {
  return request(`/${RESTFUL_PATH.vap}/spaceDevice/deviceControl/${id}`, {
    method: 'post',
    body:JSON.stringify(body)
  });
}


export async function getResourceDetail(payload) {
  const url = `/${RESTFUL_PATH.map}/space-resource/${payload}`;
  return request(url, {
    method: 'GET',
  });
}

export async function getdeviceTypeList() {
  return request(`/${RESTFUL_PATH.map}/object-model/deviceTypeList`);
}

export async function selectSpaceDeviceUnderSpaceResource(id) {
  return request(`/${RESTFUL_PATH.vap}/spaceResource/selectSpaceDeviceUnderSpaceResource/${id}`);
}


export async function getAllDeviceGroupUnderSpaceResource(payload) {
  return request(`/${RESTFUL_PATH.vap}/spaceResource/getAllDeviceGroupUnderSpaceResource${url.format({
    query:{
      relationId:payload,relationType:1
    }
  })}`, {
    method: 'GET',
  });
}


export async function selectAllSceneList(payload) {
  return request(`/${RESTFUL_PATH.vap}/iotScene/selectAllSceneList${
    url.format({
      query:payload
    })
  }`, {
    method: 'GET',
  });
}

export async function getAllSpaceDeviceUnderSpaceResource(payload) {
  return request(`/${RESTFUL_PATH.vap}/spaceResource/getAllSpaceDeviceUnderSpaceResource${
    url.format({
      query:payload
    })
  }`, {
    method: 'GET',
  });
}


export async function deviceGroupExecute({id,body}) {
  return request(`/${RESTFUL_PATH.vap}/iot/device/group/deviceGroupExecute/${id}`, {
    method: 'post',
    body:JSON.stringify(body)
  });
}


export async function updateGroupById(payload) {
  return request(`/${RESTFUL_PATH.vap}/iot/device/group/updateGroupById${
    url.format({
      query:payload
    })
  }`, {
    method: 'post',
  });
}

export async function getMultipleDeviceLastTelemetry(payload) {
  // debugger;
  return request(`/${RESTFUL_PATH.vap}/dashBoard/getMultipleDeviceLastTelemetry?deviceNumberList=${payload}`, {
    method: 'get',
  });
}
export async function updateSceneStatus(payload) {
  return request(`/${RESTFUL_PATH.vap}/iotScene/updateSceneStatus${
    url.format({
      query:payload
    })
  }`, {
    method: 'post',
  });
}

export async function getworkspace() {
  return request(`/${RESTFUL_PATH.map}/space-resource/page?current=1&size=10&categoryCode=office-space&labelName=&productCode=w`, {
    method: 'get',
  });
}

export async function getTemp(relationId) {
  return request(`/${RESTFUL_PATH.vap}/controlScreenTemplate/selectTemplateUnderResource?relationType=1&relationId=${relationId}`, {
    method: 'get',
  });
}

export async function executeScene(payload) {
  return request(`/${RESTFUL_PATH.vap}/iotScene/executeScene${
    url.format({
      query:payload
    })
  }`, {
    method: 'post',
    body:JSON.stringify(payload)
  });
}

export async function executeScenetemp(payload) {
  return request(`/${RESTFUL_PATH.vap}/iotScene/executeSceneControlTemplate${
    url.format({
      query:payload
    })
  }`, {
    method: 'post'
  });
}
