/* 1.6.5 新增文件 部分app接口独立处理  */
import { RESTFUL_PATH } from '@/utils/constant';
import request from '@/utils/request';
import { Toast } from 'antd-mobile-v2';
import ImageCompressor from 'image-compressor.js';
import { requestNoToken } from '../utils/request';
import { stringify } from 'qs';

/* 统计分析 查询 */
export const getStatisticalAnalysisData = ({ payload }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/report/statisticRpt?startTime=${payload.startTime}&endTime=${payload.endTime}`,
  );
};
/* 预约数目 查询 */
export const getReservationSum = ({ payload }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/invitations/waitConfirm?companyId=${payload.companyId}&employeeId=${payload.employeeId}`,
  );
};
export const getVstNotCfmCount = ({ userId }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/visitorData/getAuditCountByUserId/${userId}`);
};
export const getAuditData = ({ page, rows, vstKeyword }) => {
  return request(
    `/${
      RESTFUL_PATH.visitor
    }/api/app/getAuditData?page=${page}&rows=${rows}&vstKeyword=${encodeURIComponent(vstKeyword)}`,
  );
};
/**
 * 名片识别
 * @param payload
 * @returns {Object}
 */
export const businessCard = ({ payload }) => {
  const imageCompressor = new ImageCompressor();
  // const Authorization = sessionStorage.getItem('access_token');
  return imageCompressor
    .compress(payload.file[0].file, { quality: 0, toWidth: 1 })
    .then((result) => {
      const formData = new FormData();
      formData.append('file', result, result.name);
      return requestNoToken(
        `/${RESTFUL_PATH.visitor}/api/businessCard`,
        {
          method: 'POST',
          body: formData,
          // headers: {
          //   token: sessionStorage.getItem('token'),
          //   Authorization,
          // },
        },
        true,
      );
    })
    .catch((err) => {
      Toast.fail('上传失败，请稍后重试。');
      window.console.error(err);
    });
};

export const getVisitType = () => {
  return request(`/${RESTFUL_PATH.visitor}/api/vType`);
};
export const getVisit = ({ id }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/visitors/getParamByVisitorDataId/${id}`);
};
export const getAuditCountByUserId = ({ userId }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/app/getAuditCountByUserId/${userId}`);
};
export const getByTenantIdAndTelephone = ({ payload }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/face/getFaceByVendor`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const faceUpload = ({ payload }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/face/upload`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};
export const batchJudge = ({ payload }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/blackList/batchJudge`, {
    method: 'POST',
    body: JSON.stringify(payload.visitorTemp),
  });
};
export const updateFaceDataIdByPreId = ({ payload }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/updateFaceDataIdByPreId`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const findDetailByTenantIdAndPreId = ({ payload }) => {
  const { tenantId, preId } = payload;
  return request(
    `/${RESTFUL_PATH.visitor}/api/customForm/findDetailByTenantIdAndPreId/${tenantId}/${preId}`,
  );
};

export const getCustomForm = ({ payload }) => {
  const { tenantId, locationId, visitorTypeId, purposeId, isTemp } = payload;
  return request(
    `/${RESTFUL_PATH.visitor}/api/customForm/getForm?tenantId=${tenantId}&locationId=${locationId}&visitorTypeId=${visitorTypeId}&purposeId=${purposeId}&isTemp=${isTemp}`,
  );
};
/**
 * 5110版本审批同意、拒绝设置为一个接口
 * @param {payload} param0
 */
export const reviewBatch = ({ payload }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/review/batch`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

// 获取开关
export async function getSwitchConfig({ companyId }) {
  return request(`/${RESTFUL_PATH.visitor}/api/company/getSwitchConfig?companyId=${companyId}`);
}

// 获取是否显示切换数据源
export const getMaxRole = () => {
  return request(`/${RESTFUL_PATH.visitor}/api/employees/getMaxRole`);
};

export const getByBatchGroupId = ({ batchGroupId, lang }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/wx/getByBatchGroupId?batchGroupId=${batchGroupId}&lang=${lang}`,
  );
};

// 获取健康调查内容
export const getHealth = ({ companyId, locationManageId }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/wx/getHealthSurvey?companyId=${companyId}&locationManageId=${locationManageId}`,
  );
};

// 更新健康调查选项
export const withoutNoticeOption = ({ payload }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/withoutNoticeOption`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

// 获取短信页面会议信息
export const getMeetingInfoByVisitId = ({ meetingId }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/invitations/getMeetingInfoByVisitId?meetingId=${meetingId}`,
  );
};

// 批量获取自定义表单
export const findDetailByBatchAddGroupId = ({ batchAddGroupId }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/customForm/findDetailByBatchAddGroupId/${batchAddGroupId}`,
  );
};

// 短信链接页面获取会议数据
export const getExternalInfo = ({ userId }) => {
  return request(`/${RESTFUL_PATH.meeting}/feed-back/external/${userId}`);
};

// 上传人脸--会议
export const faceUploadMeeting = (params) => {
  return request(`/${RESTFUL_PATH.meeting}/feed-back/update-face`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

// batchAddGroupId 获取办公地、会议数据
export const getInfoByBatchId = ({ batchAddGroupId }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/invitations/getInfoByGroupId?batchAddGroupId=${batchAddGroupId}`,
  );
};

// 获取邀请码、二维码
export const getInvitationCode = ({ payload }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/refresh/qrcode?preId=${payload.preId}&category=${payload.category}`,
  );
};

export const getSettings = ({ tenantId }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/mobileStyleSettings/getSettings?tenantId=${tenantId}`,
  );
};

// 获取会议配置
export const getMeetingConfigAll = () => {
  return request(`/${RESTFUL_PATH.meeting}/sys-module/all`);
};

// 邀请函补发
export const noticeResend = ({ preRegistrationId }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/invitations/noticeResend?preRegistrationId=${preRegistrationId}`,
  );
};

// 获取会议配置
export const getMeetingConfig = ({ userId, tenantId }) => {
  return request(
    `/${RESTFUL_PATH.meeting}/third/getMeetingConfig?userId=${userId}&tenantId=${tenantId}`,
  );
};

// 获取微信小程序 URL Scheme
export const getMiniScheme = ({ companyId }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/wx/getMiniScheme?companyId=${companyId}`);
};

/**
 * 创建访客补填
 * @param params 访问信息、访客信息
 * @returns
 */
export const saveVisitorFill = (params) => {
  console.log(params, 'params_service');
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/saveCommonInfo`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

// new 选择会议室区域
export function getAllZones() {
  return request(`/${RESTFUL_PATH.upms}/space/tree`, {
    method: 'GET',
  });
}

/**
 * 获取历史访客列表
 * @param 分页参数、筛选参数
 * @returns
 */
export const getHistoryVisitorData = (params) => {
  const defaultParams = {
    page: 1,
    rows: 10000,
    vstKeyword: '',
    sortColumn: 'visitorId',
    sortDirection: 'desc',
  };
  const handledParams = { ...defaultParams, ...params };
  delete handledParams.vstKeyword;
  const handledVstKeyword = params?.vstKeyword?.trim() || '';
  return request(
    `/${RESTFUL_PATH.visitor}/api/visitor/list?vstKeyword=${encodeURIComponent(
      handledVstKeyword,
    )}&${stringify(handledParams)}`,
  );
};

/**
 * 删除历史访客
 * @param visitorId
 * @returns
 */
export const deleteHistoryVisitor = ({ visitorId }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/visitor/delete/${visitorId}`);
};

// 根据手机、邮箱、员工id查询历史访客数据
export const filterHistoryVisitor = (params) => {
  console.log(params, 'params');
  return request(`/${RESTFUL_PATH.visitor}/api/visitor/findVisitor`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

/**
 * 批量添加/更新访客对象至历史访客列表
 */
export const batchSaveAndUpdateHistoryVisitor = (params) => {
  return request(`/${RESTFUL_PATH.visitor}/api/visitor/batchSaveAndUpdate`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};
