export default {
  'attendee.search': '搜索',
  'attendee.cancel': '取消',
  'contact.button.select': '選擇',
  'contact.button.showMore': '顯示更多',
  'contact.load.fail': '需要在手機"設置"中打開威思客獲取通訊錄的權限',
  'meeting.attendee.page.page.value1': '該部門下沒有任何員工！',

  frequent_concat: '常用聯繫人',
};
