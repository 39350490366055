/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'scan.auth.page.product.code.title': 'Current',
  'scan.auth.page.product.code.title.workspace': 'Station',
  'scan.auth.page.product.code.title.meeting-room': 'Meeting',
  'scan.auth.page.product.code.title.locker': 'Locker',
  'scan.auth.page.product.code.space': 'Location',
  'scan.auth.page.product.code.name': ' Name',
  'scan.auth.page.product.code.number': 'Number',
  'scan.auth.page.product.code.dept': 'Departmental',
  'scan.auth.page.product.code.login.bind': 'Login Binding',
  'scan.auth.page.product.code.more.info': 'More Information',
  'scan.auth.page.product.code.more.action': 'More Actions',
  'scan.auth.page.product.code.action.report': 'Report Requirements',
  'scan.auth.page.product.code.action.report.warn': 'This function is not supported for the time being',

};
