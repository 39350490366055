import returnCode from '@/pages/Meeting/locales/zh-TW/returnCode';
import indexBottom from '@/pages/Meeting/locales/zh-TW/indexBottom';
import common from '@/pages/Meeting/locales/zh-TW/common';

export default {
  ...common,
  ...returnCode,
  ...indexBottom,
  'upload.success': '上傳成功',
  'upload.fail': '上傳失敗',
  home: '主頁',
  subscribe: '預訂',
  confirm: '確定',
  location: '會議室',
  subject: '會議主題',
  host: '主持人',
  applicantsSelect: '請選擇',
  applicants: '主持人',
  participants: '參會人',
  time: '會議時間',
  timeZone: '時區',
  delete: '刪除',
  back: '返回',
  none: '沒有',
  zoomMain: '主會場',
  zoomType: '會議形式',
  zoomScale: '參會人數',
  zoomType0: '會議（在線會議、培訓與技術支持）',
  zoomType1: '視頻網絡研討會（市場推介與全員大會）',
  zoomScale0: '免費 40分鐘 100人',
  zoomScale1: '<100人',
  zoomScale2: '<300人',
  zoomScale3: '<500人',
  zoomScale4: '<1000人',

  'meeting.notice.none': '無',
  'meeting.notice.sms': '短訊',
  'meeting.notice.email': '郵件',
  'meeting.notice.vsk': '威思客',
  'meeting.notice.wechat': '微信',
  'meeting.notice.dingtalk': '釘釘',
  'meeting.device': '設備申請',
  'meeting.services': '服務申請',
  'meeting.room.fee': '會議室費用',

  // 服務端返回key
  'msg.login.failed': '登錄失敗！',
  'msg.login.success': '登錄成功！',
  'result.back.sys.value3': '當前用戶不存在!',
  'result.back.sys.value6': '當前用戶不是當前域用戶！',
  'result.back.device.error.value1': '當前用戶無權修改設備狀態！',
  'result.back.scanning.qr.lock.success': '會議鎖屏成功！',
  'result.back.scanning.qr.unlock.success': '會議解除鎖屏成功！',
  'result.back.scanning.qr.notice.success': '提醒成功！',
  'result.back.sys.error': '當前操作失敗,請檢查參數或後台日誌',

  online_meeting_type: '會議類型',

  main_meeting_zoom_poll: '調查',
  main_meeting_zoom_advance: '高級選項',
  main_meeting_zoom_wait: '啟用等候室',
  main_meeting_zoom_join: '允許在主持人入會前加入',
  main_meeting_zoom_mute: '加入會議時靜音',
  main_meeting_zoom_record: '自動將會議錄制到本地計算機',
  main_meeting_webex_auto: '系統自動選擇Webex線路',
  main_meeting_zoom_auto: '系統自動選擇Zoom線路',

  // zoom 参会统计
  meeting_detail_zoom_participants: '參會統計',
  meeting_detail_zoom_polling: '調查結果',
  zoom_participants_label_device: '設備',
  zoom_participants_label_ip: 'IP地址',
  zoom_participants_label_location: '位置',
  zoom_participants_during_minute: '停留{minute}分鐘',
  zoom_participants_no_data: '會議使用的Zoom賬號，沒有查詢參會統計的權限。',
  zoom_participant_info_pedding: '數據正在統計中，請稍後再試。',
  zoom_participant_info_ex: '數據統計異常，請聯系管理員。',
  zoom_participant_info_nostart: '該zoom會議尚未開啟。',
  meeting_audit_accept_fail: '會議時間衝突，審核失敗！',

  'meeting.booking.step.tencent.inner': '僅企業內部成員可入會',
  'meeting.booking.step.tencent.before': '允許成員在主持人進會前加入會議',
  meeting_tencent_no_resource: '該時間段內暫無可用騰訊會議資源!',
};
