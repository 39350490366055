export default {
  main_meeting_zoom_poll_subject_add: 'Add a Question',
  main_meeting_zoom_poll_remove: 'Delete',
  main_meeting_zoom_poll_publish: 'Submit',
  main_meeting_zoom_poll_tool_edit: 'Edit',
  main_meeting_zoom_poll_tool_copy: 'Copy',
  main_meeting_zoom_poll_tool_up: 'Up',
  main_meeting_zoom_poll_tool_down: 'Down',
  main_meeting_zoom_poll_tool_del: 'Delete',
  main_meeting_zoom_poll_single_subject: 'Single Choice',
  main_meeting_zoom_poll_multiple_subject: 'Multiple Choice',
  main_meeting_zoom_poll_confirm: 'Are you sure you want to delete the polling？',
};
