export default {
  'visitor.mobile.invitation.code.visitor.name': '{name}，您好！',
  'visitor.mobile.invitation.code.visitor.clues': '如下信息可協助您的到訪，祝您到訪順利！',
  'visitor.mobile.invitation.code.visitor.inviteCode': '邀請碼',
  'visitor.mobile.invitation.code.visitor.look.navigation': '查看導航',
  'visitor.mobile.invitation.code.visitor.signTime': '到訪時間{colon}',
  'visitor.mobile.invitation.code.visitor.address': '接待地點{colon}',
  'visitor.mobile.invitation.code.visitor.meetingInfo': '會議信息{colon}',
  'visitor.mobile.invitation.code.visitor.click.look': '點擊查看',
  'visitor.mobile.invitation.code.healthCode.tip.title': '健康碼異常',
  'visitor.mobile.invitation.code.tourCode.tip.title': '行程卡異常',
  'visitor.mobile.invitation.code.healthCode.tip.failure': '健康碼失效',
  'visitor.mobile.invitation.code.tourCode.tip.failure': '行程卡失效',
  'visitor.mobile.invitation.code.btn.cancel': '取消',
  'visitor.mobile.invitation.code.upload.again': '重新上傳',
  'visitor.mobile.invitation.code.locker.tip': '可使用訪客專屬儲物櫃',
  'visitor.mobile.invitation.code.visitor.wifi': 'WIFI信息{colon}',
  'visitor.mobile.invitation.code.visitor.wifi.title': 'WIFI信息',
  'visitor.mobile.invitation.code.visitor.wifi.name': 'WIFI',
  'visitor.mobile.invitation.code.visitor.wifi.code': '驗證碼',
  'visitor.mobile.invitation.code.visitor.wifi.until': '有效期',
  'visitor.mobile.invitation.code.visitor.wifi.account': '賬號',
  'visitor.mobile.invitation.code.visitor.wifi.password': '密碼',
  'visitor.mobile.invitation.code.visitor.wifi.day': '{num}天',
  'visitor.mobile.invitation.code.visitor.wifi.hour': '{num}小時',
  'visitor.mobile.invitation.code.visitor.wifi.min': '{num}分鐘',
  'visitor.mobile.invitation.code.visitor.detail.address': '詳細地址',
  'visitor.mobile.invitation.code.accepted': '已知曉並同意',
};