export default {
  'sws.search.show.station.line': 'Show Routine',
  'sws.search.hidden.station.line': 'Hide Routine',
  'sws.search.error.tip': 'We don’t any of result as your search',
  'sws.search.station.tip': 'Please enter a name in the search box to find his/her desk details',
  'sws.search.station.placeholder': 'Input Name',
  'sws.search.no.path': 'No guidance at this station',
  'sws.search.go': 'Navigation',
  'sws.search.nav.start': 'Navigate',
  'sws.search.nav.end': 'End',
  'sws.search.no.floor': 'Not on the same floor does not support simulated navigation',
  'sws.search.no.startEnd': 'The starting point cannot be the same',
  'sws.search.no.search':
    'It is not allowed to modify the starting position when the navigation is not over, please end the navigation first',
  'sws.search.start.location': 'Start',
  'sws.search.end.location': 'End',
  'sws.search.start.value': 'Reception',
  'sws.search.look': 'Quick Reservation',
  'sws.search.back': 'Back to Search',
  'sws.search.people.no.station': 'No station information for {value1} was queried',
  'sws.search.free': 'Free',
  'sws.search.occupy': 'ccupied',
  'sws.search.no.station': 'There are no currently booked or assigned desk for this person',
  401142: 'The selected time period is not a working day, so an appointment cannot be made',
};
