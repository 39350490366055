/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.applyAccess.title': '无权限门禁',
  'access.applyAccess.btn.submit': '提交',
  'access.applyAccess.apply.reason': '请输入申请理由',
  '601001': '获取门禁列表失败',
  '601002': '获取用户名失败',
  '601003': '数据长度超过限制',

  '601012': '操作失败',
  '601013': '操作成功',
};
