export default {
  'sws.immediate.hours': '小時',
  'sws.immediate.submit': '提交',
  'sws.immediate.station': '工位',
  'sws.immediate.address': '區域',
  'sws.immediate.starttime': '開始時間',
  'sws.immediate.endtime': '結束時間',
  'sws.immediate.ok': '確認',
  'sws.immediate.cancel': '取消',
  'smt.immediate.select': '請選擇',
  'sws.immediate.select.time': '選擇時間',
  'sws.immediate.submit.success': '提交成功',
  'sws.immediate.submit.error': '提交失敗',
  'sws.immediate.no.worktime': '不在上班时间,不能扫码预订',
  401128: '預訂工位未分配部門',
  401140: '傳感判定該工位無人,簽到失敗',
  401141: '傳感器判定工位無人,無法使用工位',

  'sws.immediate.continue': '繼續使用',
  'sws.immediate.tip': '提示',
  401855: '當前工位未綁定插座設備',
  401857: '【{facilityName}】,設備已下線,是否繼續使用？',
  401856: "所選工位已被預約或佔用",

  'sws.immediate.remark.tip': '例如:項目編號'
};
