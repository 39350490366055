/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 预订操作
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function confirmReservation(param) {
  return request(`/${RESTFUL_PATH.sws}/api/reserve/normal`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}


