/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 查询普通预订的信息
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getMybook() {
  const url = `/${RESTFUL_PATH.sws}/api/reserve/list?size=9999&current=1`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 取消预订/注销预订
 * @param payload 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function cancelReservation(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/reserve/cancel?rid=${payload.id}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 查询特殊预订的信息
 * @param payload
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSpecialMybook(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/web/reserve-special?size=9999&current=1`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 查询历史记录
 * @param payload 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getHistory(payload = {}) {
  const url = `/${RESTFUL_PATH.sws}/api/client/history?size=${payload.size ||
    5}&current=${payload.current || 1}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 预订操作
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function confirmReservation(param) {
  return request(`/${RESTFUL_PATH.sws}/api/reservation/synReservation`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

/**
 * 延长预订
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function delayReservation(param) {
  return request(`/${RESTFUL_PATH.sws}/api/client/delay?hour=${param.hour}&id=${param.id}`, {
    method: 'GET',
  });
}
