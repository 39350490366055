import { history } from 'umi';
import { formatMessage } from 'umi';
import moment from 'moment-timezone';
import { ROUTE_PATH } from '@/utils/constant';
import { getScanResult, getSignInResult, getSoonest, fixedBind } from './service';
import { Toast } from 'antd-mobile-v2';

export default {
  namespace: 'swsScan',
  state: {
    scanTip: '',
    // 重新扫码，先按 返回工位首页做
    scanRetry: false,
    reverseInfo: {},
    station: {},
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    * getScanResult({ sId, t }, { call, put }) {
      const result = yield call(getScanResult, sId, t);
      if (result && result.code === 0 && result.data) {
        yield put({
          type: 'swsCommon/saveOrUpdateData',
          payload: { scanStation: result?.data?.object },
        });
        yield put({ type: 'scanSuccess', data: result.data, sId, msg: result.msg || null });
      } else if (result && result.code === 1) {
        let errorText = '';
        if (Object.prototype.hasOwnProperty.call(result, 'data')) {
          errorText = formatMessage({ id: result.msg }, { ...result.data });
        } else {
          errorText = formatMessage({ id: result.msg });
        }
        const scanRetry = String(result?.msg) === '401036';
        yield put({
          type: 'saveOrUpdateData',
          payload: { scanTip: errorText, scanRetry },
        });
        history.replace(`${ROUTE_PATH}/workspace/scanResult`);
      }
    },

    * scanSuccess({ data, sId, msg = null }, { put }) {
      // data.status; 二维码扫码操作码(1-预订；2-签到；3-固定工位绑定；4-固定工位变更；5-固定工位签到,6-签退, 7-固定工位一对多， 8-移动工位踢座， 9-固定工位一对一)
      const { zoneTime } = data?.object;
      // data.status = 9
      switch (data.status) {
        case 1:
          yield put({
            type: 'swsCommon/saveOrUpdateData',
            payload: { timeZone: zoneTime || 'Asia/Shanghai' },
          });
          history.replace(`${ROUTE_PATH}/workspace/station/immediate`);
          break;
        case 2:
          yield put({ type: 'signIn', sId });
          break;
        case 3:
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              station: data.object,
              scanTip: formatMessage({ id: 'sws.scan.fixed.bind' }),
            },
          });
          history.replace(`${ROUTE_PATH}/workspace/scanSuccess`);
          break;
        case 4:
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              station: data.object,
              scanTip: formatMessage({ id: 'sws.scan.fixed.change' }),
            },
          });
          history.replace(`${ROUTE_PATH}/workspace/scanSuccess`);
          break;
        case 5:
          yield put({
            type: 'saveOrUpdateData',
            payload: { scanTip: formatMessage({ id: 'sws.scan.fix.error' }), scanRetry: false },
          });
          history.replace(`${ROUTE_PATH}/workspace/scanResult`);
          break;
        case 6:
          history.replace(`${ROUTE_PATH}/workspace/station/signOut`);
          break;
        case 7:
          history.replace(`${ROUTE_PATH}/workspace/station/fixed`, { type: 'oneToMany', msg });
          break;
        case 8:
          history.replace(`${ROUTE_PATH}/workspace/station/cover`, { data: data });
          break;
        case 9:
          history.replace(`${ROUTE_PATH}/workspace/station/fixed`, { type: 'oneToOne', msg });
          break;
        default:
          break;
      }
    },
    // 签到
    * signIn({ sId }, { call, put, select }) {

      const result = yield call(getSignInResult, sId);
      let { menuData } = yield select((state) => state.menu);
      // console.log('sign in', result)
      if (result && result.code === 0 && result.data) {
        // time 2020-2-18  14:00~15:00
        const { stationNums, address, startTime, endTime, locationTimeZone } = result.data;
        const timeZone = locationTimeZone;
        const day = moment.tz(startTime, timeZone).format(formatMessage({ id: 'sws.time.date' }));
        const sTime = moment.tz(startTime, timeZone).format('HH:mm');
        const eTime = moment.tz(endTime, timeZone).format('HH:mm');
        // console.log('signIn', s, e, day, sTime, eTime);
        const locationNames = address?.split(',').slice(-3);

        history.replace({
          pathname: `${ROUTE_PATH}/workspace/common/components/bookingResult`,
          state: {
            choseResult: {
              timeSelected: `${day} ${sTime} ~ ${eTime}`,
              stationNum: stationNums,
            },
            timeZone,
            locationNames,
            isSignIn: true,
          },
        });
      } else if (result && result.code === 1) {
        if (String(result.msg) === '401119') {
          const menuArr = [];
          menuData?.forEach((v) => {
            if (v.path.includes('/workspace')) {
              menuArr.push(...v.children);
            }
          });
          const isNew = menuArr?.filter(v => v.path === '/workspace/station/myBookingNew');
          isNew?.length > 0 ? history.replace(`${ROUTE_PATH}/workspace/station/myBookingNew`) : history.replace(`${ROUTE_PATH}/workspace/station/myBooking`);
        } else {
          if (result.msg === '401120') {
            const time = result.data.value1;
            const { timeZone } = result.data;
            const s = Number(time.split('~')[0].trim());
            const e = Number(time.split('~')[1].trim());
            const day = moment.tz(s, timeZone).format(formatMessage({ id: 'sws.time.date' }));
            const sTime = moment.tz(s, timeZone).format('HH:mm');
            const eTime = moment.tz(e, timeZone).format('HH:mm');
            // console.log('signIn', s, e, day, sTime, eTime);
            const value1 = `${day} ${sTime}-${eTime}`;
            yield put({
              type: 'saveOrUpdateData',
              payload: { scanTip: formatMessage({ id: result.msg }, { value1 }) },
            });
          } else {
            yield put({
              type: 'saveOrUpdateData',
              payload: { scanTip: formatMessage({ id: result.msg }, { ...result.data }) },
            });
          }

          history.replace(`${ROUTE_PATH}/workspace/scanResult`);
        }
      }
    },
    // 获取立即使用界面最近一次即将使用的时间
    * getSoonest({ sId, t }, { call, put }) {
      const result = yield call(getSoonest, sId, t);
      if (result && result.code === 0) {
        yield put({
          type: 'swsCommon/saveOrUpdateData',
          payload: { lastTimeObj: result.data || {} },
        });
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }), 2);
      }
    },
    * fixedBind({ data, isReturn = false }, { call, put/* , select */ }) {
      Toast.loading('Loading...', 0);
      const result = yield call(fixedBind, data);
      if (result && result.code === 0) {
        // const { scanStation } = yield select(s => s.swsCommon)
        Toast.hide();
        console.log(result, 'resultresultresultresultresult');
        if (!isReturn) {
          yield put({ type: 'scanSuccess', data: result.data });
        } else {
          return true;
        }
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }), 2);
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname, query }) => {
        if (pathname && pathname === '/workspace/qrcode') {
          const { sId = 8, t } = query;
          // t = t || moment().tz('Asia/Shanghai').format('YYYYMMDD')
          dispatch({ type: 'swsCommon/getHoliday' }).then(() => {
            dispatch({ type: 'swsCommon/getSystemSet' }).then(() => {
              dispatch({ type: 'getScanResult', sId, t });
              dispatch({ type: 'getSoonest', sId });
            });
          });
        }
      });
    },
  },
};
