export default {
  'meeting.manage.title.service': '會議服務申請',
  'meeting.manage.title.device': '移動設備申請',
  'meeting.manage.need.attend_': '參會人：',
  'meeting.manage.sign.in': ' 人簽到, ',
  'meeting.manage.signed.people': '人',
  'meeting.manage.signed.people,': '人，',
  'meeting.manage.signed.in': '已簽到',
  'meeting.manage.not.sign': '未簽到',
  'meeting.manage.participant.not.sign': ' 人未簽到',
  'meeting.manage.sign.time_': '簽到時間：',
  'meeting.manage.retroactive': '補簽',
  'meeting.manage.service.type_': '服務項目：',
  'meeting.manage.service.level_': '服務等級',
  'meeting.manage.service.level.select.title':'請選擇服務等級',
  'meeting.manage.service.payer_': '代付人',
  'meeting.manage.service.payer_unset': '未指定',
  'meeting.manage.service.describe_': '描述：',
  'meeting.manage.service.category_': '服務類別：',
  'meeting.manage.service.unit.price': '元 / {unit}',
  'meeting.manage.service.unit.yuan': '元',
  'meeting.manage.service.upload': '上傳',
  'meeting.manage.service.input.remark': '請輸入備註文字',
  'meeting.manage.service.total_': '總計：',
  'meeting.manage.service.fee.tip': '會議服務僅對就近壹場會議生效!',
  'meeting.manage.service.level': '請選擇服務类别',
  'meeting.manage.service.noData': '暫無會議服務',
  'meeting.manage.device.type_': '設備類型：',
  'meeting.manage.device.describe_': '描述：',
  'meeting.manage.device.add': '添加移動設備',
  'meeting.manage.device.all': '全選',
  'meeting.manage.device.desc.null': '無設備描述',
  'meeting.manage.back.home': '返回首頁',
  'meeting.manage.previous.page': '返回上一頁',
  'meeting.manage.device.empty': '請聯繫管理員，為該會議室所在區域增加移動設備',
  'meeting.manage.back.mine': '會議列表',

  // 文件上傳
  'meeting.manage.upload.file.type.fail': '上傳文件類型不對',
  'meeting.manage.upload.file.fail.value1': '上傳照片不能大於5M',
  'meeting.manage.upload.file.fail.value2': '上傳文件不能大於10M',
  'meeting.manage.upload.file.fail.value3': '文件格式解析失敗',
  'meeting.manage.upload.file.fail.value4': '已選擇會議服務需要上傳附件!',
};
