/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'app.vap.meetingControl.tab1': 'Device Control',
  'app.vap.meetingControl.tab2': 'Scene Mode',
  'app.vap.temp.error1': 'No template under resources',
};
