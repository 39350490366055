/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.applyAccess.title': 'Unauthorized Access',
  'access.applyAccess.btn.submit': 'Submit',
  'access.applyAccess.apply.reason': 'Please enter the reason for application',

  '601001': 'Unable to get access control list',
  '601002': 'Failed to get username',
  '601003': 'Data length exceeds limit',

  '601012': 'The operation failed',
  '601013': 'The operation was successful',
};
