import React, { useMemo, useState } from 'react';
import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { useGetShareInviteLink, useRequest } from '@/pages/Visitor/pages/hooks';
import * as AppService from '../services/AppService';

export default function () {
  // const [historyVisitorData, setHistoryVisitorData] = useState(null);

  /**
   * 获取历史访客
   */
  const {
    data: historyVisitorData,
    run: runGetHistoryVisitorData,
    loading: getHistoryVisitorDataLoading,
  } = useRequest((params?: any) => AppService.getHistoryVisitorData(params), {
    debounceWait: 300,
    manual: true,
  });

  /**
   * 删除历史访客
   */
  const { runAsync: runDeleteHistoryVisitor, loading: deleteHistoryVisitorLoading } = useRequest(
    (params?: any) => AppService.deleteHistoryVisitor(params),
    {
      manual: true,
      onSuccess: () => {
        Toast.success(formatMessage({ id: '303004' }));
      },
    },
  );

  /**
   * 批量添加/更新访客对象至历史访客列表
   */
  const {
    runAsync: runBatchSaveAndUpdateHistoryVisitor,
    loading: batchSaveAndUpdateHistoryVisitorLoading,
  } = useRequest((params?: any) => AppService.batchSaveAndUpdateHistoryVisitor(params), {
    manual: true,
  });

  /**
   * 根据手机、邮箱、员工id查询历史访客数据
   */
  const { runAsync: runFilterHistoryVisitor, loading: filterHistoryVisitorLoading } = useRequest(
    (params?: any) => AppService.filterHistoryVisitor(params),
    {
      manual: true,
    },
  );

  const state = useMemo(() => {
    return {
      /**
       * 获取历史访客
       */
      historyVisitorData,
      getHistoryVisitorDataLoading,
      /**
       * 删除历史访客
       */
      deleteHistoryVisitorLoading,
      /**
       * 批量添加/更新访客对象至历史访客列表
       */
      batchSaveAndUpdateHistoryVisitorLoading,
      /**
       * 根据手机、邮箱、员工id查询历史访客数据
       */
      filterHistoryVisitorLoading,
    };
  }, [
    historyVisitorData,
    getHistoryVisitorDataLoading,
    deleteHistoryVisitorLoading,
    batchSaveAndUpdateHistoryVisitorLoading,
    filterHistoryVisitorLoading,
  ]);

  const actions = useMemo(() => {
    return {
      /**
       * 获取历史访客
       */
      runGetHistoryVisitorData,
      /**
       * 删除历史访客
       */
      runDeleteHistoryVisitor,
      /**
       * 批量添加/更新访客对象至历史访客列表
       */
      runBatchSaveAndUpdateHistoryVisitor,
      /**
       * 根据手机、邮箱、员工id查询历史访客数据
       */
      runFilterHistoryVisitor,
    };
  }, [
    runGetHistoryVisitorData,
    runDeleteHistoryVisitor,
    runBatchSaveAndUpdateHistoryVisitor,
    runFilterHistoryVisitor,
  ]);

  return {
    state,
    actions,
  };
}
