export default {
  'sws.inventory.title': '工位盘点',
  'sws.inventory.detail.userName': '员工姓名',
  'sws.inventory.detail.userNum': '员工账号',
  'sws.inventory.detail.deptName': '员工部门',
  'sws.inventory.station.stationNum': '手动输入工位编号',
  'sws.inventory.station.scanStationNum': '扫码识别工位编号',
  'sws.inventory.station.remarks': '备注：工位编号如有变化，请在右侧输入或扫码更改',
  'sws.inventory.station.location': '工位位置',
  'sws.inventory.station.ok': '确认',
  'sws.inventory.detail.empty': '当前无需工位盘点～',
  'sws.inventory.qrCode.error': '工位二维码信息不正确',
  401701: '企业微信开关没有打开',
  401702: '钉钉开关没有打开',
  401703: '没有可以盘点的工位',
  401704: '有未盘点的工位，不能发起盘点',
  401705: '未配置盘点有效期',
  401706: '盘点的工位没有员工，不能盘点',
  401707: '工位用户超过三个，盘点确认失败',
  401708: '盘点员工信息异常',
  401709: '用户部门和工位部门不一致，盘点失败',
  401710: '盘点的工位不是固定工位',
  401711: '盘点的工位非空间节点上线状态',
  401712: '用户不能绑定该工位',
  401717: '用户{value1}，没有权限绑定到{value2}部门工位上',
  401719: '用户绑定的工位数量超过限制',
  401858: '该工位已绑定多用户，请先行解绑再调整类型',
  401014: '该工位使用人数已经达到最大限制',
  401861: '您暂无该工位的使用权，如有问题请联系属地楼长',
};
