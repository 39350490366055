/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'login.title': '威思客服务平台',
  'login.title.2': '威思客服务平台是东半球最具影响力的服务平台',
  'login.title.app': '用户登录',
  'login.welcome': '欢迎访问威思客服务平台，点击右侧菜单选择您需要访问的功能。',
  'login.username': '账号',
  'login.username.tip': '请输入账号',
  'validation.userName.required': '请输入账号',
  'login.password': '密码',
  'validation.password.required': '请输入密码',
  'login.forgot.password': '忘记密码',
  'login.remember.password': '记住我',
  'login.login': '登录',
  'login.copy.right': '2019 威发系统有限公司 版权所有',
  'login.err': '登录失败，请检查用户名和密码是否正确，如果不行，请稍后尝试',
  'login.storage.err': '登录信息失效，请重新登录。',
  'login.storage.err.title': '登录信息错误',
  'app.login.message-invalid-credential': '用户名或密码错误',
  'login.forgot.password.email': '邮箱',
  'login.forgot.password.email.required': '请输入您的邮箱',
  'login.forgot.password.email.err': '请输入正确的邮箱格式',
  'login.forgot.password.code': '验证码',
  'login.forgot.password.code.required': '请输入验证码',
  'login.forgot.password.send.email': '发送邮件',
  'login.forgot.password.send.email.success': '邮件发送成功！',
  'login.forgot.password.send.email.success.tip': '邮件已发送至你的邮箱，请去查收邮件吧',
  'login.go.login': '重新登录',
  'login.button.confirm': '确定',
  'login.fail.title': '登录失败',
  'login.fail.title.operate': '操作失败',
  'common.operate.slider.safe': '请完成安全验证',
  'common.operate.slider.barText': '向右滑动完成验证',
  'common.operate.slider.loadingText': '正在加载中...!',
  'common.operate.slider.failedText': '再试一次',

  'login.auth.type.2FA.title': '输入安全码',
  'login.auth.type.2FA.title.placeholder': '请输入安全码',
  102163: '校验失败',
  101080: '安全码校验失败',
  101079: '安全码为空',

  'login.type.SMS.tip': '手机验证码登录',
  'login.type.SMS.tip.1': '账号登录',
  'login.type.SMS.send.title': '获取验证码',
  'login.type.SMS.phone.title.placeholder': '请输入手机号',
  'login.type.SMS.phone.code.title.placeholder': '请输入手机号验证码',
  'login.type.SMS.phone.title': '手机号',
  'login.type.SMS.phone.code.title': '手机验证码',
  'login.type.slide.check.success': '验证成功',
  'login.type.slide.check.fail': '验证失败',
  'cloud.safe.verify.invalid': '验证码已失效，请重新获取',
};
