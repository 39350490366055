/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import { message } from 'antd';
import { formatMessage } from 'umi';
import * as service from './service';

export default {
  namespace: 'basicEdition',
  state: {
    deviceNumber: '',
    currentData: [],
    currentList: [],
    isShow: false,
    errMsg: '',
    isYuandian: '',
    deviceList: '',
    refreshRate: 1,
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *checkSubscribe(_, { call, put }) {
      const { code, msg } = yield call(service?.checkSubscribe);
      if (code === 0) {
        yield put({ type: 'setState', payload: { isShow: true } });
      } else {
        yield put({
          type: 'setState',
          payload: {
            isShow: false,
            errMsg: msg,
          },
        });
        message.error(formatMessage({ id: msg }));
      }
      return code;
    },

    *getEnvSetting(_, { call, put }) {
      const { code, data, msg } = yield call(service?.getEnvSetting);
      if (code === 0) {
        yield put({ type: 'setState', payload: { basicData: data, refreshRate: Number(data?.envMonitorClientRefreshRate) } });
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },

    *deviceList(_, { call, put }) {
      const { code, data, msg } = yield call(service?.deviceList);
      if (code === 0) {
        const arr = data.map(item => {
          return {
            label: item.deviceNumber,
            value: item.deviceNumber
          }
        })
        yield put({ type: 'setState', payload: { deviceList: arr } });
      } else {
        message.error(formatMessage({ id: msg }));
      }
      return data?.[0]?.deviceNumber;
    },
    *currentData({ payload }, { call, put }) {
      const { code, data, msg } = yield call(service?.currentData, payload);
      if (code === 0) {
        yield put({ type: 'setState', payload: { currentData: data?.telemetryData } });
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },

    // 获取厂商实时数据
    *getRealData({ payload }, { call, put }) {
      const { code, data } = yield call(service.getRealData, payload);
      if (code === 0) {
        const { isYuandian, telemetryData, deviceList } = data;
        yield put({ type: 'setState', payload: { isYuandian } });
        if (isYuandian && telemetryData?.length > 0) {
          yield put({ type: 'setState', payload: { currentData: telemetryData, deviceList } });
        }
        return { isYuandian, deviceNumber: deviceList?.[0].value }
      }
    },
  },
  subscriptions: {
    set({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname.endsWith('/envMonitor/dashboard') || pathname.endsWith('/envMonitor/dashboard/')) {
          dispatch({ type: 'checkSubscribe' }).then(res => {
            if (res === 0) {
              dispatch({ type: 'getEnvSetting' });
              dispatch({ type: 'getRealData' }).then(r => {
                if (!r?.isYuandian) {
                  dispatch({ type: 'deviceList' }).then((result) => {
                    if (result) {
                      dispatch({ type: 'setState', payload: { deviceNumber: result } })
                      dispatch({
                        type: 'currentData',
                        payload: result,
                      });
                    }
                  });
                } else {
                  dispatch({ type: 'setState', payload: { deviceNumber: r?.deviceNumber } })
                }
              })
            }
          })
        }
      });
    },
  },
};
