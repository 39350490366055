export default {
  'sws.search.show.station.line': '顯示路線',
  'sws.search.hidden.station.line': '隱藏路線',
  'sws.search.error.tip': '沒有相關搜索結果',
  'sws.search.station.tip': '可以在搜索框輸入姓名進行工位查詢~',
  'sws.search.station.placeholder': '請輸入姓名',
  'sws.search.no.path': '該工位暫無導引線',
  'sws.search.go': '去這裡',
  'sws.search.nav.start': '模擬導航',
  'sws.search.nav.end': '結束導航',
  'sws.search.no.floor': '不在同一樓層不支持模擬導航',
  'sws.search.no.startEnd': '起始點不能是同一個',
  'sws.search.no.search': '導航未結束不允許修改起始位置, 請先結束導航',
  'sws.search.start.location': '請輸入起點',
  'sws.search.end.location': '請輸入終點',
  'sws.search.start.value': '前台',
  'sws.search.look': '查看位置',
  'sws.search.back': '返回上頁',
  'sws.search.people.no.station': '沒有查詢到{value1}的工位信息',
  'sws.search.free': '空閑',
  'sws.search.occupy': '佔用',
  'sws.search.no.station': '當前人員沒有正在預訂或綁定的工位',
  401142: '所選擇的時間段不是工作日，無法預約',
};
