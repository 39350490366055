/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'app.setSetting.lang': '设置语言',
  'app.setSetting.timezone': '设置时区',
  'app.setSetting.zone': '默认区域',
  zh_CN: '简体中文',
  zh_TW: '繁體中文',
  en_US: 'English',
  'Asia/Shanghai': '中国时间',
  'Asia/Tokyo': '日本时间',
  'Asia/Seoul': '韩国时间',
  'Asia/Singapore': '新加坡时间',
  'Australia/Sydney': '东部时间 (新南威尔斯)',
  'Europe/London': '英国时间',
  'Europe/Paris': '中欧时间',
  'Europe/Moscow': '莫斯科时间',
  'America/New_York': '东部时间',
  'America/Chicago': '中部时间',
  'America/Denver': '山地时间',
  'America/Los_Angeles': '太平洋时间',
  'America/Anchorage': '阿拉斯加时间',
  'Pacific/Honolulu': '夏威夷时间',
};
