/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'feedback.textArea.placeholder':'Your valuable comments are welcome, please enter your feedback. ',
  'feedback.picture.add':'Add picture',
  'feedback.btn.submit':'Submit',
  'feedback.btn.get.more':'More feedback',
  'feedback.picture.preview':'Picture Preview',
  'feedback.picture.tips': 'Up to 5 pictures, the picture format can be jpeg/jpg/png, the size does not exceed 5M\n',
};
