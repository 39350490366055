// 判断是不是IE浏览器
import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone/index';
import shortid from 'shortid';

import { TIMEZONE } from '@/utils/constant';

export const isIE = () => {
  const explorer = window.navigator.userAgent;
  return (
    explorer.indexOf('MSIE') >= 0 ||
    explorer.indexOf('Trident/7.0') >= 0 ||
    explorer.indexOf('Edge') >= 0
  );
};

// 将系统参数返回的list组装成对象
export const systemList2Obj = (systemList) => {
  let obj = {};
  systemList.forEach((v) => {
    obj = {
      ...obj,
      [v.paramName]: v.paramValue,
    };
  });
  return obj;
};

/**
 * 处理地图组件气泡提示
 * @param tipObj 气泡显示内容对象
 */
export const handleToolTip = (tipObj) => {
  console.log(tipObj,'tipObj')
  if (_.isEmpty(tipObj)) return null;
  const tipArr = Object.keys(tipObj);
  const radius = 5;
  const width = 150;
  const height = 30 + (tipArr.length - 1) * 20;
  return (
    <g>
      <path
        fill="black"
        fillOpacity="0.7"
        d={`M0 0 l10 10 h ${width / 2 - 10 - radius}
          a ${radius} ${radius} 90 0 1 ${radius} ${radius} v ${height}
          a ${radius} ${radius} 90 0 1 ${-radius} ${radius} h ${2 * radius - width}
          a ${radius} ${radius} 90 0 1 ${-radius} ${-radius} v ${-height}
          a ${radius} ${radius} 90 0 1 ${radius} ${-radius} h ${width / 2 - 10 - radius} Z`}
      />
      {tipArr.map((k, index) => {
        return (
          <text
            key={shortid.generate()}
            dy={`${15 + index * 20}`}
            dominantBaseline="text-before-edge"
            fill="white"
            textAnchor="middle"
          >
            {tipObj[k]}
          </text>
        );
      })}
    </g>
  );
};

export const handleToolTipGis = (tipObj) => {
  if (_.isEmpty(tipObj)) return null;
  const tipArr = Object.keys(tipObj);
  // const radius = 5;
  // const width = 150;
  // const height = 30 + (tipArr.length - 1) * 20;
  const arr = []
  console.log(tipArr, 'tipArr', tipObj)
  tipArr.forEach((k) => {
    arr.push({ name: '', value: tipObj[k] })
  });
  return arr
}
/**
 * 生成位置Options，获取mapId
 * @param mapIdSpaceTree 数据树
 * @returns {Array}
 */
export const recursionSpace2mapId = ({ spaceTree }) => {
  const treeData = [];
  if (spaceTree) {
    spaceTree.forEach((space) => {
      const isLeaf = Number(space.structure) === 2;
      const permission = space.isAuthFlag;
      if (permission && space.children && space.children.length > 0) {
        treeData.push({
          ...space,
          children: recursionSpace2mapId({ spaceTree: space.children }),
        });
      } else if (permission && isLeaf && space.mapId) {
        treeData.push({ ...space });
      }
    });
  }
  return treeData;
};

/**
 * 通过控制字段查找树节点
 * @param tree 数据树
 * @param keyInItem 字段名
 * @returns {Object}
 */
export const findByRecursion = (tree, keyInItem) => {
  let rItem = {};
  const recursion = (treeTarget, key) => {
    treeTarget.forEach((item) => {
      if (item[key]) {
        rItem = item;
      } else {
        recursion(item.children, key);
      }
    });
  };
  if (tree) {
    recursion(tree, keyInItem);
  }
  return rItem;
};

/**
 * 部分浏览器（ios）在页面加载完成后就不监听document.title的change事件了
 * 修改title之后，给页面加上一个内容为空的iframe，随后立即删除这个iframe，这时候会刷新title
 * @param title
 */
export const changeHtmlTitle = (title) => {
  document.title = title;
  // const i = document.createElement('iframe');
  // // i.src = "https://www.baidu.com/favicon.ico";
  // i.style.display = 'none';
  // i.onload = () => {
  //   setTimeout(() => {
  //     i.remove();
  //   }, 10);
  // };
  // document.body.appendChild(i);
};

/**
 * 视口的大小，部分移动设备浏览器对innerWidth的兼容性不好，需要
 * document.documentElement.clientWidth或者document.body.clientWidth
 * 来兼容（混杂模式下对document.documentElement.clientWidth不支持）。
 * 使用方法 ： getViewPort().width;
 */
export function getViewPort() {
  // 浏览器嗅探，混杂模式
  if (document.compatMode === 'BackCompat') {
    return {
      vw: document.body.clientWidth,
      vh: document.body.clientHeight,
    };
  }
  return {
    vw: document.documentElement.clientWidth,
    vh: document.documentElement.clientHeight,
  };
}

// 设施管理页面：绑定状态
export const MAP_TYPE = {
  wafer: 0,
  chuangve: 1,
  gis:2
};

/**
 *
 * @param date
 * @returns {*|moment.Moment}
 */
export const toMomentWithZone = (date) => {
  const curTimeZone = sessionStorage.getItem('curTimeZone');
  const offsetGMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const timezone = TIMEZONE.find((e) => e.key === curTimeZone);
  if (timezone) {
    return moment(date.getTime() - (offsetGMT * 60 * 1000 + timezone.timezone * 60 * 60 * 1000));
  }
  return moment(date.getTime());
};
