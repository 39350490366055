export default {
  // APP頁面title
  'app.visitor.reserve': '訪客預約',
  'app.visit.record': '訪問記錄',
  'app.new.reserve': '新建邀請',
  'app.visitor.confirmed': '預約確認',
  'app.visitor.Invites': '邀請記錄',
  'app.visitor.Visitors': '訪客記錄',
  'app.visitor.Confirmed': '訪客審批',
  'app.visitor.statisticAnalysis': '統計分析',
  'app.visitor.sum': '預約確認',
  'app.reserve.detail': '預約詳情',
  'app.visit.type': '訪問事由',
  'app.reserve.delay': '變更邀請',
  'app.reserve.again': '再次預約',
  'app.detail': '列表詳情',
  'app.visitor.title': '訪客信息',
  // 其他
  'app.time.format': 'YYYY年M月D日 HH:mm',
  'app.no.data': '無數據',
  'app.loading': '正在加載',
  'app.loading...': '加載中...',
  'app.new.associates': '添加此次邀請的其他接待人員',
  app_ass_tip: '關聯人最多5人',
  // 自助預約
  'app.self.rightHost.tip': '請輸入正確的拜訪對象',
  'app.self.rightHost.tip2': '請先選擇訪問地點',
  'app.self.confirmed': '已確認',
  'app.self.NoConfirmed': '未確認',
  'app.self.photo': '人臉照片',
  'app.self.isConfirmed': '確認',

  app_invitation_letter: '邀請函',
  app_invitation_code_fail: '獲取失敗',
  'app.loading.freshed': '刷新完畢',
  'app.loading.pullToRefresh': '下拉可以刷新',
  'app.loading.releaseAndfreshed': '鬆開立即刷新',
  'app.loading.reject': '拒絕',
  'app.loading.agree': '同意',
  'app.loading.noListShow': '沒有數據？',
  'app.loading.noListShow.add': '點擊新建邀請即可新建訪客哦～',
  'app.loading.pleaseInputOk': '請輸入正確的搜索內容',
  'app.loading.noListShow.confirm': '沒有需要審批的訪問申請',
  'app.loading.pleaseInputValid': '最少請輸入1位字元',
  'app.loading.today': '今天',
  'app.analysis.today': '今天',
  'app.analysis.week': '本周',
  'app.analysis.month': '本月',
  'app.analysis.year': '年度',
  'app.analysis.history': '歷史訪客總數',
  'app.analysis.Invites': '邀請訪客總數',
  'app.analysis.reserve': '預約訪客總數',
  'app.analysis.temporary': '臨時訪客總數',
  'app.visitors.input': '請輸入關鍵字搜索',

  'app.visitor.info': '訪客資訊',
  'app.access.info': '訪問資訊',
  'app.meeting.details': '訪問詳情',
  'app.meeting.info': '會議信息',
  'app.new.visitor': '新增訪客',
  'app.new.visitor1': '添加訪客',
  'app.phone.address': '從手機通訊錄選擇',
  'app.phone.business_card': '從名片識別(僅支持簡體中文)',
  'app.visitors.state': '訪客狀態',
  'app.create.visitor.error': '姓名是必填項',
  'visit.reserve.visitor.phone.or.email.required': '手機和郵箱至少填寫一個',
  'visit.reserve.visitor.phone.required': '手機是必填項',
  'app.create.visitor.error2': '姓名、身份證號是必填項',
  'app.create.number.error': '訪客資訊重複,請修改後重試',
  'app.contacts.select': '選擇',
  'app.upload.businessCard': '正在識別...',

  'app.connect': '只支持選擇大陸或香港地區移動電話',
  'app.connect.none': '用戶不存在',
  'app.map.none': '查詢失敗',

  'app.visitor.call': '尊敬的',
  'app.visitor.hello': '您好，',
  'app.visitor.hello_1': '！',
  'app.visitor.tip': '如下資訊可協助您的到訪，祝您到訪順利！',
  'app.visitor.text': '通過二維碼掃碼開門或以下邀請碼進行簽到',
  'app.visitor.signInTime': '到訪時間：',
  'app.invitation.tip1': '企業未配置導航點',
  'app.invitation.tip2': '請直接聯繫訪問接待人獲取',
  'app.reserve.visit.online.zoom': 'Zoom會議: ',
  'app.reserve.visit.online.webx': 'Webex會議: ',
  'app.reserve.visit.online.teams': 'Teams會議: ',
  'app.reserve.visit.online.flyBook': '飛書會議: ',
  'app.reserve.visit.online.tencent': '騰訊會議: ',
  'app.reserve.visit.online.video': '視頻會議: ',
  'visit.reserve.visit.online.zoom.warn': '只能選擇一種會議類型',
  'visit.reserve.meetingRoom.tip': '會議室不能為空',
  'visit.reserve.meetingRoom': '選擇會議室',

  'visit.meetingRomm.name': '物理會議室',
  'visit.meetingRomm.name.type': '會議類型',
  'visit.meetingRomm.time': '會議開始時間',
  'visit.meetingRomm.end.time': '會議結束時間',
  'visit.meetingRomm.time.length': '會議時長',
  'visit.meetingRomm.time.min': '分鐘',
  'visit.reserve.detail.online.zoom': 'Zoom會議',
  'visit.reserve.detail.online.webx': 'Webex會議',
  'visit.reserve.detail.online.teams': 'Teams會議',
  'visit.reserve.detail.online.flyBook': '飛書會議',
  'visit.reserve.detail.online.tencent': '騰訊會議',
  'visit.reserve.detail.online.video': '視頻會議',
  'visit.reserve.detail.online.ordinary': '普通會議',
  'visit.meeting.detail': '會議詳情',

  'visit.reserve.detail.tip': '確定要取消',
  'visit.reserve.detail.reject.tip': '確定要拒絕',
  'visit.reserve.detail.reject.tip1': '的訪問嗎?',
  'visit.reserve.detail.tip1': '的邀請嗎?',
  'visit.common.ok': '是',
  'visit.common.cancel': '否',
  'app.reserve.new.visitor': '請完成當前訪客信息',
  'app.reserve.new.visitor.tip': '變更邀請不支持添加新訪客',
  'app_invitation_img.title': '照片採集',
  'app_invitation_img.upload.success': '上傳成功',

  'visit.reserve.visitor.repeat.phone.tip': '訪客手機不能和當前用戶相同',
  'visit.reserve.visitor.repeat.mail.tip': '訪客郵箱不能和當前用戶相同',
  'app.reject.reason.placeholder': '請輸入拒絕理由',
  'app.reject.reason': '拒絕理由',
  'app.currentUser.error': '無法獲取用戶信息，請稍後再試',

  'app.self.custom.tip': '請輸入正確的',
  'visit.delay.tip': '邀請信息變更，請重新確認訪客信息。',
  'visit.loop.not.allow': '会议不支持循環',
  'visit.loop.not.allow2': '跨天不支持循環',

  'visit.reserve.loop.mode': '定期模式',
  'visit.reserve.loop.range': '重複範圍',
  'visit.reserve.loop.times': '重複',
  'visit.reserve.loop.count': '次',
  'visit.reserve.loop.day.count': '每',
  'visit.reserve.loop.day.type': '每個工作日',
  'visit.reserve.loop.week.type': '每周的',
  'visit.reserve.loop.week.1': '周一',
  'visit.reserve.loop.week.2': '周二',
  'visit.reserve.loop.week.3': '周三',
  'visit.reserve.loop.week.4': '周四',
  'visit.reserve.loop.week.5': '周五',
  'visit.reserve.loop.week.6': '周六',
  'visit.reserve.loop.week.7': '周日',
  'visit.reserve.loop.count.tip': '請輸入重複次數',
  'visit.reserve.loop.times.tip': '請輸入重複天數',
  'visit.reserve.loop.day': '天',
  'visit.reserve.loop.week': '周',
  'visit.reserve.loop.month': '月',
  'visit.reserve.loop.title': '循環邀請',
  'visit.reserve.loop.tip.max': '該周期預約次數不能超過最大預約限制',
  'visit.reserve.loop.tip.min': '該周期預約次數不能小於最小預約限制',
  'visit.reserve.loop.tip.max.day': '該周期預約天數不能超過最大預約限制',
  'visit.reserve.loop.tip.min.day': '該周期預約天數不能小於最小預約限制',

  'visit.loop.info': '循環邀請',
  'visit.loop.not': '不循環',
  'visit.loop.day': '按天',
  'visit.loop.week': '按周',
  'visit.loop.month': '按月',

  // v6.6 访客主页
  'visit.visitor.homepage': '訪客管理',
  'visit.visitor.homepage.title': '接待、訪問輕鬆便捷',
  'visit.visitor.homepage.resever': '邀請記錄',
  'visit.visitor.homepage.visit': '訪問記錄',
  'visit.visitor.homepage.shenpi': '訪客審批',
  'visit.visitor.homepage.tongji': '統計分析',
  'visit.visitor.homepage.invite': '邀請訪客',

  'visit.reserve.edit.error': '未變更信息，請修改後再試',

  'visit.visitor.epidemic.title': '防疫碼',
  // v6.8
  'visit.visitor.avatar': '訪客頭像',
  // v6.9
  'app.meeting.hand.sign': '訪客簽名',
  'app.visitor.all': '全部',
  'app.visitor.my.invite': '我邀請的',
  'app.visitor.visit.me': '拜訪我的',

  'app.batch.invitation.company': '公司',
  'app.batch.invitation.time': '時間',
  'app.batch.invitation.type': '事由',

  app_batch_invitation_code: '訪客信息',
  'app.loading.noListShow.teams': '暫無數據',
  // v7.1
  app_invitation_health: '健康調查',
  app_invitation_meeting_info: '會議信息',
  app_invitation_meeting_topic: '會議主題：',
  app_invitation_meeting_room: '會議室：',
  app_invitation_meeting_start: '開始時間：',
  app_invitation_meeting_end: '結束時間：',
  app_invitation_meeting_id: '會議ID：',
  app_invitation_meeting_no: '參會密碼：',
  app_invitation_meeting_link: '邀請鏈接：',
  'app.reject.cancel.placeholder': '請輸入取消原因',
  'app.reject.cancel': '取消原因',

  app_invitation_meeting_info_topic: '會議主題',
  app_invitation_meeting_info_room: '會議室',
  app_invitation_meeting_info_start: '開始時間',
  app_invitation_meeting_info_end: '結束時間',
  app_invitation_meeting_info_id: '會議ID',
  app_invitation_meeting_info_no: '參會密碼',
  app_invitation_meeting_info_link: '邀請鏈接',

  'app.meeting.title': '會議信息',
  'app.meeting.select': '選擇會議室',
  'app.loading.select': '選擇',
  'app.visitor.home.page': '首頁',

  'visit.invitation.code.tip.err': '二維碼失效，點擊重試',
  'app_invitation_health.button.tip': '（請於{mouth}月{day}日提交）',
  'visit.invitation.code.edit.title.healthCode': '健康碼',
  'visit.invitation.code.edit.title.tourCode': '行程卡',
  'visit.invitation.code.edit.title.vaccination': '疫苗證明',
  'visit.invitation.code.edit.title.nucleic': '核酸報告',
  'visit.invitation.code.edit.tip': '注 : 請上傳當地健康碼、行程卡、疫苗接種證明或核酸檢測報告',
  'visit.invitation.code.edit.error': '請上傳當地{errStr}',
  'app.fail.reason': '失敗原因',
  'visit.reserve.visitor.cancel.outer.meeting': '同時從外部參會人中刪除',
  'visit.reserve.meeting.check.max.time.tip': '會議預訂時長超出會議室單場會議最大時間{value}分鐘',
  'visit.reserve.meeting.check.max.time.tip1': '會議預訂時長超出會議室單場會議最大時間',
  'visit.reserve.meeting.check.across.day.tip': '跨天會議時間不能大於{value}天',
  'visit.reserve.meeting.check.across.permissions.tip': '當前用戶沒有預約跨天會議權限',
  'visit.reserve.meeting.check.same.time.tip': '該時間段已預訂會議，不能再次預訂',
  'visit.reserve.meeting.check.before.time.tip': '審批會議室需提前{time}分鐘申請！',
  'visit.invitation.code.vx.share.title': '{companyName}邀請函',
  'visit.reserve.visitor.cancel.send.notice': '向訪客發送取消通知',
  'visit.reserve.copy.link': '複製鏈接',
  'visit.reserve.copy.link.success': '鏈接已複製',
  'visit.reserve.generate.qrCode': '生成二維碼',
  'visit.reserve.send.letter': '發送邀請函',
  'visit.reserve.share': '分享',
  'visit.reserve.down.generate.qrCode': '邀請函二維碼.png',
  'visit.reserve.down.generate.qrCode.success': '下載成功',
  'visit.reserve.read.generate.qrCode': '掃碼打開訪客邀請鏈接',
  'visit.visitor.information.repeat.tip': '{str}不能重複',
  'visit.meeting.booking.cross.day.tip1': '跨天會議允許預約{num}天內會議',
  'visit.meeting.booking.cross.day.tip2':
    '跨天會議允許預約{num}天內會議，超出{num}天的會議需要管理員審批',
  'visit.reserve.universal.share': '通用分享',
  'visit.reserve.universal.share.tip': '鏈接中不含訪客個人信息，需要訪客自行填寫',
  'visit.reserve.exclusive.share': '專屬分享',
  'visit.reserve.exclusive.share.tip': '鏈接中包含訪客個人信息，訪客可對已有信息進行編輯或補充',
  'visit.reserve.information.filling': '訪客信息填寫',
  'visit.visitor.fill.sharepage.card.name': '尊敬的{visitorName}，您好！',
  'visit.visitor.fill.sharepage.card.name.placeholder': '訪客',
  'visit.visitor.fill.sharepage.card.universal.tip':
    '{inviterName}邀請您來訪，請識別二維碼填寫您的個人信息',
  'visit.visitor.fill.sharepage.card.exclusive.tip':
    '{inviterName}邀請您來訪，請識別二維碼補充或修改您的個人信息',
  'visit.visitor.fill.bottom.tip':
    '通用鏈接僅在訪問開始時間前有效，專屬鏈接僅在該訪客的訪問狀態是“未開始”時有效。',
  'app.visitors.meeting.filter': '篩選',
  'app.visitors.meeting.hour': '小時',
  'app.reserve.copy.link.tip': '該訪客{value}，無法複製鏈接或生成二維碼',
  'visit.reserve.visitor.repeat.current.chooes.user.phone.tip': '訪客手機不能和當前拜訪對象相同',
  'visit.reserve.visitor.repeat.current.chooes.user.mail.tip': '訪客郵箱不能和當前拜訪對象相同',
};
