/**
 * Created : vincent
 * Date : 2019-03-20  19:22
 * Email : wangxiao@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export async function queryCurrentUserInfo() {
  return request(`/${RESTFUL_PATH.admin}/user/info`);
}

export async function queryCurrentUserDept(deptId) {
  return request(`/${RESTFUL_PATH.admin}/dept/${deptId}`);
}

export async function getHasNoPurviewDoorList() {
  return request(`/${RESTFUL_PATH.access}/api/lockPurview/getHasNoPurviewDoorList`);
}

export async function applyLockPurview(params) {
  return request(`/${RESTFUL_PATH.access}/api/lockPurview/applyLockPurview`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
