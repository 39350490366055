import * as service from './service';
import { formatMessage, history } from 'umi';
import { Toast } from 'antd-mobile-v2';

export default {
  namespace: 'swsLiftTable',
  state: {
    liftTableData:{},
    saveLiftTableData:{},
    myTableList:{},
    stationNumList:[],
    controlData:{}
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },

  },
  effects: {
    // 升降桌偏好设置显示
    *findLiftTableByUserId({ saveSuccess }, { call, put }) {
      const result = yield call(service.findLiftTableByUserId);
      if (result && result.code === 0) {
        const { data ={} } = result;
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            liftTableData: data,
          },
        });
        if(saveSuccess){
          Toast.success(formatMessage({ id: 'sws.liftTable.set.success' }), 1);
        }
      }
    },
    // 偏好设置保存
    *saveLiftTableSetting({ saveLiftTableData }, { call, put }) {
      const result = yield call(service.saveLiftTableSetting, saveLiftTableData);
      if (result && result.code === 0) {
        yield put({
          type: 'findLiftTableByUserId',
          saveSuccess: true
        });
      } else {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
    *getMyTableList({ params }, { call, put }) {
      const result = yield call(service.getMyTableList, params);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
             myTableList: result.data ?? {}
          },
        });
      }
    },
    *getControlStationNumList({ params }, { call, put }) {
      const result = yield call(service.getControlStationNumList, params);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            stationNumList: result.data ?? []
          },
        });
      }
    },
    *getControlData({ params }, { call, put }) {
      const result = yield call(service.getControlData, params);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            controlData: result.data ?? []
          },
        });
      }
    },
    // 升降桌高度控制
    *saveHeightControl({ params }, { call }) {
      const result = yield call(service.saveHeightControl, params);
      if (result && result.code !== 0) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
  },
  subscriptions: {},
};
