/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  602001: '用戶名稱不能為空',
  602002: '獲取上傳人臉的URL地址失敗',
  602003: '上傳第三方人臉庫失敗',

  603001: '二維碼不存在，請檢查',

  603002: '二維碼使用次數已達到最大',
  603003: '二維碼總有效期已到期',

  601013: '操作成功',

  601028: '添加或更新門禁報修單失敗',
  601029: '開門失敗，請查看配置',

  'menu.sas.app.smartaccess': '智能門禁',
  'menu.sas.app.qrCode': '我的二維碼',
  'menu.sas.app.faceDatabase': '我的人臉庫',
  'menu.sas.app.faultRepair': '故障報修',
  'menu.sas.app.myAccess': '我的門禁',

  601034: '門禁歸屬區域為空！',
  601035: '模擬開門失敗！',
  601036: '配置文件Pattern屬性值為為空！',
  601037: '控制器編號為空！',
  601038: 'door對象為空，請檢查門禁配置是否存在！',
  601039: '門禁狀態停用',
  601040: '控制器無法連接或控制器編號錯誤',
  601041: '邏輯門號錯誤',
  601042: '門禁已停用',
  601043: '二維碼已到期或達到最大次數',
  602006: '文件為空！',

  601044: '開門失敗！邏輯門號設置不正確',
  601045: '指令未發送成功！請檢查控制器是否連接和controllerSN配置',

  601046: '當前卡號不存在',
  601047: '文件為空！',
  601048: 'zip解壓異常',
  601049: '暂无开门权限',
  601100: '註冊時找不到人臉',
  601101: '註冊時有多張人臉',
  601102: '註冊時人臉太小',
  601103: '註冊時照片模糊',
  601104: '識別時找不到人臉',
  601105: '識別時人臉太小或模糊',
  601106: '識別時人臉組不存在',
  601107: '刪除時人臉組不存在',
  601108: '刪除時人員id不存在',
  601109: '刪除人員id對應的照片文件不存在',
  601110: '查詢用戶id時人臉組不存在',
  601111: '查詢用戶id時起始序號大於結束序號',
  601112: '查詢用戶id下人臉圖像時人臉組不存在',
  601113: '查詢用戶id下人臉圖像時用戶id不存在',
  601114: '查詢用戶id下人臉圖像時人臉圖像文件不存在',
  601115: '下載特徵文件時，文件不存在',
  601116: '人臉識別查詢為空',
  601117: '人臉無法識別',
  601118: '人臉照片與其他用戶重複',
  601119: '人臉閾值設置有誤',
  601120: '人臉照片與該用戶現有人臉照片不一致',
  601065: '未啟用威發人臉庫功能',
  601070: '獲取用戶名失敗',
  601071: '數據長度超過限制',
  601121: '人臉帶有口罩',
};
