/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';


/**
 * 取消预订/注销预订
 * @param payload 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function cancelReservation(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/reserve/cancel?rid=${payload.id}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 取消特殊预订
 * @param payload 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function cancelSpecialReservation(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/web/cancel-special-all?uuid=${payload.uuid}&stationId=${payload.stationId}&rId=${payload.rId}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 我的预约记录
 * @param payload 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function myReservationRecords(payload = {}) {
  const url = `/${RESTFUL_PATH.sws}/api/v2/reserve/myReservationRecords?size=${payload.size ||
    5}&current=${payload.current || 1}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 延长预订
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function delayReservation(param) {
  return request(`/${RESTFUL_PATH.sws}/api/client/delay?hour=${param.hour}&id=${param.id}`, {
    method: 'GET',
  });
}
