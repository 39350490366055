export default {
  'sws.detail.sws.time': '时间',
  'sws.detail.sws.type': '类型',
  'sws.detail.sws.device': '固定设备',
  'sws.detail.sws.reserve.log': '预订记录',
  'sws.detail.sws.cancel.btn': '取消',
  'sws.detail.sws.reserve.btn': '预订工位',
  'sws.detail.sws.station.no': '工位号',
  'sws.detail.sws.station.user': '使用人',
  'sws.detail.sws.station.user.select': '请选择',
  'sws.detail.sws.ok.btn': '确定',
  'sws.detail.sws.reserve.ok': '预订成功',
  'sws.detail.sws.reserve.time.tip': '请选择预订时间',
  'sws.detail.sws.reserve.man.tip': '请选择使用人',

  'sws.stationType.mobile': '共享工位',
  'sws.stationType.fixed': '固定工位',
  'sws.detail.no.data': '暂无数据',
  'sws.detail.reserve.man': '预订人',

  'sws.detail.week.0': '星期天',
  'sws.detail.week.1': '星期一',
  'sws.detail.week.2': '星期二',
  'sws.detail.week.3': '星期三',
  'sws.detail.week.4': '星期四',
  'sws.detail.week.5': '星期五',
  'sws.detail.week.6': '星期六',

  'sws.detail.ktyd': '跨天预订',
  'sws.detail.daiding': '代订',
  'sws.detail.s.time': '开始时间',
  'sws.detail.e.time': '结束时间',

  'sws.detail.time.diff': '开始时间不能大于结束时间'
};
