export default {
  'router.title.meeting.MyMeeting': 'My Meeting',
  'router.title.meeting.MeetingDetail': 'Meeting Details',
  'router.title.meeting.DeviceTab': 'Conference Device',
  'router.title.meeting.SignTab': 'Meeting Check in',
  'router.title.meeting.Agenda': 'Meeting Agenda',
  'router.title.meeting.SvcTab': 'Conference Service',
  'router.title.meeting.MeetingReceipt': 'Meeting Receipt',
  'router.title.meeting.SubscribeDetail': 'Video Conference',
  'router.title.meeting.subdetail': 'Meeting Booking',
  'router.title.meeting.webexmeeting': 'Network Meeting',
  'router.title.meeting.MeetingDelay': 'Extend Meeting',
  'router.title.meeting.RoomFeeDetail': 'Conference Room Details',
  'router.title.meeting.SvcFeeDetail': 'Fee Details',
  'router.title.meeting.SvcApply': 'Conference Service',
  'router.title.meeting.PollingQuestion': 'Questionnaire',
  'router.title.meeting.MeetingContact': 'Add External Attendee',
  'router.title.meeting.ExternalContact': 'Add External Attendee',
  'router.title.meeting.ExternalPage': 'Select External Attendee',
  'router.title.meeting.AttendeePage': 'Personnel selection',
  'router.title.meeting.RoomList': 'Select Meeting Room',
  'router.title.meeting.RoomDetail': 'Room Details',
  'router.title.meeting.Subscribe': 'Select Meeting Room',
  'router.title.meeting.About': 'About',
  'router.title.meeting.HomePage': 'Smart Meeting',
  'router.title.meeting.cycle': 'Circular rule',
  'router.title.meeting.seat': 'Seat arrangement',
  'router.title.meeting.seat.member': 'Personnel selection',
};
