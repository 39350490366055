export default {
//設置界面
  'sws.liftTable.dept': '部門：',
  'sws.liftTable.memory.height': '記憶高度',
  'sws.liftTable.standing.height': '站姿高度',
  'sws.liftTable.sitting.height': '坐姿高度',
  'sws.liftTable.sedentary.reminder': '久坐提醒',
  'sws.liftTable.reminder.method': '提醒方式',
  'sws.liftTable.reminder.duration': '提醒時長',
  'sws.liftTable.lunch.break': '午休免打擾',
  'sws.liftTable.automatic.homing': '自動歸位({value}cm)',
  'sws.liftTable.please.enter': '請輸入高度',
  'sws.liftTable.minute': '分鐘',
  'sws.liftTable.set.success': '設置成功',
// 控制界面
'sws.liftTable.hour': '小時',
  'sws.liftTable.stand.today': '今日站立:',
  'sws.liftTable.stand-to-sit.ratio': '今日站坐比',
  'sws.liftTable.standing.number': '站立次數',
  'sws.liftTable.sitting.number': '坐姿次數',
  'sws.liftTable.burn.calories': '燃燒卡路里',
  'sws.liftTable.second-rate': '次',
  'sws.liftTable.current.altitude': '當前高度',
  'sws.liftTable.stand': '站立',
  'sws.liftTable.stand1': '站立：',
  'sws.liftTable.reset': '歸位',
  'sws.liftTable.sitting.position': '坐姿',
  'sws.liftTable.sitting.position1': '坐姿：',
  'sws.liftTable.no.control.data': '你當前沒有可控制的升降桌哦～',
// 數據界面
'sws.liftTable.shared': '共享',
  'sws.liftTable.fixed': '固定',
  'sws.liftTable.no.data': '暫無數據哦～',
  'sws.liftTable.time': '時間：',
//底部菜單
  'sws.liftTable.control': '控制',
  'sws.liftTable.data': '數據',
  'sws.liftTable.set': '設置',
  401088:'查詢的工位家具類型為空或重複'
};
