export default {
  'cloud.background.management.title': '在电脑上登录管理后台',
  'cloud.background.management.tip.1': '更方便地管理企业通讯录',
  'cloud.background.management.tip.2': '更灵活地处理工作需求',
  'cloud.background.management.tip.smt': '更容易的维护会议室',
  'cloud.background.management.tip.vst': '更容易的维护访客接待地',
  'cloud.background.management.tip.sws': '更容易的维护工位和地图',
  'cloud.background.management.tip.3': '更丰富的企业数据统计',
  'cloud.background.management.tip.4': '更多的个性化设置',

  'operate.my': '我的信息',
  'operate.admin': '后台管理',
  'operate.scan': '扫一扫',
  'operate.logout': '退出',
}
