import { requestNoAuthorization } from '@/utils/request';

import request from '../utils/request';
import { RESTFUL_PATH, temperatureList } from '../utils/constant';

export const getSceneForPC = async ({ areaId }) => {
  return request(`/v1/api/mb/ctrl/areastatus/${areaId}`);
};

export const getSceneForDyNet = async ({ areaId }) => {
  return new Promise((resolve) => {
    setTimeout(
      () =>
        resolve(
          requestNoAuthorization(
            `${window.location.protocol}//${window.location.host}/GetDyNet.cgi?a=${areaId}`,
          ),
        ),
      500,
    );
  })
    .then((d) => d.text())
    .then((data) => {
      let areaLevel = (data && typeof data === 'string' && data.split('=')[1]) || '';
      if (!/p=\d*/i.test(data) && !/\d+/.test(areaLevel)) {
        areaLevel = '';
      }
      return { areaLevel, areaId };
    });
};

export const setSceneForPC = async ({ payload }) => {
  return request('/v1/api/mb/ctrl/level', { method: 'post', body: JSON.stringify(payload) });
};
export const setSceneForPCAll = async ({ payload }) => {
  requestNoAuthorization(`/p1/SetDyNet.cgi?a=${payload.areaId}&p=${payload.areaLevel}`);
  requestNoAuthorization(`/p2/SetDyNet.cgi?a=${payload.areaId}&p=${payload.areaLevel}`);
  requestNoAuthorization(`/p3/SetDyNet.cgi?a=${payload.areaId}&p=${payload.areaLevel}`);
  requestNoAuthorization(`/p4/SetDyNet.cgi?a=${payload.areaId}&p=${payload.areaLevel}`);
  requestNoAuthorization(`/p5/SetDyNet.cgi?a=${payload.areaId}&p=${payload.areaLevel}`);
  requestNoAuthorization(`/p6/SetDyNet.cgi?a=${payload.areaId}&p=${payload.areaLevel}`);
  requestNoAuthorization(`/p7/SetDyNet.cgi?a=${payload.areaId}&p=${payload.areaLevel}`);
  requestNoAuthorization(`/p8/SetDyNet.cgi?a=${payload.areaId}&p=${payload.areaLevel}`);
};

export const setSceneForDyNet = ({ payload }) => {
  requestNoAuthorization(
    `${window.location.protocol}//${window.location.host}/SetDyNet.cgi?a=${payload.areaId}&p=${payload.areaLevel}`,
  );
};

export const colorChangeForDyNet = ({ area, channel, color }) => {
  // channel : {r, g, b} ; color: {r, g, b}
  requestNoAuthorization(
    `${window.location.protocol}//${window.location.host}/SetDyNet.cgi?a=${area}&c=${channel.r}&l=${color.r}`,
  );
  requestNoAuthorization(
    `${window.location.protocol}//${window.location.host}/SetDyNet.cgi?a=${area}&c=${channel.g}&l=${color.g}`,
  );
  requestNoAuthorization(
    `${window.location.protocol}//${window.location.host}/SetDyNet.cgi?a=${area}&c=${channel.b}&l=${color.b}`,
  );
};

export const temperatureChangeForDyNet = ({ area, channel, temperature, brightness }) => {
  // temperature angle, brightness angle , channel: {c, w}
  requestNoAuthorization(
    `${window.location.protocol}//${window.location.host}/SetDyNet.cgi?a=${area}&c=${
      channel.c
    }&l=${Math.round(temperature.cw * brightness)}`,
  );
  requestNoAuthorization(
    `${window.location.protocol}//${window.location.host}/SetDyNet.cgi?a=${area}&c=${
      channel.w
    }&l=${Math.round((1 - temperature.cw) * brightness)}`,
  );
};
export const brightnessChangeForDyNet = ({ area, channel, brightness }) => {
  // temperature angle, brightness angle , channel: {c, w}
  requestNoAuthorization(
    `${window.location.protocol}//${
      window.location.host
    }/SetDyNet.cgi?a=${area}&c=${channel}&l=${Math.round(brightness)}`,
  );
};

export const channelChangeForPC = ({ area, channel, color, temperature, brightness }) => {
  const channelList = [];
  if (color) {
    Object.keys(color).forEach((c) => {
      channelList.push({ channelId: channel[c], channelLevel: c });
    });
  } else if (
    (temperature && brightness) ||
    (brightness === 0 && temperature && temperature.cw === 0)
  ) {
    const temp = temperatureList.filter((t) => t.cw === temperature.cw)[0] || { temperature: 2700 };
    channelList.push({
      channelId: channel.c,
      channelLevel: Math.round(temperature.cw * brightness),
      channelTemperature: temp.temperature,
    });
    channelList.push({
      channelId: channel.w,
      channelLevel: Math.round((1 - temperature.cw) * brightness),
      channelTemperature: temp.temperature,
    });
  } else {
    channelList.push({ channelId: channel, channelLevel: Math.round(brightness) });
  }
  return request('/v1/api/mb/ctrl/channel', {
    method: 'POST',
    body: JSON.stringify({ areaId: area, channelList }),
  });
};

export const getChannelForId = async ({ channelId }) => {
  return request(`/v1/api/mb/workstation/panel2?channelGroupId=${channelId}`);
};

export function getReportData(data) {
  return request(`/vc/reportforms`, { method: 'POST', body: JSON.stringify(data) });
}

export async function setSceneData(data) {
  return request(`/vc/ctrl/sceneforms`, { method: 'POST', body: JSON.stringify(data) });
}

export async function setChannelData(data) {
  return request(`/vc/ctrl/channelforms`, { method: 'POST', body: JSON.stringify(data) });
}

export async function queryCurrentUserInfo() {
  return request(`/${RESTFUL_PATH.admin}/user/info`);
}
