import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import {
  queryCurrentUserInfo,
  queryCurrentUserDept,
  getUserAccessQrCode,
  updateUserAccessQrCode,
} from './service';

export default {
  namespace: 'qrCode',
  state: {
    currentUser: {},
    deptInfo: {},
    qrCodeInfo: {},
    bUpdate: false,
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetchCurrentUserInfo(_, { call, put }) {
      const response = yield call(queryCurrentUserInfo);
      if (response.code === 0) {
        const { sysUser } = response.data;
        yield put({
          type: 'save',
          payload: {
            currentUser: sysUser,
          },
        });
        yield put({ type: 'fetchCurrentUserDept', deptId: sysUser.deptId });
        yield put({
          type: 'getUserAccessQrCode',
          params: {
            userId: sysUser.userId || '',
            userName: sysUser.username || '',
            realName: sysUser.realName || '',
            deptId: sysUser.deptId || '',
          },
        });
      }
    },
    *fetchCurrentUserDept({ deptId }, { call, put }) {
      const response = yield call(queryCurrentUserDept, deptId);
      if (response.code === 0) {
        const userDept = response.data;
        yield put({
          type: 'save',
          payload: {
            deptInfo: userDept,
          },
        });
      }
    },
    *getUserAccessQrCode({ params }, { call, put }) {
      const response = yield call(getUserAccessQrCode, { ...params });
      if (response.code === 0) {
        const userQrCode = response.data;
        yield put({
          type: 'save',
          payload: {
            qrCodeInfo: userQrCode,
            bUpdate: true,
          },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
    *updateUserAccessQrCode({ qrCode }, { call, put }) {
      const response = yield call(updateUserAccessQrCode, qrCode);
      if (response.code === 0) {
        const userQrCode = response.data;
        yield put({
          type: 'save',
          payload: {
            qrCodeInfo: userQrCode,
          },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        if (pathname === '/accessApp/qrCode') {
          dispatch({ type: 'fetchCurrentUserInfo' });
        }
      });
    },
  },
};
