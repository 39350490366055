export default {
  'menu.lighting.manage': '照明管理',
  'title.humidity': '室內濕度',
  'title.env': '環境指數',
  'title.humidity.sub': '參考值：30%-80%舒適',
  'title.wendu': '室內溫度',
  'title.wendu.sub': '參考值：15℃-20℃舒適',
  'title.co2.sub': '參考值：≤800PPM空氣清新',
  'title.pm25.sub': '參考值：<75 空氣清新',
  'title.voc.sub': '參考值：<125 空氣清新',
  'title.energy': '照明能耗統計',
  'title.air.energy': '空調能耗統計',
  'none.area.auth': '沒有任何授權的區域',
  'none.area.find': '沒找到可用的區域',
  'env.comfort': '舒適',
  'env.wendu.high': '溫度過高',
  'env.wendu.low': '溫度較低',
  'env.shidu.shushi': '舒適',
  'env.shidu.chaoshi': '潮濕',
  'env.shidu.ganzao': '乾燥',
  'env.co2.0': '空氣清新',
  'env.co2.1': '符合標準',
  'env.co2.2': '輕度污染',
  'env.co2.3': '嚴重污染',
  'env.pm25.0': '空氣清新',
  'env.pm25.1': '符合標準',
  'env.pm25.2': '輕度污染',
  'env.pm25.3': '重度污染',
  'env.voc.0': '舒適',
  'env.voc.1': '符合標準',
  'env.voc.2': '輕度污染',
  'select.area': '請先選擇區域',
  'title.energy.all': '能耗統計',
  'env.filter.0': '今日',
  'env.filter.1': '本周',
  'env.filter.2': '本月',
  'env.filter.3': '年度',
  'menu.slt.app.lighting': '智慧照明',
  'menu.slt.app.report.environment': '環境分析',
  'menu.slt.app.report.energy': '能耗分析',
  'light.floor': '樓層',
  'light.select.floor': '選擇樓層',
  'light.area': '區域',
  'light.select.area': '選擇區域',

  '501000': '面板資訊不存在！',
  '501001': '場景更新失敗',
  '501002': '獲取區域狀態失敗',
  '501003': '未配置指令',
  '501004': '通道更新失敗',
};
