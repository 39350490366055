/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.qrCode.click': 'Click',
  'access.qrCode.update': 'Refresh',
  'access.qrCode.show': 'Refreshed',
};
