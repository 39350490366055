export default {
  group_new: '新建邀請',
  group_subject: '主題',
  group_subject_tip: '請輸入主題',
  group_host: '接待人員',
  group_host_tip: '請輸入並選擇正確的接待人員',
  group_photo: '頭像',
  group_loginUrl_: '團體邀請登記表地址：',
  group_subject_: '主題：',
  group_host_: '接待人員：',
  group_address_: '接待地址：',
  group_address: '接待地址',
  group_date_: '訪問時間：',
  group_date: '訪問時間',
  group_detail: '查看',
  group_invite: '新建邀請',
  group_mail: '郵箱',
  group_mail_tip: '請輸入郵箱',
  group_success: '登記成功',
  group_formTitle: '團體邀請登記表',
  group_carNumber: '車牌號',
  group_carNumber_tip: '請輸入車牌號',
};
