export default {
  'sws.sign.out.end': '结束使用',
  'sws.sign.out.delay': '延长使用',
  'sws.sign.out.tip': '操作提示',
  'sws.sign.out.isEnd': '是否结束当前工位使用？',
  'sws.sign.out.end.success': '签退成功',
  'sws.sign.out.delay.success': '延时成功',
  'sws.sign.out.no.time': '无可用时间',
  401144: '此工位已绑定{value1}个用户',
};
