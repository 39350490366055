export default {
  'meeting.svc.btn.new': '新增服務',
  'meeting.svc.room': '會議室',
  'meeting.svc.level': '服務等級',
  'meeting.svc.payer': '代付人',
  'meeting.svc.total': '總計: ',
  'meeting.svc.confirm': '未確認',
  'meeting.svc.state-2': '未提交',
  'meeting.svc.state-1': '未確認',
  'meeting.svc.state1': '待審批',
  'meeting.svc.state2': '未確認',
  'meeting.svc.state0': '已確認',
  'meeting.svc.state3': '已拒絕',
  'meeting.svc.state4': '已取消',
  'meeting.svc.state5': '已刪除',
  'meeting.svc.cancel': '取消',
  'meeting.svc.remove': '刪除',
  'meeting.svc.tips.title': '友情提示',
  'meeting.svc.tips.remove': '刪除後不可恢復，確認要刪除么？',
  'meeting.svc.tips.cancel': '確認要取消么？',
  'meeting.svc.showpage.toatl.tips': '小計',
  'meeting.svcdeal.state1': '待服務',
  'meeting.svcdeal.state2': '服務已完成',
  'meeting.svcdeal.state3': '服務已過期',
  'meeting.svc.bpmstate-2': '未提交',
  'meeting.svc.bpmstate-1': '未確認',
  'meeting.svc.bpmstate0': '待審批',
  'meeting.svc.bpmstate1': '已完成',
  'meeting.svc.bpmstate2': '已拒絕',
  'meeting.svc.bpmstate3': '已取消',
  'meeting.svc.bpmstate4': '待服務',
  'meeting.svc.bpmstate5': '借用中',
  'meeting.svc.bpmstate6': '已過期',
  'meeting.svc.bpmstate7': '已删除',
};
