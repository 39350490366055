import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export const fetch = async () => {
  return request(`/${RESTFUL_PATH.meeting}/sys-module/all`);
};
export const fetchShare = async () => {
  return request(`/${RESTFUL_PATH.meeting}/sys-module/all/1000`);
};
export default fetch;
