export default {
  none: '没有',

  'sign.in.type.no': '不签到',
  'sign.in.type.anonymity': '匿名签到',
  'sign.in.type.password': '认证签到',
  'sign.in.type.invitation': '邀请码签到',
  'sign.in.type.QR.code': '二维码签到',
  'sign.in.type.face': '人脸识别',
  'sign.in.type.zoom': 'Zoom签到',
  'sign.in.type.card': '刷卡签到',

  'meeting.room.device.repair': '报修',
  'meeting.room.device.repaired': '已报修',
  'meeting.room.device.repair.success': '设备报修成功',
  'meeting.room.manager': '会议室管理员',
  'meeting.room.need.audit': '需审核',
  'meeting.room.service': '会议服务',
  'meeting.room.service.have': '有',
  'meeting.room.detail.name': '会议室名称',
  'meeting.room.detail.area': '所属区域',
  'meeting.room.detail.mail': '邮箱',
  'meeting.room.detail.number': '人数',
  'meeting.room.detail.price': '使用费用',
  'meeting.room.detail.unit': '元 / 小时',
  'meeting.room.detail.type': '会议室类型',
  'meeting.room.detail.company': '所属公司',
  'meeting.room.detail.sign': '签到类型',
  'meeting.room.detail.manager': '会议室管理员',
  'meeting.room.detail.photo': '会议室照片',
  'meeting.room.detail.device': '固定设备',
  'meeting.room.detail.reserve': '发起预约',
  'meeting.calendar.room.price.free': '免费',

  meeting_room_capacity_capacity0: '普通会议室',
  meeting_room_capacity_capacity1: 'WebEx会议室',
  meeting_room_capacity_capacity2: 'Acano会议室',
  meeting_room_capacity_capacity3: 'Exchange会议室',
  meeting_room_capacity_capacity4: 'Google Calendar会议室',
  meeting_room_capacity_capacity5: 'Domino|Notes会议室',
  meeting_room_capacity_capacity6: 'Cisco视频会议室',
  meeting_room_capacity_capacity7: '红外会议室',
  meeting_room_capacity_capacity8: 'Zoom会议室',
  meeting_room_capacity_capacity9: '腾讯会议虚拟会议室',
  meeting_room_capacity_capacity10: '飞书会议室',
  meeting_room_capacity_capacity12: '静音仓',
  'meeting.room.detail.tencent': '方数',
};
