export default {
  'cmma.status.connected': '已连接',
  'cmma.status.ringing': '拨号中',
  'cmma.status.unknown': '未连接',
  'cmma.tag.terminal': '终端',
  'cmma.tag.number': '号码',
  'cmma.tag.No': '号码:',
  'cmma.tag.online': '方在线',
  'cmma.tag.pwd': '密码:',
  'cmma.tag.time': '时间:',
  'cmma.btn.mainRoom': '主会场',
  'cmma.btn.appoint': '点名发言',
  'cmma.btn.camera': '摄像头',
  'cmma.btn.mute': '静音',
  'cmma.btn.hangUp': '挂断',
  'cmma.btn.reload': '重拨',
  'cmma.btn.startNow': '一键开启',
  'cmma.btn.add': '添加',
  'cmma.btn.muteAll': '全部静音',
  'cmma.btn.dial': '拨号',
  'cmma.btn.lock': '锁定会议',
  'cmma.btn.delay': '延长',
  'cmma.btn.text': '字幕',
  'cmma.btn.layout': '分屏',
  'cmma.btn.rotation': '轮询',
  'cmma.btn.send': '发送',
  'cmma.btn.yes': '是',
  'cmma.btn.no': '否',
  'cmma.btn.cancel': '取消',
  'cmma.btn.rotation.stop': '取消轮询',
  'cmma.btn.autoReconnect': '自动重连',
  'cmma.msg.allMuteValid': '全部静音成功',
  'cmma.msg.allMuteInvalid': '全部静音关闭',
  'cmma.msg.lockValid': '会议锁定成功',
  'cmma.msg.lockInvalid': '会议锁定关闭',
  'cmma.msg.mainRoom': '主会议室设置成功',
  'cmma.msg.appointValid': '点名发言打开成功',
  'cmma.msg.appointInvalid': '点名发言关闭成功',
  'cmma.msg.cameraValid': '视频打开成功',
  'cmma.msg.cameraInvalid': '视频关闭成功',
  'cmma.msg.muteValid': '静音设置成功',
  'cmma.msg.muteInvalid': '静音取消成功',
  'cmma.msg.hangUp': '挂断成功',
  'cmma.msg.currentClose': '当前会议已结束',
  'cmma.msg.startSuccess': '视频会议启动成功',
  'cmma.msg.busyList': '以下会议室被占用',
  'cmma.msg.startFailedBusy': '视频会议启动失败，资源占用',
  'cmma.msg.startFailed': '视频会议启动失败',
  'cmma.msg.closeSuccess': '视频会议结束成功',
  'cmma.msg.dialFailed': '呼叫失败',
  'cmma.msg.usingInfo': '终端被以下会议占用',
  'cmma.msg.delay': '会议延长成功',
  'cmma.msg.hangupTitle': '挂断',
  'cmma.msg.hangupMsg': '是否释放会议室资源',
  'cmma.msg.socketError': 'CMMA网络连接异常',
  'cmma.msg.duplicate': '您呼叫的号码已经在当前会议中',
  'cmma.msg.text.title': '编辑字幕',
  'cmma.msg.text.ph1': '*请输入显示时长(秒)',
  'cmma.msg.text.ph2': '*请输入60字以内的消息',

  'cmma.msg.text.success': '字幕消息发送成功',
  'cmma.msg.rotation': '当前轮询间隔为 {interval} 秒',
  'cmma.title.rotation': '轮询设置',
  'cmma.msg.rotation.stop': '已取消轮询设置',
  'cmma.msg.rotation.start': '成功开启轮询设置',
  'cmma.msg.layout.success': '分屏模式设置成功',
  'cmma.msg.autoReonnect.open': '自动重连已打开',
  'cmma.msg.autoReonnect.close': '自动重连已关闭',
  '': '',

  'cmma.layout.speakerOnly': '全屏布局',
  'cmma.layout.telepresence': '网真布局',
  'cmma.layout.stacked': '堆叠布局',
  'cmma.layout.allEqual': '对等分屏',
  'cmma.layout.allEqualQuarters': '2*2分屏',
  'cmma.layout.allEqualNinths': '3*3分屏',
  'cmma.layout.allEqualSixteenths': '4*4分屏',
  'cmma.layout.allEqualTwentyFifths': '5*5分屏',
  'cmma.layout.onePlusN': '1+n分屏',
  'cmma.layout.onePlusFive': '1+5分屏',
  'cmma.layout.onePlusSeven': '1+7分屏',
  'cmma.layout.onePlusNine': '1+9分屏',
  'cmma.layout.global': '全局',

  mc_btn_subtitle: '会议字幕',
  mc_btn_minute: '会议纪要',
  mc_subtitle_language: '语言',
  mc_subtitle_translate: '翻译',
  mc_subtitle_sublanguage: '副语言',
  mc_subtitle_language_zh: '中文-普通话',
  mc_subtitle_language_en_u: '英语-美式',
  mc_subtitle_language_en_a: '英语-澳大利亚',
  mc_subtitle_language_kr: '韩国语',
  mc_subtitle_language_jp: '日本语',
  mc_subtitle_language_my: '马来语',
  mc_subtitle_google: '仅Google支持',
  mc_asr_config_error: '视频空间配置异常',
  mc_msg_success: '操作成功，请稍候...',
};
