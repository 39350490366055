import { formatMessage, history } from 'umi';
import { getUrlParam } from '@/utils/utils';
import { ROUTE_PATH } from '@/utils/constant';
import { Toast } from 'antd-mobile-v2';
import { cloudHubBindFuc } from './services';

export default {
  namespace: 'cloudHub',

  state: {
    bBindState: -1,
    userInfo: {},
    userBindInfo: {
      userId: '',
      password: '',
    },
  },

  effects: {
    *cloudHubBindFuc({ payload }, { call, put }) {
      const aid = sessionStorage.getItem('yzj_aid');
      const openid = sessionStorage.getItem('yzj_openid');
      const client = sessionStorage.getItem('yzj_client');
      const lc = sessionStorage.getItem('yzj_lc');
      const tempUrl = sessionStorage.getItem('yzj_tempUrl');
      const hr = sessionStorage.getItem('yzj_hr');
      const result = yield call(cloudHubBindFuc, {
        userId: payload.userName,
        password: payload.password,
        code: payload.code,
        randomStr: payload.randomStr,
        aid,
        openid,
        client,
        lc,
        tempUrl,
        hr,
      });
      if (result && result.code === 0) {
        if (result.data.startsWith('http')) {
          window.location.href = result.data;
        } else {
          history.replace(`${ROUTE_PATH}/${result.data}`);
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: { bBindState: 0 },
        });
      } else {
        yield put({
          type: 'saveOrUpdateData',
          payload: { bBindState: 1 },
        });
        Toast.fail(formatMessage({ id: result.msg }), 2);
      }
    },
  },

  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  subscriptions: {
    setup({ history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        if (pathname === '/wdfclient/cloudHub') {
          sessionStorage.setItem('yzj_aid', getUrlParam('aid'));
          sessionStorage.setItem('yzj_openid', getUrlParam('openid'));
          sessionStorage.setItem('yzj_client', getUrlParam('client'));
          if (getUrlParam('lc')) {
            sessionStorage.setItem('yzj_lc', getUrlParam('lc'));
          } else {
            sessionStorage.removeItem('yzj_lc');
          }
          if (getUrlParam('tempUrl')) {
            sessionStorage.setItem('yzj_tempUrl', getUrlParam('tempUrl'));
          } else {
            sessionStorage.removeItem('yzj_tempUrl');
          }
          if (getUrlParam('hr')) {
            sessionStorage.setItem('yzj_hr', getUrlParam('hr'));
          } else {
            sessionStorage.removeItem('yzj_hr');
          }
        }
      });
    },
  },
};
