import { message } from 'antd';
import { formatMessage } from 'umi';
import { zhIntl } from '@/utils/intlUtils';
import { getMapIndex, searchMapInfo, getSpaceTree, getEleIdsByMapId, getSvgType, getMapElementDataByPro, getOperationSpaceTree } from './service';

export default {
  namespace: 'mapIndex',

  state: {
    mapIndexData: {
      mapId: '',
    },
    searchMapList: [],
    spaceTree: {},
    clickMapData: {},
    searchText: '',
    isShowCommonList: false,
    stationsOnMap: [],
    mapAllData: [],
    markId: '',
    mapElements: [],
    isSearchInfo: false,
    // 工位
    isShowSWSList: false,
    // 会议
    isShowLockerList: false,
    // 会议
    isShowSMTList: false,
    // 往哪去
    isShowPath: false,
    isShowTypeSearch: false,
    // 素材类型
    svgTypeArr: [],
    dataArr: [],
    isShowAreaView: false,
    expandedKeys: [],
    showPathWafer: false,
    searchData: {
      curPage: 1,
      totalList: 0,
      key: '',
      svgTypeCode: '-1',
      spaceId: '',
      pages: 1,
    },
    mapColors: {},
    curSelectEle: [],
    selectTime: {}, // 这里默认为空对象
    searchApi: [],
    spaceId: '',
    timeFilter: '0',
    operationSpaceTree: [],
    shareSpaceTab: 0,
    isSearchEmpty: false,
  },

  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
    // reSetFilter(state) {
    //   return { ...state, isShowSWSList: false, isShowLockerList: false, isShowSMTList: false, isShowCommonList: false};
    // },
  },

  effects: {
    *getMapIndex({ payload }, { call, put, select }) {
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          isShowSWSList: false,
          isShowLockerList: false,
          isShowSMTList: false,
          isShowTypeSearch: false,
        },
      });
      const result = yield call(getMapIndex, { spaceId: payload.spaceId });
      const { mapIndexData } = yield select((state) => state.mapIndex);

      const mapId = (mapIndexData && mapIndexData.mapId) || '';
      if (result.code === 0) {
        if (result.data) {

          const { map, searchApi, timeFilter } = result.data;
          let bLoading = false;
          let colors = {};
          if (map) {
            if (payload.spaceId && map.mapId && Number(mapId) !== Number(map.mapId)) {
              bLoading = true;
            }
            if (map.colors) {
              colors = JSON.parse(map.colors);
            }
          }
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              mapIndexData: map,
              mapColors: colors,
              spaceId: map.floorId,
              searchApi: Object.values(searchApi),
              timeFilter,
            },
          });
          yield put({ type: 'getEleIdsByMapId', mapId: result.data?.map?.mapId });
        }
      } else {
        message.warn(formatMessage({ id: result.msg }));
      }
    },
    *searchMapInfo({ payload }, { call, put, select }) {
      const { spaceId } = yield select((state) => state.mapIndex);
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          searchMapList: [],
          dataArr: [],
          isSearchEmpty: false,
        },
      });
      const result = yield call(searchMapInfo, {
        key: payload.key || '',
        svgTypeCode: payload.svgTypeCode || '',
        spaceId: spaceId,
        current: 1,
        size: 20,
      });
      if (result.code === 0) {
        const searchMapList =(result.data && result.data.records) || [];
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            isSearchEmpty: result.data && result.data.records?.length === 0 ? true : false,
            searchMapList,
            isShowCommonList: true,
            isShowSWSList: false,
            isShowLockerList: false,
            isShowSMTList: false,
            isShowTypeSearch: true,
            searchData: {
              key: payload.key || '',
              svgTypeCode: payload.svgTypeCode || '-1',
              spaceId: payload.spaceId || '',
              curPage: result.data && result.data.current,
              totalList: result.data && result.data.total,
              pages: result.data && result.data.pages,
            },
          },
        });
        return searchMapList?.[0] || {};
      } ;
      message.error(formatMessage({ id: result.msg }));
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          searchMapList: [],
          dataArr: [],
          isShowCommonList: false,
          isShowSWSList: false,
          isShowLockerList: false,
          isShowSMTList: false,
          isShowTypeSearch: false,
        },
        });
    },
    *searchMapInfoList(_, { call, put, select }) {
      const { searchData, searchMapList, spaceId } = yield select((state) => state.mapIndex);
      const result = yield call(searchMapInfo, {
        key: searchData.key || '',
        svgTypeCode: searchData.svgTypeCode || '',
        spaceId: spaceId,
        current: searchData.curPage + 1,
        size: 20,
      });
      if (result.code === 0) {
        const searchList = searchMapList.concat((result.data && result.data.records) || []);
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            searchMapList: searchList,
            isShowCommonList: true,
            isShowSWSList: false,
            isShowLockerList: false,
            isShowSMTList: false,
            isShowTypeSearch: false,
            searchData: {
              ...searchData,
              curPage: result.data && result.data.current,
              totalList: result.data && result.data.total,
              pages: result.data && result.data.pages,
            },
          },
        });
      } else {
        message.error(formatMessage({ id: result.msg }));
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            dataArr: [],
            searchMapList: [],
            isShowCommonList: false,
            isShowSWSList: false,
            isShowLockerList: false,
            isShowSMTList: false,
            isShowTypeSearch: false,
          },
        });
      }
    },
    *getSpaceTree(_, { call, put }) {
      const result = yield call(getSpaceTree);
      if (result && result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { spaceTree: result.data } });
      }
    },
    // 查询对应mapId下的所有元素
    *getEleIdsByMapId({ mapId }, { call, put }) {
      const result = yield call(getEleIdsByMapId, mapId);
      if (result && result.code === 0) {
        const { data } = result;
        const eleIds = [];
        const showList = [];
        data.forEach((v) => {
          if(!(v?.objectBind && (Number(v?.objectStatus) === 1 || Number(v?.objectStatus) === 2))){
            showList.push(v.mapElementId);

            let showText = v.objectName;
            if(v.svgTypeCode === 'meeting-room' && v.objectValue ){
              showText = `${v.objectName}(${v.objectValue}${zhIntl('人')})`
            }

            const obj = {
              id: v.mapElementId,
              state: v.objectSvgStateCode,
              color: v.objectColor,
              text: showText,
              svgTypeCode: v.svgTypeCode,
              contentObj: {
                objectColor: v.objectColor,
                objectId: v.objectId,
                objectName: showText,
                objectSvgStateCode: v.objectSvgStateCode,
              },
            };
            eleIds.push(obj);
          }
        });
        const mapData = eleIds;
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            stationsOnMap: mapData,
            mapAllData: showList,
          //  reloadMap: true,
            mapElements: data,
          },
        });
      }
    },
    *getSvgType(_, { call, put }) {
      const result = yield call(getSvgType);
      if (result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            svgTypeArr: result.data,
          },
        });
      } else {
        message.error(result.msg);
      }
    },
    *getMapElementDataByPro({ payload }, { call }) {
      const result = yield call(getMapElementDataByPro, payload);
      if (result.code === 0) {
        return result.data;
      }
      return [];
    },
    *getOperationSpaceTree(_, { call, put }) {
      const result = yield call(getOperationSpaceTree);
      if (result && result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { operationSpaceTree: result.data } });
      }
    },
  },

  subscriptions: {},
};
