import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export function getMeetingDetail(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/detail-info/${meetingId}`, {
    method: 'GET',
  });
}

export function getMeetingOther(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-svc/getMeetingSvcDetail/${meetingId}`, {
    method: 'GET',
  });
}
/**
 * 会议服务同意一个任务
 * @param json 任务参数
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function bpmTask(json) {
  return request(`/${RESTFUL_PATH.meeting}/bpm/task`, {
    method: 'POST',
    body: JSON.stringify(json),
  });
}
/**
 * 会议服务拒绝一个任务
 * @param json 任务参数
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function bpmResuse(json) {
  return request(`/${RESTFUL_PATH.meeting}/bpm/refuse`, {
    method: 'POST',
    body: JSON.stringify(json),
  });
}
