export default {
  'sws.special.booking': 'Confirm Booking',
  'sws.special.reset': 'Reset',
  'sws.special.booking.empty': 'Please select space to make special booking',
  'sws.special.confirm.cancel': 'Confirm of Selected',
  'sws.special.no.location': 'Please select a location first',
  'sws.special.station.location.none':
    'You have not selected location information, please select',
  'sws.special.station.stop.using': 'Stop using',
  'sws.search.select.employee.name': 'Please enter your name',
  'sws.special.station.user': 'User',
  'sws.special.tip': 'Operation Prompt',
  'sws.special.tip.text': 'Cancel booking this station',
  'sws.special.yes': 'Yes',
  'sws.special.no': 'No',
  'sws.special.cancel': 'Cancel Station',
  'sws.special.Back': 'Back',
  'sws.special.submit': 'Submit Booking',
  'sws.special.search': 'Please enter a key word to search',
  'sws.special.phone': 'Phone',
  'sws.special.phone.error': 'Mobile phone format is incorrect! ',
  'sws.special.external.user': 'Outsiders',
  'sws.special.input.content': 'Please enter content',
  'timezone.name.title.simplified.chinese': 'Simplified Chinese',
  401149: "The number of bookings cannot exceed {value1}",
  401157: 'External visitor name cannot be empty!'
};
