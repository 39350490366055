export default {
  'sws.delay.hours': '小時',
  'sws.delay.pc.title': '選擇延時',
  'sws.delay.pc.station': '工位：',
  'sws.delay.pc.address': '地址：',
  'sws.delay.pc.starttime': '開始時間：',
  'sws.delay.pc.endtime': '結束時間：',
  'sws.delay.pc.delaytime': '延長時間：',
  'sws.delay.submit': '提交',
  'sws.delay.mobile.station': '工位',
  'sws.delay.mobile.address': '地址',
  'sws.delay.mobile.starttime': '開始時間',
  'sws.delay.mobile.endtime': '結束時間',
  'sws.delay.mobile.delaytime': '延長時間',
  'sws.delay.ok': '確認',
  'sws.delay.cancel': '取消',
  'sws.delay.select.delay': '選擇延長時間',
  'sws.delay.input.delay': '輸入延長時間',
  'sws.delay.input.delay.unit': '單位：小時',
  'sws.delay.submit.success': '延長成功',
  'sws.delay.submit.error': '提交失敗',
  'sws.delay.hour.error': '延時最大限製為{hour}小時',
  'sws.delay.hour.null': '請輸入需要延長的時間',
};
