export default {
  'face.tab1': '未审核',
  'face.tab2': '已审核',
  'face.audit': '审核',
  'face.audit.agree1': '已通过',
  'face.audit.reject1': '已拒绝',
  'face.audit.title': '审核人脸识别照片"通过"或者"拒绝"',
  'face.audit.agree': '通过',
  'face.audit.reject': '拒绝',
  'face.audit.cancel': '取消',
  'face.audit.success': '审核成功',
  'face.audit.error': '审核失败',

  'menu.sas.app.faceAudit': '人脸审核',
};
