export default (arr) => {
  const res = [];
  let i;
  let j;
  const len = arr.length;
  for (i = 0; i < len; i += 1) {
    for (j = i + 1; j < len; j += 1) {
      if (arr[i].pathName === arr[j].pathName) {
        // eslint-disable-next-line no-plusplus
        j = ++i;
      }
    }
    res.push({ zoneName: arr[i].pathName, path: i, children: [] });
  }
  res.forEach((v) => {
    arr.forEach((item) => {
      if (v.zoneName === item.pathName) {
        v.children.push({
          ...item,
          roomId: item.roomId,
          roomName: item.roomName,
          roomNameEn: item.roomNameEn,
          roomNameTw: item.roomNameTw,
          needAudit: item.needAudit,
          advSignTime: item.advSignTime,
          maxMeetingTime: item.maxMeetingTime,
        });
      }
    });
  });
  return res;
};

export const meetingRoomList = (arr) => {
  const list = [];
  if (arr.length) {
    arr.map((item) => list.push(item));
  }
  return list.join(';');
};
