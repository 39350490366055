/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'myInfo.my.code': '我的二維碼',
  'myInfo.face.lib': '人臉庫',
  'myInfo.edit.password': '修改密碼',
  'myInfo.version.num': '版本號',
  'myInfo.version.user': '用戶信息',
};
