export default {
  'sws.myBooking.station': '工位',
  'sws.myBooking.station.colon': '工位：',
  'sws.myBooking.space.colon': '区域：',
  'sws.myBooking.check.in': '签到',
  'sws.myBooking.info': '提示',
  'sws.myBooking.station.chose.cancel': '您确定要取消已经选择的工位?',
  'sws.myBooking.station.using': '您正在使用的工位：',
  'sws.myBooking.station.booking': '您预订的工位：',
  'sws.station.info': '工位信息',
  'sws.cancel.station': '取消预订',
  'sws.check.station': '查看工位',
  'sws.myBooking.station.using.or.reserve': '您没有正在使用或预订的工位',
  'sws.myBooking.cancel.reserve.station': '您确定要取消已经预订的工位?',
  'sws.myBooking.logout.station.using': '您确定要提前注销正在使用的工位?',
  'sws.myBooking.space': '区域',
  'sws.myBooking.timeZone': '时区',
  'sws.myBooking.booking.time': '预订时间',
  'sws.myBooking.booking.cancel': '取消预订',
  'sws.myBooking.booking.time.colon': '预订时间：',
  'sws.myBooking.used.time.colon': '使用时间：',
  'sws.myBooking.reserveStatus': '状态',
  'sws.myBooking.canceled': '已取消',
  'sws.myBooking.used': '已使用',
  'sws.myBooking.using': '使用中',
  'sws.myBooking.operation': '操作',
  'sws.myBooking.reserve.again': '再次预订',
  'sws.myBooking.delay': '延长工位',
  'sws.myBooking.logout': '注销工位',
  'sws.myBooking.station.num': '共{num}个工位',
  'sws.history.not.booking': '您已经有预订的工位，不支持预订两个或以上的座位',
  'sws.history.no.auth': '此工位没有权限，请选择其他工位',
  'sws.history.reBook': '再次预订',
  'sws.history.not.booked': '您还没有预订过工位',
  'sws.reserve.delay.success': '延长成功',
  'sws.chose.time.delay': '选择延长时间',
  'sws.can.not.delay': '无法延长',
  'sws.can.not.reverse': '很抱歉，您没有预订当前工位的权限',
  'sws.no.reverse': '您当前没有预订的工位哦～',
  'sws.no.reverse.history': '您当前没有预订记录哦～',
  'sws.myBooking.reverse': '预订工位',
  'sws.myBooking.common': '普通预订',
  'sws.myBooking.normal': '普通',
  'sws.myBooking.special': '特殊',
  'sws.myBooking.category': '类型：',
  'sws.myBooking.not.fixed': '固定工位不能再次预订了',
  'sws.myBooking.total': '共',
  'sws.myBooking.set': '个',
  'sws.myBooking.view.detail': '查看详情',
  401115: '特殊预订无法延时',
  401139: '预订记录不存在',
  'sws.myBooking.confirm.tip': '提示',
  'sws.myBooking.confirm.ok': '确认',
  'sws.myBooking.confirm.no': '取消',
};
