import { history } from 'umi';
import { ROUTE_PATH } from '@/utils/constant';
import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import moment from 'moment-timezone';
import getScanResult from './service';
function GetRequest(urlStr) {
  let url = '?' + urlStr.split('?')[1];
  let theRequest = new Object();
  if (url.indexOf('?') != -1) {
    let str = url.substr(1);
    let strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
    }
  }
  return theRequest;
}

export default {
  namespace: 'lockerScan',
  state: {},
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
    scanSuccess(state, { data, id, t }) {
      // data.status; 二维码扫码操作码(0-立即使用；1-开门；2-签到)
      // data.scanState = 1
      switch (data.scanState) {
        case 0:
          history.replace({
            pathname: `${ROUTE_PATH}/locker/immediate`,
            query: { id },
          });
          break;
        case 1:
          history.replace({
            pathname: `${ROUTE_PATH}/locker/open-door`,
            query: { id: t === 'group' ? data.lockerId : id },
          });
          break;
        case 2:
          history.replace({ pathname: `${ROUTE_PATH}/locker/common/components/SignedResult` });
          break;
        default:
          break;
      }
      return state;
    },
  },
  effects: {
    *getScanResult({ id, value, t }, { call, put }) {
      const result = yield call(getScanResult, id, value, t);
      if (result && result.code === 0 && result.data) {
        // const scanLocker = Object.assign({}, result.data, {
        //   time: moment().format(formatMessage({ id: 'sws.time.date' })),
        // });
        const scanLocker = {
          ...result.data,
          time: moment().format(formatMessage({ id: 'sws.time.date' })),
        };
        yield put({
          type: 'locker/saveOrUpdateData',
          payload: { scanLocker },
        });
        yield put({ type: 'scanSuccess', data: result.data, id, t });
      } else {
        const failMsg = formatMessage({ id: result.msg }, { ...result.data });
        history.replace({
          pathname: `${ROUTE_PATH}/locker/immediate/result`,
          query: { result: 'fail', failMsg },
        });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
        if (pathname && pathname === '/locker/qrcode') {
          // let q = GetRequest(decodeURIComponent(`${pathname}${search}`));
          const { id, t } = query;
          // Toast.fail(`${pathname}${search}`);
          if (!id) {
            Toast.fail(formatMessage({ id: 'menu.parameter.is.incorrect' }));
            return;
          }
          dispatch({
            type: 'locker/getSystemSet',
            callBack: (value) => {
              dispatch({ type: 'getScanResult', id, value, t });
            },
          });
        }
      });
    },
  },
};
