export default {
  // 新建会议
  meeting_name: '会议名称',
  meeting_name_tip: '请输入会议名称',
  meeting_introduce: '会议简介',
  meeting_introduce_tip1: '请输入会议简介',
  meeting_introduce_tip2: '会议简介长度不能小于10个字符',
  meeting_address: '会议选址',
  meeting_address_tip: '请选择会议地址',
  meeting_detail: '详细地址',
  meeting_detail_tip: '请输入会议详细地址',
  meeting_continue: '保存并继续',
  meeting_text: '单行文本',
  meeting_validity: '报名有效期',
  meeting_validity_tip: '请输入报名有效期',
  meeting_content: '报名表内容',
  meeting_set: '报名表设置',
  meeting_isRAE: '(必填项，可编辑)',
  meeting_isEdit: '(可编辑)',
  meeting_delForm: '确定要删除表单吗?',
  meeting_isRequired: '(必设项)',
  meeting_add: '添加',
  meeting_signSuccess: '签到成功',
  meeting_signFail: '签到失败',
  meeting_signValidity: '签到有效期',
  meeting_signValidity_tip: '请输入签到有效期',
  meeting_successInfo: '成功提示信息',
  meeting_successInfo_tip: '请输入成功提示信息',
  meeting_failInfo: '签到失败提示',
  meeting_failInfo_tip: '请输入签到失败提示',
  meeting_textSet: '单行文本设置',
  meeting_titleSet: '标题设置',
  meeting_titleSet_tip: '请输入标题',
  meeting_presetValue: '预设值',
  meeting_tipInfo: '提示信息',
  meeting_required: '是否必填',
  meeting_info: '会议信息',
  meeting_setForm: '设置报名表',
  meeting_setSign: '设置签到信息',
  meeting_update: '更新',
  meeting_create: '创建',
  meeting_cancel: '取消',
  meeting_createSuccess: '创建成功',
  meeting_name_: '会议名称：',
  meeting_entryUrl_: '会议报名地址：',
  meeting_entryUrlCode_: '报名表地址二维码：',
  meeting_entryValidity_: '报名表有效期：',
  meeting_entry_tip: ' 报名表地址仅在报名有效期内生效，提前及过期访问将无法填写及提交',
  meeting_detail_: ' 查看详情',
  // 会议详情
  meeting_addUser: '添加用户',
  meeting_updatePhoto_tip: '请上传您的照片',
  meeting_userName: '姓名',
  meeting_userName_tip: '请输入姓名',
  meeting_company: '公司',
  meeting_company_tip: '请输入公司',
  meeting_phone: '手机',
  meeting_mail: '邮箱',
  meeting_phone_tip1: '请输入手机号',
  meeting_phone_tip2: '请输入正确的手机号',
  meeting_required_halfTip: '不能为空',
  meeting_num: '序号',
  meeting_yet: '未签到',
  meeting_notYet: '已签到',
  meeting_operation: '操作',
  meeting_retroactive: '补签',
  meeting_delUser_tip1: '确定要删除此条记录吗?(此操作会导致该用户无法签到)',
  meeting_delUser_tip2: '确定要删除此条记录吗?',
  meeting_delUser: '删除',
  meeting_noUser_tip1: '还没有用户哦，您可以添加用户',
  meeting_noUser_tip2: '或给用户分享报名表地址：',
  meeting_overview: '会议概览',
  meeting_tableCount: '报名表总数',
  meeting_viewCount: '报名表浏览量',
  meeting_daySubmitCount: '今日报名表提交量',
  meeting_signCount: '签到量',
  meeting_projectIntro_: '会议介绍：',
  meeting_createDate_: '创建时间：',
  meeting_entryFormUrl_: '报名表地址：',
  meeting_signValidity_: '签到有效期：',
  meeting_loginCode_: '签到登陆码：',
  meeting_userData: '用户数据',
  meeting_search_tip: '请输入你要搜索的姓名或手机号',
  // index
  meeting_userCount: '用户数',
  meeting_createDate: '创建时间',
  meeting_notFilled: '未填写',
  meeting_loginCode: '登录码',
  meeting_status: '状态',
  meeting_creating: '创建中',
  meeting_underway: '进行中',
  meeting_expired: '已过期',
  meeting_delProject_tip: '确定要删除该访客信息吗?',
  meeting_new: '新建会议',
  meeting_to: '至',
  // 报名表
  meeting_formTitle: '填写报名表',
  meeting_input: '请输入',
  meeting_noTime: '未到报名时间',
  meeting_sign_time: '报名时间：',
  meeting_success: '报名成功',
  meeting_success_tip1: '与会当日您可通过刷脸完成签到',
  meeting_success_tip2: '二维码可用作签到凭证，请截屏保存此页面以备用',

  // 思科迁移
  meeting_attending_area: '参会区',
  meeting_new_attending_area: '创建参会区',
  meeting_edit_attending_area: '编辑参会区',
  meeting_serial_number: '序号',
  meeting_attending_area_name: '参会区名称',
  meeting_attending_area_name_placeholder: '请输入参会区名称,最多20个字符',
  meeting_user_count: '用户数',
  meeting_create_time: '创建时间',
  meeting_time: '会议时间',
  meeting_not_filled: '未填写',
  meeting_status_in_create: '创建中',
  meeting_status_process: '进行中',
  meeting_status_end: '已过期',
  meeting_operation_edit: '编辑',
  meeting_operation_view: '查看',
  meeting_operation_del: '删除',
  meeting_del_confirm: '确定要删除这个项目吗?',
  meeting_button_confirm: '确定',
  meeting_button_cancel: '取消',
  meeting_subject: '会议主题',
  meeting_subject_placeholder: '请输入会议主题，长度不能小于6个字符',
  meeting_start_time: '开始时间',
  meeting_start_time_placeholder: '请选择开始时间',
  meeting_start_time_error: '开始时间必须早于结束时间',
  meeting_end_time: '结束时间',
  meeting_end_time_placeholder: '请选择结束时间',
  meeting_end_time_error: '结束时间必须晚于开始时间',
  meeting_attending_area_save_success: '保存成功',
  meeting_attending_area_del_success: '删除成功',
  meeting_attendees: '参会人员',
  meeting_sign_in_quantity: '签到量',
  meeting_sign_in_avatar: '人脸照片（用于刷脸快速入场）',
  meeting_user_name: '姓名',
  meeting_user_telephone: '手机',
  meeting_user_meeting_time: '签到时间',
  meeting_user_sign_in_time: '状态',
  meeting_sign_in_status: '已签到',
  meeting_fill_avatar: '输入参会码以重新上传人脸照片',
  meeting_fill_avatar_tabs: '补传人脸照片',
  meeting_participant_code: '参会码',
  meeting_participant_code_placeholder: '请输入参会码',
  meeting_query_avatar: '查询人脸照片',
  meeting_avatar: '人脸照片',
  meeting_avatar_placeholder: '请选择人脸照片',
  meeting_avatar_already_have: '人脸照片已经上传，不用再补传',
  meeting_attendees_export: '导出参会区人员',
  meeting_user_company: '公司',
  meeting_button_reset: '重新查询',
  meeting_avatar_update_err: '人脸照片上传错误，请稍后尝试',
  meeting_service_err: '服务器异常，请稍后再试！',
  meeting_upload_err: '上传失败，请重试！',
  meeting_update_face_success: '补传人脸照片成功',
  meeting_please_upload_face: '请上传您的照片',
  meeting_export: '导出',
};
