/**
 * Created : fangkemeng
 * Date : 2019-10-12  10:00
 * Email : fangkemeng@wafersystems.com
 */
import { formatMessage, history } from 'umi';
import { Toast } from 'antd-mobile-v2';
import moment from 'moment-timezone';
import { ROUTE_PATH } from '@/utils/constant';
import {
  isOffWork,
  getDateShow,
  changeTimeAfter as changeTimeAfterFun,
  changeTimeBefore,
  isTimeWorkTime,
  allAmPm,
  onlyPm,
  ALL_VALUE,
  CUSTOM_VALUE,
  selectTime,
  AM_VALUE,
  PM_VALUE,
  getAmPmTime
} from '../../utils/timeTools';
import * as bookingService from './service';
import { getAdvanceTime,resAdvanceDateDisable } from '@/pages/WorkSpace/utils/tools'


export default {
  namespace: 'swsBookingNew',
  state: {
    loading: false,
    showAlertModal: false,
    station: {},
    showReservationResult: false,
    resultStatus: 0,
    startDate: undefined, // 开始日期
    endDate: undefined, // 结束日期
    startTime: undefined, // 开始时间
    endTime: undefined, // 开始时间
    selectAmPm: '',
    // todo 干啥的
    date: '',
    // todo 干啥的
    amPm: '',
    // 时间选择结果
    choseResult: {
      timeSelected: '',
      stationNum: '',
      // 开始结束日期
      startDate: '',
      endDate: '',
      // 开始结束时间
      startTime: '',
      endTime: '',
    },
    againLocationId: '',
    failReason: '',
    reloadMap: false,
    selectedEle: [],
    timeText: '', // 预订页面需要回显的时间
    tipModal: false,
    minTime: '',
    maxTime: '',
    selectedList: [],
    stationItem: {},
    timeZone: 'Asia/Shanghai',
    visibleList: [],
    specialCancelinfo: { list: [] },
    specailSearchIndex: undefined,
    centerElement: {
      mapElementId: '',
      stationx: '',
      stationy: '',
      customElementWidth: '',
      customElementHeight: '',
    },
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
    initMapBookNew(state){
      return {
        ...state,
        startDate: undefined, // 开始日期
        endDate: undefined, // 结束日期
        startTime: undefined, // 开始时间
        endTime: undefined, // 开始时间
        amPm: [],
        selectAmpm: '',
        dataList: [],
        isNextStep: false,
        isBind: false,
        showAlertModal: false,
        againLocationId: '',
        timeText: '',
        tipModal: false,
        minTime:'',
        maxTime:'',
        selectedEle:[],
        selectedList:[]
      };
    }
  },
  effects: {
    // 预订确认操作
    *confirmReservation({ payload, timeZone }, { call, put, select }) {
      const result = yield call(bookingService.confirmReservation, payload);
      if (result && result.code === 0) {
        Toast.hide();
        const { selectedList, startDate, endDate, selectAmPm } = yield select(
          (state) => state.swsBookingNew,
        );
        const { startTime = '', endTime = '' } = result?.data?.[0];
        // const { locationSelected } = yield select((state) => state.swsCommon);
        const locationNames = selectedList?.[0]?.locationName?.split(',').slice(-3)|| selectedList?.[0]?.address?.split(',').slice(-3);
        let timeSelected = '';
        timeSelected = selectTime(
          selectAmPm,
          startDate,
          endDate,
          moment(startTime),
          moment(endTime),
        );
        history.push({
          pathname: `${ROUTE_PATH}/workspace/common/components/bookingResult`,
          state: {
            choseResult: {
              timeSelected,
              stationNum: selectedList?.[0]?.stationNum,
            },
            locationNames,
            timeZone,
          },
        });
        // //成功后恢复区域默认初始化
        yield put({
          type: 'swsCommon/saveOrUpdateData',
          payload: {
            isShareLocation: false,
          },
        });
      } else if (result && result.code === 1) {
        // console.log('toast hide')
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
    // 检查工位是否占用
    *checkStationOccupy({ payload }, { put, select }) {
      const {
        bookingList,
        globalTime: { amEndTime },
      } = yield select((state) => state.swsCommon);
      if (!bookingList[0]) {
        return;
      }
      const { startTime, endTime } = bookingList[0];
      const sTime = startTime.split(' ')[1];
      const eTime = endTime.split(' ')[1];
      // 用duration判断时、分、秒的大小来区分上午、下午
      let timeDay = 'all_day';
      if (moment.duration(eTime).valueOf() <= moment.duration(amEndTime).valueOf()) {
        timeDay = 'am';
      } else if (moment.duration(amEndTime).valueOf() <= moment.duration(sTime).valueOf()) {
        timeDay = 'pm';
      }
      const time = `${moment(startTime).format(
        formatMessage({ id: 'sws.time.date' }),
      )} ${formatMessage({ id: timeDay })}`;
      yield put({
        type: 'swsCommon/saveOrUpdateData',
        payload: {
          stationOccupyModal: true,
          stationOccupyData: [{ id: 1, stationNum: payload.stationNum, time }],
        },
      });
    },
    *selectedEle({ payload, callback }, { put, select }) {
      const { elementId } = payload;
      const { mapData } = yield select((state) => state.swsCommon);
      const element = mapData.find((d) => {
        return d.mapElementId === elementId;
      });
      // console.log('selectedEle element', element)
      if (element) {
        callback([element]);
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            selectedEle: [element],
          },
        });
      }
    },
    // 在位置树获取后尝试初始化时间
    *initTime({ payload, callback = () => { } }, { put }) {
      const {
        timeZone,
        globalTime,
        systemSet: { reserve_time_mode },
        workday,
        swsCommon,
        currentDate = undefined,
        init = false,
      } = payload;
      const now = moment();// currentDate?.isSame?.(moment(), 'day') ? moment() : moment(currentDate);
      let amPm = {};
      let selectAmPm = ALL_VALUE;
      let dateDay = workday?.[0] || moment().format('YYYY-MM-DD');
      if (currentDate) {
        dateDay = (currentDate?.isSame?.(moment(), 'day') ? moment() : moment(currentDate)).tz(timeZone).format('YYYY-MM-DD')
      }
      console.log(!currentDate,init,resAdvanceDateDisable({ date: moment(dateDay), swsCommon }),99999999)
      // 工位需要至少提前?分钟预订
      if (!currentDate && init && resAdvanceDateDisable({ date: moment(dateDay), swsCommon })) {
        let hasFind = false
        for (const day of workday) {
          if (!resAdvanceDateDisable({ date: moment(day), swsCommon })) {
            dateDay = day
            hasFind = true;
            break;
          }
        }
        if (!hasFind) {
          console.log('not find')
          return false;
        }
      }
      const offWork = isOffWork(globalTime, timeZone);
      // // 如果过了下班时间，日期按工作日数组里顺延一天
      // if (offWork) {
      //   dateDay = moment.tz(workday?.[1], timeZone).format('YYYY-MM-DD');
      // console.log(dateDay,'dateDay')
      const startDate = moment.tz(dateDay, timeZone);
      const endDate = moment.tz(dateDay, timeZone);
      const amStartTime = moment.tz(`${dateDay} ${globalTime.amStartTime}:00`, timeZone);
      const amEndTime = moment.tz(`${dateDay} ${globalTime.amEndTime}:00`, timeZone);
      const pmStartTime = moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone);
      const pmEndTime = moment.tz(`${dateDay} ${globalTime.pmEndTime}:00`, timeZone);
      const endTime = changeTimeBefore(pmEndTime);

      let failReason = '';
      // 第二天没有日期，且是下班时间不支持预订
      // 未设置工作日 不支持预订
      const noTime = workday?.length === 0 && isOffWork(globalTime, timeZone);
      // console.log('initTime reserveNormalLimitDay', Number(reserveNormalLimitDay) === 1, isOffWork(globalTime, timeZone))
      const noGlobalTime = !(
        globalTime.amStartTime ||
        globalTime.amEndTime ||
        globalTime.pmStartTime ||
        globalTime.pmEndTime
      );

      if (noTime || noGlobalTime) {
        if (noTime) {
          failReason = noTime && formatMessage({ id: 'sws.booking.get.off.work' });
        } else if (noGlobalTime) {
          failReason = noGlobalTime && formatMessage({ id: 'sws.booking.get.no.time' });
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            failReason,
            startDate: undefined,
            endDate: undefined,
            startTime: undefined,
            endTime: undefined,
          },
        });
        callback(undefined, undefined, failReason);
        return;
      }
      const changeTimeAfter = (startDate) => {
        return changeTimeAfterFun(startDate, getAdvanceTime({ startDate, endDate, swsCommon }))
      }
      yield put({
        type: 'saveOrUpdateData',
        payload: { failReason },
      });
      let startTime = changeTimeBefore(amStartTime);
      // 开始时间设置，如果当前时间大于上午开始时间，开始时间则是当前时间
      if (now > amStartTime && !offWork) {
        startTime = changeTimeAfter(now);
      }
      if (isTimeWorkTime(startTime, 'start', globalTime, timeZone)) {
        startTime = changeTimeAfter(pmStartTime);
      }

      if (changeTimeAfter(now) < amEndTime) {
        amPm = allAmPm;
        selectAmPm = ALL_VALUE;
      } else if (changeTimeAfter(now) < pmEndTime) {
        amPm = onlyPm;
        selectAmPm = PM_VALUE;
      } else {
        amPm = allAmPm;
        selectAmPm = ALL_VALUE;
      }
      // 是给上午，下午，增加时间显示
      amPm = getAmPmTime(amPm, dateDay,timeZone, amStartTime, amEndTime, pmStartTime,pmEndTime)

      let timeText = ''; // 预订页面需要回显的时间
      let date = getDateShow(startDate,endDate,timeZone)  //同天 YYYY-MM-DD HH:mm 或者 不同天YYYY-MM-DD-YYYY-MM-DD HH:mm-HH:mm
      // 如果是从再次预约跳转过来的timeFlag,或者不是从再次预约跳过来并且时间选择不是自定义
      // if (timeFlag !== CUSTOM_VALUE || (!timeFlag && selectAmPm !== CUSTOM_VALUE)) {
      // let timeDay = formatMessage({ id: 'all_day' });

      let data = amPm?.find(item=>item.value === ALL_VALUE)
      let showTime=`(${moment(data?.startTime).format('HH:mm')} - ${moment(data?.endTime).format('HH:mm')})`
      let timeDay = `${formatMessage({ id: 'all_day' })}${showTime}`;
      if (
        moment.duration(changeTimeBefore(moment.tz(endTime, timeZone))).valueOf() <=
        moment.duration(amEndTime).valueOf()
      ) {
        data = amPm?.find(item=>item.value === AM_VALUE)
        showTime=`(${moment(data?.startTime).format('HH:mm')} - ${moment(data?.endTime).format('HH:mm')})`
        timeDay = `${formatMessage({ id: 'am' })}${showTime}`;
        selectAmPm = AM_VALUE;
      } else if (
        moment.duration(amEndTime).valueOf() <=
        moment.duration(changeTimeAfter(moment.tz(startTime, timeZone))).valueOf()
      ) {
        data = amPm?.find(item=>item.value === PM_VALUE)
        showTime=`(${moment(data?.startTime).format('HH:mm')} - ${moment(data?.endTime).format('HH:mm')})`
        timeDay = `${formatMessage({ id: 'pm' })}${showTime}`;
        selectAmPm = PM_VALUE;
      }
      timeText = `${date} ${timeDay}`;
      let modal = amPm;
      if (Number(reserve_time_mode) === 1) {
        modal = amPm.filter((v) => v.value === CUSTOM_VALUE);
        selectAmPm = CUSTOM_VALUE;
        timeText = `${moment
          .tz(startDate, timeZone)
          .format(formatMessage({ id: 'sws.time.date' }))} ${moment
          .tz(startTime, timeZone)
          .format('HH:mm')} ~ ${moment
          .tz(endDate, timeZone)
          .format(formatMessage({ id: 'sws.time.date' }))} ${moment
          .tz(endTime, timeZone)
          .format('HH:mm')}`;
      }
      if (Number(reserve_time_mode) === 2) {
        modal = amPm.filter((v) => v.value !== CUSTOM_VALUE);
      }
      // const copyBookData={...swsBooking,}
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          startDate,
          endDate,
          startTime,
          endTime,
          amPm: modal,
          timeText,
          selectAmPm,
          minTime: startTime,
          maxTime: endTime,
        },
      });
      callback(startTime, endTime, failReason, startDate, endDate);
    },
    // 初始化minTime,MaxTime
    // *initChangeTime({ payload, callback = () => { } }, { put, select }) {
    //   debugger
    //   const { timeZone, globalTime, systemSet, workday } = payload;
    //   const now = moment.tz(moment(), timeZone);
    //   const { selectAmPm } = yield select((state) => state.swsBooking);
    //   const { reserveNormalOpenDay } = systemSet;
    //   const dateDay = workday?.[0] || now.format('YYYY-MM-DD');
    //   const offWork = isOffWork(globalTime, timeZone);
    //   const startDate = moment.tz(dateDay, timeZone);
    //   const endDate = moment.tz(dateDay, timeZone);
    //   const amStartTime = moment.tz(`${dateDay} ${globalTime.amStartTime}:00`, timeZone);
    //   const amEndTime = moment.tz(`${dateDay} ${globalTime.amEndTime}:00`, timeZone);
    //   const pmStartTime = moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone);
    //   const pmEndTime = moment.tz(`${dateDay} ${globalTime.pmEndTime}:00`, timeZone);
    //   const endTime = changeTimeBefore(pmEndTime);
    //
    //   // console.log('endTime', endTime.format('HH:mm'))
    //   let failReason = '';
    //   // 第二天没有日期，且是下班时间不支持预订
    //   // 未设置工作日 不支持预订
    //   const noTime = Number(reserveNormalOpenDay) === 1 && isOffWork(globalTime, timeZone);
    //   // console.log('initTime reserveNormalLimitDay', Number(reserveNormalLimitDay) === 1, isOffWork(globalTime, timeZone))
    //   const noGlobalTime = !(
    //     globalTime.amStartTime ||
    //     globalTime.amEndTime ||
    //     globalTime.pmStartTime ||
    //     globalTime.pmEndTime
    //   );
    //
    //   if (noTime || noGlobalTime) {
    //     if (noTime) {
    //       failReason = noTime && formatMessage({ id: 'sws.booking.get.off.work' });
    //     } else if (noGlobalTime) {
    //       failReason = noGlobalTime && formatMessage({ id: 'sws.booking.get.no.time' });
    //     }
    //     yield put({
    //       type: 'saveOrUpdateData',
    //       payload: {
    //         failReason,
    //         startDate: undefined,
    //         endDate: undefined,
    //         startTime: undefined,
    //         endTime: undefined,
    //       },
    //     });
    //     callback(undefined, undefined, failReason);
    //     return;
    //   }
    //   yield put({
    //     type: 'saveOrUpdateData',
    //     payload: { failReason },
    //   });
    //
    //   let startTime = changeTimeBefore(amStartTime);
    //   // 开始时间设置，如果当前时间大于上午开始时间，开始时间则是当前时间
    //   if (now > amStartTime && !offWork) {
    //     startTime = changeTimeAfter(now);
    //   }
    //   if (isTimeWorkTime(startTime, 'start', globalTime, timeZone)) {
    //     startTime = changeTimeAfter(pmStartTime);
    //   }
    //   if (selectAmPm !== CUSTOM_VALUE) {
    //     yield put({
    //       type: 'saveOrUpdateData',
    //       payload: { selectAmPm: ALL_VALUE },
    //     });
    //     if (
    //       moment.duration(changeTimeBefore(moment.tz(endTime, timeZone))).valueOf() <=
    //       moment.duration(amEndTime).valueOf()
    //     ) {
    //       yield put({
    //         type: 'saveOrUpdateData',
    //         payload: { selectAmPm: AM_VALUE },
    //       });
    //     } else if (
    //       moment.duration(amEndTime).valueOf() <=
    //       moment.duration(changeTimeAfter(moment.tz(startTime, timeZone))).valueOf()
    //     ) {
    //       yield put({
    //         type: 'saveOrUpdateData',
    //         payload: { selectAmPm: PM_VALUE },
    //       });
    //     }
    //   } else {
    //     yield put({
    //       type: 'saveOrUpdateData',
    //       payload: { selectAmPm: CUSTOM_VALUE },
    //     });
    //   }
    //   callback(startTime, endTime, failReason, startDate, endDate);
    // },
    // 改变上下午全天或者自定义选项
    *changAmPmTime({ payload, callback = () => { } }, { put, select }) {
      const { timeZone, globalTime, selectAmPm } = payload;
      const now = moment.tz(moment(), timeZone);
      let startTime = payload.startTime || '';
      let endTime = payload.endTime || '';
      const dateDay = moment(startTime).tz(timeZone).format('YYYY-MM-DD');
      // if (isOffWork(globalTime, timeZone || 'Asia/Shanghai')) {
      //   dateDay = moment(startTime).tz(timeZone).add(1, 'day').format('YYYY-MM-DD');
      // }
      const startDate = moment.tz(dateDay, timeZone);
      const endDate = moment.tz(dateDay, timeZone);
      const amStartTime = moment.tz(`${dateDay} ${globalTime.amStartTime}:00`, timeZone);
      const amEndTime = moment.tz(`${dateDay} ${globalTime.amEndTime}:00`, timeZone);
      const pmStartTime = moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone);
      const pmEndTime = moment.tz(`${dateDay} ${globalTime.pmEndTime}:00`, timeZone);
      const { swsCommon } = yield select(state=>state)

      const changeTimeAfter = (startDate) => {
        return changeTimeAfterFun(startDate, getAdvanceTime({ startDate, endDate, swsCommon }))
      }

      startTime = changeTimeAfter(amStartTime);
      // 开始时间设置，如果当前时间大于上午开始时间，开始时间则是当前时间
      if (now > amStartTime && now < endTime) {
        startTime = changeTimeAfter(now);
      }
      if (isTimeWorkTime(startTime, 'start', globalTime, timeZone)) {
        startTime = changeTimeAfter(pmStartTime);
      }
      if (Number(selectAmPm) === AM_VALUE) {
        endTime = changeTimeBefore(amEndTime);
      }
      if (Number(selectAmPm) === PM_VALUE) {
        startTime = changeTimeAfter(pmStartTime);
        if (now > startTime) {
          startTime = changeTimeAfter(now);
        }
        endTime = changeTimeBefore(pmEndTime);
      }
      if (Number(selectAmPm) === ALL_VALUE || Number(selectAmPm) === CUSTOM_VALUE) {
        endTime = changeTimeBefore(pmEndTime);
      }
      yield put({
        type: 'saveOrUpdateData',
        payload: { startDate, endDate, startTime, endTime, selectAmPm },
      });
      callback(startTime, endTime, startDate, endDate, selectAmPm);
    },
    // 座位查询页面查看位置  初始化指定的时间
    // *initDefineTime({ payload }, { put }) {
    //   /**
    //    * 如果有传递这三个参数，说明选择的是共享工位
    //    * dayStamp:某一天开始时间戳
    //    * startTimeStamp: 开始时间时间戳
    //    * endTimeStamp: 结束时间时间戳
    //    */
    //   const {
    //     timeZone,
    //     globalTime,
    //     systemSet,
    //     dayStamp,
    //     startTimeStamp,
    //     endTimeStamp,
    //     timeFlag,
    //     workday,
    //   } = payload;
    //   const { reserve_time_mode } = systemSet;
    //   // 获取对应的天
    //   const now = moment.tz(moment(), timeZone);
    //   let amPm = '';
    //   let selectedAmPm;
    //   let modal;
    //   let timeText = '';
    //   let timeDay = '';
    //
    //   let dateDay = moment(dayStamp).tz(timeZone).format(TIME_FORMAT.date);
    //   let startDate = moment.tz(startTimeStamp, timeZone);
    //   let endDate = moment.tz(endTimeStamp, timeZone);
    //
    //   // 针对固定工位，从上班前开始，默认时间处理，以及后面的时间校验，只处理当天
    //   const amStartTime = moment.tz(`${dateDay} ${globalTime.amStartTime}:00`, timeZone);
    //   const amEndTime = moment.tz(`${dateDay} ${globalTime.amEndTime}:00`, timeZone);
    //   const pmStartTime = moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone);
    //   const pmEndTime = moment.tz(`${dateDay} ${globalTime.pmEndTime}:00`, timeZone);
    //   const offWorkTime = changeTimeBefore(pmEndTime);
    //   // 获取当天
    //   const today = moment.tz(timeZone).format(TIME_FORMAT.date);
    //   // 判断是否为当天，如果是才做后面判断
    //   const isToday = today === dateDay;
    //   // 判断当前时间是不是下班
    //   const isOffWorkTime = isOffWork(globalTime, timeZone || 'Asia/Shanghai');
    //   let startTime = changeTimeAfter(moment.tz(startTimeStamp, timeZone));
    //   let endTime = changeTimeBefore(moment.tz(endTimeStamp, timeZone));
    //   // 根据当前时间判断上午，下午还是全天
    //   if (changeTimeAfter(now) < amEndTime) {
    //     amPm = allAmPm;
    //   } else if (changeTimeAfter(now) < pmEndTime) {
    //     amPm = onlyPm;
    //   } else {
    //     amPm = onlyPm;
    //   }
    //   let showTime =`(${moment(startTime).format('HH:mm')} - ${moment(endTime).format('HH:mm')})`
    //
    //   // 查询记录带过来 上午/下午/全天/自定义
    //   if (timeFlag === AM_VALUE) {
    //     selectedAmPm = AM_VALUE;
    //     timeDay =`${formatMessage({ id: 'am' })}${showTime}`;
    //   } else if (timeFlag === PM_VALUE) {
    //     selectedAmPm = PM_VALUE;
    //     timeDay = `${formatMessage({ id: 'pm' })}${showTime}`;
    //   } else if (timeFlag === ALL_VALUE) {
    //     selectedAmPm = ALL_VALUE;
    //     timeDay = `${formatMessage({ id: 'all_day' })}${showTime}`;
    //   }
    //   timeText = `${dateDay} ${timeDay}`;
    //   if (timeFlag === CUSTOM_VALUE) {
    //     selectedAmPm = CUSTOM_VALUE;
    //     timeText = `${moment
    //       .tz(startDate, timeZone)
    //       .format(formatMessage({ id: 'sws.time.date' }))} ${moment
    //       .tz(startTime, timeZone)
    //       .format('HH:mm')} ~ ${moment
    //       .tz(endDate, timeZone)
    //       .format(formatMessage({ id: 'sws.time.date' }))} ${moment
    //       .tz(endTime, timeZone)
    //       .format('HH:mm')}`;
    //   }
    //   modal = amPm;
    //   // reserve_time_mode  1 是自定义时间点 2 是上/下午/全天
    //   if (Number(reserve_time_mode) === 1) {
    //     modal = allAmPm.filter((v) => v.value === CUSTOM_VALUE);
    //     selectedAmPm = CUSTOM_VALUE;
    //     timeText = `${moment
    //       .tz(startDate, timeZone)
    //       .format(formatMessage({ id: 'sws.time.date' }))} ${moment
    //       .tz(startTime, timeZone)
    //       .format('HH:mm')} ~ ${moment
    //       .tz(endDate, timeZone)
    //       .format(formatMessage({ id: 'sws.time.date' }))} ${moment
    //       .tz(endTime, timeZone)
    //       .format('HH:mm')}`;
    //   }
    //   if (Number(reserve_time_mode) === 2) {
    //     modal = amPm.filter((v) => v.value !== CUSTOM_VALUE);
    //     if (isToday) {
    //       // 记录是自定义，根据时间判断是否是上/下午/全天
    //       if (Number(timeFlag) === CUSTOM_VALUE) {
    //         if (changeTimeAfter(now) < amEndTime) {
    //           selectedAmPm = ALL_VALUE;
    //           timeDay = formatMessage({ id: 'all_day' });
    //         } else if (changeTimeAfter(now) < pmEndTime) {
    //           selectedAmPm = PM_VALUE;
    //           timeDay = formatMessage({ id: 'pm' });
    //         } else {
    //           selectedAmPm = PM_VALUE;
    //           timeDay = formatMessage({ id: 'pm' });
    //         }
    //         startTime = changeTimeAfter(now);
    //         if (now < amStartTime) {
    //           startTime = amStartTime;
    //         } else if (now > amStartTime && !isTimeWorkTime(now, 'start', globalTime, timeZone)) {
    //           startTime = changeTimeAfter(now);
    //         } else if (isTimeWorkTime(now, 'start', globalTime, timeZone)) {
    //           startTime = pmStartTime;
    //         }
    //         endTime = pmEndTime;
    //       }
    //       if (startTimeStamp && isOffWorkTime) {
    //         selectedAmPm = PM_VALUE;
    //         modal = onlyPm.filter((v) => v.value !== CUSTOM_VALUE);
    //       }
    //     } else {
    //       // 当前日期不是当天，记录是自定义，默认都是全天
    //       if (Number(timeFlag) === CUSTOM_VALUE) {
    //         selectedAmPm = ALL_VALUE;
    //         startTime = changeTimeAfter(amStartTime);
    //         endTime = changeTimeBefore(pmEndTime);
    //         let showTime =`(${moment(startTime).format('HH:mm')} - ${moment(endTime).format('HH:mm')})`
    //         timeDay = `${formatMessage({ id: 'all_day' })}${showTime}`;
    //       }
    //     }
    //     timeText = `${dateDay} ${timeDay}`;
    //   }
    //
    //   // // 如果转换后的起、止时间一样且都是下班时间(或大于下班时间)，则将时间设置为第二天
    //   const isConvert2OffWorkTime = startTime.valueOf() >= offWorkTime.valueOf();
    //   let failReason = '';
    //   // 第二天没有日期，且是下班时间或者转换后起止时间等于下班时间，则不支持预订
    //   if (workday?.length === 0 && (isOffWorkTime || isConvert2OffWorkTime)) {
    //     failReason = formatMessage({ id: 'sws.booking.get.off.work' });
    //     yield put({
    //       type: 'saveOrUpdateData',
    //       payload: {
    //         failReason,
    //         startDate: undefined,
    //         endDate: undefined,
    //         startTime: undefined,
    //         endTime: undefined,
    //       },
    //     });
    //     return;
    //   }
    //   yield put({
    //     type: 'saveOrUpdateData',
    //     payload: {
    //       failReason,
    //       startDate,
    //       endDate,
    //       startTime,
    //       endTime,
    //       amPm: modal,
    //       selectAmPm: selectedAmPm,
    //       timeText,
    //     },
    //   });
    // },

    // *initDefineTime({ payload }, { put }) {
    //   const { timeZone, globalTime, startTime, endTime, systemSet: {reserve_time_mode }, workday } = payload;
    //   const startDate = moment.tz(startTime.format('YYYY-MM-DD'), timeZone);
    //   const endDate = moment.tz(endTime.format('YYYY-MM-DD'), timeZone);
    //   let start = startTime;
    //   let end = endTime;
    //   start = changeTimeAfter(start);
    //   if (isTimeWorkTime(start, 'start', globalTime, timeZone)) {
    //     const day = start.format('YYYY-MM-DD');
    //     start = changeTimeAfter(moment.tz(`${day} ${globalTime.pmStartTime}:00`, timeZone));
    //     if (endTime.format('HH:mm') === globalTime.pmStartTime) {
    //       end = changeTimeBefore(moment.tz(`${day} ${globalTime.pmEndTime}:00`, timeZone));
    //     }
    //   }
    //   if (startTime <= moment()) {
    //     // 使用中状态，时间特殊处理
    //     // 1， 当前时间 转换成标准时间，
    //     // 2，和比较结束时间比较，是否小于结束时间
    //     // 3，小于结束时间，不用处理
    //     // 4， 否则重新初始化时间
    //     const dateDay = moment().tz(timeZone).format('YYYY-MM-DD');
    //     start = changeTimeAfter(moment().tz(timeZone));
    //     if (isTimeWorkTime(start, 'start', globalTime, timeZone)) {
    //       start = changeTimeAfter(moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone));
    //     }
    //     if (start >= endTime) {
    //       debugger
    //       yield put({
    //         type: 'initTime',
    //         payload,
    //       });
    //     }
    //   }
    //   console.log(start.valueOf(),'start')
    //   let dateDay = start.format('YYYY-MM-DD');
    //   // if (workday && workday[0]) {
    //   //   [dateDay] = workday;
    //   // }
    //   const amEndTime = moment.tz(`${dateDay} ${globalTime.amEndTime}:00`, timeZone);
    //   const pmEndTime = moment.tz(`${dateDay} ${globalTime.pmEndTime}:00`, timeZone);
    //   const pmStartTime = moment.tz(`${dateDay} ${globalTime.pmStartTime}:00`, timeZone);
    //
    //   let timeText ='';
    //   let selectAmPm = '';
    //   let amPm = '';
    //   const now = moment();
    //   if (now < amEndTime) {
    //     amPm = allAmPm;
    //     // selectAmPm = ALL_VALUE;
    //   } else if (now < pmEndTime) {
    //     amPm = onlyPm;
    //     // selectAmPm = PM_VALUE;
    //   } else {
    //     amPm = allAmPm;
    //     // selectAmPm = ALL_VALUE;
    //   }
    //   let modal = amPm
    //   if(Number(reserve_time_mode) === 1){
    //     modal= amPm.filter(v=>v.value === CUSTOM_VALUE)
    //     selectAmPm = CUSTOM_VALUE
    //     timeText = `${moment
    //       .tz(startDate, timeZone)
    //       .format(formatMessage({ id: 'sws.time.date' }))} ${moment
    //       .tz(start, timeZone)
    //       .format('HH:mm')} ~ ${moment
    //       .tz(endDate, timeZone)
    //       .format(formatMessage({ id: 'sws.time.date' }))} ${moment
    //       .tz(end, timeZone)
    //       .format('HH:mm')}`;
    //   }
    //   if (Number(reserve_time_mode) === 2) {
    //     let timeDay = formatMessage({ id: 'all_day' });
    //     selectAmPm = ALL_VALUE;
    //     console.log(moment.duration(amEndTime).valueOf(),moment.duration(moment.tz(startTime, timeZone)).valueOf(),435555)
    //
    //     if (
    //       moment.duration(moment.tz(endTime, timeZone)).valueOf() <=
    //       moment.duration(amEndTime).valueOf()
    //     ) {
    //       console.log('am')
    //
    //       timeDay = formatMessage({ id: 'am' });
    //       selectAmPm = AM_VALUE;
    //     } else if (
    //       moment.duration(amEndTime).valueOf() >=
    //       moment.duration(moment.tz(startTime, timeZone)).valueOf() &&  moment.duration(pmStartTime).valueOf() <
    //       moment.duration(moment.tz(endTime, timeZone)).valueOf()
    //     ) {
    //       timeDay = formatMessage({ id: 'pm' });
    //       selectAmPm = PM_VALUE;
    //     }
    //     modal = amPm.filter(v=>v.value !== CUSTOM_VALUE)
    //     timeText = `${dateDay} ${timeDay}`;
    //   }
    //   yield put({
    //     type: 'saveOrUpdateData',
    //     payload: { startDate, endDate, startTime: start, endTime: end, selectAmPm: modal, timeText, amPm },
    //   });
    // },
  },
  subscriptions: {},
};
