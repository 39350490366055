import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取预订详情
 * @param payload
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getReservationInfo({ resId = 25 }) {
  const url = `/${RESTFUL_PATH.sws}/api/client/resId?resId=${resId}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 提交延时预订
 * @param id
 * @param hour
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function setDelayTime({ id, hour }) {
  const url = `/${RESTFUL_PATH.sws}/api/client/delay?id=${id}&hour=${hour}`;
  return request(url, {
    method: 'GET',
  });
}


export async function setDelayTimeByStationId({ stationId }) {
  const url = `/${RESTFUL_PATH.sws}/policy-manage/v1/getAllSetById?stationId=${stationId}`;
  return request(url, {
    method: 'GET',
  });
}