import { message } from 'antd';
import { MENU_PATH } from '@/pages/Meeting/utils/constant';
import { meetingPage, getcalendarRoomList, getRoomDetail } from './service';
import moment from 'moment';

export default {
  namespace: 'mapMeeting',
  state: {
    roomDetailInfo: {
      roomInfo: {},
      deviceNames: [],
      deviceNamesEn: [],
    },
    deviceNames: [],
    meetingList: [],
    isNoBooking: false,
  },
  effects: {
    *fetchMeetingList({ payload: { roomId } }, { call, put, select }) {
      const { tenantId } = yield select((state) => state.user.currentUser);
      const {
        selectTime: { startTime = '', endTime = '' },
      } = yield select((state) => state.mapIndex);
      //   console.log(startTime ,"===startTime==",endTime)
      const { code, msg, data } = yield call(meetingPage, {
        roomIds: roomId,
        isLongState: -1,
        current: 1,
        size: 9999,
        total: 9999,
        belong: 'all',
        status: '0;1;2;5;7',
        queryType: 2,
        tenantId,
        startDate: startTime || moment().startOf('day').valueOf(),
        endDate: endTime || moment().endOf('day').valueOf(),
        isMapRoomList: 1,
      });
      if (code === 0) {
        const { records = [] } = data;

        yield put({
          type: 'update',
          payload: {
            meetingList: records,
          },
        });
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
    *fetchCalendarRoomList(_, { select, call, put }) {
      const { tenantId, userId, timeZone } = yield select((state) => state.user.currentUser);
      //  const { date } = yield select((state) => state.mapMeeting);
      const {
        code,
        msg,
        data: { records },
      } = yield call(getcalendarRoomList, {
        tenantId,
        userId,
        timeZone,
        size: 9999,
        current: 1,
        type: 2,
        //    startTime: date.startOf("day").valueOf()
      });
      if (code === 0) {
        yield put({
          type: 'set',
          payload: {
            calendaRoomList: records,
          },
        });
      } else {
        message.error(formatMessage({ id: msg }));
      }
      return records;
    },
    *getRoomDetail({ payload: { roomId } }, { call, put }) {
      const { data, code, msg = '' } = yield call(getRoomDetail, { roomId });
      if (code === 0) {
        yield put({ type: 'update', payload: { roomDetailInfo: data } });
      } else {
        message.error(formatMessage({ id: msg }));
      }
      return { code, data };
    },
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      dispatch({ type: 'recordInitialState' });
      return umiHistory.listen((params) => {
        const { pathname } = params;
        if (pathname === MENU_PATH.indexMap) {
          dispatch({ type: 'subscribe/reset' });
          //  dispatch({ type: 'getRoomState' });
        }
      });
    },
  },
};
