/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'myInfo.my.code': '我的二维码',
  'myInfo.face.lib': '人脸库',
  'myInfo.edit.password': '修改密码',
  'myInfo.edit.phone': '修改手机号',
  'myInfo.version.num': '版本号',
  'myInfo.version.user': '用户信息',
  'myInfo.setting.title': '设置',
  'myInfo.setting.scan': '扫一扫',
  'myInfo.setting.feedback': '意见反馈',
  'myInfo.setting.info': '查看用户信息',
  'myInfo.my.face': '人脸识别资料',
};
