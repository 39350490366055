/**
 * Created : vincent
 * Date : 2019-03-20  19:22
 * Email : wangxiao@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

// 获取门禁列表
export async function getDoorList() {
  return request(`/${RESTFUL_PATH.access}/api/door/getAllDoors`);
}

// 获取门禁报修列表
export async function getDoorTroubleTicketListBySelf() {
  return request(`/${RESTFUL_PATH.access}/api/doorTroubleTicket/getDoorToubleTicketListBySelf`);
}
