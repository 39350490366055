/**
 * Created by wu on 2017/5/31.
 */
import virsical from 'virsical-jssdk';
import { Toast } from 'antd-mobile-v2';

const config = (jsSDKInfo, isDebug = true) =>
  new Promise((resolve, reject) => {
    virsical.ready(resolve);
    virsical.error(reject);
    virsical.config({
      debug: isDebug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来。
      client_id: jsSDKInfo.waferAppId, // 必填，第三方应用接入ID ReserveStation
      client_secret: jsSDKInfo.waferAppSecret, // 必填，第三方应用接入秘钥 wafer
    });
  });

const login = () =>
  new Promise((resolve, reject) => {
    virsical.login({
      success: (res) => {
        // alert(res.info);
        resolve(res.info);
      },
      fail: (res) => {
        reject(res);
      },
    });
  });

const captureQR = () =>
  new Promise((resolve, reject) => {
    virsical.captureQR({
      success: (res) => {
        resolve(res.url);
      },
      fail: (res) => {
        window.console.log('fail', res);
        reject(res);
      },
    });
  });

/**
 * 获得初始化Virsical环境，获得用户信息
 * @param jsSDKInfo
 * @returns {Promise.<void>}
 */
export const init = async (jsSDKInfo) => {
  const userInfo = await config(jsSDKInfo)
    .then(login, (err) => {
      window.console.log('visical config error', err);
      window.console.log(err.msg);
    })
    .catch((err) => {
      window.console.log(err);
      window.console.log(err.msg);
    });
  return typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo;
};

/**
 * 调用威思客二维码扫描
 * @returns 扫描结果
 */
export const qrScan = async (jsSDKInfo) => {
  const result = await config(jsSDKInfo)
    .then(captureQR, (err) => {
      Toast.fail('visical captureQR error', err);
      Toast.fail(err.msg);
    })
    .catch((err) => {
      Toast.fail('visical config error', err);
      Toast.fail(err.msg);
    });
  return typeof result === 'string' ? JSON.parse(result) : result;
};

export const messageCallback = (callback) => {
  virsical.messageCallback(callback);
};

/**
 * 判断是不是威思客浏览器
 * @returns {boolean}
 */
export const getVirsicalBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('virsical') > -1;
};
