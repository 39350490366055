export default {
  'menu.meeting.meeting_app': 'Smart Meeting',
  'menu.workspace.mobile': 'Smart Workspace',
  'menu.vst.app.manage': 'Smart Visitor',
  'menu.app.index': 'Home',
  'menu.app.my': 'Me',
  'menu.feishu.homePage.title': 'MetaWork',
  'menu.feishu.homePage.remind': 'Digital Administrative Office',
  'menu.envm.index': 'Environment Monitoring',
  'menu.tcs.index': 'Smart Toilet',
};
