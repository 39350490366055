export default {
  'sws.menu.station.facilityManage': 'Facilities',
  'sws.facility.manage': 'This QR code is not a qr code for a workstation or a device',
  // hanlei
  'sws.facility.sure.release': 'Confirm to Unlink of Below ？',
  'sws.facility.sure.no': 'Quit',
  'sws.facility.sure.yes': 'Unlink',
  'sws.facility.unbind.facility': 'Unlink Selected',
  'sws.facility.sure.releaseAll': 'Are you sure to unbind all facilities ？',
  'sws.facility.sure.noAll': 'Quit',
  'sws.facility.sure.yesAll': 'Unlink',
  'sws.facility.enter.num': 'Input Seat or Facilites’s ID',
  'sws.facility.unbind.facilityAll': 'Unlink All of Above',

  'sws.facility.prompt.num': 'Input Seat or Facilites’s ID',
  'sws.facility.location.information': 'Seat Information',

  'sws.facility.sure.breleaseAll': 'Are you sure to unbind all facilities ？',
  'sws.facility.sure.bnoAll': 'Quit',
  'sws.facility.sure.byesAll': 'Unlink',
  'sws.facility.unbind.bfacilityAll': 'Unlink ALL',

  'sws.facility.station.belongs': 'Linked with Seat',
  'sws.facility.facil.num': 'ID：',
  'sws.facility.facil.type': 'TYPE：',
  'sws.facility.facil.serial': 'SN：',
  'sws.facility.scan.binding': 'Scan QR or Barcode of Facilities～',
  'sws.facility.scan.first': 'Scan QR or Barcode is First Step ～',

  'sws.facility.binding.scan': 'Confirm to Link with Seat',
  'sws.facility.sure.bNo': 'Quit',
  'sws.facility.sweep.location': 'Scan',

  'sws.facility.facil.anum': 'ID：',
  'sws.facility.facil.aname': 'NAME：',
  'sws.facility.facil.atype': 'TYPE：',
  'sws.facility.facil.aserial': ' SN：',

  'sws.facility.whether.bind': 'Confirm to Link with Seat',
  'sws.facility.no.bind': 'Quit',
  'sws.facility.yes.bind': 'Link',

  'sws.facility.facil.bnum': 'ID：',
  'sws.facility.facil.bname': 'NAME：',
  'sws.facility.facil.btype': 'TYPE：',
  'sws.facility.facil.bserial': ' SN：',

  'sws.facility.whether.unBind': 'Confirm to Unlink of Below ？',
  'sws.facility.no.unBind': 'Quit',
  'sws.facility.yes.unBind': 'Unlink',

  'sws.facility.facil.cnum': 'ID：',
  'sws.facility.facil.cname': 'NAME：',
  'sws.facility.facil.ctype': 'TYPE：',
  'sws.facility.facil.cserial': 'SN：',

  'sws.facility.location.location': 'LOCATE：',
  'sws.facility.location.num': 'SEAT：',
  'sws.facility.location.depart': 'DEPT：',
  'sws.facility.location.person': 'USER：',

  401316: 'Work stations have been bound to such facilities ',
  401317: 'The facility does not exist ',
  401013: 'This station does not exist ',
};
