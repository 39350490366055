import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';
import _ from 'lodash';
import * as detailService from '@/pages/Meeting/services/meetingDetail';
import * as manageService from '../services/meetingManageService';

let initialState = {};

export default {
  namespace: 'manage',
  state: {
    meetingId: '',
    meetingDetail: {},
    signInType: '',
    signInList: [],
    allSvcInfo: [],
    serviceDataMap: {},
    serviceDataArray: [],
    servicePayerMap: {},
    serviceBillMap: {},
    serviceData: [],
    allServiceList: [],
    deviceList: [],
    allDeviceList: [],
    // meetingVideo: {},
    currentPlayVideo: {},
    currentApplyType: 0,
    fromType: 0,
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
    reset() {
      return { ..._.cloneDeep(initialState) };
    },
  },
  effects: {
    *getMeetingDetail({ meetingId }, { call, put }) {
      const { data } = yield call(detailService.getMeetingDetail, meetingId);
      const { meetingInfo } = data || {};
      yield put({ type: 'update', payload: { meetingDetail: data } });
      yield put({ type: 'getAllDevice', tenantId: meetingInfo.isShareRoom ? 1000 : meetingInfo.tenantId });
      return { meetingDetail: data };
    },
    *getMeetingSignInfo(meetingId, { call, put }) {
      const { data } = yield call(manageService.getMeetingSignIn, meetingId);
      if (data && data.list) {
        yield put({
          type: 'update',
          payload: { signInList: data.list, signInType: data.signType },
        });
      }
    },
    *submitFace({ path }, { call, select }) {
      const { userInfo } = yield select((state) => state.common);
      const { messages } = yield select((state) => state.i18n);
      const result = yield call(manageService.uploadFacePhoto, {
        userId: userInfo.userId,
        userType: userInfo.userType,
        path,
      });
      if (result && result.status === 0) {
        Toast.success(messages['upload.success'], 1);
      }
    },
    *reSignIn({ userId, meetingId, userType }, { call, put }) {
      const { code, msg } = yield call(manageService.retroactive, {
        meetingId,
        userId,
        type: userType,
      });
      if (code === 0) {
        yield put({ type: 'getMeetingSignInfo', meetingId });
      } else {
        Toast.success(msg ? formatMessage({ id: msg }) : '', 1);
      }
    },
    //    *getMeetingServiceInfo(meetingId, { call, put }) {
    //      const { data } = yield call(manageService.getMeetingService, meetingId);
    //      yield put({
    //        type: 'update',
    //        payload: { serviceData: data },
    //      });
    //      yield put({ type: 'getAllService' });
    //      yield put({ type: 'getAllSvcInfo' });
    //    },
    *getAllService({ tenantId }, { call, put }) {
      const { data } = yield call(manageService.getServiceList, { tenantId });
      yield put({ type: 'update', payload: { allServiceList: data } });
    },
    *getAllSvcInfo({ tenantId }, { call, put }) {
      const { data } = yield call(manageService.getAllSvcInfo, { tenantId });
      yield put({ type: 'update', payload: { allSvcInfo: data } });
    },
    *updateSvcPayer({ payload }, { select, put }) {
      const { roomId, payer, payerName } = payload;
      const { servicePayerMap } = yield select((state) => state.manage);
      servicePayerMap[Number(roomId)] = { payer, payerName };
      yield put({ type: 'update', payload: { servicePayerMap: _.cloneDeep(servicePayerMap) } });
    },
    *updateByRoomIds({ payload }, { select, put }) {
      const { roomIds } = payload;
      const tempServiceDataMap = {};
      let tempserviceDataArray = [];
      let count = 0;
      if (roomIds) {
        const roomIdArray = roomIds.split(';');
        const { serviceDataMap = {}, serviceDataArray = [] } = yield select((state) => state.manage);
        Object.keys(serviceDataMap).forEach((roomId) => {
          if (roomIdArray.indexOf(String(roomId)) > -1) {
            tempServiceDataMap[roomId] = serviceDataMap[roomId];
            const rSvc = serviceDataMap[roomId];
            if (rSvc && rSvc.svcInfos) {
              rSvc.svcInfos.forEach((item) => {
                count += parseFloat(item.price) * Number(item.num || 0);
                // count += item.checked ? parseFloat(item.price) * Number(item.num || 0) : 0;
              });
            }
          }
        });
        // 预约会议-会议室A添加会议服务，修改为其他会议室B，再次修改会议室为AB两个，服务费用无显示，点击进去有服务,预约会议其实无服务(应该过滤掉服务，去重新添加)
        tempserviceDataArray = serviceDataArray.filter(item => roomIdArray.indexOf(String(item.roomId)) > -1)
      }
      yield put({ type: 'update', payload: { serviceDataMap: _.cloneDeep(tempServiceDataMap), serviceDataArray: tempserviceDataArray } });
      yield put({ type: 'subscribe/update', payload: { svcPrice: count } });
    },
    *saveMeetingService(__, { call, select }) {
      const { meetingId, serviceDataMap = {}, servicePayerMap = {} } = yield select(
        (state) => state.manage,
      );

      const { messages } = yield select((state) => state.i18n);
      if (_.isEmpty(serviceDataMap)) {
        return;
      }
      // const meetingServiceData = [];
      const meetingSVCData = [];
      Object.keys(serviceDataMap).forEach((roomId) => {
        // window.console.log(roomId, "====roomId===,预约会议保存服务")
        const roomSvc = serviceDataMap[roomId] || {};
        const roomPayer = servicePayerMap[roomId] || {};
        const svcRoomVo = {
          meetingId,
          roomId,
          levelId: roomSvc.svcLevel,
          svcPayer: roomPayer.payer || '',
        };
        // const svcList = [];
        const { svcInfos = [] } = roomSvc;
        let dataArr = [] // 按tempId对数据进行拆分，一个会议室添加多次服务时，进行拆分，否则会存为一条数据
        svcInfos.map((mapItem) => {
          if (dataArr.length == 0) {
            dataArr.push({ tempId: mapItem.tempId, svcinfoList: [mapItem] })
          } else {
            let res = dataArr.some((item) => {
              //判断相同的tempId，有就添加到当前项
              if (item.tempId == mapItem.tempId) {
                item.svcinfoList.push(mapItem)
                return true
              }
            })
            if (!res) {
              //如果没找相同的tempId添加一个新对象
              dataArr.push({ tempId: mapItem.tempId, svcinfoList: [mapItem] })
            }
          }
        })

        dataArr.forEach((item) => {
          const { svcinfoList } = item;
          let svcParam = [];
          svcinfoList.forEach((svc) => {
            svcParam.push({
              id: 0,
              svcId: svc.id,
              name: svc.name,
              describes: svc.describes,
              price: svc.price,
              unit: svc.unit,
              num: svc.num || 0,
              file: svc.file || '',
              fileName: svc.fileName || '',
              remark: svc.remark || '',
              auditType: 0,
              tenantId: svc.tenantId,
            });
          });
          const svcRoomParam = {
            ...svcRoomVo, svcList: svcinfoList
          }
          if (svcinfoList && svcinfoList.length > 0) {
            meetingSVCData.push(svcRoomParam);
          }
        })
        // svcInfos.forEach((svc) => {
        //   svcList.push({
        //     id: 0,
        //     svcId: svc.id,
        //     name: svc.name,
        //     describes: svc.describes,
        //     price: svc.price,
        //     unit: svc.unit,
        //     num: svc.num || 0,
        //     file: svc.file || '',
        //     fileName: svc.fileName || '',
        //     remark: svc.remark || '',
        //     auditType: 0,
        //     tenantId: svc.tenantId,
        //   });

        // });
        // svcRoomVo.svcList = svcList;
        // if (svcList && svcList.length > 0) {
        //   meetingServiceData.push(svcRoomVo);
        // }
      });

      const { code, msg } = yield call(manageService.setServiceData, meetingSVCData);
      if (code === 0) {
        // 预约会议时，同时申请会议服务，成功后会调两次会议详情接口 // yield put({ type: 'getMeetingDetail', meetingId });
      } else {
        Toast.fail(messages[msg], 3);
      }
    },
    *getMeetingDeviceInfo(meetingId, { call, put }) {
      const { data } = yield call(manageService.getMeetingDevice, meetingId);
      const newData=[]
      data.forEach(item=>{
        item.devicesList.forEach(device=>{
            newData.push({...device,...item.supplementInfo})

        })
      })
      yield put({ type: 'update', payload: { deviceList: newData } });
    },
    /** getMeetingVideo(meetingId, {call, put}){
      const {status, data = {}} = yield  call(manageService.getMeetingVideo, meetingId);
      if (status === 0) {
        yield put({type: 'update', payload: {meetingVideo: data.meetingVideo || {}}});
      } else {
        yield put({type: 'update', payload: {meetingVideo: {}}});
      }
    }, */
    *getAllDevice({ tenantId }, { call, put }) {
      const { data } = yield call(manageService.getDeviceData, { type: 2, tenantId });
      yield put({ type: 'update', payload: { allDeviceList: data } });
    },
    *saveMeetingDevice(__, { call, select }) {
      const { meetingId, deviceList } = yield select((state) => state.manage);
      const { messages } = yield select((state) => state.i18n);
      const data = [];
      if (deviceList) {
        deviceList.forEach((item) => {
          if (item.device) {
            data.push({ deviceId: item.device.id, number: item.number });
          } else {
            data.push({ deviceId: item.deviceId, number: item.number });
          }
        });
      }
      if (data.length === 0) {
        return false;
      }
      const result = yield call(manageService.setDeviceData, {
        meetingId,
        svcAuditType: 0,
        serviceData: [],
        type: 0,
        skipPrice: 1,
        agendaData: [],
        deviceData: data,
      });
      if (result && result.code === 0) {
        // Toast.success('移动设备申请已提交', 1);
      } else {
        Toast.fail(messages[result.msg], 1);
      }
      return true;
    },
    *recordInitialState(__, { select }) {
      const manage = yield select((state) => state.manage);
      initialState = _.cloneDeep(manage);
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      dispatch({ type: 'recordInitialState' });
      return history.listen(() => { });
    },
  },
};
