import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import {
  updateLoadImage,
  addFeedback,
  getFeedbackList,
} from './service';

export default {
  namespace: 'feedback',
  state: {
    feedbackListData: {},
    feedbackContent: '',
    imageFiles: [],
    feedbackList: [],
    dataArr: [],
    isShowMore: true,
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *updateLoadImage({ formData }, { call, put, select }) {
      const response = yield call(updateLoadImage, { formData });
      if (response.code === 0) {
        let { imageFiles } = yield select((state) => state.feedback);
        const file = { url: response.data.fileUrl };
        if (imageFiles.length === 0) {
          imageFiles = [{ url: response.data.fileUrl }];
        } else {
          imageFiles.push(file);
        }

        yield put({
          type: 'save',
          payload: { imageFiles },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
    *addFeedback(_, { call, put, select }) {
      const { feedbackContent, imageFiles } = yield select((state) => state.feedback);
      const payload = {
        attachments: imageFiles?.length > 0 ? imageFiles.map(item => item.url).join(';') : '',
        content: feedbackContent?.trim(),
      };
      if(payload.attachments || (payload.content && payload.content.trim())){
        const result = yield call(addFeedback, { payload });
        if (result.code === 0) {
          yield put({
            type: 'save',
            payload: {
              feedbackContent: '',
              imageFiles: [],
              dataArr: [],
              feedbackList: []
            },
          });

          yield put({
            type: 'getFeedbackList',
            payload: {
              current: 1,
              size: 2,
            },
          });
        } else {
          Toast.error(formatMessage({ id: result.msg }));
        }
      }
    },
    *getFeedbackList({ payload }, { call, put }) {
      const result = yield call(getFeedbackList, payload);
      if (result.code === 0) {
        yield put({
          type: 'save',
          payload: {
            feedbackListData: result.data,
            feedbackList: (result.data && result.data.records) || [],
            isShowMore: result.data.current < result.data.pages,
            dataArr: [],
          },
        });
      } else {
        Toast.error(formatMessage({ id: result.msg }));
        yield put({
          type: 'save',
          payload: {
            feedbackData: {},
            feedbackList: [],
            dataArr: [],
            isShowMore: false,
          },
        });
      }
    },
    *getFeedbackListMore(_, { call, put, select }) {
      const { feedbackListData, feedbackList } = yield select((state) => state.feedback);
      if(feedbackListData.current < feedbackListData.pages){
        const result = yield call(getFeedbackList, {
          current: feedbackListData.current + 1,
          size: 2,
        });
        if (result.code === 0) {
          const moreList = feedbackList.concat((result.data && result.data.records) || []);
          yield put({
            type: 'save',
            payload: {
              feedbackList: moreList,
              feedbackListData: result.data,
              isShowMore: result.data.current < result.data.pages,
            },
          });
        } else {
          Toast.error(formatMessage({ id: result.msg }));
          yield put({
            type: 'save',
            payload: {
              dataArr: [],
              feedbackList: [],
              isShowMore: false,
            },
          });
        }
      } else {
        yield put({
          type: 'save',
          payload: {
            isShowMore: false
          },
        });

      }
    },
  },
  subscriptions: {},
};
