export default {
  'menu.envm.app.env_monitor': '環境監測',
  'menu.envm.app.env_dashborad': '實時數據',
  'menu.envm.app.history': '曆史數據',
  'env.temperature.indoor': '室內溫度',
  'env.humidity.indoor': '室內濕度',

  'env.excellent': '優',
  'env.good': '良',
  'env.normal': '正常',
  'env.damp': '潮濕',
  'env.dry': '幹燥',
  'env.too.high': '過高',
  'env.high': '偏高',
  'env.low': '偏低',
  'env.too.low': '過低',
  'env.scorching': '酷熱',
  'env.hot': '炎熱',
  'env.comfortable': '舒適',
  'env.lightly.cold': '微寒',
  'env.cold': '寒冷',
  'env.heavily.polluted': '嚴重汙染',
  'env.heavy.pollution': '重度汙染',
  'env.middle.pollution': '中度汙染',
  'env.lightly.pollution': '輕度汙染',
  'env.lightly.exceeded': '輕度超標',
  'env.middle.exceeded': '中度超標',
  'env.severely.exceeded': '重度超標',
  'env.seriously.exceeded': '嚴重超標',
  'env.avoid.outside': '避免留在室外',
  'env.reduce.outside': '減少室外活動',
  'env.sensitive.people': '敏感人群註意',
  'env.reduce.outside.exercise': '減少戶外劇烈運動',
  'env.normal.activity': '正常活動',
  'env.fresh.air': '空氣清新',
  'env.discomfort': '身體嚴重不適',
  'env.lightly.discomfort': '身體輕微不適',
  'env.heavily.discomfort': '身體狀態欠佳',
  'env.timely.ventilation': '及時通風換氣',
  'env.irritant.gas': '刺激性氣體',
  'env.slight.odor': '輕微異味',

  'env.device': '設備',
  'env.device.number': '設備編號',
  'env.device.tip': '請選擇設備',
  'env.current.day': '當天',
  'env.current.week': '本周',
  'env.value.min': '最小值',
  'env.value.max': '最大值',
  'env.value.avg': '平均值',

  'env.reference.temp': '參考值：16℃～27℃ 舒適',
  'env.reference.hum': '參考值：30%～50% 舒適',
  'env.reference.pm25': '參考值：<=35 μg/m³ 空氣清新',
  'env.reference.tvoc': '參考值：<=400 PPb 空氣清新',
  'env.reference.co2': '參考值：<=500 PPm 空氣清新',

  'home.week.1': '周壹',
  'home.week.2': '周二',
  'home.week.3': '周三',
  'home.week.4': '周四',
  'home.week.5': '周五',
  'home.week.6': '周六',
  'home.week.7': '周天',

  'env.device.none': '請綁定設備！',
  'cloud.nodata': '暫無數據',

  'env.device.select': '點擊選擇設備',

  'cloud.expired.renewal.tip1': '您的訂閱已過期，請使用浏覽器登錄並續費(企業管理員)',
  'cloud.expired.renewal.tip2': '訂閱過期後滿180天未及時續訂，系統將自動注銷租戶並刪除所有數據',
  'cloud.expired.renewal.tip5': '您無權限訪問',
  'cloud.expired.renewal.tip6': '建議檢查您是否已擁有角色，或者角色是否已分配菜單(企業管理員)',

  'cloud.privacy.policy': '隱私政策',
  'cloud.service.terms': '服務條款',
}