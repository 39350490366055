export default {
  'meeting.svc.btn.new': '新增服务',
  'meeting.svc.room': '会议室',
  'meeting.svc.level': '服务等级',
  'meeting.svc.payer': '代付人',
  'meeting.svc.total': '总计: ',
  'meeting.svc.confirm': '未确认',
  'meeting.svc.state-2': '未提交',
  'meeting.svc.state-1': '未确认',
  'meeting.svc.state1': '待审批',
  'meeting.svc.state2': '未确认',
  'meeting.svc.state0': '已确认',
  'meeting.svc.state3': '已拒绝',
  'meeting.svc.state4': '已取消',
  'meeting.svc.state5': '已删除',
  'meeting.svc.cancel': '取消',
  'meeting.svc.remove': '刪除',
  'meeting.svc.tips.title': '友情提示',
  'meeting.svc.tips.remove': '删除后不可恢复，确认要删除么？',
  'meeting.svc.tips.cancel': '确认要取消么？',
  'meeting.svc.showpage.toatl.tips': '小计',
  'meeting.svcdeal.state1': '待服务',
  'meeting.svcdeal.state2': '服务已完成',
  'meeting.svcdeal.state3': '服务已过期',
  
};
