import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import {
  getDoorList,
  updateLoadImage,
  saveOrUpdateDoorTroubleTicket,
  queryCurrentUserInfo,
} from './service';

export default {
  namespace: 'faultRepair',
  state: {
    doorListData: [],
    applyReason: '',
    imageFiles: [],
    doorId: -1,
    doorName: '',
    currentUser: {},
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getDoorList(_, { call, put }) {
      const response = yield call(getDoorList);
      if (response.code === 0) {
        yield put({
          type: 'save',
          payload: {
            doorListData: response.data || [],
          },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
    *updateLoadImage({ formData }, { call, put, select }) {
      const response = yield call(updateLoadImage, { formData });
      if (response.code === 0) {
        let { imageFiles } = yield select((state) => state.faultRepair);
        const file = { url: response.data.fileUrl };
        if (imageFiles.length === 0) {
          imageFiles = [{ url: response.data.fileUrl }];
        } else {
          imageFiles.push(file);
        }

        yield put({
          type: 'save',
          payload: { imageFiles },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
    *fetchCurrentUserInfo(_, { call, put }) {
      const response = yield call(queryCurrentUserInfo);
      if (response.code === 0) {
        const { sysUser } = response.data;
        yield put({
          type: 'save',
          payload: {
            currentUser: sysUser,
          },
        });
      }
    },
    *saveOrUpdateDoorTroubleTicket({ params }, { call, put }) {
      const response = yield call(saveOrUpdateDoorTroubleTicket, { ...params });
      if (response.code === 0) {
        const successText = formatMessage({ id: 601013 });
        Toast.success(successText);
        yield put({
          type: 'getDoorList',
        });
        yield put({
          type: 'save',
          payload: {
            doorId: -1,
            imageFiles: [],
            applyReason: '',
          },
        });
      } else {
        const errorText = formatMessage({ id: response.msg });
        Toast.fail(errorText);
      }
    },
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        if (pathname === '/accessApp/faultRepair') {
          dispatch({ type: 'getDoorList' });
          dispatch({ type: 'fetchCurrentUserInfo' });
        }
      });
    },
  },
};
