export default {
  'myInfo.info.account': 'Account',
  'myInfo.info.name': 'Name',
  'myInfo.info.sex': 'Gender',
  'myInfo.info.role': 'Role',
  'myInfo.info.dept': 'Department',
  'myInfo.info.position': 'Position',
  'myInfo.info.tel': 'Mobile',
  'myInfo.info.email': 'Email',
  'myInfo.info.male': 'Male',
  'myInfo.info.female': 'Female',

  'myInfo.info.userName': 'Account',
  'myInfo.info.back': 'Go Back',
};
