export default {
  // 经协商，此处应该备份一份工位菜单
  'sws.smartWorkspace': '智能工位',
  'sws.menu.station.special': '特殊预订',
  'sws.menu.station.my.booking': '我的预订',
  'sws.menu.station.booking.history': '历史预订',
  'sws.menu.station.booking': '工位预订',
  'sws.menu.station.repair': '工位报修',
  'sws.menu.station.repair.manage': '报修管理',
  'sws.menu.station.search': '工位查询',
  'sws.menu.station.records': '我的预订',
  'sws.menu.locker.booking': '储物柜预订',
  'sws.menu.locker.records': '储物柜预订记录',

  all_day: '全天',
  am: '上午',
  pm: '下午',
  custom: '自定义',
  locale: 'zh-CN',
  // 表单
  'sws.no.data': '无数据',
  'sws.select.all': '全部',
  'sws.input.name': '请输入名称',
  'sws.time.colon': '时间：',
  'sws.time1': '时间',
  'sws.time.hour': '小时',
  // 按钮文字
  'sws.btn.ok': '确定',
  'sws.btn.got.it': '知道了',
  'sws.btn.cancel': '取消',
  'sws.btn.exit': '取消',
  'sws.btn.close': '关闭',
  'sws.btn.chose.time': '选择时间',
  'sws.btn.return.list': '返回列表',
  'sws.btn.reset': '重置',
  'sws.btn.return.reserve': '返回工位预订',
  'sws.btn.return.station.dashboard': '返回工位首页',
  'sws.btn.return.reverse.history': '返回预订记录',
  'sws.btn.home': '主页',
  'sws.btn.more': '更多',
  // 操作结果
  'sws.congratulation.success': '恭喜您，操作成功！',
  'sws.reserve.success': '预订成功',
  'sws.reserve.error': '预订失败',
  'sws.logout.success': '注销成功',
  'sws.logout.error': '注销失败',
  'sws.cancel.success': '取消成功',
  'sws.cancel.error': '取消失败',
  'sws.globalTime.check.error': '租户时间查询失败',
  // 校验提示信息
  'sws.tip.time': '请选择时间',
  'sws.tip.station': '请选择工位',
  'sws.chose.station': '已选中{num}个工位',
  'sws.station.reservation': '确认预订',
  'sws.station.immediate': '立即使用',
  'sws.station.chosed': '您选择的工位：',
  'sws.time.date.chosed': '您选择的日期和时间：',
  // 其它
  'sws.common.location': '区域',
  'sws.common.timezone': '工位所在时区',
  'sws.date.time': '日期和时间',
  'sws.date.range': '日期范围',
  'sws.time.start': '开始时间',
  'sws.time.end': '结束时间',
  'sws.please.select.colon': '请选择：',
  'sws.please.select': '请选择',
  'sws.footer.canUse': '可用',
  'sws.footer.reserved': '已预订',
  'sws.footer.occupied': '已占用',
  'sws.footer.cannot.reserve': '不可预订',
  'sws.footer.fixed.station': '固定工位',
  'sws.station.booking': '预订工位',
  'sws.booking.info': '预订信息',
  'sws.station.number': '工位编号',
  'sws.station.check.in': '时间',
  'sws.node.no.select': '此区域不可以选择',
  'sws.select.tip.title': '工位信息',

  // 提示信息
  'sws.tip.loading': '数据加载中...',
  'sws.reserve.confirm.fail': '您正在使用工位，不支持同一时间预定两个或两个以上座位！',
  'sws.tip.station.occupy': '以下时间段工位被占用',
  'sws.tip.station.location.none': '你没有选择区域信息，请进行选择',
  'sws.tip.none': '无',

  'sws.book.result.gong': '共',
  'sws.book.result.ge': '个',

  // 异常
  401000: '系统异常',
  401001: '工位编号已存在',
  401002: '工位信息不存在',
  401003: '参数错误',
  401004: '工位模板Excel表头列名空错误',
  401005: '第{row}行：工位编号空错误',
  401006: '第{row}行：工位区域空错误',
  401007: '第{row}行：工位家具类型空错误',
  401008: '第{row}行：工位编号{stationNum}重复',
  401009: '第{row}行：用户工号{userId}不存在',
  401010: '第{row}行：工位区域{location}错误',
  401011: '第{row}行：共享工位{stationNum}不支持分配人员',
  401012: '工位{stationNum}处于预订或使用中，请停用后，再删除',
  401013: '此工位不存在',
  401014: '该工位【{value1}】使用人数超出【{value2}】人系统上限，请联系工位管理员调整设置',
  401015: '此工位已绑定素材',
  401016: '此素材已绑定工位',
  401017: '用户{value1}已绑定3个工位',
  401018: '工位处于预订或使用中，不可以编辑工位状态',
  401019: '工位编号未绑定地图，不可以启用',
  401020: '工位编号未绑定地图，不可以停用',
  401021: '工位{stationNum}处于预订或使用中，请停用后，再解绑',
  401022: '启用共享工位数量超过license限制，请联系管理员',
  401023: '启用固定工位数量超过license限制，请联系管理员',
  401024: '工位{stationNum}处于预订或使用中，不可编辑部门',
  401025: '空间信息为空',
  401026: '用户{value1}绑定的工位数量超过限制',
  401027: '工位处于预订或使用中，不可以修改类型',
  401028: '工位编号{value}未绑定地图，不可以启用',
  401029: '工位编号{stationNum}未绑定地图，不可以停用',
  401030: '工位处于预订或使用中，不可编辑部门',
  401031: '请使用正确的文件模板',
  401032: '工位编号{stationNum}已存在,请去除重复工位',
  401033: '第{row}行：工位{stationNum}绑定的人员数量超过限制',
  401034: '第{row}行：员工{userId}分配的工位数量超过限制',
  401035: '工位处于使用中，不允许解除或更改传感器',
  401103: '所选工位在预订时间段内已被占用',
  401104: '您已经有预订或使用的工位，不支持同时预订或使用两个及以上的工位',
  401105: '用户不存在',
  401106: '当前域未设置上下班时间',
  401107: '当前用户未设置部门权限',
  401108: '当前用户未设置区域权限',
  401109: '当前用户没有部门权限',
  401110: '当前用户没有区域权限',
  401111: '今天已过下班时间，无法预订',
  401113: '不在工作时间内，扫码无效',
  401201: '该用户当前绑定的工位数超过限制',
  401202: '{1}当前绑定的用户数与申请用户数之和超过三个，请重新申请',
  401251: '上下班时间类型异常',
  401252: '日期参数异常',
  401253: '类型参数异常',
  401254: '用户未登录',
  401255: 'top统计用户信息异常',
  401256: '操作日志模板异常',
  401257: '空间树获取失败',
  401258: '空间树节点异常',
  401259: '权限不足',
  401260: '部门权限不足',
  401261: '工作日设定异常',
  401262: '节假日不做统计',
  401263: '对象原型异常',
  401264: '参数异常',
  401265: '预订记录工位参数异常',
  401266: '定时任务状态参数异常',
  401267: '类别已存在',
  401268: '类别工位绑定参数异常!',
  401269: '绑定失败，不能跨域绑定!',
  401270: '部門不存在！',
  401271: '操作日志模板异常',
  401272: '操作日志，查询用户失败！',
  401291: '',
  401311: '工位设施名称已存在',
  401312: '工位设施保存失败',
  401313: '工位设施删除失败',
  401314: '选择的设施已绑定工位，需解绑后再删除',
  401321: '数据字典租户默认信息未初始化',
  401322: '数据字典全局默认信息未初始化',
  401331: '权限名称已存在',
  401332: '参数错误',
  401351: '文件上传失败',
  401356: 'license解析失败',
  401357: 'license已过期',
  401358: 'license已超过限制',
  401361: '文件上传模版类型错误',
  401362: '第{value1}行，设备编号为空',
  401363: '第{value1}行，设备类型为空',
  401364: '第{value1}行，工位编号不存在',
  401366: '电子标牌服务地址为空',
  401367: '电子标牌MAC已绑定其他工位',
  401381: '工位报修参数错误',
  401114: '超出最大延时时间',
  401131: '不存在已预订状态的记录',
  401143: '当前时间已过预约结束时间，无法预约',

  'sws.booking.interval.tip': '该工位目前处于锁定状态，暂不可预订',
  'sws.booking.interval.cancel.tip': '该工位取消算未履约，确定要继续吗？',

  "401504": "工位至少提前{value1}分钟预订！",
  "401505": "该工位不支持扫码签到！",
  "401506": "工位{value1}已被锁定，请稍后预订！",

  "401036": "工位状态异常",
  "401083": "二维码无法识别",
  "401057": "二维码已失效",
  "401073": "当前工位不支持扫码绑定业务",
  "401037": "工位{value1}使用人数超出系统设置，请联系管理员",
  "401859": "工位{value1}仅限单人单座使用",
  "401044": "工位{value1}未分配使用部门",
  "401089": "该工位【{value1}】仅限单人专用，不支持扫码更换业务",
  "401090": "用户【{value1}】同时拥有工位数量超过系统设置上限，请联系工位管理员调整设置",
  'default.region.select.option.no': '不选本级',
  401159: '用户无所属部门',

  //360新增
  401205: '扫码绑定失败（仅允许绑定本部门范围内所属工位）',
};
