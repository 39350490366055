export default {
  device_tab_value1: 'Please choose mobile devices!',
  device_tab_value2: 'This device is out of stock.',

  'meeting.manage.title.device': 'Mobile devices application',
  'meeting.manage.device.type_': 'Device type: ',
  'meeting.manage.device.describe_': 'Description: ',
  'meeting.manage.device.add': 'Add device',
  'meeting.manage.device.all': 'All',
  'meeting.manage.device.desc.null': 'No device description',
  'meeting.manage.back.home': 'Back Homepage',
  'meeting.manage.previous.page': 'Back',
  'meeting.manage.device.empty':
    'Please contact with administrator to add device(s) to the area where the conference room is located.',
    'meeting.manage.device.info.shenpi': 'The device is under approval or borrowing and cannot be deleted temporarily!',
    'meeting.new.device.add.success': 'Added successfully! ',
   'new.meeting.device.status.approval': 'Approval',
   'meeting.svcdeal.wait': 'Available to borrow',
   'meeting.devicedeal.borrow': 'borrowing',
   'meeting.devicedeal.expire': 'Expired',
   'meeting.devicedeal.already.cancel': 'Cancelled',
   'meeting.devicedeal.finish': 'Returned',
   'meeting.manage.device.noData':'No conference equipment',
   'meeting.new.device.stop': 'Collapse',
   'meeting.new.device.Expand': 'Expand',
};
