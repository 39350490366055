import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export function getMeetingDetail(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/detail-info/${meetingId}`, {
    method: 'GET',
  });
}

export function updateRoomPayer(params) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/updateRoomPayer`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
