import { useMemoizedFn } from 'ahooks';

/**
 *  一些自定义方法
 */
export default function () {
  /**
   * 从字符串中提取出汉字
   */
  const extractChineseCharacters = useMemoizedFn((str) => {
    // 使用正则表达式匹配汉字字符
    const chineseChars = str.match(/[\u4e00-\u9fa5]/g);
    return chineseChars ? chineseChars.join('') : '';
  });

  /**
   * 从字符串中提取出英文字母和数字
   */
  const extractLettersAndDigits = useMemoizedFn((str) => {
    // 使用正则表达式匹配英文字母和数字
    const lettersAndDigits = str.match(/[a-zA-Z0-9]/g);
    return lettersAndDigits ? lettersAndDigits.join('') : '';
  });

  /**
   * 根据配置格式化员工显示
   */
  const formatEmployeeDisplay = useMemoizedFn((empInfoDisplayFormat = '', employee = null) => {
    const { employeeName, employeeDeptName, employeePosition } = employee || {};
    let empInfoStr = employeeName;
    switch (empInfoDisplayFormat) {
      case 'nameDept':
        if (employeeDeptName) {
          empInfoStr = `${employeeName} (${employeeDeptName})`;
        }
        break;
      case 'namePosition':
        if (employeePosition) {
          empInfoStr = `${employeeName} (${employeePosition})`;
        }
        break;
      case 'nameDeptPosition':
        if (employeeDeptName && employeePosition) {
          empInfoStr = `${employeeName} (${employeeDeptName}/${employeePosition})`;
        } else if (employeeDeptName) {
          empInfoStr = `${employeeName} (${employeeDeptName})`;
        } else if (employeePosition) {
          empInfoStr = `${employeeName} (${employeePosition})`;
        }
        break;
      default:
    }
    return empInfoStr;
  });

  /**
   * 校验手机、邮箱是否重复
   */
  const checkPhoneOrEmailDuplicate = useMemoizedFn((visitor, user) => {
    const { telAreaCode, visitorNumber, visitorMail } = visitor || {};
    const { phone, mail, employeeNumber, employeeMail } = user || {};
    const userPhone = phone || employeeNumber;
    const userMail = mail || employeeMail;
    let phoneIsRepeat = false;
    let mailIsRepeat = false;
    if (visitorNumber && userPhone) {
      phoneIsRepeat = userPhone === telAreaCode + visitorNumber;
    }
    if (visitorMail && userMail) {
      mailIsRepeat = userMail === visitorMail;
    }
    return { phoneIsRepeat, mailIsRepeat };
  });

  /**
   * 针对Select组件已选数据回显时数据源中已不存在该选项，处理数据
   * @returns
   */
  const handleMissingSelectedOptions = (
    dataSource: any = [],
    selectedData: any = null,
    needDefaultValue = false,
  ) => {
    let selectedDataAfterHandle: any = undefined;
    if (dataSource?.length && selectedData !== undefined) {
      // 多选
      if (Array.isArray(selectedData)) {
        selectedDataAfterHandle = [];
        selectedData?.forEach((item: any) => {
          if (dataSource?.find((v: any) => String(v?.id) === String(item))) {
            selectedDataAfterHandle?.push(item);
          }
        });
      } else {
        // 单选
        if (dataSource?.find((item: any) => String(item?.id) === String(selectedData))) {
          selectedDataAfterHandle = selectedData;
        }
      }
    }
    if (needDefaultValue && selectedDataAfterHandle === undefined) {
      selectedDataAfterHandle = dataSource?.[0]?.id;
    }
    return selectedDataAfterHandle;
  };

  return {
    extractChineseCharacters,
    extractLettersAndDigits,
    formatEmployeeDisplay,
    checkPhoneOrEmailDuplicate,
    handleMissingSelectedOptions,
  };
}
