export default {
  machine_invited: '受邀访客',
  machine_invited_code: '邀请码',
  machine_uninvited: '临时访客',
  machine_signOut: '签出',
  machine_home: '返回首页',
  machine_clear: '清空',
  machine_del: '删除',
  machine_signOut_tip1: '已打印的',
  machine_signOut_tip2: '二维码保持平整',
  machine_signOut_tip3: '将二维码',
  machine_signOut_tip4: '对准机器扫描窗口',
  machine_login_title: '管理员登录',
  machine_login_account: '企业账号',
  machine_login_password: '密码',
  machine_login_account_tip: '企业账号不能为空',
  machine_login_password_tip: '密码不能为空',
  machine_format: 'YYYY年MM月DD日 ddd HH:mm:ss',
  machine_welcome: '欢迎光临',
  machine_disagree: '不同意',
  machine_agree: '同意协议',
  machine_remake: '重拍',
  machine_back: '上一步',
  machine_next: '下一步',
  machine_success: '签出成功', // Check Out successfully
  machine_print: '正在打印中...',
  machine_verify_tip1: '请准备',
  machine_verify_tip2: '二代身份证件',
  machine_verify_tip3: '将身份证件',
  machine_verify_tip4: '置于读卡区域',
  machine_verify_tip5: '请保持身份证',
  machine_verify_tip6: '放置至少3秒,直到',
  machine_verify_tip7: '登记界面跳转到下一步',
  machine_forgetID: '未带身份证',
  machine_signOn: '登记成功，请等待审核',
  machine_addAccompany: '添加人员',
  machine_registerAgain: '重新登记',
  machine_register: '登记',
  machine_accompany: '随行人员',
  machine_idCard_tip1: '身份证号不能为空',
  machine_idCard_tip2: '请输入正确身份证号',
};
