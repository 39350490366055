/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  106101: '操作成功',
  106102: '操作失敗',
  106103: '該用戶尚未綁定，請登錄進行綁定！',
  106104: '獲取個人微信賬號ID失敗！',
  106105: '獲取個人微信賬號ID失敗：公眾賬號系統配置有誤！',
  106106: '獲取個人微信賬號ID失敗：找不到賬號！',
  106107: '獲取個人微信賬號ID失敗：code不能為空！',
  106108: '獲取用戶綁定狀態失敗！',
  106109: '綁定賬號成功！',
  106110: '綁定賬號失敗！',
  106111: '用戶名或密碼錯誤！',
  106112: '該賬號已被綁定，不能重複綁定！',
  106113: '發送消息失敗！',
  106114: '獲取用戶信息失敗！',
  106115: 'code認證失敗',
  106116: '公眾賬號系統配置有誤',
  106117: '找不到對應的小程序',
  106118: '登錄服務器未響應',
  106119: '獲取賬號綁定界面URL失敗',
  106120: '訪問令牌無效或過期，請重新登錄',
  106121: '獲取域信息失失敗',
  106222: '名稱重複,請重新輸入',
  106223: '刪除失敗，不能刪除已綁定應用的類型',
  106224: '請選擇應用類型',
  106225: '沒有對應的應用類型',
  106226: '微服務認證失敗',
  106227: '對接企業微信平台設置可信域名失敗',
  106228: '程序內部異常！',

  106001: '操作成功',
  106002: '威思客應用不存在！',
  106003: '獲取AccessToken異常',
  106004: '生成菜單異常',
  106005: '參數非法',
  106006: '遠程服務器異常',

  106000: '系統异常',
  106504: '認證失敗！',
  106505: '用戶名或密碼錯誤！',
};
