export default {
  'sws.inventorylist.inventorytitle.confim': '未盤點',
  'sws.inventorylist.inventorytitle.wait': '待確認',
  'sws.inventorylist.inventorytitle.done': '已盤點',
  'sws.inventorylist.stateTitle.yes': '正常',
  'sws.inventorylist.stateTitle.no': '已終止',
  'sws.inventorylist.taskStatusTitle.will': '未開始',
  'sws.inventorylist.taskStatusTitle.doning': '進行中',
  'sws.inventorylist.taskStatusTitle.done': '已結束',
  'sws.inventorylist.contant.time': '任務日期',
  'sws.inventorylist.contant.name': '任務名稱',
  'sws.inventorylist.contant.task': '任務狀態',
  'sws.inventorylist.contant.tasktime': '盤點時間',
  'sws.inventorylist.content.placeholder': '在這裡手動輸入',
};
