import { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取微信JS-SDK密钥
 * @param domainId 租户Id
 * @param wechatType  wechatType  1 服务号  3 企业微信  4 小程序
 * @returns {{data, headers}|{data}|*}
 */

export const getJssdkTicket = ({ domainId, wechatType }) => {
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/wdfClient/enterpriseNumber/getWechatTicket`, {
    method: 'POST',
    data: JSON.stringify({
      domainId,
      wechatType,
      urlPath: window.location.href.split('#')[0],
    }),
  });
};
