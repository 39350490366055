import { formatMessage, getLocale } from 'umi';
import { getMenu } from '@/services/user'
import { MENU_PATH, ACCESS_TOKEN } from '@/pages/Meeting/utils/constant';
import { getUrlParam } from '../../utils/common';
/**
 * format original menu data from fetch.
 * @param original
 * @returns {{path: *, authority: undefined, icon: *, name: *, locale: *}}
 */
const formatDate = (original) => {
  return {
    authority: original.authority,
    children: [],
    icon: original?.icon?.trim().length > 0 ? original?.icon : null,
    locale: original.name,
    name: original.label,
    path: original.path,
    permission: original.permission,
  };
};
/**
 *
 * @param menu
 * @param parent
 */
const menuDataConversion = (menu, parent) => {
  menu.forEach((v) => {
    if(v.productCode === 'smt') {
      const node = formatDate(v);
      parent.push(node);
      if (v.children.length > 0) {
        menuDataConversion(v.children, node.children);
      } else {
        node.children = null;
      }
    }
  });
};

export default {
  namespace: 'homePage',
  state: {
    appIndexBtns: [],
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *loadBtnConf(_, { call, put }) {
      const menu = yield call(getMenu);
      const menuData = [];
      let m = [];
      menuDataConversion(menu.data, menuData);
      if(menuData && menuData.length > 0) {
        menuData.forEach(items => {
          const item = items.children;
          m = item;
        });
      }
      yield put({ type: 'update', payload: { appIndexBtns: m } });
    },
    *initTitle(_, { select }) {
      const { appIndexTitle = '' } = yield select((state) => state.sysModule.base);
      if (appIndexTitle && appIndexTitle.split(';').length === 3) {
        const zhTitle = appIndexTitle.split(';')[0];
        const twTitle = appIndexTitle.split(';')[1];
        const usTitle = appIndexTitle.split(';')[2];
        if (getLocale() === 'en-US') {
          document.title = usTitle;
        } else if (getLocale() === 'zh-TW') {
          document.title = twTitle;
        } else {
          document.title = zhTitle;
        }
        return appIndexTitle;
      }
      document.title = formatMessage({ id: 'router.title.meeting.HomePage' });
      return null;
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname.indexOf('meeting/index') > -1||pathname.indexOf('index') > -1) {
          // console.log(history,"===history")
          // 清除会议预约数据
          dispatch({ type: 'subscribe/reset' });
          dispatch({ type: 'attendee/reset' });
          const roomId = getUrlParam('roomId');
          const v = getUrlParam('v');
          const token = getUrlParam('token');
          if (token) {
            sessionStorage.setItem(ACCESS_TOKEN, token);
          }
          if (roomId) {
            // dispatch({
            //   type: 'user/fetchCurrentUserInfo',
            //   payload: {
            //     saveInfo: false,
            //   },
            // }).then(() => {
            //     dispatch({ type: 'subscribe/initHost' }).then(()=>{
            //        sessionStorage.setItem('QR_SCAN_ROOM_ID', String(roomId));
            //   history.replace(MENU_PATH.subscribeDetail);
            //     });
            // });
            dispatch({
              type: 'user/fetchCurrentUserInfo',
              payload: {
                saveInfo: true,
                redirect: decodeURIComponent(`${MENU_PATH.feedback}?roomId=${roomId}`),
              },
            });
          } else if (v) {
            dispatch({
              type: 'subscribe/getdecryptRoomId',
              payload: {
                roomId: v,
              },
            }).then((res) => {
              dispatch({
                type: 'user/fetchCurrentUserInfo',
                payload: {
                  saveInfo: true,
                  redirect: decodeURIComponent(`${MENU_PATH.feedback}?roomId=${res}`),
                },
              });
            }).catch((e) => {
              dispatch({
                type: 'user/fetchCurrentUserInfo',
                payload: {
                  saveInfo: true,
                  redirect: decodeURIComponent(`${MENU_PATH.feedback}?roomId=${v}`),
                },
              });
            });
          } else {
            document.title = '';
            let count = 0;
            const titleInterval = setInterval(() => {
              if (count < 5) {
                dispatch({ type: 'initTitle' })?.then((appIndexTitle) => {
                  if (appIndexTitle) {
                    count = 10;
                    clearInterval(titleInterval);
                  }
                });
              }
              count += 1;
            }, 150);
            dispatch({ type: 'loadBtnConf' });
          }
        }
      });
    },
  },
};
