/**
 * Created : vincent
 * Date : 2019-03-20  19:22
 * Email : wangxiao@wafersystems.com
 */
import request, { requestNoAuthorization } from '@/utils/request';
import { ACCESS_TOKEN, RESTFUL_PATH } from '@/utils/constant';

// 获取门禁列表
export async function getDoorList() {
  return request(`/${RESTFUL_PATH.access}/api/door/getAllDoors`);
}

/* 上传头像 */
export async function updateLoadImage({ formData }) {
  return requestNoAuthorization(`/${RESTFUL_PATH.access}/sss/upload`, {
    method: 'POST',
    body: formData.formData,
    headers: {
      Authorization: `bearer ${sessionStorage.getItem(ACCESS_TOKEN)}`,
    },
  });
}

/* 添加或更新门禁报修单 */
export async function saveOrUpdateDoorTroubleTicket(params) {
  return request(`/${RESTFUL_PATH.access}/api/doorTroubleTicket/saveOrUpdateDoorToubleTicket`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export async function queryCurrentUserInfo() {
  return request(`/${RESTFUL_PATH.admin}/user/info`);
}
