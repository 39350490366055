/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.myAccess.empower.title': '已经授权门禁',
  'access.myAccess.apply.btn.title': '申请门禁',
  'access.myAccess.btn.open': '开门',
  'access.open.door.success': '操作成功',
};
