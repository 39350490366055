import request from '@/utils/request';
import { downloadFile2,downloadFile } from '@/pages/Visitor/utils/download';
import { RESTFUL_PATH, ACCESS_TOKEN } from '@/utils/constant';
import { specialAscall } from '@/pages/Meeting/utils/common';

export function reserveMeeting({ params }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/reserve`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function getAllZones() {
  return request(`/${RESTFUL_PATH.upms}/space/tree`, {
    method: 'GET',
  });
}

export function getAllRooms(params) {
  return request(`/${RESTFUL_PATH.meeting}/room-info/rooms`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function getValidInfo(params) {
  return request(`/${RESTFUL_PATH.meeting}/room-info/getValidInfo`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function getRoomListValidInfo(params) {
  return request(`/${RESTFUL_PATH.meeting}/room-info/getRoomListValidInfo`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function getDevices() {
  return request(`/${RESTFUL_PATH.meeting}/device-info/list`, {
    method: 'GET',
  });
}

export function findRoomByEmail(params) {
  return request(`/${RESTFUL_PATH.meeting}/room-info/findRoomByEmail`, {
    method: 'POST',
    params,
  });
}

export const sendMeetingNotice = ({ meetingId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/sendMeetingNotice/${meetingId}`, {
    method: 'POST',
  });
};

export function getDetailInfo({ meetingId }) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/detail-info/${meetingId}`, {
    method: 'GET',
  });
}

export const getReservePageInfo = () => {
  return request(`/${RESTFUL_PATH.meeting}/app-info/reserve-page`, {
    method: 'GET',
  });
};

export const savePollings = ({ pollData }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-zoom/saveOrUpdateZoomPolling`, {
    method: 'POST',
    body: JSON.stringify(pollData),
  });
};

export const getZoomPolling = ({ meetingId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-zoom/getZoomPoll?meetingId=${meetingId}`, {
    method: 'GET',
  });
};

export const getRoomStates = () => {
  return request(`/${RESTFUL_PATH.meeting}/room-info/findAllRoomState`, {
    method: 'GET',
  });
};

export const getUserSeat = ({ meetingId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/getUserSeatInfo/${meetingId}`, {
    method: 'GET',
  });
};

export const getSeatTemplateInfo = () => {
  return request(`/${RESTFUL_PATH.meeting}/room-template/getSeatTemplate`, {
    method: 'GET',
  });
};

export const getRoomDash = ({ roomId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/getRoomMeetingDetail/${roomId}`, {
    method: 'GET',
  });
};
export async function blackApi(userId) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-blacklist/isInBlackList/${userId}`);
}

// 获取解密后的roomId
export const getdecryptRoomId = ({ roomId }) => {
  return request(`/${RESTFUL_PATH.meeting}/room-info/getdecryptRoomId?roomId=${roomId}`, {
    method: 'GET',
  });
};

export async function getMeetingstartTimeOption({ roomIds, meetingId }) {
  if (meetingId && meetingId > 0) {
    return request(
      `/${RESTFUL_PATH.meeting}/web-info/getRoomCanReserveTime?roomIds=${roomIds}&meetingId=${meetingId}`,
      {
        method: 'GET',
      },
    );
  }
  return request(`/${RESTFUL_PATH.meeting}/web-info/getRoomCanReserveTime?roomIds=${roomIds}`, {
    method: 'GET',
  });
}

export async function fetchCheckCycle(param) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/getRecurringMeetingRule`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

// 获取访客列表是否签到
export const getMeetingVisitorList = ({ visitId }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/invitations/getVisitorList/${visitId}`, {
    method: 'GET',
  });
};

// CUSTOM:会议资料下载
export async function exportMeetingFile(params) {
  const { meetingAttachment, userId, tenantId } = params;
  let url = `/${RESTFUL_PATH.meeting}/meeting-info/exportMeetingFile?userId=${userId}&tenantId=${tenantId}`;
  const { fileName, fileUrl, meetingId } = meetingAttachment;
  if (fileName) {
    const newName = specialAscall(fileName);
    url = `${url}&fileName=${newName}`;
  }
  if (meetingId) {
    url = `${url}&meetingId=${meetingId}`;
  }
  if (fileUrl) {
    url = `${url}&fileUrl=${fileUrl}`;
  }
  return downloadFile2('', url);
}

export async function exportMeetingAgendaFile(params){
  const { meetingAttachment, userId, tenantId } = params;
  let url = `/${RESTFUL_PATH.meeting}/agenda-info/exportMeetingFile?userId=${userId}&tenantId=${tenantId}`;
  const { fileName, fileUrl, meetingId } = meetingAttachment;
  if (fileName) {
    const newName = specialAscall(fileName);
    url = `${url}&fileName=${newName}`;
  }
  if (meetingId) {
    url = `${url}&meetingId=${meetingId}`;
  }
  if (fileUrl) {
    url = `${url}&fileUrl=${fileUrl}`;
  }
  url = `${url}&token=${sessionStorage.getItem(ACCESS_TOKEN)}`;
  return downloadFile2('', url);
}



