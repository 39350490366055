export default {
  'cmma.status.connected': '已連接',
  'cmma.status.ringing': '撥號中',
  'cmma.status.unknown': '未連接',
  'cmma.tag.terminal': '終端',
  'cmma.tag.number': '號碼',
  'cmma.tag.No': '號碼:',
  'cmma.tag.online': '方在線',
  'cmma.tag.pwd': '密碼:',
  'cmma.tag.time': '時間:',
  'cmma.btn.mainRoom': '主會場',
  'cmma.btn.appoint': '點名發言',
  'cmma.btn.camera': '攝像頭',
  'cmma.btn.mute': '靜音',
  'cmma.btn.hangUp': '掛斷',
  'cmma.btn.reload': '重撥',
  'cmma.btn.startNow': '一鍵開啟',
  'cmma.btn.add': '添加',
  'cmma.btn.muteAll': '全部靜音',
  'cmma.btn.dial': '撥號',
  'cmma.btn.lock': '鎖定會議',
  'cmma.btn.delay': '延長',
  'cmma.btn.text': '字幕',
  'cmma.btn.layout': '分屏',
  'cmma.btn.rotation': '輪詢',
  'cmma.btn.send': '發送',
  'cmma.btn.yes': '是',
  'cmma.btn.no': '否',
  'cmma.btn.cancel': '取消',
  'cmma.btn.rotation.stop': '取消輪詢',
  'cmma.btn.autoReconnect': '自動重連',
  'cmma.msg.allMuteValid': '全部靜音成功',
  'cmma.msg.allMuteInvalid': '全部靜音關閉',
  'cmma.msg.lockValid': '會議鎖定成功',
  'cmma.msg.lockInvalid': '會議鎖定關閉',
  'cmma.msg.mainRoom': '主會議室設置成功',
  'cmma.msg.appointValid': '點名發言打開成功',
  'cmma.msg.appointInvalid': '點名發言關閉成功',
  'cmma.msg.cameraValid': '視頻打開成功',
  'cmma.msg.cameraInvalid': '視頻關閉成功',
  'cmma.msg.muteValid': '靜音設置成功',
  'cmma.msg.muteInvalid': '靜音取消成功',
  'cmma.msg.hangUp': '掛斷成功',
  'cmma.msg.currentClose': '當前會議已結束',
  'cmma.msg.startSuccess': '視頻會議啟動成功',
  'cmma.msg.busyList': '以下會議室被佔用',
  'cmma.msg.startFailedBusy': '視頻會議啟動失敗，資源佔用',
  'cmma.msg.startFailed': '視頻會議啟動失敗',
  'cmma.msg.closeSuccess': '視頻會議結束成功',
  'cmma.msg.dialFailed': '呼叫失敗',
  'cmma.msg.usingInfo': '終端被以下會議佔用',
  'cmma.msg.delay': '會議延長成功',
  'cmma.msg.hangupTitle': '掛斷',
  'cmma.msg.hangupMsg': '是否釋放會議室資源',
  'cmma.msg.socketError': 'CMMA網絡連接異常',
  'cmma.msg.duplicate': '您呼叫的號碼已經在當前會議中',
  'cmma.msg.text.title': '編輯字幕',
  'cmma.msg.text.ph1': '*請輸入顯示時長(秒)',
  'cmma.msg.text.ph2': '*請輸入60字以內的消息',

  'cmma.msg.text.success': '字幕消息發送成功',
  'cmma.msg.rotation': '當前輪詢間隔為 {interval} 秒',
  'cmma.title.rotation': '輪詢設置',
  'cmma.msg.rotation.stop': '已取消輪詢設置',
  'cmma.msg.rotation.start': '成功開啟輪詢設置',
  'cmma.msg.layout.success': '分屏模式設置成功',
  'cmma.msg.autoReonnect.open': '自動重連已打開',
  'cmma.msg.autoReonnect.close': '自動重連已關閉',
  '': '',

  'cmma.layout.speakerOnly': '全屏布局',
  'cmma.layout.telepresence': '網真布局',
  'cmma.layout.stacked': '堆疊布局',
  'cmma.layout.allEqual': '對等分屏',
  'cmma.layout.allEqualQuarters': '2*2分屏',
  'cmma.layout.allEqualNinths': '3*3分屏',
  'cmma.layout.allEqualSixteenths': '4*4分屏',
  'cmma.layout.allEqualTwentyFifths': '5*5分屏',
  'cmma.layout.onePlusN': '1+n分屏',
  'cmma.layout.onePlusFive': '1+5分屏',
  'cmma.layout.onePlusSeven': '1+7分屏',
  'cmma.layout.onePlusNine': '1+9分屏',
  'cmma.layout.global': '全局',

  mc_btn_subtitle: '會議字幕',
  mc_btn_minute: '會議紀要',
  mc_subtitle_language: '語言',
  mc_subtitle_translate: '翻譯',
  mc_subtitle_sublanguage: '副語言',
  mc_subtitle_language_zh: '中文-普通話',
  mc_subtitle_language_en_u: '英語-美式',
  mc_subtitle_language_en_a: '英語-澳大利亞',
  mc_subtitle_language_kr: '韓國語',
  mc_subtitle_language_jp: '日本語',
  mc_subtitle_language_my: '馬來語',
  mc_subtitle_google: '僅Google支持',
  mc_asr_config_error: '視頻空間配置異常',
  mc_msg_success: '操作成功，請稍候...',
};
