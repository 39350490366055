/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 查询用户位置信息
 * @param payload 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getStationMapByUser(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/station/findStationsByUser?domainId=${
    payload.domainId
  }&occUserName=${payload.userName || ''}&localeTimeZone=Asia/Shanghai`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询用户位置信息
 * @param payload 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
// eslint-disable-next-line import/prefer-default-export
export async function getSearchResult(payload) {
  const url = `/${
    RESTFUL_PATH.sws
  }/api/client/findStationsByUser?occUserName=${payload.userName.trim() || ''}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询用户位置信息
 * @param payload 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getStationMapByUserX(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/station/findStationsByUser?domainId=${
    payload.domainId
  }&occUserName=${payload.userName || ''}&localeTimeZone=Asia/Shanghai`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询用户固定工位
 * 工位查询-用户使用工位
 * @param searchType 查询类型
 * @param userId,查询用户还是编号
 * @param stationId
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getUserStation({searchType, userId, stationId}) {
  let url = `/${RESTFUL_PATH.sws}/api/web/fixed-user?userId=${userId}`;
  if (Number(searchType) === 1) {
    url = `/${RESTFUL_PATH.sws}/api/web/queryStationInfo?stationId=${stationId}`;
  }
  return request(url, {
    method: 'GET',
  });
}

/**
 * 模糊查询用户
 * @param info 按照用户名称或者工位编号模糊查询
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getUser(info) {
  const url = `/${RESTFUL_PATH.sws}/api/web/like-user-stationNum?like=${info}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询用户默认地图
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getUserMap() {
  const url = `/${RESTFUL_PATH.sws}/api/web/default-map`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询用户默认区域对应的地图信息
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getDefaultMap(spaceId) {
  return request(`/${RESTFUL_PATH.map}/map/getBySpaceId/${spaceId}`, {
    method: 'GET',
  });
}
