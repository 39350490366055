/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.faultRepair.title': '所有門禁',
  'access.faultRepair.reason': '請輸入報修理由',
  'access.faultRepair.my.recode': '我的報修記錄',
  'access.faultRepair.commit': '提交',
  'menu.sas.app.faultRepair': '故障報修',
};
