/* Populated by react-webpack-redux:action */

export const CONTENT_PATH = '/ReserveStation';

// 语言类型
export const CHINESE = 'zh-CN';
export const ENGLISH = 'en-US';
export const TRADITIONAL = 'zh-TW';

// 工位状态statusCode
// export const STATUS_DISABLED = -1; // 不可用状态
export const STATUS_DISABLED = 0; // 不可用状态
export const STATUS_RESERVED = 1; // 已预订
export const STATUS_OCCUPIED = 2; // 已占用
export const STATUS_MOBILE_FREE = 3; // 移动工位空闲
export const STATUS_FREE = 5; // 固定工位空闲
export const STATUS_MERGE_FREE = 10; // 空闲
export const STATUS_MERGE_OCCUPY = 11; // 占用

// 传感器推送有人:1，无人:0
export const SENSOR_OCCUPIED = 1;

// 显示设置sessionStorage处理
export const SWS_DISPLAY_SETTING = 'sws:displaySetting';
// 基础设置
export const SWS_BASIC_SETTING = 'basicSetting';
// 工位预定
export const SWS_STATION_RESERVE = 'stationReserve';
// 工位管理
export const SWS_STATION_MANAGE = 'stationManage';
// 工位查询
export const SWS_STATION_SEARCH = 'stationSearch';
// 工位展示
export const SWS_STATION_DISPLAY = 'stationDisplay';
// 显示设置项目
export const SWS_DISPLAY_SETTING_ITEM = {
  userName: `${SWS_DISPLAY_SETTING}:${SWS_BASIC_SETTING}:userName`,
  orgName: `${SWS_DISPLAY_SETTING}:${SWS_BASIC_SETTING}:orgName`,
  stationNum: `${SWS_DISPLAY_SETTING}:${SWS_BASIC_SETTING}:stationNum`,
  free: `${SWS_DISPLAY_SETTING}:${SWS_STATION_RESERVE}:free`,
  reserved: `${SWS_DISPLAY_SETTING}:${SWS_STATION_RESERVE}:reserved`,
  used: `${SWS_DISPLAY_SETTING}:${SWS_STATION_RESERVE}:used`,
  fixed: `${SWS_DISPLAY_SETTING}:${SWS_STATION_RESERVE}:fixed`,
  stopped: `${SWS_DISPLAY_SETTING}:${SWS_STATION_MANAGE}:stopped`,
  unbind: `${SWS_DISPLAY_SETTING}:${SWS_STATION_MANAGE}:unbind`,
  mobileStation: `${SWS_DISPLAY_SETTING}:${SWS_STATION_MANAGE}:mobileStation`,
  otherDisabled: `${SWS_DISPLAY_SETTING}:${SWS_STATION_MANAGE}:otherDisabled`,
  otherOrgOccupied: `${SWS_DISPLAY_SETTING}:${SWS_STATION_MANAGE}:otherOrgOccupied`,
  otherFree: `${SWS_DISPLAY_SETTING}:${SWS_STATION_MANAGE}:otherFree`,
  administrationOrgDisChosing: `${SWS_DISPLAY_SETTING}:${SWS_STATION_MANAGE}:administrationOrgDisChosing`,
  orgManageStationAppChosing: `${SWS_DISPLAY_SETTING}:${SWS_STATION_MANAGE}:orgManageStationAppChosing`,
  orgManageStationAppLocking: `${SWS_DISPLAY_SETTING}:${SWS_STATION_MANAGE}:orgManageStationAppLocking`,
  orgManageUserDisChosing: `${SWS_DISPLAY_SETTING}:${SWS_STATION_MANAGE}:orgManageUserDisChosing`,
  searchResult: `${SWS_DISPLAY_SETTING}:${SWS_STATION_SEARCH}:searchResult`,
  pathNavi: `${SWS_DISPLAY_SETTING}:${SWS_STATION_SEARCH}:pathNavi`,
  other: `${SWS_DISPLAY_SETTING}:${SWS_STATION_SEARCH}:other`,
  stationOccupied: `${SWS_DISPLAY_SETTING}:${SWS_STATION_DISPLAY}:stationOccupied`,
  stationFree: `${SWS_DISPLAY_SETTING}:${SWS_STATION_DISPLAY}:stationFree`,
};

// websocket订阅常量
export const MAP_CHANNEL = '/topic/sws-map'; // 工位地图订阅通道
export const MAP_TOPIC = '/topic/map'; // 地图订阅通道
export const WEBSOCKET_TYPE = 'login/subscribe'; // 类型

// 工位类型 移动工位 固定工位
export const STATION_MOBILE = 1;
export const STATION_FIXED = 2;

// 预订类型
export const BOOKING_TYPE = 1; // 普通预订
export const SPECIAL_TYPE = 2; // 特殊预订
// 使用类型
export const BOOKING = 1; // 预约
export const IMMEDIATE_USE = 2; // 立即使用

// 常量-1
export const SPECIAL_NEGATIVE_VALUE = -1;

// resSwitch开启与否：从而确定是“预订”还是“立即使用”
export const RES_SWITCH_ON = 0; // 开启
export const RES_SWITCH_OUT = 1; // 关闭

// //预订、签到、注销页面编号
export const BOOKING_PAGE = 1; // 预约页面
export const SEARCH_PAGE = 4; // 查找页面
export const FIND_STATION_PAGE = 5; // 特殊预约查找
export const BOOKING_RECOMMEMD_PAGE = 6; // 推荐预订底部

export const SEARCH_CHAUNVE = 'chaunveMap'; // 创互地图查找
export const SEARCH_CHAUNVE_STATE = {
  go: 1, // 去这里
  navStart: 2, // 模拟导航
  navEnd: 3, // 结束导航
};

// 手机端类型
export const JSSDKTYPE_PORTAL = '1'; // 威思客平台
export const JSSDKTYPE_WECHAT = '2'; // 微信
export const JSSDKTYPE_APP = '3'; // 原生APP
export const JSSDKTYPE_DINGDING = '4'; // dingding APP
export const JSSDKTYPE_ZHONGJIAN = '5'; // 中建
export const JSSDKTYPE_MINI = '6'; // mini Program

export const WX_TYPE = 0; // 小程序

// 授权信息
export const ACCESS_TOKEN = 'access_token';
export const AUTHORITY = 'authority';

// 工位报修状态
export const SWS_REPAIR_STATUS = {
  pending: 1,
  processing: 2,
  processed: 3,
  cancelled: 4,
};

// 工位报修状态对应的颜色
export const SWS_REPAIR_STATUS_COLOR = {
  pending: '#FFF5F3',
  processing: '#E6FCF5',
  processed: '#EEF1F5',
};

// 工位报修状态对应的背景色
export const SWS_REPAIR_STATUS_BG_COLOR = {
  pending: 'rgba(205, 118, 101,0.15)',
  processing: 'rgba(48, 162, 153,0.15)',
  processed: 'rgba(135, 147, 163,0.15)',
};
// 工位报修状态文字对应的背景色
export const SWS_REPAIR_STATUS_FONTSIZE_COLOR = {
  pending: '#CD7665',
  processing: '#30A299',
  processed: '#8793A3',
};

// 工位预订状态
export const SWS_RESERVE_STATUS = {
  reserved: 1,
  using: 2,
  // 已取消
  canceled: 3,
  // 已使用
  used: 4,
};

// 工位预订状态对应的颜色
export const SWS_RESERVE_STATUS_COLOR = {
  reserved: '#30A299',
  using: '#CD7665',
  used: '#8793A3',
};

// 工位预订状态对应的背景色
export const SWS_RESERVE_STATUS_BG_COLOR = {
  reserved: '#CAEEE7',
  using: '#F8E2DD',
  used: '#DEE3E8',
};

// 工位预订状态card对应的背景色
export const SWS_RESERVE_STATUS_BG_COLOR_CARD = {
  reserved: '#E6FCF5',
  using: '#FFF5F3',
  used: '#EEF1F5',
};

// 工位颜色定义
export const COLOR_DEFAULT = '#999999'; // 缺省颜色，不可预订
export const COLOR_FREE = '#539DD1'; // 空闲颜色
export const COLOR_BOOKED = '#FF9930'; // 预订颜色
export const COLOR_USED = '#F00000'; // 使用中颜色
export const COLOR_SELECTED = '#2A688D'; // 选中颜色
export const COLOR_FIXED = '#783C22'; // 固定工位颜色#409809

// 位置选择组件颜色
export const ADDRESS_CHOSE = '#30A299'; // 位置选择
export const ADDRESS_FREE = '#fff'; // 位置未选择
export const ADDRESS_DISABLED = '#9F9F9F'; // 位置置灰

// 创互地图选中颜色
export const CHAUNVE_SELECTOR_COLOR = '#543B20';
