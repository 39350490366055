/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'menu.meeting.meeting_app': '智能会议',
  'menu.workspace.mobile': '智能工位',
  'menu.vst.app.manage': '智能访客',
  'menu.app.index': '主页',
  'menu.app.my': '我的',
  'menu.feishu.homePage.title': 'MetaWork',
  'menu.feishu.homePage.remind': '数字化行政办公',
  'menu.envm.index': '环境监测',
  'menu.tcs.index': '数字化卫生间',
};
