import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import moment from 'moment-timezone';
import { getBillCategory, getBillTotal, getBillList, getBillDetail } from './service';

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);

const initialFilter = {
  queryStartTime: moment(now).startOf('month').valueOf(),
  queryEndTime: moment(now).endOf('month').valueOf(),
  businessType: '',
  businessCode: '',
};

export default {
  namespace: 'bill',
  state: {
    searchDate: now,
    billBusinessType: [],
    selectValue: [],
    billTotalData: {
      income: 0,
      expenditure: 0,
    },
    searchType: {
      ...initialFilter,
    },
    billListData: { records: [], current: 1, size: 10, total: 0, pages: 1 },
    dataArr: [],
    curPage: 1,
    billDetailData: {},
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
    reSetFilter(state) {
      return { ...state, searchType: initialFilter, searchDate: now };
    },
  },
  effects: {
    *getBillCategory(_, { put, call }) {
      const result = yield call(getBillCategory);
      if (result.code === 0) {
        yield put({ type: 'save', payload: { billBusinessType: result.data || [] } });
      }
    },
    *getBillTotal({ payload }, { put, call, select }) {
      const selectTime = yield (payload && payload.searchDate) ||
        select((state) => state.bill.searchDate);
      const result = yield call(getBillTotal, {
        queryStartTime: moment(selectTime).startOf('month').valueOf(),
        queryEndTime: moment(selectTime).endOf('month').valueOf(),
      });
      if (result.code === 0) {
        const searchType = yield select((state) => state.bill.searchType);
        yield put({
          type: 'save',
          payload: {
            billTotalData: result.data || {},
            searchDate: selectTime,
            searchType: {
              ...searchType,
              queryStartTime: moment(selectTime).startOf('month').valueOf(),
              queryEndTime: moment(selectTime).endOf('month').valueOf(),
            },
          },
        });
      }
    },
    *getBillList({ searchType, page, size, bUpdate = false }, { put, call, select }) {
      const tempSearchType = yield searchType || select((s) => s.bill.searchType);
      const result = yield call(getBillList, {
        queryStartTime: tempSearchType.queryStartTime,
        queryEndTime: tempSearchType.queryEndTime,
        businessType: tempSearchType.businessType,
        businessCode: tempSearchType.businessCode,
        page: page || 1,
        size: size || 10,
      });
      if (result.code === 0) {
        if (!bUpdate) {
          if (page > 1) {
            const { data } = result;
            const { billListData } = yield select((state) => state.bill);
            data.records.push(...billListData.records);
            Object.assign(billListData, data);
            yield put({
              type: 'save',
              payload: {
                billListData: billListData || {},
                searchType: tempSearchType,
                curPage: page,
              },
            });
          } else {
            yield put({
              type: 'save',
              payload: {
                billListData: result.data || {},
                searchType: tempSearchType,
                curPage: page,
              },
            });
          }
        } else {
          yield put({
            type: 'save',
            payload: {
              billListData: result.data || {},
              dataArr: [],
              searchType: tempSearchType,
              curPage: page,
            },
          });
        }
      }
    },
    *getBillDetail({ payload }, { call, put }) {
      const result = yield call(getBillDetail, { ...payload });
      if (result.code === 0) {
        yield put({ type: 'save', payload: { billDetailData: result.data } });
      } else {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
  },
  subscriptions: {},
};
