/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import { history } from 'umi';
import { Toast } from 'antd-mobile-v2';
import 'pinyin4js';
import Virsical from 'virsical-jssdk';
import { APP_HOME_PIX, APP_ROUTE_PATH, customListToInfo } from '@/pages/Visitor/utils/constant';

const getAreaCode = (phone) => {
  const obj = { code: '', mobile: '' };
  if (phone && phone.indexOf('+') === 0) {
    if (phone && phone.indexOf('+86') === 0 && phone.length === 14) {
      obj.code = '+86';
      obj.mobile = phone.substring(3, phone.length);
    } else if (phone && phone.indexOf('+852') === 0 && phone.length === 12) {
      obj.code = '+852';
      obj.mobile = phone.substring(4, phone.length);
    }
  } else if (phone && phone.indexOf('00') === 0) {
    if (phone && phone.indexOf('0086') === 0 && phone.length === 15) {
      obj.code = '+86';
      obj.mobile = phone.substring(4, phone.length);
    } else if (phone && phone.indexOf('00852') === 0 && phone.length === 13) {
      obj.code = '+852';
      obj.mobile = phone.substring(5, phone.length);
    }
  } else if (phone && phone.indexOf('86') === 0 && phone.length === 13) {
    obj.code = '+86';
    obj.mobile = phone.substring(2, phone.length);
  } else if (phone && phone.indexOf('852') === 0 && phone.length === 11) {
    obj.code = '+852';
    obj.mobile = phone.substring(3, phone.length);
  } else if ((phone && phone.length === 11) || (phone && phone.length === 12)) {
    obj.code = '+86';
    obj.mobile = phone;
  } else if (phone && phone.length === 8) {
    obj.code = '+852';
    obj.mobile = phone;
  } else {
    obj.mobile = phone;
  }
  if (obj.mobile === '') {
    Toast.info('只支持选择大陆或香港地区移动电话', 2);
  }
  return obj;
};

// const organizeDataByLetter = arr => {
//   const letters = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`;
//   Object.keys(arr).forEach(i => {
//     const item = arr[i];
//     const { name } = item;
//     const phone = getAreaCode(item.phone);
//     item.key = `${item.name}_${item.mail ? item.mail : ''}_${phone.mobile ? phone.mobile : ''}`;
//     item.letter = window.PinyinHelper.convertToPinyinString(
//       name,
//       '',
//       window.PinyinFormat.WITH_TONE_MARK
//     ).toUpperCase();
//   });
//   // for (const i in arr) {
//   //   const item = arr[i];
//   //   let name;
//   //   name = item.name;
//   //   const phone = getAreaCode(item.phone);
//   //   item.key = `${item.name}_${item.mail ? item.mail : ''}_${phone.mobile ? phone.mobile : ''}`;
//   //   item.letter = window.PinyinHelper.convertToPinyinString(
//   //     name,
//   //     '',
//   //     window.PinyinFormat.WITH_TONE_MARK
//   //   ).toUpperCase();
//   // }
//   arr.sort((a, b) => {
//     return a.letter.charCodeAt(0) - b.letter.charCodeAt(0);
//   });
//   const result = [{ letter: '#', data: [] }];
//   const resultLetters = ['#'];
//   // Object.keys(arr).forEach(item => {
//   //   const letter = item.letter.charAt(0);
//   //   if (letters && letters.indexOf(letter) === -1) {
//   //     result[0].data.push(item);
//   //   } else {
//   //     const index = resultLetters.indexOf(letter);
//   //     if (index >= 0) {
//   //       result[index].data.push(item);
//   //     } else {
//   //       result.push({ letter, data: [item] });
//   //       resultLetters.push(letter);
//   //     }
//   //   }
//   // });
//   // eslint-disable-next-line no-restricted-syntax
//   for (const item of arr) {
//     const letter = item.letter.charAt(0);
//     if (letters && letters.indexOf(letter) === -1) {
//       result[0].data.push(item);
//     } else {
//       const index = resultLetters.indexOf(letter);
//       if (index >= 0) {
//         result[index].data.push(item);
//       } else {
//         result.push({ letter, data: [item] });
//         resultLetters.push(letter);
//       }
//     }
//   }
//   return { result, resultLetters };
// };

const organizeDataByLetter = (arr) => {
  const letters = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`;
  arr.forEach((item) => {
    const { name } = item;
    const phone = getAreaCode(item.phone);
    item.key = `${item.name}_${item.mail ? item.mail : ''}_${phone.mobile ? phone.mobile : ''}`;
    item.letter = window.PinyinHelper.convertToPinyinString(
      // eslint-disable-line
      name,
      '',
      window.PinyinFormat.WITH_TONE_MARK,
    ).toUpperCase();
  });

  arr.sort((a, b) => {
    return a.letter.charCodeAt(0) - b.letter.charCodeAt(0);
  });
  const result = [{ letter: '#', data: [] }];
  const resultLetters = ['#'];
  for (const item of arr) {
    // eslint-disable-line
    const letter = item.letter.charAt(0);
    if (letters.indexOf(letter) === -1) {
      result[0].data.push(item);
    } else {
      const index = resultLetters.indexOf(letter);
      if (index >= 0) {
        result[index].data.push(item);
      } else {
        result.push({ letter, data: [item] });
        resultLetters.push(letter);
      }
    }
  }
  if (!result?.[0]?.data?.length) {
    result?.shift();
    resultLetters?.shift();
  }
  return { result, resultLetters };
};
export default {
  namespace: 'contact',
  state: {
    data: [],
    letters: [],
    selectedKey: [],
    outerList: [],
  },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload };
    },
    doWhenLoadOutContactFail() {
      Toast.info('请开通获取通讯录权限', 3);
      history.replace(APP_ROUTE_PATH.APP_VISITOR_NEW);
    },
  },
  effects: {
    *reset(_, { put }) {
      const contact = {
        data: [],
        letters: [],
        selectedKey: [],
        outerList: [],
      };
      yield put({ type: 'set', payload: contact });
    },
    *loadForOuterContact({ payload: { contactArr: contactArrs } }, { put }) {
      // contactArr结构
      // let contactArr = [
      //   {
      //     id: 1,
      //     name: 'Bruce1',
      //     phone: `+8613312345654`,
      //     mail: '492629143@qq.com',
      //     remark: '这是备注',
      //     company: '威发嘎嘎嘎嘎',
      //   },
      //   {
      //     id: 2,
      //     name: 'Bruce2',
      //     phone: `008613365895231`,
      //     mail: '492629143@qq.com',
      //     remark: '这是备注',
      //   },
      //   {
      //     id: 3,
      //     name: 'Bruce3',
      //     phone: `8613321452532`,
      //     mail: '492629143@qq.com',
      //     remark: '这是备注',
      //   },
      //   {
      //     id: 4,
      //     name: 'Bruce4',
      //     phone: `85265321256`,
      //     mail: '492629143@qq.com',
      //     remark: '这是备注',
      //   },
      //   {
      //     id: 5,
      //     name: 'Bruce5',
      //     phone: `+85265321256`,
      //     mail: '492629143@qq.com',
      //     remark: '这是备注',
      //   },
      //   {
      //     id: 6,
      //     name: 'Bruce6',
      //     phone: `0085265321256`,
      //     mail: '492629143@qq.com',
      //     remark: '这是备注',
      //   },
      //   {
      //     id: 7,
      //     name: 'Bruce7',
      //     phone: `13321452986`,
      //     mail: '492629143@qq.com',
      //     remark: '这是备注',
      //   },
      //   { id: 8, name: 'Bruce8', phone: `65326556`, mail: '492629143@qq.com', remark: '这是备注' },
      // ];
      // 根据name mail phone 去个重 去个重
      let contactArr = contactArrs;
      // contactArr.unshift({ id: 999, name: '韩磊', phone: `+8613312345654`, mail: '492629143@qq.com', remark: "这是备注", company: '威发嘎嘎嘎嘎' })
      contactArr = contactArr.filter((contact) => {
        return contact.name || contact.phone || contact.mail;
      });
      const newContactArr = [];
      contactArr.map((v) => {
        const contact = v;
        if (contact.name) {
          // contact.name = contact.name;
        } else if (contact.phone) {
          contact.name = contact.phone;
        } else if (contact.mail) {
          contact.name = contact.mail;
        } else {
          contact.name = '';
        }
        // contact.name = contact.name
        //   ? contact.name
        //   : contact.phone
        //   ? contact.phone
        //   : contact.mail
        //   ? contact.mail
        //   : '';
        // let exist = false;
        // newContactArr.map(newContact => {
        //   if (
        //     newContact.name === contact.name &&
        //     newContact.phone === contact.phone &&
        //     newContact.mail === contact.mail
        //   ) {
        //     exist = true;
        //   }
        // });
        // if (!exist) {
        newContactArr.push(contact);
        // }

        return true;
      });
      window.console.log(newContactArr, 'newContactArr');
      const { result, resultLetters } = organizeDataByLetter(newContactArr);
      const contact = {
        data: result,
        letters: resultLetters,
      };
      yield put({ type: 'set', payload: contact });
    },
    *loadSelectedUser(_, { put, select }) {
      const { selectedKey = [], outerList } = yield select((state) => state.contact);
      const { visitorTemp = [] } = yield select((state) => state.app);
      if (visitorTemp) {
        visitorTemp.map((user) => {
          if (user.visitorName && user.visitorNumber) {
            selectedKey.push(
              `${user.visitorName}_${user.visitorMail ? user.visitorMail : ''}_${
                user.visitorNumber ? user.visitorNumber : ''
              }`,
            );
            outerList.push({
              ...user,
              key: `${user.visitorName}_${user.visitorMail ? user.visitorMail : ''}_${
                user.visitorNumber ? user.visitorNumber : ''
              }`,
            });
          }
          return true;
        });
      }
      // visitorTemp &&
      //   visitorTemp.map(user => {
      //     if (user.visitorName && user.visitorNumber) {
      //       selectedKey.push(
      //         `${user.visitorName}_${user.visitorMail ? user.visitorMail : ''}_${
      //           user.visitorNumber ? user.visitorNumber : ''
      //         }`
      //       );
      //       outerList.push({
      //         username: user.visitorName,
      //         email: user.visitorMail,
      //         mobile: user.visitorNumber,
      //         key: `${user.visitorName}_${user.visitorMail ? user.visitorMail : ''}_${
      //           user.visitorNumber ? user.visitorNumber : ''
      //         }`,
      //       });
      //     }
      //   });
      yield put({
        type: 'set',
        payload: { selectedKey, outerList },
      });
      history.push(`${APP_HOME_PIX}${APP_ROUTE_PATH.APP_CONTACTS}`);
    },
    *changeSelected({ payload: { key, info } }, { put, select }) {
      let { selectedKey = [], outerList } = yield select((state) => state.contact);
      if (selectedKey.indexOf(key) === -1) {
        selectedKey.push(key);
        outerList.push({
          username: info.name,
          email: info.mail,
          mobile: info.phone,
          company: info.company,
          key: `${info.name}_${info.mail ? info.mail : ''}_${info.phone ? info.phone : ''}`,
        });
        getAreaCode(info.phone);
      } else {
        selectedKey = selectedKey.filter((selKey) => String(selKey) !== String(key));
        outerList = outerList.filter((item) => String(item.key) !== String(key));
      }
      yield put({
        type: 'set',
        payload: { selectedKey, outerList },
      });
    },
    *onChoose(_, { put, select }) {
      const { outerList } = yield select((state) => state.contact);
      const { customFormList = [], areaCode } = yield select((state) => state.app);
      // 外部参会人
      const list = [];
      if (outerList) {
        outerList.map((v) => {
          if (v && (v.mobile || v.telAreaCode)) {
            let phone = undefined;
            let customForm = {};
            if (v.mobile) {
              phone = getAreaCode(v.mobile);
            }
            if (!v.telAreaCode) {
              customForm = customListToInfo(customFormList);
            }
            list.push({
              ...v,
              visitorName: v.visitorName || v.username,
              telAreaCode: v.telAreaCode || phone?.code || areaCode?.[0]?.code,
              visitorNumber: v.visitorNumber || phone?.mobile || '',
              visitorCompany: v.visitorCompany || v.company || '',
              visitorMail: v.visitorMail || v.email || '',
              ...customForm,
            });
          }
          return true;
        });
      }
      // outerList &&
      //   outerList.map(v => {
      //     if (v && v.mobile) {
      //       const phone = getAreaCode(v.mobile);
      //       list.push({
      //         visitorName: v.username,
      //         visitorCompany: '',
      //         telAreaCode: phone.code,
      //         visitorNumber: phone.mobile,
      //         visitorMail: v.email,
      //       });
      //     }
      //   });
      yield put({
        type: 'app/save',
        payload: { visitorTemp: list },
      });
      history.go(-1);
    },
  },
  subscriptions: {
    setup(root) {
      const { dispatch, history: umiHistory } = root;
      return umiHistory.listen(({ pathname }) => {
        if (pathname.endsWith(APP_ROUTE_PATH.APP_CONTACTS)) {
          // dispatch({
          //   type: 'loadForOuterContact',
          //   payload: { contactArr: [] },
          // });
          Virsical.phoneContacts({
            success: (res) => {
              try {
                const result = JSON.parse(res.result);
                dispatch({
                  type: 'loadForOuterContact',
                  payload: { contactArr: result },
                });
              } catch (ee) {
                dispatch({ type: 'doWhenLoadOutContactFail', payload: {} });
              }
            },
            fail: () => {
              dispatch({ type: 'doWhenLoadOutContactFail', payload: {} });
            },
            refresh: false,
          });
        } else {
          dispatch({ type: 'reset' });
        }
      });
    },
  },
};
