export default {
  'home.page.title': '智能会议',
  'home.page.subtitle': '一键开启，智能办公',

  'home.page.btn.book': '预订会议',
  'home.page.btn.about': '关于',
  'home.page.btn.video.meeting': '视频会议',
  'home.page.btn.my.meeting': '会议列表',
  'home.page.btn.initiate': '发起会议',
  'home.page.btn.notwork.meeting': '网络会议',
};
