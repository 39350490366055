export default {
  'menu.workspace.user': 'Smart Workspace',
  'menu.workspace.booking': 'Quick Reservation',
  'menu.workspace.specialBooking': 'Advanced Reservation',
  'menu.workspace.myBooking': 'Reservation Records',
  'menu.workspace.history': "Past's Records",
  'menu.workspace.search': 'Wayfinding',
  'menu.workspace.repair': 'Desk Repair',
  'menu.workspace.mobile': 'Smart Workspace',
  'sws.menu.station.booking': 'Quick Reservation',
  'sws.menu.station.repair': 'Desk Repair',
  'sws.menu.station.search': 'Wayfinding',
  'sws.menu.station.records': 'Reservation Records​',
  'sws.menu.station.special': 'Advanced Reservation',
  'sws.menu.locker.booking': 'Quick Reservation',
  'sws.menu.locker.records': 'Reservation Record',
  'sws.time': 'MM/DD/YYYY HH:mm',
  'sws.time.date': 'MM/DD/YYYY',
  'sws.location.tip': 'Please select',
  'sws.location.tip.no.permission': 'The area you added currently does not have permission',
  'sws.booking.no.all.tip': 'Please select a region and time',
  'sws.menu.station.liftTable': 'Adjustable Desks',

  401127: 'System working day time is not set',
  401129: 'The current user does not have department permissions for station {value1}',
  401137:
    'The current user has occupied or reserved a workstation within the selected time, please choose another time',
  401061:
    'The time zone information is not set for the area {value1}, please maintain it in the area management',
  403054: 'Failed to obtain the locker authentication permission',
  403055: 'The current user has no location permission',

  'sws.delay.num.format': '{num} minutes',
  'sws.myBooking.station.no': 'Desk',
  'sws.fixed.confirm.bind.tip': 'Are all fixed workstations unlocked?',
  'sws.fixed.confirm.bind.scan.expire.tip':
    'The QR code information is no longer valid, please scan the code again',
};
