import { useState } from 'react';
import { useMemoizedFn } from 'ahooks';

/**
 * 一些业务方法
 */
export default function () {
  // 来自于哪个列表的详情页面
  const getWhichDetailsPage = useMemoizedFn((detailsPageSource = 1) => {
    return {
      isInvitations: detailsPageSource === 1, //  邀请记录
      isVisits: detailsPageSource === 2, //  访问记录
      isInvitationReview: detailsPageSource === 3, //  访客审批
    };
  });

  return { getWhichDetailsPage };
}
