/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  bind_finish: '完成',
  bind_virsical: '威思客',
  bind_submit: '提交',
  bind_hasbind: '該微信號已經綁定賬號',
  bind_inputAccount: '請輸入賬號',
  bind_notUserNameEmpty: '用戶名不能為空!',
  bind_notPassWordEmpty: '密碼不能為空!',
  bind_inputPassword: '請輸入密碼',
  bind_close: '關閉',
  bind_getStateFail: '獲取用戶綁定狀態失敗，請關閉重試',
  bind_bindFail: '綁定賬號失敗',
  106000: '系統异常',
  106504: '認證失敗！',
  106505: '用戶名或密碼錯誤！',
};
