/**
 * Created by wu on 2017/6/1.
 */
// import Toast from 'antd-mobile-v2/lib/toast';
import { formatMessage, history } from 'umi';
// import { formatMessage, history } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { CLOUDHUB_AUTH, ROUTE_PATH } from '@/utils/constant';
import { authCloudHub, initAuth } from './services';

export default {
  namespace: 'clouldHubAuth',
  state: {
    waferUrl: '',
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *authGetPerson({ params }, { call }) {
      const qingData = yield call(initAuth);
      if (qingData && qingData.success && qingData.success.toString() === 'true') {
        const obj = { ...params };
        obj.openid = qingData.data?.openid || '';
        obj.openId = qingData.data?.openId || '';
        obj.name = qingData.data?.name || '';
        obj.email = qingData.data?.email || '';
        obj.mobile = qingData.data?.mobile || '';
        obj.jobNo = qingData.data?.jobNo || '';
        obj.qingData = qingData.data || '';
        const result = yield call(authCloudHub, { ...obj });
        if (result && result.code === 0) {
          if (result.data) {
            if (result.data.startsWith('http')) {
              window.location.href = result.data;
            } else {
              history.replace(`${ROUTE_PATH}/${result.data}`);
            }
          }
        } else {
          Toast.fail(formatMessage({ id: result.msg }), 2);
        }
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return umiHistory.listen(({ pathname, query }) => {
        if (pathname.indexOf(CLOUDHUB_AUTH) !== -1) {
          const params = {
            aid: query.aid,
            tempUrl: query.tempUrl,
            lc: query.lc || '',
            hr: query.hr || '',
          };
          dispatch({ type: 'authGetPerson', params });
        }
      });
    },
  },
};
