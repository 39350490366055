import { useMemo } from 'react';
import useRequest from '../useRequest';
import * as service from './service';

export default function useGetVisitorCount() {
  const {
    data: visitorCount,
    loading: getVisitorCountLoading,
    run: runGetVisitorCount,
  } = useRequest((params: any) => service.getVisitorCount(params), {
    manual: true,
    cacheKey: 'visitorCount',
    cacheTime: 5000,
    debounceWait: 500,
  });
  return useMemo(() => {
    return {
      visitorCount,
      getVisitorCountLoading,
      runGetVisitorCount,
    };
  }, [getVisitorCountLoading, runGetVisitorCount, visitorCount]);
}
