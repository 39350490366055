/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.applyAccess.title': '無權限門禁',
  'access.applyAccess.btn.submit': '提交',
  'access.applyAccess.apply.reason': '請輸入申請理由',
  '601001': '獲取門禁列表失敗',
  '601002': '獲取用戶名失敗',
  '601003': '數據長度超過限制',

  '601012': '操作失敗',
  '601013': '操作成功',
};
