export default {
  // 经协商，此处应该备份一份工位菜单
  'sws.smartWorkspace': 'Smart Workspace',
  'sws.menu.station.special': 'Advanced Reservation',
  'sws.menu.station.my.booking': 'Reservation Records',
  'sws.menu.station.booking.history': "Past's Records",
  'sws.menu.station.booking': 'Quick Reservation',
  'sws.menu.station.repair': 'Desk Repair',
  'sws.menu.station.repair.manage': "Desk Repair's Records",
  'sws.menu.station.search': 'Wayfinding',
  'sws.menu.station.records': 'Reservation Records',
  'sws.menu.locker.booking': 'Quick Reservation',
  'sws.menu.locker.records': 'Reservation Record',
  all_day: 'DAY',
  am: 'AM',
  pm: 'PM',
  custom: 'CUSTOM',
  locale: 'zh-US',
  // 表单
  'sws.no.data': 'No Data',
  'sws.select.all': 'All',
  'sws.input.name': 'Please enter a name',
  'sws.time.colon': 'Scheduled：',
  'sws.time1': 'Time',
  'sws.time.hour': 'Hour',
  // 按钮文字
  'sws.btn.ok': 'Confirm',
  'sws.btn.got.it': 'Got it',
  'sws.btn.cancel': 'Cancel',
  'sws.btn.exit': 'Exit',
  'sws.btn.close': 'Close',
  'sws.btn.chose.time': 'selection period',
  'sws.btn.return.list': 'Back to Request List',
  'sws.btn.reset': 'Cancel',
  'sws.btn.return.reserve': 'Back to Booking',
  'sws.btn.return.station.dashboard': 'Back to Home',
  'sws.btn.return.reverse.history': 'Back to Records',
  'sws.btn.home': 'Home',
  'sws.btn.more': 'More',
  // 操作结果
  'sws.congratulation.success': 'Good Job, Your Reservation Is Confirmed !',
  'sws.reserve.success': 'Request is OK',
  'sws.reserve.error': 'Request is Fail',
  'sws.logout.success': 'Operate is OK',
  'sws.logout.error': 'Operate is Fail',
  'sws.cancel.success': 'Cancelled',
  'sws.cancel.error': 'Cancel is Fail',
  'sws.globalTime.check.error': 'Search of Time is fail',
  // 校验提示信息
  'sws.tip.time': 'Please select time',
  'sws.tip.station': 'Please select space',
  'sws.chose.station': '{num} of space is selected',
  'sws.station.reservation': 'Confirm',
  'sws.station.immediate': 'Instant use',
  'sws.station.chosed': 'Desk：',
  'sws.time.date.chosed': 'Selected Date & Time as shown：',
  // 其它
  'sws.common.location': 'Location',
  'sws.common.timezone': '工位所在时区',
  'sws.date.time': 'Date & Time',
  'sws.date.range': 'Date',
  'sws.time.start': 'Start Time',
  'sws.time.end': 'End Time',
  'sws.please.select.colon': 'Please Select：',
  'sws.please.select': 'Please select',
  'sws.footer.canUse': 'Avaliable',
  'sws.footer.reserved': 'Reserved',
  'sws.footer.occupied': 'Occupied',
  'sws.footer.cannot.reserve': 'Unavaliable',
  'sws.footer.fixed.station': 'Fixed Desk',
  'sws.station.booking': 'Shared Desk',
  'sws.booking.info': 'Info of Booking',
  'sws.station.number': 'Space',
  'sws.station.check.in': 'Check in',
  'sws.node.no.select': 'Reservation is not supported at the current time',
  'sws.select.tip.title': 'Desk Info：',

  // 提示信息
  'sws.tip.loading': 'Loading',
  'sws.reserve.confirm.fail':
    'Notice : as policy setting, you can’t reserve more than one space and use in same time.',
  'sws.tip.station.occupy': 'Timetable of Scheduled',
  'sws.tip.station.location.none': 'You have not selected location information, please select',
  'sws.tip.none': 'None',

  'sws.book.result.gong': 'Total',
  'sws.book.result.ge': 'Entries',

  // 异常
  401000: 'System is temporary error',
  401001: 'Space ID is NOT exit in database',
  401002: 'Space Info is NOT exit in database',
  401003: 'Setting or some of parameter is error',
  401004: 'Temeplete of head column is empty , please fill it .',
  401005: '{row} of column that named Space ID is can’t be empty',
  401006: '{row} of column that named Location is can’t be empty',
  401007: 'Line {row}: Empty workstation furniture type error',
  401008: '{row} of column that named Space ID is duplicate',
  401009: '{row} of column that named Staff ID is NOT exit',
  401010: '{row} of column that named Location is NOT correct',
  401011: '{row} of column that is Shared desk which NOT be assign',
  401012: 'Space ID {stationNum} is revesed or offline, Stop then delete it',
  401013: 'This space is NOT exit',
  401014:
    "This seat's {value1} assign policy is maximun of [{value2}] share to use, Contact with facility administrator to help fix it",
  401015: 'This space is linked with vector of space',
  401016: 'Vector of space is linked with Space',
  401017: 'This user {value1} is associated with 3 of space',
  401018: 'This space is occpied or reserved now , Can’t be edit now',
  401019: 'This space is NOT linked with Vector, Can’t be online',
  401020: 'This space is NOT linked with Vector, Can’t be offline',
  401021: 'Space {stationNum } is under work , Stop then Unlink it',
  401022: 'Amount of Shared Desk is oversize of license purchase',
  401023: 'Amount of Fixed Desk is oversize of license purchase',
  401024: 'This {stationNum} is under work now , Can’t modify “Dept “',
  401025: 'Facility of space info is under “Blank”',
  401026: 'Amount of Assigned Space {value1} is oversize of license purcase',
  401027: 'Space is under work, Can’t modify type of Hot or Fixed',
  401028: 'Space {value} is NOT associated with MAP, Can’t be online',
  401029: 'This space is NOT linked with Vector, Can’t be offline',
  401030: 'The space is under work now , Can’t modify “Dept “',
  401031: 'Please check tempelte is correct or not',
  401032: 'This space {stationNum} is already exit, Do NOT make duplicate',
  401033: 'Check column of {row}, {stationNum} that assigned user is oversize error',
  401034: 'Check column of {row}, {userId} that User’s associated is oversize',
  401035: 'Do NOT modify sensor’s setting, it is under work now',
  401103: 'The space is occupied while your select’s schedule time',
  401104:
    'You already have a reserved or used station, it is not supported to reserve or use two or more stations at the same time',
  401105: 'User is NOT exit',
  401106: 'Current Domain is NOT set Duty of Time',
  401107: 'Current User is NOT set Dept’s permission',
  401108: 'Current User is Without Location’s permission',
  401109: 'Current User is NOT set Dept’s permission',
  401110: 'Current User is Without Location’s permission',
  401111: 'This is Duty off time, Can NOT provide reserve service',
  401113: 'QR Code is unavaliable that during Duty Off Time',
  401201: 'This user whom associated with seat is oversize setting',
  401202: 'Assocaited user is NOT match application’s policy',
  401251: 'Duty on & off time is error',
  401252: 'Date’s setting is error',
  401253: 'Catagory’s setting is error',
  401254: 'User is not logged in',
  401255: 'Top of Rank’s user info is error',
  401256: 'Templete of system’s log is error',
  401257: 'Query of Space’s Tree is fail',
  401258: 'Space’s Tree info is error',
  401259: 'Permission is match setting',
  401260: 'Dept’s Permission is match setting',
  401261: 'Working day’s setting is error',
  401262: 'Public holiday is out of filelds that data statistic ',
  401263: 'Object of vector is error',
  401264: 'Parameter is error',
  401265: 'Space’s Parameter of History is error',
  401266: 'schedule time of task’s parameter is error',
  401267: 'Catagories is EXIT already',
  401268: 'Space link with Catagories parameter is error!',
  401269: 'We NOT suuport associated space service cross domain!',
  401270: '“Dept” info is NOT exit',
  401271: 'Templete of Operation’s log is error',
  401272: 'Query of user in Operation’s log is error',
  401291: '',
  401311: '“Faclility’s name ” is saved',
  401312: '“Faclility” save is fail',
  401313: '“Faclility” delete is fail',
  401314: '“Faclility link with space ” need Unlink then delete',
  401321: '“Data directory’s user info ” is NOT initial',
  401322: '“Data directory’s Global info ” is NOT initial',
  401331: '“Permission’s name” is EXIT',
  401332: '“Parameter” is NOT correct',
  401351: '“File’s upload” is fail',
  401356: '“License’s hash” is fail',
  401357: '“License” is expried',
  401358: '“License” is exceed amount of purchased',
  401361: '“Templete of File’s upload ” formatted is NOT correct',
  401362: 'Column of {value1}, Facility ID is Blank',
  401363: 'Column of {value1}, Facility Type is Blank',
  401364: 'Column of {value1}, Facility ID is NOT exit',
  401366: 'Space’s E-Ink service address is “Blank”',
  401367: 'Space’s E-Ink MAC address is linked with another’s desk',
  401381: '“Space’s repair ’s parameter is error',
  401114: 'Extending time is exceed than MAX of setting',
  401131: 'There are no records with reserved status',
  401143: 'The current time has passed the end time of reservation and cannot be booked ',

  'sws.booking.interval.tip': 'This seat is currently locked and cannot be booked at this time',
  'sws.booking.interval.cancel.tip':
    'The station cancellation counts as non-performance, are you sure you want to continue?',

  401504: 'Stations are booked at least {value1} minutes in advance!',
  401505: 'This station does not support scan code check-in!',
  401506: 'Seat {value1} is locked, please book later!',

  401036: 'Workstation status abnormal',
  401083: 'QR code not recognized',
  401057: 'QR code has expired',
  401073: 'This seat is NOT support QR code scan to associated mode',
  401037:
    'The number of users of the workstation {value1} exceeds the system settings, please contact the administrator',
  401859: 'Workstation {value1} is for a single person and a single seat only',
  401044: 'Workstation {value1} not assigned department',
  401089:
    'This seat [{value1}] is Only for assigned and fixed to use mode, NOT support SWAP to Change mode',
  401090:
    "User [{value1}] 's seat owned ‘s amount in same time is reached of maximum’s owned amount for system’s setting.  Contact with facility administrator to help fix it",
  'default.region.select.option.no': 'This level is not selected',
  401159: 'User does not belong to a department',

  // 360新增
  401205: 'Scan code binding failed (only allowed to bind workstations within the scope of this department)',
};
