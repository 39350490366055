export default {
  'cloud.background.management.title': 'Log in admin system on PC',
  'cloud.background.management.tip.1': 'Easier management of enterprise address',
  'cloud.background.management.tip.2': 'More flexible handling of job requirements',
  'cloud.background.management.tip.smt': 'Easier maintenance of meeting rooms',
  'cloud.background.management.tip.vst': 'Easier maintenance of visitor reception areas',
  'cloud.background.management.tip.sws': 'Easier maintenance of stations and maps',
  'cloud.background.management.tip.3': 'More abundant enterprise data statistics',
  'cloud.background.management.tip.4': 'More personalization',

  'operate.my': 'My Info',
  'operate.admin': 'Admin Manage',
  'operate.scan': 'Scan',
  'operate.logout': 'Logout',
}
