export default {
  'sws.repairManage.space.colon': 'Location：',
  'sws.repairManage.check.in': 'Check In',
  'sws.repairManage.info': 'Tips',
  'sws.repairManage.pending': 'Pending',
  'sws.repairManage.processing': 'Processing',
  'sws.repairManage.processed': 'Done',
  'sws.repairManage.cancelled': 'Cancelled',
  'sws.repairManage.repairType': 'Repair Type',
  'sws.repairManage.userName': 'User Name',
  'sws.repairManage.repairTime': 'Repair Time',
  'sws.repairManage.no.pending': 'You currently have no pending repairs~',
  'sws.repairManage.no.processing': 'You currently have no processing a repair request~',
  'sws.repairManage.no.processed': 'You currently have no processed repair requests~',
  'sws.repairManage.no.cancel': 'Cancel',
  'sws.repairManage.no.detail': 'Repair Detail',
  'sws.repairManage.handle.start': 'Start Handling',
  'sws.repairManage.handle.end': 'End Handing',
  'sws.repairManage.department': 'Department',
  'sws.repairManage.repair.status': 'Repair Status',
  'sws.repairManage.station.type': 'Furniture Type',
  'sws.repairManage.station.fixed': 'Fixed Station',
  'sws.repairManage.station.mobile': 'Shared Station',
  'sws.repairManage.station.update.success': 'Update successfully!',
};
