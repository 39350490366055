export default {
  phone_contacts_value1: 'Please select from mobile phone address book',
  phone_contacts_value2: 'Please enter the content to be searched',
  phone_contacts_value3: 'Only mobile phone in Mainland China or Hong Kong is supported',

  'external.contacts.add': 'Add external attendee(s)',
  'external.contacts.name': 'Name',
  'external.contacts.name_': 'Name: ',
  'external.contacts.department': 'Department',
  'external.contacts.position': 'Position',
  'external.contacts.mail': 'Email',
  'external.contacts.phone': 'Phone',
  'external.contacts.company': 'Company',
  'external.contacts.placeholder': 'Please enter',
  'external.contacts.name.fail': 'Please enter the name',
  'external.contacts.department.fail': 'Please enter the department',
  'external.contacts.position.fail': 'Please enter the position',
  'external.contacts.mail.null.fail': 'Please enter the email',
  'external.contacts.mail.fail': 'Incorrect email format',
  'external.contacts.phone.null.fail': 'Please enter the phone number',
  'external.contacts.phone.fail': 'Incorrect phone number format',
  'external.visitor.notice': 'Invite',

  'meeting.booking.select.outer.form.name.pls': 'Please enter contact name',
  'meeting.booking.select.outer.form.tel.pls': 'Please enter contact phone number',
  'meeting.booking.select.outer.form.tel.mail.pls': 'Please enter contact phone number or email',
  'meeting.booking.select.outer.form.email.pls': 'Please enter contact email',
  'meeting.booking.select.outer.form.tel.error': 'Incorrect phone number format',
  'meeting.booking.select.outer.form.email.error': 'Incorrect email format',
  'meeting.booking.select.outer.form.phone.error': 'The mobile number {phone} is duplicated',
  'meeting.booking.select.outer.form.email.error': 'The email {email} is duplicated',
  'meeting.booking.select.outer.form.country.china': 'China +86',
  'meeting.booking.select.outer.form.country.china.hk': 'Hong Kong,China +852',
};
