export default {
  'sws.inventory.title': 'Workplace Inventory',
  'sws.inventory.detail.userName': 'Employee Name',
  'sws.inventory.detail.userNum': 'Employee Number',
  'sws.inventory.detail.deptName': 'Employee Department',
  'sws.inventory.station.stationNum': 'Manual Input',
  'sws.inventory.station.scanStationNum': 'Scan Code',
  'sws.inventory.station.remarks':
    'Remarks: If the station number has changed, please enter it on the right side or scan the code to change it',
  'sws.inventory.station.location': 'Station location',
  'sws.inventory.station.ok': 'Confirm',

  'sws.inventory.detail.empty': 'No inventory is needed at present~',
  'sws.inventory.qrCode.error': 'The QR code information of the workstation is incorrect',
  'sws.inventory.btn.stationnum': 'Please select the station number',
  'sws.inventory.btn.confim': 'OK',
  'sws.inventory.stationnum': 'Station number',
  'sws.inventory.return': 'Return to task',
  'sws.inventory.length': 'Total {value1}pcs',
  'sws.menu.station.inventorytask': 'Desk Review-regularly',
};
