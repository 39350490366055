import { ACCESS_TOKEN, LOGIN_ROUTER } from '@/utils/constant';
import * as service from './services';
import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';

export default {
  namespace: 'scanAuthPage',

  state: {
    userScanAUthState: -1,
    cm: null,
    tempToken: null,
    openId: null,
    resourceInfo: null,
    resourceData: null,
    // resourceData: {
    //   moreInfo: [
    //     {
    //       keyCn: "预定人",
    //       keyEn: "Booker",
    //       keyTw: "預定人",
    //       value: "张珊珊",
    //       startTime: 1673575200000,
    //       endTime: 1673580600000,
    //       title: "项目经理季度报告会",
    //       operations: [
    //         {
    //           keyCn: "签到",
    //           keyEn: "Sign on",
    //           keyTw: "簽到",
    //           btnTypeCode: "signOn",
    //           type: "api",
    //           value: "xxx"
    //         },
    //         {
    //           keyCn: "延长",
    //           keyEn: "Extend",
    //           keyTw: "延长",
    //           btnTypeCode: "extend",
    //           type: "apiCallback",
    //           value: "xxx"
    //         },
    //         {
    //           keyCn: "结束",
    //           keyEn: "End",
    //           keyTw: "結束",
    //           btnTypeCode: "close",
    //           type: "api",
    //           value: "xxx"
    //         },
    //         {
    //           keyCn: "取消",
    //           keyEn: "Cancel",
    //           keyTw: "取消",
    //           btnTypeCode: "cancel",
    //           type: "api",
    //           value: "xxx"
    //         }
    //       ]
    //     },
    //     {
    //       keyCn: "预定人",
    //       keyEn: "Booker",
    //       keyTw: "預定人",
    //       value: "张珊珊",
    //       startTime: 1673575200000,
    //       endTime: 1673580600000,
    //     },
    //   ],
    // },
    productParam: '',
    isShowLoading: false,
  },

  effects: {
    *getInfoForScan({ payload }, { call, put }) {
      const result = yield call(service.getInfoForScan, { ...payload });
      if (result?.code === 0) {
        if (!payload?.tempToken && result?.data.productApi) {
          yield put({
            type: 'getInfoByProduct',
            payload: { url: result?.data.productApi, resourceId: result?.data?.resourceId },
          });
        }
        yield put({ type: 'saveOrUpdateData', payload: { resourceInfo: result.data } });
      } else {
        Toast.fail(formatMessage({ id: result.msg }), 2);
      }
    },
    *getInfoByProduct({ payload }, { call, put, select }) {
      const { productParam } = yield select((state) => state.scanAuthPage);
      const result = yield call(service.getInfoByProduct, {
        ...payload,
        productParam: productParam,
      });
      if (result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { resourceData: { ...result.data } } });
        //  yield put({type: 'saveOrUpdateData', payload:{resourceData: result.data}});
      } else {
        Toast.fail(formatMessage({ id: result.msg }, { ...result?.data }), 2);
      }
    },
    *handleActionApi({ payload }, { call, put, select }) {
      Toast.loading('Loading...', 0);
      const result = yield call(service.handleActionApi, { ...payload });
      if (result.code === 0) {
        const { resourceInfo } = yield select((state) => state.scanAuthPage);
        yield put({
          type: 'getInfoByProduct',
          payload: { url: resourceInfo?.productApi, resourceId: resourceInfo?.resourceId },
        });
        Toast.hide();
      } else {
        Toast.fail(formatMessage({ id: result.msg }, { ...result?.data }), 2);
      }
    },
    *handleActionApiByMeeting({ payload }, { call, put, select }) {
      Toast.loading('Loading...', 0);
      const result = yield call(service.handleActionApi, { ...payload });
      if (result.code === 0) {
        Toast.hide();
        if (result.msg) {
          Toast.success(formatMessage({ id: result.msg }));
        }
        const { resourceInfo } = yield select((state) => state.scanAuthPage);
        yield put({
          type: 'getInfoByProduct',
          payload: { url: resourceInfo?.productApi, resourceId: resourceInfo?.resourceId },
        });
        return result;
      } else {
        Toast.fail(formatMessage({ id: result.msg }, { ...result?.data }), 2);
      }
    },
    *handleApiByMeeting({ payload }, { call }) {
      Toast.loading('Loading...', 0);
      const result = yield call(service.handleActionApi, { ...payload });
      if (result.code === 0) {
        Toast.hide();
        if (result.msg) {
          Toast.success(formatMessage({ id: result.msg }));
        }
        return result;
      } else {
        Toast.fail(formatMessage({ id: result.msg }, { ...result?.data }), 2);
      }
    },
    *showSubmitBtn({ payload }, { call, select }) {
      const result = yield call(service.showSubmitBtn, { ...payload });
      const userScanAUthState = yield select((state) => state.scanAuthPage.userScanAUthState);
      if (result?.code === 0) {
        if(userScanAUthState === 1){
          // 移除临时token
          sessionStorage.removeItem(ACCESS_TOKEN);
        }
        return result.data;
      } else {
        Toast.fail(formatMessage({ id: result.msg }), 2);
      }
    },
  },

  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname, query }) => {
        if (pathname.indexOf('scanAuthPage') !== -1) {
          // B站需求-需要能跳转回此页面-session暂存
          sessionStorage.setItem(
            'scanAuthPageUrl',
            // eslint-disable-next-line no-undef
            window.location.pathname.replace(staticResourcePrefix.replace(/\/$/, ''), '') +
              window.location.search,
          );

          const { cm = null, openId = null, tempToken = null, productParam = null } = query;
          window.console.log(query, productParam, '22222');
          if (cm) {
            dispatch({
              type: 'saveOrUpdateData',
              payload: { cm, openId, tempToken, productParam: productParam },
            });
          } else {
            Toast.fail(`scanAuthPage: 参数缺失 / Parameters are missing`, 5, () => {
              history.replace(LOGIN_ROUTER);
            });
            return;
          }
          if (tempToken) {
            // 存储临时token
            sessionStorage.setItem(ACCESS_TOKEN, tempToken);
            // 未绑定登录
            dispatch({
              type: 'saveOrUpdateData',
              payload: { userScanAUthState: 1 },
            });
            // 查询
            dispatch({ type: 'getInfoForScan', payload: { cm, openId, tempToken } });
            return;
          }

          // 登录绑定查询
          dispatch({ type: 'getInfoForScan', payload: { cm, productParam } });
          dispatch({
            type: 'saveOrUpdateData',
            payload: { userScanAUthState: 0 },
          });
        }
      });
    },
  },
};
