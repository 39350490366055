export default {
  'room.btn.add': '添加',
  'subscribe.fail.zoom.scale': '请选择参会人数',
  'subscribe.fail.emptyDelayTime': '请选择延长时间！',
  'subscribe.fail.delayTimeToMax': '会议延长时间不能大于{min}分钟！',
  'subscribe.fail.delayTimeToMin': '会议延长时间不能小于{min}分钟！',
  'subscribe.fail.emptyMeetingTime': '请选择会议时间!',
  'subscribe.fail.zoom.days.limit': '请预定90天以内的Zoom会议！',
  'subscribe.fail.feishu.days.limit': '飞书会议无法预约一个月后的会议!',
  'subscribe.time.longer.than.forty.mins': '免费账号，只能预订40分钟内的会议!',
  'subscribe.fail.emptySubject': '请输入会议主题',
  'subscribe.subject.placeholder': '的会议',
  'subscribe.fail.memberConflict': '主持人与参会人冲突！',
  'subscribe.remark.placeholder': '请输入备注信息',
  'subscribe.fail.one.host': '网络会议只能选择一个主持人！',
  'subscribe.fail.value0': '请选择主持人！',
  'subscribe.fail.value1': '会议主题不能为空！',
  'subscribe.fail.visit.cross': '非跨天会议,到访日期必须为同一天！',
  'subscribe.fail.value2': '立即开始的会议不能预订访客',
  'subscribe.fail.value3': '周期会议不能预订访客',
  'subscribe.reservation': '立即预订',
  'subscribe.reminding': '提醒方式',
  'subscribe.secret': '保密',
  'subscribe.secret.text': '对非与会人员及公共显示屏幕不展示会议主题',
  'subscribe.alluser': '全部人员',
  'subscribe.alluser.pls': '全员',
  'subscribe.reminding.ways': '邮件',
  'subscribe.meeting.type': '会议类型',
  'subscribe.meeting.type.native': '本地',
  'subscribe.meeting.type.phone': '电话',
  'subscribe.meeting.type.video': '视频',
  'subscribe.meeting.sign.in': '签到',
  'subscribe.meeting.sign.in.remind': '开启后，规定时间内未完成签到，会议室将被释放',
  'subscribe.meeting.long.pls': '注：跨天会议持续时间最大为{day}天。',
  'subscribe.meeting.sign.before.time': '会议开始前',
  'subscribe.meeting.sign.after.time': '会议开始后',
  'subscribe.meeting.appointment': '预  订',
  'subscribe.meeting.appointment.inform': '预订并通知',
  'subscribe.meeting.save': '保  存',
  'subscribe.meeting.save.inform': '保存并通知',
  'subscribe.meeting.prompt': '操作提示',
  'subscribe.meeting.create.success': '新建会议成功',
  'subscribe.meeting.edit.success': '修改会议成功',
  'subscribe.meeting.manage': '是否需要继续管理会议？',
  'subscribe.startNow': '立即开始',
  'subscribe.startAuto': '自动发起',
  'subscribe.reconnect': '自动重连',
  'subscribe.longState': '跨天会议',
  'subscribe.duration': '会议时长',
  'subscribe.duration.new': '会议时长(分钟)',
  'subscribe.startNow.unit': ' 分钟',
  'subscribe.startNow.placeholder': '请输入会议时长',
  'subscribe.joinPwd': '会议密码',
  'subscribe.joinPwd.placeholder': '请设置参会密码',
  'subscribe.joinPwd.warning': '参会密码必须是数字',
  'subscribe.warning.title': '操作提示',
  'subscribe.warning.visitor.advance': '邀请访客必须提前{value}分钟预订',
  'subscribe.warning.content': '切换会议类型会导致已选择的会议室和时间发生变化，是否继续？',
  'subscribe.meetingType': '会议类型',
  'subscribe.meetingType.ok': '确定',
  'subscribe.meetingType.dismiss': '取消',
  'subscribe.meetingType.cancel': '取消',
  'subscribe.meetingType.normal': '普通会议',
  'subscribe.meetingType.long': '跨天会议',
  'subscribe.meetingType.video': '视频会议',
  'subscribe.meetingType.zoom': 'Zoom会议',
  'subscribe.meetingType.webex': 'Webex会议',
  'subscribe.meetingType.flybook': '飞书视频会议',
  'subscribe.meetingType.warning': '请选择正确的会议室类型',
  'subscribe.people.less': '会议室没有添加参会人，是否继续操作？',
  'subscribe.people.more': '参会人数超出会议室可容纳人数，是否继续操作？',
  subscribe_ad_options: '高级选项',
  subscribe_room_audit_tip: '（包含需要审批的会议室）',
  subscribe_apply_project: '确定要申请这些项目',

  'webex.routes.choice': '系统自动选择WebEx线路',
  'meeting.no.devices': '暂时没有可用的设备',
  'meeting.save.btn.apply': '申  请',
  'meeting.save.btn.notice': '发送通知',

  meeting_save_tips_subject: '主题',
  meeting_save_tips_holder: '申请人',
  meeting_save_tips_time: '时间',
  meeting_save_tips_reason: '原因',
  meeting_save_tips_lock_time: '锁定时间',
  'meeting.save.tips.modal.loop.create': '是否重新生成周期会议?',

  'meeting.save.tips.modal.title1': '以下时间段预订失败,是否跳过并继续预订？',
  'meeting.save.tips.modal.title2': '以下时间段预订失败，无可预订会议！',
  meeting_save_tips_reason_0: '时间段被其他会议占用',
  meeting_save_tips_reason_1: '与其他会议锁定时间冲突',
  meeting_save_tips_reason_2: '该会议锁定时间被占用',
  meeting_save_tips_reason_3: '主持人已预订该时间段会议',
  'meeting.save.tips.modal.extend': '会议顺延提示',
  'meeting.save.tips.modal.title-290': '该周期会议因节假日和会议室维护需顺延，是否继续？',
  'meeting.save.tips.modal.title-291': '该周期会议因节假日需顺延，是否继续？',
  'meeting.save.tips.modal.title-292': '该周期会议因会议室维护需顺延，是否继续？',
  'meeting.save.tips.modal.title-293': '该会议因节假日和会议室维护，预订失败!',
  'meeting.save.tips.modal.title-294': '该会议因节假日，预订失败!',
  'meeting.save.tips.modal.title-295': '该会议因会议室维护，预订失败!',
  'meeting.save.tips.modal.title-attend': '本场会议与以下人员的其它会议存在冲突，是否继续？',
  meeting_tips_holiday: '节假日',
  meeting_tips_room_maintain: '会议室维护',

  'meeting.save.tips.modal.cancel': '取消',
  'meeting.save.tips.modal.continue': '继续',
  'meeting.save.tips.modal.meetingprice': '会议费用',
  'meeting.save.tips.modal.bookit': '继续预订',
  'meeting.save.tips.modal.giveup': '放弃预订',
  'meeting.save.tips.modal.gotit': '知道了',
  'meeting.save.tips.modal.skip': '跳过并预订',
  'meeting.save.tips.modal.ignore': '忽略并预订',
  'meeting.apply.start.fail.value01': '存在WebEx会议室，不能立即开始',
  'meeting.apply.start.fail.value02': '存在审批会议室，就不能立即开始',
  'meeting.apply.start.fail.value03': '当前时间存在会议，就不能立即开始',

  room_fee: '会议室费用',
  service_fee: '会议服务费用',

  'participants.count': `共{num}人`,
  'outer.participants': '外部参会人',
  'outer.participants.count': `共{num}人`,
  remark: '备注',
  welcome: '欢迎词',
  'subscribe.welcome.placeholder': '请输入欢迎词',
  'modal.notice': '提示',
  'modal.confirmDelete': '是否确认删除？',
  'modal.cancel': '取消',
  'modal.close': '关闭',
  'modal.confirm': '确认',
  'modal.notice.svcApplied': '当前会议已申请会议服务，只允许修改参会人信息',

  'subscribe.warning.room': '请选择会议室',
  'zoom.meeting.room.placeholder': '非必填，根据需要选择',
  'meeting.book.outer.visit.time': '访问时间',
  'meeting.book.outer.visit.time.modal.start': '访问开始时间',
  'meeting.book.outer.visit.time.modal.end': '访问结束时间',
  'meeting.book.outer.visit.type': '访问事由',
  'meeting.book.outer.visit.address': '接待地点',
  'meeting.booking.vst.type.empty': '请选择访客访问事由',
  'meeting.booking.vst.location.empty': '请选择访客访问地点',

  'meeting.seat.manage': '座位安排',
  'meeting.seat.bound.number': '已绑定 {num} 人',
  'subscribe.warning.template': '所选会议室没有座位模板',

  'Asia/Shanghai': '中国时间',
  'Asia/Tokyo': '日本时间',
  'Asia/Seoul': '韩国时间',
  'Asia/Singapore': '新加坡时间',
  'Australia/Sydney': '东部时间 (新南威尔斯)',
  'Europe/London': '英国时间',
  'Europe/Paris': '中欧时间',
  'Europe/Moscow': '莫斯科时间',
  'America/New_York': '东部时间',
  'America/Chicago': '中部时间',
  'America/Denver': '山地时间',
  'America/Los_Angeles': '太平洋时间',
  'America/Anchorage': '阿拉斯加时间',
  'Pacific/Honolulu': '夏威夷时间',

  'meeting.booking.step.time.max': '该会议预订最大时间为{value}分钟',
  'meeting.booking.before.day': '该会议需提前{value}天预订',
  202107: '会议所包含会议室需提前预订',
  'meeting.booking.host_user_no_auth': '会议主持人无会议室权限！',
  202129: '只允许同时存在一条大型腾讯会议线路!',

  'meeting.booking.step.tencent.outer.tips': '开启后，外部参会人将无法加入腾讯会议！',
  'subscribe.meetingType.tencent': '腾讯会议',
  'subscribe.meetingType.teams': 'Teams会议',
  'subscribe.meetingType.all': '同步预订',
  'subscribe.meetingType.poly.all': '视频会议类型',
  'subscribe.meeting.special.type': '类型',

  'subscribe.meeting_audit_tip': '{roomStr}在{timeStr}已经有审批中的会议，是否继续预订？',
  'meeting.manage.service.self.bear': '本人承担',
  202104: '会议延长失败，当前会议不是进行中的会议',
  'meeting.agenda': '会议议程',
  'meeting.agenda.look': '查看',
  'agenda.user.name': '议题',
  'agenda.user.name.pls': '请输入议题',
  'agenda.user.startTime': '开始时间',
  'agenda.user.endTime': '结束时间',
  'agenda.user.attend': '参会人',
  'agenda.user.select': '请选择',
  'agenda.user.time.tips': '议程开始时间不能晚于结束时间！',
  'meeting.booking.agenda.tip': '会议议程时间超出会议时间范围，请修改！',
  'agenda.user.selectall': '全选',
  'meeting.booking.agenda.tip2': '议程参会人中存在非会议参会人，请检查！',
  'meeting.agenda.starttime.tip': '议程开始时间不能为空!',
  'meeting.agenda.endtime.tip': '议程结束时间不能为空!',
  'meeting.agenda.name.tip': '议题不能为空!',
  'meeting.agenda.repeat.tip': '议程时间不能重叠!',
  'meeting.agenda.sameTime.tip': '已经存在和会议结束时间一致的议题，请先修改议题时间再继续添加!',
  'meeting.new.no.select.service': '去选择',
  'meeting.new.no.select.device': '去选择',
  'meeting.new.outer.loop.tip': '周期会议不支持同时预约访客，是否继续？',
  'meeting.booking.before.day.room': '需提前{value}天预订',
  'meeting.booking.before.day.room.new': '需提前{value}天预订,请重新选择日期',
  'meeting.booking.max.day.room': '只能预订{value}天内的会议，请重新选择日期',
  subscribe_room_audit_tip_room: '会议室预约需要审批',
  'meeting.booking.step.time.max.less': '预订最大时间为{value}分钟',
  'subscribe.meeting.sign.in.setting': '签到设置',
  'subscribe.meeting.sign.in.duration': '需要小于会议时长({value}分钟)',
  'subscribe.meeting.sign.time.before': '会议开始前{value}分钟至',
  'subscribe.meeting.sign.time.after': '开始后{value}分钟',
  'subscribe.meeting.user.placeholder.host': '选择主持人',
  'subscribe.meeting.user.placeholder.inner': '选择参会人',
  'subscribe.meeting.user.placeholder.outer': '选择外部参会人',

  'subscribe.meeting.modifiy1':
    '会议室修改后，原会议室申请的{modifyDetail}将会被取消，系统将自动按照新的会议室及原有服务项重新申请！',
  'subscribe.meeting.modifiy1.dev.ser':
    '会议室修改后，原会议室申请的会议设备和会议服务，将自动为您转移到新的会议室',
  'subscribe.meeting.modifiy1.dev':
    '会议室修改后，原会议室申请的会议设备将自动为您转移到新的会议室',
  'subscribe.meeting.modifiy2':
    '该会议已经申请了{modifyDetail}，修改{modifyStr}后您是否需要修改{modifyDetail}？',
  'subscribe.meeting.modifiy3':
    '该会议已经申请了{modifyDetail}，修改会议室后原{modifyDetail}将被取消，是否继续？',

  'subscribe.meeting.modifyAuditType1':
    '该会议包含需要审批的会议室，修改会议时间后需要重新审批，请确认是否修改？',
  'subscribe.meeting.modifyAuditType2':
    '修改后的会议室包含需要审批的会议室，修改后会议需要重新审批，请确认是否修改？',
  'subscribe.meeting.modifyAuditType3':
    '该会议包含需要审批的会议室，修改循环规则后需要重新审批，请确认是否修改？',
  'subscribe.cross_days_meeting_days_limit': '跨天会议的开始日期和结束日用期不能为同一天',
  'subscribe.meeting.startNow.sign.title': '预约成功',
  'meeting.booking.step.sign.new.text': '该会议为签到会议,请在{time}分钟内进行签到！',
  'meeting.booking.step.times.limit.tip':
    '可预订{limitMaxDay}天内且会议时长为{limitMinTime}-{limitMaxTime}分钟的会议',
  'meeting.booking.step.times.limit.tip2': '，{limitMaxDay}天以后的会议需要管理员审批',
  'meeting.booking.step.times.noLimit.tip':
    '可预订会议时长为{limitMinTime}-{limitMaxTime}分钟的会议',
  'meeting.booking.step.times.cross.noLimit.tip': '可预订最大跨度为{data}天的会议',
  'meeting.booking.step.times.cross.noCross.tip': '可预订{data}天内的会议',
  'meeting.booking.step.times.limit.cylce': '，超出{limitMaxDay}天的会议需要管理员审批',
  'meeting.booking.normal_day_meeting_limit':
    '只允许预订时长为{limitMinTime}-{limitMaxTime}分钟的会议',
  'meeting.booking.normal_day_meeting_limit_date': '当前日期不可用，请选择其他日期预约！',
  'meeting.visit.time.year': '年',
  'meeting.visit.time.month': '月',
  'meeting.visit.time.day': '日',
  'meeting.visit.time.hour': '时',
  'meeting.visit.time.minute': '分',
  'meeting.visit.time.second': '秒',
  'meeting.vst.time.validate': '会议开始时间不得早于访客到访时间',
  'meeting.booking.step.times.cross.limit.tip': '可预订{value}天内且最大跨度为{data}天的会议',
  'meeting.attend.user.status.FREE': '空闲',
  'meeting.attend.user.status.BUSY': '忙碌',
  'meeting.attend.user.status.TENTATIVE': '暂定',
  'meeting.attend.user.status.UNKNOW': '未知',
  'meeting.booking.step.meetingType.zoom': 'Zoom',
  'meeting.booking.step.meetingType.teams': 'Teams',
  'meeting.booking.step.meetingType.dingding': 'DingDing',
  'meeting.booking.step.meetingType.feishu': 'Feishu',
  'meeting.booking.step.meetingType.tencent': 'Tencent',
  'meeting.booking.step.meetingType.changyun': '畅云',
  'meeting.booking.step.meetingType.quanshi': '全时',
  'meeting.booking.step.meetingType.hasPoly': '本地会议',
  'meeting.booking.not.joinPwd.length.less': '参会密码长度至少为5！',
  'meeting.booking.not.joinPwd.length.less6': '参会密码长度至少为6位！',
  'meeting.booking.agenda.startNow.validate':
    '该会议有添加议程，修改为立即开始的会议，议程数据将被删除',
  'meeting.booking.vst.time.cross.validate': '跨天会议访客到访的开始日期和结束日期不能为同一天',
  'meeting.booking.step.visitorCount.limit': '当前地点最大访客人数为{data}人',
  'meeting.booking.fail.visit.invite.more': '当前接待地点{timeFormat}只能邀请{visitorCount}位访客',
  'meeting.booking.fail.visit.invite.enough':
    '当前接待地点{timeFormat}邀请的访客数量已达上限，无法继续邀请访客',
  'meeting.booking.fail.smc.min': '华为视频立即开始会议不能小于{duration}分钟',
  'meeting.booking.fail.smc.min.normal': '华为视频会议不能小于{duration}分钟',
  
};
