export default {
  'sws.inventory.title': 'Workplace Inventory',
  'sws.inventory.detail.userName': 'Employee Name',
  'sws.inventory.detail.userNum': 'Employee Account',
  'sws.inventory.detail.deptName': 'Employee Department',
  'sws.inventory.station.stationNum': 'Manual Input',
  'sws.inventory.station.scanStationNum': 'Scan Code',
  'sws.inventory.station.remarks':
    'Remarks: If the station number has changed, please enter it on the right side or scan the code to change it',
  'sws.inventory.station.location': 'Station location',
  'sws.inventory.station.ok': 'Confirm',
  'sws.inventory.detail.empty': 'No inventory is needed at present~',
  'sws.inventory.qrCode.error': 'The QR code information of the workstation is incorrect',
  401701: 'Enterprise WeChat switch is not turned on',
  401702: 'Ding nail switch is not turned on',
  401703: 'There is no workstation that can be counted',
  401704: 'There are uncounted positions, cannot initiate an inventory',
  401705: 'The inventory validity period is not configured',
  401706: 'The counting station has no employees and cannot be counted',
  401707: 'There are more than three workstation users, inventory confirmation failed',
  401708: 'Inventory employee information is abnormal',
  401709: 'User department and workstation department are inconsistent, inventory failed',
  401710: 'The counting station is not a fixed station',
  401711: 'Online status of non-spatial nodes of inventory stations',
  401712: 'The user cannot bind this workstation',
  401717: 'User {value1}, does not have permission to bind to department {value2}',
  401719: 'The number of workstations bound by the user exceeds the limit',
  401858: 'The workstation is bound to multiple users, please unbind it before adjusting the type',
  401014: 'The maximum number of people used at this station has been reached',
  401861: 'You do not have the right to use this station, if you have any questions, please contact the owner of the building',
};
