import returnCode from './zh-CN/returnCode';
import indexBottom from './zh-CN/indexBottom';
import common from './zh-CN/common';

export default {
  ...common,
  ...returnCode,
  ...indexBottom,
  'upload.success': '上传成功',
  'upload.fail': '上传失败',
  home: '主页',
  subscribe: '预 订',
  confirm: '确 定',
  location: '会议室',
  subject: '会议主题',
  host: '主持人',
  applicantsSelect: '请选择',
  applicants: '主持人',
  participants: '参会人',
  time: '会议时间',
  timeZone: '时区',
  delete: '删除',
  back: '返回',
  none: '没有',
  zoomMain: '主会场',
  zoomType: '会议形式',
  zoomScale: '参会人数',
  zoomType0: '会议（在线会议、培训与技术支持）',
  zoomType1: '视频网络研讨会（市场推介与全员大会）',
  zoomScale0: '免费 40分钟 100人',
  zoomScale1: '<100人',
  zoomScale2: '<300人',
  zoomScale3: '<500人',
  zoomScale4: '<1000人',

  'meeting.notice.none': '无',
  'meeting.notice.sms': '短信',
  'meeting.notice.email': '邮件',
  'meeting.notice.vsk': '威思客',
  'meeting.notice.wechat': '微信',
  'meeting.notice.dingtalk': '钉钉',
  'meeting.device': '设备申请',
  'meeting.services': '服务申请',
  'meeting.room.fee': '会议室费用',

  // 服务端返回key
  'msg.login.failed': '登录失败！',
  'msg.login.success': '登录成功！',
  'result.back.sys.value3': '当前用户不存在!',
  'result.back.sys.value6': '当前用户不是当前域用户！',
  'result.back.device.error.value1': '当前用户无权修改设备状态！',
  'result.back.scanning.qr.lock.success': '会议锁屏成功！',
  'result.back.scanning.qr.unlock.success': '会议解除锁屏成功！',
  'result.back.scanning.qr.notice.success': '提醒成功！',
  'result.back.sys.error': '当前操作失败,请检查参数或后台日志',

  // zoom
  online_meeting_type: '会议类型',

  main_meeting_zoom_poll: '调查',
  main_meeting_zoom_advance: '高级选项',
  main_meeting_zoom_wait: '启用等候室',
  main_meeting_zoom_join: '允许在主持人入会前加入',
  main_meeting_zoom_mute: '加入会议时静音',
  main_meeting_zoom_record: '自动将会议录制到本地计算机',
  main_meeting_webex_auto: '系统自动选择Webex线路',
  main_meeting_zoom_auto: '系统自动选择Zoom线路',

  // zoom 参会统计
  meeting_detail_zoom_participants: '参会统计',
  meeting_detail_zoom_polling: '调查结果',
  zoom_participants_label_device: '设备',
  zoom_participants_label_ip: 'IP地址',
  zoom_participants_label_location: '位置',
  zoom_participants_during_minute: '停留{minute}分钟',
  zoom_participants_no_data: '会议使用的Zoom账号，没有查询参会统计的权限。',
  zoom_participant_info_pedding: '数据正在统计中，请稍后再试。',
  zoom_participant_info_ex: '数据统计异常，请联系管理员。',
  zoom_participants_attention: '注意力集中分数',
  zoom_participant_info_nostart: '该zoom会议尚未开启。',
  meeting_audit_accept_fail: '会议时间冲突，审核失败！',

  'meeting.booking.step.tencent.inner': '仅企业内部成员可入会',
  'meeting.booking.step.tencent.before': '允许成员在主持人进会前加入会议',
  meeting_tencent_no_resource: '该时间段内暂无可用腾讯会议资源!',
};
