/**
 * Created by XuQiang on 2017/6/7.
 */

export default {
  'reserve.new': '新增',
  'reserve.visitor.title': '訪客',
  'reserve.visitor.name': '訪客姓名',
  'reserve.visitor.name.tip': '請輸入訪客姓名',
  'reserve.visitor.name.tip_': '請輸入訪客姓名',
  'reserve.visitor.name.error': '姓名不能包含特殊字元',
  'reserve.visitor.company': '訪客公司',
  'reserve.visitor.company.tip': '請輸入訪客公司',
  'reserve.visitor.company.tip_': '請輸入訪客公司',
  'reserve.visit.telephone': '訪客電話',
  'reserve.visit.telephone.tip': '請輸入訪客手機',
  'reserve.visit.telephone.tip_': '請輸入訪客手機',
  'reserve.visit.telephone.error': '請輸入正確的手機號碼',
  'reserve.visit.email': '訪客郵箱',
  'reserve.visit.email.tip': '請輸入訪客郵箱',
  'reserve.visit.email.tip_': '請輸入訪客郵箱',
  'reserve.visit.email.error': '請輸入正確的郵箱',
  'reserve.visit.cause': '訪問事由',
  'reserve.visit.cause_': '訪問事由',
  'reserve.visit.cause.tip': '請選擇訪問事由',
  'reserve.visit.cause.tip_': '請選擇訪問事由',
  'reserve.visit.time': '到訪時間',
  'reserve.visit.time.tip': '請選擇到訪時間',
  'reserve.visit.time.tip_': '請選擇到訪時間',
  'reserve.visit.address': '接待地點',
  'reserve.visit.address.tip': '請選擇接待地點',
  'reserve.visit.address.tip_': '請選擇接待地點',
  'reserve.visit.elseAddress': '其它地址',
  'reserve.visit.booking': '預訂會議室',
  'reserve.visit.booking.tip1': '請先填寫邀請時間',
  'reserve.visit.booking.tip2': '自定義接待地點不支持預訂會議室',
  'reserve.visit.booking.tip3': '請先選擇接待地點',
  'reserve.submit': '提交',
  'reserve.cancel': '取消邀請',
  'reserve.delete': '删除',
  'reserve.resend': '補發邀請函',
  'reserve.print': '打印並簽到',
  'reserve.delay': '變更邀請',
  'reserve.again': '再次邀請',
  'reserve.sign.out': '簽出',
  'reserve.time': '邀請時間',
  'reserve.time_': '邀請時間：',
  'reserve.view': '邀請詳情',
  'reserve.sign.in.time': '簽到時間：',
  'reserve.sign.in.time_': '簽到時間',
  'reserve.sign.out.time': '簽出時間：',
  'reserve.sign.out.time_': '簽出時間',
  'reserve.sign.accompanying_': '隨行人員：',
  'reserve.visitor.host': '拜訪對象',
  'reserve.visitor.host.placeholder': '請選擇拜訪對象',
  'reserve.visitor.template': '範本下載',
  'reserve.visitor.import': '批量導入',
  'reserve.visitor.export': '導出',
  'reserve.visitor.delete': '刪除',
  'reserve.map.place': '請選擇正確的地點',
  'reserve.emailOrPhone': '訪客郵箱與訪客電話請至少填寫一個',
  'reserve.intl.phone': '國際號碼請加國際區號，例如：+852',
  'visit.reserve.type': '訪客來源',
  'visit.reserve.type.0': '主動邀約',
  'visit.reserve.type.1': '自助預約',
  'visit.reserve.type.2': '臨時訪客',
  'visit.reserve.type.3': '團體邀請',
  'reserve.visit.type': '訪客類型',
  'reserve.visit.type_': '請選擇訪客類型',
  'reserve.visit.type_0': '普通訪客',
  'reserve.visit.type_1': 'VIP訪客',
  'reserve.visit.face': '人臉照片（用於刷臉快速入場）',
  'reserve.visit.time.exceed': '到訪時間應超過當前時間5分鐘',
  'reserve.tableFooter.tip1': '您還沒有邀請記錄呢，去',
  'reserve.tableFooter.tip2': '創建',
  'reserve.tableFooter.tip3': '一個吧，點擊紅色字體直接打開新建邀請介面',
  'reserve.startTime': '開始時間',
  'reserve.startTime.tip1': '開始時間不能為空',
  'reserve.startTime.tip2': '會議開始時間應在邀請時間之後',
  'reserve.endTime': '結束時間',
  'reserve.endTime.tip1': '結束時間不能為空',
  'reserve.endTime.tip2': '會議結束時間應超過開始時間15分鐘',
  'reserve.meetingRoom': '選擇會議室',
  'reserve.meetingRoom.tip': '會議室不能為空',
  'reserve.meetingDuration': '會議時長',
  'reserve.meetingDuration.tip': '會議時長不能為空',
  'reserve.ass': '添加訪客',
  'reserve.del.record': '刪除所選記錄',
  'reserve.no.invitee': '請填寫訪客資訊（至少一位）',
  'reserve.invitee.tip': '請先確認當前正在編輯的訪客資訊',
  'reserve.15': '15 分鐘',
  'reserve.30': '30 分鐘',
  'reserve.45': '45 分鐘',
  'reserve.60': '1 小時',
  'reserve.90': '1.5 小時',
  'reserve.120': '2 小時',
  'reserve.180': '3 小時',
  'reserve.240': '4 小時',
  'reserve.300': '5 小時',
  'reserve.360': '6 小時',
  'reserve.420': '7 小時',
  'reserve.480': '8 小時',
  'reserve.540': '9 小時',
  'reserve.600': '10 小時',
  'reserve.660': '11 小時',
  'reserve.720': '12 小時',
  'reserve.change.tip': '變更邀請不支持添加新訪客',
  'reserve.change.tip2': '變更邀請不支持刪除訪客',
  'reserve.change.tip3': '變更邀請不支持編輯訪客資訊',
  'reserve.visit.time.than.meeting.time': '會議開始時間不得早於到訪時間',
  'reserve.confirm.epidemic.code': '防疫碼',
  'reserve.visitor.card.No': '證件號碼',

  'reserve.visit.car.number': '車牌號',
  'reserve.visit.car.number.tip_': '請輸入訪客車牌號',
  'reserve.visit.car.number.error': '請輸入正確的車牌號',
  'reserve.visit.time.than.now.time': '會議開始時間不得早於當前時間',
  'reserve.visit.time.than.now.time5': '會議開始時間應在當前時間5分鐘之後',

  'reserve.leave.time.than.visit.time': '離開時間應超過到訪時間15分鐘',
  'reserve.visit.time.end': '離開時間',
  'reserve.visit.end.time.than.meeting.time': '會議開始時間不得晚於離開時間',
  'reserve.meeting.time.than.meeting.end.time': '會議結束時間應晚於會議開始時間15分鐘',
  'reserve.visit.tip1': '離開時間應超過到訪時間15分鐘',
  'reserve.leave.time': '請選擇離開時間',

  'reserve.visit.private.office': '私有辦公地',
  'reserve.visit.share.office': '共享辦公地',

  'reserve.leave.time.than.visit.time.noAcross': '非跨天邀請，請檢查邀請時間',
  'reserve.visitor.name.add': '添加訪客',
  'reserve.leave.time.than.meeting.time.noAcross': '非跨天邀請，請檢查會議時間',

  'reserve.visit.meeting.name': '會議室',
  'reserve.visit.meeting.name.choose.tip': '請重新選擇會議室',
  'reserve.visit.meeting.room.none': '暫無可用會議室',
  'reserve.visit.meeting.name.choose.place': '請選擇會議室',
  'reserve.visit.meeting.theme': '會議主題',
  'reserve.visit.meeting.theme.placeholder': '{realName}的會議',

  'reserve.visit.now.after.meeting.start': '會議進入簽到時間或已經開始，不可變更邀請',
  'reserve.visit.now.after.meeting.start.cancel': '會議進入簽到時間或已經開始，不可取消邀請',

  'reserve.detail.epit.title.healthCode': '健康碼',
  'reserve.detail.epit.title.tourCode': '行程卡',
  'reserve.detail.epit.title.vaccination': '疫苗證明',
  'reserve.detail.epit.title.nucleic': '核酸報告',
  'reserve.visit.across.max.time': '跨天邀請持續時間最大為{day}天',
  'reserve.visit.visitDayNum.vst.time': '到訪時間不能超過當前日期{day}天',
  'reserve.visit.visitDayNum.smt.time': '會議開始時間不能超過當前日期{day}天',

  'reserve.visit.id.card': '身份證號',
  'reserve.visit.id.card.tip_': '請輸入訪客身份證號',
  'reserve.visit.id.card.error': '請輸入正確的身份證號',
  'reserve.visit.id.card.error.validate': '該訪客身份證號不正確',
  'reserve.visit.meeting.need.approval.tip': '{tipStr}需要審批',
  'reserve.visit.delete.visitor.confirm.text': '確定要刪除該訪客信息嗎?',
  'reserve.visit.work.day.visit.time.error': '請選擇正確的時間，工作日時間：{time}',
  'reserve.visit.work.day.visit.date.error': '節假日不允許預約，請選擇工作日。',
  'visit.meeting.booking.normal_day_meeting_limit': '只允許預訂時長為{time}分鐘的會議',
  'visit.meeting.booking.step.times.limit.tip': '只允許預約{days}天內的會議',
  'visit.reserve.visitor.edit.send.notice': '是否需要向訪客發送更新通知',
  'visit.reserve.visitor.edit.send.notice.yes': '是',
  'visit.reserve.visitor.edit.send.notice.no': '否',
  'visit.meeting.booking.agenda.visit.tip':
    '本次邀請預訂的會議含有議程，不支持在訪客端修改邀約時間',
  'visit.meeting.booking.agenda.meeting.tip': '該會議添加了議程，不支持在訪客端修改時間',
  'visit.reserve.visit.after.now.add.five':
    '預訂的會議含有議程，且當前時間距邀約時間不足5分鐘，不支持變更。',
  'visit.reserve.visit.telephone.error': '請輸入正確的手機號碼',
  'visit.reserve.visit.email.error': '請輸入正確的郵箱',
  'visit.reserve.visit.telephone.or.email.error': '請輸入手機或郵箱',
  'visit.reserve.across.day.visit.time.error': '邀約時間的開始日期和結束日期不能為同一天',
  'visit.reserve.across.day.meeting.time.error': '跨天會議的開始日期和結束日期不能為同一天',
  'visit.visitor.information.count.tip1': '當前接待地點{timeFormat}還可邀請{num}位訪客',
  'visit.visitor.information.count.tip2': '訪客數量已超出當前接待地點{timeFormat}的可邀請數量',
  'visit.visitor.information.count.tip3': '被訪者今日可接待的訪客人數已達上限',
  'visit.visitor.information.count.tip4': '訪客數量已超出當前接待地點的可邀請數量',
  'reserve.fill': '訪客補填',
  'reserve.more.operations': '更多操作',
  'visit.visitor.fill.meeting.tip':
    '如果您在本次邀請中使用過「訪客補填」功能，打開「預訂會議室」後，之前分享給訪客的鏈接將會失效。如果您未使用「訪客補填」功能，請忽略本次提示',
  'reserve.visit.visitor.check.out.title': '簽出成功',
  'reserve.visit.visitor.check.out.text':
    '請提醒該訪客，{value}分鐘內取出存放在儲物櫃中的物品（如已取出，請忽略）',
  'reserve.visit.visitor.check.out.ok': '知道了',

  'reserve.visit.map.type.label': '地圖類型',
  'reserve.visit.map.amap': '高德地圖',
  'reserve.visit.map.google': '谷歌地圖',
  'reserve.visit.map.mapValue.label': '標記接待地導航位置',
  'reserve.visit.map.mapValue.place': '請輸入並選擇導航地址',
  'reserve.visit.map.more.address.label': '詳細地址',
  'reserve.visit.map.more.address.place': '例如：XXX省XX市XX路XX號XX大廈XX層',
  'reserve.visit.map.more.location.label': '接待地點名稱',
  'reserve.visit.map.more.location.place': '請輸入接待地點名稱',
  'reserve.visit.map.position.label': '標記接待地導航位置',
  'reserve.visit.map.position.place': '請輸入導航位置',
  'reserve.visit.map.position.error': '請輸入正確的導航位置',
  'reserve.visit.map.position.error2': '請輸入接待地點名稱和導航位置',
  'reserve.visit.map.position.tip':
    '請在谷歌地圖上找到接待地所在位置，點擊該位置的分享按鈕，然後複製分享鏈接，填寫在這裡',
  'visitor.form.check.information.required': '{value}的必填信息未填寫，請填寫後再提交。',
  'reserve.visit.invitationCode.full.btn': '確認並繼續',
};
