import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';
import { Toast } from 'antd-mobile-v2';
import { history, setLocale } from 'umi';
import { getMenu } from '@/services/user';
import { DEFAULT_INTERFACE, INDEX_ROUTER, MENUDATA_KEY } from '@/utils/constant';
import {base64Encode, getTokenByRefreshToken, isDingTalk, } from '@/utils/utils';

/**
 * get SubMenu or Item
 */
const getSubMenu = (item) => {
  // doc: add hideChildrenInMenu
  if (item.children && !item.hideChildrenInMenu && item.children.some((child) => child.name)) {
    return {
      ...item,
      children: filterMenuData(item.children), // eslint-disable-line
    };
  }
  return item;
};

/**
 * filter menuData
 */
const filterMenuData = (menuData) => {
  if (!menuData) {
    return [];
  }
  return menuData
    .filter((item) => item.name && !item.hideInMenu)
    .map((item) => getSubMenu(item))
    .filter((item) => item);
};
/**
 * 获取面包屑映射
 * @param {Object} menuData 菜单配置
 */
const getBreadcrumbNameMap = (menuData) => {
  const routerMap = {};

  const flattenMenuData = (data) => {
    data.forEach((menuItem) => {
      if (menuItem.children) {
        flattenMenuData(menuItem.children);
      }
      // Reduce memory usage
      routerMap[menuItem.path] = menuItem;
    });
  };
  flattenMenuData(menuData);
  return routerMap;
};

const getCurrentUser = (state) => state.user.currentUser;

/**
 * format original menu data from fetch.
 * @param original
 * @returns {{path: *, authority: undefined, icon: *, name: *, locale: *}}
 */
const formatDate = (original) => {
  return {
    authority: original.authority,
    children: [],
    icon: original?.icon?.trim().length > 0 ? original.icon : null,
    locale: original.name,
    name: original.label,
    path: original.path,
    permission: original.permission,
    mobileHomeShow: original.mobileHomeShow,
    remark: original.remark
  };
};

/**
 *
 * @param menu
 * @param parent
 */
const menuDataConversion = (menu, parent) => {
  menu.forEach((v) => {
    const node = formatDate(v);
    parent.push(node);
    if (v.children.length > 0) {
      menuDataConversion(v.children, node.children);
    } else {
      node.children = null;
    }
  });
};

const memoizeOneGetBreadcrumbNameMap = memoizeOne(getBreadcrumbNameMap, isEqual);

export default {
  namespace: 'menu',

  state: {
    menuData: [],
    breadcrumbNameMap: {},
    authority: [],
  },

  effects: {
    *getMenuData({ payload: { refresh, redirect } }, { call, put, select }) {
      const menu = yield call(getMenu);
      const menuData = [];
      menuDataConversion(menu.data, menuData);
      const breadcrumbNameMap = memoizeOneGetBreadcrumbNameMap(menuData);
      if (menuData) {
        window[MENUDATA_KEY] = menuData;
        sessionStorage.setItem(MENUDATA_KEY,base64Encode(JSON.stringify(menuData)));
      }

      yield put({
        type: 'save',
        payload: { menuData, breadcrumbNameMap },
      });

      // alert(`menuData: ${JSON.stringify(menuData)}`);
      // 非刷新页面，需要重定向到参数页面
      if (!refresh) {
        if (redirect) {
          // fix 添加 history: 'hash' 后统一登录跳转失败问题
          if (redirect.match(/^.*#/)) {
            const str = redirect.split('#');
            /*eslint-disable*/
            redirect = str.shift();
            /* eslint-enable */
          }
          // 重定向地址是完整地址的，进行href替换
          if (redirect.startsWith('http')) {
            window.location.href = redirect;
          } else {
            if (isDingTalk()) {
              window.location.replace(
                `${window.location.origin}/${DEFAULT_INTERFACE}app${redirect}`,
              ); // 钉钉
              return;
            }
            history.replace(redirect);
            const currentUser = yield select(getCurrentUser);
            if (currentUser && currentUser.lang) {
              let userLang = currentUser.lang;
              if (userLang.indexOf('en') !== -1) {
                userLang = 'en-US';
              }
              if (userLang.indexOf('TW') !== -1) {
                userLang = 'zh-TW';
              }
              if (userLang.indexOf('CN') !== -1) {
                userLang = 'zh-CN';
              }
              setLocale(userLang);
            }
          }
        } else {
          // 默认到首页
          history.replace(INDEX_ROUTER);
        }
        Toast.hide();
      }

      // 刷新页面，重新启动refreshToken定时器
      if(refresh){
        getTokenByRefreshToken();
      }

      const {
        location: { pathname },
      } = yield select((state) => state.router);

      const from = sessionStorage.getItem('from');
      if (from && from === 'cloud') {
        if (menuData.length === 0 && pathname !== '/cloud/expiredPage') {
          console.log('123');
          history.replace(`/cloud/expiredPage`);
          return;
        } else if (menuData.length > 0 && pathname === '/cloud/expiredPage') {
          // 默认到首页
          history.replace(INDEX_ROUTER);
        }
      }

      yield put({
        type: 'getAuthority',
        payload: {
          path: pathname,
        },
      });
    },
    *getAuthority({ payload: { path } }, { put, select }) {
      const { breadcrumbNameMap } = yield select((state) => state.menu);
      try {
        const { authority } = breadcrumbNameMap[path];
        yield put({
          type: 'save',
          payload: {
            authority,
          },
        });
      } catch (e) {
        yield put({
          type: 'save',
          payload: {
            authority: [],
          },
        });
      }
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return umiHistory.listen(({ pathname }) => {
        dispatch({
          type: 'getAuthority',
          payload: {
            path: pathname,
          },
        });
      });
    },
  },
};
