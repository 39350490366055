export default {
  'meeting.region.btn.select': '篩選',
  'meeting.region.search.choice': '請選擇',
  'meeting.region.search.startDate': '選擇日期',
  'meeting.region.search.startTime': '開始時間',
  'meeting.region.search.timeLong': '會議時長(單選)',
  'meeting.region.search.minute': '分鐘',
  'meeting.region.search.reset': '重置',
  'meeting.region.search.submit': '確定',
  'meeting.region.search.time': '選擇時間',
};
