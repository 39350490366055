import { matchPath } from 'umi';
import { MENU_PATH } from '@/pages/Meeting/utils/constant';
import getZoomParticipantInfo from './service';

export default {
  namespace: 'zoomParticipant',
  state: {
    meetingId: 0,
    participantsData: {
      code: 0,
      data: [],
    },
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *loadParticipantInfo({ meetingId }, { put, call }) {
      const result = yield call(getZoomParticipantInfo, { meetingId });
      if (result.code === 0) {
        const { data } = result;
        yield put({ type: 'update', payload: { participantsData: data } });
      } else {
        yield put({ type: 'update', payload: { participantsData: { code: 2 } } });
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.zoomParticipant)) {
          const { query } = history.location;
          const { meetingId } = query || {};
          dispatch({ type: 'update', payload: { meetingId } });
          dispatch({ type: 'loadParticipantInfo', meetingId });
        }
      });
    },
  },
};
