/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  scan_auth_success: '掃碼認證成功',
  scan_auth_fail: '掃碼認證失敗',
  scan_auth_close: '關閉',
};
