export default {
  'menu.workspace.user': '智能工位',
  'menu.workspace.booking': '工位预订',
  'menu.workspace.specialBooking': '特殊预订',
  'menu.workspace.myBooking': '我的预订222',
  'menu.workspace.history': '历史记录',
  'menu.workspace.search': '工位查询',
  'menu.workspace.repair': '工位报修',
  'menu.workspace.mobile': '智能工位 ',
  'sws.menu.station.booking': '工位预订',
  'sws.menu.station.repair': '工位报修',
  'sws.menu.station.search': '工位查询',
  'sws.menu.station.records': '我的预订',
  'sws.menu.station.special': '工位特殊预订',
  'sws.menu.locker.booking': '储物柜预订',
  'sws.menu.locker.records': '储物柜预订记录',
  'sws.time': 'YYYY-MM-DD HH:mm',
  'sws.time.date': 'YYYY-MM-DD',
  'sws.location.tip': '请选择区域',
  'sws.location.tip.no.permission': '您添加的区域暂无权限',
  'sws.booking.no.all.tip': '请选择区域和时间',
  'sws.menu.station.liftTable': '我的升降桌',

  401127: '系统工作日时间未设置',
  401129: '当前用户没有工位{value1}的部门权限',
  401137: '当前用户在所选时间内已占用或预约工位，请选择其他时间',
  401061: '区域{value1}未设置时区信息，请在区域管理中进行维护',
  403054: '储物柜认证权限获取失败',
  403055: '当前用户未设置位置权限',

  'sws.delay.num.format': '{num}分钟',
  'sws.myBooking.station.no': '工位编号',
  'sws.fixed.confirm.bind.tip': '是否解除所有已绑定的固定工位？',
  'sws.fixed.confirm.bind.scan.expire.tip': '二维码信息已失效，请重新扫码',
};
