export default {
  main_meeting_zoom_poll_question_title: '題目名稱',
  main_meeting_zoom_poll_question_title_pls: '請輸入題目名稱',
  main_meeting_zoom_poll_option: '選項',
  main_meeting_zoom_poll_option_add: '添加選項',
  main_meeting_zoom_poll_option_pls: '請輸入選項',
  main_meeting_zoom_poll_questionName_repeat: '調查問卷內題目不能重複',
  main_meeting_zoom_poll_option_repeat: '題目答案不能重複',
  main_meeting_zoom_poll_option_min: '題目選項不能少於2個',
  main_meeting_zoom_poll_option_max: '題目選項不能多於10個',
  main_meeting_zoom_poll_switch: '切換至多選題',
  main_meeting_zoom_poll_submit: '提交',
};
