import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 立即使用
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getStationCheckInfo(params) {
  const url = `/${RESTFUL_PATH.sws}/api/station-check/info?id=${params.id}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 工位盘点详情
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getStationCheckInfo2({ id }) {
  const url = `/${RESTFUL_PATH.sws}/api/check-task-info/infoForAppLink?id=${id}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 查询工位编号
 * @param payload 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getStationNums(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/check-task-info/stationNo?stationNum=${
    payload.stationNum || ''
  }`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 盘点确认
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function checkConfirm(params) {
  return request(`/${RESTFUL_PATH.sws}/api/check-task-info/submitForApp`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

/**
 * 工位id查询工位信息
 * @param stationId 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function searchScanStationInfo(stationId) {
  const url = `/${RESTFUL_PATH.sws}/api/station-check/queryById?id=${stationId}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 过滤移动工位的工位id查询工位信息
 * @param stationId 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function searchScanStationInfo2(stationId) {
  const url = `/${RESTFUL_PATH.sws}/api/check-task-info/queryById?id=${stationId}`;
  return request(url, {
    method: 'GET',
  });
}
