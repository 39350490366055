export default {
  'sws.inventory.title': '工位盘点',
  'sws.inventory.detail.userName': '员工姓名',
  'sws.inventory.detail.userNum': '员工账号',
  'sws.inventory.detail.deptName': '员工部门',
  'sws.inventory.station.stationNum': '手动输入工位编号',
  'sws.inventory.station.scanStationNum': '扫码识别工位编号',
  'sws.inventory.station.remarks': '备注：工位编号如有变化，请在右侧输入或扫码更改',
  'sws.inventory.station.location': '工位位置',
  'sws.inventory.station.ok': '确认',

  'sws.inventory.detail.empty': '当前无需工位盘点～',
  'sws.inventory.qrCode.error': '工位二维码信息不正确',
  'sws.inventory.btn.stationnum': '请选择工位编号',
  'sws.inventory.btn.confim': '确定',
  'sws.inventory.stationnum': '工位编号',
  'sws.inventory.return': '返回任务',
  'sws.inventory.length': '共{value1}个',
  'sws.menu.station.inventorytask': '工位盘点',
};
