export default {
  'sign.in.type.no': 'No check-in',
  'sign.in.type.anonymity': 'Anonymous check-in',
  'sign.in.type.password': 'Authentication',
  'sign.in.type.invitation': 'Invitation code',
  'sign.in.type.QR.code': 'QR code',
  'sign.in.type.face': 'Face recognition',
  'sign.in.type.zoom': 'Zoom',
  'sign.in.type.card': 'Card check-in',

  'meeting.room.device.repair': 'Repair apllication',
  'meeting.room.device.repaired': 'Repair applied',
  'meeting.room.device.repair.success': 'Device repair applied successfully',
  'meeting.room.manager': 'Administrator',
  'meeting.room.need.audit': 'Approval required',
  'meeting.room.service': 'Service',
  'meeting.room.service.have': 'Yes',
  'meeting.room.detail.name': 'Name',
  'meeting.room.detail.area': 'Area',
  'meeting.room.detail.mail': 'Email',
  'meeting.room.detail.number': 'Attendee(s)',
  'meeting.room.detail.price': 'Price',
  'meeting.room.detail.unit': 'Yuan per hour',
  'meeting.room.detail.type': 'Type',
  'meeting.room.detail.company': 'Company',
  'meeting.room.detail.sign': 'Check-in type',
  'meeting.room.detail.manager': 'Administrator',
  'meeting.room.detail.photo': 'Photo',
  'meeting.room.detail.device': 'Device',
  'meeting.room.detail.reserve': 'Book',
  'meeting.calendar.room.price.free': 'Free',

  meeting_room_capacity_capacity0: 'General Room',
  meeting_room_capacity_capacity1: 'WebEx Room',
  meeting_room_capacity_capacity2: 'Acano Room',
  meeting_room_capacity_capacity3: 'Exchange Room',
  meeting_room_capacity_capacity4: 'Google Calendar Room',
  meeting_room_capacity_capacity5: 'Domino|Notes Room',
  meeting_room_capacity_capacity7: 'Infrared meeting room',
  meeting_room_capacity_capacity6: 'Cisco Video Room',
  meeting_room_capacity_capacity8: 'Zoom room',
  meeting_room_capacity_capacity9: 'Tencent virtual room',
  meeting_room_capacity_capacity10: 'Feishu Room',
  meeting_room_capacity_capacity12: 'Silencing bin',
  'meeting.room.detail.tencent': 'Square number',
};
