/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'menu.meeting.meeting_app': '智能會議',
  'menu.meeting.subscribe': '會議預訂',
  'menu.meeting.mines': '會議列表',
  'menu.meeting.webexmeeting': '在線會議',
  'menu.meeting.videomeeting': '視頻會議',
  'menu.workspace.mobile': '智能工位',
  'sws.menu.station.booking': '工位預約',
  'sws.menu.station.repair': '工位報修',
  'sws.menu.station.search': '工位查詢',
  'sws.menu.station.records': '預約記錄',
  'sws.menu.workspace.recommendBooking':'推薦預訂',
  'menu.slt.app.lighting': '智慧照明',
  'menu.slt.app.report.environment': '環境分析',
  'menu.slt.app.report.energy': '能耗分析',
  'menu.sas.app.smartaccess': '智能門禁',
  'menu.sas.app.qrCode': '我的二維碼',
  'menu.sas.app.faceDatabase': '我的人臉庫',
  'menu.sas.app.faultRepair': '故障報修',
  'menu.sas.app.myAccess': '我的門禁',
  'menu.vst.app.manage': '智能訪客',
  'menu.vst.app.reserve': '邀請記錄',
  'menu.vst.app.visit': '訪問記錄',
  'menu.vst.app.confirm': '訪客審批',
  'menu.vst.app.statisticalAnalysis': '統計分析',
  'menu.app.index': '主頁',
  'menu.app.my': '我的',
};
