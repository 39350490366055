export default {
  'cmma.status.connected': 'Connected',
  'cmma.status.ringing': 'Dialing',
  'cmma.status.unknown': 'Not connected',
  'cmma.tag.terminal': 'TRML',
  'cmma.tag.number': 'No.',
  'cmma.tag.No': 'No.',
  'cmma.tag.online': 'Online',
  'cmma.tag.pwd': 'Password:',
  'cmma.tag.time': 'Time:',
  'cmma.btn.mainRoom': 'Main meeting room',
  'cmma.btn.appoint': 'Appoint',
  'cmma.btn.camera': 'Camera',
  'cmma.btn.mute': 'Mute',
  'cmma.btn.hangUp': 'Hang up',
  'cmma.btn.reload': 'Redial',
  'cmma.btn.startNow': 'Start',
  'cmma.btn.add': 'Add',
  'cmma.btn.muteAll': 'Mute all',
  'cmma.btn.dial': 'Dial',
  'cmma.btn.lock': 'Lock',
  'cmma.btn.delay': 'Extend',
  'cmma.btn.text': 'Message',
  'cmma.btn.layout': 'Layout',
  'cmma.btn.rotation': 'Rotation',
  'cmma.btn.send': 'Send',
  'cmma.btn.yes': 'Yes',
  'cmma.btn.no': 'No',
  'cmma.btn.cancel': 'Cancel',
  'cmma.btn.rotation.stop': 'Stop rotation',
  'cmma.btn.autoReconnect': 'Auto-reconnect',
  'cmma.msg.allMuteValid': 'Mute all turned on',
  'cmma.msg.allMuteInvalid': 'Mute all turned off',
  'cmma.msg.lockValid': 'Meeting lock turned on',
  'cmma.msg.lockInvalid': 'Meeting lock turned off',
  'cmma.msg.mainRoom': 'Main room selected',
  'cmma.msg.appointValid': 'Appoint turned on',
  'cmma.msg.appointInvalid': 'Appoint turned off',
  'cmma.msg.cameraValid': 'Camera turned on',
  'cmma.msg.cameraInvalid': 'Camera turned off',
  'cmma.msg.muteValid': 'Mute turned on',
  'cmma.msg.muteInvalid': 'Mute turned off',
  'cmma.msg.hangUp': 'Hang up successfully',
  'cmma.msg.currentClose': 'The meeting has ended',
  'cmma.msg.startSuccess': 'Video conference has started successfully',
  'cmma.msg.busyList': 'The following meeting rooms are in service',
  'cmma.msg.startFailedBusy': 'The video conference is unable to start due to resources occupied',
  'cmma.msg.startFailed': 'The video conference is unable to start ',
  'cmma.msg.closeSuccess': 'Video conference has ended successfully',
  'cmma.msg.dialFailed': 'Dail failed',
  'cmma.msg.usingInfo': 'Terminal is occupied by the following conferences',
  'cmma.msg.delay': 'The meeting is extended successfully ',
  'cmma.msg.hangupTitle': 'Hang up',
  'cmma.msg.hangupMsg': 'Whether to release conference room resources',
  'cmma.msg.socketError': 'CMMA network connection is abnormal',
  'cmma.msg.duplicate': 'The number you are dailing is already in current conference',
  'cmma.msg.text.title': 'Edit Messages',
  'cmma.msg.text.ph1': '* Duration (s)',
  'cmma.msg.text.ph2': '* Enter a message within 60 words',
  'cmma.msg.text.success': 'Message sent successfully',
  'cmma.msg.rotation': 'Current rotation setting is {interval} s',
  'cmma.title.rotation': 'Rotation Setting',
  'cmma.msg.rotation.stop': 'Stop rotation',
  'cmma.msg.rotation.start': 'Rotation starts successfully',
  'cmma.msg.layout.success': 'Layout changed successfully',
  'cmma.msg.autoReonnect.open': 'Automatic reconnect is turned on',
  'cmma.msg.autoReonnect.close': 'Automatic reconnect is turned off',

  'cmma.layout.speakerOnly': 'Full screen',
  'cmma.layout.telepresence': 'Telepresence',
  'cmma.layout.stacked': 'Stacked',
  'cmma.layout.allEqual': 'Equal',
  'cmma.layout.allEqualQuarters': '2*2',
  'cmma.layout.allEqualNinths': '3*3',
  'cmma.layout.allEqualSixteenths': '4*4',
  'cmma.layout.allEqualTwentyFifths': '5*5',
  'cmma.layout.onePlusN': '1+N',
  'cmma.layout.onePlusFive': '1+5',
  'cmma.layout.onePlusSeven': '1+7',
  'cmma.layout.onePlusNine': '1+9',
  'cmma.layout.global': 'Global',

  mc_btn_subtitle: 'Subtitle',
  mc_btn_minute: 'Minute',
  mc_subtitle_language: 'Language',
  mc_subtitle_translate: 'Translate',
  mc_subtitle_sublanguage: 'Sublanguage',
  mc_subtitle_language_zh: 'Chinese-Mandarin',
  mc_subtitle_language_en_u: 'English-US',
  mc_subtitle_language_en_a: 'English-Australia',
  mc_subtitle_language_kr: 'Korean',
  mc_subtitle_language_jp: 'Japanese',
  mc_subtitle_language_my: 'Malay',
  mc_subtitle_google: 'Only google support',
  mc_asr_config_error: 'Video play config exception',
  mc_msg_success: 'Operate successfully, please wait for a moment...',
};
