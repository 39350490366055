export default {
  200000: 'Operation success!',
  200001: 'Operation failure！',
  201002: 'Current user does not have the permission to manage meetings!',

  201000: 'Checked in, there is no need to check in again!',
  201001: 'This user is not a attendee of current meeting and cannot check in!',
  201009: 'The current user has rejected the meeting, there is no need to check in again!',
  201013: 'Current request has been processed!',
  202031: 'Operation failed, meeting does not exist',
  203001:
    'Unable to schedule a video meeting without available meeting number resource. Please contact with administrator.',
  cmma_no_devices:
    'Meeting reservation has failed because the room {names} does not connect with vedio device.',
  203003: 'Video conference room resources are occupied.',
  203004: 'Video conference information is not found',

  202001: 'Start time can not be empty',
  202002: 'End time can not be empty',
  202003: 'Start time shall not be after the end time.',
  202004: 'Current meeting status is not allowed to be edited.',
  202005: 'Periodic meeting duration is not allowed to cross the days',
  202006: 'Cross-day meeting range shall not be less than the minimum days',
  202064: 'Cross-day meeting time cannot be greater than {value} days',
  202007: 'Current user does not have permissions to modify the meeting!',
  202008: 'You shall be the Host or Attendee！',
  202009: 'Start time cannot be earlier than current time, please select again!',
  202010: 'The meeting cannot be reserved due to meeting room maintenance.',
  202011: 'The Loop Parameters of the Periodic Meeting are not complete!',
  202012: 'Periodic Meetings can not be created according to the repeat rule!',
  202013: 'The appointment time of the periodic meeting exceeds the maximum meeting range!',
  202014:
    'The function of periodic meeting is turned off. No periodic meeting reservation is allowed!',
  202015: 'Appointment failure. The meeting start time is earlier than the working hours!',
  202016: 'Appointment failure. The meeting end time is later than the working hours!',
  202017: 'No webex line is available during the period!',
  202018: 'The webex module is not available!',
  202019: 'WebEx meeting synchronization failed!',
  202021:
    'Current meeting service is not allowed to be revised because it is being reviewed or approved already.',
  202022:
    'Current services of the periodic meetings are not allowed to be revised because they are being reviewed or approved already.',
  202023: 'Check-in time has not arrived.',
  202024: 'Check-in time has ended.',
  202028: 'Please apply for the meeting {time} minutes in advance！',

  202077:
    'You do not have reservation permission for the {roomList} meeting room. If you need to modify the meeting information, please re-select the meeting room!',

  204001: 'No acknowledgement for the meeting{state}!',
  204002: 'The meeting does not exist!',
  204003:
    'Current user is not the attendee or host of the meeting and is not allowed to return the meeting acknowledgement!',
  204004: 'Current user has sent the meeting acknowledgement, no need to send again!',
  202039: 'Exchange Meeting time conflict',
  202040: 'Google Meeting time conflict',
  202041: 'Exchange approval room cannot be selected at the same time as other approval rooms!',
  202045: 'There are not enough resources in the zoom room, please contact the administrator!',
  202046: 'The zoom module is not available!',
  202047:
    'Zoom conference interface call exception, please try again later or contact the system administrator !',
  202048: 'Meeting extension not enabled',
  202049: 'Meeting sign in function is not enabled',
  202056:
    'This meeting room is an Exchange meeting room, and the scheduled meeting time cannot exceed the range of Exchange synchronization days!',
  202050: 'Polling editing is not allowed in current meeting status',
  202051: 'The current meeting zoom information does not exist',
  202052: 'Failed to delete polling',
  202053: 'Failed to create polling',
  202054: 'Failed to update polling',
  202055: 'The account does not have the authority to create a polling',
  202101: 'If the extension fails, the meeting can be extended for insufficient time',
  202105: 'The creator of the third party appointment meeting does not exist',
  202058: 'Duplicate email address of external and internal attendees',

  202057: 'Failed to open the display board, reason: no number is bound',
  202038: 'Operation failed, current user has no operation permission',
  202061: 'Meeting status has changed, failed to cancel!',
  202059: 'This is an Exchange meeting, please add the email information of external attendees!',

  202030: 'The mobile number {phone} is already in the historical attendees list',
  202065: 'The email {email} is already in the historical attendees list',
  200006: 'The number of appointments for the current user has been limited',
  202060: 'The reverse synchronization function is not enabled. The appointment failed',
  202108: 'Meeting room not available',
  202109: 'Non physical meeting room can not scan the code for appointment',

  202134: 'Meeting in process, no editing allowed!',
  202110: 'Booking failed, room: {rooms} offline',
  202111: 'Booking failed, room: {rooms} deleted',
  202112: 'Booking failed, room: {rooms} maintained',
  202066: 'The meeting has started to sign in. Editing is not allowed！',
  202067: 'The user does not have the right to reserve a circular meeting！',
  202068: 'The user does not have the right to reserve a cross day meeting！',
  20206701: 'The user does not have the right to edit a circular meeting！',
  'meeting.booking.fail.title.maintain':
    'The {roomName} will be maintained during the meeting time you choose, and the meeting reservation failed!',
  'meeting.booking.fail.title.maintain.edit':
    'The {roomName} will be maintained during the meeting time you choose, and the meeting edit failed!',

  202120: 'Create failed, founder or host phone is empty! ',
  202121: 'Tencent meeting failed! ',
  202122: 'Update Tencent meeting failed! ',
  202115:
    'You have been pulled into the blacklist of the system and cannot reserve a meeting temporarily',
  'meeting.booking.fail.tencent.max':
    'Only {num} Tencent meetings can be reserved at the same time, please change the time and try again.',

  202131:
    'Error getting TEAMS meeting parameter configuration information, please contact administrator',
  202132: 'Failed to create Teams meeting!',
  202133: 'Update Teams meeting failed!',
  207012:
    'The length of meeting reservation exceeds the maximum time {value} minutes of a single meeting in the meeting room',
  200999: 'Scheduled meeting conflicts with other meetings, appointment failed',
  207013:
    'The meeting duration is greater than the maximum minutes across days set in the conference room',
  202143: 'Failed to get YMS unbound device information',
  202144:
    'YMS module is not configured, please contact the administrator for configuration related information',
  202145: 'YMS enrollment time has arrived, operation is not allowed',
  202146: 'Failed to create YMS meeting!',
  202147:
    'The conference room is not bound to a device terminal, please reselect the conference room!',
  202148:
    'The appointment time shall not be less than 5 minutes from the beginning of the YMS meeting',
  202149: 'Failed to end YMS meeting!',
  202151: 'Failed to operate YMS conference!',
  202150: 'Failed to edit YMS meeting!',
  202152: 'Failed to create flybook meeting!',
  202153: 'Failed to update flybook meeting',
  202154: 'Failed to extend the flybook meeting!',
  'error.meeting.teams.timeout': 'Teams network request timed out',
  202158: 'The creator Feishu ID does not exist',
  20210059: 'Incorrect password format. Only 6 digits are supported!',
  202200:
    'Flybook conference room and exchange conference room cannot be reserved at the same time',
  202201: 'Exchange meeting rooms cannot be directly replaced with flybook meeting rooms',
  202202: 'Flybook meeting rooms cannot be directly replaced with exchange meeting rooms',
  202204: 'The meeting reservation application has been rejected',
  202205:
    'This conference reservation request is automatically canceled because no one approves it',
  202164:
    'You are not allowed to make an appointment for a cross day meeting for an online meeting!',
  202162:
    'The current user has no permission to make an appointment for a team meeting, The appointment failed!',
  202161:
    'Error getting Tencent meeting parameter configuration information, please contact administrator!',

  202206:
    'Visitor reservation time limit is less than {value} days, please make another appointment',
  202207: 'The cross day configuration is not enabled for visitors',
  202208:
    'Visitor cross day time limit is less than {value} days, please make an appointment again',
  202209: "Visitor's visit time is earlier than working time",
  202210: "The end time of visitors' visit is later than the working time",
  202211: 'The last extension operation is being processed, please try again later',
  202212: 'The meeting start time is later than the working time, the appointment failed!',
  202213: 'The meeting end time is earlier than the working time, appointment failed!',
  202169: 'Single day meeting duration is less than the configured minimum duration!',
  202172: 'Extension failed. The meeting duration cannot exceed {value} minutes!',
  202171: 'The current date is unavailable, please select another date to make an appointment!',
  // 202180: 'Video conference creation failed',
  // 202181: 'Video conference update failed',
  // 202182: 'There are too many people currently booking the meeting, please try again later',
  202173: 'Exchange sync meeting without receipt, please use Outlook on receipt',
  202174: 'Feishu synchronous meeting does not require a receipt, please use the receipt on Feishu',
  202178: 'You cannot make an appointment for a meeting in {value} days!',
  202181: 'Circular meeting cannot be reserved for {value} days later!',
  202179: 'The duration of the circular meeting is less than the configured minimum duration!',
  202214: 'The number of visitors exceeds the maximum number of visitors on the day!',
  202180: 'The duration of the circular meeting is greater than the configured maximum duration!',
  202182: 'Extension failed. The meeting duration cannot exceed {value} minutes!',
  202176: 'The meeting extension failed. The {value} is(are) under maintenance !',
  202177: 'The meeting extension failed. The {value} has been deactivated !',
  202183:
    'There are still unfinished meetings for the current cycle meeting, please edit them later',
  202184:
    'There are still unfinished meetings for the current cycle meeting, please cancel them later',
  20218101: 'You cannot book more than one session on the same day. Please select another date',
  2023001: 'Creating the CloudMeet meeting failed, please check the background log!',
  2023002: 'Updating the CloudMeet meeting failed, please check the background log!',
  2023003: 'Extending the CloudMeet meeting failed, please check the background log!',
  2023004: 'The CloudMeet meeting has entered or started in advance, unable to cancel!',
  2023005: 'Failed to end the CloudMeet meeting, please check the background log!',
  202401: 'Creating the SMC meeting failed, please check the background log！',
  202402: 'Updating the SMC meeting failed, please check the background log！',
  202404: 'Extending the SMC meeting failed, please check the background log！',
  202403: 'Failed to end the SMC meeting, please check the background log！',
  202405: 'Failed to cancel the SMC meeting. please check the background log！',
  202406: 'SMC meeting configurations are incorrect. please check the background log！',
  202407: 'The operation failed. Please check the background log！',
  2023006:
    'The meeting has started or has been enrolled in advance, editing or cancellation is not allowed!',
  2023007: 'CloudMeet meeting has ended, unable to operate!',
  202185:
    'This meeting contains meeting rooms that need to be approved. After changing the meeting time, it needs to be approved again. Please confirm whether to change the meeting time.',

  202186:
    'The modified meeting room includes the meeting room that needs to be approved. After the modification, the meeting needs to be approved again. Please confirm whether it is modified or not.',
  202187:
    'This meeting contains meeting rooms that need to be approved. After modifying the cycle rules, it needs to be approved again. Please confirm whether to modify it.',
  202190:
    'After changing a meeting, the conference services/equipment booked for a single meeting will be canceled, will it continue?',
  202194: 'Visitor integration function is not enabled',
  202195: 'Invited visitors must book {value} minutes in advance',
  202197: 'Feishu Meeting Room cannot reserve Teams meetings',
  202215:
    "Meeting invitation for visitors, the visitor's appointment time is outside working hours, operation failed!",
  202216:
    "Meeting invitation for visitors, the visitor's appointment time exceeds the limit for consecutive days, operation failed!",
  202217:
    "Meeting invitation for visitors, the visitor's appointment time exceeds the limit for days, operation failed!",
  202218:
    'Meeting invitation for visitors, the number of visitors exceeds the maximum capacity of the reception area, operation failed!',
    202219: 'This record has been approved by another person and cannot be re approved！',
  202199:
    'Unable to retrieve the meeting from the Exchange/Office365 conference room calendar, please check if the conference room calendar has responded successfully',
  202220: 'There is no meeting room under the current tenant, unable to init simulation data',
  202221:
    'Currently, it is a free version of enterprise and micro meeting, and the current function cannot be used',
  202222: 'Failed to create enterprise micro meeting. Please check the backend logs',
  202223: 'Failed to update enterprise micro meeting. Please check the backend logs',
  202224: 'Failed to cancel enterprise micro meeting. Please check the backend logs',
  202225: 'Extension failed, enterprise and micro meeting cannot be extended',
  2023008:
    'The reverse synchronization type only supports fixed accounts, and the default reservation account cannot be empty',
  20231008: 'The webex meeting duration must be greater than 10 minutes',
  202310241700: 'Webex synchronization appointment failed',
  202310241722: 'Webex synchronization update failed',
  202402061443:
    'Please check whether the webex account is valid or whether the scope has enough privileges.',
  202402061445: 'Please check your webex configuration or check if your token is expired.',
  20240923: 'Please select at least one terminal conference room',
  201016: 'The meeting room is not bound to a workflow template',
  //  201016: 'Appointment failed. Room:{rooms} is no room administrator in the current meeting',
  201017: 'The meeting service is not bound to a workflow template',
  201018: 'The meeting mobile device is not bound to a workflow template',
  2023083001:'Attachment information cannot be empty!',
  2023083002:'File name cannot be empty!',
  2023083003:'File suffix cannot be empty!',
  2023083004:'File format error!',
  2023083005:'The attachment already exists and cannot be uploaded again!',
  2023083006:'The current user does not have download permission!',
  2023083007: 'Backend storage  near full',
  2023083008: 'Failed to upload',
  2023083009: 'Invalid file name',
  2023083010: 'The meeting has ended',
  2023083011: 'The meeting has been cancelled',
  20100501: 'A meeting room has been deleted. Please refresh the page and select a new one',
};
