export default {
  common_cancel: 'Cancel',
  common_confirm: 'OK',
  'meeting.sub.tip': 'Tips',
  'meeting.sub.content':
    'You have been pulled into the blacklist of the system and cannot reserve a meeting room temporarily.',
  'meeting.sub.content.flybook': 'Feishu meeting room cannot be booked',
  'meeting.sub.ok': 'OK',
  'meeting.evaluate.title': 'Evaluation',
  'meeting.evaluate.redio': 'Single choice',
  'meeting.evaluate.choice': 'Multiple choice',
  'meeting.evaluate.submit.sure': 'Are you sure you want to submit? ',
  'meeting.evaluate.ok': 'OK',
  'meeting.evaluate.cacle': 'Cancel',
  'meeting.evaluate.select.tip': 'Please select this',
  'meeting.evaluate.text.tip': 'Please fill in this item',
  'meeting.evaluate.submit': 'Submit',
  'meeting.evaluate.text.place': 'Please enter content',
  'meeting.evaluate.commit.tip': 'Thank you for your evaluation',
  'meeting.evaluate.commit.tip.1': 'Submitted successfully! ',
  'meeting.evaluate.overdue': 'Questionnaire expired or unavailable !',
  'meeting.attend.root': 'Contacts',
  'meeting.attend.name.number': 'Topic {value}',
  'meeting.xrooms.feishu': 'Feishu Rooms',
  'meeting.xrooms.tengxun': 'Tencent Rooms',
  'meeting.xrooms.teams': 'Teams Rooms'
};
