import request from '@/utils/request';

import { RESTFUL_PATH } from '@/utils/constant';

export const getFaceData = ({ payload }) => {
  const { page, size } = payload;
  return request(`/${RESTFUL_PATH.access}/api/face/getFace?currentPage=${page}&pageSize=${size}`, {
    method: 'POST',
  });
};

export const review = ({ payload }) => {
  return request(`/${RESTFUL_PATH.access}/api/face/reviewFace`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};
