import { Toast } from 'antd-mobile-v2';
import { history, formatMessage } from 'umi';
import { getUrlParam } from '@/utils/utils';
import { ACCESS_TOKEN, ROUTE_PATH } from '@/utils/constant';

// import vconsole from 'vconsole';
//
// const vConsole = new vconsole();

import { dingDingBindFuc, callback } from '../WeChat/services';

export default {
  namespace: 'dingDing',

  state: {
    bBindState: 0,
    userInfo: {},
    userBindInfo: {
      userId: '',
      password: '',
    },
  },

  effects: {
    *dingDingBindFuc({ payload }, { call }) {
      const state = sessionStorage.getItem('state');
      const accId = sessionStorage.getItem('accId');
      const tempUrl = sessionStorage.getItem('tempUrlDD');
      const appId = sessionStorage.getItem('appId');
      const hr = sessionStorage.getItem('hrWx');
      const lc = sessionStorage.getItem('lcDD');
      const isFm = sessionStorage.getItem('isFm');
      const data = yield call(dingDingBindFuc, {
        userId: payload.userName,
        password: payload.password,
        code: payload.code,
        randomStr: payload.randomStr,
        state,
        accId,
        tempUrl,
        appId,
        hr,
        lc,
        isFm,
      });
    //  window.console.log(data, 'dingDingBindFuc');
      if (data.code === 0) {
        sessionStorage.setItem(ACCESS_TOKEN, data.data.dingDingLocalUser.token);
        if (data.data.redirectUrl.startsWith('http')) {
          window.dd.biz.navigation.replace({
            url: data.data.redirectUrl,
            onSuccess(result) {
              window.console.log(result);
            },
            onFail(err) {
              Toast.fail(`dd error: ${JSON.stringify(err)}`);
            },
          });
        } else {
          history.replace(`${ROUTE_PATH}/${data.data.redirectUrl}`);
        }
      } else {
        const errorText = formatMessage({ id: data.msg });
        Toast.fail(errorText);
      }
    },
    *callback({ code }, { call, put }) {
      if (code) {
        const state = sessionStorage.getItem('state');
        const appId = sessionStorage.getItem('appId');
        const domain = sessionStorage.getItem('domain');
        const tempUrl = sessionStorage.getItem('tempUrlDD');
        const hr = sessionStorage.getItem('hrWx');
        const lc = sessionStorage.getItem('lcDD');
        const isFm = sessionStorage.getItem('isFm');
        const data = yield call(callback, { code, state, id: appId, domain, tempUrl, hr, lc, isFm });
        if (data && data.code === 0) {
          const dataResObj = data.data;
          if (dataResObj.state === 0) {
            if (dataResObj.tempUrl.startsWith('http')) {
              window.dd.biz.navigation.replace({
                url: dataResObj.tempUrl,
                onSuccess(result) {
                  window.console.log(result);
                },
                onFail(err) {
                  Toast.fail(`dd error: ${JSON.stringify(err)}`);
                },
              });
            } else {
              history.replace(`${ROUTE_PATH}/${dataResObj.tempUrl}`);
            }
            // window.location.href = dataResObj.tempUrl;
          } else {
            sessionStorage.setItem('accId', dataResObj.accId);
            // 未绑定
            if(dataResObj?.tempUrl.indexOf('scanAuthPage') !== -1){
           //   alert(`dataResObj?.tempUrl---${dataResObj?.tempUrl}`)
              // 统一扫码
              const { location } = history;
              history.replace(`${ROUTE_PATH}/scanAuthPage${location.search}&accId=${dataResObj?.accId}`);
            } else {
              yield put({ type: 'saveOrUpdateData', payload: { bBindState: 1 } });
            }
          }
        } else if (data.code === 1) {
          const errorText = formatMessage({ id: data.msg });
          Toast.fail(errorText);
          yield put({ type: 'saveOrUpdateData', payload: { bBindState: 0 } });
        }
      }
    },
  },

  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        if (pathname === '/wdfclient/init') {
          sessionStorage.setItem('tokenSn', getUrlParam('tokenSn'));
          sessionStorage.setItem('sign', getUrlParam('sign'));
          sessionStorage.setItem('token', getUrlParam('token'));

          sessionStorage.setItem('devMode', getUrlParam('devMode'));
          sessionStorage.setItem('appId', getUrlParam('appId'));
          // 获取使用需要解密
          sessionStorage.setItem('corpId', getUrlParam('corpId'));
          sessionStorage.setItem('state', getUrlParam('state'));
          sessionStorage.setItem('domain', getUrlParam('domainId'));
          sessionStorage.setItem('tempUrlDD', getUrlParam('tempUrl'));
          sessionStorage.setItem('hrWx', getUrlParam('hr'));
          sessionStorage.setItem('isFm', getUrlParam('isfm'));
          if (getUrlParam('lc')) {
            sessionStorage.setItem('lcDD', getUrlParam('lc'));
          } else {
            sessionStorage.removeItem('lcDD');
          }
          if (getUrlParam('devMode') === 1) {
            // 获取使用需要解密
            sessionStorage.setItem('appkey', getUrlParam('appkey'));
            // 获取使用需要解密
            sessionStorage.setItem('appsecret', getUrlParam('appsecret'));
          } else if (getUrlParam('devMode') === 2) {
            // 获取使用需要解密
            sessionStorage.setItem('corpSecret', getUrlParam('corpSecret'));
          }

          if(getUrlParam('scanFrom') === 'scanAuth'){
            dispatch({type: 'saveOrUpdateData', payload: {bBindState: 1}});
          }
        }
      });
    },
  },
};
