
import * as services from './service';

export default {
  namespace: 'metaSpaceMap',
  state: {
    color:{
      free: 'rgb(66,206,114)',
      freeTransparent: 0.8,
      busyTransparent: 0.8,
      ocuupy: "rgb(233,79,68)",
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getMapInfo({payload}, { call, put }) {
      const result = yield call(services.getMapInfo, payload);
      if (result.code === 0) {
        const { data } = result;
        const colors = data.colors;
        if(colors){
          yield put({ type: 'setState', payload: {
              color:{
                free:JSON.parse(colors).freeRgb,
                ocuupy:JSON.parse(colors).busyRgb,
                freeTransparent:JSON.parse(colors).freeTransparent||1,
                busyTransparent:JSON.parse(colors).busyTransparent||1,
              }
            } });
        }

      }
    },
    *getEleIdsByMapId({ mapId }, { call, put }) {
      const result = yield call(services.getEleIdsByMapId, mapId);
      if (result && result.code === 0) {
        const { data } = result;
        const eleIds = [];
        const visibleElement = [];
        data.forEach((v) => {
          if (v.objectBind) {
            visibleElement.push(v.mapElementId);
          }
          const obj = {
            id: v.mapElementId,
            state: v.objectSvgStateCode,
            color: v.objectColor,
            text: v.objectName,
            svgTypeCode: v.svgTypeCode,
            contentObj: {
              objectColor: v.objectColor,
              objectId: v.objectId,
              objectName: v.objectName,
              objectSvgStateCode: v.objectSvgStateCode,
            },
          };
          eleIds.push(obj);
        });
        yield put({
          type: 'setState',
          payload: {
            stationsOnMap: eleIds,
            stationsData: data.filter((v) => v.objectBind),
            visibleElement: visibleElement,
          },
        });
        return data || [];
      }
    },
  },
  subscriptions: {

  },
};
