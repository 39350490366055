export default {
  'home.timeLater': '{timeStr}後開始',
  'home.timeLater.new': 'days later',
  'home.timeLater.new2': 'start after',
  'home.timeStop': '{timeStr}後結束',
  'home.timeLater.new': '天后開始',
  'home.timeLater.new2': '後開始',
  'home.message.knowIt': '知道了',
  'home.message.refuse': '拒絕',
  'home.message.accept': '參加',
  'home.message.gotit': '操作成功',

  'home.index.reserved': '未開始',
  'home.index.processing': '進行中',
  'home.index.auditing': '審批中',
  'home.index.filter': '會議篩選',

  'home.audit.refuse': '拒絕',
  'home.audit.adopt': '通過',
  'home.audit.submit': '提交',
  'home.audit.refusal': '拒絕理由',
  'home.audit.emptyRefusal': '請輸入拒絕理由',
  'home.audit.noneAudit': '暫無審核工作',
  'home.audit.meetingMode': '重複頻率',
  'home.audit.meetingRange': '重複範圍',
  'home.audit.meetingMode.day': '按日',
  'home.audit.meetingMode.workday': '按工作日',
  'home.audit.meetingMode.week': '按周',
  'home.audit.meetingMode.month': '按月',
  'home.audit.meetingMode.year': '按年',
  'home.audit.meetingMode.normal': '不循環',
  'home.audit.meetingRange.forever': '無結束日期',
  'home.audit.meetingRange.repeat': '重複',
  'home.audit.meetingRange.time': '次',
  'home.audit.repeat.label': '循環',
  'home.audit.adopt.success': '審核通過',
  'home.audit.refuse.success': '操作成功',

  'home.index.history.label': '歷史會議',
  'home.index.new.label': '新會議',
  'home.index.message.label': '新消息',
  'home.index.audit.label': '審批會議',
  'home.index.unit.session': '場',
  'home.index.unit.pcs': '個',
  'home.index.unit.day': '天',
  'home.index.unit.days': '天',
  'home.index.unit.hours': '小時',
  'home.index.unit.hourss': '小時',
  'home.index.unit.minute': '分鐘',
  'home.index.unit.start': '後開始',
  'home.index.unit.end': '後結束',
  'home.index.unit.start_now': '即將開始',
  'home.index.unit.end_now': '即將結束',
  'home.index.unit.time.long': '時長',
  'home.index.meeting.status0': '未開始',
  'home.index.meeting.status1': '進行中',
  'home.index.meeting.status2': '已結束',
  'home.index.meeting.status3': '已取消',
  'home.index.meeting.status4': '已拒絕',
  'home.index.meeting.status5': '待審批',
  'home.index.meeting.status6': '無效',
  'home.index.meeting.status7': '處理中',
  'home.index.meeting.status8': '失敗',
  'home.index.meeting.status9': 'EX審批中',
  meeting_other_no_approved: '等待管理員處理',
  'home.index.none': '暫無會議',
  'home.index.none.info': '點擊「+」按鈕可預約會議',
  'home.history.none': '暫無歷史會議',
  'home.message.none': '暫無新消息',
  'home.loading': '加載中...',
  'home.activate': '鬆開立即刷新',
  'home.deactivate': '下拉可以刷新',
  'home.finish': '完成刷新',
  'home.myMeeting': '會議列表',
  'home.about': '關於',
  'home.booking': '預訂會議',
  'home.video': '視頻會議',
  'home.initiate': '發起會議',
  'home.room': '會議室',
  'home.segment.history': '已結束',
  'home.segment.pending': '待參加',
  'home.segment.mine': '我發佈',
  'home.none.meeting': '您沒有新的會議哦~',
  'home.none.history': '您沒有歷史會議哦~',
  'home.none.audit': '暫時沒有審核工作哦~',
  'home.text.book': '預訂會議室',

  'home.filter.my.meeting': '會議列表',
  'home.filter.appointment': '我預約的',
  'home.filter.attending': '我參會的',
  'home.filter.start.time': '開始時間',
  'home.filter.end.time': '結束時間',
  'home.search.placeholder': '請輸入會議主題搜索',

  'meeting.detail.refuse.tip': '是否拒絕當前會議？',
  'meeting.detail.close.tip': '是否結束當前會議？',
  'meeting.detail.confirm.cmma': '視頻會議仍在進行，請在會控頁面結束視頻會議',
  'meeting.detail.cancel.tip': '是否取消當前會議？',
  cancel_loop_meeting: '取消該系列的所有週期會議?',
  modify_loop_meeting: '修改該系列的所有週期會議?',
  refuse_loop_meeting: '拒絕該系列的所有週期會議?',
  adopt_loop_meeting: '通過該系列的所有週期會議?',
  cancel_current_cycle_meeting_confirm: '僅取消本場會議',
  cancel_cycle_meeting_confirm: '取消該系列的所有週期會議',
  edit_current_cycle_meeting_confirm: '僅編輯本場會議',
  edit_cycle_meeting_confirm: '編輯該系列的所有週期會議',

  'home.action.meeting.detail': '會議詳情',
  'home.action.meeting.service': '會議服務',
  'home.action.meeting.edit': '編輯會議',
  'home.action.meeting.sign': '簽到管理',
  'home.action.meeting.cancel': '取消會議',
  'home.action.meeting.delete': '删除會議',
  'home.action.meeting.delay.new': '延長會議(分鐘)',
  'home.action.meeting.delay': '延長會議',
  'home.action.meeting.end': '結束會議',
  'home.action.meeting.control': '會控管理',
  'home.action.meeting.restart': '再次發起',
  'home.action.meeting.reapply': '重新申請',
  'home.action.meeting.adopt': '審批通過',
  'home.action.meeting.refuse': '審批拒絕',
  'home.action.meeting.polling': '調查問卷',
  'home.action.meeting.start': '開啟會議',
  'home.action.meeting.join': '加入會議',
  'home.action.cancel': '取消',
  'home.action.meeting.sign.value1': '請輸入6位邀請碼',
  'home.action.meeting.sign.value2': '請輸入邀請碼',

  cancel_meeting_reason_placeholder: '請輸入取消理由（非必填）',
  refuse_meeting_reason_placeholder: '請輸入拒絕理由（必填）',
  'home.action.meeting.seat.manage': '座位安排',
  'home.action.meeting.seat.view': '座位安排',
  'home.action.meeting.net.join': '加入網絡會議',
  'home.close.cmma.sure': '是否同時釋放視屏會議資源？',
  'meeting.book.video': '視頻',
  'meeting.book.Webex': 'Webex',
  'meeting.book.Tecent': '騰訊會議',
  'meeting.book.ZOOM': 'ZOOM',
  'meeting.book.Teams': 'Teams',
  'meeting.book.Feishu': '飛書視頻會議',
  'meeting.list.column.duration': '召開時長(分)',
  'home.action.meeting.evaluation': '使用評價',
  'home.action.meeting.evaluation.show': '查看評價',
  'menu.meeting.svc_approval': '服務確認',
  tab_processed: '已處理',
  tab_pending: '待處理',
  btn_accepted: '已同意',
  btn_approved: '已通過',
  btn_rejected: '已拒絕',
  btn_reject: '拒絕',
  btn_accept: '同意',
  btn_daiding: '暫不確定是否接受',
  btn_accept_new: '已接受',
  svc_approval_error: '該條記錄已被其他人員審批，無法再次審批',
  'home.action.svc.approval': '服務審批',
  'home.action.svc.detail': '服務詳情',
  meeting_status_error: '當前會議已失效',
  201013: '當前申請已被處理！',
  status_error: '已失效',
  btn_accept_new_text: '您{text}會議邀請',
  btn_accept_new_text_accept: '您已接受會議邀請',
  btn_accept_new_text_reject: '您已拒絕會議邀請',
  btn_accept_new_text_daiding: '您暫不確定是否接受會議邀請',
  'home.index.acceptText.start': '，距會議開始還有{value}',
  'home.index.acceptText.start1': '距會議開始還有{value}',
  meeting_opened_action_tip: '會議已經開始，不允許編輯',
  'meeting.rowData.meetingAgendaNums': '{value}個議題',
  meeting_poly_cycle_edit_cancel: 'CloudMeet週期會議僅支持編輯和取消該系列未開始的第一場會議!',
  'meeting.poly.control.no.auth': '當前用戶無操作許可權',
};
