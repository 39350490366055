export default {
  403035: '当前处于非工作时间，不支持立即使用！',
  403036: '您没有权限使用该储物柜！',
  403038: '未到签到时间，无法签到！',
  'scan.signed.result.success.tip': '恭喜您，操作成功！',
  403040: '所选时间处于非工作时间，请选择其他时间进行预订！',
  403050: '用户{value1}在该组中已拥有共享储物柜，暂不支持使用两种储物柜!',
  403051: '用户{value1}在该组中已拥有固定储物柜，暂不支持使用两种储物柜！',
  403063: '您的储物柜不在当前{value1}柜，请移步至 {value2}柜，柜号为{value3} 寻找您的储物柜! '
};
