/**
 * Created by wang sen on 2017/7/6.
 */
import { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取绑定信息
 * @param payload
 */

export async function getWaferAppInfo(appId) {
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/wdf/getWaferAppInfo?appId=${appId}`);
}

export async function authWafer(params) {
  return requestNoAuthorization(`/${RESTFUL_PATH.wdf}/wdf/authWafer`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
