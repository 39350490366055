import moment from 'moment-timezone';
import { formatMessage } from 'umi';
import { systemList2Obj } from '@/pages/SmartLocker/utils/utils';
import { Toast } from 'antd-mobile-v2';
import * as service from '@/pages/SmartLocker/service';
import {searchPermissionByMap} from "@/pages/WorkSpace/utils/utils";

export default {
  namespace: 'locker',
  state: {
    scanLocker: {
      lockerNum: 'Lo92',
      locationName: '西安 西安软件园 零壹广场 1F',
      // time: moment().format(formatMessage({ id: 'sws.time.date' })),
      time: moment().format('YYYY-MM-DD'),
    },
    stationOccupyModal: false,
    stationOccupyData: [],
    spaceTree: [],
    departmentTree: [],
    userSearchList: [],
    systemSet: {},
    holiday: {},
    areaSelected: {},
    mapId: '',
    locationId: '',
    lockersOnMap: [],
    lockerInfoData: [],
    floorList: [],
    globalTime: {
      amStartTime: '',
      amEndTime: '',
      pmStartTime: '',
      pmEndTime: '',
    },
    btnRole: false,
    showPositionSelect: false,
    addressChoseList: [],
    addressChildrenList: null,
    locationSelected: '',
    scanStation: {},
    bookingList: [],
    eleIds: [],
    mapData: [],
    spacePerm: '',
    mapInfo: {},
    failReason: '',
    result: 'none',
    defaultLocation: {
      mapId: undefined,
      locationId: undefined,
    },
    pathNameArr: [],
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getSpaceTree(_, { call, put }) {
      const result = yield call(service.getSpaceTree);
      if (result && result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { spaceTree: result.data } });
      }
    },
    *getSpaceTreeAndMapId(_, { call, put }) {
      const result = yield call(service.getSpaceTreeAndMapId);
      if (result && result.code === 0) {
        const Arr = [];
        if (result.data.length > 0) {
          result.data.forEach((item) => {
            const obj = item;
            if (obj.timeZone === '') {
              obj.timeZone = 'Asia/Shanghai';
            }
            Arr.push(obj);
          });
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: { spaceTree: Arr },
        });
      }
    },
    *getHoliday(_, { call, put, select }) {
      let tenantId = 0;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        tenantId = info.tenant_id;
      }
      const result = yield call(service.getHoliday, tenantId);
      if (result && result.code === 0 && result.data) {
        const globalTime = {
          amStartTime: result.data.amStartTime,
          amEndTime: result.data.amEndTime,
          pmStartTime: result.data.pmStartTime,
          pmEndTime: result.data.pmEndTime,
        };
        yield put({ type: 'saveOrUpdateData', payload: { holiday: result.data, globalTime } });
      }
    },
    *getDepartmentTree(_, { call, put }) {
      const result = yield call(service.getDepartmentTree);
      if (result && result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { departmentTree: result.data } });
      }
    },
    *getSearchUsers({ payload }, { call, put }) {
      const result = yield call(service.getSearchUsers, payload);
      if (result && result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { userSearchList: result.data } });
      }
    },
    // 查询我的预订
    *getSystemSet({ callBack = () => {} }, { call, put }) {
      const result = yield call(service.getSystemParam);
      if (result && result.code === 0) {
        if (result.data && result.data.length > 0) {
          const systemSet = systemList2Obj(result.data);
          yield put({ type: 'saveOrUpdateData', payload: { systemSet } });
          if (callBack) {
            callBack(systemSet && systemSet.lockerManufacturer);
          }
        } else {
          Toast.fail(formatMessage({ id: 'sws.search.no.result' }));
        }
      }
    },
    *getEleIdsByMapId({ mapId }, { call, put }) {
      const result = yield call(service.getEleIdsByMapId, mapId);
      if (result && result.code === 0) {
        const { data } = result;
        // 过滤掉停用的素材
        let mapData =
          data?.filter((v) => {
            return !(v?.objectBind && (Number(v?.objectStatus) === 1 || Number(v?.objectStatus) === 2));
          }) || [];
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            mapData,
          },
        });
      } else if (result.code === 1 && result.msg && result.msg) {
        yield put({
          type: 'locker/errorMsg',
          msg: formatMessage({ id: result.msg }),
        });
      } else {
        yield put({ type: 'locker/errorMsg', msg: formatMessage({ id: 'sws.search.error' }) });
      }
    },
    // 查询位置下所有的储物柜
    *getLockersByLocationId({ payload }, { call, put }) {
      const result = yield call(service.getLockersByLocationId, payload);
      if (result && result.code === 0) {
        const { data } = result;
        const list = [];
        data.forEach((item) => {
          if (list.id !== item.lockerElementId) {
            list.push({
              id: item.lockerElementId,
              state: item.businessState,
              text: item.lockerNum,
              lockerNum: item.lockerNum,
              orgId: item.orgId,
            });
          }
        });
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            lockersOnMap: list,
            lockerInfoData: data,
          },
        });
      }
    },
    // 获取地图配置信息
    *getBaseMap({ mapId, callback = () => {} }, { call, put }) {
      const result = yield call(service.getBaseMap, { mapId });
      if (result && result.code === 0) {
        const mapInfo = result.data || {};
        callback(mapInfo);
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            mapInfo,
          },
        });
      }
    },
    *openDoor({ payload }, { call, put }) {
      // console.log('openDoor', payload)
      const result = yield call(service.openDoor, payload);
      Toast.hide();
      if (result && result.code === 0) {
        if (payload.type === 'myBooking') {
          Toast.success(formatMessage({ id: 'sws.open.success' }), 1);
        } else {
          yield put({ type: 'saveOrUpdateData', payload: { result: 'success' } });
        }
      } else if (result && result.code === 1) {
        // TODO 失败处理
        const failReason = formatMessage({ id: result.msg }, { ...result.data });
        if (payload.type === 'myBooking') {
          // Toast.fail(formatMessage({ id: 'sws.reserve.delay.success' }), 1);
          Toast.fail(formatMessage({ id: 'sws.open.error' }), 1);
        } else {
          yield put({ type: 'saveOrUpdateData', payload: { result: 'fail', failReason } });
        }
      }
      return result?.code;
    },
    *saveDataBytree({ tree }, { put }) {
      const { timeZone, id, name, pathName, mapId = 3 } = tree;
      console.log(tree,'tree')
      yield put({
        type: 'saveOrUpdateData',
        payload: {
          locationSelected: pathName,
          locationId: id,
          locationSelectedOne: name,
          mapId,
          timeZone: timeZone || 'Asia/Shanghai',
        },
      });
      yield put({ type: 'getBaseMap', mapId });
    },
    // 查询用户默认地图
    *getUserMap(_, { call, put, select }) {
      const { curDefaultZone } = yield select((state) => state.user);
      const { spaceTree } = yield select((state) => state.locker);
      if (curDefaultZone) {
        const locationId = curDefaultZone;
        const result = yield call(service.getDefaultMap, locationId);
        if (result && result.code === 0) {
          if (result.data && result.data?.mapId) {
            const { mapId, floorId } = result.data || {};
            yield put({
              type: 'saveOrUpdateData',
              payload: { defaultLocation: { mapId, locationId: floorId } },
            });
          } else {
            const searchData = searchPermissionByMap({ treeData:  spaceTree  });
            if(searchData?.mapId){
              const { mapId, id: floorId } = searchData;
              yield put({
                type: 'saveOrUpdateData',
                payload: { defaultLocation: { mapId, locationId: floorId } },
              });
            } else {
              yield put({
                type: 'saveOrUpdateData',
                payload: { defaultLocation: {} },
              });
            }
          }
          // console.log('getUserMap', result.data)
        }
      } else {
        const searchData = searchPermissionByMap({ treeData:  spaceTree  });

        if(searchData?.mapId){
          const { mapId, id: floorId } = searchData;
          yield put({
            type: 'saveOrUpdateData',
            payload: { defaultLocation: { mapId, locationId: floorId } },
          });
        }
      }
    },
    *savePathNameArr({ pathname }, { put, select }) {
      const { pathNameArr } = yield select((state) => state.swsCommon);
      if (pathNameArr.length >= 5) {
        pathNameArr.shift();
      }
      pathNameArr.push(pathname);

      yield put({
        type: 'saveOrUpdateData',
        payload: {
          pathNameArr,
        },
      });
    },
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        dispatch({ type: 'savePathNameArr', pathname });
      });
    },
  },
};
