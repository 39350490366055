export default {
  'sws.inventorylist.inventorytitle.confim': 'Pending',
  'sws.inventorylist.inventorytitle.wait': 'To be confirmed',
  'sws.inventorylist.inventorytitle.done': 'Processed',
  'sws.inventorylist.stateTitle.yes': 'Normal',
  'sws.inventorylist.stateTitle.no': 'terminated',
  'sws.inventorylist.taskStatusTitle.will': 'Not started',
  'sws.inventorylist.taskStatusTitle.doning': 'In progress',
  'sws.inventorylist.taskStatusTitle.done': 'Ended',
  'sws.inventorylist.contant.time': 'Task Date',
  'sws.inventorylist.contant.name': 'Task name',
  'sws.inventorylist.contant.task': 'Task Status',
  'sws.inventorylist.contant.tasktime': 'Inventory time',
  'sws.inventorylist.content.placeholder': 'Manual input at here',
};
