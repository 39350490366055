export default {
  'points.account.overage.name': '賬戶餘額({unit})',
  'points.account.overage.credits': '信用額度',
  'points.account.overage.lockIn': '鎖定額度',

  'points.bill.orderStatus.title': '賬單分類',
  'points.bill.orderStatus.income': '收入',
  'points.bill.orderStatus.expenditure': '支出',
  'points.bill.modal.confirm': '確定',
  'points.bill.modal.cancel': '取消',

  'points.billDetail.serial.number': '流水號',
  'points.billDetail.state': '狀態',
  'points.billDetail.consumer.users': '消費用戶',
  'points.billDetail.orderStatus': '賬單分類',
  'points.billDetail.transaction.hour': '交易時間',
  'points.billDetail.principal': '負責人',
  'points.billDetail.remarks': '備註',
  'points.billDetail.remarks.none': '無',
  'points.billDetail.consumption.username': '預訂人',
  'points.billDetail.consumption.number': '預定對象',
  'points.billDetail.consumption.startTime': '開始時間',
  'points.billDetail.consumption.endTime': '結束時間',
  'points.billDetail.orderStatus.0': '充值',
  'points.billDetail.orderStatus.1': '消費',
  'points.billDetail.orderStatus.2': '獎勵',
  'points.billDetail.orderStatus.3': '懲罰',
  'points.billDetail.orderStatus.4': '退款',
  'points.billDetail.orderStatus.all': '全部',
  'points.billDetail.noData': '暫無數據',
  'points.billDetail.state.0': '已鎖定',
  'points.billDetail.state.1': '已完成',
  'points.billDetail.state.2': '已取消',

  'points.punishment.new.btn.title': '新建罰單',
  'points.punishment.myList.btn.title': '罰單記錄',
  'points.punishment.new.record.violation': '違規項',
  'points.punishment.new.record.violation.dept': '違規部門',
  'points.punishment.new.record.violation.userName': '負責人',
  'points.punishment.new.record.violation.checkTime': '檢查時間',
  'points.punishment.new.record.violation.upload': '上傳照片（選填）',
  'points.punishment.new.record.violation.upload.tip':
    '圖片格式可以為jpeg / jpg / png, 大小不超過500K',
  'points.punishment.new.record.violation.remark': ' 文本備註（選填）',
  'points.punishment.new.record.submit': '提交罰單',
  'points.punishment.state.0': '待確認',
  'points.punishment.state.1': '已確認',
  'points.punishment.state.2': '已取消',
  'points.punishment.state.3': '已駁回',
  'points.punishment.record.account': '關聯賬戶: ',
  'points.punishment.record.checkTime': '檢查時間: ',
  'points.punishment.record.cancelPunishment': '取消罰單',
  'points.punishment.record.resetPunishment': '重新發起',
  'points.punishment.record.punishmentDetail': '罰單詳情',
  'points.punishment.record.cancel': '取消',
  'points.punishment.record.detail.back': '返回列表',
  'points.punishment.new.record.select': '請選擇',
  'points.punishment.new.record.add.success': '操作成功',
  'points.punishment.new.record.add.error': '操作失敗',
  'points.punishment.new.record.add.rule': '違規項或者違規部門不能為空',
  'points.punishment.new.record.cancel.success': '取消成功',

  107001: '規則編號已存在',
  107002: '可用餘額不足',
  107003: '賬戶類型配置無法識別',
  107004: '賬戶未找到',
  107005: '業務標識未識別',
  107006: '鎖定金額失敗',
  107007: '充值金額失敗',
  107008: '取消訂單失敗',
  107009: '訂單不存在',
  107010: '訂單修改失敗',
  107011: '操作失敗',
  107012: '參數錯誤',
  107013: '部門管理員用戶不存在',
  107014: '罰單已處理',
  107015: '信用額度修改失敗',
  107016: '該部門存在自動充值配置',
  107017: '系統未配置域名，請聯繫管理員',

  'points.year': '年',
  'points.month': '月',
  'points.day': '日',
  'points.hour': '時',
  'points.min': '分',

  'points.product.exchange': '商品兌換',
  'points.product.exchange1': '兌換',
  'points.product.exchange.total': '總計：',
  'points.product.exchange.success': '兌換成功',
  'points.product.exchange.points.current': '您當前的積分爲',
  'points.product.exchange.points.unit': '分',
  'points.product.exchange.points.unit1': '積分',
  'points.product.exchange.points.price': '單價',
  'points.product.exchange.points.detail': '積分明細',
  'points.product.exchange.points.notenough': '積分不足',
  'points.product.exchange.points.enough': '立即兌換',
  'points.product.exchange.sure': '我能兌換',
  'points.product.exchange.back': '返回列表',
  'points.details.view': '查看明細',
  'points.header.main': '主體負責人',
  'points.rewards': '獎勵的積分',
  'points.used': '使用的積分',
};
