import _ from 'lodash';
import request, { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export function get(url, payload, options) {
  if (!_.isEmpty(payload)) {
    let newUrl = url.indexOf('?') >= 0 ? `${url}&` : `${url}?`;
    _.forIn(payload, (value, key) => {
      newUrl = `${newUrl}${key}=${value}&`;
      return newUrl;
    });
    // eslint-disable-next-line no-param-reassign
    url = newUrl.substring(0, newUrl.length - 1);
  }
  return request(url, { ...options, method: 'GET' });
}

export function post(url, payload, options) {
  return request(url, { ...options, method: 'POST', body: JSON.stringify(payload) });
}

// 登录
export const login = ({ employeeId, employeeSecret, deviceType, macAddress }) => {
  if (deviceType === 0 && macAddress) {
    return request(`/${RESTFUL_PATH.visitor}/api/auth/terminal`, {
      method: 'POST',
      body: JSON.stringify({
        username: employeeId,
        password: employeeSecret,
        deviceType,
        macAddress,
      }),
    });
  }
  return request(`/${RESTFUL_PATH.visitor}/api/auth/app`, {
    method: 'POST',
    body: JSON.stringify({ username: employeeId, password: employeeSecret }),
  });
};
// 注销
export const loginOut = ({ macAddress, username }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/auth/terminal/logout`, {
    method: 'POST',
    body: JSON.stringify({ macAddress, username }),
  });
};
// virsical  Pc验证
// eslint-disable-next-line camelcase
export const jump = ({ token, access_token }) => {
  // eslint-disable-next-line camelcase
  return request(`/vclient/oauth/me?token=${token}&access_token=${access_token}&flag=0`);
};
export const getNoticeType = ({ companyId, noticeType }) => {
  return request(
    `/${RESTFUL_PATH.visitor}/api/config/notifyType?companyId=${companyId}&noticeType=${noticeType}`
  );
};
export const editNoticeType = ({ companyId, flag, noticeType }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/config/notifyType`, {
    method: 'POST',
    body: JSON.stringify({ companyId, noticeType, flag }),
  });
};
export const getEmployee = ({ userid }) => {
  return request(`/${RESTFUL_PATH.visitor}/api/employees/userInfo`, {
    method: 'POST',
    body: JSON.stringify({ employeeId: userid }),
  });
};

// 修改密码
export const changePassWord = userInfo => {
  const url = '';
  const postData = {
    domain: userInfo.domain,
    pass: userInfo.oldPwd,
    newPass: userInfo.newPwd,
    token: userInfo.token,
  };
  // 密码重置
  // let postData = {'domain': 'public',pass:'Wafer!123',newPass:'wafer',token:'E0E094459B4826CB3E29FE1959D2F17A410917D4570BF8672F8DB5EBA77C547F92F03C4FD55EB80753D6B1F79ABC60CB'}
  return request(url, { method: 'POST', body: JSON.stringify(postData) });
};

/**
 * 登录平台
 * @param payload
 */
export const portal = payload => {
  return requestNoAuthorization(
    `/${RESTFUL_PATH.visitor}/api/employees/addr?employeeId=${payload.employeeId}`
  );
};

// export const get${RESTFUL_PATH.visitor}Config = () => {
//   if (process.env.NODE_ENV === 'production') {
//     return request(`/vst/virsical/config/config.json`); // 打包时切换此地址
//   }
//   return request(`../../config/config.json`);
// };

/* 微信免登录认证 */
export const weChatSSO = payload => {
  return requestNoAuthorization(`/${RESTFUL_PATH.visitor}/api/auth/getLoginInfoByToken`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      token: payload.token,
    },
  });
};

// 查询客户购买的产品版本
export const getVisitorProduct = ({ authorization }) => {
  return request('/coms/customers/product/version/vst', {
    headers: {
      Authorization: authorization,
    },
  });
};
