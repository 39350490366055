export default {
  'tcs.title': 'Cubicle Occupied State',
  'tcs.free': 'leisure',
  'tcs.free.unit': 'Entries',
  'tcs.build.all': 'All buildings',
  'tcs.all': 'All floors',
  'tcs.toilet.show': 'Show toilet room',
  'tcs.build': 'Building',
  'tcs.confirm': 'Confirm',
  'tcs.cancel': 'Cancel',
  'tcs.status.0': 'Free',
  'tcs.status.1': 'Occupied',
  'tcs.status.2': 'Repair',
  'tcs.status.3': 'Fault',
  'tcs.status.-1': 'Offline',

  'tcs.alarm.config.alarm.receiver.type.device.short': 'Failure',
  'tcs.alarm.config.alarm.receiver.type.baojie.short': 'There is a large flow of people',
  'tcs.alarm.config.alarm.receiver.type.timeout.short': 'Occupancy Timeout',
  'tcs.alarm.config.alarm.receiver.type.consumable.short': 'Toilet Paper Absence',
  'tcs.alarm.config.alarm.receiver.type.odor.short': 'Environment Discomfort',
  'tcs.alarm.config.alarm.receiver.type.waterLevel.short': 'Hand Sanitizer Absence',

  'tcs.alarm.config.alarm.receiver.type.device': 'Device Failure Alarm',
  'tcs.alarm.config.alarm.receiver.type.baojie': 'Pedestrian Traffic Alarm',
  'tcs.alarm.config.alarm.receiver.type.timeout': 'Timeout Occupancy Alarm',
  'tcs.alarm.config.alarm.receiver.type.consumable': 'Toilet Paper Surplus Alarm',
  'tcs.alarm.config.alarm.receiver.type.odor': 'Environment Alarm',
  'tcs.alarm.config.alarm.receiver.type.waterLevel': 'Hand Sanitizer Remaining Alarm',

  'tcs.alarm.list.search.time': 'Time Range',
  'tcs.alarm.list.search.alarm.type': 'Alarm Type',
  'tcs.alarm.list.search.alarm.status': 'Alarm Status',
  'tcs.alarm.list.table.alarm.time': 'Alarm Time',
  'tcs.alarm.list.table.alarm.rank': 'Alarm Level',
  'tcs.alarm.list.table.alarm.content': 'Alarm Content',
  'tcs.alarm.list.table.alarm.address': 'Alarm Location',
  'tcs.alarm.list.table.alarm.receiver': 'Alarm Receiver',
  'tcs.alarm.list.table.alarm.status': 'Status',
  'tcs.alarm.list.table.alarm.status.0': 'Unprocessed',
  'tcs.alarm.list.table.alarm.status.1': 'Processed',
  'tcs.alarm.list.table.alarm.status.2': 'Cancelled',
  'tcs.alarm.list.table.alarm.rank.1': 'Alert',
  'tcs.alarm.list.table.alarm.rank.2': 'Urgent',
  'tcs.alarm.list.table.alarm.status.tip': 'Are you sure you have finished processing this alert? ',
  'tcs.alarm.list.nodata': 'There\'s no more',
  'tcs.alarm.list.handler.person': 'Processor',
  'tcs.alarm.list.handler.time': 'Processing Time',
};
