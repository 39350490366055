export default {
  'menu.app.index.space': 'Space Management',
  'menu.app.index.map.sub': 'Space Map',
  'index.map.click.search.placeholder': 'Search',
  'index.map.search.station.placeholder': 'Please enter your search keywords',
  'index.map.btn.cancel': 'Cancel',
  'index.map.searchList.noData': 'No data',
  104018: 'The target area is offline and cannot be jumped',

  'index.map.select.time.range': 'Time range:',
  'map.color.setting.material.status.1': 'Free',
  'map.color.setting.material.status.2': 'Occupied',
  'map.color.setting.material.status.3': 'Disabled',

  'index.map.space.local': 'local area',
  'index.map.space.share': 'Shared area',

  'index.map.space.setting.default.space': 'Default Locale',
  'index.map.space.setting.default.placeholder': 'Go to Setting',
  'index.map.btn.back': 'Return',
  'index.map.space.setting.privacy': 'Privacy Settings',
  'index.map.space.setting.privacy.placeholder': 'Home map allows me to be searched',
};
