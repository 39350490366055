export default {
  'sws.delay.hours': '小时',
  'sws.delay.pc.title': '选择延时',
  'sws.delay.pc.station': '工位：',
  'sws.delay.pc.address': '地址：',
  'sws.delay.pc.starttime': '开始时间：',
  'sws.delay.pc.endtime': '结束时间：',
  'sws.delay.pc.delaytime': '延长时间：',
  'sws.delay.submit': '提交',
  'sws.delay.mobile.station': '工位',
  'sws.delay.mobile.address': '地址',
  'sws.delay.mobile.starttime': '开始时间',
  'sws.delay.mobile.endtime': '结束时间',
  'sws.delay.mobile.delaytime': '延长时间',
  'sws.delay.ok': '确认',
  'sws.delay.cancel': '取消',
  'sws.delay.select.delay': '选择延长时间',
  'sws.delay.input.delay': '输入延长时间',
  'sws.delay.input.delay.unit': '单位：小时',
  'sws.delay.submit.success': '延长成功',
  'sws.delay.submit.error': '提交失败',
  'sws.delay.hour.error': '延时最大限制为{hour}小时',
  'sws.delay.hour.null': '请输入需要延长的时间',
};
