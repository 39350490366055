/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';
import {getMapEleListLang} from "@/pages/IndexMap/utils/utils";

/**
 * 根据空间id查询地图底图
 *
 * @param payload 请求参数体
 */
export async function getMapIndex({ spaceId }) {
  return request(`/${RESTFUL_PATH.map}/map/index?spaceId=${spaceId}`);
}

/**
 * 模糊搜索地图元素
 *
 * @param payload 请求参数体
 */
export async function searchMapInfo({ key, svgTypeCode, spaceId, current, size }) {
  let queryParameters = `current=${!current ? 1 : current}&size=${size || 10}`;

  if (key) {
    queryParameters = `${queryParameters}&key=${encodeURIComponent(key)}`;
  }
  if (svgTypeCode && svgTypeCode !== '-1') {
    queryParameters = `${queryParameters}&svgTypeCode=${svgTypeCode}`;
  }
  if (spaceId) {
    queryParameters = `${queryParameters}&spaceId=${spaceId}`;
  }
  return request(`/${RESTFUL_PATH.map}/map/search?${queryParameters}`);
}

/**
 * 获取空间树
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getSpaceTree() {
  let queryParameters = '';
  const product = sessionStorage.getItem('p');
  if (product) {
    queryParameters = `&p=${product}`;
  }
  return request(`/${RESTFUL_PATH.map}/map/space-tree?permission=app@common@index_map${queryParameters}`, {
    method: 'GET',
  });
}

/**
 * 查询对应mapId下的所有元素
 *
 * @param mapId 地图Id
 */
export async function getEleIdsByMapId(mapId) {
  let queryParameters = '';
  const product = sessionStorage.getItem('p');
  if (product) {
    queryParameters = `&p=${product}`;
  }
  const url = `/${RESTFUL_PATH.map}/map-element/list?mapId=${mapId}&permission=app@common@index_map${queryParameters}&queryPath=0&lang=${getMapEleListLang()}`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 获取素材列表
 *
 */
export async function getSvgType() {
  return request(`/${RESTFUL_PATH.map}/svg-type/list?`, {
    method: 'GET',
  });
}

/**
 * 获取地图上的产品数据
 *
 */
export async function getMapElementDataByPro(payload) {
  return request(`${payload.url}?startTime=${payload.startTime}&endTime=${payload.endTime}&spaceId=${payload.spaceId}`, {
    method: 'GET',
  });
}


/**
 * 获取空间树
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getOperationSpaceTree() {
  return request(`/${RESTFUL_PATH.map}/map/operation/space-tree`, {
    method: 'GET',
  });
}
