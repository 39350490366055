import request, { requestNoAuthorization } from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export function sendMailCode(data) {
  if (data.email) {
    // eslint-disable-next-line no-param-reassign
    data.email = data.email.toLowerCase();
  }
  return request(`/${RESTFUL_PATH.cloudOms}/users/send/captcha`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export const thirdRegister = (payload) => {
  return request(`/${RESTFUL_PATH.cloudOms}/third/register/teams`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const thirdBind = (payload) => {
  return request(`/${RESTFUL_PATH.upms}/teams/user/bind`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const feishuBind = (payload) => {
  return request(`/${RESTFUL_PATH.wdf}/feishu/user/bind`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const cloudLogin = ({ username, password, grantType, scope, Authorization, code, randomStr }) => {
  const data = {
    username,
    password,
    grant_type: grantType,
    scope,
  };
  const form = new FormData();
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const k in data) {
    form.append(k, data[k]);
  }
  if (code) form.append('code', code);
  if (randomStr) form.append('randomStr', randomStr);
  return requestNoAuthorization(`/${RESTFUL_PATH.auth}/oauth/token`, {
    method: 'POST',
    headers: {
      Authorization,
      'vsk-auth': Authorization,
    },
    data: form,
  });
};

export const checkIsExist = (payload) => {
  return request(`/${RESTFUL_PATH.upms}/user/checkIsExist`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

// code注册
export const codeRegister = (data) => {
  const { payload, entrySource } = data;
  return request(`/${RESTFUL_PATH.cloudOms}/third/register/code/${entrySource}`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export function commonInitData() {
  return request(`/${RESTFUL_PATH.upms}/common/init/data`);
}

// 双因素验证
export async function securityCodeCheck({ payload }) {
  return request(
    `/${RESTFUL_PATH.auth}/token/securityCodeCheck?securityCode=${
      payload.securityCode
    }&flag=${encodeURIComponent(payload.flag)}`,
  );
}

// 获取手机区号
export function getAreaCode() {
  return request(`/${RESTFUL_PATH.upms}/common/area-code`);
}
