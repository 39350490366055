export default {
  'sws.inventory.title': '工位盤點',
  'sws.inventory.detail.userName': '員工姓名',
  'sws.inventory.detail.userNum': '員工賬號',
  'sws.inventory.detail.deptName': '員工部門',
  'sws.inventory.station.stationNum': '手動輸入工位賬號',
  'sws.inventory.station.scanStationNum': '掃碼識別工位編號',
  'sws.inventory.station.remarks': '備註：工位編號如有變化，請在右側輸入或掃碼更改',
  'sws.inventory.station.location': '工位位置',
  'sws.inventory.station.ok': '確認',
  'sws.inventory.detail.empty': '當前無需工位盤點～',
  'sws.inventory.qrCode.error': '工位二維碼信息不正確',
  401701: '企業微信開關沒有打開',
  401702: '釘釘開關沒有打開',
  401703: '沒有可以盤點的工位',
  401704: '有未盤點的工位，不能發起盤點',
  401705: '未配置盤點有效期',
  401706: '盤點的工位沒有員工，不能盤點',
  401707: '工位用戶超過三個，盤點確認失敗',
  401708: '盤點員工信息異常',
  401709: '用戶部門和工位部門不一致，盤點失敗',
  401710: '盤點的工位不是固定工位',
  401711: '盤點的工位非空間節點上線狀態',
  401712: '用戶不能綁定該工位',
  401717: '用戶{value1}，沒有權限綁定到{value2}部門工位上',
  401719: '用戶綁定的工位數量超過限制',
  401858: '該工位已綁定多使用者，請先行解綁再調整類型',
  401014: '該工位使用人數已經達到最大限制',
  401861: '您暫無該工位的使用權，如有問題請聯繫屬地樓長',
};
