export default {
  map_room_detail_basic_title: '基本信息',
  map_room_detail_book_title: '我的預訂',
  map_room_detail_book_btn: '預訂會議',
  map_room_detail_go_btn: '去這裡',
  map_room_detail_view_btn: '查看位置',
  map_room_detail_more_btn: '更多信息 >',
  'meeting.calendar.need_audit_map': '審批',
  map_index_meeting_tab_meeting_list_title: '預訂記錄',
  'map_indexbooking.meeting.romm.Authority': '你無權使用該會議室,請選擇新的會議室！',
};
