import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 特殊预订
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function synReservation(payload) {
  return request(`/${RESTFUL_PATH.sws}/api/reserve/special`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 * 查询对应mapId下的所有元素
 *
 * @param mapId 地图Id
 */
export async function getEleIdsByMapId(mapId) {
  const url = `/${RESTFUL_PATH.map}/map-element/list?mapId=${mapId}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 特殊预订取消预订界面信息查询
 * @param uuid
 */
export async function specialCancel(uuid) {
  const url = `/${RESTFUL_PATH.sws}/api/web/special-cancel-info?uuid=${uuid}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 取消特殊预订
 * @param payload 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function cancelSpecialReservation(payload) {
  const url = `/${RESTFUL_PATH.sws}/api/web/cancel-special-all?uuid=${payload.uuid}&stationId=${payload.stationId}&rId=${payload.rId}`;
  return request(url, {
    method: 'GET',
  });
}
// /**
//  * 旧版本新增或者更新外部预定人员
//  * @param payload
//  * @returns {Promise<questMethodAddToUrl>}
//  */
// export async function editExternalUser(payload) {
//   return request(`/${RESTFUL_PATH.sws}/api/externalUser/insertOrEdit`, {
//     method: 'POST',
//     body: JSON.stringify(payload),
//   });
// }
/**
 * 特殊预约代订查询外部用户信息列表
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getAllExternalList() {
  const url = `/${RESTFUL_PATH.sws}/api/externalUser/queryAll`;
  return request(url, {
    method: 'GET',
  });
}

/**
 * 新增或者更新外部预定人员
 * @param payload
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function updateExternalList({updateExternalList}) {
  return request(`/${RESTFUL_PATH.sws}/api/externalUser/batchSaveOrUpdate`, {
    method: 'POST',
    body: JSON.stringify(updateExternalList),
  });
}
// /**
//  * 旧版本根据外部用户id查询外部用户信息
//  *
//  * @param payload 外部用户id
//  */
// export async function queryByExternalUserId(payload) {
//   const url = `/${RESTFUL_PATH.sws}/api/externalUser/queryByExternalUserId?userId=${payload.userId}`;
//   return request(url, {
//     method: 'GET',
//   });
// }
/**
 * 删除外部人员
 * @param id
 */
export const deleteExternal = ({ id }) => {
  return request(`/${RESTFUL_PATH.sws}/api/externalUser/delete/${id}`, {
    method: 'POST',
  });
};
