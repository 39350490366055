/**
 * Created : fangkemeng
 * Date : 2019-10-12  10:00
 * Email : fangkemeng@wafersystems.com
 */
import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';
import * as myBookingService from './service';

export default {
  namespace: 'homeNewBooking',
  state: {
    linkUrl: '',
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 新人指引url
    *getTrinaSolarLinkUrl(_, { call, put }) {
      const result = yield call(myBookingService.getTrinaSolarLinkUrl);
      if (result.code === 0) {
        yield put({ type: 'saveOrUpdateData', payload: { linkUrl: result?.data?.guideIndex||'' } });
      } else {
        Toast.fail.error(result.msg);
      }
    },
  },
  subscriptions: {},
};
