/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'myInfo.my.code': '我的二維碼',
  'myInfo.face.lib': '人臉庫',
  'myInfo.edit.password': '修改密碼',
  'myInfo.edit.phone': '修改手機號',
  'myInfo.version.num': '版本號',
  'myInfo.version.user': '用戶信息',
  'myInfo.setting.title': '設置',
  'myInfo.setting.scan': '掃一掃',
  'myInfo.setting.feedback': '意見反饋',
  'myInfo.setting.info': '查看用戶信息',
  'myInfo.my.face': '人臉識別資料',
};
