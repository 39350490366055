export default {
  'meeting.detail.name': '會議主題',
  'meeting.detail.time': '會議時間',
  'meeting.detail.room': '會議地點',
  'meeting.detail.holder': '會議主持人',
  'meeting.detail.member': '參會人',
  'meeting.detail.external': '外部參會人',
  'meeting.detail.memberCnt': '共{num}人',
  'meeting.detail.method': '提醒方式',
  'meeting.detail.remark': '備註',
  'meeting.detail.welcome': '歡迎詞',
  'meeting.detail.audit': '審核狀態',
  'meeting.detail.auditRemark': '拒絕理由',
  'meeting.detail.attach': '附件',
  'meeting.detail.none': '無',
  'meeting.detail.mailText': '郵件',
  'meeting.detail.rejected': '已拒絕',
  'meeting.detail.webexTitle': 'WebEx會議',
  'meeting.detail.btn.backToList': '返回列表',
  'meeting.detail.btn.cancel': '取消會議',
  'meeting.detail.btn.edit': '編輯會議',
  'meeting.detail.btn.save': '保存為會議模板',
  'meeting.detail.btn.restart': '發起會議',
  'meeting.detail.btn.delay': '延長',
  'meeting.detail.btn.close': '結束',
  'meeting.detail.btn.control': '會控',
  'meeting.detail.btn.join': '加入',
  'meeting.detail.service': '服務类别',
  'meeting.detail.no.service': '無服務',
  'meeting.detail.no.service.placeholder': '未選擇',
  'meeting.detail.sign': '簽到記錄',
  'meeting.detail.secret': '會議保密',
  'meeting.detail.secret_yes': '是',
  'meeting.detail.secret_no': '否',
  'meeting.detail.allUser': '全員會議',
  'meeting.detail.allUser_yes': '是',
  'meeting.detail.allUser_no': '否',
  'meeting.detail.allUser.pls': '全員',
  'meeting.detail.close.tip': '是否結束當前會議？',
  'meeting.detail.confirm.cmma': '視頻會議仍在進行，請在會控頁面結束視頻會議',
  'meeting.detail.cancel.tip': '是否取消當前會議？',
  cancel_loop_meeting: '取消該系列的所有週期會議?',
  modify_loop_meeting: '修改該系列的所有週期會議?',
  'meeting.detail.agenda': '會議議程',
  'meeting.detail.video': '直錄播',
  'meeting.detail.look': '查看',
  'meeting.detail.number': '會議號',
  'meeting.detail.attender.receipt': '參會回執',
  'meeting.detail.and': ',',
  'meeting.detail.accept': '人同意',
  'meeting.detail.refuse': '人拒絕',
  'meeting.detail.undo': '人未處理',
  'meeting.static.accept': '同意',
  'meeting.static.accept.tip': '同意的回執',
  'meeting.static.refuse': '拒絕',
  'meeting.static.refuse.tip': '拒絕的回執',
  'meeting.static.undo': '未處理',
  'meeting.static.undo.tip': '未處理的回執',
  'meeting.detail.back': '返回上一頁',
  'meeting.attendee.page.page.value1': '該部門下沒有任何員工！',
  'meeting.detail.webex.email.empty': 'Webex參會人郵箱不能為空！',

  'detail.meeting.meetingMode': '循環模式',
  'detail.loop.everyworkday': '每個工作日',
  'detail.loop.everyday': '每 {day} 天',
  'detail.loop.everyMonth': '每月 {day} 號',
  'detail.loop.everyMonthDay': '第',
  'detail.loop.every': '每',
  'detail.loop.mon': '周一',
  'detail.loop.thu': '周二',
  'detail.loop.wed': '周三',
  'detail.loop.thr': '周四',
  'detail.loop.fri': '周五',
  'detail.loop.sat': '周六',
  'detail.loop.sun': '周日',
  'meeting.status.invalid': '无效时间占用',

  'meeting.detail.zoomTitle': 'Zoom會議',
  'meeting.detail.zoomRoomTitle': 'Zoom Rooms會議',
  'meeting.detail.btn.start': '開啟',

  'meeting.detail.form.cancel.reason': '取消理由',
  meeting_detail_zoom_meeting_id: '會議ID',
  meeting_detail_zoom_join_password: '參會密碼',
  meeting_detail_zoom_join_url: '加會鏈接',
  meeting_detail_zoom_copy_url: '點擊複製鏈接',
  meeting_detail_zoom_copy_success: '加會鏈接已被復制',
  meeting_detail_webex_meeting_id: '會議ID',
  meeting_detail_webex_join_password: '參會密碼',
  meeting_detail_webex_join_url: '加會鏈接',
  meeting_detail_webex_copy_url: '點擊複製鏈接',
  meeting_detail_webex_copy_success: '加會鏈接已被復制',
  meeting_detail_copy_success: '已復制',
  'meeting.detail.qr': '開門二維碼',
  'meeting.detail.qr.info': '用此二維碼對準讀頭開門',

  'meeting.detail.tencent.title': '騰訊會議',
  'meeting.detail.wechat.title': '企微會議',
  'meeting.detail.teams.title': 'Teams會議',
  'meeting.detail.flybook.title': '飛書會議',
  'meeting.detail.dingding.title': '釘釘會議',
  'meeting.detail.huichang.title': '暢雲會議',
  'meeting.detail.quanshi.title': '全時會議',
  'meeting.detail.net': '請點擊加入網絡會議',
  'meeting.cancel.user': '取消人',
  'meeting.cancel.type0': '自動取消',
  'meeting.cancel.type1': '用戶取消',
  'meeting.cancel.type2': '未簽到取消',
  'meeting.cancel.type3': '未審批取消',
  'meeting.cancel.type4': 'Exchange/O356取消',
  'meeting.cancel.type5': '協力廠商取消',
  'meeting.cancel.type6': '訪客端取消',
  'meeting.cancel.log': '取消記錄',
  'meeting.Agenda.title': '議題',
  'meeting.Agenda.time': '時間',
  'meeting.detail.memberCnt.agenda': '共{num}人',
  main_tip: '提示',
  main_tip_content:
    '很抱歉!目前無法從企業微信中直接跳轉，請複制列表中的teams會議鏈接到瀏覽器中參加會議。',
  'meeting.visit.outuser': '訪客',

  'meeting.detail.video.password.tips': '請使用密碼【{value}】入會',

  meeting_sign_status_value0: '已回復參會',
  meeting_sign_status_value1: '已回復不參會',
  meeting_sign_status_value2: '已回復待定',
  meeting_sign_status0: '參會',
  meeting_sign_status1: '不參會',
  meeting_sign_status2: '待定',
  meeting_sign_modal_title: '您是否參加會議？',
  'meeting.detail.creater.name': '預訂人',
  'meeting.detail.creater.time': '預訂時間',
  'meeting.detail.item.other': '其他',
  'meeting.info.item.welcome': '歡迎詞',
  'meeting.info.item.remark': '備註',
  'meeting.info.item.service': '服務',
  'meeting.info.item.device': '設備',
  'meeting.info.item.agenda': '議程',
  'meeting.info.item.cycle': '循環',
  'meeting.info.title.service': '會議服務',
  'meeting.info.title.device': '移動設備',
  'meeting.info.item.welcome.tip': '暫時沒有會議歡迎詞',
  'meeting.info.item.remark.tip': '暫時沒有備註信息',
  'meeting.info.item.service.tip': '暫時沒有會議服務',
  'meeting.info.item.device.tip': '暫時沒有會議設備',
  'meeting.info.item.agenda.tip': '暫時沒有會議議程',
  'meeting.info.item.cycle.tip': '不循環',
  'meeting.info.item.questionnaire.tip': '暫時沒有調查結果',
  'meeting.info.start.time.tip': '距会议开始还有',
  'meeting.info.sign.time.before': '會議開始前{value}分鐘至',
  'meeting.info.sign.time.after': '會議開始後{value}分鐘內簽到',
  'meeting.info.sign.time.startNow.after': '会议开始后{value}分钟内签到',
  'meeting.info.sign.time.after.before': '會議開始前{value}分鐘至會議開始後{value}分鐘內簽到',
  'meeting.info.host.modal.title': '會議主持人',
  meeting_sign_status_value_null: '未回復',
  meeting_sign_modal_title_refuse: '不參會原因',
  meeting_sign_modal_title_refuse_tips: '請輸入不參會原因（選填）',
  'meeting.list.column.attend': '邀請參會人數',
  'meeting.list.column.actual.attend': '實際參會人數',
  'meeting.list.column.actual.attend.gotit': '我知道了',
  'meeting.detail.release.time': '釋放時間',
  'meeting.cancel.type9': '因會議室維護被取消',
  'meeting.cancel.type10': '因會議室停用被取消',
  'meeting.detail.form.cancel.Stop': '會議室（{value}）停用',
  'meeting.detail.form.cancel.Maint': '會議室（{value}）維護',
};
