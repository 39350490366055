/**
 * Created by wang sen on 2017/7/6.
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取绑定信息
 * @param payload
 */

export default ({ code, token }) => {
  return request(`/${RESTFUL_PATH.auth}/token/push-scan-auth-msg?code=${code}&token=${token}`);
};
