/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'scan.auth.page.product.code.title': '当前',
  'scan.auth.page.product.code.title.workspace': '工位',
  'scan.auth.page.product.code.title.meeting-room': '会议室',
  'scan.auth.page.product.code.title.locker': '储物柜',
  'scan.auth.page.product.code.name': '名称',
  'scan.auth.page.product.code.number': '编号',
  'scan.auth.page.product.code.space': '位置',
  'scan.auth.page.product.code.dept': '部门',
  'scan.auth.page.product.code.login.bind': '登录绑定',
  'scan.auth.page.product.code.more.info': '更多信息',
  'scan.auth.page.product.code.more.action': '更多操作',
  'scan.auth.page.product.code.action.report': '提报需求',
  'scan.auth.page.product.code.action.report.warn': '暂不支持此功能',
};
