import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export function getCmmaAuth() {
  return request(`/${RESTFUL_PATH.meeting}/cmma/getCmmaAuth`, {
    method: 'GET',
  });
}

export function startNow(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/cmma/startNow`, {
    method: 'POST',
    body: JSON.stringify({ meetingId }),
  });
}

export function cmmaInfo(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/cmma/cmmaInfo/${meetingId}`, {
    method: 'GET',
  });
}

export function cmmaStatus(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/cmma/cmmaStatus/${meetingId}`, {
    method: 'GET',
  });
}

export function resetCmmaInfo(meetingId) {
  return request(`/${RESTFUL_PATH.meeting}/cmma/resetCmmaInfo/${meetingId}`, {
    method: 'POST',
  });
}

export function addOnRoom(param) {
  return request(`/${RESTFUL_PATH.meeting}/cmma/addOnRoom`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

export function addingFailed(param) {
  return request(`/${RESTFUL_PATH.meeting}/cmma/addingFailed`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

export const delayCallNum = (callNum, mins) => {
  return request(`/${RESTFUL_PATH.meeting}/cmma/callNumDelay`, {
    method: 'POST',
    body: JSON.stringify({ callNum, mins }),
  });
};

export function getAllCmmaDevice(tenantId) {
  return request(`/${RESTFUL_PATH.meeting}/cmma/allCmmaDevice/${tenantId}`, {
    method: 'GET',
  });
}

export function rmvParticipant(param) {
  return request(`/${RESTFUL_PATH.meeting}/cmma/removeParticipant`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}
