export default {
  'sws.scan.sign.error': 'The system does not support scan sign-in',
  'sws.scan.fixed.error': 'The system does not support scanning fixed stations for the time being',
  401118: 'Reservation record does not exist. Sign-in failed. ',
  401119: 'Checked in, please do not check in repeatedly. ',
  401120:
    'The check-in time has not arrived. Please check in within the check-in time ({value1}). ',
  401121: 'The station has been released or not booked, the check-in fails',
  401125: 'The station is being used, please check in in {value1} minutes.',
  401046: 'Special booking station does not support code scanning.',
  401047: 'Special reservation stations do not support code scanning sign-in.',
  401126: 'This station has been reserved by someone else, the sign-in failed',
  401044: 'The workstation {value1} is not assigned a usage department',
  401057: 'The QR code has expired',
  401138: 'The current time is not within the check-in time range',
  403016: 'The locker department is inconsistent with the user department',
  403041: 'Unassigned department of the reserved locker',
  403042: 'The current user does not have department permissions for locker {value1}',
  401857: "{value1} device has been offline, continue to use?",
  'sws.scan.fix.error': 'You have bound the station',
  'sws.scan.error.tip':
    'This station does not support code scanning appointment. Please change the station!',
  'sws.scan.fixed.sign': 'Sign in successfully',
  'sws.scan.fixed.bind': 'Binding successful',
  'sws.scan.fixed.change': 'Work position change successfully',
  'sws.scan.fixed.username': 'User',
  'sws.scan.next.booking': 'Next Booking ',
};
