/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  scan_auth_success: '扫码认证成功',
  scan_auth_fail: '扫码认证失败',
  scan_auth_close: '关闭',
};
