export default {
  main_meeting_zoom_poll_subject_add: '添加题目',
  main_meeting_zoom_poll_remove: '删除调查',
  main_meeting_zoom_poll_publish: '确定',
  main_meeting_zoom_poll_tool_edit: '编辑',
  main_meeting_zoom_poll_tool_copy: '复制',
  main_meeting_zoom_poll_tool_up: '上移',
  main_meeting_zoom_poll_tool_down: '下移',
  main_meeting_zoom_poll_tool_del: '删除',
  main_meeting_zoom_poll_single_subject: '单选题',
  main_meeting_zoom_poll_multiple_subject: '多选题',
  main_meeting_zoom_poll_confirm: '确认要删除调查问卷？',
};
