import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取会议签到信息
 * @param meetingId
 * @returns {Object}
 */
export const getMeetingSignIn = ({ meetingId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/sign-info/${meetingId}`, {
    method: 'GET',
  });
};

/**
 * 获取会议服务
 * @param meetingId
 * @returns {Object}
 */
export const getMeetingService = ({ meetingId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-svc/list/${meetingId}`, { method: 'GET' });
};

/**
 * 获取所有服务
 * @returns {Object}
 */
export const getServiceList = ({ tenantId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-svc/getSvclist?tenantId=${tenantId}`, { method: 'GET' });
};

/**
 * 获取服务等级及等级下的服务信息
 * @returns {questMethodAddToUrl}
 */
export const getAllSvcInfo = ({ tenantId }) => {
  return request(`/${RESTFUL_PATH.meeting}/svc-info/getAllSvcVoInfo?tenantId=${tenantId}`, { method: 'GET' });
};

/**
 * 保存会议服务信息
 * @param params
 * @returns {Object}
 */
export const setServiceData = params => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-svc/saveMeetingSvcList`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

/**
 * 获取会议设备信息
 * @param meetingId
 * @returns {Object}
 */
export const getMeetingDevice = ({ meetingId }) => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-devices/getDeviceList/${meetingId}`, {
    method: 'GET',
  });
};

/**
 * 获取所有移动设备
 * @param type 0.会议室设备,1.固定设备,2.移动设备
 * @returns {Object}
 */
export const getDeviceData = ({ type, tenantId }) => {
  return request(`/${RESTFUL_PATH.meeting}/device-info/listByType?type=${type}&tenantId=${tenantId}`, {
    method: 'GET',
  });
};

/**
 * 保存会议设备信息
 * @param params
 * @returns {Object}
 */
export const setDeviceData = params => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/saveOtherData`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

/**
 * 补签接口
 * @param id
 * @returns {Object}
 */
export const retroactive = params => {
  return request(`/${RESTFUL_PATH.meeting}/meeting-info/reSign`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

/**
 * 上传用户人脸识别签到头像
 * @param userId
 * @param userType
 * @param path
 * @returns {Object}
 */
export const uploadFacePhoto = ({ userId, userType, path }) => {
  return request(
    `/${
      RESTFUL_PATH.admin
    }/user/submitFace?lang=${'zh_CN'}&userId=${userId}&userType=${userType}&path=${path}`,
    { method: 'GET' }
  );
};
