/**
 * Created : vincent
 * Date : 2019-03-27  19:22
 * Email : wangxiao@wafersystems.com
 */
export default {
  'access.myAccess.empower.title': 'Authorized Access',
  'access.myAccess.apply.btn.title': 'Apply for Access',
  'access.myAccess.btn.open': 'Open',
  'access.open.door.success': 'Success',
};
