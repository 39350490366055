export default {
  'meeting.seat.manage.room': 'Room',
  'meeting.seat.manage.seat.label': 'Seat',
  'meeting.seat.manage.user.placeholder': 'Please select attendee',
  'meeting.seat.button.text.ok': 'OK',
  'meeting.seat.room.template.none': 'No seat template available in room',

  'meeting.seat.button.text.save': 'Save',
  'meeting.seat.button.text.back': 'Back',
  'meeting.seat.manage.seat.label1': 'Seat No',
  'meeting.seat.manage.seat.username': 'Name of attendees',
};
