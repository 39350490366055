/**
 * Created JS
 * User: JiangYilong
 * Date: 2019/2/26
 * Time: 9:54
 * Email: jiangyilong@wafersystems.com
 */

export default {
  custom_iPadLogo: 'Company LOGO',
  custom_iPadBgImg: 'Home Background  Picture',
  custom_iPadOtherBgImg: ' Page Background  Picture',
  custom_pcColor: 'Company Color',
  custom_pc_template: 'Company Template',
  custom_machineBgImg: 'Page Background  Banner',
  custom_iPadLogo_title: 'Company LOGO will be showed in iPad',
  custom_machineLogo_title: 'Company LOGO will be showed in machine',
  custom_pcLogo_title: 'Company LOGO will be showed in pc',
  custom_pc_template_title: 'Company Template will be used in pc',
  custom_iPad_template_title: 'Company Template will be used in iPad',
  custom_machine_template_title: 'Company Template will be used in machine',
  custom_pc_template_tip: 'Current paper type: DK-11202',
  custom_pc_template_tip1: 'Current paper type: DK-22205,DK-22251',
  custom_iPadBgImg_title: 'Background Picture will be showed in iPad Home ',
  custom_machineBgImg_title: 'Background Banner will be showed in Machine',
  custom_pcBgImg_title: 'Customize the appearance color of PC client',
  custom_iPadOtherBgImg_title: 'Background Picture will be showed in iPad page',
  custom_machineOtherBgImg_title: 'Background Picture will be showed in Machine',
  custom_imgTip: 'tip：Support JPEG、PNG，PNG images with transparent backgrounds are recommended',
  custom_pcColor_btn: 'Choose Color',
  custom_pcColor_set: 'Input Color',
  custom_pcColor_input: 'Input Color',
  custom_pcColor_placeholder: 'please Input Color',
  custom_pcColor_Recommend: 'Recommend Color',
  custom_machine_Horizontal: 'Horizontal',
  custom_machine_Vertical: 'Vertical',
  custom_save: 'save',
};
