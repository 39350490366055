import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import CryptoJS from 'crypto-js';
import { ACCESS_TOKEN, VIRSICAL_SCAN } from '@/utils/constant';
import * as wechatService from './services/wechat';
import * as applyService from './services/services';
import * as virsicalService from './services/virsical';
import * as feishuService from './services/feishu';

export default {
  namespace: 'scanQRCode',

  state: {},

  effects: {
    *qrScan({ scanType }, { call }) {
      /* eslint-disable*/
      const res = yield call(wechatService.qrScan, scanType);
      /* eslint-disable*/

      if (scanType === 1) {
        Toast.success(res);
        window.location.href = res;
      }
      // yield put({ type: 'checkCode', payload: { res, scanType } });
    },
    *configScan({ scanType }, { call, put }) {
      const data = yield call(applyService.getJssdkTicket);
      window.console.log(data);
      if (Number(data.code) === 0) {
        yield call(wechatService.init, data.data, ['scanQRCode']);
        setTimeout(() => {}, 500);
        yield put({ type: 'qrScan', scanType });
      } else {
        Toast.fail(formatMessage({ id: data.msg }));
      }
    },
    *getWaferAppInfo(_, { call }) {
      const appId = sessionStorage.getItem(VIRSICAL_SCAN);
      if (appId) {
        const data = yield call(applyService.getWaferAppInfo, appId);
        if (data.code === 0) {
          const waferData = yield call(virsicalService.qrScan, data.data);
          Toast.success(waferData);
        } else {
          const errorText = formatMessage({ id: data.msg });
          Toast.fail(errorText);
        }
      }
    },
    *qrScanFeiShu(_, { call }) {
      /* eslint-disable*/
      const res = yield call(feishuService.qrScan);
      /* eslint-disable*/

      Toast.success(res);
      window.console.log(res, 'qrScanFeiShu is success');
      //window.location.href = res;

      // yield put({ type: 'checkCode', payload: { res, scanType } });
    },
    *configScanFeiShu(_, { call, put }) {
      const data = yield call(applyService.getJssdkTicketFeiShu);
      alert('getJssdkTicketFeiShu :');
      alert(JSON.stringify(data));
      if (Number(data.code) === 0) {
        const verifyStr = `jsapi_ticket=${data.data.jsapi_ticket}&noncestr=${data.data.nonceStr}&timestamp=${data.data.timestamp}&url=${window.location.href.split('#')[0]}`;
        const signatureTemp = CryptoJS.SHA1(verifyStr);
        alert(`verifyStr = ${verifyStr}  ----------   signatureTemp = ${signatureTemp}`);
        window.h5sdk.error(error => alert(`config: success ${JSON.stringify(error)}`));
        if(window.h5sdk){
          alert('window.h5sdk');
          window.h5sdk.config({
            appId: sessionStorage.getItem("FEISHU_SCAN_APPID_USER"), // 必填，应用ID
            timestamp: data.data.timestamp, // 必填，生成签名的时间戳，毫秒级
            nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
            signature: data.data.signature, // 必填，签名
            jsApiList: ['biz.util.scan'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2,
            onSuccess: (res) => {
              window.h5sdk.device.notification.toast({
                text: "config success",
                duration: 3
              });
              alert(`config: success ${JSON.stringify(res)}`);
            },
            onFailed: (res) => {
              window.h5sdk.device.notification.toast({
                text: "config fail",
                duration: 3
              });
              alert(`config: fail ${JSON.stringify(res)}`);
            }
          });
        }
      } else {
        Toast.fail(formatMessage({ id: data.msg }));
      }
    },
  },

  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  subscriptions: {
    setup({ history }) {
      return history.listen(({ pathname, query }) => {
        if (pathname === '/wdfclient/scanQRCode') {
          if (query.token) {
            sessionStorage.setItem(ACCESS_TOKEN, query.token);
          }
          if (query.appId) {
            sessionStorage.setItem(VIRSICAL_SCAN, query.appId);
            sessionStorage.setItem('FEISHU_SCAN_APPID', query.appId);
          }

          if (query.enterpriseId) {
            sessionStorage.setItem('FEISHU_SCAN', query.enterpriseId);
          }

          if (query.userAppId) {
            sessionStorage.setItem('FEISHU_SCAN_APPID_USER', query.userAppId);
          }
        }
      });
    },
  },
};
