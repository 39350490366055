import { useMemoizedFn } from 'ahooks';

/**
 * 截取字符串特定字符作为头像内容
 * name 访客姓名
 * @returns
 */
export default function useGenerateAvatar() {
  const generateAvatar = useMemoizedFn((name: string) => {
    if (!name) {
      return '';
    }
    const matchRes = name.match(/^([\u4e00-\u9fa5a-zA-Z][\u4e00-\u9fa5a-zA-Z\s]*|[\W\d])/);
    if (!matchRes) {
      return '';
    }
    const firstChar = matchRes[0].charAt(0);
    if (/[\u4e00-\u9fa5]/.test(firstChar)) {
      return firstChar;
    }
    const words = matchRes[0].trim().split(/\s+/);
    if (words.length === 1) {
      return firstChar.toUpperCase();
    }
    return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
  });

  return { generateAvatar };
}
