export default {
  'external.contacts.add': '添加外部参会人',
  'external.contacts.name': '姓名',
  'external.contacts.name_': '姓名：',
  'external.contacts.department': '部门',
  'external.contacts.position': '职位',
  'external.contacts.mail': '邮箱',
  'external.contacts.phone': '手机',
  'external.contacts.company': '公司',
  'external.contacts.placeholder': '请输入',
  'external.contacts.placeholder.name': '请输入姓名',
  'external.contacts.placeholder.phone': '请输入手机',
  'external.contacts.placeholder.email': '请输入邮箱',
  'external.contacts.placeholder.company': '请输入公司',
  'external.contacts.name.fail': '请输入姓名',
  'external.contacts.department.fail': '请输入部门',
  'external.contacts.position.fail': '请输入职位',
  'external.contacts.mail.null.fail': '请输入邮箱',
  'external.contacts.mail.fail': '邮箱格式不正确',
  'external.contacts.phone.null.fail': '请输入手机号',
  'external.contacts.phone.fail': '手机格式不正确',
  'external.visitor.notice': '访问邀约',
  'external.contacts.edit': '修改',
  'external.contacts.del': '删除',
  'meeting.booking.warning.visitor.canot.audit': '所选会议室中包含审批会议室，无法进行访客邀约',
  'external.visit.outuser': '设为访客',
};
