export default {
  'sign.in.type.no': '不簽到',
  'sign.in.type.anonymity': '匿名簽到',
  'sign.in.type.password': '認證簽到',
  'sign.in.type.invitation': '邀請碼簽到',
  'sign.in.type.QR.code': '二維碼簽到',
  'sign.in.type.face': '人臉識別',
  'sign.in.type.zoom': 'Zoom簽到',
  'sign.in.type.card': '刷卡簽到',

  'meeting.room.device.repair': '報修',
  'meeting.room.device.repaired': '已報修',
  'meeting.room.device.repair.success': '設備報修成功',
  'meeting.room.manager': '會議室管理員',
  'meeting.room.need.audit': '需審核',
  'meeting.room.service': '會議服務',
  'meeting.room.service.have': '有',
  'meeting.room.detail.name': '會議室名稱',
  'meeting.room.detail.area': '所屬區域',
  'meeting.room.detail.mail': '郵箱',
  'meeting.room.detail.number': '人數',
  'meeting.room.detail.price': '使用費用',
  'meeting.room.detail.unit': '元 / 小時',
  'meeting.room.detail.type': '會議室類型',
  'meeting.room.detail.company': '所屬公司',
  'meeting.room.detail.sign': '簽到類型',
  'meeting.room.detail.manager': '會議室管理員',
  'meeting.room.detail.photo': '會議室照片',
  'meeting.room.detail.device': '固定設備',
  'meeting.room.detail.reserve': '發起預約',
  'meeting.calendar.room.price.free': '免費',

  meeting_room_capacity_capacity0: '普通會議室',
  meeting_room_capacity_capacity1: 'WebEx會議室',
  meeting_room_capacity_capacity2: 'Acano會議室',
  meeting_room_capacity_capacity3: 'Exchange會議室',
  meeting_room_capacity_capacity4: 'Google Calendar會議室',
  meeting_room_capacity_capacity5: 'Domino|Notes會議室',
  meeting_room_capacity_capacity6: 'Cisco視頻會議室',
  meeting_room_capacity_capacity7: '紅外會議室',
  meeting_room_capacity_capacity8: 'Zoom會議室',
  meeting_room_capacity_capacity9: '騰訊會議虛擬會議室',
  meeting_room_capacity_capacity10: '飛書會議室',
  meeting_room_capacity_capacity12: '靜音倉',
  'meeting.room.detail.tencent': '方數',
};
