export default {
  //  访客项目,30开头

  //  ---

  //  邀请 10

  //  访问 20

  //  公共 30

  //  通知 40

  //  办公地/域 50

  //  其他(终端 门禁 访客云 会议等) 60

  //  ---

  //  邀请

  301001: '找不到该邀请码的邀请者',
  301002: '该邀请码无法识别',
  301003: '该邀请码已过期',
  301004: '该邀请码已执行过签到',
  301005: '该邀请码已签出',
  301006: '该邀请码已取消',
  301007: '向被邀请人发送访问邀请函成功！',
  301008: '团队邀请不存在',
  301009: '邀请码不能为空',
  301010: '找不到邀请码对应的访客预约记录',
  301011: '邀请码签到成功',
  301012: '你已完成签到',
  301013: '预约时间小于当前时间，请重新预约',
  301014: '预约时间限制{value}天以内，请重新预约',
  301015: '跨天时间限制{value}天以内，请重新预约',
  301016: '你创建的预约已经取消成功，请知悉。',
  301017: '预约拒绝通知',
  301018: '操作成功',
  301019: '邀请码签到失败',

  301020: '查询最后一次预约记录失败',

  301021: '生成临时预约记录失败',

  301022: '查询临时预约记录失败',

  301023: '找不到邀请码对应的访客签到记录',

  301024: '找不到邀请码对应的访客登记记录',

  301025: '临时预约单ID不能为空',

  301026: '临时预约单不存在',

  //  访问访客

  302001: '操作成功',

  302002: '预约访问操作失败',

  302003: '查询历史信息失败',

  302004: '访问类型已存在',

  302005: '访问公司:',

  302006: '访问时间:',

  302007: '访问目的:',

  302008: '访客姓名',

  302009: '访客公司',

  302010: '访客电话',

  302011: '访客邮箱',

  302012: '访问事由',

  302013: '访问时间',

  302014: '拜访通知',

  302015: '访客（外部参会人）列表不能为空',

  302016: '访客审核失败:找不到对应的访客记录！',

  302017: '访客审核失败:该访客记录已审核或无需审核！',

  302018: '访客审核失败:审核结果不能为空！',

  302019: '访客审核失败',

  302020: '查询访问类型失败',

  302021: '新建访问类型成功',

  302022: '新建访问类型失败',

  302023: '修改访问类型成功',

  302024: '修改访问类型失败',

  302025: '删除访问类型成功',

  302026: '删除访问类型失败',

  302027: '访客签出成功',

  302028: '访客签出失败',

  302029: '该访客没有预约记录',

  //  公共

  303001: '您好',

  303002: '访客',

  303003: '祝您工作愉快！',

  303004: '成功',

  303005: '失败',

  303006: '全部',

  303007: '未开始',

  303008: '未到访',

  303009: '最近签入',

  303010: '已签出',

  303011: '已取消',

  303012: '待审核',

  303013: '已拒绝',

  303014: '已过期',

  303015: '已通过',

  303016: '合计:',

  303017: '人次',

  303018: '登录失败',

  303019: '添加成功',

  303020: '添加失败',

  303021: '删除成功',

  303022: '删除失败',

  303023: '更新成功',

  303024: '更新失败',

  303025: '查询的人员不在黑名单中',

  303026: '黑名单查询失败',

  303027: '操作成功',

  303028: '当前公司已不存在',

  303029: '查询失败',

  303030: '文件不能为空',

  303031: '企业域不存在',

  303032: '用户不存在',

  303033: '修改成功',

  303034: '修改失败',

  303035: '参数异常',

  303036: 'token失效',

  303037: '当前用户没有操作权限',

  303038: '公司不存在',

  303039: '服务器未返回任何结果',

  303040: '服务器返回失败结果',

  // 通知

  304001: '短信或邮件服务异常',

  304002: '通知工位系统分配工位接失败:调用接口失败',

  304003: '通知展板显示欢迎信息成功',

  304004: '通知打印设备打印通知单失败:生成请求信息失败',

  304005: '通知打印设备打印通知单失败:',

  304006: '无有效标出提示',

  304007: '获取WIFI密码失败',

  304008: '访客证打印成功',

  304009: '访客证打印失败',

  304010: '查无此人',

  304011: '无效的邀请码链接',

  304012: '临时登记失败',

  304013: '获取访客系统token失败',

  304014: '小程序参数无效',

  304015: '用户已被加入黑名单',

  304016: '获取用户信息失败',

  304017: '打印机报警',

  304018: '新建保密协议成功',

  304019: '新建保密协议失败',

  304020: '修改保密协议成功',

  304021: '修改保密协议失败',

  304022: '删除保密协议成功',

  304023: '删除保密协议失败',

  304024: '查询管理员列表失败',

  304025: '创建管理员成功',

  304026: '创建管理员失败',

  304027: '修改管理员配置失败',

  304028: '模糊查询企业列表失败',

  304029: '获取WIFI账密失败',

  304030: '获取访客系统token失败',

  304031: '入参scope不能为空',

  304032: '入参scope有效值为[0,1]',

  304033: '查询我的预约失败',

  304034: '查询已预约过的企业失败',

  304035: '请先通过调口接口获取用户资料',

  304036: '用户登录凭证无效',

  304037: 'Company表未启用短信通知',

  304038: '短信发送失败',

  304039: '短信发送成功',

  304040: 'Company表未启用邮件通知',

  304041: '邮件发送失败',

  304042: '邮件发送成功',

  304043: '采集身份证信息成功',

  304044: '采集身份证信息失败',

  304045: '访问历史批量同步上传成功',

  304046: '访问历史批量同步上传失败',

  304047: '文件上传失败',

  304048: '查询不到访问类型',

  304049: '发送Portal消息失败',

  // 办公地

  305001: '办公区域未授权，禁止进入！',

  305002: '找不到访客对应的办公区域！',

  305003: '向办公区域管理员发送消息失败:获取办公区域管理员清单失败',

  305004: '办公区域没有管理员，跳过管理员消息提醒',

  305005: '办公区域没有管理员，跳过管理员消息提醒！',

  305006: '当前用户没有设置办公地',

  305007: '当前用户未绑定办公地，请联系管理员！',

  305008: '请解绑当前区域下部门',

  305009: '办公地点不存在（用户未绑定办公地）',

  305010: '请解绑当前区域下员工',

  305011: '向办公区域管理员发送消息成功！',

  305012: '向办公区域管理员发送消息失败:解析接口响应内容失败！',

  305013: '向办公区域管理员发送消息失败',

  305014: '向办公区域管理员发送消息失败:调用接口失败',

  305015: '未启用向办公区域管理员发送消息功能，自动跳过。',

  // 其他

  306001: '当前域套餐信息不存在',

  306002: '当前域没有初始化',

  306003: '当前终端数量已超过限制',

  306004: '请使用终端管理员账号登录',

  306005: '执行门禁扫码相关操作失败！',

  306006: '访客签到成功，自动为您开门',

  306007: '访客认证成功，自动为您开门',

  306008: '【会议预约访客】访客预约取消失败',

  306009: '[预约+会议]取消失败',

  306010: '会议取消失败',

  306011: '获取会议室失败',

  306012: '未配置保密协议',

  306013: '微信 - code换取access_token失败',

  306014: '微信 - 获取用户信息失败',

  306015: '微信 - 刷新token失败',

  306016: '微信小程序 - 登录凭证校验失败',

  306017: 'wx -> 获取访问类型失败',

  306018: 'wx -> 获取办公地点失败',

  306019: 'wx -> 模糊查找员工失败',

  306020: '【会议预约访客】访客预约取消成功',

  306021: '向微信接口发起请求未收到响应，请先检查网络',

  306022: '获取accesstoken失败',

  306023: '微信小程序登录失败',
  306024: '访客门禁扫码相关操作失败！',

  306025: '该访客已被加入黑名单',

  306026: '不在邀请时间范围内，不能开门',

  306027: '当前区域未配置门禁和设备关联',

  306028: '分配工位成功',

  306029: '解析接口响应内容失败',

  306030: '未启用通知方糖展板显示欢迎信息功能，自动跳过。',

  306031: '通知方糖展板显示欢迎信息失败:查询统计访客数量失败',

  306032: '未启用通知展板显示欢迎信息功能，自动跳过。',

  306033: '通知展板显示欢迎信息失败:查询统计访客数量失败',

  306034: '通知展板显示欢迎信息失败:调用接口失败',

  306035: '通知展板显示欢迎信息成功！',

  306036: '通知展板显示欢迎信息失败:接口返回失败结果！',

  306037: '通知展板显示欢迎信息失败:解析接口响应内容失败！',

  306038: '通知打印设备打印通知单失败:websocket 推送失败！',

  306039: '通知打印设备打印通知单成功！',

  306040: '未检测到通知打印设备, 跳过打印！',

  306041: '通知打印设备打印通知单失败:获取内部员工或访问类型失败',

  306042: '未启用通知打印设备打印通知单功能，自动跳过。',

  306043: '您的剩余短信不够发送邀请通知，请及时购买短信包',
  306048: '跨天会议模块没有开启',
  306049: '取消预约失败',
  306056: '会议开始时间应在到访时间之后',
  306058: '会议已经开始',
  306059: '您已经上传过防疫登记信息，请重新点击链接进入此页面',
  // OCR识别
  306060: '健康码识别结果异常，请上传绿色健康码',
  306061: '行程卡识别结果异常，请上传绿色行程卡',
  306062: '健康码已失效，请上传{number}小时内的健康码',
  306063: '行程卡已失效，请上传{number}小时内的行程卡',
  306064: '请上传正确的健康码图片',
  306065: '请上传正确的行程卡图片',
  306066: '只有未到访、已取消、处理中状态的记录才能删除',
  306067: '只有未开始状态的记录才能取消',
  306068: '非工作时间，操作失败！',
  306070: '腾讯ocr配置异常，请联系管理员',
  306073: '会议进入签到时间',
  306081: '手机不能重复',
  306082: '邮箱不能重复',
  306083: '手机、邮箱不能重复',
  306079: '只有未开始状态的记录才能补发',
  306080: '访客数量已超出当前接待地点{timeFormat}的可邀请数量',
  306084: '跨天会议允许预定{num}天内会议',
  306087: '被访人已离职，您无法进行签到/签出，请联系管理员。',
  306089: '跨天访客功能未打开',
  306091: '会议集成开关未打开',
  306098: '从Outlook取消',
  3999999: '已经无法修改个人信息',
  //  菜单

  'menu.system-management.blacklist': '黑名单管理',
  'menu.system-management': '系统管理',
  'menu.system-management.office': '办公地管理',
  'menu.system-management.setting': '前台配置',
  'menu.system-management.visitType': '访问类型管理',
  'menu.system-management.terminal': '终端管理',
  'menu.system-management.manage': '访客管理',

  //  会议活动70

  307001: '导出参会区人员异常',

  307002: '数据异常,请稍后再试',

  307003: '获取签到用户失败',

  307004: '导出签到用户失败',

  307005: '当前会议已经被取消',

  307006: '尚未到会议活动开始时间',

  307007: '会议活动已经结束',

  307008: '尚未到会议活动签到时间',

  307009: '会议活动签到已经结束',

  307010: '手机号已报名，请更换手机号并重试报名',

  307011: '短信发送失败,请稍后再试',

  307012: '未识别出人脸信息,请重新上传',

  307013: '用户未注册',

  307014: '参会码为空！',

  307015: '尚未到会议签到时间',

  307016: '会议已经结束',

  307017: '会议不在今天举行',

  307018: '用户签到失败！',

  307019: '签到对象为空',

  307020: '签到失败',

  307021: '未查询到用户数据',

  307022: '非法token',

  307023: '成功',

  307024: '失败',

  307025: '当前会议不存在',

  // 人脸识别
  10001: '图片中找不到人脸！',
  10002: '图片中包含多张人脸！',
  10003: '图片中的人脸太小！',
  10004: '图片过于模糊！',
  30001: '分组id不存在！',
  30002: '用户id不存在！',

  309001: '主键不能为空',
  309002: '主键参数无效',
  309003: '姓名不能为空',
  309004: '姓名长度不能超过50',
  309005: '手机号码不能为空',
  309006: '手机号码长度不能超过15',
  309007: '终端类型不能为空',
  309008: '终端MAC地址不能为空',
  309009: '办公地名称不能为空',
  309010: '办公地名称长度不能超过100',
  309011: '办公地全称不能为空',
  309012: '办公地全称长度不能超过100',
  309013: '地址不能为空',
  309014: '地址长度不能超过255',
  309015: '消息类型不能为空',
  309016: '消息类型不能超过11',
  309017: '消息开关标志不能为空',
  309018: '消息开关标志长度不能超过11',
  309019: 'Code不能为空',
  309020: '保密协议内容不能为空',
  309021: '保密协议长度不能超过10000',
  309022: '办公地ID不能为空',
  309023: '门禁编号不能为空',
  309024: '系统签出开关不能为空',
  309025: '系统签出时间不能为空',
  309026: '提前通知类型不能为空',
  309027: 'WIFI开关不能为空',
  309028: '人脸开关不能为空',
  309029: '绑定数据不能为空',
  309030: '主题不能为空',
  309031: '主题长度不能超过255',
  309032: '接待人员不能为空',
  309033: '接待人员长度不能超过255',
  309034: '访问事由不能为空',
  309035: '接待地点不能为空',
  309036: '接待地点长度不能超过255',
  309037: '到访时间不能为空',
  309038: '人脸不能为空',
  309039: '区号不能为空',
  309040: '拜访人不能为空',
  309041: '签到码不能为空',
  309042: '设备编码不能为空',
  306107: '选择的访客类型不存在，请重新选择',
  20240919: '会议已取消',
};
