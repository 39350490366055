/**
 * Created : linli
 * Date : 2020-11-18
 * Email : linli@wafersystems.com
 */
import { formatMessage } from 'umi';
import { Toast } from 'antd-mobile-v2';
import { parse } from 'qs';
import { sleep } from '@/utils/utils';

import * as facilityService from './service';
import * as wechatService from './services/wechat';
import * as applyService from './services/services';
import * as virsicalService from './services/virsical';

const SWS_DEVICE_ID = 'sws:device:id';
const STATION_QR_CODE_URL = '/workspace/qrcode?';

export default {
  namespace: 'swsFacility',
  state: {
    facilityListCode: [],
    dataCode: '',
    pickerItem: {},
    dataId: '',
    queryType: '',
    scanModalVisible: false,
    station: {}, // 待绑定工位信息
    list: [],
    deviceInfo: {},
    stationBind: {}, // 已绑定设施的工位信息
    bindDeviceVisible: false,
    unBindDeviceVisible: false,
    modalVisible: false,
    scanFlag: false,
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
    clearVal(state) {
      return { ...state, dataCode: '', station: '', pickerItem: '' };
    },
    clearHistory(state) {
      return { ...state, facilityListCode: [] };
    },
  },
  effects: {
    // 查询设备或者工位编号列表信息
    *getListByDataCode({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(facilityService.getListByDataCode, param);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            facilityListCode: result.data || [],
          },
        });
      }
    },

    // 查询当前编号的设备或者工位列表信息
    *queryByDataId({ payload }, { call, put, select }) {
      window.console.log('queryByDataId', payload);

      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(facilityService.queryByDataId, param);
      if (result && result.code === 0) {
        const { station } = yield select((state) => state.swsFacility);
        // 1 工位，2设备
        const { queryType } = payload;
        const { deviceList, deviceSysVo, stationSysVo = {} } = result.data || {};
        // 如果扫的是工位
        if (Number(queryType) === 1 && stationSysVo) {
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              station: stationSysVo || {},
              list: deviceList || [],
            },
          });
        } else if (Number(queryType) === 2) {
          // 如果扫的是 设施
          if (station && stationSysVo && station.id && station.id !== stationSysVo.id) {
            // 设施已绑定其他工位
            Toast.fail('设施已绑定工位');
            return;
          }
          if (station && station.id && deviceSysVo) {
            // 存在工位绑定设施
            yield put({
              type: 'saveOrUpdateData',
              payload: {
                deviceInfo: deviceSysVo || {},
                bindDeviceVisible: true,
              },
            });
          } else if (stationSysVo) {
            // 不存在待绑定工位，存在已绑定工位，提示解绑
            yield put({
              type: 'saveOrUpdateData',
              payload: {
                deviceInfo: deviceSysVo || {},
                stationBind: stationSysVo || {},
                unBindDeviceVisible: true,
              },
            });
          } else {
            // 不存在工位，不存在已绑定工位，存储设施数据
            yield put({
              type: 'saveOrUpdateData',
              payload: {
                deviceInfo: deviceSysVo || {},
                scanModalVisible: true,
              },
            });
          }
        }
      }
    },

    // 解绑
    *unbindDevice({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(facilityService.unbindDevice, param);
      if (result && result.code === 0) {
        Toast.success(formatMessage({ id: 'access.open.door.success' }), 1);
        yield put({
          type: 'queryByDataId',
          payload: { dataId: param.stationId, queryType: 1 },
        });
        yield put({
          type: 'saveOrUpdateData',
          payload: { unBindDeviceVisible: false, modalVisible: false },
        });
      }
    },
    // 绑定设施
    *bindDevice({ payload }, { call, put, select }) {
      const param = payload;
      const { info } = yield select((state) => state.login);
      window.console.log('绑定设施bindDevice', payload);

      // 获取租户Id
      if (info && info.tenant_id) {
        param.domainId = info.tenant_id;
      }
      const result = yield call(facilityService.bindDevice, param);
      if (result && result.code === 0) {
        Toast.success(formatMessage({ id: 'access.open.door.success' }), 1);
        yield put({
          type: 'saveOrUpdateData',
          payload: { bindDeviceVisible: false },
        });
        yield put({
          type: 'queryByDataId',
          payload: { dataId: param.stationId, queryType: 1 },
        });
      }
    },

    *checkCode({ payload }, { select, put }) {
      const { deviceInfo, station } = yield select((state) => state.swsFacility);
      const { sId, device } = payload;
      window.console.log('payload', payload);
      window.console.log('deviceInfo', deviceInfo);
      window.console.log('station', station);

      if (sId) {
        window.console.log('sId', sId);
        if (!station.id && deviceInfo.deviceId) {
          window.console.log('bindDevice', sId, deviceInfo);

          yield put({
            type: 'bindDevice',
            payload: { stationId: sId, deviceId: deviceInfo.deviceId },
          });
        } else {
          yield put({
            type: 'queryByDataId',
            payload: { dataId: sId, queryType: 1 },
          });
        }
      } else {
        yield put({
          type: 'queryByDataId',
          payload: { dataId: device, queryType: 2 },
        });
      }
    },

    *weChatQrScan({ scanType }, { call, put }) {
      /* eslint-disable*/
      const res = yield call(wechatService.qrScan, scanType);
      yield put({ type: 'handleResult', res });
      // yield put({ type: 'checkCode', payload: { device: 20} });
    },
    *handleResult({ res }, { put }) {
      const result = decodeURIComponent(res);
      if (result && (result.includes(SWS_DEVICE_ID) || result.includes('sId='))) {
        /* eslint-disable*/
        let payload = {};
        if (result.includes(SWS_DEVICE_ID)) {
          // 如果是设备，提取设备Id值
          payload.device = Number(String.prototype.slice.call(result, SWS_DEVICE_ID.length + 1));
        } else {
          const index = result.indexOf('sId');
          const sId = encodeURIComponent(
            result.substr(index, result.length).split('&')[0].split('sId=')[1],
          );
          // 如果是工位，则提取工位Id
          payload.sId = sId;
        }
        yield put({ type: 'checkCode', payload });
      } else {
        Toast.fail(formatMessage({ id: 'sws.facility.manage' }));
      }
    },
    *configScan({ scanType }, { call, put }) {
      // yield put({ type: 'weChatQrScan', scanType });
      // return
      const data = yield call(applyService.getJssdkTicket);
      window.console.log(data, 'getJssdkTicket');
      if (Number(data.code) === 0) {
        yield call(wechatService.init, data.data, ['scanQRCode']);
        sleep(1000);
        yield put({ type: 'weChatQrScan', scanType });
      } else {
        Toast.fail(formatMessage({ id: data.msg }));
      }
    },
    *virsicalQrScan(_, { call, put }) {
      const data = yield call(facilityService.getWaferAppInfo);
      window.console.log(data, 'getWaferAppInfo');
      if (data.code === 0) {
        const res = yield call(virsicalService.qrScan, data.data);
        yield put({ type: 'handleResult', res });
      } else {
        const errorText = formatMessage({ id: data.msg });
        Toast.fail(errorText);
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        // 监听路由变化，并发送类别查询请求
        if (pathname) {
          if (
            pathname.includes('/workspace/station/facilityManage/searchNum') ||
            pathname.includes('/workspace/station/facilityManage')
          ) {
            dispatch({
              type: 'clearHistory',
            });
          } else {
            dispatch({
              type: 'clearVal',
            });
          }
        }
      });
    },
  },
};
