import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 获取预订详情
 * @param payload
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getReservationInfo({ resId = 25,t }) {
  let url = `/${RESTFUL_PATH.sws}/api/client/resId?resId=${resId}`;
  if (t) {
    url += `&t=${t}`;
  }
  return request(url, {
    method: 'GET',
  });
}
/**
 * 释放/继续使用
 * @param id(预约id)
 * @param noticeId (消息记录id)
 * @param flag (1代表继续使用，2代表立即释放)

 * @returns {Promise<questMethodAddToUrl>}
 */
export async function operate(id,noticeId,flag) {
  const url = `/${RESTFUL_PATH.sws}/api/client/operate?reserveId=${id}&noticeId=${noticeId}&flag=${flag}`;
  return request(url, {
    method: 'GET',
  });
}
