/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  'menu.meeting.meeting_app': '智能會議',
  'menu.workspace.mobile': '智能工位',
  'menu.vst.app.manage': '智能訪客',
  'menu.app.index': '主頁',
  'menu.app.my': '我的',
  'menu.feishu.homePage.title': 'MetaWork',
  'menu.feishu.homePage.remind': '數字化行政辦公',
  'menu.envm.index': '環境監測',
  'menu.tcs.index': '數字化衛生間',
};
