export default {
  main_meeting_zoom_poll_question_title: '题目名称',
  main_meeting_zoom_poll_question_title_pls: '请输入题目名称',
  main_meeting_zoom_poll_option: '选项',
  main_meeting_zoom_poll_option_add: '添加选项',
  main_meeting_zoom_poll_option_pls: '请输入选项',
  main_meeting_zoom_poll_questionName_repeat: '调查问卷内题目不能重复',
  main_meeting_zoom_poll_option_repeat: '题目答案不能重复',
  main_meeting_zoom_poll_option_min: '题目选项不能少于2个',
  main_meeting_zoom_poll_option_max: '题目选项不能多于10个',
  main_meeting_zoom_poll_switch: '切换至多选题',
  main_meeting_zoom_poll_submit: '提交',
  main_meeting_zoom_poll_answer:'选项{num}'
};
