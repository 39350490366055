import formatMessage from '@/utils/intlUtils';

// 脱敏显示 手机号码、证件号
export const plusXing = (str, front, end) => {
  if (str == null || str === undefined || str === '') return str;
  let frontLen = 2;
  let endLen = 2;
  // if (str.length >= 10 && front) {
  if (front) {
    frontLen = front;
    endLen = end;
  }
  let len = str.length - frontLen - endLen;
  if (!len || len < 0) {
    len = 4; // 兼容前三后四身份证
  }
  let xing = '';
  for (let i = 0; i < len; i += 1) {
    xing += '*';
  }
  return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
};

// 脱敏显示 邮箱
export const emailMask = (input) => {
  let output = input;
  if (input == null || input === undefined || input === '') return output;
  const emailParts = input.split('@');
  if (emailParts.length > 1) {
    if (emailParts[0].length > 3)
      output = `${emailParts[0].substr(0, 2)}***${emailParts[0].substr(emailParts[0].length - 1)}`;
    else if (emailParts[0].length > 0) output = `${emailParts[0].substr(0, 1)}***`;
    output += `@${emailParts[1]}`;
  }
  return output;
};

// 访问事由国际化 name nameTw nameEn
export const showTypeByName = (typeObj) => {
  const locale = localStorage.getItem('umi_locale') || 'zh-CN';
  let value = '';
  switch (locale) {
    case 'zh-CN':
      value = typeObj?.name;
      break;
    case 'zh-TW':
      value = typeObj?.nameTw;
      break;
    case 'en-US':
      value = typeObj?.nameEn;
      break;
  }
  return value;
};

// 访问事由国际化 visitorType visitorTypeTw visitorTypeEn
export const showTypeByVisitorType = (typeObj) => {
  const locale = localStorage.getItem('umi_locale') || 'zh-CN';
  let value = '';
  switch (locale) {
    case 'zh-CN':
      value = typeObj?.visitorType;
      break;
    case 'zh-TW':
      value = typeObj?.visitorTypeTw;
      break;
    case 'en-US':
      value = typeObj?.visitorTypeEn;
      break;
  }
  return value;
};

// 1、是否云服务 2、是否部署会议
export const isFromCloudAndHasMeeting = () => {
  const omsUserProduct = sessionStorage.getItem('omsUserProduct') || null;
  const form = sessionStorage.getItem('from') || null;
  return form === 'cloud' && omsUserProduct && omsUserProduct.indexOf('smt') === -1; // 云服务、未部署会议
};

//- 小写数字转换成大写, 只处理到[0 ~ 99]
export function numberConvertToUppercase() {
  return function (num) {
    // eslint-disable-next-line no-param-reassign
    num = Number(num);
    var upperCaseNumber = [
      '零',
      '一',
      '二',
      '三',
      '四',
      '五',
      '六',
      '七',
      '八',
      '九',
      '十',
      '百',
      '千',
      '万',
      '亿',
    ];
    var length = String(num).length;
    if (length == 1) {
      return upperCaseNumber[num];
    } else if (length == 2) {
      if (num == 10) {
        return upperCaseNumber[num];
      } else if (num > 10 && num < 20) {
        return '十' + upperCaseNumber[String(num).charAt(1)];
      } else {
        return (
          upperCaseNumber[String(num).charAt(0)] +
          '十' +
          upperCaseNumber[String(num).charAt(1)].replace('零', '')
        );
      }
    }
  };
}

// 详情无数据展示'无‘
export const showDefaultValue = () => {
  return formatMessage({ id: 'points.billDetail.remarks.none' });
};

/**
 * 历史访客姓名显示（从会议copy来的）
 */
export const filterName = (name, num = 2) => {
  var reg = /^[^\u4e00-\u9fa5]+$/; // 不包含汉字
  let nameStr = name;
  // 去除空格 以及特殊字符
  const pattern =
    /[`~!@#$^\-&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
  nameStr = nameStr.replace(pattern, '');
  // nameStr = nameStr.replace(/^\s*|\s*$/g, "");
  if (reg.test(nameStr)) {
    const enStr = nameStr.split(' ');
    return enStr?.length > 1
      ? `${enStr[0].slice(0, 1)}${enStr[1].slice(0, 1)}`
      : nameStr.slice(0, num);
  }
  if (nameStr.length > num) {
    return nameStr.slice(nameStr.length - num);
  }
  return nameStr;
};

/**
 * 根据当前语言获取key
 * @param keyMap
 * @returns
 */
export function getLangKey(locale, keyMap) {
  return keyMap?.[locale];
}

// 国家码回显处理
export const showTelAreaCode = (telAreaCode) => {
  return telAreaCode === '000' ? '' : telAreaCode;
};

/**
 *
 * 判断是否在teams中打开
 */
export const isTeams = () => {
  return (
    sessionStorage.getItem('entrySource') === 'teams' ||
    navigator.userAgent.toLowerCase().indexOf('teams') > -1 ||
    navigator.userAgent.toLowerCase().indexOf('teamsmobile') > -1
  );
};

export const getContentByLanguage = ({ contentCN, contentTW, contentEN, lang }) => {
  switch (lang) {
    case 'zh-CN':
      return contentCN;
    case 'zh-TW':
      return contentTW;
    case 'en-US':
      return contentEN;
    default:
      return contentCN;
  }
};
