import { ROUTE_PATH } from '@/utils/constant';

export const CHINESE = 'zh-CN';
export const ENGLISH = 'en-US';
export const CHINESE_HK = 'zh-HK';
export const COMMA = ',';
export const SEMICOLON = ';';

export const ACCESS_TOKEN = 'access_token';
export const PASSWORD_AES_KEY = 'XDH206VF30Nf9q7t';
// 十二月缩写
export const monthArr = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May.',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sept.',
  'Oct.',
  'Nov.',
  'Dec.',
];

// 用户大于3000人使用AttendeePage 界面,小于3000人使用AttendeePage界面
export const SELECT_USER_PAGE_LIMIT = 3000;

export const RESTFUL_PATH = {
  auth: 'virsical-auth',
  admin: 'virsical-upms',
  upms: 'virsical-upms',
  meeting: 'smt-meeting',
  syncuser: 'virsical-syncuser',
  notice: 'virsical-notice',
  map: 'virsical-map',
  push: 'virsical-push',
  sss: 'virsical-sss',
  wdf: 'virsical-wdf',
  oss: 'smt-meeting',
};

export const MENU_PATH = {
  memberSelect: `${ROUTE_PATH}/meeting/members`,
  meetingSeat: `${ROUTE_PATH}/meeting/seat`,
  vskJump: `${ROUTE_PATH}/meeting/vsk`,
  about: `${ROUTE_PATH}/meeting/about`,
  myMeeting: `${ROUTE_PATH}/meeting/mines`,
  home: `${ROUTE_PATH}/meeting/index`,
  index: `${ROUTE_PATH}/index`,
  feedback: `${ROUTE_PATH}/meeting/f`,
  subscribe: `${ROUTE_PATH}/meeting/subscribe`,
  roomDetail: `${ROUTE_PATH}/meeting/room/detail`,
  subscribeDetail: `${ROUTE_PATH}/meeting/subdetail`, // meetingId可选
  roomList: `${ROUTE_PATH}/meeting/roomList`,
  attendee: `${ROUTE_PATH}/meeting/attendee`,
  attendeeSmall: `${ROUTE_PATH}/meeting/attendeeSmall`,
  external: `${ROUTE_PATH}/meeting/external`,
  externalContact: `${ROUTE_PATH}/meeting/contact/external`,
  meetingContact: `${ROUTE_PATH}/meeting/contact/phone`,
  meetingDetail: `${ROUTE_PATH}/meeting/detail`,
  roomControlList: `${ROUTE_PATH}/meeting/roomControlList`,
  roomFeeDetail: `${ROUTE_PATH}/meeting/room/fee`,
  svcFeeDetail: `${ROUTE_PATH}/meeting/svc/detail`,
  svcApprovalDetail: `${ROUTE_PATH}/meeting/svc/approvalDetail`, // 会议服务审批
  svcApply: `${ROUTE_PATH}/meeting/svc/add`,
  manageSign: `${ROUTE_PATH}/meeting/manage/sign`, // viewOnly: 1只能看，0可以编辑，可以不带此参数
  manageCycle: `${ROUTE_PATH}/meeting/manage/cycle`, // viewOnly: 1只能看，0可以编辑，可以不带此参数
  meetingDevice: `${ROUTE_PATH}/meeting/add/device`,
  meetingDeviceByBooking: `${ROUTE_PATH}/meeting/device/booking/detail`,
  newMeetingAgenda: `${ROUTE_PATH}/meeting/meetingAgenda`,
  agendaUser: `${ROUTE_PATH}/meeting/meetingAgendaUser`,
  manageDevice: `${ROUTE_PATH}/meeting/device/detail`, // viewOnly: 1只能看，0可以编辑，可以不带此参数
  meetingAgenda: `${ROUTE_PATH}/meeting/agenda`,
  manageSvc: `${ROUTE_PATH}/meeting/manage/svc`, // viewOnly: 1只能看，0可以编辑，可以不带此参数
  meetingStatistics: `${ROUTE_PATH}/meeting/statistics`,
  onlyVideo: `${ROUTE_PATH}/meeting/videomeeting`,
  meetingCtrl: `${ROUTE_PATH}/meeting/cmma`,
  zoomParticipant: `${ROUTE_PATH}/meeting/zoomParticipant`,
  meetingDetailUser: `${ROUTE_PATH}/meeting/meetingDetailUser`, // 详情页参会人

  login: `${ROUTE_PATH}login`,
  transition: `${ROUTE_PATH}transition`,
  meetingIndex: `${ROUTE_PATH}meeting`,

  auditOpinion: `${ROUTE_PATH}mines/audit/opinion/:meetingId(\\d+)`,

  webExMeeting: `${ROUTE_PATH}/meeting/webexmeeting`,
  delay: `${ROUTE_PATH}/meeting/delay`,

  servicePicker: `${ROUTE_PATH}servicePicker`,

  innerContact: `${ROUTE_PATH}contact/inner`,
  outerContact: `${ROUTE_PATH}contact/outer`,
  phoneContact: `${ROUTE_PATH}contract/phone`,

  weChat: `${ROUTE_PATH}weChat`,

  signIn: `${ROUTE_PATH}signin/:meetingId(\\d+)`,
  signInResult: `${ROUTE_PATH}signResult/:result(\\d+)`,

  // 直录播播放页面
  player: `${ROUTE_PATH}player`,
  choosePlayer: `${ROUTE_PATH}chooseplayer`,
  webex: `${ROUTE_PATH}webex`,

  zoomPolling: `${ROUTE_PATH}/meeting/zoomPolling`,
  polling: `${ROUTE_PATH}/meeting/polling`,
  pollingTitle: `${ROUTE_PATH}/meeting/pollingTitle`,
  pollingQuestion: `${ROUTE_PATH}/meeting/pollingQuestion`,
  mapRoomDetail: `${ROUTE_PATH}/meeting/map/roomDetail`,
  indexMap: `${ROUTE_PATH}/index-map`,
  evaluate: `${ROUTE_PATH}/room/evaluate`,
  AttendeeSmallDatail: `${ROUTE_PATH}/meeting/attendeeSmallDetail`,
};

export const CONTACT_PAGE_FROM = {
  temp_detail_holder: 1, // 模板详情 - 主持人 ps:模板现在没有主持人了
  temp_detail_member: 2, // 模板详情 - 参会人
  subscribe_holder: 3, // 预约会议 - 主持人
  subscribe_member: 4, // 预约会议 - 参会人
  subscribe_outer_member: 5, // 预约会议 - 外部参会人
  manage_service_payer: 6, // 会议服务费用代付人    //  弹框的会议服务代付人
  manage_room_payer: 7, // 会议室费用代付人         //  弹框的会议室 代付人
  room_detail_payer: 8, // 会议室费用代付人   // 详情  会议室 代付人
  room_service_payer: 9, // 会议室费用代付人     //  svcApply 代付人
};

export const CREATOR = {
  me: '0',
  notMe: '1',
};
export const MEETING_STATUS = {
  pending: 0, // 未开始
  opened: 1, // 已开始
  closed: 2, // 已结束
  cancel: 3, // 已取消
  reject: 4, // 审核拒绝
  audit: 5, // 待审核
  archive: 6, // 无效
  progress: 7, // 处理中
  fail: 8, // 失败
  exAudit: 9, // ex 审批
};
// 会议来源
export const MEETING_SOURCE = {
  WEB: 1,
  APP: 2,
  BOARD: 3,
  EXCHANGE: 4,
  GOOGLE_CALENDAR: 5,
  IMMEDIATE: 6,
  VST: 7,
  THIRD: 8,
};
// 同步策略
export const EX_SUPPORT = {
  // 仅支持用户账号
  ONLY_USER: 0,
  // 支持指定域使用默认预约账号
  CUSTOM_DOMAIN: 1,
  // 仅支持会议室账号
  ONLY_ROOM: 2,
  // 固定账号
  FIXED_ACCOUNT: 3,
};
export const DATE_FORMAT = 'YYYY-MM-DD';

export const TIME_FORMAT = 'HH:mm';

export const TIME_FORMATS = {
  H_00: 'HH:00',
  H_15: 'HH:15',
  H_30: 'HH:30',
  H_45: 'HH:45',
  D_H_00: 'YYYY-MM-DD HH:00',
  D_H_15: 'YYYY-MM-DD HH:15',
};

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const RES_STATUS = {
  success: 0,
  failure: 1,
};

export const DEFAULT_CANCEL_TYPE = 0;

export const LIMIT_USER_LENGTH = 20; // 显示用户人数

export const USE_NEW_ATTENDEE_PAGE = true;

export const EXCEPTION_MSG = {
  // 后台请求报错时，对错误msg进行优化显示
  zh_40x: '请求异常，请联系管理员。错误信息：',
  en_40x: 'Request exception, please contact the administrator. Error Message: ',
  zh_50x: '服务器异常，请联系管理员。错误信息：',
  en_50x: 'Server exception, please contact the administrator. Error Message: ',
};

export const FREQUENT_CONTACTS_NODE = '001999';
export const EXTERNAL_USERS_NODE = '001998';
export const LOCAL_USERS_NODE = '001997';

export const ROLES_KEY = {
  admin: 'common@admin', // 系统管理员
  normal: 'common@user', // 普通用户
  room_admin: 'smt@room_admin', // 会议室管理员
  meeting_admin: 'smt@mt_admin', // 会议室管理员
  svc_admin: 'smt@svc_admin', // 会议服务管理员
  device_admin: 'smt@device_admin', // 设备管理员
  reserve_helper: 'smt@reserve_helper', // 代订人员
  svc_helper: 'smt@svc_helper', // 服务审批人员
};

export const DEVICE_STATE = {
  NORMAL: 0,
  FAULT: 1,
};

// “视频会议”设备的数据库id
export const VIDEO_DEVICE_ID = 110;

export const REQUEST_TYPE = {
  // 请求类型，是业务上的概念
  normal: 1,
  upload: 2,
  downloadExcel: 3,
};

export const SVC_AUDIT_TYPE = {
  // 会议服务的审批类型
  no: 0,
  yes: 1,
};

export const ROOM_NEED_AUDIT = {
  // 会议室是否需要审批
  no: 0,
  yes: 1,
};

export const MEETING_AUDIT = {
  // 会议是否需要审批
  yes: 1, // 需要
  no: 0, // 不需要
};

export const SYSTEM_SHOW_MEETING_PRIVATE = {
  // 允许公开会议主题和预定人信息, 0 否 ， 1 是
  yes: '1',
  no: '0',
};
export const SVC_STATE = {
  // 会议服务审批状态
  noApply: 0, // 未申请
  audit: 1, // 审批中
  reject: 2, // 已拒绝
  agree: 3, // 已通过
  noAudit: 4, // 本地用户申请不需要审批
};

export const ROOM_TYPES = {
  commonRoom: '0',
  webexRoom: '1',
  videoRoom: '6',
  zoomRoom: '8',
};

export const CMMA_ROOM_ID = {
  noCmmaRoomId: '0',
};

export const VERSION_NUM = {
  version: '4.6',
  years: '2000-2018',
};

export const LAYOUTS = {
  speaker: 'speakerOnly', // 全屏
  telepresence: 'telepresence', // 网真
  stacked: 'stacked', // 堆叠
  equalAll: 'allEqual', // 对等
  equal4: 'allEqualQuarters', // 2*2
  equal9: 'allEqualNinths', // 3*3
  equal16: 'allEqualSixteenths', // 4*4
  equal25: 'allEqualTwentyFifths', // 5*5
  oneN: 'onePlusN', // 1+n
  one5: 'onePlusFive', // 1+5
  one7: 'onePlusSeven', // 1+7
  one9: 'onePlusNine', // 1+9
};

export const PARTICIPANT_STATUS = {
  ringing: 'ringing',
  connected: 'connected',
  unknown: 'unknown',
};

export const INDEX_CONFIG_KEY = {
  BOOK_KEY: 'BOOKING',
  ABOUT_KEY: 'ABOUT',
  VIDEO_MEETING_KEY: 'VIDEO_MEETING',
  MY_MEETING_KEY: 'MY_MEETING',
  NETWORK_MEETING_KEY: 'NETWORK_MEETING',
  MY_KEY: 'MY_KEY',
};
export const PERMISSION_MEETING_MENU = {
  BOOK_KEY: 'app@smt@meeting_subscribe',
  VIDEO_MEETING_KEY: 'app@smt@meeting_video',
  MY_MEETING_KEY: 'app@smt@meeting_mines',
  NETWORK_MEETING_KEY: 'app@smt@meeting_webex',
  MY_KEY: 'MY_KEY',
  ABOUT_KEY: 'ABOUT',
};

export const ATTENDEE_STATUS = {
  AGREED: 3, // 同意
  REJECTED: 2, // 拒绝
  UNPROCESSED: 1, // 未处理
};

export const SIGN_TYPE = {
  no: '-1', // 不签到
  anonymous: '0', // 匿名签到
  password: '1', // 安全签到（账户认证签到）
  inviteCode: '2', // 邀请码签到
  qr: '3', // 二维码签到
  face: '4', // 人脸签到
};
// 会议室类型
export const MEETING_ROOM_TYPE = {
  Normal: 0, // 物理会议室
  WebEx: 1, // WebEx会议室
  Exchange: 3, // Exchange会议室
  GoogleCalendar: 5, // Google Calendar会议室
  Cisco: 6, // Cisco视频会议室
  immediate: 7,
  zoom: 8,
};
export const MEETING_TYPE = {
  // 会议类型:0.普通会议,1.周期会议(按天),2.周期会议(按周),3.周期会议(按月),4.周期会议(按工作日)
  normal: 0,
  cycleDay: 1,
  cycleWeek: 2,
  cycleMonth: 3,
  cycleWorkDay: 4,
  cycleYear: 7
};

export const MAINTAIN_STATUS = {
  not_set: 0,
  not_start: 1,
  started: 2,
};

export const USER_SIGN_TYPE = {
  NONE: 0, // 未签到
  PWD: 1, // 密码签到
  QR_CODE: 2, // 扫码签到
  FACE: 3, // 人脸识别签到
  INFRARED: 4, // 红外签到
  SUPPLEMENT_SIGN: 5, // 补签
  ANONYMOUS: 6, // 匿名签到
  DOOR_ACCESS: 7, // 门禁签到
};

export const LIVE_TYPE = {
  normal: 'Generic',
  asr: 'Asr',
};

export const MEETING_CAPACITY = {
  normal: 0,
  webex: 1,
  exchange: 3,
  google: 5,
  cisco: 6,
  immediate: 7,
  zoom: 8,
  tencent: 9, //  腾讯会议虚拟会议室
  flybook: 10, // 飞书会议室
};
export const MEETING_BOOK_TYPE = {
  // 预约会议类型
  NORMAL: 0, // 普通会议
  CISCO: 1, // 视频会议
  ZOOM: 2, // zoom会议
  WEBEX: 3, // webex会议
  TENCENT: 4, // 腾讯会议
  TEAMS: 5, // TEAMS
  FEISHU: 8, //飞书会议
  WECHAT: 9, //飞书会议
};

export const ROOM_STATES = {
  AVAILABLE: 0, // 可用
  OCCUPY: 1, // 正在使用
  WAIT: 2, // 签到时间
  OFFLINE: 3, // 下线
};

export const YES = '1';
export const NO = '0';

export const MEETING_AUDIT_PERMISSION = 'user@smt@meeting_approval';

export const timeOfEnUs = 'MM/DD/YYYY HH:mm';
export const SVC_APPROVAL = 'user@smt@svc_approval';
export const DATE_TIME_FORMAT2 = 'YYYY/MM/DD HH:mm';
// 会议系统使用的 new permission值  2022-03-15
export const MEETINGPREMISSION = {
  meetingApproval: 'user@smt@meeting_approval_btn', // 会议审批
  serviceApproval: 'user@smt@svc_approval_btn', // 服务审批
  servicePending: 'admin@smt@svc_deal_confirm', // 服务待办
  deviceApproval: 'user@smt@device_approval_btn', // 设备审批
  devicePending: 'admin@smt@device_deal_confirm', // 设备待办
};

export function closest(el, selector) {
  const matchesSelector =
    el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}

// 参会人 外部参会人 四种颜色  HEXA
export const UserColor = [
  'linear-gradient(to bottom right, #D6CEFA 0%, #ACA0F2 100%)',
  'linear-gradient(to bottom right, #FFD9A3 0%, #FFB26A 100%)',
  'linear-gradient(to bottom right, #5ECFC9 0%, #30A299 100%)',
  'linear-gradient(to bottom right, #B4E497 0%, #86D057 100%)',
  'linear-gradient(to bottom right, #A1C6FF 0%, #5E9DFF 100%)',
  'linear-gradient(to bottom right, #FFA6A3 0%, #FF6D6A 100%)',
];

export const XroomsTypeColor = {
  0: '#7B82EA',
  1: '#04D1B8',
  2: '#3C6EFF',
};


// 服务待办状态4:待服务,1:已完成,6:已过期,3:已取消
export const NEWSVCSTATE = {
  TOBESERVED: 4,
  FINISHED: 1,
  EXPERIED: 6,
  CANCELLED: 3,
};

// 设备待办状态4:待服务,5:借用中,1:已归还,6:已过期,3:已取消
export const NEWDEVICESTATE = {
  TOBESERVED: 4,
  BORROWED: 5,
  RETURNED: 1,
  EXPERIED: 6,
  CANCELLED: 3,
};

// 9.3对接工作流，新版流程实例状态（0﹔审批中 1:已结束 2:已拒绝 3:已取消  4:待服务5:服务中 6:已过期 7已删除)") 
// -2是编辑时前端新增
export const BPMSTATE = {
  ADD: -2,
  APPROVALING: 0,
  ENDED: 1,
  REFUSED: 2,
  CANCELED: 3,
  TOBESERVED: 4,
  SEVREING: 5,
  EXPERIED: 6,
  DELETED: 7,
};
