/**
 * Created by XuQiang on 2017/6/7.
 */

export default {
  'admin.office.name': '办公地名称',
  'admin.new': '新增',
  'admin.edit': '编辑管理员',
  'admin.name': '管理员姓名',
  'admin.name_': '姓名',
  'admin.name.tip': '请输入正确的管理员姓名',
  'admin.name.tip.p': '请输入管理员姓名',
  'admin.role': '管理员角色',
  'admin.role_': '角色',
  'admin.role.tip': '请选择管理员角色',
  'admin.del.tip': '您确定要删除该管理员吗？',
  'admin.black.del.tip': '您确定要删除该黑名单吗？',
  'admin.office.shortName_': '办公地简称',
  'admin.office.shortName': '办公地简称',
  'admin.office.allName_': '办公地全称',
  'admin.office.allName': '办公地全称',
  'admin.office.address': '办公地地址',
  'admin.office.organization': '绑定',
  'admin.office.shortName.tip': '请输入办公地简称',
  'admin.office.allName.tip': '请输入办公地全称',
  'admin.office.address.tip': '请输入办公地地址',
  'admin.office.detailAddress.tip': '请输入详细地址',
  'admin.office.edit': '编辑',
  'admin.office.editInfo': '编辑办公地',
  'admin.office.delete': '删除',
  'admin.office.add': '新增',
  'admin.office.detailAddress': '详细地址',
  'admin.office.choose.organization': '请选择组织机构',
  'admin.office.delete.tip': '您确定要删除该办公地吗？',
  'admin.visitType.name': '访问类型',
  'admin.visitType.name.tip': '请输入访问类型',
  'admin.visitType.addInfo': '新增',
  'admin.visitType.editInfo': '编辑类型',
  'admin.visitType.companyName': '办公地名称',
  'admin.visitType.serial': '序号',
  'admin.visitType.del.tip': '您确定要删除该类型吗？',
  'admin.NDA': '保密协议',
  'admin.NDA.companyName': '办公地名称',
  'admin.NDA.add': '新增',
  'admin.NDA.watch': '查看',
  'admin.NDA.watchNDA': '查看协议',
  'admin.NDA.addNDA': '新增协议',
  'admin.NDA.editNDA': '编辑协议',
  'admin.NDA.del.tip': '你确定要删除该公司的保密协议吗?',
  'admin.NDA.choose.companyName': '请选择办公地',
  'admin.NDA.exist.companyName': '办公地保密协议已经存在，请在列表中对其执行编辑操作',
  'admin.NDA.tip': '请输入保密协议',
  'admin.device.type': '登录终端类型',
  'admin.device.id': '登录终端标识',
  'admin.device.user': '登录用户',
  'admin.device.close': '关闭',
  'admin.device.time': '登录时间',
  'admin.device.type_': '登录终端类型：',
  'admin.device.id_': '登录终端标识：',
  'admin.device.time_': '登录时间：',
  'admin.device.ip_': '登录IP：',
  'admin.device.detail': '登录详情',
  'admin.notify.msg': '短信通知',
  'admin.notify.msg.tip': '以发送短信的方式通知配置相关事项',
  'admin.notify.email': '邮件通知',
  'admin.notify.email.tip': '以发送邮件的方式通知配置相关事项',
  'admin.notify.desk': '前台通知',
  'admin.notify.desk.tip': '发送短信的方式通知前台人员访客到访',
};
