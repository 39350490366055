import React, { useMemo, useCallback } from 'react';
import { useModel, useSelector } from 'umi';
import moment from 'moment';
import { changeTimeAfter } from '@/pages/Visitor/utils/constant';

export default function () {
  const { oldVisitorCountObj, visitorCount } = useModel('Visitor.visitorCount', (state) => ({
    oldVisitorCountObj: state?.state?.oldVisitorCountObj,
    visitorCount: state?.state?.visitorCount,
  }));

  const {
    locationManageId = null,
    preVisitorTimeFormat = null,
    visitorCount: oldVisitorCount = 0,
  } = oldVisitorCountObj || {};

  const {
    area,
    visitor: { visitors = [], preVisitorTime = null },
  } = useSelector((state: any) => state.app);

  const visitorTime = useMemo(() => {
    return preVisitorTime ? moment(preVisitorTime) : changeTimeAfter(moment().add(10, 'm'));
  }, [preVisitorTime]);

  // 获取访客人数校验对象 return: num: 变更前访客人数 canInvite: 是否允许提交邀请 canAdd: 是否允许添加访客
  if (area?.id === locationManageId && visitorTime?.format('YYYY-MM-DD') === preVisitorTimeFormat) {
    return {
      num: oldVisitorCount,
      canInvite: visitorCount + oldVisitorCount >= visitors?.length,
      canAdd: visitorCount + oldVisitorCount > visitors?.length,
    };
  } else {
    return {
      num: 0,
      canInvite: visitorCount >= visitors?.length,
      canAdd: visitorCount > visitors?.length,
    };
  }
}
