export default {
  'face.tab1': 'Not reviewed',
  'face.tab2': 'Reviewed',
  'face.audit': 'Review',
  'face.audit.agree1': 'Passed',
  'face.audit.reject1': 'Rejected',
  'face.audit.title': '"Pass" or "reject" of face recognition photos',
  'face.audit.agree': 'Pass',
  'face.audit.reject': 'Reject',
  'face.audit.cancel': 'Cancel',
  'face.audit.success': 'Review success',
  'face.audit.error': 'Review fail',

  'menu.sas.app.faceAudit': 'Face Audit',
};
