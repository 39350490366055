/**
 * Created : fangkemeng
 * Date : 2019-10-12  10:00
 * Email : fangkemeng@wafersystems.com
 */
import { Toast } from 'antd-mobile-v2';
import { formatMessage } from 'umi';
import * as myBookingService from './service';

export default {
  namespace: 'swsMyBookingNew',
  state: {
    bookListNewData: { records: [], current: 1, size: 5, total: 0, pages: 1 },
    bookListNew: [],
    loading: false,
    showReservationResult: false,
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询我的预订记录
    *myReservationRecords({ payload, editId }, { call, put, select }) {
      // console.log('###########getHistory', search)
      const { bookListNew } = yield select((state) => state.swsMyBookingNew);
      const result = yield call(myBookingService.myReservationRecords, payload);
      if (result && result.code === 0) {
        const { data = {} } = result;
        console.log(editId,'editId',bookListNew)
        if (editId) {
          const oldBookList = bookListNew?.filter((old) => old.id !== editId);
          console.log(oldBookList,'oldBookList')

          yield put({
            type: 'saveOrUpdateData',
            payload: {
              bookListNew: [...oldBookList, ...data?.records],
              bookListNewData: data,
            },
          });
        } else {
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              bookListNew: [...bookListNew, ...data?.records],
              bookListNewData: data,
            },
          });
        }
      }
    },
    // 取消预订
    *cancelReservation({ payload }, { call, put }) {
      const result = yield call(myBookingService.cancelReservation, payload);
      if (result && result.code === 0) {
        console.log(payload,'payload')
        yield put({
          type: 'myReservationRecords',
          editId: payload.id,
        });
        Toast.success(formatMessage({ id: 'sws.cancel.success' }), 1);
      }
    },
    *cancelSpecialReservation({ payload }, { call, put }) {
      const result = yield call(myBookingService.cancelSpecialReservation, payload);
      if (result && result.code === 0) {
        console.log(payload,'payloadS')

        yield put({
          type: 'myReservationRecords',
          editId: payload.rId,
        });
        Toast.success(formatMessage({ id: 'sws.cancel.success' }), 1);
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
    // // 注销预订
    // *logoutReservation({ payload }, { call, put }) {
    //   const result = yield call(myBookingService.cancelReservation, payload);
    //   if (result && result.code === 0) {
    //     yield put({
    //       type: 'myReservationRecords',
    //     });
    //     Toast.success(formatMessage({ id: 'sws.logout.success' }), 1);
    //   }
    // },
    // 预订延时
    *delayReservation({ payload }, { call, put }) {
      const result = yield call(myBookingService.delayReservation, payload);
      if (result && result.code === 0) {
        yield put({
          type: 'myReservationRecords',
        });
        Toast.success(formatMessage({ id: 'sws.reserve.delay.success' }), 1);
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(({ pathname }) => {
    //     // 监听路由变化，并发送类别查询请求
    //     if (pathname && pathname.includes('/workspace/station/myBooking')) {
    //       // console.log('myBooking model 初始化')
    //       dispatch({ type: 'swsCommon/getSpaceTreeAndMapId' });
    //       dispatch({ type: 'getMybook'});
    //       dispatch({ type: 'getSpecialMybook' });
    //       dispatch({ type: 'getHistory' });
    //       dispatch({ type: 'swsCommon/getHoliday' });
    //     }
    //   });
    // },
  },
};
