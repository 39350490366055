/** eslint-disable * */
/**
 * Created : fangkemeng
 * Date : 2019-09-20  10:00
 * Email : fangkemeng@wafersystems.com
 */
import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

/**
 * 查询设备或者工位编号列表信息
 * @param param 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getListByDataCode(param) {
  const url = `/${RESTFUL_PATH.sws}/api/device/queryListByDataCode?domainId=${param.domainId}&dataCode=${param.facilityCode}`;
  return request(url, {
    method: 'GET',
  });
}
/**
 * 查询当前编号的设备或者工位列表信息
 * @param param 请求参数用户Id
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function queryByDataId(param) {
  let url = `/${RESTFUL_PATH.sws}/api/device/queryByDataIdScan?domainId=${param.domainId}&dataId=${param.dataId}&queryType=${param.queryType}`;
  if (param.isNoScan) {
    url = `/${RESTFUL_PATH.sws}/api/device/queryByDataId?domainId=${param.domainId}&dataId=${param.dataId}&queryType=${param.queryType}`;
  }
  return request(url, {
    method: 'GET',
  });
}
/**
 * 解绑
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function unbindDevice(param) {
  return request(`/${RESTFUL_PATH.sws}/api/device/unbindDevice`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}
/**
 * 绑定设施
 * @param param 请求参数体
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function bindDevice(param) {
  return request(`/${RESTFUL_PATH.sws}/api/device/bindDevice`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
}

/**
 * 获取virsical扫一扫相关参数
 * @returns {Promise<questMethodAddToUrl>}
 */
export async function getWaferAppInfo() {
  return request(`/${RESTFUL_PATH.sws}/api/sysSetting/getWaferAppInfo`, {
    method: 'get',
  });
}
