export default {
  'sws.detail.sws.time': 'Time',
  'sws.detail.sws.type': 'type',
  'sws.detail.sws.device': 'Fixed equipment',
  'sws.detail.sws.reserve.log': 'Reservation Records',
  'sws.detail.sws.cancel.btn': 'Cancel',
  'sws.detail.sws.reserve.btn': 'Booking station',
  'sws.detail.sws.station.no': 'Desk',
  'sws.detail.sws.station.user': 'User',
  'sws.detail.sws.station.user.select': 'Please select',
  'sws.detail.sws.ok.btn': 'Submit',
  'sws.detail.sws.reserve.ok': 'Booked successful',
  'sws.detail.sws.reserve.time.tip': 'Please select the time of reservation',
  'sws.detail.sws.reserve.man.tip': 'Please select a user',

  'sws.stationType.mobile': 'Shared Desk',
  'sws.stationType.fixed': 'Fixed station',
  'sws.detail.no.data': 'No data',
  'sws.detail.reserve.man': 'Booker',

  'sws.detail.week.0': 'Sunday',
  'sws.detail.week.1': 'Monday',
  'sws.detail.week.2': 'Tuesday',
  'sws.detail.week.3': 'Wednesday',
  'sws.detail.week.4': 'Thursday',
  'sws.detail.week.5': 'Friday',
  'sws.detail.week.6': 'Saturday',

  'sws.detail.ktyd': 'Transsky reservation ',
  'sws.detail.daiding': 'Delegation',

  'sws.detail.s.time': 'Start time',
  'sws.detail.e.time': 'End time',

  'sws.detail.time.diff': 'The start time cannot be greater than the end time',
};
