export default {
  'router.title.meeting.MyMeeting': '我的會議',
  'router.title.meeting.MeetingDetail': '會議詳情',
  'router.title.meeting.DeviceTab': '會議設備',
  'router.title.meeting.SignTab': '會議簽到',
  'router.title.meeting.Agenda': '會議議程',
  'router.title.meeting.SvcTab': '會議服務',
  'router.title.meeting.MeetingReceipt': '會議回執',
  'router.title.meeting.SubscribeDetail': '視頻會議',
  'router.title.meeting.subdetail': '會議預訂',
  'router.title.meeting.webexmeeting': '網絡會議',
  'router.title.meeting.MeetingDelay': '延長會議',
  'router.title.meeting.RoomFeeDetail': '會議室詳情',
  'router.title.meeting.SvcFeeDetail': '費用詳情',
  'router.title.meeting.SvcApply': '會議服務',
  'router.title.meeting.PollingQuestion': '調查問卷',
  'router.title.meeting.MeetingContact': '添加外部參會人',
  'router.title.meeting.ExternalContact': '添加外部參會人',
  'router.title.meeting.ExternalPage': '選擇外部參會人',
  'router.title.meeting.AttendeePage': '人員選擇',
  'router.title.meeting.RoomList': '選擇會議室',
  'router.title.meeting.RoomDetail': '會議室詳情',
  'router.title.meeting.Subscribe': '選擇會議室',
  'router.title.meeting.About': '關於',
  'router.title.meeting.HomePage': '智能會議',
  'router.title.meeting.cycle': '迴圈規則',
  'router.title.meeting.seat': '座位安排',
  'router.title.meeting.seat.member': '人員選擇',
};
