export default {
  'meeting.svc.btn.new': 'Add Service',
  'meeting.svc.room': 'Room',
  'meeting.svc.level': 'Level',
  'meeting.svc.payer': 'Payer ',
  'meeting.svc.total': 'Total: ',
  'meeting.svc.confirm': 'Not confirmed',
  'meeting.svc.state-2': 'Unsubmitted',
  'meeting.svc.state-1': 'Not confirmed',
  'meeting.svc.state1': 'Pending',
  'meeting.svc.state2': 'Not confirmed',
  'meeting.svc.state0': 'Confirmed',
  'meeting.svc.state3': 'Rejected',
  'meeting.svc.state4': 'Canceled',
  'meeting.svc.state5': 'Deleted',
  'meeting.svc.cancel': 'Cancel',
  'meeting.svc.remove': 'Remove',
  'meeting.svc.tips.title': 'Tips',
  'meeting.svc.tips.remove': 'Are you sure you want to delete it?',
  'meeting.svc.tips.cancel': 'Are you sure you want to cancel it?',
  'meeting.svc.showpage.toatl.tips': 'Total',
  'meeting.svcdeal.state1': 'To be served',
  'meeting.svcdeal.state2': 'Completed',
  'meeting.svcdeal.state3': 'Expired',
};
