import { formatMessage, history } from 'umi';
import moment from 'moment-timezone';
import { Toast } from 'antd-mobile-v2';
import { ROUTE_PATH } from '@/utils/constant';
import { isOffWork } from '../../utils/timeTools';
import * as service from './service';
import { UserColor } from '@/pages/Meeting/utils/constant';
import { zhIntl } from '@/utils/intlUtils';

export default {
  namespace: 'swsSpecial',
  state: {
    visibleList: [],
    startTime: undefined,
    endTime: undefined,
    selectedList: [],
    specialCancelinfo: { list: [] },
    stationItem: {},
    timeZone: 'Asia/Shanghai',
    specailSearchIndex: undefined,
    selectedEle: [],
    centerElement: {
      mapElementId: '',
      stationx: '',
      stationy: '',
      customElementWidth: '',
      customElementHeight: '',
    },
    updateExternalList:[],// 批量添加或者单个编辑外部人员的数据
    originalExternalUsers:[],// 外部人员的源所有列表数据
    externalUsers:[],// 外部人员的本地临时存储的所有列表数据
    selectedExternal: {} // 单选框选择的外部人员数据
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *synReservation({ payload, locationName, timeZone }, { call,put }) {
      const result = yield call(service.synReservation, payload);
      const choseResult = {};
      if (result && result.code === 0) {
        Toast.hide();
        let nums = '';
        payload.forEach((v) => {
          nums = `${v.stationNums} (${v.realNames});${nums}`;
        });
        choseResult.stationNum = nums.substring(0, nums.length - 1);
        choseResult.timeSelected = [
          `${moment(payload[0].startTimeStr)
            .tz(timeZone)
            .format(formatMessage({ id: 'sws.time.date' }))}`,
          `${moment(payload[0].endTimeStr)
            .tz(timeZone)
            .format(formatMessage({ id: 'sws.time.date' }))}`,
        ];
        // const { locationSelected } = yield select((state) => state.swsCommon);
        const locationNames = locationName?.split(',').slice(-3);
        history.push({
          pathname: `${ROUTE_PATH}/workspace/common/components/bookingResult`,
          state: { choseResult, locationNames, timeZone },
        });
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            selectedList: []
          },
        });
        yield put({
          type: 'swsCommon/saveOrUpdateData',
          payload: {
            isShareLocation: false,
          },
        });
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
    // 在位置树获取后尝试初始化时间
    *initTime({ payload, callback = () => {} }, { put }) {
      const { timeZone, globalTime, workday } = payload;
      // console.log('initTime timeZone', systemSet);
      const dateDay = workday?.[0] || moment().format('YYYY-MM-DD');
      // // 如果过了下班时间，日期按工作日数组里顺延一天
      // if (offWork) {
      //   dateDay = moment.tz(workday?.[1], timeZone).format('YYYY-MM-DD');
      // console.log(dateDay,'dateDay')
      const startTime = moment.tz(dateDay, timeZone);
      const endTime = moment.tz(dateDay, timeZone);
      // console.log('endTime', endTime.format('HH:mm'))
      let failReason = '';
      // 第二天没有日期，且是下班时间不支持预订
      // 未设置工作日 不支持预订
      const noTime = workday?.length === 0 && isOffWork(globalTime, timeZone);
      // console.log('initTime reserveNormalLimitDay', Number(reserveNormalLimitDay) === 1, isOffWork(globalTime, timeZone))
      const noGlobalTime = !(
        globalTime.amStartTime ||
        globalTime.amEndTime ||
        globalTime.pmStartTime ||
        globalTime.pmEndTime
      );

      if (noTime || noGlobalTime) {
        if (noTime) {
          failReason = noTime && formatMessage({ id: 'sws.booking.get.off.work' });
        } else if (noGlobalTime) {
          failReason = noGlobalTime && formatMessage({ id: 'sws.booking.get.no.time' });
        }
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            failReason,
            startTime: undefined,
            endTime: undefined,
          },
        });
        callback(undefined, undefined, failReason);
        return;
      }
      yield put({
        type: 'saveOrUpdateData',
        payload: { startTime, endTime, failReason },
      });
      callback(startTime, endTime, failReason);
    },
    *specialCancel({ uuid }, { call, put }) {
      const result = yield call(service.specialCancel, uuid);
      if (result && result.code === 0) {
        yield put({
          type: 'saveOrUpdateData',
          payload: {
            specialCancelinfo: result.data || {},
          },
        });
      } else if (result && result.code === 1) {
        Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
      }
    },
    // 检查工位是否占用
    *checkStationOccupy(_, { put, select }) {
      const {
        selectedList = [],
        mapStationList = [],
        bookingList = [],
      } = yield select((state) => {
        const { swsSpecial, swsCommon } = state;
        return {
          selectedList: swsSpecial.selectedList,
          mapStationList: swsSpecial.mapStationList,
          bookingList: swsCommon.bookingList,
        };
      });
      const copySelected = [];
      selectedList.forEach((item) => {
        const obj = item;
        mapStationList.forEach((map) => {
          if (String(item.elementId) === String(map.elementId)) {
            obj.statusCode = map.statusCode;
          }
        });
        copySelected.push(obj);
      });
      let selectedData = copySelected.filter(
        (item) => String(item.statusCode) === '5' || String(item.statusCode) === '6',
      );
      const copySelectedData = [];
      selectedData.forEach((item) => {
        const obj = item;
        bookingList.forEach((book) => {
          if (
            String(item.stationNum) === String(book.stationNums) &&
            (String(book.reserveStatus) === '1' || String(book.reserveStatus) === '2')
          ) {
            obj.startTime = book.startTime;
            obj.endTime = book.endTime;
          }
        });
        copySelectedData.push(obj);
      });

      selectedData = copySelectedData;
      if (selectedData && selectedData.length > 0) {
        yield put({
          type: 'swsCommon/saveOrUpdateData',
          payload: {
            stationOccupyModal: true,
            stationOccupyData: selectedData,
            specialBooking: true,
          },
        });
      }
    },
    // 取消特殊预订
    *cancelSpecialReservation({ payload }, { call }) {
      const result = yield call(service.cancelSpecialReservation, payload);
      if (result && result.code === 0) {
        // history.replace(`${ROUTE_PATH}/workspace/station/myBooking`);
        history.go(-1);
      } else if (result.code === 1 && result.msg && result.msg) {
        Toast.fail(formatMessage({ id: result.msg }));
      }
    },
    *getAllExternalList(_, { call, put }) {
      const result = yield call(service.getAllExternalList);
      // const result={
      //   "code": 0,
      //   "msg": null,
      //   "data": [
      //     {
      //       "gmtCreate": 1684735910000,
      //       "gmtModified": 1687661865000,
      //       "gmuCreate": "管理员",
      //       "gmuModified": "管理员",
      //       "id": 16,
      //       "tenantId": 1001,
      //       "userName": "zrr ",
      //       "phone": "18765433245",
      //       "mail": "",
      //       "interType": 1
      //     },
      //     {
      //       "gmtCreate": 1686646357000,
      //       "gmtModified": 1687657544000,
      //       "gmuCreate": "管理员",
      //       "gmuModified": "管理员",
      //       "id": 191,
      //       "tenantId": 1001,
      //       "userName": "zzzz",
      //       "phone": "",
      //       "mail": "",
      //       "interType": 1
      //     }
      //   ],
      //   "traceId": "a7683dac99ccfe9b"
      // }
      if (result && result.code === 0) {
        const array = result?.data?.map((item) => {
          // 用userId 取余
          const r = item.id % 4;
          return { ...item, color: UserColor[r] };
        });
        console.log(array,'result?.data')
        yield put({ type: 'saveOrUpdateData', payload: { externalUsers: array || [],originalExternalUsers: array || []} });
      }
    },
    // // 编辑外部访客保存操作
    // *saveExternalUser({ payload }, { call }) {
    //   const result = yield call(service.updateExternalList, payload);
    //   if (result && result.code === 0) {
    //     return result?.data || {};
    //   } else if (result.code === 1 && result.msg && result.msg) {
    //     Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
    //   }
    // },
    // // 根据外部用户id查询外部用户信息
    // *queryByExternalUserId({ payload }, { call, put }) {
    //   const result = yield call(service.queryByExternalUserId, payload);
    //   if (result && result.code === 0) {
    //     yield put({
    //       type: 'saveOrUpdateData',
    //       payload: {
    //         externalUser: result.data,
    //       },
    //     });
    //     return result?.data || {};
    //   } else if (result.code === 1 && result.msg && result.msg) {
    //     Toast.fail(formatMessage({ id: result.msg }, { ...result.data }));
    //   }
    // },
    *updateExternalList(_, { call, put, select }) {
      const { updateExternalList, selectedExternal } = yield select((state) => state.swsSpecial);
      if (updateExternalList.length === 0) {
        history.goBack();
        return;
      }
      const result = yield call(service.updateExternalList, { updateExternalList });
      if (result && result.code === 0) {
        const result1 = yield call(service.getAllExternalList);
        let newSelected ={};
        if (result1.code === 0) {
          const selected = result1?.data?.find(item=>item.id === selectedExternal.id)
          newSelected = {
            id: selected?.id,
            userName: selected?.userName,
          };
          // 给人员添加随机颜色
          const array = result1.data.map(item => {
            const r = item.id % 6;
            return { ...item, color: UserColor[r] }
          })
          yield put({
            type: 'saveOrUpdateData',
            payload: {
              externalUsers: array,
              selectedExternal: newSelected,
            },
          });
        }
        const restExternal = [];
        restExternal.push({
          userName: '',
          phone: '',
          telAreaCode: '+86',
          mail: '',
        });
        yield put({ type: 'saveOrUpdateData', payload: { updateExternalList: restExternal } });
        history.goBack();
        return newSelected;
      } else {
        Toast.fail(result && result.msg ? formatMessage({ id: result.msg }) : '');
      }
    },
    *deleteExternal({ user }, { call, put }) {
      const data = yield call(service.deleteExternal, { id: user.id });
      if (data && data.code === 0) {
        // 删除后重新进行外部参会人过滤并保存哦~ 不然直接点击返回按钮会出现问题
        // Toast.loading(formatMessage({ id: 'app.loading' }), 30);

        const result = yield call(service.getAllExternalList);
        if (result.code === 0) {
          // 给人员添加随机颜色
          const array = result.data.map(item => {
            const r = item.id % 6;
            return { ...item, color: UserColor[r] }
          })
          yield put({ type: 'saveOrUpdateData', payload: { externalUsers: array || [], originalExternalUsers: array || []} });
          Toast.success(zhIntl('删除成功'));
          return true;
        }
        //  Toast.hide();
      }
    },
  },
  subscriptions: {},
};
