export default {
  'points.account.overage.name': '账户余额({unit})',
  'points.account.overage.credits': '信用额度',
  'points.account.overage.lockIn': '锁定额度',

  'points.bill.orderStatus.title': '账单分类',
  'points.bill.orderStatus.income': '收入',
  'points.bill.orderStatus.expenditure': '支出',
  'points.bill.modal.confirm': '确定',
  'points.bill.modal.cancel': '取消',

  'points.billDetail.serial.number': '流水号',
  'points.billDetail.state': '状态',
  'points.billDetail.consumer.users': '消费用户',
  'points.billDetail.orderStatus': '账单分类',
  'points.billDetail.transaction.hour': '交易时间',
  'points.billDetail.principal': '负责人',
  'points.billDetail.remarks': '备注',
  'points.billDetail.remarks.none': '无',
  'points.billDetail.consumption.username': '预订人',
  'points.billDetail.consumption.number': '预定对象',
  'points.billDetail.consumption.startTime': '开始时间',
  'points.billDetail.consumption.endTime': '结束时间',
  'points.billDetail.orderStatus.0': '充值',
  'points.billDetail.orderStatus.1': '消费',
  'points.billDetail.orderStatus.2': '奖励',
  'points.billDetail.orderStatus.3': '惩罚',
  'points.billDetail.orderStatus.4': '退款',
  'points.billDetail.orderStatus.all': '全部',
  'points.billDetail.noData': '暂无数据',
  'points.billDetail.state.0': '已锁定',
  'points.billDetail.state.1': '已完成',
  'points.billDetail.state.2': '已取消',

  'points.punishment.new.btn.title': '新建罚单',
  'points.punishment.myList.btn.title': '罚单记录',
  'points.punishment.new.record.violation': '违规项',
  'points.punishment.new.record.violation.dept': '违规部门',
  'points.punishment.new.record.violation.userName': '负责人',
  'points.punishment.new.record.violation.checkTime': '检查时间',
  'points.punishment.new.record.violation.upload': '上传照片（选填）',
  'points.punishment.new.record.violation.upload.tip':
    '图片格式可以为jpeg / jpg / png, 大小不超过500K',
  'points.punishment.new.record.violation.remark': ' 文本备注（选填）',
  'points.punishment.new.record.submit': '提交罚单',
  'points.punishment.state.0': '待确认',
  'points.punishment.state.1': '已确认',
  'points.punishment.state.2': '已取消',
  'points.punishment.state.3': '已驳回',
  'points.punishment.record.account': '关联账户: ',
  'points.punishment.record.checkTime': '检查时间: ',
  'points.punishment.record.cancelPunishment': '取消罚单',
  'points.punishment.record.resetPunishment': '重新发起',
  'points.punishment.record.punishmentDetail': '罚单详情',
  'points.punishment.record.cancel': '取消',
  'points.punishment.record.detail.back': '返回列表',
  'points.punishment.new.record.select': '请选择',
  'points.punishment.new.record.add.success': '操作成功',
  'points.punishment.new.record.add.error': '操作失败',
  'points.punishment.new.record.add.rule': '违规项或者违规部门不能为空',
  'points.punishment.new.record.cancel.success': '取消成功',

  107001: '规则编号已存在',
  107002: '可用余额不足',
  107003: '账户类型配置无法识别',
  107004: '账户未找到',
  107005: '业务标识未识别',
  107006: '锁定金额失败',
  107007: '充值金额失败',
  107008: '取消订单失败',
  107009: '订单不存在',
  107010: '订单修改失败',
  107011: '操作失败',
  107012: '参数错误',
  107013: '部门管理员用户不存在',
  107014: '罚单已处理',
  107015: '信用额度修改失败',
  107016: '该部门存在自动充值配置',
  107017: '系统未配置域名，请联系管理员',

  'points.year': '年',
  'points.month': '月',
  'points.day': '日',
  'points.hour': '时',
  'points.min': '分',

  'points.product.exchange': '商品兑换',
  'points.product.exchange1': '兑换',
  'points.product.exchange.total': '总计：',
  'points.product.exchange.success': '兑换成功',
  'points.product.exchange.points.current': '您当前的积分为',
  'points.product.exchange.points.unit': '分',
  'points.product.exchange.points.unit1': '积分',
  'points.product.exchange.points.price': '单价',
  'points.product.exchange.points.detail': '积分明细',
  'points.product.exchange.points.notenough': '积分不足',
  'points.product.exchange.points.enough': '立即兑换',
  'points.product.exchange.sure': '我能兑换',
  'points.product.exchange.back': '返回列表',
  'points.details.view': '查看明细',
  'points.header.main': '主体负责人',
  'points.rewards': '奖励的积分',
  'points.used': '使用的积分',
};
