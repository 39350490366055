export default {
  'menu.app.index.space': '空間管理',
  'menu.app.index.map.sub': '空間地圖',
  'index.map.click.search.placeholder': '搜索',
  'index.map.search.station.placeholder': '請輸入搜索關鍵字',
  'index.map.btn.cancel': '取消',
  'index.map.searchList.noData': '暫無數據',
  104018: '目標區域已下線，無法進行跳轉',

  'index.map.select.time.range': '時間範圍：',
  'map.color.setting.material.status.1': '空閑',
  'map.color.setting.material.status.2': '佔用',
  'map.color.setting.material.status.3': '不可用',

  'index.map.space.local': '本地區域',
  'index.map.space.share': '共享區域',

  'index.map.space.setting.default.space': '默認區域設置',
  'index.map.space.setting.default.placeholder': '去設置',
  'index.map.btn.back': '返回',
  'index.map.space.setting.privacy': '隱私設置',
  'index.map.space.setting.privacy.placeholder': '首頁地圖容許搜到我',
};
