/**
 * Created : vincent
 * Date : 2019-03-28  09:48
 * Email : wangxiao@wafersystems.com
 */
export default {
  106101: '操作成功',
  106102: '操作失败',
  106103: '该用户尚未绑定，请登录进行绑定！',
  106104: '获取个人微信账号ID失败！',
  106105: '获取个人微信账号ID失败：公众账号系统配置有误！',
  106106: '获取个人微信账号ID失败：找不到账号！',
  106107: '获取个人微信账号ID失败：code不能为空！',
  106108: '获取用户绑定状态失败！',
  106109: '绑定账号成功！',
  106110: '绑定账号失败！',
  106111: '用户名或密码错误！',
  106112: '该账号已被绑定，不能重复绑定！',
  106113: '发送消息失败！',
  106114: '获取用户信息失败！',
  106115: 'code认证失败',
  106116: '公众账号系统配置有误',
  106117: '找不到对应的小程序',
  106118: '登录服务器未响应',
  106119: '获取账号绑定界面URL失败',
  106120: '访问令牌无效或过期，请重新登录',
  106121: '获取域信息失失败',
  106222: '名称重复,请重新输入',
  106223: '删除失败，不能删除已绑定应用的类型',
  106224: '请选择应用类型',
  106225: '没有对应的应用类型',
  106226: '微服务认证失败',
  106227: '对接企业微信平台设置可信域名失败',
  106228: '程序内部异常！',

  106000: '系统异常',
  106504: '认证失败！',
  106505: '用户名或密码错误！',
};
