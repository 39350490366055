/**
 * Created by wu on 2017/6/1.
 */
// import Toast from 'antd-mobile-v2/lib/toast';
// import { formatMessage } from 'umi';
import { formatMessage, history } from 'umi';
import { Toast } from 'antd-mobile-v2';
import {
  ROUTE_PATH,
  WAFER_TOKEN_LOGIN_ROUTER,
  PASSWORD_AES_KEY,
  LOGIN_ROUTER,
} from '@/utils/constant';
import { decryptAes } from '@/utils/utils';
import * as virsicalService from './services/virsical';
import { getWaferAppInfo, authWafer } from './services/services';

export default {
  namespace: 'waferConfig',
  state: {
    waferUrl: '',
  },
  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getWaferAppInfo({ appId }, { call, put }) {
      const data = yield call(getWaferAppInfo, appId);
      if (data.code === 0) {
        const waferApp = data.data;
        if (waferApp.waferAppId && waferApp.waferAppId !== '') {
          waferApp.waferAppId = decryptAes(waferApp.waferAppId, PASSWORD_AES_KEY);
        }
        if (waferApp.waferAppSecret && waferApp.waferAppSecret !== '') {
          waferApp.waferAppSecret = decryptAes(waferApp.waferAppSecret, PASSWORD_AES_KEY);
        }
        const waferData = yield call(virsicalService.init, waferApp);
        sessionStorage.setItem('waferLogin', data.data.waferUrl);
        // alert(data.data.waferUrl);
        yield put({
          type: 'authWafer',
          params: {
            appId,
            accessToken: waferData.accessToken,
            token: waferData.token,
            tempUrl: sessionStorage.getItem('tempUrlV'),
            hr: sessionStorage.getItem('hrV'),
            lc: sessionStorage.getItem('lcV'),
          },
        });
      } else {
        const errorText = formatMessage({ id: data.msg });
        Toast.fail(errorText);
      }
    },
    *authWafer({ params }, { call }) {
      const data = yield call(authWafer, { ...params });
      if (data.code === 0) {
        // alert(data.data.jumpUrl);
        if (data.data.jumpUrl.startsWith('http')) {
          window.location.replace(data.data.jumpUrl);
        } else {
          history.replace(`${ROUTE_PATH}/${data.data.jumpUrl}`);
        }
      } else {
        const errorText = formatMessage({ id: data.msg });
        Toast.fail(errorText);
        sessionStorage.setItem('loginType', 'WAFERLOGIN');
        history.replace({
          pathname: `${ROUTE_PATH}/user/login`,
        });
      }
    },
    *getCloudAuth({ redirect }, { call }) {
      const waferData = yield call(virsicalService.cloudAuth);
      if (waferData) {
        if (redirect && waferData.token) {
          const useAddress = `token_login?token=${waferData.token}&redirect=${redirect}`;
          history.replace(`${ROUTE_PATH}/${useAddress}`);
        } else {
          Toast.fail(`跳转地址缺失 / Redirect are missing`, 5, () => {
            history.replace(LOGIN_ROUTER);
          });
        }
      } else {
        Toast.fail(formatMessage({ id: 106504 }));
      }
    },
  },
  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return umiHistory.listen(({ pathname, query }) => {
        if (pathname.indexOf(WAFER_TOKEN_LOGIN_ROUTER) !== -1) {
          if (query.appId) {
            if (query.tempUrl) {
              sessionStorage.setItem('tempUrlV', query.tempUrl);
            }
            if (query.hr) {
              sessionStorage.setItem('hrV', query.hr);
            }
            if (query.lc) {
              sessionStorage.setItem('lcV', query.lc);
            } else {
              sessionStorage.removeItem('lcV');
            }
            dispatch({ type: 'getWaferAppInfo', appId: query.appId });
          }
        }
        if (pathname.indexOf('/cloud_scanAuth') !== -1) {
          if (query.r) {
            dispatch({ type: 'getCloudAuth', redirect: encodeURIComponent(decodeURIComponent(query.r)) });
          }
        }
      });
    },
  },
};
