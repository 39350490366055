import { matchPath } from 'umi';
import { MENU_PATH, RES_STATUS } from '@/pages/Meeting/utils/constant';
import { getRoomSeatList, saveMeetingUserSeat } from '@/pages/Meeting/pages/MeetingSeat/service';

export default {
  namespace: 'meetingSeat',
  state: {
    meetingId: 0,
    roomSeatData: [],
    roomSeatList: [],
    roomSeat: {},
    from: 1,
    pagination: {
      current: 1,
      pageSize: 5,
    },
  },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload };
    },
    addCurrent(state,) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current:state.pagination.current+1
        },
      };
    },
  },
  effects: {
    *getRoomSeatList(__, { select, call, put }) {
      const { roomIds = '' } = yield select((state) => state.subscribe);
      if (roomIds) {
        const { code, data } = yield call(getRoomSeatList, { roomIds });
        if (code === RES_STATUS.success) {
          if (data && data.length > 0) {
            yield put({
              type: 'set',
              payload: { roomSeatData: data, pagination: { current: 1, pageSize: 5 } },
            });
            yield put({ type: 'getSeatListForPag' });
          }
        }
      } else {
        window.console.log('未选择会议室');
      }
    },
    *getSeatListForPag(_, { put, select }) {
      const { selectRoomIdForSeat } = yield select((state) => state.subscribe);
      const { pagination, roomSeatData } = yield select((state) => state.meetingSeat);
      const roomSeatObj = roomSeatData.find(
        (item) => String(item.roomId) === String(selectRoomIdForSeat),
      );
      const roomSeatList = roomSeatObj.seatList.slice(0, pagination.current * pagination.pageSize);
      yield put({ type: 'set', payload: { roomSeatList, roomSeat: roomSeatObj } });
      return  roomSeatList
    },

    *submit({ payload: { meetingUserSeat } }, { select, call }) {
      const { meetingId } = yield select((state) => state.meetingSeat);
      const result = yield call(saveMeetingUserSeat, { meetingId, meetingUserSeat });
      return result;
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (matchPath(pathname, MENU_PATH.meetingSeat)) {
          const { query } = history.location;
          const { meetingId = 0, from = 0 } = query || {};
          dispatch({ type: 'set', payload: { meetingId, from } });
          dispatch({ type: 'getRoomSeatList' });
        }
      });
    },
  },
};
