import request from '@/utils/request';
import { RESTFUL_PATH } from '@/utils/constant';

export function loadAllUser({ tenantId }) {
  return request(`/${RESTFUL_PATH.meeting}/user/listByTenant?tenantId=${tenantId}`, {
    method: 'GET',
  });
}
export async function findUserVersion() {
  return request(`/${RESTFUL_PATH.meeting}/user/version`, {
    method: 'GET',
  });
}

/**
 * 查询所有部门
 * @param deptFrom
 * @returns {Object}
 */
export const getAllDepartment = () => {
  return request(`/${RESTFUL_PATH.admin}/dept/tree`, {
    method: 'GET',
  });
};

/**
 * 查询某个部门及子部门下的所有用户
 * @param deptId
 * @returns {Object}
 */
export const getUserListByDeptId = ({ deptId }) => {
  return request(`/${RESTFUL_PATH.admin}/user/getUserListByDeptId?deptId=${deptId}`, {
    method: 'GET',
  });
};

/**
 * 查询外部参会人
 * @returns {Promise<*>}
 */
export const getExternalList = () => {
  return request(`/${RESTFUL_PATH.meeting}/external-contacts/list`, {
    method: 'GET',
  });
};

/**
 * 获取常用联系人
 */
export const getFrequentUsers = ({ current, size = 10, name }) => {
  // let queryParameters = `current=${!current ? 1 : current}`;
  // if (size) {
  //   queryParameters = `${queryParameters}&size=${size}`;
  // }
  // if (name) {
  //   queryParameters = `${queryParameters}&name=${encodeURIComponent(name)}`;
  // }
  return request(`/${RESTFUL_PATH.meeting}/frequent-user/frequentUsers`);
};

// 手动添加常用联系人
export const addCollectUser = (param) => {
  return request(`/${RESTFUL_PATH.meeting}/frequent-user/add`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
};

// 取消常用联系人
export const deleteCollectUser = (param) => {
  return request(`/${RESTFUL_PATH.meeting}/frequent-user/delete`, {
    method: 'POST',
    body: JSON.stringify(param),
  });
};

/**
 * 批量保存外部参会人
 */
export const saveUserList = ({ list }) => {
  return request(`/${RESTFUL_PATH.meeting}/external-contacts/save`, {
    method: 'POST',
    body: JSON.stringify(list),
  });
};

/**
 * 删除外部参会人
 * @param id
 * @returns {Object}
 */
export const deleteUser = ({ id }) => {
  return request(`/${RESTFUL_PATH.meeting}/external-contacts/delete/${id}`, {
    method: 'POST',
  });
};

/**
 * 选人组件查询
 * @param current
 * @param size
 * @param deptId
 * @param realName
 * @returns {Promise<questMethodAddToUrl|*>}
 */
export async function getUserList({ nameKey, size, current }) {
  return request(
    `/${RESTFUL_PATH.admin}/user/page?delFlag=0&realName=${nameKey}&size=${size}&current=${current}`,
  );
}

export async function getUserStatus(obj) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-user/scheduler`, {
    method: 'POST',
    body: JSON.stringify(obj),
  });
}

export async function getUserStatusOfO365(obj) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-user/scheduler/o365`, {
    method: 'POST',
    body: JSON.stringify(obj),
  });
}

export async function getUserStatusOfFeishu(obj) {
  return request(`/${RESTFUL_PATH.meeting}/meeting-user/scheduler/feishu`, {
    method: 'POST',
    body: JSON.stringify(obj),
  });
}

// 获取国家码
export async function getAreaCode() {
  return request(`/${RESTFUL_PATH.meeting}/external-contacts/getCountryList`);
}
