import { AUTHORITY } from '@/utils/constant';
import {getTokenByRefreshToken} from "@/utils/utils";

// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority(str) {
  // return localStorage.getItem('antd-pro-authority') || ['admin', 'user'];
  const authorityString =
    typeof str === 'undefined' ? localStorage.getItem('antd-pro-authority') : str;
  // authorityString could be admin, "admin", ["admin"]
  let authority;
  try {
    authority = JSON.parse(authorityString);
  } catch (e) {
    authority = authorityString;
  }
  if (typeof authority === 'string') {
    return [authority];
  }
  return authority || ['admin'];
}

export function setAuthority(authority) {
  window.console.log(authority, '2222222')

  // 登录后启动refreshToken定时器
  getTokenByRefreshToken();

  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('lark') !== -1) {
    return localStorage.setItem(
      'authorityLark',
      window.btoa(encodeURIComponent(JSON.stringify(authority))),
    );
  } else {
    return localStorage.setItem(
      AUTHORITY,
      window.btoa(encodeURIComponent(JSON.stringify(authority)))
    );
  }
}
