import { formatMessage, history } from 'umi';
import { getUrlParam } from '@/utils/utils';
import { ACCESS_TOKEN, ROUTE_PATH } from '@/utils/constant';
import { Toast } from 'antd-mobile-v2';
import { getUserBindState, weChatBindFuc } from './services';

export default {
  namespace: 'bind',

  state: {
    bBindState: 0,
    userInfo: {},
    userBindInfo: {
      userId: '',
      password: '',
    },
  },

  effects: {
    *getUserBindStatus(_, { call, put }) {
      const state = sessionStorage.getItem('state');
      const accId = sessionStorage.getItem('accId');
      const menuId = sessionStorage.getItem('menuId');
      const tempUrl = sessionStorage.getItem('tempUrlWx');
      const hr = sessionStorage.getItem('hr');
      const data = yield call(getUserBindState, { accId, state, menuId, tempUrl, hr });

      if (data && data.code !== 1) {
        if (data.data.loginUserVo.userId) {
          sessionStorage.setItem(ACCESS_TOKEN, data.data.loginUserVo.token);
          if (data.data.urlReturn.startsWith('http')) {
            window.location.replace(data.data.urlReturn);
          } else {
            history.replace(`${ROUTE_PATH}/${data.data.urlReturn}`);
          }
        } else {
          yield put({
            type: 'saveOrUpdateData',
            payload: { bBindState: 0, userInfo: data.data.loginUserVo },
          });
        }
      } else {
        yield put({
          type: 'saveOrUpdateData',
          payload: { bBindState: 1, userInfo: data.data.loginUserVo },
        });
      }
    },
    *weChatBind({ payload }, { call, put }) {
      const state = sessionStorage.getItem('state');
      const accId = sessionStorage.getItem('accId');
      const menuId = sessionStorage.getItem('menuId');
      const tempUrl = sessionStorage.getItem('tempUrlWx');
      const hr = sessionStorage.getItem('hr');
      const lc = sessionStorage.getItem('lcWx');
      const data = yield call(weChatBindFuc, {
        userId: payload.userName,
        password: payload.password,
        code: payload.code,
        randomStr: payload.randomStr,
        state,
        accId,
        menuId,
        tempUrl,
        hr,
        lc,
      });
      if (data && data.code === 0) {
        if (data.data.member.userId) {
          if (data.data.urlReturn.startsWith('http')) {
            window.location.replace(data.data.urlReturn);
          } else {
            history.replace(`${ROUTE_PATH}/${data.data.urlReturn}`);
          }
        } else {
          yield put({
            type: 'saveOrUpdateData',
            payload: { bBindState: 0, userInfo: data.data.member },
          });
        }
      } else if (data && data.msg) {
        Toast.fail(formatMessage({ id: data.msg }), 2);
      }
    },
  },

  reducers: {
    saveOrUpdateData(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  subscriptions: {
    setup({ dispatch, history: umiHistory }) {
      return umiHistory.listen(({ pathname }) => {
        if (pathname === '/wdfclient/bind') {
          sessionStorage.setItem('accId', getUrlParam('accId'));
          sessionStorage.setItem('state', getUrlParam('state'));
          sessionStorage.setItem('tokenSn', getUrlParam('tokenSn'));
          sessionStorage.setItem('sign', getUrlParam('sign'));
          sessionStorage.setItem('token', getUrlParam('token'));
          sessionStorage.setItem('menuId', getUrlParam('menuId'));
          sessionStorage.setItem('tempUrlWx', getUrlParam('tempUrl'));
          sessionStorage.setItem('hr', getUrlParam('hr'));
          if (getUrlParam('lc')) {
            sessionStorage.setItem('lcWx', getUrlParam('lc'));
          } else {
            sessionStorage.removeItem('lcWx');
          }
          if(getUrlParam('tempUrl').indexOf('scanAuthPage') !== -1){
            // 统一扫码进来的，未绑定
            dispatch({
              type: 'saveOrUpdateData',
              payload: { userInfo: {} },
            });
          } else {
            dispatch({ type: 'getUserBindStatus' });
          }
        }
      });
    },
  },
};
