export default {
  'sws.myBooking.station': '工位',
  'sws.myBooking.station.colon': '工位：',
  'sws.myBooking.space.colon': '区域：',
  'sws.myBooking.check.in': '签到',
  'sws.myBooking.info': '提示',
  'sws.myBooking.station.chose.cancel': '您确定要取消已经选择的工位?',
  'sws.myBooking.station.using': '您正在使用的工位：',
  'sws.myBooking.station.booking': '您预订的工位：',
  'sws.station.info': '工位信息',
  'sws.cancel.station': '取消预订',
  'sws.check.station': '查看工位',
  'sws.myBooking.station.using.or.reserve': '您没有正在使用或预订的工位',
  'sws.myBooking.cancel.reserve.station': '您确定要取消已经预订的工位?',
  'sws.myBooking.logout.station.using': '您确定要提前注销正在使用的工位?',
  'sws.myBooking.space': '区域',
  'sws.myBooking.booking.time': '预订时间',
  'sws.myBooking.booking.cancel': '取消预订',
  'sws.myBooking.booking.time.colon': '预订时间：',
  'sws.myBooking.used.time.colon': '使用时间：',
  'sws.myBooking.reserveStatus': '状态',
  'sws.myBooking.canceled': '已取消',
  'sws.myBooking.used': '已使用',
  'sws.myBooking.using': '使用中',
  'sws.myBooking.operation': '操作',
  'sws.myBooking.reserve.again': '再次预订',
  'sws.myBooking.delay': '延长工位',
  'sws.myBooking.lock.logout': '注销储物柜',
  'sws.myBooking.station.num': '共{num}个工位',
  'sws.history.not.booking': '您已经有预订的工位，不支持预订两个或以上的座位',
  'sws.history.no.auth': '此工位没有权限，请选择其他工位',
  'sws.history.reBook': '再次预订',
  'sws.history.not.booked': '您还没有预订过工位',
  'sws.reserve.delay.success': '延长成功',
  'sws.chose.time.delay': '选择延长时间',
  'sws.can.not.delay': '无法延长',
  'locker.can.not.reverse': '很抱歉，您没有预订当前储物柜的权限',
  'locker.no.reverse': '您当前没有预订的储物柜哦～',
  'sws.no.reverse.history': '您当前没有预订记录哦～',
  'sws.locker.reverse': '预订储物柜',
  'sws.myBooking.common': '普通预订',
  'sws.myBooking.category': '类型：',
  'locker.myBooking.not.fixed': '专用储物柜不能再次预订',
  'locker.myBooking.not.use': '当前储物柜不可预订',
  401115: '特殊预订无法延时',
  'sws.open.success': '开启成功',
  'sws.open.error': '开启失败',
  'sws.open': '开门',
  'sws.selected': '确认取消以上选择?',
};
