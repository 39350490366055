export default {
  'locker.immediate.hours': '小时',
  'locker.immediate.submit': '立即使用',
  'locker.immediate.station': '储物柜',
  'locker.immediate.address': '区域',
  'locker.immediate.time': '时间',
  'locker.immediate.ok': '确认',
  'locker.immediate.cancel': '取消',
  'locker.immediate.select.time': '选择时间',
  'locker.immediate.submit.success': '恭喜您，操作成功！',
  403000: '系统异常',
  403003: '参数错误',
  403013: '储物柜不存在',
  403014: '储物柜未绑定',
  403032: '储物柜已被占用',
  403033: '不支持预订两个或者两个以上储物柜！',
  403034: '系统工作日时间未设置',
};
